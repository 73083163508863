import React from 'react';
import { useReactiveVar } from '@apollo/client';
import Collapse from '@mui/material/Collapse';
import FooterAction from '../../../presentationals/FormComponents/FooterAction';
import Promotion from './Promotion';
import ProductPromotion from './ProductPromotion';
import ClassificationPromotion from './ClassificationPromotion';
import { form, step } from '../../../apollo/cache';

const NextStepComponent = () => {
  const formVar = useReactiveVar(form);

  return [1, 3].includes(parseInt(formVar.promotionType)) ? (
    <ProductPromotion />
  ) : (
    <ClassificationPromotion />
  );
};

const FormPromotionComponent = () => {
  const stepVar = useReactiveVar(step);
  return (
    <div className='py-1 px-3'>
      <Collapse in={stepVar === 0}>
        <Promotion />
      </Collapse>
      <Collapse in={stepVar === 1}>
        <NextStepComponent />
      </Collapse>
      <FooterAction />
    </div>
  );
};

export default FormPromotionComponent;
