var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { forwardRef, useImperativeHandle } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { isSaleCustomerFormOpen, customerSaleV2, inputError, actionForm, } from '../../../../../../../../apollo/cache';
import setInputErrorFormat from '../../../../../helpers/setInputErrorFormat';
import getInputErrorFormat from '../../../../../helpers/getInputErrorFormat';
import useCreateApolloMutation from '../../../../../../../../hooks/useApolloMutation';
import Button from '../../../../../../../../presentationals/Dialog/Footer/Button';
import setCreateBody from './helpers/setCreateBody';
import CONSTANTS_ERROR from '../../../../../../../../constants/error';
import GQL_CUSTOMER from '../../../../../../../../apollo/gql/customer';
import useSnackbarDialog from '../../../../../../../../store/snackbar/snackbarDialog';
var CreateButtonFooterCreateFormCustomerActionSaleV2Component = forwardRef(function (props, ref) {
    var setSnackbar = useSnackbarDialog().setSnackbar;
    var params = useParams();
    var navigate = useNavigate();
    var _a = useCreateApolloMutation(GQL_CUSTOMER.CREATE_CUSTOMER, {
        name: 'customers',
        fragment: GQL_CUSTOMER.FRAGMENT_CUSTOMER,
        fragmentName: 'FragmentCustomer',
    }), create = _a[0], loading = _a[1].loading;
    var handleClick = function () { return __awaiter(void 0, void 0, void 0, function () {
        var values, variables, response, e_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    inputError({});
                    values = props.getValues();
                    if (!values)
                        throw new Error(CONSTANTS_ERROR.UNDEFINED_VALUES);
                    if (values.firstName === '')
                        throw new Error(setInputErrorFormat('firstName', 'Proporciona el nombre del cliente.'));
                    if (values.businessName === '' && values.identifier.length > 0)
                        throw new Error(setInputErrorFormat('businessName', 'Proporciona una razón social.'));
                    if (values.identifier === '' && values.businessName.length > 0)
                        throw new Error(setInputErrorFormat('identifier', 'Proporciona un RFC.'));
                    if (values.email === '' && values.identifier.length > 0)
                        throw new Error(setInputErrorFormat('email', 'Proporciona un correo electrónico.'));
                    variables = setCreateBody(values);
                    return [4 /*yield*/, create({
                            variables: __assign(__assign({}, variables), { id: params.customerId, rateId: variables.priceName || null, priceName: variables.rateId || null }),
                        })];
                case 1:
                    response = _a.sent();
                    customerSaleV2(__assign(__assign(__assign({}, values), response.data.createCustomer), { priceName: response.data.createCustomer.priceName
                            ? response.data.createCustomer.priceName
                            : -1, employeeId: response.data.createCustomer.employeeId
                            ? response.data.createCustomer.employeeId
                            : '', phone: response.data.createCustomer.phone ? response.data.createCustomer.phone : '', phone2: response.data.createCustomer.phone2 ? response.data.createCustomer.phone2 : '', rateId: response.data.createCustomer.rateId ? response.data.createCustomer.rateId : '' }));
                    isSaleCustomerFormOpen(false);
                    actionForm({ isOpen: false, action: '', defaultData: {}, callback: null });
                    setSnackbar({
                        isOpen: true,
                        label: 'El cliente se creó correctamente.',
                        severity: 'success',
                    });
                    if (!props.noReturn)
                        navigate('..');
                    return [3 /*break*/, 3];
                case 2:
                    e_1 = _a.sent();
                    getInputErrorFormat(e_1);
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    useImperativeHandle(ref, function () { return ({
        onPressEnter: handleClick,
    }); });
    return (React.createElement(Button, { disabled: loading, className: 'ml-1', onClick: handleClick }, "Almacenar"));
});
export default CreateButtonFooterCreateFormCustomerActionSaleV2Component;
