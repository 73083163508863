import React from 'react';

import MessageIcon from '@mui/icons-material/MessageOutlined';
import AccessTimeIcon from '@mui/icons-material/AccessTimeOutlined';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

const MenuIconComponent = props => {
  switch (props.type) {
    case 'MESSAGE':
      return <MessageIcon />;
    case 'CUSTOMER_CHECK_IN':
      return <AccessTimeIcon />;
    case 'CUSTOMER_CHECK_OUT':
      return <AccessTimeIcon />;
    default:
      return <HelpOutlineIcon />;
  }
};

export default MenuIconComponent;
