var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useEffect } from 'react';
import { useMutation, useReactiveVar } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { inputError, isEditProductLimitInventoryOpen } from '../../../../../apollo/cache';
import DialogComponent from '../../../../../presentationals/Dialog';
import getInputErrorFormat from '../../../../sale.v2/Action/helpers/getInputErrorFormat';
import GQL_PRODUCT from '../../../../../apollo/gql/product';
import BodyLimitInventoryFormComponent from '../Create/Body';
import useApolloLazyQuery from '../../../../../hooks/useApolloLazyQuery';
import LoadingProgress from '../../../../../presentationals/LoadingProgress';
var EditLimitInventoryProductPresentationComponent = function () {
    var params = useParams();
    var isOpen = useReactiveVar(isEditProductLimitInventoryOpen);
    var _a = useMutation(GQL_PRODUCT.UPDATE_BRANCH_PRODUCT_PRESENTATION_CONFIGURATION), createLimitInventory = _a[0], loading = _a[1].loading;
    var _b = useApolloLazyQuery(GQL_PRODUCT.GET_BRANCH_PRODUCT_PRESENTATION_CONFIGURATION_BY_ID), getData = _b[0], _c = _b[1], loadingData = _c.loading, data = _c.data;
    useEffect(function () {
        if (isOpen)
            getData({
                variables: {
                    id: isOpen,
                },
            });
    }, [isOpen]);
    var handleClose = function () {
        inputError({});
        isEditProductLimitInventoryOpen(false);
    };
    var handleCreate = function (values) { return __awaiter(void 0, void 0, void 0, function () {
        var error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, createLimitInventory({
                            variables: {
                                productPresentationId: params.productPresentationId,
                                minimum: parseFloat(values.minimum),
                                maximum: parseFloat(values.maximum),
                                branchId: values.branchId,
                            },
                        })];
                case 1:
                    _a.sent();
                    handleClose();
                    return [3 /*break*/, 3];
                case 2:
                    error_1 = _a.sent();
                    getInputErrorFormat(error_1);
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    return (React.createElement(DialogComponent, { fullScreen: true, open: !!isOpen, title: 'Editar l\u00EDmite de inventario', onClose: handleClose }, loadingData || !data ? (React.createElement(LoadingProgress, null)) : (React.createElement(BodyLimitInventoryFormComponent, { defaultValues: {
            maximum: data.maximum.toString(),
            minimum: data.minimum.toString(),
            branchId: parseInt(data.branchId.toString()),
        }, buttonLabel: 'Editar l\u00EDmite de inventario', loading: loading, onButtonClick: handleCreate, deleteId: isOpen || undefined }))));
};
export default EditLimitInventoryProductPresentationComponent;
