import React, { useState, useEffect } from 'react';

import ListAltIcon from '@mui/icons-material/ListAltOutlined';

import { actions } from '../../apollo/cache';

import ProductsDialog from './products.dialog';

const ActionComponent = () => {
  const [isOpenProducts, setIsOpenProducts] = useState([false, null]);

  const handleProducts = (e, { id }) => setIsOpenProducts([true, id]);
  const handleCloseProducts = () => setIsOpenProducts([false, null]);

  useEffect(() => {
    actions(
      [
        {
          icon: <ListAltIcon />,
          label: 'Productos',
          onClick: handleProducts,
        },
      ].filter(el => el),
    );
    return () => {
      actions([]);
    };
  }, []);

  return (
    <ProductsDialog
        isOpen={isOpenProducts[0]}
        handleClose={handleCloseProducts}
        id={isOpenProducts[1]}
      />
  );
};

export default ActionComponent;
