import React from 'react';
import useApolloQuery from '../../../hooks/useApolloQuery';
import GQL_CALENDAR from '../../../apollo/gql/calendar';
import ItemUpcomingAppointmentsCalendarQueueManagerComponent from '../UpcomingAppointments/Item';
//BG bg-[#21656d]
//TX text-white
var InTurnAppointmentsCalendarQueueManagerComponent = function (props) {
    var data = useApolloQuery(GQL_CALENDAR.GET_CALENDARS, {
        variables: { status: [4] },
    }).data;
    if (!data)
        return React.createElement("div", null);
    return (React.createElement("div", { className: 'w-7/12 flex px-2 justify-center flex-col overflow-hidden', style: {
            backgroundColor: props.Color.leftHeaderBackgroundColor || '#fff',
        } },
        React.createElement("div", { className: 'leading-tight text-5xl font-medium', style: {
                color: props.Color.leftHeaderTextColor || '#000',
            } }, data.rows.length === 0 ? 'En espera de turno' : 'Turno de'),
        data.rows.map(function (el, index) {
            return index === 0 && (React.createElement(ItemUpcomingAppointmentsCalendarQueueManagerComponent, { textColor: props.Color.leftHeaderTextColor, key: el.id, Calendar: el }));
        })));
};
export default InTurnAppointmentsCalendarQueueManagerComponent;
