var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { forwardRef, useImperativeHandle } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from '@mui/material';
import getInputErrorFormat from '../../../sale.v2/Action/helpers/getInputErrorFormat';
import useSnackbarDialog from '../../../../store/snackbar/snackbarDialog';
import useApolloMutation from '../../../../hooks/useApolloMutation';
import GQL from '../../_gql';
import { inputError } from '../../../../apollo/cache';
import setInputErrorFormat from '../../../sale.v2/Action/helpers/setInputErrorFormat';
import CONSTANTS_ERROR from '../../../../constants/error';
var CreateButtonEmployeeComponent = forwardRef(function (props, ref) {
    var setSnackbar = useSnackbarDialog().setSnackbar;
    var navigate = useNavigate();
    var _a = useApolloMutation(GQL.CREATE, {
        name: 'employees',
        fragment: GQL.FRAGMENT_NEW_EMPLOYEE,
        fragmentName: 'fragmentNewEmployee',
    }), createEmployee = _a[0], loading = _a[1].loading;
    var handleClick = function () { return __awaiter(void 0, void 0, void 0, function () {
        var values, error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    inputError({});
                    values = props.getValues();
                    if (!values)
                        throw new Error(CONSTANTS_ERROR.UNDEFINED_VALUES);
                    if (values.firstName === '')
                        throw new Error(setInputErrorFormat('firstName', 'Proporciona el nombre del empleado.'));
                    if (values.lastName === '')
                        throw new Error(setInputErrorFormat('lastName', 'Proporciona los apellidos del empleado.'));
                    if (values.phone === '')
                        throw new Error(setInputErrorFormat('phone', 'Proporciona el teléfono del empleado.'));
                    if (!values.roleId)
                        throw new Error(setInputErrorFormat('roleId', 'Selecciona los permisos del empleado.'));
                    if (!values.branchId)
                        throw new Error(setInputErrorFormat('branchId', 'Selecciona la sucursal predeterminada del empleado.'));
                    if (values.password === '')
                        throw new Error(setInputErrorFormat('password', 'Proporciona la contraseña para acceso a la web.'));
                    if (values.userPassword === '')
                        throw new Error(setInputErrorFormat('userPassword', 'Proporciona una contraseña para el punto de venta.'));
                    return [4 /*yield*/, createEmployee({ variables: values })];
                case 1:
                    _a.sent();
                    setSnackbar({
                        isOpen: true,
                        label: 'El empleado se registró correctamente.',
                        severity: 'success',
                    });
                    navigate('..');
                    return [3 /*break*/, 3];
                case 2:
                    error_1 = _a.sent();
                    getInputErrorFormat(error_1);
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    useImperativeHandle(ref, function () { return ({
        onPressEnter: handleClick,
    }); });
    return (React.createElement(Button, { disabled: loading, color: 'success', variant: 'contained', className: 'px-5 py-3', onClick: handleClick }, "Almacenar"));
});
export default CreateButtonEmployeeComponent;
