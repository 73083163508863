import React, { useEffect, useState } from 'react';

// import LockIcon from '@mui/icons-material/LockOutlined';
// import AttachMoneyIcon from '@mui/icons-material/AttachMoneyOutlined';
import ListAltIcon from '@mui/icons-material/ListAltOutlined';
import PrintIcon from '@mui/icons-material/Print';
import CreditCardIcon from '@mui/icons-material/CreditCardOutlined';
import DescriptionIcon from '@mui/icons-material/DescriptionOutlined';
import ContentCopyIcon from '@mui/icons-material/FileCopyOutlined';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';

import CONFIG from '../../config/config.json';

import { actions, isCloseMaintenanceOpen } from '../../apollo/cache';

import ProduceMaintenance from './produceTechnicalServiceOrder.dialog';
import ProductsDialog from './products.dialog';
import Payment from '../receivable.v2/payment.dialog';
import CancelForm from './cancel.form';
import XLS from './xls.products';
import CloseMaintenanceComponent from './CloseMaintenance';
import getSession from '../../helpers/getSession';

const ActionComponent = () => {
  const [isOpen, setIsOpen] = useState([false, null]);
  const [isOpenProducts, setIsOpenProducts] = useState([false, null, null]);
  const [isOpenPayment, setIsOpenPayment] = useState([false, null]);
  const [isOpenCancelMaintenance, setIsOpenCancelMaintenance] = useState([false, null]);

  const handleCloseProduce = () => setIsOpen([false, null]);
  const handleOpenProducts = (e, { id, status }) => setIsOpenProducts([true, id, status]);
  const handleCloseProducts = () => setIsOpenProducts([false, null, null]);

  const handlePayment = (e, { AccountReceivable }) => {
    if (!AccountReceivable) return false;
    setIsOpenPayment([true, AccountReceivable.id]);
  };
  const handleClosePayment = () => setIsOpenPayment([false, null]);

  const handlePdf = (e, { AccountReceivable }) => {
    const session = getSession();
    if (session?.Company.id === 71)
      if (AccountReceivable && AccountReceivable.Sale && AccountReceivable.Sale.Invoice)
        window.open(
          `${CONFIG['restServer'].uri}/files/invoices/${AccountReceivable.Sale.Invoice.uuid}/pdf`,
        );
      else
        window.open(
          `${CONFIG['restServer'].uri}/files/sales/${AccountReceivable.Sale.id}?category=1`,
        );
  };

  const handleProductsXls = (e, { id }) => XLS(id);

  const handleCloneMaintenance = (e, { id }) => isCloseMaintenanceOpen([true, id]);

  const handleCancelMaintenance = (e, { id, Sale: { invoiceId } }) =>
    setIsOpenCancelMaintenance([true, { id, invoiceId }]);

  const handleCloseCancelMaintenance = () => setIsOpenCancelMaintenance([false, null]);

  useEffect(() => {
    actions([
      {
        icon: <PrintIcon />,
        label: 'Imprimir PDF',
        onClick: handlePdf,
      },
      {
        icon: <DescriptionIcon />,
        label: 'Exportar Productos a xls',
        onClick: handleProductsXls,
      },
      {
        icon: <CreditCardIcon />,
        label: 'Abonar',
        onClick: handlePayment,
        disable: { key: 'status', value: [1, 3] },
      },
      {
        icon: <ListAltIcon />,
        label: 'Productos',
        onClick: handleOpenProducts,
        disable: { key: 'status', value: 3 },
      },
      {
        icon: <ContentCopyIcon />,
        label: 'Cerrar y Crear Nueva Mensualidad',
        onClick: handleCloneMaintenance,
        disable: { key: 'status', value: 3 },
      },
      {
        icon: <DeleteOutlineIcon />,
        label: 'Cancelar Mantenimiento',
        onClick: handleCancelMaintenance,
      },
    ]);
  }, []);

  return (
    <>
      <ProduceMaintenance isOpen={isOpen[0]} handleClose={handleCloseProduce} id={isOpen[1]} />
      <ProductsDialog
        isOpen={isOpenProducts[0]}
        handleClose={handleCloseProducts}
        id={isOpenProducts[1]}
        status={isOpenProducts[2]}
      />
      <CloseMaintenanceComponent />
      <Payment
        isOpen={isOpenPayment[0]}
        handleClose={handleClosePayment}
        accountReceivableId={isOpenPayment[1]}
      />
      <CancelForm
        isOpen={isOpenCancelMaintenance[0]}
        handleClose={handleCloseCancelMaintenance}
        id={isOpenCancelMaintenance[1] && isOpenCancelMaintenance[1].id}
        invoiceId={isOpenCancelMaintenance[1] && isOpenCancelMaintenance[1].invoiceId}
      />
    </>
  );
};

export default ActionComponent;
