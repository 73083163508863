var getProductColumnsToExport = function () {
    return [
        {
            value: 'productId',
            label: 'Identificador del producto',
        },
        {
            value: 'productName',
            label: 'Nombre del producto',
        },
        {
            value: 'classificationName',
            label: 'Clasificación',
        },
        {
            value: 'productType',
            label: 'Tipo de producto',
        },
        {
            value: 'productPrice',
            label: 'Precio de venta del producto',
        },
        {
            value: 'productCost',
            label: 'Precio de compra del producto',
        },
        {
            value: 'productDescription',
            label: 'Descripción',
        },
        {
            value: 'productSku',
            label: 'SKU',
        },
        {
            value: 'productBarcode',
            label: 'Código de barras',
        },
        {
            value: 'productProductKey',
            label: 'Clave de producto y servicio (SAT)',
        },
        {
            value: 'productUnitKey',
            label: 'Código de unidad de medida (SAT)',
        },
        {
            value: 'productIva',
            label: 'IVA %',
        },
        {
            value: 'productIeps',
            label: 'IEPS %',
        },
        {
            value: 'productPrefix',
            label: 'Prefijo del producto',
        },
        {
            value: 'stock',
            label: 'Stock',
        },
    ];
};
export default getProductColumnsToExport;
