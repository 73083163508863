var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import GQL from '../containers/employee/_gql';
var useGetEmployees = function (variables, format) {
    var _a = useState({ count: 0, rows: [] }), formattedData = _a[0], setFormattedData = _a[1];
    var _b = useQuery(GQL.GET, { variables: variables }), data = _b.data, loading = _b.loading, error = _b.error;
    useEffect(function () {
        if (data && data.employees) {
            var _a = data.employees, count = _a.count, rows = _a.rows;
            var formattedRows = rows;
            switch (format) {
                case 'select':
                    formattedRows = rows.map(function (el) { return ({
                        label: "".concat(el.firstName, " ").concat(el.lastName).trim().toUpperCase(),
                        value: el.id,
                    }); });
                    formattedRows = __spreadArray([{ value: '', label: 'VENTA DE MOSTRADOR' }], formattedRows, true);
                    break;
                default:
                    break;
            }
            setFormattedData({ count: count, rows: formattedRows });
        }
    }, [data, format]);
    return { data: formattedData, loading: loading, error: error };
};
export default useGetEmployees;
