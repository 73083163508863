import ERP_LOGIN from './erpLogin';
import FRAGMENT_BRANCH_SESSION from './fragmentBranchSession';
import GET_BRANCH_SESSION from './getBranchSession';
import RELOGIN from './reLogin';
import UPDATE_WEB_PASSWORD from './updateWebPassword';
var GQL_SESSION = {
    ERP_LOGIN: ERP_LOGIN,
    RELOGIN: RELOGIN,
    GET_BRANCH_SESSION: GET_BRANCH_SESSION,
    FRAGMENT_BRANCH_SESSION: FRAGMENT_BRANCH_SESSION,
    UPDATE_WEB_PASSWORD: UPDATE_WEB_PASSWORD,
};
export default GQL_SESSION;
