var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { gql } from '@apollo/client';
import getProductGqlColumn from '../../../containers/product/helpers/getGqlColumn';
import getProductTableColumnConfiguration from '../../../helpers/getProductTableColumnConfiguration';
var CUSTOMIZED_GET_PRODUCTS = function (customizedColumns) {
    var settings = customizedColumns
        ? __assign(__assign({}, customizedColumns), { name: true, price: true }) : getProductTableColumnConfiguration();
    var columns = Object.keys(settings)
        .filter(function (key) { return settings[key]; })
        .map(function (key) { return getProductGqlColumn(key); })
        .join(' ');
    return gql(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    query Products(\n      $limit: Int\n      $offset: Int\n      $name: [String]\n      $type: [Int]\n      $classificationId: [ID]\n      $order: [String]\n      $forPurchase: Boolean\n      $ids: [ID]\n    ) {\n      products(\n        limit: $limit\n        offset: $offset\n        name: $name\n        type: $type\n        classificationId: $classificationId\n        order: $order\n        forPursache: $forPurchase\n        ids: $ids\n      ) {\n        count\n        rows {\n          id\n          type\n          ", "\n        }\n      }\n    }\n    "], ["\n    query Products(\n      $limit: Int\n      $offset: Int\n      $name: [String]\n      $type: [Int]\n      $classificationId: [ID]\n      $order: [String]\n      $forPurchase: Boolean\n      $ids: [ID]\n    ) {\n      products(\n        limit: $limit\n        offset: $offset\n        name: $name\n        type: $type\n        classificationId: $classificationId\n        order: $order\n        forPursache: $forPurchase\n        ids: $ids\n      ) {\n        count\n        rows {\n          id\n          type\n          ", "\n        }\n      }\n    }\n    "])), columns);
};
export default CUSTOMIZED_GET_PRODUCTS;
var templateObject_1;
