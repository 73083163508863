import React, { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import DialogComponent from '../../../../../presentationals/Dialog';
import FooterDialogComponent from '../../../../../presentationals/Dialog/Footer';
import CreateRequisitionProductButtonComponent from './CreateButton';
import BodyCreateRequisitionProductDialogComponent from './Body';
var CreateRequisitionProductDialogComponent = forwardRef(function (props, ref) {
    var bodyCreateRequisitionProductDialogRef = useRef(null);
    var _a = useState(false), open = _a[0], setOpen = _a[1];
    var handleClose = function () {
        setOpen(false);
    };
    var handleOpen = function () {
        setOpen(true);
    };
    var handleGetValues = function () { var _a; return (_a = bodyCreateRequisitionProductDialogRef.current) === null || _a === void 0 ? void 0 : _a.getValues(); };
    useImperativeHandle(ref, function () { return ({
        open: handleOpen,
    }); });
    return (React.createElement(DialogComponent, { open: !!open, title: 'A\u00F1adir producto a la requisici\u00F3n', onClose: handleClose, fullScreen: true },
        React.createElement("div", { className: 'flex flex-1 flex-col' },
            React.createElement(BodyCreateRequisitionProductDialogComponent, { ref: bodyCreateRequisitionProductDialogRef }),
            React.createElement(FooterDialogComponent, null,
                React.createElement("div", null),
                React.createElement(CreateRequisitionProductButtonComponent, { getValues: handleGetValues, onCreated: handleClose, requisitionId: props.requisitionId })))));
});
export default CreateRequisitionProductDialogComponent;
