var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useEffect, useState } from 'react';
import { useReactiveVar } from '@apollo/client';
import DialogComponent from '../../../presentationals/Dialog';
import { filter } from '../../../apollo/cache';
import Button from '../../../presentationals/Dialog/Footer/Button';
import BranchFieldFilterEmployeeComponent from './BranchField';
import FooterDialogComponent from '../../../presentationals/Dialog/Footer';
import SearchFieldFilterEmployeeComponent from './SearchField';
import getInputErrorFormat from '../../sale.v2/Action/helpers/getInputErrorFormat';
import useFilterButtonDialog from '../../../store/filterButton/filterButtonDialog';
var defaultValues = {
    like: ['name', ''],
    branchId: '-1',
};
var isLoading = false;
var FilterEmployeeComponent = function () {
    var currentFilter = useReactiveVar(filter);
    var open = useFilterButtonDialog(function (state) { return state.open; });
    var setOpen = useFilterButtonDialog(function (state) { return state.setOpen; });
    var _a = useState(defaultValues), values = _a[0], setValues = _a[1];
    useEffect(function () {
        setValues(function (values) { return (__assign(__assign({}, values), { like: [values.like[0], currentFilter.like ? currentFilter.like[1] : ''] })); });
    }, [currentFilter]);
    var handleClose = function () {
        setOpen(false);
    };
    var handleChange = function (name, value) {
        setValues(function (values) {
            var _a;
            return (__assign(__assign({}, values), (_a = {}, _a[name] = value, _a)));
        });
    };
    var handleClear = function () {
        setValues(defaultValues);
    };
    var handleClick = function () {
        try {
            filter(__assign(__assign({}, currentFilter), { branchId: !values.branchId || values.branchId === '-1' ? undefined : values.branchId, like: values.like }));
            handleClose();
        }
        catch (error) {
            getInputErrorFormat(error);
        }
    };
    return (React.createElement(DialogComponent, { open: open, fullScreen: true, title: 'Filtro para b\u00FAsqueda de empleados', onClose: handleClose },
        React.createElement("div", { className: 'grid flex-1 grid-cols-1 md:grid-cols-3 md:gap-4 px-4 py-1' },
            React.createElement("div", null,
                React.createElement(SearchFieldFilterEmployeeComponent, { onChange: handleChange, value: values.like }),
                React.createElement(BranchFieldFilterEmployeeComponent, { onChange: handleChange, value: values.branchId }))),
        React.createElement(FooterDialogComponent, null,
            React.createElement("div", null),
            React.createElement("div", null,
                React.createElement(Button, { disabled: isLoading, className: 'mr-2', color: 'inherit', onClick: handleClear }, "Limpiar"),
                React.createElement(Button, { disabled: isLoading, color: 'success', onClick: handleClick }, "Aplicar filtro")))));
};
export default FilterEmployeeComponent;
