import { gql } from '@apollo/client';

const GET = gql`
  query OrderAuthorizations($limit: Int, $offset: Int, $status: Int, $branchId: ID) {
    orderAuthorizations(limit: $limit, offset: $offset, status: $status, branchId: $branchId) {
      count
      rows {
        id
        description
        action
        status
        code
        deadline
        createdAt
        Employee {
          id
          firstName
          lastName
        }
        AuthEmployee {
          id
          firstName
          lastName
        }
        DeleteEmployee {
          id
          firstName
          lastName
        }
        Branch {
          id
          name
        }
      }
    }
  }
`;

const GENERATE_CODE = gql`
  mutation GenerateOrderAuthorizationCode($id: ID) {
    generateOrderAuthorizationCode(id: $id) {
      id
      description
      action
      status
      code
      deadline
      createdAt
      Employee {
        id
        firstName
        lastName
      }
      AuthEmployee {
        id
        firstName
        lastName
      }
      DeleteEmployee {
        id
        firstName
        lastName
      }
      Branch {
        id
        name
      }
    }
  }
`;

export default { GET, GENERATE_CODE };
