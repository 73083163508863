import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import SearchPermissionEditRoleComponent from './SearchPermission';
import BranchPermissionEditRoleComponent from './Branch';
import RolePermissionEditRoleComponent from './Role';
import EmployeePermissionEditRoleComponent from './Employee';
import BoxPermissionEditRoleComponent from './Box';
import ClassificationPermissionEditRoleComponent from './Classification';
import PaymentMethodPermissionEditRoleComponent from './PaymentMethod';
import ProviderPermissionEditRoleComponent from './Provider';
import CustomerPermissionEditRoleComponent from './Customer';
import LoginPermissionEditRoleComponent from './Login';
import CashFlowPermissionEditRoleComponent from './CashFlow';
import CashClosingPermissionEditRoleComponent from './CashClosing';
import PromotionPermissionEditRoleComponent from './Promotion';
import QuotationPermissionEditRoleComponent from './Quotation';
import ServiceOrderPermissionEditRoleComponent from './ServiceOrder';
import MaintenancePermissionEditRoleComponent from './Maintenance';
import EcommercePermissionEditRoleComponent from './Ecommerce';
import ReceivablePermissionEditRoleComponent from './Receivable';
import PayablePermissionEditRoleComponent from './Payable';
import ExpensesPermissionEditRoleComponent from './Expenses';
import IncomeStatementPermissionEditRoleComponent from './IncomeStatement';
import TransformationPermissionEditRoleComponent from './Transformation';
import PurchaseOrderPermissionEditRoleComponent from './PurchaseOrder';
import PurchaseOrderReportPermissionEditRoleComponent from './PurchaseOrderReport';
import RequisitionPermissionEditRoleComponent from './Requisition';
import TransferPermissionEditRoleComponent from './Transfer';
import InventoryAdjustPermissionEditRoleComponent from './InventoryAdjust';
import InventoryPermissionEditRoleComponent from './Inventory';
import AttendancePermissionEditRoleComponent from './Attendance';
import RentPermissionEditRoleComponent from './Rent';
import ProductPermissionEditRoleComponent from './Product';
import SalePermissionEditRoleComponent from './Sale';
import OrderAuthorizationPermissionEditRoleComponent from './OrderAuthorization';
import ProductionPermissionEditRoleComponent from './Production';
import SaleReportsPermissionEditRoleComponent from './SaleReports';
import GeneralSaleReportPermissionEditRoleComponent from './GeneralSaleReport';
import useApolloLazyQuery from '../../../../hooks/useApolloLazyQuery';
import GQL_ROLE from '../../../../apollo/gql/role';
import LoadingProgress from '../../../../presentationals/LoadingProgress';
import ErrorDataComponent from '../../../../presentationals/ErrorData';
var PermissionEditRoleComponent = function () {
    var params = useParams();
    var _a = useApolloLazyQuery(GQL_ROLE.GET_ROLE_BY_ID), getRole = _a[0], _b = _a[1], loading = _b.loading, data = _b.data;
    useEffect(function () {
        if (params.roleId)
            getRole({ variables: { id: params.roleId } });
    }, [params]);
    if (loading)
        return React.createElement(LoadingProgress, null);
    if (!data)
        return React.createElement(ErrorDataComponent, null);
    if (!params.roleId || !data.Permissions)
        return React.createElement("div", null);
    return (React.createElement("div", { className: 'flex-1 flex flex-col overflow-auto basis-0' }, data.name === 'PROPIETARIO' ? (React.createElement("div", { className: 'flex flex-1 p-4' }, "Los permisos de un propietario no pueden ser modificados.")) : (React.createElement("div", { className: 'flex flex-col' },
        React.createElement(SearchPermissionEditRoleComponent, null),
        React.createElement("div", { className: 'border-0 border-b border-[#d6d6d6] border-solid pb-2' }),
        React.createElement(InventoryAdjustPermissionEditRoleComponent, { module: 'InventoryAdjust', Permissions: data.Permissions.InventoryAdjust }),
        React.createElement(OrderAuthorizationPermissionEditRoleComponent, { module: 'OrderAuthorization', Permissions: data.Permissions.OrderAuthorization }),
        React.createElement(BoxPermissionEditRoleComponent, { module: 'Box', Permissions: data.Permissions.Box }),
        React.createElement(ClassificationPermissionEditRoleComponent, { module: 'Classification', Permissions: data.Permissions.Classification }),
        React.createElement(CustomerPermissionEditRoleComponent, { module: 'Customer', Permissions: data.Permissions.Customer }),
        React.createElement(ReceivablePermissionEditRoleComponent, { module: 'Receivable', Permissions: data.Permissions.Receivable }),
        React.createElement(AttendancePermissionEditRoleComponent, { module: 'Attendance', Permissions: data.Permissions.Attendance }),
        React.createElement(CashClosingPermissionEditRoleComponent, { module: 'CashClosing', Permissions: data.Permissions.CashClosing }),
        React.createElement(QuotationPermissionEditRoleComponent, { module: 'Quotation', Permissions: data.Permissions.Quotation }),
        React.createElement(PayablePermissionEditRoleComponent, { module: 'Payable', Permissions: data.Permissions.Payable }),
        React.createElement(EcommercePermissionEditRoleComponent, { module: 'Ecommerce', Permissions: data.Permissions.Ecommerce }),
        React.createElement(EmployeePermissionEditRoleComponent, { module: 'Employee', Permissions: data.Permissions.Employee }),
        React.createElement(IncomeStatementPermissionEditRoleComponent, { module: 'IncomeStatement', Permissions: data.Permissions.IncomeStatement }),
        React.createElement(PaymentMethodPermissionEditRoleComponent, { module: 'PaymentMethod', Permissions: data.Permissions.PaymentMethod }),
        React.createElement(ExpensesPermissionEditRoleComponent, { module: 'Expense', Permissions: data.Permissions.Expense }),
        React.createElement(PurchaseOrderPermissionEditRoleComponent, { module: 'PurchaseOrder', Permissions: data.Permissions.PurchaseOrder }),
        React.createElement(InventoryPermissionEditRoleComponent, { module: 'Inventory', Permissions: data.Permissions.Inventory }),
        React.createElement(PurchaseOrderReportPermissionEditRoleComponent, { module: 'PurchaseOrderReport', Permissions: data.Permissions.PurchaseOrderReport }),
        React.createElement(GeneralSaleReportPermissionEditRoleComponent, { module: 'SaleReports', Permissions: data.Permissions.SaleReports }),
        React.createElement(LoginPermissionEditRoleComponent, { module: 'Employee', Permissions: data.Permissions.Employee }),
        React.createElement(CashFlowPermissionEditRoleComponent, { module: 'CashFlow', Permissions: data.Permissions.CashFlow }),
        React.createElement(MaintenancePermissionEditRoleComponent, { module: 'Maintenance', Permissions: data.Permissions.Maintenance }),
        React.createElement(ServiceOrderPermissionEditRoleComponent, { module: 'ServiceOrder', Permissions: data.Permissions.ServiceOrder }),
        React.createElement(ProductPermissionEditRoleComponent, { module: 'Product', Permissions: data.Permissions.Product }),
        React.createElement(ProductionPermissionEditRoleComponent, { module: 'Production', Permissions: data.Permissions.Production }),
        React.createElement(PromotionPermissionEditRoleComponent, { module: 'Promotion', Permissions: data.Permissions.Promotion }),
        React.createElement(ProviderPermissionEditRoleComponent, { module: 'Provider', Permissions: data.Permissions.Provider }),
        React.createElement(RolePermissionEditRoleComponent, { module: 'Role', Permissions: data.Permissions.Role }),
        React.createElement(RentPermissionEditRoleComponent, { module: 'Rent', Permissions: data.Permissions.Rent }),
        React.createElement(SaleReportsPermissionEditRoleComponent, { module: 'SaleReports', Permissions: data.Permissions.SaleReports }),
        React.createElement(RequisitionPermissionEditRoleComponent, { module: 'Requisition', Permissions: data.Permissions.Requisition }),
        React.createElement(BranchPermissionEditRoleComponent, { module: 'Branch', Permissions: data.Permissions.Branch }),
        React.createElement(TransferPermissionEditRoleComponent, { module: 'Transfer', Permissions: data.Permissions.Transfer }),
        React.createElement(TransformationPermissionEditRoleComponent, { module: 'Transformation', Permissions: data.Permissions.Transformation }),
        React.createElement(SalePermissionEditRoleComponent, { module: 'Sale', Permissions: data.Permissions.Sale })))));
};
export default PermissionEditRoleComponent;
