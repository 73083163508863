var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React, { useState, useEffect } from 'react';
import SelectFieldComponent from '../../../../../../../../../presentationals/FormComponents/SelectField';
import GQL from '../../../../../../../../product.v2/_gql';
import useApolloLazyQuery from '../../../../../../../../../hooks/useApolloLazyQuery';
var PriceNameSelectFieldHelpersBodyCreateFormCustomerActionSaleV2Component = function (props) {
    var _a = useState([]), options = _a[0], setOptions = _a[1];
    var _b = useApolloLazyQuery(GQL.GET_RATES), getRates = _b[0], _c = _b[1], data = _c.data, loading = _c.loading;
    useEffect(function () {
        getRates({ variables: { branchId: null } });
    }, []);
    useEffect(function () {
        if (data && data.rows) {
            var newData = data.rows.map(function (el) { return ({ value: el.id, label: el.name }); });
            setOptions(__spreadArray([{ value: -1, label: 'Estándar' }], newData, true));
        }
    }, [data]);
    var handleChange = function (name, value) {
        props.handleChange(name, value);
    };
    return (React.createElement(SelectFieldComponent, { label: props.label || 'Tarifa de precios predeterminada', name: props.name || 'priceName', value: options.length > 0 && props.value ? props.value : '', error: props.error, onChange: handleChange, disabled: loading, options: options, helperText: props.helperText }));
};
export default PriceNameSelectFieldHelpersBodyCreateFormCustomerActionSaleV2Component;
