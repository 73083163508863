import { gql } from '@apollo/client';
import { redirect } from 'react-router-dom';
import client from '../index';
import getSession from '../../helpers/getSession';
import setSession from '../../helpers/setSession';

const REFRESH_TOKEN = gql`
  mutation RefreshToken($token: String!) {
    refreshToken(token: $token) {
      accessToken
    }
  }
`;

const getNewToken = async () => {
  try {
    const session = getSession();
    const token = session?.refreshToken || '';
    const { data } = await client.mutate({
      mutation: REFRESH_TOKEN,
      variables: { token },
    });
    const newToken = data.refreshToken.accessToken;
    setSession({ accessToken: newToken });
    return `Bearer ${newToken}`;
  } catch (e) {
    localStorage.clear();
    redirect('/');
  }
};

export default getNewToken;
