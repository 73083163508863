import React from 'react';

import { useReactiveVar } from '@apollo/client';

import MenuIcon from '@mui/icons-material/Menu';

import IconButton from '@mui/material/IconButton';

import { isMenuOpen } from '../../../../apollo/cache';

const MenuButton = () => {
  const isMenuOpenVar = useReactiveVar(isMenuOpen);

  const handleDrawerAction = () => {
    isMenuOpen(!isMenuOpenVar);
  };

  return (
    <IconButton
      edge='start'
      color='default'
      aria-label='open menu'
      onClick={handleDrawerAction}
      size='large'
    >
      <MenuIcon fontSize='small' />
    </IconButton>
  );
};

export default MenuButton;
