import React, { useRef } from 'react';
import PercentOutlinedIcon from '@mui/icons-material/PercentOutlined';
import { useController, useFormContext } from 'react-hook-form';
import OnlineOrderMenuButtonComponent from '../helpers/MenuButton';
import RHFDiscountPopoverComponent from '../../../presentationals/RHForm/DiscountPopover';
var OnlineOrderDiscountButtonComponent = function () {
    var rhfDiscountPopoverRef = useRef(null);
    var control = useFormContext().control;
    var value = useController({
        name: 'discount',
        control: control,
    }).field.value;
    var handleClick = function (e) {
        var _a;
        (_a = rhfDiscountPopoverRef.current) === null || _a === void 0 ? void 0 : _a.open(e.currentTarget);
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(OnlineOrderMenuButtonComponent, { onClick: handleClick, label: 'Descuento general', Icon: PercentOutlinedIcon, value: !value ? 'No aplicado' : "".concat(value, "%") }),
        React.createElement(RHFDiscountPopoverComponent, { name: 'discount', ref: rhfDiscountPopoverRef })));
};
export default OnlineOrderDiscountButtonComponent;
