import React, { useState, useEffect } from 'react';

import { useLazyQuery, useReactiveVar } from '@apollo/client';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import FormDialog from '../../presentationals/FormDialog';

import { currencyFormat } from '../../helpers/general';
import { filter } from '../../apollo/cache';

import GQL from './_gql';

const DialogComponent = props => {
  const filterVar = useReactiveVar(filter);
  const [values, setValues] = useState([]);

  const [getProductTransfer, { loading }] = useLazyQuery(GQL.GET_PRODUCT_TRANSFER, {
    onCompleted: data => {
      data &&
        data.transferProductDetails &&
        data.transferProductDetails.rows.length > 0 &&
        setValues(data.transferProductDetails.rows);
    },
  });

  useEffect(() => {
    if (props.productId && props.branchTransferId)
      getProductTransfer({
        variables: {
          limit: 0,
          start: filterVar.start,
          end: filterVar.end,
          branchTransferId: props.branchTransferId,
          productId: props.productId,
        },
      });
  }, [props.productId]);

  const handleClose = () => {
    setValues([]);
    props.handleClose();
  };

  return (
    <FormDialog
        isOpen={props.isOpen}
        isLoading={loading}
        title={`Traspasos de ${props.product}`}
        disableEscape
        // width='md'
        handleClose={handleClose}
        hideAction
      >
        <TableContainer>
          <Table size='small'>
            <TableHead>
              <TableRow>
                <TableCell>Cant.</TableCell>
                <TableCell>Unidad</TableCell>
                <TableCell align='center'>Costo Unitario</TableCell>
                <TableCell align='center'>Costo Total</TableCell>
                <TableCell align='right'>Folio</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {values.map((el, i) => (
                <TableRow key={i}>
                  <TableCell>{el.quantity}</TableCell>
                  <TableCell>
                    {el.ProductPresentation ? el.ProductPresentation.name : el.ProductUnit.name}
                  </TableCell>
                  <TableCell align='center'>{`$${currencyFormat(el.cost)}`}</TableCell>
                  <TableCell align='center'>{`$${currencyFormat(el.total)}`}</TableCell>
                  <TableCell align='right'>{`${el.Transfer.folio}-${el.Transfer.serial}`}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </FormDialog>
  );
};

export default DialogComponent;
