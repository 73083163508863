import React from 'react';
import AddCircleIcon from '@mui/icons-material/AddOutlined';
import IconButtonComponent from '../../../../../presentationals/IconButton';
var OpenAddRequisitionProductDialogButtonComponent = function (props) {
    var handleClick = function () {
        props.onClick();
    };
    return (React.createElement(IconButtonComponent, { action: handleClick, icon: React.createElement(AddCircleIcon, null), size: 'small', color: 'secondary', label: 'A\u00F1adir tarifa' }));
};
export default OpenAddRequisitionProductDialogButtonComponent;
