import React, { useState, useEffect } from 'react';

import { useQuery } from '@apollo/client';

import Typography from '@mui/material/Typography';

import FilterDrawer from '../../presentationals/FilterDrawer.v2';
import Autocomplete from '../../presentationals/Autocomplete';
import { filter } from '../../apollo/cache';
import { customerLabel } from '../../helpers';

import GQL_CUSTOMER from '../customer/_gql';
import GQL_EMPLOYEE from '../employee/_gql';
import SelectField from '../../presentationals/FormComponents/SelectField';

const customerVar = customerLabel({ textTransform: 'capitalize' });

const defaultValue = {
  customer: null,
  paid: false,
  timeStatus: 0,
  sellerId: null,
};

const yesNoOptions = [
  { label: 'No', value: false },
  { label: 'Si', value: true },
];

const timeStatusOptions = [
  { label: 'Todas', value: 0 },
  { label: 'Vencidas', value: 1 },
  { label: 'Vigentes', value: 2 },
];

const FilterDialog = () => {
  const [customers, setCustomers] = useState([]);
  const [values, setValues] = useState(defaultValue);
  const [sellerOptions, setSellerOptions] = useState([]);

  const { data } = useQuery(GQL_CUSTOMER.GET_NAME_AND_PHONE, { variables: { limit: 0 } });

  const { data: sellerData } = useQuery(GQL_EMPLOYEE.GET_NAME, {
    variables: { limit: 0, isSeller: true },
  });

  useEffect(() => {
    if (data) {
      const tmp = data.customers.rows.map(el => ({
        label: `${el.firstName} ${el.lastName || ''} ${el.phone || ''}`,
        value: el.id,
        priceName: el.priceName,
      }));
      setCustomers(tmp);
    }
  }, [data]);

  useEffect(() => {
    if (sellerData && sellerData.employees) {
      const tmp = sellerData.employees.rows.map(el => {
        return {
          label: `${el.firstName.trim()} ${el.lastName.trim()}`.trim(),
          value: el.id,
        };
      });
      setSellerOptions(tmp);
    }
  }, [sellerData]);

  const handleAction = (e, data) => {
    const sellerId = values.sellerId ? values.sellerId.value : null;
    filter({
      branchId: data.branchId,
      customerId: values.customer && values.customer.value,
      paid: values.paid,
      timeStatus: values.timeStatus,
      sellerId,
    });
  };

  const handleAutocompleteChange = (e, name, value) => {
    setValues(values => ({ ...values, [name]: value }));
  };

  const handleChange = (name, value) => {
    setValues(values => ({ ...values, [name]: value }));
  };

  return (
    <FilterDrawer handleAction={handleAction} showBranches>
      <Typography variant='subtitle1'>{`${customerVar}:`}</Typography>
      <Autocomplete
        name='customer'
        variant='outlined'
        onChange={handleAutocompleteChange}
        options={customers}
        value={values.customer}
        autoFocus
        noFormat
      />
      <Typography variant='subtitle1'>Cuentas pagadas</Typography>
      <SelectField
        name='paid'
        variant='outlined'
        onChange={handleChange}
        options={yesNoOptions}
        value={values.paid}
        noFormat
      />
      <Typography variant='subtitle1'>Estatus de Cuentas</Typography>
      <SelectField
        name='timeStatus'
        variant='outlined'
        onChange={handleChange}
        options={timeStatusOptions}
        value={values.timeStatus}
        noFormat
      />
      <Typography variant='subtitle1'>Vendedor:</Typography>
      <Autocomplete
        name='sellerId'
        onChange={handleAutocompleteChange}
        options={sellerOptions}
        value={values.sellerId}
        // size='medium'
        variant='outlined'
        noFormat
      />
    </FilterDrawer>
  );
};

export default FilterDialog;
