import React from 'react';
import { useParams } from 'react-router-dom';
import { useReactiveVar } from '@apollo/client';
import DialogComponent from '../../../../presentationals/Dialog';
import { isCancelPaymentOpen } from '../../../../apollo/cache';
import BodyCancelPaymentDialogComponent from './Body';
var CancelPaymentDialogComponent = function () {
    var isOpen = useReactiveVar(isCancelPaymentOpen);
    var params = useParams();
    var handleClose = function () {
        isCancelPaymentOpen(false);
    };
    if (!isOpen)
        return React.createElement("div", null);
    if (!params.accountReceivableId)
        return React.createElement("div", null);
    return (React.createElement(DialogComponent, { fullScreen: true, open: !!isOpen, title: 'Cancelar abono a cuenta', onClose: handleClose },
        React.createElement(BodyCancelPaymentDialogComponent, { accountReceivableId: params.accountReceivableId, transactionId: isOpen })));
};
export default CancelPaymentDialogComponent;
