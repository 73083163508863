var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useMemo } from 'react';
import ModuleV3Component from '../../presentationals/Module.v3';
import getSaleReportHeaderColumns from './helpers/getSaleReportHeaderColumns';
import TableSettingsSaleComponent from './TableSettings';
import FilterSaleDialog from './Filter';
import useGetSales from './helpers/useGetSales';
import RedirectEditFormToRouteComponent from '../../presentationals/RedirectEditFormToRoute';
import MoreOptionsSaleReportComponent from './MoreOptions';
import getSearchSaleByOptions from '../../helpers/getSearchSaleByOptions';
import getSaleTotalsFromData from './helpers/getSaleTotalsFromData';
import convertToCurrencyLabel from '../../helpers/convertToCurrencyLabel';
import getSaleReportActions from './helpers/actions';
var SaleReportComponent = function () {
    var _a = useGetSales(), data = _a.data, loading = _a.loading;
    var searchByOptions = getSearchSaleByOptions();
    var saleReportActions = getSaleReportActions();
    var header = useMemo(function () {
        var headers = getSaleReportHeaderColumns();
        var totals = getSaleTotalsFromData(data);
        return headers.map(function (el) {
            var accessor = el.accessor;
            return __assign(__assign({}, el), { Footer: Object.keys(totals).includes(el.accessor)
                    ? convertToCurrencyLabel(totals[accessor])
                    : '' });
        });
    }, [data]);
    return (React.createElement(React.Fragment, null,
        React.createElement(ModuleV3Component, { actions: saleReportActions, tableSetting: true, searchFieldOptions: {
                name: 'searchSaleBy',
                options: searchByOptions,
                defaultValue: 'folio',
            }, filter: true, title: 'Ventas por folio', hideAdd: true, data: data, header: header, isLoading: loading, showReturnButton: true, route: -1 }),
        React.createElement(TableSettingsSaleComponent, null),
        React.createElement(FilterSaleDialog, null),
        React.createElement(RedirectEditFormToRouteComponent, null),
        React.createElement(MoreOptionsSaleReportComponent, null)));
};
export default SaleReportComponent;
