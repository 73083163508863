import React, { useState, useEffect } from 'react';
import { useQuery } from '@apollo/client';
import Typography from '@mui/material/Typography';
import FilterDrawer from '../../presentationals/FilterDrawer.v2';
import { filter } from '../../apollo/cache';
import Autocomplete from '../../presentationals/FormComponents/Autocomplete';
import PRODUCT_GQL from '../product/_gql';
import BRANCH_GQL from '../branch/_gql';
import GQL_CLASSIFICATION from '../../apollo/gql/classification';

const defaultValues = {
  productId: null,
  branchId: null,
  classificationId: null,
};

const FilterDialog = () => {
  const [products, setProducts] = useState([]);
  const [branches, setBranches] = useState([]);
  const [values, setValues] = useState(defaultValues);
  const [classifications, setClassifications] = useState([]);

  const { data: dataClassifications } = useQuery(GQL_CLASSIFICATION.GET_CLASSIFICATION, {
    variables: { limit: 0 },
  });

  const handleAction = (e, data) => {
    const { startDate: start, endDate: end } = data;
    const productId = values.productId ? values.productId.value : null;
    const branchId = values.branchId ? values.branchId.value : null;
    const classificationId = values.classificationId ? values.classificationId.value : null;
    filter({ start, end, productId, branchId, classificationId });
  };

  useQuery(PRODUCT_GQL.GET_NAME_AND_PRESENTATIONS, {
    variables: { limit: 0, includeBaseInPresentations: true },
    onCompleted: data => {
      const tmp = data.productsV2.rows.map(el => ({
        label: `${String(el.code).padStart(3, '0')} ${el.name.trim()} ${
          el.sku ? ` | SKU ${el.sku.trim()}` : ''
        } ${el.barcode ? ` | C.B. ${el.barcode.trim()}` : ''}`.trim(),
        value: el.id,
      }));
      setProducts(tmp);
    },
  });

  useQuery(BRANCH_GQL.GET, {
    variables: { limit: 0 },
    onCompleted: data => {
      const tmp = data.branches.rows.map(el => ({ label: el.name, value: el.id }));
      setBranches(tmp);
    },
  });

  useEffect(() => {
    if (dataClassifications) {
      const tmp = dataClassifications.classifications.rows.map(el => ({
        label: el.name,
        value: el.id,
      }));
      setClassifications(tmp);
    }
  }, [dataClassifications]);

  const handleAutocompleteChange = (name, value) => {
    setValues(values => ({ ...values, [name]: value }));
  };

  return (
    <FilterDrawer handleAction={handleAction} showDates>
      <Typography variant='subtitle1'>Producto:</Typography>
      <Autocomplete
        name='productId'
        variant='outlined'
        onChange={handleAutocompleteChange}
        options={products}
        value={values.productId}
        size='medium'
      />
      <br />
      <Typography variant='subtitle1'>Sucursal:</Typography>
      <Autocomplete
        name='branchId'
        variant='outlined'
        onChange={handleAutocompleteChange}
        options={branches}
        value={values.branchId}
        size='medium'
      />
      <br />
      <Typography variant='subtitle1'>Clasificación:</Typography>
      <Autocomplete
        name='classificationId'
        variant='outlined'
        onChange={handleAutocompleteChange}
        options={classifications}
        value={values.classificationId}
        size='medium'
      />
    </FilterDrawer>
  );
};

export default FilterDialog;
