import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import DeleteButtonImagePromotionComponent from './DeleteButton';
import ImageComponent from '../../../product/Item/Image/Item';
import UploadButtonImagePromotionComponent from './UploadPromotionButton';
import LoadingProgress from '../../../../presentationals/LoadingProgress';
import useApolloLazyQuery from '../../../../hooks/useApolloLazyQuery';
import GQL_PROMOTION from '../../../../apollo/gql/promotion';
import ErrorDataComponent from '../../../../presentationals/ErrorData';
var ImagePromotionComponent = function () {
    var params = useParams();
    var _a = useApolloLazyQuery(GQL_PROMOTION.GET_BY_ID), getById = _a[0], _b = _a[1], loading = _b.loading, data = _b.data;
    useEffect(function () {
        if (params.promotionId)
            getById({ variables: { id: params.promotionId } });
    }, [params]);
    if (loading)
        return React.createElement(LoadingProgress, null);
    if (!data)
        return React.createElement(ErrorDataComponent, null);
    return (React.createElement("div", { className: 'flex flex-1 px-4 py-3 flex-col ' },
        React.createElement("div", { className: 'text-base font-medium mb-1 flex' }, "Im\u00E1genes del producto"),
        React.createElement("div", { className: 'text-sm sm:text-base text-gray-600 mb-2' },
            "Las im\u00E1genes son una pieza fundamental al presentar un producto. A trav\u00E9s de ellas, los clientes pueden visualizar y conectar de manera directa con lo que est\u00E1n adquiriendo. En caso de que el producto cuente con diferentes presentaciones, aseg\u00FArate de configurar las im\u00E1genes correspondientes para cada una de ellas en la secci\u00F3n de",
            ' ',
            React.createElement("strong", null, "Estructura"),
            ". De lo contrario, se utilizar\u00E1n las im\u00E1genes predeterminadas."),
        React.createElement("div", null,
            React.createElement(UploadButtonImagePromotionComponent, null)),
        React.createElement("div", { className: 'mt-3 flex-1 flex flex-col sm:grid sm:grid-cols-6 sm:gap-4 overflow-auto' }, (data === null || data === void 0 ? void 0 : data.fileId) && (React.createElement("div", { className: 'flex flex-col' },
            React.createElement(ImageComponent, { width: 'full', src: data.fileId.toString(), alt: "promotion-".concat(data.fileId) }),
            React.createElement(DeleteButtonImagePromotionComponent, { fileId: data.fileId }))))));
};
export default ImagePromotionComponent;
