import React, { useEffect } from 'react';
import useTheme from '@mui/material/styles/useTheme';
import useMediaQuery from '@mui/material/useMediaQuery';
import Dialog from '@mui/material/Dialog';
import Header from './Header';
import { actionForm, isEditFormOpen } from '../../apollo/cache';
var DialogComponent = function (props) {
    var theme = useTheme();
    var isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    useEffect(function () {
        return function () {
            if (props.inherit)
                return;
            isEditFormOpen(false);
            actionForm({
                isOpen: false,
                action: '',
                defaultData: {},
                callback: null,
            });
        };
    }, []);
    var handleClose = function () {
        props.onClose && props.onClose();
    };
    return (React.createElement(Dialog, { disablePortal: props.disablePortal, onClose: handleClose, disableEscapeKeyDown: props.disableEscapeKeyDown, fullScreen: props.fullScreen || isSmallScreen, fullWidth: typeof props.fullWidth === 'boolean' ? props.fullWidth : true, maxWidth: props.maxWidth, open: props.open, classes: {
            paper: 'bg-slate-50',
        } },
        (props.title || !props.hideHeaderCloseButton) && (React.createElement(Header, { title: props.title, onClose: handleClose })),
        props.children));
};
export default DialogComponent;
