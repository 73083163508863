var getRouteFromProductMenuValue = function (value) {
    switch (value) {
        case 0:
            return '.';
        case 1:
            return 'presentation';
        case 2:
            return 'image';
        case 3:
            return 'commentary';
        case 4:
            return 'barcode';
        case 5:
            return 'ecommerce';
        default:
            return false;
    }
};
export default getRouteFromProductMenuValue;
