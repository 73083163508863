import React from 'react';
import AppRegistrationIcon from '@mui/icons-material/AppRegistration';
import { Badge, Fab, Tooltip } from '@mui/material';
import useAdjustInventoryStore from '../../../store/inventoryReport/adjustInventoryStore';
var OpenAdjustInventoryFromInventoryReportButtonComponent = function () {
    var addedProducts = useAdjustInventoryStore(function (state) { return state.products; });
    var setOpen = useAdjustInventoryStore(function (state) { return state.setOpenAdjustInventoryForm; });
    var handleClick = function (event) {
        setOpen(event.currentTarget);
    };
    return addedProducts.length > 0 ? (React.createElement(Tooltip, { title: 'Ajuste de inventario' },
        React.createElement(Fab, { onClick: handleClick, color: 'secondary', "aria-label": 'adjustInventory', className: 'ml-2' },
            React.createElement(Badge, { badgeContent: addedProducts.length, color: 'info', className: 'absolute top-2 right-2' }),
            React.createElement(AppRegistrationIcon, null)))) : (React.createElement("div", null));
};
export default OpenAdjustInventoryFromInventoryReportButtonComponent;
