var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { useReactiveVar } from '@apollo/client';
import { Grid, FormGroup, FormControlLabel, Switch } from '@mui/material';
import TextField from '../../../../../../../presentationals/FormComponents/TextField';
import { setUseContactAddress, inputError } from '../../../../../../../apollo/cache';
import Title from './helpers/title';
import PriceNameSelectField from './helpers/PriceNameSelectField';
import EmployeeIdAutocomplete from './helpers/EmployeeIdAutocomplete';
import TaxRegimeSelectFieldHelpersBodyCreateFormCustomerActionSaleV2Component from './helpers/TaxRegimeSelectField';
import ChooseBillingInformationBodyCreateFormCustomerComponent from './ChooseBillingInformation';
import getDefaultCustomerValues from './helpers/defaultValues';
import useGetSession from '../../../../../../../hooks/useGetSession';
import DateFieldComponent from '../../../../../../../presentationals/FormComponents/DateField';
import SelectBranchFieldComponent from '../../../../../../../presentationals/FormComponents/SelectBranchField';
var BodyCreateFormCustomerActionSaleV2Component = forwardRef(function (props, ref) {
    var session = useGetSession();
    var defaultValues = getDefaultCustomerValues();
    var _a = useState(defaultValues), values = _a[0], setValues = _a[1];
    var useContactAddress = useReactiveVar(setUseContactAddress);
    var errors = useReactiveVar(inputError);
    useEffect(function () {
        if (props.defaultValues)
            setValues(getDefaultCustomerValues(__assign(__assign({}, props.defaultValues), { birthday: props.defaultValues.birthday
                    ? new Date(props.defaultValues.birthday)
                    : undefined, admissionDate: props.defaultValues.admissionDate
                    ? new Date(props.defaultValues.admissionDate)
                    : undefined })));
    }, [props.defaultValues]);
    var handleChange = function (name, value) {
        var _a;
        setValues(__assign(__assign({}, values), (_a = {}, _a[name] = value, _a)));
    };
    var handleInvoiceAddress = function (e) {
        setUseContactAddress(e.target.checked);
    };
    var handleDateChange = function (name, value) {
        setValues(function (values) {
            var _a;
            return (__assign(__assign({}, values), (_a = {}, _a[name] = value, _a)));
        });
    };
    var handleKeyPress = function (e) {
        if (e.key === 'Enter') {
            e.preventDefault();
            props.onPressEnter && props.onPressEnter();
        }
    };
    var getValues = function () { return values; };
    var handleClear = function () {
        setValues(defaultValues);
    };
    useImperativeHandle(ref, function () { return ({
        getValues: getValues,
        onClear: handleClear,
    }); });
    return (React.createElement("div", { className: 'flex flex-1 basis-0 overflow-y-auto px-3 py-2' },
        React.createElement(Grid, { container: true, spacing: 4 },
            React.createElement(Grid, { item: true, xs: 12, md: 3 },
                React.createElement(Title, { label: 'Datos del contacto' }),
                React.createElement(TextField, { label: 'Nombre', name: 'firstName', value: values.firstName, error: errors.firstName, onChange: handleChange, onKeyDown: handleKeyPress }),
                React.createElement(TextField, { label: 'Apellidos', name: 'lastName', value: values.lastName, error: errors.lastName, onChange: handleChange, onKeyDown: handleKeyPress }),
                React.createElement(TextField, { label: 'Tel\u00E9fono', name: 'phone', value: values.phone, error: errors.phone, onChange: handleChange, onKeyDown: handleKeyPress }),
                React.createElement(TextField, { label: 'Correo eletr\u00F3nico', name: 'email', value: values.email, error: errors.email, onChange: handleChange, onKeyDown: handleKeyPress }),
                React.createElement(TextField, { label: 'Correo eletr\u00F3nico alt 1', name: 'altEmail', value: values.altEmail, error: errors.altEmail, onChange: handleChange, onKeyDown: handleKeyPress }),
                React.createElement(TextField, { label: 'Correo eletr\u00F3nico alt 2', name: 'otherEmail', value: values.otherEmail, error: errors.otherEmail, onChange: handleChange, onKeyDown: handleKeyPress })),
            React.createElement(Grid, { item: true, xs: 12, md: 3 },
                React.createElement(Title, { label: 'Domicilio del contacto' }),
                React.createElement(TextField, { label: 'Calle y n\u00FAmero', name: 'addressLine1', value: values.addressLine1, error: errors.addressLine1, onChange: handleChange, onKeyDown: handleKeyPress }),
                React.createElement(TextField, { label: 'Colonia', name: 'addressLine2', value: values.addressLine2, error: errors.addressLine2, onChange: handleChange, onKeyDown: handleKeyPress }),
                React.createElement(TextField, { label: 'Referencias', name: 'reference', value: values.reference, error: errors.reference, onChange: handleChange, onKeyDown: handleKeyPress }),
                React.createElement(TextField, { label: 'C\u00F3digo Postal', name: 'zipCode', value: values.zipCode, error: errors.zipCode, onChange: handleChange, onKeyDown: handleKeyPress }),
                React.createElement(TextField, { label: 'Ciudad', name: 'city', value: values.city, error: errors.city, onChange: handleChange, onKeyDown: handleKeyPress }),
                React.createElement(TextField, { label: 'Estado', name: 'state', value: values.state, error: errors.state, onChange: handleChange, onKeyDown: handleKeyPress })),
            React.createElement(Grid, { item: true, xs: 12, md: 3 },
                React.createElement(Title, { label: 'Configuraci\u00F3n' }),
                session.Company && !['207'].includes(session.Company.id.toString()) && (React.createElement(React.Fragment, null,
                    React.createElement(PriceNameSelectField, { value: values.priceName, error: errors.priceName, handleChange: handleChange }),
                    React.createElement(TextField, { label: 'L\u00EDmite de Cr\u00E9dito', name: 'creditLimit', type: 'number', value: values.creditLimit.toString(), error: errors.creditLimit, onChange: handleChange, onKeyDown: handleKeyPress }),
                    React.createElement(TextField, { label: 'D\u00EDas de Cr\u00E9dito', name: 'creditDays', type: 'number', value: values.creditDays.toString(), error: errors.creditDays, onChange: handleChange, onKeyDown: handleKeyPress }),
                    React.createElement(EmployeeIdAutocomplete, { value: values.employeeId, error: errors.employeeId, handleChange: handleChange }))),
                session.Company && session.Company.id.toString() === '207' && (React.createElement(React.Fragment, null,
                    React.createElement(TextField, { label: 'Escuela procedente', name: 'schoolName', value: values.schoolName || '', error: errors.schoolName, onChange: handleChange, onKeyDown: handleKeyPress }),
                    React.createElement(TextField, { label: 'Grado actual', name: 'schoolGrade', value: values.schoolGrade || '', error: errors.schoolGrade, onChange: handleChange, onKeyDown: handleKeyPress }),
                    React.createElement(TextField, { label: 'Bloque', name: 'schoolBlock', value: values.schoolBlock || '', error: errors.schoolBlock, onChange: handleChange, onKeyDown: handleKeyPress }),
                    React.createElement(DateFieldComponent, { label: 'Fecha de admisi\u00F3n', name: 'admissionDate', value: values.admissionDate, error: errors.admissionDate, onChange: handleDateChange }),
                    React.createElement(DateFieldComponent, { label: 'Fecha de nacimiento', name: 'birthday', value: values.birthday, error: errors.birthday, onChange: handleDateChange }),
                    React.createElement(SelectBranchFieldComponent, { name: 'mainBranch', defaultValue: values.mainBranch || undefined, onChange: handleChange, disallowAllOptions: true })))),
            React.createElement(Grid, { item: true, xs: 12, md: 3 },
                React.createElement("div", { className: 'flex items-start' },
                    React.createElement("div", { className: 'flex flex-1' },
                        React.createElement(Title, { label: 'Datos de facturaci\u00F3n' })),
                    React.createElement(ChooseBillingInformationBodyCreateFormCustomerComponent, null)),
                React.createElement(TextField, { label: 'Nombre comercial', name: 'tradename', value: values.tradename, error: errors.tradename, onChange: handleChange, onKeyDown: handleKeyPress }),
                React.createElement(TextField, { label: 'Raz\u00F3n social', name: 'businessName', value: values.businessName, error: errors.businessName, onChange: handleChange, onKeyDown: handleKeyPress }),
                React.createElement(TextField, { label: 'RFC', name: 'identifier', value: values.identifier, error: errors.identifier, onChange: handleChange, onKeyDown: handleKeyPress }),
                React.createElement(TaxRegimeSelectFieldHelpersBodyCreateFormCustomerActionSaleV2Component, { value: values.taxRegime, onChange: handleChange }),
                ['601', '603'].includes(values.taxRegime) && (React.createElement(TextField, { label: '% Retenci\u00F3n ISR', name: 'isrRetTax', value: values.isrRetTax.toString(), error: errors.isrRetTax, onChange: handleChange })),
                React.createElement(Title, { label: 'Domicilio de facturaci\u00F3n', margin: '2' }),
                React.createElement(FormGroup, null,
                    React.createElement(FormControlLabel, { control: React.createElement(Switch, { checked: useContactAddress, inputProps: { 'aria-label': 'Use contact address' }, onChange: handleInvoiceAddress }), label: 'Usar domicilio del contacto' })),
                !useContactAddress && (React.createElement(React.Fragment, null,
                    React.createElement(TextField, { label: 'Calle y n\u00FAmero', name: 'businessAddressLine1', value: useContactAddress ? values.addressLine1 : values.businessAddressLine1, error: errors.businessAddressLine1, onChange: handleChange, onKeyDown: handleKeyPress }),
                    React.createElement(TextField, { label: 'Colonia', name: 'businessAddressLine2', value: useContactAddress ? values.addressLine2 : values.businessAddressLine2, error: errors.businessAddressLine2, onChange: handleChange, onKeyDown: handleKeyPress }),
                    React.createElement(TextField, { label: 'C\u00F3digo Postal', name: 'businessZipCode', value: useContactAddress ? values.zipCode : values.businessZipCode, error: errors.businessZipCode, onChange: handleChange, onKeyDown: handleKeyPress }),
                    React.createElement(TextField, { label: 'Ciudad', name: 'businessCity', value: useContactAddress ? values.city : values.businessCity, error: errors.businessCity, onChange: handleChange, onKeyDown: handleKeyPress }),
                    React.createElement(TextField, { label: 'Estado', name: 'businessState', value: useContactAddress ? values.state : values.businessState, error: errors.businessState, onChange: handleChange, onKeyDown: handleKeyPress })))))));
});
export default BodyCreateFormCustomerActionSaleV2Component;
