import React, { useEffect, useState } from 'react';
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt';
import TextFieldComponent from '../../../../../../presentationals/FormComponents/TextField';
import IconButtonComponent from '../../../../../../presentationals/IconButton';
var QtyFieldCloseRequisitionComponent = function (props) {
    var _a = useState(null), value = _a[0], setValue = _a[1];
    var _b = useState(''), error = _b[0], setError = _b[1];
    useEffect(function () {
        if (parseFloat(props.defaultValue) >= 0 && value === null)
            setValue(props.defaultValue);
    }, [props.defaultValue]);
    var handleChange = function (name, value) {
        setValue(value);
    };
    var handleClick = function () {
        try {
            if (value === null || parseFloat(value) < 0)
                throw new Error('Proporciona una cantidad mayor a cero.');
            props.onConfirm(parseFloat(value));
        }
        catch (error) {
            setError(error.message);
        }
    };
    if (value === null)
        return React.createElement("div", null);
    return (React.createElement("div", { className: 'flex flex-1 flex-row items-end' },
        React.createElement("div", { className: 'flex flex-1 pr-2' },
            React.createElement(TextFieldComponent, { error: error, label: 'Cant. recibida', value: value, size: 'small', type: 'number', onChange: handleChange })),
        React.createElement(IconButtonComponent, { size: 'medium', className: 'bg-green-800 hover:bg-green-700 text-slate-50 mb-1', icon: React.createElement(ThumbUpAltIcon, null), action: handleClick })));
};
export default QtyFieldCloseRequisitionComponent;
