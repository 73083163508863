import 'date-fns';
import React from 'react';

import localeES from 'date-fns/locale/es';

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';

import Grid from '@mui/material/Grid';
import { TextField } from '@mui/material';

const TextFieldC = (textFieldProps, error, helperText, fullWidth, size) => {
  return (
    <TextField
      {...textFieldProps}
      error={!!error}
      helperText={error || helperText}
      autoComplete='off'
      fullWidth={fullWidth || true}
      size={size || 'small'}
    />
  );
};

const Component = ({
  noFormat,
  icon,
  error,
  size,
  onChange,
  fullWidth,
  helperText,
  value,
  ...props
}) => {
  const handleChange = date => {
    onChange(props.name, date);
  };

  return !noFormat ? (
    <Grid container spacing={0} alignItems='flex-end'>
      <Grid item xs={1}>
        {icon || ''}
      </Grid>
      <Grid item xs={10}>
        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={localeES}>
          <DatePicker
            label={props.label || ''}
            value={new Date(value)}
            onChange={handleChange}
            format='dd MMMM yyyy'
            slots={{
              textField: textFieldProps =>
                TextFieldC(textFieldProps, error, helperText, fullWidth, size),
            }}
            error={!!error}
            helperText={error || helperText}
            {...props}
          />
        </LocalizationProvider>
      </Grid>
    </Grid>
  ) : (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={localeES}>
      <DatePicker
        label={props.label || ''}
        value={new Date(value)}
        onChange={handleChange}
        format='dd MMMM yyyy'
        autoComplete='off'
        slots={{
          textField: textFieldProps =>
            TextFieldC(textFieldProps, error, helperText, fullWidth, size),
        }}
        error={!!error}
        helperText={error || helperText}
        {...props}
      />
    </LocalizationProvider>
  );
};

export default Component;
