import React, { useEffect, useState } from 'react';

import { useReactiveVar } from '@apollo/client';

import { isSaleProductFormOpen, editProductSaleV2 } from '../../../../apollo/cache';
import DialogComponent from '../../../../presentationals/Dialog';

import Action from './Action';
import Search from './Search';
import CreateProductDialogProductFormChooseProductSaleV2Component from './Create';

const ProductDialogProductFormChooseProductSaleV2Component = () => {
  const isSaleProductFormOpenVar = useReactiveVar(isSaleProductFormOpen);
  const editProductSaleV2Var = useReactiveVar(editProductSaleV2);
  const [value, setValue] = useState(0);
  useEffect(() => {
    if (editProductSaleV2Var) {
      editProductSaleV2(false);
      setValue(1);
    }
  }, [editProductSaleV2Var]);
  useEffect(() => {
    if (!isSaleProductFormOpenVar) setValue(0);
  }, [isSaleProductFormOpenVar]);
  const handleClick = (e, value) => {
    setValue(value);
  };
  const handleClose = () => {
    isSaleProductFormOpen(false);
  };
  return (
    <DialogComponent
      title='Configuración del producto'
      onClose={handleClose}
      fullScreen
      open={isSaleProductFormOpenVar}
    >
      <Action value={value} handleClick={handleClick} />
      {value === 0 && <Search />}
      {value === 1 && <CreateProductDialogProductFormChooseProductSaleV2Component noReturn />}
    </DialogComponent>
  );
};

export default ProductDialogProductFormChooseProductSaleV2Component;
