var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { gql } from '@apollo/client';
import jsPDF from 'jspdf';
import { format } from 'date-fns';
import locale from 'date-fns/locale/es';
import client from '../../apollo';
import { phoneFormat, customerLabel } from '../../helpers';
import sortByType from '../../helpers/sortByType';
var customerVar = customerLabel({ textTransform: 'capitalize' });
var GET_SALE_BY_ID = gql(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  query Sale($id: ID!, $branchId: ID) {\n    sale(id: $id, branchId: $branchId) {\n      id\n      category\n      serial\n      folio\n      commentary\n      subtotal\n      discount\n      taxes\n      total\n      totalPaid\n      Calendar {\n        id\n        end\n        address\n      }\n      SaleProducts {\n        id\n        name\n        quantity\n        commentary\n      }\n      Customer {\n        firstName\n        lastName\n        phone\n      }\n      Branch {\n        id\n        addressLine1\n        addressLine2\n        zipCode\n        city\n        state\n        phone\n        name\n      }\n      clientCreatedAt\n    }\n  }\n"], ["\n  query Sale($id: ID!, $branchId: ID) {\n    sale(id: $id, branchId: $branchId) {\n      id\n      category\n      serial\n      folio\n      commentary\n      subtotal\n      discount\n      taxes\n      total\n      totalPaid\n      Calendar {\n        id\n        end\n        address\n      }\n      SaleProducts {\n        id\n        name\n        quantity\n        commentary\n      }\n      Customer {\n        firstName\n        lastName\n        phone\n      }\n      Branch {\n        id\n        addressLine1\n        addressLine2\n        zipCode\n        city\n        state\n        phone\n        name\n      }\n      clientCreatedAt\n    }\n  }\n"])));
var getSale = function (id) { return __awaiter(void 0, void 0, void 0, function () {
    var data;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, client.mutate({
                    mutation: GET_SALE_BY_ID,
                    variables: { id: id },
                })];
            case 1:
                data = (_a.sent()).data;
                return [2 /*return*/, data.sale];
        }
    });
}); };
var pickup80mmTicket = function (saleId) { return __awaiter(void 0, void 0, void 0, function () {
    var data, folio, clientCreatedAt, commentary, Branch, Customer, SaleProducts, addressLineBranch, products, doc, breakline, customerName, splitGeneralCommentary;
    var _a;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0: return [4 /*yield*/, getSale(saleId)];
            case 1:
                data = _b.sent();
                folio = data.folio, clientCreatedAt = data.clientCreatedAt, commentary = data.commentary, Branch = data.Branch, Customer = data.Customer, SaleProducts = data.SaleProducts;
                addressLineBranch = "".concat(Branch.addressLine1, ", ").concat(Branch.addressLine2).trim();
                products = sortByType(__spreadArray([], SaleProducts, true), 'name', 'text', 'asc');
                doc = new jsPDF({
                    unit: 'mm',
                    format: [200, 600],
                });
                doc.setProperties({
                    title: "pickup_".concat(clientCreatedAt),
                });
                breakline = 10;
                if (data.Branch.id.toString() === '196') {
                    doc.addImage('https://storage.capitalike.com/files/16168', 20, 10, 30, 30, 'logo');
                    breakline += 33;
                }
                doc.setFontSize(10);
                doc.setFont('times', 'bold');
                doc.text("".concat(Branch.name), 35, breakline, { align: 'center' });
                doc.setFont('times', 'normal');
                breakline += 3;
                doc.text(doc.splitTextToSize(addressLineBranch, 60), 35, breakline, { align: 'center' });
                breakline = addressLineBranch.length >= 60 ? breakline + 6 : breakline + 3;
                doc.text("".concat(Branch.city, ", ").concat(Branch.state, ", C.P. ").concat(Branch.zipCode), 35, breakline, {
                    align: 'center',
                });
                breakline += 3;
                doc.text("Tel. ".concat(phoneFormat(Branch.phone)), 35, breakline, { align: 'center' });
                breakline += 5;
                doc.setFontSize(12);
                doc.text('ORDEN DE RECOLECCIÓN', 35, breakline, { align: 'center' });
                breakline += 7;
                doc.text("FOLIO: ".concat(folio), 5, breakline, { align: 'left' });
                doc.setFontSize(12);
                breakline += 6;
                customerName = "".concat(Customer.firstName, " ").concat(Customer.lastName || '');
                doc.text("".concat(customerVar, ": ").concat(customerName), 5, breakline, { align: 'left' });
                if (customerName.trim() === 'PUBLICO EN GENERAL') {
                    breakline += 6;
                }
                else {
                    breakline += 4;
                    doc.text("Tel\u00E9fono: ".concat(Customer.phone || ''), 5, breakline, { align: 'left' });
                    breakline += 4;
                    ((_a = data === null || data === void 0 ? void 0 : data.Calendar) === null || _a === void 0 ? void 0 : _a.address) &&
                        doc.text(doc.splitTextToSize("Domicilio: ".concat(data.Calendar.address), 60), 5, breakline, {
                            align: 'left',
                        });
                    breakline =
                        data.Calendar.address && data.Calendar.address.length >= 60 ? breakline + 12 : breakline + 4;
                }
                breakline += 4;
                doc.text('FECHA DE RECOLECCIÓN', 5, breakline, { align: 'left' });
                breakline += 4;
                doc.text("".concat(format(new Date(data.Calendar.end), 'dd/MM/yyyy HH:ss', { locale: locale })), 5, breakline, {
                    align: 'left',
                });
                breakline += 3;
                doc.line(5, breakline, 66, breakline);
                breakline += 3;
                doc.setFontSize(10);
                doc.setFont('times', 'bold');
                doc.text('Productos', 5, breakline, { align: 'left' });
                doc.setFont('times', 'normal');
                breakline += 1;
                doc.line(5, breakline, 66, breakline);
                breakline += 4;
                doc.setFontSize(12);
                products.forEach(function (el) {
                    var splitName = doc.splitTextToSize("(".concat(el.quantity, ") ").concat(el.name ? el.name : ''), 55);
                    doc.text(splitName, 5, breakline, { align: 'left' });
                    breakline = breakline + splitName.length * 3;
                    doc.text('_____', 55, breakline - 2, { align: 'left' });
                    if (el.commentary !== null && el.commentary !== '') {
                        var splitCommentary = doc.splitTextToSize(el.commentary, 195);
                        doc.text(splitCommentary, 5, breakline, { align: 'left' });
                        breakline = breakline + splitCommentary.length * 2;
                    }
                    doc.line(5, breakline, 66, breakline);
                    breakline += 4;
                });
                if (commentary.length > 0) {
                    breakline += 3;
                    doc.setFont('times', 'bold');
                    doc.text('Anotaciones:', 35, breakline, { align: 'center' });
                    doc.setFont('times', 'normal');
                    breakline += 4;
                    splitGeneralCommentary = doc.splitTextToSize(commentary, 60);
                    doc.text(splitGeneralCommentary, 35, breakline, { align: 'center' });
                }
                doc.autoPrint();
                window.open(doc.output('bloburl'), '_blank');
                return [2 /*return*/];
        }
    });
}); };
export default pickup80mmTicket;
var templateObject_1;
