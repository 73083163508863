import React, { useEffect, useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { Link } from 'react-router-dom';
import convertToCurrencyLabel from '../../../helpers/convertToCurrencyLabel';
import { dateTimeFormat } from '../../../helpers';
var TransactionsSummaryIncomeReportComponent = function (props) {
    var _a = useState([]), salePercent = _a[0], setSalePercent = _a[1];
    useEffect(function () {
        if (props.TotalTransactions) {
            var total_1 = props.TotalTransactions.reduce(function (total, el) { return total + el.Total.total; }, 0);
            var SalePercent = props.TotalTransactions.map(function (el) { return (el.Total.total / total_1) * 100; });
            setSalePercent(SalePercent);
        }
    }, [props.TotalTransactions]);
    return (React.createElement("div", { className: 'flex flex-1 mt-4 flex-col overflow-hidden min-h-[200px] basis-0' },
        React.createElement("div", { className: 'flex justify-between items-end' },
            React.createElement("div", { className: 'text-xl' }, "Transacciones"),
            React.createElement(Link, { to: '../transaction', className: ' text-sky-600 no-underline hover:underline' }, "Ver m\u00E1s")),
        React.createElement("div", { className: 'flex flex-1 max-w-full overflow-hidden bg-slate-50 rounded-lg mt-2 border-solid border-slate-200' },
            React.createElement(TableContainer, null,
                React.createElement(Table, { stickyHeader: true, size: 'small' },
                    React.createElement(TableHead, null,
                        React.createElement(TableRow, null,
                            React.createElement(TableCell, { className: 'bg-slate-50 whitespace-nowrap' }, "Forma de pago"),
                            React.createElement(TableCell, { className: 'bg-slate-50' }, "#"),
                            React.createElement(TableCell, { className: 'bg-slate-50' }, "Total"),
                            React.createElement(TableCell, { className: 'bg-slate-50' }, "%"),
                            React.createElement(TableCell, { className: 'bg-slate-50 whitespace-nowrap' }, "\u00DAltima transacci\u00F3n"))),
                    React.createElement(TableBody, null, props.TotalTransactions &&
                        props.TotalTransactions.map(function (el, i) { return (React.createElement(TableRow, { key: i },
                            React.createElement(TableCell, { className: 'whitespace-nowrap' }, el.name),
                            React.createElement(TableCell, null, el.Total.count),
                            React.createElement(TableCell, null,
                                "$",
                                convertToCurrencyLabel(el.Total.total)),
                            React.createElement(TableCell, null, convertToCurrencyLabel(salePercent[i] || 0)),
                            React.createElement(TableCell, { className: 'whitespace-nowrap' }, el.latestDate ? dateTimeFormat(el.latestDate, 'd MMM HH:mm') : '-'))); })))))));
};
export default TransactionsSummaryIncomeReportComponent;
