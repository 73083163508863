var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useEffect, useState } from 'react';
import CategoryIcon from '@mui/icons-material/Category';
import ListItemPermissionEditRoleComponent from '../helpers/ListItem';
import SwitchPermissionFieldComponent from '../helpers/SwitchPermissionField';
var defaultClassificationPermissionValues = {
    createNewClassification: false,
    editClassification: false,
    editClassificationImage: false,
    getClassifications: false,
    deleteClassification: false,
};
var ClassificationPermissionEditRoleComponent = function (props) {
    var _a = useState(defaultClassificationPermissionValues), values = _a[0], setValues = _a[1];
    useEffect(function () {
        setValues(function (values) { return (__assign(__assign({}, values), props.Permissions)); });
    }, [props.Permissions]);
    return (React.createElement(ListItemPermissionEditRoleComponent, { icon: React.createElement(CategoryIcon, null), name: 'Clasificaciones de productos' },
        React.createElement("div", { className: 'mx-4 py-3 grid grid-cols-1 md:grid-cols-3 gap-4' },
            React.createElement("div", { className: 'flex flex-col' },
                React.createElement(SwitchPermissionFieldComponent, __assign({}, props, { name: 'createNewClassification', label: 'Crear nuevas clasificaciones', defaultValue: values.createNewClassification })),
                React.createElement(SwitchPermissionFieldComponent, __assign({}, props, { name: 'editClassification', label: 'Editar informaci\u00F3n general', defaultValue: values.editClassification })),
                React.createElement(SwitchPermissionFieldComponent, __assign({}, props, { name: 'editClassificationImage', label: 'Editar imagen de clasificaci\u00F3n', defaultValue: values.editClassificationImage })),
                React.createElement(SwitchPermissionFieldComponent, __assign({}, props, { name: 'getClassifications', label: 'Consulta de datos', defaultValue: values.getClassifications })),
                React.createElement(SwitchPermissionFieldComponent, __assign({}, props, { name: 'deleteClassification', label: 'Dar de baja clasificaciones', defaultValue: values.deleteClassification }))))));
};
export default ClassificationPermissionEditRoleComponent;
