import React, { useEffect, useState } from 'react';
import LinkMenuDashboardComponent from '../../helpers/Link';
import getSession from '../../../../../../helpers/getSession';
var SurveyLinkBlockOneMenuDashboardComponent = function () {
    var _a = useState(false), show = _a[0], setShow = _a[1];
    useEffect(function () {
        var _a;
        var session = getSession();
        if (!(session === null || session === void 0 ? void 0 : session.Company))
            return;
        setShow(((_a = session === null || session === void 0 ? void 0 : session.Company) === null || _a === void 0 ? void 0 : _a.id.toString()) === '112');
    }, []);
    if (!show)
        return React.createElement("div", null);
    return (React.createElement(LinkMenuDashboardComponent, { url: 'survey', primary: 'Encuestas', secondary: 'Crea, edita y responde encuentas.' }));
};
export default SurveyLinkBlockOneMenuDashboardComponent;
