import { Alert } from '@mui/material';
import React from 'react';
import { isCreateProductPriceOpen } from '../../../../../apollo/cache';
var NoProductRatesAlertProductPromotionComponent = function () {
    var handleOpenCreateProductPrice = function (e) {
        e.preventDefault();
        isCreateProductPriceOpen(true);
    };
    return (React.createElement(Alert, { severity: 'info', className: 'mt-3 w-full sm:w-1/2' },
        "De momento, no cuentas con tarifas personalizadas, esto significa que se tomar\u00E1 el precio est\u00E1ndar de venta registrado en la promoci\u00F3n. Si lo deseas, puedes",
        ' ',
        React.createElement("a", { href: '#', className: 'text-black font-medium', onClick: handleOpenCreateProductPrice }, "registrar una nueva tarifa"),
        "."));
};
export default NoProductRatesAlertProductPromotionComponent;
