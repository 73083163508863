import React from 'react';
import { useNavigate } from 'react-router-dom';
import ReceiptIcon from '@mui/icons-material/Receipt';
import TocIcon from '@mui/icons-material/Toc';
import DeleteIcon from '@mui/icons-material/Delete';
import VerifiedIcon from '@mui/icons-material/Verified';
var getSaleReportActions = function () {
    var navigate = useNavigate();
    return [
        {
            icon: React.createElement(ReceiptIcon, null),
            label: 'Datos generales',
            onClick: function (identifier) {
                navigate(identifier.toString());
            },
        },
        {
            icon: React.createElement(TocIcon, null),
            label: 'Contenido',
            onClick: function (identifier) {
                navigate("".concat(identifier, "/products"));
            },
        },
        {
            icon: React.createElement(VerifiedIcon, null),
            label: 'Factura electrónica',
            onClick: function (identifier) {
                navigate("".concat(identifier, "/invoice"));
            },
        },
        {
            icon: React.createElement(DeleteIcon, null),
            label: 'Cancelar transacción',
            onClick: function (identifier) {
                navigate("".concat(identifier, "/cancel"));
            },
        },
    ];
};
export default getSaleReportActions;
