var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { useReactiveVar } from '@apollo/client';
import { useEffect, useState } from 'react';
import { filter } from '../../../apollo/cache';
import useApolloQuery from '../../../hooks/useApolloQuery';
import getFormattedAddressBranch from '../../branch/helpers/getFormattedAddressBranch';
import GQL_PROVIDER from '../../../apollo/gql/provider';
var useGetProviderData = function (props) {
    var variables = useReactiveVar(filter);
    var _a = useApolloQuery(GQL_PROVIDER.GET_PROVIDERS, props.getByFilter ? { variables: variables } : {}), response = _a.data, loading = _a.loading, refetch = _a.refetch;
    var _b = useState([]), data = _b[0], setData = _b[1];
    useEffect(function () {
        if (props.getByFilter)
            refetch(__assign(__assign({}, variables), { like: variables.like ? variables.like : undefined }));
    }, [variables, props.getByFilter]);
    useEffect(function () {
        if (response) {
            var Rows = response.rows;
            var data_1 = Rows.map(function (el) { return ({
                id: el.id.toString(),
                name: el.name,
                businessName: el.businessName || '-',
                identifier: el.identifier || '-',
                phone: el.phone || '-',
                email: el.email || '-',
                address: getFormattedAddressBranch({
                    addressLine1: el.addressLine1 || '',
                    addressLine2: el.addressLine2,
                    zipCode: el.zipCode,
                    city: el.city,
                    state: el.state,
                }),
                creditDays: "".concat(el.creditDays || 0, " d\u00EDas"),
            }); });
            setData(data_1);
        }
    }, [response]);
    return { data: data, loading: loading };
};
export default useGetProviderData;
