var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import getSession from './getSession';
var setSession = function (params) {
    var currentSession = getSession();
    var newSession = __assign(__assign({}, currentSession), params);
    localStorage.setItem('session', JSON.stringify(newSession));
};
export default setSession;
