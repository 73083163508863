import React, { useState, useEffect } from 'react';
import { useLazyQuery } from '@apollo/client';

import Grid from '@mui/material/Grid';

import LinearForm from '../../../../presentationals/LinearForm';
import Autocomplete from '../../../../presentationals/Autocomplete';
import TextField from '../../../../presentationals/FormComponents/TextField';
import SelectField from '../../../../presentationals/SelectField';

import { currencyFormat } from '../../../../helpers/general';

import GQL_PRODUCT from '../../../product/_gql';
import GQL_PRODUCT_V2 from '../../../product.v2/_gql';

const defaultValues = {
  quantity: 1,
  unitPrice: 0,
  productPresentation: null,
  productId: null,
  productPriceId: null,
  Inventory: '-',
};

const Form = props => {
  const [values, setValues] = useState(defaultValues);
  const [products, setProducts] = useState([]);
  const [productPresentations, setProductPresentations] = useState([]);
  const [productIdBarcode, setProductIdBarcode] = useState('');
  const [errors, setErrors] = useState({});
  const [prices, setPrices] = useState([]);

  const [getProducts, { data: productsData }] = useLazyQuery(
    GQL_PRODUCT.GET_NAME_AND_PRESENTATIONS,
  );
  const [getProductPrices] = useLazyQuery(GQL_PRODUCT_V2.GET_PRODUCT_PRICES_BY_ID, {
    onCompleted: data => {
      const tmp = data.productPrices.rows.map(el => ({
        value: el.id,
        label: currencyFormat(el.price),
      }));
      setPrices(tmp);
      // setValues(values => ({ ...values, productPriceId: tmp[0].value }));
    },
  });

  useEffect(() => {
    getProducts({
      variables: {
        limit: 0,
        includeBaseInPresentations: true,
        branchId: props.branchId ? props.branchId : null,
      },
    });
  }, [props.branchId]);

  useEffect(() => {
    if (productsData && productsData.productsV2) {
      const tmpProducts = productsData.productsV2.rows.map(el => ({
        label: `${String(el.code).padStart(3, '0')} ${el.name.trim()} ${
          el.sku ? ` | SKU ${el.sku.trim()}` : ''
        } ${el.barcode ? ` | C.B. ${el.barcode.trim()}` : ''}`.trim(),
        value: el.id,
        ProductPresentations: el.ProductPresentations,
        Inventory: el.Inventory,
        iva: el.iva ? el.iva : 0,
        ieps: el.ieps ? el.ieps : 0,
      }));
      setProducts(tmpProducts);
    }
  }, [productsData]);

  useEffect(() => {
    setValues(defaultValues);
  }, [props.codeToClear]);

  const handleAutocompleteChangeProduct = async (e, name, value) => {
    const unitPrice = value && value.cost ? value.cost : 0;
    const tmp =
      value && value.ProductPresentations
        ? value.ProductPresentations.map(el => ({ value: el.id, label: el.name, cost: el.cost }))
        : [];
    const inventory = value.Inventory
      ? `${value.Inventory.quantity} ${value.Inventory.PresentationBase.name}`
      : '-';
    setProductPresentations(tmp);
    const productPresentation = tmp ? tmp[0] : null;
    setValues(values => ({ ...values, [name]: value, unitPrice, productPresentation, inventory }));
    await getProductPrices({ variables: { productId: value.value } });
  };

  const handleAutocompleteChange = (e, name, value) => {
    setValues(values => ({ ...values, [name]: value }));
  };

  const handleChange = (name, value) => {
    setValues(values => ({ ...values, [name]: value }));
  };

  const handleProductPriceChange = e => {
    const { name, value } = e.target;
    setValues(values => ({ ...values, [name]: value }));
  };

  const handleAddProduct = () => {
    try {
      if (!values.productId) {
        const e = new Error('Selecciona un producto.');
        e.name = 'productId';
        throw e;
      }
      if (!values.productPresentation) {
        const e = new Error('Selecciona una unidad de medida.');
        e.name = 'productPresentation';
        throw e;
      }

      if (parseFloat(values.quantity) <= 0 || Number.isNaN(Number(values.quantity))) {
        const e = new Error('Proporciona la cantidad de producto.');
        e.name = 'quantity';
        throw e;
      }
      const cost = values.productPriceId
        ? prices.find(tmp => parseInt(tmp.value) === parseInt(values.productPriceId)).label
        : values.productPresentation.cost;
      console.log(cost);
      props.handleAdd({
        product: values.productId,
        productPresentation: values.productPresentation,
        quantity: parseFloat(values.quantity),
        cost: parseFloat(cost),
        // cost: parseFloat(values.productPresentation.cost),
        iva: parseFloat(values.productId.iva),
        ieps: parseFloat(values.productId.ieps),
        total: parseFloat(cost) * parseFloat(values.quantity),
        // total: parseFloat(values.productPresentation.cost) * parseFloat(values.quantity),
        inventory: values.inventory,
        productPriceId: values.productPriceId,
      });
      setValues(defaultValues);
      setProductPresentations([]);
      setProductIdBarcode('');
      setErrors({});
      setPrices([]);
    } catch (e) {
      console.log(e);
      setErrors({ [e.name]: e.message });
    }
  };

  const handleChangeBarcode = (name, value) => {
    setProductIdBarcode(value);
  };

  const getProductByBarcode = productIdBarcode => {
    const response = products.reduce((product, el) => {
      const tmpProduct = el.ProductPresentations.find(
        elCh => elCh.barcode === productIdBarcode || elCh.altBarcode === productIdBarcode,
      );
      if (tmpProduct) console.log(el);
      return tmpProduct
        ? {
            product: el,
            productPresentation: { ...tmpProduct, label: tmpProduct.name },
            cost: parseFloat(tmpProduct.cost),
            iva: parseFloat(el.iva),
            ieps: parseFloat(el.ieps),
            inventory: parseFloat(el.Inventory.quantity),
          }
        : product;
    }, null);

    return response;
  };

  const handleAddByBarcode = e => {
    try {
      if (e.key === 'Enter' && productIdBarcode !== '') {
        let quantity = 1;
        let product = getProductByBarcode(productIdBarcode);
        if (!product) {
          const newProductIdBarcode = productIdBarcode.slice(0, -6);
          const tmpQuantity = productIdBarcode.substring(productIdBarcode.length - 6);
          const unformattedQuantity = tmpQuantity.match(/.{1,3}/g);
          quantity =
            unformattedQuantity.length === 2
              ? parseFloat(`${unformattedQuantity[0]}.${unformattedQuantity[1]}`)
              : -1;
          product = getProductByBarcode(newProductIdBarcode);
        }
        if (!product) throw new Error('No encontramos un producto con este código de barras.');
        if (quantity === -1) throw new Error('No identificamos la cantidad del producto.');
        props.handleAdd({
          ...product,
          total: parseFloat(product.cost) * parseFloat(quantity),
          quantity,
        });
        setValues(defaultValues);
        setProductPresentations([]);
        setProductIdBarcode('');
        setErrors({});
      }
    } catch (e) {
      setErrors({ productIdBarcode: e.message });
      setProductIdBarcode('');
    }
  };

  return (
    <LinearForm handleAdd={handleAddProduct} align='center'>
      <Grid container spacing={1}>
        <Grid item xs={12} md={2}>
          <TextField
            name='productIdBarcode'
            onChange={handleChangeBarcode}
            placeholder='Código de barras'
            value={productIdBarcode}
            error={errors.productIdBarcode}
            onKeyDown={handleAddByBarcode}
          />
        </Grid>
        <Grid item xs={12} md={prices.length > 0 ? 4 : 5}>
          <Autocomplete
            name='productId'
            label='Producto'
            variant='outlined'
            onChange={handleAutocompleteChangeProduct}
            options={products}
            value={values.productId}
            error={errors.productId}
            noFormat
          />
        </Grid>
        <Grid item xs={8} md={prices.length > 0 ? 2 : 3}>
          <Autocomplete
            name='productPresentation'
            label='Presentación'
            variant='outlined'
            onChange={handleAutocompleteChange}
            options={productPresentations}
            value={values.productPresentation}
            error={errors.productPresentation}
            noFormat
          />
        </Grid>
        <Grid item xs={4} md={2}>
          <TextField
            name='quantity'
            placeholder='Cantidad'
            type='number'
            variant='outlined'
            onChange={handleChange}
            value={values.quantity}
            error={errors.quantity}
          />
        </Grid>
        {prices.length > 0 && (
          <Grid item xs={4} md={2}>
            <SelectField
              name='productPriceId'
              label='Precio'
              variant='outlined'
              size='small'
              onChange={handleProductPriceChange}
              options={prices}
              value={values.productPriceId}
              error={errors.productPriceId}
            />
          </Grid>
        )}
      </Grid>
    </LinearForm>
  );
};

export default Form;
