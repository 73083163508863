import React from 'react';
import PurchaseOrderComponent from '../containers/purchaseOrder';
import CreatePurchaseOrderLayoutComponent from './PurchaseOrder/CreatePurchaseOrderLayout';
var getPurchaseOrderChildrenRoutes = function () {
    return [
        { index: true, element: React.createElement(PurchaseOrderComponent, null) },
        {
            path: 'create',
            element: React.createElement(CreatePurchaseOrderLayoutComponent, null),
        },
    ];
};
export default getPurchaseOrderChildrenRoutes;
