import React from 'react';
import CreateButton from './CreateButton';
import PrintButton from './PrintButton';
import SettingButton from './SettingButton';
var FooterFormCalendarComponent = function () {
    return (React.createElement("div", { className: 'py-2 px-4 flex justify-between items-center border-0 border-t border-[#d6d6d6] border-solid' },
        React.createElement("div", { className: 'flex flex-1' },
            React.createElement(SettingButton, null),
            React.createElement(PrintButton, null)),
        React.createElement("div", { className: 'flex' },
            React.createElement(CreateButton, null))));
};
export default FooterFormCalendarComponent;
