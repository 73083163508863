var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import useBranchSession from '../../../../../hooks/useBranchSession';
import LoadingProgress from '../../../../../presentationals/LoadingProgress';
import ErrorDataComponent from '../../../../../presentationals/ErrorData';
import ItemMultiCompanyReportComponent from './Item';
var CompaniesMultiCompanyReportComponent = function () {
    var _a = useBranchSession(), data = _a.data, loading = _a.loading;
    if (loading)
        return React.createElement(LoadingProgress, null);
    if (!data)
        return React.createElement(ErrorDataComponent, null);
    return (React.createElement("div", { className: 'flex flex-col' },
        React.createElement("div", { className: 'text-slate-600 mt-3 mb-1 text-lg' }, "Selecciona las sucursales que deseas visualizar en el informe de operaciones"),
        data.map(function (el) { return (React.createElement(ItemMultiCompanyReportComponent, __assign({ key: el.companyId }, el))); })));
};
export default CompaniesMultiCompanyReportComponent;
