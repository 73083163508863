var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useEffect } from 'react';
import { useApolloClient } from '@apollo/client';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import subscribeSocket from '../../../socket/helpers/subscribe';
import unsubscribeSocket from '../../../socket/helpers/unsubscribe';
import getSession from '../../../helpers/getSession';
//bg-[#002590]
//text-white
var NotificationCalendarQueueManagerComponent = function (props) {
    var client = useApolloClient();
    var settings = {
        dots: false,
        infinite: true,
        speed: 1000,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 8000,
    };
    useEffect(function () {
        var f = function (data) {
            var _a;
            var session = getSession();
            var calendarRef = client.cache.identify({
                __typename: 'CalendarQueueManager',
                companyId: (_a = session === null || session === void 0 ? void 0 : session.Company) === null || _a === void 0 ? void 0 : _a.id,
            });
            client.cache.modify({
                id: calendarRef,
                fields: {
                    notifications: function () { return data.notifications; },
                },
            });
        };
        subscribeSocket('UPDATE_QUEUE_NOTIFICATIONS', f);
        return function () {
            unsubscribeSocket('UPDATE_QUEUE_NOTIFICATIONS', f);
        };
    }, []);
    if (props.Notifications.length === 0)
        return React.createElement("div", null);
    if (props.Notifications.length === 1)
        return (React.createElement("div", { style: {
                color: props.Color.footerTextColor || '#000',
                backgroundColor: props.Color.footerBackgroundColor || '#fff',
            }, className: 'flex leading-tight px-4 py-1 text-5xl justify-center' }, props.Notifications[0]));
    return (React.createElement("div", { className: 'leading-tight py-1 text-center overflow-hidden', style: {
            backgroundColor: props.Color.footerBackgroundColor,
        } },
        React.createElement(Slider, __assign({}, settings), props.Notifications.map(function (content, index) { return (React.createElement("div", { key: index, className: 'slide' },
            React.createElement("div", { style: {
                    color: props.Color.footerTextColor,
                }, className: 'text-5xl' }, content))); }))));
};
export default NotificationCalendarQueueManagerComponent;
