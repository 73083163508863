import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import FooterDialogComponent from '../../../../presentationals/Dialog/Footer';
import EditProductIngredientButtonComponent from './EditButton';
import BodyProductIngredientComponent from '../../ProductPresentation/Component/Create/Body';
import useApolloQuery from '../../../../hooks/useApolloQuery';
import GQL_PRODUCT from '../../../../apollo/gql/product';
import LoadingProgress from '../../../../presentationals/LoadingProgress';
import { ingredientProductForm } from '../../../../apollo/cache';
import DeleteButtonGeneralProductIngredientComponent from './DeleteButton';
import getDefaultIngredientProductForm from '../../ProductPresentation/Component/Create/Body/helpers/getDefaultIngredientProductForm';
import ErrorDataComponent from '../../../../presentationals/ErrorData';
var GeneralProductIngredientComponent = function () {
    var params = useParams();
    var _a = useApolloQuery(GQL_PRODUCT.GET_PRODUCT_INGREDIENT_BY_ID, {
        variables: { id: params.componentId },
    }), loading = _a.loading, data = _a.data;
    useEffect(function () {
        if (data)
            ingredientProductForm({
                ingredientId: data.Ingredient.id,
                type: data.ProductVariation ? data.ProductVariation.id : data.type,
                portion: data.portion,
                portionQty: data.quantity,
                portionPrice: data.price,
                didiPrice: data.didiPrice,
                uberPrice: data.uberPrice,
                rappiPrice: data.rappiPrice,
                freePortionQty: data.freeLimit,
                extra: data.extra,
                toGo: data.toGo,
            });
    }, [data]);
    useEffect(function () {
        return function () {
            var defaultIngredientProductValues = getDefaultIngredientProductForm();
            ingredientProductForm(defaultIngredientProductValues);
        };
    }, []);
    if (loading)
        return React.createElement(LoadingProgress, null);
    if (!data)
        return React.createElement(ErrorDataComponent, null);
    return (React.createElement("div", { className: 'flex flex-1 flex-col overflow-hidden' },
        React.createElement(BodyProductIngredientComponent, null),
        React.createElement(FooterDialogComponent, null,
            React.createElement(DeleteButtonGeneralProductIngredientComponent, null),
            React.createElement(EditProductIngredientButtonComponent, null))));
};
export default GeneralProductIngredientComponent;
