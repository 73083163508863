var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { create } from 'zustand';
var defaultValues = {
    anchorEl: null,
    products: [],
    destinationBranchId: null,
};
var useTransferProductsToBranchStore = create()(function (set) { return (__assign(__assign({}, defaultValues), { setOpen: function (anchorEl) { return set({ anchorEl: anchorEl }); }, addProduct: function (data) {
        return set(function (state) {
            var index = state.products.findIndex(function (el) { return el.presentationId === data.presentationId; });
            return __assign(__assign({}, state), { products: data.qty > 0
                    ? index >= 0
                        ? state.products.map(function (el, i) {
                            return i === index ? __assign(__assign({}, el), { qty: el.qty + data.qty }) : el;
                        })
                        : __spreadArray(__spreadArray([], state.products, true), [data], false)
                    : state.products });
        });
    }, deleteProduct: function (presentationId) {
        return set(function (state) { return (__assign(__assign({}, state), { products: state.products.filter(function (el) { return el.presentationId !== presentationId; }) })); });
    }, setDestinationBranch: function (branchId) {
        return set(function (state) { return (__assign(__assign({}, state), { destinationBranchId: branchId })); });
    } })); });
export default useTransferProductsToBranchStore;
