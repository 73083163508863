import React, { useEffect, useState } from 'react';

import { useReactiveVar } from '@apollo/client';
import { List } from '@mui/material';

import { searchUnitKeyField } from '../../../../../../../../../../apollo/cache';
import Item from './Item';

const TableSearchDialogUnitKeyFieldHelpersBodyCreateProductDialogProductFormChooseProductSaleV2Component = () => {
    const searchUnitKeyFieldVar = useReactiveVar(searchUnitKeyField);

    const [data, setData] = useState([]);
    const [result, setResult] = useState([]);

    useEffect(() => {
      const f = async () => {
        try {
          const response = await fetch(
            'https://raw.githubusercontent.com/bambucode/catalogos_sat_JSON/master/c_ClaveUnidad.json',
          );
          const jsonResponse = await response.json();
          setData(jsonResponse.map(el => ({ label: el.nombre, id: el.id })));
        } catch (e) {
          console.log('Error', e);
        }
      };
      f();
    }, []);

    useEffect(() => {
      if (searchUnitKeyFieldVar.length > 0 && data.length > 0) {
        const searchString = searchUnitKeyFieldVar.toLowerCase();
        const currentUnitKey = data.filter(
          el =>
            el.label.toLowerCase().includes(searchString) ||
            el.id.toLowerCase().includes(searchString),
        );
        setResult(currentUnitKey);
      }
    }, [searchUnitKeyFieldVar, data]);

    return (
      <div className='flex flex-1 flex-col overflow-y-auto'>
        <List component='nav' aria-labelledby='nested-customer-list'>
          {result.map((el, i) => (
            <Item key={el.id} {...el} background={i % 2 > 0 ? 'bg-inherit' : 'bg-gray-100'} />
          ))}
        </List>
      </div>
    );
  };

export default TableSearchDialogUnitKeyFieldHelpersBodyCreateProductDialogProductFormChooseProductSaleV2Component;
