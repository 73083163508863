import React, { forwardRef, useImperativeHandle, useState } from 'react';
import DialogComponent from '../../../presentationals/Dialog';
import BodyEditRequisitionProductsDialogComponent from './Body';
var EditRequisitionProductsDialogComponent = forwardRef(function (props, ref) {
    var _a = useState(null), open = _a[0], setOpen = _a[1];
    var handleClose = function () {
        setOpen(null);
    };
    var handleOpen = function (id) {
        setOpen(id);
    };
    useImperativeHandle(ref, function () { return ({
        open: handleOpen,
    }); });
    return (React.createElement(DialogComponent, { open: !!open, onClose: handleClose, fullScreen: true },
        React.createElement(BodyEditRequisitionProductsDialogComponent, { requisitionId: open })));
});
export default EditRequisitionProductsDialogComponent;
