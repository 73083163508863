import { MenuItem } from '@mui/material';
import React from 'react';
var ItemPaymentFormsSalesGlobalInvoiceFieldComponent = function (props) {
    var handleClick = function () {
        props.onClick(props.name, props.value);
    };
    return (React.createElement(MenuItem, { dense: true, classes: {
            dense: 'break-words whitespace-normal',
        }, onClick: handleClick }, props.name));
};
export default ItemPaymentFormsSalesGlobalInvoiceFieldComponent;
