import React from 'react';

import CloudDownloadIcon from '@mui/icons-material/CloudDownloadOutlined';
import KeyboardReturnIcon from '@mui/icons-material/KeyboardArrowLeftOutlined';
import PrintIcon from '@mui/icons-material/PrintOutlined';
import { useReactiveVar } from '@apollo/client';

import Menu from '@mui/material/Menu';
import Divider from '@mui/material/Divider';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

import {
  exportPdf,
  exportXls,
  exportCustomXls,
  exportCustomPdf,
} from '../../../../../apollo/cache';

const HeaderMenu = props => {
  const exportCustomXlsVar = useReactiveVar(exportCustomXls);
  const handleRedirect = () => {
    console.log('goback');
  };
  const downloadPDF = () => {
    props.customPdf ? exportCustomPdf(true) : exportPdf({ export: true });
  };
  const downloadXLS = () => {
    props.customXls ? exportCustomXls(true) : exportXls({ export: true });
  };
  return (
    <Menu
      id='header-menu'
      anchorEl={props.anchorEl}
      keepMounted
      open={Boolean(props.anchorEl)}
      onClose={props.onClose}
    >
      <MenuItem onClick={downloadPDF}>
        <ListItemIcon>
          <PrintIcon />
        </ListItemIcon>
        <ListItemText primary='Exportar | PDF' />
      </MenuItem>
      <MenuItem disabled={exportCustomXlsVar} onClick={downloadXLS}>
        <ListItemIcon>
          <CloudDownloadIcon />
        </ListItemIcon>
        <ListItemText primary={exportCustomXlsVar ? 'Descargando XLS...' : 'Exportar | XLS'} />
      </MenuItem>
      {props.additionalAction && <Divider />}
      {props.additionalAction && Array.isArray(props.additionalAction)
        ? props.additionalAction.map((el, i) => (
            <MenuItem key={i} onClick={el.handle}>
              <ListItemIcon>{el.icon}</ListItemIcon>
              <ListItemText primary={el.label} />
            </MenuItem>
          ))
        : props.additionalAction && (
            <MenuItem onClick={props.additionalAction.handle}>
              <ListItemIcon>{props.additionalAction.icon}</ListItemIcon>
              <ListItemText primary={props.additionalAction.label} />
            </MenuItem>
          )}
      {props.return && <Divider />}
      {props.return && (
        <MenuItem onClick={handleRedirect}>
          <ListItemIcon>
            <KeyboardReturnIcon />
          </ListItemIcon>
          <ListItemText primary='Regresar' />
        </MenuItem>
      )}
    </Menu>
  );
};

export default HeaderMenu;
