import React, { useState, useCallback, useRef, useEffect } from 'react';

import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';

import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';

function getFileNameWithExt(event) {
  if (!event || !event.target || !event.target.files || event.target.files.length === 0) return;

  const { name } = event.target.files[0];
  const lastDot = name.lastIndexOf('.');

  const fileName = name.substring(0, lastDot);
  const ext = name.substring(lastDot + 1);

  return [fileName, ext];
}

const ImageField = ({ resetImage, ...props }) => {
  const [fileData, setFileData] = useState(['file', 'jpeg']);
  const [upImg, setUpImg] = useState();
  const imgRef = useRef(null);
  const [crop, setCrop] = useState({
    unit: '%',
    width: props.width || 30,
    aspect: props.aspect || 1,
  });
  // const [previewUrl, setPreviewUrl] = useState();

  useEffect(() => {
    if (props.isUpload) {
      setUpImg(null);
      resetImage();
    }
  }, [props.isUpload, resetImage]);

  const onSelectFile = e => {
    if (props.disabled) return;
    if (e.target.files && e.target.files.length > 0) {
      const tmpFileData = getFileNameWithExt(e);
      setFileData(tmpFileData);
      const reader = new FileReader();
      reader.addEventListener('load', () => setUpImg(reader.result));
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  const onLoad = useCallback(img => {
    imgRef.current = img;
  }, []);

  const createCropPreview = async (image, crop, fileName) => {
    const canvas = document.createElement('canvas');
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext('2d');

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height,
    );

    return new Promise((resolve, reject) => {
      canvas.toBlob(
        blob => {
          const tmp = blob;
          if (!tmp) {
            reject(new Error('Canvas esta vacío.'));
            return;
          }
          tmp.name = fileName;
          // window.URL.revokeObjectURL(previewUrl);
          // setPreviewUrl(window.URL.createObjectURL(blob));
          props.croppedImage(window.URL.createObjectURL(tmp), tmp);
        },
        `image/${fileData[1]}`,
        1,
      );
    });
  };

  const makeClientCrop = async crop => {
    if (imgRef.current && crop.width && crop.height)
      await createCropPreview(imgRef.current, crop, fileData.join('.'));
  };

  const onChange = c => setCrop(c);

  const onReturn = e => {
    e.preventDefault();
    setUpImg(null);
  };

  return (
    <Grid container spacing={0} alignContent='center' className={!props.noHeight ? 'h-60' : ''}>
      <Grid item xs={12} align='center'>
        {!upImg ? (
          <Grid container className='overflow-auto min-h-full' alignItems='center'>
            <Grid item xs={12}>
              <label htmlFor='contained-button-file'>
                <input
                  accept='image/*'
                  className='hidden'
                  id='contained-button-file'
                  onChange={onSelectFile}
                  type='file'
                  disabled={props.disabled || false}
                />
                {!props.hideButton && (
                  <Button
                    variant='outlined'
                    component='span'
                    disableElevation
                    disabled={props.disabled || false}
                    className='border border-solid border-[#808080]'
                  >
                    Selecciona una imagen de tu ordenador
                  </Button>
                )}
              </label>
            </Grid>
            {props.children && (
              <Grid item xs={12} className='m-6'>
                {props.children}
              </Grid>
            )}
          </Grid>
        ) : (
          <div>
            <p>{`Para recortar la imagen, arrastra el área seleccionada y haz clic en "${
              props.buttonLabel || 'Guardar'
            }"`}</p>
            {props.noHeight ? (
              <ReactCrop
                src={upImg}
                onImageLoaded={onLoad}
                crop={crop}
                onChange={onChange}
                onComplete={makeClientCrop}
                keepSelection={props.keepSelection || true}
                locked
              />
            ) : (
              <ReactCrop
                src={upImg}
                onImageLoaded={onLoad}
                crop={crop}
                onChange={onChange}
                onComplete={makeClientCrop}
                keepSelection={props.keepSelection || true}
                minHeight={props.minHeight || 100}
                imageStyle={{ height: props.height || 250 }}
              />
            )}

            <br />
            <Link href='#' onClick={onReturn} color='primary'>
              Quiero seleccionar otra imagen.
            </Link>
          </div>
        )}
      </Grid>
    </Grid>
  );
};

export default ImageField;
