var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React, { useEffect, useState } from 'react';
import { useReactiveVar } from '@apollo/client';
import SalesSummaryIncomeReportComponent from './SalesSummary';
import FilterButtonHeaderModuleV3Component from '../../presentationals/Module.v3/Header/SearchInput/FilterButton';
import TransactionsSummaryIncomeReportComponent from './TransactionsSummary';
import SaleProductSummaryIncomeReportComponent from './SaleProductSummary';
import CopyrightComponent from '../../presentationals/Module.v2/Copyright';
import SaleByDayChartIncomeReportComponent from './SaleByDayChart';
import SaleByHourChartIncomeReportComponent from './SaleByHourChart';
import SaleByBranchChartIncomeReportComponent from './SaleByBranchChart';
import GQL_SALE from '../../apollo/gql/sale';
import FilterIncomeReportDialog from './Filter';
import LoadingProgress from '../../presentationals/LoadingProgress';
import { filter } from '../../apollo/cache';
import useApolloLazyQuery from '../../hooks/useApolloLazyQuery';
import SaleByClassificationSummaryComponent from './SaleByClassificationSummary';
import SaleByChannelComponent from './SaleByChannel';
import getSession from '../../helpers/getSession';
import GoBackButtonComponent from '../../presentationals/GoBackButton';
import DetailedSalesByDayIncomeReportComponent from './DetailedSalesByDay';
var IncomeReportComponent = function () {
    var filterVar = useReactiveVar(filter);
    var _a = useState(false), ownerPermissions = _a[0], setOwnerPermissions = _a[1];
    var _b = useApolloLazyQuery(GQL_SALE.GET_GENERAL_SALE_REPORT, {
        fetchPolicy: 'network-only',
    }), getSales = _b[0], _c = _b[1], data = _c.data, loading = _c.loading;
    useEffect(function () {
        var session = getSession();
        if (session === null || session === void 0 ? void 0 : session.Company)
            setOwnerPermissions(session.Company.Role.name === 'PROPIETARIO');
    }, []);
    useEffect(function () {
        if (filterVar.start && filterVar.end)
            getSales({
                variables: {
                    start: filterVar.start,
                    end: filterVar.end,
                    branchId: filterVar.branchId,
                    commission: filterVar.commission,
                    shipping: filterVar.shipping,
                },
            });
    }, [filterVar]);
    if (loading)
        return React.createElement(LoadingProgress, null);
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: 'flex flex-1 flex-col overflow-auto' },
            React.createElement("div", { className: 'flex flex-1 flex-col px-4 pt-3' },
                React.createElement("div", { className: 'flex justify-between' },
                    React.createElement("div", { className: 'text-2xl mr-3 items-center' },
                        React.createElement(GoBackButtonComponent, null),
                        " Informes de ventas"),
                    React.createElement(FilterButtonHeaderModuleV3Component, { isLoading: loading, size: 'large' })),
                React.createElement("div", { className: 'flex flex-1 flex-col sm:flex-row items-start' },
                    React.createElement("div", { className: 'flex flex-col max-w-full h-full overflow-hidden' },
                        React.createElement(SalesSummaryIncomeReportComponent, { TotalCashFlows: data === null || data === void 0 ? void 0 : data.TotalCashFlows, TotalSales: data === null || data === void 0 ? void 0 : data.TotalSales }),
                        React.createElement(TransactionsSummaryIncomeReportComponent, { TotalTransactions: data === null || data === void 0 ? void 0 : data.TotalTransactions }),
                        React.createElement(SaleProductSummaryIncomeReportComponent, { TotalSaleProducts: data === null || data === void 0 ? void 0 : data.TotalSaleProducts }),
                        React.createElement(SaleByClassificationSummaryComponent, { TotalClassifications: data === null || data === void 0 ? void 0 : data.TotalClassifications })),
                    React.createElement("div", { className: 'flex flex-1 flex-col w-full h-full pl-0 sm:pl-4' },
                        React.createElement(SaleByChannelComponent, { TotalTypeSales: data === null || data === void 0 ? void 0 : data.TotalTypeSales }),
                        React.createElement(SaleByDayChartIncomeReportComponent, { Sales: data === null || data === void 0 ? void 0 : data.Sales }),
                        React.createElement(SaleByHourChartIncomeReportComponent, { Sales: data === null || data === void 0 ? void 0 : data.Sales }),
                        ownerPermissions && (data === null || data === void 0 ? void 0 : data.TotalBranchSales) && data.TotalBranchSales.length > 1 && (React.createElement(SaleByBranchChartIncomeReportComponent, { TotalBranchSales: data.TotalBranchSales })))),
                React.createElement(DetailedSalesByDayIncomeReportComponent, { multiBranchPermissions: ownerPermissions, Sales: data ? __spreadArray(__spreadArray([], data.Sales, true), data.BranchSales, true) : [] })),
            React.createElement("div", { className: 'flex p-4 justify-center' },
                React.createElement(CopyrightComponent, null))),
        React.createElement(FilterIncomeReportDialog, null)));
};
export default IncomeReportComponent;
