import React from 'react';
import DialogComponent from '../../../presentationals/Dialog';
import FooterDialogComponent from '../../../presentationals/Dialog/Footer';
import BodyTableSettingsPurchaseOrderComponent from './Body';
import ApplyChangesButtonTableSettingsProductComponent from '../../product/TableSettings/ApplyChangesButton';
import useTableSettingsDialog from '../../../store/tableSettings/tableSettingsDialog';
var TableSettingsPurchaseOrderComponent = function () {
    var open = useTableSettingsDialog(function (state) { return state.open; });
    var setOpen = useTableSettingsDialog(function (state) { return state.setOpen; });
    var handleClose = function () {
        setOpen(false);
    };
    return (React.createElement(DialogComponent, { title: 'Preferencias', fullScreen: true, open: open, onClose: handleClose },
        React.createElement(BodyTableSettingsPurchaseOrderComponent, null),
        React.createElement(FooterDialogComponent, null,
            React.createElement("div", null),
            React.createElement(ApplyChangesButtonTableSettingsProductComponent, null))));
};
export default TableSettingsPurchaseOrderComponent;
