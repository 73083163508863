import React, { forwardRef } from 'react';
var ItemTableCustomerGlobalInvoiceComponent = forwardRef(function (props, ref) {
    var handleClick = function () {
        props.onClick(props.name, props.id);
    };
    var handleKeyDown = function (e) {
        var keyPressed = e.key;
        if (['up', 'down'].includes(keyPressed) && props.onKeyDown)
            props.onKeyDown(keyPressed);
    };
    return (React.createElement("div", { ref: ref, role: 'button', tabIndex: -1, onClick: handleClick, onKeyDown: props.onKeyDown && handleKeyDown, className: "".concat(props.selected ? 'bg-slate-200' : props.index % 2 === 0 ? 'bg-slate-100' : 'bg-white', " px-3 py-2 cursor-pointer hover:bg-slate-200") },
        React.createElement("div", null, props.name)));
});
export default ItemTableCustomerGlobalInvoiceComponent;
