import React, { useEffect, useState } from 'react';

import { useLazyQuery, useMutation } from '@apollo/client';
import Typography from '@mui/material/Typography';

import FormDialog from '../../presentationals/FormDialog';
import TextField from '../../presentationals/FormComponents/TextField';
import Snackbar from '../../presentationals/Snackbar';

import { parseError } from '../../helpers';

import GQL from './_gql';
import useSnackbarDialog from '../../store/snackbar/snackbarDialog';

const defaultValues = {
  phone: '',
};

const Component = props => {
  const { setSnackbar } = useSnackbarDialog();
  const [values, setValues] = useState(defaultValues);
  const [sendQuoteWhatsapp, { loading }] = useMutation(GQL.SEND_QUOTE_WHATSAPP);
  const [getQuote, { data: dataQuote }] = useLazyQuery(GQL.GET_BY_ID);

  useEffect(() => {
    if (props.id) getQuote({ variables: { id: props.id } });
  }, [props.id]);

  useEffect(() => {
    let phone = '';
    if (dataQuote)
      if (dataQuote.quote.Customer)
        if (dataQuote.quote.Customer.phone && dataQuote.quote.Customer.phone.trim() !== '')
          phone = dataQuote.quote.Customer.phone.trim();
        else if (dataQuote.quote.Customer.CustomerBillingInformation)
          if (
            dataQuote.quote.Customer.CustomerBillingInformation.phone &&
            dataQuote.quote.Customer.CustomerBillingInformation.phone.trim() !== ''
          )
            phone = dataQuote.quote.Customer.CustomerBillingInformation.phone.trim();

    setValues(values => ({ ...values, phone }));
  }, [dataQuote]);

  const handleClose = () => {
    props.handleClose();
    setValues(defaultValues);
  };

  const handleChange = (name, value) => {
    setValues(values => ({ ...values, [name]: value }));
  };

  const handleAction = async () => {
    try {
      await sendQuoteWhatsapp({ variables: { id: props.id, phone: values.phone.trim() } });
      handleClose();
      setValues(defaultValues);
    } catch (e) {
      console.log(e);
      if (e.input) {
        setErrors({ [e.name]: e.message });
        return;
      }
      const parseErrors = parseError(e);
      parseErrors.forEach(el => {
        if (el.name === 'BAD_USER_INPUT') setErrors(el.message);
        else
          setSnackbar({
            variables: {
              isOpen: true,
              time: 3000,
              label: el.message,
              severity: 'error',
            },
          });
      });
    }
  };

  return (
    <>
      <FormDialog
        title=''
        isOpen={props.isOpen}
        isLoading={loading}
        handleClose={handleClose}
        handleAction={handleAction}
        actionLabel='Enviar'
      >
        <div>
          <Typography variant='h5' gutterBottom>
            Reenviar PDF
          </Typography>
          <Typography variant='subtitle1'>
            Asegúrate de que el número proporcionado sea el correcto para hacer llegar al archivo
            satisfactoriamente.
          </Typography>
          <TextField
            label='Número de Teléfono'
            name='phone'
            value={values.phone}
            onChange={handleChange}
            noFormat
            variant='outlined'
          />
        </div>
      </FormDialog>
      <Snackbar horizontal='center' />
    </>
  );
};

export default Component;
