var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMutation, useReactiveVar } from '@apollo/client';
import clearSession from '../../../helpers/clearSession';
import getInputErrorFormat from '../../sale.v2/Action/helpers/getInputErrorFormat';
import { inputError, isManageAccountOpen } from '../../../apollo/cache';
import setInputErrorFormat from '../../sale.v2/Action/helpers/setInputErrorFormat';
import TextFieldComponent from '../../../presentationals/FormComponents/TextField';
import DialogComponent from '../../../presentationals/Dialog';
import FooterDialogComponent from '../../../presentationals/Dialog/Footer';
import ButtonFooterDialogComponent from '../../../presentationals/Dialog/Footer/Button';
import GQL_SESSION from '../../../apollo/gql/session';
import SubtitleDialogComponent from '../../../presentationals/Dialog/helpers/Subtitle';
import UnderConstructionAlertComponent from '../../../presentationals/UnderConstructionAlert';
var defaultValues = {
    newPassword: '',
    confirmPassword: '',
};
var ManageAccountDialogComponent = function () {
    var navigate = useNavigate();
    var isOpen = useReactiveVar(isManageAccountOpen);
    var errors = useReactiveVar(inputError);
    var _a = useState(defaultValues), values = _a[0], setValues = _a[1];
    var _b = useMutation(GQL_SESSION.UPDATE_WEB_PASSWORD), updatePassword = _b[0], loading = _b[1].loading;
    var handleClick = function () { return __awaiter(void 0, void 0, void 0, function () {
        var e_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 3, , 4]);
                    if (values.newPassword.length < 6)
                        throw new Error(setInputErrorFormat('newPassword', 'La contraseña debe de contar con al menos 6 caracteres'));
                    if (values.newPassword !== values.confirmPassword)
                        throw new Error(setInputErrorFormat('confirmPassword', 'Las contraseñas no coinciden'));
                    return [4 /*yield*/, updatePassword({ variables: { password: values.newPassword } })];
                case 1:
                    _a.sent();
                    setValues(defaultValues);
                    inputError({});
                    return [4 /*yield*/, clearSession()];
                case 2:
                    _a.sent();
                    navigate('/');
                    return [3 /*break*/, 4];
                case 3:
                    e_1 = _a.sent();
                    getInputErrorFormat(e_1);
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    }); };
    var handleClose = function () {
        setValues(defaultValues);
        inputError({});
        isManageAccountOpen(false);
    };
    var handleChange = function (name, value) {
        setValues(function (values) {
            var _a;
            return (__assign(__assign({}, values), (_a = {}, _a[name] = value, _a)));
        });
    };
    return (React.createElement(DialogComponent, { title: 'Gestionar mi cuenta', fullScreen: true, open: isOpen, onClose: handleClose },
        React.createElement(UnderConstructionAlertComponent, null),
        React.createElement("div", { className: 'flex flex-1 flex-col px-4 py-3' },
            React.createElement("div", { className: 'w-full md:w-1/4' },
                React.createElement(SubtitleDialogComponent, { label: 'Cambio de contrase\u00F1a' }),
                React.createElement(TextFieldComponent, { name: 'newPassword', label: 'Nueva Contrase\u00F1a', onChange: handleChange, value: values.newPassword, error: errors.newPassword, type: 'password' }),
                React.createElement(TextFieldComponent, { name: 'confirmPassword', label: 'Confirma tu Contrase\u00F1a', onChange: handleChange, value: values.confirmPassword, error: errors.confirmPassword, type: 'password' }))),
        React.createElement(FooterDialogComponent, null,
            React.createElement("div", null),
            React.createElement(ButtonFooterDialogComponent, { disabled: loading, className: 'ml-2', onClick: handleClick }, "Cambiar contrase\u00F1a"))));
};
export default ManageAccountDialogComponent;
