import React from 'react';
import { TableCell } from '@mui/material';
import IsCheckedItemPresentationTableEditProductComponent from './IsCheckedIcon';
import { currencyFormat } from '../../../../../../helpers';
import TableRowItemPresentationTableEditProductComponent from './TableRow';
import TableCellQuantityItemPresentationTableEditProductComponent from './TableCellQuantity';
import TableCellLimitItemPresentationTableEditProductComponent from './TableCellLimit';
var ItemPresentationTableEditProductComponent = function (props) {
    return (React.createElement(TableRowItemPresentationTableEditProductComponent, { id: props.Presentation.id, background: props.index % 2 > 0 ? 'bg-inherit' : 'bg-gray-100' },
        React.createElement(TableCell, null, props.Presentation.name),
        React.createElement(TableCellQuantityItemPresentationTableEditProductComponent, { quantity: props.Presentation.quantity, presentationBase: props.presentationBase }),
        React.createElement(TableCellLimitItemPresentationTableEditProductComponent, { limit: props.Presentation.limit }),
        React.createElement(TableCell, null, currencyFormat(props.Presentation.price)),
        React.createElement(TableCell, { align: 'center', padding: 'none' },
            React.createElement(IsCheckedItemPresentationTableEditProductComponent, { isChecked: props.Presentation.forSale })),
        React.createElement(TableCell, { align: 'center', padding: 'none' },
            React.createElement(IsCheckedItemPresentationTableEditProductComponent, { isChecked: props.Presentation.forPursache }))));
};
export default ItemPresentationTableEditProductComponent;
