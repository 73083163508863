const setSettings = (name, value) => {
  const currentSettings = localStorage.getItem('settings')
    ? JSON.parse(localStorage.getItem('settings'))
    : {};

  const newSettings = {
    ...currentSettings,
    [name]: value,
  };

  localStorage.setItem('settings', JSON.stringify(newSettings));

  return true;
};

export default setSettings;
