var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { getHours, parseISO } from 'date-fns';
import getIndexByHour from './getIndexByHour';
import getDefaultHoursChartData from './getDefaultHours';
import getHoursByWorkingHours from './getHoursByWorkingHours';
var getFormattedSaleByHourData = function (data, chartByTotal, hour) {
    var defaultData = hour.start && hour.end
        ? getHoursByWorkingHours(hour.start, hour.end)
        : getDefaultHoursChartData();
    var newArray = data.reduce(function (array, el) {
        var hour = getHours(parseISO(el.clientCreatedAt));
        var index = getIndexByHour(hour);
        return array.map(function (elCh) {
            return elCh.index === index
                ? __assign(__assign({}, elCh), { total: elCh.total + el.total, qty: elCh.qty + 1 }) : elCh;
        });
    }, defaultData);
    return chartByTotal
        ? newArray
        : newArray.map(function (el) { return (__assign(__assign({}, el), { total: el.qty > 0 ? el.total / el.qty : 0 })); });
};
export default getFormattedSaleByHourData;
