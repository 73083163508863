import getDefaultCustomerValues from '../../containers/sale.v2/Action/Customer/Form/Create/Body/helpers/defaultValues';
import getSettings from '../../helpers/getSettings';
import { branchSaleV2, cartItems, commentarySaleV2, currencySaleV2, customerSaleV2, employeeSaleV2, formatSaleV2, isPettyCashOpen, pinSaleV2, searchV3, } from '../cache';
var resetMakeVars = function () {
    var settings = getSettings();
    var defaultCustomerFormValues = getDefaultCustomerValues();
    customerSaleV2(defaultCustomerFormValues);
    formatSaleV2(settings.defaultFormatSaleV2);
    employeeSaleV2('');
    pinSaleV2('');
    commentarySaleV2('');
    branchSaleV2({ branchSale: '', branchInventory: '' });
    currencySaleV2(['MXN', 1]);
    cartItems([]);
    searchV3('');
    isPettyCashOpen(false);
};
export default resetMakeVars;
