import jsPDF from 'jspdf';

import JsBarcode from 'jsbarcode';

const canvas = document.createElement('canvas');

const BarcodePDF = async (data, total = 1) => {
  const doc = new jsPDF({
    orientation: 'l',
    format: [29, 40],
    unit: 'mm',
  });

  if (!total || parseInt(total) <= 0) return;

  doc.setFontSize(10);
  doc.setFont('times', 'normal');
  const value =
    data.Presentation.barcode && data.Presentation.barcode.trim() !== ''
      ? data.Presentation.barcode
      : data.Presentation.altBarcode && data.Presentation.altBarcode.trim() !== ''
      ? data.Presentation.altBarcode
      : data.Presentation.id;
  JsBarcode(canvas, value, { text: ' ' });

  for (let i = 0; i < parseInt(total); i++) {
    i !== 0 && doc.addPage();
    doc.addImage(canvas.toDataURL('image/png'), 'JPEG', 1, 1, 38, 8);
    doc.text(value, 20, 10, 'center');
    doc.text(
      doc.splitTextToSize(
        `${data.Presentation.sku} ${data.name} $${data.Presentation.price}`.trim(),
        34,
      ),
      20,
      13,
      'center',
    );
  }

  window.open(doc.output('bloburl'), '_blank');
};

export default BarcodePDF;
