import React, { useEffect, useState } from 'react';
import ItemSalesSummaryIncomeReportComponent from '../SalesSummary/Item';
var SaleByChannelComponent = function (props) {
    var _a = useState([]), salePercent = _a[0], setSalePercent = _a[1];
    useEffect(function () {
        if (props.TotalTypeSales) {
            var total_1 = props.TotalTypeSales.reduce(function (total, el) { return total + el.Total.total; }, 0);
            var SalePercent = props.TotalTypeSales.map(function (el) { return (el.Total.total / total_1) * 100; });
            setSalePercent(SalePercent);
        }
    }, [props.TotalTypeSales]);
    if (!props.TotalTypeSales)
        return React.createElement("div", null);
    return (React.createElement("div", { className: 'flex flex-col overflow-hidden bg-slate-700 rounded p-2 mb-4' },
        React.createElement("div", { className: 'text-lg text-white font-medium' }, "Ventas por canal"),
        React.createElement("div", { className: 'flex' }, props.TotalTypeSales.map(function (el, i) { return (React.createElement(ItemSalesSummaryIncomeReportComponent, { key: el.id, border: true, qty: el.Total.count, total: el.Total.total, percent: salePercent[i], label: el.name })); }))));
};
export default SaleByChannelComponent;
