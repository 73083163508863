import React, { useState, useEffect } from 'react';

import { useLazyQuery, useMutation } from '@apollo/client';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';

import FormDialog from '../../../presentationals/FormDialog';
import SelectField from '../../../presentationals/FormComponents/SelectField';
import TextField from '../../../presentationals/FormComponents/TextField';
import Autocomplete from '../../../presentationals/FormComponents/Autocomplete';
import Button from '../../../presentationals/Button';

import { currencyFormat } from '../../../helpers';

import InvoiceDialogComponent from '../../cashSale/Header/invoice.dialog';
import GQL_SALES from '../../sale.report.v2/_gql';
import GQL from '../_gql';

import Table from './table';

const defaultValues = {
  type: 2,
  reference: '',
  commentary: '',
  bonus: '',
  product: null,
  quantity: '',
  totalToDiscount: '',
  devolutionType: 1,
};
const typeOptions = [
  { label: 'Bonificación', value: 1 },
  { label: 'Devolución', value: 2 },
];
const devolutionTypeOptions = [
  { label: 'Por cantidad de unidades', value: 1 },
  { label: 'Por descuento a importe', value: 2 },
];

const availableReference = [
  { value: '01', label: 'Nota de crédito de los documentos relacionados' },
  { value: '02', label: 'Nota de débito de los documentos relacionados' },
  { value: '03', label: 'Devolución de mercancía sobre facturas o traslados previos' },
  { value: '04', label: 'Sustitución de los CFDI previos' },
  { value: '05', label: 'Traslados de mercancias facturados previamente' },
  { value: '06', label: 'Factura generada por los traslados previos' },
  { value: '07', label: 'CFDI por aplicación de anticipo' },
  { value: '08', label: 'Factura generada por pagos en parcialidades' },
  { value: '09', label: 'Factura generada por pagos diferidos' },
];

const CreditNoteDialogComponent = props => {
  const [values, setValues] = useState(defaultValues);
  const [productOptions, setProductOptions] = useState([]);
  const [quantityOptions, setQuantityOptions] = useState([]);
  const [products, setProducts] = useState([]);
  const [isOpen, setIsOpen] = useState([false, {}]);
  const [errors, setErrors] = useState({});

  const [createCreditNote, { loading }] = useMutation(GQL.CREATE_CREDIT_NOTE);

  const [getSale, { data: dataProducts, loading: loadingProducts }] = useLazyQuery(
    GQL_SALES.GET_BY_ID_FOR_NC,
  );
  useEffect(() => {
    if (props.saleId) getSale({ variables: { id: props.saleId } });
  }, [props.saleId]);

  useEffect(() => {
    if (dataProducts) {
      const tmpProducts = dataProducts.sale.SaleProducts.map(el => {
        return {
          value: el.id,
          label: `${el.quantity} x ${el.Product.name} $${currencyFormat(el.total)}`,
          quantity: el.quantity,
          name: el.Product ? el.Product.name : el.name ? el.name : '',
          total: el.total,
          sku: el.Product ? el.Product.sku : '',
        };
      });
      setProductOptions(tmpProducts);
    }
  }, [dataProducts]);
  const handleClose = () => {
    props.handleClose();
  };
  const handleChange = (name, value) => {
    let totalToDiscount = '';
    if (name === 'product') {
      if (values.devolutionType === 1) {
        const tmpQuantity = [...Array(parseFloat(value.quantity)).keys()].map(i => ({
          value: i + 1,
          label: i + 1,
        }));
        setQuantityOptions(tmpQuantity);
      } else if (values.devolutionType === 2) {
        totalToDiscount = value.total;
      }
    } else if (name === 'devolutionType' && value === 2 && !!values.product) {
      totalToDiscount = values.product.total;
    }

    setValues(values => ({ ...values, totalToDiscount, [name]: value }));
  };

  const handleAdd = () => {
    try {
      setErrors({});
      let newError;
      if (!values.product) {
        newError = new Error('Selecciona un producto para devolución o descuento.');
        newError.name = 'product';
        throw newError;
      }
      if (values.devolutionType === 1 && values.quantity === '') {
        newError = new Error('Selecciona una cantidad a devolver.');
        newError.name = 'quantity';
        throw newError;
      }
      if (values.devolutionType === 2 && values.totalToDiscount === '') {
        newError = new Error('Selecciona el total a descontar.');
        newError.name = 'totalToDiscount';
        throw newError;
      }
      switch (values.devolutionType) {
        case 1: {
          const tmpProductsType1 = productOptions
            .map(el => {
              const newQuantity =
                parseFloat(el.value) === parseFloat(values.product.value)
                  ? parseFloat(el.quantity) - parseFloat(values.quantity)
                  : el.quantity;
              return parseFloat(el.value) === parseFloat(values.product.value) &&
                parseFloat(values.quantity) === parseFloat(el.quantity)
                ? null
                : {
                    ...el,
                    label: `${newQuantity} x ${el.name}`,
                    quantity: newQuantity,
                  };
            })
            .filter(el => el);
          setProductOptions(tmpProductsType1);
          setProducts(products => [
            ...products,
            { ...values.product, quantity: values.quantity, discount: 0 },
          ]);
          break;
        }
        case 2: {
          const tmpProductsType2 = productOptions.filter(el => el.value !== values.product.value);
          setProductOptions(tmpProductsType2);
          setProducts(products => [
            ...products,
            {
              ...values.product,
              discount: values.totalToDiscount,
            },
          ]);
          break;
        }
        default:
          break;
      }

      setValues(values => ({ ...values, product: null, quantity: '', totalToDiscount: '' }));
      setQuantityOptions([]);
    } catch (e) {
      if (['quantity', 'totalToDiscount', 'product'].includes(e.name)) {
        setErrors({ [e.name]: e.message });
        return;
      }
      console.log(e.message);
    }
  };

  const handleAction = async () => {
    try {
      setErrors({});
      let newError;
      if (values.reference === '') {
        newError = new Error('Selecciona un tipo de relación.');
        newError.name = 'reference';
        throw newError;
      }
      if (values.type === 2 && products.length === 0) {
        newError = new Error('Selecciona al menos un producto para devolver.');
        newError.name = 'product';
        throw newError;
      }
      const tmpProducts = products.map(el => ({
        saleProductId: el.value,
        quantity: el.quantity,
        unitPrice: parseFloat(el.discount),
        type: parseFloat(el.discount) > 0 ? 3 : 1,
      }));

      const variables = {
        invoiceId: dataProducts.sale.invoiceId,
        type: parseInt(values.type),
        reference: values.reference,
        commentary: values.commentary,
        bonus: !Number.isNaN(parseFloat(values.bonus)) ? parseFloat(values.bonus) : 0,
        ivaTax: 16,
        Products: tmpProducts,
        //type: 2
      };
      const { data } = await createCreditNote({ variables });
      setIsOpen([true, data.createCreditNote]);
      handleClose();
    } catch (e) {
      if (['reference', 'product'].includes(e.name)) {
        setErrors({ [e.name]: e.message });
        return;
      }
      console.log(e.message);
    }
  };
  const handleCloseInvoice = () => {
    setIsOpen([false, {}]);
  };
  return (
    <>
      <FormDialog
        title='Emitir Nota de Crédito'
        isOpen={props.isOpen}
        isLoading={loadingProducts || loading}
        width='md'
        handleClose={handleClose}
        handleAction={handleAction}
      >
        <Grid container spacing={2}>
          <Grid item md={4} xs={12}>
            <SelectField
              name='type'
              label='Tipo de Nota de Crédito'
              value={values.type}
              options={typeOptions}
              onChange={handleChange}
            />
          </Grid>
          <Grid item md={8} xs={12} />
          <Grid item md={4} xs={12}>
            <SelectField
              name='reference'
              label='Tipo de relación'
              onChange={handleChange}
              options={availableReference}
              value={values.reference}
              error={errors.reference}
            />
          </Grid>
          <Grid item md={8} xs={12} />
          <Grid item md={4} xs={12}>
            <TextField
              name='commentary'
              label='Anotaciones'
              onChange={handleChange}
              value={values.commentary}
            />
          </Grid>
          <Grid item md={8} xs={12} />
          <Grid item xs={12}>
            <Divider />
          </Grid>
          {values.type === 1 ? (
            <>
              <Grid item md={4} xs={12}>
                <TextField
                  name='bonus'
                  label='Cantidad a Bonificar'
                  type='number'
                  onChange={handleChange}
                  value={values.bonus}
                />
              </Grid>
              <Grid item md={8} xs={12} />
            </>
          ) : (
            values.type === 2 && (
              <>
                <Grid item md={4} xs={12}>
                  <SelectField
                    name='devolutionType'
                    label='Tipo de devolución'
                    value={values.devolutionType}
                    options={devolutionTypeOptions}
                    onChange={handleChange}
                  />
                  <Autocomplete
                    name='product'
                    label='Producto de la venta'
                    onChange={handleChange}
                    options={productOptions}
                    value={values.product}
                    error={errors.product}
                  />
                  {values.devolutionType === 1 && (
                    <SelectField
                      name='quantity'
                      label='Cantidad a devolver'
                      value={values.quantity}
                      options={quantityOptions}
                      onChange={handleChange}
                      error={errors.quantity}
                    />
                  )}
                  {values.devolutionType === 2 && (
                    <TextField
                      name='totalToDiscount'
                      label='Total a descontar ($)'
                      type='number'
                      onChange={handleChange}
                      value={values.totalToDiscount}
                      error={errors.totalToDiscount}
                    />
                  )}
                  <Button
                    label='Añadir'
                    onClick={handleAdd}
                    isLoading={loadingProducts}
                    variant='outlined'
                  />
                </Grid>
                <Grid item md={8} xs={12}>
                  <Table products={products} />
                </Grid>
              </>
            )
          )}
        </Grid>
      </FormDialog>
      <InvoiceDialogComponent
        isOpen={isOpen[0]}
        data={isOpen[1]}
        handleClose={handleCloseInvoice}
      />
    </>
  );
};

export default CreditNoteDialogComponent;
