import React from 'react';
import { useReactiveVar } from '@apollo/client';
import { isSendInvoiceComplementByEmailOpen } from '../../apollo/cache';
import DialogComponent from '../Dialog';
import BodySendInvoiceComplementByEmailDialogComponent from './Body';
var SendInvoiceComplementByEmailDialogComponent = function () {
    var isOpen = useReactiveVar(isSendInvoiceComplementByEmailOpen);
    var handleClose = function () {
        isSendInvoiceComplementByEmailOpen(false);
    };
    return (React.createElement(DialogComponent, { title: 'Enviar documentos por correo electr\u00F3nico', fullScreen: true, open: !!isOpen, onClose: handleClose },
        React.createElement(BodySendInvoiceComplementByEmailDialogComponent, null)));
};
export default SendInvoiceComplementByEmailDialogComponent;
