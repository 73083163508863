import React from 'react';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import Badge from '@mui/material/Badge';
import useGetSession from '../../../../hooks/useGetSession';
import LogoutButtonComponent from './LogoutButton';
import TermsProfileLinkComponent from './TermsProfileLink';
import PrivacyProfileLinkComponent from './PrivacyProfileLink';
import ManageAccountButtonComponent from './ManageAccountButton';
import ChangeProfileButtonComponent from './ChangeProfileButton';
var ProfileDialogComponent = function (props) {
    var session = useGetSession();
    return (React.createElement(Menu, { id: 'profile-menu', anchorEl: props.anchorEl, anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
        }, transformOrigin: {
            vertical: 'top',
            horizontal: 'right',
        }, keepMounted: true, open: props.isOpen, onClose: props.onClose, classes: { paper: 'bg-slate-100 w-[360px] mt-10' } },
        React.createElement("div", { className: 'bg-white flex-1 flex flex-row rounded mt-1 mx-3 p-4 pb-5' },
            React.createElement("div", { className: 'mr-5' },
                React.createElement(Badge, { overlap: 'circular', anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'right',
                    }, badgeContent: React.createElement(ChangeProfileButtonComponent, null) },
                    React.createElement(Avatar, { alt: session.name, className: 'bg-[#1e88e5] w-20 h-20 text-4xl' }))),
            React.createElement("div", { className: 'pt-1 flex flex-1 flex-col' },
                (session === null || session === void 0 ? void 0 : session.Company) && React.createElement("div", { className: 'font-medium text-sm' }, session.Company.name),
                React.createElement("div", { className: 'font-semibold text-sm' }, session.name),
                React.createElement("div", { className: 'text-gray-500 text-sm' }, session.phone),
                React.createElement(ManageAccountButtonComponent, null))),
        React.createElement(LogoutButtonComponent, null),
        React.createElement("div", { className: 'flex items-center justify-center mt-2' },
            React.createElement(TermsProfileLinkComponent, null),
            React.createElement("div", { className: 'mx-2 text-xl' }, "\u2022"),
            React.createElement(PrivacyProfileLinkComponent, null))));
};
export default ProfileDialogComponent;
