import React, { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import AutoCompleteComponent from '../../../../../../presentationals/FormComponents/Autocomplete';
import EMPLOYEE_GQL from '../../../../../employee/_gql';
import useGetSession from '../../../../../../hooks/useGetSession';
var AdviserFieldComponent = function (props) {
    var _a;
    var session = useGetSession();
    var adviserLabel = ((_a = session === null || session === void 0 ? void 0 : session.Company) === null || _a === void 0 ? void 0 : _a.activity) === 3 ? 'Terapeuta' : 'Asesor';
    var _b = useState([]), employeeOptions = _b[0], setEmployeeOptions = _b[1];
    var dataEmployees = useQuery(EMPLOYEE_GQL.GET_NAME).data;
    var _c = useState(null), value = _c[0], setValue = _c[1];
    useEffect(function () {
        if (employeeOptions.length > 0 && props.value) {
            var inArray = employeeOptions.find(function (el) { return el.value === props.value; });
            if (inArray)
                setValue(inArray);
            else
                setValue(null);
        }
    }, [employeeOptions, props.value]);
    useEffect(function () {
        if (dataEmployees && dataEmployees.employees.count > 0) {
            var tmp = dataEmployees.employees.rows.map(function (el) { return ({
                label: "".concat(el.firstName.trim(), " ").concat(el.lastName.trim()).trim(),
                value: el.id,
            }); });
            setEmployeeOptions(tmp);
        }
    }, [dataEmployees]);
    return (React.createElement(AutoCompleteComponent, { value: employeeOptions.length > 0 ? value : null, name: 'adviserId', label: adviserLabel, onChange: props.onChange, options: employeeOptions, helperText: null, error: null, onFocus: null, onBlur: null, extra: null, disabled: props.disabled }));
};
export default AdviserFieldComponent;
