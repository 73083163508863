import React from 'react';
import AddIcon from '@mui/icons-material/Add';
import { IconButton, Tooltip } from '@mui/material';
import { isCreateTransactionAccountOpen } from '../../../../apollo/cache';
var CreateTransactionAccountButtonComponent = function () {
    var handleClick = function () {
        isCreateTransactionAccountOpen(true);
    };
    return (React.createElement("div", { className: 'flex items-center ml-1 mb-1' },
        React.createElement(Tooltip, { title: 'Nueva cuenta destino' },
            React.createElement("div", null,
                React.createElement(IconButton, { onClick: handleClick, "aria-label": 'new-transaction-account' },
                    React.createElement(AddIcon, null))))));
};
export default CreateTransactionAccountButtonComponent;
