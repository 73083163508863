import React from 'react';
import convertToCurrencyLabel from '../../../../helpers/convertToCurrencyLabel';
var ItemSalesSummaryIncomeReportComponent = function (props) {
    return (React.createElement("div", { className: "flex-col mt-2 mr-2 pr-2 whitespace-nowrap ".concat(props.border ? 'border-0 border-r border-solid border-[#f5f5f5]' : '') },
        React.createElement("div", { className: 'text-xs font-semibold text-white' },
            props.total ? "$".concat(convertToCurrencyLabel(props.total)) : '$0.00',
            props.percent ? " (".concat(convertToCurrencyLabel(props.percent), "%)") : ''),
        React.createElement("div", { className: 'text-xs text-white' },
            props.label,
            props.qty ? " (".concat(props.qty, ")") : '')));
};
export default ItemSalesSummaryIncomeReportComponent;
