var getSearchSaleByOptions = function () {
    return [
        { value: 'folio', label: 'Folio' },
        { value: 'customer', label: 'Razon social' },
        { value: 'identifier', label: 'RFC' },
        { value: 'seller', label: 'Vendedor' },
        { value: 'total', label: 'Importe' },
    ];
};
export default getSearchSaleByOptions;
