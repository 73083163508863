var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { useEffect, useMemo, useState } from 'react';
import { useReactiveVar } from '@apollo/client';
import { fetchMoreData, filter, isTableScrollLoading } from '../apollo/cache';
import GQL_PURCHASE_ORDER from '../apollo/gql/purchaseOrder';
import useApolloLazyQuery from './useApolloLazyQuery';
import getLocalStorageSearchPurchaseOrderBy from '../helpers/getLocalStorageSearchPurchaseOrderBy';
import getPurchaseOrderRowsToShowInTable from '../apollo/gql/purchaseOrder/helpers/getPurchaseOrderRowsToShowInTable';
var useGetPurchaseOrders = function (props) {
    var fetchMoreDataVar = useReactiveVar(fetchMoreData);
    var variables = useReactiveVar(filter);
    var GET = useMemo(function () { return GQL_PURCHASE_ORDER.CUSTOMIZED_GET_PURCHASE_ORDERS(); }, []);
    var _a = useApolloLazyQuery(GET), getPurchaseOrders = _a[0], _b = _a[1], loading = _b.loading, response = _b.data;
    var _c = useState([]), data = _c[0], setData = _c[1];
    var _d = useState(false), isLoading = _d[0], setIsLoading = _d[1];
    useEffect(function () {
        if (props.getByFilter) {
            var searchBy = getLocalStorageSearchPurchaseOrderBy();
            var like = variables.like ? [searchBy, variables.like[1]] : undefined;
            getPurchaseOrders({
                variables: __assign(__assign({}, variables), { name: like ? like[1] : '', status: variables.status || 1, limit: 100, offset: 0 }),
            });
        }
    }, [variables, props.getByFilter]);
    useEffect(function () {
        setIsLoading(loading && !fetchMoreDataVar);
    }, [loading]);
    useEffect(function () {
        if (fetchMoreDataVar) {
            isTableScrollLoading(true);
            var searchBy = getLocalStorageSearchPurchaseOrderBy();
            var like = variables.like ? [searchBy, variables.like[1]] : undefined;
            getPurchaseOrders({
                variables: __assign(__assign({}, variables), { name: like ? like[1] : '', status: variables.status || 1, limit: 100, offset: Math.floor(data.length / 100) }),
            });
            isTableScrollLoading(false);
        }
    }, [fetchMoreDataVar]);
    useEffect(function () {
        if (response) {
            var Rows = response.rows;
            var data_1 = getPurchaseOrderRowsToShowInTable(Rows);
            if (fetchMoreDataVar) {
                setData(function (currentData) { return __spreadArray(__spreadArray([], currentData, true), data_1, true); });
                fetchMoreData(false);
            }
            else {
                setData(data_1);
            }
        }
    }, [response]);
    return { data: data, loading: isLoading };
};
export default useGetPurchaseOrders;
