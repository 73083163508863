var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useEffect, useState } from 'react';
import InventoryOutlinedIcon from '@mui/icons-material/InventoryOutlined';
import ListItemPermissionEditRoleComponent from '../helpers/ListItem';
import SwitchPermissionFieldComponent from '../helpers/SwitchPermissionField';
import SelectPermissionFieldComponent from '../helpers/SelectPermissionField';
import getDefaultBranchOptions from '../helpers/getDefaultBrachOptions';
var defaultPurchaseOrderPermissionValues = {
    createNewPurchaseOrder: false,
    editPurchaseOrder: 0,
    closePurchaseOrder: 0,
    getPurchaseOrders: 0,
    deletePurchaseOrder: 0,
};
var PurchaseOrderPermissionEditRoleComponent = function (props) {
    var defaultBranchOptions = getDefaultBranchOptions();
    var _a = useState(defaultPurchaseOrderPermissionValues), values = _a[0], setValues = _a[1];
    useEffect(function () {
        setValues(function (values) { return (__assign(__assign({}, values), props.Permissions)); });
    }, [props.Permissions]);
    return (React.createElement(ListItemPermissionEditRoleComponent, { icon: React.createElement(InventoryOutlinedIcon, null), name: 'Gesti\u00F3n de compras' },
        React.createElement("div", { className: 'mx-4 py-3 grid grid-cols-1 md:grid-cols-3 gap-4' },
            React.createElement("div", { className: 'flex flex-col' },
                React.createElement(SwitchPermissionFieldComponent, __assign({}, props, { name: 'createNewPurchaseOrder', label: 'Crear nuevas compras', defaultValue: values.createNewPurchaseOrder })),
                React.createElement(SelectPermissionFieldComponent, __assign({}, props, { name: 'editPurchaseOrder', label: 'Editar contenido de las \u00F3rdenes de compras', defaultValue: values.editPurchaseOrder, options: defaultBranchOptions })),
                React.createElement(SelectPermissionFieldComponent, __assign({}, props, { name: 'closePurchaseOrder', label: 'Cerrar las \u00F3rdenes de compra', defaultValue: values.closePurchaseOrder, options: defaultBranchOptions })),
                React.createElement(SelectPermissionFieldComponent, __assign({}, props, { name: 'getPurchaseOrders', label: 'Consultar datos generales y contenido de las compras u \u00F3rdenes de compra', defaultValue: values.getPurchaseOrders, options: defaultBranchOptions })),
                React.createElement(SelectPermissionFieldComponent, __assign({}, props, { name: 'deletePurchaseOrder', label: 'Eliminar compras u \u00F3rdenes de compras', defaultValue: values.deletePurchaseOrder, options: defaultBranchOptions }))))));
};
export default PurchaseOrderPermissionEditRoleComponent;
