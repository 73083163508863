import React from 'react';
import convertToCurrencyLabel from '../../../../../helpers/convertToCurrencyLabel';
var TotalsCreatePurchaseOrderCartLabelComponent = function (props) {
    return (React.createElement("div", { className: 'flex border-0 border-t border-solid border-slate-300' },
        props.alternative && React.createElement("div", { className: 'text-sm p-2 ml-2' }, props.alternative),
        React.createElement("div", { className: 'flex flex-1 text-right justify-end items-center p-2 text-sm' }, props.label),
        React.createElement("div", { className: "w-36 bg-gray-200 p-2 text-sm ".concat(props.boldValue ? 'font-semibold' : '') },
            "$",
            convertToCurrencyLabel(props.value))));
};
export default TotalsCreatePurchaseOrderCartLabelComponent;
