import React from 'react';
import GoBackButtonComponent from '../../../presentationals/GoBackButton';
import EditProviderComponent from '../../../containers/provider.v2/Edit';
var ProviderLayoutComponent = function () {
    return (React.createElement("div", { className: 'flex flex-1 flex-col' },
        React.createElement("div", { className: 'flex items-center px-4 py-0 border-0 border-b border-[#d6d6d6] border-solid' },
            React.createElement("div", { className: 'mr-3' },
                React.createElement(GoBackButtonComponent, null)),
            React.createElement("div", { className: 'text-lg text-gray-700 tracking-tight' }, "Configuraci\u00F3n del proveedor")),
        React.createElement("div", { className: 'flex flex-1 flex-col h-full overflow-hidden' },
            React.createElement(EditProviderComponent, null))));
};
export default ProviderLayoutComponent;
