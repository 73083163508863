import getPurchaseOrderTableColumnConfiguration from '../../../helpers/getPurchaseOrderTableColumnConfiguration';
var getPurchaseOrderHeaderColumns = function () {
    var defaultHeader = [
        { Header: 'Proveedor', accessor: 'provider' },
        { Header: 'Importe', accessor: 'total' },
    ];
    var settings = getPurchaseOrderTableColumnConfiguration();
    var allowedColumns = Object.keys(settings).filter(function (key) { return settings[key]; });
    var columns = defaultHeader.filter(function (el) { return allowedColumns.includes(el.accessor); });
    return columns;
};
export default getPurchaseOrderHeaderColumns;
