import React from 'react';
import { useReactiveVar } from '@apollo/client';
import { Dialog } from '@mui/material';
import Header from './Header';
import Body from './Body';
import Footer from './Footer';
import { isCalendarFormOpen } from '../../../apollo/cache';
var CreateCalendarDialogComponent = function () {
    var isCalendarFormOpenVar = useReactiveVar(isCalendarFormOpen);
    var handleClose = function () {
        isCalendarFormOpen([false, {}]);
    };
    return (React.createElement(Dialog, { onClose: handleClose, fullScreen: true, open: !!isCalendarFormOpenVar[0] },
        React.createElement("div", { className: 'flex flex-1 flex-col h-screen overflow-hidden' },
            React.createElement(Header, null),
            React.createElement(Body, null),
            React.createElement(Footer, null))));
};
export default CreateCalendarDialogComponent;
