var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var getDefaultTextColorValues = function (Colors, TextAttributes) {
    return Colors.reduce(function (object, el) {
        var _a;
        var currentAttribute = TextAttributes.find(function (elCh) { return elCh.principalAttributeId === el.id; });
        return __assign(__assign({}, object), (_a = {}, _a[el.id] = currentAttribute ? currentAttribute.attribute : '', _a));
    }, {});
};
export default getDefaultTextColorValues;
