import React, { useState } from 'react';

import { useQuery } from '@apollo/client';

import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';

import FormDialog from '../../presentationals/FormDialog';
import DateField from '../../presentationals/DateField';
import Button from '../../presentationals/Button';
import Autocomplete from '../../presentationals/FormComponents/Autocomplete';

import CONFIG from '../../config/config.json';
import GQL_CUSTOMER from '../customer/_gql';
import getSession from '../../helpers/getSession';

const defaultValues = {
  start: new Date(),
  end: new Date(),
  customerId: null,
};

const InvoiceDialogComponent = props => {
  const [values, setValues] = useState(defaultValues);
  const [customers, setCustomers] = useState([]);

  useQuery(GQL_CUSTOMER.GET_NAME_AND_PHONE, {
    variables: { limit: 0 },
    fetchPolicy: 'network-only',
    onCompleted: data => {
      const tmp = data.customers.rows.map(el => {
        return {
          label: `${el.firstName} ${el.lastName || ''} ${el.phone || ''}`,
          value: el.id,
        };
      });
      setCustomers(tmp);
    },
  });

  const handleClose = () => {
    props.handleClose();
  };
  const downloadXml = () => {
    const session = getSession();
    window.open(
      `${CONFIG['restServer'].uri}/files/invoices/xml/zip?token=${session.accessToken}&start=${values.start}&end=${values.end}&customerId=${values.customerId.value}`,
    );
  };
  const downloadPdf = () => {
    const session = getSession();
    window.open(
      `${CONFIG['restServer'].uri}/files/invoices/pdf/zip?token=${session.accessToken}&start=${values.start}&end=${values.end}&customerId=${values.customerId.value}`,
    );
  };
  const handleDateChange = (name, value) => {
    setValues(values => ({ ...values, [name]: value }));
  };
  const handleAutocompleteChange = (name, value) => {
    setValues(values => ({ ...values, [name]: value }));
  };

  return (
    <FormDialog isOpen={props.isOpen} handleClose={handleClose} noActions>
      <Typography variant='h5' gutterBottom>
        ¡Descargar archivo ZIP!
      </Typography>
      <Typography variant='subtitle1'>Máximo 7 días en un rango de fechas.</Typography>

      <DateField
        name='start'
        label='Inicio'
        value={values.start}
        onChange={handleDateChange}
        noFormat
      />
      <DateField name='end' label='Fin' value={values.end} onChange={handleDateChange} noFormat />
      <br />
      <Typography variant='subtitle1'>Cliente.</Typography>
      <Autocomplete
        name='customerId'
        onChange={handleAutocompleteChange}
        options={customers}
        value={values.customerId}
      />
      <br />
      <Grid container spacing={1}>
        <Grid item md={6} xs={12}>
          <Button label='Exportar XML' onClick={downloadXml} />
        </Grid>
        <Grid item md={6} xs={12}>
          <Button label='Exportar PDF' onClick={downloadPdf} />
        </Grid>
      </Grid>
    </FormDialog>
  );
};

export default InvoiceDialogComponent;
