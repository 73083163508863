var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import { Link } from '@mui/material';
import { addHours, format } from 'date-fns';
import { calendarForm } from '../../../../../../../../apollo/cache';
var AvailableButtonItemItemAgendaBodyFormCalendarComponent = function (props) {
    var handleClick = function (e) {
        e.preventDefault();
        var calendarFormVar = calendarForm();
        var minutes = props.minutes === '30' ? '50' : props.minutes;
        var hour = format(new Date(props.date), "HH.".concat(minutes));
        var endHour = format(addHours(new Date(props.date), 1), "HH.".concat(minutes));
        calendarForm(__assign(__assign({}, calendarFormVar), { startHour: parseFloat(hour), endHour: parseFloat(endHour) }));
    };
    return (React.createElement(Link, { underline: 'hover', className: 'text-sm cursor-pointer', href: '#', onClick: handleClick }, "Disponible"));
};
export default AvailableButtonItemItemAgendaBodyFormCalendarComponent;
