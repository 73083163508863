import React, { useEffect } from 'react';
import { useReactiveVar } from '@apollo/client';
import useApolloLazyQuery from '../../../../hooks/useApolloLazyQuery';
import GQL_CLASSIFICATION from '../../../../apollo/gql/classification';
import CheckboxComponent from '../../../../presentationals/FormComponents/Checkbox';
import { inputError } from '../../../../apollo/cache';
var ClassificationsCheckboxFieldComponent = function (props) {
    var errors = useReactiveVar(inputError);
    var _a = useApolloLazyQuery(GQL_CLASSIFICATION.GET_CLASSIFICATION), getClassification = _a[0], data = _a[1].data;
    useEffect(function () {
        getClassification({ variables: { limit: 0 } });
    }, []);
    var handleChange = function (id, checked) {
        props.onChange(id, checked);
    };
    var handleSelectAll = function (selectAll) {
        if (data && props.onSelectAll)
            props.onSelectAll(selectAll ? data.rows.map(function (el) { return el.id; }) : []);
    };
    return (React.createElement(CheckboxComponent, { label: 'Clasificaciones', onChange: handleChange, row: true, onSelectAll: handleSelectAll, error: errors.forSale, values: data
            ? data.rows.map(function (el) { return ({
                name: el.id,
                label: el.name,
                checked: props.defaultValues.includes(el.id),
            }); })
            : [] }));
};
export default ClassificationsCheckboxFieldComponent;
