import React, { useState } from 'react';

import FactCheckIcon from '@mui/icons-material/FactCheckOutlined';

import Container from '../../../presentationals/Module.v2/Container';

import Action from '../_action';
import Filter from '../_filter';
import InvoiceComplement from '../invoiceComplement.dialog';

import Table from './Table';
import getSearchByReceivableOptions from '../../../helpers/getSearchByReceivableOptions';

const title = 'Cuentas por cobrar';

const CustomerReceivableV2Component = () => {
  const searchCustomerReceivableByOptions = getSearchByReceivableOptions();

  const [isOpen, setIsOpen] = useState(false);

  const handleOpen = () => setIsOpen(true);
  const handleClose = () => setIsOpen(false);

  return (
    <>
      <Container
        title={title}
        filter
        searchFieldOptions={{
          name: 'searchReceivableBy',
          options: searchCustomerReceivableByOptions,
          defaultValue: 'customer',
        }}
        noTable
        extra={{
          label: 'Complemento de pago',
          action: handleOpen,
          icon: <FactCheckIcon />,
        }}
      >
        <Table title={title} />
      </Container>
      <Action />
      <Filter />
      <InvoiceComplement isOpen={isOpen} handleClose={handleClose} />
    </>
  );
};

export default CustomerReceivableV2Component;
