import React from 'react';
import DownloadInvoiceXMLButtonComponent from './DownloadInvoiceXMLButton';
import DownloadInvoicePDFButtonComponent from './DownloadInvoicePDFButton';
import DownloadInvoiceAcusePDFButton from './DownloadInvoiceAcusePDFButton';
import DownloadInvoiceAcuseXMLButton from './DownloadInvoiceAcuseXMLButton';
import { dateFormat } from '../../../../../helpers';
var InvoiceCreatedComponent = function (props) {
    return (React.createElement("div", { className: 'flex flex-1 flex-col p-3' },
        React.createElement("div", { className: 'flex items-center' },
            React.createElement("div", { className: 'text-base font-medium mb-1 flex flex-1' }, "Factura electr\u00F3nica")),
        React.createElement("div", { className: 'text-sm sm:text-base text-gray-600' }, props.status
            ? "Ya se emiti\u00F3 una factura electr\u00F3nica con el uuid ".concat(props.Invoice.uuid, ". Si lo necesitas, puedes descargar los documentos.")
            : "La factura no puede ser emitida ya que la venta fue cancelada el d\u00EDa$ ".concat(dateFormat(props.updatedAt))),
        React.createElement("div", { className: 'flex flex-col sm:flex-row mt-2' },
            React.createElement(DownloadInvoicePDFButtonComponent, { uuid: props.Invoice.uuid }),
            React.createElement(DownloadInvoiceXMLButtonComponent, { uuid: props.Invoice.uuid }),
            props.status === 0 ? React.createElement(DownloadInvoiceAcusePDFButton, { uuid: props.Invoice.uuid }) : null,
            props.status === 0 ? React.createElement(DownloadInvoiceAcuseXMLButton, { uuid: props.Invoice.uuid }) : null)));
};
export default InvoiceCreatedComponent;
