import { gql } from '@apollo/client';

const GET = gql`
  query AccountPayables(
    $order: [String]
    $limit: Int
    $offset: Int
    $status: [Int]
    $branchId: ID
    $paid: Boolean
    $name: String
    $duePayment: Int
    $providerId: ID
  ) {
    accountPayables(
      order: $order
      limit: $limit
      offset: $offset
      status: $status
      branchId: $branchId
      paid: $paid
      like: $name
      duePayment: $duePayment
      providerId: $providerId
    ) {
      count
      totals {
        id
        name
        qty
        amount
      }
      rows {
        id
        clientCreatedAt
        folio
        serial
        discount
        subtotal
        taxes
        total
        payment
        balance
        name
        customerId
        timeLimit
        limitDate
        type
        background
        paid
        purchaseOrderId
        Branch {
          id
          name
        }
        PurchaseOrder {
          serial
          subFolio
        }
        Provider {
          id
          name
        }
        currency
        usdTotal
      }
    }
  }
`;

const GET_BY_ID = gql`
  query AccountPayable($id: ID!) {
    accountPayable(id: $id) {
      id
      clientCreatedAt
      folio
      serial
      discount
      subtotal
      taxes
      total
      payment
      balance
      name
      customerId
      timeLimit
      limitDate
      type
    }
  }
`;

const CREATE_ACCOUNT_PAYABLE_TRANSACTION = gql`
  mutation CreateAccountPayableTransaction(
    $total: Float!
    $paymentMethodId: ID
    $accountPayableId: ID!
    $currency: String
    $exchangeRate: Float
    $date: Date
    $transactionAccountId: ID
    $commentary: String
  ) {
    createAccountPayableTransaction(
      input: {
        total: $total
        paymentMethodId: $paymentMethodId
        accountPayableId: $accountPayableId
        currency: $currency
        exchangeRate: $exchangeRate
        date: $date
        transactionAccountId: $transactionAccountId
        commentary: $commentary
      }
    ) {
      id
      total
      paymentMethodId
      PaymentMethod {
        name
      }
      clientCreatedAt
      AccountPayable {
        id
        total
        serial
        purchaseOrderId
        Provider {
          name
        }
        PurchaseOrder {
          subFolio
          serial
        }
      }
      commentary
    }
  }
`;

const GET_ACCOUNT_PAYABLE_TRANSACTIONS = gql`
  query AccountPayableTransactions(
    $name: String
    $limit: Int
    $offset: Int
    $start: Date
    $end: Date
    $order: [String]
    $accountPayableId: ID
    $paymentMethodId: ID
  ) {
    accountPayableTransactions(
      like: $name
      limit: $limit
      offset: $offset
      start: $start
      end: $end
      order: $order
      accountPayableId: $accountPayableId
      paymentMethodId: $paymentMethodId
    ) {
      count
      totals {
        id
        name
        qty
        amount
      }
      rows {
        id
        total
        paymentMethodId
        createdAt
        PaymentMethod {
          name
        }
        clientCreatedAt
        AccountPayable {
          id
          total
          serial
          purchaseOrderId
          Provider {
            name
          }
          PurchaseOrder {
            subFolio
            serial
          }
        }
        commentary
      }
    }
  }
`;

const UPDATE = gql`
  mutation UpdateAccountPayable(
    $id: ID!
    $name: String!
    $type: Int!
    $total: Float!
    $customerId: ID
    $timeLimit: Int
  ) {
    updateAccountPayable(
      id: $id
      input: {
        name: $name
        type: $type
        total: $total
        customerId: $customerId
        timeLimit: $timeLimit
      }
    ) {
      id
      clientCreatedAt
      folio
      serial
      discount
      subtotal
      taxes
      total
      payment
      background
      balance
      name
      customerId
      timeLimit
      limitDate
      type
    }
  }
`;

const SEND_PURCHASE_ORDER = gql`
  mutation SendPurchaseOrder($id: ID!, $file: Upload, $emails: [String!]) {
    sendPurchaseOrder(id: $id, file: $file, emails: $emails)
  }
`;

const DELETE_ACCOUNT_PAYABLE_TRANSACTION = gql`
  mutation DeleteAccountPayableTransaction($id: ID!, $userPassword: String) {
    deleteAccountPayableTransaction(id: $id, userPassword: $userPassword) {
      id
      AccountPayable {
        id
        clientCreatedAt
        folio
        serial
        discount
        subtotal
        taxes
        total
        payment
        balance
        name
        customerId
        timeLimit
        limitDate
        type
        background
        paid
        purchaseOrderId
        PurchaseOrder {
          serial
          subFolio
        }
        Provider {
          id
          name
        }
        currency
        usdTotal
      }
    }
  }
`;

const FRAGMENT_NEW_ACCOUNT_PAYABLE_TRANSACTION = gql`
  fragment NewAccountPayableTransaction on AccountPayableTransaction {
    id
    total
    paymentMethodId
    PaymentMethod {
      name
    }
    clientCreatedAt
    AccountPayable {
      id
      total
      serial
      purchaseOrderId
      Provider {
        name
      }
      PurchaseOrder {
        subFolio
        serial
      }
    }
    commentary
  }
`;

const FRAGMENT_NEW_ACCOUNT_PAYABLE = gql`
  fragment NewAccountPayable on AccountPayable {
    id
    clientCreatedAt
    folio
    serial
    discount
    subtotal
    taxes
    total
    payment
    balance
    name
    customerId
    timeLimit
    limitDate
    type
    background
    paid
    purchaseOrderId
    PurchaseOrder {
      serial
      subFolio
    }
    Provider {
      id
      name
    }
    currency
    usdTotal
  }
`;

export default {
  GET,
  GET_BY_ID,
  CREATE_ACCOUNT_PAYABLE_TRANSACTION,
  UPDATE,
  GET_ACCOUNT_PAYABLE_TRANSACTIONS,
  SEND_PURCHASE_ORDER,
  DELETE_ACCOUNT_PAYABLE_TRANSACTION,
  FRAGMENT_NEW_ACCOUNT_PAYABLE_TRANSACTION,
  FRAGMENT_NEW_ACCOUNT_PAYABLE,
};
