var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
var editProductToCreatePurchaseOrderCart = function (index, data, cart) {
    var _a, _b;
    if (!data.Product)
        throw new Error('Ocurrió un error inesperado, contacta soporte técnico.');
    if (!((_a = data.Product) === null || _a === void 0 ? void 0 : _a.ProductPresentations))
        throw new Error('Este producto no cuenta con presentaciones registradas.');
    var ProductPresentations = __spreadArray([
        data.Product.PresentationBase
    ], (((_b = data.Product) === null || _b === void 0 ? void 0 : _b.ProductPresentations) || []), true);
    var ProductPresentation = ProductPresentations.find(function (el) { return el.id === data.productPresentationId; });
    if (!ProductPresentation)
        throw new Error('Este producto no cuenta con una presentación seleccionada.');
    var Product = data.Product;
    return cart.map(function (el, i) {
        return i === index
            ? {
                Product: Product,
                ProductPresentation: ProductPresentation,
                cost: parseFloat(data.cost || '0'),
                percentAdjust: parseFloat(data.percentAdjust || '0'),
                quantity: parseFloat(data.quantity || '0'),
                salePrice: parseFloat(data.salePrice || '0'),
                updateSalePrice: data.updateSalePrice,
                withoutTaxes: 0,
                taxes: 0,
                total: 0,
                price: parseFloat(data.cost || '0'),
            }
            : el;
    });
};
export default editProductToCreatePurchaseOrderCart;
