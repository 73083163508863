import React from 'react';

import Container from '../../presentationals/Module.v2/Container';
import { customerLabel } from '../../helpers';

import GQL from './_gql';
import Action from './_action';
import Filter from './_filter';
import XlsExport from './xlsExport';

const customerVar = customerLabel({ textTransform: 'capitalize' });

const title = 'Mantenimiento';
const operation = ['maintenance', 'maintenances'];

const Rows = [
  { label: customerVar, key: 'Customer firstName && Customer lastName', filter: true },
  { label: 'Fecha de Salida', key: 'createdAt', sort: 'text', format: 'dateTime' },
  { label: 'Estatus', key: 'status', sort: 'text', format: 'orderStatus' },
  { label: 'Folio', key: 'serial', prefix: 'MTO', padStart: [5, '0'], sort: 'text', filter: true },
  { label: 'NV', key: 'folioSale', sort: 'text', filter: true },
  { label: 'Comentario', key: 'commentary' },
  {
    label: 'Importe',
    key: 'AccountReceivable total',
    sort: 'text',
    format: 'currency',
    align: 'right',
  },
  {
    label: 'Abonado',
    key: 'AccountReceivable payment',
    sort: 'text',
    format: 'currency',
    align: 'right',
  },
  {
    label: 'Saldo',
    key: 'AccountReceivable balance',
    sort: 'text',
    format: 'currency',
    align: 'right',
  },
  {
    label: 'Gastos',
    key: 'expenses',
    sort: 'text',
    format: 'currency',
    align: 'right',
  },
];

const Component = () => {
  return (
    <>
      <Container
        title={title}
        operation={operation}
        Rows={Rows}
        get={GQL.GET}
        noEdit
        filter
        customXls
      />
      <Action />
      <Filter />
      <XlsExport />
    </>
  );
};

export default Component;
