import React, { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import MenuSearchCustomerByGlobalInvoiceFieldComponent from './Menu';
var SearchCustomerByGlobalInvoiceFieldComponent = forwardRef(function (props, ref) {
    var _a = useState(['Nombre', 'name']), Customer = _a[0], setCustomer = _a[1];
    var menuSearchCustomerByGlobalInvoiceField = useRef(null);
    var handleChange = function (name, value) {
        setCustomer([name, value]);
        props.onChange && props.onChange(name, value);
    };
    var handleClick = function (event) {
        var _a;
        (_a = menuSearchCustomerByGlobalInvoiceField.current) === null || _a === void 0 ? void 0 : _a.open(event.currentTarget);
    };
    var getValue = function () { return Customer[1]; };
    useImperativeHandle(ref, function () { return ({
        getValue: getValue,
    }); });
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { role: 'button', tabIndex: -1, onClick: handleClick, className: 'flex flex-col justify-center mr-2 cursor-pointer' },
            React.createElement("div", { className: 'text-xs text-gray-400 leading-none tracking-tighter' }, "B\u00FAsqueda por:"),
            React.createElement("div", { className: 'text-sm leading-none tracking-tighter text-gray-600' }, Customer[0])),
        React.createElement(MenuSearchCustomerByGlobalInvoiceFieldComponent, { ref: menuSearchCustomerByGlobalInvoiceField, onChange: handleChange })));
});
export default SearchCustomerByGlobalInvoiceFieldComponent;
