var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useEffect, useState } from 'react';
import { useReactiveVar } from '@apollo/client';
import TextFieldComponent from '../../../../../../presentationals/FormComponents/TextField';
import { inputError } from '../../../../../../apollo/cache';
import FooterDialogComponent from '../../../../../../presentationals/Dialog/Footer';
import CreateAddressLine2ButtonComponent from './CreateButton';
import DeleteAddressLine2ButtonComponent from './DeleteButton';
var BodyAddressLine2BranchComponent = function (props) {
    var defaultValues = {
        name: '',
        zipCode: '',
        price: '',
    };
    var errors = useReactiveVar(inputError);
    var _a = useState(defaultValues), values = _a[0], setValues = _a[1];
    useEffect(function () {
        if (props.defaultValues)
            setValues(props.defaultValues);
    }, [props.defaultValues]);
    var handleChange = function (name, value) {
        setValues(function (values) {
            var _a;
            return (__assign(__assign({}, values), (_a = {}, _a[name] = value, _a)));
        });
    };
    return (React.createElement("div", { className: 'flex flex-1 flex-col' },
        React.createElement("div", { className: 'grid grid-cols-1 sm:grid-cols-4 flex-1 px-4 py-3' },
            React.createElement("div", null,
                React.createElement(TextFieldComponent, { name: 'name', label: 'Nombre', onChange: handleChange, value: values.name, error: errors.name }),
                React.createElement(TextFieldComponent, { name: 'zipCode', label: 'C\u00F3digo postal', onChange: handleChange, value: values.zipCode, error: errors.zipCode }),
                React.createElement(TextFieldComponent, { name: 'price', label: 'Precio para env\u00EDo', onChange: handleChange, value: values.price, error: errors.price }))),
        React.createElement(FooterDialogComponent, null,
            props.delete ? (React.createElement("div", null,
                React.createElement(DeleteAddressLine2ButtonComponent, null))) : (React.createElement("div", null)),
            React.createElement(CreateAddressLine2ButtonComponent, { loading: props.loading, label: props.Button.label, onClick: props.Button.onClick, values: values }))));
};
export default BodyAddressLine2BranchComponent;
