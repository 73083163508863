import { Alert } from '@mui/material';
import React from 'react';
import { isCreateProductIngredientPriceOpen } from '../../../../../apollo/cache';
var NoProductPricesAlertEditProductIngredientComponent = function () {
    var handleOpenCreateProductPrice = function (e) {
        e.preventDefault();
        isCreateProductIngredientPriceOpen(true);
    };
    return (React.createElement(Alert, { severity: 'info', className: 'mt-3 w-full' },
        "De momento, no cuentas con tarifas personalizadas, esto significa que se tomar\u00E1 el precio est\u00E1ndar de venta configurado en el ingrediente. Si lo deseas, puedes",
        ' ',
        React.createElement("a", { href: '#', className: 'text-black font-medium', onClick: handleOpenCreateProductPrice }, "registrar una nueva tarifa"),
        "."));
};
export default NoProductPricesAlertEditProductIngredientComponent;
