import React, { useEffect, useState } from 'react';

import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabelMui from '@mui/material/FormControlLabel';
import CheckboxMui from '@mui/material/Checkbox';
import Typography from '@mui/material/Typography';

const FormControlLabel = props => {
  const noPadding = props.noPadding ? { padding: 0 } : {};
  const handleChange = e => {
    props.handleChange(e.target.name, e.target.checked, props.params);
  };
  return (
    <FormControlLabelMui
      control={
        <CheckboxMui
          checked={props.checked}
          onChange={handleChange}
          name={props.name}
          style={{ color: props.color, ...noPadding }}
        />
      }
      label={props.label}
    />
  );
};

const CheckboxComponent = ({ error, label, ...props }) => {
  const [selectAll, setSelectAll] = useState(false);
  useEffect(() => {
    const selectAll = props.values.reduce((selectAll, el) => {
      return !el.checked ? false : selectAll;
    }, true);
    setSelectAll(selectAll);
  }, [props.values]);
  const handleChange = (name, checked, params) => {
    props.onChange(name, checked, params);
  };
  const handleSelectAll = () => {
    setSelectAll(!selectAll);
    props.onSelectAll(!selectAll);
  };
  return (
    <div className='my-1'>
      {label && (
        <div className='flex flex-row items-center'>
          {props.onSelectAll && (
            <CheckboxMui className='p-0' checked={selectAll} onChange={handleSelectAll} />
          )}
          <Typography className='font-medium ml-2' variant='subtitle1'>
            {label}
          </Typography>
        </div>
      )}
      <FormControl disabled={props.disabled} required component='fieldset' className='w-full'>
        <FormGroup row={props.row || false}>
          {props.values &&
            props.values.map(el => (
              <FormControlLabel
                key={`${el.name}-${el.label}`}
                checked={el.checked}
                name={el.name.toString()}
                label={el.label}
                params={el.params}
                handleChange={handleChange}
                noPadding={props.noPadding}
                color={el.color}
              />
            ))}
        </FormGroup>
      </FormControl>
      {error && (
        <Typography variant='caption' color='error'>
          {error}
        </Typography>
      )}
    </div>
  );
};

export default CheckboxComponent;
