import React, { useEffect } from 'react';
import { useLazyQuery } from '@apollo/client';

import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';

import FormDialog from '../../presentationals/FormDialog';
import GQL from './_gql';
import getInputErrorFormat from '../sale.v2/Action/helpers/getInputErrorFormat';

const Component = props => {
  const [ecSaleTrackingNumber, { data, loading }] = useLazyQuery(GQL.EC_SALE_TRACKING_NUMBER);

  useEffect(async () => {
    if (props.isOpen && props.id)
      try {
        if (props.id) await ecSaleTrackingNumber({ variables: { id: props.id } });
      } catch (e) {
        getInputErrorFormat(e);
      }
  }, [props.id]);

  const handleClose = () => {
    props.handleClose();
  };

  const handleTrackingUrl = () =>
    data && data.ecSaleTrackingNumber && window.open(data.ecSaleTrackingNumber.trackingUrl);

  return (
    <FormDialog
      title='Guía de Envió'
      isOpen={props.isOpen || false}
      isLoading={loading}
      handleClose={handleClose}
      hideAction
    >
      <Typography variant='body2' gutterBottom>
        {`Paquetería: ${
          data && data && data.ecSaleTrackingNumber
            ? data.ecSaleTrackingNumber.provider
            : 'Cargando...'
        }`}
        <br />
        {`No. de guía: ${
          data && data && data.ecSaleTrackingNumber
            ? data.ecSaleTrackingNumber.trackingNumber
            : 'Cargando...'
        }`}
        <br />
        {data && data.ecSaleTrackingNumber && (
          <>
            <Link onClick={handleTrackingUrl}> Consultar Rastreo del paquete aquí. </Link> <br />
          </>
        )}
      </Typography>
    </FormDialog>
  );
};

export default Component;
