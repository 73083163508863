import React from 'react';
import useGetClassifications from '../../../../../../../../hooks/useGetClassifications';
import SelectFieldComponent from '../../../../../../../../presentationals/FormComponents/SelectField';
var ClassificationFieldProductComponent = function (props) {
    var _a = useGetClassifications(null, 'select'), data = _a.data, loading = _a.loading, error = _a.error;
    var handleChange = function (name, value) {
        props.onChange(name, value);
    };
    return (React.createElement(SelectFieldComponent, { label: 'Clasificaci\u00F3n', name: 'classificationId', value: data.rows.length > 0 ? props.value : '', error: props.error, onChange: handleChange, disabled: loading || props.isLoading || data.rows.length === 0 || !!error, options: data.rows }));
};
export default ClassificationFieldProductComponent;
