var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
var addAdjustProductByBarcode = function (Item, Rows) {
    var isInArray = Rows.find(function (el) { return el.barcode === Item.barcode; });
    if (isInArray)
        return __spreadArray([
            __assign(__assign({}, isInArray), { barcode: Item.barcode, quantity: Item.quantity, newInvQty: isInArray.newInvQty + Item.quantity, movementQty: isInArray.movementQty ? isInArray.movementQty + 1 : 1, history: __spreadArray(__spreadArray([], (isInArray.history || []), true), [
                    {
                        movement: isInArray.history ? isInArray.history.length + 1 : 1,
                        quantity: Item.quantity,
                        total: isInArray.newInvQty + Item.quantity,
                    },
                ], false) })
        ], Rows.filter(function (el) { return el.barcode !== Item.barcode; }), true);
    return __spreadArray([
        {
            barcode: Item.barcode,
            quantity: Item.quantity,
            movementQty: 1,
            newInvQty: Item.quantity,
            history: [{ movement: 1, quantity: Item.quantity, total: Item.quantity }],
        }
    ], Rows, true);
};
export default addAdjustProductByBarcode;
