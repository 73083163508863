import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { moduleMoreOptionsItems } from '../../../apollo/cache';
// import CreateGlobalBillComponent from '../../../presentationals/Module.v2/Container/GlobalBillButton/FormDialog';
var MoreOptionsSaleReportComponent = function () {
    var navigate = useNavigate();
    useEffect(function () {
        var globalInvoice = {
            label: 'Factura Global',
            imageSrc: 'https://mistimbres.com.mx/images/factura.png',
            onClick: function () {
                navigate('/panel/globalInvoice');
            },
        };
        moduleMoreOptionsItems([globalInvoice]);
    }, []);
    return React.createElement("div", null);
};
export default MoreOptionsSaleReportComponent;
