import React, { useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { inputError } from '../../../apollo/cache';
import FooterDialogComponent from '../../../presentationals/Dialog/Footer';
import EditButtonPaymentMethodComponent from './EditButton';
import DeleteButtonPaymentMethodComponent from './DeleteButton';
import useApolloLazyQuery from '../../../hooks/useApolloLazyQuery';
import GQL from '../_gql';
import LoadingProgress from '../../../presentationals/LoadingProgress';
import ErrorDataComponent from '../../../presentationals/ErrorData';
import BodyPaymentMethodComponent from '../Create/Body';
var EditPaymentMethodComponent = function () {
    var bodyPaymentMethodRef = useRef(null);
    var params = useParams();
    var _a = useApolloLazyQuery(GQL.GET_BY_ID), getById = _a[0], _b = _a[1], loading = _b.loading, data = _b.data;
    useEffect(function () {
        return function () {
            inputError({});
        };
    }, []);
    useEffect(function () {
        if (params.paymentMethodId)
            getById({ variables: { id: params.paymentMethodId } });
    }, [params]);
    var handleGetValues = function () {
        var _a;
        return (_a = bodyPaymentMethodRef.current) === null || _a === void 0 ? void 0 : _a.getValues();
    };
    if (loading)
        return React.createElement(LoadingProgress, null);
    if (!data)
        return React.createElement(ErrorDataComponent, null);
    return (React.createElement(React.Fragment, null,
        React.createElement(BodyPaymentMethodComponent, { ref: bodyPaymentMethodRef, defaultValues: data }),
        React.createElement(FooterDialogComponent, null,
            React.createElement(DeleteButtonPaymentMethodComponent, null),
            React.createElement(EditButtonPaymentMethodComponent, { getValues: handleGetValues }))));
};
export default EditPaymentMethodComponent;
