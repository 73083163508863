import React, { useState } from 'react';
import { useMutation } from '@apollo/client';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import FormDialog from '../../presentationals/FormDialog';
import TextField from '../../presentationals/TextField';
import Snackbar from '../../presentationals/Snackbar';
import SelectField from '../../presentationals/SelectField';

import { parseError } from '../../helpers';

import GQL from '../transaction/_gql';
import useSnackbarDialog from '../../store/snackbar/snackbarDialog';

const defaultValues = {
  userPassword: '',
  reason: '01',
  relatedFolio: '',
};
const defaultSnackbar = { isOpen: true, time: 3000 };

const reasonOptions = [
  { label: '01 Comprobante emitido con errores con relación', value: '01' },
  { label: '02 Comprobante emitido con errores sin relación', value: '02' },
  { label: '03 No se llevó a cabo la operación', value: '03' },
  { label: '04 Operación nominativa relacionada en la factura global', value: '04' },
];

const Component = props => {
  const { setSnackbar } = useSnackbarDialog();

  const [values, setValues] = useState(defaultValues);

  const [handleCancelTransaction, { loading }] = useMutation(GQL.DELETE);
  const [handleCancelTransactionComplement, { loading: loadingCancel }] = useMutation(
    GQL.DELETE_WITH_COMPLEMENT,
  );

  const resetValues = () => {
    setValues(defaultValues);
  };

  const handleAction = async () => {
    try {
      if (values.reason === '01' && props.invoiceId && values.relatedFolio === '') {
        newError = new Error('Proporciona el UUID a relacionar.');
        newError.name = 'relatedFolio';
        throw newError;
      }
      if (values.userPassword === '') {
        newError = new Error('Proporciona una contraseña con privilegios.');
        newError.name = 'userPassword';
        throw newError;
      }
      const relatedFolio = values.reason === '01' ? { relatedFolio: values.relatedFolio } : {};
      const invoiceData = props.invoiceId ? { reason: values.reason, ...relatedFolio } : {};
      if (props.invoiceId)
        await handleCancelTransactionComplement({
          variables: { id: props.id, userPassword: values.userPassword, ...invoiceData },
        });
      else
        await handleCancelTransaction({
          variables: { id: props.id, userPassword: values.userPassword },
        });

      resetValues();
      setSnackbar({
        ...defaultSnackbar,
        label: 'El abono fue cancelado con éxito.',
        severity: 'success',
      });
      props.handleClose(props.id);
    } catch (e) {
      const parseErrors = parseError(e);
      parseErrors.forEach(el => {
        console.log(el);
        if (el.name === 'BAD_USER_INPUT') {
          const key = Object.keys(el.message)[0];
          setSnackbar({ ...defaultSnackbar, label: el.message[key], severity: 'error' });
        } else {
          setSnackbar({ ...defaultSnackbar, label: el.message, severity: 'error' });
        }
      });
    }
  };

  const handleChange = e => {
    const { name } = e.target;
    const { value } = e.target;
    setValues(values => ({ ...values, [name]: value }));
  };

  return (
    <>
      <FormDialog
        title='Cancelar Abono'
        isOpen={props.isOpen}
        isLoading={loading || loadingCancel}
        handleAction={handleAction}
        handleClose={props.handleClose}
      >
        <Grid container>
          <Grid item xs={12}>
            <Typography variant='body2' gutterBottom>
              Estás a punto de cancelar un abono. Solicitamos una contraseña con privilegios para
              realizar esta acción.
            </Typography>
          </Grid>
          {props.invoiceId && (
            <Grid item xs={12}>
              <SelectField
                label='Motivo de cancelación'
                name='reason'
                value={values.reason}
                onChange={handleChange}
                options={reasonOptions}
                noFormat
              />
            </Grid>
          )}
          {props.invoiceId && values.reason === '01' && (
            <Grid item xs={12}>
              <TextField
                label='Folio relacionado'
                name='relatedFolio'
                value={values.relatedFolio}
                onChange={handleChange}
                noFormat
              />
            </Grid>
          )}
          <Grid item xs={12}>
            <TextField
              label='Contraseña'
              name='userPassword'
              value={values.userPassword}
              onChange={handleChange}
              noFormat
              type='password'
            />
          </Grid>
        </Grid>
      </FormDialog>
      <Snackbar horizontal='center' />
    </>
  );
};

export default Component;
