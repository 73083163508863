var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import getInputErrorFormat from '../../../../../../sale.v2/Action/helpers/getInputErrorFormat';
var deleteCalendarCache = function (cache, _a) {
    var data = _a.data;
    try {
        cache.modify({
            fields: {
                calendars: function (existingRefs, _a) {
                    var readField = _a.readField;
                    return __assign(__assign({}, existingRefs), { rows: existingRefs.rows.filter(function (existingRef) {
                            return !__spreadArray([data.updateStatusCalendar.id], data.updateStatusCalendar.cancelledIds, true).includes(readField('id', existingRef));
                        }) });
                },
            },
        });
    }
    catch (error) {
        getInputErrorFormat(error);
    }
};
export default deleteCalendarCache;
