import React, { useState } from 'react';

import { useMutation } from '@apollo/client';

import FormDialog from '../../../../presentationals/FormDialog';
import TextField from '../../../../presentationals/TextField';

import { parseError } from '../../../../helpers';
import GQL_PROVIDER from '../../../../apollo/gql/provider';
import useSnackbarDialog from '../../../../store/snackbar/snackbarDialog';

const defaultValue = {
  name: '',
};

const Customer = props => {
  const { setSnackbar } = useSnackbarDialog();
  const [values, setValues] = useState(defaultValue);
  const [errors, setErrors] = useState({});

  const [createProvider, { loading }] = useMutation(GQL_PROVIDER.CREATE_PROVIDER, {
    update(cache, { data: response }) {
      try {
        const dataResponse = response.createProvider;
        const oldQuery = cache.readQuery({
          query: GQL_PROVIDER.GET_PROVIDERS,
          variables: { limit: 0 },
        });
        cache.writeQuery({
          query: GQL_PROVIDER.GET_PROVIDERS,
          variables: { limit: 0 },
          data: {
            customers: {
              ...oldQuery.providers,
              count: oldQuery.providers.count + 1,
              rows: [dataResponse, ...oldQuery.providers.rows],
            },
          },
        });
      } catch (e) {
        console.log(e.message);
      }
    },
  });

  const handleClose = response => {
    props.handleClose(response);
  };

  const handleAction = async () => {
    try {
      if (!values.name || values.name.trim() === '') {
        const e = new Error('Proporciona el nombre del proveedor.');
        e.name = 'name';
        throw e;
      }
      const response = await createProvider({ variables: { name: values.name.trim() } });

      handleClose(response);
    } catch (e) {
      if (['name'].includes(e.name)) {
        setErrors({ [e.name]: e.message });
        return;
      }
      const parseErrors = parseError(e);
      parseErrors.forEach(el => {
        if (el.name === 'BAD_USER_INPUT') setErrors(el.message);
        else
          setSnackbar({
            variables: {
              isOpen: true,
              time: 3000,
              label: el.message,
              severity: 'error',
            },
          });
      });
    }
  };

  const handleChange = e => {
    const { name } = e.target;
    const { value } = e.target;
    setValues(values => ({ ...values, [name]: value }));
  };

  return (
    <FormDialog
      title='Nuevo Proveedor'
      isOpen={props.isOpen || false}
      isLoading={loading}
      handleClose={handleClose}
      handleAction={handleAction}
    >
      <TextField
        name='name'
        label='Nombre'
        onChange={handleChange}
        value={values.name}
        error={errors.name}
        autoFocus
      />
    </FormDialog>
  );
};

export default Customer;
