import React from 'react';
import { useReactiveVar } from '@apollo/client';
import DialogComponent from '../../../presentationals/Dialog';
import { inputError, isCreateProductPriceOpen, productPromotionPriceForm, } from '../../../apollo/cache';
import BodyProductPromotionPriceComponent from './Body';
import FooterDialogComponent from '../../../presentationals/Dialog/Footer';
import CreateProductPriceButtonComponent from './CreateButton';
import getDefaultProductPromotionPriceValues from './Body/helpers/getDefaultValues';
var CreateProductPromotionPriceComponent = function () {
    var isOpen = useReactiveVar(isCreateProductPriceOpen);
    var handleClose = function () {
        var defaultValues = getDefaultProductPromotionPriceValues();
        productPromotionPriceForm(defaultValues);
        inputError({});
        isCreateProductPriceOpen(false);
    };
    return (React.createElement(DialogComponent, { fullScreen: true, open: isOpen, title: 'Nueva tarifa', onClose: handleClose, "aria-labelledby": 'create-product-promotion-price', "aria-describedby": 'create-product-promotion-price-and-more' },
        React.createElement("div", { className: 'flex flex-1 flex-col overflow-hidden' },
            React.createElement(BodyProductPromotionPriceComponent, null)),
        React.createElement(FooterDialogComponent, null,
            React.createElement("div", null),
            React.createElement(CreateProductPriceButtonComponent, null))));
};
export default CreateProductPromotionPriceComponent;
