var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useEffect, useState } from 'react';
import { useReactiveVar } from '@apollo/client';
import { useParams } from 'react-router-dom';
import SubtitleDialogComponent from '../../../../presentationals/Dialog/helpers/Subtitle';
import TextFieldComponent from '../../../../presentationals/FormComponents/TextField';
import { inputError } from '../../../../apollo/cache';
import FooterDialogComponent from '../../../../presentationals/Dialog/Footer';
import EditButtonGeneralEditBranchComponent from './EditButton';
import TaxRegimeSelectFieldHelpersBodyCreateFormCustomerActionSaleV2Component from '../../../sale.v2/Action/Customer/Form/Create/Body/helpers/TaxRegimeSelectField';
import useApolloLazyQuery from '../../../../hooks/useApolloLazyQuery';
import GQL_BRANCH from '../../../../apollo/gql/branch';
import LoadingProgress from '../../../../presentationals/LoadingProgress';
import ErrorDataComponent from '../../../../presentationals/ErrorData';
import { getDefaultBranchBillingInformationEditValue } from '../../helpers/getDefaultBranchValues';
var BillingInformationEditBranchComponent = function (props) {
    var _a;
    var params = useParams();
    var errors = useReactiveVar(inputError);
    var defaultValues = getDefaultBranchBillingInformationEditValue();
    var _b = useState(defaultValues), values = _b[0], setValues = _b[1];
    var _c = useApolloLazyQuery(GQL_BRANCH.GET_BRANCH_BY_ID), getById = _c[0], _d = _c[1], loading = _d.loading, data = _d.data;
    useEffect(function () {
        if (params.branchId && !props.defaultValues)
            getById({ variables: { id: params.branchId } });
    }, [params, props.defaultValues]);
    useEffect(function () {
        if (props.defaultValues)
            setValues(getDefaultBranchBillingInformationEditValue(props.defaultValues));
    }, [props.defaultValues]);
    useEffect(function () {
        if (data)
            setValues(getDefaultBranchBillingInformationEditValue(data));
    }, [data]);
    var handleChange = function (name, value) {
        setValues(function (values) {
            var _a;
            return (__assign(__assign({}, values), (_a = {}, _a[name] = value, _a)));
        });
    };
    var handleGetValues = function () { return values; };
    if (loading)
        return React.createElement(LoadingProgress, null);
    if (!data && !props.defaultValues)
        return React.createElement(ErrorDataComponent, null);
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: 'flex-1 px-6 py-4 flex flex-col md:grid md:grid-cols-4 md:gap-4 overflow-auto' },
            React.createElement("div", { className: 'mb-4 md:mb-0' },
                React.createElement(SubtitleDialogComponent, { label: 'Datos fiscales' }),
                React.createElement(TextFieldComponent, { label: 'Raz\u00F3n social', name: 'businessName', value: values.businessName || '', error: errors.businessName, onChange: handleChange }),
                React.createElement(TextFieldComponent, { label: 'RFC', name: 'identifier', value: values.identifier || '', error: errors.identifier, onChange: handleChange }),
                React.createElement(TaxRegimeSelectFieldHelpersBodyCreateFormCustomerActionSaleV2Component, { value: values.taxRegime || '', onChange: handleChange }))),
        React.createElement(FooterDialogComponent, null,
            React.createElement("div", null),
            React.createElement(EditButtonGeneralEditBranchComponent, { branchId: (_a = props.defaultValues) === null || _a === void 0 ? void 0 : _a.id, getValues: handleGetValues, onUpdated: props.onClose }))));
};
export default BillingInformationEditBranchComponent;
