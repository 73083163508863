var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useEffect, useState } from 'react';
import { useReactiveVar } from '@apollo/client';
import { Button } from '@mui/material';
import { searchBillingInformationField } from '../../../../../../../../../../apollo/cache';
import TextField from '../../../../../../../../../../presentationals/FormComponents/TextField';
import SelectField from '../../../../../../../../../../presentationals/FormComponents/SelectField';
import useSnackbarDialog from '../../../../../../../../../../store/snackbar/snackbarDialog';
var filterOptions = {
    identifier: 'RFC',
    businessName: 'Razón social',
};
export var defaultSearchBillingInformationField = {
    value: '',
    key: 'identifier',
};
var FormSearchDialogChooseBillingInformationBodyCreateFormCustomerComponent = function () {
    var setSnackbar = useSnackbarDialog().setSnackbar;
    var searchBillingInformationFieldVar = useReactiveVar(searchBillingInformationField);
    var _a = useState(defaultSearchBillingInformationField), value = _a[0], setValue = _a[1];
    useEffect(function () {
        setValue(searchBillingInformationFieldVar);
        return function () {
            searchBillingInformationField(defaultSearchBillingInformationField);
        };
    }, []);
    var handleChange = function (name, value) {
        setValue(function (values) {
            var _a;
            return (__assign(__assign({}, values), (_a = {}, _a[name] = value, _a)));
        });
    };
    var handleClick = function () {
        try {
            searchBillingInformationField(value);
        }
        catch (e) {
            setSnackbar({ isOpen: true, label: e.message, severity: 'error' });
        }
    };
    var handleKeyDown = function (e) {
        if (e.keyCode === 13) {
            e.preventDefault();
            handleClick();
        }
    };
    return (React.createElement("div", { className: 'flex items-center px-5 pt-3 pb-1' },
        React.createElement("div", { className: 'flex pr-2' },
            React.createElement(SelectField, { defaultLabel: 'Filtro', name: 'key', value: value.key, onChange: handleChange, options: Object.keys(filterOptions).map(function (key) { return ({
                    label: filterOptions[key],
                    value: key,
                }); }) })),
        React.createElement("div", { className: 'flex flex-1 pr-2' },
            React.createElement(TextField, { autoFocus: true, name: 'value', defaultLabel: "B\u00FAsqueda por ".concat(filterOptions[value.key]), value: value.value, onChange: handleChange, onKeyDown: handleKeyDown })),
        React.createElement("div", { className: 'mb-1' },
            React.createElement(Button, { color: 'secondary', variant: 'contained', onClick: handleClick }, "Buscar"))));
};
export default FormSearchDialogChooseBillingInformationBodyCreateFormCustomerComponent;
