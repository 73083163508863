import { Card, Skeleton } from '@mui/material';
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useReactiveVar } from '@apollo/client';
import ItemCashClossinSummaryIncomeReportComponent from '../../../cashClosing/Item/Detail/Item';
import getCashClosingDatesLabel from '../../../cashClosing/Item/Detail/Balance/helpers/getCashClosingDatesLabel';
import useDateRangeFieldStore from '../../../../store/filterInputs/dateRangeFieldStore';
import useApolloQuery from '../../../../hooks/useApolloQuery';
import GQL_BRANCH from '../../../../apollo/gql/branch';
import useApolloLazyQuery from '../../../../hooks/useApolloLazyQuery';
import GQL_SALE from '../../../../apollo/gql/sale';
import { filter } from '../../../../apollo/cache';
var BalanceDetailedReportComponent = function () {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m;
    var params = useParams();
    var filterVar = useReactiveVar(filter);
    var dates = useDateRangeFieldStore(function (state) { return state.value; });
    var data = useApolloQuery(GQL_BRANCH.GET_BRANCH_BY_ID, {
        variables: { id: params.branchId },
    }).data;
    var _o = useApolloLazyQuery(GQL_SALE.GET_GENERAL_SALE_REPORT), getSales = _o[0], generalInfo = _o[1].data;
    useEffect(function () {
        if (filterVar.start && filterVar.end)
            getSales({
                variables: {
                    start: filterVar.start,
                    end: filterVar.end,
                    branchId: filterVar.branchId,
                    commission: filterVar.commission,
                    shipping: filterVar.shipping,
                },
            });
    }, [filterVar]);
    return (React.createElement(Card, { className: 'flex flex-col overflow-hidden p-3 bg-slate-700' },
        React.createElement("div", { className: 'flex text-white leading-none font-semibold' }, "".concat(data === null || data === void 0 ? void 0 : data.Company.name, ", ").concat(data === null || data === void 0 ? void 0 : data.name)),
        data ? (React.createElement("div", { className: 'flex text-white text-xs leading-6' }, "DEL ".concat(getCashClosingDatesLabel(dates[0], dates[1])))) : (React.createElement(Skeleton, { variant: 'text', className: 'text-xs bg-gray-300 leading-6 w-1/2' })),
        React.createElement("div", { className: 'flex flex-1 overflow-x-auto overflow-y-hidden' },
            React.createElement(ItemCashClossinSummaryIncomeReportComponent, { label: 'Ingresos', total: generalInfo === null || generalInfo === void 0 ? void 0 : generalInfo.TotalSales.transaction }),
            React.createElement(ItemCashClossinSummaryIncomeReportComponent, { label: 'Ventas', total: (_b = (_a = generalInfo === null || generalInfo === void 0 ? void 0 : generalInfo.TotalSales) === null || _a === void 0 ? void 0 : _a.Total) === null || _b === void 0 ? void 0 : _b.total, qty: (_d = (_c = generalInfo === null || generalInfo === void 0 ? void 0 : generalInfo.TotalSales) === null || _c === void 0 ? void 0 : _c.Total) === null || _d === void 0 ? void 0 : _d.count }),
            React.createElement(ItemCashClossinSummaryIncomeReportComponent, { label: 'Cancelaciones', total: (_f = (_e = generalInfo === null || generalInfo === void 0 ? void 0 : generalInfo.TotalSales) === null || _e === void 0 ? void 0 : _e.Cancelled) === null || _f === void 0 ? void 0 : _f.total, qty: (_h = (_g = generalInfo === null || generalInfo === void 0 ? void 0 : generalInfo.TotalSales) === null || _g === void 0 ? void 0 : _g.Cancelled) === null || _h === void 0 ? void 0 : _h.count }),
            React.createElement(ItemCashClossinSummaryIncomeReportComponent, { label: 'Descuentos', total: (_k = (_j = generalInfo === null || generalInfo === void 0 ? void 0 : generalInfo.TotalSales) === null || _j === void 0 ? void 0 : _j.Discount) === null || _k === void 0 ? void 0 : _k.total, qty: (_m = (_l = generalInfo === null || generalInfo === void 0 ? void 0 : generalInfo.TotalSales) === null || _l === void 0 ? void 0 : _l.Discount) === null || _m === void 0 ? void 0 : _m.count }))));
};
export default BalanceDetailedReportComponent;
