var getSaleTotalsFromData = function (data) {
    return data.reduce(function (totals, el) {
        return {
            commission: el.commission
                ? totals.commission + parseFloat(el.commission)
                : totals.commission,
            shipping: el.shipping ? totals.shipping + parseFloat(el.shipping) : totals.shipping,
            subtotal: el.subtotal ? totals.subtotal + parseFloat(el.subtotal) : totals.subtotal,
            taxes: el.taxes ? totals.taxes + parseFloat(el.taxes) : totals.taxes,
            discount: el.discount ? totals.discount + parseFloat(el.discount) : totals.discount,
            total: el.total ? totals.total + parseFloat(el.total) : totals.total,
        };
    }, {
        commission: 0,
        shipping: 0,
        subtotal: 0,
        taxes: 0,
        discount: 0,
        total: 0,
    });
};
export default getSaleTotalsFromData;
