var getDefaultCompanyValues = function () { return ({
    name: '',
    branchName: '',
    activity: 1,
    phone: '',
    addressLine1: '',
    addressLine2: '',
    city: '',
    municipality: '',
    state: '',
    zipCode: '',
    country: 1,
    logo: null,
}); };
export default getDefaultCompanyValues;
