var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useEffect, useState } from 'react';
import { useReactiveVar } from '@apollo/client';
import TextFieldComponent from '../../../presentationals/FormComponents/TextField';
import { inputError } from '../../../apollo/cache';
import FooterDialogComponent from '../../../presentationals/Dialog/Footer';
// import CreateOnlineInvoiceButtonComponent from './CreateButton';
// import DocOnlineInvoiceComponent from '../Doc';
import SearchOnlineInvoiceButtonComponent from './SearchButton';
import CustomerOnlineInvoiceForm from './CustomerForm';
import useQueryParams from '../../../hooks/useQueryParams';
var CreateOnlineInvoiceComponent = function () {
    var query = useQueryParams();
    var errors = useReactiveVar(inputError);
    var _a = useState(undefined), saleId = _a[0], setSaleId = _a[1];
    var _b = useState(undefined), customerBillingInformation = _b[0], setCustomerBillingInformation = _b[1];
    var defaultValues = {
        code: '',
        total: '',
        rfc: '',
    };
    var _c = useState(defaultValues), values = _c[0], setValues = _c[1];
    useEffect(function () {
        if (query.get('total') && query.get('code'))
            setValues(function (values) { return (__assign(__assign({}, values), { total: "".concat(query.get('total')), code: "".concat(query.get('code')) })); });
    }, []);
    var handleChange = function (name, value) {
        setValues(function (values) {
            var _a;
            return (__assign(__assign({}, values), (_a = {}, _a[name] = value, _a)));
        });
    };
    var handleSearched = function (data) {
        setSaleId(data === null || data === void 0 ? void 0 : data.saleId);
        setCustomerBillingInformation(data === null || data === void 0 ? void 0 : data.CustomerBillingInformation);
    };
    if (saleId)
        return (React.createElement(CustomerOnlineInvoiceForm, { saleId: saleId, CustomerBillingInformation: customerBillingInformation }));
    return (React.createElement("div", { className: 'flex flex-1 flex-col' },
        React.createElement("div", { className: 'px-4 py-2 flex flex-1 flex-col overflow-y-auto basis-0' },
            React.createElement("div", { className: 'text-lg' }, "Facturaci\u00F3n electr\u00F3nica"),
            React.createElement("div", { className: 'text-base text-gray-600' }, "Genera una factura electr\u00F3nica v\u00E1lida ante el Sistema de Administraci\u00F3n Tributaria (SAT) de M\u00E9xico. Para emitir el documento necesitas el c\u00F3digo de facturaci\u00F3n y el importe neto de la compra."),
            React.createElement("div", { className: 'flex flex-1 sm:flex-col' },
                React.createElement("div", { className: 'flex flex-col w-full sm:w-1/4' },
                    React.createElement(TextFieldComponent, { label: 'C\u00F3digo de facturaci\u00F3n', name: 'code', value: values.code, onChange: handleChange, error: errors.code }),
                    React.createElement(TextFieldComponent, { label: 'Importe total', name: 'total', value: values.total, onChange: handleChange, error: errors.total, type: 'number' }),
                    React.createElement(TextFieldComponent, { label: 'RFC', name: 'rfc', value: values.rfc, onChange: handleChange, error: errors.rfc })))),
        React.createElement(FooterDialogComponent, null,
            React.createElement("div", null),
            React.createElement(SearchOnlineInvoiceButtonComponent, { values: values, onSearched: handleSearched }))));
};
export default CreateOnlineInvoiceComponent;
