import React, { useState, useEffect } from 'react';
import { useQuery } from '@apollo/client';

import Grid from '@mui/material/Grid';

import LinearForm from '../../../../presentationals/LinearForm';
import Autocomplete from '../../../../presentationals/Autocomplete';
import TextField from '../../../../presentationals/TextField';

import GQL_PRODUCT from '../../../product/_gql';

const defaultValues = {
  quantity: 1,
  productPresentation: null,
  productId: null,
};

const Form = props => {
  const [values, setValues] = useState(defaultValues);
  const [products, setProducts] = useState([]);
  const [productPresentations, setProductPresentations] = useState([]);
  const [errors, setErrors] = useState({});

  const { data: productsData } = useQuery(GQL_PRODUCT.GET_NAME_AND_PRESENTATIONS, {
    variables: { limit: 0, includeBaseInPresentations: true },
  });

  useEffect(() => {
    if (productsData && productsData.productsV2) {
      const tmpProducts = productsData.productsV2.rows.map(el => ({
        label: `${String(el.code).padStart(3, '0')} ${el.name.trim()} ${
          el.sku ? ` | SKU ${el.sku.trim()}` : ''
        } ${el.barcode ? ` | C.B. ${el.barcode.trim()}` : ''}`.trim(),
        value: el.id,
        ProductPresentations: el.ProductPresentations,
      }));
      setProducts(tmpProducts);
    }
  }, [productsData]);

  useEffect(() => {
    setValues(defaultValues);
  }, [props.codeToClear]);

  const handleAutocompleteChangeProduct = (e, name, value) => {
    const tmp =
      value && value.ProductPresentations
        ? value.ProductPresentations.map(el => ({ value: el.id, label: el.name, cost: el.cost }))
        : [];
    setProductPresentations(tmp);
    setValues(values => ({ ...values, [name]: value, productPresentation: null }));
  };

  const handleAutocompleteChange = (e, name, value) => {
    setValues(values => ({ ...values, [name]: value }));
  };

  const handleChange = e => {
    const { name, value } = e.target;
    setValues(values => ({ ...values, [name]: value }));
  };

  const handleAddProduct = () => {
    try {
      if (!values.productId) {
        const e = new Error('Selecciona un producto.');
        e.name = 'productId';
        throw e;
      }
      if (!values.productPresentation) {
        const e = new Error('Selecciona una unidad de medida.');
        e.name = 'productPresentation';
        throw e;
      }

      if (parseFloat(values.quantity) <= 0 || Number.isNaN(Number(values.quantity))) {
        const e = new Error('Proporciona la cantidad de producto.');
        e.name = 'quantity';
        throw e;
      }
      props.handleAdd({
        product: values.productId,
        productPresentation: values.productPresentation,
        quantity: parseFloat(values.quantity),
      });
      setValues(defaultValues);
      setProductPresentations([]);
      setErrors({});
    } catch (e) {
      console.log(e);
      setErrors({ [e.name]: e.message });
    }
  };

  return (
    <LinearForm handleAdd={handleAddProduct} align='center'>
      <Grid container spacing={1}>
        <Grid item xs={12} md={6}>
          <Autocomplete
            name='productId'
            label='Producto'
            variant='outlined'
            onChange={handleAutocompleteChangeProduct}
            options={products}
            value={values.productId}
            error={errors.productId}
            noFormat
          />
        </Grid>
        <Grid item xs={8} md={4}>
          <Autocomplete
            name='productPresentation'
            label='Presentación'
            variant='outlined'
            onChange={handleAutocompleteChange}
            options={productPresentations}
            value={values.productPresentation}
            error={errors.productPresentation}
            noFormat
          />
        </Grid>
        <Grid item xs={4} md={2}>
          <TextField
            name='quantity'
            label='Cant.'
            type='number'
            variant='outlined'
            onChange={handleChange}
            value={values.quantity}
            error={errors.quantity}
            noFormat
          />
        </Grid>
      </Grid>
    </LinearForm>
  );
};

export default Form;
