import React, { useRef } from 'react';
import DestinationBranchLabelComponent from './BranchLabel';
import DestinationBranchMenuComponent from './Menu';
var DestinationBranchButtonComponent = function () {
    var destinationBranchMenuRef = useRef(null);
    var handleClick = function (e) {
        var _a;
        (_a = destinationBranchMenuRef.current) === null || _a === void 0 ? void 0 : _a.open(e.currentTarget);
    };
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { role: 'button', onClick: handleClick, tabIndex: 0, className: 'flex flex-col hover:rounded-lg hover:bg-slate-200 px-2 py-1 cursor-pointer' },
            React.createElement("div", { className: 'text-xs tracking-tighter leading-none text-gray-500' }, "Destino"),
            React.createElement(DestinationBranchLabelComponent, null)),
        React.createElement(DestinationBranchMenuComponent, { ref: destinationBranchMenuRef })));
};
export default DestinationBranchButtonComponent;
