import React, { useEffect } from 'react';
import { useReactiveVar } from '@apollo/client';
import DialogComponent from '../../../presentationals/Dialog';
import { inputError, isProductPricePromotionOpen, productPromotionPriceForm, } from '../../../apollo/cache';
import BodyProductPromotionPriceComponent from '../CreateProductPromotionPrice/Body';
import FooterDialogComponent from '../../../presentationals/Dialog/Footer';
import EditProductPriceButtonComponent from './EditButton';
import useApolloLazyQuery from '../../../hooks/useApolloLazyQuery';
import GQL_PROMOTION from '../../../apollo/gql/promotion';
import LoadingProgress from '../../../presentationals/LoadingProgress';
import DeleteProductPriceButtonComponent from './DeleteButton';
import getDefaultProductPromotionPriceValues from '../CreateProductPromotionPrice/Body/helpers/getDefaultValues';
var EditProductPromotionPriceComponent = function () {
    var isOpen = useReactiveVar(isProductPricePromotionOpen);
    var _a = useApolloLazyQuery(GQL_PROMOTION.GET_PRODUCT_PROMOTION_PRICE_BY_ID), getById = _a[0], _b = _a[1], loading = _b.loading, data = _b.data;
    useEffect(function () {
        if (isOpen)
            getById({ variables: { id: isOpen } });
    }, [isOpen]);
    useEffect(function () {
        if (data && isOpen)
            productPromotionPriceForm({
                price: data.price,
                type: data.type || -1,
                branchId: data.branchId || -1,
            });
    }, [data, isOpen]);
    var handleClose = function () {
        var defaultValues = getDefaultProductPromotionPriceValues();
        productPromotionPriceForm(defaultValues);
        inputError({});
        isProductPricePromotionOpen(false);
    };
    return (React.createElement(DialogComponent, { fullScreen: true, open: !!isOpen, title: 'Editar tarifa', onClose: handleClose, "aria-labelledby": 'edit-product-promotion-price', "aria-describedby": 'edit-product-promotion-price-and-more' }, loading || !data ? (React.createElement(LoadingProgress, null)) : (React.createElement(React.Fragment, null,
        React.createElement(BodyProductPromotionPriceComponent, null),
        React.createElement(FooterDialogComponent, null,
            React.createElement(DeleteProductPriceButtonComponent, null),
            React.createElement(EditProductPriceButtonComponent, null))))));
};
export default EditProductPromotionPriceComponent;
