import React, { forwardRef, useImperativeHandle, useState } from 'react';
import DialogComponent from '../../../../presentationals/Dialog';
import CreateCashFlowComponent from '..';
var CreateCashFlowDialogComponent = forwardRef(function (props, ref) {
    var _a = useState(false), open = _a[0], setOpen = _a[1];
    var handleClose = function () {
        setOpen(false);
    };
    var handleOpen = function () {
        setOpen(true);
    };
    useImperativeHandle(ref, function () { return ({
        open: handleOpen,
    }); });
    return (React.createElement(DialogComponent, { title: 'Nuevo movimiento de caja', fullScreen: true, open: open, onClose: handleClose },
        React.createElement(CreateCashFlowComponent, { noRedirect: true, onCreated: handleClose })));
});
export default CreateCashFlowDialogComponent;
