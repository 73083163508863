import React from 'react';

import EventIcon from '@mui/icons-material/EventOutlined';

import { filterForm } from '../../../../apollo/cache';

import IconButton from '../../../IconButton';

const FilterButtonComponent = () => {
  const handleFilter = () => {
    filterForm(true);
  };
  return <IconButton label='Filtrar' action={handleFilter} icon={<EventIcon />} size='large' />;
};

export default FilterButtonComponent;
