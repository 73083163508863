var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { useEffect, useState } from 'react';
import { Button } from '@mui/material';
import { branchSaleV2, cartItems, currencySaleV2, discountTypeSaleV2, formatSaleV2, isItemDetailDialogOpen, totalsSaleV2, } from '../../../../../../../apollo/cache';
import TextField from '../../../../../../../presentationals/FormComponents/TextField';
import useApolloMutation from '../../../../../../../hooks/useApolloMutation';
import GQL from '../../../../../ChooseProduct/AddButton/_gql';
import getTotalizedCartProducts from '../../../../../helpers/getTotalizedCartProducts';
import EmployeeIdAutocompletedHelpersBodyCreateFormCustomerActionSaleV2Component from '../../../../../Action/Customer/Form/Create/Body/helpers/EmployeeIdAutocomplete';
import getInputErrorFormat from '../../../../../Action/helpers/getInputErrorFormat';
var CartFormBodyItemDetailDialogCartSaleV2Component = function (props) {
    var defaultValues = {
        index: null,
        quantity: '',
        unitPrice: '',
        discount: '',
        commentary: '',
        employeeId: '',
    };
    var discountTypeSaleV2Var = discountTypeSaleV2();
    var _a = useState(defaultValues), values = _a[0], setValues = _a[1];
    var _b = useApolloMutation(GQL.CHECK_CART), checkCart = _b[0], loading = _b[1].loading;
    useEffect(function () {
        if (props)
            setValues({
                index: props.index,
                quantity: props.quantity.toString(),
                unitPrice: props.unitPrice ? props.unitPrice.toString() : '0',
                discount: props.discount ? props.discount.toString() : '0',
                commentary: props.commentary.toString(),
                employeeId: props.employeeId ? props.employeeId.toString() : '',
            });
    }, [props]);
    var handleChange = function (name, value) {
        setValues(function (values) {
            var _a;
            return (__assign(__assign({}, values), (_a = {}, _a[name] = value, _a)));
        });
    };
    var handleEmployeeChange = function (name, value) {
        setValues(function (values) {
            var _a;
            return (__assign(__assign({}, values), (_a = {}, _a[name] = value ? value.value : undefined, _a)));
        });
    };
    var handleClick = function () { return __awaiter(void 0, void 0, void 0, function () {
        var formatSaleV2Var, currencySaleV2Var, branchSaleV2Var, cartItemsVar, index_1, newCart, data, cart, e_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    formatSaleV2Var = formatSaleV2();
                    currencySaleV2Var = currencySaleV2();
                    branchSaleV2Var = branchSaleV2();
                    cartItemsVar = cartItems();
                    index_1 = isItemDetailDialogOpen();
                    newCart = cartItemsVar.map(function (_a, i) {
                        var stock = _a.stock, unitPrice = _a.unitPrice, taxes = _a.taxes, el = __rest(_a, ["stock", "unitPrice", "taxes"]);
                        return {
                            quantity: i === index_1
                                ? parseFloat(values.quantity)
                                : el.quantity
                                    ? parseFloat(el.quantity.toString())
                                    : 0,
                            price: i === index_1 ? parseFloat(values.unitPrice) : unitPrice || 0,
                            cost: el.cost ? parseFloat(el.cost.toString()) : 0,
                            discount: i === index_1
                                ? values.discount
                                    ? parseFloat(values.discount.toString())
                                    : 0
                                : parseInt(discountTypeSaleV2Var.toString()) === 1
                                    ? el.discountRate
                                        ? parseFloat(el.discountRate.toString())
                                        : 0
                                    : parseFloat(el.discount ? el.discount.toString() : '0'),
                            commentary: i === index_1 ? values.commentary : el.commentary,
                            employeeId: i === index_1 ? (values.employeeId ? values.employeeId : undefined) : el.employeeId,
                            productId: el.productId,
                        };
                    });
                    return [4 /*yield*/, checkCart({
                            variables: {
                                isInvoice: formatSaleV2Var[2],
                                taxesOption: formatSaleV2Var[1],
                                discountType: parseInt(discountTypeSaleV2Var.toString()),
                                shipping: 0,
                                branchId: parseInt(branchSaleV2Var.branchInventory.toString()),
                                currencyKey: parseFloat(currencySaleV2Var[0].toString()),
                                exchangeRate: parseFloat(currencySaleV2Var[1].toString()),
                                category: parseInt(formatSaleV2Var[0].toString()),
                                Products: newCart,
                                Transactions: [],
                                showTaxes: formatSaleV2Var[3],
                            },
                        })];
                case 1:
                    data = (_a.sent()).data;
                    cart = getTotalizedCartProducts(data.totalizeCart.Products, cartItemsVar, {
                        taxesOption: formatSaleV2Var[1],
                        isInvoice: formatSaleV2Var[2],
                        showTaxes: formatSaleV2Var[3],
                    });
                    totalsSaleV2([
                        data.totalizeCart.subtotal,
                        data.totalizeCart.discount,
                        data.totalizeCart.taxes,
                        data.totalizeCart.total,
                    ]);
                    cartItems(cart);
                    isItemDetailDialogOpen(null);
                    return [3 /*break*/, 3];
                case 2:
                    e_1 = _a.sent();
                    getInputErrorFormat(e_1);
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    var handleKeyPress = function (e) { return e.key === 'Enter' && handleClick(); };
    return (React.createElement(React.Fragment, null,
        React.createElement(TextField, { name: 'quantity', label: 'Cantidad', onKeyDown: handleKeyPress, value: values.quantity, onChange: handleChange }),
        React.createElement(TextField, { name: 'unitPrice', label: 'Precio unitario', value: values.unitPrice, onKeyDown: handleKeyPress, onChange: handleChange }),
        React.createElement(TextField, { name: 'discount', label: "Descuento ".concat(discountTypeSaleV2Var === 1 ? '%' : '$', "}"), value: values.discount, onKeyDown: handleKeyPress, onChange: handleChange }),
        React.createElement(TextField, { name: 'commentary', label: 'Comentarios para el documento', value: values.commentary, onChange: handleChange, onKeyDown: handleKeyPress, multiline: true, rows: 2 }),
        React.createElement(EmployeeIdAutocompletedHelpersBodyCreateFormCustomerActionSaleV2Component, { value: values.employeeId, handleChange: handleEmployeeChange }),
        React.createElement(Button, { disabled: loading, variant: 'contained', color: 'primary', className: 'mt-2', onClick: handleClick }, "Actualizar informaci\u00F3n del carrito")));
};
export default CartFormBodyItemDetailDialogCartSaleV2Component;
