import React, { useState, useEffect } from 'react';

import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import { currencyFormat } from '../../../../helpers/general';

import IconButton from './helpers/iconButton';

const defaultValues = {
  subtotal: 0,
  iva: 0,
  ieps: 0,
  total: 0,
};

const TableComponent = props => {
  const [values, setValues] = useState(defaultValues);

  useEffect(() => {
    setValues(defaultValues);
  }, [props.codeToClear]);

  useEffect(() => {
    const tmp = props.cartItems.reduce((object, el) => {
      const tmpIva = parseFloat(el.subtotal) * (parseFloat(el.iva) / 100);
      const tmpIeps = parseFloat(el.subtotal) * (parseFloat(el.ieps) / 100);
      return {
        subtotal: parseFloat(object.subtotal) + parseFloat(el.subtotal),
        iva: parseFloat(object.iva) + tmpIva,
        ieps: parseFloat(object.ieps) + tmpIeps,
        total: parseFloat(object.total) + parseFloat(el.total),
      };
    }, defaultValues);
    setValues(tmp);
  }, [props.cartItems]);

  const handleDeleteItem = (e, position) => {
    props.removeItem(position);
  };

  return (
    <TableContainer>
        <Table size='small'>
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell>Producto</TableCell>
              <TableCell>Unidad</TableCell>
              <TableCell align='center'>Cant.</TableCell>
              <TableCell align='center'>Existencia</TableCell>
              <TableCell align='right'>C. Unitario</TableCell>
              <TableCell align='center'>IVA</TableCell>
              <TableCell align='center'>IEPS</TableCell>
              <TableCell align='right'>Importe</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.cartItems.map((el, i) => (
              <TableRow key={i}>
                <TableCell>
                  <IconButton
                    label='Eliminar'
                    icon={<DeleteOutlineIcon />}
                    params={i}
                    action={handleDeleteItem}
                    size='small'
                  />
                </TableCell>
                <TableCell>{el.product ? el.product.label : el.concept}</TableCell>
                <TableCell>{el.productPresentation ? el.productPresentation.label : '-'}</TableCell>
                <TableCell align='center'>{el.quantity}</TableCell>
                <TableCell align='center'>{el.inventory}</TableCell>
                <TableCell align='right'>{currencyFormat(el.cost)}</TableCell>
                <TableCell align='center'>{`${el.iva}%`}</TableCell>
                <TableCell align='center'>{`${el.ieps}%`}</TableCell>
                <TableCell align='right'>{currencyFormat(el.total)}</TableCell>
              </TableRow>
            ))}
            <TableRow>
              <TableCell colSpan={8} align='right'>
                Subtotal:
              </TableCell>
              <TableCell align='right'>{currencyFormat(values.subtotal)}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell colSpan={8} align='right'>
                IVA:
              </TableCell>
              <TableCell align='right'>{currencyFormat(values.iva)}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell colSpan={8} align='right'>
                IEPS:
              </TableCell>
              <TableCell align='right'>{currencyFormat(values.ieps)}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell colSpan={8} align='right'>
                <strong>Total:</strong>
              </TableCell>
              <TableCell align='right'>
                <strong>{currencyFormat(values.total)}</strong>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
  );
};

export default TableComponent;
