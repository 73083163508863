import React, { useEffect, useState } from 'react';

import { useReactiveVar, useQuery, useMutation } from '@apollo/client';

import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';

import TextField from '../../presentationals/TextField';
import Autocomplete from '../../presentationals/Autocomplete';

import { parseError } from '../../helpers';
import { actionForm } from '../../apollo/cache';

import FormDialog from '../../presentationals/FormDialog';

import GQL_PRODUCT from '../product/_gql';
import GQL from './_gql';

import Cart from './cart';
import useSnackbarDialog from '../../store/snackbar/snackbarDialog';

const defaultValues = {
  quantity: 1,
  productPresentation: null,
  productId: null,
  Products: [],
};

const CreateProductionFormComponent = () => {
  const { setSnackbar } = useSnackbarDialog();
  const [values, setValues] = useState(defaultValues);
  const [products, setProducts] = useState([]);
  const [productPresentations, setProductPresentations] = useState([]);
  const [errors, setErrors] = useState({});
  const [codeToClear, setCodeToClear] = useState(null);

  const { data: productsData } = useQuery(GQL_PRODUCT.GET_NAME_AND_PRESENTATIONS, {
    variables: { limit: 0, includeBaseInPresentations: true },
  });
  const [createTransformation, { loading }] = useMutation(GQL.CREATE);

  useEffect(() => {
    if (productsData && productsData.productsV2) {
      const tmpProducts = productsData.productsV2.rows.map(el => ({
        label: `${String(el.code).padStart(3, '0')} ${el.name.trim()} ${
          el.sku ? ` | SKU ${el.sku.trim()}` : ''
        } ${el.barcode ? ` | C.B. ${el.barcode.trim()}` : ''}`.trim(),
        value: el.id,
        ProductPresentations: el.ProductPresentations,
      }));
      setProducts(tmpProducts);
    }
  }, [productsData]);

  const actionFormVar = useReactiveVar(actionForm);

  const handleClose = () => {
    actionForm({ ...actionForm(), isOpen: false, action: '' });
    setCodeToClear(null);
  };

  const handleAction = async () => {
    try {
      if (!values.productId || !values.productId.value) {
        const e = new Error('Selecciona un producto.');
        e.name = 'productId';
        throw e;
      }
      if (!values.productPresentation || !values.productPresentation.value) {
        const e = new Error('Selecciona una presentación.');
        e.name = 'productPresentation';
        throw e;
      }
      if (
        !values.quantity ||
        Number.isNaN(parseFloat(values.quantity)) ||
        parseFloat(values.quantity) <= 0
      ) {
        const e = new Error('La cantidad es inválida.');
        e.name = 'quantity';
        throw e;
      }
      if (!values.Products || values.Products.length <= 0) {
        const e = new Error('Selecciona al menos un producto.');
        e.name = 'GENERAL_ERROR';
        throw e;
      }

      const variables = {
        quantity: parseFloat(values.quantity),
        productPresentationId: values.productPresentation.value,
        productId: values.productId.value,
        Products: values.Products.map(el => ({
          quantity: parseFloat(el.quantity),
          productId: el.product.value,
          productPresentationId: el.productPresentation.value,
        })),
      };

      await createTransformation({ variables });
      setValues(defaultValues);
      setCodeToClear(null);
      setErrors({});
      handleClose();
    } catch (e) {
      console.log(e);
      if (e.name !== 'Error') {
        setErrors({ [e.name]: e.message });
        return;
      }
      if (e.name === 'GENERAL_ERROR')
        setSnackbar({
          isOpen: true,
          time: 3000,
          label: e.message,
          severity: 'error',
        });
      const parseErrors = parseError(e);
      if (parseErrors[0].name === 'GENERAL_ERROR')
        setSnackbar({
          isOpen: true,
          time: 3000,
          label: parseErrors[0].message,
          severity: 'error',
        });
    }
  };

  const getCart = data => {
    setValues(values => ({ ...values, Products: data }));
  };

  const handleAutocompleteChangeProduct = (e, name, value) => {
    const tmp =
      value && value.ProductPresentations
        ? value.ProductPresentations.map(el => ({ value: el.id, label: el.name }))
        : [];
    setProductPresentations(tmp);
    setValues(values => ({ ...values, [name]: value, productPresentation: null }));
  };

  const handleAutocompleteChange = (e, name, value) => {
    setValues(values => ({ ...values, [name]: value }));
  };

  const handleChange = e => {
    const { name, value } = e.target;
    setValues(values => ({ ...values, [name]: value }));
  };

  return (
    <FormDialog
      isOpen={actionFormVar.isOpen && actionFormVar.action === 'create'}
      isLoading={loading}
      handleClose={handleClose}
      title='Nueva Transformación'
      handleAction={handleAction}
      width='md'
    >
      <Grid container justifyContent='flex-end' spacing={1}>
        <Grid item xs={12} md={6}>
          <Autocomplete
            name='productId'
            label='Producto'
            variant='outlined'
            onChange={handleAutocompleteChangeProduct}
            options={products}
            value={values.productId}
            error={errors.productId}
            noFormat
          />
        </Grid>
        <Grid item xs={8} md={4}>
          <Autocomplete
            name='productPresentation'
            label='Presentación'
            variant='outlined'
            onChange={handleAutocompleteChange}
            options={productPresentations}
            value={values.productPresentation}
            error={errors.productPresentation}
            noFormat
          />
        </Grid>
        <Grid item xs={4} md={2}>
          <TextField
            name='quantity'
            label='Cant.'
            type='number'
            variant='outlined'
            onChange={handleChange}
            value={values.quantity}
            error={errors.quantity}
            noFormat
          />
        </Grid>
      </Grid>
      <br />
      <Divider />
      <br />
      <Cart getCart={getCart} codeToClear={codeToClear} products={values.Products} />
    </FormDialog>
  );
};

export default CreateProductionFormComponent;
