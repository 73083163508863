import { useReactiveVar } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import { branchSaleV2 } from '../../../../../../../apollo/cache';
import useApolloLazyQuery from '../../../../../../../hooks/useApolloLazyQuery';
import GQL_BRANCH from '../../../../../../../apollo/gql/branch';
import LoadingProgress from '../../../../../../../presentationals/LoadingProgress';
import ErrorDataComponent from '../../../../../../../presentationals/ErrorData';
import useGetTaxRegime from '../../../../../../../hooks/useGetTaxRegime';
import OpenEditBranchBillingInformationComponent from './EditButton';
var SaleBranchBillingInformationComponent = function () {
    var branchSale = useReactiveVar(branchSaleV2).branchSale;
    var _a = useState(''), taxRegime = _a[0], setTaxRegime = _a[1];
    var _b = useApolloLazyQuery(GQL_BRANCH.GET_BRANCH_BY_ID), getById = _b[0], _c = _b[1], loading = _c.loading, data = _c.data;
    var TaxRegimes = useGetTaxRegime();
    useEffect(function () {
        if (TaxRegimes.length > 0 && (data === null || data === void 0 ? void 0 : data.taxRegime)) {
            var taxRegime_1 = TaxRegimes.find(function (el) { return parseInt(el.value.toString()) === parseInt(data.taxRegime.toString()); });
            if (taxRegime_1)
                setTaxRegime(taxRegime_1.label);
        }
    }, [TaxRegimes, data]);
    useEffect(function () {
        if (branchSale)
            getById({ variables: { id: branchSale } });
    }, [branchSale]);
    if (loading)
        return React.createElement(LoadingProgress, null);
    if (!data)
        return React.createElement(ErrorDataComponent, null);
    return (React.createElement("div", { className: 'flex flex-1 flex-col' },
        React.createElement("div", { className: 'flex' },
            React.createElement("div", { className: 'font-medium text-lg' }, "Datos de facturaci\u00F3n de la sucursal"),
            React.createElement("div", { className: 'ml-2' },
                React.createElement(OpenEditBranchBillingInformationComponent, null))),
        React.createElement("div", { className: 'text-base' },
            React.createElement("b", null, "Raz\u00F3n social: "),
            data.businessName),
        React.createElement("div", { className: 'text-base' },
            React.createElement("b", null, "RFC: "),
            data.identifier),
        React.createElement("div", { className: 'text-base' },
            React.createElement("b", null, "Regimen fiscal: "),
            taxRegime),
        React.createElement("div", { className: 'text-base' },
            React.createElement("b", null, "C\u00F3digo postal: "),
            data.zipCode)));
};
export default SaleBranchBillingInformationComponent;
