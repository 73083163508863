import React, { useEffect, useRef, useState } from 'react';
import { useReactiveVar } from '@apollo/client';
import { customerSaleV2, isSaleCustomerFormOpen, searchField } from '../../../../../apollo/cache';
import DialogComponent from '../../../../../presentationals/Dialog';
import Action from './Action';
import Create from './Create';
import Search from './Search';
import useApolloLazyQuery from '../../../../../hooks/useApolloLazyQuery';
import GQL_CUSTOMER from '../../../../../apollo/gql/customer';
import getDefaultCustomerValues from './Create/Body/helpers/defaultValues';
import useGetSession from '../../../../../hooks/useGetSession';
var FormCustomerActionSaleV2Component = function () {
    var _a;
    var createFormCustomerActionSaleV2Ref = useRef(null);
    var isSaleCustomerFormOpenVar = useReactiveVar(isSaleCustomerFormOpen);
    var customerSaleV2Var = useReactiveVar(customerSaleV2);
    var searchFieldVar = useReactiveVar(searchField);
    var session = useGetSession();
    var customerLabel = ((_a = session === null || session === void 0 ? void 0 : session.Company) === null || _a === void 0 ? void 0 : _a.activity) === 3 ? 'paciente' : 'cliente';
    var _b = useApolloLazyQuery(GQL_CUSTOMER.GET_CUSTOMER_BY_ID), getCustomer = _b[0], data = _b[1].data;
    var _c = useState(0), value = _c[0], setValue = _c[1];
    useEffect(function () {
        if (customerSaleV2Var && customerSaleV2Var.id && !searchFieldVar) {
            setValue(1);
            getCustomer({ variables: { id: customerSaleV2Var.id } });
        }
    }, [customerSaleV2Var]);
    var handleClick = function (e, value) {
        var _a;
        if (value === 2) {
            var defaultCustomerFormValues = getDefaultCustomerValues();
            customerSaleV2(defaultCustomerFormValues);
            setValue(1);
            (_a = createFormCustomerActionSaleV2Ref.current) === null || _a === void 0 ? void 0 : _a.onClear();
            return;
        }
        setValue(value);
    };
    var handleClose = function () {
        isSaleCustomerFormOpen(false);
    };
    return (React.createElement(DialogComponent, { fullScreen: true, disableEscapeKeyDown: true, title: "Configuraci\u00F3n del ".concat(customerLabel), onClose: handleClose, open: isSaleCustomerFormOpenVar },
        React.createElement(Action, { value: value, handleClick: handleClick }),
        value === 0 ? React.createElement(Search, null) : React.createElement("div", null),
        value === 1 ? (React.createElement(Create, { ref: createFormCustomerActionSaleV2Ref, defaultValues: customerSaleV2Var.id ? data : undefined })) : (React.createElement("div", null))));
};
export default FormCustomerActionSaleV2Component;
