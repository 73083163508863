import { useReactiveVar } from '@apollo/client';
import { useEffect, useState } from 'react';
import { filter } from '../../../apollo/cache';
import useGetBranches from '../../../hooks/useGetBranches';
import getBranchTypeById from './getBranchTypeById';
import getFormattedAddressBranch from './getFormattedAddressBranch';
var useGetBranchData = function (props) {
    var variables = useReactiveVar(filter);
    var _a = useGetBranches(props.getByFilter && variables), response = _a.data, loading = _a.loading;
    var _b = useState([]), data = _b[0], setData = _b[1];
    useEffect(function () {
        if (response) {
            var Rows = response.rows;
            var data_1 = Rows.map(function (el) {
                var type = getBranchTypeById(el.type);
                var address = getFormattedAddressBranch(el);
                return {
                    id: el.id,
                    type: type,
                    prefix: el.prefix,
                    name: el.name,
                    phone: el.phone,
                    address: address,
                };
            });
            setData(data_1);
        }
    }, [response]);
    return { data: data, loading: loading };
};
export default useGetBranchData;
