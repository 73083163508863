import React from 'react';

import TableCell from '@mui/material/TableCell';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';

import CONFIG from '../../../../config/config.json';

const Component = props => {
  return (
    <TableCell colSpan={props.colSpan || 1} align={props.align || 'left'} variant='body'>
      {props.format === 'avatar' ? (
        <Avatar
          alt={`IMG-${props.url}`}
          src={`${CONFIG['api-file-service'].url}/files/${props.url}`}
        />
      ) : (
        <Typography variant='body2' noWrap={props.noWrap}>
          {props.children}
        </Typography>
      )}
    </TableCell>
  );
};

export default Component;
