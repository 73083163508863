import React, { useEffect, Fragment, useState } from 'react';
import { useQuery, useReactiveVar, useLazyQuery } from '@apollo/client';

import SearchIcon from '@mui/icons-material/Search';

import LinearProgress from '@mui/material/LinearProgress';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import Typography from '@mui/material/Typography';

import Table from '../../presentationals/Module.v2/Table/TableContainer';
import Pagination from '../../presentationals/Module.v2/Table/Pagination';
import IconButton from '../../presentationals/IconButton';
import { filter, count, exportCustomXls } from '../../apollo/cache';
import { currencyFormat } from '../../helpers';

import GQL from './_gql';
import Xls from './_xls';

import ProductDetails from './productDetails.dialog';

const TableComponent = () => {
  const filterVar = useReactiveVar(filter);
  const exportCustomXlsVar = useReactiveVar(exportCustomXls);

  const [dialog, setDialog] = useState([false, null, null]);

  const { data, loading, refetch } = useQuery(GQL.GET, {
    notifyOnNetworkStatusChange: true,
  });

  const [getTransferProducts] = useLazyQuery(GQL.GET, {
    notifyOnNetworkStatusChange: true,
    onCompleted: dataR => {
      dataR.purchaseOrderDetailReport && Xls(dataR.purchaseOrderDetailReport.rows);
      exportCustomXls(false);
    },
  });

  useEffect(() => {
    if (exportCustomXlsVar)
      //Reparar esto
      getTransferProducts({ variables: { ...filterVar, limit: 0 } });
  }, [exportCustomXlsVar]);

  useEffect(() => {
    if (Object.keys(filterVar).length > 0) refetch(filterVar);
  }, [filterVar, refetch]);

  useEffect(() => {
    if (data && data.purchaseOrderDetailReport) count(data.purchaseOrderDetailReport.count);
  }, [data]);

  const handleRows = (offset, limit) => {
    filter({ ...filterVar, limit, offset });
  };

  const handleSummary = (e, params) => {
    setDialog([true, params.productId, params.branchId]);
  };

  const handleClose = () => {
    setDialog([false, null, null]);
  };

  return (
    <>
      {loading && <LinearProgress color='secondary' />}
      <Table>
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell />
            <TableCell />
            {data &&
              data.purchaseOrderDetailReport.count > 0 &&
              data.purchaseOrderDetailReport.rows[0].PurchaseOrderDetails.map((el, i) => {
                return (
                  <TableCell
                    key={el.Branch.name}
                    align='center'
                    colSpan={3}
                    style={{
                      background: i % 2 === 0 ? '#c9c9c9' : '#fafafa',
                      borderColor: i % 2 === 0 ? '#989898' : '#ddd',
                      minWidth: 10,
                    }}
                  >
                    <Typography variant='body2' noWrap>
                      <strong>{el.Branch.name}</strong>
                    </Typography>
                  </TableCell>
                );
              })}
          </TableRow>
          <TableRow>
            <TableCell style={{ top: 33 }}>Producto</TableCell>
            <TableCell
              style={{
                top: 33,
                textAlign: 'center',
                background: '#c9c9c9',
                borderColor: '#989898',
              }}
            >
              Clasificación
            </TableCell>
            <TableCell style={{ top: 33, textAlign: 'center' }}>Unidad</TableCell>
            {data &&
              data.purchaseOrderDetailReport.count > 0 &&
              data.purchaseOrderDetailReport.rows[0].PurchaseOrderDetails.map((el, i) => {
                return (
                  <Fragment key={i}>
                    <TableCell
                      align='center'
                      style={{
                        background: i % 2 === 0 ? '#c9c9c9' : '#fafafa',
                        borderColor: i % 2 === 0 ? '#989898' : '#ddd',
                        top: 33,
                      }}
                    >
                      Cant
                    </TableCell>
                    <TableCell
                      align='right'
                      style={{
                        background: i % 2 === 0 ? '#c9c9c9' : '#fafafa',
                        borderColor: i % 2 === 0 ? '#989898' : '#ddd',
                        top: 33,
                      }}
                    >
                      Prec.
                    </TableCell>
                    <TableCell
                      style={{
                        background: i % 2 === 0 ? '#c9c9c9' : '#fafafa',
                        borderColor: i % 2 === 0 ? '#989898' : '#ddd',
                        top: 33,
                      }}
                    />
                  </Fragment>
                );
              })}
          </TableRow>
        </TableHead>
        <TableBody>
          {data &&
            data.purchaseOrderDetailReport.rows.map(el => {
              return (
                <TableRow key={el.id}>
                  <TableCell>
                    <Typography variant='body2' noWrap>
                      {` ${el.Product.name} `}
                    </Typography>
                  </TableCell>
                  <TableCell
                    style={{ textAlign: 'center', background: '#ddd', borderColor: '#989898' }}
                  >
                    <Typography variant='body2' noWrap>
                      {el.Product.Classification.name}
                    </Typography>
                  </TableCell>
                  <TableCell style={{ textAlign: 'center' }}>
                    <Typography variant='body2' noWrap>
                      {el.Product.PresentationBase ? el.Product.PresentationBase.name : '-'}
                    </Typography>
                  </TableCell>
                  {el.PurchaseOrderDetails.map((elCh, ii) => {
                    return (
                      <Fragment key={`${el.id}-${ii}`}>
                        <TableCell
                          align='center'
                          style={{
                            background: ii % 2 === 0 ? '#ddd' : '#fafafa',
                            borderColor: ii % 2 === 0 ? '#989898' : '#ddd',
                          }}
                        >
                          {currencyFormat(elCh.quantity)}
                        </TableCell>
                        <TableCell
                          align='right'
                          style={{
                            background: ii % 2 === 0 ? '#ddd' : '#fafafa',
                            borderColor: ii % 2 === 0 ? '#989898' : '#ddd',
                          }}
                        >
                          {currencyFormat(elCh.cost)}
                        </TableCell>
                        <TableCell
                          align='right'
                          style={{
                            background: ii % 2 === 0 ? '#ddd' : '#fafafa',
                            borderColor: ii % 2 === 0 ? '#989898' : '#ddd',
                          }}
                        >
                          <IconButton
                            size='small'
                            action={handleSummary}
                            params={{ productId: el.id, branchId: elCh.Branch.id }}
                            icon={<SearchIcon size='small' />}
                          />
                        </TableCell>
                      </Fragment>
                    );
                  })}
                </TableRow>
              );
            })}
        </TableBody>
      </Table>
      <Pagination handleRows={handleRows} />
      <ProductDetails
        handleClose={handleClose}
        isOpen={dialog[0]}
        productId={dialog[1]}
        branchId={dialog[2]}
        start={filterVar.start}
        end={filterVar.end}
      />
    </>
  );
};

export default TableComponent;
