import React, { useEffect } from 'react';
import { useLazyQuery } from '@apollo/client';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import FormDialog from '../../presentationals/FormDialog';

import GQL from './_gql';

const TableComponent = props => {
  const [getProducts, { data }] = useLazyQuery(GQL.GET_BY_ID);

  useEffect(() => {
    if (props.id) getProducts({ variables: { id: props.id } });
  }, [props.id]);
  return (
    <FormDialog
        title='Productos'
        isOpen={props.isOpen || false}
        handleClose={() => props.handleClose()}
        hideAction
      >
        <Table size='small'>
          <TableHead>
            <TableRow>
              <TableCell>Producto</TableCell>
              <TableCell align='center'>Unidad</TableCell>
              <TableCell align='right'>Cant.</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data &&
              data.production &&
              data.production.ProductionDetails.length > 0 &&
              data.production.ProductionDetails.map((el, i) => (
                <TableRow key={i}>
                  <TableCell>{el.Product.name}</TableCell>
                  <TableCell align='center'>{el.ProductPresentation.name}</TableCell>
                  <TableCell align='right'>{el.quantity}</TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </FormDialog>
  );
};

export default TableComponent;
