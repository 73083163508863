import React from 'react';
import { List } from '@mui/material';
import ItemTableFormDialogProductFieldComponent from './Item';
import LoadingProgress from '../../../../../LoadingProgress';
var TableDialogProductFieldComponent = function (props) {
    if (props.isLoading)
        return React.createElement(LoadingProgress, null);
    return (React.createElement("div", { className: 'flex flex-1 overflow-y-auto' },
        React.createElement(List, { component: 'nav', className: 'w-full', "aria-labelledby": 'nested-product-list' }, props.Products.map(function (el, i) { return (React.createElement(ItemTableFormDialogProductFieldComponent, { key: el.id, Product: el, background: i % 2 > 0 ? 'bg-inherit' : 'bg-gray-100' })); }))));
};
export default TableDialogProductFieldComponent;
