var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useEffect, useState } from 'react';
import { useReactiveVar } from '@apollo/client';
import getDefaultCustomerValues from '../../../sale.v2/Action/Customer/Form/Create/Body/helpers/defaultValues';
import TextFieldComponent from '../../../../presentationals/FormComponents/TextField';
import TaxRegimeSelectFieldHelpersBodyCreateFormCustomerActionSaleV2Component from '../../../sale.v2/Action/Customer/Form/Create/Body/helpers/TaxRegimeSelectField';
import FooterDialogComponent from '../../../../presentationals/Dialog/Footer';
import CreateOnlineInvoiceButtonComponent from '../CreateButton';
import useGetPaymentForm from '../../../../hooks/useGetSatPaymentForm';
import useGetUseCfdi from '../../../../hooks/useGetUseCfdi';
import SelectFieldComponent from '../../../../presentationals/FormComponents/SelectField';
import { inputError } from '../../../../apollo/cache';
import DocOnlineInvoiceComponent from '../../Doc';
var CustomerOnlineInvoiceForm = function (props) {
    var errors = useReactiveVar(inputError);
    var getPaymentFormOptions = useGetPaymentForm();
    var getUseCfdiOptions = useGetUseCfdi();
    var defaultValues = getDefaultCustomerValues();
    var _a = useState(__assign(__assign({}, defaultValues), { paymentForm: '', useCfdi: '' })), values = _a[0], setValues = _a[1];
    var _b = useState(undefined), uuid = _b[0], setUuid = _b[1];
    useEffect(function () {
        if (props.CustomerBillingInformation) {
            var newValues_1 = getDefaultCustomerValues({
                CustomerBillingInformation: props.CustomerBillingInformation,
            });
            setValues(function (values) { return (__assign(__assign({}, values), newValues_1)); });
        }
    }, [props.CustomerBillingInformation]);
    var handleChange = function (name, value) {
        setValues(function (values) {
            var _a;
            return (__assign(__assign({}, values), (_a = {}, _a[name] = value, _a)));
        });
    };
    var handleCreated = function (uuid) {
        setUuid(uuid);
    };
    if (uuid)
        return React.createElement(DocOnlineInvoiceComponent, { uuid: uuid });
    return (React.createElement("div", { className: 'flex flex-1 flex-col' },
        React.createElement("div", { className: 'flex flex-1 flex-col  p-4' },
            React.createElement("div", { className: 'text-lg' }, "Verificaci\u00F3n del cliente"),
            React.createElement("div", { className: 'text-base text-gray-600' }, "Proporciona los datos que a continuaci\u00F3n se solicitan."),
            React.createElement("div", { className: 'flex-1 flex-col basis-0 overflow-y-auto grid grid-cols-1 sm:grid-cols-4 gap-4' },
                React.createElement("div", null,
                    React.createElement(TextFieldComponent, { name: 'identifier', label: 'RFC', onChange: handleChange, value: values.identifier }),
                    React.createElement(TextFieldComponent, { name: 'businessName', label: 'Razon social', onChange: handleChange, value: values.businessName }),
                    React.createElement(TaxRegimeSelectFieldHelpersBodyCreateFormCustomerActionSaleV2Component, { onChange: handleChange, value: values.taxRegime }),
                    React.createElement(SelectFieldComponent, { label: 'Forma de pago', name: 'paymentForm', value: values.paymentForm, onChange: handleChange, error: errors.paymentForm, options: getPaymentFormOptions }),
                    React.createElement(SelectFieldComponent, { label: 'Uso del CFDi', name: 'useCfdi', value: values.useCfdi, onChange: handleChange, error: errors.useCfdi, options: getUseCfdiOptions }),
                    React.createElement(TextFieldComponent, { name: 'phone', label: 'Tel\u00E9fono', onChange: handleChange, value: values.phone, type: 'phone' }),
                    React.createElement(TextFieldComponent, { name: 'email', label: 'Correo electr\u00F3nico', onChange: handleChange, value: values.email, type: 'email' })),
                React.createElement("div", null,
                    React.createElement(TextFieldComponent, { name: 'businessZipCode', label: 'C\u00F3digo postal', onChange: handleChange, value: values.businessZipCode }),
                    React.createElement(TextFieldComponent, { name: 'businessAddressLine1', label: 'Calle y n\u00FAmero', onChange: handleChange, value: values.businessAddressLine1 }),
                    React.createElement(TextFieldComponent, { name: 'businessAddressLine2', label: 'Colonia', onChange: handleChange, value: values.businessAddressLine2 }),
                    React.createElement(TextFieldComponent, { name: 'businessCity', label: 'Ciudad', onChange: handleChange, value: values.businessCity }),
                    React.createElement(TextFieldComponent, { name: 'businessMunicipality', label: 'Municipio', onChange: handleChange, value: values.businessMunicipality }),
                    React.createElement(TextFieldComponent, { name: 'businessState', label: 'Estado', onChange: handleChange, value: values.businessState })))),
        React.createElement(FooterDialogComponent, null,
            React.createElement("div", null),
            React.createElement(CreateOnlineInvoiceButtonComponent, { values: values, onCreated: handleCreated, saleId: props.saleId }))));
};
export default CustomerOnlineInvoiceForm;
