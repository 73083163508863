import { gql } from '@apollo/client';
import jsPDF from 'jspdf';

import { format, parseISO } from 'date-fns';
import locale from 'date-fns/locale/es';
import client from '../../../apollo';
import { filter } from '../../../apollo/cache';
import { currencyFormat, phoneFormat, customerLabel } from '../../../helpers';
import GQL_PRINT from '../../print/_gql';
import { getCompanyData } from '../../../presentationals/PDF/cashClosing.80mm';

const customerVar = customerLabel({ textTransform: 'capitalize' });

const GET_SALE_BY_ID = gql`
  query Sale($id: ID!, $branchId: ID) {
    sale(id: $id, branchId: $branchId) {
      id
      category
      serial
      folio
      commentary
      subtotal
      discount
      taxes
      total
      totalPaid
      Cashier {
        firstName
        lastName
      }
      SaleProducts {
        id
        name
        quantity
        withoutTaxes
        subtotal
        discount
        commentary
        serialNumber
        Product {
          id
          name
        }
      }
      Customer {
        firstName
        lastName
        addressLine1
        addressLine2
        zipCode
        city
        state
        phone
      }
      Branch {
        addressLine1
        addressLine2
        zipCode
        city
        state
        phone
        name
      }
      clientCreatedAt
      Transactions {
        id
        PaymentMethod {
          name
        }
        total
      }
      cashier
      seller
    }
  }
`;

const getSale = async id => {
  try {
    const { data } = await client.mutate({
      mutation: GET_SALE_BY_ID,
      variables: { id, branchId: filter().branchId },
    });
    return data.sale;
  } catch (e) {
    console.log(e);
  }
};

const print = async (id, type) => {
  try {
    const { data } = await client.mutate({
      mutation: GQL_PRINT.PRINT,
      variables: { id, type },
    });
    return data.print;
  } catch (e) {
    console.log(e);
    return e;
  }
};

export default async saleId => {
  const companyData = await getCompanyData();
  if (parseInt(companyData.printClient) === 2) {
    await print(saleId, 'sale');
    return;
  }

  const data = await getSale(saleId);

  const {
    folio,
    serial,
    clientCreatedAt,
    commentary,
    Cashier,
    Branch,
    Customer,
    subtotal,
    discount,
    taxes,
    total,
    SaleProducts,
  } = data;
  const addressLineBranch = `${Branch.addressLine1}, ${Branch.addressLine2}`.trim();

  const products = SaleProducts.sort((a, b) => parseInt(a.id) - parseInt(b.id));

  const doc = new jsPDF({
    unit: 'mm',
    format: [200, 600],
  });

  doc.setProperties({
    title: `doc_${folio}-${serial}_${clientCreatedAt}`,
  });

  let breakline = 10;

  doc.setFontSize(10);
  doc.setFont('times', 'bold');
  doc.text(`${Branch.name}`, 35, breakline, 'center');
  doc.setFontSize(8);
  doc.setFont('times', 'normal');

  breakline += 3;
  doc.text(doc.splitTextToSize(addressLineBranch, 60), 35, breakline, 'center');

  breakline = addressLineBranch.length >= 60 ? breakline + 6 : breakline + 3;
  doc.text(`${Branch.city}, ${Branch.state}, C.P. ${Branch.zipCode}`, 35, breakline, 'center');

  breakline += 3;
  doc.text(`Tel. ${phoneFormat(Branch.phone)}`, 35, breakline, 'center');

  breakline += 5;
  doc.setFontSize(10);

  const allowedCategories = [
    '',
    'NOTA DE VENTA',
    'VENTA A CRÉDITO',
    'ORDEN DE SERVICIO',
    '',
    '',
    '',
    'RENTA',
  ];
  doc.text(allowedCategories[data.category], 35, breakline, 'center');

  const addressLineCustomer = `${Customer.addressLine1}, ${Customer.addressLine2}`.trim();
  breakline += 5;

  doc.setFontSize(10);
  doc.text(`FOLIO: ${folio}`, 5, breakline, 'left');

  breakline += 5;
  doc.setFontSize(8);
  const customerName = `${Customer.firstName} ${Customer.lastName || ''}`;
  doc.text(`${customerVar}: ${customerName}`, 5, breakline, 'left');

  if (customerName.trim() === 'PUBLICO EN GENERAL') {
    breakline += 6;
  } else {
    breakline += 3;
    doc.text(`Teléfono: ${Customer.phone || ''}`, 5, breakline, 'left');
    breakline += 3;
    doc.text(doc.splitTextToSize(`Domicilio: ${addressLineCustomer}`, 60), 5, breakline, 'left');
    breakline = addressLineCustomer.length >= 60 ? breakline + 12 : breakline + 9;
  }
  const cashier = Cashier ? `${Cashier.firstName} ${Cashier.lastName}` : data.cashier || '';
  doc.text(`Atendió: ${cashier}`, 5, breakline, 'left');

  breakline += 3;
  doc.text(
    `${format(parseISO(clientCreatedAt), 'dd/MM/yyyy HH:ss', { locale })}`,
    5,
    breakline,
    'left',
  );

  breakline += 3;
  doc.line(5, breakline, 66, breakline);

  breakline += 3;
  doc.setFont('times', 'bold');
  doc.setFontSize(6);
  doc.text('Producto', 5, breakline, 'left');
  doc.text('Cant', 35, breakline, 'center');
  doc.text('P.Unit', 47, breakline, 'right');
  doc.text('Dscto.', 56, breakline, 'right');
  doc.text('Importe', 66, breakline, 'right');
  doc.setFont('times', 'normal');

  breakline += 1;
  doc.line(5, breakline, 66, breakline);

  breakline += 3;
  doc.setFontSize(6);

  products.forEach(el => {
    const serialNumber = el.serialNumber ? ` ${el.serialNumber}` : '';
    const splitName = doc.splitTextToSize(
      el.Product ? el.Product.name + serialNumber : el.name ? el.name + serialNumber : '',
      18,
    );

    doc.text(splitName, 5, breakline, 'left');
    doc.text(`${el.quantity}`, 35, breakline, 'center');
    doc.text(`${currencyFormat(el.withoutTaxes)}`, 47, breakline, 'right');
    doc.text(`${currencyFormat(el.discount)}`, 56, breakline, 'right');
    doc.text(`${currencyFormat(el.subtotal)}`, 66, breakline, 'right');

    breakline = breakline + (splitName.length + 1.5) * 2;

    if (el.commentary !== null && el.commentary !== '') {
      const splitCommentary = doc.splitTextToSize(el.commentary, 60);
      doc.text(splitCommentary, 5, breakline, 'left');
      breakline = breakline + splitCommentary.length * 2;
    }

    doc.line(5, breakline, 66, breakline);

    breakline += 3;
  });

  doc.setFontSize(8);
  doc.text('Subtotal:', 48, breakline, 'right');
  doc.text(`${currencyFormat(subtotal)}`, 66, breakline, 'right');

  doc.text('Dscto:', 48, breakline + 3, 'right');
  doc.text(`${currencyFormat(discount)}`, 66, breakline + 3, 'right');

  doc.text('IVA:', 48, breakline + 6, 'right');
  doc.text(`${currencyFormat(taxes)}`, 66, breakline + 6, 'right');

  doc.setFontSize(10);
  doc.text('Total:', 48, breakline + 10, 'right');
  doc.text(`${currencyFormat(total)}`, 66, breakline + 10, 'right');

  doc.setFontSize(8);
  doc.line(5, breakline + 11, 66, breakline + 11);

  breakline += 17;
  doc.text('Pago con:', 48, breakline, 'right');
  doc.text(`${currencyFormat(data.totalPaid)}`, 66, breakline, 'right');

  const change = total - parseFloat(data.totalPaid);
  const changeAction = change >= 0 ? 'Resta' : 'Sobra';

  breakline += 3;
  doc.text(`${changeAction}:`, 48, breakline, 'right');
  doc.text(`${currencyFormat(Math.abs(change))}`, 66, breakline, 'right');

  breakline += 1;
  doc.line(5, breakline, 66, breakline);

  let tmpbreakline = 9;

  if (commentary.length > 0) {
    breakline += 6;
    doc.setFont('times', 'bold');
    doc.text('Anotaciones:', 35, breakline, 'center');
    doc.setFont('times', 'normal');
    breakline += 3;
    const splitGeneralCommentary = doc.splitTextToSize(commentary, 60);
    doc.text(splitGeneralCommentary, 35, breakline, 'center', 'justify');
    tmpbreakline = +splitGeneralCommentary.length * 4;
  } else {
    tmpbreakline = 6;
  }

  if (data.Transactions && data.Transactions.length > 0) {
    const transactionString = data.Transactions.reduce((string, el, i) => {
      const paymentMethod = el.PaymentMethod ? el.PaymentMethod.name.toLowerCase() : 'efectivo';
      return el.total > 0
        ? i + 1 === data.Transactions.length
          ? `${string} ${paymentMethod}.`
          : `${string} ${paymentMethod},`
        : string;
    }, '');
    doc.text(
      `Forma de pago: ${doc.splitTextToSize(transactionString, 60)}`,
      35,
      breakline + parseInt(tmpbreakline),
      'center',
    );
    breakline += 6;
  }

  doc.text('¡Gracias por su compra!', 35, breakline + parseInt(tmpbreakline), 'center');

  breakline += 6;
  doc.text(
    doc.splitTextToSize(
      'Puedes solicitar tu factura electrónica ingresando a www.capitalike.com/facturacion. El folio caducará en 3 días a partir de la fecha de compra.',
      60,
    ),
    35,
    breakline + parseInt(tmpbreakline),
    'center',
  );

  doc.autoPrint();

  window.open(doc.output('bloburl'), '_blank');
};
