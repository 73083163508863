import React from 'react';

import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import IconButton from './helpers/iconButton';

const TableComponent = props => {
  const handleDeleteItem = (e, position) => {
    props.removeItem(position);
  };

  return (
    <TableContainer>
        <Table size='small'>
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell>Producto</TableCell>
              <TableCell align='center'>Unidad</TableCell>
              <TableCell align='right'>Cant.</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.cartItems.map((el, i) => (
              <TableRow key={i}>
                <TableCell>
                  <IconButton
                    label='Eliminar'
                    icon={<DeleteOutlineIcon />}
                    params={i}
                    action={handleDeleteItem}
                    size='small'
                  />
                </TableCell>
                <TableCell>{el.product ? el.product.label : el.concept}</TableCell>
                <TableCell align='center'>
                  {el.productPresentation ? el.productPresentation.label : '-'}
                </TableCell>
                <TableCell align='right'>{el.quantity}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
  );
};

export default TableComponent;
