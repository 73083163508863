import React, { useEffect, useState } from 'react';
import { Divider } from '@mui/material';
import getPurchaseOrderDescriptionLabel from './helpers/getPurchaseOrderDescriptionLabel';
import getPurchaseOrderActionLabel from '../../../../../helpers/getPurchaseOrderActionLabel';
var WhatWilllHappenPurchaseOrdenLabelComponent = function (props) {
    var _a = useState(''), actionLabel = _a[0], setActionLabel = _a[1];
    var _b = useState(''), descriptionLabel = _b[0], setDescriptionLabel = _b[1];
    useEffect(function () {
        setActionLabel(getPurchaseOrderActionLabel(props.action));
        setDescriptionLabel(getPurchaseOrderDescriptionLabel(props.action));
    }, [props.action]);
    return (React.createElement("div", { className: 'flex flex-col' },
        React.createElement("div", { className: 'text-lg text-gray-700 tracking-tight' },
            "\u00BFQu\u00E9 pasar\u00E1 despu\u00E9s de finalizar la ",
            actionLabel,
            "?"),
        React.createElement("div", { className: 'text-gray-600 text-base mt-2' }, descriptionLabel),
        React.createElement(Divider, { className: 'w-full sm:w-1/3 mt-4' })));
};
export default WhatWilllHappenPurchaseOrdenLabelComponent;
