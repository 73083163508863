var getRouteFromRentMenuValue = function (value) {
    switch (value) {
        case 0:
            return '.';
        case 1:
            return 'pickup';
        case 2:
            return 'close';
        case 3:
            return 'finished';
        default:
            return false;
    }
};
export default getRouteFromRentMenuValue;
