import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from '@mui/material';
import React from 'react';
var IsZeroFieldComponent = function (props) {
    var handleRadioChange = function (event) {
        props.onChange(props.name, event.target.value === '1');
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(FormControl, { className: 'mt-1' },
            React.createElement(FormLabel, { className: 'text-gray-900', id: props.name }, "\u00BFQu\u00E9 hacer con los productos que no se a\u00F1adan a la lista?"),
            React.createElement(RadioGroup, { onChange: handleRadioChange, row: true, value: props.value ? 1 : 0, "aria-labelledby": 'isZeroLabel', name: props.name },
                React.createElement(FormControlLabel, { value: 0, control: React.createElement(Radio, null), label: 'Mantener el stock actual.' }),
                React.createElement(FormControlLabel, { value: 1, control: React.createElement(Radio, null), label: 'Modificar su stock a cero. (Cuidado)' }))),
        props.value && (React.createElement("div", { className: 'text-red-600' }, "\u00A1Advertencia! Todos los productos que no a\u00F1adas a la lista se actualizar\u00E1 su stock a cero."))));
};
export default IsZeroFieldComponent;
