import React, { useState, useEffect } from 'react';

import { useReactiveVar, useQuery } from '@apollo/client';

import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';

import FilterDrawer from '../../presentationals/FilterDrawer.v2';
import Checkbox from '../../presentationals/Checkbox';
import SelectField from '../../presentationals/FormComponents/SelectField';

import { filter, searchField } from '../../apollo/cache';

import GQL_BRANCHES from '../branch/_gql';

const defaultValues = {
  status: 1,
};

const statusOptions = [
  { value: -1, label: 'Todos' },
  { value: 1, label: 'Abiertos' },
  { value: 2, label: 'Autorizados' },
  { value: 3, label: 'Recibidos' },
  // { value: 4, label: 'Cancelados' },
];

const FilterDialog = () => {
  const searchFieldVar = useReactiveVar(searchField);
  const filterVar = useReactiveVar(filter);
  const [values, setValues] = useState(defaultValues);
  const [valuesBranches, setValuesBranches] = useState({});
  const [branches, setBranches] = useState([]);

  const { data: dataBranches } = useQuery(GQL_BRANCHES.GET, { variables: { limit: 0 } });

  useEffect(() => {
    if (dataBranches) {
      const tmp = dataBranches.branches.rows.reduce(
        (object, el) => ({ ...object, [el.id]: true }),
        {},
      );
      setValuesBranches({ ...tmp, '-1': true });
      setBranches([{ id: '-1', name: 'Seleccionar Todos' }, ...dataBranches.branches.rows]);
      // filter({ ...filter(), branchId: Object.keys(tmp).filter(key => !!tmp[key]) });
    }
  }, [dataBranches]);

  useEffect(() => {
    if (searchFieldVar && searchFieldVar.trim() !== '')
      filter({ ...filterVar, like: searchFieldVar });
  }, [searchFieldVar]);

  const handleAction = (e, data) => {
    const { startDate: start, endDate: end } = data;
    const status = values.status === -1 ? values.status : [values.status];
    filter({
      status,
      start,
      end,
      branchId: Object.keys(valuesBranches).filter(key => !!valuesBranches[key] && key !== '-1'),
    });
  };

  const handleChange = (name, value) => {
    setValues(values => ({ ...values, [name]: value }));
  };

  const handleCheckBoxBranches = (name, checked) => {
    if (name === '-1') {
      const tmp = Object.keys(valuesBranches).reduce((object, key) => {
        return { ...object, [key]: checked };
      }, valuesBranches);
      setValuesBranches(tmp);
      return;
    }
    const numberOfTrue = Object.keys(valuesBranches).filter(key => !!valuesBranches[key]);
    if ((numberOfTrue.length === 1 || Object.keys(valuesBranches).length === 2) && !checked) return;
    let number = numberOfTrue.length;
    if (checked) number = number + 2;
    else number = number - 1;
    if (number === Object.keys(valuesBranches).length)
      setValuesBranches(values => ({ ...values, [name]: checked, '-1': true }));
    else setValuesBranches(values => ({ ...values, [name]: checked, '-1': false }));
  };

  return (
    <FilterDrawer handleAction={handleAction} showDates>
      <Typography variant='subtitle1'>Estatus:</Typography>
      <SelectField
        name='status'
        onChange={handleChange}
        options={statusOptions}
        size='medium'
        value={values.status}
      />
      <br />
      <Divider />
      <br />
      <Typography variant='subtitle1'>Sucursal:</Typography>
      <Checkbox
        handleChange={handleCheckBoxBranches}
        noFormat
        values={branches.map(el => ({
          name: el.id,
          label: el.name,
          checked: !!valuesBranches[el.id] && valuesBranches[el.id],
          color: el.color,
        }))}
      />
    </FilterDrawer>
  );
};

export default FilterDialog;
