import { gql } from '@apollo/client';

const CREATE = gql`
  mutation UpdateServiceOrder($id: ID!, $Products: [SaleProductData!]!) {
    updateServiceOrder(id: $id, input: { Products: $Products }) {
      id
      SaleProducts {
        id
      }
    }
  }
`;

const ADD_PRODUCT = gql`
  mutation AddProductServiceOrder(
    $id: ID!
    $productId: ID
    $quantity: Float!
    $discount: Float
    $price: Float
    $iva: Float
    $ieps: Float
  ) {
    addProductServiceOrder(
      id: $id
      Product: {
        productId: $productId
        quantity: $quantity
        discount: $discount
        price: $price
        iva: $iva
        ieps: $ieps
      }
    ) {
      id
      name
      productId
      quantity
      unitPrice
      withoutTaxes
      subtotal
      discount
      commentary
      taxes
      total
      outDate
    }
  }
`;

const DELETE_PRODUCT = gql`
  mutation DeleteServiceOrderProduct($id: ID!) {
    deleteServiceOrderProduct(id: $id) {
      id
      subtotal
      taxes
      total
    }
  }
`;

const CLOSE_SERVICE_ORDER = gql`
  mutation CloseServiceOrder($id: ID!, $Transactions: [TransactionSaleData]!) {
    closeServiceOrder(id: $id, Transactions: $Transactions) {
      id
    }
  }
`;

const FRAGMENT_NEW_SALE_PRODUCT = gql`
  fragment NewSaleProduct on SaleProducts {
    id
    name
    productId
    quantity
    unitPrice
    withoutTaxes
    subtotal
    discount
    commentary
    taxes
    total
  }
`;

const CLOSE_SERVICE_ORDER_INVOICE = gql`
  mutation CloseServiceOrderInvoice(
    $id: ID!
    $Transactions: [TransactionSaleData]!
    $paymentMethod: String!
    $paymentMethodId: ID
    $useCfdi: String!
    $applyExtraTaxes: Boolean
    $identifier: String!
    $businessName: String!
    $addressLine1: String!
    $addressLine2: String!
    $zipCode: String!
    $city: String!
    $municipality: String!
    $state: String!
    $country: String!
    $email: String!
    $phone: String
    $relateUUID: String
    $relateType: String
    $Products: [ProductInvoice]
  ) {
    closeServiceOrderInvoice(
      id: $id
      input: {
        Transactions: $Transactions
        paymentMethod: $paymentMethod
        paymentMethodId: $paymentMethodId
        useCfdi: $useCfdi
        applyExtraTaxes: $applyExtraTaxes
        relateUUID: $relateUUID
        relateType: $relateType
        Products: $Products
        CustomerBillingInformation: {
          identifier: $identifier
          businessName: $businessName
          addressLine1: $addressLine1
          addressLine2: $addressLine2
          zipCode: $zipCode
          city: $city
          municipality: $municipality
          state: $state
          country: $country
          email: $email
          phone: $phone
        }
      }
    ) {
      id
      serial
      paid
      folio
      subtotal
      discount
      taxes
      total
      category
      Customer {
        id
        firstName
        lastName
      }
      AccountReceivable {
        id
        folio
        serial
        payment
        balance
      }
      Invoice {
        uuid
        serial
        folio
      }
    }
  }
`;

const UPDATE_COMMENTARY = gql`
  mutation UpdateSaleCommentary($id: ID!, $commentary: String!) {
    updateSaleCommentary(id: $id, commentary: $commentary) {
      id
      commentary
    }
  }
`;

const UPDATE_PRODUCT_SERVICE_ORDER = gql`
  mutation UpdateProductServiceOrder(
    $id: ID!
    $saleId: ID!
    $productId: ID
    $quantity: Float!
    $price: Float
    $outDate: Date
    $pin: String
  ) {
    updateProductServiceOrder(
      id: $id
      saleId: $saleId
      pin: $pin
      input: { productId: $productId, quantity: $quantity, price: $price, outDate: $outDate }
    ) {
      id
      productId
      quantity
      unitPrice
      withoutTaxes
      subtotal
      discount
      commentary
      taxes
      total
      outDate
    }
  }
`;

const UPDATE_SALE_PRODUCT_COMMENTARY = gql`
  mutation UpdateSaleProductCommentary($id: ID!, $commentary: String!) {
    updateSaleProductCommentary(id: $id, commentary: $commentary) {
      id
      commentary
    }
  }
`;

const CREATE_SALE_EXPENSE = gql`
  mutation CreateSaleExpense(
    $saleId: ID!
    $name: String!
    $quantity: Float!
    $price: Float!
    $discount: Float
    $iva: Float
    $ieps: Float
    $outDate: Date
  ) {
    createSaleExpense(
      saleId: $saleId
      input: {
        name: $name
        quantity: $quantity
        price: $price
        discount: $discount
        iva: $iva
        ieps: $ieps
        outDate: $outDate
      }
    ) {
      id
      name
      quantity
      unitPrice
      withoutTaxes
      subtotal
      discount
      taxes
      total
      outDate
    }
  }
`;

const DELETE_SALE_EXPENSE = gql`
  mutation DeleteSaleExpense($id: ID!) {
    deleteSaleExpense(id: $id) {
      id
      name
      quantity
      unitPrice
      withoutTaxes
      subtotal
      discount
      taxes
      total
      outDate
    }
  }
`;

const GET_SALE_EXPENSES = gql`
  query SaleExpenses($saleId: ID!, $limit: Int) {
    saleExpenses(saleId: $saleId, limit: $limit) {
      count
      rows {
        id
        name
        quantity
        unitPrice
        withoutTaxes
        subtotal
        discount
        taxes
        total
        outDate
        createdAt
      }
    }
  }
`;

const FRAGMENT_NEW_SALE_EXPENSE_PRODUCT = gql`
  fragment NewSaleExpense on SaleExpense {
    id
    name
    quantity
    unitPrice
    withoutTaxes
    subtotal
    discount
    taxes
    total
    outDate
  }
`;

export default {
  CREATE,
  ADD_PRODUCT,
  DELETE_PRODUCT,
  CLOSE_SERVICE_ORDER,
  FRAGMENT_NEW_SALE_PRODUCT,
  CLOSE_SERVICE_ORDER_INVOICE,
  UPDATE_COMMENTARY,
  UPDATE_PRODUCT_SERVICE_ORDER,
  UPDATE_SALE_PRODUCT_COMMENTARY,
  CREATE_SALE_EXPENSE,
  DELETE_SALE_EXPENSE,
  GET_SALE_EXPENSES,
  FRAGMENT_NEW_SALE_EXPENSE_PRODUCT,
};
