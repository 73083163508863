import React from 'react';
import { Divider } from '@mui/material';
import LabelPreviewGeneralSettingsCreatePurchaseOrderComponent from './Label';
import getPurchaseOrderActionLabel from '../../../../../helpers/getPurchaseOrderActionLabel';
import ProviderLabelPreviewGeneralSettingsCreatePurchaseOrderComponent from './ProviderLabel';
import { capitalize } from '../../../../../helpers';
var PreviewGeneralSettingsCreatePurchaseOrderComponent = function (props) {
    return (React.createElement("div", { className: 'flex flex-col mt-3' },
        React.createElement("div", { className: 'text-lg text-gray-700 tracking-tight mb-2 mr-2' }, "Configuraci\u00F3n general"),
        React.createElement(LabelPreviewGeneralSettingsCreatePurchaseOrderComponent, { title: 'Proceso' }, capitalize(getPurchaseOrderActionLabel(props.action))),
        React.createElement(ProviderLabelPreviewGeneralSettingsCreatePurchaseOrderComponent, { providerId: props.providerId }),
        React.createElement(Divider, { className: 'w-full sm:w-1/3 mt-4' })));
};
export default PreviewGeneralSettingsCreatePurchaseOrderComponent;
