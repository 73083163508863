var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useEffect, useState } from 'react';
import BadgeIcon from '@mui/icons-material/Badge';
import ListItemPermissionEditRoleComponent from '../helpers/ListItem';
import SelectPermissionFieldComponent from '../helpers/SelectPermissionField';
export var defaultBranchPermissionValues = {
    createNewEmployee: 0,
    editGeneralEmployeeData: 0,
    editPasswordEmployee: 0,
    editPinEmployee: 0,
    editEmployeeCalendar: 0,
    deleteEmployees: 0,
    getEmployees: 0,
};
var defaultOptions = [
    { value: 0, label: 'No tiene permisos' },
    { value: 1, label: 'Si, solo de sucursal predeterminada' },
    { value: 2, label: 'Si, de cualquier sucursal' },
];
var EmployeePermissionEditRoleComponent = function (props) {
    var _a = useState(defaultBranchPermissionValues), values = _a[0], setValues = _a[1];
    useEffect(function () {
        setValues(function (values) { return (__assign(__assign({}, values), props.Permissions)); });
    }, [props.Permissions]);
    return (React.createElement(ListItemPermissionEditRoleComponent, { icon: React.createElement(BadgeIcon, null), name: 'Empleados' },
        React.createElement("div", { className: 'mx-4 py-3 grid grid-cols-1 md:grid-cols-3 gap-4' },
            React.createElement("div", { className: 'flex flex-col' },
                React.createElement(SelectPermissionFieldComponent, __assign({}, props, { name: 'getEmployees', label: 'Consultar empleados', defaultValue: values.getEmployees, options: defaultOptions })),
                React.createElement(SelectPermissionFieldComponent, __assign({}, props, { name: 'createNewEmployee', label: 'Crear nuevos empleados', defaultValue: values.createNewEmployee, options: defaultOptions })),
                React.createElement(SelectPermissionFieldComponent, __assign({}, props, { name: 'editGeneralEmployeeData', label: 'Editar datos generales de un empleado', defaultValue: values.editGeneralEmployeeData, options: defaultOptions })),
                React.createElement(SelectPermissionFieldComponent, __assign({}, props, { name: 'editPasswordEmployee', label: 'Cambiar contrase\u00F1a de un empleado', defaultValue: values.editPasswordEmployee, options: defaultOptions })),
                React.createElement(SelectPermissionFieldComponent, __assign({}, props, { name: 'editPinEmployee', label: 'Cambiar pin de comandero y autorizaciones de empleados', defaultValue: values.editPinEmployee, options: defaultOptions })),
                React.createElement(SelectPermissionFieldComponent, __assign({}, props, { name: 'editEmployeeCalendar', label: 'Editar el calendario de un empleado', defaultValue: values.editEmployeeCalendar, options: defaultOptions })),
                React.createElement(SelectPermissionFieldComponent, __assign({}, props, { name: 'deleteEmployees', label: 'Dar de baja empleados', defaultValue: values.deleteEmployees, options: defaultOptions }))))));
};
export default EmployeePermissionEditRoleComponent;
