var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useEffect, useState } from 'react';
import { useReactiveVar } from '@apollo/client';
import SearchIcon from '@mui/icons-material/SearchOutlined';
import { InputAdornment } from '@mui/material';
import OutlinedFieldComponent from '../../../../../../presentationals/FormComponents/OutlinedField';
import IconButton from '../../../../../../presentationals/IconButton';
import { customerSaleV2, isCalendarFormOpen, isSaleCustomerFormOpen, searchField, } from '../../../../../../apollo/cache';
import useApolloLazyQuery from '../../../../../../hooks/useApolloLazyQuery';
import GQL from '../../../../../customer/_gql';
import getInputErrorFormat from '../../../../../sale.v2/Action/helpers/getInputErrorFormat';
import TextFieldComponent from '../../../../../../presentationals/FormComponents/TextField';
import useGetSession from '../../../../../../hooks/useGetSession';
var CustomerFieldFormBodyFormCalendarComponent = function (props) {
    var _a, _b, _c;
    var customerSaleV2Var = useReactiveVar(customerSaleV2);
    var calendarFormData = useReactiveVar(isCalendarFormOpen);
    var session = useGetSession();
    var customerLabel = ((_a = session === null || session === void 0 ? void 0 : session.Company) === null || _a === void 0 ? void 0 : _a.activity) === 3 ? 'Paciente' : 'Cliente';
    var _d = useState(''), value = _d[0], setValue = _d[1];
    var _e = useState(''), isSubscriptionActive = _e[0], setIsSubscriptionActive = _e[1];
    var _f = useApolloLazyQuery(GQL.GET), get = _f[0], loading = _f[1].loading;
    var getById = useApolloLazyQuery(GQL.GET_BY_ID)[0];
    useEffect(function () {
        var _a;
        if (customerSaleV2Var && customerSaleV2Var.id) {
            var name_1 = "".concat(customerSaleV2Var.firstName, " ").concat(customerSaleV2Var.lastName);
            props.handleChange('customer', {
                value: customerSaleV2Var.id,
                label: name_1,
            });
            if (((_a = customerSaleV2Var.Crms) === null || _a === void 0 ? void 0 : _a.length) > 0)
                setIsSubscriptionActive('𝐏');
            setValue(name_1);
        }
    }, [customerSaleV2Var]);
    var handleChange = function (name, value) {
        setValue(value);
    };
    var handleClick = function (e, action) { return __awaiter(void 0, void 0, void 0, function () {
        var data, id, customer, e_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 5, , 6]);
                    return [4 /*yield*/, get({ variables: { name: value } })];
                case 1:
                    data = (_a.sent()).data;
                    if (!(data.rows.length === 1)) return [3 /*break*/, 3];
                    id = data.rows[0].id;
                    return [4 /*yield*/, getById({ variables: { id: id } })];
                case 2:
                    customer = (_a.sent()).data;
                    if (action !== 'enter') {
                        isSaleCustomerFormOpen(true);
                        searchField(['name', value]);
                    }
                    customerSaleV2(customer);
                    return [3 /*break*/, 4];
                case 3:
                    searchField(['name', value]);
                    setValue(value);
                    isSaleCustomerFormOpen(true);
                    _a.label = 4;
                case 4: return [3 /*break*/, 6];
                case 5:
                    e_1 = _a.sent();
                    getInputErrorFormat(e_1);
                    return [3 /*break*/, 6];
                case 6: return [2 /*return*/];
            }
        });
    }); };
    var handleKeyPress = function (e) {
        if (e.key === 'Enter') {
            e.preventDefault();
            handleClick(undefined, 'enter');
        }
    };
    if ((_b = calendarFormData[1]) === null || _b === void 0 ? void 0 : _b.customerId)
        return React.createElement("div", null);
    return (React.createElement(React.Fragment, null,
        React.createElement(OutlinedFieldComponent, { label: customerLabel, name: 'customer', value: value, error: (_c = props.error) === null || _c === void 0 ? void 0 : _c.customer, onChange: handleChange, onKeyDown: handleKeyPress, disabled: props.disabled, endAdornment: React.createElement(InputAdornment, { position: 'end' },
                React.createElement(IconButton, { disabled: props.disabled || loading, action: handleClick, size: 'small', className: 'p-0 m-0', icon: React.createElement(SearchIcon, { fontSize: 'small' }) })) }),
        customerSaleV2Var && customerSaleV2Var.phone && (React.createElement(TextFieldComponent, { label: "T\u00E9lefono de ".concat(customerLabel.toLowerCase()), value: "".concat(customerSaleV2Var.phone, " ").concat(isSubscriptionActive), disabled: true }))));
};
export default CustomerFieldFormBodyFormCalendarComponent;
