import React, { useEffect, useState } from 'react';
import { Tabs, Tab } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import StorefrontIcon from '@mui/icons-material/Storefront';
import CategoryOutlinedIcon from '@mui/icons-material/CategoryOutlined';
import InsertPhotoOutlinedIcon from '@mui/icons-material/InsertPhotoOutlined';
import DvrIcon from '@mui/icons-material/Dvr';
import ViewQuiltIcon from '@mui/icons-material/ViewQuilt';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import getMenuValueFromPath from './getMenuValueFromPath';
import getRouteFromMenuValue from './getRouteFromMenuValue';
var MenuCompanyLayoutComponent = function () {
    var navigate = useNavigate();
    var location = useLocation();
    var _a = useState(0), value = _a[0], setValue = _a[1];
    useEffect(function () {
        var value = getMenuValueFromPath(location.pathname);
        setValue(value);
    }, [location.pathname]);
    var handleChange = function (e, value) {
        setValue(value);
        var route = getRouteFromMenuValue(value);
        if (route)
            navigate(route);
    };
    return (React.createElement(Tabs, { value: value, onChange: handleChange, variant: 'scrollable', scrollButtons: 'auto', className: 'flex bg-slate-100 border-0 border-y border-[#d6d6d6] border-solid' },
        React.createElement(Tab, { icon: React.createElement(CategoryOutlinedIcon, null), label: 'General' }),
        React.createElement(Tab, { icon: React.createElement(InsertPhotoOutlinedIcon, null), label: 'Logotipo' }),
        React.createElement(Tab, { icon: React.createElement(StorefrontIcon, null), label: 'Ecommerce' }),
        React.createElement(Tab, { icon: React.createElement(DvrIcon, null), label: 'Gesti\u00F3n de colas' }),
        React.createElement(Tab, { icon: React.createElement(ViewQuiltIcon, null), label: 'M\u00F3dulos' }),
        React.createElement(Tab, { icon: React.createElement(WhatsAppIcon, null), label: 'Twilio Whatsapp API' })));
};
export default MenuCompanyLayoutComponent;
