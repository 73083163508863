var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useEffect, useState } from 'react';
import { useReactiveVar } from '@apollo/client';
import { formatSaleV2, branchSaleV2 as setValues } from '../../../../../../apollo/cache';
import getSaleFormatLabel from '../../../../../../helpers/getSaleFormatLabel';
import SelectFieldComponent from '../../../../../../presentationals/FormComponents/SelectField';
import SaleBranchBillingInformationComponent from './BillingInformation';
import getNewCart from '../../../../helpers/getNewCart';
var BodyFormBranchActionSaleV2Component = function (props) {
    var formatSaleV2Var = useReactiveVar(formatSaleV2);
    var values = useReactiveVar(setValues);
    var _a = useState('indefinido'), formatLabel = _a[0], setFormatLabel = _a[1];
    useEffect(function () {
        if (values.branchInventory || values.branchSale)
            getNewCart({ branchInventory: values.branchInventory, branchSale: values.branchSale });
    }, [values.branchInventory, values.branchSale]);
    useEffect(function () {
        var saleFormat = parseInt(formatSaleV2Var[0].toString());
        var saleFormatLabel = getSaleFormatLabel(saleFormat, 'lowercase');
        setFormatLabel(saleFormatLabel);
    }, [formatSaleV2Var]);
    var handleChange = function (name, value) {
        var _a;
        setValues(__assign(__assign({}, values), (_a = {}, _a[name] = value, _a)));
    };
    return (React.createElement("div", { className: 'px-4 pb-4 grid grid-cols-1 sm:grid-cols-4 flex-1 gap-0 sm:gap-4' },
        React.createElement("div", { className: 'flex flex-1 flex-col' },
            React.createElement(SelectFieldComponent, { label: "\u00BFA que sucursal se le asignar\u00E1 esta ".concat(formatLabel, "?"), name: 'branchSale', value: values.branchSale, options: props.Branches.map(function (el) { return ({ value: el.id, label: el.name }); }), onChange: handleChange }),
            React.createElement(SelectFieldComponent, { label: '\u00BFA que sucursal se le descontar\u00E1 el inventario?', name: 'branchInventory', value: values.branchInventory, options: props.Branches.map(function (el) { return ({ value: el.id, label: el.name }); }), onChange: handleChange })),
        React.createElement("div", { className: 'mt-3' }, parseInt(formatSaleV2Var[0].toString()) === -1 && (React.createElement("div", { className: 'border border-solid rounded border-slate-300 p-3' },
            React.createElement(SaleBranchBillingInformationComponent, null))))));
};
export default BodyFormBranchActionSaleV2Component;
