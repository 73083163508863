import { gql } from '@apollo/client';

const FRAGMENT_INVOICE = gql`
  fragment fragmentInvoice on Invoice {
    id
    uuid
    status
    serial
    folio
    folio2
    subtotal
    discount
    taxes
    total
    createdAt
    paymentMethod
    OriginalInvoice {
      folio
      serial
    }
    PaymentMethod {
      name
    }
    Sale {
      id
      serial
      folio
      Customer {
        firstName
        lastName
      }
    }
  }
`;

const CREATE = gql`
  ${FRAGMENT_INVOICE}
  mutation CreateInvoice(
    $saleId: ID!
    $paymentMethod: String!
    $useCfdi: String!
    $paymentForm: String!
    $relateUUID: String
    $relateType: String
    $customerId: ID
  ) {
    createInvoice(
      input: {
        saleId: $saleId
        paymentMethod: $paymentMethod
        useCfdi: $useCfdi
        paymentForm: $paymentForm
        relateUUID: $relateUUID
        relateType: $relateType
        customerId: $customerId
      }
    ) {
      ...fragmentInvoice
    }
  }
`;

const GET = gql`
  ${FRAGMENT_INVOICE}
  query Invoices(
    $name: String
    $limit: Int
    $offset: Int
    $start: Date
    $end: Date
    $branchId: ID
    $order: [String]
    $paymentMethodId: ID
    $type: Int
    $status: Int
    $paymentMethod: String
  ) {
    invoices(
      like: $name
      limit: $limit
      offset: $offset
      start: $start
      end: $end
      branchId: $branchId
      order: $order
      paymentMethodId: $paymentMethodId
      type: $type
      status: $status
      paymentMethod: $paymentMethod
    ) {
      count
      totals {
        id
        name
        qty
        amount
      }
      rows {
        ...fragmentInvoice
      }
    }
  }
`;

const CREATE_CREDIT_NOTE = gql`
  mutation CreateCreditNote(
    $invoiceId: ID!
    $type: Int
    $reference: String!
    $commentary: String
    $bonus: Float
    $ivaTax: Int
    $Products: [ProductCreditNoteData]
  ) {
    createCreditNote(
      id: $invoiceId
      input: {
        type: $type
        reference: $reference
        commentary: $commentary
        bonus: $bonus
        ivaTax: $ivaTax
        Products: $Products
      }
    ) {
      id
      uuid
    }
  }
`;

export default { FRAGMENT_INVOICE, CREATE, GET, CREATE_CREDIT_NOTE };
