import React, { useState, useEffect } from 'react';
import { useMutation, useReactiveVar, useQuery } from '@apollo/client';

import Grid from '@mui/material/Grid';
import Chip from '@mui/material/Chip';

import LinearForm from '../../../../presentationals/LinearForm';
import TextField from '../../../../presentationals/TextField';
import DateField from '../../../../presentationals/DateField';
import SelectField from '../../../../presentationals/SelectField';
import Autocomplete from '../../../../presentationals/Autocomplete';

import { parseError } from '../../../../helpers/general';
import { discountType } from '../../../../apollo/cache';

import GQL from '../../_gql';
import GQL_PRODUCT from '../../../product/_gql';
import useSnackbarDialog from '../../../../store/snackbar/snackbarDialog';

const defaultValues = {
  type: 1,
  quantity: 1,
  unitPrice: 0,
  discount: 0,
  name: '',
  outDate: new Date(),
  productId: null,
};

const defaultSnackbar = { isOpen: true, time: 3000 };

const Form = props => {
  const { setSnackbar } = useSnackbarDialog();
  const discountTypeVar = useReactiveVar(discountType);

  const [values, setValues] = useState(defaultValues);
  const [errors, setErrors] = useState({});
  const [ivaPercent, setIvaPercent] = useState(0);
  const [iepsPercent, setIepsPercent] = useState(0);
  const [addTaxes, setAddTaxes] = useState(false);
  const [products, setProducts] = useState([]);

  const [createMaintenanceExpense] = useMutation(GQL.CREATE_MAINTENANCE_EXPENSE, {
    update(cache, { data }) {
      cache.modify({
        fields: {
          maintenanceExpenses(cachedMaintenance, { fieldName, storeFieldName }) {
            const args = JSON.parse(storeFieldName.replace(`${fieldName}(`, '').slice(0, -1));
            if (args && args.maintenanceId && parseInt(args.maintenanceId) === parseInt(props.id)) {
              const newMaintenanceProductsRef = cache.writeFragment({
                data: { ...data.createMaintenanceExpense },
                fragment: GQL.FRAGMENT_NEW_MAINTENANCE_EXPENSE_PRODUCT,
              });
              console.log(cachedMaintenance.rows);
              return {
                ...cachedMaintenance,
                count: cachedMaintenance.count + 1,
                rows: [...cachedMaintenance.rows, newMaintenanceProductsRef],
              };
            }
            return cachedMaintenance;
          },
        },
      });
    },
  });

  useQuery(GQL_PRODUCT.GET_NAME_AND_PRESENTATIONS, {
    variables: { limit: 0 },
    onCompleted: data => {
      const tmp = data.productsV2.rows.map(el => ({
        label: `${String(el.code).padStart(3, '0')} ${el.name.trim()} ${
          el.sku ? ` | SKU ${el.sku.trim()}` : ''
        } ${el.barcode ? ` | C.B. ${el.barcode.trim()}` : ''}`.trim(),
        value: el.id,
      }));
      setProducts(tmp);
    },
  });

  useEffect(() => {
    setValues(defaultValues);
    setIvaPercent(0);
    setIepsPercent(0);
    setAddTaxes(false);
  }, [props.codeToClear]);

  const handleChange = e => {
    const { name, value } = e.target;
    setValues(values => ({ ...values, [name]: value }));
  };
  const handleAddProduct = async () => {
    try {
      if (parseInt(values.type) === 1 && !values.productId) {
        const e = new Error('Selecciona un producto.');
        e.name = 'productId';
        e.input = true;
        throw e;
      }

      if (parseInt(values.type) === 2 && (!values.name || values.name === '')) {
        const e = new Error('Escribe un gasto.');
        e.name = 'name';
        e.input = true;
        throw e;
      }

      if (parseFloat(values.quantity) <= 0 || Number.isNaN(Number(values.quantity))) {
        const e = new Error('Proporciona la cantidad de gastos.');
        e.name = 'quantity';
        e.input = true;
        throw e;
      }

      if (values.discount < 0) {
        const e = new Error('Proporciona un descuento mayor o igual a cero.');
        e.name = 'discount';
        e.input = true;
        throw e;
      }

      if (values.discount > 100 && discountTypeVar === '%') {
        const e = new Error('Proporciona un descuento menor al 100%.');
        e.name = 'discount';
        e.input = true;
        throw e;
      }

      if (
        !values.unitPrice ||
        parseFloat(values.unitPrice) < 0 ||
        Number.isNaN(Number(values.unitPrice))
      ) {
        const e = new Error('Proporciona el precio del gasto.');
        e.name = 'unitPrice';
        e.input = true;
        throw e;
      }

      const amount = parseFloat(values.quantity) * parseFloat(values.unitPrice);

      let tmpDiscount = 0;

      if (discountTypeVar === '%') tmpDiscount = values.discount;
      else if (discountTypeVar === '$') tmpDiscount = (parseFloat(values.discount) / amount) * 100;

      const price = addTaxes
        ? parseFloat(values.unitPrice)
        : parseFloat(values.unitPrice) / (1 + parseFloat(ivaPercent) + parseFloat(iepsPercent));

      await createMaintenanceExpense({
        variables: {
          maintenanceId: props.id,
          name: values.name.trim(),
          quantity: parseFloat(values.quantity),
          discount: parseFloat(tmpDiscount),
          price,
          iva: parseFloat(ivaPercent) * 100,
          ieps: parseFloat(iepsPercent) * 100,
          outDate: values.outDate ? values.outDate : null,
          type: values.type,
          productId: parseInt(values.type) === 1 ? values.productId.value : null,
        },
      });
      setValues(defaultValues);
      setErrors({});
      setIvaPercent(0);
      setIepsPercent(0);
      setAddTaxes(false);
    } catch (e) {
      console.log(e);
      if (e.input) {
        setErrors({ [e.name]: e.message });
      } else {
        console.log(e);
        const parseErrors = parseError(e);
        parseErrors.forEach(el => {
          if (el.name === 'BAD_USER_INPUT') {
            const key = Object.keys(el.message)[0];
            setSnackbar({ ...defaultSnackbar, label: el.message[key], severity: 'error' });
          } else {
            setSnackbar({ ...defaultSnackbar, label: el.message, severity: 'error' });
          }
        });
      }
    }
  };

  const handleIvaPercent = () => {
    const tmpIvaPercent = ivaPercent === 0 ? 0.16 : 0;
    setIvaPercent(tmpIvaPercent);
  };

  const handleIepsPercent = () => {
    const tmpIepsPercent = iepsPercent === 0 ? 0.08 : 0;
    setIepsPercent(tmpIepsPercent);
  };

  const handleTaxes = () => {
    setAddTaxes(addTaxes => !addTaxes);
  };

  const handleDateChange = (name, value) => {
    setValues(values => ({ ...values, [name]: value }));
  };

  const handleAutocompleteChange = (e, name, value) => {
    setValues(values => ({ ...values, [name]: value }));
  };

  return (
    <LinearForm handleAdd={handleAddProduct} align='center'>
      <Grid container spacing={1}>
        <Grid item xs={2} md={2}>
          <SelectField
            name='type'
            label='Tipo'
            variant='outlined'
            onChange={handleChange}
            value={values.type}
            error={errors.type}
            options={[
              { value: 1, label: 'Inventario' },
              { value: 2, label: 'Libre' },
            ]}
            noFormat
            size='small'
          />
        </Grid>
        <Grid item xs={10} md={10}>
          {parseInt(values.type) === 1 ? (
            <Autocomplete
              name='productId'
              label='Producto'
              variant='outlined'
              onChange={handleAutocompleteChange}
              value={values.productId}
              error={errors.productId}
              options={products}
              noFormat
            />
          ) : (
            <TextField
              name='name'
              label='Concepto'
              variant='outlined'
              onChange={handleChange}
              value={values.name}
              error={errors.name}
              noFormat
            />
          )}
        </Grid>
        <Grid item xs={2} className='flex flex-1 justify-between items-center'>
          <Chip
            className='h-20 flex flex-1 mr-1'
            onClick={handleIvaPercent}
            label={`IVA ${ivaPercent * 100}%`}
            classes={{ label: 'px-2' }}
            color='primary'
            variant='outlined'
          />
          <Chip
            className='h-20 flex flex-1 mr-1'
            onClick={handleIepsPercent}
            label={`IEPS ${iepsPercent * 100}%`}
            classes={{ label: 'px-2' }}
            color='primary'
            variant='outlined'
          />
        </Grid>
        <Grid item xs={2} className='flex flex-1 justify-between items-center'>
          <Chip
            className='h-20 flex flex-1 mr-1'
            onClick={handleTaxes}
            label={addTaxes ? 'Añadir Impuestos' : 'Desglosar Impuestos'}
            classes={{ label: 'px-2' }}
            color='secondary'
            variant='outlined'
          />
        </Grid>
        <Grid item xs={4} md={2}>
          <TextField
            name='quantity'
            label='Cant.'
            type='number'
            variant='outlined'
            onChange={handleChange}
            value={values.quantity}
            error={errors.quantity}
            noFormat
          />
        </Grid>
        <Grid item xs={4} md={2}>
          <TextField
            name='discount'
            label={`Dscto. ${discountTypeVar}`}
            type='number'
            variant='outlined'
            onChange={handleChange}
            value={values.discount}
            error={errors.discount}
            noFormat
          />
        </Grid>
        <Grid item xs={4} md={2}>
          <TextField
            name='unitPrice'
            label='Precio Unitario'
            variant='outlined'
            type='number'
            onChange={handleChange}
            value={values.unitPrice}
            error={errors.unitPrice}
            noFormat
          />
        </Grid>
        <Grid item xs={4} md={2}>
          <DateField
            name='outDate'
            label='Fecha de Salida'
            variant='outlined'
            onChange={handleDateChange}
            value={values.outDate}
            error={errors.outDate}
            noFormat
            size='small'
          />
        </Grid>
      </Grid>
    </LinearForm>
  );
};

export default Form;
