import React from 'react';
import { useNavigate } from 'react-router-dom';
import Container from '../../presentationals/Module.v2/Container';
import GQL from './_gql';
import Filter from './_filter';
import Action from './_action';

const title = 'Gestión de gastos';
const operation = ['operingExpense', 'operingExpenses'];

const Rows = [
  { label: 'Creación', key: 'createdAt', sort: 'text', format: 'dateTime' },
  { label: 'Fecha', key: 'date', sort: 'text', format: 'dateTime' },
  { label: 'Folio', key: 'serial', prefix: 'GO', padStart: [5, '0'], strcat: '-', sort: 'text' },
  { label: '# Compra', key: 'subFolio', sort: 'text' },
  { label: 'Proveedor', key: 'Provider name' },
  { label: 'Responsable', key: 'Employee firstName && Employee lastName' },
  { label: 'Cuenta', key: 'TransactionAccount name' },
  { label: 'Subtotal', key: 'subtotal', sort: 'text', format: 'currency', align: 'right' },
  { label: 'Descuento', key: 'discount', sort: 'text', format: 'currency', align: 'right' },
  { label: 'IVA', key: 'ivaTotal', sort: 'text', format: 'currency', align: 'right' },
  { label: 'IEPS', key: 'iepsTotal', sort: 'text', format: 'currency', align: 'right' },
  { label: 'Importe', key: 'total', sort: 'text', format: 'currency', align: 'right' },
  {
    label: 'Abonado',
    key: 'AccountPayable payment',
    sort: 'text',
    format: 'currency',
    align: 'right',
  },
  {
    label: 'Saldo',
    key: 'AccountPayable balance',
    sort: 'text',
    format: 'currency',
    align: 'right',
  },
  { label: 'Anotación', key: 'description', wrap: true },
];

const OperatingExpensesComponent = () => {
  const navigate = useNavigate();
  const handleCustomCreate = () => {
    navigate('create');
  };
  return (
    <>
      <Container
        title={title}
        operation={operation}
        Rows={Rows}
        get={GQL.GET}
        create={GQL.CREATE}
        noDelete
        noEdit
        filter
        handleCustomCreate={handleCustomCreate}
      />
      {/* <Form /> */}
      <Filter />
      <Action />
    </>
  );
};

export default OperatingExpensesComponent;
