import { Button } from '@mui/base';
import React from 'react';
import { isAppsMenuOpen } from '../../../../../apollo/cache';
var AppMenuButtonComponent = function (props) {
    var handleClick = function () {
        isAppsMenuOpen([false, null]);
        props.onClick();
    };
    return (React.createElement(Button, { onClick: handleClick, className: 'bg-transparent border-none flex flex-col items-center justify-center py-4 m-3 hover:bg-[#f0f7fe] rounded text-slate-700' },
        props.icon,
        React.createElement("div", { className: 'mt-1 text-sm' }, props.label)));
};
export default AppMenuButtonComponent;
