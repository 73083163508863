var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { format } from 'date-fns';
var getScheduleStructurePerMinutes = function (schedulePerHour, scheduleArray, minutes, hour) {
    var scheduleInTime = scheduleArray.filter(function (el) { return format(new Date(el.start), 'hh:mmaaa') === hour; });
    return Array.from(Array(schedulePerHour).keys()).reduce(function (array) {
        var currentSchedule = array
            .map(function (el) { var _a; return (((_a = el === null || el === void 0 ? void 0 : el.schedule) === null || _a === void 0 ? void 0 : _a.id) ? el.schedule.id : null); })
            .filter(function (el) { return el; });
        var schedule = scheduleInTime.reduce(function (result, key) {
            if (result)
                return result;
            return !currentSchedule.includes(key.id) ? key : result;
        }, null);
        return __spreadArray(__spreadArray([], array, true), [
            {
                minutes: minutes,
                hour: hour,
                schedule: schedule,
            },
        ], false);
    }, []);
};
var getScheduleStrutucture = function (schedulePerHour, scheduleArray, hour) {
    var newSchedules00 = getScheduleStructurePerMinutes(schedulePerHour[0], scheduleArray, '00', hour[0]);
    var newSchedules30 = getScheduleStructurePerMinutes(schedulePerHour[1], scheduleArray, '30', hour[1]);
    return __spreadArray(__spreadArray([], newSchedules00, true), newSchedules30, true);
};
export default getScheduleStrutucture;
