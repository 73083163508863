import React, { useEffect, useState } from 'react';
import { Area, AreaChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis, } from 'recharts';
import { useReactiveVar } from '@apollo/client';
import { Link } from '@mui/material';
import getFormattedSaleByHourData from './helpers/getFormattedSaleByHourData';
import useGetBranchById from '../../../hooks/useGetBranchById';
import { filter } from '../../../apollo/cache';
var SaleByHourChartIncomeReportComponent = function (props) {
    var _a = useState([]), data = _a[0], setData = _a[1];
    var filterVar = useReactiveVar(filter);
    var Branch = useGetBranchById(filterVar.branchId).data;
    var _b = useState(true), chartByTotal = _b[0], setCharByTotal = _b[1];
    useEffect(function () {
        if (props.Sales && props.Sales.length > 0 && Branch) {
            var data_1 = getFormattedSaleByHourData(props.Sales, chartByTotal, {
                end: Branch.endHour,
                start: Branch.startHour,
            });
            setData(data_1);
        }
    }, [props.Sales, chartByTotal, Branch]);
    var handleClick = function () {
        setCharByTotal(function (chartByTotal) { return !chartByTotal; });
    };
    return (React.createElement("div", { className: 'flex flex-1 mt-4 flex-col min-h-[300px] sm:min-h-0 overflow-hidden' },
        React.createElement("div", { className: 'flex justify-between items-end text-sm' },
            React.createElement("div", { className: 'text-lg' }, "Ventas por hora"),
            React.createElement(Link, { onClick: handleClick, className: ' text-sky-600 no-underline hover:underline' }, "Ver por ".concat(chartByTotal ? 'promedio' : 'totales'))),
        React.createElement("div", { className: 'flex flex-1 max-w-full overflow-hidden bg-slate-50 rounded-lg mt-2 border-solid border-slate-200 p-4' },
            React.createElement(ResponsiveContainer, { width: '100%', height: '100%' },
                React.createElement(AreaChart, { data: data, margin: {
                        top: 10,
                        right: 30,
                        left: 0,
                        bottom: 0,
                    } },
                    React.createElement(CartesianGrid, { strokeDasharray: '3 3' }),
                    React.createElement(XAxis, { dataKey: 'name' }),
                    React.createElement(YAxis, null),
                    React.createElement(Tooltip, null),
                    React.createElement(Area, { name: 'Total', type: 'monotone', dataKey: 'total', stroke: '#1376c7', fill: '#1376c7', fillOpacity: 1 }))))));
};
export default SaleByHourChartIncomeReportComponent;
