var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import GQL from '../containers/role/_gql';
var useGetRoles = function (variables, format) {
    var _a = useState({ count: 0, rows: [] }), formattedData = _a[0], setFormattedData = _a[1];
    var _b = useQuery(GQL.GET, {
        variables: __assign(__assign({}, variables), { limit: 0, offset: 0 }),
    }), data = _b.data, loading = _b.loading, error = _b.error;
    useEffect(function () {
        if (data && data.roles) {
            var _a = data.roles, count = _a.count, rows = _a.rows;
            var formattedRows = rows;
            switch (format) {
                case 'select':
                    formattedRows = rows.map(function (el) { return ({
                        label: el.name,
                        value: el.id,
                    }); });
                    break;
                default:
                    break;
            }
            setFormattedData({ count: count, rows: formattedRows });
        }
    }, [data, format]);
    return { data: formattedData, loading: loading, error: error };
};
export default useGetRoles;
