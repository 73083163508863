import React, { useEffect } from 'react';

import { useReactiveVar } from '@apollo/client';

import {
  customerSaleV2,
  formatSaleV2,
  invoiceSaleV2,
  inputError,
} from '../../../../../../apollo/cache';

import SelectFieldComponent from '../../../../../../presentationals/FormComponents/SelectField';

import useGetUseCfdi from '../../../../../../hooks/useGetUseCfdi';
import useGetPaymentForm from '../../../../../../hooks/useGetSatPaymentForm';
import useGetPaymentMethod from '../../../../../../hooks/useGetSatPaymentMethod';
import useApolloLazyQuery from '../../../../../../hooks/useApolloLazyQuery';

import GQL from '../../../../../customer/_gql';
import getInputErrorFormat from '../../../../Action/helpers/getInputErrorFormat';

const InvoiceFormPaymentFormBodyConfirmDialogSaleV2Component = () => {
  const paymentMethodOptions = useGetPaymentMethod();
  const getPaymentFormOptions = useGetPaymentForm();
  const getUseCfdiOptions = useGetUseCfdi();

  const [getData] = useApolloLazyQuery(GQL.GET_BY_ID);

  const errors = useReactiveVar(inputError);
  const formatSaleV2Var = useReactiveVar(formatSaleV2);
  const invoiceSaleV2Var = useReactiveVar(invoiceSaleV2);
  const customerSaleV2Var = useReactiveVar(customerSaleV2);

  useEffect(() => {
    const f = async () => {
      try {
        const { data } = await getData({ variables: { id: customerSaleV2Var.id } });
        if (data && data.CustomerBillingInformation) {
          const { paymentMethod, useCfdi, paymentForm } = data.CustomerBillingInformation;
          invoiceSaleV2({
            ...invoiceSaleV2Var,
            paymentMethod: paymentMethod || invoiceSaleV2Var.paymentMethod || '',
            paymentForm: paymentForm || invoiceSaleV2Var.paymentForm || '',
            useCfdi: useCfdi || invoiceSaleV2Var.useCfdi,
          });
        }
      } catch (e) {
        getInputErrorFormat(e);
      }
    };
    if (customerSaleV2Var.id) f();
  }, [customerSaleV2Var]);

  const handleChange = (name, value) => {
    if (Object.keys(errors).length > 0) inputError({});
    invoiceSaleV2({ ...invoiceSaleV2Var, [name]: value });
  };

  if (!formatSaleV2Var[2]) return <div />;
  return (
    <div className='flex flex-col'>
      {paymentMethodOptions.length > 0 && (
        <SelectFieldComponent
          label='Método de pago'
          name='paymentMethod'
          value={invoiceSaleV2Var.paymentMethod}
          onChange={handleChange}
          error={errors.paymentMethod}
          options={paymentMethodOptions}
        />
      )}
      {getPaymentFormOptions.length > 0 && (
        <SelectFieldComponent
          label='Forma de pago'
          name='paymentForm'
          value={invoiceSaleV2Var.paymentForm}
          onChange={handleChange}
          error={errors.paymentForm}
          options={getPaymentFormOptions}
        />
      )}
      {getUseCfdiOptions.length > 0 && (
        <SelectFieldComponent
          label='Uso del CFDi'
          name='useCfdi'
          value={invoiceSaleV2Var.useCfdi}
          onChange={handleChange}
          error={errors.useCfdi}
          options={getUseCfdiOptions}
        />
      )}
    </div>
  );
};

export default InvoiceFormPaymentFormBodyConfirmDialogSaleV2Component;
