import React from 'react';
import SelectFieldComponent from '../../../../../../../presentationals/FormComponents/SelectField';
var CreditNoteQuantityFieldComponent = function (props) {
    var availableQty = [
        { value: '1', label: '1' },
        { value: '2', label: '2' },
        { value: '3', label: '3' },
        { value: '4', label: '4' },
        { value: '5', label: '5' },
        { value: '6', label: '6' },
        { value: '7', label: '7' },
        { value: '8', label: '8' },
        { value: '9', label: '9' },
    ];
    return (React.createElement(SelectFieldComponent, { name: 'quantity', label: 'Cantidad a devolver', value: props.value, options: availableQty, onChange: props.onChange }));
};
export default CreditNoteQuantityFieldComponent;
