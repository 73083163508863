import React from 'react';
import { useReactiveVar } from '@apollo/client';

import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';

import { currencyFormat } from '../../../../../../../helpers';
import { adjustPricePurchase, editPurchaseProduct } from '../../../../../../../apollo/cache';

const TotalsComponent = props => {
  const adjustPricePurchaseVar = useReactiveVar(adjustPricePurchase);
  const editPurchaseProductVar = useReactiveVar(editPurchaseProduct);

  // EN CASO DE OCUPAR DE NUEVO AJUSTAR EL PRECIO UTILIZANDO EL PORCENTAJE PONER
  // colSpan={editPurchaseProductVar ? (adjustPricePurchaseVar ? 10 : 8) : 7}
  return (
    <>
      <TableRow>
        <TableCell
          colSpan={editPurchaseProductVar ? (adjustPricePurchaseVar ? 10 : 7) : 7}
          align='right'
          className='bg-[#f5f5f5]'
        >
          Subtotal:
        </TableCell>
        <TableCell align='right' className='bg-[#f5f5f5]'>
          {`$${currencyFormat(props.totals.subtotal)}`}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell
          colSpan={editPurchaseProductVar ? (adjustPricePurchaseVar ? 10 : 7) : 7}
          align='right'
          className='bg-[#f5f5f5]'
        >
          IVA:
        </TableCell>
        <TableCell align='right' className='bg-[#f5f5f5]'>
          {`$${currencyFormat(props.totals.iva)}`}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell
          colSpan={editPurchaseProductVar ? (adjustPricePurchaseVar ? 10 : 7) : 7}
          align='right'
          className='bg-[#f5f5f5]'
        >
          IEPS:
        </TableCell>
        <TableCell align='right' className='bg-[#f5f5f5]'>
          {`$${currencyFormat(props.totals.ieps)}`}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell
          colSpan={editPurchaseProductVar ? (adjustPricePurchaseVar ? 10 : 7) : 7}
          align='right'
          className='bg-[#f5f5f5]'
        >
          <strong>Total:</strong>
        </TableCell>
        <TableCell align='right' className='bg-[#f5f5f5]'>
          <strong>{`$${currencyFormat(props.totals.total)}`}</strong>
        </TableCell>
      </TableRow>
    </>
  );
};

export default TotalsComponent;
