import React, { useState, useEffect } from 'react';

import { useMutation } from '@apollo/client';

import InsertInvitationIcon from '@mui/icons-material/InsertInvitationOutlined';

import FormDialog from '../../presentationals/FormDialog';
import TextField from '../../presentationals/TextField';
import { parseError } from '../../helpers';
import GQL from './_gql';
import useSnackbarDialog from '../../store/snackbar/snackbarDialog';

const defaultValues = {
  timeLimit: 0,
};

const Component = props => {
  const { setSnackbar } = useSnackbarDialog();
  const [values, setValues] = useState(defaultValues);
  const [errors, setErrors] = useState({});

  const [updateAccount, { loading: loadingCreate }] = useMutation(GQL.UPDATE);
  useEffect(() => {
    if (props.data)
      setValues(values => ({
        ...values,
        timeLimit:
          parseInt(props.data.timeLimit) > 0
            ? props.data.timeLimit
            : props.data.Customer.creditDays,
      }));
  }, [props.data]);

  const handleClose = () => {
    props.handleClose();
    setValues(defaultValues);
  };

  const handleChange = e => {
    const { name, value } = e.target;
    setValues(values => ({ ...values, [name]: value }));
  };

  const handleAction = async () => {
    try {
      if (values.timeLimit === '' || parseInt(values.timeLimit) <= 0) {
        const timeLimitError = new Error('El plazo tiene que ser mayor a cero.');
        timeLimitError.name = 'total';
        timeLimitError.input = true;
        throw timeLimitError;
      }
      const variables = {
        id: props.id,
        timeLimit: parseInt(values.timeLimit),
        name: props.data.name || '',
        type: props.data.type,
        total: props.data.total,
        customerId: props.data.customerId,
      };
      await updateAccount({ variables });
      setValues(defaultValues);
      handleClose();
    } catch (e) {
      if (e.input) {
        setErrors({ [e.name]: e.message });
        return;
      }
      const parseErrors = parseError(e);
      parseErrors.forEach(el => {
        if (el.name === 'BAD_USER_INPUT') setErrors(el.message);
        else
          setSnackbar({
            variables: {
              isOpen: true,
              time: 3000,
              label: el.message,
              severity: 'error',
            },
          });
      });
    }
  };

  return (
    <FormDialog
      title='Configuración de la cuenta por pagar'
      isOpen={props.isOpen}
      handleClose={handleClose}
      handleAction={handleAction}
      isLoading={loadingCreate}
    >
      <TextField
        icon={<InsertInvitationIcon />}
        value={values.timeLimit}
        error={errors.timeLimit}
        name='timeLimit'
        label='Plazo'
        onChange={handleChange}
        type='number'
        required
        autoFocus
        // options={timeLimitOptions}
      />
    </FormDialog>
  );
};

export default Component;
