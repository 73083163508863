import React, { useState, useEffect } from 'react';

import { useReactiveVar } from '@apollo/client';

import SearchIcon from '@mui/icons-material/SearchOutlined';
import IconButton from '@mui/material/IconButton';
import InputBase from '@mui/material/InputBase';
import Paper from '@mui/material/Paper';

import { filter } from '../../../../apollo/cache';
import SelectSearchInputComponent from '../../../Module.v3/Header/SearchInput/SelectInput';
import setDefaultSearchBy from '../../../Module.v3/Header/SearchInput/helpers/setDefaultSearchBy';

const SearchButtonComponent = props => {
  const filterVar = useReactiveVar(filter);

  const title = props.title ? `Buscar ${props.title && props.title.toLowerCase()}` : '';

  const [value, setValue] = useState('');

  useEffect(() => {
    return () => {
      setValue('');
      filter({});
    };
  }, []);

  const handleChange = e => {
    const { value } = e.target;
    setValue(value);
  };

  const handleClick = () => {
    if (value && value !== '') {
      if (props.searchFieldOptions) {
        const searchBy = setDefaultSearchBy(props.searchFieldOptions);
        filter({ ...filterVar, name: [searchBy, value] });
        return;
      }
      filter({ ...filterVar, name: value });
    } else {
      const newLocalFilter = Object.keys(filterVar).reduce((object, key) => {
        return key !== 'name' ? { ...object, [key]: filterVar[key] } : object;
      }, {});
      filter(newLocalFilter);
    }
  };

  const handleKeyDown = e => {
    if (e.key === 'Enter') handleClick();
  };

  return (
    <Paper className='w-full md:w-1/3 flex flex-1 ml-6' variant='outlined'>
      {props.searchFieldOptions && (
        <SelectSearchInputComponent {...props.searchFieldOptions} onClick={handleClick} />
      )}
      <InputBase
        className='flex flex-1 ml-2'
        placeholder={title}
        autoFocus={props.autoFocus}
        inputProps={{ 'aria-label': props.arialLabel || title || 'search-field' }}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
      />
      <IconButton onClick={handleClick} aria-label='search'>
        <SearchIcon />
      </IconButton>
    </Paper>
  );
};

export default SearchButtonComponent;
