import React, { useEffect, useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableRow } from '@mui/material';
import HeaderDetailedSalesByDayIncomeReportComponent from './Header';
import useApolloQuery from '../../../hooks/useApolloQuery';
import GQL_BRANCH from '../../../apollo/gql/branch';
import convertToCurrencyLabel from '../../../helpers/convertToCurrencyLabel';
import getSession from '../../../helpers/getSession';
import getDetailedSalesByDayArray from './helpers/getDetailedSalesByDayArray';
var DetailedSalesByDayIncomeReportComponent = function (props) {
    var _a = useApolloQuery(GQL_BRANCH.GET_BRANCHES), branches = _a.data, loadingBranches = _a.loading;
    var _b = useState([]), rows = _b[0], setRows = _b[1];
    useEffect(function () {
        if (branches && props.Sales) {
            var session = getSession();
            if (!props.Sales)
                return;
            if (!session || !session.Company)
                return;
            var Branch = session.Company.Branch;
            var rows_1 = getDetailedSalesByDayArray({
                Sales: props.Sales,
                branches: branches.rows,
                multiBranchPermissions: props.multiBranchPermissions,
                sessionBranchId: Branch.id,
            });
            setRows(rows_1);
        }
    }, [branches, props.Sales]);
    if (!props.Sales || loadingBranches)
        return React.createElement("div", null);
    return (React.createElement("div", { className: 'flex flex-col mt-2' },
        React.createElement("div", { className: 'text-lg' }, "Ventas diarias detalladas"),
        React.createElement(TableContainer, { className: 'border-solid border-slate-200 min-h-28' },
            React.createElement(Table, { size: 'small', stickyHeader: true },
                React.createElement(HeaderDetailedSalesByDayIncomeReportComponent, null),
                React.createElement(TableBody, null, rows.map(function (el) { return (React.createElement(TableRow, { hover: true, key: el.id },
                    React.createElement(TableCell, { className: 'whitespace-normal sm:whitespace-nowrap sticky left-0 bg-slate-100' }, el.name),
                    el.days.map(function (day) { return (React.createElement(TableCell, { className: 'whitespace-nowrap', key: day.day }, "(".concat(day.qty, ") ").concat(convertToCurrencyLabel(day.total)))); }),
                    React.createElement(TableCell, { className: 'whitespace-nowrap font-semibold  sticky right-0 bg-slate-200' }, "(".concat(el.qty, ") ").concat(convertToCurrencyLabel(el.total))))); }))))));
};
export default DetailedSalesByDayIncomeReportComponent;
