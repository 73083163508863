var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useMemo } from 'react';
import { Card } from '@mui/material';
import Table from './Table';
import getWidthPerColumn from './helpers/getWidthPerColumn';
import LoadingProgress from '../../LoadingProgress';
var BodyModuleV3Component = function (props) {
    var columns = useMemo(function () {
        var widthArray = getWidthPerColumn(props.data, props.header);
        return props.header.map(function (el, i) { return (__assign(__assign({}, el), { width: widthArray[i] })); });
    }, [props.data, props.header]);
    return (React.createElement(Card, { className: 'mt-2 flex flex-1 overflow-x-auto' }, props.isLoading ? (React.createElement(LoadingProgress, null)) : (React.createElement(Table, { actions: props.actions, columns: columns, data: props.data }))));
};
export default BodyModuleV3Component;
