import React from 'react';
import { useReactiveVar } from '@apollo/client';
import getDayByNumber from '../WorkingDaysField/helpers/getDayByNumber';
import { inputError, workingHoursForm } from '../../../../../apollo/cache';
import WorkHourWorkingHoursFieldComponent from './WorkHourField';
var WorkingHoursFieldComponent = function () {
    var errors = useReactiveVar(inputError);
    var workingHoursVar = useReactiveVar(workingHoursForm);
    return (React.createElement("div", { className: 'flex flex-col' }, workingHoursVar.map(function (el) { return (React.createElement("div", { key: el.day },
        React.createElement("div", { className: 'flex flex-row items-center my-1' },
            React.createElement("div", { className: 'w-20' }, getDayByNumber(el.day, 2)),
            React.createElement("div", { className: 'bg-gray-200 rounded flex flex-row overflow-hidden' },
                React.createElement(WorkHourWorkingHoursFieldComponent, { day: el.day, type: 'entry', hour: el.entry }),
                React.createElement("div", { className: 'p-2 pt-3' }, "a"),
                React.createElement(WorkHourWorkingHoursFieldComponent, { day: el.day, type: 'out', hour: el.out }))),
        errors[el.day] && React.createElement("div", { className: 'text-sm text-red-700 mb-2' }, errors[el.day]))); })));
};
export default WorkingHoursFieldComponent;
