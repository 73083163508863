import React, { useState } from 'react';
import { Tab, Tabs } from '@mui/material';
import { useReactiveVar } from '@apollo/client';
import setPurchaseOrderStatusFilterOption from './helpers/setPurchaseOrderStatusFilterOption';
import { isTableScrollLoading } from '../../../apollo/cache';
var ItemCategoriesPurchaseOrderComponent = function (props) {
    var isLoading = useReactiveVar(isTableScrollLoading);
    var _a = useState(0), value = _a[0], setValue = _a[1];
    var handleChange = function (e, value) {
        setValue(value);
        setPurchaseOrderStatusFilterOption(value);
    };
    return (React.createElement(Tabs, { value: value, onChange: handleChange, variant: 'scrollable', scrollButtons: 'auto', className: 'mt-1 bg-[#fff] border border-[#d6d6d6] border-solid' },
        React.createElement(Tab, { disabled: isLoading || props.isLoading, label: '1. Orden de compra' }),
        React.createElement(Tab, { disabled: isLoading || props.isLoading, label: '2. Autorizado' }),
        React.createElement(Tab, { disabled: isLoading || props.isLoading, label: '3. Enviado a proveedor' }),
        React.createElement(Tab, { disabled: isLoading || props.isLoading, label: '4. Entrega de productos' }),
        React.createElement(Tab, { disabled: isLoading || props.isLoading, label: '5. Recepci\u00F3n y verificaci\u00F3n' }),
        React.createElement(Tab, { disabled: isLoading || props.isLoading, label: '6. Finalizado' })));
};
export default ItemCategoriesPurchaseOrderComponent;
