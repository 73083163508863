import React from 'react';
import SelectFieldComponent from '../../../../../../../presentationals/FormComponents/SelectField';
var CreditNoteReferenceFieldComponent = function (props) {
    var availableReference = [
        { value: '01', label: 'Nota de crédito de los documentos relacionados' },
        { value: '02', label: 'Nota de débito de los documentos relacionados' },
        { value: '03', label: 'Devolución de mercancía sobre facturas o traslados previos' },
        { value: '04', label: 'Sustitución de los CFDI previos' },
        { value: '05', label: 'Traslados de mercancias facturados previamente' },
        { value: '06', label: 'Factura generada por los traslados previos' },
        { value: '07', label: 'CFDI por aplicación de anticipo' },
        { value: '08', label: 'Factura generada por pagos en parcialidades' },
        { value: '09', label: 'Factura generada por pagos diferidos' },
    ];
    return (React.createElement(SelectFieldComponent, { name: 'reference', label: 'Tipo de relaci\u00F3n (SAT)', value: props.value, options: availableReference, onChange: props.onChange }));
};
export default CreditNoteReferenceFieldComponent;
