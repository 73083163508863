import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import logo from '../../assets/static/logo.png';
import MenuOnlineInvoiceComponent from './Menu';
import CreateOnlineInvoiceComponent from './Create';
import DownloadOnlineInvoiceComponent from './Download';
var OnlineInvoiceComponent = function () {
    var _a = useState(0), step = _a[0], setStep = _a[1];
    var handleChange = function (step) {
        setStep(step);
    };
    return (React.createElement("div", { className: 'flex flex-1 flex-col bg-slate-50 h-screen' },
        React.createElement("div", { className: 'px-2 py-4' },
            React.createElement(Link, { to: '.' },
                React.createElement("img", { src: logo, alt: 'Capitalike', className: 'h-5 ml-3 hidden md:flex' }))),
        React.createElement(MenuOnlineInvoiceComponent, { onChange: handleChange }),
        React.createElement("div", { className: 'flex-1 flex-col flex' },
            step === 0 && React.createElement(CreateOnlineInvoiceComponent, null),
            step === 1 && React.createElement(DownloadOnlineInvoiceComponent, null))));
};
export default OnlineInvoiceComponent;
