import { Avatar } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useReactiveVar } from '@apollo/client';
import getDayByNumber from '../helpers/getDayByNumber';
import { workingHoursForm } from '../../../../../../apollo/cache';
import getNewWorkingDaysArray from '../helpers/getNewWorkingDaysArray';
import sortByType from '../../../../../../helpers/sortByType';
var ItemWorkingDaysFielComponent = function (props) {
    var workingHoursFormVar = useReactiveVar(workingHoursForm);
    var _a = useState(false), isSelected = _a[0], setIsSelected = _a[1];
    useEffect(function () {
        var value = workingHoursFormVar.find(function (el) { return el.day === props.day; });
        setIsSelected(!!(value && value.entry >= 0));
    }, [workingHoursFormVar]);
    var handleChange = function () {
        var newArray = getNewWorkingDaysArray(props.day, workingHoursFormVar);
        workingHoursForm(sortByType(newArray, 'day', 'number', 'desc'));
        setIsSelected(function (isSelected) { return !isSelected; });
    };
    return (React.createElement(Avatar, { className: "mr-2 ".concat(isSelected ? 'bg-sky-600' : ''), onClick: handleChange }, getDayByNumber(props.day)));
};
export default ItemWorkingDaysFielComponent;
