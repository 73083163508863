import React from 'react';
import { TableRow } from '@mui/material';
import { isEditProductLimitInventoryOpen } from '../../../../../../../apollo/cache';
var TableRowItemLimitInventoryProductPresentationComponent = function (props) {
    var handleClick = function () {
        isEditProductLimitInventoryOpen(props.productPresentationLimitInventoryId);
    };
    return (React.createElement(TableRow, { hover: true, className: "cursor-pointer ".concat(props.background), onClick: handleClick }, props.children));
};
export default TableRowItemLimitInventoryProductPresentationComponent;
