var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import { useReactiveVar } from '@apollo/client';
import SubtitleDialogComponent from '../../../../presentationals/Dialog/helpers/Subtitle';
import TextFieldComponent from '../../../../presentationals/FormComponents/TextField';
import { companyForm, inputError } from '../../../../apollo/cache';
import SelectFieldComponent from '../../../../presentationals/FormComponents/SelectField';
import countryOptions from '../../../../helpers/countryOptions';
import UploadImageCreateCompanyComponent from './UploadImage';
var companyActivityOptions = [
    { label: 'Comercio', value: 1 },
    { label: 'Food Truck', value: 4 },
    { label: 'Restaurante', value: 2 },
    { label: 'Salud', value: 3 },
];
var BodyCreateCompanyComponent = function () {
    var values = useReactiveVar(companyForm);
    var errors = useReactiveVar(inputError);
    var handleChange = function (name, value) {
        var _a;
        companyForm(__assign(__assign({}, values), (_a = {}, _a[name] = value, _a)));
    };
    return (React.createElement("div", { className: 'flex-1 px-6 py-4 flex flex-col md:grid md:grid-cols-4 md:gap-4 overflow-auto' },
        React.createElement("div", { className: 'mb-4 md:mb-0' },
            React.createElement(SubtitleDialogComponent, { label: 'Datos generales' }),
            React.createElement(TextFieldComponent, { label: 'Nombre comercial de la empresa', name: 'name', value: values.name, error: errors.name, onChange: handleChange }),
            React.createElement(TextFieldComponent, { label: 'Nombre de la sucursal', name: 'branchName', value: values.branchName, error: errors.branchName, onChange: handleChange }),
            React.createElement(SelectFieldComponent, { label: 'Giro / actividad', name: 'activity', value: values.activity, error: errors.activity, onChange: handleChange, options: companyActivityOptions }),
            React.createElement(TextFieldComponent, { label: 'Tel\u00E9fono principal', name: 'phone', value: values.phone, error: errors.phone, onChange: handleChange })),
        React.createElement("div", { className: 'mb-4 md:mb-0' },
            React.createElement(SubtitleDialogComponent, { label: 'Domicilio' }),
            React.createElement(TextFieldComponent, { label: 'Calle y n\u00FAmero', name: 'addressLine1', value: values.addressLine1, onChange: handleChange }),
            React.createElement(TextFieldComponent, { label: 'Colonia', name: 'addressLine2', value: values.addressLine2, onChange: handleChange }),
            React.createElement(TextFieldComponent, { label: 'Ciudad', name: 'city', value: values.city, onChange: handleChange }),
            React.createElement(TextFieldComponent, { label: 'Municipio', name: 'municipality', value: values.municipality, onChange: handleChange }),
            React.createElement(TextFieldComponent, { label: 'Estado', name: 'state', value: values.state, onChange: handleChange }),
            React.createElement(TextFieldComponent, { label: 'C\u00F3digo postal', name: 'zipCode', error: errors.zipCode, value: values.zipCode, onChange: handleChange }),
            React.createElement(SelectFieldComponent, { label: 'Pais', name: 'country', value: values.country, error: errors.country, onChange: handleChange, options: countryOptions })),
        React.createElement("div", { className: 'mb-4 md:mb-0' },
            React.createElement(SubtitleDialogComponent, { label: 'Logotipo de la empresa' }),
            React.createElement(UploadImageCreateCompanyComponent, null))));
};
export default BodyCreateCompanyComponent;
