import React from 'react';

import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';

import { currencyFormat } from '../../../../../../../helpers';

const TotalsComponent = props => {
  return (
    <TableRow>
      <TableCell colSpan={4} align='right' className='bg-[#f5f5f5]'>
        <strong>Total: {`$${currencyFormat(props.total)}`}</strong>
      </TableCell>
    </TableRow>
  );
};

export default TotalsComponent;
