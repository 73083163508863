import React, { useEffect } from 'react';
import { useReactiveVar } from '@apollo/client';
import { List } from '@mui/material';
import { searchField } from '../../../../../../../apollo/cache';
import useApolloLazyQuery from '../../../../../../../hooks/useApolloLazyQuery';
import Item from './Item';
import GQL_CUSTOMER from '../../../../../../../apollo/gql/customer';
var TableSearchFormCustomerActionSaleV2Component = function () {
    var searchFieldVar = useReactiveVar(searchField);
    var _a = useApolloLazyQuery(GQL_CUSTOMER.GET_CUSTOMERS), get = _a[0], _b = _a[1], loading = _b.loading, data = _b.data;
    useEffect(function () {
        if (searchFieldVar && searchFieldVar[1].length > 1)
            get({ variables: { like: [searchFieldVar[0], searchFieldVar[1]] } });
        return function () {
            searchField(null);
        };
    }, [searchFieldVar]);
    if (loading || !data)
        return false;
    return (React.createElement(List, { component: 'nav', "aria-labelledby": 'nested-customer-list' }, data.rows.map(function (el, i) { return (React.createElement(Item, { key: el.id, id: el.id, name: "".concat(el.firstName, " ").concat(el.lastName), phone: el.phone, identifier: el.CustomerBillingInformation ? el.CustomerBillingInformation.identifier : '', businessName: el.CustomerBillingInformation ? el.CustomerBillingInformation.businessName : '', background: i % 2 > 0 ? 'bg-inherit' : 'bg-gray-100' })); })));
};
export default TableSearchFormCustomerActionSaleV2Component;
