import React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import BodyTableRowProductRentComponent from './BodyTableRow';
import { currencyFormat, dateFormat } from '../../../../../helpers';
var BodyProductsRentComponent = function (props) {
    if (props.SaleProducts.length === 0)
        return (React.createElement("div", { className: 'p-4' },
            "De momento, la ",
            props.module || 'renta',
            " no cuenta con productos."));
    return (React.createElement(TableContainer, null,
        React.createElement(Table, { stickyHeader: true, size: 'small' },
            React.createElement(TableHead, null,
                React.createElement(TableRow, null,
                    React.createElement(TableCell, null, "Fecha de salida"),
                    React.createElement(TableCell, null, "Producto"),
                    React.createElement(TableCell, { className: 'bg-slate-400 text-center' }, "Cant."),
                    React.createElement(TableCell, { className: 'whitespace-nowrap' }, "P. unitario"),
                    React.createElement(TableCell, null, "Impuestos"),
                    React.createElement(TableCell, null, "Subtotal"),
                    React.createElement(TableCell, null, "Importe"))),
            React.createElement(TableBody, null,
                props.SaleProducts.map(function (el, i) { return (React.createElement(BodyTableRowProductRentComponent, { deliveryStatus: props.deliveryStatus, index: i, key: el.id, saleProductId: el.id },
                    React.createElement(TableCell, null, el.outDate ? dateFormat(el.outDate) : '-'),
                    React.createElement(TableCell, null,
                        React.createElement("div", null,
                            el.Product.name,
                            " ",
                            el.ProductPresentation.name),
                        el.SaleProductIngredients.map(function (elCh) { return (React.createElement("div", { className: 'text-sm text-gray-600' },
                            "- (",
                            elCh.portion,
                            ") ",
                            elCh.Product.name,
                            ' ',
                            elCh.ProductPresentation ? elCh.ProductPresentation.name : '',
                            " $",
                            currencyFormat(elCh.price))); }),
                        el.commentary && React.createElement("div", { className: 'text-sm text-gray-600' },
                            "- ",
                            el.commentary)),
                    React.createElement(TableCell, { className: 'bg-slate-300 text-center' }, currencyFormat(el.quantity)),
                    React.createElement(TableCell, null, currencyFormat(el.withoutTaxes)),
                    React.createElement(TableCell, null, currencyFormat(el.taxes)),
                    React.createElement(TableCell, null, currencyFormat(el.subtotal)),
                    React.createElement(TableCell, null, currencyFormat(el.total)))); }),
                React.createElement(TableRow, null,
                    React.createElement(TableCell, { className: 'text-right', colSpan: 6 }, "Subtotal"),
                    React.createElement(TableCell, { className: 'bg-slate-300' }, currencyFormat(props.subtotal))),
                React.createElement(TableRow, null,
                    React.createElement(TableCell, { className: 'text-right', colSpan: 6 }, "Impuestos"),
                    React.createElement(TableCell, { className: 'bg-slate-300' }, currencyFormat(props.taxes))),
                React.createElement(TableRow, null,
                    React.createElement(TableCell, { className: 'text-right', colSpan: 6 }, "Total"),
                    React.createElement(TableCell, { className: 'bg-slate-300 font-medium' }, currencyFormat(props.total)))))));
};
export default BodyProductsRentComponent;
