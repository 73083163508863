import React from 'react';
import { useReactiveVar } from '@apollo/client';
import Drawer from '@mui/material/Drawer';
import { isMenuOpen } from '../../../../apollo/cache';
import HeaderDrawerComponent from './Header';
import ItemsMenuDashboardComponent from '../Items';
var DrawerComponent = function () {
    var isMenuOpenVar = useReactiveVar(isMenuOpen);
    var handleDrawerClose = function () {
        isMenuOpen(false);
    };
    return (React.createElement(Drawer, { classes: { paper: 'w-full' }, onClose: handleDrawerClose, open: isMenuOpenVar },
        React.createElement("div", { className: 'flex flex-1 flex-col bg-slate-50' },
            React.createElement(HeaderDrawerComponent, null),
            React.createElement("div", { className: 'flex flex-1 py-2 px-3' },
                React.createElement(ItemsMenuDashboardComponent, null)))));
};
export default DrawerComponent;
