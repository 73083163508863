import React from 'react';
import { TableCell } from '@mui/material';
import TableRowItemTableComponentProductPresentationComponent from './TableRow';
import { currencyFormat } from '../../../../../../helpers';
import getProductIngredientTypeLabel from '../../../../../../helpers/getProductIngredientTypeLabel';
import PortionTableCellItemTableComponentProductPresentationComponent from './PortionTableCell';
import QtyTableCellItemTableComponentProductPresentationComponent from './QtyTableCell';
var ItemTableComponentProductPresentationComponent = function (props) {
    var _a;
    return (React.createElement(TableRowItemTableComponentProductPresentationComponent, { productIngredient: props.ProductIngredient.id, background: props.index % 2 > 0 ? 'bg-inherit' : 'bg-gray-100' },
        React.createElement(TableCell, null, props.ProductIngredient.ProductVariation &&
            (props.ProductIngredient.type > 3 || props.ProductIngredient.type === 0)
            ? props.ProductIngredient.ProductVariation.name
            : getProductIngredientTypeLabel(props.ProductIngredient.type)),
        React.createElement(TableCell, null, props.ProductIngredient.Ingredient.name),
        React.createElement(PortionTableCellItemTableComponentProductPresentationComponent, { portion: props.ProductIngredient.portion }),
        React.createElement(QtyTableCellItemTableComponentProductPresentationComponent, { quantity: props.ProductIngredient.quantity, presentationBase: (_a = props.ProductIngredient.Ingredient.PresentationBase) === null || _a === void 0 ? void 0 : _a.name }),
        React.createElement(TableCell, null, currencyFormat(props.ProductIngredient.price)),
        React.createElement(TableCell, null, currencyFormat(props.ProductIngredient.freeLimit))));
};
export default ItemTableComponentProductPresentationComponent;
