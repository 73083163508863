var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useEffect, useMemo, useState } from 'react';
import { useTable, useBlockLayout } from 'react-table';
import { VariableSizeList } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
import scrollbarWidth from './helpers/scrollbarWidth';
import getWidthPerRow from '../helpers/getWidthPerRow';
import RowTableBodyModuleV3Component from './Row';
import HeaderRowTableBodyModuleV3Component from './HeaderRow';
import { fetchMoreData, isTableScrollLoading } from '../../../../apollo/cache';
var TableBodyModuleV3Component = function (props) {
    var _a = useState(0), tableWidth = _a[0], setTableWidth = _a[1];
    var _b = useState(0), bodySize = _b[0], setBodySize = _b[1];
    var _c = useState(false), isFooter = _c[0], setIsFooter = _c[1];
    var _d = useState(false), isDoubleHeader = _d[0], setIsDoubleHeader = _d[1];
    var defaultColumn = useMemo(function () { return ({
        maxWidth: 500,
    }); }, []);
    var scrollBarSize = useMemo(function () { return scrollbarWidth(); }, []);
    var _e = useTable({
        columns: props.columns,
        data: props.data,
        defaultColumn: defaultColumn,
    }, useBlockLayout), getTableProps = _e.getTableProps, getTableBodyProps = _e.getTableBodyProps, headerGroups = _e.headerGroups, footerGroups = _e.footerGroups, rows = _e.rows, totalColumnsWidth = _e.totalColumnsWidth, prepareRow = _e.prepareRow;
    var footerHeight = useMemo(function () {
        switch (headerGroups.length) {
            case 1:
                return 67;
            default:
                return 97;
        }
    }, [headerGroups]);
    var handleItemSize = function (i) {
        var itemSize = getWidthPerRow(props.data[i]);
        return itemSize;
    };
    useEffect(function () {
        if (headerGroups) {
            var isDoubleHeader_1 = false;
            headerGroups.forEach(function (headerGroup) {
                headerGroup.headers.forEach(function (column) {
                    if (column.depth)
                        isDoubleHeader_1 = true;
                });
            });
            setIsDoubleHeader(isDoubleHeader_1);
        }
    }, [headerGroups]);
    useEffect(function () {
        if (footerGroups) {
            var isFooter_1 = false;
            footerGroups.forEach(function (footerGroup) {
                footerGroup.headers.forEach(function (column) {
                    if (!column.columns && typeof column.render('Footer') === 'string')
                        isFooter_1 = true;
                });
            });
            setIsFooter(isFooter_1);
        }
    }, [footerGroups]);
    useEffect(function () {
        if (props.data) {
            var newBodySize = props.data.reduce(function (total, el, i) { return total + handleItemSize(i); }, 0);
            setBodySize(newBodySize);
        }
    }, [props.data]);
    useEffect(function () {
        setTableWidth(totalColumnsWidth + scrollBarSize);
    }, [totalColumnsWidth, scrollBarSize]);
    var RenderRow = React.useCallback(function (_a) {
        var index = _a.index, style = _a.style;
        var row = rows[index];
        var original = row.original;
        prepareRow(row);
        return (React.createElement(RowTableBodyModuleV3Component, { actions: props.actions, getRowProps: row.getRowProps, style: style, identifier: original.id, index: index, cells: row.cells }));
    }, [prepareRow, rows]);
    var handleScroll = function (_a) {
        var scrollDirection = _a.scrollDirection, scrollOffset = _a.scrollOffset, scrollUpdateWasRequested = _a.scrollUpdateWasRequested;
        var isLoading = isTableScrollLoading();
        if (!isLoading &&
            scrollDirection === 'forward' &&
            scrollOffset > 0 &&
            scrollOffset > bodySize - 1000 &&
            !scrollUpdateWasRequested)
            fetchMoreData(true);
    };
    return (React.createElement(AutoSizer, null, function (_a) {
        var width = _a.width, height = _a.height;
        return (React.createElement("div", __assign({}, getTableProps(), { className: 'table', style: { minWidth: width } }),
            React.createElement("div", { style: { minWidth: width }, className: 'border-gray-300 border-0 border-b border-solid' }, headerGroups.map(function (headerGroup) { return (React.createElement("div", __assign({}, headerGroup.getHeaderGroupProps(), { className: 'tr' }), headerGroup.headers.map(function (column, i) { return (React.createElement(HeaderRowTableBodyModuleV3Component, { key: i, className: column.headerClassName, getHeaderProps: column.getHeaderProps, render: column.render })); }))); })),
            React.createElement("div", __assign({}, getTableBodyProps(), { style: { minWidth: width } }),
                React.createElement(VariableSizeList, { height: isFooter || isDoubleHeader ? height - footerHeight : height - footerHeight / 2, itemCount: rows.length, itemSize: handleItemSize, width: tableWidth > width ? tableWidth : width, onScroll: handleScroll }, RenderRow)),
            props.data.length > 0 && isFooter && (React.createElement("div", { style: { minWidth: width }, className: 'border-gray-300 border-0 border-t border-solid' }, footerGroups.length > 0 && (React.createElement("div", __assign({}, footerGroups[0].getFooterGroupProps(), { className: 'tr' }), footerGroups[0].headers.map(function (column, i) { return (React.createElement(HeaderRowTableBodyModuleV3Component, { key: i, getHeaderProps: column.getFooterProps, render: column.render, renderType: 'Footer' })); })))))));
    }));
};
export default TableBodyModuleV3Component;
