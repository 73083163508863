var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useState } from 'react';
import IsBreakTimeAvailableFieldComponent from './IsBreakTimeAvailableField';
var BreakTimeComponent = function () {
    var defaultValues = {
        isBreakTimeAvailable: false,
        breakTimeType: 1,
        numberOfHours: 0,
        start: undefined,
        end: undefined,
    };
    var _a = useState(defaultValues), values = _a[0], setValues = _a[1];
    var handleSwitchChange = function (name, value) {
        setValues(function (values) {
            var _a;
            return (__assign(__assign({}, values), (_a = {}, _a[name] = value, _a)));
        });
    };
    return (React.createElement("div", { className: 'mt-3' },
        React.createElement(IsBreakTimeAvailableFieldComponent, { onChange: handleSwitchChange, value: values.isBreakTimeAvailable })));
};
export default BreakTimeComponent;
