import React from 'react';
import { useMutation } from '@apollo/client';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import FormDialog from '../../presentationals/FormDialog';
import Snackbar from '../../presentationals/Snackbar';

import { parseError } from '../../helpers';

import GQL from './_gql';
import useSnackbarDialog from '../../store/snackbar/snackbarDialog';

const defaultSnackbar = { isOpen: true, time: 3000 };

const PettyCash = ({ id, ...props }) => {
  const { setSnackbar } = useSnackbarDialog();

  const [sendToProduction, { loading }] = useMutation(GQL.SEND_TO_PRODUCTION);

  const handleAction = async () => {
    try {
      await sendToProduction({
        variables: { id },
      });
      setSnackbar({
        ...defaultSnackbar,
        label: 'El mantenimiento fue actualizada con éxito.',
        severity: 'success',
      });
      props.handleClose();
    } catch (e) {
      const parseErrors = parseError(e);
      parseErrors.forEach(el => {
        console.log(el);
        if (el.name === 'BAD_USER_INPUT') {
          const key = Object.keys(el.message)[0];
          setSnackbar({ ...defaultSnackbar, label: el.message[key], severity: 'error' });
        } else {
          setSnackbar({ ...defaultSnackbar, label: el.message, severity: 'error' });
        }
      });
    }
  };

  return (
    <>
      <FormDialog
        title='Mandar a Producción'
        isOpen={props.isOpen}
        isLoading={loading}
        handleAction={handleAction}
        handleClose={props.handleClose}
      >
        <Grid container>
          <Grid item xs={12}>
            <Typography variant='body2' gutterBottom>
              Estás a punto de mandar a producción un mantenimiento. ¿Deseas continuar?
            </Typography>
          </Grid>
        </Grid>
      </FormDialog>
      <Snackbar horizontal='center' />
    </>
  );
};

export default PettyCash;
