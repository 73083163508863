import React from 'react';

import { useReactiveVar } from '@apollo/client';

import { Button } from '@mui/material';

import { currencySaleV2, isSaleCurrencyFormOpen } from '../../../../../../apollo/cache';
import TextField from '../../../../../../presentationals/FormComponents/TextField';

const ExchangeRateFormCurrencyActionSaleV2Component = () => {
  const currencySaleV2Var = useReactiveVar(currencySaleV2);

  if (currencySaleV2Var[0] === 'MXN') return false;

  const handleClick = () => {
    if (currencySaleV2Var[1] === '') currencySaleV2([currencySaleV2Var[0], 1]);
    isSaleCurrencyFormOpen(false);
  };

  const handleChange = (name, value) => {
    const newValue = parseFloat(value) >= 0 ? parseFloat(value) : '';
    currencySaleV2([currencySaleV2Var[0], newValue]);
  };

  const handleKeyDown = e => {
    if (e.keyCode === 13) {
      e.preventDefault();
      handleClick();
    }
  };

  return (
    <div className='p-5 pb-8 bg-gray-100'>
      <TextField
        autoFocus
        label='Tipo de cambio'
        name='exchangeRate'
        value={currencySaleV2Var[1]}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
      />
      <Button
        onClick={handleClick}
        color='secondary'
        variant='contained'
        className='mt-1 shadow-none hover:shadow-none'
      >
        Continuar
      </Button>
    </div>
  );
};

export default ExchangeRateFormCurrencyActionSaleV2Component;
