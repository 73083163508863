var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useMemo } from 'react';
import { useMutation } from '@apollo/client';
import RHFormComponent from '../../../../presentationals/RHForm/Form/indext';
import RHFTextFieldComponent from '../../../../presentationals/RHForm/TextField';
import RHFPhoneFieldComponent from '../../../../presentationals/RHForm/PhoneField';
import RHFButtonComponent from '../../../../presentationals/RHForm/Button';
import ContentTemplatesFieldComponent from './ContentTemplatesField';
import getInputErrorFormat from '../../../sale.v2/Action/helpers/getInputErrorFormat';
import GQL_TWILIO from '../../../../apollo/gql/Twilio';
import useSnackbarDialog from '../../../../store/snackbar/snackbarDialog';
import useApolloQuery from '../../../../hooks/useApolloQuery';
var TwilioWhatsappApiComponent = function () {
    var _a = useApolloQuery(GQL_TWILIO.GET_TWILIO_SETTINGS), data = _a.data, loadingData = _a.loading;
    var snackbar = useSnackbarDialog(function (state) { return state.setSnackbar; });
    var _b = useMutation(GQL_TWILIO.UPDATE_TWILIO_SETTINGS), updateData = _b[0], loading = _b[1].loading;
    var defaultValues = useMemo(function () { return ({
        messagingServiceSid: data ? data.messagingServiceSid : '',
        countryCode: data ? data.countryCode : '+52',
        phone: data ? data.phone : '',
        ContentTemplates: (data === null || data === void 0 ? void 0 : data.ContentTemplates)
            ? data.ContentTemplates.map(function (el) { return ({
                contentSid: el.contentSid,
                name: el.name,
                numOfVariables: el.numOfVariables,
            }); })
            : [{ contentSid: '', name: '', numOfVariables: '' }],
    }); }, [data]);
    var handleSubmit = function (data) { return __awaiter(void 0, void 0, void 0, function () {
        var error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, updateData({
                            variables: __assign(__assign({}, data), { ContentTemplates: data.ContentTemplates.map(function (el) { return (__assign(__assign({}, el), { numOfVariables: parseInt(el.numOfVariables) })); }) }),
                        })];
                case 1:
                    _a.sent();
                    snackbar({ isOpen: true, label: 'La configuración de twilio se actualizó exitosamente.' });
                    return [3 /*break*/, 3];
                case 2:
                    error_1 = _a.sent();
                    getInputErrorFormat(error_1);
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    if (!data || loadingData)
        return React.createElement("div", null);
    return (React.createElement("div", { className: 'flex flex-1 px-4 py-3 flex-col basis-0 overflow-auto' },
        React.createElement("div", { className: 'text-base font-medium mb-1 flex' }, "Configuraci\u00F3n para Twilio Whatsapp"),
        React.createElement("div", { className: 'text-sm sm:text-base text-gray-600 mb-2' },
            "Este m\u00F3dulo permite configurar las credenciales y plantillas necesarias para la integraci\u00F3n con el servicio de Twilio para WhatsApp. Utiliza las credenciales proporcionadas por Twilio, como el messaging service ID, y el n\u00FAmero de WhatsApp asociado a tu cuenta de Twilio, para habilitar la funcionalidad de mensajer\u00EDa a trav\u00E9s de la API de WhatsApp.",
            React.createElement("br", null),
            "Aseg\u00FArate de ingresar los datos correctos, ya que ser\u00E1n utilizados para autenticar y enviar mensajes desde tu cuenta de WhatsApp a trav\u00E9s de la plataforma de Twilio."),
        React.createElement("div", { className: 'flex flex-1 flex-col rounded bg-slate-200 p-4' },
            React.createElement("div", { className: 'grid grid-cols-1 sm:grid-cols-2 sm:gap-4 flex-1 flex-col rounded bg-slate-50 p-4' },
                React.createElement("div", { className: 'flex flex-1 flex-col' },
                    React.createElement("div", { className: 'flex text-xl font-medium' }, "Configuraci\u00F3n general"),
                    React.createElement("div", { className: 'flex' }, "Proporciona los datos b\u00E1sicos para establecer la conexi\u00F3n"),
                    React.createElement("div", { className: 'mt-2' },
                        React.createElement(RHFormComponent, { onSubmit: handleSubmit, defaultValues: defaultValues },
                            React.createElement("div", { className: 'w-full sm:w-1/3' },
                                React.createElement(RHFTextFieldComponent, { name: 'messagingServiceSid', label: 'ID del servicio de mensajer\u00EDa (messagingServiceSid)', config: {
                                        required: true,
                                    } }),
                                React.createElement(RHFPhoneFieldComponent, { label: 'Tel\u00E9fono vinculado', config: {
                                        required: true,
                                    } })),
                            React.createElement("div", { className: 'flex text-lg mt-3' }, "Configuraci\u00F3n del contenido de plantillas (ContentTemplates)"),
                            React.createElement(ContentTemplatesFieldComponent, null),
                            React.createElement("div", null,
                                React.createElement(RHFButtonComponent, { disabled: loadingData || loading, size: 'medium', className: 'mt-3' }, "Actualizar")))))))));
};
export default TwilioWhatsappApiComponent;
