import React from 'react';
import { Button } from '@mui/material';
import { inputError } from '../../../../apollo/cache';
var ClearButtonCreateEmployeeComponent = function (props) {
    var handleClick = function () {
        props.onClick();
        inputError({});
    };
    return (React.createElement(Button, { color: 'inherit', variant: 'contained', className: 'px-5 py-3 mr-1', onClick: handleClick }, "Limpiar"));
};
export default ClearButtonCreateEmployeeComponent;
