var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import { useReactiveVar } from '@apollo/client';
import TextFieldComponent from '../../../../../../presentationals/FormComponents/TextField';
import { saleV2FolioField } from '../../../../../../apollo/cache';
var FolioFormatActionSaleV2Component = function () {
    var values = useReactiveVar(saleV2FolioField);
    var handleChange = function (name, value) {
        var _a;
        saleV2FolioField(__assign(__assign({}, values), (_a = {}, _a[name] = value, _a)));
    };
    return (React.createElement("div", { className: 'p-5 pb-0 bg-gray-100 grid grid-cols-2 gap-4' },
        React.createElement(TextFieldComponent, { label: 'Folio', name: 'folio', value: values.folio, type: 'number', onChange: handleChange })));
};
export default FolioFormatActionSaleV2Component;
