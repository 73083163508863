var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import getPreferences from './getPreferences';
var setLocalStorageShowTransactionGraph = function (value) {
    var Preferences = getPreferences();
    var newPreferences = Preferences
        ? __assign(__assign({}, Preferences), { showTransactionGraph: value }) : { showTransactionGraph: value };
    localStorage.setItem('Preferences', JSON.stringify(newPreferences));
};
export default setLocalStorageShowTransactionGraph;
