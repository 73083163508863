import React from 'react';
import { MenuItem } from '@mui/material';
var SelectBranchInventoryReportMenuItemComponent = function (props) {
    var handleClick = function () {
        props.onClick(props.id, props.label);
    };
    return (React.createElement(MenuItem, { dense: true, classes: {
            dense: 'break-words whitespace-normal',
        }, onClick: handleClick }, props.label));
};
export default SelectBranchInventoryReportMenuItemComponent;
