import React from 'react';
import { useParams } from 'react-router-dom';
import GoBackButtonComponent from '../../../presentationals/GoBackButton';
import useApolloQuery from '../../../hooks/useApolloQuery';
import GQL_ECOMMERCE from '../../../apollo/gql/ecommerce';
var HeaderProductListComponent = function () {
    var params = useParams();
    var data = useApolloQuery(GQL_ECOMMERCE.GET_EC_COMPANY, {
        variables: {
            ecName: params.ecName,
        },
    }).data;
    if (!data)
        return React.createElement("div", null);
    return (React.createElement("div", { className: 'flex flex-row items-center px-2 sm:px-8 pt-4 sm:pt-16 pb-8' },
        params.ecName &&
            [
                'bocadosdelcielodrinks',
                'bsburger',
                'makaosushi',
                'bocadosdelcielomariscos',
                'mrpancake',
                'muchimania',
                'mrpancakereposteria',
                'shirosbobas',
                'tacosdonpolo',
                'theramenfactory',
            ].includes(params.ecName) && React.createElement(GoBackButtonComponent, { darkTheme: true, route: -1 }),
        React.createElement("div", { className: 'text-6xl font-medium text-[#ffb600]' }, data.name)));
};
export default HeaderProductListComponent;
