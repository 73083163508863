var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useEffect, useState } from 'react';
import RequestQuoteIcon from '@mui/icons-material/RequestQuote';
import ListItemPermissionEditRoleComponent from '../helpers/ListItem';
import SwitchPermissionFieldComponent from '../helpers/SwitchPermissionField';
import SelectPermissionFieldComponent from '../helpers/SelectPermissionField';
import getDefaultBranchOptions from '../helpers/getDefaultBrachOptions';
var defaultQuotationPermissionValues = {
    createNewQuotation: false,
    editQuotation: 0,
    duplicateQuotation: 0,
    sellQuotation: 0,
    getQuotations: 0,
    deleteQuotation: 0,
    createAuthQuotation: 0,
    allowAuthQuotation: 0,
};
var QuotationPermissionEditRoleComponent = function (props) {
    var defaultBranchOptions = getDefaultBranchOptions();
    var _a = useState(defaultQuotationPermissionValues), values = _a[0], setValues = _a[1];
    useEffect(function () {
        setValues(function (values) { return (__assign(__assign({}, values), props.Permissions)); });
    }, [props.Permissions]);
    return (React.createElement(ListItemPermissionEditRoleComponent, { icon: React.createElement(RequestQuoteIcon, null), name: 'Cotizaciones' },
        React.createElement("div", { className: 'mx-4 py-3 grid grid-cols-1 md:grid-cols-3 gap-4' },
            React.createElement("div", { className: 'flex flex-col' },
                React.createElement(SwitchPermissionFieldComponent, __assign({}, props, { name: 'createNewQuotation', label: 'Crear nuevas cotizaciones', defaultValue: values.createNewQuotation })),
                React.createElement(SelectPermissionFieldComponent, __assign({}, props, { name: 'editQuotation', label: 'Editar contenido y comentario de una cotizaci\u00F3n', defaultValue: values.editQuotation, options: defaultBranchOptions })),
                React.createElement(SelectPermissionFieldComponent, __assign({}, props, { name: 'duplicateQuotation', label: 'Duplicar cotizaci\u00F3n', defaultValue: values.duplicateQuotation, options: defaultBranchOptions })),
                React.createElement(SelectPermissionFieldComponent, __assign({}, props, { name: 'sellQuotation', label: 'Vender/cerrar una cotizaci\u00F3n', defaultValue: values.sellQuotation, options: defaultBranchOptions })),
                React.createElement(SelectPermissionFieldComponent, __assign({}, props, { name: 'getQuotations', label: 'Consultar datos generales y contenido de una cotizaci\u00F3n', defaultValue: values.getQuotations, options: defaultBranchOptions })),
                React.createElement(SelectPermissionFieldComponent, __assign({}, props, { name: 'deleteQuotation', label: 'Eliminar cotizaciones', defaultValue: values.deleteQuotation, options: defaultBranchOptions })),
                React.createElement(SelectPermissionFieldComponent, __assign({}, props, { name: 'createAuthQuotation', label: 'Crear cotizaciones autorizadas para venta', defaultValue: values.createAuthQuotation, options: defaultBranchOptions })),
                React.createElement(SelectPermissionFieldComponent, __assign({}, props, { name: 'allowAuthQuotation', label: 'Autorizar cotizaciones para venta', defaultValue: values.allowAuthQuotation, options: defaultBranchOptions }))))));
};
export default QuotationPermissionEditRoleComponent;
