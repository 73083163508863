import React, { useEffect } from 'react';

import { useReactiveVar } from '@apollo/client';

import Card from '@mui/material/Card';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';

import { totals } from '../../../apollo/cache';

const TotalsComponent = () => {
  const totalsVar = useReactiveVar(totals);

  useEffect(() => {
    return () => {
      totals(null);
    };
  }, []);

  if (!totalsVar) return null;

  return (
    <Card className='m-0 mt-1 py-0 px-1 flex overflow-x-auto'>
      {totalsVar.map(el => (
        <Paper variant='outlined' className='p-2 m-2 min-w-[120px]' key={el.label}>
          <Typography variant='body1'>
            <strong>{el.value}</strong>
          </Typography>
          <Typography variant='body2'>{el.label}</Typography>
        </Paper>
      ))}
    </Card>
  );
};

export default TotalsComponent;
