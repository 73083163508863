var getServerPaymentForm = function (value) {
    switch (value) {
        case '0':
            return {};
        case '-1':
            return { paymentMethodId: null };
        default:
            return { paymentMethodId: value };
    }
};
export default getServerPaymentForm;
