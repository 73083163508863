import React, { useEffect, useState } from 'react';
import { useReactiveVar } from '@apollo/client';
import Button from '../../helpers/Button';
import { formatSaleV2, isSaleFormatFormOpen } from '../../../../../apollo/cache';
import getSaleFormatLabel from '../../../../../helpers/getSaleFormatLabel';
import useApolloQuery from '../../../../../hooks/useApolloQuery';
import GQL_COMPANY from '../../../../../apollo/gql/company';
import { convertSaleFormatLabelToNumber } from '../Form/helpers/getSaleFormatByCompany';
var ButtonFormatActionSaleV2Component = function () {
    var formatSaleV2Var = useReactiveVar(formatSaleV2);
    var _a = useState('Cargando...'), label = _a[0], setLabel = _a[1];
    var _b = useApolloQuery(GQL_COMPANY.GET_ALLOWED_MODULES), data = _b.data, loading = _b.loading;
    useEffect(function () {
        if (data) {
            var moduleKeys = Object.keys(data);
            var allowedModuleKeys = moduleKeys
                .filter(function (key) { var _a; return (_a = data[key]) === null || _a === void 0 ? void 0 : _a.isAllowed; })
                .map(function (el) { return convertSaleFormatLabelToNumber(el); });
            var saleFormatId = parseInt("".concat(formatSaleV2Var[0]));
            if (allowedModuleKeys.includes(saleFormatId)) {
                setLabel(getSaleFormatLabel(saleFormatId));
            }
            else {
                var newSaleFormatId = allowedModuleKeys[0];
                formatSaleV2([newSaleFormatId, formatSaleV2Var[1], formatSaleV2Var[2], formatSaleV2Var[3]]);
                setLabel(getSaleFormatLabel(saleFormatId));
            }
        }
    }, [data, formatSaleV2Var]);
    var handleClick = function () {
        isSaleFormatFormOpen(true);
    };
    return React.createElement(Button, { title: 'Formato', disabled: loading, subtitle: label, onClick: handleClick });
};
export default ButtonFormatActionSaleV2Component;
