import React from 'react';
import { useReactiveVar } from '@apollo/client';
import DialogComponent from '../Dialog';
import DownloadCfdiFormInvoiceOnlineComponent from '../../containers/invoiceOnline/Form/downloadCfdi';
import { isCreatedInvoiceOpen } from '../../apollo/cache';
var CreatedInvoiceDialogComponent = function () {
    var uuid = useReactiveVar(isCreatedInvoiceOpen);
    var handleClose = function () {
        isCreatedInvoiceOpen(false);
    };
    return (React.createElement(DialogComponent, { fullScreen: true, open: !!uuid, title: 'Factura electr\u00F3nica', onClose: handleClose },
        React.createElement("div", { className: 'grid grid-cols-1 sm:grid-cols-3 flex-1 flex-col px-4 py-3 ' },
            React.createElement(DownloadCfdiFormInvoiceOnlineComponent, { uuid: uuid || '' }))));
};
export default CreatedInvoiceDialogComponent;
