var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useEffect, useState } from 'react';
import { Typography } from '@mui/material';
import { useReactiveVar } from '@apollo/client';
import { inputError } from '../../../../../../../apollo/cache';
import getDefaultTextColorValues from './helpers/getDefaultTextColorValues';
import ItemColorFieldEcommerceEditFormComponent from './Item';
import EditButtonColorFieldEcommerceEditFormComponent from './EditButton';
var ColorFieldEcommerceEditFormComponent = function (props) {
    var errors = useReactiveVar(inputError);
    var _a = useState({}), values = _a[0], setValues = _a[1];
    useEffect(function () {
        if (props.TextAttributes && props.TextAttributes.length > 0 && props.Colors.length > 0)
            setValues(getDefaultTextColorValues(props.Colors, props.TextAttributes));
    }, [props.TextAttributes, props.Colors]);
    var handleChange = function (id, attribute) {
        if ((errors === null || errors === void 0 ? void 0 : errors.textColors) && errors.textColors.length > 0)
            inputError({});
        setValues(function (values) {
            var _a;
            return (__assign(__assign({}, values), (_a = {}, _a[id] = attribute, _a)));
        });
    };
    return (React.createElement("div", { className: 'mt-3' },
        React.createElement(Typography, { variant: 'subtitle1', className: 'font-bold' }, "Color de texto"),
        props.Colors.length > 0 ? (React.createElement(React.Fragment, null,
            React.createElement(Typography, { variant: 'subtitle2' }, "Elige el color de texto seg\u00FAn el color de tu producto."),
            React.createElement("div", { className: 'gap-2 grid grid-cols-3' }, props.Colors.map(function (el) { return (React.createElement(ItemColorFieldEcommerceEditFormComponent, { key: el.id, id: el.id, attribute: el.attribute, onChange: handleChange, value: values[el.id] || '' })); })))) : (React.createElement(ItemColorFieldEcommerceEditFormComponent, { id: '0', value: values[0] || '', onChange: handleChange })),
        React.createElement("div", { className: 'text-red-600' }, errors.textColors),
        React.createElement(EditButtonColorFieldEcommerceEditFormComponent, { values: values, TextAttributes: props.TextAttributes })));
};
export default ColorFieldEcommerceEditFormComponent;
