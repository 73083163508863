var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useEffect, useRef } from 'react';
import { useReactiveVar } from '@apollo/client';
import { Divider, LinearProgress } from '@mui/material';
import { branchSaleV2, cartItems, commentarySaleV2, customerSaleV2, formatSaleV2, inputError, invoiceSaleV2, isCloseQuotationOpen, pinSaleV2, saleTotalToPay, totalsSaleV2, } from '../../../apollo/cache';
import DialogComponent from '../../../presentationals/Dialog';
import TextField from '../../sale.v2/ConfirmDialog/Body/PaymentForm/TextField';
import PaymentMethod from '../../sale.v2/ConfirmDialog/Body/PaymentForm/PaymentMethod';
import InvoiceForm from '../../sale.v2/ConfirmDialog/Body/PaymentForm/InvoiceForm';
import IsInvoice from '../../sale.v2/Action/Format/Form/TaxesOption/IsInvoice';
import getDefaultTotalToPay from '../../sale.v2/helpers/getDefaultTotalToPay';
import useApolloLazyQuery from '../../../hooks/useApolloLazyQuery';
import getInputErrorFormat from '../../sale.v2/Action/helpers/getInputErrorFormat';
import defaultCustomerValues from '../../sale.v2/Action/Customer/Form/Create/Body/helpers/defaultValues';
import GQL from '../_gql';
import SummaryFormBodyConfirmDialogSaleV2Component from '../../sale.v2/ConfirmDialog/Body/SummaryForm';
import CreateButton from './CreateButton';
import FooterDialogComponent from '../../../presentationals/Dialog/Footer';
import PinField from '../../sale.v2/ConfirmDialog/Footer/PinField';
import PrintOption from '../../sale.v2/ConfirmDialog/Footer/PrintOption';
import getProductToCheckCart from '../../sale.v2/helpers/getProductToCheckCart';
import useApolloMutation from '../../../hooks/useApolloMutation';
import GQL_CART from '../../sale.v2/ChooseProduct/AddButton/_gql';
import ShowTaxesSwitch from '../../sale.v2/Action/Format/Form/TaxesOption/ShowTaxesSwitch';
import getTotalizedCartProducts from '../../sale.v2/helpers/getTotalizedCartProducts';
import BranchCloseQuotationFieldComponent from './BranchField';
var CloseQuotationComponent = function () {
    var branchCloseQuotationFieldRef = useRef(null);
    var isOpen = useReactiveVar(isCloseQuotationOpen);
    var formatSaleV2Var = useReactiveVar(formatSaleV2);
    var _a = useApolloLazyQuery(GQL.GET_BY_ID), getQuotation = _a[0], _b = _a[1], data = _b.data, loading = _b.loading;
    var _c = useApolloMutation(GQL_CART.CHECK_CART), checkCart = _c[0], loadingCheckCart = _c[1].loading;
    var handleClose = function () {
        var _a;
        var defaultTotalToPay = getDefaultTotalToPay();
        saleTotalToPay(defaultTotalToPay);
        isCloseQuotationOpen([false, null]);
        customerSaleV2(defaultCustomerValues());
        cartItems([]);
        commentarySaleV2('');
        totalsSaleV2([0, 0, 0, 0]);
        invoiceSaleV2({
            paymentMethod: 'PUE',
            paymentForm: '',
            useCfdi: '',
            relateType: '',
            relateUUID: '',
        });
        pinSaleV2('');
        inputError({});
        (_a = branchCloseQuotationFieldRef.current) === null || _a === void 0 ? void 0 : _a.clear();
    };
    useEffect(function () {
        return function () { return handleClose(); };
    }, []);
    var getCart = function (Products) { return __awaiter(void 0, void 0, void 0, function () {
        var discountType_1, newCart, dataCart, cart, e_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    discountType_1 = 2;
                    newCart = getProductToCheckCart(Products.map(function (el) {
                        if (el.discountRate)
                            discountType_1 = 1;
                        return __assign(__assign({}, el), { discountRate: el.discountRate || el.discount });
                    }));
                    return [4 /*yield*/, checkCart({
                            variables: {
                                isInvoice: formatSaleV2Var[2],
                                taxesOption: 1,
                                discountType: discountType_1,
                                // discountType: 2,
                                shipping: 0,
                                currencyKey: 'MXN',
                                exchangeRate: 1,
                                category: 1,
                                isQuote: true,
                                Products: newCart,
                                Transactions: [],
                                showTaxes: formatSaleV2Var[3],
                            },
                        })];
                case 1:
                    dataCart = (_a.sent()).data;
                    cart = getTotalizedCartProducts(dataCart.totalizeCart.Products, Products, {
                        taxesOption: 1,
                        isInvoice: formatSaleV2Var[2],
                        showTaxes: formatSaleV2Var[3],
                    });
                    totalsSaleV2([
                        dataCart.totalizeCart.subtotal,
                        dataCart.totalizeCart.discount,
                        dataCart.totalizeCart.taxes,
                        dataCart.totalizeCart.total,
                    ]);
                    cartItems(cart);
                    return [3 /*break*/, 3];
                case 2:
                    e_1 = _a.sent();
                    getInputErrorFormat(e_1);
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    useEffect(function () {
        var f = function () { return __awaiter(void 0, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!data || !isOpen[1])
                            return [2 /*return*/];
                        return [4 /*yield*/, getCart(data.QuoteProducts)];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        }); };
        f();
    }, [isOpen[1], data, formatSaleV2Var]);
    useEffect(function () {
        if (isOpen[1]) {
            var f = function () { return __awaiter(void 0, void 0, void 0, function () {
                var data_1, e_2;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            _a.trys.push([0, 2, , 3]);
                            return [4 /*yield*/, getQuotation({ variables: { id: isOpen[1] } })];
                        case 1:
                            data_1 = (_a.sent()).data;
                            console.log(data_1, 'data');
                            customerSaleV2(defaultCustomerValues(data_1.Customer));
                            commentarySaleV2(data_1.commentary);
                            branchSaleV2({
                                branchInventory: data_1.branchId,
                                branchSale: data_1.branchId,
                            });
                            formatSaleV2([1, formatSaleV2Var[1], formatSaleV2Var[2], data_1.showTaxes]);
                            return [3 /*break*/, 3];
                        case 2:
                            e_2 = _a.sent();
                            getInputErrorFormat(e_2);
                            return [3 /*break*/, 3];
                        case 3: return [2 /*return*/];
                    }
                });
            }); };
            f();
        }
    }, [isOpen[1]]);
    return (React.createElement(DialogComponent, { title: 'Cerrar cotizaci\u00F3n', onClose: handleClose, open: isOpen[0], fullScreen: true },
        (loading || loadingCheckCart) && React.createElement(LinearProgress, { color: 'secondary' }),
        React.createElement("div", { className: 'grid grid-cols-1 md:grid-cols-4 gap-4 flex-1 px-4  py-0' },
            React.createElement("div", { className: 'flex-1 flex flex-col basis-0' },
                React.createElement(BranchCloseQuotationFieldComponent, { ref: branchCloseQuotationFieldRef }),
                React.createElement(Divider, { className: 'my-2' }),
                React.createElement(IsInvoice, null),
                React.createElement(ShowTaxesSwitch, null),
                React.createElement(Divider, { className: 'mt-2' }),
                React.createElement(TextField, null),
                React.createElement(PaymentMethod, null),
                React.createElement(InvoiceForm, null)),
            React.createElement("div", { className: 'col-span-3 h-full hidden md:flex flex-1  flex-col' },
                React.createElement(SummaryFormBodyConfirmDialogSaleV2Component, null))),
        React.createElement(FooterDialogComponent, null,
            React.createElement("div", { className: 'hidden md:block' },
                React.createElement(PrintOption, null)),
            React.createElement("div", { className: 'flex' },
                React.createElement("div", { className: 'mr-2' },
                    React.createElement(PinField, null)),
                React.createElement(CreateButton, { onClose: handleClose })))));
};
export default CloseQuotationComponent;
