import { useParams } from 'react-router-dom';
import useApolloQuery from '../../../../hooks/useApolloQuery';
import GQL_PRODUCT from '../../../../apollo/gql/product';
var useGetItemProduct = function () {
    var params = useParams();
    var _a = useApolloQuery(GQL_PRODUCT.GET_PRODUCT_BY_ID, {
        variables: { id: params.productId },
    }), data = _a.data, loading = _a.loading;
    return { data: data, loading: loading };
};
export default useGetItemProduct;
