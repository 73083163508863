import { TableRow } from '@mui/material';
import React from 'react';
import { isEditAddressLine2Open } from '../../../../../apollo/cache';
var TableAddressLine2RowComponent = function (props) {
    var handleClick = function () {
        isEditAddressLine2Open(props.id);
    };
    return React.createElement(TableRow, { onClick: handleClick }, props.children);
};
export default TableAddressLine2RowComponent;
