import { filter } from '../../../apollo/cache';
var getTotalExchange = function (data) {
    var filterVar = filter();
    return data.reduce(function (total, el) {
        return (el.status === 1 || (filterVar.status.length === 1 && filterVar.status[0] === 0)) &&
            !el.paymentMethodId &&
            parseFloat(el.total) < 0
            ? [total[0] + parseFloat(el.total), total[1] + 1]
            : total;
    }, [0, 0]);
};
export default getTotalExchange;
