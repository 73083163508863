import dateFormat from 'date-fns/format';
import parseISO from 'date-fns/parseISO';

import { currencyFormat } from '../../../helpers';

export const productType = [
  '-',
  'Unidad',
  'Compuesto',
  'Compuesto Personalizado',
  'Paquete por Eventos',
  'Componente',
];
export const yesNoType = value => (value ? 'SI' : 'NO');
export const printer = ['No Imprimir', 'Impresora 1', 'Impresora 2'];
export const action = ['-', 'Retiro', 'Depósito', 'Caja Fuerte'];
export const orderStatus = ['Cancelada', 'Abierta', 'En Proceso', 'Cerrada', 'Completado'];
export const typeSale = [
  'Local',
  'Local',
  'Ordene y Recoja',
  'Domicilio',
  'Drive Thru',
  'Didi Food',
  'Rappi',
  'Uber Eats',
];
export const orderAuthorizationStatus = ['Sin Código', 'Vigente', 'Expirado', 'Finalizado'];
export const receivableType = ['-', 'Venta', 'Cargo'];

const format = (value, type) => {
  switch (type) {
    case 'date':
      return value && value !== '-' ? dateFormat(parseISO(value), 'dd/MM/yyyy') : '-';
    case 'dateTime':
      return value && value !== '-' ? dateFormat(parseISO(value), 'dd/MM/yyyy HH:mm:ss') : '-';
    case 'time':
      return value && value !== '-' ? dateFormat(parseISO(value), 'HH:mm:ss') : '-';
    case 'currency':
      return currencyFormat(value);
    case 'percent':
      return !Number.isNaN(parseInt(value)) ? `${currencyFormat(value)}%` : '-';
    case 'balance':
      return parseFloat(value) === 0 ? '-' : currencyFormat(value);
    case 'productType':
      return productType[value];
    case 'yesNoType':
      return yesNoType(value);
    case 'printer':
      return printer[value];
    case 'action':
      return action[value];
    case 'orderStatus':
      return orderStatus[value];
    case 'typeSale':
      return typeSale[value];
    case 'promotionAvailableDays':
      return promotionAvailableDays(value);
    case 'receivableType':
      return receivableType[value];
    case 'orderAuthorizationStatus':
      return orderAuthorizationStatus[value];
    default:
      return typeof value === 'undefined' || value === '' ? '-' : value;
  }
};

export default format;
