import React from 'react';
import InvoiceReportLayoutComponent from './InvoiceReportLayout';
import InvoiceReportV2Component from '../containers/invoice.report.v2';
import CreditNoteInvoiceReportComponent from '../containers/invoiceReport/Item/CreditNote';
import CancelInvoiceReportComponent from '../containers/invoiceReport/Item/Cancel';
import ProductInvoiceReportComponent from '../containers/invoiceReport/Item/Product';
import GeneralInvoiceReportComponent from '../containers/invoiceReport/Item/General';
var getInvoiceReportChildrenRoutes = function () {
    return [
        { index: true, element: React.createElement(InvoiceReportV2Component, null) },
        {
            path: ':invoiceId',
            element: React.createElement(InvoiceReportLayoutComponent, null),
            children: [
                {
                    index: true,
                    element: React.createElement(GeneralInvoiceReportComponent, null),
                },
                {
                    path: 'products',
                    element: React.createElement(ProductInvoiceReportComponent, null),
                },
                {
                    path: 'creditNote',
                    element: React.createElement(CreditNoteInvoiceReportComponent, null),
                },
                {
                    path: 'cancel',
                    element: React.createElement(CancelInvoiceReportComponent, null),
                },
            ],
        },
    ];
};
export default getInvoiceReportChildrenRoutes;
