var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useState } from 'react';
import { Button } from '@mui/material';
import { Link } from 'react-router-dom';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import logo from '../../assets/static/iso.png';
import TextFieldComponent from '../../presentationals/FormComponents/TextField';
import Copyright from '../../presentationals/Module.v2/Copyright';
import AcceptTermsLinkComponent from '../login/AcceptTermsLink';
var defaultValues = {
    name: '',
    phone: '',
    email: '',
    password: '',
};
var CreateAccountComponent = function () {
    var _a = useState(defaultValues), values = _a[0], setValues = _a[1];
    var handleChange = function (name, value) {
        setValues(function (values) {
            var _a;
            return (__assign(__assign({}, values), (_a = {}, _a[name] = value, _a)));
        });
    };
    //const handleClick = async () => {};
    var _b = React.useState(false), open = _b[0], setOpen = _b[1];
    var handleClickOpen = function () {
        setOpen(true);
    };
    var handleClose = function () {
        setOpen(false);
    };
    return (React.createElement("div", { className: 'flex flex-1 h-screen flex-col bg-slate-50' },
        React.createElement("div", { className: 'flex justify-center items-center p-10 md:pt-16' },
            React.createElement("img", { src: logo, alt: 'Capitalike', className: 'w-20' })),
        React.createElement("div", { className: 'flex items-center justify-center font-thin text-2xl text-gray-800' }, "Ingresa tus datos"),
        React.createElement("div", { className: 'flex flex-1 items-center justify-center flex-col p-4' },
            React.createElement("div", { className: 'w-full sm:w-96' },
                React.createElement(TextFieldComponent, { defaultLabel: 'Nombre', name: 'name', value: values.name, onChange: handleChange, 
                    /*onKeyDown={handleKeyDown}
                  error={errors.name}
                  disabled={loading}*/
                    size: 'medium' }),
                React.createElement(TextFieldComponent, { defaultLabel: 'Telefono', name: 'phone', value: values.phone, onChange: handleChange, 
                    /*onKeyDown={handleKeyDown}
                  error={errors.phone}
                  disabled={loading}*/
                    size: 'medium', margin: 'dense' }),
                React.createElement(TextFieldComponent, { defaultLabel: 'Correo electronico', name: 'email', value: values.email, onChange: handleChange, 
                    /*onKeyDown={handleKeyDown}
                  error={errors.email}
                  disabled={loading}*/
                    size: 'medium', margin: 'dense', type: 'email' }),
                React.createElement(TextFieldComponent, { defaultLabel: 'Contrase\u00F1a', name: 'password', value: values.password, onChange: handleChange, 
                    /*onKeyDown={handleKeyDown}
                  error={errors.password}
                  disabled={loading}*/
                    size: 'medium', margin: 'dense', type: 'password' }),
                React.createElement("div", { className: 'mt-4 mb-6' },
                    React.createElement(Button, { variant: 'contained', className: 'rounded-lg normal-case', size: 'large', fullWidth: true, onClick: handleClickOpen }, "Registrar"),
                    React.createElement(Dialog, { open: open, onClose: handleClose, "aria-labelledby": 'alert-dialog-title', "aria-describedby": 'alert-dialog-description' },
                        React.createElement(DialogTitle, { id: 'alert-dialog-title' }),
                        React.createElement(DialogContent, null,
                            React.createElement(DialogContentText, { id: 'alert-dialog-description', className: 'text-gray-700' }, "\u00A1Estamos trabajando en ello! Pronto estar\u00E1 listo. \u00A1Gracias por tu paciencia!")),
                        React.createElement(DialogActions, null,
                            React.createElement(Button, { onClick: handleClose }, "Cerrar"))),
                    React.createElement(AcceptTermsLinkComponent, null)))),
        React.createElement("div", { className: 'flex flex-col justify-center items-center p-4' },
            React.createElement("div", { className: 'w-40 mb-6 border-0 border-b border-solid border-gray-600' }),
            React.createElement("div", { className: 'flex items-center' },
                React.createElement("div", { className: 'text-sm mr-3' }, "\u00BFYa tienes una cuenta?"),
                React.createElement(Link, { className: 'text-gray-600 text-sm font-medium no-underline hover:underline', to: '/login' }, "Iniciar sesi\u00F3n")),
            React.createElement("div", { className: 'flex justify-center p-6' },
                React.createElement(Copyright, null)))));
};
export default CreateAccountComponent;
