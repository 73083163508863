import React, { useEffect, useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { useParams } from 'react-router-dom';
import useApolloQuery from '../../../../hooks/useApolloQuery';
import GQL_SALE from '../../../../apollo/gql/sale';
import LoadingProgress from '../../../../presentationals/LoadingProgress';
import ErrorDataComponent from '../../../../presentationals/ErrorData';
import convertToCurrencyLabel from '../../../../helpers/convertToCurrencyLabel';
import getSalesByClassificationFromSaleProducts from './helpers/getSalesByClassificationFromSaleProducts';
var TotalByClassificationCashClosingComponent = function () {
    var params = useParams();
    var _a = useState([]), SalesByClassification = _a[0], setSalesByClassification = _a[1];
    var _b = useApolloQuery(GQL_SALE.GET_SALE_PRODUCTS, {
        variables: {
            cashClosingId: params.cashClosingId,
        },
    }), data = _b.data, loading = _b.loading;
    useEffect(function () {
        if (data) {
            var SalesByClassification_1 = getSalesByClassificationFromSaleProducts(data);
            setSalesByClassification(SalesByClassification_1);
        }
    }, [data]);
    if (loading)
        return React.createElement(LoadingProgress, null);
    if (!data)
        return React.createElement(ErrorDataComponent, null);
    return (React.createElement(TableContainer, null,
        React.createElement(Table, { stickyHeader: true, size: 'small' },
            React.createElement(TableHead, null,
                React.createElement(TableRow, null,
                    React.createElement(TableCell, null, "Clasificaci\u00F3n"),
                    React.createElement(TableCell, null, "Cantidad"),
                    React.createElement(TableCell, null, "Importe"))),
            React.createElement(TableBody, null, SalesByClassification.map(function (el) { return (React.createElement(TableRow, { key: el.id },
                React.createElement(TableCell, null, el.name),
                React.createElement(TableCell, null, el.qty),
                React.createElement(TableCell, null, convertToCurrencyLabel(el.total)))); })))));
};
export default TotalByClassificationCashClosingComponent;
