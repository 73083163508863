import React from 'react';
import ManufactureComponent from '../containers/manufacture';
import CreateManufactureComponent from '../containers/manufacture/Create';
import ManufactureLayoutComponent from './ManufactureLayout';
import GeneralManufactureComponent from '../containers/manufacture/Item/General';
import CancelManufactureComponent from '../containers/manufacture/Item/Cancel';
var getManufactureChildrenRoutes = function () {
    return [
        { index: true, element: React.createElement(ManufactureComponent, null) },
        {
            path: 'create',
            element: React.createElement(CreateManufactureComponent, null),
        },
        {
            path: ':manufactureId',
            element: React.createElement(ManufactureLayoutComponent, null),
            children: [
                { index: true, element: React.createElement(GeneralManufactureComponent, null) },
                { path: 'cancel', element: React.createElement(CancelManufactureComponent, null) },
            ],
        },
    ];
};
export default getManufactureChildrenRoutes;
