import React, { useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import useApolloLazyQuery from '../../../hooks/useApolloLazyQuery';
import GQL from '../_gql';
import BodyCreateFormCustomerActionSaleV2Component from '../../sale.v2/Action/Customer/Form/Create/Body';
import FooterDialogComponent from '../../../presentationals/Dialog/Footer';
import DeleteButtonGeneralEditCustomerComponent from './DeleteButton';
import EditButtonGeneralEditCustomerComponent from './EditButton';
import LoadingProgress from '../../../presentationals/LoadingProgress';
import ErrorDataComponent from '../../../presentationals/ErrorData';
var GeneralEditCustomerComponent = function () {
    var params = useParams();
    var editButtonCustomerRef = useRef(null);
    var bodyCustomerRef = useRef(null);
    var _a = useApolloLazyQuery(GQL.GET_BY_ID), getCustomer = _a[0], _b = _a[1], loading = _b.loading, data = _b.data;
    useEffect(function () {
        if (params.customerId)
            getCustomer({ variables: { id: params.customerId } });
    }, [params]);
    var handleEdit = function () {
        var _a;
        (_a = editButtonCustomerRef.current) === null || _a === void 0 ? void 0 : _a.onPressEnter();
    };
    var handleGetValues = function () { var _a; return (_a = bodyCustomerRef.current) === null || _a === void 0 ? void 0 : _a.getValues(); };
    if (loading)
        return React.createElement(LoadingProgress, null);
    if (!data)
        return React.createElement(ErrorDataComponent, null);
    return (React.createElement("div", { className: 'flex flex-1 flex-col overflow-hidden mt-1' },
        React.createElement(BodyCreateFormCustomerActionSaleV2Component, { ref: bodyCustomerRef, defaultValues: data, onPressEnter: handleEdit }),
        React.createElement(FooterDialogComponent, null,
            React.createElement("div", null,
                React.createElement(DeleteButtonGeneralEditCustomerComponent, null)),
            React.createElement(EditButtonGeneralEditCustomerComponent, { getValues: handleGetValues, ref: editButtonCustomerRef }))));
};
export default GeneralEditCustomerComponent;
