import React from 'react';
import useGetBranches from '../../../../../hooks/useGetBranches';
import SelectBranchInventoryReportMenuItemComponent from './Item';
var SelectBranchInventoryReportMenuItemsComponent = function (props) {
    var _a = useGetBranches(), data = _a.data, loading = _a.loading;
    if (loading)
        return React.createElement("div", { className: 'w-full text-center p-4' }, "Consultando sucursales disponibles...");
    return (React.createElement("div", null, data.rows.map(function (el) {
        return (!props.avoidBranchId ||
            (props.avoidBranchId && !props.avoidBranchId.includes(el.id))) && (React.createElement(SelectBranchInventoryReportMenuItemComponent, { key: el.id, onClick: props.onClick, id: el.id, label: el.name }));
    })));
};
export default SelectBranchInventoryReportMenuItemsComponent;
