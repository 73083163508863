import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import BodyLimitInventoryProductPresentationComponent from './Body';
import CreateLimitInventoryProductPresentationComponent from './Create';
import useApolloLazyQuery from '../../../../hooks/useApolloLazyQuery';
import GQL_PRODUCT from '../../../../apollo/gql/product';
import LoadingProgress from '../../../../presentationals/LoadingProgress';
import EditLimitInventoryProductPresentationComponent from './Edit';
import ErrorDataComponent from '../../../../presentationals/ErrorData';
var LimitInventoryProductPresentationComponent = function () {
    var params = useParams();
    var _a = useApolloLazyQuery(GQL_PRODUCT.GET_BRANCH_PRODUCT_PRESENTATION_CONFIGURATIONS), getData = _a[0], _b = _a[1], data = _b.data, loading = _b.loading;
    useEffect(function () {
        if (params.productPresentationId)
            getData({
                variables: {
                    productPresentationId: params.productPresentationId,
                },
            });
    }, [params.productPresentationId]);
    if (loading)
        return React.createElement(LoadingProgress, null);
    if (!data)
        return React.createElement(ErrorDataComponent, null);
    return (React.createElement(React.Fragment, null,
        React.createElement(BodyLimitInventoryProductPresentationComponent, { ProductPresentationLimitInventories: data }),
        React.createElement(CreateLimitInventoryProductPresentationComponent, null),
        React.createElement(EditLimitInventoryProductPresentationComponent, null)));
};
export default LimitInventoryProductPresentationComponent;
