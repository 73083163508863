var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { useController, useFormContext } from 'react-hook-form';
import ItemTableProductListComponent from './Item';
var TableProductListComponent = function (props) {
    var control = useFormContext().control;
    var value = useController({
        name: props.arrayName,
        control: control,
    }).field.value;
    return (React.createElement(TableContainer, { className: 'mt-4 h-80' },
        React.createElement(Table, { stickyHeader: true, size: 'small' },
            React.createElement(TableHead, null,
                React.createElement(TableRow, null,
                    React.createElement(TableCell, null),
                    React.createElement(TableCell, null, "Producto"),
                    React.createElement(TableCell, null, "Presentaci\u00F3n"),
                    React.createElement(TableCell, { align: 'center' }, "Cantidad"))),
            React.createElement(TableBody, null, value &&
                value.map(function (el) { return (React.createElement(ItemTableProductListComponent, __assign({ key: el.productPresentationId, arrayName: props.arrayName }, el))); })))));
};
export default TableProductListComponent;
