import React, { useEffect, useState } from 'react';
import ImageComponent from '../../../../product/Item/Image/Item';
import { currencyFormat } from '../../../../../helpers';
import QtyFieldCloseRequisitionComponent from './QtyField';
var ItemProductRequisitionComponent = function (props) {
    var _a = useState(null), fileId = _a[0], setFileId = _a[1];
    var _b = useState(false), selected = _b[0], isSelected = _b[1];
    useEffect(function () {
        if (props.RequisitionProduct) {
            var fileId_1 = props.RequisitionProduct.Product.Files && props.RequisitionProduct.Product.Files.length > 0
                ? props.RequisitionProduct.Product.Files[0].id
                : null;
            setFileId(fileId_1);
        }
    }, [props.RequisitionProduct]);
    var handleClick = function (qty) {
        isSelected(true);
        props.onQtyChange(props.RequisitionProduct.id, qty);
    };
    return (React.createElement("div", { className: "flex h- flex-row ".concat(selected ? 'bg-blue-200' : 'bg-slate-200', " rounded items-center") },
        React.createElement("div", { className: 'flex' }, fileId && (React.createElement(ImageComponent, { height: '208px', src: fileId.toString(), alt: props.RequisitionProduct.Product.name }))),
        React.createElement("div", { className: 'flex flex-1 flex-col p-3' },
            React.createElement("div", null, props.RequisitionProduct.Product.name),
            React.createElement("div", null, props.RequisitionProduct.ProductPresentation.sku),
            React.createElement("div", { className: 'border-0 border-t border-b border-solid border-gray-400 pt-1 pb-2 mt-2 mb-1' },
                React.createElement("div", null, "Cantidad enviada"),
                React.createElement("div", { className: 'font-medium' },
                    currencyFormat(props.RequisitionProduct.sendQuantity),
                    " (",
                    props.RequisitionProduct.ProductPresentation.name,
                    ")")),
            React.createElement(QtyFieldCloseRequisitionComponent, { defaultValue: props.RequisitionProduct.receivedQuantity.toString(), onConfirm: handleClick }),
            props.RequisitionProduct.receivedQuantity !== props.RequisitionProduct.sendQuantity && (React.createElement("div", { className: 'font-medium' }, "Registro modificado")))));
};
export default ItemProductRequisitionComponent;
