import React from 'react';
import { useParams } from 'react-router-dom';
import GQL_PRODUCT from '../../../../apollo/gql/product';
import LoadingProgress from '../../../../presentationals/LoadingProgress';
import useApolloQuery from '../../../../hooks/useApolloQuery';
import CreateProductIngredientComponent from './Create';
import BodyComponentProductPresentationComponent from './Body';
import ErrorDataComponent from '../../../../presentationals/ErrorData';
var ComponentProductPresentationComponent = function () {
    var params = useParams();
    var _a = useApolloQuery(GQL_PRODUCT.GET_PRODUCT_PRESENTATION_BY_ID, {
        variables: { id: params.productPresentationId },
    }), loading = _a.loading, data = _a.data;
    if (loading)
        return React.createElement(LoadingProgress, null);
    if (!data)
        return React.createElement(ErrorDataComponent, null);
    return (React.createElement(React.Fragment, null,
        React.createElement(BodyComponentProductPresentationComponent, { ProductIngredients: data.ProductIngredients }),
        React.createElement(CreateProductIngredientComponent, null)));
};
export default ComponentProductPresentationComponent;
