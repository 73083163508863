import { Alert } from '@mui/material';
import React from 'react';
import { isCreateProductComponentOpen } from '../../../../../../apollo/cache';
var NoComponentAlertProductPresentationComponent = function () {
    var handleOpenConfigRates = function (e) {
        e.preventDefault();
        isCreateProductComponentOpen(true);
    };
    return (React.createElement(Alert, { severity: 'info', className: 'w-full sm:w-1/2' },
        "De momento, no cuentas con componentes registrados, esto significa que al realizar una transacci\u00F3n con este producto, no se ver\u00E1 afectado en el inventario.",
        React.createElement("br", null),
        "Si lo deseas, puedes",
        ' ',
        React.createElement("a", { href: '#', className: 'text-black font-medium', onClick: handleOpenConfigRates }, "a\u00F1adir un nuevo componente"),
        "."));
};
export default NoComponentAlertProductPresentationComponent;
