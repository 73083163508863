import React from 'react';
import ButtonFooterDialogComponent from '../../../../../../../presentationals/Dialog/Footer/Button';
import getInputErrorFormat from '../../../../../../sale.v2/Action/helpers/getInputErrorFormat';
import setInputErrorFormat from '../../../../../../sale.v2/Action/helpers/setInputErrorFormat';
import { inputError } from '../../../../../../../apollo/cache';
var LimitInventoryActionButtonComponent = function (props) {
    var handleClick = function () {
        inputError({});
        try {
            if (props.values.minimum === '' ||
                isNaN(parseFloat(props.values.minimum)) ||
                parseFloat(props.values.minimum) < 0)
                throw new Error(setInputErrorFormat('minimum', 'Proporciona el mínimo de existencia en inventario.'));
            if (props.values.maximum === '' ||
                isNaN(parseFloat(props.values.maximum)) ||
                parseFloat(props.values.maximum) < 0)
                throw new Error(setInputErrorFormat('maximum', 'Proporciona el máximo de existencia en inventario.'));
            if (props.values.branchId === -1)
                throw new Error(setInputErrorFormat('branchId', 'Selecciona una sucursal.'));
            props.onClick(props.values);
        }
        catch (error) {
            getInputErrorFormat(error);
        }
    };
    return (React.createElement(ButtonFooterDialogComponent, { loading: props.loading, onClick: handleClick }, props.label));
};
export default LimitInventoryActionButtonComponent;
