import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import TableRowProductsRequisitionComponent from './TableRow';
import addRequisitionProductByBarcode from './helpers/addRequisitionProductByBarcode';
import addRequisitionProductById from './helpers/addRequisitionProductById';
var ProductsRequisitionTableComponent = forwardRef(function (props, ref) {
    var _a = useState([]), rows = _a[0], setRows = _a[1];
    var handleAddProductByBarcode = function (Item) {
        var newRows = addRequisitionProductByBarcode(Item, rows);
        setRows(newRows);
    };
    var handleAddProduct = function (Item) {
        var newRows = addRequisitionProductById(Item, rows);
        setRows(newRows);
    };
    var handleRowError = function (index) {
        setRows(function (rows) { return rows.filter(function (el, i) { return i !== index; }); });
    };
    var handleGetProducts = function () { return rows; };
    useImperativeHandle(ref, function () { return ({
        onAddProductByBarcode: handleAddProductByBarcode,
        onAddProduct: handleAddProduct,
        getProducts: handleGetProducts,
    }); });
    return (React.createElement(TableContainer, { className: 'bg-slate-50 rounded border border-solid border-gray-300' },
        React.createElement(Table, { stickyHeader: true, size: 'small' },
            React.createElement(TableHead, null,
                React.createElement(TableRow, { className: 'bg-[#f6f6f6]' },
                    React.createElement(TableCell, null),
                    React.createElement(TableCell, { className: 'whitespace-nowrap' }, "Descripci\u00F3n"),
                    React.createElement(TableCell, { className: 'whitespace-nowrap' }, "Presentaci\u00F3n"),
                    React.createElement(TableCell, { align: 'center' }, "Stock actual"),
                    React.createElement(TableCell, { align: 'center' }, "Cantidad solicitada"))),
            React.createElement(TableBody, null, rows.map(function (el, i) { return (React.createElement(TableRowProductsRequisitionComponent, { key: el.productPresentationId || el.barcode, branchId: props.branchId, index: i, onDelete: handleRowError, onError: handleRowError, quantity: el.quantity, barcode: el.barcode, productPresentationId: el.productPresentationId })); })))));
});
export default ProductsRequisitionTableComponent;
