import React, { useEffect, useState } from 'react';
import AvailableButton from './AvailableButton';
import getHour from './helpers/getHour';
import getScheduleStrutucture from './helpers/getScheduleStrutucture';
var ItemItemAgendaBodyFormCalendarComponent = function (props) {
    var _a = useState(null), hour = _a[0], setHour = _a[1];
    var _b = useState(2), cols = _b[0], setCols = _b[1];
    var _c = useState([]), schedules = _c[0], setSchedules = _c[1];
    var _d = useState(false), isFull = _d[0], setIsFull = _d[1];
    useEffect(function () {
        if (props.schedules && props.schedulePerHour && hour && props.limitNotification) {
            var newSchedules = getScheduleStrutucture(props.schedulePerHour, props.schedules, hour);
            var tmpIsFull = newSchedules.filter(function (el) { return el.schedule; }).length >= props.limitNotification;
            setIsFull(tmpIsFull);
            setSchedules(newSchedules);
        }
    }, [props.schedules, props.schedulePerHour, hour, props.limitNotification]);
    useEffect(function () {
        if (props.schedulePerHour) {
            var totalOfColumns = props.schedulePerHour[0] + props.schedulePerHour[1];
            setCols(totalOfColumns >= 3 ? 3 : totalOfColumns);
        }
    }, [props.schedulePerHour]);
    useEffect(function () {
        var currentHour = getHour(props.date, '00');
        var currentHalfHour = getHour(props.date, '30');
        setHour([currentHour, currentHalfHour]);
    }, [props.date]);
    if (!hour)
        return React.createElement("div", null);
    return (React.createElement("div", { className: "grid grid-cols-1 md:grid-cols-".concat(cols) }, schedules.map(function (el, i) {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
        return (React.createElement("div", { className: 'flex border border-solid p-2 text-sm', style: {
                borderColor: ((_b = (_a = el.schedule) === null || _a === void 0 ? void 0 : _a.Tag) === null || _b === void 0 ? void 0 : _b.color) ? el.schedule.Tag.color : '#6b7280',
                backgroundColor: ((_d = (_c = el.schedule) === null || _c === void 0 ? void 0 : _c.Tag) === null || _d === void 0 ? void 0 : _d.color)
                    ? el.schedule.Tag.color
                    : isFull
                        ? '#89909d'
                        : '#fff',
                color: ((_f = (_e = el.schedule) === null || _e === void 0 ? void 0 : _e.Tag) === null || _f === void 0 ? void 0 : _f.color) ? '#fff' : '#000',
            }, key: i },
            React.createElement("div", { className: 'mr-2' }, el.hour),
            React.createElement("div", null, el.schedule ? (((_h = (_g = el.schedule) === null || _g === void 0 ? void 0 : _g.Customer) === null || _h === void 0 ? void 0 : _h.firstName) && ((_k = (_j = el.schedule) === null || _j === void 0 ? void 0 : _j.Customer) === null || _k === void 0 ? void 0 : _k.lastName) ? ("".concat(el.schedule.Customer.firstName, " ").concat(el.schedule.Customer.lastName)) : (el.schedule.title)) : isFull ? (React.createElement("div", null, "Lleno")) : (React.createElement(AvailableButton, { date: props.date, minutes: el.minutes })))));
    })));
};
export default ItemItemAgendaBodyFormCalendarComponent;
