import React from 'react';

import Table from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import TableBody from './Body';

const ProductPromotionProductComponent = () => {
  return (
    <TableContainer className='border border-solid border-[#d8d8d8] rounded my-2 min-h-[200px]'>
      <Table size='small'>
        <TableHead>
          <TableRow className='bg-[#f6f6f6]'>
            <TableCell padding='none' />
            <TableCell>Descripción</TableCell>
            <TableCell>Presentación</TableCell>
            <TableCell align='center'>Cant.</TableCell>
            <TableCell align='center'>Recibido</TableCell>
            <TableCell align='right'>Descuento</TableCell>
            <TableCell align='right'>P. Unitario</TableCell>
            <TableCell align='right'>Importe</TableCell>
          </TableRow>
        </TableHead>
        <TableBody />
      </Table>
    </TableContainer>
  );
};

export default ProductPromotionProductComponent;
