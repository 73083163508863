import React from 'react';
import { Divider, Typography } from '@mui/material';
import Title from './Title';
import Tags from './Tags';
import Hour from './Hour';
import QtyPerHour from './QtyPerHour';
import LimitNotification from './LimitNotification';
var ItemAgendaSettingsScheduleAvailabilityDialogSettingButtonFooterFormCalendarComponent = function (props) {
    return (React.createElement("div", { className: 'bg-gray-100 p-4 mb-4 rounded' },
        React.createElement(Typography, { variant: 'subtitle1', className: 'font-bold' },
            "Agenda ",
            props.index + 1),
        React.createElement(Title, { index: props.index }),
        React.createElement(Divider, { className: 'my-4' }),
        React.createElement(Hour, { index: props.index }),
        React.createElement(Divider, { className: 'my-4' }),
        React.createElement(LimitNotification, { index: props.index }),
        React.createElement(Divider, { className: 'my-4' }),
        React.createElement(QtyPerHour, { index: props.index }),
        React.createElement(Divider, { className: 'my-4' }),
        React.createElement(Tags, { index: props.index })));
};
export default ItemAgendaSettingsScheduleAvailabilityDialogSettingButtonFooterFormCalendarComponent;
