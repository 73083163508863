var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { FormLabel, Paper } from '@mui/material';
import InputProductFieldComponent from './Input';
import FieldErrorComponent from '../Error';
import FormControlProductFieldComponent from './FormControl';
import RHFProductFieldPopoverComponent from './Popover';
import ProductFieldProvider from '../../../store/productField/Provider';
var RHFProductFieldComponent = function (_a) {
    var name = _a.name, config = _a.config, label = _a.label, props = __rest(_a, ["name", "config", "label"]);
    return (React.createElement(ProductFieldProvider, null,
        React.createElement(FormControlProductFieldComponent, { disabled: props.disabled },
            label && (React.createElement(FormLabel, { htmlFor: name, component: 'legend' }, label)),
            React.createElement(Paper, { className: 'flex px-2 py-[3px] bg-transparent border-[#c5c5c5] items-center', variant: 'outlined' },
                React.createElement(InputProductFieldComponent, { name: name, disabled: props.disabled, placeholder: props.placeholder })),
            React.createElement(FieldErrorComponent, { name: name })),
        React.createElement(RHFProductFieldPopoverComponent, { name: name })));
};
export default RHFProductFieldComponent;
