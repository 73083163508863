var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { gql } from '@apollo/client';
import FRAGMENT_PRODUCT_PRESENTATION_PRICES from './fragmentProductPresentationPrices';
import FRAGMENT_PRODUCT_INGREDIENT from './fragmentProductIngredient';
var GET_PRODUCT_PRESENTATION_BY_ID = gql(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  ", "\n  ", "\n  query ProductPresentation($id: ID!) {\n    productPresentation(id: $id) {\n      id\n      name\n      forPursache\n      forRequisition\n      forSale\n      quantity\n      barcode\n      sku\n      price\n      uberPrice\n      didiPrice\n      rappiPrice\n      shippingPrice\n      pickupPrice\n      limit\n      printer\n      ProductPresentationPrices {\n        ...FragmentProductPresentationPrices\n      }\n      ProductIngredients {\n        ...FragmentProductIngredient\n      }\n    }\n  }\n"], ["\n  ", "\n  ", "\n  query ProductPresentation($id: ID!) {\n    productPresentation(id: $id) {\n      id\n      name\n      forPursache\n      forRequisition\n      forSale\n      quantity\n      barcode\n      sku\n      price\n      uberPrice\n      didiPrice\n      rappiPrice\n      shippingPrice\n      pickupPrice\n      limit\n      printer\n      ProductPresentationPrices {\n        ...FragmentProductPresentationPrices\n      }\n      ProductIngredients {\n        ...FragmentProductIngredient\n      }\n    }\n  }\n"])), FRAGMENT_PRODUCT_PRESENTATION_PRICES, FRAGMENT_PRODUCT_INGREDIENT);
export default GET_PRODUCT_PRESENTATION_BY_ID;
var templateObject_1;
