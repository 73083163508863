import React, { useState, useEffect } from 'react';

import { useQuery, useMutation, useLazyQuery } from '@apollo/client';

import AddIcon from '@mui/icons-material/AddOutlined';
import CloseIcon from '@mui/icons-material/CloseOutlined';
import Typography from '@mui/material/Typography';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';

import Grid from '@mui/material/Grid';

import Autocomplete from '../../presentationals/FormComponents/Autocomplete';
import FormDialog from '../../presentationals/FormDialog';
import TextField from '../../presentationals/FormComponents/TextField';
import DateField from '../../presentationals/FormComponents/DateField';
import SelectField from '../../presentationals/FormComponents/SelectField';
import PaymentTicket from '../../presentationals/PDF/payment.80mm';
import IconButton from '../../presentationals/IconButton';
import Checkbox from '../../presentationals/Checkbox';
import Button from '../../presentationals/Button';

import { parseError, capitalize, dateTimeFormat, currencyFormat } from '../../helpers';

import GQL_TRANSACTION from '../transaction/_gql';
import GQL_PAYMENT_METHOD from '../paymentMethod/_gql';
import GQL from './_gql';
import InvoiceDialog from './invoice.dialog';
import CancelForm from '../transaction.report.v2/cancel.form';
import useBranchConfig from '../../hooks/useBranchConfig';
import useSnackbarDialog from '../../store/snackbar/snackbarDialog';

const defaultValues = {
  total: '',
  paymentMethodId: -1,
  currency: 'MXN',
  exchangeRate: 1,
  transactionAccount: null,
  newTransactionAccount: '',
  date: new Date(),
  commentary: '',
};

const currencyOptions = [
  { value: 'MXN', label: 'Pesos Mexicanos (MXN)' },
  { value: 'USD', label: 'Dólares Americanos (USD)' },
];

const Component = props => {
  const { setSnackbar } = useSnackbarDialog();
  const { data: branchConfig } = useBranchConfig();
  const [values, setValues] = useState(defaultValues);
  const [errors, setErrors] = useState({});
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [transactionAccounts, setTransactionAccounts] = useState([]);
  const [newTrasactionAccount, setNewTransactionAccount] = useState(false);
  const [transactionValues, setTransactionValues] = useState({});
  const [transactions, setTransactions] = useState([]);
  const [isInvoiceFormOpen, setIsInvoiceFormOpen] = useState([false, null]);
  const [isOpenCancelTransaction, setIsOpenCancelTransaction] = useState([false, null, null]);

  const { data, loading } = useQuery(GQL_PAYMENT_METHOD.GET_AUTOCOMPLETE, {
    variables: { limit: 0 },
  });
  const [createTransactionAccount] = useMutation(GQL_PAYMENT_METHOD.CREATE_TRANSACTION_ACCOUNT, {
    update(cache, { data: response }) {
      cache.modify({
        fields: {
          transactionAccounts(cachedTransactionAccounts) {
            const newTransactionAccountRef = cache.writeFragment({
              data: { ...response.createTransactionAccount },
              fragment: GQL_PAYMENT_METHOD.FRAGMENT_CORE_TRANSACTION_ACCOUNT_FIELDS,
            });
            return {
              ...cachedTransactionAccounts,
              count: cachedTransactionAccounts.count + 1,
              rows: [...cachedTransactionAccounts.rows, newTransactionAccountRef],
            };
          },
        },
      });
    },
  });
  const { data: dataTransactionAccounts } = useQuery(GQL_PAYMENT_METHOD.GET_TRANSACTION_ACCOUNTS);
  const [getTransactions, { data: dataTransactions }] = useLazyQuery(GQL_TRANSACTION.GET);
  const [createTransaction, { loading: loadingCreate }] = useMutation(GQL_TRANSACTION.CREATE, {
    update(cache, { data: response }) {
      const payment = parseFloat(response.createTransaction.total);
      cache.modify({
        id: `AccountReceivable:${response.createTransaction.AccountReceivable.id}`,
        fields: {
          payment(cachedPayment) {
            return cachedPayment + payment;
          },
          balance(cachedBalance) {
            return cachedBalance - payment;
          },
        },
      });
      cache.modify({
        id: 'Totals:-1',
        fields: {
          qty(cachedQty) {
            return cachedQty + 1;
          },
          amount(cachedAmount) {
            return cachedAmount + payment;
          },
        },
      });
      cache.modify({
        id: `Totals:${response.createTransaction.paymentMethodId}`,
        fields: {
          qty(cachedQty) {
            return cachedQty + 1;
          },
          amount(cachedAmount) {
            return cachedAmount + payment;
          },
        },
      });
      cache.modify({
        fields: {
          transactions(existingTransactionsRefs) {
            return {
              ...existingTransactionsRefs,
              count: existingTransactionsRefs.count + 1,
              rows: [
                ...existingTransactionsRefs.rows,
                {
                  id: response.createTransaction.id,
                  __typename: 'Transaction',
                  total: payment,
                  paymentMethodId:
                    response.createTransaction.paymentMethodId > 0
                      ? response.createTransaction.paymentMethodId
                      : null,
                  clientCreatedAt: response.createTransaction.clientCreatedAt,
                },
              ],
            };
          },
        },
      });
      cache.modify({
        fields: {
          transactionsReport(existingTransactionsRefs) {
            return {
              ...existingTransactionsRefs,
              count: existingTransactionsRefs.count + 1,
              rows: [
                ...existingTransactionsRefs.rows,
                {
                  id: response.createTransaction.id,
                  __typename: 'Transaction',
                  total: payment,
                  paymentMethodId:
                    response.createTransaction.paymentMethodId > 0
                      ? response.createTransaction.paymentMethodId
                      : null,
                  clientCreatedAt: response.createTransaction.clientCreatedAt,
                },
              ],
            };
          },
        },
      });
    },
  });

  const [createInvoiceComplement, { loading: isStoreLoading }] = useMutation(
    GQL.CREATE_INVOICE_COMPLEMENT,
  );

  // const [deleteTransaction] = useMutation(GQL.DELETE_TRANSACTION);

  useEffect(() => {
    if (dataTransactionAccounts) {
      const tmpTransactionAccounts = dataTransactionAccounts.transactionAccounts.rows.map(el => ({
        value: el.id,
        label: el.name,
      }));
      setTransactionAccounts(tmpTransactionAccounts);
    }
  }, [dataTransactionAccounts]);
  useEffect(() => {
    if (data) {
      const tmp = data.paymentMethods.rows.map(tmp => ({
        value: tmp.id,
        label: capitalize(tmp.name),
      }));
      setPaymentMethods([{ value: -1, label: 'EFECTIVO' }, ...tmp]);
    }
  }, [data]);

  useEffect(() => {
    if (props.accountReceivableId)
      getTransactions({ variables: { accountReceivableId: props.accountReceivableId } });
  }, [props.accountReceivableId]);

  useEffect(() => {
    if (dataTransactions && dataTransactions.transactions.rows.length > 0) {
      const tmp = dataTransactions.transactions.rows.reduce((object, el) => {
        if (el.invoiceComplementId) return object;
        return { ...object, [el.id]: false };
      }, {});
      setTransactionValues({ '-1': false, ...tmp });
      setTransactions([
        { id: '-1', clientCreatedAt: 'Seleccionar Todos', total: 0 },
        ...dataTransactions.transactions.rows,
      ]);
    }
  }, [dataTransactions]);

  const handleClose = () => {
    props.handleClose();
    setValues(defaultValues);
    setTransactions([]);
    setTransactionValues({});
  };

  const handleChange = (name, value) => {
    setValues(values => ({ ...values, [name]: value }));
  };

  const handleAction = async () => {
    try {
      if (values.total === '' || parseFloat(values.total) <= 0) {
        const totalError = new Error('La cantidad de abono tiene que ser mayor a cero.');
        totalError.name = 'total';
        totalError.input = true;
        throw totalError;
      }
      if (
        values.currency !== 'MXN' &&
        (Number.isNaN(parseFloat(values.exchangeRate)) || parseFloat(values.exchangeRate) <= 0)
      ) {
        const totalError = new Error('El precio del dólar es inválido.');
        totalError.name = 'exchangeRate';
        totalError.input = true;
        throw totalError;
      }

      let transactionAccountId = values.transactionAccount ? values.transactionAccount.value : null;

      if (newTrasactionAccount) {
        const responseTransactionAccount = await createTransactionAccount({
          variables: { name: values.newTransactionAccount },
        });
        transactionAccountId = responseTransactionAccount.data.createTransactionAccount.id;
      }

      const variables = {
        accountReceivableId: props.accountReceivableId,
        total: parseFloat(values.total),
        paymentMethodId: parseInt(values.paymentMethodId) !== -1 ? values.paymentMethodId : null,
        calendarId: props.calendarId && props.calendarId,
        currency: values.currency,
        exchangeRate: values.currency === 'MXN' ? 1 : parseFloat(values.exchangeRate),
        transactionAccountId,
        date: values.date ? values.date : null,
        commentary: values.commentary,
      };
      await createTransaction({ variables });
      setValues(defaultValues);
      setNewTransactionAccount(false);
      if (branchConfig?.printReceiptAfterPayment) PaymentTicket(props.accountReceivableId);
      handleClose();
    } catch (e) {
      console.log(e);
      if (e.input) {
        setErrors({ [e.name]: e.message });
        return;
      }
      const parseErrors = parseError(e);
      parseErrors.forEach(el => {
        if (el.name === 'BAD_USER_INPUT') setErrors(el.message);
        else
          setSnackbar({
            variables: {
              isOpen: true,
              time: 3000,
              label: el.message,
              severity: 'error',
            },
          });
      });
    }
  };

  const handleAddTransaction = () => {
    setNewTransactionAccount(newTrasactionAccount => !newTrasactionAccount);
  };

  const handleDateChange = (name, value) => {
    setValues(values => ({ ...values, [name]: value }));
  };

  const handleCheckBox = (name, checked) => {
    if (name === '-1') {
      const tmp = Object.keys(transactionValues).reduce((object, key) => {
        return { ...object, [key]: checked };
      }, transactionValues);
      setTransactionValues(tmp);
      return;
    }
    const numberOfTrue = Object.keys(transactionValues).filter(key => !!transactionValues[key]);
    let number = numberOfTrue.length;
    if (checked) number = number + 2;
    else number = number - 1;
    if (number === Object.keys(transactionValues).length)
      setTransactionValues(values => ({ ...values, [name]: checked, '-1': true }));
    else setTransactionValues(values => ({ ...values, [name]: checked, '-1': false }));
  };

  const handleInvoice = async () => {
    try {
      if (
        Object.keys(transactionValues).filter(key => !!transactionValues[key] && key !== '-1')
          .length <= 0
      ) {
        const e = new Error('Selecciona una transacción.');
        e.name = 'transactions';
        e.input = true;
        throw e;
      }
      const responseInvoice = await createInvoiceComplement({
        variables: {
          accountReceivableId: props.accountReceivableId,
          Transactions: Object.keys(transactionValues).filter(
            key => !!transactionValues[key] && key !== '-1',
          ),
        },
      });
      setIsInvoiceFormOpen([true, responseInvoice.data.createInvoiceComplement.uuid]);
      setValues(defaultValues);
    } catch (e) {
      console.log(e);
      if (e.input) {
        setSnackbar({
          variables: {
            isOpen: true,
            time: 3000,
            label: e.message,
            severity: 'error',
          },
        });
        return;
      }
      const parseErrors = parseError(e);
      parseErrors.forEach(el => {
        if (el.name === 'BAD_USER_INPUT') setErrors(el.message);
        else
          setSnackbar({
            variables: {
              isOpen: true,
              time: 3000,
              label: el.message,
              severity: 'error',
            },
          });
      });
    }
  };

  const handleInvoiceClose = () => {
    setIsInvoiceFormOpen([false, null]);
    handleClose();
  };

  const handleCloseCancel = id => {
    setIsOpenCancelTransaction([false, null, null]);
    const tmp = transactions
      .map(el => {
        if (parseInt(el.id) === parseInt(id)) return;
        return el;
      })
      .filter(elCh => elCh);
    setTransactions(tmp);
  };
  const handleDeleteItem = async (e, { id, invoiceComplementId }) => {
    setIsOpenCancelTransaction([true, id, invoiceComplementId]);
    // try {
    //   await deleteTransaction({ variables: { id } });
    //   const tmp = transactions
    //     .map(el => {
    //       if (parseInt(el.id) === parseInt(id)) return;
    //       return el;
    //     })
    //     .filter(elCh => elCh);
    //   setTransactions(tmp);
    // } catch (e) {
    //   console.log(e);
    //   if (e.input) {
    //     setSnackbar({
    //       variables: {
    //         isOpen: true,
    //         time: 3000,
    //         label: e.message,
    //         severity: 'error',
    //       },
    //     });
    //     return;
    //   }
    //   const parseErrors = parseError(e);
    //   parseErrors.forEach(el => {
    //     if (el.name === 'BAD_USER_INPUT') setErrors(el.message);
    //     else
    //       setSnackbar({
    //         variables: {
    //           isOpen: true,
    //           time: 3000,
    //           label: el.message,
    //           severity: 'error',
    //         },
    //       });
    //   });
    // }
  };

  return (
    <FormDialog
      title='Abono a cuenta por cobrar'
      isOpen={props.isOpen}
      handleClose={handleClose}
      handleAction={handleAction}
      isLoading={loading || loadingCreate}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} md={8}>
          <SelectField
            value={values.currency}
            error={errors.currency}
            name='currency'
            label='Moneda'
            onChange={handleChange}
            options={currencyOptions}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12} md={8}>
          {values.currency !== 'MXN' && (
            <TextField
              value={values.exchangeRate}
              error={errors.exchangeRate}
              name='exchangeRate'
              label='Precio del Dólar'
              onChange={handleChange}
              type='number'
            />
          )}
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12} md={8}>
          <DateField
            noFormat
            size='small'
            variant='outlined'
            name='date'
            label='Fecha de Pago'
            onChange={handleDateChange}
            value={values.date}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12} md={8}>
          <TextField
            value={values.total}
            error={errors.total}
            name='total'
            label='Cantidad'
            onChange={handleChange}
            type='number'
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12} md={8}>
          <SelectField
            value={values.paymentMethodId}
            error={errors.paymentMethodId}
            name='paymentMethodId'
            label='Método de Pago'
            onChange={handleChange}
            options={paymentMethods}
          />
        </Grid>
      </Grid>
      <Grid container alignItems='flex-end' spacing={2}>
        <Grid item xs={11} md={8}>
          {newTrasactionAccount ? (
            <TextField
              name='newTransactionAccount'
              label='¿Cuál será el nombre de la nueva cuenta?'
              value={values.newTransactionAccount}
              onChange={handleChange}
            />
          ) : (
            <Autocomplete
              name='transactionAccount'
              label='Cuenta (Opcional)'
              value={values.transactionAccount}
              options={transactionAccounts}
              onChange={handleChange}
            />
          )}
        </Grid>
        <Grid item xs={1} md={4}>
          <IconButton
            color='secondary'
            icon={
              newTrasactionAccount ? <CloseIcon fontSize='small' /> : <AddIcon fontSize='small' />
            }
            action={handleAddTransaction}
            size='large'
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12} md={8}>
          <TextField
            value={values.commentary}
            error={errors.commentary}
            name='commentary'
            label='Comentario'
            onChange={handleChange}
            multiline
          />
        </Grid>
      </Grid>
      <br />
      Esta vista es temporal.
      <div>
        {transactions.length > 0 && <Typography variant='subtitle1'>Abonos</Typography>}
        {transactions.length > 0 && props.showGenerateComplement && (
          <Checkbox
            handleChange={handleCheckBox}
            noFormat
            values={transactions.map(el => ({
              name: el.id,
              label:
                el.id !== '-1'
                  ? `${dateTimeFormat(el.clientCreatedAt)}, $${currencyFormat(el.total)}${
                      el.invoiceComplementId
                        ? ` Complemento: ${el.InvoiceComplement.folio} ${el.InvoiceComplement.serial}`
                        : ''
                    } ${el.commentary}`
                  : el.clientCreatedAt,
              checked: !!transactionValues[el.id] && transactionValues[el.id],
              disabled: el.invoiceComplementId,
              extra:
                el.id !== '-1' ? (
                  <IconButton
                    label='Eliminar'
                    icon={<DeleteOutlineIcon />}
                    params={{ id: el.id, invoiceComplementId: el.invoiceComplementId }}
                    action={handleDeleteItem}
                    size='small'
                  />
                ) : null,
            }))}
          />
        )}
        {transactions.length > 0 &&
          !props.showGenerateComplement &&
          transactions
            .map(el => {
              if (el.id === '-1') return false;
              return (
                <div key={el.id}>
                  {!el.invoiceComplementId && (
                    <IconButton
                      label='Eliminar'
                      icon={<DeleteOutlineIcon />}
                      params={{ id: el.id }}
                      action={handleDeleteItem}
                      size='small'
                    />
                  )}
                  {`${dateTimeFormat(el.clientCreatedAt)}, $${currencyFormat(el.total)} ${
                    el.commentary
                  }`}
                </div>
              );
            })
            .filter(tmp => tmp)}
      </div>
      {transactions.length > 0 && props.showGenerateComplement && (
        <div>
          {!props.hideActions && (
            <Button
              label='Generar Complemento de Pago'
              onClick={handleInvoice}
              color='secondary'
              variant='outlined'
              isLoading={isStoreLoading}
            />
          )}
        </div>
      )}
      <InvoiceDialog
        isOpen={isInvoiceFormOpen[0]}
        uuid={isInvoiceFormOpen[1]}
        handleClose={handleInvoiceClose}
      />
      <CancelForm
        isOpen={isOpenCancelTransaction[0]}
        handleClose={handleCloseCancel}
        id={isOpenCancelTransaction[1]}
        invoiceId={isOpenCancelTransaction[2]}
      />
    </FormDialog>
  );
};

export default Component;
