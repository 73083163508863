var getQuestionTypeById = function (value) {
    switch (value) {
        case 103:
            return 'Clasificación';
        case 102:
            return 'Subtítulo';
        case 101:
            return 'Título';
        case 4:
            return 'Pregunta con opción única';
        case 3:
            return 'Pregunta con opción múltiple';
        case 2:
            return 'Pregunta con rango numérico';
        case 1:
        default:
            return 'Pregunta abierta';
    }
};
export default getQuestionTypeById;
