import { useEffect, useState } from 'react';
import { useReactiveVar } from '@apollo/client';
import { filter } from '../../../apollo/cache';
import GQL_ATTENDANCE from '../../../apollo/gql/attendance';
import useApolloLazyQuery from '../../../hooks/useApolloLazyQuery';
var useGetAttendanceDetail = function () {
    var filterVar = useReactiveVar(filter);
    var _a = useState([]), data = _a[0], setData = _a[1];
    var _b = useApolloLazyQuery(GQL_ATTENDANCE.GET_ATTENDANCE_REPORT), get = _b[0], _c = _b[1], response = _c.data, loading = _c.loading;
    useEffect(function () {
        if (filterVar.start && filterVar.end)
            get({ variables: filterVar });
    }, [filterVar]);
    useEffect(function () {
        if (response) {
            var data_1 = response.rows.map(function (el) { return ({
                id: el.Employee.id,
                delayQty: el.delayQty,
                delay: el.delay,
                absent: el.absent,
                workHours: el.workHours,
                extraHours: el.extraHours,
                totalWorkHours: el.totalWorkHours,
                totalDays: el.totalDays,
                employee: "".concat(el.Employee.firstName, " ").concat(el.Employee.lastName),
            }); });
            setData(data_1);
        }
    }, [response]);
    return { data: data, loading: loading };
};
export default useGetAttendanceDetail;
