var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { useReactiveVar } from '@apollo/client';
import SubtitleDialogComponent from '../../../../presentationals/Dialog/helpers/Subtitle';
import TextFieldComponent from '../../../../presentationals/FormComponents/TextField';
import { inputError } from '../../../../apollo/cache';
import SwitchComponent from '../../../../presentationals/FormComponents/Switch';
import PaymentMethodSatFieldComponent from './PaymentMethodSatField';
import getDefaulPaymentMethod from '../../helpers/getDefaulPaymentMethod';
var BodyPaymentMethodComponent = forwardRef(function (props, ref) {
    var defaultValues = getDefaulPaymentMethod();
    var _a = useState(defaultValues), values = _a[0], setValues = _a[1];
    var errors = useReactiveVar(inputError);
    useEffect(function () {
        if (props.defaultValues)
            setValues(getDefaulPaymentMethod(props.defaultValues));
    }, [props.defaultValues]);
    var handleChange = function (name, value) {
        var _a;
        setValues(__assign(__assign({}, values), (_a = {}, _a[name] = value, _a)));
    };
    var handleSwitchChange = function (name, value) {
        var _a;
        setValues(__assign(__assign({}, values), (_a = {}, _a[name] = value, _a)));
    };
    var getValues = function () { return values; };
    var handleClear = function () {
        setValues(defaultValues);
    };
    useImperativeHandle(ref, function () { return ({
        getValues: getValues,
        onClear: handleClear,
    }); });
    return (React.createElement("div", { className: 'flex-1 px-6 py-4 flex flex-col md:grid md:grid-cols-4 md:gap-4 overflow-auto' },
        React.createElement("div", { className: 'mb-4 md:mb-0' },
            React.createElement(SubtitleDialogComponent, { label: 'Datos generales' }),
            React.createElement(TextFieldComponent, { label: 'Nombre', name: 'name', value: values.name, error: errors.name, onChange: handleChange }),
            React.createElement(TextFieldComponent, { label: 'Comisi\u00F3n por uso (%)', name: 'commission', value: values.commission, error: errors.commission, onChange: handleChange, type: 'number' }),
            React.createElement(PaymentMethodSatFieldComponent, { value: values.code, onChange: handleChange })),
        React.createElement("div", { className: 'mb-4 md:mb-0' },
            React.createElement(SubtitleDialogComponent, { label: 'Configuraci\u00F3n' }),
            React.createElement(SwitchComponent, { name: 'isCash', label: '\u00BFRecibir\u00E1 dinero en efectivo?', defaultValue: values.isCash, onChange: handleSwitchChange, className: 'mt-2' }),
            React.createElement(SwitchComponent, { name: 'isAccountPayable', label: '\u00BFSe podr\u00E1n realizar pagos a proveedores con esta forma de pago?', defaultValue: values.isAccountPayable, onChange: handleSwitchChange, className: 'mt-2' }),
            React.createElement(SwitchComponent, { name: 'isUnique', label: '\u00BFEs \u00FAnico al cobrar?', defaultValue: values.isUnique, onChange: handleSwitchChange, className: 'mt-2' }))));
});
export default BodyPaymentMethodComponent;
