import React, { useRef } from 'react';
import { Table, TableContainer } from '@mui/material';
import TableHeadProductInventoryComponent from './TableHead';
import TableBodyProductInventoryComponent from './TableBody';
import SerialNumberProductsDialogComponent from '../SerialNumberProductsDialog';
var TableProductInventoryComponent = function (props) {
    var serialNumberProductsDialogRef = useRef(null);
    var handleProductClick = function (id) {
        var _a;
        (_a = serialNumberProductsDialogRef.current) === null || _a === void 0 ? void 0 : _a.open(id);
    };
    return (React.createElement("div", { className: 'flex flex-1 flex-col basis-0 overflow-auto' },
        React.createElement(TableContainer, null,
            React.createElement(Table, { size: 'small', stickyHeader: true },
                React.createElement(TableHeadProductInventoryComponent, { Inventories: props.rows.length > 0 ? props.rows[0].Inventories : [] }),
                React.createElement(TableBodyProductInventoryComponent, { onItemClick: handleProductClick, rows: props.rows }))),
        React.createElement(SerialNumberProductsDialogComponent, { ref: serialNumberProductsDialogRef })));
};
export default TableProductInventoryComponent;
