import React, { useState, useEffect } from 'react';

import { useQuery } from '@apollo/client';

import Typography from '@mui/material/Typography';
import Switch from '@mui/material/Switch';

import FilterDrawer from '../../presentationals/FilterDrawer.v2';
import Checkbox from '../../presentationals/Checkbox';

import { filter } from '../../apollo/cache';

import GQL_TAG from '../Tag/_gql';

const FilterDialog = () => {
  const showAllCache = localStorage.getItem('showAllCalendars')
    ? localStorage.getItem('showAllCalendars')
    : 'true';

  const [values, setValues] = useState({});
  const [showAll, setShowAll] = useState(true);
  const { data: dataTags } = useQuery(GQL_TAG.GET);
  useEffect(() => {
    if (dataTags) {
      const tmp = dataTags.tags.reduce((object, el) => ({ ...object, [el.id]: true }), {});
      setValues(tmp);
      filter({ tags: tmp, showAll });
    }
  }, [dataTags]);

  useEffect(() => {
    const tmpShowAll = showAllCache === 'true';
    setShowAll(tmpShowAll);
    filter({ ...filter(), showAll: tmpShowAll });
  }, [showAllCache]);

  const handleChange = event => {
    setShowAll(event.target.checked);
  };
  const handleCheckBox = (name, checked) => {
    setValues(values => ({ ...values, [name]: checked }));
  };
  const handleAction = () => {
    localStorage.setItem('showAllCalendars', showAll);
    filter({ tags: values, showAll });
  };
  return (
    <FilterDrawer handleAction={handleAction}>
      <Typography variant='subtitle1'>Mostrar todo:</Typography>
      <Switch checked={showAll} onChange={handleChange} name='checkedA' />
      <Typography variant='subtitle1'>Etiquetas:</Typography>
      <Checkbox
        handleChange={handleCheckBox}
        noFormat
        values={
          dataTags &&
          dataTags.tags.map(el => ({
            name: el.id,
            label: el.name,
            checked: !!values[el.id] && values[el.id],
            color: el.color,
          }))
        }
      />
    </FilterDrawer>
  );
};

export default FilterDialog;
