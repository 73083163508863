import CREATE_PROVIDER from './createProvider';
import DELETE_PROVIDER from './deleteProvider';
import EDIT_PROVIDER from './editProvider';
import FRAGMENT_PROVIDER from './fragmentProvider';
import GET_PROVIDER_BY_ID from './getProviderById';
import GET_PROVIDERS from './getProviders';
var GQL_PROVIDER = {
    CREATE_PROVIDER: CREATE_PROVIDER,
    DELETE_PROVIDER: DELETE_PROVIDER,
    EDIT_PROVIDER: EDIT_PROVIDER,
    FRAGMENT_PROVIDER: FRAGMENT_PROVIDER,
    GET_PROVIDER_BY_ID: GET_PROVIDER_BY_ID,
    GET_PROVIDERS: GET_PROVIDERS,
};
export default GQL_PROVIDER;
