var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { useEffect, useState } from 'react';
import { useReactiveVar } from '@apollo/client';
import GQL_SALE from '../../../apollo/gql/sale';
import useApolloLazyQuery from '../../../hooks/useApolloLazyQuery';
import { filter } from '../../../apollo/cache';
import { currencyFormat } from '../../../helpers';
var useGetSaleProductPresentationRotation = function () {
    var filterVar = useReactiveVar(filter);
    var _a = useState([]), data = _a[0], setData = _a[1];
    var _b = useApolloLazyQuery(GQL_SALE.GET_SALE_PRODUCT_PRESENTATIONS_ROTATION), getSales = _b[0], queryResult = _b[1];
    useEffect(function () {
        getSales({
            variables: {
                like: filterVar.like,
                start: filterVar.start,
                end: filterVar.end,
                branchId: filterVar.branchId,
                status: filterVar.status,
            },
        });
    }, [filterVar]);
    useEffect(function () {
        if (queryResult.data) {
            var data_1 = queryResult.data.map(function (el) { return ({
                id: el.productPresentationId,
                sku: el.sku,
                product: "".concat(el.productName, " ").concat(el.productPresentationName),
                quantity: currencyFormat(el.quantity),
                portion: '',
                presentation: '',
                total: currencyFormat(el.total),
            }); });
            setData(data_1);
        }
    }, [queryResult.data]);
    return __assign(__assign({}, queryResult), { data: data });
};
export default useGetSaleProductPresentationRotation;
