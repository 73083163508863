import { Link } from '@mui/material';
import React from 'react';
import CONFIG from '../../../config/config.json';
var DownloadCfdiFormInvoiceComplementOnlineComponent = function (props) {
    return (React.createElement("div", { className: 'mt-6' },
        React.createElement("div", null,
            React.createElement(Link, { href: "".concat(CONFIG.restServer.uri, "/files/invoices/complements/").concat(props.uuid, "/pdf"), target: '_blank', className: 'mr-8' },
                React.createElement("img", { src: 'https://storage.capitalike.com/files/23184', alt: 'Descargar PDF', className: 'w-28' })),
            React.createElement(Link, { href: "".concat(CONFIG.restServer.uri, "/files/invoices/").concat(props.uuid, "/xml"), target: '_blank' },
                React.createElement("img", { src: 'https://storage.capitalike.com/files/23185', alt: 'Descargar XML', className: 'w-28' }))),
        React.createElement("div", { className: 'mt-6 mb-4' },
            "Tu complemento de pago con UUID ",
            React.createElement("strong", null, props.uuid),
            " fue generada exitosamente. Puedes descargarla en formato PDF y XML dando clic en las im\u00E1genes.")));
};
export default DownloadCfdiFormInvoiceComplementOnlineComponent;
