import React, { useState } from 'react';
import { useReactiveVar } from '@apollo/client';
import SelectFieldComponent from '../SelectField';
import { inputError } from '../../../apollo/cache';
import { paymentMethodOptions } from '../../../helpers/invoice.options';
var SelectPaymentMethodFieldComponent = function (props) {
    var errors = useReactiveVar(inputError);
    var _a = useState('PUE'), value = _a[0], setValue = _a[1];
    var handleChange = function (name, value) {
        setValue(value);
        props.onChange(name, value);
    };
    return (React.createElement(SelectFieldComponent, { name: props.name || 'paymentMethodId', label: 'M\u00E9todo de pago', onChange: handleChange, value: value, error: errors.paymentMethodId, options: paymentMethodOptions }));
};
export default SelectPaymentMethodFieldComponent;
