var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { gql } from '@apollo/client';
import FRAGMENT_PRODUCT from './fragmentProduct';
import FRAGMENT_PRODUCT_PRESENTATION from './fragmentProductPresentation';
var GET_PRODUCT_BY_ID = gql(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  ", "\n  ", "\n  query Product($id: ID!, $branchId: ID, $includeBaseInPresentations: Boolean) {\n    product(id: $id, branchId: $branchId, includeBaseInPresentations: $includeBaseInPresentations) {\n      ...FragmentProduct\n      ProductPresentations {\n        ...FragmentProductPresentation\n      }\n      Files {\n        id\n      }\n      ProductPresentationPrices {\n        id\n        price\n        type\n        Rate {\n          id\n          name\n        }\n      }\n      Commentaries\n      public\n      currentInventory\n    }\n  }\n"], ["\n  ", "\n  ", "\n  query Product($id: ID!, $branchId: ID, $includeBaseInPresentations: Boolean) {\n    product(id: $id, branchId: $branchId, includeBaseInPresentations: $includeBaseInPresentations) {\n      ...FragmentProduct\n      ProductPresentations {\n        ...FragmentProductPresentation\n      }\n      Files {\n        id\n      }\n      ProductPresentationPrices {\n        id\n        price\n        type\n        Rate {\n          id\n          name\n        }\n      }\n      Commentaries\n      public\n      currentInventory\n    }\n  }\n"])), FRAGMENT_PRODUCT, FRAGMENT_PRODUCT_PRESENTATION);
export default GET_PRODUCT_BY_ID;
var templateObject_1;
