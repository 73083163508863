import React from 'react';

import { useMutation } from '@apollo/client';

import FormDialog from '../../presentationals/FormDialog';

import GQL from './_gql';
import getInputErrorFormat from '../sale.v2/Action/helpers/getInputErrorFormat';

const Component = props => {
  const [updateDeliveryStatus, { loading }] = useMutation(GQL.UPDATE_DELIVERY_STATUS);

  const handleClose = () => {
    props.handleClose();
  };

  const handleAction = async () => {
    try {
      const deliveryStatus = parseInt(props.deliveryStatus) === 0 ? 1 : 2;
      await updateDeliveryStatus({
        variables: {
          id: props.id,
          deliveryStatus,
        },
      });
      handleClose();
    } catch (e) {
      getInputErrorFormat(e);
    }
  };

  return (
    <FormDialog
      title={
        parseInt(props.deliveryStatus) === 0
          ? 'La venta está lista para enviar'
          : 'La venta fue enviada'
      }
      isOpen={props.isOpen}
      handleClose={handleClose}
      handleAction={handleAction}
      actionLabel='Confirmar'
      isLoading={loading}
    >
      {parseInt(props.deliveryStatus) === 0
        ? 'La venta está lista para ser enviada.'
        : 'La venta ya fue enviada.'}
    </FormDialog>
  );
};

export default Component;
