import { useReactiveVar } from '@apollo/client';
import React, { useEffect } from 'react';
import { exportXls } from '../../../apollo/cache';
import receivableXlsDoc from '../helpers/XLSDoc';
var ExportReceivableXlsComponent = function () {
    var exportXlsVar = useReactiveVar(exportXls);
    useEffect(function () {
        if (exportXlsVar.export) {
            receivableXlsDoc();
            exportXls({ export: false });
        }
    }, [exportXlsVar]);
    return React.createElement("div", null);
};
export default ExportReceivableXlsComponent;
