var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import { useReactiveVar } from '@apollo/client';
import AdviserFieldComponent from '../../../../calendar/Create/Body/Form/AdviserField';
import SelectHourFieldComponent from '../../../../../presentationals/FormComponents/SelectHourField';
import WorkHourWorkingHoursFieldComponent from '../../../../employee/Edit/WorkingHours/WorkingHoursField/WorkHourField';
import getDayByNumber from '../../../../employee/Edit/WorkingHours/WorkingDaysField/helpers/getDayByNumber';
import { inputError, workingHoursForm } from '../../../../../apollo/cache';
import TagFieldBodyFormCalendarComponent from '../../../../calendar/Create/Body/Form/TagField';
var ItemWorkingCalendarFieldComponent = function (props) {
    var errors = useReactiveVar(inputError);
    var handleHourChange = function (name, value) {
        var workingHoursFormVar = workingHoursForm();
        var newArray = workingHoursFormVar.map(function (el) {
            return el.day === props.day
                ? __assign(__assign({}, el), { reminder: value }) : el;
        });
        workingHoursForm(newArray);
    };
    var handleChange = function (name, value) {
        var workingHoursFormVar = workingHoursForm();
        var newArray = workingHoursFormVar.map(function (el) {
            return el.day === props.day
                ? __assign(__assign({}, el), { tagId: value }) : el;
        });
        workingHoursForm(newArray);
    };
    var handleAutoCompleteChange = function (name, value) {
        var workingHoursFormVar = workingHoursForm();
        var newArray = workingHoursFormVar.map(function (el) {
            return el.day === props.day
                ? __assign(__assign({}, el), { adviserId: value ? value.value : undefined }) : el;
        });
        workingHoursForm(newArray);
    };
    return (React.createElement("div", { className: 'flex flex-row items-end gap-2' },
        React.createElement("div", { className: 'min-w-64 w-64' },
            React.createElement("div", { className: 'flex flex-row items-center' },
                React.createElement("div", { className: 'w-20' }, getDayByNumber(props.day, 2)),
                React.createElement("div", { className: 'bg-gray-200 rounded flex flex-row overflow-hidden' },
                    React.createElement(WorkHourWorkingHoursFieldComponent, { day: props.day, type: 'entry', hour: props.entry }),
                    React.createElement("div", { className: 'p-2 pt-3' }, "a"),
                    React.createElement(WorkHourWorkingHoursFieldComponent, { day: props.day, type: 'out', hour: props.out }))),
            errors[props.day] && React.createElement("div", { className: 'text-sm text-red-700 mb-2' }, errors[props.day])),
        React.createElement("div", null,
            React.createElement(SelectHourFieldComponent, { initialValue: props.reminder, name: 'startHour', label: 'Recordatorio', onChange: handleHourChange })),
        React.createElement("div", { className: 'min-w-72 w-72 mt-2' },
            React.createElement(AdviserFieldComponent, { onChange: handleAutoCompleteChange, value: props.adviserId || null })),
        React.createElement("div", { className: 'min-w-72 w-72' },
            React.createElement(TagFieldBodyFormCalendarComponent, { error: false, value: props.tagId || null, handleChange: handleChange, hideActions: true }))));
};
export default ItemWorkingCalendarFieldComponent;
