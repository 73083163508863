var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { useEffect, useState } from 'react';
import { useReactiveVar } from '@apollo/client';
import useApolloLazyQuery from '../../../hooks/useApolloLazyQuery';
import { filter } from '../../../apollo/cache';
import { dateTimeFormat } from '../../../helpers';
import convertToCurrencyLabel from '../../../helpers/convertToCurrencyLabel';
import GQL_CASH_FLOW from '../../../apollo/gql/cashFlow';
import getCashFlowActionLabel from './getCashFlowActionLabel';
var useGetCashFlows = function () {
    var filterVar = useReactiveVar(filter);
    var _a = useState([]), data = _a[0], setData = _a[1];
    var _b = useApolloLazyQuery(GQL_CASH_FLOW.GET_CASH_FLOWS), getData = _b[0], _c = _b[1], loading = _c.loading, response = _c.data;
    useEffect(function () {
        getData({ variables: __assign(__assign({}, filterVar), { limit: 0 }) });
    }, [filterVar]);
    useEffect(function () {
        if (response) {
            var data_1 = response.map(function (el) { return ({
                id: el.id,
                createdAt: dateTimeFormat(el.clientCreatedAt),
                total: convertToCurrencyLabel(el.total),
                action: getCashFlowActionLabel(el.action),
                description: el.description,
                employee: el.employee,
                branch: el.branch,
            }); });
            setData(data_1);
        }
    }, [response]);
    return { loading: loading, data: data };
};
export default useGetCashFlows;
