import React from 'react';
import { isECUpdateColorFormOpen } from '../../../../../../../apollo/cache';
import ColorEcommerceButtonComponent from '../../../helpers/ColorButton';
var ItemColorEcommerceProductComponent = function (props) {
    var handleClick = function () {
        isECUpdateColorFormOpen([true, props.id]);
    };
    return (React.createElement(ColorEcommerceButtonComponent, { label: props.label, onClick: handleClick, fileId: props.fileId, background: props.background }));
};
export default ItemColorEcommerceProductComponent;
