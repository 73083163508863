import { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import GQL_CLASSIFICATION from '../apollo/gql/classification';

const useGetClassifications = (variables, format) => {
  const [formattedData, setFormattedData] = useState({ count: 0, rows: [] });
  const { data, loading, error } = useQuery(GQL_CLASSIFICATION.GET_CLASSIFICATION, { variables });
  useEffect(() => {
    if (data && data.classifications) {
      const { count, rows } = data.classifications;
      let formattedRows = rows;
      switch (format) {
        case 'select':
          formattedRows = rows.map(el => ({ label: el.name, value: el.id }));
          break;
        default:
          break;
      }
      setFormattedData({ count, rows: formattedRows });
    }
  }, [data, format]);

  return { data: formattedData, loading, error };
};

export default useGetClassifications;
