import React, { useMemo } from 'react';
import ModuleV3Component from '../../presentationals/Module.v3';
import getServiceOrderReportHeaderColumns from './helpers/getServiceOrderReportHeaderColumns';
import TableSettingsSaleComponent from './TableSettings';
import useGetServiceOrder from './helpers/useGetServiceOrder';
import RedirectEditFormToRouteComponent from '../../presentationals/RedirectEditFormToRoute';
import FilterSaleDialog from '../sale.report/Filter';
import getSearchSaleByOptions from '../../helpers/getSearchSaleByOptions';
var ServiceOrderReportComponent = function () {
    var _a = useGetServiceOrder(), data = _a.data, loading = _a.loading;
    var searchByOptions = getSearchSaleByOptions();
    var header = useMemo(function () { return getServiceOrderReportHeaderColumns(); }, []);
    return (React.createElement(React.Fragment, null,
        React.createElement(ModuleV3Component, { tableSetting: true, searchFieldOptions: {
                name: 'searchSaleBy',
                options: searchByOptions,
                defaultValue: 'folio',
            }, filter: true, title: '\u00D3rdenes de servicio', hideAdd: true, data: data, header: header, isLoading: loading }),
        React.createElement(TableSettingsSaleComponent, null),
        React.createElement(FilterSaleDialog, null),
        React.createElement(RedirectEditFormToRouteComponent, null)));
};
export default ServiceOrderReportComponent;
