import React from 'react';
import { useParams } from 'react-router-dom';
import PresentationDescriptionEditProductComponent from './Description';
import NoPresentationsAlertEditProductComponent from './NoPresentationsAlert';
import PresentationTableEditProductComponent from './PresentationTable';
import OpenCreatePresentationDialogButton from './OpenCreatePresentationDialogButton';
import useApolloQuery from '../../../../hooks/useApolloQuery';
import GQL_PRODUCT from '../../../../apollo/gql/product';
import LoadingProgress from '../../../../presentationals/LoadingProgress';
import CreateProductPresentationDialogComponent from './CreatePresentation';
var StructureProductComponent = function () {
    var params = useParams();
    var _a = useApolloQuery(GQL_PRODUCT.GET_PRODUCT_BY_ID, {
        variables: { id: params.productId, includeBaseInPresentations: true },
    }), data = _a.data, loading = _a.loading;
    if (loading || !data)
        return React.createElement(LoadingProgress, null);
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: 'p-3 flex flex-1 flex-col' },
            React.createElement("div", { className: 'flex items-center' },
                React.createElement("div", { className: 'text-base font-medium mb-1 flex flex-1' }, "Presentaciones del producto"),
                React.createElement(OpenCreatePresentationDialogButton, null)),
            React.createElement(PresentationDescriptionEditProductComponent, null),
            React.createElement("div", { className: 'pt-4 flex flex-1 flex-col' }, data.ProductPresentations.length === 0 ? (React.createElement(NoPresentationsAlertEditProductComponent, { PresentationBase: data.PresentationBase })) : (React.createElement(PresentationTableEditProductComponent, { presentationBase: data.PresentationBase.name, Presentations: data.ProductPresentations })))),
        React.createElement(CreateProductPresentationDialogComponent, null)));
};
export default StructureProductComponent;
