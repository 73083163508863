var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import getFormattedApolloQueryData from '../helpers/getFormattedApolloQueryData';
var useApolloQuery = function (gql, params) {
    var _a = useState(undefined), data = _a[0], setData = _a[1];
    var queryResult = useQuery(gql, {
        variables: params === null || params === void 0 ? void 0 : params.variables,
    });
    useEffect(function () {
        if (queryResult.data) {
            var formattedServerData = getFormattedApolloQueryData(queryResult.data);
            if (formattedServerData)
                if (formattedServerData.count && formattedServerData.rows) {
                    var data_1 = {
                        count: formattedServerData.count,
                        rows: formattedServerData.rows.map(function (_a) {
                            var __typename = _a.__typename, el = __rest(_a, ["__typename"]);
                            return el;
                        }),
                    };
                    setData(data_1);
                }
                else {
                    if (typeof formattedServerData === 'object') {
                        setData(formattedServerData);
                        return;
                    }
                    var __typename = formattedServerData.__typename, response2 = __rest(formattedServerData, ["__typename"]);
                    setData(response2);
                }
        }
    }, [queryResult.data]);
    return __assign(__assign({}, queryResult), { data: data });
};
export default useApolloQuery;
