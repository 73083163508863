import React, { useState, useEffect } from 'react';

import { useLazyQuery, useMutation } from '@apollo/client';

import FormDialog from '../../presentationals/FormDialog';
import TextField from '../../presentationals/FormComponents/TextField';

import Cart from './cartExpense';
import GQL from './_gql';

const defaultValues = {
  commentary: '',
};

const FormComponent = props => {
  const [codeToClear, setCodeToClear] = useState(null);
  const [values, setValues] = useState(defaultValues);

  const [getMaintenance, { data }] = useLazyQuery(GQL.GET_BY_ID);

  const [updateMaintenanceExpensesCommentary, { loading }] = useMutation(
    GQL.UPDATE_MAINTENANCE_COMMENTARY,
    {
      update(cache, { data }) {
        cache.modify({
          id: `Maintenance:${props.id}`,
          fields: {
            commentary() {
              return data.updateMaintenanceCommentary.commentary;
            },
          },
        });
      },
    },
  );

  useEffect(() => {
    if (props.id) getMaintenance({ variables: { id: props.id } });
  }, [props.id]);

  useEffect(() => {
    if (data) setValues(values => ({ ...values, commentary: data.maintenance.commentary }));
  }, [data]);

  const handleClose = () => {
    setCodeToClear(null);
    props.handleClose();
  };

  const handleUpdateCommentary = async () => {
    try {
      await updateMaintenanceExpensesCommentary({
        variables: { id: props.id, commentary: values ? values.commentary : '' },
      });
      handleClose();
      // setValues(defaultValues);
    } catch (e) {
      console.log(e.message);
    }
  };

  const handleChange = (name, value) => {
    setValues(values => ({ ...values, [name]: value }));
  };

  return (
    <FormDialog
      isOpen={props.isOpen}
      isLoading={loading}
      title='Productos'
      disableEscape
      fullScreen
      handleClose={handleClose}
      handleAction={handleUpdateCommentary}
      actionLabel='Actualizar'
    >
      <Cart codeToClear={codeToClear} id={props.id} status={props.status} />
      <TextField
        onChange={handleChange}
        name='commentary'
        label='Comentarios'
        value={values.commentary}
        multiline
        minRows={2}
      />
    </FormDialog>
  );
};

export default FormComponent;
