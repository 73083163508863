import { filter } from '../../../apollo/cache';
var getTotalTransactions = function (data) {
    var filterVar = filter();
    return data.reduce(function (total, el) {
        return el.status === 1 || (filterVar.status.length === 1 && filterVar.status[0] === 0)
            ? total + parseFloat(el.total)
            : total;
    }, 0);
};
export default getTotalTransactions;
