import { discountTypeSaleV2 } from '../../../../apollo/cache';

const getProductFormatToCheckCart = Products => {
  const discountTypeSaleV2Var = discountTypeSaleV2();
  return Products.map(el => ({
    productId: el.productId,
    commentary: el.commentary,
    cost: parseFloat(el.cost),
    discount:
      parseInt(discountTypeSaleV2Var) === 1 ? parseFloat(el.discountRate) : parseFloat(el.discount),
    price: parseFloat(el.unitPrice),
    quantity: parseFloat(el.quantity),
    employeeId: el.employeeId,
    serialNumber: el.serialNumber,
  }));
};

export default getProductFormatToCheckCart;
