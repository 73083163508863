const getPrintOptions = format => {
  const ticket = [1, 3, 6, 7].includes(parseInt(format))
    ? [{ value: 1, label: 'Deseo generar un ticket de 80mm.' }]
    : [];
  return [
    { value: 0, label: 'No deseo generar ningún comprobante.' },
    ...ticket,
    { value: 2, label: 'Deseo generar un formato tamaño carta.' },
  ];
};

export default getPrintOptions;
