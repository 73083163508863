import React, { useEffect, useState } from 'react';

import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import ListAltIcon from '@mui/icons-material/ListAltOutlined';
import PrintIcon from '@mui/icons-material/Print';

import { actions } from '../../apollo/cache';

import CancelForm from '../payable/cancel.form';
import ProductsDialog from './products.dialog';

import Ticket from '../purchaseOrder.v2/_pdf';

const ActionComponent = () => {
  const [isOpenCancelTransaction, setIsOpenCancelTransaction] = useState([false, null]);
  const [isOpenProducts, setIsOpenProducts] = useState([false, null]);

  const handleCancel = (e, { id }) => setIsOpenCancelTransaction([true, id]);
  const handleCloseCancel = () => setIsOpenCancelTransaction([false, null]);

  const handleOpenProducts = (e, { AccountPayable }) =>
    AccountPayable &&
    AccountPayable.purchaseOrderId &&
    setIsOpenProducts([true, AccountPayable.purchaseOrderId]);
  const handleCloseProducts = () => setIsOpenProducts([false, null]);

  const handleTicket = (e, { AccountPayable }) =>
    AccountPayable && AccountPayable.purchaseOrderId && Ticket(AccountPayable.purchaseOrderId);

  useEffect(() => {
    actions([
      {
        icon: <ListAltIcon />,
        label: 'Productos',
        onClick: handleOpenProducts,
      },
      {
        icon: <PrintIcon />,
        label: 'Imprimir Documento',
        onClick: handleTicket,
      },
      {
        icon: <DeleteOutlineIcon />,
        label: 'Cancelar Abono',
        onClick: handleCancel,
      },
    ]);
    return () => {
      actions([]);
    };
  }, []);

  return (
    <>
      <CancelForm
        isOpen={isOpenCancelTransaction[0]}
        handleClose={handleCloseCancel}
        id={isOpenCancelTransaction[1]}
      />
      <ProductsDialog
        isOpen={isOpenProducts[0]}
        purchaseOrderId={isOpenProducts[1]}
        handleClose={handleCloseProducts}
      />
    </>
  );
};

export default ActionComponent;
