import React, { useEffect, useState } from 'react';
import { eachDayOfInterval } from 'date-fns';
import { TableCell, TableHead, TableRow } from '@mui/material';
import { useReactiveVar } from '@apollo/client';
import { filter } from '../../../../apollo/cache';
import { dateTimeFormat } from '../../../../helpers';
var HeaderDetailedSalesByDayIncomeReportComponent = function () {
    var filterVar = useReactiveVar(filter);
    var _a = useState([]), cells = _a[0], setCells = _a[1];
    useEffect(function () {
        if (filterVar.start && filterVar.end) {
            var interval = eachDayOfInterval({
                start: filterVar.start,
                end: filterVar.end,
            });
            setCells(interval.map(function (date) { return dateTimeFormat(date, "d'/'MMM"); }));
        }
    }, [filterVar]);
    return (React.createElement(TableHead, null,
        React.createElement(TableRow, null,
            React.createElement(TableCell, { className: 'sticky left-0 bg-slate-200 z-10' }, "Sucursal"),
            cells.map(function (date) { return (React.createElement(TableCell, { key: date, className: 'bg-transparent' }, date)); }),
            React.createElement(TableCell, { className: 'sticky right-0 bg-slate-300 z-10' }, "Total"))));
};
export default HeaderDetailedSalesByDayIncomeReportComponent;
