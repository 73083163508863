import React, { useRef } from 'react';
import EditIcon from '@mui/icons-material/Edit';
import SelectBranchInventoryReportMenuOptionsComponent from './Menu';
import SelectBranchInventoryReportLabelComponent from './Label';
var SelectBranchInventoryReportInputComponent = function (props) {
    var selectBranchInventoryReportMenuOptionsRef = useRef(null);
    var handleClick = function (event) {
        var _a;
        if (!props.disabled)
            (_a = selectBranchInventoryReportMenuOptionsRef.current) === null || _a === void 0 ? void 0 : _a.open(event.currentTarget);
    };
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { role: 'button', tabIndex: -1, onClick: handleClick, className: "flex flex-row items-center justify-center ".concat(!props.disabled ? 'hover:bg-[#ececec] hover:rounded cursor-pointer' : '', " px-2 ").concat(props.className || '') },
            React.createElement("div", { className: "flex flex-col ".concat(props.align ? (props.align === 'left' ? 'items-start' : 'items-end') : 'items-end', " justify-center") },
                React.createElement("div", { className: 'text-xs text-gray-600 leading-none tracking-tighter' }, "Sucursal"),
                React.createElement(SelectBranchInventoryReportLabelComponent, null)),
            !props.disabled && React.createElement(EditIcon, { className: 'ml-1 text-xl text-gray-500' })),
        React.createElement(SelectBranchInventoryReportMenuOptionsComponent, { ref: selectBranchInventoryReportMenuOptionsRef })));
};
export default SelectBranchInventoryReportInputComponent;
