import React from 'react';
import { useReactiveVar } from '@apollo/client';
import { Card, Table, TableCell, TableHead, TableRow } from '@mui/material';
import BodyCartSaleV2Component from './Body';
import ItemDetailDialogCartSaleV2Component from './ItemDetailDialog';
import { formatSaleV2 } from '../../../apollo/cache';
var CartSaleV2Component = function () {
    var formatSaleV2Var = useReactiveVar(formatSaleV2);
    return (React.createElement(React.Fragment, null,
        React.createElement(Card, { className: 'mt-2 sm:mt-4 md:flex flex-1 basis-0 overflow-auto' },
            React.createElement("div", { className: 'w-full' },
                React.createElement(Table, { stickyHeader: true, size: 'small', "aria-label": 'cart item table' },
                    React.createElement(TableHead, null,
                        React.createElement(TableRow, null,
                            React.createElement(TableCell, null, "SKU"),
                            React.createElement(TableCell, null, "Nombre"),
                            formatSaleV2Var[2] && React.createElement(TableCell, null, "ClaveSAT"),
                            formatSaleV2Var[2] && React.createElement(TableCell, null, "UnidadSAT"),
                            React.createElement(TableCell, { align: 'center' }, "Stock"),
                            React.createElement(TableCell, { align: 'center' }, "Cant"),
                            React.createElement(TableCell, { align: 'right', className: 'whitespace-nowrap' }, "P. Unitario"),
                            React.createElement(TableCell, { align: 'right' }, "Dscto"),
                            React.createElement(TableCell, { align: 'right' }, "Impuestos"),
                            React.createElement(TableCell, { align: 'right' }, "Importe"))),
                    React.createElement(BodyCartSaleV2Component, null)))),
        React.createElement(ItemDetailDialogCartSaleV2Component, null)));
};
export default CartSaleV2Component;
