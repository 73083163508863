var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { useEffect } from 'react';
import { useReactiveVar } from '@apollo/client';
import GQL_ACCOUNT_RECEIVABLE from '../../../apollo/gql/accountReceivable';
import useApolloLazyQuery from '../../../hooks/useApolloLazyQuery';
import { filter } from '../../../apollo/cache';
var useGetAccountReceivables = function () {
    var filterVar = useReactiveVar(filter);
    var _a = useApolloLazyQuery(GQL_ACCOUNT_RECEIVABLE.GET_CUSTOMER_ACCOUNT_RECEIVABLES), getData = _a[0], _b = _a[1], data = _b.data, loading = _b.loading;
    useEffect(function () {
        getData({ variables: __assign({}, filterVar) });
    }, [filterVar]);
    return { data: data, loading: loading };
};
export default useGetAccountReceivables;
