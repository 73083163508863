import React from 'react';
import ButtonFooterDialogComponent from '../../../../../../../presentationals/Dialog/Footer/Button';
import { inputError, isEditPickupItemOpen } from '../../../../../../../apollo/cache';
import getInputErrorFormat from '../../../../../../sale.v2/Action/helpers/getInputErrorFormat';
import setInputErrorFormat from '../../../../../../sale.v2/Action/helpers/setInputErrorFormat';
var EditPickupItemButtonComponent = function (props) {
    var handleClick = function () {
        var _a;
        try {
            inputError({});
            var saleProductId = isEditPickupItemOpen();
            if (!saleProductId)
                throw new Error('Proporciona el identificador del producto.');
            if (!props.values.qty ||
                (((_a = props.values) === null || _a === void 0 ? void 0 : _a.qty) &&
                    (parseFloat(props.values.qty) < 0 || isNaN(parseFloat(props.values.qty)))))
                throw new Error(setInputErrorFormat('qty', 'La cantidad debe ser igual o mayor a cero.'));
            isEditPickupItemOpen(false);
        }
        catch (error) {
            getInputErrorFormat(error);
        }
    };
    return React.createElement(ButtonFooterDialogComponent, { onClick: handleClick }, "Editar");
};
export default EditPickupItemButtonComponent;
