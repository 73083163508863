import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { Popover } from '@mui/material';
import TableBranchFieldComponent from './Table';
import useBranchFieldStore from '../../../../store/filterInputs/branchFieldStore';
var PopoverBranchFieldComponent = forwardRef(function (props, ref) {
    var setBranch = useBranchFieldStore(function (state) { return state.setBranch; });
    var _a = useState(null), anchorEl = _a[0], setAnchorEl = _a[1];
    var handleOpen = function (anchorEl) {
        setAnchorEl(anchorEl);
    };
    var handleClose = function () {
        setAnchorEl(null);
    };
    var handleSelect = function (id) {
        setBranch(id);
        setAnchorEl(null);
    };
    useImperativeHandle(ref, function () { return ({
        open: handleOpen,
    }); });
    return (React.createElement(Popover, { id: 'SearchBranchPopover', open: !!anchorEl, anchorEl: anchorEl, onClose: handleClose, anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'right',
        }, transformOrigin: {
            vertical: 'top',
            horizontal: 'right',
        }, slotProps: {
            paper: {
                className: 'w-full sm:w-96',
            },
        } },
        React.createElement("div", { className: 'flex flex-1 flex-col' },
            React.createElement("div", { className: 'flex flex-col p-2 pt-1 border-0 border-b border-solid border-gray-300' },
                React.createElement("div", { className: 'text-base font-medium text-sky-600' }, "Selecci\u00F3n de la sucursal")),
            React.createElement(TableBranchFieldComponent, { allowAllBranches: props.allowAllBranches, onSelect: handleSelect }))));
});
export default PopoverBranchFieldComponent;
