import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { productPromotionPriceForm } from '../../../apollo/cache';
import BodyProductPromotionPriceComponent from '../../promotion/CreateProductPromotionPrice/Body';
import FooterDialogComponent from '../../../presentationals/Dialog/Footer';
import LoadingProgress from '../../../presentationals/LoadingProgress';
import DeleteProductPriceButtonComponent from './DeleteButton';
import GQL_PRODUCT from '../../../apollo/gql/product';
import useApolloQuery from '../../../hooks/useApolloQuery';
import EditButtonProductPresentationRateComponent from './EditButton';
import getDefaultProductPromotionPriceValues from '../../promotion/CreateProductPromotionPrice/Body/helpers/getDefaultValues';
import ErrorDataComponent from '../../../presentationals/ErrorData';
var ProductPresentationRateComponent = function () {
    var params = useParams();
    var _a = useApolloQuery(GQL_PRODUCT.GET_PRODUCT_PRESENTATION_PRICE_BY_ID, {
        variables: { id: params.rateId },
    }), loading = _a.loading, data = _a.data;
    useEffect(function () {
        if (data)
            productPromotionPriceForm({
                rateId: data.Rate ? data.Rate.id : '',
                price: data.price,
                type: data.type || -1,
                branchId: data.branchId || -1,
            });
    }, [data]);
    useEffect(function () {
        return function () {
            var productPromotionPriceValues = getDefaultProductPromotionPriceValues();
            productPromotionPriceForm(productPromotionPriceValues);
        };
    }, []);
    if (loading)
        return React.createElement(LoadingProgress, null);
    if (!data)
        return React.createElement(ErrorDataComponent, null);
    return (React.createElement("div", { className: 'flex flex-1 flex-col overflow-hidden' },
        React.createElement(BodyProductPromotionPriceComponent, { showRatesNames: true }),
        React.createElement(FooterDialogComponent, null,
            React.createElement(DeleteProductPriceButtonComponent, null),
            React.createElement(EditButtonProductPresentationRateComponent, null))));
};
export default ProductPresentationRateComponent;
