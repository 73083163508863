import React, { useState, useEffect } from 'react';

import { useReactiveVar } from '@apollo/client';

import {
  customerSaleV2,
  employeeSaleV2,
  isSaleEmployeeFormOpen,
} from '../../../../../apollo/cache';

import useGetEmployees from '../../../../../hooks/useGetEmployees';

import Button from '../../helpers/Button';

const EmployeeActionSaleV2Component = () => {
  const employeeSaleV2Var = useReactiveVar(employeeSaleV2);
  const customerSaleV2Var = useReactiveVar(customerSaleV2);

  const [currentEmployee, setCurrentEmployee] = useState('');

  const { data } = useGetEmployees(null, 'select');

  useEffect(() => {
    if (customerSaleV2Var?.employeeId) employeeSaleV2(customerSaleV2Var.employeeId.value);
  }, [customerSaleV2Var]);

  useEffect(() => {
    if (data.count > 0) {
      const currentEmployee = data.rows.find(el => el.value === employeeSaleV2Var);
      if (currentEmployee) setCurrentEmployee(currentEmployee.label);
    }
  }, [data, employeeSaleV2Var]);

  const handleClick = () => {
    isSaleEmployeeFormOpen(true);
  };
  return <Button title='Vendedor' subtitle={currentEmployee} onClick={handleClick} />;
};

export default EmployeeActionSaleV2Component;
