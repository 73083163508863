import { TableCell, TableRow } from '@mui/material';
import React from 'react';
import DeleteProductListButtonComponent from './DeleteButton';
import GQL_PRODUCT from '../../../../../../apollo/gql/product';
import useApolloQuery from '../../../../../../hooks/useApolloQuery';
var ItemTableProductListComponent = function (props) {
    var data = useApolloQuery(GQL_PRODUCT.GET_PRODUCT_BY_PRODUCT_PRESENTATION_ID, {
        variables: { productPresentationId: props.productPresentationId },
    }).data;
    return (React.createElement(TableRow, null,
        React.createElement(TableCell, { align: 'left' },
            React.createElement(DeleteProductListButtonComponent, { productPresentationId: data ? data.ProductPresentation.id : null, arrayName: props.arrayName })),
        React.createElement(TableCell, null, data ? data.name : ''),
        React.createElement(TableCell, null, data ? data.ProductPresentation.name : ''),
        React.createElement(TableCell, { align: 'center' }, props.qty)));
};
export default ItemTableProductListComponent;
