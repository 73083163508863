var CONSTANTS_ERROR = {
    PRODUCT_NOT_FOUND: 'No se encontraron coincidencias.',
    REQUIRED_INPUT: 'Este campo es obligatorio.',
    SERVER_ERROR: 'Ocurrió un error al realizar la petición, intente más tarde.',
    STORE_AT_LEAST_ONE_PRODUCT: 'Almacena al menos un producto para continuar.',
    UNDEFINED_BRANCH_ID: 'El identificador de la sucursal proporcionado es indefinido.',
    UNDEFINED_CUSTOMER_ID: 'El identificador del cliente proporcionado es indefinido.',
    UNDEFINED_DATE: 'Proporciona una fecha para continuar.',
    UNDEFINED_INVOICE_CONCEPT: 'Proporciona el concepto de la factura para continuar.',
    UNDEFINED_INVOICE_TOTAL: 'Proporciona el importe de la factura para continuar.',
    UNDEFINED_PRODUCT_ID: 'El identificador del producto proporcionado es indefinido.',
    UNDEFINED_PURCHASE_ORDER_PROVIDER: 'Selecciona el proveedor que abastecerá tu compra para continuar.',
    UNDEFINED_SAT_UNIT_KEY: 'Proporciona la unidad de medida para continuar.',
    UNDEFINED_SAT_PRODUCT_KEY: 'Proporciona la clave de producto para continuar',
    UNDEFINED_SESSION: 'No encontramos una sesión iniciada.',
    UNDEFINED_VALUES: 'El retorno de los valores es indefinido, reporta este problema a soporte técnico.',
};
export default CONSTANTS_ERROR;
