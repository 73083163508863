import React from 'react';

import Grid from '@mui/material/Grid';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Tooltip from '@mui/material/Tooltip';
import FormHelperText from '@mui/material/FormHelperText';

const RadioComponent = ({ noFormat, icon, error, ...props }) => {
  const handleChange = e => {
    props.handleChange(e);
  };
  return (
    <Grid container spacing={0} alignItems='center' style={{ marginTop: 2 }}>
      <Grid item xs={noFormat ? false : 1}>
        {icon || ''}
      </Grid>
      <Grid item xs={noFormat ? 12 : 10}>
        <Grid container spacing={0} alignItems='center'>
          <Grid item>
            <FormControl error={!!error} component='fieldset'>
              {props.label && <FormLabel component='label'>{props.label}</FormLabel>}
              <RadioGroup
                row
                aria-label={props.name}
                name={props.name}
                onChange={handleChange}
                defaultValue='top'
              >
                {props.options &&
                  props.options.map(el => (
                    <FormControlLabel
                      checked={el.value === props.value}
                      key={el.value}
                      value={el.value}
                      control={
                        <Tooltip title={el.tooltip}>
                          <Radio style={{ color: el.color || '#1976d2' }} />
                        </Tooltip>
                      }
                      label={el.label || ''}
                    />
                  ))}
              </RadioGroup>
              <FormHelperText>{error && error}</FormHelperText>
            </FormControl>
          </Grid>
          <Grid item>{props.extra}</Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default RadioComponent;
