import React from 'react';

import { useReactiveVar } from '@apollo/client';

import { ListItemButton, ListItemText } from '@mui/material';

import { currencySaleV2, isSaleCurrencyFormOpen } from '../../../../../../apollo/cache';

const ItemFormFormatActionSalV2Component = props => {
  const currencySaleV2Var = useReactiveVar(currencySaleV2);
  const handleClick = () => {
    currencySaleV2([props.id, props.id === 'MXN' ? 1 : currencySaleV2Var[1]]);
    props.id === 'MXN' && isSaleCurrencyFormOpen(false);
  };

  return (
    <ListItemButton
      onClick={handleClick}
      className={currencySaleV2Var[0] === props.id ? 'bg-gray-200' : undefined}
    >
      <ListItemText primary={props.label} />
    </ListItemButton>
  );
};

export default ItemFormFormatActionSalV2Component;
