import React from 'react';
import EmailIcon from '@mui/icons-material/Email';
import { IconButton, Tooltip } from '@mui/material';
import getInputErrorFormat from '../../../sale.v2/Action/helpers/getInputErrorFormat';
import { isSendSaleByEmailOpen } from '../../../../apollo/cache';
var SendEmailSaleButtonComponent = function (props) {
    var handleClick = function () {
        try {
            if (!props.saleId)
                throw new Error('Proporciona el identificador de la venta.');
            isSendSaleByEmailOpen(props.saleId);
        }
        catch (error) {
            getInputErrorFormat(error);
        }
    };
    return (React.createElement(Tooltip, { title: 'Enviar documentos por correo electr\u00F3nico' },
        React.createElement("div", null,
            React.createElement(IconButton, { onClick: handleClick },
                React.createElement(EmailIcon, null)))));
};
export default SendEmailSaleButtonComponent;
