import React, { useEffect, useState } from 'react';
import { InputBase } from '@mui/material';
import { useReactiveVar } from '@apollo/client';
import { searchV3 } from '../../../../../apollo/cache';
var InputSearchInputHeaderModuleV3Component = function (props) {
    var value = useReactiveVar(searchV3);
    var _a = useState(''), title = _a[0], setTitle = _a[1];
    useEffect(function () {
        var title = props.label ? "Buscar ".concat(props.label.toLowerCase()) : '';
        setTitle(title);
    }, [props.label]);
    var handleChange = function (event) {
        var value = event.target.value;
        searchV3(value);
    };
    var handleKeyDown = function (e) {
        if (e.key === 'Enter')
            props.onPressEnter();
    };
    return (React.createElement(InputBase, { placeholder: title, disabled: props.isLoading, value: value, className: 'flex flex-1 ml-1', inputProps: { 'aria-label': title || 'search-field' }, onChange: handleChange, onKeyDown: handleKeyDown }));
};
export default InputSearchInputHeaderModuleV3Component;
