import React from 'react';
import MoveUpIcon from '@mui/icons-material/MoveUp';
import IconButtonComponent from '../../../../../presentationals/IconButton';
import useTransferProductStore from '../../../../../store/inventoryReport/addProductToTransferStore';
import useApolloQuery from '../../../../../hooks/useApolloQuery';
import GQL_BRANCH from '../../../../../apollo/gql/branch';
var TransferProductInventoryButtonComponent = function (props) {
    var _a = useApolloQuery(GQL_BRANCH.GET_BRANCHES), data = _a.data, loading = _a.loading;
    var setOpen = useTransferProductStore(function (state) { return state.setOpen; });
    var handleClick = function () {
        setOpen(true, props.presentationId, props.currentQty);
    };
    if (loading || (data && data.count === 1))
        return React.createElement("div", null);
    return (React.createElement(IconButtonComponent, { size: 'small', icon: React.createElement(MoveUpIcon, null), action: handleClick, label: 'Transferir a otra sucursal' }));
};
export default TransferProductInventoryButtonComponent;
