import React, { useEffect, useState } from 'react';

import { useReactiveVar } from '@apollo/client';
import { Button } from '@mui/material';

import { searchProductKeyField } from '../../../../../../../../../../apollo/cache';

import TextField from '../../../../../../../../../../presentationals/FormComponents/TextField';
import useSnackbarDialog from '../../../../../../../../../../store/snackbar/snackbarDialog';

const FormSearchDialogProductKeyFieldHelpersBodyCreateProductDialogProductFormChooseProductSaleV2Component =
  () => {
    const { setSnackbar } = useSnackbarDialog();

    const searchProductKeyFieldVar = useReactiveVar(searchProductKeyField);
    const [value, setValue] = useState('');

    useEffect(() => {
      setValue(searchProductKeyFieldVar);
      return () => {
        searchProductKeyField('');
      };
    }, []);

    const handleChange = (name, value) => {
      setValue(value);
    };

    const handleClick = () => {
      try {
        searchProductKeyField(value);
      } catch (e) {
        setSnackbar({ isOpen: true, label: e.message, severity: 'error' });
      }
    };
    const handleKeyDown = e => {
      if (e.keyCode === 13) {
        e.preventDefault();
        handleClick();
      }
    };
    return (
      <div className='flex items-center px-5 pt-3 pb-1'>
        <div className='flex flex-1 pr-2'>
          <TextField
            autoFocus
            name='search'
            defaultLabel='Búsqueda por nombre'
            value={value}
            onChange={handleChange}
            onKeyDown={handleKeyDown}
          />
        </div>
        <div className='mb-1'>
          <Button color='secondary' variant='contained' onClick={handleClick}>
            Buscar
          </Button>
        </div>
      </div>
    );
  };

export default FormSearchDialogProductKeyFieldHelpersBodyCreateProductDialogProductFormChooseProductSaleV2Component;
