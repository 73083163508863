import React, { useEffect } from 'react';
import { useLazyQuery } from '@apollo/client';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
// import Typography from '@mui/material/Typography';

import { currencyFormat } from '../../helpers/general';
import FormDialog from '../../presentationals/FormDialog';
import GQL from './_gql';

// const defaultTotals = {
//   subtotal: 0,
//   discount: 0,
//   taxes: 0,
//   total: 0,
// };

const Component = ({ saleId, ...props }) => {
  // const [totals, setTotals] = useState(defaultTotals);

  const [getProducts, { data }] = useLazyQuery(GQL.GET_BY_ID, {
    fetchPolicy: 'no-cache',
  });

  useEffect(() => {
    if (saleId !== null) getProducts({ variables: { id: saleId } });
  }, [saleId]); // eslint-disable-line

  return (
    <FormDialog
      title='Productos'
      isOpen={props.isOpen || false}
      handleClose={() => props.handleClose()}
      hideAction
      width='md'
    >
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Producto</TableCell>
            <TableCell>Presentación</TableCell>
            <TableCell align='center'>Cant.</TableCell>
            <TableCell align='right'>Total</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data?.ecSale.map(el => (
            <>
              <TableRow hover key={el.id}>
                <TableCell>{el.Product ? el.Product.name : el.name ? el.name : ''}</TableCell>
                <TableCell>
                  {el.ProductPresentation ? el.ProductPresentation.name : 'PIEZA'}
                </TableCell>
                <TableCell align='center'>{el.quantity}</TableCell>
                <TableCell align='right'>{currencyFormat(el.total)}</TableCell>
              </TableRow>
              {el.SaleProductIngredients &&
                el.SaleProductIngredients.map(elCh => (
                  <>
                    <TableRow hover key={`${elCh.id}A`}>
                      <TableCell>{`* ${elCh.ProductIngredient.Ingredient.name}`}</TableCell>
                      <TableCell />
                      <TableCell />
                      <TableCell align='right'>{currencyFormat(elCh.price)}</TableCell>
                    </TableRow>
                    {elCh.SaleProductAttributes &&
                      elCh.SaleProductAttributes.map(tmp => (
                        <TableRow hover key={`${tmp.id}B`}>
                          <TableCell>
                            {`- ${
                              tmp.ProductAttribute?.name
                                ? tmp.ProductAttribute.name
                                : tmp.ProductAttribute.attribute
                                ? tmp.ProductAttribute.attribute
                                : tmp.attribute
                            }`}
                          </TableCell>
                          <TableCell />
                          <TableCell />
                          <TableCell align='right'>{currencyFormat(tmp.price)}</TableCell>
                        </TableRow>
                      ))}
                  </>
                ))}
            </>
          ))}
        </TableBody>
      </Table>
    </FormDialog>
  );
};

export default Component;
