import CREATE_SURVEY from './createSurvey';
import CREATE_SURVEY_CUSTOMER from './createSurveyCustomer';
import CREATE_SURVEY_QUESTION from './createSurveyQuestion';
import DELETE_SURVEY_QUESTION from './deleteSurverQuestion';
import FRAGMENT_SURVEY from './fragmentSurvey';
import FRAGMENT_SURVEY_CUSTOMER from './fragmentSurveyCustomer';
import FRAGMENT_SURVEY_QUESTION from './fragmentSurveyQuestion';
import GET_SURVEY_BY_ID from './getSurveyById';
import GET_SURVEY_CUSTOMER_BY_ID from './getSurveyCustomerById';
import GET_SURVEY_CUSTOMERS from './getSurveyCustomers';
import GET_SURVEYS from './getSurveys';
import UPDATE_SURVEY_CUSTOMER from './updateSurveyCustomer';
var GQL_SURVEY = {
    CREATE_SURVEY: CREATE_SURVEY,
    CREATE_SURVEY_CUSTOMER: CREATE_SURVEY_CUSTOMER,
    CREATE_SURVEY_QUESTION: CREATE_SURVEY_QUESTION,
    DELETE_SURVEY_QUESTION: DELETE_SURVEY_QUESTION,
    FRAGMENT_SURVEY: FRAGMENT_SURVEY,
    FRAGMENT_SURVEY_CUSTOMER: FRAGMENT_SURVEY_CUSTOMER,
    FRAGMENT_SURVEY_QUESTION: FRAGMENT_SURVEY_QUESTION,
    GET_SURVEY_BY_ID: GET_SURVEY_BY_ID,
    GET_SURVEY_CUSTOMER_BY_ID: GET_SURVEY_CUSTOMER_BY_ID,
    GET_SURVEY_CUSTOMERS: GET_SURVEY_CUSTOMERS,
    GET_SURVEYS: GET_SURVEYS,
    UPDATE_SURVEY_CUSTOMER: UPDATE_SURVEY_CUSTOMER,
};
export default GQL_SURVEY;
