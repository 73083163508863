import React from 'react';
import { IconButton } from '@mui/material';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
var DeleteItemOnlineOrderCartButtonComponent = function (props) {
    var handleDelete = function () {
        props.onClick(props.index);
    };
    return (React.createElement(IconButton, { className: 'p-0', onClick: handleDelete },
        React.createElement(DeleteOutlineIcon, null)));
};
export default DeleteItemOnlineOrderCartButtonComponent;
