import { addDays, addHours, eachHourOfInterval, format, startOfDay } from 'date-fns';
import getIndexByHour from './getIndexByHour';
import getDefaultHoursChartData from './getDefaultHours';
var getHoursByWorkingHours = function (start, end) {
    var startDate = addHours(startOfDay(new Date()), start);
    var endDate = end >= 0 && end <= 4
        ? addDays(addHours(startOfDay(new Date()), end), 1)
        : addHours(startOfDay(new Date()), end);
    var interval = eachHourOfInterval({ start: startDate, end: endDate });
    var indexToShow = interval.map(function (el) { return getIndexByHour(parseInt(format(el, 'H'))); });
    return getDefaultHoursChartData().filter(function (el, i) { return indexToShow.includes(i); });
};
export default getHoursByWorkingHours;
