var getDefaultCustomerValues = function (values) { return ({
    id: values && values.id ? values.id : null,
    firstName: values && values.firstName ? values.firstName : '',
    lastName: values && values.lastName ? values.lastName : '',
    phone: values && values.phone ? values.phone : '',
    addressLine1: values && values.addressLine1 ? values.addressLine1 : '',
    addressLine2: values && values.addressLine2 ? values.addressLine2 : '',
    city: values && values.city ? values.city : '',
    state: values && values.state ? values.state : '',
    zipCode: values && values.zipCode ? values.zipCode : '',
    reference: values && values.reference ? values.reference : '',
    taxRegime: values && values.CustomerBillingInformation && values.CustomerBillingInformation.taxRegime
        ? values.CustomerBillingInformation.taxRegime
        : '601',
    isrRetTax: values && values.CustomerBillingInformation && values.CustomerBillingInformation.isrRetTax
        ? values.CustomerBillingInformation.isrRetTax
        : '',
    email: values && values.CustomerBillingInformation && values.CustomerBillingInformation.email
        ? values.CustomerBillingInformation.email
        : '',
    priceName: values && values.rateId ? values.rateId : -1,
    creditLimit: values && values.creditLimit ? values.creditLimit : 0,
    creditDays: values && values.creditDays ? values.creditDays : 0,
    employeeId: values && values.employeeId ? values.employeeId : null,
    identifier: values && values.CustomerBillingInformation && values.CustomerBillingInformation.identifier
        ? values.CustomerBillingInformation.identifier
        : '',
    tradename: values && values.CustomerBillingInformation && values.CustomerBillingInformation.tradename
        ? values.CustomerBillingInformation.tradename
        : '',
    businessName: values && values.CustomerBillingInformation && values.CustomerBillingInformation.businessName
        ? values.CustomerBillingInformation.businessName
        : '',
    altEmail: values && values.CustomerBillingInformation && values.CustomerBillingInformation.altEmail
        ? values.CustomerBillingInformation.altEmail
        : '',
    otherEmail: values && values.CustomerBillingInformation && values.CustomerBillingInformation.otherEmail
        ? values.CustomerBillingInformation.otherEmail
        : '',
    businessAddressLine1: values && values.CustomerBillingInformation && values.CustomerBillingInformation.addressLine1
        ? values.CustomerBillingInformation.addressLine1
        : '',
    businessAddressLine2: values && values.CustomerBillingInformation && values.CustomerBillingInformation.addressLine2
        ? values.CustomerBillingInformation.addressLine2
        : '',
    businessZipCode: values && values.CustomerBillingInformation && values.CustomerBillingInformation.zipCode
        ? values.CustomerBillingInformation.zipCode
        : '',
    businessCity: values && values.CustomerBillingInformation && values.CustomerBillingInformation.city
        ? values.CustomerBillingInformation.city
        : '',
    businessMunicipality: values && values.CustomerBillingInformation && values.CustomerBillingInformation.municipality
        ? values.CustomerBillingInformation.municipality
        : '',
    businessState: values && values.CustomerBillingInformation && values.CustomerBillingInformation.state
        ? values.CustomerBillingInformation.state
        : '',
    businessCountry: values && values.CustomerBillingInformation && values.CustomerBillingInformation.country
        ? values.CustomerBillingInformation.country
        : '',
    Crms: (values === null || values === void 0 ? void 0 : values.Crms) ? values.Crms : [],
    schoolName: (values === null || values === void 0 ? void 0 : values.schoolName) ? values.schoolName : '',
    schoolGrade: (values === null || values === void 0 ? void 0 : values.schoolGrade) ? values.schoolGrade : '',
    schoolBlock: (values === null || values === void 0 ? void 0 : values.schoolBlock) ? values.schoolBlock : '',
    admissionDate: (values === null || values === void 0 ? void 0 : values.admissionDate) ? values.admissionDate : null,
    birthday: (values === null || values === void 0 ? void 0 : values.birthday) ? values.birthday : null,
    mainBranch: (values === null || values === void 0 ? void 0 : values.mainBranch) ? values.mainBranch : null,
}); };
export default getDefaultCustomerValues;
