import React from 'react';
import { useReactiveVar } from '@apollo/client';
import { Dialog } from '@mui/material';
import { isSatProductKeyDialogOpen } from '../../../../../../../../../apollo/cache';
import Header from './Header';
import Form from './Form';
import Table from './Table';
var SearchDialogProductKeyFieldProductComponent = function () {
    var isSatProductKeyDialogOpenVar = useReactiveVar(isSatProductKeyDialogOpen);
    return (React.createElement(Dialog, { fullScreen: true, open: isSatProductKeyDialogOpenVar },
        React.createElement(Header, null),
        React.createElement(Form, null),
        React.createElement(Table, null)));
};
export default SearchDialogProductKeyFieldProductComponent;
