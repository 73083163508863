var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
var getSortedClassificationInventories = function (Classifications) {
    var storedSortedClassifications = localStorage.getItem('sortedClassificationInventories');
    var parsedSortedClassifications = storedSortedClassifications
        ? JSON.parse(storedSortedClassifications)
        : [];
    var SortedClassifications = parsedSortedClassifications
        .filter(function (id) { return Classifications.find(function (el) { return el.id === id; }); })
        .map(function (id) { return Classifications.find(function (el) { return el.id === id; }); });
    return Classifications.reduce(function (array, el) {
        var inArray = SortedClassifications.find(function (elCh) { return elCh.id === el.id; });
        return inArray ? array : __spreadArray(__spreadArray([], array, true), [el], false);
    }, SortedClassifications);
};
export default getSortedClassificationInventories;
