var countryOptions = [
    { label: 'México', value: 1 },
    { label: 'Argentina', value: 2 },
    { label: 'Bolivia', value: 3 },
    { label: 'Brasil', value: 4 },
    { label: 'Chile', value: 5 },
    { label: 'Colombia', value: 6 },
    { label: 'Costa Rica', value: 7 },
    { label: 'Cuba', value: 8 },
    { label: 'Ecuador', value: 9 },
    { label: 'El Salvador', value: 10 },
    { label: 'España', value: 11 },
    { label: 'Estados Unidos', value: 12 },
    { label: 'Guayana Francesa', value: 13 },
    { label: 'Granada', value: 14 },
    { label: 'Guatemala', value: 15 },
    { label: 'Guayana', value: 16 },
    { label: 'Haití', value: 17 },
    { label: 'Honduras', value: 18 },
    { label: 'Jamaica', value: 19 },
    { label: 'Nicaragua', value: 20 },
    { label: 'Paraguay', value: 21 },
    { label: 'Panamá', value: 22 },
    { label: 'Perú', value: 23 },
    { label: 'Puerto Rico', value: 24 },
    { label: 'República Dominicana', value: 25 },
    { label: 'Surinam', value: 26 },
    { label: 'Uruguay', value: 27 },
    { label: 'Venezuela', value: 28 },
];
export default countryOptions;
