import React from 'react';
import ReceiptLongOutlinedIcon from '@mui/icons-material/ReceiptLongOutlined';
import { IconButton, Tooltip } from '@mui/material';
import getInputErrorFormat from '../../../sale.v2/Action/helpers/getInputErrorFormat';
import { isInvoiceComplementOpen } from '../../../../apollo/cache';
var InvoiceComplementButtonComponent = function (props) {
    var handleClick = function () {
        try {
            if (!props.customerId)
                throw new Error('Proporciona el identificador del cliente.');
            isInvoiceComplementOpen(props.customerId);
        }
        catch (error) {
            getInputErrorFormat(error);
        }
    };
    return (React.createElement(Tooltip, { title: 'Generar complemento de pago' },
        React.createElement(IconButton, { onClick: handleClick },
            React.createElement(ReceiptLongOutlinedIcon, null))));
};
export default InvoiceComplementButtonComponent;
