import React, { useState } from 'react';
import { TableCell, TableRow } from '@mui/material';
import TimelineIcon from '@mui/icons-material/Timeline';
import { useNavigate } from 'react-router-dom';
import IconButtonComponent from '../../../../presentationals/IconButton';
import convertToCurrencyLabel from '../../../../helpers/convertToCurrencyLabel';
import getInputErrorFormat from '../../../sale.v2/Action/helpers/getInputErrorFormat';
var ItemMultiCompanyReportComponent = function (props) {
    var navigate = useNavigate();
    var _a = useState(false), selected = _a[0], setSelected = _a[1];
    var handleMouseOver = function () {
        setSelected(true);
    };
    var handleMouseOut = function () {
        setSelected(false);
    };
    var onClick = function () {
        try {
            navigate("".concat(props.id, "/detail"));
        }
        catch (error) {
            getInputErrorFormat(error);
        }
    };
    return (React.createElement(TableRow, { hover: true, onMouseEnter: handleMouseOver, onMouseLeave: handleMouseOut, className: "".concat(props.index % 2 ? 'bg-gray-200' : '', " hover:!bg-gray-200 relative") },
        React.createElement(TableCell, { className: 'relative' },
            selected && (React.createElement("div", { className: 'absolute pr-2 bg-gray-200 h-full top-0 items-center' },
                React.createElement(IconButtonComponent, { size: 'small', icon: React.createElement(TimelineIcon, null), action: onClick }))),
            props.Company.name),
        React.createElement(TableCell, null, props.Branch.name),
        React.createElement(TableCell, null, "(".concat(props.Income.qty, ") $").concat(convertToCurrencyLabel(props.Income.total))),
        React.createElement(TableCell, null, "(".concat(props.Sales.qty, ") $").concat(convertToCurrencyLabel(props.Sales.total))),
        React.createElement(TableCell, null, "(".concat(props.SalesCancelled.qty, ") $").concat(convertToCurrencyLabel(props.SalesCancelled.total))),
        React.createElement(TableCell, null, "(".concat(props.SalesDiscount.qty, ") $").concat(convertToCurrencyLabel(props.SalesDiscount.total))),
        React.createElement(TableCell, null, "(".concat(props.CashFlowBalance.qty, ") $").concat(convertToCurrencyLabel(props.CashFlowBalance.total)))));
};
export default ItemMultiCompanyReportComponent;
