import { create } from 'zustand';
import { persist } from 'zustand/middleware';
var useSelectBranchesDialogStore = create()(persist(function (set) { return ({
    open: false,
    branches: [],
    setOpen: function (open) { return set(function () { return ({ open: open }); }); },
    setBranches: function (branches) { return set({ branches: branches }); },
}); }, {
    name: 'selectedBranchesToMultiCompanyReport',
    partialize: function (state) { return ({ branches: state.branches }); },
}));
export default useSelectBranchesDialogStore;
