import React from 'react';

import { useReactiveVar } from '@apollo/client';

import { TableCell, Typography, TableBody } from '@mui/material';

import { cartItems, formatSaleV2 } from '../../../../apollo/cache';
import { currencyFormat } from '../../../../helpers/general';
import RowBodyCartSaleV2Component from './Row';
import EmployeeLabelBodyCartSaleComponent from './EmployeeLabel';

const BodyCartSaleV2Component = () => {
  const cartItemsVar = useReactiveVar(cartItems);
  const formatSaleV2Var = useReactiveVar(formatSaleV2);

  return (
    <TableBody>
      {cartItemsVar.map((el, i) => (
        <RowBodyCartSaleV2Component
          key={i}
          index={i}
          background={i % 2 > 0 ? 'bg-inherit' : 'bg-gray-100'}
        >
          <TableCell>
            <Typography>{el.sku}</Typography>
          </TableCell>
          <TableCell>
            <Typography>{el.name}</Typography>
            {el.serialNumber && (
              <Typography variant='caption'>{`Número de serie: ${el.serialNumber}`}</Typography>
            )}
            {el.commentary.length > 0 && <Typography variant='caption'>{el.commentary}</Typography>}
            {el.employeeId && <EmployeeLabelBodyCartSaleComponent employeeId={el.employeeId} />}
          </TableCell>
          {formatSaleV2Var[2] && <TableCell>{el.productKey || '-'}</TableCell>}
          {formatSaleV2Var[2] && <TableCell>{el.unitKey || '-'}</TableCell>}
          <TableCell align='center'>{el.stock}</TableCell>
          <TableCell align='center'>{el.quantity}</TableCell>
          <TableCell align='right'>${currencyFormat(el.unitPriceToShow)}</TableCell>
          <TableCell align='right' className='whitespace-nowrap'>
            ${currencyFormat(el.discount)} ({el.discountRate}%)
          </TableCell>
          <TableCell align='right'>${currencyFormat(el.taxes)}</TableCell>
          <TableCell align='right'>${currencyFormat(el.subtotal)}</TableCell>
        </RowBodyCartSaleV2Component>
      ))}
    </TableBody>
  );
};

export default BodyCartSaleV2Component;
