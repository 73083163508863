import React from 'react';
import convertToCurrencyLabel from '../../../../../../helpers/convertToCurrencyLabel';
var ProductPriceLabelItemCartCreatePurchaseOrderComponent = function (props) {
    if (!props.updateSalePrice)
        return React.createElement("div", null);
    return (React.createElement("div", { className: 'text-xs text-gray-500' },
        "Nuevo precio de venta: (+",
        props.percentAdjust,
        "%) ",
        convertToCurrencyLabel(props.salePrice)));
};
export default ProductPriceLabelItemCartCreatePurchaseOrderComponent;
