import React, { useState } from 'react';

import FormDialog from '../../presentationals/FormDialog';

import Cart from './cartExpense';

const FormComponent = props => {
  const [codeToClear, setCodeToClear] = useState(null);

  const handleClose = () => {
    setCodeToClear(null);
    props.handleClose();
  };

  return (
    <FormDialog
      isOpen={props.isOpen}
      title='Productos'
      disableEscape
      fullScreen
      handleClose={handleClose}
      hideAction
    >
      <Cart codeToClear={codeToClear} id={props.id} folio={props.folio} />
    </FormDialog>
  );
};

export default FormComponent;
