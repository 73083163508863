var getProductIngredientTypeLabel = function (value) {
    var defaultTypeArray = [
        'VARIACIÓN',
        'BASE',
        'EXTRA',
        'OPCIONAL',
        '-',
        'VERIFICACIÓN POR PAQUETE',
    ];
    return defaultTypeArray[value];
};
export default getProductIngredientTypeLabel;
