import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useReactiveVar } from '@apollo/client';
import { isCreateFormOpen } from '../../apollo/cache';
var RedirectCreateFormToRouteComponent = function () {
    var navigate = useNavigate();
    var isCreateFormOpenVar = useReactiveVar(isCreateFormOpen);
    useEffect(function () {
        if (isCreateFormOpenVar)
            navigate('create');
        return function () {
            isCreateFormOpen(false);
        };
    }, [isCreateFormOpenVar]);
    return React.createElement("div", null);
};
export default RedirectCreateFormToRouteComponent;
