import React, { useState, useEffect } from 'react';

import { useQuery, useLazyQuery, useMutation, useReactiveVar } from '@apollo/client';

import Grid from '@mui/material/Grid';
import Switch from '@mui/material/Switch';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';

import FormDialog from '../../presentationals/FormDialog';
import TextField from '../../presentationals/TextField';
import Snackbar from '../../presentationals/Snackbar';

import { currencyFormat, parseError } from '../../helpers';

import GQL_PAYMENT_METHOD from '../paymentMethod/_gql';
import GQL_SALE from '../sale.report.v2/_gql';
import GQL from './_gql';
import ProductInvoiceDialog from './productInvoice.dialog';

import { invoiceFormState, billingInfo } from '../../apollo/cache';

import InvoiceForm from '../cashSale/Header/InvoiceForm';
import InvoiceDialog from '../cashSale/Header/invoice.dialog';
import useSnackbarDialog from '../../store/snackbar/snackbarDialog';

const defaultValues = {
  cash: '',
};
const defaultSnackbar = { isOpen: true, time: 3000 };

const Customer = props => {
  const { setSnackbar } = useSnackbarDialog();

  const invoiceFormStateVar = useReactiveVar(invoiceFormState);

  const [paymentMethod, setPaymentMethod] = useState([]);
  const [createInvoice, setCreateInvoice] = useState(false);
  const [customerId, setCustomerId] = useState(null);
  const [values, setValues] = useState(defaultValues);
  const [amount, setAmount] = useState(0);
  const [totalPaid, setTotalPaid] = useState(0);
  const [change, setChange] = useState(0);
  const [changeAction, setChangeAction] = useState('Resta');
  const [isInvoiceFormOpen, setIsInvoiceFormOpen] = useState([false, {}]);
  const [isOpenProductInvoice, setIsOpenProductInvoice] = useState(false);

  useQuery(GQL_PAYMENT_METHOD.GET, {
    onCompleted: data => {
      const tmp = data.paymentMethods.rows.map(el => ({
        label: el.name,
        value: el.id,
      }));
      setPaymentMethod(tmp);
    },
  });

  const [getServiceOrder, { data: dataServiceOrder }] = useLazyQuery(GQL_SALE.GET_BY_ID_TOTAL);

  const [closeServiceOrderInvoice, { loading: loadingInvoice }] = useMutation(
    GQL.CLOSE_SERVICE_ORDER_INVOICE,
  );

  const [closeServiceOrder, { loading }] = useMutation(GQL.CLOSE_SERVICE_ORDER);

  useEffect(() => {
    if (props.id) getServiceOrder({ variables: { id: props.id, includeModels: false } });
  }, [props.id]);

  useEffect(() => {
    if (dataServiceOrder) {
      setAmount(dataServiceOrder.sale.total);
      setCustomerId(dataServiceOrder.sale.customerId);
    }
  }, [dataServiceOrder]);

  useEffect(() => {
    const tmpTotalPaid = Object.keys(values).reduce((total, key) => {
      const tmp = !Number.isNaN(parseFloat(values[key])) ? parseFloat(values[key]) : 0;
      return total + tmp;
    }, 0);

    const tmpChange = amount - tmpTotalPaid;
    const tmpChangeAction = tmpChange >= 0 ? 'Resta' : 'Sobra';

    setChangeAction(tmpChangeAction);
    setTotalPaid(tmpTotalPaid);
    setChange(Math.abs(tmpChange));
  }, [values, amount]);

  const handleClose = () => {
    props.handleClose('finishSale');
  };

  const resetValues = () => {
    setAmount(0);
    setTotalPaid(0);
    setChange(0);
    invoiceFormState(0);
    setChangeAction('Resta');
    setCreateInvoice(false);
    setValues(defaultValues);
  };

  const handleChangeSwitch = event => {
    setCreateInvoice(event.target.checked);
  };

  const handleInvoiceClose = () => {
    setIsInvoiceFormOpen([false, {}]);
  };

  const handleActionWithInvoice = () => {
    invoiceFormState(1);
  };

  const handleAction = async (e, withInvoice, Products) => {
    try {
      const Transactions = Object.keys(values).map(key => ({
        paymentMethodId: !Number.isNaN(parseInt(key)) ? parseInt(key) : null,
        total: !Number.isNaN(parseFloat(values[key])) ? parseFloat(values[key]) : 0,
      }));

      if (withInvoice) {
        const invoicePreData = billingInfo();
        const invoiceData = {
          ...invoicePreData,
          applyExtraTaxes:
            invoicePreData.applyExtraTaxes === '' ? null : invoicePreData.applyExtraTaxes,
          paymentMethodId:
            parseInt(invoicePreData.paymentMethodId) === 0 ? null : invoicePreData.paymentMethodId,
        };

        const responseInvoice = await closeServiceOrderInvoice({
          variables: {
            ...invoiceData,
            Transactions,
            id: props.id,
            Products: Products
              ? Products.map(el => ({ id: el.id, productKey: el.productKey, unitKey: el.unitKey }))
              : null,
          },
        });
        setIsOpenProductInvoice(false);
        setIsInvoiceFormOpen([true, { ...responseInvoice.data.closeServiceOrderInvoice.Invoice }]);
      } else {
        await closeServiceOrder({ variables: { Transactions, id: props.id } });
      }

      handleClose();
      resetValues();
    } catch (e) {
      console.log(e);
      const parseErrors = parseError(e);
      parseErrors.forEach(el => {
        if (el.name === 'BAD_USER_INPUT') {
          const key = Object.keys(el.message)[0];
          setSnackbar({ ...defaultSnackbar, label: el.message[key], severity: 'error' });
        } else if (el.name === 'PRODUCT_INVOICE_ERROR') {
          setIsOpenProductInvoice(true);
        } else {
          setSnackbar({ ...defaultSnackbar, label: el.message, severity: 'error' });
        }
      });
    }
  };

  useEffect(() => {
    if (invoiceFormStateVar === 2) handleAction(null, true);
  }, [invoiceFormStateVar]);

  const handleChange = e => {
    const { name } = e.target;
    const { value } = e.target;
    setValues(values => ({ ...values, [name]: value }));
  };

  const handleProductInvoice = () => setIsOpenProductInvoice(false);

  return (
    <>
      <FormDialog
        title='Forma de Pago'
        isOpen={props.isOpen}
        isLoading={loading || loadingInvoice}
        rightContent={
          <div className='text-right items-center text-base'>Moneda: MXN, Tipo de Cambio: 1.00</div>
        }
        handleClose={handleClose}
        handleAction={createInvoice ? handleActionWithInvoice : handleAction}
      >
        <Grid container alignItems='flex-start' spacing={1}>
          <Grid item container spacing={1} md={6} xs={12}>
            <Grid item xs={12}>
              <TextField
                label='Efectivo'
                name='cash'
                value={values.cash}
                onChange={handleChange}
                noFormat
                type='number'
                variant='outlined'
              />
            </Grid>
            {paymentMethod.map(el => (
              <Grid key={el.value} item xs={12}>
                <TextField
                  label={el.label}
                  name={el.value}
                  value={values[el.value] || ''}
                  onChange={handleChange}
                  noFormat
                  type='number'
                  variant='outlined'
                />
              </Grid>
            ))}
          </Grid>
          <Grid item md={6} xs={12} align='right'>
            <Table padding='none' size='small'>
              <TableBody>
                <TableRow>
                  <TableCell align='right' className='text-lg'>
                    Subtotal
                  </TableCell>
                  <TableCell align='right' className='text-xl'>
                    {currencyFormat(amount)}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align='right' className='text-lg'>
                    Comisión
                  </TableCell>
                  <TableCell align='right' className='text-xl'>
                    0.00
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align='right' className='text-lg'>
                    Total
                  </TableCell>
                  <TableCell align='right' className='text-xl'>
                    {currencyFormat(amount)}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align='right' className='text-lg'>
                    Paga con
                  </TableCell>
                  <TableCell align='right' className='text-xl'>
                    {currencyFormat(totalPaid)}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align='right' className='text-lg'>
                    {changeAction}
                  </TableCell>
                  <TableCell align='right' className='text-xl'>
                    <strong>{currencyFormat(change)}</strong>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Grid>
          <Grid item xs={12}>
            <Switch
              checked={createInvoice}
              onChange={handleChangeSwitch}
              name='createInvoice'
              inputProps={{ 'aria-label': 'print' }}
            />
            ¿Deseás emitir factura electrónica?
          </Grid>
          <Grid item xs={12}>
            <InvoiceForm createInvoice={createInvoice} customerId={customerId} />
          </Grid>
        </Grid>
      </FormDialog>
      <InvoiceDialog
        isOpen={isInvoiceFormOpen[0]}
        data={isInvoiceFormOpen[1]}
        handleClose={handleInvoiceClose}
      />
      <ProductInvoiceDialog
        isOpen={isOpenProductInvoice}
        id={props.id}
        Products={
          dataServiceOrder && dataServiceOrder.sale
            ? dataServiceOrder.sale.SaleProducts
              ? dataServiceOrder.sale.SaleProducts
              : []
            : []
        }
        handleClose={handleProductInvoice}
        handleAction={handleAction}
      />
      <Snackbar horizontal='center' />
    </>
  );
};

export default Customer;
