import React from 'react';
import BranchComponent from '../containers/branch';
import CreateBranchLayoutComponent from './Branch/CreateBranchLayout';
import GeneralEditBranchComponent from '../containers/branch/Edit/General';
import BillingInformationEditBranchComponent from '../containers/branch/Edit/BillingInformation';
import BranchLayoutComponent from './Branch/BranchLayout';
import CredentialsEditBranchComponent from '../containers/branch/Edit/Credentials';
import AdressesLine2BranchComponent from '../containers/branch/Edit/AdressesLine2';
import BranchSettingsComponent from '../containers/branch/Edit/Settings';
var getBranchChildrenRoutes = function () {
    return [
        { index: true, element: React.createElement(BranchComponent, null) },
        {
            path: 'create',
            element: React.createElement(CreateBranchLayoutComponent, null),
        },
        {
            path: ':branchId',
            element: React.createElement(BranchLayoutComponent, null),
            children: [
                { index: true, element: React.createElement(GeneralEditBranchComponent, null) },
                { path: 'billlinginformation', element: React.createElement(BillingInformationEditBranchComponent, null) },
                { path: 'credentials', element: React.createElement(CredentialsEditBranchComponent, null) },
                { path: 'adress', element: React.createElement(AdressesLine2BranchComponent, null) },
                { path: 'setting', element: React.createElement(BranchSettingsComponent, null) },
            ],
        },
    ];
};
export default getBranchChildrenRoutes;
