import React, { useState } from 'react';

import { useMutation } from '@apollo/client';

import ForwardIcon from '@mui/icons-material/Forward';

import TextField from '@mui/material/TextField';
import Collapse from '@mui/material/Collapse';
import Link from '@mui/material/Link';

import LinearForm from '../../../../presentationals/LinearForm';

import GQL from '../CustomerCalendarCheck/_gql';

const ResponseMessageComponent = props => {
  const [message, setMessage] = useState('');
  const [error, setError] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [linkString, setLinkString] = useState('Responder');

  const [sendMessage, { loading }] = useMutation(GQL.SEND_MESSAGE);

  const handleChange = e => {
    setMessage(e.target.value);
  };

  const handleClick = e => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleAdd = async () => {
    try {
      if (message === '') throw new Error();

      await sendMessage({ variables: { message, user: props.user } });
      setError(false);
      setShowForm(false);
      setMessage(false);
      setLinkString('Mensaje enviado');
    } catch (e) {
      setError(true);
      console.log(e.message);
    }
  };

  const handleShowForm = e => {
    e.preventDefault();
    e.stopPropagation();
    setShowForm(true);
  };

  return (
    <div>
      {!showForm && (
        <Link onClick={handleShowForm} className='text-sm'>
          {linkString}
        </Link>
      )}
      <Collapse in={showForm} className='mt-1'>
        <LinearForm
          align='left'
          isLoading={loading}
          childrenContainer='small'
          handleAdd={handleAdd}
          buttonSize='small'
          icon={<ForwardIcon fontSize='small' />}
        >
          <TextField
            variant='outlined'
            name='message'
            onChange={handleChange}
            onClick={handleClick}
            value={message}
            fullWidth
            error={error}
            size='small'
            InputProps={{ classes: { input: 'bg-white h-6' } }}
          />
        </LinearForm>
      </Collapse>
    </div>
  );
};

export default ResponseMessageComponent;
