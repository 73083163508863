import React from 'react';
import BodySurveyCustomerComponent from './Body';
import CreateCustomerSurverButtonComponent from './CreateButton';
var SurveyCustomerComponent = function () {
    return (React.createElement("div", { className: 'flex flex-1 flex-col' },
        React.createElement("div", { className: 'flex items-center px-4 border-0 border-b border-[#d6d6d6] border-solid' },
            React.createElement("div", { className: 'flex flex-1 font-medium' }, "Entrevistas disponibles"),
            React.createElement(CreateCustomerSurverButtonComponent, null)),
        React.createElement(BodySurveyCustomerComponent, null)));
};
export default SurveyCustomerComponent;
