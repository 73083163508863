import React from 'react';
import AddCircleIcon from '@mui/icons-material/AddOutlined';
import IconButtonComponent from '../../../../../presentationals/IconButton';
import { isCreateProductPriceOpen } from '../../../../../apollo/cache';
var OpenAddProductPriceDialogButton = function () {
    var handleClick = function () {
        isCreateProductPriceOpen(true);
    };
    return (React.createElement(IconButtonComponent, { action: handleClick, icon: React.createElement(AddCircleIcon, null), size: 'small', color: 'secondary', label: 'A\u00F1adir tarifa' }));
};
export default OpenAddProductPriceDialogButton;
