var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useEffect, useMemo, useState } from 'react';
import { useReactiveVar } from '@apollo/client';
import DialogComponent from '../../../presentationals/Dialog';
import { filter, inputError } from '../../../apollo/cache';
import DateFieldComponent from '../../../presentationals/FormComponents/DateField';
import BranchFieldFilterEmployeeComponent from '../../employee/Filter/BranchField';
import FooterDialogComponent from '../../../presentationals/Dialog/Footer';
import ButtonFooterDialogComponent from '../../../presentationals/Dialog/Footer/Button';
import getInputErrorFormat from '../../sale.v2/Action/helpers/getInputErrorFormat';
import getSession from '../../../helpers/getSession';
import IncludeShippingFieldComponent from './IncludeShippingField';
import IncludeCommissionFieldComponent from './IncludeCommissionField';
import LoadingProgress from '../../../presentationals/LoadingProgress';
import useFilterButtonDialog from '../../../store/filterButton/filterButtonDialog';
var FilterIncomeReportDialog = function () {
    var defaultValues = useMemo(function () {
        var _a;
        var session = getSession();
        var branchId = (_a = session === null || session === void 0 ? void 0 : session.Company) === null || _a === void 0 ? void 0 : _a.Branch.id;
        return {
            start: new Date(),
            end: new Date(),
            branchId: branchId ? branchId.toString() : '',
            shipping: true,
            commission: true,
        };
    }, []);
    var errors = useReactiveVar(inputError);
    var open = useFilterButtonDialog(function (state) { return state.open; });
    var setOpen = useFilterButtonDialog(function (state) { return state.setOpen; });
    var _a = useState(defaultValues), values = _a[0], setValues = _a[1];
    var _b = useState(true), isLoading = _b[0], setIsLoading = _b[1];
    useEffect(function () {
        if (open) {
            var filterVar_1 = filter();
            setValues(function (values) { return ({
                start: filterVar_1.start ? filterVar_1.start : values.start,
                end: filterVar_1.end ? filterVar_1.end : values.end,
                shipping: typeof filterVar_1.shipping === 'boolean' ? filterVar_1.shipping : true,
                commission: typeof filterVar_1.commission === 'boolean' ? filterVar_1.commission : true,
                branchId: filterVar_1.branchId ? filterVar_1.branchId.toString() : values.branchId,
            }); });
            setTimeout(function () {
                setIsLoading(false);
            }, 500);
        }
    }, [open]);
    var handleClose = function () {
        setOpen(false);
    };
    var handleDateChange = function (name, value) {
        setValues(function (values) {
            var _a;
            return (__assign(__assign({}, values), (_a = {}, _a[name] = value, _a)));
        });
    };
    var handleChange = function (name, value) {
        setValues(function (values) {
            var _a;
            return (__assign(__assign({}, values), (_a = {}, _a[name] = value, _a)));
        });
    };
    var handleCheckboxChange = function (name, value) {
        setValues(function (values) {
            var _a;
            return (__assign(__assign({}, values), (_a = {}, _a[name] = value, _a)));
        });
    };
    var handleClick = function () {
        try {
            var currentDate = new Date();
            var currentFilter = filter();
            filter(__assign(__assign({}, currentFilter), { branchId: !values.branchId ? undefined : values.branchId, commission: values.commission, shipping: values.shipping, start: values.start || currentDate, end: values.end || currentDate }));
            handleClose();
        }
        catch (error) {
            getInputErrorFormat(error);
        }
    };
    return (React.createElement(DialogComponent, { open: open, fullScreen: true, title: 'Filtro para consulta de informes', onClose: handleClose }, isLoading ? (React.createElement(LoadingProgress, null)) : (React.createElement("div", { className: 'flex flex-1 flex-col overflow-auto' },
        React.createElement("div", { className: 'flex flex-col flex-1 p-4' },
            React.createElement("div", { className: 'flex flex-col w-full sm:w-1/4' },
                React.createElement(DateFieldComponent, { name: 'start', label: 'Del', value: values.start, onChange: handleDateChange, error: errors.start }),
                React.createElement(DateFieldComponent, { name: 'end', label: 'Al', value: values.end, onChange: handleDateChange, error: errors.end }),
                React.createElement(BranchFieldFilterEmployeeComponent, { allBranchesOptionHidden: true, value: values.branchId, onChange: handleChange }),
                React.createElement(IncludeShippingFieldComponent, { initialValue: values.shipping, onChange: handleCheckboxChange }),
                React.createElement(IncludeCommissionFieldComponent, { initialValue: values.commission, onChange: handleCheckboxChange }))),
        React.createElement(FooterDialogComponent, null,
            React.createElement("div", null),
            React.createElement(ButtonFooterDialogComponent, { color: 'success', onClick: handleClick }, "Aplicar filtro"))))));
};
export default FilterIncomeReportDialog;
