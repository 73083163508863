import React, { useRef } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import StyleOutlinedIcon from '@mui/icons-material/StyleOutlined';
import OnlineOrderMenuButtonComponent from '../helpers/MenuButton';
import RHFOrderTypePopoverComponent from '../../../presentationals/RHForm/OrderTypePopover';
var OnlineOrderTypeButtonComponent = function () {
    var rhfOrderTypePopoverRef = useRef(null);
    var control = useFormContext().control;
    var value = useController({
        name: 'type',
        control: control,
    }).field.value;
    var handleClick = function (e) {
        var _a;
        (_a = rhfOrderTypePopoverRef.current) === null || _a === void 0 ? void 0 : _a.open(e.currentTarget);
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(OnlineOrderMenuButtonComponent, { onClick: handleClick, label: 'Tipo de pedido', Icon: StyleOutlinedIcon, value: value === 2 ? 'Ordene y recoja' : 'Domicilio' }),
        React.createElement(RHFOrderTypePopoverComponent, { name: 'type', ref: rhfOrderTypePopoverRef })));
};
export default OnlineOrderTypeButtonComponent;
