var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useEffect, useState } from 'react';
import SwitchComponent from '../../../../presentationals/FormComponents/Switch';
import getTransactionReportTableSettingColumnLabel from '../../helpers/getTransactionReportTableSettingColumnLabel';
import setTransactionTableColumnConfiguration from '../../../../helpers/setTransactionTableColumnConfiguration';
import getTransactionTableColumnConfiguration, { defaultTransactionTableColumnsConfiguration, } from '../../../../helpers/getTransactionTableColumnConfiguration';
import setLocalStorageShowTransactionGraph from '../../../../helpers/setLocalStorageShowTransactionGraph';
import getLocalStorageShowTransactionGraph from '../../../../helpers/getLocalStorageShowTransactionGraph';
import useTableSettingsDialog from '../../../../store/tableSettings/tableSettingsDialog';
var BodyTableSettingsTransactionComponent = function () {
    var open = useTableSettingsDialog(function (state) { return state.open; });
    var _a = useState(defaultTransactionTableColumnsConfiguration), values = _a[0], setValues = _a[1];
    var _b = useState(true), showGraph = _b[0], setShowGraph = _b[1];
    useEffect(function () {
        if (open) {
            var settings = getTransactionTableColumnConfiguration();
            setValues(settings);
            var graphValue = getLocalStorageShowTransactionGraph();
            setShowGraph(graphValue);
        }
    }, [open]);
    var handleChange = function (name, value) {
        setValues(function (values) {
            var _a;
            return (__assign(__assign({}, values), (_a = {}, _a[name] = value, _a)));
        });
        setTransactionTableColumnConfiguration(name, value);
    };
    var handleGraphChange = function (name, value) {
        setShowGraph(value);
        setLocalStorageShowTransactionGraph(value);
    };
    return (React.createElement("div", { className: 'flex-1 grid-cols-1 md:grid-cols-4 p-3' },
        React.createElement("div", null,
            React.createElement("div", { className: 'ml-1 font-medium' }, "Columnas de la tabla"),
            React.createElement("div", { className: 'ml-1 text-sm text-gray-500' }, "Selecciona las columnas visibles"),
            Object.keys(values).map(function (key) { return (React.createElement(SwitchComponent, { key: key, name: key, disabled: ['total'].includes(key), className: 'py-0 p-0', size: 'small', label: getTransactionReportTableSettingColumnLabel(key), onChange: handleChange, defaultValue: values[key], labelPlacement: 'end' })); }),
            React.createElement("div", { className: 'ml-1 font-medium mt-4' }, "Gr\u00E1fica de transacci\u00F3nes por d\u00EDa"),
            React.createElement("div", { className: 'ml-1 text-sm text-gray-500' }, "Selecciona las opciones seg\u00FAn tu preferencia"),
            React.createElement(SwitchComponent, { name: 'showGraph', className: 'py-0 p-0', size: 'small', label: 'Mostrar gr\u00E1fica', onChange: handleGraphChange, defaultValue: showGraph, labelPlacement: 'end' })),
        React.createElement("div", null),
        React.createElement("div", null),
        React.createElement("div", null)));
};
export default BodyTableSettingsTransactionComponent;
