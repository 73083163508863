var getDayByNumber = function (numberOfDay, type) {
    switch (numberOfDay) {
        case 0:
            return type === 2 ? 'Domingo' : 'D';
        case 1:
            return type === 2 ? 'Lunes' : 'L';
        case 2:
            return type === 2 ? 'Martes' : 'M';
        case 3:
            return type === 2 ? 'Miércoles' : 'W';
        case 4:
            return type === 2 ? 'Jueves' : 'J';
        case 5:
            return type === 2 ? 'Viernes' : 'V';
        case 6:
            return type === 2 ? 'Sábado' : 'S';
        default:
            return '?';
    }
};
export default getDayByNumber;
