import React, { useEffect, useState } from 'react';
import { FormControlLabel, Switch } from '@mui/material';
var SwitchComponent = function (props) {
    var _a = useState(false), isActive = _a[0], setIsActive = _a[1];
    useEffect(function () {
        setIsActive(!!props.defaultValue);
    }, [props.defaultValue]);
    var handleChange = function (e) {
        e.stopPropagation();
        setIsActive(function (isActive) { return !isActive; });
        props.onChange(props.name, !isActive);
    };
    return (React.createElement("div", { className: "w-full ".concat(props.className) },
        React.createElement(FormControlLabel, { onChange: handleChange, className: 'm-0', classes: {
                label: props.size === 'small' ? 'text-base' : '',
            }, control: React.createElement(Switch, { size: props.size || 'medium', className: "m-0 ".concat(!props.labelPlacement ? 'ml-1' : undefined), checked: isActive, color: 'primary', disableRipple: true }), label: props.label, disabled: props.isLoading || props.disabled, labelPlacement: props.labelPlacement || 'start' })));
};
export default SwitchComponent;
