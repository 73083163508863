var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { useEffect, useState } from 'react';
import { useReactiveVar } from '@apollo/client';
import { filter } from '../apollo/cache';
import useApolloLazyQuery from './useApolloLazyQuery';
import GQL_SALE from '../apollo/gql/sale';
import convertToCurrencyLabel from '../helpers/convertToCurrencyLabel';
var useGetDetailedSaleProductIngredientRotation = function (ingredientId) {
    var filterVar = useReactiveVar(filter);
    var _a = useState([]), data = _a[0], setData = _a[1];
    var _b = useApolloLazyQuery(GQL_SALE.GET_SALE_PRODUCT_INGREDIENTS_ROTATION_DETAILS), getSales = _b[0], queryResult = _b[1];
    useEffect(function () {
        if (ingredientId)
            getSales({
                variables: {
                    productId: ingredientId,
                    like: filterVar.like,
                    start: filterVar.start,
                    end: filterVar.end,
                    branchId: filterVar.branchId,
                },
            });
    }, [ingredientId, filterVar]);
    useEffect(function () {
        if (queryResult.data) {
            var data_1 = queryResult.data.map(function (el) { return ({
                id: el.productId,
                sku: el.sku,
                product: el.productName,
                presentation: el.productPresentationName,
                quantity: convertToCurrencyLabel(el.quantityIngredient),
                portion: convertToCurrencyLabel(el.quantity),
                total: convertToCurrencyLabel(el.total),
            }); });
            setData(data_1);
        }
    }, [queryResult.data]);
    return __assign(__assign({}, queryResult), { data: data });
};
export default useGetDetailedSaleProductIngredientRotation;
