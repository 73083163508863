var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import addAdjustProductByBarcode from './helpers/addAdjustProductByBarcode';
import TableRowAdjustInventoryProductComponent from './TableRow';
import getProductsFromCache from '../../helpers/getProductsFromCache';
import AdjustHeaderRowComponent from './AdjustHeaderRow';
import DiffHeaderRowComponent from './DiffHeaderRow';
import deleteProductByIdFromCache from '../../helpers/deleteProductFromCache';
import addAdjustProductById from './helpers/addAdjustProductById';
import AdjustInventoryProductDetailDialogComponent from '../ProductDetailDialog';
var AdjustInventoryProductTableComponent = forwardRef(function (props, ref) {
    var adjustInventoryProductDetailDialogRef = useRef(null);
    var _a = useState([]), rows = _a[0], setRows = _a[1];
    useEffect(function () {
        var f = function () { return __awaiter(void 0, void 0, void 0, function () {
            var data;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, getProductsFromCache()];
                    case 1:
                        data = _a.sent();
                        setRows(data.map(function (el) { return ({
                            productPresentationId: el.productPresentationId,
                            barcode: el.barcode,
                            newInvQty: el.qty,
                            quantity: el.qty,
                            movementQty: 1,
                            history: [{ movement: 1, quantity: el.qty, total: el.qty }],
                        }); }));
                        return [2 /*return*/];
                }
            });
        }); };
        f();
    }, []);
    var handleAddProductByBarcode = function (Item) {
        var newRows = addAdjustProductByBarcode(Item, rows);
        setRows(newRows);
    };
    var handleAddProduct = function (Item) {
        var newRows = addAdjustProductById(Item, rows);
        setRows(newRows);
    };
    var handleRowError = function (index) {
        setRows(function (rows) { return rows.filter(function (el, i) { return i !== index; }); });
    };
    var handleDelete = function (index, productPresentationId) {
        deleteProductByIdFromCache(productPresentationId);
        handleRowError(index);
    };
    var handleGetProducts = function () { return rows; };
    useImperativeHandle(ref, function () { return ({
        onAddProductByBarcode: handleAddProductByBarcode,
        onAddProduct: handleAddProduct,
        getProducts: handleGetProducts,
    }); });
    var handleRowClick = function (index, History) {
        var _a;
        (_a = adjustInventoryProductDetailDialogRef.current) === null || _a === void 0 ? void 0 : _a.open(index, History);
    };
    var handleRowsChange = function (Rows) {
        setRows(Rows);
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(TableContainer, { className: 'bg-slate-50 rounded border border-solid border-gray-300' },
            React.createElement(Table, { stickyHeader: true, size: 'small' },
                React.createElement(TableHead, null,
                    React.createElement(TableRow, { className: 'bg-[#f6f6f6]' },
                        React.createElement(TableCell, null),
                        React.createElement(TableCell, null, "Nombre"),
                        React.createElement(TableCell, null, "Presentaci\u00F3n"),
                        React.createElement(TableCell, null, "Movimientos"),
                        React.createElement(TableCell, { className: 'whitespace-nowrap', align: 'center' }, "Stock en sistema"),
                        React.createElement(AdjustHeaderRowComponent, { adjustType: props.adjustType }),
                        React.createElement(DiffHeaderRowComponent, { adjustType: props.adjustType }),
                        React.createElement(TableCell, { align: 'right' }, "Costo"))),
                React.createElement(TableBody, null, rows.map(function (el, i) { return (React.createElement(TableRowAdjustInventoryProductComponent, { key: el.productPresentationId || el.barcode, index: i, productPresentationId: el.productPresentationId, barcode: el.barcode, movementQty: el.movementQty, newInvQty: el.newInvQty, history: el.history, onClick: handleRowClick, onError: handleRowError, onDelete: handleDelete, adjustType: props.adjustType, branchId: props.branchId })); })))),
        React.createElement(AdjustInventoryProductDetailDialogComponent, { ref: adjustInventoryProductDetailDialogRef, onRowsChange: handleRowsChange, Rows: rows })));
});
export default AdjustInventoryProductTableComponent;
