var getDefaultCreateColorValues = function () {
    return {
        name: '',
        attribute: '',
        presentationId: -1,
        priceType: 1,
        price: '',
        file: null,
    };
};
export default getDefaultCreateColorValues;
