import React from 'react';
import useGePaymentMethodData from './helpers/useGetPaymentMethodData';
import ModuleV3Component from '../../presentationals/Module.v3';
import RedirectCreateFormToRouteComponent from '../../presentationals/RedirectCreateFormToRoute';
import RedirectEditFormToRouteComponent from '../../presentationals/RedirectEditFormToRoute';
var PaymentMethodComponent = function () {
    var _a = useGePaymentMethodData({ getByFilter: true }), data = _a.data, loading = _a.loading;
    var header = [
        { Header: 'Nombre', accessor: 'name' },
        { Header: 'Comisión por uso', accessor: 'commission' },
        { Header: '¿Es dinero en efectivo?', accessor: 'isCash' },
        { Header: '¿Es único al cobrar?', accessor: 'isUnique' },
        { Header: 'Código SAT', accessor: 'code' },
    ];
    return (React.createElement(React.Fragment, null,
        React.createElement(ModuleV3Component, { title: 'Formas de pago', data: data, header: header, isLoading: loading }),
        React.createElement(RedirectCreateFormToRouteComponent, null),
        React.createElement(RedirectEditFormToRouteComponent, null)));
};
export default PaymentMethodComponent;
