var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import getPreferences from './getPreferences';
export var defaultTransactionTableColumnsConfiguration = {
    createdAt: true,
    clientCreatedAt: true,
    status: true,
    saleFolio: true,
    saleTotal: true,
    customer: true,
    invoiceComplementUuid: true,
    paymentForm: true,
    total: true,
};
var getTransactionTableColumnConfiguration = function () {
    var Preferences = getPreferences();
    return (Preferences === null || Preferences === void 0 ? void 0 : Preferences.transactionTableColumnConfiguration)
        ? __assign(__assign({}, Preferences.transactionTableColumnConfiguration), { total: true }) : defaultTransactionTableColumnsConfiguration;
};
export default getTransactionTableColumnConfiguration;
