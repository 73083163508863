var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import EditIcon from '@mui/icons-material/Edit';
import IconButton from '../../../../../../../../presentationals/IconButton';
import { isEditPurchaseOrderItemOpen, editPurchaseOrderItemForm, } from '../../../../../../../../apollo/cache';
var EditButtonItemBodyTableComponent = function (props) {
    var handleEdit = function () {
        var editPurchaseOrderItemFormVar = editPurchaseOrderItemForm();
        editPurchaseOrderItemForm(__assign(__assign({}, editPurchaseOrderItemFormVar), { qty: "".concat(props.quantity), discount: "".concat(props.discount), price: "".concat(props.price), index: props.index, purchaseOrderDetailId: props.purchaseOrderDetailId }));
        isEditPurchaseOrderItemOpen(true);
    };
    return React.createElement(IconButton, { label: 'Editar', size: 'small', icon: React.createElement(EditIcon, null), action: handleEdit });
};
export default EditButtonItemBodyTableComponent;
