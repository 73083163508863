import React from 'react';
import { TableCell } from '@mui/material';
import TableRowItemProductTableEditPromotionComponent from './TableRow';
import { currencyFormat } from '../../../../../helpers';
var ItemProductTableEditPromotionComponent = function (props) {
    var _a;
    return (React.createElement(TableRowItemProductTableEditPromotionComponent, { productPromotionId: props.ProductPromotion.id, background: props.index % 2 > 0 ? 'bg-inherit' : 'bg-gray-100' },
        React.createElement(TableCell, null, props.ProductPromotion.Product.name),
        React.createElement(TableCell, null, ((_a = props.ProductPromotion.ProductPresentation) === null || _a === void 0 ? void 0 : _a.name) || '-'),
        React.createElement(TableCell, { align: 'center' }, props.ProductPromotion.quantity),
        React.createElement(TableCell, null, currencyFormat(props.ProductPromotion.price))));
};
export default ItemProductTableEditPromotionComponent;
