var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { format, getDay } from 'date-fns';
var chartByAvg = function (data) {
    var defaultDays = [0, 0, 0, 0, 0, 0, 0];
    var filteredDays = data.reduce(function (currentObject, el) {
        var _a;
        var day = format(new Date(el.clientCreatedAt), 'yyyy-MM-dd 12:00:00');
        var currentKeys = Object.keys(currentObject);
        return __assign(__assign({}, currentObject), (_a = {}, _a[day] = currentKeys.includes(day) ? currentObject[day] + 1 : 1, _a));
    }, {});
    return Object.keys(filteredDays).reduce(function (array, el) {
        var day = getDay(new Date(el));
        return array.map(function (elCh, i) { return (i === day ? elCh + 1 : elCh); });
    }, defaultDays);
};
var getFormattedSaleByDayData = function (data, chartByTotal) {
    var defaultArray = [
        {
            name: 'Domingo',
            lastPeriod: 0,
            currentPeriod: 0,
            qty: 0,
        },
        {
            name: 'Lunes',
            lastPeriod: 0,
            currentPeriod: 0,
            qty: 0,
        },
        {
            name: 'Martes',
            lastPeriod: 0,
            currentPeriod: 0,
            qty: 0,
        },
        {
            name: 'Miércoles',
            lastPeriod: 0,
            currentPeriod: 0,
            qty: 0,
        },
        {
            name: 'Jueves',
            lastPeriod: 0,
            currentPeriod: 0,
            qty: 0,
        },
        {
            name: 'Viernes',
            lastPeriod: 0,
            currentPeriod: 0,
            qty: 0,
        },
        {
            name: 'Sábado',
            lastPeriod: 0,
            currentPeriod: 0,
            qty: 0,
        },
    ];
    var filteredDays = chartByAvg(data);
    var newArray = data.reduce(function (array, el) {
        var day = getDay(new Date(el.clientCreatedAt));
        return array.map(function (elCh, i) {
            return i === day
                ? __assign(__assign({}, elCh), { currentPeriod: elCh.currentPeriod + el.total, qty: filteredDays[i] }) : elCh;
        });
    }, defaultArray);
    return chartByTotal
        ? newArray
        : newArray.map(function (el) { return (__assign(__assign({}, el), { currentPeriod: el.qty > 0 ? el.currentPeriod / el.qty : 0, lastPeriod: el.qty > 0 ? el.lastPeriod / el.qty : 0 })); });
};
export default getFormattedSaleByDayData;
