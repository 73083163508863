import React from 'react';

import { ListItemButton } from '@mui/material';

import getInputErrorFormat from '../../../../../../../../../../Action/helpers/getInputErrorFormat';
import {
  isSatUnitKeyDialogOpen,
  productSaleV2,
} from '../../../../../../../../../../../../apollo/cache';

const ListItemButtonItemTableSearchFormCustomerActionSaleV2Component = props => {
  const handleClick = () => {
    try {
      const values = productSaleV2();
      productSaleV2({ ...values, unitKey: props.id });
      isSatUnitKeyDialogOpen(false);
    } catch (e) {
      getInputErrorFormat(e);
    }
  };
  return (
    <ListItemButton onClick={handleClick} className={`${props.background} p-0`}>
      {props.children}
    </ListItemButton>
  );
};

export default ListItemButtonItemTableSearchFormCustomerActionSaleV2Component;
