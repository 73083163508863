var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useState } from 'react';
import { useReactiveVar } from '@apollo/client';
import FooterDialogComponent from '../../../../presentationals/Dialog/Footer';
import CreateCashClosingButtonComponent from './CreateButton';
import TextFieldComponent from '../../../../presentationals/FormComponents/TextField';
import { inputError } from '../../../../apollo/cache';
var BodyCreateCashClosingComponent = function () {
    var defaultValues = {
        realBalance: '',
    };
    var errors = useReactiveVar(inputError);
    var _a = useState(defaultValues), values = _a[0], setValues = _a[1];
    var handleChange = function (name, value) {
        setValues(function (values) {
            var _a;
            return (__assign(__assign({}, values), (_a = {}, _a[name] = value, _a)));
        });
    };
    return (React.createElement("div", { className: 'flex flex-1 flex-col basis-0 overflow-auto' },
        React.createElement("div", { className: 'grid grid-cols-1 sm:grid-cols-2 flex-1 px-4 py-3' },
            React.createElement("div", { className: 'flex flex-1 flex-col' },
                React.createElement("div", null, "Est\u00E1s a punto de realizar un corte de caja. Te sugerimos que cuentes de manera minuciosa el dinero en efectivo que actualmente tienes en caja chica."),
                React.createElement("div", { className: 'w-full sm:w-1/2' },
                    React.createElement(TextFieldComponent, { name: 'realBalance', label: 'Saldo final en efectivo', type: 'number', value: values.realBalance, error: errors.realBalance, onChange: handleChange })))),
        React.createElement(FooterDialogComponent, null,
            React.createElement("div", null),
            React.createElement(CreateCashClosingButtonComponent, { values: values }))));
};
export default BodyCreateCashClosingComponent;
