var getRentMenuValueFromPath = function (pathname) {
    var pathnameParts = pathname.split('/');
    var path = pathnameParts[pathnameParts.length - 1];
    switch (path) {
        case 'delivery':
            return 0;
        case 'pickup':
            return 1;
        case 'close':
            return 2;
        case 'finished':
            return 3;
        default:
            return 0;
    }
};
export default getRentMenuValueFromPath;
