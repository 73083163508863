import React from 'react';

import { ListItem, ListItemText } from '@mui/material';

import ListItemButton from './ListItemButton';
import SearchButton from './SearchButton';

const ItemTableSearchFormCustomerActionSaleV2Component = props => {
  return (
    <ListItemButton id={props.id} background={props.background}>
      <ListItem secondaryAction={<SearchButton id={props.id} />}>
        <ListItemText primary={props.name} secondary={props.phone} />
      </ListItem>
    </ListItemButton>
  );
};

export default ItemTableSearchFormCustomerActionSaleV2Component;
