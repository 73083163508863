import React, { useEffect, useState } from 'react';

import { useLazyQuery } from '@apollo/client';
import Typography from '@mui/material/Typography';

import FormDialog from '../../presentationals/FormDialog';
import TextField from '../../presentationals/FormComponents/TextField';
import Snackbar from '../../presentationals/Snackbar';

import GQL from './_gql';
import getInputErrorFormat from '../sale.v2/Action/helpers/getInputErrorFormat';

const defaultValues = {
  email: '',
  altEmail: '',
  otherEmail: '',
};

const Component = props => {
  const [values, setValues] = useState(defaultValues);
  const [sendQuote, { loading }] = useLazyQuery(GQL.RE_SEND_EC_SALE);
  const [getQuote, { data: dataQuote }] = useLazyQuery(GQL.GET_BY_ID);

  useEffect(() => {
    if (props.id) getQuote({ variables: { id: props.id } });
  }, [props.id]);

  useEffect(() => {
    if (dataQuote)
      if (dataQuote.quote.Customer)
        if (dataQuote.quote.Customer.CustomerBillingInformation) {
          const email = dataQuote.quote.Customer.CustomerBillingInformation.email
            ? dataQuote.quote.Customer.CustomerBillingInformation.email
            : '';
          const altEmail = dataQuote.quote.Customer.CustomerBillingInformation.altEmail
            ? dataQuote.quote.Customer.CustomerBillingInformation.altEmail
            : '';
          const otherEmail = dataQuote.quote.Customer.CustomerBillingInformation.otherEmail
            ? dataQuote.quote.Customer.CustomerBillingInformation.otherEmail
            : '';
          setValues(values => ({ ...values, email, altEmail, otherEmail }));
        }
  }, [dataQuote]);

  const handleClose = () => {
    props.handleClose();
    setValues(defaultValues);
  };

  const handleChange = (name, value) => {
    setValues(values => ({ ...values, [name]: value }));
  };

  const handleAction = async () => {
    try {
      const emails = [
        values.email !== '' && typeof values.email === 'string' && values.email.trim(),
        values.altEmail !== '' && typeof values.altEmail === 'string' && values.altEmail.trim(),
        values.otherEmail !== '' &&
          typeof values.otherEmail === 'string' &&
          values.otherEmail.trim(),
      ].filter(el => el);
      await sendQuote({ variables: { id: props.id, emails } });
      handleClose();
      setValues(defaultValues);
    } catch (e) {
      getInputErrorFormat(e);
    }
  };

  return (
    <>
      <FormDialog
        title=''
        isOpen={props.isOpen}
        isLoading={loading}
        handleClose={handleClose}
        handleAction={handleAction}
        actionLabel='Enviar'
      >
        <div>
          <Typography variant='h5' gutterBottom>
            Reenviar PDF
          </Typography>
          <Typography variant='subtitle1'>
            Asegúrate de que el correo proporcionado sea el correcto para hacer llegar al archivo
            satisfactoriamente.
          </Typography>
          <TextField
            label='Correo 1'
            name='email'
            value={values.email}
            onChange={handleChange}
            noFormat
            variant='outlined'
          />
          <TextField
            label='Correo 2'
            name='altEmail'
            value={values.altEmail}
            onChange={handleChange}
            noFormat
            variant='outlined'
          />
          <TextField
            label='Correo 3'
            name='otherEmail'
            value={values.otherEmail}
            onChange={handleChange}
            noFormat
            variant='outlined'
          />
        </div>
      </FormDialog>
      <Snackbar horizontal='center' />
    </>
  );
};

export default Component;
