import React from 'react';
import LoadingProgress from '../../../../presentationals/LoadingProgress';
import useGetSaleByParamSaleId from '../../../../hooks/useGetSaleByParamSaleId';
import BodyProductsRentComponent from './Body';
import AddRentProductComponent from './AddProduct';
import OpenAddProductButtonComponent from './OpenAddProductButton';
import EditRentProductComponent from './EditProduct';
var ProductsRentComponent = function () {
    var _a = useGetSaleByParamSaleId(), data = _a.data, loading = _a.loading;
    if (loading || !data)
        return React.createElement(LoadingProgress, null);
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: 'flex flex-1 flex-col p-3' },
            React.createElement("div", { className: 'flex items-center' },
                React.createElement("div", { className: 'text-base font-medium mb-1 flex flex-1' }, "Productos de la renta"),
                data.deliveryStatus === 0 && React.createElement(OpenAddProductButtonComponent, null)),
            React.createElement("div", { className: 'text-sm sm:text-base text-gray-600' }, "Consulta y personaliza el los productos registrados en la renta. En esta secci\u00F3n podr\u00E1s a\u00F1adir nuevos productos o modificar los ya existentes. Es importante notificar que la cantidad registrada de los productos se descuenta virtualmente del inventario en el rango de fechas seleccionadas de entrega y recolecci\u00F3n."),
            React.createElement("div", { className: 'rounded mt-3 overflow-hidden flex flex-1' },
                React.createElement(BodyProductsRentComponent, { deliveryStatus: data.deliveryStatus, SaleProducts: data.SaleProducts, subtotal: data.subtotal, taxes: data.taxes, total: data.total }))),
        React.createElement(AddRentProductComponent, null),
        React.createElement(EditRentProductComponent, null)));
};
export default ProductsRentComponent;
