import React, { useState } from 'react';
import { TableCell, TableRow } from '@mui/material';
import convertToCurrencyLabel from '../../../../helpers/convertToCurrencyLabel';
import ShowProductHistoryButtonComponent from './ShowProductHistoryButton';
import AdjustInventoryButtonComponent from './AdjustInventoryButton';
import TransferProductInventoryButtonComponent from './TransferButton';
var ProductInventoryReportComponent = function (props) {
    var _a = useState(false), selected = _a[0], setSelected = _a[1];
    var handleShowHistory = function () {
        props.onShowProductHistoryButtonClick(props.Product.id);
    };
    var handleMouseOver = function () {
        setSelected(true);
    };
    var handleMouseOut = function () {
        setSelected(false);
    };
    return (React.createElement(TableRow, { hover: true, onMouseEnter: handleMouseOver, onMouseLeave: handleMouseOut, className: "".concat(props.index % 2 ? 'bg-gray-100' : '', " hover:!bg-gray-200 relative") },
        React.createElement(TableCell, { className: 'relative' },
            selected && (React.createElement("div", { className: 'absolute bg-gray-200 pr-2 h-full top-0 items-center' },
                React.createElement(ShowProductHistoryButtonComponent, { onClick: handleShowHistory }),
                React.createElement(AdjustInventoryButtonComponent, { presentationId: props.ProductPresentation.id, currentQty: props.quantity }),
                React.createElement(TransferProductInventoryButtonComponent, { presentationId: props.ProductPresentation.id, currentQty: props.quantity }))),
            React.createElement("div", { className: 'flex' },
                React.createElement("div", null, "".concat(props.Product.name, " ").concat(props.ProductPresentation.name)),
                React.createElement("div", { className: 'text-sm text-gray-500' }, "".concat(props.Product.sku, " ").concat(props.Product.barcode)))),
        React.createElement(TableCell, null, convertToCurrencyLabel(props.quantity)),
        React.createElement(TableCell, null, "$".concat(convertToCurrencyLabel(props.cost)))));
};
export default ProductInventoryReportComponent;
