var getSurveyType = function (type) {
    switch (type) {
        case 1:
            return 'Encuesta';
        case 2:
        default:
            return 'Entrevista';
    }
};
export default getSurveyType;
