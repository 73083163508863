import React from 'react';
import { useReactiveVar } from '@apollo/client';
import DialogComponent from '../../../presentationals/Dialog';
import { isCreateFormOpen } from '../../../apollo/cache';
import BodyCreateInvoiceComplementComponent from './Body';
var CreateInvoiceComplementComponent = function () {
    var isOpen = useReactiveVar(isCreateFormOpen);
    var handleClose = function () {
        isCreateFormOpen(false);
    };
    return (React.createElement(DialogComponent, { fullScreen: true, title: 'Emitir un complemento de pago', open: isOpen, onClose: handleClose },
        React.createElement(BodyCreateInvoiceComplementComponent, null)));
};
export default CreateInvoiceComplementComponent;
