import React, { useEffect, useState } from 'react';
// import { useNavigate } from 'react-router-dom';
import ReceiptIcon from '@mui/icons-material/ReceiptOutlined';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdfOutlined';
import ReplyIcon from '@mui/icons-material/ReplyOutlined';
import CodeIcon from '@mui/icons-material/CodeOutlined';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import EmailIcon from '@mui/icons-material/EmailOutlined';
import { actions, isSendSaleByEmailOpen } from '../../apollo/cache';
import CONFIG from '../../config/config.json';
import Ticket from './ticket';
import CreditNoteDialog from './CreditNoteDialog';
import CancelForm from '../sale.report.v2/cancel.form';
import SendSaleByEmailDialogComponent from '../../presentationals/SendSaleByEmailDialog';
var ActionComponent = function () {
    // const navigate = useNavigate();
    var _a = useState([false, null]), isCreditNoteDialogOpen = _a[0], setIsCreditNoteDialogOpen = _a[1];
    var _b = useState([false, null]), isOpenCancelInvoice = _b[0], setIsOpenCancelInvoice = _b[1];
    var handleTicket = function (e, _a) {
        var uuid = _a.uuid;
        return Ticket(uuid);
    };
    var handlePDF = function (e, _a) {
        var uuid = _a.uuid;
        window.open("".concat(CONFIG['restServer'].uri, "/files/invoices/").concat(uuid, "/pdf"));
    };
    var handleOpenCreditNote = function (e, data) {
        // navigate(`${data.id}/creditNote`);
        setIsCreditNoteDialogOpen([true, data.id, data.saleId]);
    };
    var handleCloseCreditNote = function () { return setIsCreditNoteDialogOpen([false, null, null]); };
    var handleXML = function (e, data) {
        window.open("".concat(CONFIG.restServer.uri, "/files/invoices/").concat(data.uuid, "/xml"));
    };
    var handleReSend = function (e, data) {
        isSendSaleByEmailOpen(data.saleId);
    };
    var handleCancel = function (e, _a) {
        var id = _a.id, saleId = _a.saleId;
        return setIsOpenCancelInvoice([true, { saleId: saleId, invoiceId: id }]);
    };
    var handleCloseCancel = function () { return setIsOpenCancelInvoice([false, null]); };
    useEffect(function () {
        actions([
            {
                icon: React.createElement(EmailIcon, null),
                label: 'Reenviar PDF',
                onClick: handleReSend,
            },
            {
                icon: React.createElement(ReplyIcon, null),
                label: 'Nota de Crédito',
                onClick: handleOpenCreditNote,
                disable: { key: 'status', value: 0 },
            },
            {
                icon: React.createElement(CodeIcon, null),
                label: 'Descargar XML',
                onClick: handleXML,
            },
            {
                icon: React.createElement(PictureAsPdfIcon, null),
                label: 'Consultar PDF',
                onClick: handlePDF,
            },
            {
                icon: React.createElement(ReceiptIcon, null),
                label: 'Consultar Ticket',
                onClick: handleTicket,
            },
            {
                icon: React.createElement(DeleteOutlineIcon, null),
                label: 'Cancelar Factura',
                onClick: handleCancel,
                disable: { key: 'status', value: 0 },
            },
        ]);
        return function () {
            actions([]);
        };
    }, []);
    return (React.createElement("div", null,
        React.createElement(CreditNoteDialog, { isOpen: isCreditNoteDialogOpen[0], id: isCreditNoteDialogOpen[1], saleId: isCreditNoteDialogOpen[2], handleClose: handleCloseCreditNote }),
        React.createElement(CancelForm, { isOpen: isOpenCancelInvoice[0], handleClose: handleCloseCancel, saleId: isOpenCancelInvoice[1] && isOpenCancelInvoice[1].saleId, invoiceId: isOpenCancelInvoice[1] && isOpenCancelInvoice[1].invoiceId }),
        React.createElement(SendSaleByEmailDialogComponent, null)));
};
export default ActionComponent;
