var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import getPreferences from './getPreferences';
export var defaultServiceOrderTableColumnsConfiguration = {
    createdAt: true,
    folio: true,
    customer: true,
    status: true,
    total: true,
    shipping: true,
    commission: true,
    taxes: true,
    subtotal: true,
    cashier: true,
    seller: true,
    commentary: true,
    expenses: true,
};
var getServiceOrderTableColumnConfiguration = function () {
    var Preferences = getPreferences();
    return (Preferences === null || Preferences === void 0 ? void 0 : Preferences.serviceOrderTableColumnConfiguration)
        ? __assign(__assign({}, Preferences.serviceOrderTableColumnConfiguration), { total: true }) : defaultServiceOrderTableColumnsConfiguration;
};
export default getServiceOrderTableColumnConfiguration;
