import React from 'react';
import Item from './Item';
import useApolloQuery from '../../../../../../../../hooks/useApolloQuery';
import GQL_CALENDAR from '../../../../../../../../apollo/gql/calendar';
import LoadingProgress from '../../../../../../../../presentationals/LoadingProgress';
var AgendaSettingsScheduleAvailabilityDialogSettingButtonFooterFormCalendarComponent = function () {
    var _a;
    var _b = useApolloQuery(GQL_CALENDAR.EMPLOYEE_CALENDAR_SETTINGS), data = _b.data, loadingData = _b.loading;
    if (loadingData)
        return React.createElement(LoadingProgress, null);
    return ((_a = data === null || data === void 0 ? void 0 : data.scheduleAvailability) === null || _a === void 0 ? void 0 : _a.qty) ? (React.createElement("div", null, Array.from(Array(data.scheduleAvailability.qty).keys()).map(function (key) { return (React.createElement(Item, { key: key, index: key })); }))) : (React.createElement("div", null));
};
export default AgendaSettingsScheduleAvailabilityDialogSettingButtonFooterFormCalendarComponent;
