import React from 'react';
import AppRegistrationIcon from '@mui/icons-material/AppRegistration';
import IconButtonComponent from '../../../../../presentationals/IconButton';
import useAddProductToAdjustInventoryStore from '../../../../../store/inventoryReport/addProductToAdjustInventoryStore';
var AdjustInventoryButtonComponent = function (props) {
    var setOpen = useAddProductToAdjustInventoryStore(function (state) { return state.setOpen; });
    var handleClick = function () {
        setOpen(true, props.presentationId, props.currentQty);
    };
    return (React.createElement(IconButtonComponent, { size: 'small', icon: React.createElement(AppRegistrationIcon, null), action: handleClick, label: 'Ajuste de inventario' }));
};
export default AdjustInventoryButtonComponent;
