var getCompanyMenuValueFromPath = function (pathname) {
    var pathnameParts = pathname.split('/');
    var path = pathnameParts[pathnameParts.length - 1];
    switch (path) {
        case 'general':
            return 0;
        case 'image':
            return 1;
        case 'ecommerce':
            return 2;
        case 'queueManager':
            return 3;
        case 'allowedModules':
            return 4;
        case 'twilioWhatsappApi':
            return 5;
        default:
            return 0;
    }
};
export default getCompanyMenuValueFromPath;
