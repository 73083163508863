import React from 'react';

import Table from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';

import TableBody from './Body';

const ProductPurchaseProductComponent = props => {
  return (
    <TableContainer className='my-2 rounded min-h-[120px] border border-solid border-[#d8d8d8]'>
      <Table size='small'>
        <TableHead>
          <TableRow className='bg-[#f6f6f6]'>
            <TableCell />
            <TableCell>Descripción</TableCell>
            <TableCell>Presentación</TableCell>
            <TableCell align='center'>Cant.</TableCell>
            <TableCell align='right'>Desc.</TableCell>
            <TableCell align='right'>
              <Typography variant='subtitle2' noWrap>
                P. Unitario
              </Typography>
            </TableCell>
            <TableCell align='right'>
              <Typography variant='subtitle2' noWrap>
                Importe
              </Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody noEdit={props.noEdit} />
      </Table>
    </TableContainer>
  );
};

export default ProductPurchaseProductComponent;
