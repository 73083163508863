import FRAGMENT_PRODUCT_INVENTORY from './fragmentProductInventory';
import GET_PRODUCT_INVENTORIES from './getProductInventories';
import GET_PRODUCT_MOVEMENT_LOGS from './getProductMovementsLogs';
import GET_SERIAL_NUMBER_BY_PRODUCT_ID from './getSerialNumbersByProductId';
var GQL_PRODUCT_INVENTORY = {
    FRAGMENT_PRODUCT_INVENTORY: FRAGMENT_PRODUCT_INVENTORY,
    GET_PRODUCT_INVENTORIES: GET_PRODUCT_INVENTORIES,
    GET_PRODUCT_MOVEMENT_LOGS: GET_PRODUCT_MOVEMENT_LOGS,
    GET_SERIAL_NUMBER_BY_PRODUCT_ID: GET_SERIAL_NUMBER_BY_PRODUCT_ID,
};
export default GQL_PRODUCT_INVENTORY;
