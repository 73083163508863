import { setContext } from '@apollo/client/link/context';
import getSession from '../helpers/getSession';

const authLink = setContext((_, { headers }) => {
  const session = getSession();
  const token = session?.accessToken;
  const authorization = token ? { authorization: `Bearer ${token}` } : {};
  return {
    headers: {
      ...headers,
      ...authorization,
    },
  };
});

export default authLink;
