import React from 'react';

import FilterDrawer from '../../presentationals/FilterDrawer.v2';
import { filter } from '../../apollo/cache';

const FilterDialog = () => {
  const handleAction = (e, data) => {
    const { startDate: start, endDate: end, branchId, name } = data;
    filter({ start, end, branches: branchId > 0 ? [branchId] : [], name: name || '' });
  };

  return <FilterDrawer handleAction={handleAction} showDates showBranches includeAllOption />;
};

export default FilterDialog;
