import React, { useEffect } from 'react';
import LabelPreviewGeneralSettingsCreatePurchaseOrderComponent from '../Label';
import useApolloLazyQuery from '../../../../../../hooks/useApolloLazyQuery';
import GQL_PROVIDER from '../../../../../../apollo/gql/provider';
var ProviderLabelPreviewGeneralSettingsCreatePurchaseOrderComponent = function (props) {
    var _a = useApolloLazyQuery(GQL_PROVIDER.GET_PROVIDER_BY_ID), getData = _a[0], data = _a[1].data;
    useEffect(function () {
        if (props.providerId)
            getData({ variables: { id: props.providerId } });
    }, [props.providerId]);
    return (React.createElement(LabelPreviewGeneralSettingsCreatePurchaseOrderComponent, { title: 'Proveedor' }, !props.providerId ? 'No asignado' : data ? data.name : 'Cargando...'));
};
export default ProviderLabelPreviewGeneralSettingsCreatePurchaseOrderComponent;
