import React from 'react';
import { Tabs, Tab } from '@mui/material';
import CategoryIcon from '@mui/icons-material/Category';
import GridViewIcon from '@mui/icons-material/GridView';
var MenuItemDetailSaleV2Component = function (props) {
    return (React.createElement(Tabs, { value: props.value, onChange: props.onChange, variant: 'scrollable', scrollButtons: 'auto', className: 'bg-slate-200 border-0 border-b border-[#d6d6d6] border-solid' },
        React.createElement(Tab, { icon: React.createElement(CategoryIcon, null), label: 'General' }),
        React.createElement(Tab, { icon: React.createElement(GridViewIcon, null), label: 'Componentes' })));
};
export default MenuItemDetailSaleV2Component;
