import { gql } from '@apollo/client';
import client from '../index';
import getSession from '../../helpers/getSession';
import setSession from '../../helpers/setSession';

const REFRESH_TOKEN = gql`
  query GenerateWsToken {
    generateWsToken
  }
`;
const getNewWsToken = async () => {
  try {
    const session = getSession();
    const token = session?.refreshToken || '';
    const { data } = await client.mutate({
      mutation: REFRESH_TOKEN,
      variables: { token },
    });
    setSession({ wsToken: data.generateWsToken });
    return data.generateWsToken;
  } catch (e) {
    console.log('Error de socket: ', e.message);
  }
};

export default getNewWsToken;
