import React, { useState, useEffect } from 'react';

import { useQuery, useReactiveVar } from '@apollo/client';

import Typography from '@mui/material/Typography';

import FilterDrawer from '../../presentationals/FilterDrawer.v2';
import { filter, searchField } from '../../apollo/cache';

import { selectpaymentFormOptions } from '../../helpers/paymentForm.options';

import Autocomplete from '../../presentationals/FormComponents/Autocomplete';
import SelectField from '../../presentationals/FormComponents/SelectField';
import GQL_PROVIDER from '../../apollo/gql/provider';

const defaultValues = {
  providerId: null,
  status: 0,
  type: 0,
  paymentForm: '',
};

const statusOptions = [
  { value: 0, label: 'Todos (excepto cancelados)' },
  { value: 1, label: 'Orden de Compra' },
  { value: 3, label: 'Compra' },
  { value: -1, label: 'Cancelado' },
];

const typeOptions = [
  { value: 0, label: 'Todos' },
  { value: 1, label: 'Contado' },
  { value: 2, label: 'Crédito' },
];

const FilterDialog = () => {
  const searchFieldVar = useReactiveVar(searchField);
  const filterVar = useReactiveVar(filter);
  const [providers, setProviders] = useState([]);
  const [values, setValues] = useState(defaultValues);

  const handleAction = (e, data) => {
    const { startDate: start, endDate: end, branchId } = data;
    const providerId = values.providerId ? values.providerId.value : null;
    const status = values.status === 0 ? null : values.status;
    const type = values.type === 0 ? null : values.type;
    const paymentForm = values.paymentForm !== '' ? values.paymentForm : null;
    filter({ start, end, branchId, providerId, status, type, paymentForm });
  };

  useQuery(GQL_PROVIDER.GET_PROVIDERS, {
    variables: { limit: 0 },
    onCompleted: data => {
      const tmp = data.providers.rows.map(el => ({ label: el.name, value: el.id }));
      setProviders(tmp);
    },
  });

  useEffect(() => {
    if (searchFieldVar && searchFieldVar.trim() !== '')
      filter({ ...filterVar, like: searchFieldVar });
  }, [searchFieldVar]);

  const handleChange = (name, value) => {
    setValues(values => ({ ...values, [name]: value }));
  };

  return (
    <FilterDrawer handleAction={handleAction} showDates showBranches>
      <Typography variant='subtitle1'>Proveedor:</Typography>
      <Autocomplete
        name='providerId'
        onChange={handleChange}
        options={providers}
        value={values.providerId}
        size='medium'
        searchField
      />
      <br />
      <Typography variant='subtitle1'>Estatus del Registro:</Typography>
      <SelectField
        name='status'
        onChange={handleChange}
        options={statusOptions}
        size='medium'
        value={values.status}
      />
      <br />
      <Typography variant='subtitle1'>Método de Pago:</Typography>
      <SelectField
        name='type'
        onChange={handleChange}
        options={typeOptions}
        size='medium'
        value={values.type}
      />
      <br />
      <Typography variant='subtitle1'>Forma de Pago:</Typography>
      <SelectField
        name='paymentForm'
        onChange={handleChange}
        options={selectpaymentFormOptions}
        size='medium'
        value={values.paymentForm}
      />
    </FilterDrawer>
  );
};

export default FilterDialog;
