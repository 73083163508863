var getMainError = function (name, errors) {
    return errors
        ? name
            .split('.')
            .reduce(function (obj, name) {
            return obj && obj[name]
                ? obj[name].message
                    ? { type: obj[name].type || null, message: obj[name].message || '' }
                    : obj[name]
                : null;
        }, errors)
        : null;
};
export default getMainError;
