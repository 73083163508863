import React, { useEffect } from 'react';
import { ListItemButton } from '@mui/material';
import useApolloLazyQuery from '../../../../../../../../hooks/useApolloLazyQuery';

import GQL from '../../../../../../../product.v2/_gql';
import getInputErrorFormat from '../../../../../../Action/helpers/getInputErrorFormat';
import {
  isSaleProductFormOpen,
  productSaleV2,
  searchProductField,
} from '../../../../../../../../apollo/cache';

const ListItemButtonItemTableSearchFormCustomerActionSaleV2Component = props => {
  const [get, { data, loading }] = useApolloLazyQuery(GQL.GET_BY_ID);

  useEffect(() => {
    if (data && !loading) {
      productSaleV2({
        ...data,
        unitBase: data.PresentationBase.name,
        cost: data.PresentationBase ? data.PresentationBase.cost : 0,
      });
      isSaleProductFormOpen(false);
      searchProductField(data.name);
    }
  }, [loading, data]);

  const handleClick = () => {
    try {
      get({ variables: { id: props.id } });
    } catch (e) {
      getInputErrorFormat(e);
    }
  };
  return (
    <ListItemButton disabled={loading} onClick={handleClick} className={`${props.background} p-0`}>
      {props.children}
    </ListItemButton>
  );
};

export default ListItemButtonItemTableSearchFormCustomerActionSaleV2Component;
