var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { create } from 'zustand';
var defaultValues = {
    open: false,
    presentationId: null,
    currentQty: 0,
};
var useAddProductToAdjustInventoryStore = create()(function (set) { return (__assign(__assign({}, defaultValues), { setOpen: function (open, presentationId, currentQty) { return set({ open: open, presentationId: presentationId, currentQty: currentQty }); }, setClose: function () { return set(defaultValues); } })); });
export default useAddProductToAdjustInventoryStore;
