var getPurchaseOrderDescriptionLabel = function (action) {
    switch (action) {
        case 1:
            return 'Se creará un registro de la orden de compra el cual permitirá dar seguimiento a la transacción con tu proveedor. Una vez realizada la transacción se podrá cerrar la orden de compra para dar acceso de la mercancía al inventario.';
        case 2:
        default:
            return 'Se añadirá la mercancía al inventario de la sucursal seleccionada, además, en caso de no liquidar la compra, se podrá llegar un seguimiento en cuentas por cobrar.';
    }
};
export default getPurchaseOrderDescriptionLabel;
