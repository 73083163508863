import React, { useEffect, useState } from 'react';
import ClassificationInventoryReportComponent from './Classification';
import useClassifications from '../../../hooks/useClassifications';
import LoadingProgress from '../../../presentationals/LoadingProgress';
import ErrorDataComponent from '../../../presentationals/ErrorData';
import useInventoryReportStore from '../../../store/inventoryReport';
import DraggableItemComponent from '../../../presentationals/DraggableItem';
import DragDropContainerComponent from '../../../presentationals/DragDropContainer';
import reorderDragAndDropList from '../../../helpers/dragAndDropReorder';
import getSortedClassificationInventories from './helpers/getSortedClassifications';
import setSortedClassificationInventories from './helpers/setSortedClassifications';
var BodyInventoryReportComponent = function (props) {
    var _a = useClassifications(), data = _a.data, loading = _a.loading;
    var _b = useState([]), Classifications = _b[0], setClassifications = _b[1];
    useEffect(function () {
        if (data && data.rows.length > 0)
            setClassifications(getSortedClassificationInventories(data.rows));
    }, [data]);
    var branchId = useInventoryReportStore(function (state) { return state.branchId; });
    var handleDragEnd = function (result) {
        if (!result.destination)
            return;
        var reorderedClassifications = reorderDragAndDropList(Classifications, result.source.index, result.destination.index);
        setSortedClassificationInventories(reorderedClassifications);
        setClassifications(reorderedClassifications);
    };
    if (loading)
        return React.createElement(LoadingProgress, null);
    if (!data)
        return React.createElement(ErrorDataComponent, null);
    if (!branchId)
        return React.createElement("div", null);
    return (React.createElement("div", { className: 'flex flex-1 flex-col overflow-auto basis-0 my-2 p-1' },
        React.createElement(DragDropContainerComponent, { id: 'classificationDragDropContainer', onDranEnd: handleDragEnd }, Classifications.map(function (el, index) { return (React.createElement(DraggableItemComponent, { key: el.id, id: el.id.toString(), index: index },
            React.createElement(ClassificationInventoryReportComponent, { branchId: branchId, Classification: el, onShowProductHistoryClick: props.onShowProductHistoryClick }))); }))));
};
export default BodyInventoryReportComponent;
