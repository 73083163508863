import React from 'react';
import { useReactiveVar } from '@apollo/client';
import { isChangeProfilePictureOpen } from '../../../apollo/cache';
import DialogComponent from '../../../presentationals/Dialog';
import UnderConstructionAlertComponent from '../../../presentationals/UnderConstructionAlert';
var ChangeProfilePictureDialogComponent = function () {
    var isOpen = useReactiveVar(isChangeProfilePictureOpen);
    var handleClose = function () {
        isChangeProfilePictureOpen(false);
    };
    return (React.createElement(DialogComponent, { title: 'Cambiar foto de perfil', fullScreen: true, open: isOpen, onClose: handleClose },
        React.createElement(UnderConstructionAlertComponent, null)));
};
export default ChangeProfilePictureDialogComponent;
