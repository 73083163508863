var getBranchTypeById = function (id) {
    switch (id) {
        case 1:
            return 'Punto de Venta';
        case 2:
            return 'Almacen';
        case 3:
            return 'Franquicia';
        default:
            return 'Indefinido';
    }
};
export default getBranchTypeById;
