import React from 'react';
import TextFieldComponent from '../../../../../../../../presentationals/FormComponents/TextField';
var ItemColorFieldEcommerceEditFormComponent = function (props) {
    var handleChange = function (name, value) {
        props.id && props.onChange(props.id, value);
    };
    return (React.createElement("div", { className: props.attribute ? 'flex border border-solid rounded p-2 pt-1' : 'flex', style: { borderColor: props.attribute || 'transparent' } },
        React.createElement(TextFieldComponent, { name: 'hexa', label: 'C\u00F3digo hexadecimal', placeholder: '#ffffff', value: props.value, onChange: handleChange })));
};
export default ItemColorFieldEcommerceEditFormComponent;
