import React, { useRef } from 'react';
import FooterDialogComponent from '../../../presentationals/Dialog/Footer';
import BodyCreateBranchComponent from './Body';
import CreateButtonFooterCreateBranchComponent from './Footer/CreateButton';
import ClearButtonFooterCreateBranchComponent from './Footer/ClearButton';
var CreateBranchComponent = function () {
    var bodyCreateBranchRef = useRef(null);
    var createButtonFooterCreateBranchRef = useRef(null);
    var handleCreate = function () {
        var _a;
        (_a = createButtonFooterCreateBranchRef.current) === null || _a === void 0 ? void 0 : _a.onPressEnter();
    };
    var handleGetValues = function () {
        var _a;
        return (_a = bodyCreateBranchRef.current) === null || _a === void 0 ? void 0 : _a.getValues();
    };
    var handleClear = function () {
        var _a;
        (_a = bodyCreateBranchRef.current) === null || _a === void 0 ? void 0 : _a.onClear();
    };
    return (React.createElement("div", { className: 'flex flex-1 flex-col' },
        React.createElement(BodyCreateBranchComponent, { ref: bodyCreateBranchRef, onPressEnter: handleCreate }),
        React.createElement(FooterDialogComponent, null,
            React.createElement("div", null),
            React.createElement("div", null,
                React.createElement(ClearButtonFooterCreateBranchComponent, { onClick: handleClear }),
                React.createElement(CreateButtonFooterCreateBranchComponent, { getValues: handleGetValues, ref: createButtonFooterCreateBranchRef })))));
};
export default CreateBranchComponent;
