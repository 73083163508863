var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useRef } from 'react';
import QuantityBarcodeLabelInputComponent from './Input';
import PrintBarcodeLabelItemButtonComponent from './PrintButton';
var BarcodeLabelItemComponent = function (props) {
    var quantityBarcodeLabelInputRef = useRef(null);
    var handleGetValues = function () {
        var _a;
        var quantity = (_a = quantityBarcodeLabelInputRef.current) === null || _a === void 0 ? void 0 : _a.getValue();
        return __assign(__assign({}, props), { quantity: quantity ? parseFloat(quantity) : 1 });
    };
    return (React.createElement("div", { className: 'flex rounded border border-solid border-slate-200 p-3 flex-col' },
        React.createElement("div", { className: 'text-base font-medium' }, props.Product.name),
        React.createElement("div", { className: 'text-base' }, props.ProductPresentation.name),
        React.createElement("div", { className: 'flex flex-row items-end' },
            React.createElement(QuantityBarcodeLabelInputComponent, { ref: quantityBarcodeLabelInputRef, quantity: props.quantity }),
            React.createElement("div", { className: 'pl-2' },
                React.createElement(PrintBarcodeLabelItemButtonComponent, { getValues: handleGetValues })))));
};
export default BarcodeLabelItemComponent;
