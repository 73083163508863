var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React from 'react';
import { useParams } from 'react-router-dom';
import ButtonFooterDialogComponent from '../../../../../presentationals/Dialog/Footer/Button';
import getInputErrorFormat from '../../../../sale.v2/Action/helpers/getInputErrorFormat';
import { ingredientProductForm, isCreateProductVariationOpen, productVariationForm, } from '../../../../../apollo/cache';
import setInputErrorFormat from '../../../../sale.v2/Action/helpers/setInputErrorFormat';
import getDefaultProductVariationValues from '../../Body/helpers/getDefaultProductVariationValues';
import useApolloMutation from '../../../../../hooks/useApolloMutation';
import GQL_PRODUCT from '../../../../../apollo/gql/product';
var CreateProductVariationButtonComponent = function () {
    var params = useParams();
    var _a = useApolloMutation(GQL_PRODUCT.CREATE_PRODUCT_VARIATION, {
        parent: [params.productPresentationId, 'ProductPresentation'],
        fragment: GQL_PRODUCT.FRAGMENT_PRODUCT_VARIATION,
        fragmentName: 'FragmentProductVariation',
        name: 'ProductVariation',
    }), create = _a[0], loading = _a[1].loading;
    var handleClick = function () { return __awaiter(void 0, void 0, void 0, function () {
        var values, data, ingredientValues, defaultValues, error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    values = productVariationForm();
                    if (values.name === '')
                        throw new Error(setInputErrorFormat('name', 'Proporciona el nombre de tipo de componente.'));
                    if (parseFloat(values.limitVariation) < 0)
                        throw new Error(setInputErrorFormat('limitVariation', 'El límite debe ser mayor o igual a cero.'));
                    return [4 /*yield*/, create({
                            variables: {
                                name: values.name,
                                productPresentationId: params.productPresentationId,
                                limitVariation: parseInt(values.limitVariation) || 0,
                                isRequired: values.isRequired,
                                selectionType: values.selectionType,
                            },
                        })];
                case 1:
                    data = (_a.sent()).data;
                    ingredientValues = ingredientProductForm();
                    ingredientProductForm(__assign(__assign({}, ingredientValues), { type: data.createProductVariation.id }));
                    defaultValues = getDefaultProductVariationValues();
                    productVariationForm(defaultValues);
                    isCreateProductVariationOpen(false);
                    return [3 /*break*/, 3];
                case 2:
                    error_1 = _a.sent();
                    getInputErrorFormat(error_1);
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    return (React.createElement(ButtonFooterDialogComponent, { disabled: loading, onClick: handleClick }, "Almacenar"));
};
export default CreateProductVariationButtonComponent;
