import React, { useState } from 'react';
import { useMutation, useReactiveVar } from '@apollo/client';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import FormDialog from '../../../../../presentationals/FormDialog';
import TextField from '../../../../../presentationals/TextField';
import Snackbar from '../../../../../presentationals/Snackbar';

import { form, handleContinueConfiguration, step } from '../../../../../apollo/cache';

import { parseError } from '../../../../../helpers';

import GQL from '../../../_gql';
import useSnackbarDialog from '../../../../../store/snackbar/snackbarDialog';

const defaultValues = {
  userPassword: '',
};
const defaultSnackbar = { isOpen: true, time: 3000 };

const Component = ({ id, products, ...props }) => {
  const { setSnackbar } = useSnackbarDialog();
  const formVar = useReactiveVar(form);
  const [values, setValues] = useState(defaultValues);

  const [handleCreditNote, { loading }] = useMutation(
    GQL.CREATE_CREDIT_NOTE_PRODUCT_PURCHASE_ORDER_V2,
  );

  const resetValues = () => {
    setValues(defaultValues);
  };

  const handleAction = async () => {
    try {
      if (products.length <= 0) {
        const e = new Error('Seleccione al menos un producto.');
        e.fields = true;
        throw e;
      }
      await handleCreditNote({
        variables: {
          id,
          userPassword: values.userPassword,
          subtotal: parseFloat(formVar.subtotal),
          discount: parseFloat(formVar.discount) || 0,
          iva: parseFloat(formVar.iva),
          ieps: parseFloat(formVar.ieps),
          total: parseFloat(formVar.total),
          date: formVar.date,
          folio: formVar.folio,
          paymentMethodId: parseInt(formVar.paymentMethodId) === 0 ? null : formVar.paymentMethodId,
          Products: products,
        },
      });
      resetValues();
      setSnackbar({
        ...defaultSnackbar,
        label: 'La nota de crédito fue creada con éxito.',
        severity: 'success',
      });
      props.handleClose();
      props.handleCloseAll();
      step(0);
      form({});
      handleContinueConfiguration({});
    } catch (e) {
      if (e.fields) {
        setSnackbar({ ...defaultSnackbar, label: e.message, severity: 'error' });
        return;
      }
      const parseErrors = parseError(e);
      parseErrors.forEach(el => {
        console.log(el);
        if (el.name === 'BAD_USER_INPUT') {
          const key = Object.keys(el.message)[0];
          setSnackbar({ ...defaultSnackbar, label: el.message[key], severity: 'error' });
        } else {
          setSnackbar({ ...defaultSnackbar, label: el.message, severity: 'error' });
        }
      });
    }
  };

  const handleChange = e => {
    const { name, value } = e.target;
    setValues(values => ({ ...values, [name]: value }));
  };

  return (
    <>
      <FormDialog
        title='Confirmar'
        isOpen={props.isOpen}
        isLoading={loading}
        handleAction={handleAction}
        handleClose={props.handleClose}
      >
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Typography variant='body2' gutterBottom>
              Estás a punto de devolver productos de la orden de compra, se creara un abono en la
              CXP con el valor de la nota de credito al realizar esta acción. Se require contraseña.
            </Typography>
          </Grid>
          <br />
          <Grid item xs={12}>
            <TextField
              label='Contraseña'
              name='userPassword'
              value={values.userPassword}
              onChange={handleChange}
              variant='outlined'
              noFormat
              type='password'
            />
          </Grid>
        </Grid>
      </FormDialog>
      <Snackbar horizontal='center' />
    </>
  );
};

export default Component;
