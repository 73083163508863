var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { useReactiveVar } from '@apollo/client';
import React, { useState } from 'react';
import { Button } from '@mui/material';
import TextFieldComponent from '../../../presentationals/FormComponents/TextField';
import { inputError } from '../../../apollo/cache';
var DownloadOnlineInvoiceComponent = function () {
    var errors = useReactiveVar(inputError);
    var defaultValues = {
        code: '',
        rfc: '',
    };
    var _a = useState(defaultValues), values = _a[0], setValues = _a[1];
    var handleChange = function (name, value) {
        setValues(function (values) {
            var _a;
            return (__assign(__assign({}, values), (_a = {}, _a[name] = value, _a)));
        });
    };
    return (React.createElement("div", { className: 'grid grid-cols-1 sm:grid-cols-4 flex-1 basis-0 overflow-auto' },
        React.createElement("div", { className: 'text-lg' }, "Facturaci\u00F3n electr\u00F3nica"),
        React.createElement("div", { className: 'text-base text-gray-600' }, "Descarga una factura electr\u00F3nica previamente generada a partir del c\u00F3digo de facturaci\u00F3n y RFC."),
        React.createElement(TextFieldComponent, { label: 'C\u00F3digo de facturaci\u00F3n', name: 'code', value: values.code, onChange: handleChange, error: errors.code }),
        React.createElement(TextFieldComponent, { label: 'RFC', name: 'rfc', value: values.rfc, onChange: handleChange, error: errors.rfc }),
        React.createElement("div", { className: 'flex' },
            React.createElement(Button, { className: 'mt-3', variant: 'contained' }, "Buscar factura electr\u00F3nica"))));
};
export default DownloadOnlineInvoiceComponent;
