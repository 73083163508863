import React from 'react';

import { useMutation } from '@apollo/client';

import LinearProgress from '@mui/material/LinearProgress';
import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';

import Snackbar from '../../presentationals/Snackbar';

import { parseError } from '../../helpers';

import GQL from './_gql';

const Component = props => {
  const { setSnackbar } = useSnackbarDialog();
  const [liquidateReceivable, { loading }] = useMutation(GQL.LIQUIDATE_RECEIVABLE);

  const handleAction = async () => {
    try {
      await liquidateReceivable({ variables: { id: props.id } });
      props.handleClose();
    } catch (e) {
      const parseErrors = parseError(e);
      parseErrors.forEach(el => {
        setSnackbar({
          variables: {
            isOpen: true,
            time: 3000,
            label: el.message,
            severity: 'error',
          },
        });
      });
    }
  };

  return (
    <>
      <Dialog
        open={props.isOpen || false}
        fullWidth={true}
        maxWidth='xs'
        aria-labelledby='Confirmar'
        aria-describedby='Dialogo de confirmación para validar un registro.'
      >
        {loading && <LinearProgress color='secondary' />}
        <DialogTitle>¿Estás seguro que deseas continuar?</DialogTitle>
        <DialogContent>Se liquidará la cuenta si abonos.</DialogContent>
        <DialogActions>
          <Button onClick={props.handleClose} color='primary'>
            Cancelar
          </Button>
          <Button onClick={handleAction} color='primary' autoFocus>
            Si, estoy seguro.
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar horizontal='center' />
    </>
  );
};

export default Component;
