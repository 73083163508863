var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { create } from 'zustand';
import getDefaultBranchId from '../../helpers/getDefaultBranchId';
var defaultValues = {
    branchId: getDefaultBranchId(),
};
var useInventoryReportStore = create()(function (set) { return (__assign(__assign({}, defaultValues), { setBranchId: function (branchId) { return set({ branchId: branchId || getDefaultBranchId() }); } })); });
export default useInventoryReportStore;
