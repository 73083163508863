import React from 'react';

import CloseIcon from '@mui/icons-material/Close';
import { IconButton } from '@mui/material';

import { isSatUnitKeyDialogOpen } from '../../../../../../../../../../../apollo/cache';

const CloseButtonHeaderSearchDialogUnitKeyFieldHelpersBodyCreateProductDialogProductFormChooseProductSaleV2Component = () => {
    const handleClose = () => {
      isSatUnitKeyDialogOpen(false);
    };
    return (
      <IconButton onClick={handleClose}>
        <CloseIcon />
      </IconButton>
    );
  };

export default CloseButtonHeaderSearchDialogUnitKeyFieldHelpersBodyCreateProductDialogProductFormChooseProductSaleV2Component;
