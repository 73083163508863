import React from 'react';
import { useReactiveVar } from '@apollo/client';
import SelectFieldComponent from '../../../../../presentationals/FormComponents/SelectField';
import { inputError } from '../../../../../apollo/cache';
import GQL from '../../../../branch/_gql';
import useApolloQuery from '../../../../../hooks/useApolloQuery';
var BranchFieldComponent = function (props) {
    var errors = useReactiveVar(inputError);
    var _a = useApolloQuery(GQL.GET), data = _a.data, loading = _a.loading;
    var handleChange = function (name, value) {
        props.onChange(name, value);
    };
    return (React.createElement(SelectFieldComponent, { name: 'branchId', disabled: loading || !data, label: 'Sucursal predeterminada', value: (data === null || data === void 0 ? void 0 : data.rows) ? props.value : '', error: errors.branchId, onChange: handleChange, options: (data === null || data === void 0 ? void 0 : data.rows) ? data.rows.map(function (el) { return ({ value: el.id, label: el.name }); }) : [] }));
};
export default BranchFieldComponent;
