import React, { useEffect, useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { useParams } from 'react-router-dom';
import { format } from 'date-fns';
import GQL_SALE from '../../../../apollo/gql/sale';
import useApolloQuery from '../../../../hooks/useApolloQuery';
import LoadingProgress from '../../../../presentationals/LoadingProgress';
import OpenAddExpenseButtonComponent from './OpenAddExpenseButton';
import AddExpenseComponent from './AddExpense';
import BodyTableRowSaleExpenseComponent from './BodyTableRow';
import { currencyFormat } from '../../../../helpers';
import EditExpenseComponent from './EditExpense';
var ExpensesServiceOrderComponent = function () {
    var params = useParams();
    var _a = useState([0, 0, 0]), totals = _a[0], setTotals = _a[1];
    var _b = useApolloQuery(GQL_SALE.GET_SALE_EXPENSES, {
        variables: { saleId: params.saleId },
    }), data = _b.data, loading = _b.loading;
    useEffect(function () {
        if (data) {
            var totals_1 = data.rows.reduce(function (totals, el) { return [el.subtotal + totals[0], el.taxes + totals[1], el.total + totals[2]]; }, [0, 0, 0]);
            setTotals(totals_1);
        }
    }, [data]);
    if (loading || !data)
        return React.createElement(LoadingProgress, null);
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: 'flex flex-1 flex-col p-3' },
            React.createElement("div", { className: 'flex items-center' },
                React.createElement("div", { className: 'text-base font-medium mb-1 flex flex-1' }, "Gastos de la orden de servicio"),
                React.createElement(OpenAddExpenseButtonComponent, null)),
            React.createElement("div", { className: 'text-sm sm:text-base text-gray-600' }, "Esta funcionalidad est\u00E1 dise\u00F1ada para brindar un control total sobre los costos asociados a la orden de servicio. A trav\u00E9s de esta secci\u00F3n, los usuarios podr\u00E1n a\u00F1adir y gestionar de manera eficiente todos los gastos."),
            React.createElement("div", { className: 'rounded mt-3 overflow-hidden flex flex-1' }, data.rows.length === 0 ? (React.createElement("div", null, "De momento no cuentas con gastos a\u00F1adidos a esta orden de servicio")) : (React.createElement(TableContainer, null,
                React.createElement(Table, { stickyHeader: true, size: 'small' },
                    React.createElement(TableHead, null,
                        React.createElement(TableRow, null,
                            React.createElement(TableCell, null, "Creado el"),
                            React.createElement(TableCell, null, "Fecha de salida"),
                            React.createElement(TableCell, null, "Nombre"),
                            React.createElement(TableCell, { className: 'bg-slate-400 text-center' }, "Cant."),
                            React.createElement(TableCell, { className: 'whitespace-nowrap' }, "P. unitario"),
                            React.createElement(TableCell, null, "Impuestos"),
                            React.createElement(TableCell, null, "Subtotal"),
                            React.createElement(TableCell, null, "Importe"))),
                    React.createElement(TableBody, null,
                        data.rows.map(function (el, i) { return (React.createElement(BodyTableRowSaleExpenseComponent, { key: el.id, id: el.id, index: i },
                            React.createElement(TableCell, null, format(new Date(el.createdAt), 'dd/MM/yyyy HH:mm')),
                            React.createElement(TableCell, null, el.outDate ? format(new Date(el.outDate), 'dd/MM/yyyy') : '-'),
                            React.createElement(TableCell, null, el.name),
                            React.createElement(TableCell, { className: 'bg-slate-400 text-center' }, currencyFormat(el.quantity)),
                            React.createElement(TableCell, null, currencyFormat(el.unitPrice)),
                            React.createElement(TableCell, null, currencyFormat(el.taxes)),
                            React.createElement(TableCell, null, currencyFormat(el.subtotal)),
                            React.createElement(TableCell, null, currencyFormat(el.total)))); }),
                        React.createElement(TableRow, null,
                            React.createElement(TableCell, { className: 'text-right', colSpan: 7 }, "Subtotal"),
                            React.createElement(TableCell, { className: 'bg-slate-300' }, currencyFormat(totals[0]))),
                        React.createElement(TableRow, null,
                            React.createElement(TableCell, { className: 'text-right', colSpan: 7 }, "Impuestos"),
                            React.createElement(TableCell, { className: 'bg-slate-300' }, currencyFormat(totals[1]))),
                        React.createElement(TableRow, null,
                            React.createElement(TableCell, { className: 'text-right', colSpan: 7 }, "Total"),
                            React.createElement(TableCell, { className: 'bg-slate-300 font-medium' }, currencyFormat(totals[2]))))))))),
        React.createElement(AddExpenseComponent, null),
        React.createElement(EditExpenseComponent, null)));
};
export default ExpensesServiceOrderComponent;
