import React from 'react';
import { ListItem, ListItemButton, ListItemText } from '@mui/material';
import getInputErrorFormat from '../../../../../../containers/sale.v2/Action/helpers/getInputErrorFormat';
var ItemSearchSatUnitKeyTableComponent = function (props) {
    var handleClick = function () {
        try {
            props.onClick(props.id.toString());
        }
        catch (e) {
            getInputErrorFormat(e);
        }
    };
    return (React.createElement(ListItemButton, { onClick: handleClick, className: "".concat(props.background, " p-0") },
        React.createElement(ListItem, null,
            React.createElement(ListItemText, { primary: "".concat(props.id, " ").concat(props.label) }))));
};
export default ItemSearchSatUnitKeyTableComponent;
