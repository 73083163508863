import React from 'react';
import { IconButton } from '@mui/material';
import PhotoCamera from '@mui/icons-material/PhotoCameraOutlined';
import { isChangeProfilePictureOpen } from '../../../../../apollo/cache';
var ChangeProfileButtonComponent = function () {
    var handleChange = function () {
        isChangeProfilePictureOpen(true);
    };
    return (React.createElement(IconButton, { "aria-label": 'Choose profile', component: 'span', onClick: handleChange, className: 'p-2 bg-white border border-solid border-[#e1e1e1] hover:text-sky-600 hover:bg-white shadow-[0_0_0_3px_rgba(255,255,255,1)]', size: 'large' },
        React.createElement(PhotoCamera, { fontSize: 'small' })));
};
export default ChangeProfileButtonComponent;
