var getSaleRotationMenuValueFromPath = function (pathname) {
    var pathnameParts = pathname.split('/');
    var path = pathnameParts[pathnameParts.length - 1];
    switch (path) {
        case 'product':
            return 0;
        case 'presentation':
            return 1;
        case 'ingredient':
            return 2;
        case 'promotion':
            return 3;
        case 'productAndIngredient':
            return 4;
        default:
            return 0;
    }
};
export default getSaleRotationMenuValueFromPath;
