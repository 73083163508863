import React, { useEffect, useState } from 'react';
import { Tabs, Tab } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import CategoryOutlinedIcon from '@mui/icons-material/CategoryOutlined';
import GridViewOutlinedIcon from '@mui/icons-material/GridViewOutlined';
import InsertPhotoOutlinedIcon from '@mui/icons-material/InsertPhotoOutlined';
import SpeakerNotesOutlinedIcon from '@mui/icons-material/SpeakerNotesOutlined';
import StorefrontIcon from '@mui/icons-material/Storefront';
import QrCodeOutlinedIcon from '@mui/icons-material/QrCodeOutlined';
import getMenuValueFromPath from './getMenuValueFromPath';
import getRouteFromMenuValue from './getRouteFromMenuValue';
var MenuProductLayoutComponent = function () {
    var navigate = useNavigate();
    var location = useLocation();
    var _a = useState(0), value = _a[0], setValue = _a[1];
    useEffect(function () {
        var value = getMenuValueFromPath(location.pathname);
        setValue(value);
    }, [location.pathname]);
    var handleChange = function (e, value) {
        var route = getRouteFromMenuValue(value);
        if (route)
            navigate(route);
    };
    return (React.createElement(Tabs, { value: value, onChange: handleChange, variant: 'scrollable', scrollButtons: 'auto', className: 'flex bg-slate-100 border-0 border-y border-[#d6d6d6] border-solid' },
        React.createElement(Tab, { icon: React.createElement(CategoryOutlinedIcon, null), label: 'Datos generales' }),
        React.createElement(Tab, { icon: React.createElement(GridViewOutlinedIcon, null), label: 'Estructura' }),
        React.createElement(Tab, { icon: React.createElement(InsertPhotoOutlinedIcon, null), label: 'Imagen' }),
        React.createElement(Tab, { icon: React.createElement(SpeakerNotesOutlinedIcon, null), label: 'Comentarios' }),
        React.createElement(Tab, { icon: React.createElement(QrCodeOutlinedIcon, null), label: 'Etiquetas' }),
        React.createElement(Tab, { icon: React.createElement(StorefrontIcon, null), label: 'Ecommerce' })));
};
export default MenuProductLayoutComponent;
