import React, { useState } from 'react';
import { useMutation } from '@apollo/client';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import FormDialog from '../../presentationals/FormDialog';
import TextField from '../../presentationals/TextField';
import SelectField from '../../presentationals/SelectField';
import Snackbar from '../../presentationals/Snackbar';

import { parseError } from '../../helpers';

import GQL from './_gql';
import useSnackbarDialog from '../../store/snackbar/snackbarDialog';

const defaultValues = {
  userPassword: '',
  reason: '01',
  relatedFolio: '',
  noReturnInventory: false,
  reasonCancel: '',
  commentary: '',
};

const reasonCancelOptions = [
  { value: 1, label: 'PRECIO' },
  { value: 2, label: 'CRÉDITO' },
  { value: 3, label: 'CALIDAD DEL PROYECTO' },
  { value: 9, label: 'TRATO AL CLIENTE' },
  { value: 4, label: 'TIEMPO DE ENTREGA DEL PROYECTO' },
  { value: 5, label: 'SITUACIÓN GEOGRAFICA' },
  { value: 6, label: 'MAL ENFOQUE DEL PROYECTO' },
  { value: 7, label: 'OTROS' },
  { value: 8, label: 'RAZÓN NO CLASIFICADO' },
];

const reasonOptions = [
  { label: '01 Comprobante emitido con errores con relación', value: '01' },
  { label: '02 Comprobante emitido con errores sin relación', value: '02' },
  { label: '03 No se llevó a cabo la operación', value: '03' },
  { label: '04 Operación nominativa relacionada en la factura global', value: '04' },
];

// const noReturnInventoryOptions = [
//   { label: 'Si', value: false },
//   { label: 'No', value: true },
// ];

const defaultSnackbar = { isOpen: true, time: 3000 };

const PettyCash = props => {
  const { setSnackbar } = useSnackbarDialog();

  const [values, setValues] = useState(defaultValues);
  const [error, setError] = useState({});

  const [handleCancelMaintenance, { loading }] = useMutation(GQL.CANCEL_MAINTENANCE);

  const resetValues = () => {
    setValues(defaultValues);
  };

  const handleAction = async () => {
    try {
      setError({});
      let newError = null;
      if (values.reason === '01' && props.invoiceId && values.relatedFolio === '') {
        newError = new Error('Proporciona el UUID a relacionar.');
        newError.name = 'relatedFolio';
        throw newError;
      }
      if (values.reasonCancel === '') {
        newError = new Error('Proporciona una razón por lo caul la venta no se logro.');
        newError.name = 'reasonCancel';
        throw newError;
      }
      if (values.userPassword === '') {
        newError = new Error('Proporciona una contraseña con privilegios.');
        newError.name = 'userPassword';
        throw newError;
      }
      const relatedFolio = values.reason === '01' ? { relatedFolio: values.relatedFolio } : {};
      const invoiceData = props.invoiceId ? { reason: values.reason, ...relatedFolio } : {};
      await handleCancelMaintenance({
        variables: {
          id: props.id,
          userPassword: values.userPassword,
          noReturnInventory: values.noReturnInventory,
          reasonCancel: parseInt(values.reasonCancel),
          commentary: values.commentary,
          ...invoiceData,
        },
      });
      resetValues();
      setSnackbar({
        ...defaultSnackbar,
        label: 'El mantenimiento fue cancelado con éxito.',
        severity: 'success',
      });
      props.handleClose();
    } catch (e) {
      if (['relatedFolio', 'userPassword'].includes(e.name)) {
        setError({ [e.name]: e.message });
        return;
      }
      const parseErrors = parseError(e);
      parseErrors.forEach(el => {
        if (el.name === 'BAD_USER_INPUT') {
          const key = Object.keys(el.message)[0];
          setSnackbar({ ...defaultSnackbar, label: el.message[key], severity: 'error' });
        } else {
          setSnackbar({ ...defaultSnackbar, label: el.message, severity: 'error' });
        }
      });
    }
  };

  const handleChange = e => {
    const { name } = e.target;
    const { value } = e.target;
    setValues(values => ({ ...values, [name]: value }));
  };

  return (
    <>
      <FormDialog
        title='Cancelar Venta'
        isOpen={props.isOpen}
        isLoading={loading}
        handleAction={handleAction}
        handleClose={props.handleClose}
      >
        <Grid container>
          <Grid item xs={12}>
            <Typography variant='body2' gutterBottom>
              Estás a punto de cancelar un mantenimiento y respectiva venta. Solicitamos una
              contraseña con privilegios para realizar esta acción.
            </Typography>
          </Grid>
          {/* <Grid item xs={12}>
            <SelectField
              label='¿Deseas regresar los productos a su inventario?'
              name='noReturnInventory'
              value={values.noReturnInventory}
              onChange={handleChange}
              options={noReturnInventoryOptions}
              noFormat
            />
          </Grid> */}
          {props.invoiceId && (
            <Grid item xs={12}>
              <SelectField
                label='Motivo de cancelación'
                name='reason'
                value={values.reason}
                onChange={handleChange}
                options={reasonOptions}
                noFormat
              />
            </Grid>
          )}
          {props.invoiceId && values.reason === '01' && (
            <Grid item xs={12}>
              <TextField
                label='Folio relacionado'
                name='relatedFolio'
                value={values.relatedFolio}
                error={error.relatedFolio}
                onChange={handleChange}
                noFormat
              />
            </Grid>
          )}
          <Grid item xs={12}>
            <SelectField
              label='¿Razón de la venta no lograda?'
              name='reasonCancel'
              value={values.reasonCancel}
              error={error.reasonCancel}
              options={reasonCancelOptions}
              onChange={handleChange}
              noFormat
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label='Comentarios'
              name='commentary'
              value={values.commentary}
              error={error.commentary}
              onChange={handleChange}
              noFormat
              multiline
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label='Contraseña'
              name='userPassword'
              value={values.userPassword}
              onChange={handleChange}
              error={error.userPassword}
              noFormat
              type='password'
            />
          </Grid>
        </Grid>
      </FormDialog>
      <Snackbar horizontal='center' />
    </>
  );
};

export default PettyCash;
