import { gql } from '@apollo/client';

const CHECK_CART = gql`
  mutation TotalizeCart(
    $discountType: Int
    $shipping: Float
    $currencyKey: String
    $exchangeRate: Float
    $branchInventoryId: ID
    $branchSaleId: ID
    $taxesOption: Int
    $isInvoice: Boolean
    $showTaxes: Boolean
    $category: Int!
    $isQuote: Boolean
    $Products: [SaleProductData!]!
    $Transactions: [TransactionSaleData]!
  ) {
    totalizeCart(
      input: {
        discountType: $discountType
        shipping: $shipping
        taxesOption: $taxesOption
        currencyKey: $currencyKey
        exchangeRate: $exchangeRate
        branchInventoryId: $branchInventoryId
        branchSaleId: $branchSaleId
        isInvoice: $isInvoice
        category: $category
        Products: $Products
        Transactions: $Transactions
        showTaxes: $showTaxes
        type: 1
      }
      isQuote: $isQuote
    ) {
      discount
      exchange
      shipping
      subtotal
      taxes
      taxesIeps
      taxesIepsUnit
      taxesIva
      total
      usdTotal
      Products {
        currency
        discount
        discountRate
        employeeId
        exchangeRate
        ieps
        stock
        iva
        productId
        productPromotionId
        promotionId
        quantity
        unitPrice
        withoutTaxes
        commentary
        total
        serialNumber
        subtotal
        taxes
        Product {
          name
          sku
          productKey
          unitKey
          type
        }
      }
    }
  }
`;

export default {
  CHECK_CART,
};
