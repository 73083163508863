var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { filter } from '../../../apollo/cache';
import getSession from '../../../helpers/getSession';
var setProductTypeFilterOption = function (value) {
    var _a;
    var session = getSession();
    var productType;
    switch (value) {
        case 0:
            productType = 1;
            break;
        case 1:
            productType = 2;
            break;
        case 2:
            productType = 5;
            break;
        case 3:
            productType = 6;
            break;
        case 4:
            productType = ((_a = session === null || session === void 0 ? void 0 : session.Company) === null || _a === void 0 ? void 0 : _a.id) === 25 ? 4 : 7;
            break;
        default:
            break;
    }
    var filterVar = filter();
    if (!productType)
        return;
    filter(__assign(__assign({}, filterVar), { type: productType }));
};
export default setProductTypeFilterOption;
