import React, { useState } from 'react';
import { useReactiveVar } from '@apollo/client';
import { isItemDetailDialogOpen } from '../../../../apollo/cache';
import Dialog from '../../../../presentationals/Dialog';
import GeneralItemDetailSaleV2Component from './General';
import MenuItemDetailSaleV2Component from './Menu';
import ComponentsItemDetailSaleV2Component from './Components';
var ItemDetailDialogCartSaleV2Component = function () {
    var _a = useState(0), menuOption = _a[0], setMenuOption = _a[1];
    var isItemDetailDialogOpenVar = useReactiveVar(isItemDetailDialogOpen);
    var handleClose = function () {
        isItemDetailDialogOpen(null);
    };
    var handleMenu = function (e, value) {
        setMenuOption(value);
    };
    return (React.createElement(Dialog, { title: 'Configuraci\u00F3n del producto en carrito', onClose: handleClose, fullScreen: true, open: typeof isItemDetailDialogOpenVar === 'number' },
        React.createElement("div", { className: 'flex flex-1 flex-col' },
            React.createElement(MenuItemDetailSaleV2Component, { onChange: handleMenu, value: menuOption }),
            menuOption === 0 && React.createElement(GeneralItemDetailSaleV2Component, null),
            menuOption === 1 && React.createElement(ComponentsItemDetailSaleV2Component, null))));
};
export default ItemDetailDialogCartSaleV2Component;
