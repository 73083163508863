var getDiffQty = function (newInvQty, currentInvQty, adjustType) {
    return adjustType === 1
        ? newInvQty - currentInvQty
        : adjustType === 2
            ? newInvQty + currentInvQty
            : adjustType === 3
                ? currentInvQty - newInvQty
                : 0;
};
export default getDiffQty;
