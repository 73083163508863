var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React from 'react';
import { useMutation } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import { activityLabel } from '../../../../helpers';
import config from '../../../../config/config.json';
import defaultLogo from '../../../../assets/static/default-company-icon.png';
import getInputErrorFormat from '../../../sale.v2/Action/helpers/getInputErrorFormat';
import getSession from '../../../../helpers/getSession';
import GQL_SESSION from '../../../../apollo/gql/session';
import setSession from '../../../../helpers/setSession';
import reconnectSocket from '../../../../socket/helpers/reconnect';
var CompanyButton = function (props) {
    var navigate = useNavigate();
    var _a = useMutation(GQL_SESSION.RELOGIN), reLogin = _a[0], loading = _a[1].loading;
    var handleRedirect = function (e) { return __awaiter(void 0, void 0, void 0, function () {
        var session, data, e_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 3, , 4]);
                    e.preventDefault();
                    if (loading)
                        return [2 /*return*/, false];
                    session = getSession();
                    if (!session)
                        throw new Error('Sesión rechazada.');
                    return [4 /*yield*/, reLogin({
                            variables: { companyId: props.id, token: session === null || session === void 0 ? void 0 : session.refreshToken },
                        })];
                case 1:
                    data = (_a.sent()).data;
                    return [4 /*yield*/, setSession({
                            name: session.name,
                            email: session.email,
                            phone: session.phone,
                            accessToken: data.reLogin.accessToken,
                            refreshToken: data.reLogin.refreshToken,
                            wsToken: data.reLogin.wsToken,
                            status: 'loggedIn',
                            Company: {
                                id: data.reLogin.companyId,
                                name: data.reLogin.Branch[0].companyName,
                                activity: data.reLogin.Branch[0].activity,
                                logo: data.reLogin.Branch[0].logoFileId,
                                employeeId: data.reLogin.employeeId,
                                Role: {
                                    id: data.reLogin.Branch[0].Role.id,
                                    name: data.reLogin.Branch[0].Role.name,
                                },
                                Branch: {
                                    id: data.reLogin.Branch[0].id,
                                    name: data.reLogin.Branch[0].name,
                                },
                            },
                        })];
                case 2:
                    _a.sent();
                    reconnectSocket(data.reLogin.wsToken);
                    navigate('/panel', { replace: true });
                    return [3 /*break*/, 4];
                case 3:
                    e_1 = _a.sent();
                    getInputErrorFormat(e_1);
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    }); };
    return (React.createElement("div", { className: 'flex h-36 overflow-hidden cursor-pointer items-center f-full md:w-1/3', role: 'button', tabIndex: 0, onClick: handleRedirect },
        React.createElement("div", null,
            React.createElement("img", { src: props.logoFileId
                    ? "".concat(config['api-file-service'].url, "/files/").concat(props.logoFileId)
                    : defaultLogo, className: 'w-36', alt: 'logo' })),
        React.createElement("div", { className: 'flex items-center pl-2' },
            React.createElement("div", null,
                React.createElement(Typography, { variant: 'h6', className: 'leading-none hover:text-[#e33371]' }, props.name),
                React.createElement(Typography, { variant: 'subtitle1', color: 'textSecondary' }, activityLabel(props.activity)),
                React.createElement(Typography, { variant: 'subtitle1', color: 'textSecondary' }, props.role)))));
};
export default CompanyButton;
