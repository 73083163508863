import React, { useState } from 'react';

import Container from '../../presentationals/Module.v2/Container';
import Snackbar from '../../presentationals/Snackbar';

import Action from './_action';
import GQL from './_gql';
import Form from './_form';
import Filter from './_filter';

const Rows = [
  { label: 'Fecha', key: 'date', format: 'dateTime' },
  { label: 'Folio', key: 'folio && serial', strcat: '-', sort: 'text' },
  { label: 'Total', key: 'total', format: 'currency' },
  { label: 'Origen', key: 'Branch name', sort: 'text' },
  { label: 'Destino', key: 'BranchTransfer name' },
  { label: 'Responsable', key: 'Employee firstName && Employee lastName' },
];
const title = 'Transferencia de mercancía interna';
const operation = ['transfers', 'transfers'];

const _permissions = localStorage.getItem('permissions')
  ? JSON.parse(localStorage.getItem('permissions'))
  : null;
const TransferPermission = _permissions && _permissions.Transfer ? _permissions.Transfer : null;

const TransferComponent = () => {
  const [isOpenForm, setIsOpenForm] = useState(false);

  const handleCreate = () => setIsOpenForm(true);
  const handleCreateClose = () => setIsOpenForm(false);

  return (
    <>
      <Container
        title={title}
        operation={operation}
        Rows={Rows}
        get={GQL.GET}
        create={TransferPermission ? TransferPermission.create : true}
        handleCustomCreate={
          !TransferPermission || (TransferPermission && TransferPermission.create)
            ? handleCreate
            : null
        }
        filter
        noDelete
        noEdit
      />
      <Action />
      <Filter />
      <Form isOpen={isOpenForm} handleClose={handleCreateClose} />
      <Snackbar />
    </>
  );
};

export default TransferComponent;
