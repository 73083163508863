import getProductTableColumnConfiguration from '../../../helpers/getProductTableColumnConfiguration';
var getProductHeaderColumns = function () {
    var defaultHeader = [
        { Header: 'Código', accessor: 'code' },
        { Header: 'SKU', accessor: 'sku' },
        { Header: 'Prefijo', accessor: 'prefix' },
        { Header: 'Nombre', accessor: 'name' },
        { Header: 'Stock', accessor: 'stock' },
        { Header: 'Precio', accessor: 'price' },
        { Header: 'Costo', accessor: 'cost' },
        { Header: 'Clasificación', accessor: 'classification' },
        { Header: 'Presentación base', accessor: 'presentation' },
        { Header: 'Código de barras', accessor: 'barcode' },
        { Header: 'Clave SAT', accessor: 'productKey' },
        { Header: 'Unidad SAT', accessor: 'unitKey' },
        { Header: 'IVA', accessor: 'iva' },
        { Header: 'IEPS', accessor: 'ieps' },
    ];
    var settings = getProductTableColumnConfiguration();
    var allowedColumns = Object.keys(settings).filter(function (key) { return settings[key]; });
    var columns = defaultHeader.filter(function (el) { return allowedColumns.includes(el.accessor); });
    return columns;
};
export default getProductHeaderColumns;
