var getSaleMenuValueFromPath = function (pathname) {
    var pathnameParts = pathname.split('/');
    var path = pathnameParts[pathnameParts.length - 1];
    switch (path) {
        case 'general':
            return 0;
        case 'products':
            return 1;
        case 'invoice':
            return 2;
        case 'cancel':
            return 3;
        default:
            return 0;
    }
};
export default getSaleMenuValueFromPath;
