import { gql } from '@apollo/client';

const UPDATE_STATUS_WITH_PHONE = gql`
	mutation UpdateStatusCalendarWithPhone(
		$id: ID!,
		$status: Int!
	){
		updateStatusCalendarWithPhone(
			id: $id,
			status: $status
		) {
			id
			Customer {
				phone
				firstName
				lastName
			}
			Tag {
				name
				color
			}
			start
			end
			checkIn
			checkOut
		}
	}
`;

export default { UPDATE_STATUS_WITH_PHONE };
