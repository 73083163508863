var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useEffect, useMemo, useState } from 'react';
import { InputAdornment, Typography } from '@mui/material';
import getDefaultProportionTextValues from './helpers/getDefaultValues';
import OutlinedFieldComponent from '../../../../../../../presentationals/FormComponents/OutlinedField';
import EditButtonProportionFieldEcommerceEditFormComponent from './EditButton';
var ProportionFieldEcommerceEditFormComponent = function (props) {
    var defaultValues = useMemo(getDefaultProportionTextValues, []);
    var _a = useState(defaultValues), values = _a[0], setValues = _a[1];
    useEffect(function () {
        if (props.TextAttributes && props.TextAttributes.length > 0) {
            var newValues = props.TextAttributes.reduce(function (newObject, el) {
                var _a;
                return (__assign(__assign({}, newObject), (_a = {}, _a["att".concat(el.type)] = el.attribute, _a)));
            }, defaultValues);
            setValues(newValues);
        }
    }, [props.TextAttributes]);
    var handleChange = function (name, value) {
        setValues(function (values) {
            var _a;
            return (__assign(__assign({}, values), (_a = {}, _a[name] = value, _a)));
        });
    };
    return (React.createElement("div", { className: 'mt-4' },
        React.createElement(Typography, { variant: 'subtitle1', className: 'font-bold', gutterBottom: true }, "Medidas de cuadro de texto"),
        React.createElement("div", { className: 'grid grid-cols-3 gap-4' },
            React.createElement("div", null,
                React.createElement(Typography, { variant: 'subtitle2' }, "Laptops y Equipos de escritorio"),
                React.createElement(OutlinedFieldComponent, { name: 'att12', label: 'Alto de cuadro', endAdornment: React.createElement(InputAdornment, { position: 'end' }, "px"), value: values.att12.toString(), onChange: handleChange, type: 'number' }),
                React.createElement(OutlinedFieldComponent, { name: 'att15', label: 'Ancho de cuadro', endAdornment: React.createElement(InputAdornment, { position: 'end' }, "px"), value: values.att15.toString(), onChange: handleChange, type: 'number' })),
            React.createElement("div", null,
                React.createElement(Typography, { variant: 'subtitle2' }, "Tablets"),
                React.createElement(OutlinedFieldComponent, { name: 'att13', label: 'Alto de cuadro', endAdornment: React.createElement(InputAdornment, { position: 'end' }, "px"), value: values.att13.toString(), onChange: handleChange, type: 'number' }),
                React.createElement(OutlinedFieldComponent, { name: 'att16', label: 'Ancho de cuadro', endAdornment: React.createElement(InputAdornment, { position: 'end' }, "px"), value: values.att16.toString(), onChange: handleChange, type: 'number' })),
            React.createElement("div", null,
                React.createElement(Typography, { variant: 'subtitle2' }, "Tel\u00E9fonos"),
                React.createElement(OutlinedFieldComponent, { name: 'att14', label: 'Alto de cuadro', endAdornment: React.createElement(InputAdornment, { position: 'end' }, "px"), value: values.att14.toString(), onChange: handleChange, type: 'number' }),
                React.createElement(OutlinedFieldComponent, { name: 'att17', label: 'Ancho de cuadro', endAdornment: React.createElement(InputAdornment, { position: 'end' }, "px"), value: values.att17.toString(), onChange: handleChange, type: 'number' }))),
        React.createElement("div", { className: 'mt-3' },
            React.createElement(EditButtonProportionFieldEcommerceEditFormComponent, { TextAttributes: props.TextAttributes, values: values }))));
};
export default ProportionFieldEcommerceEditFormComponent;
