import React from 'react';
import { useParams } from 'react-router-dom';
import AddCircleIcon from '@mui/icons-material/AddOutlined';
import IconButtonComponent from '../../../../../presentationals/IconButton';
import { isCreateAddressLine2Open } from '../../../../../apollo/cache';
var OpenAddAddressLine2Button = function () {
    var params = useParams();
    var handleClick = function () {
        if (params.branchId)
            isCreateAddressLine2Open(params.branchId);
    };
    return (React.createElement(IconButtonComponent, { action: handleClick, icon: React.createElement(AddCircleIcon, null), size: 'small', color: 'secondary', label: 'Nueva colonia' }));
};
export default OpenAddAddressLine2Button;
