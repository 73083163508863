import React from 'react';
import UnderConstructionAlertComponent from '../../../../presentationals/UnderConstructionAlert';
import FooterDialogComponent from '../../../../presentationals/Dialog/Footer';
import DeleteButtonPromotionComponent from '../DeleteButton';
var GeneralEditPromotionComponent = function () {
    return (React.createElement("div", { className: 'flex flex-1 flex-col' },
        React.createElement("div", { className: 'flex flex-1 px-3 py-2 w-full sm:w-1/2' },
            React.createElement("div", null,
                React.createElement(UnderConstructionAlertComponent, null))),
        React.createElement(FooterDialogComponent, null,
            React.createElement(DeleteButtonPromotionComponent, null))));
};
export default GeneralEditPromotionComponent;
