import React, { useState, useEffect } from 'react';

import { useQuery, useMutation, useLazyQuery } from '@apollo/client';

import Grid from '@mui/material/Grid';
import AddIcon from '@mui/icons-material/AddOutlined';
import CloseIcon from '@mui/icons-material/CloseOutlined';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';

import Autocomplete from '../../presentationals/FormComponents/Autocomplete';
import FormDialog from '../../presentationals/FormDialog';
import IconButton from '../../presentationals/IconButton';
import TextField from '../../presentationals/FormComponents/TextField';
import DateField from '../../presentationals/FormComponents/DateField';
import SelectField from '../../presentationals/FormComponents/SelectField';

import { parseError, capitalize, dateTimeFormat, currencyFormat } from '../../helpers';

import GQL from './_gql';
import GQL_PAYMENT_METHOD from '../paymentMethod/_gql';
import CancelForm from './cancel.form';
import useSnackbarDialog from '../../store/snackbar/snackbarDialog';

const defaultValues = {
  total: '',
  paymentMethodId: -1,
  currency: 'MXN',
  exchangeRate: 1,
  transactionAccount: null,
  newTransactionAccount: '',
  date: new Date(),
  commentary: '',
};

const currencyOptions = [
  { value: 'MXN', label: 'Pesos Mexicanos (MXN)' },
  { value: 'USD', label: 'Dólares Americanos (USD)' },
];

const Component = props => {
  const { setSnackbar } = useSnackbarDialog();
  const [values, setValues] = useState(defaultValues);
  const [errors, setErrors] = useState({});
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [newTrasactionAccount, setNewTransactionAccount] = useState(false);
  const [transactionAccounts, setTransactionAccounts] = useState([]);
  const [transactions, setTransactions] = useState([]);
  const [isOpenCancelTransaction, setIsOpenCancelTransaction] = useState([false, null]);

  const { data, loading } = useQuery(GQL_PAYMENT_METHOD.GET_AUTOCOMPLETE, {
    variables: { limit: 0 },
  });
  const [createAccountPayableTransaction, { loading: loadingCreate }] = useMutation(
    GQL.CREATE_ACCOUNT_PAYABLE_TRANSACTION,
    {
      update(cache, { data: response }) {
        try {
          const payment = parseFloat(response.createAccountPayableTransaction.total);
          const accountPayableId = response.createAccountPayableTransaction.AccountPayable.id;
          cache.modify({
            id: `AccountPayable:${accountPayableId}`,
            fields: {
              payment(cachedPayment) {
                return cachedPayment + payment;
              },
              balance(cachedBalance) {
                return cachedBalance - payment;
              },
            },
          });
          cache.modify({
            id: 'Totals:2',
            fields: {
              qty(cachedQty) {
                return cachedQty + 1;
              },
              amount(cachedAmount) {
                return cachedAmount + payment;
              },
            },
          });
          cache.modify({
            id: 'Totals:3',
            fields: {
              qty(cachedQty) {
                return cachedQty + 1;
              },
              amount(cachedAmount) {
                return cachedAmount - payment;
              },
            },
          });
          cache.modify({
            fields: {
              accountPayableTransactions(existingTransactionsRefs, { fieldName, storeFieldName }) {
                const args = JSON.parse(storeFieldName.replace(`${fieldName}(`, '').slice(0, -1));
                if (
                  (args && args.accountPayableId === accountPayableId) ||
                  Object.keys(args).length === 0
                ) {
                  const newTransactionRef = cache.writeFragment({
                    data: { ...response.createAccountPayableTransaction },
                    fragment: GQL.FRAGMENT_NEW_ACCOUNT_PAYABLE_TRANSACTION,
                  });
                  return {
                    ...existingTransactionsRefs,
                    count: existingTransactionsRefs.count + 1,
                    rows: [newTransactionRef, ...existingTransactionsRefs.rows],
                  };
                }
                return existingTransactionsRefs;
              },
            },
          });
        } catch (e) {
          console.log(e.message);
        }
      },
    },
  );
  const [getTransactions, { data: dataAccountPayableTransactions }] = useLazyQuery(
    GQL.GET_ACCOUNT_PAYABLE_TRANSACTIONS,
  );
  const { data: dataTransactionAccounts } = useQuery(GQL_PAYMENT_METHOD.GET_TRANSACTION_ACCOUNTS);
  const [createTransactionAccount] = useMutation(GQL_PAYMENT_METHOD.CREATE_TRANSACTION_ACCOUNT, {
    update(cache, { data: response }) {
      cache.modify({
        fields: {
          transactionAccounts(cachedTransactionAccounts) {
            const newTransactionAccountRef = cache.writeFragment({
              data: { ...response.createTransactionAccount },
              fragment: GQL_PAYMENT_METHOD.FRAGMENT_CORE_TRANSACTION_ACCOUNT_FIELDS,
            });
            return {
              ...cachedTransactionAccounts,
              count: cachedTransactionAccounts.count + 1,
              rows: [...cachedTransactionAccounts.rows, newTransactionAccountRef],
            };
          },
        },
      });
    },
  });

  useEffect(() => {
    if (
      dataAccountPayableTransactions &&
      dataAccountPayableTransactions.accountPayableTransactions.rows.length > 0
    )
      setTransactions(dataAccountPayableTransactions.accountPayableTransactions.rows);
  }, [dataAccountPayableTransactions]);

  useEffect(() => {
    if (props.accountPayableId)
      getTransactions({ variables: { limit: 0, accountPayableId: props.accountPayableId } });
  }, [props.accountPayableId]);

  useEffect(() => {
    if (dataTransactionAccounts) {
      const tmpTransactionAccounts = dataTransactionAccounts.transactionAccounts.rows.map(el => ({
        value: el.id,
        label: el.name,
      }));
      setTransactionAccounts(tmpTransactionAccounts);
    }
  }, [dataTransactionAccounts]);

  useEffect(() => {
    if (data) {
      const tmp = data.paymentMethods.rows.map(tmp => ({
        value: tmp.id,
        label: capitalize(tmp.name),
      }));
      setPaymentMethods([{ value: -1, label: 'EFECTIVO' }, ...tmp]);
    }
  }, [data]);

  const handleClose = () => {
    props.handleClose();
    setValues(defaultValues);
    setErrors({});
  };

  const handleChange = (name, value) => {
    setValues(values => ({ ...values, [name]: value }));
  };

  const handleDateChange = (name, value) => {
    setValues(values => ({ ...values, [name]: value }));
  };

  const handleAddTransaction = () => {
    setNewTransactionAccount(newTrasactionAccount => !newTrasactionAccount);
  };

  const handleAction = async () => {
    try {
      if (values.total === '' || parseFloat(values.total) <= 0) {
        const totalError = new Error('La cantidad de abono tiene que ser mayor a cero.');
        totalError.name = 'total';
        totalError.input = true;
        throw totalError;
      }
      if (
        values.currency !== 'MXN' &&
        (Number.isNaN(parseFloat(values.exchangeRate)) || parseFloat(values.exchangeRate) <= 0)
      ) {
        const totalError = new Error('El precio del dólar es inválido.');
        totalError.name = 'exchangeRate';
        totalError.input = true;
        throw totalError;
      }

      let transactionAccountId = values.transactionAccount ? values.transactionAccount.value : null;
      if (newTrasactionAccount) {
        const responseTransactionAccount = await createTransactionAccount({
          variables: { name: values.newTransactionAccount },
        });
        transactionAccountId = responseTransactionAccount.data.createTransactionAccount.id;
      }

      const variables = {
        accountPayableId: props.accountPayableId,
        total: parseFloat(values.total),
        paymentMethodId: parseInt(values.paymentMethodId) !== -1 ? values.paymentMethodId : null,
        currency: values.currency,
        exchangeRate: values.currency === 'MXN' ? 1 : parseFloat(values.exchangeRate),
        date: values.date ? values.date : null,
        transactionAccountId,
        commentary: values.commentary ? values.commentary : '',
      };

      await createAccountPayableTransaction({ variables });
      setValues(defaultValues);
      handleClose();
    } catch (e) {
      if (e.input) {
        setErrors({ [e.name]: e.message });
        return;
      }
      const parseErrors = parseError(e);
      parseErrors.forEach(el => {
        if (el.name === 'BAD_USER_INPUT') setErrors(el.message);
        else
          setSnackbar({
            variables: {
              isOpen: true,
              time: 3000,
              label: el.message,
              severity: 'error',
            },
          });
      });
    }
  };

  const handleCloseDeleteTransaction = id => {
    setIsOpenCancelTransaction([false, null]);
    const tmp = transactions
      .map(el => {
        if (parseInt(el.id) === parseInt(id)) return;
        return el;
      })
      .filter(elCh => elCh);
    setTransactions(tmp);
  };

  const handleDeleteTransaction = (e, id) => {
    setIsOpenCancelTransaction([true, id]);
  };

  return (
    <FormDialog
      title='Abono a cuenta por pagar'
      isOpen={props.isOpen}
      handleClose={handleClose}
      handleAction={handleAction}
      isLoading={loading || loadingCreate}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} md={8}>
          <SelectField
            value={values.currency}
            error={errors.currency}
            name='currency'
            label='Moneda'
            onChange={handleChange}
            options={currencyOptions}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12} md={8}>
          {values.currency !== 'MXN' && (
            <TextField
              value={values.exchangeRate}
              error={errors.exchangeRate}
              name='exchangeRate'
              label='Precio del Dólar'
              onChange={handleChange}
              type='number'
            />
          )}
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12} md={8}>
          <DateField
            size='small'
            variant='outlined'
            name='date'
            label='Fecha de Pago'
            onChange={handleDateChange}
            value={values.date}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12} md={8}>
          <TextField
            value={values.total}
            error={errors.total}
            name='total'
            label='Cantidad'
            onChange={handleChange}
            type='number'
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12} md={8}>
          <SelectField
            value={values.paymentMethodId}
            error={errors.paymentMethodId}
            name='paymentMethodId'
            label='Método de Pago'
            onChange={handleChange}
            options={paymentMethods}
          />
        </Grid>
      </Grid>
      <Grid container alignItems='flex-end' spacing={2}>
        <Grid item xs={11} md={8}>
          {newTrasactionAccount ? (
            <TextField
              name='newTransactionAccount'
              label='¿Cuál será el nombre de la nueva cuenta?'
              value={values.newTransactionAccount}
              onChange={handleChange}
            />
          ) : (
            <Autocomplete
              name='transactionAccount'
              label='Cuenta (Opcional)'
              value={values.transactionAccount}
              options={transactionAccounts}
              onChange={handleChange}
            />
          )}
        </Grid>
        <Grid item xs={1} md={4}>
          <IconButton
            color='secondary'
            icon={
              newTrasactionAccount ? <CloseIcon fontSize='small' /> : <AddIcon fontSize='small' />
            }
            action={handleAddTransaction}
            size='large'
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12} md={8}>
          <TextField
            value={values.commentary}
            error={errors.commentary}
            name='commentary'
            label='Comentario'
            onChange={handleChange}
            multiline
          />
        </Grid>
      </Grid>
      <br />
      Esta vista es temporal.
      {transactions
        .map(el => (
          <div key={el.id}>
            <IconButton
              label='Eliminar'
              icon={<DeleteOutlineIcon />}
              params={el.id}
              action={handleDeleteTransaction}
              size='small'
            />
            {`${dateTimeFormat(el.clientCreatedAt)}, $${currencyFormat(el.total)} ${el.commentary}`}
          </div>
        ))
        .filter(tmp => tmp)}
      <CancelForm
        isOpen={isOpenCancelTransaction[0]}
        handleClose={handleCloseDeleteTransaction}
        id={isOpenCancelTransaction[1]}
      />
    </FormDialog>
  );
};

export default Component;
