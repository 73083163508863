import { Button } from '@mui/material';
import React from 'react';
import CONFIG from '../../../../../../config/config.json';
var DownloadInvoiceAcuseXMLButtonComponent = function (props) {
    var handleClick = function () {
        window.open("".concat(CONFIG.restServer.uri, "/files/invoices/").concat(props.uuid, "/acuse/xml"));
    };
    return (React.createElement(Button, { color: 'secondary', variant: 'contained', className: 'px-5 py-3', onClick: handleClick }, "Descargar XML del acuse"));
};
export default DownloadInvoiceAcuseXMLButtonComponent;
