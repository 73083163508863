import React from 'react';
import { Link } from '@mui/material';
import { isDuplicateSaleOpen } from '../../../../../../apollo/cache';
var DuplicateFormatFormFormatActionSaleV2Component = function () {
    var handleClick = function (e) {
        e.preventDefault();
        isDuplicateSaleOpen(true);
    };
    return (React.createElement("div", { className: 'p-5' },
        React.createElement(Link, { href: '#', onClick: handleClick }, "Deseo duplicar el formato con un folio existente.")));
};
export default DuplicateFormatFormFormatActionSaleV2Component;
