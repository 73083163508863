import { gql } from '@apollo/client';

const GET = gql`
  query OperingExpenses(
    $branchId: ID
    $paymentForm: String
    $name: String
    $limit: Int
    $offset: Int
    $status: Int
    $start: Date
    $end: Date
    $order: [String]
    $providerId: ID
    $type: Int
  ) {
    operingExpenses(
      branchId: $branchId
      paymentForm: $paymentForm
      like: $name
      limit: $limit
      offset: $offset
      status: $status
      start: $start
      end: $end
      order: $order
      providerId: $providerId
      type: $type
    ) {
      count
      totals {
        qty
        amount
        name
      }
      rows {
        id
        folio
        serial
        date
        subtotal
        discount
        taxes
        description
        total
        type
        ivaTotal
        iepsTotal
        subFolio
        status
        currency
        exchangeRate
        usdTotal
        createdAt
        AccountPayable {
          id
          payment
          balance
        }
        TransactionAccount {
          name
        }
        Provider {
          id
          name
        }
        Employee {
          firstName
          lastName
        }
        deletedAt
      }
    }
  }
`;

const CREATE = gql`
  mutation CreateOperingExpense(
    $date: Date
    $description: String
    $status: Int!
    $providerId: ID
    $branchId: ID!
    $subFolio: String
    $paymentForm: String
    $type: Int
    $currency: String
    $exchangeRate: Float
    $Products: [ProductPurchaseOrderDataV2]
    $transactionAccountId: ID
    $subtype: Int
  ) {
    createOperingExpense(
      input: {
        date: $date
        description: $description
        providerId: $providerId
        branchId: $branchId
        status: $status
        subFolio: $subFolio
        paymentForm: $paymentForm
        type: $type
        currency: $currency
        exchangeRate: $exchangeRate
        Products: $Products
        transactionAccountId: $transactionAccountId
        subtype: $subtype
      }
    ) {
      id
      folio
      serial
      date
      subtotal
      discount
      taxes
      total
      type
      status
      subFolio
      TransactionAccount {
        name
      }
      Provider {
        id
        name
      }
      Employee {
        firstName
        lastName
      }
      PurchaseOrderDetails {
        quantity
        unitPrice
        Product {
          id
        }
        ProductPresentation {
          id
        }
      }
    }
  }
`;

const DELETE_OPERATING_EXPENSE = gql`
  mutation DeleteOperingExpense($id: ID!, $deleteJustification: String!) {
    deleteOperingExpense(id: $id, deleteJustification: $deleteJustification) {
      id
    }
  }
`;

const FRAGMENT_NEW_OPERING_EXPENSE = gql`
  fragment NewOperingExpense on OperingExpense {
    id
    folio
    serial
    date
    subtotal
    discount
    taxes
    description
    total
    type
    ivaTotal
    iepsTotal
    subFolio
    status
    currency
    exchangeRate
    usdTotal
    AccountPayable {
      id
      payment
      balance
    }
    TransactionAccount {
      name
    }
    Provider {
      id
      name
    }
    Employee {
      firstName
      lastName
    }
    deletedAt
  }
`;

const CREATE_SERVICE_ORDER_EXPENSE = gql`
  mutation CreateServiceOrderExpense(
    $date: Date
    $description: String
    $status: Int!
    $branchId: ID!
    $subFolio: String
    $paymentForm: String
    $type: Int
    $currency: String
    $exchangeRate: Float
    $Products: [ProductPurchaseOrderDataV2]
    $saleId: ID!
  ) {
    createServiceOrderExpense(
      saleId: $saleId
      input: {
        date: $date
        description: $description
        branchId: $branchId
        status: $status
        subFolio: $subFolio
        paymentForm: $paymentForm
        type: $type
        currency: $currency
        exchangeRate: $exchangeRate
        Products: $Products
      }
    ) {
      id
      folio
      serial
      date
      subtotal
      discount
      taxes
      total
      type
      status
      subFolio
      TransactionAccount {
        name
      }
      Provider {
        id
        name
      }
      Employee {
        firstName
        lastName
      }
      PurchaseOrderDetails {
        quantity
        unitPrice
        Product {
          id
        }
        ProductPresentation {
          id
        }
      }
    }
  }
`;

export default {
  GET,
  CREATE,
  DELETE_OPERATING_EXPENSE,
  FRAGMENT_NEW_OPERING_EXPENSE,
  CREATE_SERVICE_ORDER_EXPENSE,
};
