import { gql } from '@apollo/client';

const GET = gql`
  query Crms(
    $name: String
    $limit: Int
    $offset: Int
    $start: Date
    $end: Date
    $branchId: ID
    $customerId: ID
    $productId: ID
    $calendarId: ID
    $saleProductId: ID
    $order: [String]
  ) {
    crms(
      like: $name
      limit: $limit
      offset: $offset
      start: $start
      end: $end
      branchId: $branchId
      customerId: $customerId
      productId: $productId
      calendarId: $calendarId
      saleProductId: $saleProductId
      order: $order
    ) {
      count
      rows {
        id
        createdAt
        folio
        sessions
        sessionsTaken
        customer
        product
        price
        total
        payment
        accountReceivableId
        customerId
        code
      }
    }
  }
`;

const RELATION = gql`
  mutation RelateCrm($crmId: ID!, $calendarId: ID!) {
    relateCrm(crmId: $crmId, calendarId: $calendarId) {
      calendarId
    }
  }
`;

const UPDATE = gql`
  mutation UpdateCrm($id: ID!, $sessionsTaken: Int!) {
    updateCrm(id: $id, input: { sessionsTaken: $sessionsTaken }) {
      id
      sessions
      sessionsTaken
    }
  }
`;

const DELETE = gql`
  mutation DeleteCrm($id: ID!) {
    deleteCrm(id: $id) {
      id
    }
  }
`;

export default { GET, RELATION, UPDATE, DELETE };
