import React from 'react';
import { Skeleton } from '@mui/material';
import convertToCurrencyLabel from '../../../../../helpers/convertToCurrencyLabel';
var ItemCashClossinSummaryIncomeReportComponent = function (props) {
    return (React.createElement("div", { className: 'flex-1 flex flex-col whitespace-nowrap mr-2' },
        typeof props.total === 'number' ? (React.createElement("div", { className: 'text-lg font-semibold text-white' },
            props.qty ? "(".concat(props.qty, ") ") : '', "$".concat(convertToCurrencyLabel(props.total)))) : (React.createElement(Skeleton, { variant: 'text', className: 'text-lg bg-gray-300 w-1/2' })),
        React.createElement("div", { className: 'text-sm text-white leading-none' }, typeof props.total === 'number' && props.label ? (props.label) : (React.createElement(Skeleton, { variant: 'text', className: 'text-sm bg-gray-300 w-3/4 leading-none' })))));
};
export default ItemCashClossinSummaryIncomeReportComponent;
