import React, { useState, useEffect } from 'react';

import format from 'date-fns/format';

import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import { useNavigate } from 'react-router-dom';

import { calendarId, customerCalendarCheck, customerSaleV2 } from '../../../../../../apollo/cache';

import ResponseMessage from '../../responseMessage';

import DeleteButton from '../delete.button';

import MenuIcon from './menu.icon';
import getDefaultCustomerValues from '../../../../../sale.v2/Action/Customer/Form/Create/Body/helpers/defaultValues';
import getInputErrorFormat from '../../../../../sale.v2/Action/helpers/getInputErrorFormat';
import useApolloLazyQuery from '../../../../../../hooks/useApolloLazyQuery';
import GQL_CUSTOMER from '../../../../../customer/_gql';

const MenuItemComponent = props => {
  const navigate = useNavigate();
  const [getCustomer] = useApolloLazyQuery(GQL_CUSTOMER.GET_BY_ID);

  const [showCompleteString, setShowCompleteString] = useState(false);
  const [title, setTitle] = useState('');
  const [message, setMessage] = useState('');

  useEffect(() => {
    const createdAt = props.createdAt ? ` ${format(new Date(props.createdAt), 'hh:mm aaa')}` : '';
    if (showCompleteString) {
      setTitle(props.title);
      setMessage(`${props.message}${createdAt}`);
    } else {
      const message =
        props.message && props.message.length > 19
          ? `${props.message.substring(0, 19)}...${createdAt}`
          : `${props.message}${createdAt}`;
      const title = props.title.length > 25 ? `${props.title.substring(0, 25)}...` : props.title;
      setTitle(title);
      setMessage(message);
    }
  }, [showCompleteString]);

  const handleClick = async () => {
    try {
      if (props.type === 'CUSTOMER_CHECK_OUT' && !props.saleId) {
        const { data } = await getCustomer({ variables: { id: props.customerId } });
        const customerData = getDefaultCustomerValues(data);
        customerSaleV2(customerData);
        calendarId(props.dataId);
        navigate('v2/sale');
      }

      if (props.type === 'CUSTOMER_CHECK_OUT' && props.saleId)
        customerCalendarCheck({ isOpen: true, dataId: props.dataId, type: props.type });

      if (props.type === 'CUSTOMER_DELAYED')
        customerCalendarCheck({ isOpen: true, dataId: props.dataId, type: props.type });

      setShowCompleteString(showCompleteString => !showCompleteString);
    } catch (error) {
      getInputErrorFormat(error);
    }
  };
  return (
    <MenuItem onClick={handleClick} classes={{ root: 'pl-1' }}>
      <ListItemIcon classes={{ root: 'pr-0 mr-0 justify-center flex' }}>
        <MenuIcon type={props.type} />
      </ListItemIcon>
      <ListItemText classes={{ root: 'break-words whitespace-normal pl-0 ml-0' }}>
        {title !== '' ? title : message}
        <p className='text-[#808080] text-sm m-0 p-0'>{message !== '' && message}</p>
        {props.type === 'MESSAGE' && <ResponseMessage user={props.from} />}
      </ListItemText>
      <ListItemSecondaryAction>
        <DeleteButton id={props.id} />
      </ListItemSecondaryAction>
    </MenuItem>
  );
};

export default MenuItemComponent;
