import React from 'react';

import CreateButton from './CreateButton';
import FooterDialogComponent from '../../../../../../presentationals/Dialog/Footer';

const FooterCreateFormCustomerActionSaleV2Component = () => {
  return (
    <FooterDialogComponent>
      <div />
      <CreateButton />
    </FooterDialogComponent>
  );
};

export default FooterCreateFormCustomerActionSaleV2Component;
