import { gql } from '@apollo/client';

const GET_BY_ID = gql`
  query Calendar($id: ID!) {
    calendar(id: $id) {
      id
      title
      start
      end
      reminder
      diagnosis
      description
      crmId
      customerId
      cancelCommentary
      Tag {
        id
        name
        color
      }
      address
      status
      session
      permissions
      Employee {
        id
        firstName
        lastName
      }
      Customer {
        id
        firstName
        lastName
        phone
      }
      CalendarNotes {
        id
        description
      }
      CalendarFiles {
        fileId
      }
      Crm {
        sessionsTaken
        sessions
      }
      Branch {
        addressLine1
        addressLine2
        zipCode
        city
        state
        phone
        name
      }
      adviserId
      Adviser {
        id
        firstName
        lastName
      }
      Files {
        id
      }
    }
  }
`;

const GET = gql`
  query Calendars(
    $order: [String!]
    $tagId: [ID]
    $customerId: ID
    $withCrm: Boolean
    $limit: Int
    $offset: Int
    $status: [Int]
    $start: Date
    $end: Date
  ) {
    calendars(
      order: $order
      tagId: $tagId
      customerId: $customerId
      withCrm: $withCrm
      limit: $limit
      offset: $offset
      status: $status
      start: $start
      end: $end
    ) {
      count
      rows {
        id
        title
        start
        end
        diagnosis
        customerId
        Tag {
          id
          name
          color
        }
        status
        Customer {
          id
          firstName
          lastName
        }
      }
    }
  }
`;

const GET_BY_CUSTOMER_ID = gql`
  query CalendarByCustomer($customerId: ID!, $status: [Int]) {
    calendarByCustomer(customerId: $customerId, status: $status) {
      count
      rows {
        id
        title
        start
        end
        diagnosis
        permissions
        description
        checkIn
        checkOut
        customerId
        Tag {
          id
          name
          color
        }
        status
        Customer {
          id
          firstName
          lastName
        }
      }
    }
  }
`;

const CALENDAR_CONTROLS = gql`
  query CalendarControls($limit: Int) {
    calendarControls(limit: $limit) {
      count
      rows {
        id
        name
        countTotal
        count
        total
        paid
      }
    }
  }
`;

const CALENDAR_CONTROL = gql`
  query CalendarControl($id: ID!) {
    calendarControl(id: $id) {
      id
      name
      countTotal
      count
      total
      paid
    }
  }
`;

const ADD_CALENDAR_CONTROL = gql`
  mutation AddCalendarControl($id: ID!) {
    addCalendarControl(id: $id) {
      id
      name
      countTotal
      count
      total
      paid
    }
  }
`;

const UPDATE_CALENDAR_CONTROL = gql`
  mutation UpdateCalendarControl(
    $id: ID!
    $name: String!
    $total: Float!
    $countTotal: Int!
    $count: Int
  ) {
    updateCalendarControl(
      id: $id
      input: { name: $name, total: $total, countTotal: $countTotal, count: $count }
    ) {
      id
      name
      countTotal
      count
      total
      paid
    }
  }
`;

const UPDATE = gql`
  mutation UpdateCalendar(
    $id: ID!
    $start: Date!
    $end: Date!
    $permissions: Int!
    $tagId: ID!
    $diagnosis: String
    $address: String
    $description: String
    $customerId: ID
    $adviserId: ID
  ) {
    updateCalendar(
      id: $id
      input: {
        start: $start
        end: $end
        permissions: $permissions
        tagId: $tagId
        address: $address
        diagnosis: $diagnosis
        description: $description
        customerId: $customerId
        adviserId: $adviserId
      }
    ) {
      id
      title
      start
      customerId
      end
      diagnosis
      address
      description
      adviserId
      Adviser {
        id
        firstName
        lastName
      }
      Tag {
        id
        name
        color
      }
    }
  }
`;
const CREATE_NOTE = gql`
  mutation CreateCalendarNote($calendarId: ID!, $description: String!) {
    createCalendarNote(input: { calendarId: $calendarId, description: $description }) {
      id
      CalendarNotes {
        id
        description
      }
    }
  }
`;

const CREATE_FILE = gql`
  mutation CreateCalendarFile($calendarId: ID!, $file: Upload!) {
    createCalendarFile(input: { calendarId: $calendarId, file: $file }) {
      id
      CalendarFiles {
        fileId
      }
    }
  }
`;

const UPDATE_STATUS = gql`
  mutation UpdateStatusCalendar(
    $id: ID!
    $status: Int!
    $userPassword: String
    $cancelCommentary: String
    $cancelSimilarEvents: Boolean
  ) {
    updateStatusCalendar(
      id: $id
      status: $status
      userPassword: $userPassword
      cancelCommentary: $cancelCommentary
      cancelSimilarEvents: $cancelSimilarEvents
    ) {
      id
      status
      cancelCommentary
      cancelledIds
    }
  }
`;

const SET_CALENDAR_ZONE = gql`
  mutation SetCalendarZone($calendarId: ID!, $planeZoneId: ID) {
    setCalendarZone(calendarId: $calendarId, planeZoneId: $planeZoneId) {
      id
      title
      start
      end
      diagnosis
      customerId
      Tag {
        color
      }
      status
      Customer {
        id
        firstName
        lastName
      }
      PlaneZone {
        id
      }
    }
  }
`;

const SUBSCRIBE_TO_PLANE_ZONE = gql`
  subscription SubscribeToPlaneZone($wsToken: String!) {
    subscribeToPlaneZone(wsToken: $wsToken) {
      calendarId
      planeZoneId
    }
  }
`;

const PAY_CALENDAR_CONTROL = gql`
  mutation CreateCalendarControlPayment($id: ID!, $total: Float!) {
    createCalendarControlPayment(id: $id, total: $total) {
      id
      total
      action
      description
      clientCreatedAt
      updatedAt
      Employee {
        firstName
        lastName
      }
      CalendarControl {
        id
        name
        countTotal
        count
        paid
        total
        tagId
      }
    }
  }
`;

const CALENDAT_CONTROLS_REPORT = gql`
  query CalendarControlPayments($calendarControlId: ID!) {
    calendarControlPayments(calendarControlId: $calendarControlId) {
      count
      rows {
        CashFlow {
          id
          total
          action
          description
          clientCreatedAt
          updatedAt
          CalendarControl {
            id
            name
            countTotal
            count
            paid
            total
            tagId
          }
        }
        Calendars {
          id
          title
          start
          end
          checkIn
          checkOut
          customerId
          Customer {
            id
            firstName
            lastName
          }
        }
      }
    }
  }
`;

export default {
  CREATE_NOTE,
  CREATE_FILE,
  UPDATE,
  GET,
  GET_BY_ID,
  UPDATE_STATUS,
  GET_BY_CUSTOMER_ID,
  SET_CALENDAR_ZONE,
  SUBSCRIBE_TO_PLANE_ZONE,
  CALENDAR_CONTROLS,
  CALENDAR_CONTROL,
  ADD_CALENDAR_CONTROL,
  UPDATE_CALENDAR_CONTROL,
  PAY_CALENDAR_CONTROL,
  CALENDAT_CONTROLS_REPORT,
};
