var getDefaultProviderValues = function (values) { return ({
    name: (values === null || values === void 0 ? void 0 : values.name) ? values.name : '',
    phone: (values === null || values === void 0 ? void 0 : values.phone) ? values.phone : '',
    identifier: (values === null || values === void 0 ? void 0 : values.identifier) ? values.identifier : '',
    businessName: (values === null || values === void 0 ? void 0 : values.businessName) ? values.businessName : '',
    addressLine1: (values === null || values === void 0 ? void 0 : values.addressLine1) ? values.addressLine1 : '',
    addressLine2: (values === null || values === void 0 ? void 0 : values.addressLine2) ? values.addressLine2 : '',
    zipCode: (values === null || values === void 0 ? void 0 : values.zipCode) ? values.zipCode : '',
    city: (values === null || values === void 0 ? void 0 : values.city) ? values.city : '',
    state: (values === null || values === void 0 ? void 0 : values.state) ? values.state : '',
    country: (values === null || values === void 0 ? void 0 : values.country) ? values.country : '',
    email: (values === null || values === void 0 ? void 0 : values.email) ? values.email : '',
    altEmail: (values === null || values === void 0 ? void 0 : values.altEmail) ? values.altEmail : '',
    otherEmail: (values === null || values === void 0 ? void 0 : values.otherEmail) ? values.otherEmail : '',
    creditDays: (values === null || values === void 0 ? void 0 : values.creditDays) ? values.creditDays : '',
}); };
export default getDefaultProviderValues;
