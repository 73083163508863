import React, { useEffect, useState } from 'react';
import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import useApolloLazyQuery from '../../../../hooks/useApolloLazyQuery';
import GQL from '../../_gql';
import LoadingProgress from '../../../../presentationals/LoadingProgress';
import getFormattedCustomerHistory from './helpers/getFormattedCustomerHistory';
import EditDiagnosisButton from './EditDiagnosisButton';
import { dateFormat, hourFormat } from '../../../../helpers';
import TableRowHistoryCustomerComponent from './helpers/TableRow';
import EditDesciptionFormHistoryCustomerComponent from './EditDescriptionForm';
import EditDiagnosisFormHistoryCustomerComponent from './EditDiagnosisForm';
var BodyHistoryCalendarComponent = function (props) {
    var _a = useApolloLazyQuery(GQL.GET_BY_CUSTOMER_ID), getCustomerHistory = _a[0], _b = _a[1], loading = _b.loading, data = _b.data;
    var _c = useState([]), customerHistory = _c[0], setCustomerHistory = _c[1];
    useEffect(function () {
        if (props.customerId)
            getCustomerHistory({
                variables: {
                    status: [1, 5],
                    customerId: props.customerId,
                },
            });
    }, [props.customerId]);
    useEffect(function () {
        if (data) {
            var customerHistoryArray = getFormattedCustomerHistory(data === null || data === void 0 ? void 0 : data.rows);
            setCustomerHistory(customerHistoryArray);
        }
    }, [data]);
    if (loading)
        return React.createElement(LoadingProgress, null);
    return (React.createElement("div", { className: 'flex flex-1 flex-col h-screen overflow-hidden md:pt-0 md:pr-0' },
        customerHistory.map(function (el) { return (React.createElement("div", { key: el.name, className: 'flex flex-col border-0 border-t-4 border-solid border-gray-400' },
            React.createElement("div", { className: 'flex flex-row items-center bg-gray-200 px-2' },
                React.createElement("div", { className: 'text-lg font-semibold' }, el.name || 'Sin diagnóstico'),
                React.createElement(EditDiagnosisButton, { name: el.name, Events: el.rows.map(function (el) { return el.id; }) })),
            React.createElement("div", { className: 'flex flex-1 flex-col' },
                React.createElement(Table, { size: 'small' },
                    React.createElement(TableHead, null,
                        React.createElement(TableRow, null,
                            React.createElement(TableCell, null, "Cita"),
                            React.createElement(TableCell, null, "Estatus"),
                            React.createElement(TableCell, null, "Anotaciones"))),
                    React.createElement(TableBody, null, el.rows.map(function (elCh) { return (React.createElement(TableRowHistoryCustomerComponent, { eventId: elCh.id, description: elCh.description, key: elCh.id },
                        React.createElement(TableCell, { width: 220 }, "".concat(dateFormat(elCh.start), " ").concat(hourFormat(elCh.start))),
                        React.createElement(TableCell, { width: 140 }, elCh.checkIn ? 'Asistió' : 'Por asistir'),
                        React.createElement(TableCell, null, elCh.description || '-'))); })))))); }),
        React.createElement(EditDesciptionFormHistoryCustomerComponent, null),
        React.createElement(EditDiagnosisFormHistoryCustomerComponent, null)));
};
export default BodyHistoryCalendarComponent;
