import React, { useState, Fragment } from 'react';
import { useMutation } from '@apollo/client';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import FormDialog from '../../presentationals/FormDialog';
import TextField from '../../presentationals/TextField';
import Snackbar from '../../presentationals/Snackbar';

import { parseError } from '../../helpers';

import GQL from './_gql';
import useSnackbarDialog from '../../store/snackbar/snackbarDialog';

const defaultValues = {
  userPassword: '',
};
const defaultSnackbar = { isOpen: true, time: 3000 };

const Component = ({ id, ...props }) => {
  const { setSnackbar } = useSnackbarDialog();
  const [values, setValues] = useState(defaultValues);

  const [deleteAccountPayableTransaction, { loading }] = useMutation(
    GQL.DELETE_ACCOUNT_PAYABLE_TRANSACTION,
  );

  const resetValues = () => {
    setValues(defaultValues);
  };

  const handleAction = async () => {
    try {
      await deleteAccountPayableTransaction({
        variables: { id, userPassword: values.userPassword },
      });
      resetValues();
      setSnackbar({
        ...defaultSnackbar,
        label: 'El abono fue cancelado con éxito.',
        severity: 'success',
      });
      props.handleClose(id);
    } catch (e) {
      const parseErrors = parseError(e);
      parseErrors.forEach(el => {
        console.log(el);
        if (el.name === 'BAD_USER_INPUT') {
          const key = Object.keys(el.message)[0];
          setSnackbar({ ...defaultSnackbar, label: el.message[key], severity: 'error' });
        } else {
          setSnackbar({ ...defaultSnackbar, label: el.message, severity: 'error' });
        }
      });
    }
  };

  const handleChange = e => {
    const { name } = e.target;
    const { value } = e.target;
    setValues(values => ({ ...values, [name]: value }));
  };

  return (
    <>
      <FormDialog
        title='Cancelar Abono'
        isOpen={props.isOpen}
        isLoading={loading}
        handleAction={handleAction}
        handleClose={props.handleClose}
      >
        <Grid container>
          <Grid item xs={12}>
            <Typography variant='body2' gutterBottom>
              Estás a punto de cancelar un abono. Solicitamos una contraseña con privilegios para
              realizar esta acción.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField
              label='Contraseña'
              name='userPassword'
              value={values.userPassword}
              onChange={handleChange}
              noFormat
              type='password'
            />
          </Grid>
        </Grid>
      </FormDialog>
      <Snackbar horizontal='center' />
    </>
  );
};

export default Component;
