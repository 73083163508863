import { TableRow } from '@mui/material';
import React from 'react';
import { isEditRentProductOpen } from '../../../../../../apollo/cache';
var BodyTableRowProductRentComponent = function (props) {
    var handleClick = function () {
        typeof props.deliveryStatus === 'number' &&
            props.deliveryStatus === 0 &&
            isEditRentProductOpen(props.saleProductId);
    };
    return (React.createElement(TableRow, { className: "".concat(props.index % 2 ? 'bg-slate-200' : '', " hover:bg-slate-300"), onClick: handleClick }, props.children));
};
export default BodyTableRowProductRentComponent;
