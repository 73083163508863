var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import jsPDF from 'jspdf';
import JsBarcode from 'jsbarcode';
var canvas = document.createElement('canvas');
var BarcodePDF146 = function (data, newTotal) { return __awaiter(void 0, void 0, void 0, function () {
    var total, doc, value, i;
    return __generator(this, function (_a) {
        total = newTotal ? parseInt(newTotal) : 1;
        doc = new jsPDF({
            orientation: 'l',
            format: [29, 50],
            unit: 'mm',
        });
        if (!total || total <= 0)
            return [2 /*return*/];
        doc.setFontSize(10);
        doc.setFont('times', 'normal');
        value = data.Presentation.barcode && data.Presentation.barcode.trim() !== ''
            ? data.Presentation.barcode
            : data.Presentation.altBarcode && data.Presentation.altBarcode.trim() !== ''
                ? data.Presentation.altBarcode
                : data.Presentation.id;
        JsBarcode(canvas, value, { text: ' ' });
        for (i = 0; i < total; i++) {
            i !== 0 && doc.addPage();
            doc.addImage(canvas.toDataURL('image/png'), 'JPEG', 11, 14, 38, 8);
            doc.addImage('https://storage.capitalike.com/files/11946', 'JPEG', 10, 20, 26, 7, undefined, undefined, 90);
            doc.text(value, 30, 24, { align: 'center' });
            doc.text(doc.splitTextToSize("".concat(data.Presentation.sku, " ").concat(data.name).trim().substring(0, 32), 34), 30, 5, { align: 'center' });
            doc.text("$".concat(data.Presentation.price), 30, 13, { align: 'center' });
        }
        window.open(doc.output('bloburl'), '_blank');
        return [2 /*return*/];
    });
}); };
export default BarcodePDF146;
