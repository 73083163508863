import React, { useState, useEffect } from 'react';

import { useNavigate } from 'react-router-dom';
import { useQuery } from '@apollo/client';

import useTheme from '@mui/material/styles/useTheme';
import useMediaQuery from '@mui/material/useMediaQuery';

import FilterListIcon from '@mui/icons-material/FilterList';
import AddCircleIcon from '@mui/icons-material/AddOutlined';
import UpdateIcon from '@mui/icons-material/Update';
import MapIcon from '@mui/icons-material/MapOutlined';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedInOutlined';
import PersonIcon from '@mui/icons-material/Person';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import IconButton from '../../presentationals/IconButton';
import Time from '../../presentationals/Time';

import { filterForm, isCalendarFormOpen, isPostponeCalendarOpen } from '../../apollo/cache';
import getUrlQueries from '../../helpers/getUrlQueries';

import Filter from './_filter';
import MapDialog from './map.dialog';
import CalendarControlDialog from './calendarControl.dialog';
import PayCalendarControlDialog from './payCalendarControl.dialog';

import GQL from './_gql';
import { customerLabel } from '../../helpers';

const MapButton = () => {
  const [openMap, setOpenMap] = useState(false);
  const screenQuery = getUrlQueries('screen');
  useEffect(() => {
    if (screenQuery === 'fixed') setOpenMap(true);
  }, [screenQuery]);
  const handleOpenMap = () => {
    setOpenMap(true);
  };
  const handleClose = () => {
    setOpenMap(false);
  };
  return (
    <>
      <IconButton label='Mapa de Sucursal' action={handleOpenMap} icon={<MapIcon />} size='large' />
      <MapDialog isOpen={openMap} handleClose={handleClose} />
    </>
  );
};

const CrmButton = () => {
  const navigate = useNavigate();
  const handleOpenCrm = () => {
    navigate('/panel/report/crm', { replace: true });
  };
  return (
    <IconButton label='CRM' action={handleOpenCrm} icon={<AssignmentTurnedInIcon />} size='large' />
  );
};

const CustomerButton = () => {
  const navigate = useNavigate();
  const handleOpenCrm = () => {
    navigate('/panel/customer', { replace: true });
  };
  return (
    <IconButton
      label={customerLabel({ term: 'plural', textTransform: 'capitalize' })}
      action={handleOpenCrm}
      icon={<PersonIcon />}
      size='large'
    />
  );
};

const FilterButton = () => {
  const handleOpenFilter = () => {
    filterForm(true);
  };
  return (
    <IconButton label='Filtrar' action={handleOpenFilter} icon={<FilterListIcon />} size='large' />
  );
};
const PostponeButton = () => {
  const handleOpenPostpone = () => {
    isPostponeCalendarOpen(true);
  };
  return (
    <IconButton
      label='Pendientes Reagendar'
      action={handleOpenPostpone}
      icon={<UpdateIcon />}
      size='large'
    />
  );
};

const CreateButton = props => {
  const handleOpenCreate = () => {
    const newDate = new Date();
    isCalendarFormOpen([true, { start: newDate, end: newDate }, props.handleStoreEvent]);
  };
  return (
    <IconButton
      color='secondary'
      label='Crear'
      action={handleOpenCreate}
      icon={<AddCircleIcon />}
      size='large'
    />
  );
};

const HeaderComponent = props => {
  const Theme = useTheme();
  const isSmallScreen = useMediaQuery(Theme.breakpoints.down('lg'));
  const [isOpenCalendarControl, setIsOpenCalendarControl] = useState([false, null]);
  const [isOpenPayCalendarControl, setIsOpenPayCalendarControl] = useState([false, null, 0]);

  const { data } = useQuery(GQL.CALENDAR_CONTROLS, { variables: { limit: 0 } });

  const handleAddCalendarControl = async (id, total) => {
    if (!id) return false;
    setIsOpenPayCalendarControl([true, id, total]);
  };
  const handleClosePay = () => setIsOpenPayCalendarControl([false, null, 0]);

  const handleOpenCalendarControl = async id => {
    if (!id) return false;
    setIsOpenCalendarControl([true, id]);
  };

  const handleCloseCalendarControl = () => setIsOpenCalendarControl([false, null]);

  return (
    <>
      <Grid
        container
        alignItems='center'
        className='px-2 border-0 border-b border-solid border-gray-200'
        spacing={0}
      >
        {isSmallScreen ? (
          <Grid item xs={4}>
            <Typography variant='h6' display='block'>
              Calendario
            </Typography>
          </Grid>
        ) : data && data.calendarControls && parseInt(data.calendarControls.count) !== 0 ? (
          <Grid item md={6}>
            <Grid container spacing={0}>
              {data.calendarControls.rows.map((el, i) => (
                <Grid container spacing={0} md={3} alignItems='flex-end'>
                  <Grid item md={2} key={`${el.id}-a`}>
                    <IconButton
                      color='secundary'
                      onClick={() => handleAddCalendarControl(el.id, el.total)}
                      size='small'
                      icon={<AddCircleIcon fontSize='small' color='secondary' />}
                    />
                  </Grid>
                  <Grid item md={10} key={i}>
                    <Typography
                      variant='subtitle2'
                      onClick={() => handleOpenCalendarControl(el.id)}
                      className={parseInt(el.count) <= 0 ? 'text-red-800' : 'text-black'}
                    >
                      {`${el.name} ${el.count || 0}/${el.countTotal}`}
                    </Typography>
                  </Grid>
                </Grid>
              ))}
            </Grid>
          </Grid>
        ) : (
          <Grid item md={6} xs={4}>
            <Typography variant='h6' display='block'>
              Calendario
            </Typography>
          </Grid>
        )}
        <Grid
          item
          md={6}
          xs={8}
          align='right'
          style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}
        >
          <Typography variant='h3' display='block' className='hidden md:block'>
            <Time format='h:mm aaa' />
          </Typography>
          <CreateButton handleStoreEvent={props.handleStoreEvent} />
          <MapButton />
          <CrmButton />
          <CustomerButton />
          <PostponeButton />
          {!isSmallScreen && <FilterButton />}
        </Grid>
      </Grid>
      <Filter />
      <CalendarControlDialog
        isOpen={isOpenCalendarControl[0]}
        id={isOpenCalendarControl[1]}
        handleClose={handleCloseCalendarControl}
      />
      <PayCalendarControlDialog
        isOpen={isOpenPayCalendarControl[0]}
        id={isOpenPayCalendarControl[1]}
        total={isOpenPayCalendarControl[2]}
        handleClose={handleClosePay}
      />
    </>
  );
};

export default HeaderComponent;
