import React from 'react';
import CreateSurveyDialogComponent from './Create';
import useGetSurveys from './helpers/useGetSurveys';
import ModuleV3Component from '../../presentationals/Module.v3';
import RedirectEditFormToRouteComponent from '../../presentationals/RedirectEditFormToRoute';
import useQueryParams from '../../hooks/useQueryParams';
var SurveysComponent = function () {
    var query = useQueryParams();
    var _a = useGetSurveys(), data = _a.data, loading = _a.loading;
    var header = [
        { Header: 'Creado el', accessor: 'createdAt' },
        { Header: 'Nombre', accessor: 'name' },
    ];
    return (React.createElement(React.Fragment, null,
        React.createElement(ModuleV3Component, { showReturnButton: !!query.get('customerId'), route: "/panel/customer/".concat(query.get('customerId'), "/survey"), title: query.get('title') || 'Encuestas', data: data, header: header, isLoading: loading, hideAdd: !!query.get('customerId') }),
        React.createElement(CreateSurveyDialogComponent, null),
        React.createElement(RedirectEditFormToRouteComponent, { queryParams: {
                customerId: query.get('customerId') || undefined,
                title: query.get('title') || undefined,
            } })));
};
export default SurveysComponent;
