var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import Container from '../../presentationals/Module.v2/Container';
import XlsDocGeneratorComponent from './XlsDocGenerator';
import useGetProductInventory from './helpers/useGetProductInventory';
import TableProductInventoryComponent from './Table';
import LoadingProgress from '../../presentationals/LoadingProgress';
import TablePaginationComponent from '../../presentationals/Module.v2/Table/Pagination';
var ProductInventoryReportV2Component = function () {
    var _a = useGetProductInventory(), data = _a.data, loading = _a.loading;
    return (React.createElement(React.Fragment, null,
        React.createElement(Container, { title: 'Inventario de productos', noSearch: true, noFilter: true, noTable: true, customXls: true },
            !data || loading ? React.createElement(LoadingProgress, null) : React.createElement(TableProductInventoryComponent, __assign({}, data)),
            React.createElement(TablePaginationComponent, null)),
        React.createElement(XlsDocGeneratorComponent, null)));
};
export default ProductInventoryReportV2Component;
