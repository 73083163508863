import { setUseContactAddress } from '../../../../../../../../../apollo/cache';

const setCreateBody = values => {
  const setUseContactAddressVar = setUseContactAddress();

  const addressLine2 = values.addressLine2 || '';
  const zipCode = values.zipCode || '';
  const addressLine1 = values.addressLine1 || '';
  const city = values.city || '';
  const municipality = values.municipality || '';
  const state = values.state || '';
  const country = values.country || '';

  return {
    type: values.type || '',
    firstName: values.firstName || '',
    lastName: values.lastName || '',
    phone: values.phone || '',
    phone2: values.phone2 || '',
    addressLine1,
    addressLine2,
    zipCode,
    city,
    state,
    reference: values.reference || '',
    priceName: values.priceName !== -1 ? values.priceName : null,
    creditLimit: parseFloat(values.creditLimit) || 0,
    creditDays: parseFloat(values.creditDays) || 0,
    employeeId:
      values.employeeId && typeof values.employeeId === 'object'
        ? values.employeeId.value
        : values.employeeId,
    discount: values.discount || 0,
    prefix: values.prefix || '',
    birthday: values.birthday || '',
    rateId: values.rateId || '',
    schoolName: values.schoolName || undefined,
    schoolGrade: values.schoolGrade || undefined,
    schoolBlock: values.schoolBlock || undefined,
    admissionDate: values.admissionDate || undefined,
    mainBranch: values.mainBranch || undefined,
    CustomerBillingInformation: {
      taxRegime: values.taxRegime || '',
      identifier: values.identifier || '',
      businessName: values.businessName || '',
      addressLine1: setUseContactAddressVar ? addressLine1 : values.businessAddressLine1 || '',
      addressLine2: setUseContactAddressVar ? addressLine2 : values.businessAddressLine2 || '',
      zipCode: setUseContactAddressVar ? zipCode : values.businessZipCode || '',
      city: setUseContactAddressVar ? city : values.businessCity || '',
      municipality: setUseContactAddressVar ? municipality : values.businessMunicipality || '',
      state: setUseContactAddressVar ? state : values.businessState || '',
      country: setUseContactAddressVar ? country : values.businessCountry || '',
      email: values.email || '',
      altEmail: values.altEmail || '',
      phone: values.phone || '',
      otherEmail: values.otherEmail || '',
      tradename: values.tradename || '',
      isrRetTax:
        ['601', '603'].includes(values.taxRegime) && values.isrRetTax
          ? parseFloat(values.isrRetTax)
          : 0,
    },
  };
};

export default setCreateBody;
