import React from 'react';
import Button from '../../../../../presentationals/Dialog/Footer/Button';
import { inputError } from '../../../../../apollo/cache';
var ClearButtonFooterCreatePaymentMethodComponent = function (props) {
    var handleClick = function () {
        props.onClick();
        inputError({});
    };
    return (React.createElement(Button, { color: 'inherit', className: 'ml-1', onClick: handleClick }, "Limpiar"));
};
export default ClearButtonFooterCreatePaymentMethodComponent;
