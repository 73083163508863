import React, { useRef } from 'react';
import BodyCreateclassificationComponent from './Body';
import ClearButtonFooterCreateClassificationComponent from './Footer/ClearButton';
import CreateButtonFooterCreateClassificationComponent from './Footer/CreateButton';
import FooterDialogComponent from '../../../presentationals/Dialog/Footer';
var CreateClassificationComponent = function () {
    var createButtonFooterCreateClassificationRef = useRef(null);
    var handleCreate = function () {
        var _a;
        (_a = createButtonFooterCreateClassificationRef.current) === null || _a === void 0 ? void 0 : _a.onPressEnter();
    };
    return (React.createElement("div", { className: 'flex flex-1 flex-col h-screen overflow-hidden mt-1' },
        React.createElement(BodyCreateclassificationComponent, { onPressEnter: handleCreate }),
        React.createElement(FooterDialogComponent, null,
            React.createElement("div", null),
            React.createElement("div", null,
                React.createElement(ClearButtonFooterCreateClassificationComponent, null),
                React.createElement(CreateButtonFooterCreateClassificationComponent, { ref: createButtonFooterCreateClassificationRef })))));
};
export default CreateClassificationComponent;
