var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import { useReactiveVar } from '@apollo/client';
import { inputError, productSaleV2 } from '../../../../../apollo/cache';
import SubtitleDialogComponent from '../../../../../presentationals/Dialog/helpers/Subtitle';
import TextFieldComponent from '../../../../../presentationals/FormComponents/TextField';
import ProductTypeFieldProductComponent from '../../../../sale.v2/ChooseProduct/ProductDialog/Create/Body/helpers/ProductTypeField';
import ClassificationFieldProductComponent from '../../../../sale.v2/ChooseProduct/ProductDialog/Create/Body/helpers/ClassificationField';
import UnitBaseFieldProductComponent from '../helpers/UnitBaseField';
import UtilityPercentFieldProductComponent from '../../../../sale.v2/ChooseProduct/ProductDialog/Create/Body/helpers/UtilityPercentField';
import ProviderFieldProductComponent from '../../../../sale.v2/ChooseProduct/ProductDialog/Create/Body/helpers/ProviderField';
import IvaFieldProductComponent from '../helpers/IvaField';
import IepsFieldProductComponent from '../helpers/IepsField';
import ProductKeyProductComponent from '../../../../sale.v2/ChooseProduct/ProductDialog/Create/Body/helpers/ProductKeyField';
import UnitKeyProductComponent from '../../../../sale.v2/ChooseProduct/ProductDialog/Create/Body/helpers/UnitKeyField';
import getSalePriceByCostAndUtilityPercent from '../../../../../helpers/getSalePriceByCostAndUtilityPercent';
import SwitchComponent from '../../../../../presentationals/FormComponents/Switch';
var BodyGeneralEditProductComponent = function () {
    var values = useReactiveVar(productSaleV2);
    var error = useReactiveVar(inputError);
    var handleChange = function (name, value) {
        var _a, _b, _c;
        if (name === 'cost' && parseFloat(values.percentAdjust) > 0) {
            var price = getSalePriceByCostAndUtilityPercent(parseFloat(value), parseFloat(values.percentAdjust));
            productSaleV2(__assign(__assign({}, values), (_a = {}, _a[name] = value, _a.price = price.toFixed(2).toString(), _a)));
            return;
        }
        if (name === 'percentAdjust' && parseFloat(values.cost) > 0) {
            var price = getSalePriceByCostAndUtilityPercent(parseFloat(values.cost), parseFloat(value));
            productSaleV2(__assign(__assign({}, values), (_b = {}, _b[name] = value, _b.price = price.toFixed(2).toString(), _b)));
            return;
        }
        productSaleV2(__assign(__assign({}, values), (_c = {}, _c[name] = value, _c)));
    };
    var handleSwitchChange = function (name, value) {
        var _a;
        productSaleV2(__assign(__assign({}, values), (_a = {}, _a[name] = value, _a)));
    };
    return (React.createElement("div", { className: 'p-3 flex-1 grid grid-cols-1 md:grid-cols-4 gap-4 overflow-auto basis-0' },
        React.createElement("div", null,
            React.createElement(SubtitleDialogComponent, { label: 'Informaci\u00F3n general' }),
            React.createElement(TextFieldComponent, { name: 'name', label: 'Nombre', onChange: handleChange, value: values.name, error: error.name }),
            React.createElement(ProductTypeFieldProductComponent, { onChange: handleChange, value: values.type }),
            React.createElement(UnitBaseFieldProductComponent, { onChange: handleChange, value: values.unitBase }),
            React.createElement(ClassificationFieldProductComponent, { onChange: handleChange, value: values.classificationId }),
            React.createElement(SwitchComponent, { label: '\u00BFSe comercializa por n\u00FAmero de serie?', name: 'isSerialized', onChange: handleSwitchChange, defaultValue: values.isSerialized })),
        React.createElement("div", null,
            React.createElement(SubtitleDialogComponent, { label: 'Identificaci\u00F3n del producto' }),
            React.createElement(TextFieldComponent, { name: 'sku', label: 'SKU', onChange: handleChange, value: values.sku }),
            React.createElement(TextFieldComponent, { name: 'barcode', label: 'C\u00F3digo de barras', onChange: handleChange, value: values.barcode }),
            React.createElement(TextFieldComponent, { name: 'prefix', label: 'Prefijo', onChange: handleChange, value: values.prefix }),
            React.createElement(TextFieldComponent, { name: 'description', label: 'Descripci\u00F3n o caracter\u00EDsticas', onChange: handleChange, value: values.description, multiline: true, rows: 2 })),
        React.createElement("div", null,
            React.createElement(SubtitleDialogComponent, { label: 'Informaci\u00F3n comercial' }),
            React.createElement(TextFieldComponent, { name: 'price', label: "Precio de venta por ".concat(values.unitBase ? values.unitBase.toLowerCase() : ''), onChange: handleChange, value: values.price, error: error.price }),
            React.createElement(TextFieldComponent, { name: 'cost', label: "Precio de compra por ".concat(values.unitBase ? values.unitBase.toLowerCase() : ''), onChange: handleChange, value: values.cost, error: error.cost }),
            React.createElement(UtilityPercentFieldProductComponent, { onChange: handleChange, value: values.percentAdjust, error: error.percentAdjust }),
            React.createElement(ProviderFieldProductComponent, { onChange: handleChange, value: values.providerId, error: error.providerId })),
        React.createElement("div", null,
            React.createElement(SubtitleDialogComponent, { label: 'Impuestos y facturaci\u00F3n' }),
            React.createElement(IvaFieldProductComponent, { value: values.iva, onChange: handleChange, error: error.iva }),
            React.createElement(IepsFieldProductComponent, { value: values.ieps, onChange: handleChange, error: error.ieps }),
            React.createElement(ProductKeyProductComponent, { value: values.productKey, onChange: handleChange, error: error.productKey }),
            React.createElement(UnitKeyProductComponent, { value: values.unitKey, onChange: handleChange, error: error.unitKey })),
        React.createElement("div", null)));
};
export default BodyGeneralEditProductComponent;
