var getBranchMenuValueFromPath = function (pathname) {
    var pathnameParts = pathname.split('/');
    var path = pathnameParts[pathnameParts.length - 1];
    switch (path) {
        case 'general':
            return 0;
        case 'billlinginformation':
            return 1;
        case 'credentials':
            return 2;
        case 'adress':
            return 3;
        case 'setting':
            return 4;
        default:
            return 0;
    }
};
export default getBranchMenuValueFromPath;
