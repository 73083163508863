var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useEffect, useState } from 'react';
import AttachMoneyOutlinedIcon from '@mui/icons-material/AttachMoneyOutlined';
import ListItemPermissionEditRoleComponent from '../helpers/ListItem';
import SwitchPermissionFieldComponent from '../helpers/SwitchPermissionField';
var defaultSalePermissionValues = {
    rePrintSaleTicket: false,
    cancelSale: false,
    cancelInvoice: false,
    cancelServiceOrder: false,
    changeSalePrice: false,
};
var SalePermissionEditRoleComponent = function (props) {
    var _a = useState(defaultSalePermissionValues), values = _a[0], setValues = _a[1];
    useEffect(function () {
        setValues(function (values) { return (__assign(__assign({}, values), props.Permissions)); });
    }, [props.Permissions]);
    return (React.createElement(ListItemPermissionEditRoleComponent, { icon: React.createElement(AttachMoneyOutlinedIcon, null), name: 'Ventas' },
        React.createElement("div", { className: 'mx-4 py-3 grid grid-cols-1 md:grid-cols-3 gap-4' },
            React.createElement("div", { className: 'flex flex-col' },
                React.createElement(SwitchPermissionFieldComponent, __assign({}, props, { name: 'rePrintSaleTicket', label: 'Reimprimir tickets de venta', defaultValue: values.rePrintSaleTicket })),
                React.createElement(SwitchPermissionFieldComponent, __assign({}, props, { name: 'cancelSale', label: 'Cancelar venta', defaultValue: values.cancelSale })),
                React.createElement(SwitchPermissionFieldComponent, __assign({}, props, { name: 'cancelInvoice', label: 'Cancelar facturas', defaultValue: values.cancelInvoice })),
                React.createElement(SwitchPermissionFieldComponent, __assign({}, props, { name: 'cancelServiceOrder', label: 'Cancelar \u00F3rdenes de servicio', defaultValue: values.cancelServiceOrder })),
                React.createElement(SwitchPermissionFieldComponent, __assign({}, props, { name: 'changeSalePrice', label: 'Vender a un precio menor al de venta.', defaultValue: values.changeSalePrice }))))));
};
export default SalePermissionEditRoleComponent;
