import React, { useEffect, useState } from 'react';
import { Divider } from '@mui/material';
import GroupsIcon from '@mui/icons-material/Groups';
import TuneIcon from '@mui/icons-material/Tune';
import TitleDrawerMenuDashboardComponent from '../helpers/Title';
import PaymentMethodLinkBlockFourListItemsMenuDashboardComponent from './PaymentMethodLink';
import BranchLinkBlockFourListItemsMenuDashboardComponent from './BranchLink';
import ParamsLinkBlockFourListItemsMenuDashboardComponent from './ParamsLink';
// import BoxLinkBlockFourListItemsMenuDashboardComponent from './BoxLink';
import EmployeeLinkBlockTwoListItemsMenuDashboardComponent from '../BlockTwo/EmployeesLink';
import RoleLinkBlockTwoListItemsMenuDashboardComponent from '../BlockTwo/RoleLink';
import AttendanceReportLinkBlockTwoListItemsMenuDashboardComponent from '../BlockTwo/AttendanceReportLink';
import useGetSessionRole from '../../../../../hooks/useGetSessionRole';
import getIfShowHumanResources from './helpers/getIfShowHumanReources';
import getIfShowCompanySettings from './helpers/getIfShowCompanySettings';
var BlockFourMenuDashboardComponent = function () {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m;
    var _o = useGetSessionRole(), data = _o.data, loading = _o.loading, isOwner = _o.isOwner;
    var _p = useState(true), showHumanResources = _p[0], setShowHumanResources = _p[1];
    var _q = useState(true), showCompanySettings = _q[0], setShowCompanySettings = _q[1];
    useEffect(function () {
        if (data) {
            var showHumanResources_1 = getIfShowHumanResources(data.Permissions);
            setShowHumanResources(showHumanResources_1 || isOwner);
            var showCompanySettings_1 = getIfShowCompanySettings(data.Permissions);
            setShowCompanySettings(showCompanySettings_1 || isOwner);
        }
    }, [data, isOwner]);
    if (loading || !data || !showHumanResources)
        return null;
    return (React.createElement("div", { className: 'w-full' },
        showHumanResources && (React.createElement(React.Fragment, null,
            React.createElement(TitleDrawerMenuDashboardComponent, { icon: React.createElement(GroupsIcon, null), title: 'Recursos humanos' }),
            (isOwner || !!((_b = (_a = data === null || data === void 0 ? void 0 : data.Permissions) === null || _a === void 0 ? void 0 : _a.Employee) === null || _b === void 0 ? void 0 : _b.getEmployees)) && (React.createElement(EmployeeLinkBlockTwoListItemsMenuDashboardComponent, null)),
            (isOwner || !!((_d = (_c = data === null || data === void 0 ? void 0 : data.Permissions) === null || _c === void 0 ? void 0 : _c.Role) === null || _d === void 0 ? void 0 : _d.getRoles)) && (React.createElement(RoleLinkBlockTwoListItemsMenuDashboardComponent, null)),
            (isOwner || !!((_f = (_e = data === null || data === void 0 ? void 0 : data.Permissions) === null || _e === void 0 ? void 0 : _e.Attendance) === null || _f === void 0 ? void 0 : _f.getAttendance)) && (React.createElement(AttendanceReportLinkBlockTwoListItemsMenuDashboardComponent, null)),
            React.createElement(Divider, { className: 'my-2' }))),
        showCompanySettings && (React.createElement(React.Fragment, null,
            React.createElement(TitleDrawerMenuDashboardComponent, { icon: React.createElement(TuneIcon, null), title: 'Configuraci\u00F3n de la empresa' }),
            (isOwner || !!((_h = (_g = data === null || data === void 0 ? void 0 : data.Permissions) === null || _g === void 0 ? void 0 : _g.Company) === null || _h === void 0 ? void 0 : _h.getCompanySettings)) && (React.createElement(ParamsLinkBlockFourListItemsMenuDashboardComponent, null)),
            (isOwner || !!((_k = (_j = data === null || data === void 0 ? void 0 : data.Permissions) === null || _j === void 0 ? void 0 : _j.Branch) === null || _k === void 0 ? void 0 : _k.getBranches)) && (React.createElement(BranchLinkBlockFourListItemsMenuDashboardComponent, null)),
            (isOwner || !!((_m = (_l = data === null || data === void 0 ? void 0 : data.Permissions) === null || _l === void 0 ? void 0 : _l.PaymentMethod) === null || _m === void 0 ? void 0 : _m.getPaymentMethods)) && (React.createElement(PaymentMethodLinkBlockFourListItemsMenuDashboardComponent, null)),
            React.createElement(Divider, { className: 'my-2' })))));
};
export default BlockFourMenuDashboardComponent;
