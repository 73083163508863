var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import { useReactiveVar } from '@apollo/client';
import SubtitleDialogComponent from '../../../../presentationals/Dialog/helpers/Subtitle';
import TextFieldComponent from '../../../../presentationals/FormComponents/TextField';
import { inputError, productPromotionPriceForm } from '../../../../apollo/cache';
import PlatformFieldProductPromotionPriceComponent from './PlatformField';
import BranchFieldProductPromotionPriceComponent from './BranchField';
import PriceNameSelectFieldHelpersBodyCreateFormCustomerActionSaleV2Component from '../../../sale.v2/Action/Customer/Form/Create/Body/helpers/PriceNameSelectField';
import FieldContainerProductIngredientComponent from '../../../product/ProductPresentation/Component/Create/Body/FieldContainer';
import OpenCreateRateButtonComponent from './OpenCreateRateButton';
import DeleteRateButtonComponent from './DeleteRateButton';
var BodyProductPromotionPriceComponent = function (props) {
    var errors = useReactiveVar(inputError);
    var values = useReactiveVar(productPromotionPriceForm);
    var handleChange = function (name, value) {
        var _a;
        productPromotionPriceForm(__assign(__assign({}, values), (_a = {}, _a[name] = value, _a)));
    };
    return (React.createElement("div", { className: 'flex-1 px-6 py-4 flex flex-col md:grid md:grid-cols-4 md:gap-4 overflow-auto basis-0' },
        React.createElement("div", null,
            React.createElement(SubtitleDialogComponent, { label: 'Datos generales' }),
            props.showRatesNames && (React.createElement("div", { className: 'flex flex-row items-end' },
                React.createElement(PriceNameSelectFieldHelpersBodyCreateFormCustomerActionSaleV2Component, { name: 'rateId', label: 'Nombre de la tarifa asignada', value: values.rateId, error: errors.rateId, handleChange: handleChange }),
                !values.rateId ? (React.createElement(OpenCreateRateButtonComponent, null)) : (React.createElement(DeleteRateButtonComponent, { disabled: values.rateId === -1, rateId: values.rateId })))),
            React.createElement(FieldContainerProductIngredientComponent, null,
                React.createElement(TextFieldComponent, { name: 'price', label: 'Precio de venta', value: values.price, error: errors.price, onChange: handleChange })),
            React.createElement(FieldContainerProductIngredientComponent, null,
                React.createElement(PlatformFieldProductPromotionPriceComponent, { onChamge: handleChange, value: values.type })),
            React.createElement(FieldContainerProductIngredientComponent, null,
                React.createElement(BranchFieldProductPromotionPriceComponent, { onChange: handleChange, value: values.branchId })))));
};
export default BodyProductPromotionPriceComponent;
