var getPlaneWidth = function (width) {
    switch (width) {
        case 100:
            return 'w-[66.66px] sm:w-[150px]';
        case 200:
            return 'w-[133.33px] sm:w-[300px]';
        case 300:
            return 'w-[200px] sm:w-[450px]';
        default:
            return '';
    }
};
export default getPlaneWidth;
