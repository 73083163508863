var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import { Menu } from '@mui/material';
import IconButtonComponent from '../../../../../IconButton';
import MenuActionModuleV3ItemComponent from './MenuItem';
var ModuleV3ActionComponent = function (props) {
    var _a = React.useState(null), anchorEl = _a[0], setAnchorEl = _a[1];
    var open = Boolean(anchorEl);
    var handleClick = function (event) {
        event.stopPropagation();
        event.nativeEvent.stopPropagation();
        event.nativeEvent.stopImmediatePropagation();
        if (props.actions) {
            setAnchorEl(event.currentTarget);
            return;
        }
        props.onClick && props.onClick(props.identifier);
    };
    var handleClose = function () {
        setAnchorEl(null);
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(IconButtonComponent, { size: 'small', disabled: props.loading, icon: props.icon, action: handleClick, label: props.label }),
        props.actions && (React.createElement(Menu, { id: "table-option-menu-".concat(props.identifier), anchorEl: anchorEl, open: open, onClose: handleClose, MenuListProps: {
                'aria-labelledby': "basic-table-option-button-".concat(props.identifier),
                role: 'listbox',
            } }, props.actions.map(function (el) { return (React.createElement(MenuActionModuleV3ItemComponent, __assign({ key: el.label }, el, { identifier: props.identifier }))); })))));
};
export default ModuleV3ActionComponent;
