import React from 'react';
import { Link } from 'react-router-dom';
var BocadosDelCieloProductListComponent = function () {
    return (React.createElement("div", { className: 'w-full h-screen flex flex-1 flex-col px-16 py-6 bg-gray-900 overflow-auto' },
        React.createElement("div", { className: 'text-5xl font-bold text-[#ffb600]' }, "BOCADOS DEL CIELO"),
        React.createElement("div", { className: 'text-4xl font-bold text-[#ffe000] mt-2' }, "Elije tu antojo"),
        React.createElement(Link, { to: '/bocadosdelcielodrinks/menu', className: 'text-white no-underline tracking-tighter text-2xl my-1' }, "BEBIDAS"),
        React.createElement(Link, { to: '/bsburger/menu', className: 'text-white no-underline tracking-tighter text-2xl my-1' }, "BS BURGER"),
        React.createElement(Link, { to: '/makaosushi/menu', className: 'text-white no-underline tracking-tighter text-2xl my-1' }, "MAKAO SUSHI"),
        React.createElement(Link, { to: '/bocadosdelcielomariscos/menu', className: 'text-white no-underline tracking-tighter text-2xl my-1' }, "MARISCOS"),
        React.createElement(Link, { to: '/mrpancake/menu', className: 'text-white no-underline tracking-tighter text-2xl my-1' }, "MR PANCAKE"),
        React.createElement(Link, { to: '/muchimania/menu', className: 'text-white no-underline tracking-tighter text-2xl my-1' }, "MUNCHI MANIA"),
        React.createElement(Link, { to: '/mrpancakereposteria/menu', className: 'text-white no-underline tracking-tighter text-2xl my-1' }, "POSTRES BY MR PANCAKE"),
        React.createElement(Link, { to: '/shirosbobas/menu', className: 'text-white no-underline tracking-tighter text-2xl my-1' }, "SHIRO'S BOBA'S"),
        React.createElement(Link, { to: '/tacosdonpolo/menu', className: 'text-white no-underline tracking-tighter text-2xl my-1' }, "TACOS DON POLO"),
        React.createElement(Link, { to: '/theramenfactory/menu', className: 'text-white no-underline tracking-tighter text-2xl my-1' }, "THE RAMEN FACTORY")));
};
export default BocadosDelCieloProductListComponent;
