var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React from 'react';
import SelectFieldComponent from '../../../../../presentationals/FormComponents/SelectField';
var CashFlowActionFieldComponent = function (props) {
    props.includeAllOptions;
    var options = [
        { label: 'Retiro', value: 1 },
        { label: 'Depósito', value: 2 },
        { label: 'Caja fuerte', value: 3 },
    ];
    return (React.createElement(SelectFieldComponent, { name: 'action', label: 'Acci\u00F3n / Operaci\u00F3n', value: props.value, onChange: props.onChange, options: __spreadArray(__spreadArray([], [props.includeAllOptions && { label: 'Todas las opciones', value: -1 }].filter(function (el) { return el; }), true), options, true) }));
};
export default CashFlowActionFieldComponent;
