import jsPDF from 'jspdf';
import format from 'date-fns/format';
import locale from 'date-fns/locale/es';

import client from '../../apollo';
import { phoneFormat, currencyFormat } from '../../helpers';

import GQL from './_gql';

const getDataFromServer = async id => {
  try {
    const { data } = await client.mutate({
      mutation: GQL.GET_BY_ID,
      variables: { id },
    });
    return data.transfer;
  } catch (e) {
    console.log(e);
  }
};

const Pdf = async id => {
  const data = await getDataFromServer(id);
  const products = data.TransferProducts.sort((a, b) => parseInt(a.id) - parseInt(b.id));
  const currentDate = format(new Date(), 'dd/MM/yyyy HH:mm:ss', { locale });
  const folio = `${data.folio}-${data.serial}`;
  const zipCode = data.Branch.zipCode ? `${data.Branch.zipCode},` : '';
  const city = data.Branch.city ? `${data.Branch.city},` : '';
  const state = data.Branch.state ? `${data.Branch.state},` : '';
  const createdAt = format(new Date(data.createdAt), 'dd/MM/yyyy HH:mm', { locale });
  const branchTransfer = data.BranchTransfer.name;

  const doc = new jsPDF();

  doc.setProperties({
    title: `traspaso_${folio}`,
  });

  let breakLine = 20;

  doc.setFontSize(11);
  doc.setFont('times', 'bold');
  doc.text(data ? data.Company.name : '-', 105, breakLine, 'center');

  if (data && data.Company.name !== data.Branch.name) {
    doc.setFontSize(9);
    breakLine += 4;
    doc.text(data.Branch.name, 105, breakLine, 'center');
  }

  doc.setFont('times', 'normal');
  doc.setFontSize(10);
  breakLine += 8;
  doc.text(
    doc.splitTextToSize(`${data.Branch.addressLine1}, ${data.Branch.addressLine2}`, 130),
    105,
    breakLine,
    'center',
  );
  breakLine += 5;
  doc.text(`CP ${zipCode} ${city} ${state} México`, 105, breakLine, 'center');

  if (data.Branch.phone || data.Branch.altPhone) {
    breakLine += 5;
    doc.text(
      `Tel. ${phoneFormat(data.Branch.phone || data.Branch.altPhone)}`,
      105,
      breakLine,
      'center',
    );
  }

  doc.setFontSize(14);
  doc.setFont('times', 'bold');

  breakLine += 10;
  doc.text('TRASPASO DE PRODUCTOS', 105, breakLine, 'center');

  doc.text(`TRA ${folio}`, 195, 18, 'right');

  doc.setFontSize(10);

  doc.setFont('times', 'bold');
  doc.text('Fecha de Emisión', 195, 24, 'right');
  doc.setFont('times', 'normal');
  doc.text(createdAt, 195, 28, 'right');

  doc.setFont('times', 'bold');
  doc.text('Fecha de Impresión', 195, 33, 'right');
  doc.setFont('times', 'normal');
  doc.text(currentDate, 195, 37, 'right');

  doc.setFont('times', 'bold');
  doc.text('Sucursal Destino', 195, 42, 'right');
  doc.setFont('times', 'normal');
  doc.text(branchTransfer, 195, 46, 'right');

  breakLine = data.Branch.phone || data.Branch.altPhone ? 57 : 53;

  doc.setFontSize(9);
  doc.setFont('times', 'bold');

  doc.setDrawColor(30, 30, 30);
  doc.setFillColor(30, 30, 30);
  doc.rect(10, breakLine - 3, 185, 4, 'FD');
  doc.setTextColor(255, 255, 255);
  doc.text('Conceptos', 105, breakLine, 'center');

  breakLine += 5;

  doc.setDrawColor(30, 30, 30);
  doc.setFillColor(30, 30, 30);
  doc.rect(10, breakLine - 3, 185, 4, 'FD');
  doc.setTextColor(255, 255, 255);

  doc.text('Cantidad', 19, breakLine, 'center');
  doc.text('Presentación', 40, breakLine, 'center');
  doc.text('Descripción', 56, breakLine);
  doc.text('Existencia Previa', 120, breakLine, 'center');
  doc.text('Costo Unitario', 170, breakLine, 'right');
  doc.text('Importe', 192, breakLine, 'right');

  doc.setFont('times', 'normal');
  doc.setTextColor(0);

  breakLine += 4;

  products.forEach(el => {
    const name = el.Product ? el.Product.name : '-';
    const presentation = el.ProductPresentation ? el.ProductPresentation.name : '-';

    doc.setDrawColor(244, 244, 244);
    doc.setFillColor(244, 244, 244);
    doc.rect(10, breakLine - 3, 185, 5, 'FD');

    doc.text(`${el.quantity.toFixed(3)}`, 19, breakLine, 'center');
    doc.text(presentation, 40, breakLine, 'center');
    doc.text(name, 56, breakLine);
    doc.text(`${el.currentQuantity.toFixed(3)}`, 120, breakLine, 'center');
    doc.text(currencyFormat(el.cost), 170, breakLine, 'right');
    doc.text(currencyFormat(el.total), 192, breakLine, 'right');

    breakLine += 5;
  });

  doc.setFontSize(10);

  doc.setDrawColor(30, 30, 30);
  doc.setFillColor(30, 30, 30);
  doc.rect(10, breakLine, 125, 4, 'FD');
  doc.rect(138, breakLine, 32, 22, 'FD');

  doc.setDrawColor(244, 244, 244);
  doc.setFillColor(244, 244, 244);
  doc.rect(171, breakLine, 25, 22, 'FD');

  doc.setTextColor(255, 255, 255);

  doc.setFont('times', 'bold');

  doc.text('Comentarios', 12, breakLine + 3);

  doc.setFont('times', 'normal');
  doc.text('Subtotal', 168, breakLine + 4, 'right');
  doc.text('IVA', 168, breakLine + 8, 'right');
  doc.text('IEPS', 168, breakLine + 12, 'right');
  doc.text('Impuestos', 168, breakLine + 16, 'right');

  doc.setFont('times', 'bold');
  doc.text('Total', 168, breakLine + 21, 'right');

  doc.setTextColor(0);
  doc.setFont('times', 'normal');
  doc.text(currencyFormat(data.subtotal), 193, breakLine + 4, 'right');
  doc.text(currencyFormat(data.ivaTotal), 193, breakLine + 8, 'right');
  doc.text(currencyFormat(data.iepsTotal), 193, breakLine + 12, 'right');
  doc.text(currencyFormat(data.taxes), 193, breakLine + 16, 'right');

  doc.setFont('times', 'bold');
  doc.text(currencyFormat(data.total), 193, breakLine + 21, 'right');

  const receptionText = data.description ? data.description : '';

  doc.setDrawColor(244, 244, 244);
  doc.setFillColor(244, 244, 244);
  doc.rect(10, breakLine + 4, 125, 10, 'FD');

  doc.setFont('times', 'normal');
  doc.setTextColor(0);
  doc.text(doc.splitTextToSize(receptionText, 134), 12, breakLine + 8);

  doc.setFont('times', 'bold');
  doc.text('Emitido por:', 12, breakLine + 20);
  doc.text('Autorizado por:', 12, breakLine + 24);

  doc.setFont('times', 'normal');
  doc.text(`${data.Employee.firstName} ${data.Employee.lastName}`, 38, breakLine + 20);
  doc.text(
    data.AuthEmployee
      ? `${data.AuthEmployee.firstName} ${data.AuthEmployee.lastName}`
      : 'Pendiente Autorización',
    38,
    breakLine + 24,
  );

  doc.setDrawColor(0);
  doc.setFillColor(0);
  doc.setFontSize(10);

  doc.line(85, breakLine + 46, 125, breakLine + 46);
  doc.text('Nombre, Firma Y Hora', 105, breakLine + 50, 'center');

  doc.line(85, breakLine + 58, 125, breakLine + 58);
  doc.text('Nombre, Firma Y Hora de Recibido', 105, breakLine + 64, 'center');

  doc.text(`Este documento no tiene validez fiscal. Fecha de Impresión: ${currentDate}.`, 10, 280);
  if (parseInt(data && data.Company.id) === 84)
    doc.text('Documento para archivo de bodega', 10, 284);

  window.open(doc.output('bloburl'), '_blank');
};

export default Pdf;
