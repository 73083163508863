var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
var getHeadersToExport = function (header) {
    return header.reduce(function (array, el) {
        if (el.accessor)
            return __spreadArray(__spreadArray([], array, true), [{ label: el.Header, key: el.accessor }], false);
        if (el.columns) {
            var columnsAccessor = el.columns
                .filter(function (elCh) { return elCh.accessor; })
                .map(function (elCh) { return ({ label: "".concat(el.Header, " ").concat(elCh.Header), key: elCh.accessor }); });
            return __spreadArray(__spreadArray([], array, true), columnsAccessor, true);
        }
        return array;
    }, []);
};
export default getHeadersToExport;
