var getPlaneHeight = function (height) {
    switch (height) {
        case 100:
            return 'h-[66.66px] sm:h-[150px]';
        case 200:
            return 'h-[133.33px] sm:h-[300px]';
        case 300:
            return 'h-[200px] sm:h-[435px]';
        case 600:
            return 'h-[400px] sm:h-[870px]';
        default:
            return '';
    }
};
export default getPlaneHeight;
