import React from 'react';
import Card from '@mui/material/Card';
import Copyright from '../Copyright';
import Table from '../Table';
import Totals from '../Totals';
import IconButton from '../../IconButton';
import Title from './Title';
import Search from './SearchButton';
import SearchButton from './SearchButtonPhone';
import CreateButton from './CreateButton';
import FilterButton from './FilterButton';
import ConfigButton from './ConfigButton';
import MenuButton from './MenuButton';
import GoBackButtonComponent from '../../GoBackButton';

const ContainerComponent = props => {
  return (
    <div className='flex flex-1 flex-col bg-[#f6f6f6] px-2 py-1'>
      <div className='flex items-center justify-between'>
        <div className='flex items-center'>
          <GoBackButtonComponent route={-1} />
          <Title name={props.title} subTitle={props.subTitle} />
          {!props.noSearch && (
            <Search
              searchFieldOptions={props.searchFieldOptions}
              marginleft={5}
              name={props.altSearchButtonKey}
              title={props.title}
            />
          )}
        </div>
        <div className='flex items-center'>
          {(!!props.create || props.handleCustomCreate) && (
            <CreateButton handleCustomCreate={props.handleCustomCreate} />
          )}
          {!props.noSearch && <SearchButton name={props.altSearchButtonKey} title={props.title} />}
          {props.extra && (
            <IconButton
              label={props.extra.label}
              action={props.extra.action}
              icon={props.extra.icon}
              size='large'
            />
          )}
          {props.config && <ConfigButton />}
          {props.filter && <FilterButton />}
          {!props.noMenu && (
            <MenuButton
              customXls={props.customXls}
              additionalAction={props.additionalAction}
              customPdf={props.customPdf}
            />
          )}
        </div>
      </div>
      <Totals />
      <Card className='flex-1 flex flex-col basis-0 my-1 mx-0'>
        {!props.noTable && (
          <Table
            title={props.title}
            operation={props.operation}
            Rows={props.Rows}
            delete={props.delete}
            edit={props.edit}
            get={props.get}
          />
        )}
        {props.children}
      </Card>
      <div className='py-2'>
        <Copyright />
      </div>
    </div>
  );
};

export default ContainerComponent;
