import React, { useEffect, useState } from 'react';
import useGetTransactions from './helpers/useGetTransactions';
import LoadingProgress from '../../presentationals/LoadingProgress';
import ErrorDataComponent from '../../presentationals/ErrorData';
import HeaderModuleV3Component from '../../presentationals/Module.v3/Header';
import FilterTransactionReportDialog from './Filter';
import TransactionChartComponent from './Chart';
import TotalTransactionByPaymentFormComponent from './TotalByPaymentForm';
import FooterModuleV3Component from '../../presentationals/Module.v3/Footer';
import BodyTransactionReportComponent from './Body';
import TableSettingsTransactionComponent from './TableSettings';
import getLocalStorageShowTransactionGraph from '../../helpers/getLocalStorageShowTransactionGraph';
var TransactionReportComponent = function () {
    var _a = useGetTransactions(), data = _a.data, loading = _a.loading;
    var _b = useState(false), showGraph = _b[0], setShowGraph = _b[1];
    useEffect(function () {
        var showGraph = getLocalStorageShowTransactionGraph();
        setShowGraph(showGraph);
    }, []);
    if (loading)
        return React.createElement(LoadingProgress, null);
    if (!data)
        return React.createElement(ErrorDataComponent, null);
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: 'flex flex-1 flex-col p-3 bg-slate-50' },
            React.createElement(HeaderModuleV3Component, { tableSetting: true, isLoading: loading, title: 'Transacciones de ventas', filter: true, hideAdd: true, showReturnButton: true, route: -1 }),
            React.createElement("div", { className: 'flex flex-1 flex-col' },
                React.createElement(TotalTransactionByPaymentFormComponent, { data: data }),
                React.createElement("div", { className: 'grid grid-cols-1 sm:grid-cols-4 flex-1 gap-0 sm:gap-4' },
                    React.createElement("div", { className: "flex ".concat(showGraph ? 'col-span-3' : 'col-span-4', " mb-1") },
                        React.createElement(BodyTransactionReportComponent, { loading: loading, data: data })),
                    showGraph && (React.createElement("div", { className: 'hidden sm:flex flex-1 rounded border-solid border border-slate-300 items-center justify-center mt-2 mb-1 p-4' }, data.length > 0 ? (React.createElement(TransactionChartComponent, { data: data })) : (React.createElement("div", { className: 'text-center' }, "La gr\u00E1fica no cuenta con suficientes datos para mostrar.")))))),
            React.createElement(FooterModuleV3Component, null)),
        React.createElement(FilterTransactionReportDialog, null),
        React.createElement(TableSettingsTransactionComponent, null)));
};
export default TransactionReportComponent;
