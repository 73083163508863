var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useState } from 'react';
import FilterDrawer from '../../../presentationals/FilterDrawer.v2';
import { filter } from '../../../apollo/cache';
import PaymentFormFieldComponent from '../helpers/PaymentFormField';
import getServerPaymentForm from './helpers/getServerPaymentForm';
var defaultValues = {
    paymentForm: '0',
};
var FilterTransactionPayableReportDialogComponent = function () {
    var _a = useState(defaultValues), values = _a[0], setValues = _a[1];
    var handleAction = function (e, data) {
        var start = data.startDate, end = data.endDate, branchId = data.branchId;
        filter(__assign({ start: start, end: end, branchId: branchId }, getServerPaymentForm(values.paymentForm)));
    };
    var handleChange = function (name, value) {
        setValues(function (values) {
            var _a;
            return (__assign(__assign({}, values), (_a = {}, _a[name] = value, _a)));
        });
    };
    return (React.createElement(FilterDrawer, { handleAction: handleAction, showDates: true, showBranches: true },
        React.createElement(PaymentFormFieldComponent, { onChange: handleChange, value: values.paymentForm })));
};
export default FilterTransactionPayableReportDialogComponent;
