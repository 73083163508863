import CREATE_TRANSACTION_ACCOUNT from './createTransactionAccount';
import FRAGMENT_TRANSACTION_ACCOUNT from './fragmentTransactionAccount';
import GET_PAYMENT_FORMS from './getPaymentForms';
import GET_TRANSACTION_ACCOUNTS from './getTransactionAccounts';
var GQL_PAYMENT_FORM = {
    CREATE_TRANSACTION_ACCOUNT: CREATE_TRANSACTION_ACCOUNT,
    FRAGMENT_TRANSACTION_ACCOUNT: FRAGMENT_TRANSACTION_ACCOUNT,
    GET_PAYMENT_FORMS: GET_PAYMENT_FORMS,
    GET_TRANSACTION_ACCOUNTS: GET_TRANSACTION_ACCOUNTS,
};
export default GQL_PAYMENT_FORM;
