import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { DateRange } from 'react-date-range';
import localeEs from 'date-fns/locale/es';
import { Button, Popover } from '@mui/material';
var DateRangeCalendarFieldComponent = forwardRef(function (props, ref) {
    var _a = useState(null), anchorEl = _a[0], setAnchorEl = _a[1];
    var _b = useState([
        {
            startDate: new Date(),
            endDate: new Date(),
            key: 'selection',
        },
    ]), dateRange = _b[0], setDateRange = _b[1];
    var handleOpen = function (anchorEl) {
        setAnchorEl(anchorEl);
    };
    var handleClose = function () {
        setAnchorEl(null);
    };
    var handleChange = function (item) {
        setDateRange([item.selection]);
    };
    var handleClick = function () {
        props.onChange(dateRange[0].startDate || new Date(), dateRange[0].endDate || new Date());
        handleClose();
    };
    useImperativeHandle(ref, function () { return ({
        open: handleOpen,
    }); });
    return (React.createElement(Popover, { id: 'DateRangeCalendarPopover', open: !!anchorEl, anchorEl: anchorEl, onClose: handleClose, anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
        }, transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
        } },
        React.createElement("div", { className: 'flex flex-1 flex-col' },
            React.createElement(DateRange, { locale: localeEs, onChange: handleChange, months: 2, ranges: dateRange, direction: 'horizontal', showDateDisplay: false, showPreview: false }),
            React.createElement("div", { className: 'flex justify-end' },
                React.createElement(Button, { variant: 'contained', color: 'success', size: 'small', className: 'm-2', onClick: handleClick }, "Continuar")))));
});
export default DateRangeCalendarFieldComponent;
