import React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import OpenAddComponentButtonComponent from './OpenAddComponentButton';
import NoComponentAlertProductPresentationComponent from './NoComponentAlert';
import ItemTableComponentProductPresentationComponent from './Item';
var BodyComponentProductPresentationComponent = function (props) {
    return (React.createElement("div", { className: 'p-3 flex flex-1 flex-col' },
        React.createElement("div", { className: 'flex items-center' },
            React.createElement("div", { className: 'text-base font-medium mb-1 flex flex-1' }, "Componentes de la presentaci\u00F3n del producto"),
            React.createElement(OpenAddComponentButtonComponent, null)),
        React.createElement("div", { className: 'text-sm sm:text-base text-gray-600' }, "Al hablar de los componentes, nos referimos a los insumos o materia prima que conforman este producto. En esta secci\u00F3n podr\u00E1s ajustar las caracter\u00EDsticas de los componentes, definir los precios de manera precisa y configurar su comportamiento para adaptarlo a tus necesidades \u00FAnicas."),
        React.createElement("div", { className: 'pt-4 flex flex-1 flex-col' }, props.ProductIngredients.length === 0 ? (React.createElement(NoComponentAlertProductPresentationComponent, null)) : (React.createElement("div", { className: 'flex flex-1 flex-col basis-0 overflow-auto w-full' },
            React.createElement(TableContainer, { className: 'rounded border border-solid border-[#d8d8d8]' },
                React.createElement(Table, { stickyHeader: true, size: 'small' },
                    React.createElement(TableHead, null,
                        React.createElement(TableRow, { className: 'bg-[#f6f6f6]' },
                            React.createElement(TableCell, null, "Tipo"),
                            React.createElement(TableCell, null, "Insumo/materia prima"),
                            React.createElement(TableCell, null, "Porciones en producto por default"),
                            React.createElement(TableCell, null, "Cantidad por porci\u00F3n"),
                            React.createElement(TableCell, null, "Precio estandar por porci\u00F3n"),
                            React.createElement(TableCell, null, "Insumos gratuitos"))),
                    React.createElement(TableBody, null, props.ProductIngredients.map(function (el, i) { return (React.createElement(ItemTableComponentProductPresentationComponent, { key: el.id, index: i, ProductIngredient: el })); })))))))));
};
export default BodyComponentProductPresentationComponent;
