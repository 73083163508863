import React from 'react';
import TodayIcon from '@mui/icons-material/Today';
import { useNavigate } from 'react-router-dom';
import AppMenuButtonComponent from '../helpers/AppMenuButton';
var CalendarMenuButtonComponent = function () {
    var navigate = useNavigate();
    var handleClick = function () {
        navigate('calendar');
    };
    return (React.createElement(AppMenuButtonComponent, { label: 'Calendario', icon: React.createElement(TodayIcon, { fontSize: 'large' }), onClick: handleClick }));
};
export default CalendarMenuButtonComponent;
