import React, { useState, useEffect } from 'react';

import { useQuery } from '@apollo/client';

import Typography from '@mui/material/Typography';

import FilterDrawer from '../../presentationals/FilterDrawer.v2';
import Autocomplete from '../../presentationals/Autocomplete';
import { filter } from '../../apollo/cache';

import SelectField from '../../presentationals/FormComponents/SelectField';
import GQL_PROVIDER from '../../apollo/gql/provider';

const defaultValue = {
  provider: null,
  duePayment: 1,
};

const yesNoOptions = [
  { label: 'Todos', value: 1 },
  { label: 'Por vencer', value: 2 },
  { label: 'Vencido', value: 3 },
];

const FilterDialog = () => {
  const [providers, setProviders] = useState([]);
  const [values, setValues] = useState(defaultValue);

  const { data } = useQuery(GQL_PROVIDER.GET_PROVIDERS, { variables: { limit: 0 } });

  useEffect(() => {
    if (data) {
      const tmp = data.providers.rows.map(el => ({
        label: el.name,
        value: el.id,
      }));
      setProviders(tmp);
    }
  }, [data]);

  const handleAction = (e, filterValues) => {
    filter({
      providerId: values.provider && values.provider.value,
      duePayment: values.duePayment,
      branchId:
        filterValues.branchId === -1
          ? null
          : !filterValues.branchId
          ? undefined
          : filterValues.branchId,
    });
  };

  const handleAutocompleteChange = (e, name, value) => {
    setValues(values => ({ ...values, [name]: value }));
  };
  const handleChange = (name, value) => {
    setValues(values => ({ ...values, [name]: value }));
  };

  return (
    <FilterDrawer includeAllOption showBranches handleAction={handleAction}>
      <Typography variant='subtitle1'>Proveedor:</Typography>
      <Autocomplete
        name='provider'
        variant='outlined'
        onChange={handleAutocompleteChange}
        options={providers}
        value={values.provider}
        autoFocus
        noFormat
      />
      <Typography variant='subtitle1'>Vencimiento:</Typography>
      <SelectField
        name='duePayment'
        variant='outlined'
        onChange={handleChange}
        options={yesNoOptions}
        value={values.duePayment}
        autoFocus
      />
    </FilterDrawer>
  );
};

export default FilterDialog;
