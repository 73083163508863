import getTextWidth from '../Table/helpers/getTextWidth';
var getWidthPerColumn = function (data, header) {
    try {
        if (!header || header.length === 0)
            return [];
        var defaultArray_1 = header.map(function (el) {
            return el.columns
                ? el.columns.reduce(function (total, elCh) { return getTextWidth(elCh.Header, 'bold 14pt arial') + 60 + total; }, 0)
                : getTextWidth(el.Header, 'bold 14pt arial') + 60;
        });
        if (!data || data.length === 0)
            return defaultArray_1;
        var newArray = header.reduce(function (array, el, i) {
            if (el.columns) {
                var maxWidthByColumns_1 = data.reduce(function (maxWidth, elCh) {
                    var _a;
                    var currentWidth = (_a = el.columns) === null || _a === void 0 ? void 0 : _a.reduce(function (total, child) {
                        if (!child.accessor)
                            return total;
                        return total + getTextWidth(elCh[child.accessor], 'normal 14pt arial');
                    }, 0);
                    return currentWidth > maxWidth ? currentWidth : maxWidth;
                }, defaultArray_1[i]);
                return array.map(function (width, ii) { return (ii === i ? maxWidthByColumns_1 : width); });
            }
            if (!el.accessor)
                return array;
            var accessor = el.accessor;
            var maxWidth = data.reduce(function (maxWidth, elCh) {
                var currentWidth = getTextWidth(elCh[accessor], 'normal 14pt arial');
                return currentWidth > maxWidth ? currentWidth : maxWidth;
            }, defaultArray_1[i]);
            return array.map(function (width, ii) { return (ii === i ? maxWidth : width); });
        }, defaultArray_1);
        return newArray;
    }
    catch (error) {
        return [];
    }
};
export default getWidthPerColumn;
