var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { useReactiveVar } from '@apollo/client';
import useGetSession from '../../../../../hooks/useGetSession';
import { inputError } from '../../../../../apollo/cache';
import SelectBranchFieldComponent from '../../../../../presentationals/FormComponents/SelectBranchField';
import SelectFieldComponent from '../../../../../presentationals/FormComponents/SelectField';
import IsZeroFieldComponent from './IsZeroField';
var BodyMainAdjustInventoryComponent = forwardRef(function (props, ref) {
    var defaultValues = {
        branchId: '',
        isZero: false,
        adjustType: '1',
    };
    var session = useGetSession();
    var adjustTypeOptions = [
        { label: 'Sustituir', value: '1' },
        { label: 'Entrada', value: '2' },
        { label: 'Salida', value: '3' },
    ];
    var errors = useReactiveVar(inputError);
    var _a = useState(defaultValues), values = _a[0], setValues = _a[1];
    useEffect(function () {
        var _a;
        if ((_a = session === null || session === void 0 ? void 0 : session.Company) === null || _a === void 0 ? void 0 : _a.Branch) {
            var branchId_1 = session.Company.Branch.id.toString();
            setValues(function (values) { return (__assign(__assign({}, values), { branchId: branchId_1 })); });
        }
    }, [session]);
    var handleChange = function (name, value) {
        setValues(function (values) {
            var _a;
            return (__assign(__assign({}, values), (_a = {}, _a[name] = value, _a)));
        });
    };
    var handleIsZeroChange = function (name, value) {
        setValues(function (values) {
            var _a;
            return (__assign(__assign({}, values), (_a = {}, _a[name] = value, _a)));
        });
    };
    var getValues = function () { return values; };
    useImperativeHandle(ref, function () { return ({
        getValues: getValues,
    }); });
    return (React.createElement("div", { className: 'flex flex-1 flex-col px-4 py-3 basis-0 overflow-auto' },
        React.createElement("div", { className: 'grid flex-1 grid-cols-1 sm:grid-cols-4' },
            React.createElement("div", { className: 'flex flex-1 flex-col' },
                React.createElement(SelectBranchFieldComponent, { label: 'Sucursal que ser\u00E1 afectada por el ajuste de inventario', defaultValue: values.branchId, onChange: handleChange, disallowAllOptions: true }),
                React.createElement(SelectFieldComponent, { name: 'adjustType', label: 'Tipo de ajuste', onChange: handleChange, options: adjustTypeOptions, value: values.adjustType, error: errors.adjustType }),
                React.createElement(IsZeroFieldComponent, { value: values.isZero, name: 'isZero', onChange: handleIsZeroChange })))));
});
export default BodyMainAdjustInventoryComponent;
