import React from 'react';
import { inputError } from '../../../../../../../../apollo/cache';
import Button from '../../../../../../../../presentationals/Dialog/Footer/Button';
var ClearButtonFooterCreateFormCustomerActionSaleV2Component = function (props) {
    var handleClick = function () {
        props.onClick();
        inputError({});
    };
    return (React.createElement(Button, { color: 'inherit', className: 'ml-1', onClick: handleClick }, "Limpiar"));
};
export default ClearButtonFooterCreateFormCustomerActionSaleV2Component;
