import React from 'react';
import CreateCashClosingLayoutComponent from './CashClosing/CreateCashClosingLayout';
import CashClosingComponent from '../containers/cashClosing';
import DetailCashClosingLayout from './CashClosing/DetailCashClosingLayout';
import DetailCashClosingComponent from '../containers/cashClosing/Item/Detail';
import TotalByFolioComponent from '../containers/cashClosing/Item/TotalByFolio';
import TotalByClassificationComponent from '../containers/cashClosing/Item/TotalByClassification';
import TotalByProductComponent from '../containers/cashClosing/Item/TotalByProduct';
import TotalByEmployeeComponent from '../containers/cashClosing/Item/TotalByEmployee';
var getCashClosingChildrenRoutes = function () {
    return [
        { index: true, element: React.createElement(CashClosingComponent, null) },
        {
            path: 'create',
            element: React.createElement(CreateCashClosingLayoutComponent, null),
        },
        {
            path: ':cashClosingId',
            element: React.createElement(DetailCashClosingLayout, null),
            children: [
                { index: true, element: React.createElement(DetailCashClosingComponent, null) },
                { path: 'totalbyfolio', element: React.createElement(TotalByFolioComponent, null) },
                { path: 'totalbyclassification', element: React.createElement(TotalByClassificationComponent, null) },
                { path: 'totalbyproduct', element: React.createElement(TotalByProductComponent, null) },
                { path: 'totalbyemployee', element: React.createElement(TotalByEmployeeComponent, null) },
            ],
        },
    ];
};
export default getCashClosingChildrenRoutes;
