import React from 'react';
import SelectBranchesDialogComponent from './SelectBranchesDialog';
import HeaderMultiCompanyReportComponent from './Header';
import BodyMultiCompanyReportComponent from './Body';
var MultiCompanyReportComponent = function () {
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: 'flex flex-1 flex-col w-full h-full whitespace-nowrap bg-slate-50' },
            React.createElement(HeaderMultiCompanyReportComponent, null),
            React.createElement(BodyMultiCompanyReportComponent, null)),
        React.createElement(SelectBranchesDialogComponent, null)));
};
export default MultiCompanyReportComponent;
