import React, { useState } from 'react';
import SettingsIcon from '@mui/icons-material/Settings';
import { IconButton } from '@mui/material';
import Dialog from './Dialog';
var SettingButtonFooterFormCalendarComponent = function () {
    var _a = useState(false), isOpen = _a[0], setIsOpen = _a[1];
    var handleOpen = function () {
        setIsOpen(function (isOpen) { return !isOpen; });
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(IconButton, { onClick: handleOpen, className: 'mr-2' },
            React.createElement(SettingsIcon, null)),
        React.createElement(Dialog, { isOpen: isOpen, handleClose: handleOpen })));
};
export default SettingButtonFooterFormCalendarComponent;
