import React from 'react';
import HorizontalSplitIcon from '@mui/icons-material/HorizontalSplitOutlined';
import Container from '../../../presentationals/Module.v2/Container';

import Action from '../_action';

import Filter from '../_filter';
import Table from './table';
import getSession from '../../../helpers/getSession';
import getInputErrorFormat from '../../sale.v2/Action/helpers/getInputErrorFormat';

const title = 'Cuentas por pagar';

const Component = () => {
  const handleOpenXlsx = () => {
    try {
      const session = getSession();
      const companyId = session?.Company?.id;
      window.open(
        `https://api.erp.capitalike.com/files/accountPayableDocByProvider/${companyId}/pdf`,
        '_blank',
      );
    } catch (error) {
      getInputErrorFormat(error);
    }
  };
  return (
    <>
      <Container
        title={title}
        filter
        noTable
        additionalAction={[
          {
            handle: handleOpenXlsx,
            label: 'PDF de ds',
            icon: <HorizontalSplitIcon />,
          },
        ]}
      >
        <Table title={title} />
      </Container>
      <Action />
      <Filter />
    </>
  );
};

export default Component;
