import React from 'react';
import { useReactiveVar } from '@apollo/client';
import Button from '../../Button';
import {
  handleContinue,
  handleContinueConfiguration,
  handleBack,
  clearForm,
  step,
  storeAction,
  isStoreLoading,
} from '../../../apollo/cache';

const FooterActionComponent = props => {
  const stepVar = useReactiveVar(step);
  const storeActionVar = useReactiveVar(storeAction);
  const handleContinueConfigurationVar = useReactiveVar(handleContinueConfiguration);
  const isStoreLoadingVar = useReactiveVar(isStoreLoading);

  const handleAction = (e, params) => {
    e.preventDefault();
    if (params === 'back') {
      stepVar > 0 && step(stepVar - 1);
      handleContinueConfiguration({});
      handleBack(true);
      return;
    }
    handleContinue(true);
  };
  const handleClear = async () => {
    clearForm(true);
  };
  const handleStoreAction = () => {
    storeAction([true, true, storeActionVar[2]]);
  };
  return (
    <>
      <div className='flex flex-1 w-full h-[70px]' />
      <div className='p-3 flex flex-1 w-full justify-between absolute bottom-0 bg-white h-[70px]'>
        <div>
          {!props.hideActions && (
            <Button
              label='Ir atras'
              onClick={handleAction}
              disabled={stepVar === 0}
              color='primary'
              variant='text'
              params='back'
            />
          )}
        </div>
        <div>
          {!props.hideActions && (
            <>
              <Button
                label='Limpiar'
                onClick={handleClear}
                color='inherit'
                variant='text'
                params='clear'
                className='mr-1'
              />
              <Button
                label={
                  handleContinueConfigurationVar.label
                    ? handleContinueConfigurationVar.label
                    : 'Siguiente'
                }
                onClick={handleAction}
                isLoading={isStoreLoadingVar}
                color={
                  handleContinueConfigurationVar.color
                    ? handleContinueConfigurationVar.color
                    : 'success'
                }
                variant='contained'
                params='next'
              />
            </>
          )}
          {storeActionVar[0] && (
            <Button
              label={storeActionVar[2] ? 'Editar' : 'Guardar'}
              onClick={handleStoreAction}
              color='secondary'
              variant='outlined'
              isLoading={isStoreLoadingVar}
              className='ml-1'
            />
          )}
        </div>
      </div>
    </>
  );
};

export default FooterActionComponent;
