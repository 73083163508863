import { gql } from '@apollo/client';

const FRAGMENT_NEW_TRANSACTION = gql`
  fragment NewTransaction on Transaction {
    id
    total
    paymentMethodId
    invoiceComplementId
    commentary
    TransactionAccount {
      name
    }
    InvoiceComplement {
      uuid
      serial
      folio
    }
    PaymentMethod {
      name
    }
    Sale {
      id
      folio
      serial
      total
      Customer {
        firstName
        lastName
      }
    }
    clientCreatedAt
    AccountReceivable {
      id
      status
      type
      paid
      folio
      serial
      description
      subtotal
      discount
      taxes
      total
      payment
      balance
      clientCreatedAt
      Customer {
        firstName
        lastName
      }
    }
  }
`;

const CREATE_PAYMENT_RELATED_SALE = gql`
  ${FRAGMENT_NEW_TRANSACTION}
  mutation CreatePaymentRelationSale(
    $paymentMethodId: ID
    $customerId: ID!
    $date: Date!
    $transactionAccountId: ID
    $Transactions: [TransactionData!]!
  ) {
    createPaymentRelationSale(
      input: {
        paymentMethodId: $paymentMethodId
        customerId: $customerId
        date: $date
        transactionAccountId: $transactionAccountId
        Transactions: $Transactions
      }
    ) {
      id
      customerId
      paymentForm
      Transactions {
        ...NewTransaction
      }
    }
  }
`;
//createPaymentRelatedSale

const GET = gql`
  ${FRAGMENT_NEW_TRANSACTION}
  query Transactions(
    $name: String
    $limit: Int
    $offset: Int
    $saleId: ID
    $start: Date
    $end: Date
    $paymentMethodId: ID
    $onlyCash: Boolean
    $branchId: ID
    $order: [String]
    $accountReceivableId: ID
    $transactionAccountId: [ID]
  ) {
    transactions(
      like: $name
      limit: $limit
      offset: $offset
      saleId: $saleId
      start: $start
      end: $end
      paymentMethodId: $paymentMethodId
      onlyCash: $onlyCash
      branchId: $branchId
      order: $order
      accountReceivableId: $accountReceivableId
      transactionAccountId: $transactionAccountId
    ) {
      count
      totals {
        id
        name
        qty
        amount
      }
      rows {
        ...NewTransaction
      }
    }
  }
`;

const GET_REPORT = gql`
  query TransactionsReport(
    $name: String
    $limit: Int
    $offset: Int
    $saleId: ID
    $start: Date
    $end: Date
    $paymentMethodId: ID
    $onlyCash: Boolean
    $branchId: ID
    $order: [String]
    $accountReceivableId: ID
    $transactionAccountId: [ID]
  ) {
    transactionsReport(
      like: $name
      limit: $limit
      offset: $offset
      saleId: $saleId
      start: $start
      end: $end
      paymentMethodId: $paymentMethodId
      onlyCash: $onlyCash
      branchId: $branchId
      order: $order
      accountReceivableId: $accountReceivableId
      transactionAccountId: $transactionAccountId
    ) {
      count
      totals {
        id
        name
        qty
        amount
      }
      rows {
        id
        total
        currency
        TransactionAccount {
          name
        }
        PaymentMethod {
          name
        }
        clientCreatedAt
        createdAt
        Sale {
          id
          folio
          serial
          invoiceId
          Invoice {
            uuid
          }
        }
        AccountReceivable {
          id
          serial
          total
          Customer {
            firstName
            lastName
          }
        }
      }
    }
  }
`;

const CREATE = gql`
  mutation CreateTransaction(
    $total: Float!
    $paymentMethodId: ID
    $accountReceivableId: ID!
    $calendarId: ID
    $currency: String
    $exchangeRate: Float
    $transactionAccountId: ID
    $date: Date
    $commentary: String
  ) {
    createTransaction(
      input: {
        total: $total
        paymentMethodId: $paymentMethodId
        accountReceivableId: $accountReceivableId
        calendarId: $calendarId
        currency: $currency
        exchangeRate: $exchangeRate
        transactionAccountId: $transactionAccountId
        date: $date
        commentary: $commentary
      }
    ) {
      id
      total
      transactionAccountId
      paymentMethodId
      commentary
      TransactionAccount {
        name
      }
      PaymentMethod {
        name
      }
      Sale {
        id
      }
      clientCreatedAt
      AccountReceivable {
        id
      }
    }
  }
`;

const CREATE_INVOICE_COMPLEMENT_MANY = gql`
  mutation CreateInvoiceComplementMany(
    $total: Float!
    $paymentMethodId: ID
    $AccountReceivables: [AccountReceivableComplementData]
    $clientCreatedAt: String
    $calendarId: ID
    $exchangeRate: Float
    $currency: String
    $transactionAccountId: ID
    $date: Date
    $commentary: String
  ) {
    createInvoiceComplementMany(
      input: {
        total: $total
        paymentMethodId: $paymentMethodId
        AccountReceivables: $AccountReceivables
        clientCreatedAt: $clientCreatedAt
        calendarId: $calendarId
        exchangeRate: $exchangeRate
        currency: $currency
        transactionAccountId: $transactionAccountId
        date: $date
        commentary: $commentary
      }
    ) {
      id
      uuid
    }
  }
`;

const DELETE = gql`
  mutation DeleteTransaction($id: ID!, $userPassword: String!) {
    deleteTransaction(id: $id, userPassword: $userPassword) {
      id
      total
      PaymentMethod {
        name
      }
      clientCreatedAt
    }
  }
`;

const DELETE_WITH_COMPLEMENT = gql`
  mutation DeleteTransactionComplement(
    $id: ID!
    $userPassword: String!
    $reason: String!
    $relatedFolio: String
  ) {
    deleteTransactionComplement(
      id: $id
      userPassword: $userPassword
      reason: $reason
      relatedFolio: $relatedFolio
    ) {
      id
      total
      PaymentMethod {
        name
      }
      clientCreatedAt
    }
  }
`;

const GET_CHART = gql`
  query TransactionChart {
    transactionChart {
      totals {
        id
        name
        amount
      }
      rows {
        id
        clientCreatedAt
        total
        PaymentMethod {
          name
        }
        Sale {
          serial
          folio
          Customer {
            firstName
            lastName
          }
        }
      }
    }
  }
`;

export default {
  GET,
  CREATE,
  DELETE,
  DELETE_WITH_COMPLEMENT,
  GET_REPORT,
  GET_CHART,
  CREATE_INVOICE_COMPLEMENT_MANY,
  FRAGMENT_NEW_TRANSACTION,
  CREATE_PAYMENT_RELATED_SALE,
};
