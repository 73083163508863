import { Popover } from '@mui/material';
import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import ItemTableCustomerGlobalInvoiceComponent from '../../../containers/globalInvoice/Sales/CustomerField/SearchCustomer/Table/Item';
var RHFOrderTypePopoverComponent = forwardRef(function (props, ref) {
    var _a = useState(null), popover = _a[0], setPopover = _a[1];
    var control = useFormContext().control;
    var _b = useController({
        name: props.name,
        control: control,
    }).field, value = _b.value, onChange = _b.onChange;
    var handleClose = function () {
        setPopover(null);
    };
    var handleOpen = function (anchorEl) {
        setPopover(anchorEl);
    };
    var handleClick = function (name, id) {
        onChange(id);
        handleClose();
    };
    useImperativeHandle(ref, function () { return ({
        open: handleOpen,
    }); });
    return (React.createElement(Popover, { id: "OrderTypePopover".concat(props.name), open: !!popover, anchorEl: popover, onClose: handleClose, anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'right',
        }, transformOrigin: {
            vertical: 'top',
            horizontal: 'right',
        }, slotProps: {
            paper: {
                className: 'w-48',
            },
        } },
        React.createElement("div", { className: 'flex max-h-96 overflow-y-auto overflow-x-hidden flex-col' },
            React.createElement(ItemTableCustomerGlobalInvoiceComponent, { index: 0, name: 'Ordene y recoja', id: 2, onClick: handleClick, selected: value === 2 }),
            React.createElement(ItemTableCustomerGlobalInvoiceComponent, { index: 1, name: 'Domicilio', id: 3, onClick: handleClick, selected: value === 3 }))));
});
export default RHFOrderTypePopoverComponent;
