import React from 'react';

import Form from './Form';
import Table from './Table';

const SearchFormCustomerActionSaleV2Component = () => {
  return (
    <div className='flex flex-col'>
      <Form />
      <Table />
    </div>
  );
};

export default SearchFormCustomerActionSaleV2Component;
