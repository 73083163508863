import React from 'react';
import { Outlet } from 'react-router-dom';
import MenuRentLayoutComponent from './Menu';
var RentLayoutComponent = function () {
    return (React.createElement("div", { className: 'flex flex-1 flex-col' },
        React.createElement(MenuRentLayoutComponent, null),
        React.createElement("div", { className: 'flex flex-1 flex-col h-full overflow-hidden' },
            React.createElement(Outlet, null))));
};
export default RentLayoutComponent;
