var getCalendarStatus = function (status) {
    switch (status) {
        case 1:
            return 'Por asistir';
        case 2:
            return 'Solicitud de cancelación';
        case 3:
            return 'Cancelado';
        case 4:
            return 'Asistido';
        case 5:
            return 'Finalizado';
        case 6:
            return 'Pospuesto';
        default:
            return null;
    }
};
export default getCalendarStatus;
