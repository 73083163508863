import React, { useEffect, useState } from 'react';
import { Divider } from '@mui/material';
import ShoppingBasketIcon from '@mui/icons-material/ShoppingBasket';
import WarehouseIcon from '@mui/icons-material/Warehouse';
import TitleDrawerMenuDashboardComponent from '../helpers/Title';
import PurchaseOrderLinkBlockTwoMenuDashboardComponent from './PurchaseOrderLink';
import ProviderLinkBlockTwoMenuDashboardComponent from './ProviderLink';
import RequisitionLinkBlockTwoListItemsMenuDashboardComponent from './RequisitionLink';
import TransferLinkBlockTwoListItemsMenuDashboardComponent from './TransferLink';
import AdjustInventoryLinkBlockThreeMenuDashboardComponent from './AdjustInventoryLink';
import PurchaseReportsLinkBlockThreeMenuDashboardComponent from './PurchaseReportsLink';
import InventoryManagementLinkBlockThreeMenuDashboardComponent from './InventoryManagementLink';
import useGetSessionRole from '../../../../../hooks/useGetSessionRole';
import getIfShowPurchases from './helpers/getIfShowPurchases';
import getIfShowInventory from './helpers/getIfShowInventory';
var BlockThreeMenuDashboardComponent = function () {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p;
    var _q = useGetSessionRole(), data = _q.data, loading = _q.loading, isOwner = _q.isOwner;
    var _r = useState(true), showPurchases = _r[0], setShowPurchases = _r[1];
    var _s = useState(true), showInventory = _s[0], setShowInventory = _s[1];
    useEffect(function () {
        if (data) {
            var showPurchases_1 = getIfShowPurchases(data.Permissions);
            setShowPurchases(showPurchases_1 || isOwner);
            var showInventory_1 = getIfShowInventory(data.Permissions);
            setShowInventory(showInventory_1 || isOwner);
        }
    }, [data, isOwner]);
    if (loading || !data || (!showPurchases && !showInventory))
        return null;
    return (React.createElement("div", { className: 'w-full' },
        showPurchases && (React.createElement(React.Fragment, null,
            React.createElement(TitleDrawerMenuDashboardComponent, { icon: React.createElement(ShoppingBasketIcon, null), title: 'Compras' }),
            (isOwner || !!((_b = (_a = data.Permissions) === null || _a === void 0 ? void 0 : _a.PurchaseOrder) === null || _b === void 0 ? void 0 : _b.getPurchaseOrders)) && (React.createElement(PurchaseOrderLinkBlockTwoMenuDashboardComponent, null)),
            (isOwner || !!((_d = (_c = data.Permissions) === null || _c === void 0 ? void 0 : _c.Provider) === null || _d === void 0 ? void 0 : _d.getProviders)) && (React.createElement(ProviderLinkBlockTwoMenuDashboardComponent, null)),
            (isOwner || !!((_f = (_e = data.Permissions) === null || _e === void 0 ? void 0 : _e.PurchaseOrderReport) === null || _f === void 0 ? void 0 : _f.getPurchaseOrderReport)) && (React.createElement(PurchaseReportsLinkBlockThreeMenuDashboardComponent, null)),
            React.createElement(Divider, { className: 'my-2' }))),
        showInventory && (React.createElement(React.Fragment, null,
            React.createElement(TitleDrawerMenuDashboardComponent, { icon: React.createElement(WarehouseIcon, null), title: 'Inventarios' }),
            (isOwner || !!((_h = (_g = data.Permissions) === null || _g === void 0 ? void 0 : _g.Requisition) === null || _h === void 0 ? void 0 : _h.getRequisitions)) && (React.createElement(RequisitionLinkBlockTwoListItemsMenuDashboardComponent, null)),
            (isOwner || !!((_k = (_j = data.Permissions) === null || _j === void 0 ? void 0 : _j.Transfer) === null || _k === void 0 ? void 0 : _k.getTransfer)) && (React.createElement(TransferLinkBlockTwoListItemsMenuDashboardComponent, null)),
            (isOwner || !!((_m = (_l = data.Permissions) === null || _l === void 0 ? void 0 : _l.InventoryAdjust) === null || _m === void 0 ? void 0 : _m.getInventoryAdjust)) && (React.createElement(AdjustInventoryLinkBlockThreeMenuDashboardComponent, null)),
            (isOwner || !!((_p = (_o = data.Permissions) === null || _o === void 0 ? void 0 : _o.Inventory) === null || _p === void 0 ? void 0 : _p.getInventory)) && (React.createElement(InventoryManagementLinkBlockThreeMenuDashboardComponent, null)),
            React.createElement(Divider, { className: 'my-2' })))));
};
export default BlockThreeMenuDashboardComponent;
