var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React, { useEffect, useState } from 'react';
import { Typography } from '@mui/material';
import SelectFieldComponent from '../../../../presentationals/FormComponents/SelectField';
import TextFieldComponent from '../../../../presentationals/FormComponents/TextField';
import useGetSession from '../../../../hooks/useGetSession';
var keysOptions = [
    { label: 'Nombre', value: 'name' },
    { label: 'Teléfono', value: 'phone' },
    { label: 'Correo electrónico', value: 'email' },
];
var SearchFieldFilterEmployeeComponent = function (props) {
    var _a;
    var session = useGetSession();
    var _b = useState('nombre'), selectedKey = _b[0], setSelectedKey = _b[1];
    useEffect(function () {
        var selectedKey = keysOptions.reduce(function (key, el) { return (el.value === props.value[0] ? el.label.toLowerCase() : key); }, 'nombre');
        setSelectedKey(selectedKey);
    }, [props.value[0]]);
    var handleSelectFieldChange = function (name, value) {
        props.onChange(name, [value, props.value[1]]);
    };
    var handleTextFieldChange = function (name, value) {
        props.onChange(name, [props.value[0], value]);
    };
    return (React.createElement("div", null,
        React.createElement(Typography, { variant: 'subtitle1' }, "B\u00FAsqueda por ".concat(selectedKey)),
        React.createElement("div", { className: 'flex flex-row' },
            React.createElement("div", { className: 'flex mr-2' },
                React.createElement(SelectFieldComponent, { defaultLabel: 'Filtro', name: 'like', value: props.value[0], options: ((_a = session.Company) === null || _a === void 0 ? void 0 : _a.id) === '207'
                        ? __spreadArray(__spreadArray([], keysOptions, true), [{ label: 'Escuela procedente', value: 'schoolName' }], false) : keysOptions, onChange: handleSelectFieldChange })),
            React.createElement("div", { className: 'flex flex-1' },
                React.createElement(TextFieldComponent, { defaultLabel: 'Palabra clave', name: 'like', value: props.value[1], onChange: handleTextFieldChange })))));
};
export default SearchFieldFilterEmployeeComponent;
