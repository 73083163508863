const setCreateProductBody = values => {
  const percentAdjust =
    values?.percentAdjust && !isNaN(parseFloat(values.percentAdjust))
      ? parseFloat(values.percentAdjust)
      : 0;
  return {
    id: values.id ? values.id : null,
    name: values.name ? values.name : '',
    prefix: values.prefix ? values.prefix : '',
    type: values.type ? parseInt(values.type) : 1,
    price: values.price ? parseFloat(values.price) : 0,
    cost: values.cost ? parseFloat(values.cost) : 0,
    iva: values.iva && !isNaN(parseFloat(values.iva)) ? parseFloat(values.iva) : 0,
    ieps: values.ieps && !isNaN(parseFloat(values.ieps)) ? parseFloat(values.ieps) : 0,
    percentAdjust,
    adjustPrice: percentAdjust > 0,
    sku: values.sku ? values.sku : '',
    barcode: values.barcode ? values.barcode : '',
    classificationId: values.classificationId,
    providerId: values.providerId ? values.providerId : null,
    unitKey: values.unitKey ? values.unitKey : '',
    productKey: values.productKey ? values.productKey : '',
    unitBase: values.unitBase ? values?.unitBase : 'PIEZA',
    description: values.description ? values.description : '',
    isSerialized: values.isSerialized ? values.isSerialized : false,
  };
};

export default setCreateProductBody;
