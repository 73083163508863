import React, { useState, useEffect } from 'react';
import { styled } from '@mui/system';
import getFormattedLabel from '../../../../../helpers/getFormattedLabel';
var Subtitle = styled('div')(function () { return ({
    color: '#525252',
    fontSize: 14,
    lineHeight: 1,
}); });
var SubtitleButtonActionSaleV2Component = function (props) {
    var _a = useState(''), subtitle = _a[0], setSubtitle = _a[1];
    useEffect(function () {
        var newSubtitle = props.noLimit ? props.label : getFormattedLabel(props.label, { limit: 20 });
        setSubtitle(newSubtitle);
    }, [props.label, props.noLimit]);
    return React.createElement(Subtitle, { className: 'font-medium' }, subtitle);
};
export default SubtitleButtonActionSaleV2Component;
