import React, { useEffect, useState } from 'react';

import { List } from '@mui/material';

import useApolloQuery from '../../../../../../hooks/useApolloQuery';

import GQL from '../../../../../paymentMethod/_gql';
import LoadingProgress from '../../../../../../presentationals/LoadingProgress';

import Item from './Item';

const PaymentMethodPaymentFormBodyConfirmDialogSaleV2Component = () => {
  const { data, loading } = useApolloQuery(GQL.GET);

  const [paymentMethods, setPaymentMethods] = useState([]);

  useEffect(() => {
    if (data && data.rows)
      setPaymentMethods([{ name: 'Efectivo', id: -1, code: '01' }, ...data.rows]);
  }, [data]);

  if (loading) return <LoadingProgress noText />;
  return (
    <div className='flex flex-1 basis-0 overflow-x-hidden overflow-y-auto'>
      <List className='w-full'>
        {paymentMethods.map((el, i) => (
          <Item key={el.id} id={el.id} index={i} name={el.name} code={el.code} />
        ))}
      </List>
    </div>
  );
};

export default PaymentMethodPaymentFormBodyConfirmDialogSaleV2Component;
