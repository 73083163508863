var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { FormControl, FormLabel, MenuItem, TextField } from '@mui/material';
import { useFormContext, useWatch } from 'react-hook-form';
import FieldErrorComponent from '../Error';
var RHFSelectFieldComponent = function (_a) {
    var name = _a.name, config = _a.config, label = _a.label, props = __rest(_a, ["name", "config", "label"]);
    var _b = useFormContext(), _c = _b.formState, errors = _c.errors, isSubmitting = _c.isSubmitting, register = _b.register;
    var value = useWatch({
        name: name,
    });
    return (React.createElement(FormControl, { className: 'w-full', disabled: props.disabled || isSubmitting, error: !!errors[name] },
        label && (React.createElement(FormLabel, { htmlFor: name, component: 'legend' }, label)),
        React.createElement(TextField, __assign({}, register(name, config), props, { id: name, placeholder: props.placeholder, select: true, variant: props.variant || 'outlined', fullWidth: props.fullWidth || true, autoFocus: props.autoFocus || false, helperText: props.helperText, autoComplete: 'off', disabled: props.options.length === 0 || props.disabled || isSubmitting, size: props.size || 'small', value: props.options.length > 0 && value ? value : '' }), props.options.map(function (el) { return (React.createElement(MenuItem, { key: el.label, value: el.value }, el.label)); })),
        React.createElement(FieldErrorComponent, { name: name })));
};
export default RHFSelectFieldComponent;
