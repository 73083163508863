var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useEffect, useState } from 'react';
import { useReactiveVar } from '@apollo/client';
import FooterDialogComponent from '../../../../presentationals/Dialog/Footer';
import CloseRequisitionButtonComponent from './CloseButton';
import { isCloseRequisitionOpen } from '../../../../apollo/cache';
import useApolloLazyQuery from '../../../../hooks/useApolloLazyQuery';
import GQL_REQUISITION from '../../../../apollo/gql/requisition';
import LoadingProgress from '../../../../presentationals/LoadingProgress';
import ItemProductRequisitionComponent from './Item';
var BodyCloseRequisitionComponent = function () {
    var requisitionId = useReactiveVar(isCloseRequisitionOpen);
    var _a = useState([]), Products = _a[0], setProducts = _a[1];
    var _b = useApolloLazyQuery(GQL_REQUISITION.GET_REQUISITION_BY_ID), getData = _b[0], _c = _b[1], loading = _c.loading, data = _c.data;
    useEffect(function () {
        if (requisitionId)
            getData({ variables: { id: requisitionId } });
    }, [requisitionId]);
    useEffect(function () {
        if (data)
            setProducts(data.RequisitionProducts.map(function (el) { return (__assign(__assign({}, el), { receivedQuantity: el.sendQuantity })); }));
    }, [data]);
    var handleQtyChange = function (requisitionId, qty) {
        setProducts(Products.map(function (el) { return (el.id === requisitionId ? __assign(__assign({}, el), { receivedQuantity: qty }) : el); }));
    };
    if (!requisitionId)
        return React.createElement("div", null);
    if (loading || !data)
        return React.createElement(LoadingProgress, null);
    return (React.createElement("div", { className: 'flex flex-1 flex-col' },
        React.createElement("div", { className: 'flex flex-1 flex-col px-4 pt-3' },
            React.createElement("div", { className: 'text-base font-medium mb-1 flex' }, "Cierre de requisici\u00F3n de suministro"),
            React.createElement("div", { className: 'text-sm sm:text-base text-gray-600 mb-2' }, "Comprueba que los productos que est\u00E1s recibiendo, son los productos que est\u00E1n en lista de la requisici\u00F3n, ya que al momento de finalizar este paso, los productos ser\u00E1n descontados de la sucursal de origen y a\u00F1adidos al inventario de la sucursal destino."),
            React.createElement("div", { className: 'grid grid-cols-1 sm:grid-cols-4 gap-4' }, Products.map(function (el) { return (React.createElement(ItemProductRequisitionComponent, { onQtyChange: handleQtyChange, key: el.id, RequisitionProduct: el })); }))),
        React.createElement(FooterDialogComponent, null,
            React.createElement("div", null),
            React.createElement(CloseRequisitionButtonComponent, { requisitionId: requisitionId, RequisitionProducts: Products }))));
};
export default BodyCloseRequisitionComponent;
