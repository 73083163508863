var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import { useParams } from 'react-router-dom';
import GQL_ECOMMERCE from '../../../../apollo/gql/ecommerce';
import useApolloQuery from '../../../../hooks/useApolloQuery';
import LoadingProgress from '../../../../presentationals/LoadingProgress';
import ErrorDataComponent from '../../../../presentationals/ErrorData';
import ProductItemProductListComponent from './ProductItem';
var ClassificationItemProductListComponent = function (props) {
    var params = useParams();
    var _a = useApolloQuery(GQL_ECOMMERCE.GET_EC_PRODUCTS, {
        variables: {
            ecName: params.ecName,
            classificationId: props.id,
            order: ['name', 'asc'],
            limit: null,
        },
    }), data = _a.data, loading = _a.loading;
    if (loading)
        return React.createElement(LoadingProgress, { darkTheme: true });
    if (!data)
        return React.createElement(ErrorDataComponent, null);
    return (React.createElement("div", { className: 'flex flex-col' },
        React.createElement("div", { className: 'font-semibold text-[#ffe000] text-5xl tracking-tighter' }, props.name),
        React.createElement("div", { className: 'grid grid-cols-2 sm:grid-cols-5 sm:col-span-6 my-3' }, data.map(function (el) { return (React.createElement(ProductItemProductListComponent, __assign({ key: el.id }, el))); }))));
};
export default ClassificationItemProductListComponent;
