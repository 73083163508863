import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Cell, Legend, Pie, PieChart, ResponsiveContainer, Tooltip } from 'recharts';
var COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#8042ff'];
var RADIAN = Math.PI / 180;
var renderCustomizedLabel = function (_a) {
    var cx = _a.cx, cy = _a.cy, midAngle = _a.midAngle, innerRadius = _a.innerRadius, outerRadius = _a.outerRadius, percent = _a.percent;
    var radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    var x = cx + radius * Math.cos(-midAngle * RADIAN);
    var y = cy + radius * Math.sin(-midAngle * RADIAN);
    return (React.createElement("text", { className: ' text-sm', x: x, y: y, fill: 'white', textAnchor: x > cx ? 'start' : 'end', dominantBaseline: 'central' }, "".concat((percent * 100).toFixed(0), "%")));
};
var SaleByBranchChartIncomeReportComponent = function (props) {
    var _a = useState([]), data = _a[0], setData = _a[1];
    useEffect(function () {
        if (props.TotalBranchSales && props.TotalBranchSales.length > 0)
            setData(props.TotalBranchSales.map(function (el) { return ({ name: el.name, value: el.total }); }));
    }, [props.TotalBranchSales]);
    if (data.length <= 1)
        return React.createElement("div", null);
    return (React.createElement("div", { className: 'flex flex-1 mt-4 w-full max-w-full sm:mt-0 min-h-[300px] sm:min-h-0 flex-col overflow-hidden' },
        React.createElement("div", { className: 'flex justify-between items-end' },
            React.createElement("div", { className: 'text-lg' }, "Ingresos por sucursal"),
            React.createElement(Link, { to: '#', className: ' text-sky-600 no-underline hover:underline text-sm' }, "Ver por promedio")),
        React.createElement("div", { className: 'flex flex-1 max-w-full overflow-hidden bg-slate-50 rounded-lg mt-2 border-solid border-slate-200 p-4' },
            React.createElement(ResponsiveContainer, { width: '100%', height: '100%' },
                React.createElement(PieChart, null,
                    React.createElement(Pie, { data: data, cx: '50%', cy: '50%', labelLine: false, label: renderCustomizedLabel, outerRadius: 80, fill: '#8884d8', dataKey: 'value' }, data.map(function (el, index) { return (React.createElement(Cell, { key: "cell-".concat(index), fill: COLORS[index % COLORS.length] })); })),
                    React.createElement(Tooltip, null),
                    React.createElement(Legend, null))))));
};
export default SaleByBranchChartIncomeReportComponent;
