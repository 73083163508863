import { ApolloClient, from } from '@apollo/client';

import errorLink from './errorLink';
import authLink from './authLink';
import httpLink from './httpLink';

import { cache } from './cache';

const client = new ApolloClient({
  link: from([errorLink, authLink, httpLink]),
  cache,
  connectToDevTools: true,
});

export default client;
