import React, { useState } from 'react';
import DialogComponent from '../../../presentationals/Dialog';
import SelectBranchInventoryReportMenuItemsComponent from '../SelectBranchInput/Menu/Items';
import useInventoryReportStore from '../../../store/inventoryReport';
var SelectBranchInventoryDialogComponent = function () {
    var setBranchId = useInventoryReportStore(function (state) { return state.setBranchId; });
    var _a = useState(true), isOpen = _a[0], setIsOpen = _a[1];
    var handleClose = function () {
        setIsOpen(false);
    };
    var handleClick = function (id) {
        setBranchId(id);
        handleClose();
    };
    return (React.createElement(DialogComponent, { fullWidth: false, maxWidth: 'xs', open: isOpen, hideHeaderCloseButton: true, onClose: handleClose },
        React.createElement("div", { className: 'flex flex-1 flex-col pb-2' },
            React.createElement("div", { className: 'font-medium text-base px-3 pt-2 pb-1 leading-tight' }, "Selecciona una sucursal"),
            React.createElement(SelectBranchInventoryReportMenuItemsComponent, { onClick: handleClick }))));
};
export default SelectBranchInventoryDialogComponent;
