import React, { useEffect } from 'react';

import { useLazyQuery } from '@apollo/client';

import Table from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';

import FormDialog from '../../presentationals/FormDialog';
import { dateTimeFormat, currencyFormat } from '../../helpers';

import GQL from '../productMovement/_gql';

const Component = props => {
  const [getQuery, { data, loading }] = useLazyQuery(GQL.GET);

  useEffect(() => {
    if (props.productId)
      getQuery({
        variables: { limit: 0, productId: props.productId, start: props.start, end: props.end },
      });
  }, [props.productId, props.start, props.end]);

  const handleClose = () => {
    props.handleClose();
  };

  return (
    <FormDialog
      title='Detalle por Producto'
      isOpen={props.isOpen}
      handleClose={handleClose}
      isLoading={loading}
      hideAction
      noActions
      noPadding
    >
      <TableContainer className='mb-2'>
        <Table size='small'>
          <TableHead>
            <TableRow className='bg-[#f6f6f6]'>
              <TableCell>Fecha</TableCell>
              <TableCell>Descripción</TableCell>
              <TableCell align='right'>Entrada</TableCell>
              <TableCell align='right'>Salida</TableCell>
              <TableCell align='right'>Saldo</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data &&
              data.productMovementLogs.rows.map((el, i) => (
                <TableRow key={i}>
                  <TableCell>{dateTimeFormat(el.createdAt)}</TableCell>
                  <TableCell>{el.description}</TableCell>
                  <TableCell align='right'>{currencyFormat(el.input)}</TableCell>
                  <TableCell align='right'>{currencyFormat(el.output)}</TableCell>
                  <TableCell align='right'>{currencyFormat(el.balance)}</TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </FormDialog>
  );
};

export default Component;
