var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useEffect, useState } from 'react';
import SwitchComponent from '../../../../presentationals/FormComponents/Switch';
import getSaleTableColumnConfiguration, { defaultSaleTableColumnsConfiguration, } from '../../../../helpers/getSaleTableColumnConfiguration';
import getSaleReportTableSettingColumnLabel from '../../helpers/getSaleReportTableSettingColumnLabel';
import setSaleTableColumnConfiguration from '../../../../helpers/setSaleTableColumnConfiguration';
import useTableSettingsDialog from '../../../../store/tableSettings/tableSettingsDialog';
var BodyTableSettingsSaleComponent = function () {
    var open = useTableSettingsDialog(function (state) { return state.open; });
    var _a = useState(defaultSaleTableColumnsConfiguration), values = _a[0], setValues = _a[1];
    useEffect(function () {
        if (open) {
            var settings = getSaleTableColumnConfiguration();
            setValues(settings);
        }
    }, [open]);
    var handleChange = function (name, value) {
        setValues(function (values) {
            var _a;
            return (__assign(__assign({}, values), (_a = {}, _a[name] = value, _a)));
        });
        setSaleTableColumnConfiguration(name, value);
    };
    return (React.createElement("div", { className: 'flex-1 grid-cols-1 md:grid-cols-4 p-3' },
        React.createElement("div", null,
            React.createElement("div", { className: 'ml-1 font-medium' }, "Columnas de la tabla"),
            React.createElement("div", { className: 'ml-1 text-sm text-gray-500' }, "Selecciona las columnas visibles"),
            Object.keys(values).map(function (key) { return (React.createElement(SwitchComponent, { key: key, name: key, disabled: ['total'].includes(key), className: 'py-0 p-0', size: 'small', label: getSaleReportTableSettingColumnLabel(key), onChange: handleChange, defaultValue: values[key], labelPlacement: 'end' })); })),
        React.createElement("div", null),
        React.createElement("div", null),
        React.createElement("div", null)));
};
export default BodyTableSettingsSaleComponent;
