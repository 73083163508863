import React from 'react';

import { useMutation, useQuery, gql } from '@apollo/client';

import ConfirmDialog from '../ConfirmDialog';

import { snackbar, deleteForm } from '../../apollo/cache';

const GET_LOCAL = gql`
  query DeleteForm {
    deleteForm @client
  }
`;

const defaultSnackbar = { isOpen: true, time: 3000 };

const DeleteDialog = props => {
  const { data: local } = useQuery(GET_LOCAL);

  const [_delete, { loading }] = useMutation(props.delete, {
    update(cache) {
      cache.evict({ fieldName: props.operation });
      cache.gc();
    },
  });

  const handleCloseDelete = () => {
    deleteForm({ isOpen: false, params: {} });
  };

  const handleDelete = async () => {
    try {
      await _delete({ variables: local.deleteForm.params });
      snackbar({ ...defaultSnackbar, label: 'Operación realizada con éxito.' });
      !loading && handleCloseDelete();
    } catch (e) {
      console.log(e.message);
      snackbar({ ...defaultSnackbar, label: 'Error interno de servidor.', severity: 'error' });
    }
  };

  return (
    <ConfirmDialog
      isOpen={local.deleteForm.isOpen}
      handleAction={handleDelete}
      isLoading={loading}
      handleClose={handleCloseDelete}
    />
  );
};

export default DeleteDialog;
