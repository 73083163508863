import React from 'react';

import { useReactiveVar } from '@apollo/client';

import { formatSaleV2 } from '../../../../../apollo/cache';

import TextField from './TextField';
import PaymentMethod from './PaymentMethod';
import InvoiceForm from './InvoiceForm';
import QuotationForm from './QuotationForm';

const PaymentFormBodyConfirmDialogSaleV2Component = () => {
  const formatSaleV2Var = useReactiveVar(formatSaleV2);
  return (
    <div className='flex flex-1 flex-col w-full mb-1'>
      {parseInt(formatSaleV2Var[0]) !== -2 && (
        <>
          <TextField />
          <PaymentMethod />
          <InvoiceForm />
        </>
      )}
      {parseInt(formatSaleV2Var[0]) === -2 && <QuotationForm />}
    </div>
  );
};

export default PaymentFormBodyConfirmDialogSaleV2Component;
