import React from 'react';
import { Fab, Tooltip } from '@mui/material';
import MoveUpIcon from '@mui/icons-material/MoveUp';
import AddedProductsToTransferBadgeComponent from './AddedProductsToTransferBadge';
import useTransferProductsToBranchStore from '../../../store/inventoryReport/transferProductsToBranchStore';
var OpenProductToTransferFormButtonComponent = function () {
    var setOpen = useTransferProductsToBranchStore(function (state) { return state.setOpen; });
    var products = useTransferProductsToBranchStore(function (state) { return state.products; });
    var handleClick = function (e) {
        setOpen(e.currentTarget);
    };
    return products.length > 0 ? (React.createElement(Tooltip, { title: 'Transferencia de productos' },
        React.createElement(Fab, { onClick: handleClick, color: 'warning', "aria-label": 'productToTransfer' },
            React.createElement(AddedProductsToTransferBadgeComponent, null),
            React.createElement(MoveUpIcon, null)))) : (React.createElement("div", null));
};
export default OpenProductToTransferFormButtonComponent;
