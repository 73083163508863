var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React from 'react';
import { useMutation } from '@apollo/client';
import NumericField from '../../helpers/numericField';
import useApolloQuery from '../../../../../../../../hooks/useApolloQuery';
import GQL_CALENDAR from '../../../../../../../../apollo/gql/calendar';
var NumberOfScheludeSwitchScheduleAvailabilityDialogSettingButtonFooterFormCalendarComponent = function () {
    var _a;
    var _b = useApolloQuery(GQL_CALENDAR.EMPLOYEE_CALENDAR_SETTINGS), data = _b.data, loadingData = _b.loading;
    var _c = useMutation(GQL_CALENDAR.UPDATE_EMPLOYEE_CALENDAR_SETTINGS), update = _c[0], loading = _c[1].loading;
    var handleClick = function (action) { return __awaiter(void 0, void 0, void 0, function () {
        var currentQty, newQty;
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    if (!data)
                        return [2 /*return*/];
                    currentQty = ((_a = data.scheduleAvailability) === null || _a === void 0 ? void 0 : _a.qty) ? data.scheduleAvailability.qty : 0;
                    newQty = action === 'remove' ? currentQty - 1 : currentQty + 1;
                    if (newQty < 0 || newQty > 2)
                        return [2 /*return*/];
                    return [4 /*yield*/, update({
                            variables: {
                                scheduleAvailability: __assign(__assign({}, data.scheduleAvailability), { qty: newQty }),
                            },
                        })];
                case 1:
                    _b.sent();
                    return [2 /*return*/];
            }
        });
    }); };
    return (React.createElement("div", { className: 'flex flex-row items-center mb-2' },
        React.createElement(NumericField, { disabled: loadingData || loading, label: 'N\u00FAmero de agendas', handleClick: handleClick, number: ((_a = data === null || data === void 0 ? void 0 : data.scheduleAvailability) === null || _a === void 0 ? void 0 : _a.qty) ? data.scheduleAvailability.qty : 0 })));
};
export default NumberOfScheludeSwitchScheduleAvailabilityDialogSettingButtonFooterFormCalendarComponent;
