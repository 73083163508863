var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useEffect, useState } from 'react';
import DateFieldComponent from '../../../../presentationals/FormComponents/DateField';
import SelectBranchFieldComponent from '../../../../presentationals/FormComponents/SelectBranchField';
import getSession from '../../../../helpers/getSession';
var FilterFormSurveyResultsComponent = function (props) {
    var defaultValues = {
        start: new Date(),
        end: new Date(),
        branchId: '',
    };
    var _a = useState(defaultValues), values = _a[0], setValues = _a[1];
    useEffect(function () {
        var _a;
        var session = getSession();
        if ((_a = session === null || session === void 0 ? void 0 : session.Company) === null || _a === void 0 ? void 0 : _a.Branch.id) {
            var branchId_1 = session.Company.Branch.id;
            setValues(function (values) { return (__assign(__assign({}, values), { branchId: branchId_1 })); });
        }
    }, []);
    var handleDateChange = function (name, value) {
        var _a;
        var newValues = __assign(__assign({}, values), (_a = {}, _a[name] = value, _a));
        props.onChange(newValues);
        setValues(newValues);
    };
    var handleChange = function (name, value) {
        var _a;
        var newValues = __assign(__assign({}, values), (_a = {}, _a[name] = value, _a));
        props.onChange(newValues);
        setValues(newValues);
    };
    return (React.createElement("div", { className: 'flex flex-row' },
        React.createElement(DateFieldComponent, { name: 'start', label: 'Del', value: values.start, onChange: handleDateChange }),
        React.createElement("div", { className: 'mx-1' }),
        React.createElement(DateFieldComponent, { name: 'end', label: 'Al', value: values.end, onChange: handleDateChange }),
        React.createElement("div", { className: 'mx-1' }),
        React.createElement(SelectBranchFieldComponent, { disallowAllOptions: true, defaultValue: values.branchId, onChange: handleChange, label: 'Sucursal' })));
};
export default FilterFormSurveyResultsComponent;
