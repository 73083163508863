import React from 'react';

import { useReactiveVar } from '@apollo/client';

import SearchIcon from '@mui/icons-material/Search';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';

import { Tabs as TabsMui, Tab } from '@mui/material';

import { styled } from '@mui/system';

import { customerSaleV2 } from '../../../../../../apollo/cache';

const Tabs = styled(TabsMui)({
  width: '100%',
  backgroundColor: '#f5f5f5',
  overflow: 'hidden',
  borderBottom: '1px solid #d6d6d6',
  borderTop: '1px solid #d6d6d6',
  display: 'flex',
});

const ActionFormCustomerActionSaleV2Component = props => {
  const customerSaleV2Var = useReactiveVar(customerSaleV2);
  return (
    <Tabs value={props.value} onChange={props.handleClick}>
      <Tab icon={<SearchIcon />} label='Consultar' />
      <Tab
        icon={customerSaleV2Var.id ? <EditIcon /> : <AddIcon />}
        label={customerSaleV2Var.id ? 'Editar' : 'Nuevo'}
      />
      {customerSaleV2Var.id && <Tab icon={<AddIcon />} label='Nuevo' />}
    </Tabs>
  );
};

export default ActionFormCustomerActionSaleV2Component;
