import React, { useEffect } from 'react';
import { useReactiveVar } from '@apollo/client';
import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import useApolloLazyQuery from '../../../../hooks/useApolloLazyQuery';
import { isPostponeCalendarOpen } from '../../../../apollo/cache';
import LoadingProgress from '../../../../presentationals/LoadingProgress';
import GQL from '../../_gql';
import TableRowPostponeCalendarComponent from './helpers/TableRow';
import { eventFormat } from '../../../../helpers';
import EmptyPostponeCalendarComponent from './Empty';
import useGetSession from '../../../../hooks/useGetSession';
var BodyPosponeCalendarComponent = function () {
    var _a, _b;
    var isOpen = useReactiveVar(isPostponeCalendarOpen);
    var session = useGetSession();
    var customerLabel = ((_a = session === null || session === void 0 ? void 0 : session.Company) === null || _a === void 0 ? void 0 : _a.activity) === 3 ? 'Paciente' : 'Cliente';
    var _c = useApolloLazyQuery(GQL.GET), getPosponeCalendars = _c[0], _d = _c[1], loading = _d.loading, data = _d.data;
    useEffect(function () {
        if (isOpen)
            getPosponeCalendars({ variables: { status: [6], limit: 0, withCrm: true } });
    }, []);
    if (loading)
        return React.createElement(LoadingProgress, null);
    if ((data === null || data === void 0 ? void 0 : data.rows) && data.rows.length === 0)
        return React.createElement(EmptyPostponeCalendarComponent, null);
    return (React.createElement("div", { className: 'flex flex-1 flex-col h-screen overflow-hidden md:pt-0 md:pr-0' },
        React.createElement(Table, { size: 'small' },
            React.createElement(TableHead, null,
                React.createElement(TableRow, { className: 'bg-gray-400' },
                    React.createElement(TableCell, null, "Etiqueta"),
                    React.createElement(TableCell, null, "Cita"),
                    React.createElement(TableCell, null, "T\u00EDtulo"),
                    React.createElement(TableCell, null, customerLabel),
                    React.createElement(TableCell, null, "Diagn\u00F3stico"),
                    React.createElement(TableCell, null, "Descripci\u00F3n"))),
            React.createElement(TableBody, null, (_b = data === null || data === void 0 ? void 0 : data.rows) === null || _b === void 0 ? void 0 : _b.map(function (el, i) { return (React.createElement(TableRowPostponeCalendarComponent, { index: i, eventId: el.id, key: el.id },
                React.createElement(TableCell, null, el.Tag ? el.Tag.name : '-'),
                React.createElement(TableCell, null, eventFormat(el.start, el.end)),
                React.createElement(TableCell, null, el.title || '-'),
                React.createElement(TableCell, null, el.Customer ? "".concat(el.Customer.firstName, " ").concat(el.Customer.lastName) : '-'),
                React.createElement(TableCell, null, el.diagnosis || '-'),
                React.createElement(TableCell, null, el.description || '-'))); })))));
};
export default BodyPosponeCalendarComponent;
