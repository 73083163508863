import React from 'react';
import SearchIcon from '@mui/icons-material/SearchOutlined';
import { InputAdornment } from '@mui/material';
import OutlinedField from '../../../../../../../../presentationals/FormComponents/OutlinedField';
import IconButton from '../../../../../../../../presentationals/IconButton';
import { isSatProductKeyDialogOpen, searchProductKeyField, } from '../../../../../../../../apollo/cache';
var ProductKeyProductComponent = function (props) {
    var handleChange = function (name, value) {
        props.onChange(name, value);
    };
    var handleClick = function () {
        isSatProductKeyDialogOpen(true);
        searchProductKeyField(props.value);
    };
    return (React.createElement(OutlinedField, { label: 'Clave de producto y servicio (SAT)', name: 'productKey', value: props.value, error: props.error, onChange: handleChange, onKeyDown: props.onKeyPress, endAdornment: React.createElement(InputAdornment, { position: 'end' },
            React.createElement(IconButton, { action: handleClick, size: 'small', className: 'p-0 m-0', icon: React.createElement(SearchIcon, { fontSize: 'small' }) })) }));
};
export default ProductKeyProductComponent;
