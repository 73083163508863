import { useEffect, useState } from 'react';
import GQL_ROLE from '../apollo/gql/role';
import useApolloQuery from './useApolloQuery';
var useGetSessionRole = function () {
    var _a = useState(false), isOwner = _a[0], setIsOwner = _a[1];
    var _b = useApolloQuery(GQL_ROLE.GET_SESSION_ROLE), data = _b.data, loading = _b.loading;
    useEffect(function () {
        setIsOwner(!!data && data.name === 'PROPIETARIO');
    }, [data]);
    return { data: data, loading: loading, isOwner: isOwner };
};
export default useGetSessionRole;
