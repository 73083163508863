import React from 'react';
import CreateRequisitionLayoutComponent from './Requisition/CreateRequisitionLayout';
import RequisitionComponent from '../containers/requisition';
var getRequisitionChildrenRoutes = function () {
    return [
        { index: true, element: React.createElement(RequisitionComponent, null) },
        {
            path: 'create',
            element: React.createElement(CreateRequisitionLayoutComponent, null),
        },
    ];
};
export default getRequisitionChildrenRoutes;
