var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React from 'react';
import { useMutation } from '@apollo/client';
import Button from '../../../../../presentationals/Dialog/Footer/Button';
import getInputErrorFormat from '../../../../sale.v2/Action/helpers/getInputErrorFormat';
import { companyForm, inputError, isCreateFormOpen } from '../../../../../apollo/cache';
import getDefaultCompanyValues from '../../../helpers/getDefaultCompanyValues';
import setInputErrorFormat from '../../../../sale.v2/Action/helpers/setInputErrorFormat';
import GQL_COMPANY from '../../../../../apollo/gql/company';
import GQL_SESSION from '../../../../../apollo/gql/session';
var CreateButtonFooterCreateCompanyComponent = function () {
    var _a = useMutation(GQL_COMPANY.CREATE, {
        update: function (cache, _a) {
            var data = _a.data;
            try {
                cache.modify({
                    fields: {
                        branchesSession: function (existingRefs) {
                            var newRef = cache.writeFragment({
                                data: {
                                    __typename: 'BranchSession',
                                    id: data.createCompany.branchId,
                                    companyName: data.createCompany.name,
                                    companyId: data.createCompany.id,
                                    name: data.createCompany.branchName,
                                    Role: {
                                        name: 'PROPIETARIO',
                                    },
                                    activity: data.createCompany.activity,
                                    logoFileId: null,
                                },
                                fragment: GQL_SESSION.FRAGMENT_BRANCH_SESSION,
                                fragmentName: 'FragmentBranchSession',
                            });
                            return __spreadArray(__spreadArray([], existingRefs, true), [newRef], false);
                        },
                    },
                });
            }
            catch (error) {
                getInputErrorFormat(error);
            }
        },
    }), handleCreate = _a[0], loading = _a[1].loading;
    var _b = useMutation(GQL_COMPANY.UPDATE_LOGO, {
        update: function (cache, _a) {
            var data = _a.data;
            try {
                cache.modify({
                    id: cache.identify({ __typename: 'BranchSession', companyId: data.updateLogo.companyId }),
                    fields: {
                        logoFileId: function () { return data.updateLogo.id; },
                    },
                });
            }
            catch (error) {
                getInputErrorFormat(error);
            }
        },
    }), updateLogo = _b[0], loadingLogo = _b[1].loading;
    var handleClick = function () { return __awaiter(void 0, void 0, void 0, function () {
        var values, data, defaultValues, error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 4, , 5]);
                    inputError({});
                    values = companyForm();
                    if (values.name === '')
                        throw new Error(setInputErrorFormat('name', 'Proporciona el nombre comercial de la empresa.'));
                    if (values.branchName === '')
                        throw new Error(setInputErrorFormat('branchName', 'Proporciona el nombre de la sucursal.'));
                    if (values.phone === '')
                        throw new Error(setInputErrorFormat('phone', 'Proporciona el teléfono de la empresa.'));
                    if (values.zipCode === '')
                        throw new Error(setInputErrorFormat('zipCode', 'Proporciona el código postal.'));
                    if (values.logo && !['image/jpg', 'image/jpeg', 'image/png'].includes(values.logo.type))
                        throw new Error(setInputErrorFormat('logo', 'El formato de la imagen debe ser JPG o PNG.'));
                    return [4 /*yield*/, handleCreate({
                            variables: {
                                name: values.name,
                                branchName: values.branchName,
                                prefix: values.branchName.substring(0, 2),
                                iva: 0,
                                addressLine1: values.addressLine1,
                                addressLine2: values.addressLine2,
                                zipCode: values.zipCode,
                                reference: '',
                                city: values.city,
                                state: values.state,
                                country: values.country,
                                phone: values.phone,
                                altPhone: '',
                                userPassword: '000000',
                                activity: values.activity,
                            },
                        })];
                case 1:
                    data = (_a.sent()).data;
                    if (!values.logo) return [3 /*break*/, 3];
                    return [4 /*yield*/, updateLogo({
                            variables: {
                                id: data.createCompany.id,
                                file: values.logo,
                            },
                        })];
                case 2:
                    _a.sent();
                    _a.label = 3;
                case 3:
                    defaultValues = getDefaultCompanyValues();
                    companyForm(defaultValues);
                    isCreateFormOpen(false);
                    return [3 /*break*/, 5];
                case 4:
                    error_1 = _a.sent();
                    getInputErrorFormat(error_1);
                    return [3 /*break*/, 5];
                case 5: return [2 /*return*/];
            }
        });
    }); };
    return (React.createElement(Button, { disabled: loading || loadingLogo, className: 'ml-2', onClick: handleClick }, "Almacenar"));
};
export default CreateButtonFooterCreateCompanyComponent;
