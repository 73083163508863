var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useEffect, useState } from 'react';
import { useReactiveVar } from '@apollo/client';
import { productListSaleV2, searchProductField } from '../../../../../../apollo/cache';
import useApolloLazyQuery from '../../../../../../hooks/useApolloLazyQuery';
import getProductListFormatted from '../../../../helpers/getProductListFormatted';
import GQL from '../../../../../product.v2/_gql';
import FormDialogProductFieldComponent from '../../../../../../presentationals/FormComponents/ProductField/Dialog/Body/Form';
import getLocalStorageSearchProductBy from '../../../../../../helpers/getLocalStorageSearchProductBy';
import setLocalStorageSearchProductBy from '../../../../../../helpers/setLocalStorageSearchProductBy';
import useSnackbarDialog from '../../../../../../store/snackbar/snackbarDialog';
var FormSearchFormCustomerActionSaleV2Component = function () {
    var setSnackbar = useSnackbarDialog().setSnackbar;
    var searchProductFieldVar = useReactiveVar(searchProductField);
    var _a = useState(''), value = _a[0], setValue = _a[1];
    var _b = useState(''), searchBy = _b[0], setSearchBy = _b[1];
    var _c = useApolloLazyQuery(GQL.GET_LITE, { variables: { lite: true } }), getData = _c[0], loading = _c[1].loading;
    useEffect(function () {
        setSearchBy(getLocalStorageSearchProductBy());
    }, []);
    useEffect(function () {
        setValue(searchProductFieldVar);
    }, [searchProductFieldVar]);
    var handleChange = function (name, value) {
        if (name === 'searchBy') {
            setSearchBy(value);
            setLocalStorageSearchProductBy(value);
        }
        if (name === 'search')
            setValue(value);
    };
    var handleClick = function () { return __awaiter(void 0, void 0, void 0, function () {
        var data, tmpProduct, e_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 3, , 4]);
                    if (!(value.length > 2)) return [3 /*break*/, 2];
                    return [4 /*yield*/, getData({ variables: { name: [searchBy, value], limit: 999 } })];
                case 1:
                    data = (_a.sent()).data;
                    tmpProduct = getProductListFormatted(data.rows);
                    productListSaleV2(tmpProduct);
                    _a.label = 2;
                case 2: return [3 /*break*/, 4];
                case 3:
                    e_1 = _a.sent();
                    setSnackbar({ isOpen: true, label: e_1.message, severity: 'error' });
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    }); };
    return (React.createElement(FormDialogProductFieldComponent, { searchBy: searchBy, value: value, onChange: handleChange, onClick: handleClick, isLoading: loading }));
};
export default FormSearchFormCustomerActionSaleV2Component;
