var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React from 'react';
import { cartItems, formatSaleV2, inputError, invoiceSaleV2, isCloseMaintenanceOpen, pinSaleV2, saleTotalToPay, 
// totalsSaleV2,
limitDays, } from '../../../../apollo/cache';
import getIfStockProductIsBelowZero from '../../../../helpers/getIfStockProductIsBelowZero';
import getSettings from '../../../../helpers/getSettings';
import Button from '../../../../presentationals/Dialog/Footer/Button';
import getInputErrorFormat from '../../../sale.v2/Action/helpers/getInputErrorFormat';
import setInputErrorFormat from '../../../sale.v2/Action/helpers/setInputErrorFormat';
import getIfSatRequirementisOk from '../../../sale.v2/ConfirmDialog/Footer/CreateButton/helpers/getIfSatRequirementisOk';
import ticket from '../../../cashSale/Header/ticket';
import getResponseWithData from '../../../sale.v2/ConfirmDialog/Footer/CreateButton/helpers/getResponseWithData';
import getIdToPrintDocument from '../../../sale.v2/ConfirmDialog/Footer/CreateButton/helpers/getIdToPrintDocument';
import getUrlToPrintDocument from '../../../sale.v2/ConfirmDialog/Footer/CreateButton/helpers/getUrlToPrintDocument';
import useCloseMaintenanceMutation from '../../../../hooks/useCloseMaintenanceMutation';
var CreateButtonCloseMaintenanceComponent = function (props) {
    var _a = useCloseMaintenanceMutation(), handleSellMaintenance = _a[0], loading = _a[1].loading;
    var handleClick = function () { return __awaiter(void 0, void 0, void 0, function () {
        var settings, isCloseMaintenanceOpenVar, maintenanceId, formatSaleV2Var, invoiceSaleV2Var, cartItemsVar, saleTotalToPayVar, pinSaleV2Var, limitDaysVar, decision, satRequirement, data, response, formatToPrint, idToPrint, e_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    settings = getSettings();
                    isCloseMaintenanceOpenVar = isCloseMaintenanceOpen();
                    maintenanceId = isCloseMaintenanceOpenVar[1];
                    formatSaleV2Var = formatSaleV2();
                    invoiceSaleV2Var = invoiceSaleV2();
                    cartItemsVar = cartItems();
                    saleTotalToPayVar = saleTotalToPay();
                    pinSaleV2Var = pinSaleV2();
                    limitDaysVar = limitDays();
                    inputError({});
                    decision = getIfStockProductIsBelowZero(cartItemsVar);
                    satRequirement = getIfSatRequirementisOk(cartItemsVar);
                    if (formatSaleV2Var[0] !== -2 &&
                        decision &&
                        !settings.belowZeroInventory &&
                        pinSaleV2Var.length === 0)
                        throw new Error(setInputErrorFormat('pin', 'Alguno de los productos del carrito no cuenta con inventario, para realizar la venta, proporciona un PIN válido.'));
                    if (cartItemsVar.length === 0) {
                        isCloseMaintenanceOpen([false, null]);
                        throw new Error('Añade al menos un producto al carrito.');
                    }
                    if (formatSaleV2Var[2] && !satRequirement)
                        throw new Error('Alguno de los productos del carrito no cuenta con las claves del SAT configuradas.');
                    if (formatSaleV2Var[2] && invoiceSaleV2Var.paymentMethod.length === 0)
                        throw new Error(setInputErrorFormat('paymentMethod', 'Selecciona el método de pago.'));
                    if (formatSaleV2Var[2] && invoiceSaleV2Var.paymentForm.length === 0)
                        throw new Error(setInputErrorFormat('paymentForm', 'Selecciona la forma de pago.'));
                    if (formatSaleV2Var[2] && invoiceSaleV2Var.useCfdi.length === 0)
                        throw new Error(setInputErrorFormat('useCfdi', 'Selecciona el uso del CFDi.'));
                    if (formatSaleV2Var[2] &&
                        invoiceSaleV2Var.relateUUID.length === 0 &&
                        invoiceSaleV2Var.relateType.length > 0)
                        throw new Error(setInputErrorFormat('relateUUID', 'Proporciona el UUID a relacionar'));
                    if (formatSaleV2Var[2] &&
                        invoiceSaleV2Var.relateUUID.length > 0 &&
                        invoiceSaleV2Var.relateType.length === 0)
                        throw new Error(setInputErrorFormat('relateType', 'Selecciona el tipo de relación'));
                    return [4 /*yield*/, handleSellMaintenance({
                            variables: {
                                id: maintenanceId,
                                isInvoice: formatSaleV2Var[2],
                                pin: pinSaleV2Var,
                                limitDays: limitDaysVar,
                                Transactions: (saleTotalToPayVar || [])
                                    .map(function (tmp) {
                                    return tmp.total
                                        ? {
                                            paymentMethodId: tmp.id > 0 ? tmp.id : null,
                                            total: parseFloat(tmp.total),
                                        }
                                        : false;
                                })
                                    .filter(function (tmp) { return tmp; }),
                                paymentMethod: formatSaleV2Var[2] ? invoiceSaleV2Var.paymentMethod : null,
                                paymentForm: formatSaleV2Var[2] ? invoiceSaleV2Var.paymentForm : null,
                                useCfdi: formatSaleV2Var[2] ? invoiceSaleV2Var.useCfdi : null,
                                relateUUID: formatSaleV2Var[2] ? invoiceSaleV2Var.relateUUID : null,
                                relateType: formatSaleV2Var[2] ? invoiceSaleV2Var.relateType : null,
                            },
                        })];
                case 1:
                    data = (_a.sent()).data;
                    response = getResponseWithData(data);
                    if (!response) {
                        props.onClose();
                        return [2 /*return*/];
                    }
                    if (settings.formatToPrint === 1 && formatSaleV2Var[0] === 1)
                        ticket(response[1].Sale.id);
                    if (settings.formatToPrint === 2 ||
                        (settings.formatToPrint === 1 && formatSaleV2Var[0] !== 1)) {
                        formatToPrint = formatSaleV2Var[2] ? -1 : formatSaleV2Var[0];
                        idToPrint = getIdToPrintDocument(formatSaleV2Var[2] ? 5 : formatSaleV2Var[0], formatSaleV2Var[2], response[1].Sale);
                        window.open(getUrlToPrintDocument(formatToPrint, idToPrint));
                    }
                    props.onClose();
                    return [3 /*break*/, 3];
                case 2:
                    e_1 = _a.sent();
                    getInputErrorFormat(e_1);
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    return (React.createElement(Button, { disabled: loading, onClick: handleClick }, "Almacenar"));
};
export default CreateButtonCloseMaintenanceComponent;
