import React from 'react';
import { Divider } from '@mui/material';
import PointOfSaleIcon from '@mui/icons-material/PointOfSale';
import SalesLinkBlockOneListItemsMenuDashboardComponent from './SalesLink';
import CashFlowLinkBlockOneListItemsMenuDashboardComponent from './CashFlowLink';
import TitleDrawerMenuDashboardComponent from '../helpers/Title';
import AuthLinkBlockOneMenuDashboardComponent from './AuthLink';
import CashClosingLinkBlockOneMenuDashboardComponent from './CashClosingLink';
import ProductLinkBlockOneMenuDashboardComponent from './ProductLink';
import CustomerLinkBlockOneMenuDashboardComponent from './CustomerLink';
import ClassificationLinkBlockOneMenuDashboardComponent from './ClassificationLink';
import PromotionLinkBlockOneMenuDashboardComponent from './PromotionLink';
import PosReportsLinkBlockOneMenuDashboardComponent from './PosReports';
import EasportCrmLinkBlockOneMenuDashboardComponent from './EasportCrmLink';
import useGetSessionRole from '../../../../../hooks/useGetSessionRole';
import SurveyLinkBlockOneMenuDashboardComponent from './SurveyLink';
import GlobalInvoiceLinkMenuDashboardComponent from './GlobalInvoiceLink';
var BlockOneMenuDashboardComponent = function (props) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
    var _l = useGetSessionRole(), data = _l.data, loading = _l.loading, isOwner = _l.isOwner;
    if (loading || !data)
        return null;
    return (React.createElement("div", { className: 'w-full' },
        React.createElement(TitleDrawerMenuDashboardComponent, { icon: React.createElement(PointOfSaleIcon, null), title: 'Punto de venta' }),
        React.createElement(SalesLinkBlockOneListItemsMenuDashboardComponent, { posModule: props.posModule }),
        React.createElement(GlobalInvoiceLinkMenuDashboardComponent, null),
        (isOwner || !!((_b = (_a = data.Permissions) === null || _a === void 0 ? void 0 : _a.CashFlow) === null || _b === void 0 ? void 0 : _b.getCashFlow)) && (React.createElement(CashFlowLinkBlockOneListItemsMenuDashboardComponent, { posModule: props.posModule })),
        (isOwner || !!((_d = (_c = data.Permissions) === null || _c === void 0 ? void 0 : _c.CashClosing) === null || _d === void 0 ? void 0 : _d.getCashClosing)) && (React.createElement(CashClosingLinkBlockOneMenuDashboardComponent, null)),
        React.createElement(AuthLinkBlockOneMenuDashboardComponent, { posModule: props.posModule }),
        React.createElement(SurveyLinkBlockOneMenuDashboardComponent, null),
        React.createElement(EasportCrmLinkBlockOneMenuDashboardComponent, null),
        (isOwner || !!((_f = (_e = data.Permissions) === null || _e === void 0 ? void 0 : _e.Customer) === null || _f === void 0 ? void 0 : _f.getCustomers)) && (React.createElement(CustomerLinkBlockOneMenuDashboardComponent, null)),
        React.createElement(ProductLinkBlockOneMenuDashboardComponent, null),
        (isOwner || !!((_h = (_g = data.Permissions) === null || _g === void 0 ? void 0 : _g.Classification) === null || _h === void 0 ? void 0 : _h.getClassifications)) && (React.createElement(ClassificationLinkBlockOneMenuDashboardComponent, null)),
        (isOwner || !!((_k = (_j = data.Permissions) === null || _j === void 0 ? void 0 : _j.Promotion) === null || _k === void 0 ? void 0 : _k.getPromotion)) && (React.createElement(PromotionLinkBlockOneMenuDashboardComponent, null)),
        React.createElement(PosReportsLinkBlockOneMenuDashboardComponent, null),
        React.createElement(Divider, { className: 'my-2' })));
};
export default BlockOneMenuDashboardComponent;
