import React, { useEffect } from 'react';

import { useReactiveVar } from '@apollo/client';

import FilterDrawer from '../../presentationals/FilterDrawer.v2';
import { filter, searchField } from '../../apollo/cache';

const FilterDialog = () => {
  const searchFieldVar = useReactiveVar(searchField);
  const filterVar = useReactiveVar(filter);

  const handleAction = (e, data) => {
    const { startDate: start, endDate: end, branchId, useDates } = data;
    const dates = useDates ? { start, end, inRange: true } : {};
    filter({ ...dates, branchId });
  };

  useEffect(() => {
    if (searchFieldVar && searchFieldVar.trim() !== '')
      filter({ ...filterVar, like: searchFieldVar });
  }, [searchFieldVar]);

  return <FilterDrawer handleAction={handleAction} showDates showBranches optionalDates />;
};

export default FilterDialog;
