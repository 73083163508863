import React, { useState, useEffect } from 'react';
import { useMutation } from '@apollo/client';

import FormDialog from '../../presentationals/FormDialog';
import TextField from '../../presentationals/FormComponents/TextField';

import GQL from './_gql';

const defaultValues = {
  commentary: '',
};

const FormComponent = props => {
  const [values, setValues] = useState(defaultValues);

  const [updateCommentary, { loading }] = useMutation(GQL.UPDATE_COMMENTARY);

  useEffect(() => {
    if (props.commentary) setValues({ commentary: props.commentary });
  }, [props.commentary]);

  const handleAction = async () => {
    try {
      console.log(values.commentary);
      await updateCommentary({ variables: { id: props.id, commentary: values.commentary } });

      setValues(defaultValues);
      props.handleClose();
    } catch (e) {
      console.log(e);
    }
  };

  const handleClose = () => {
    props.handleClose();
    setValues(defaultValues);
  };

  const handleChange = (name, value) => {
    setValues(values => ({ ...values, [name]: value }));
  };

  return (
    <FormDialog
      isOpen={props.isOpen}
      isLoading={loading}
      title='Comentarios'
      handleClose={handleClose}
      handleAction={handleAction}
    >
      <TextField name='commentary' label='' onChange={handleChange} value={values.commentary} />
    </FormDialog>
  );
};

export default FormComponent;
