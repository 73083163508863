// updateEmployeeCalendarSettings(input: EmployeeCalendarSettingsData): EmployeeCalendarSettings!
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { gql } from '@apollo/client';
import FRAGMENT_EMPLOYEE_CALENDAR_SETTINGS from './fragmentEmployeeCalendarSettings';
var UPDATE_EMPLOYEE_CALENDAR_SETTINGS = gql(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  ", "\n  mutation UpdateEmployeeCalendarSettings($scheduleAvailability: ScheduleAvailabilityData) {\n    updateEmployeeCalendarSettings(input: { scheduleAvailability: $scheduleAvailability }) {\n      ...FragmentEmployeeCalendarSettings\n    }\n  }\n"], ["\n  ", "\n  mutation UpdateEmployeeCalendarSettings($scheduleAvailability: ScheduleAvailabilityData) {\n    updateEmployeeCalendarSettings(input: { scheduleAvailability: $scheduleAvailability }) {\n      ...FragmentEmployeeCalendarSettings\n    }\n  }\n"])), FRAGMENT_EMPLOYEE_CALENDAR_SETTINGS);
export default UPDATE_EMPLOYEE_CALENDAR_SETTINGS;
var templateObject_1;
