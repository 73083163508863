import React, { useMemo } from 'react';
import SelectFieldComponent from '../../../../../../presentationals/FormComponents/SelectField';
import getUnitBaseOptions from '../../../../helpers/getUnitBaseOptions';
import TextFieldComponent from '../../../../../../presentationals/FormComponents/TextField';
var UnitBaseFieldProductComponent = function (props) {
    var unitBaseOptions = useMemo(function () { return getUnitBaseOptions(); }, []);
    if (['PIEZA', 'KILOGRAMO', 'LITRO'].includes(props.value.toUpperCase()))
        return (React.createElement(SelectFieldComponent, { name: 'unitBase', label: 'Unidad de medida', onChange: props.onChange, value: props.value.toUpperCase(), options: unitBaseOptions, error: props.error, disabled: props.isLoading }));
    return (React.createElement(TextFieldComponent, { name: 'unitBase', label: 'Unidad de medida', onChange: props.onChange, value: props.value, error: props.error, disabled: props.isLoading }));
};
export default UnitBaseFieldProductComponent;
