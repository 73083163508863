var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React, { useState, useEffect, useMemo, useRef } from 'react';
import { useReactiveVar, useMutation } from '@apollo/client';
import Grid from '@mui/material/Grid';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import TextField from '../../../../../presentationals/FormComponents/TextField';
import SelectField from '../../../../../presentationals/FormComponents/SelectField';
import Button from '../../../../../presentationals/Button';
import { clearForm, form, step, purchaseOrderId, isStoreLoading, purchaseOrderCartItems, inputError, } from '../../../../../apollo/cache';
import { currencyFormat } from '../../../../../helpers';
import GQL from '../../../_gql';
import getSession from '../../../../../helpers/getSession';
import ProductFieldComponent from '../../../../../presentationals/FormComponents/ProductField';
import getDefaultCreatePurchaseOrderValues from './helpers/defaultValues';
import GQL_PRODUCT from '../../../../../apollo/gql/product';
import getInputErrorFormat from '../../../../sale.v2/Action/helpers/getInputErrorFormat';
import useApolloLazyQuery from '../../../../../hooks/useApolloLazyQuery';
import setInputErrorFormat from '../../../../sale.v2/Action/helpers/setInputErrorFormat';
var ProductFormComponent = function () {
    var productFieldRef = useRef(null);
    var defaultValues = useMemo(getDefaultCreatePurchaseOrderValues, []);
    var errors = useReactiveVar(inputError);
    var clearFormVar = useReactiveVar(clearForm);
    var formVar = useReactiveVar(form);
    var stepVar = useReactiveVar(step);
    var purchaseOrderIdVar = useReactiveVar(purchaseOrderId);
    var _a = useState(defaultValues), values = _a[0], setValues = _a[1];
    var _b = useState([]), ProductPresentations = _b[0], setProductPresentations = _b[1];
    var _c = useState(0), lastUnitPrice = _c[0], setLastUnitPrice = _c[1];
    var _d = useState(0), price = _d[0], setPrice = _d[1];
    var _e = useApolloLazyQuery(GQL_PRODUCT.GET_PRODUCT_BY_ID), getProductById = _e[0], productData = _e[1].data;
    var addProductToPurchaseOrder = useMutation(GQL.ADD_ITEM_V2, {
        update: function (cache, _a) {
            var data = _a.data;
            cache.modify({
                id: "PurchaseOrder:".concat(purchaseOrderIdVar),
                fields: {
                    PurchaseOrderDetails: function (cachedPurchaseOrderDetails) {
                        var newPurchaseOrderDetailRef = cache.writeFragment({
                            data: __assign(__assign({}, data.addPurchaseOrderProductV2), { lastUnitPrice: lastUnitPrice }),
                            fragment: GQL.FRAGMENT_NEW_PURCHASE_ORDER_DETAIL,
                        });
                        return __spreadArray(__spreadArray([], cachedPurchaseOrderDetails, true), [newPurchaseOrderDetailRef], false);
                    },
                },
            });
        },
    })[0];
    useEffect(function () {
        if (clearFormVar && stepVar === 1) {
            setValues(defaultValues);
            clearForm(false);
        }
    }, [clearFormVar]);
    var handleAdd = function () { return __awaiter(void 0, void 0, void 0, function () {
        var e, e, e, e, e, e, totalWOdiscount, purchaseOrderCartItemsVar, discount_1, total, variables, presentationData, e_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 3, 4, 5]);
                    isStoreLoading(true);
                    inputError({});
                    if (!values.productId && values.type === 1) {
                        e = new Error('Selecciona un producto.');
                        e.name = 'productId';
                        throw e;
                    }
                    if ((!values.concept || values.concept === '') && values.type === 2) {
                        e = new Error('Escribe un producto.');
                        e.name = 'concept';
                        throw e;
                    }
                    if (values.productPresentationId === '' && values.type === 1) {
                        e = new Error('Selecciona una presentación.');
                        e.name = 'productPresentationId';
                        throw e;
                    }
                    if (Number.isNaN(parseFloat(values.quantity)) || parseFloat(values.quantity) <= 0) {
                        e = new Error('Proporciona una cantidad.');
                        e.name = 'quantity';
                        throw e;
                    }
                    if ((productData === null || productData === void 0 ? void 0 : productData.isSerialized) && values.serialNumber === '')
                        throw new Error(setInputErrorFormat('serialNumber', 'Escribe un número de serie.'));
                    if (formVar.action === '3' &&
                        (Number.isNaN(parseFloat(values.price)) || parseFloat(values.price) < 0)) {
                        e = new Error('Proporciona un precio unitario.');
                        e.name = 'price';
                        throw e;
                    }
                    if (values.adjustPrice && Number.isNaN(parseFloat(values.percentAdjust))) {
                        e = new Error('Proporciona un porcentaje.');
                        e.name = 'percentAdjust';
                        throw e;
                    }
                    totalWOdiscount = parseFloat(values.quantity) * parseFloat(values.price);
                    purchaseOrderCartItemsVar = purchaseOrderCartItems();
                    discount_1 = formVar.discount && parseFloat(formVar.discount) > 0
                        ? parseInt(formVar.discountType) === 1
                            ? (formVar.discount / 100) * totalWOdiscount
                            : formVar.discount
                        : 0;
                    total = parseFloat(values.quantity) * parseFloat(values.price) - discount_1;
                    if (!purchaseOrderIdVar) return [3 /*break*/, 2];
                    variables = {
                        concept: values.type === 2 ? values.concept.trim() : null,
                        productId: values.type === 1 ? values.productId : null,
                        purchaseOrderId: purchaseOrderIdVar,
                        quantity: parseFloat(values.quantity),
                        productPresentationId: values.type === 1 ? values.productPresentationId : null,
                        unitPrice: parseFloat(values.price),
                        discount: discount_1,
                        serialNumber: (productData === null || productData === void 0 ? void 0 : productData.isSerialized) ? values.serialNumber : '',
                        percentAdjust: values.percentAdjust ? parseFloat(values.percentAdjust) : null,
                        iva: values.type === 2
                            ? values.iva && parseFloat(values.iva) > 0
                                ? parseFloat(values.iva)
                                : 0
                            : null,
                        ieps: values.type === 2 ? parseFloat(values.ieps || '0') : null,
                    };
                    return [4 /*yield*/, addProductToPurchaseOrder({ variables: variables })];
                case 1:
                    _a.sent();
                    (productFieldRef === null || productFieldRef === void 0 ? void 0 : productFieldRef.current) && productFieldRef.current.handleClear();
                    setValues(defaultValues);
                    return [2 /*return*/];
                case 2:
                    presentationData = values.type === 1
                        ? ProductPresentations.find(function (tmp) { return parseInt(tmp.id) === parseInt(values.productPresentationId); })
                        : undefined;
                    purchaseOrderCartItems(__spreadArray(__spreadArray([], purchaseOrderCartItemsVar.map(function (el) {
                        return parseInt(formVar.discountType) === 2
                            ? __assign(__assign({}, el), { discount: discount_1, total: el.quantity * el.price - discount_1 }) : el;
                    }), true), [
                        __assign(__assign({}, values), { iva: values.type === 1
                                ? (productData === null || productData === void 0 ? void 0 : productData.iva) && parseFloat(productData.iva) > 0
                                    ? productData.iva
                                    : 0
                                : values.iva, ieps: values.type === 1 ? productData === null || productData === void 0 ? void 0 : productData.ieps : values.ieps, concept: values.type === 2 ? values.concept.trim() : productData === null || productData === void 0 ? void 0 : productData.name, productId: values.type === 1 ? values.productId : null, total: total, serialNumber: (productData === null || productData === void 0 ? void 0 : productData.isSerialized) ? values.serialNumber : '', priceSell: values.adjustPrice ? parseFloat(price.toFixed(2)) : undefined, percentAdjust: values.percentAdjust ? parseFloat(values.percentAdjust) : undefined, discount: discount_1, productPresentationId: values.type === 1 && presentationData
                                ? {
                                    value: values.productPresentationId,
                                    label: presentationData === null || presentationData === void 0 ? void 0 : presentationData.name,
                                    barcode: presentationData === null || presentationData === void 0 ? void 0 : presentationData.barcode,
                                    sku: presentationData === null || presentationData === void 0 ? void 0 : presentationData.sku,
                                }
                                : null }),
                    ], false));
                    (productFieldRef === null || productFieldRef === void 0 ? void 0 : productFieldRef.current) && productFieldRef.current.handleClear();
                    setValues(defaultValues);
                    return [3 /*break*/, 5];
                case 3:
                    e_1 = _a.sent();
                    getInputErrorFormat(e_1);
                    return [3 /*break*/, 5];
                case 4:
                    isStoreLoading(false);
                    return [7 /*endfinally*/];
                case 5: return [2 /*return*/];
            }
        });
    }); };
    var handleProductChange = function (name, value) { return __awaiter(void 0, void 0, void 0, function () {
        var data_1, ProductPresentations_1, cost_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!value) return [3 /*break*/, 2];
                    return [4 /*yield*/, getProductById({ variables: { id: value } })];
                case 1:
                    data_1 = (_a.sent()).data;
                    if (data_1) {
                        ProductPresentations_1 = __spreadArray([
                            data_1.PresentationBase
                        ], data_1.ProductPresentations, true);
                        setProductPresentations(ProductPresentations_1.filter(function (el) { return el.forPursache; }));
                        cost_1 = data_1.PresentationBase.cost.toString();
                        setLastUnitPrice(parseFloat(cost_1));
                        setValues(function (values) { return (__assign(__assign({}, values), { productPresentationId: data_1.PresentationBase.id, price: cost_1 })); });
                    }
                    else {
                        setProductPresentations([]);
                        setValues(function (values) { return (__assign(__assign({}, values), { productPresentationId: '', price: '0' })); });
                    }
                    _a.label = 2;
                case 2:
                    setValues(function (values) {
                        var _a;
                        return (__assign(__assign({}, values), (_a = {}, _a[name] = value, _a)));
                    });
                    return [2 /*return*/];
            }
        });
    }); };
    var handleChange = function (name, value) {
        if (name === 'productPresentationId' && value) {
            var ProductPresentation_1 = ProductPresentations.find(function (tmp) { return parseInt(tmp.id) === parseInt(value); });
            if (ProductPresentation_1) {
                setLastUnitPrice(ProductPresentation_1.cost);
                setValues(function (values) { return (__assign(__assign({}, values), { price: ProductPresentation_1.cost.toString() })); });
            }
        }
        else if (name === 'productPresentationId' && !value) {
            setLastUnitPrice(0);
        }
        inputError({});
        setValues(function (values) {
            var _a;
            return (__assign(__assign({}, values), (_a = {}, _a[name] = value, _a)));
        });
    };
    var handleSwitchChange = function (e) {
        var _a;
        var session = getSession();
        var _b = e.target, name = _b.name, checked = _b.checked;
        setValues(function (values) {
            var _a;
            return (__assign(__assign({}, values), (_a = {}, _a[name] = checked, _a)));
        });
        if (values.price && parseFloat(values.price) > 0 && values.percentAdjust) {
            var price_1 = ((_a = session === null || session === void 0 ? void 0 : session.Company) === null || _a === void 0 ? void 0 : _a.id) === 71
                ? parseFloat(values.price || '0') /
                    ((100 - parseFloat(values.percentAdjust || '0')) / 100)
                : parseFloat(values.price || '0') * (1 + parseFloat(values.percentAdjust || '0') / 100);
            setPrice(price_1);
        }
    };
    var handleChangePercent = function (name, value) {
        var _a;
        var session = getSession();
        if (values.price && parseFloat(values.price) > 0 && value) {
            var price_2 = ((_a = session === null || session === void 0 ? void 0 : session.Company) === null || _a === void 0 ? void 0 : _a.id) === 71
                ? parseFloat(values.price || '0') / ((100 - parseFloat(value || '0')) / 100)
                : parseFloat(values.price || '0') * (1 + parseFloat(value || '0') / 100);
            setPrice(price_2);
        }
        setValues(function (values) {
            var _a;
            return (__assign(__assign({}, values), (_a = {}, _a[name] = value, _a)));
        });
    };
    var handleChangePrice = function (name, value) {
        var _a;
        var session = getSession();
        if (value && parseFloat(value) > 0) {
            var price_3 = ((_a = session === null || session === void 0 ? void 0 : session.Company) === null || _a === void 0 ? void 0 : _a.id) === 71
                ? parseFloat(value) / ((100 - parseFloat(values.percentAdjust || '0')) / 100)
                : parseFloat(value) * (1 + parseFloat(values.percentAdjust || '0') / 100);
            setPrice(price_3);
        }
        setValues(function (values) {
            var _a;
            return (__assign(__assign({}, values), (_a = {}, _a[name] = value, _a)));
        });
    };
    var handleProductClear = function () {
        setValues(function (values) { return (__assign(__assign({}, values), { productId: null })); });
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(Grid, { container: true, spacing: 1 },
            React.createElement(Grid, { item: true, xs: 3 },
                React.createElement(SelectField, { name: 'type', label: 'Tipo', onChange: handleChange, options: [
                        { label: 'Inv.', value: 1 },
                        { label: 'Libre', value: 2 },
                    ], value: values.type, error: errors.type })),
            React.createElement(Grid, { item: true, xs: 9 }, values.type === 1 ? (React.createElement(ProductFieldComponent, { ref: productFieldRef, name: 'productId', label: 'Producto', variables: {
                    type: [1, 5, 6],
                    forPursache: true,
                }, searchBy: 'select', initialValue: values.productId, onChange: handleProductChange, onClear: handleProductClear })) : (React.createElement(TextField, { name: 'concept', label: 'Producto 1', onChange: handleChange, value: values.concept, error: errors.concept })))),
        values.type === 2 && (React.createElement(Grid, { container: true, spacing: 1 },
            React.createElement(Grid, { item: true, xs: 12 },
                React.createElement(SelectField, { name: 'iva', label: 'IVA', onChange: handleChange, options: [
                        { label: '0%', value: 0 },
                        { label: '16%', value: 16 },
                    ], value: values.iva, error: errors.iva })))),
        values.type === 2 && (React.createElement(Grid, { container: true, spacing: 1 },
            React.createElement(Grid, { item: true, xs: 12 },
                React.createElement(SelectField, { name: 'ieps', label: 'IEPS', onChange: handleChange, options: [
                        { label: '0%', value: 0 },
                        { label: '8%', value: 8 },
                    ], value: values.ieps, error: errors.ieps })))),
        values.productId && ProductPresentations.length > 1 && (React.createElement(Grid, { container: true, spacing: 1 },
            React.createElement(Grid, { item: true, xs: 12 },
                React.createElement(SelectField, { name: 'productPresentationId', label: 'Selecciona la presentaci\u00F3n del producto', onChange: handleChange, options: ProductPresentations.map(function (el) { return ({ value: el.id, label: el.name }); }), value: values.productPresentationId, error: errors.productPresentationId })))),
        React.createElement(Grid, { container: true, spacing: 1 },
            React.createElement(Grid, { item: true, xs: 12 },
                React.createElement(TextField, { name: 'quantity', label: 'Cantidad', type: 'number', onChange: handleChange, value: values.quantity, error: errors.quantity }))),
        (!formVar.discount || parseFloat(formVar.discount) === 0) && (React.createElement(Grid, { container: true, spacing: 1 },
            React.createElement(Grid, { item: true, xs: 12 },
                React.createElement(TextField, { name: 'discount', label: "Descuento (".concat(values.discountType === 1 ? '%' : '$', ")"), type: 'number', onChange: handleChange, value: values.discount, error: errors.discount })))),
        React.createElement(Grid, { container: true, spacing: 1 },
            React.createElement(Grid, { item: true, xs: 12 },
                React.createElement(TextField, { name: 'price', label: 'Precio unitario de compra', type: 'number', onChange: handleChangePrice, value: values.price, error: errors.price }))),
        (productData === null || productData === void 0 ? void 0 : productData.isSerialized) && (React.createElement(Grid, { container: true, spacing: 1 },
            React.createElement(Grid, { item: true, xs: 12 },
                React.createElement(TextField, { name: 'serialNumber', label: 'Numero de serie', onChange: handleChange, value: values.serialNumber, error: errors.serialNumber })))),
        React.createElement(Grid, { container: true, spacing: 1 },
            React.createElement(Grid, { item: true, xs: 12 },
                React.createElement(FormControlLabel, { control: React.createElement(Switch, { checked: values.adjustPrice, onChange: handleSwitchChange, name: 'adjustPrice' }), label: '\u00BFDeseas cambiar el precio de venta?' }))),
        values.adjustPrice && (React.createElement(Grid, { container: true, spacing: 1 },
            React.createElement(Grid, { item: true, xs: 12 },
                React.createElement(TextField, { name: 'percentAdjust', label: 'Porcentaje de cambio', type: 'number', onChange: handleChangePercent, value: values.percentAdjust, error: errors.percentAdjust })))),
        values.productId && productData && (React.createElement("div", { className: 'mx-1' },
            React.createElement(Grid, { container: true, spacing: 1, className: 'my-1 border border-solid border-[#767676] rounded bg-[#f5f5f5]' },
                React.createElement(Grid, { item: true, xs: 6, className: 'border border-solid border-[#767676]' }, "M\u00EDnimo en inventario:"),
                React.createElement(Grid, { item: true, xs: 6, className: 'border border-solid border-[#767676]' }, "0"),
                React.createElement(Grid, { item: true, xs: 6, className: 'border border-solid border-[#767676]' }, "M\u00E1ximo en inventario:"),
                React.createElement(Grid, { item: true, xs: 6, className: 'border border-solid border-[#767676]' }, "0"),
                React.createElement(Grid, { item: true, xs: 6, className: 'border border-solid border-[#767676]' }, "Inventario actual:"),
                React.createElement(Grid, { item: true, xs: 6, className: 'border border-solid border-[#767676]' }, currencyFormat(productData.currentInventory || 0)),
                React.createElement(Grid, { item: true, xs: 6, className: 'border border-solid border-[#767676]' }, "\u00DAltimo costo:"),
                React.createElement(Grid, { item: true, xs: 6, className: 'border border-solid border-[#767676]' }, currencyFormat(lastUnitPrice)),
                values.adjustPrice && (React.createElement(Grid, { item: true, xs: 6 }, "Precio de venta:")),
                values.adjustPrice && (React.createElement(Grid, { item: true, xs: 6 }, currencyFormat(price)))))),
        React.createElement(Grid, { container: true, spacing: 1 },
            React.createElement(Grid, { item: true, xs: 12 },
                React.createElement(Button, { onClick: handleAdd, label: 'A\u00F1adir producto' })))));
};
export default ProductFormComponent;
