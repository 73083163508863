import React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import OpenAddProductIngredientPriceDialogButton from '../OpenAddProductIngredientPriceDialogButton';
import NoProductPricesAlertEditProductIngredientComponent from './NoProductPricesAlert';
import ItemProductPriceTableEditIngredientComponent from '../Item';
var BodyProductIngredientRatesComponent = function (props) {
    return (React.createElement("div", { className: 'p-3 flex flex-1 flex-col' },
        React.createElement("div", { className: 'flex items-center' },
            React.createElement("div", { className: 'text-base font-medium mb-1 flex flex-1' }, "Tarifas del ingrediente"),
            React.createElement(OpenAddProductIngredientPriceDialogButton, null)),
        React.createElement("div", { className: 'text-sm sm:text-base text-gray-600' }, "Personaliza tus tarifas seg\u00FAn tus necesidades. Ya sea que los precios de este ingrediente var\u00EDen entre diferentes sucursales o fluct\u00FAen seg\u00FAn la plataforma, aqu\u00ED tienes el control total para ajustarlos a tu conveniencia. Adapta tus tarifas para cada contexto sin complicaciones y aseg\u00FArate de que siempre reflejen las condiciones \u00F3ptimas para tu negocio."),
        props.ProductIngredientPrices.length === 0 ? (React.createElement(NoProductPricesAlertEditProductIngredientComponent, null)) : (React.createElement("div", { className: 'pt-4 flex flex-1 basis-0 overflow-auto w-full' },
            React.createElement(TableContainer, { className: 'rounded border border-solid border-[#d8d8d8]' },
                React.createElement(Table, { stickyHeader: true, size: 'small' },
                    React.createElement(TableHead, null,
                        React.createElement(TableRow, { className: 'bg-[#f6f6f6]' },
                            React.createElement(TableCell, null, "Precio"),
                            React.createElement(TableCell, null, "Plataforma"),
                            React.createElement(TableCell, null, "Sucursal"))),
                    React.createElement(TableBody, null, props.ProductIngredientPrices.map(function (el, i) { return (React.createElement(ItemProductPriceTableEditIngredientComponent, { key: el.id, index: i, ProductIngredientPrice: el })); }))))))));
};
export default BodyProductIngredientRatesComponent;
