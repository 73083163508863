var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useEffect } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import ItemCashClossinSummaryIncomeReportComponent from '../../cashClosing/Item/Detail/Item';
import ItemMultiCompanyReportComponent from './Item';
import useApolloLazyQuery from '../../../hooks/useApolloLazyQuery';
import GQL_MULTI_COMPANY from '../../../apollo/gql/multiCompany';
import useDateRangeFieldStore from '../../../store/filterInputs/dateRangeFieldStore';
import useSelectBranchesDialogStore from '../../../store/multiCompanyReport/selectBranchesDialogStore';
import LoadingProgress from '../../../presentationals/LoadingProgress';
import ErrorDataComponent from '../../../presentationals/ErrorData';
var BodyMultiCompanyReportComponent = function () {
    var dates = useDateRangeFieldStore(function (state) { return state.value; });
    var branches = useSelectBranchesDialogStore(function (state) { return state.branches; });
    var _a = useApolloLazyQuery(GQL_MULTI_COMPANY.GET_MULTI_COMPANY_REPORT, {
        fetchPolicy: 'no-cache',
    }), getMultiCompanyReport = _a[0], _b = _a[1], data = _b.data, loading = _b.loading, error = _b.error;
    useEffect(function () {
        if (dates && branches.length > 0)
            getMultiCompanyReport({
                variables: {
                    start: dates[0],
                    end: dates[1],
                    branchId: branches,
                },
            });
    }, [dates, branches]);
    if (loading)
        return React.createElement(LoadingProgress, null);
    if (error && !data)
        return React.createElement(ErrorDataComponent, null);
    return (React.createElement("div", { className: 'flex flex-col p-2 overflow-auto whitespace-nowrap rounded-t-md' },
        React.createElement("div", { className: 'flex flex-col overflow-auto bg-slate-700' },
            React.createElement("div", { className: 'flex font-normal p-2 text-lg text-white ' }, "Resumen de operaciones"),
            React.createElement("div", { className: 'flex flex-row w-100 border-0 border-t border-b border-solid border-gray-400 p-2' },
                React.createElement("div", { className: 'flex mr-4' },
                    React.createElement(ItemCashClossinSummaryIncomeReportComponent, { label: 'Ingresos', total: (data === null || data === void 0 ? void 0 : data.summary.Income.total) || 0, qty: (data === null || data === void 0 ? void 0 : data.summary.Income.qty) || 0 })),
                React.createElement("div", { className: 'flex mr-4' },
                    React.createElement(ItemCashClossinSummaryIncomeReportComponent, { label: 'Ventas', total: (data === null || data === void 0 ? void 0 : data.summary.Sales.total) || 0, qty: (data === null || data === void 0 ? void 0 : data.summary.Sales.qty) || 0 })),
                React.createElement("div", { className: 'flex mr-4' },
                    React.createElement(ItemCashClossinSummaryIncomeReportComponent, { label: 'Cancelaciones', total: (data === null || data === void 0 ? void 0 : data.summary.SalesCancelled.total) || 0, qty: (data === null || data === void 0 ? void 0 : data.summary.SalesCancelled.qty) || 0 })),
                React.createElement("div", { className: 'flex mr-4' },
                    React.createElement(ItemCashClossinSummaryIncomeReportComponent, { label: 'Descuentos', total: (data === null || data === void 0 ? void 0 : data.summary.SalesDiscount.total) || 0, qty: (data === null || data === void 0 ? void 0 : data.summary.SalesDiscount.qty) || 0 })),
                React.createElement("div", { className: 'flex mr-4' },
                    React.createElement(ItemCashClossinSummaryIncomeReportComponent, { label: 'Movimientos de caja', total: (data === null || data === void 0 ? void 0 : data.summary.CashFlowBalance.total) || 0, qty: (data === null || data === void 0 ? void 0 : data.summary.CashFlowBalance.qty) || 0 })))),
        React.createElement("div", { className: 'flex flex-1 flex-col overflow-auto mb-4' },
            React.createElement(TableContainer, null,
                React.createElement(Table, { stickyHeader: true, size: 'small' },
                    React.createElement(TableHead, null,
                        React.createElement(TableRow, null,
                            React.createElement(TableCell, null, "Empresa"),
                            React.createElement(TableCell, null, "Sucursal"),
                            React.createElement(TableCell, null, "Ingresos"),
                            React.createElement(TableCell, null, "Ventas"),
                            React.createElement(TableCell, null, "Cancelaciones"),
                            React.createElement(TableCell, null, "Descuentos"),
                            React.createElement(TableCell, null, "Movimientos de Caja"))),
                    React.createElement(TableBody, null, data === null || data === void 0 ? void 0 : data.branches.map(function (el, i) { return (React.createElement(ItemMultiCompanyReportComponent, __assign({ index: i, key: el.id }, el))); })))))));
};
export default BodyMultiCompanyReportComponent;
