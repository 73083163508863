import React from 'react';

import Fab from '@mui/material/Fab';
import Tooltip from '@mui/material/Tooltip';

const FabButtonComponent = props => {
  const handleClick = e => {
    props.action(e, props.params);
  };

  return (
    <Tooltip title={props.label}>
      <span>
        <Fab
          color={props.color || 'secondary'}
          size='small'
          className={props.className}
          onClick={handleClick}
        >
          {props.icon}
        </Fab>
      </span>
    </Tooltip>
  );
};

export default FabButtonComponent;
