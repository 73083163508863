import React from 'react';
import AddCircleIcon from '@mui/icons-material/AddOutlined';
import IconButtonComponent from '../../../IconButton';
import { isCreateFormOpen } from '../../../../apollo/cache';
var AddButtonHeaderModuleV3Component = function (props) {
    var handleClick = function () {
        if (props.customizedCreateOpenForm) {
            props.customizedCreateOpenForm();
            return;
        }
        isCreateFormOpen(true);
    };
    return (React.createElement(IconButtonComponent, { action: handleClick, icon: React.createElement(AddCircleIcon, null), size: 'large', color: 'secondary', label: 'Nuevo', disabled: props.isLoading }));
};
export default AddButtonHeaderModuleV3Component;
