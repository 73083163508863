import React, { useEffect, useState } from 'react';
import { Tabs, Tab } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import ReceiptIcon from '@mui/icons-material/Receipt';
import PersonIcon from '@mui/icons-material/Person';
import CategoryIcon from '@mui/icons-material/Category';
import InventoryIcon from '@mui/icons-material/Inventory';
import VerifiedIcon from '@mui/icons-material/Verified';
import getMenuValueFromPath from './getMenuValueFromPath';
import getRouteFromMenuValue from './getRouteFromMenuValue';
var MenuCashClosingLayoutComponent = function () {
    var navigate = useNavigate();
    var location = useLocation();
    var _a = useState(0), value = _a[0], setValue = _a[1];
    useEffect(function () {
        var value = getMenuValueFromPath(location.pathname);
        setValue(value);
    }, [location.pathname]);
    var handleChange = function (e, value) {
        var route = getRouteFromMenuValue(value);
        if (route)
            navigate(route);
    };
    return (React.createElement(Tabs, { value: value, onChange: handleChange, variant: 'scrollable', scrollButtons: 'auto', className: 'flex bg-slate-100 border-0 border-y border-[#d6d6d6] border-solid' },
        React.createElement(Tab, { icon: React.createElement(ReceiptIcon, null), label: 'Corte de caja' }),
        React.createElement(Tab, { icon: React.createElement(VerifiedIcon, null), label: 'Totales por folio' }),
        React.createElement(Tab, { icon: React.createElement(CategoryIcon, null), label: 'Totales por clasificacion' }),
        React.createElement(Tab, { icon: React.createElement(InventoryIcon, null), label: 'Totales por producto' }),
        React.createElement(Tab, { icon: React.createElement(PersonIcon, null), label: 'Totales por empleado' })));
};
export default MenuCashClosingLayoutComponent;
