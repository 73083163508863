import React, { useState, useEffect } from 'react';

import { useReactiveVar } from '@apollo/client';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import { productsToPrint } from '../../apollo/cache';

import TextField from '../../presentationals/TextField';
import FormDialog from '../../presentationals/FormDialog';

import PDF from './barcode';

const FormComponent = () => {
  const productsToPrintVar = useReactiveVar(productsToPrint);
  const [presentations, setPresentations] = useState([]);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (productsToPrintVar[1] && productsToPrintVar[1].length > 0)
      setPresentations(productsToPrintVar[1]);
  }, [productsToPrintVar]);

  const handleClose = () => {
    setErrors({});
    setPresentations([]);
    productsToPrint([false, []]);
  };

  const handlePrint = () => {
    try {
      PDF(presentations);
      handleClose();
    } catch (e) {
      setErrors({ [e.name]: e.message });
    }
  };

  const handleChange = e => {
    const { name, value } = e.target;
    const tmp = presentations.map((el, i) => {
      if (i === parseInt(name))
        return {
          ...el,
          total: parseInt(value),
        };
      return el;
    });
    setPresentations(tmp);
  };

  return (
    <FormDialog
      isOpen={productsToPrintVar[0]}
      // isLoading={loading}
      title='Presentaciones'
      disableEscape
      width='md'
      handleClose={handleClose}
      handleAction={handlePrint}
      actionLabel='Imprimir'
    >
      <TableContainer>
        <Table size='small'>
          <TableHead>
            <TableRow>
              <TableCell>Presentación</TableCell>
              <TableCell>Cant. a Imprimir</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {presentations &&
              presentations.length > 0 &&
              presentations.map((el, i) => (
                <TableRow key={i}>
                  <TableCell>
                    {el.name
                      ? `${el.name} ${el.Presentation ? el.Presentation.name : ''}`.trim()
                      : '-'}
                  </TableCell>
                  <TableCell>
                    <TextField
                      name={`${i}`}
                      label=''
                      variant='outlined'
                      value={presentations[`${i}`].total}
                      error={errors[`${i}`]}
                      onChange={handleChange}
                      type='number'
                      noFormat
                    />
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </FormDialog>
  );
};

export default FormComponent;
