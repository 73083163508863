import { Card } from '@mui/material';
import React from 'react';
import WhatsNewItemComponent from './Item';
var WhatsNewMainComponent = function () {
    return (React.createElement("div", { className: 'flex flex-1 flex-col w-full' },
        React.createElement("div", { className: 'flex text-lg tracking-tighter mt-2' }, "\u00DAltimas actualizaciones"),
        React.createElement(Card, { className: 'flex flex-1 flex-col mt-1 bg-slate-200 px-4 py-2 sm:basis-0 overflow-auto' },
            React.createElement(WhatsNewItemComponent, { date: new Date('2023-10-13'), lastUpdates: [
                    'Se añadió el reporte de rotación de ventas por promoción.',
                    'Se está desarrollando una nueva actualización para el módulo de Cuentas por cobrar.',
                ] }),
            React.createElement(WhatsNewItemComponent, { date: new Date('2023-10-12'), lastUpdates: [
                    'Se realizaron finalizó la optimización de código fuente en todos los módulos de reportes de ventas.',
                    'Se inició optimización de código fuente en el módulo de calendarios. Esto mejorará la velocidad de carga de datos.',
                ] }))));
};
export default WhatsNewMainComponent;
