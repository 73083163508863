import { Button } from '@mui/material';
import React from 'react';
import CONFIG from '../../../../../../config/config.json';
var DownloadInvoiceAcusePDFButtonComponent = function (props) {
    var handleClick = function () {
        window.open("".concat(CONFIG.restServer.uri, "/files/invoices/").concat(props.uuid, "/acuse/pdf"));
    };
    return (React.createElement(Button, { color: 'warning', variant: 'contained', className: 'px-5 py-3 mt-0 sm:mr-2', onClick: handleClick }, "Descargar Acuse"));
};
export default DownloadInvoiceAcusePDFButtonComponent;
