import React from 'react';
import { TableRow } from '@mui/material';
import { form, isAssignPaymentFormOpen } from '../../../../../../../apollo/cache';
var TableRowOpenAccountReceivableComponent = function (props) {
    var handleClick = function () {
        isAssignPaymentFormOpen(props.accountReceivableId);
        form({ currentPayment: props.currentPayment });
    };
    return React.createElement(TableRow, { onClick: handleClick }, props.children);
};
export default TableRowOpenAccountReceivableComponent;
