import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { Button } from '@mui/material';
import { snackbar } from '../../../../../apollo/cache';
import TextFieldComponent from '../../../TextField';
var SearchUnitKeyFormComponent = forwardRef(function (props, ref) {
    var _a = useState(''), value = _a[0], setValue = _a[1];
    var handleChange = function (name, value) {
        setValue(value);
    };
    var handleClick = function () {
        try {
            props.onClick(value);
        }
        catch (e) {
            snackbar({ isOpen: true, label: e.message, severity: 'error' });
        }
    };
    var handleKeyDown = function (e) {
        if (e.key === 'Enter') {
            e.preventDefault();
            handleClick();
        }
    };
    var handleSetValue = function (value) {
        setValue(value);
    };
    useImperativeHandle(ref, function () { return ({
        setValue: handleSetValue,
    }); });
    return (React.createElement("div", { className: 'flex items-center px-5 pt-3 pb-1' },
        React.createElement("div", { className: 'flex flex-1 pr-2' },
            React.createElement(TextFieldComponent, { autoFocus: true, name: 'search', defaultLabel: 'B\u00FAsqueda por nombre', value: value, onChange: handleChange, onKeyDown: handleKeyDown })),
        React.createElement("div", { className: 'mb-1' },
            React.createElement(Button, { color: 'secondary', variant: 'contained', onClick: handleClick }, "Buscar"))));
});
export default SearchUnitKeyFormComponent;
