import React from 'react';

import Container from '../../presentationals/Module.v2/Container';

import Action from './_action';
// import Filter from './_filter';
import { customerLabel } from '../../helpers';

import GQL from './_gql';

const customerVar = customerLabel({ textTransform: 'capitalize' });

const Rows = [
  { label: customerVar, key: 'Customer firstName && Customer lastName', filter: true },
  { label: 'Fecha', key: 'clientCreatedAt', sort: 'text', format: 'dateTime' },
  { label: 'Descuento', key: 'discount', sort: 'text', format: 'currency', align: 'right' },
  { label: 'Total', key: 'total', sort: 'text', format: 'currency', align: 'right' },
  { label: 'Estatus', key: 'status', sort: 'text', format: 'orderStatus' },
  {
    label: 'Domicilio',
    key: 'Customer addressLine1 && Customer addressLine2 && Customer zipCode && Customer reference && Customer city && Customer state',
    strcat: ', ',
    ifValue: { key: 'shipping', value: 0, newValue: 'PARA RECOGER EN TIENDA' },
  },
  { label: 'Cuenta Saldada', key: 'paid', format: 'yesNoType', sort: 'text', align: 'center' },
];
const title = 'Seguimiento de ecommerce';
const operation = ['ecSalesReport', 'ecSalesReport'];

const ClassificationComponent = () => {
  return (
    <>
      <Container title={title} operation={operation} Rows={Rows} get={GQL.GET} noDelete />
      <Action />
    </>
  );
};

export default ClassificationComponent;
