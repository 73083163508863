import React from 'react';
import { createBrowserRouter } from 'react-router-dom';
import DashboardComponent from '../containers/main';
import LandingPageComponent from '../containers/LandingPage';
import LoginComponent from '../containers/login';
import PanelLayoutComponent from './PanelLayout';
import NotFoundComponent from './NotFound';
import LandingLayoutComponent from './LandingLayout';
import CalendarComponent from '../containers/calendar';
import OrderAuthorizationComponent from '../containers/orderAuthorization';
import ChooseCompanyComponent from '../containers/dashboard/ChooseCompany';
import panelLayoutLoader from './PanelLayout/loader';
import landingLayoutLoader from './LandingLayout/loader';
import companyLayoutLoader from './CompanyLayout/loader';
import SaleV2Component from '../containers/sale.v2';
import PosReportsComponent from '../containers/posReports';
import QuotesComponent from '../containers/quotes';
import ServiceOrderV2Component from '../containers/serviceOrder.v2';
import MaintenanceComponent from '../containers/maintenance';
import EcSalesReportComponent from '../containers/ecSales.report';
import PayableByCustomerComponent from '../containers/payable/ByProvider';
import IncomeStatementReportComponent from '../containers/incomeStatement.report';
import PurchaseReportsComponent from '../containers/purchaseReports';
import TransferV2Component from '../containers/transfer.v2';
import PurchaseOrderV2Component from '../containers/purchaseOrder.v2';
import TransformationComponent from '../containers/transformation';
import BoxComponent from '../containers/box';
import AttendanceDetailReportComponent from '../containers/attendanceDetail.report';
import CustomerCheckInComponent from '../containers/customerCheckIn';
import SaleProductReportComponent from '../containers/saleProducts.report.v2';
// import SaleEmployeeReportComponent from '../containers/employeeSales.report';
import SaleCustomerReportComponent from '../containers/customerSale.report';
import ProductInventoryComponent from '../containers/productInventory.report.v2';
import ProductMovementComponent from '../containers/productMovement.report';
import ProductTransactionComponent from '../containers/productMovement';
import PurchaseOrderProductComponent from '../containers/purchaseOrderProducts.report';
import PurchaseOrderProductRotationComponent from '../containers/purchaseOrderDetails.report';
import PurchaseOrderTransactionComponent from '../containers/transactionPayable.report';
import TransferInventoryReportComponent from '../containers/transferProducts.report.v2';
import BranchTransferInventoryReportComponent from '../containers/transferProducts.report';
import ProductAdjustInventoryReportComponent from '../containers/adjustInventoryProducts.report';
import CrmReportComponent from '../containers/crm.report';
import getProductChildrenRoutes from './getProductChildrenRoutes';
import getPurchaseOrderChildrenRoutes from './getPurchaseOrderChildrenRoutes';
import CalendarQueueManagerComponent from '../containers/calendarQueueManager';
import CompanyLayoutComponent from './CompanyLayout';
import getCompanyChildrenRoutes from './getCompanyChildrenRoutes';
import IncomeReportComponent from '../containers/incomeReport';
import AttendanceLayoutComponent from './Attendance/AttendanceLayout';
import getRentChildrenRoutes from './getRentChildrenRoutes';
import getSaleChildrenRoutes from './getSaleChildrenRoutes';
import getServiceOrderChildrenRoutes from './getServiceOrderChildrenRoutes';
import OnlineInvoiceComponent from '../containers/OnlineInvoice';
import getAccountReceivableChildrenRoutes from './getAccountReceivableChildrenRoutes';
import ReceivableComponent from '../containers/receivable';
import CustomerReceivableV2Component from '../containers/receivable.v2/ByCustomer';
import SaleRotationLayoutComponent from './SaleRotationLayout';
import SaleProductRotationReportComponent from '../containers/saleProductRotationReport';
import SaleProductPresentationRotationReportComponent from '../containers/saleProductPresentationRotationReport';
import SaleProductIngredientRotationReportComponent from '../containers/saleProductIngredientRotationReport';
import SalePromotionRotationReportComponent from '../containers/salePromotionRotationReport';
import SaleProductsAndIngredientsRotationComponent from '../containers/saleProductsAndIngredientRotationReport';
import SalePromotionRotationDetailComponent from '../containers/salePromotionRotationReport/PromotionDetail';
import SurveysComponent from '../containers/surveys';
import SurveyLayoutComponent from './SurveyLayout';
import getSurveyChildrenRoutes from './getSurveyChildrenRoutes';
import getTransactionChildrenRoutes from './getTransactionChildrenRoutes';
import getInvoiceComplementChildrenRoutes from './getInvoiceComplementChildrenRoutes';
import ProductSaleDetailComponent from '../containers/saleProductRotationReport/ProductSaleDetail';
import ProductPresentationSaleDetailComponent from '../containers/saleProductPresentationRotationReport/ProductPresentationSaleDetail';
import getPromotionChildrenRoutes from './getPromotionChildrenRoutes';
import getInvoiceReportChildrenRoutes from './getInvoiceReportChildrenRoutes';
import CreateAccountComponent from '../containers/createAccount';
import RecoverPasswordNoAuthComponent from '../containers/recoverPasswordNoAuth';
import RecoverCodeVerificationComponent from '../containers/recoverPasswordNoAuth/VerificationCode';
import RecoverNewPasswordComponent from '../containers/recoverPasswordNoAuth/NewPassword';
import getCustomerChildrenRoutes from './getCustomerChildrenRoutes';
import getEmployeeChildrenRoutes from './getEmployeeChildrenRoutes';
import getProviderChildrenRoutes from './getProviderChildrenRoutes';
import getCashFlowChildrenRoutes from './getCashFlowChildrenRouter';
import getCashClosingChildrenRoutes from './getCashClosingChildrenRoutes';
import InventoryReportComponent from '../containers/inventoryReport';
import SaleReportComponent from '../containers/sale.report';
import CreditNoteReportComponent from '../containers/creditNote.report';
import InvoiceReportV2Component from '../containers/invoice.report.v2';
import InvoiceComplementReportComponent from '../containers/invoiceComplementReport';
import TransactionReportComponent from '../containers/transaction';
import GlobalInvoiceComponent from '../containers/globalInvoice';
import getClassificationChildrenRoutes from './getClassificationChildrenRoutes';
import getOperatingExpensesChildrenRoutes from './getOperatingExpensesChildrenRoutes';
import getRoleChildrenRoutes from './getRoleChildrenRoutes';
import getBranchChildrenRoutes from './getBranchChildrenRoutes';
import getRequisitionChildrenRoutes from './getRequisitionChildrenRoutes';
import getPaymentMethodChildrenRoutes from './getPaymentMethodChildren';
import ProductListComponent from '../containers/productList';
import getMultiCompanyReportChildrenRoutes from './getMultiCompanyReportChildrenRoutes';
import SaleEmployeeReportComponent from '../containers/employeeSalesReport';
import getEmployeeSalesReportChildrenRoutes from './getEmployeeSalesReportChildrenRoutes';
import getManufactureChildrenRoutes from './getManufactureChildrenRoutes';
import ItemSaleProductIngredientRotationReportComponent from '../containers/saleProductIngredientRotationReport/Item';
import ItemSaleProductPresentationRotationReportComponent from '../containers/saleProductPresentationRotationReport/Item';
import getAdjustInventoryChildrenRoutes from './getAdjustInventoryChildrenRoutes';
var productChildrenRoutes = getProductChildrenRoutes();
var purchaseOrderChildrenRoutes = getPurchaseOrderChildrenRoutes();
var cashFlowChildrenRouter = getCashFlowChildrenRoutes();
var companyChildrenRoutes = getCompanyChildrenRoutes();
var customerChildrenRoutes = getCustomerChildrenRoutes();
var cashClosingRoutes = getCashClosingChildrenRoutes();
var rentChildrenRoutes = getRentChildrenRoutes();
var saleChildrenRoutes = getSaleChildrenRoutes();
var serviceOrderChildrenRoutes = getServiceOrderChildrenRoutes();
var accountReceivableChildrenRoutes = getAccountReceivableChildrenRoutes();
var surveyChildrenRoutes = getSurveyChildrenRoutes();
var transactionChildrenRoutes = getTransactionChildrenRoutes();
var invoiceComplementChildrenRoutes = getInvoiceComplementChildrenRoutes();
var promotionChildrenRoutes = getPromotionChildrenRoutes();
var invoiceReportChildrenRoutes = getInvoiceReportChildrenRoutes();
var employeeChildrenRouter = getEmployeeChildrenRoutes();
var providerChildrenRoutes = getProviderChildrenRoutes();
var classificationChildrenRoutes = getClassificationChildrenRoutes();
var operatingExpensesChildrenRoutes = getOperatingExpensesChildrenRoutes();
var roleChildrenRoutes = getRoleChildrenRoutes();
var branchChildrenRoutes = getBranchChildrenRoutes();
var requisitionChildrenRoutes = getRequisitionChildrenRoutes();
var paymentMethodChildrenRoutes = getPaymentMethodChildrenRoutes();
var multiCompanyReportChildrenRoutes = getMultiCompanyReportChildrenRoutes();
var employeeSalesReportChildrenRoutes = getEmployeeSalesReportChildrenRoutes();
var manufactureChildrenRoutes = getManufactureChildrenRoutes();
var adjustInventoryChildreRoutes = getAdjustInventoryChildrenRoutes();
// const postReportsChildrenRoutes = getPostReportsChildrenRoutes();
var router = createBrowserRouter([
    {
        path: '/',
        element: React.createElement(LandingLayoutComponent, null),
        loader: landingLayoutLoader,
        children: [
            { index: true, element: React.createElement(LandingPageComponent, null) },
            { path: 'login', element: React.createElement(LoginComponent, null) },
            { path: 'login/signup', element: React.createElement(CreateAccountComponent, null) },
            { path: 'login/password/recovery', element: React.createElement(RecoverPasswordNoAuthComponent, null) },
            {
                path: 'login/password/recovery/codeVerification',
                element: React.createElement(RecoverCodeVerificationComponent, null),
            },
            {
                path: 'login/password/recovery/codeVerification/newPass',
                element: React.createElement(RecoverNewPasswordComponent, null),
            },
            { path: 'facturacion', element: React.createElement(OnlineInvoiceComponent, null) },
            { path: ':ecName/menu', element: React.createElement(ProductListComponent, null) },
        ],
    },
    { path: 'companies', loader: companyLayoutLoader, element: React.createElement(ChooseCompanyComponent, null) },
    {
        path: 'panel',
        element: React.createElement(PanelLayoutComponent, null),
        loader: panelLayoutLoader,
        children: [
            { index: true, element: React.createElement(DashboardComponent, null) },
            { path: 'adjustInventory', children: adjustInventoryChildreRoutes },
            {
                path: 'attendance',
                children: [
                    { index: true, element: React.createElement(AttendanceDetailReportComponent, null) },
                    { path: 'employee/:employeeId', element: React.createElement(AttendanceLayoutComponent, null) },
                ],
            },
            { path: 'branch', children: branchChildrenRoutes },
            { path: 'box', element: React.createElement(BoxComponent, null) },
            {
                path: 'calendar',
                children: [{ index: true, element: React.createElement(CalendarComponent, null) }],
            },
            { path: 'cashClosing', children: cashClosingRoutes },
            { path: 'cashFlow', children: cashFlowChildrenRouter },
            { path: 'classification', children: classificationChildrenRoutes },
            { path: 'company', element: React.createElement(CompanyLayoutComponent, null), children: companyChildrenRoutes },
            { path: 'customer', children: customerChildrenRoutes },
            { path: 'customer/calendar/checkIn', element: React.createElement(CustomerCheckInComponent, null) },
            { path: 'customer/receivable', element: React.createElement(ReceivableComponent, null) },
            {
                path: 'customer/:customerId/receivable',
                children: accountReceivableChildrenRoutes,
            },
            { path: 'customer/payable', element: React.createElement(PayableByCustomerComponent, null) },
            { path: 'employee', children: employeeChildrenRouter },
            { path: 'globalInvoice', element: React.createElement(GlobalInvoiceComponent, null) },
            { path: 'maintenance', element: React.createElement(MaintenanceComponent, null) },
            { path: 'manufacture', children: manufactureChildrenRoutes },
            { path: 'operatingExpenses', children: operatingExpensesChildrenRoutes },
            { path: 'orderAuthorization', element: React.createElement(OrderAuthorizationComponent, null) },
            { path: 'paymentMethod', children: paymentMethodChildrenRoutes },
            { path: 'pos/report', element: React.createElement(PosReportsComponent, null) },
            {
                path: 'product',
                children: productChildrenRoutes,
            },
            { path: 'promotion', children: promotionChildrenRoutes },
            { path: 'provider', children: providerChildrenRoutes },
            { path: 'purchaseOrder', children: purchaseOrderChildrenRoutes },
            { path: 'purchase/report', element: React.createElement(PurchaseReportsComponent, null) },
            { path: 'quotes', element: React.createElement(QuotesComponent, null) },
            {
                path: 'receivable',
                element: React.createElement(CustomerReceivableV2Component, null),
            },
            { path: 'rent', children: rentChildrenRoutes },
            { path: 'report/ecSale', element: React.createElement(EcSalesReportComponent, null) },
            { path: 'report/crm', element: React.createElement(CrmReportComponent, null) },
            {
                path: 'pos/report',
                children: [
                    { path: 'income', element: React.createElement(IncomeReportComponent, null) },
                    { path: 'sale/ticket', element: React.createElement(SaleReportComponent, null) },
                    { path: 'sale/employee', children: employeeSalesReportChildrenRoutes },
                    { path: 'sale/customer', element: React.createElement(SaleCustomerReportComponent, null) },
                    { path: 'sale/invoice/creditNote', element: React.createElement(CreditNoteReportComponent, null) },
                    { path: 'sale/invoice', element: React.createElement(InvoiceReportV2Component, null) },
                    { path: 'sale/invoice/complement', element: React.createElement(InvoiceComplementReportComponent, null) },
                    {
                        path: 'sale/rotation',
                        element: React.createElement(SaleRotationLayoutComponent, null),
                        children: [
                            {
                                path: 'product',
                                element: React.createElement(SaleProductRotationReportComponent, null),
                            },
                            {
                                path: 'presentation',
                                element: React.createElement(SaleProductPresentationRotationReportComponent, null),
                            },
                            {
                                path: 'ingredient',
                                element: React.createElement(SaleProductIngredientRotationReportComponent, null),
                            },
                            {
                                path: 'promotion',
                                element: React.createElement(SalePromotionRotationReportComponent, null),
                            },
                            {
                                path: 'productAndIngredient',
                                element: React.createElement(SaleProductsAndIngredientsRotationComponent, null),
                            },
                        ],
                    },
                    {
                        path: 'transaction',
                        element: React.createElement(TransactionReportComponent, null),
                    },
                ],
            },
            { path: 'report/incomeStatement', element: React.createElement(IncomeStatementReportComponent, null) },
            {
                path: 'report/inventory',
                element: React.createElement(InventoryReportComponent, null),
            },
            {
                path: 'report/inventory/adjust/product',
                element: React.createElement(ProductAdjustInventoryReportComponent, null),
            },
            { path: 'report/inventory/transfer', element: React.createElement(TransferInventoryReportComponent, null) },
            {
                path: 'report/inventory/transfer/branch',
                element: React.createElement(BranchTransferInventoryReportComponent, null),
            },
            { path: 'report/product/inventory', element: React.createElement(ProductInventoryComponent, null) },
            { path: 'report/product/transaction', element: React.createElement(ProductTransactionComponent, null) },
            { path: 'report/product/movement', element: React.createElement(ProductMovementComponent, null) },
            { path: 'report/sale/customer', element: React.createElement(SaleCustomerReportComponent, null) },
            { path: 'report/sale/employee', element: React.createElement(SaleEmployeeReportComponent, null) },
            { path: 'report/multicompany', children: multiCompanyReportChildrenRoutes },
            {
                path: 'report/sale/invoice',
                children: invoiceReportChildrenRoutes,
            },
            { path: 'pos/report/sale/invoice/complement', children: invoiceComplementChildrenRoutes },
            // { path: 'report/sale/invoice/creditNote', element: <SaleInvoiceCreditNoteReportComponent /> },
            { path: 'pos/report/sale/product', element: React.createElement(SaleProductReportComponent, null) },
            {
                path: 'report/sale/rotation',
                element: React.createElement(SaleRotationLayoutComponent, null),
                children: [
                    {
                        path: 'product',
                        element: React.createElement(SaleProductRotationReportComponent, null),
                    },
                    {
                        path: 'presentation',
                        element: React.createElement(SaleProductPresentationRotationReportComponent, null),
                    },
                    {
                        path: 'ingredient',
                        element: React.createElement(SaleProductIngredientRotationReportComponent, null),
                    },
                    {
                        path: 'promotion',
                        element: React.createElement(SalePromotionRotationReportComponent, null),
                    },
                    {
                        path: 'productAndIngredient',
                        element: React.createElement(SaleProductsAndIngredientsRotationComponent, null),
                    },
                ],
            },
            {
                path: 'pos/report/sale/rotation/ingredient/:productId',
                element: React.createElement(ItemSaleProductIngredientRotationReportComponent, null),
            },
            {
                path: 'pos/report/sale/rotation/presentation/:productPresentationId',
                element: React.createElement(ItemSaleProductPresentationRotationReportComponent, null),
            },
            {
                path: 'report/sale/rotation/product/:productId',
                element: React.createElement(ProductSaleDetailComponent, null),
            },
            {
                path: 'report/sale/rotation/presentation/:presentationId',
                element: React.createElement(ProductPresentationSaleDetailComponent, null),
            },
            {
                path: 'report/sale/rotation/promotion/:promotionId',
                element: React.createElement(SalePromotionRotationDetailComponent, null),
            },
            { path: 'pos/report/sale/ticket', children: saleChildrenRoutes },
            { path: 'report/purchaseOrder/product', element: React.createElement(PurchaseOrderProductComponent, null) },
            {
                path: 'report/purchaseOrder/product/rotation',
                element: React.createElement(PurchaseOrderProductRotationComponent, null),
            },
            { path: 'report/purchaseOrder/transaction', element: React.createElement(PurchaseOrderTransactionComponent, null) },
            { path: 'pos/report/transaction', children: transactionChildrenRoutes },
            { path: 'requisition', children: requisitionChildrenRoutes },
            { path: 'role', children: roleChildrenRoutes },
            { path: 'serviceOrder', children: serviceOrderChildrenRoutes },
            { path: 'survey', element: React.createElement(SurveysComponent, null) },
            {
                path: 'survey/:surveyId',
                element: React.createElement(SurveyLayoutComponent, null),
                children: surveyChildrenRoutes,
            },
            { path: 'transformation', element: React.createElement(TransformationComponent, null) },
            { path: 'v2/inventory', element: React.createElement(ProductInventoryComponent, null) },
            { path: 'v2/serviceOrder', element: React.createElement(ServiceOrderV2Component, null) },
            {
                path: 'v2/product',
                children: productChildrenRoutes,
            },
            { path: 'v2/purchaseOrder', element: React.createElement(PurchaseOrderV2Component, null) },
            { path: 'v2/transfer', element: React.createElement(TransferV2Component, null) },
            { path: 'v2/sale', element: React.createElement(SaleV2Component, null) },
        ],
    },
    {
        path: 'queueManager',
        element: React.createElement(CalendarQueueManagerComponent, null),
        loader: panelLayoutLoader,
    },
    { path: '*', element: React.createElement(NotFoundComponent, null) },
]);
export default router;
