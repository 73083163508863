import React from 'react';
import { TableCell } from '@mui/material';
import TableRowItemProductPriceTableEditIngredientComponent from './TableRow';
import { currencyFormat } from '../../../../helpers';
import getPlatformLabel from '../../../../helpers/getPlatformLabel';
var ItemProductPriceTableEditIngredientComponent = function (props) {
    var _a;
    return (React.createElement(TableRowItemProductPriceTableEditIngredientComponent, { productIngredientId: props.ProductIngredientPrice.id, background: props.index % 2 > 0 ? 'bg-inherit' : 'bg-gray-100' },
        React.createElement(TableCell, null, currencyFormat(props.ProductIngredientPrice.price)),
        React.createElement(TableCell, null, !props.ProductIngredientPrice.type
            ? 'Cualquier plataforma'
            : getPlatformLabel(props.ProductIngredientPrice.type)),
        React.createElement(TableCell, null, ((_a = props.ProductIngredientPrice.Branch) === null || _a === void 0 ? void 0 : _a.name) || 'Cualquier sucursal')));
};
export default ItemProductPriceTableEditIngredientComponent;
