var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import HeaderEditAppliedSurveyComponent from './Header';
import FooterFormComponent from '../../../presentationals/RHForm/Form/Footer';
import RHFButtonComponent from '../../../presentationals/RHForm/Button';
import RHFormComponent from '../../../presentationals/RHForm/Form/indext';
import useSurveyCustomer from '../../../hooks/useSurveyCustomer';
import LoadingProgress from '../../../presentationals/LoadingProgress';
import ErrorDataComponent from '../../../presentationals/ErrorData';
import SurveyQuestionComponent from './Question';
import getFormAnswerValuesFormat from '../ApplySurvey/CreateButton/helpers/getFormAnswerValuesFormat';
import getInputErrorFormat from '../../sale.v2/Action/helpers/getInputErrorFormat';
import getFormattedAnswerValues from '../ApplySurvey/CreateButton/helpers/getFormattedAnswerValues';
import useApolloMutation from '../../../hooks/useApolloMutation';
import GQL_SURVEY from '../../../apollo/gql/survey';
import useSnackbarDialog from '../../../store/snackbar/snackbarDialog';
var EditAppliedSurveyComponent = function () {
    var setSnackbar = useSnackbarDialog().setSnackbar;
    var params = useParams();
    var _a = useSurveyCustomer(params.surveyId), data = _a.data, loading = _a.loading;
    var _b = useApolloMutation(GQL_SURVEY.UPDATE_SURVEY_CUSTOMER), update = _b[0], loadingCreate = _b[1].loading;
    var defaultValues = useMemo(function () {
        if (!data)
            return {};
        return data.SurveyCustomerAnswers.reduce(function (values, el) {
            var _a;
            return el.surveyCustomerAnswerId
                ? __assign(__assign({}, values), (_a = {}, _a["".concat(el.surveyCustomerAnswerId)] = getFormAnswerValuesFormat(el.answer, el.questionType), _a)) : values;
        }, {});
    }, [data]);
    var handleSubmit = function (values) { return __awaiter(void 0, void 0, void 0, function () {
        var error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, update({
                            variables: {
                                id: params.surveyId,
                                answers: Object.keys(values)
                                    .map(function (id) {
                                    return id
                                        ? {
                                            id: id,
                                            answer: getFormattedAnswerValues(values[id]),
                                        }
                                        : undefined;
                                })
                                    .filter(function (el) { return el; }),
                            },
                        })];
                case 1:
                    _a.sent();
                    setSnackbar({
                        isOpen: true,
                        label: 'Los datos fueron actualizados exitosamente.',
                        severity: 'success',
                    });
                    return [3 /*break*/, 3];
                case 2:
                    error_1 = _a.sent();
                    getInputErrorFormat(error_1);
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    if (loading)
        return React.createElement(LoadingProgress, null);
    if (!data)
        return React.createElement(ErrorDataComponent, null);
    return (React.createElement(RHFormComponent, { defaultValues: defaultValues, onSubmit: handleSubmit },
        React.createElement(HeaderEditAppliedSurveyComponent, null),
        React.createElement("div", { className: 'grid grid-cols-1 sm:grid-cols-3 flex-1' },
            React.createElement("div", { className: 'flex flex-1 flex-col basis-0 overflow-auto px-4 py-3' },
                React.createElement("div", { className: 'text-xl' }, data.Survey.name),
                data.SurveyCustomerAnswers.map(function (el) { return (React.createElement(SurveyQuestionComponent, { key: el.id, label: el.question, type: el.questionType, name: el.surveyCustomerAnswerId ? el.surveyCustomerAnswerId.toString() : undefined })); }))),
        React.createElement(FooterFormComponent, null,
            React.createElement("div", null),
            React.createElement(RHFButtonComponent, { disabled: loadingCreate || loading }, "Editar entrevista"))));
};
export default EditAppliedSurveyComponent;
