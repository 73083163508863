var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
var addAdjustProductById = function (Item, Rows) {
    var index = Rows.findIndex(function (el) {
        return (el.barcode && el.barcode === Item.barcode) ||
            el.productPresentationId === Item.productPresentationId;
    });
    if (typeof index === 'number' && index >= 0)
        return __spreadArray([
            {
                productId: Item.productId,
                barcode: Item.barcode,
                productPresentationId: Item.productPresentationId,
                quantity: Item.quantity,
                newInvQty: Rows[index].newInvQty + Item.quantity,
                movementQty: Rows[index].movementQty ? Rows[index].movementQty + 1 : 1,
                history: __spreadArray(__spreadArray([], (Rows[index].history || []), true), [
                    {
                        movement: Rows[index].history ? Rows[index].history.length + 1 : 1,
                        quantity: Item.quantity,
                        total: Rows[index].newInvQty + Item.quantity,
                    },
                ], false),
            }
        ], Rows.filter(function (el, i) { return i !== index; }), true);
    return __spreadArray([
        {
            productId: Item.productId,
            barcode: Item.barcode,
            quantity: Item.quantity,
            productPresentationId: Item.productPresentationId,
            movementQty: 1,
            newInvQty: Item.quantity,
            history: [{ movement: 1, quantity: Item.quantity, total: Item.quantity }],
        }
    ], Rows, true);
};
export default addAdjustProductById;
