import { gql } from '@apollo/client';

const GET = gql`
  query PurchaseOrders(
    $branchId: ID
    $paymentForm: String
    $name: String
    $limit: Int
    $offset: Int
    $status: Int
    $start: Date
    $end: Date
    $order: [String]
    $providerId: ID
    $type: Int
  ) {
    purchaseOrders(
      branchId: $branchId
      paymentForm: $paymentForm
      like: $name
      limit: $limit
      offset: $offset
      status: $status
      start: $start
      end: $end
      order: $order
      providerId: $providerId
      type: $type
    ) {
      count
      totals {
        qty
        amount
        name
      }
      rows {
        id
        folio
        serial
        date
        subtotal
        discount
        taxes
        description
        total
        type
        ivaTotal
        iepsTotal
        subFolio
        status
        currency
        exchangeRate
        usdTotal
        createdAt
        bonus
        Provider {
          id
          name
        }
        Employee {
          id
          firstName
          lastName
        }
        deletedAt
        background
      }
    }
  }
`;

const GET_BY_ID = gql`
  query PurchaseOrder($id: ID!) {
    purchaseOrder(id: $id) {
      id
      folio
      status
      serial
      description
      date
      createdAt
      subtotal
      discount
      taxes
      total
      type
      ivaTotal
      iepsTotal
      subFolio
      currency
      paymentForm
      fileId
      exchangeRate
      bonus
      createdAt
      PurchaseOrderDetails {
        id
        type
        Product {
          sku
          name
          iva
          ieps
          code
          barcode
          altBarcode
        }
        quantity
        unitPrice
        discount
        total
        subtotal
        ProductUnit {
          name
        }
        ProductPresentation {
          id
          name
          sku
          barcode
          altBarcode
        }
        concept
        iepsTotal
        ivaTotal
        iva
        ieps
        originalUnitPrice
        receivedQuantity
        lastUnitPrice
      }
      Provider {
        name
        phone
        identifier
        businessName
        email
        altEmail
        otherEmail
      }
      Employee {
        firstName
        lastName
      }
      AuthEmployee {
        firstName
        lastName
      }
      Company {
        id
        name
      }
      Branch {
        id
        name
        addressLine1
        addressLine2
        zipCode
        municipality
        city
        state
        phone
        altPhone
      }
    }
  }
`;

const CREATE = gql`
  mutation CreatePurchaseOrder(
    $date: Date
    $description: String
    $status: Int!
    $providerId: ID
    $branchId: ID!
    $subFolio: String
    $paymentForm: String
    $type: Int
    $currency: String
    $exchangeRate: Float
    $bonus: Float
    $Products: [ProductPurchaseOrderDataV2]
  ) {
    createPurchaseOrderV2(
      input: {
        date: $date
        description: $description
        providerId: $providerId
        branchId: $branchId
        status: $status
        subFolio: $subFolio
        paymentForm: $paymentForm
        type: $type
        currency: $currency
        exchangeRate: $exchangeRate
        bonus: $bonus
        Products: $Products
      }
    ) {
      id
      folio
      serial
      date
      subtotal
      discount
      taxes
      total
      type
      status
      subFolio
      bonus
      Provider {
        id
        name
      }
      Employee {
        firstName
        lastName
      }
      PurchaseOrderDetails {
        quantity
        unitPrice
        Product {
          id
        }
        ProductPresentation {
          id
        }
      }
      AccountPayable {
        id
        clientCreatedAt
        folio
        serial
        discount
        subtotal
        taxes
        total
        payment
        balance
        name
        customerId
        timeLimit
        limitDate
        type
        background
        paid
        purchaseOrderId
        PurchaseOrder {
          serial
          subFolio
        }
        Provider {
          id
          name
        }
        currency
        usdTotal
      }
    }
  }
`;

const CLOSE_PURCHASE_ORDER = gql`
  mutation ClosePurchaseOrder(
    $id: ID!
    $subFolio: String
    $paymentForm: String!
    $type: Int!
    $description: String
  ) {
    closePurchaseOrder(
      id: $id
      input: {
        subFolio: $subFolio
        paymentForm: $paymentForm
        type: $type
        description: $description
      }
    ) {
      id
      folio
      serial
      date
      subtotal
      discount
      taxes
      total
      type
      status
      subFolio
      Provider {
        id
        name
      }
      Employee {
        firstName
        lastName
      }
      AccountPayable {
        id
        clientCreatedAt
        folio
        serial
        discount
        subtotal
        taxes
        total
        payment
        balance
        name
        customerId
        timeLimit
        limitDate
        type
        background
        paid
        purchaseOrderId
        PurchaseOrder {
          serial
          subFolio
        }
        Provider {
          id
          name
        }
        currency
        usdTotal
      }
    }
  }
`;

const EDIT_PRODUCTS = gql`
  mutation AddPurchaseOrderProduct(
    $purchaseOrderId: ID!
    $quantity: Float!
    $unitPrice: Float!
    $discount: Float!
    $productId: ID
    $productUnitId: ID
    $concept: String
  ) {
    addPurchaseOrderProduct(
      purchaseOrderId: $purchaseOrderId
      input: {
        quantity: $quantity
        unitPrice: $unitPrice
        discount: $discount
        productId: $productId
        productUnitId: $productUnitId
        concept: $concept
      }
    ) {
      Product {
        name
      }
      quantity
      unitPrice
      discount
      total
      ProductUnit {
        name
      }
      concept
      receivedQuantity
    }
  }
`;

const DELETE_ITEM = gql`
  mutation DeletePurchaseOrderDetail($purchaseOrderId: ID!, $purchaseOrderDetailId: ID!) {
    deletePurchaseOrderDetail(
      purchaseOrderId: $purchaseOrderId
      purchaseOrderDetailId: $purchaseOrderDetailId
    ) {
      id
    }
  }
`;

const ADD_ITEM_V2 = gql`
  mutation AddPurchaseOrderProductV2(
    $purchaseOrderId: ID!
    $quantity: Float!
    $productId: ID
    $productPresentationId: ID
    $unitPrice: Float
    $discount: Float
    $percentAdjust: Float
    $type: Int
    $concept: String
    $iva: Float
    $ieps: Float
  ) {
    addPurchaseOrderProductV2(
      purchaseOrderId: $purchaseOrderId
      input: {
        type: $type
        concept: $concept
        iva: $iva
        ieps: $ieps
        quantity: $quantity
        productId: $productId
        productPresentationId: $productPresentationId
        unitPrice: $unitPrice
        discount: $discount
        percentAdjust: $percentAdjust
      }
    ) {
      id
      type
      concept
      PurchaseOrder {
        id
      }
      Product {
        name
      }
      quantity
      unitPrice
      discount
      total
      iva
      ieps
      ivaTotal
      iepsTotal
      ProductUnit {
        name
      }
      ProductPresentation {
        name
      }
      concept
      receivedQuantity
      lastUnitPrice
    }
  }
`;

const UPDATE_ITEM = gql`
  mutation UpdatePurchaseOrderDetail(
    $purchaseOrderId: ID!
    $purchaseOrderDetailId: ID!
    $quantity: Float!
    $unitPrice: Float
    $discount: Float
  ) {
    updatePurchaseOrderDetail(
      purchaseOrderId: $purchaseOrderId
      purchaseOrderDetailId: $purchaseOrderDetailId
      input: { quantity: $quantity, unitPrice: $unitPrice, discount: $discount }
    ) {
      id
      PurchaseOrder {
        id
        total
        discount
        subtotal
        ivaTotal
        iepsTotal
      }
      Product {
        name
      }
      quantity
      unitPrice
      discount
      total
      ProductUnit {
        name
      }
      ProductPresentation {
        name
      }
      concept
      receivedQuantity
      lastUnitPrice
    }
  }
`;

const CLOSE_PURCHASE_ORDER_V2 = gql`
  mutation ClosePurchaseOrderV2(
    $id: ID!
    $date: Date!
    $subFolio: String
    $paymentForm: String!
    $type: Int!
    $Products: [ProductClosePurchaseOrderData]
    $description: String
  ) {
    closePurchaseOrderV2(
      id: $id
      input: {
        date: $date
        subFolio: $subFolio
        paymentForm: $paymentForm
        type: $type
        Products: $Products
        description: $description
      }
    ) {
      id
      date
      subtotal
      discount
      status
      subFolio
      taxes
      total
      AccountPayable {
        id
        clientCreatedAt
        folio
        serial
        discount
        subtotal
        taxes
        total
        payment
        balance
        name
        customerId
        timeLimit
        limitDate
        type
        background
        paid
        purchaseOrderId
        PurchaseOrder {
          serial
          subFolio
        }
        Provider {
          id
          name
        }
        currency
        usdTotal
      }
    }
  }
`;

const FRAGMENT_NEW_PURCHASE_ORDER = gql`
  fragment NewPurchaseOrder on PurchaseOrder {
    id
    date
    subtotal
    discount
    subFolio
    taxes
    total
    status
  }
`;

const FRAGMENT_NEW_PURCHASE_ORDER_DETAIL = gql`
  fragment NewPurchaseOrderDetail on PurchaseOrderDetail {
    id
    Product {
      name
    }
    quantity
    unitPrice
    discount
    total
    ProductUnit {
      name
    }
    ProductPresentation {
      name
    }
    concept
    receivedQuantity
    lastUnitPrice
  }
`;

const CANCEL_PURCHASE_ORDER = gql`
  mutation CancelPurchaseOrder($id: ID!, $deleteJustification: String!) {
    cancelPurchaseOrder(id: $id, deleteJustification: $deleteJustification) {
      id
    }
  }
`;

const ADD_FILE = gql`
  mutation AddFilePurchaseOrder($id: ID!, $Image: Upload!) {
    addFilePurchaseOrder(id: $id, Image: $Image) {
      fileId
    }
  }
`;

const DELETE_FILE = gql`
  mutation DeleteFilePurchaseOrder($id: ID!) {
    deleteFilePurchaseOrder(id: $id) {
      fileId
    }
  }
`;

const UPDATE_BONUS = gql`
  mutation UpdatePurchaseOrderBonus($id: ID!, $bonus: Float!) {
    updatePurchaseOrderBonus(id: $id, bonus: $bonus) {
      id
    }
  }
`;

const CREATE_CREDIT_NOTE_PRODUCT_PURCHASE_ORDER = gql`
  mutation CreateCreditNoteProductPurchaseOrder(
    $id: ID!
    $quantity: Float!
    $userPassword: String!
    $subtotal: Float!
    $discount: Float
    $total: Float!
    $iva: Float!
    $ieps: Float!
    $folio: String!
    $date: Date
    $paymentMethod: String
    $paymentMethodId: ID
  ) {
    createCreditNoteProductPurchaseOrder(
      id: $id
      input: {
        quantity: $quantity
        userPassword: $userPassword
        subtotal: $subtotal
        discount: $discount
        total: $total
        iva: $iva
        ieps: $ieps
        folio: $folio
        date: $date
        paymentMethod: $paymentMethod
        paymentMethodId: $paymentMethodId
      }
    ) {
      id
    }
  }
`;

const CREATE_CREDIT_NOTE_PURCHASE_ORDER = gql`
  mutation CreateCreditNotePurchaseOrder(
    $id: ID!
    $total: Float!
    $userPassword: String!
    $subtotal: Float!
    $discount: Float
    $iva: Float!
    $ieps: Float!
    $folio: String!
    $date: Date
    $paymentMethod: String
    $paymentMethodId: ID
  ) {
    createCreditNotePurchaseOrder(
      id: $id
      input: {
        total: $total
        userPassword: $userPassword
        subtotal: $subtotal
        discount: $discount
        iva: $iva
        ieps: $ieps
        folio: $folio
        date: $date
        paymentMethod: $paymentMethod
        paymentMethodId: $paymentMethodId
      }
    ) {
      id
      bonus
    }
  }
`;

const CREATE_CREDIT_NOTE_PRODUCT_PURCHASE_ORDER_V2 = gql`
  mutation CreateCreditNoteProductPurchaseOrderV2(
    $id: ID!
    $userPassword: String!
    $subtotal: Float!
    $discount: Float
    $total: Float!
    $iva: Float!
    $ieps: Float!
    $folio: String!
    $date: Date
    $paymentMethod: String
    $paymentMethodId: ID
    $Products: [CreditNoteProductPurchaseOrderProductData]
  ) {
    createCreditNoteProductPurchaseOrderV2(
      id: $id
      input: {
        userPassword: $userPassword
        subtotal: $subtotal
        discount: $discount
        total: $total
        iva: $iva
        ieps: $ieps
        folio: $folio
        date: $date
        paymentMethod: $paymentMethod
        paymentMethodId: $paymentMethodId
        Products: $Products
      }
    ) {
      id
    }
  }
`;

export default {
  GET,
  GET_BY_ID,
  CREATE,
  CLOSE_PURCHASE_ORDER,
  EDIT_PRODUCTS,
  DELETE_ITEM,
  ADD_ITEM_V2,
  CLOSE_PURCHASE_ORDER_V2,
  FRAGMENT_NEW_PURCHASE_ORDER,
  FRAGMENT_NEW_PURCHASE_ORDER_DETAIL,
  CANCEL_PURCHASE_ORDER,
  UPDATE_ITEM,
  ADD_FILE,
  DELETE_FILE,
  UPDATE_BONUS,
  CREATE_CREDIT_NOTE_PRODUCT_PURCHASE_ORDER,
  CREATE_CREDIT_NOTE_PURCHASE_ORDER,
  CREATE_CREDIT_NOTE_PRODUCT_PURCHASE_ORDER_V2,
};
