import React, { useRef } from 'react';
import ButtonActionSaleV2Component from '../helpers/Button';
import { cashFlowSalev2Id } from '../../../../apollo/cache';
import CreateCashFlowDialogComponent from '../../../cashFlow/Create/Dialog';
import EditCashFlowDialogComponent from '../../../cashFlow/Edit';
var CashFlowActionSaleV2ButtonComponent = function () {
    var createCashFlowDialogRef = useRef(null);
    var editCashFlowDialogRef = useRef(null);
    var handleClick = function () {
        var _a, _b;
        var cashFlowSalev2IdVar = cashFlowSalev2Id();
        if (!cashFlowSalev2IdVar)
            (_a = createCashFlowDialogRef.current) === null || _a === void 0 ? void 0 : _a.open();
        else
            (_b = editCashFlowDialogRef.current) === null || _b === void 0 ? void 0 : _b.open();
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(ButtonActionSaleV2Component, { title: 'Movimiento de caja', onClick: handleClick }),
        React.createElement(CreateCashFlowDialogComponent, { ref: createCashFlowDialogRef }),
        React.createElement(EditCashFlowDialogComponent, { ref: editCashFlowDialogRef })));
};
export default CashFlowActionSaleV2ButtonComponent;
