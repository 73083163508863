import React, { useState, useEffect } from 'react';
import { useMutation, useLazyQuery } from '@apollo/client';

import TrashIcon from '@mui/icons-material/DeleteOutline';

import Badge from '@mui/material/Badge';

import FormDialog from '../../presentationals/FormDialog';
import ImageField from '../../presentationals/ImageField';
import IconButton from '../../presentationals/IconButton';
import config from '../../config/config.json';

import GQL from './_gql';
import useSnackbarDialog from '../../store/snackbar/snackbarDialog';

const defaultSnackbar = { isOpen: true, time: 3000 };

const Component = props => {
  const { setSnackbar } = useSnackbarDialog();
  const [previewImage, setPreviewImage] = useState([null, null]);

  const [updateFile, { loading: loadingUpdate }] = useMutation(GQL.ADD_FILE);
  const [deleteFile, { loading: loadingDelete }] = useMutation(GQL.DELETE_FILE);
  const [getFile, { data }] = useLazyQuery(GQL.GET_BY_ID);

  useEffect(() => {
    props.id && getFile({ variables: { id: props.id } });
  }, [props.id]);

  const handleClose = () => {
    setPreviewImage([null, null]);
    props.handleClose();
  };

  const handleAction = async () => {
    try {
      await updateFile({ variables: { id: props.id, Image: previewImage[1] } });
      handleClose();
      setSnackbar({
        ...defaultSnackbar,
        label: 'La imagen fue actualizada con éxito.',
        severity: 'success',
      });
      props.reload({ id: classificationId });
    } catch (e) {
      console.log(e.name, e.message);
      setSnackbar({
        ...defaultSnackbar,
        label: 'Ha ocurrido un error al cargar la imagen.',
        severity: 'error',
      });
    }
  };

  const handleDeleteFile = async () => {
    try {
      await deleteFile({ variables: { id: props.id } });
      handleClose();
      setSnackbar({
        ...defaultSnackbar,
        label: 'La imagen fue eliminada con éxito.',
        severity: 'success',
      });
    } catch (e) {
      setSnackbar({
        ...defaultSnackbar,
        label: 'Ha ocurrido un error al eliminar la imagen.',
        severity: 'error',
      });
    }
  };

  const handleImage = (uri, file) => {
    window.URL.revokeObjectURL(previewImage[0]);
    setPreviewImage([uri, file]);
  };

  return (
    <FormDialog
      title='Actualizar Imagen'
      isOpen={props.isOpen || false}
      isLoading={loadingUpdate || loadingDelete}
      handleClose={handleClose}
      handleAction={handleAction}
      hideAction={!previewImage}
    >
      <ImageField
        aspect={12 / 16}
        width={100}
        height={355}
        croppedImage={handleImage}
        hideButton={data && data.purchaseOrder.fileId}
      >
        {data && data.purchaseOrder.fileId && (
          <Badge
            key={data.purchaseOrder.fileId}
            overlap='circular'
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            badgeContent={
              <IconButton
                params={data.purchaseOrder.fileId}
                action={handleDeleteFile}
                icon={<TrashIcon />}
                label='Eliminar'
                size='large'
              />
            }
          >
            <img
              src={`${config['api-file-service'].url}/files/${data.purchaseOrder.fileId}`}
              alt={`IMG-${data.purchaseOrder.fileId}`}
            />
          </Badge>
        )}
      </ImageField>
    </FormDialog>
  );
};

export default Component;
