import React, { useEffect, useState } from 'react';

import AppsIcon from '@mui/icons-material/Apps';

import { actions } from '../../apollo/cache';

import ConfirmDialog from './confirm.dialog';

const ActionComponent = () => {
  const [isOpenConfirm, setIsOpenConfirm] = useState([false, null]);

  const handleOpenProducts = (e, { id }) => {
    setIsOpenConfirm([true, id]);
  };
  const handleCloseProducts = () => setIsOpenConfirm([false, null]);

  useEffect(() => {
    actions(
      [
        {
          icon: <AppsIcon />,
          label: 'Generar Código',
          onClick: handleOpenProducts,
          disable: { key: 'status', value: 3 },
        },
      ].filter(tmp => tmp),
    );
  }, []);

  return (
    <ConfirmDialog
      isOpen={isOpenConfirm[0]}
      id={isOpenConfirm[1]}
      handleClose={handleCloseProducts}
    />
  );
};

export default ActionComponent;
