import React from 'react';
import ButtonFooterDialogComponent from '../../../../../../../presentationals/Dialog/Footer/Button';
import getInputErrorFormat from '../../../../../../sale.v2/Action/helpers/getInputErrorFormat';
import { inputError } from '../../../../../../../apollo/cache';
var ActionBranchCreatePurchaseOrderButtonComponent = function (props) {
    var handleClick = function () {
        try {
            inputError({});
            var values = props.getValues();
            if (!values)
                return;
            props.onClick(values);
        }
        catch (error) {
            getInputErrorFormat(error);
        }
    };
    return (React.createElement(ButtonFooterDialogComponent, { onClick: handleClick }, "Actualizar sucursal"));
};
export default ActionBranchCreatePurchaseOrderButtonComponent;
