var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useState } from 'react';
import { Button } from '@mui/material';
import TextFieldComponent from '../../../presentationals/FormComponents/TextField';
import logo from '../../../assets/static/iso.png';
import Copyright from '../../../presentationals/Module.v2/Copyright';
var defaultValues = {
    username: '',
    codeVerification: '',
};
var RecoverCodeVerificationComponent = function () {
    var _a = useState(defaultValues), values = _a[0], setValues = _a[1];
    // const [codeConfirm, setCodeConfirm] = useState(false);
    // const [newPass, setNewPass] = useState(false);
    var handleChange = function (name, value) {
        setValues(function (values) {
            var _a;
            return (__assign(__assign({}, values), (_a = {}, _a[name] = value, _a)));
        });
    };
    // const handleClick = () => {
    //   setCodeConfirm(true);
    //   setNewPass(true);
    // };
    return (React.createElement("div", { className: 'flex flex-1 h-screen flex-col bg-slate-50' },
        React.createElement("div", { className: 'flex justify-center items-center p-10 md:pt-16 mt-14' },
            React.createElement("img", { src: logo, alt: 'Capitalike', className: 'w-20' })),
        React.createElement("div", { className: 'flex flex-1 items-center justify-center flex-col p-4' },
            React.createElement("div", { className: 'flex flex-1 items-center justify-center flex-col p-4' },
                React.createElement("div", { className: 'w-full sm:w-96' },
                    React.createElement("div", { className: 'leading-tight mb-20 text-center' },
                        "Ingresa tu n\u00FAmero de verificaci\u00F3n",
                        React.createElement("div", { className: 'mt-4' },
                            React.createElement(TextFieldComponent, { defaultLabel: 'Tel\u00E9fono o correo electr\u00F3nico', name: 'username', value: values.username, onChange: handleChange, 
                                //   onKeyDown={handleKeyDown}
                                //   error={errors.username}
                                //   disabled={loading}
                                size: 'medium' }),
                            React.createElement(TextFieldComponent, { defaultLabel: 'C\u00F3digo de verificaci\u00F3n', name: 'codeVerification', className: 'mt-2', type: 'text', value: values.codeVerification, onChange: handleChange, 
                                //   onKeyDown={handleKeyDown}
                                //   error={errors.username}
                                //   disabled={loading}
                                size: 'medium' }))),
                    React.createElement(Button, { variant: 'contained', className: 'rounded-lg normal-case', size: 'large', fullWidth: true }, "Confirmar"))),
            React.createElement("div", { className: 'flex justify-center p-6' },
                React.createElement(Copyright, null)))));
};
export default RecoverCodeVerificationComponent;
