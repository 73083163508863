import React from 'react';
import { useReactiveVar } from '@apollo/client';
import { TableBody, TableCell, TableRow, Typography } from '@mui/material';
import { cartItems } from '../../../../../../../apollo/cache';
import { currencyFormat } from '../../../../../../../helpers/general';
import EmployeeLabelBodyCartSaleComponent from '../../../../../Cart/Body/EmployeeLabel';
var TableBodyCartInfoSummaryFormBodyConfirmDialogSaleV2Component = function () {
    var cartItemsVar = useReactiveVar(cartItems);
    return (React.createElement(TableBody, null, cartItemsVar.map(function (el, i) { return (React.createElement(TableRow, { className: i % 2 !== 1 ? 'bg-gray-100' : 'bg-white', key: i },
        React.createElement(TableCell, null,
            React.createElement("div", null, el.name),
            el.commentary && React.createElement(Typography, { variant: 'caption' }, el.commentary),
            el.employeeId && React.createElement(EmployeeLabelBodyCartSaleComponent, { employeeId: el.employeeId })),
        React.createElement(TableCell, { align: 'center' }, currencyFormat(el.quantity)),
        React.createElement(TableCell, { align: 'right' },
            "$",
            currencyFormat(el.unitPrice)),
        React.createElement(TableCell, { align: 'right' },
            "$",
            currencyFormat(el.discount)),
        React.createElement(TableCell, { align: 'right' },
            "$",
            currencyFormat(el.taxes)),
        React.createElement(TableCell, { align: 'right' },
            "$",
            currencyFormat(el.total)))); })));
};
export default TableBodyCartInfoSummaryFormBodyConfirmDialogSaleV2Component;
