import React, { useState, useEffect } from 'react';

import { useQuery } from '@apollo/client';

import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';

import FilterDrawer from '../../presentationals/FilterDrawer.v2';
import Autocomplete from '../../presentationals/FormComponents/Autocomplete';
import SelectField from '../../presentationals/FormComponents/SelectField';
import { filter } from '../../apollo/cache';

import GQL_CUSTOMER from '../customer/_gql';

const defaultValues = {
  customerId: null,
  status: '-1',
};

const statusOptions = [
  { label: 'Todos (excepto cancelados)', value: '-1' },
  { label: 'No Autorizado', value: '1' },
  { label: 'Abierto', value: '2' },
  { label: 'Cerrado', value: '3' },
  { label: 'Cancelado', value: '0' },
];

const FilterDialog = () => {
  const [values, setValues] = useState(defaultValues);
  const [customerOptions, setCustomerOptions] = useState([]);

  const { data: customerData } = useQuery(GQL_CUSTOMER.GET_NAME_AND_PHONE, {
    variables: { limit: 0 },
  });

  useEffect(() => {
    if (customerData && customerData.customers) {
      const tmp = customerData.customers.rows.map(el => {
        return {
          label: `${el.firstName.trim()} ${el.lastName.trim()}`.trim(),
          value: el.id,
        };
      });
      setCustomerOptions(tmp);
    }
  }, [customerData]);

  const handleAction = (e, data) => {
    const { startDate: start, endDate: end } = data;
    const customerId = values.customerId ? values.customerId.value : null;
    const status =
      values.status === '-1' ? { status: [1, 2, 3] } : { status: [parseInt(values.status)] };
    filter({ start, end, customerId, ...status });
  };

  const handleChange = (name, value) => {
    setValues(values => ({ ...values, [name]: value }));
  };

  return (
    <FilterDrawer handleAction={handleAction} showDates>
      <Typography variant='subtitle1'>Cliente:</Typography>
      <Autocomplete
        name='customerId'
        value={values.customerId}
        onChange={handleChange}
        size='medium'
        variant='outlined'
        options={customerOptions}
      />
      <br />
      <Divider />
      <Typography variant='subtitle1'>Estatus:</Typography>
      <SelectField
        name='status'
        value={values.status}
        onChange={handleChange}
        size='medium'
        variant='outlined'
        options={statusOptions}
      />
    </FilterDrawer>
  );
};

export default FilterDialog;
