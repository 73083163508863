import React, { useState } from 'react';
import { Button } from '@mui/material';
import TextFieldComponent from '../../../../../presentationals/FormComponents/TextField';
import { permissionSearchField } from '../../../../../apollo/cache';
var SearchPermissionEditRoleComponent = function () {
    var _a = useState(''), value = _a[0], setValue = _a[1];
    var handleClick = function () {
        permissionSearchField(value);
    };
    var handleChange = function (name, value) {
        setValue(value);
    };
    var handleKeyDown = function (e) {
        if (e.key === 'Enter') {
            e.preventDefault();
            handleClick();
        }
    };
    return (React.createElement("div", { className: 'flex flex-1 md:flex-row md:items-center px-5 pt-3 pb-1' },
        React.createElement("div", { className: 'flex flex-1 pr-2' },
            React.createElement(TextFieldComponent, { autoFocus: true, name: 'search', defaultLabel: 'Escribe el permiso que deseas buscar.', value: value, onChange: handleChange, onKeyDown: handleKeyDown })),
        React.createElement("div", { className: 'mb-1' },
            React.createElement(Button, { color: 'secondary', variant: 'contained', onClick: handleClick }, "Buscar"))));
};
export default SearchPermissionEditRoleComponent;
