import { gql } from '@apollo/client';

const GET_V2 = gql`
  query ProductInventoriesV2(
    $name: String
    $limit: Int
    $offset: Int
    $branchId: [ID]
    $classificationId: [ID]
    $productId: ID
    $isZero: Boolean
  ) {
    productInventoriesV2(
      like: $name
      limit: $limit
      offset: $offset
      branchId: $branchId
      classificationId: $classificationId
      productId: $productId
      isZero: $isZero
    ) {
      count
      rows {
        id
        Product {
          id
          name
          sku
          Classification {
            id
            name
          }
          PresentationBase {
            id
            name
          }
        }
        Inventories {
          Branch {
            id
            name
          }
          quantityDefaultPresentation
          quantity
          total
          salePrice
          cost
          utility
          utilityPercent
        }
      }
    }
  }
`;

export default { GET_V2 };
