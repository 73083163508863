import React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { useParams } from 'react-router-dom';
import useApolloQuery from '../../../../hooks/useApolloQuery';
import GQL_SALE from '../../../../apollo/gql/sale';
import LoadingProgress from '../../../../presentationals/LoadingProgress';
import ErrorDataComponent from '../../../../presentationals/ErrorData';
import { dateTimeFormat } from '../../../../helpers';
import convertToCurrencyLabel from '../../../../helpers/convertToCurrencyLabel';
import getSaleStatus from '../../../../helpers/getSaleStatus';
import useGetSession from '../../../../hooks/useGetSession';
var TotalByFolioCashClosingComponent = function () {
    var _a;
    var params = useParams();
    var session = useGetSession();
    var customerLabel = ((_a = session === null || session === void 0 ? void 0 : session.Company) === null || _a === void 0 ? void 0 : _a.activity) === 3 ? 'Paciente' : 'Cliente';
    var _b = useApolloQuery(GQL_SALE.GET_SALES('clientCreatedAt folio customer status commission subtotal discount taxes total seller'), {
        variables: {
            cashClosingId: params.cashClosingId,
        },
    }), data = _b.data, loading = _b.loading;
    if (loading)
        return React.createElement(LoadingProgress, null);
    if (!data)
        return React.createElement(ErrorDataComponent, null);
    return (React.createElement(TableContainer, null,
        React.createElement(Table, { stickyHeader: true, size: 'small' },
            React.createElement(TableHead, null,
                React.createElement(TableRow, null,
                    React.createElement(TableCell, null, "Creado el"),
                    React.createElement(TableCell, null, "Folio"),
                    React.createElement(TableCell, null, customerLabel),
                    React.createElement(TableCell, null, "Estatus"),
                    React.createElement(TableCell, null, "Comisi\u00F3n"),
                    React.createElement(TableCell, null, "Subtotal"),
                    React.createElement(TableCell, null, "Descuento"),
                    React.createElement(TableCell, null, "Impuestos"),
                    React.createElement(TableCell, null, "Importe"),
                    React.createElement(TableCell, null, "Cajero"))),
            React.createElement(TableBody, null, data.rows.map(function (el) { return (React.createElement(TableRow, { key: el.id },
                React.createElement(TableCell, null, dateTimeFormat(el.clientCreatedAt, 'dd/MM/yyyy HH:mm')),
                React.createElement(TableCell, null, el.folio),
                React.createElement(TableCell, null, el.customer),
                React.createElement(TableCell, null, getSaleStatus(el.status)),
                React.createElement(TableCell, null, convertToCurrencyLabel(el.commission)),
                React.createElement(TableCell, null, convertToCurrencyLabel(el.subtotal)),
                React.createElement(TableCell, null, convertToCurrencyLabel(el.discount)),
                React.createElement(TableCell, null, convertToCurrencyLabel(el.taxes)),
                React.createElement(TableCell, null, convertToCurrencyLabel(el.total)),
                React.createElement(TableCell, null, el.seller))); })))));
};
export default TotalByFolioCashClosingComponent;
