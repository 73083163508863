import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import Table from './Table';
import DialogComponent from '../../../Dialog';
import SearchUnitKeyFormComponent from '../../SatUnitKeyField/SearchDialog/Form';
var SearcProductKeyDialogComponent = forwardRef(function (props, ref) {
    var searchProductKeyFormRef = useRef(null);
    var searchSatProductKeyTableRef = useRef(null);
    var _a = useState(false), open = _a[0], setOpen = _a[1];
    useEffect(function () {
        var _a;
        if (open) {
            var value = props.getValue();
            (_a = searchProductKeyFormRef.current) === null || _a === void 0 ? void 0 : _a.setValue(value);
        }
    }, [open]);
    var handleOpen = function () {
        setOpen(true);
    };
    var handleClose = function () {
        setOpen(false);
    };
    var handleSearchButtonClick = function (value) {
        var _a;
        (_a = searchSatProductKeyTableRef.current) === null || _a === void 0 ? void 0 : _a.getValue(value);
    };
    var handleSelected = function (value) {
        props.onSelected(value);
        handleClose();
    };
    useImperativeHandle(ref, function () { return ({
        open: handleOpen,
    }); });
    return (React.createElement(DialogComponent, { title: 'B\u00FAsqueda de clave de producto y servicio (SAT)', disablePortal: true, fullScreen: true, open: open, onClose: handleClose },
        React.createElement(SearchUnitKeyFormComponent, { ref: searchProductKeyFormRef, onClick: handleSearchButtonClick }),
        React.createElement(Table, { ref: searchSatProductKeyTableRef, onClick: handleSelected })));
});
export default SearcProductKeyDialogComponent;
