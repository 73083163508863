import React, { useEffect } from 'react';

import { useReactiveVar } from '@apollo/client';

import { exportXls } from '../../../apollo/cache';

import XLS from './xls.doc';

const XlsExportComponent = props => {
  const exportXlsVar = useReactiveVar(exportXls);

  useEffect(() => {
    if (exportXlsVar.export) {
      XLS(props.title, props.keys, props.data);
      exportXls({ export: false });
    }
  }, [exportXlsVar]);

  return <div />;
};

export default XlsExportComponent;
