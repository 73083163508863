import React, { useEffect, useState } from 'react';
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { dateTimeFormat } from '../../../../../../helpers';
import getItemSurveyResultBackground from './helpers/getBackground';
var ItemDetailSurveyResultComponent = function (props) {
    var _a = useState(''), background = _a[0], setBackground = _a[1];
    useEffect(function () {
        var background = getItemSurveyResultBackground(props.SurveyCustomerAnswers);
        setBackground(background);
    }, [props.SurveyCustomerAnswers]);
    return (React.createElement(Accordion, null,
        React.createElement(AccordionSummary, { id: "panel".concat(props.id, "-header"), "aria-controls": "panel".concat(props.id, "-content"), expandIcon: React.createElement(ExpandMoreIcon, null), className: "".concat(background) },
            React.createElement("div", null, "Creado el ".concat(dateTimeFormat(props.createdAt, 'dd/MM/yyyy HH:mm'))),
            props.Customer && (React.createElement("div", null, "Cliente ".concat(props.Customer.firstName, " ").concat(props.Customer.lastName)))),
        React.createElement(AccordionDetails, null, props.SurveyCustomerAnswers.map(function (el, i) { return (React.createElement("div", { key: i, className: "flex flex-col ".concat(i === props.SurveyCustomerAnswers.length - 1
                ? ''
                : 'border-0 border-b border-solid border-slate-300 pb-2 mb-2') },
            React.createElement("div", null, el.question),
            React.createElement("div", { className: 'font-medium' }, el.answer))); }))));
};
export default ItemDetailSurveyResultComponent;
