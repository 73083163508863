import React from 'react';
import QrCode2Icon from '@mui/icons-material/QrCode2';
import IconButtonComponent from '../../../../../../presentationals/IconButton';
import getInputErrorFormat from '../../../../../sale.v2/Action/helpers/getInputErrorFormat';
import PDF from '../../../../../product/Item/BarcodeLabel/individualBarcode.pdf';
import PDF71 from '../../../../../product/Item/BarcodeLabel/individualBarcode.71';
import PDF146 from '../../../../../product/Item/BarcodeLabel/individualBarcode.146';
import getSession from '../../../../../../helpers/getSession';
var PrintProductRequisitionBarcodeButtonComponent = function (props) {
    var handleClick = function () {
        var _a;
        try {
            var session = getSession();
            var body = {
                name: props.name,
                Presentation: {
                    id: props.ProductPresentation.id,
                    barcode: props.ProductPresentation.barcode,
                    price: props.ProductPresentation.price,
                    sku: props.ProductPresentation.sku,
                },
            };
            var qty = props.quantity;
            if (!((_a = session === null || session === void 0 ? void 0 : session.Company) === null || _a === void 0 ? void 0 : _a.id))
                throw new Error('No se encontró el identificador de la empresa.');
            switch (session.Company.id.toString()) {
                case '146':
                    PDF146(body, qty);
                    break;
                case '71':
                    PDF71(body, qty);
                    break;
                default:
                    PDF(body, qty);
                    break;
            }
        }
        catch (error) {
            getInputErrorFormat(error);
        }
    };
    return (React.createElement(IconButtonComponent, { action: handleClick, icon: React.createElement(QrCode2Icon, { fontSize: 'medium' }), label: 'Imprimir c\u00F3digo de barras' }));
};
export default PrintProductRequisitionBarcodeButtonComponent;
