import React, { useEffect, useState } from 'react';

import { useLazyQuery } from '@apollo/client';

import EditIcon from '@mui/icons-material/EditOutlined';

import { actionForm } from '../../../../apollo/cache';

import IconButton from '../../../IconButton';

const EditButtonComponent = props => {
  const [isOpen, setIsOpen] = useState(false);

  const [getById, { data }] = useLazyQuery(props.getById);

  useEffect(() => {
    if (isOpen && data) {
      const variables = {
        isOpen: true,
        action: 'update',
        defaultData: data[props.operation],
      };
      actionForm(variables);
      setIsOpen(false);
    }
  }, [isOpen, data]);

  const handleOpenEdit = async (e, params) => {
    await getById({ variables: params });
    setIsOpen(true);
  };

  return (
    <IconButton
      label='Editar'
      action={handleOpenEdit}
      params={{ id: props.id, ...props.extraParams }}
      className='p-1'
      icon={<EditIcon fontSize='small' />}
      size='large'
    />
  );
};

export default EditButtonComponent;
