import React, { useEffect, useState } from 'react';
import { Tabs, Tab } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import ReceiptIcon from '@mui/icons-material/Receipt';
import TocIcon from '@mui/icons-material/Toc';
import ImageOutlinedIcon from '@mui/icons-material/ImageOutlined';
import getMenuValueFromPath from './getMenuValueFromPath';
import getRouteFromMenuValue from './getRouteFromMenuValue';
var MenuPromotionLayoutComponent = function () {
    var navigate = useNavigate();
    var location = useLocation();
    var _a = useState(0), value = _a[0], setValue = _a[1];
    useEffect(function () {
        var value = getMenuValueFromPath(location.pathname);
        setValue(value);
    }, [location.pathname]);
    var handleChange = function (e, value) {
        var route = getRouteFromMenuValue(value);
        if (route)
            navigate(route);
    };
    return (React.createElement(Tabs, { value: value, onChange: handleChange, variant: 'scrollable', scrollButtons: 'auto', className: 'flex bg-slate-100 border-0 border-y border-[#d6d6d6] border-solid' },
        React.createElement(Tab, { icon: React.createElement(ReceiptIcon, null), label: 'Datos generales' }),
        React.createElement(Tab, { icon: React.createElement(TocIcon, null), label: 'Contenido' }),
        React.createElement(Tab, { icon: React.createElement(ImageOutlinedIcon, null), label: 'Imagen' })));
};
export default MenuPromotionLayoutComponent;
