import React, { useEffect } from 'react';

import { useReactiveVar } from '@apollo/client';

import { Dialog, LinearProgress, List } from '@mui/material';

import {
  isSaleEmployeeFormOpen,
  employeeSaleV2,
  customerSaleV2,
} from '../../../../../apollo/cache';

import useGetEmployees from '../../../../../hooks/useGetEmployees';

import Item from './Item';

const FormEmployeeActionSaleV2Component = () => {
  const isSaleEmployeeFormOpenVar = useReactiveVar(isSaleEmployeeFormOpen);
  const customerSaleV2Var = useReactiveVar(customerSaleV2);

  const { data, loading } = useGetEmployees({ isSeller: true }, 'select');

  useEffect(() => {
    if (data.rows && customerSaleV2Var?.employeeId) {
      const isAvailable = data.rows.find(
        el => parseInt(el.value) === parseInt(customerSaleV2Var.employeeId),
      );
      if (isAvailable) employeeSaleV2(customerSaleV2Var.employeeId);
    }
  }, [data.rows, customerSaleV2Var]);

  return (
    <Dialog open={isSaleEmployeeFormOpenVar}>
      {loading && <LinearProgress color='secondary' />}
      <List component='nav' aria-label='SaleFormatList'>
        {data.rows.map(el => (
          <Item key={el.value} id={el.value} label={el.label} />
        ))}
      </List>
    </Dialog>
  );
};

export default FormEmployeeActionSaleV2Component;
