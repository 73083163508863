import React from 'react';

import { ListItemButton } from '@mui/material';

import getInputErrorFormat from '../../../../../../../../../helpers/getInputErrorFormat';
import {
  customerSaleV2,
  isChooseBillingInformationDialogOpen,
} from '../../../../../../../../../../../../apollo/cache';

const ListItemTableSearchDialogChooseBillingInformationBodyCreateFormCustomerComponent = props => {
  const handleClick = () => {
    try {
      const customerData = customerSaleV2();
      customerSaleV2({
        ...customerData,
        identifier: props.identifier || customerData.identifier,
        businessName: props.businessName || customerData.businessName,
        tradename: props.tradename || customerData.tradename,
        taxRegime: props.taxRegime || customerData.taxRegime,
        zipCode: props.zipCode || customerData.zipCode,
        businessZipCode: props.businessZipCode || customerData.businessZipCode,
      });
      isChooseBillingInformationDialogOpen(false);
    } catch (e) {
      getInputErrorFormat(e);
    }
  };
  return (
    <ListItemButton onClick={handleClick} className={`${props.background} p-0`}>
      {props.children}
    </ListItemButton>
  );
};

export default ListItemTableSearchDialogChooseBillingInformationBodyCreateFormCustomerComponent;
