import React from 'react';
import ModuleV3Component from '../../presentationals/Module.v3';
import useGetClassificationData from './helpers/useGetClassificationData';
import RedirectCreateFormToRouteComponent from '../../presentationals/RedirectCreateFormToRoute';
import RedirectEditFormToRouteComponent from '../../presentationals/RedirectEditFormToRoute';
var ClassificationComponent = function () {
    var _a = useGetClassificationData({ getByFilter: true }), data = _a.data, loading = _a.loading;
    var header = [
        { Header: 'Nombre', accessor: 'name' },
        { Header: 'Impresora asignada', accessor: 'printer' },
        { Header: '¿Está disponible en módulo de venta?', accessor: 'toSell' },
    ];
    return (React.createElement(React.Fragment, null,
        React.createElement(ModuleV3Component, { title: 'Clasificaciones', data: data, header: header, isLoading: loading }),
        React.createElement(RedirectCreateFormToRouteComponent, null),
        React.createElement(RedirectEditFormToRouteComponent, null)));
};
export default ClassificationComponent;
