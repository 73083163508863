var getRoleMenuValueFromPath = function (pathname) {
    var pathnameParts = pathname.split('/');
    var path = pathnameParts[pathnameParts.length - 1];
    switch (path) {
        case 'general':
            return 0;
        case 'permission':
            return 1;
        default:
            return 0;
    }
};
export default getRoleMenuValueFromPath;
