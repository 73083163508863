import React from 'react';
import { Divider } from '@mui/material';
import useGetSaleByParamSaleId from '../../../../hooks/useGetSaleByParamSaleId';
import LoadingProgress from '../../../../presentationals/LoadingProgress';
import { dateTimeFormat } from '../../../../helpers';
import BodyPickupRentComponent from './Body';
import EditPickupItemComponent from './Body/EditPickupItem';
import PrintSaleButtonComponent from '../helpers/PrintSaleButton';
var PickupRentComponent = function () {
    var _a = useGetSaleByParamSaleId(), data = _a.data, loading = _a.loading;
    if (loading || !data)
        return React.createElement(LoadingProgress, null);
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: 'flex flex-1 flex-col' },
            React.createElement("div", { className: 'flex flex-1 flex-col' },
                React.createElement("div", { className: 'p-3 flex flex-col sm:flex-row' },
                    React.createElement("div", { className: 'flex flex-1 flex-col' },
                        React.createElement("div", { className: 'text-xl' }, "Fecha programada para recolecci\u00F3n"),
                        React.createElement("div", { className: 'text-4xl' }, dateTimeFormat(data.Calendar.end, "EEEE, d 'de' MMMM hh:mmaaa"))),
                    React.createElement("div", { className: 'flex' },
                        React.createElement(PrintSaleButtonComponent, { label: 'Imprimir comprobante de recolecci\u00F3n', type: 'pickup', saleId: data.id }))),
                React.createElement(Divider, { className: 'mt-2' }),
                data.deliveryStatus === 4 && (React.createElement(BodyPickupRentComponent, { SaleProducts: data === null || data === void 0 ? void 0 : data.SaleProducts })))),
        React.createElement(EditPickupItemComponent, null)));
};
export default PickupRentComponent;
