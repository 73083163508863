import React, { useEffect } from 'react';

import { purchaseOrderId } from '../../../apollo/cache';

import FormDialog from '../../../presentationals/FormDialog';
import Products from '../CreateForm/Products';

const ProductsComponent = props => {
  useEffect(() => {
    if (props.id) purchaseOrderId(props.id);
  }, [props]);

  const handleClose = () => {
    purchaseOrderId(null);
    props.handleClose();
  };

  return (
    <FormDialog
      isOpen={props.isOpen || false}
      handleClose={handleClose}
      title='Lista de Productos'
      hideAction
      fullScreen
      noPadding
    >
      <div className='px-3 py-1'>
        <Products />
      </div>
    </FormDialog>
  );
};

export default ProductsComponent;
