import React, { useMemo } from 'react';
import ModuleV3Component from '../../presentationals/Module.v3';
import { customerLabel } from '../../helpers';
import useGetCustomers from './helpers/useGetCustomers';
import FilterCustomerComponent from './Filter';
import RedirectCreateFormToRouteComponent from '../../presentationals/RedirectCreateFormToRoute';
import RedirectEditFormToRouteComponent from '../../presentationals/RedirectEditFormToRoute';
var CustomerComponent = function () {
    var _a = useGetCustomers({ getByFilter: true }), data = _a.data, loading = _a.loading;
    var title = useMemo(function () { return customerLabel({ textTransform: 'capitalize', term: 'plural' }); }, []);
    var header = [
        { Header: 'Código', accessor: 'code' },
        { Header: 'Nombre', accessor: 'name' },
        { Header: 'Teléfono', accessor: 'phone' },
        { Header: 'Tarifa', accessor: 'priceName' },
        { Header: 'Límite Crédito', accessor: 'creditLimit' },
        { Header: 'Días Crédito', accessor: 'creditDays' },
        { Header: 'Domicilio', accessor: 'address' },
    ];
    return (React.createElement(React.Fragment, null,
        React.createElement(ModuleV3Component, { title: title, data: data, header: header, isLoading: loading, filter: true }),
        React.createElement(RedirectCreateFormToRouteComponent, null),
        React.createElement(RedirectEditFormToRouteComponent, null),
        React.createElement(FilterCustomerComponent, null)));
};
export default CustomerComponent;
