import React, { useEffect } from 'react';
import SubtitleButtonActionSaleV2Component from '../../../../../sale.v2/Action/helpers/Button/subtitle';
import useApolloLazyQuery from '../../../../../../hooks/useApolloLazyQuery';
import GQL_PROVIDER from '../../../../../../apollo/gql/provider';
var ProviderSubtitlePurchaseOrderComponent = function (props) {
    var _a = useApolloLazyQuery(GQL_PROVIDER.GET_PROVIDER_BY_ID), getData = _a[0], _b = _a[1], loading = _b.loading, data = _b.data;
    useEffect(function () {
        if (props.providerId)
            getData({ variables: { id: props.providerId } });
    }, [props.providerId]);
    return (React.createElement(SubtitleButtonActionSaleV2Component, { label: loading ? 'Cargando...' : data ? data.name : 'No identificado' }));
};
export default ProviderSubtitlePurchaseOrderComponent;
