import React, { useEffect, useState } from 'react';
import { FormLabel, NativeSelect } from '@mui/material';
import getMinutesFormatted from './helpers/getMinutesFormatted';
var SelectHourFieldComponent = function (props) {
    var minutesOptions = [
        { label: '00', value: 0.0 },
        { label: '10', value: 0.1667 },
        { label: '15', value: 0.25 },
        { label: '20', value: 0.3334 },
        { label: '30', value: 0.5 },
        { label: '40', value: 0.6667 },
        { label: '45', value: 0.75 },
        { label: '50', value: 0.8334 },
    ];
    var _a = useState(true), getInitialValue = _a[0], setGetInitialValue = _a[1];
    var _b = useState([9, 0]), hour = _b[0], setHour = _b[1];
    useEffect(function () {
        if (props.initialValue && getInitialValue) {
            var splittedValue = props.initialValue.toString().split('.');
            var initialHour = splittedValue[0] ? parseInt(splittedValue[0]) : 0;
            var initialMinute = splittedValue[1] ? getMinutesFormatted(splittedValue[1]) : 0;
            setHour([initialHour, initialMinute]);
            setGetInitialValue(false);
        }
    }, [props.initialValue]);
    var handleChange = function (e) {
        if (getInitialValue)
            setGetInitialValue(false);
        var _a = e.target, name = _a.name, value = _a.value;
        var newHour = [
            name === 'hour' ? parseInt(value) : hour[0],
            name === 'minute' ? parseFloat(value) : hour[1],
        ];
        setHour(newHour);
        props.onChange(props.name, newHour[0] + newHour[1]);
    };
    return (React.createElement("div", { className: 'flex flex-col flex-1 w-full' },
        React.createElement(FormLabel, { htmlFor: props.name }, props.label),
        React.createElement("div", { className: "flex flex-row border border-solid border-[#bdbdbd] rounded overflow-hidden ".concat(props.disable ? '' : 'hover:border-black', "  h-full") },
            React.createElement("div", { className: 'flex border-0 border-b-4 border-gray-200 border-solid w-2' }),
            React.createElement(NativeSelect, { name: 'hour', disableUnderline: true, className: "border-0 border-b-4 border-gray-200 border-solid ".concat(props.disable ? '' : ' hover:border-sky-600', " cursor-default"), inputProps: {
                    disabled: props.disable,
                    IconComponent: function () { return null; },
                    style: {
                        padding: '0.3rem',
                        paddingTop: '0.3rem',
                        backgroundColor: 'transparent',
                    },
                }, onChange: handleChange, endAdornment: false, value: hour[0] }, Array.from(Array(24).keys()).map(function (el) { return (React.createElement("option", { key: el, value: el }, el.toString().padStart(2, '0'))); })),
            React.createElement("div", { className: 'flex border-0 border-b-4 border-gray-200 border-solid pt-[0.3rem] pb-[0.3rem]' }, ":"),
            React.createElement(NativeSelect, { name: 'minute', disableUnderline: true, className: "border-0 border-b-4 border-gray-200 border-solid ".concat(props.disable ? '' : ' hover:border-sky-600', " cursor-default"), inputProps: {
                    disabled: props.disable,
                    IconComponent: function () { return null; },
                    style: {
                        padding: '0.3rem',
                        paddingTop: '0.3rem',
                        backgroundColor: 'transparent',
                    },
                }, onChange: handleChange, endAdornment: false, value: hour[1] }, minutesOptions.map(function (el) { return (React.createElement("option", { key: el.value, value: el.value }, el.label)); })),
            React.createElement("div", { className: 'flex flex-1 border-0 border-b-4 border-gray-200 border-solid' }))));
};
export default SelectHourFieldComponent;
