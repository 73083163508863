var getRouteFromBranchMenuValue = function (value) {
    switch (value) {
        case 0:
            return '.';
        case 1:
            return 'billlinginformation';
        case 2:
            return 'credentials';
        case 3:
            return 'adress';
        case 4:
            return 'setting';
        default:
            return false;
    }
};
export default getRouteFromBranchMenuValue;
