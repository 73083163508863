import React from 'react';
import { useReactiveVar } from '@apollo/client';
import FooterDialogComponent from '../../../../../../../../presentationals/Dialog/Footer';
import EditOperationDateButtonComponent from './EditButton';
import DateFieldComponent from '../../../../../../../../presentationals/FormComponents/DateField';
import { operationDateSaleV2 } from '../../../../../../../../apollo/cache';
var BodyEditOperationDateComponent = function () {
    var value = useReactiveVar(operationDateSaleV2);
    var handleChange = function (name, value) {
        operationDateSaleV2(value);
    };
    return (React.createElement("div", { className: 'flex flex-1 flex-col' },
        React.createElement("div", { className: 'grid flex-1 grid-cols-1 sm:grid-cols-4 px-4 py-3' },
            React.createElement(DateFieldComponent, { name: 'operationDate', label: 'Fecha de operaci\u00F3n', value: value || new Date(), onChange: handleChange })),
        React.createElement(FooterDialogComponent, null,
            React.createElement("div", null),
            React.createElement(EditOperationDateButtonComponent, null))));
};
export default BodyEditOperationDateComponent;
