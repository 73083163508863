import React, { useEffect, useState } from 'react';
import { Typography } from '@mui/material';
import SelectFieldComponent from '../../../../presentationals/FormComponents/SelectField';
import TextFieldComponent from '../../../../presentationals/FormComponents/TextField';
var keysOptions = [
    { label: 'Nombre', value: 'name' },
    { label: 'Teléfono', value: 'phone' },
];
var SearchFieldFilterEmployeeComponent = function (props) {
    var _a = useState('nombre'), selectedKey = _a[0], setSelectedKey = _a[1];
    useEffect(function () {
        var selectedKey = keysOptions.reduce(function (key, el) { return (el.value === props.value[0] ? el.label.toLowerCase() : key); }, 'nombre');
        setSelectedKey(selectedKey);
    }, [props.value[0]]);
    var handleSelectFieldChange = function (name, value) {
        props.onChange(name, [value, props.value[1]]);
    };
    var handleTextFieldChange = function (name, value) {
        props.onChange(name, [props.value[0], value]);
    };
    return (React.createElement("div", null,
        React.createElement(Typography, { variant: 'subtitle1' }, "B\u00FAsqueda por ".concat(selectedKey)),
        React.createElement("div", { className: 'flex flex-row' },
            React.createElement("div", { className: 'flex mr-2' },
                React.createElement(SelectFieldComponent, { defaultLabel: 'Filtro', name: 'like', value: props.value[0], options: keysOptions, onChange: handleSelectFieldChange })),
            React.createElement("div", { className: 'flex flex-1' },
                React.createElement(TextFieldComponent, { defaultLabel: 'Palabra clave', name: 'like', value: props.value[1], onChange: handleTextFieldChange })))));
};
export default SearchFieldFilterEmployeeComponent;
