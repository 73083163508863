var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { dateTimeFormat } from '../../../../helpers';
import getPaymentMethodsFromTransanctions from './getPaymentMethodsFromTransanctions';
var getTrasactionReportChatData = function (data, dates) {
    var paymentMethods = getPaymentMethodsFromTransanctions(data);
    var defaultObj = {};
    return dates.map(function (date) {
        var currentDate = dateTimeFormat(date, 'dd/MM/yyyy');
        var totalPayments = paymentMethods.reduce(function (obj, el) {
            var _a;
            return (__assign(__assign({}, obj), (_a = {}, _a[el.label] = data.reduce(function (total, elCh) {
                var paymentMethodId = elCh.paymentMethodId || -1;
                return dateTimeFormat(elCh.clientCreatedAt, 'dd/MM/yyyy') === currentDate &&
                    elCh.status === 1 &&
                    el.value === paymentMethodId
                    ? total + parseFloat(elCh.total)
                    : total;
            }, 0), _a)));
        }, defaultObj);
        return __assign({ date: currentDate }, totalPayments);
    });
};
export default getTrasactionReportChatData;
