import React from 'react';
import ButtonFooterDialogComponent from '../../../../../../../../../presentationals/Dialog/Footer/Button';
import { isEditFormOpen } from '../../../../../../../../../apollo/cache';
var EditOperationDateButtonComponent = function () {
    var handleClick = function () {
        isEditFormOpen(false);
    };
    return React.createElement(ButtonFooterDialogComponent, { onClick: handleClick }, "Editar");
};
export default EditOperationDateButtonComponent;
