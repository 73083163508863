import React, { useEffect, useState } from 'react';
import { FormControl } from '@mui/base';
import { FormControlLabel, FormLabel, Radio, RadioGroup } from '@mui/material';
import getLocalStorageSearchProductBy from '../../../helpers/getLocalStorageSearchProductBy';
import setLocalStorageSearchProductBy from '../../../helpers/setLocalStorageSearchProductBy';
import { searchProductsByOptions } from '../SearchProductBySelectField';
var SearchProductByRadioFieldComponent = function (props) {
    var _a = useState(''), value = _a[0], setValue = _a[1];
    useEffect(function () {
        var searchByValue = getLocalStorageSearchProductBy();
        setValue(searchByValue);
    }, []);
    var handleChange = function (event) {
        var value = event.target.value;
        setLocalStorageSearchProductBy(value);
        setValue(value);
    };
    return (React.createElement(FormControl, { className: 'mb-1' },
        React.createElement(FormLabel, { className: 'text-base text-slate-800', id: 'searchProductByLabel' }, "B\u00FAsqueda por"),
        React.createElement(RadioGroup, { onChange: handleChange, row: true, value: value, "aria-labelledby": 'searchProductByLabel', name: 'searchProductBy' }, searchProductsByOptions.map(function (el) { return (React.createElement(FormControlLabel, { disabled: props.disabled, key: el.value, value: el.value, control: React.createElement(Radio, { className: 'px-1 py-1' }), label: el.label, classes: {
                root: 'pl-1',
                label: 'text-sm',
            } })); }))));
};
export default SearchProductByRadioFieldComponent;
