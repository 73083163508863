import React, { useEffect } from 'react';

import { useReactiveVar } from '@apollo/client';

import { step, handleContinueConfiguration } from '../../../../apollo/cache';

import Form from './Form';
import Table from './Table';

const ClassificationPromotionComponent = () => {
  const stepVar = useReactiveVar(step);

  useEffect(() => {
    if (stepVar === 1) handleContinueConfiguration({ label: 'Guardar', color: 'secondary' });
  }, [stepVar]);
  return (
    <>
      <Form />
      <Table />
    </>
  );
};

export default ClassificationPromotionComponent;
