import { gql } from '@apollo/client';

const FRAGMENT_QUOTE = gql`
  fragment CoreQuoteFields on Quote {
    id
    status
    serial
    folio
    commentary
    currencyKey
    exchangeRate
    subtotal
    discount
    taxes
    total
    commission
    QuoteProducts {
      id
      quantity
      unitPrice
      withoutTaxes
      subtotal
      discount
      taxes
      total
      commentary
      priceName
      priceKey
      ingredients
      cost
      status
      quoteId
      saleProductId
      productId
      branchId
      companyId
      promotionId
      productPromotionId
      productPresentationId
      iva
      ieps
    }
    branchId
    saleId
    customerId
    couponId
    sellerId
    cashierId
    companyId
    createdAt
    fileId
    expireDate
    pdfFileId
  }
`;

const FRAGMENT_SALE = gql`
  fragment CoreSaleFields on Sale {
    id
    type
    subtype
    category
    service
    status
    deliveryStatus
    serial
    folio
    folio2
    commentary
    currencyKey
    exchangeRate
    shipping
    subtotal
    discount
    taxes
    total
    totalPaid
    paid
    customerId
    code
    sellerId
    cashierId
    deliveryManId
    commission
    paymentMethod
    createdAt
    clientCreatedAt
    updatedAt
    deletedAt
    cashier
    seller
    usdTotal
    invoiceId
    expenses
    pdfFileId
    pdfServiceOrderFileId
    pdfWorksAndRemodelingFilId
    SaleProducts {
      id
      name
      productId
      quantity
      unitPrice
      withoutTaxes
      subtotal
      discount
      taxes
      total
      priceName
      commentary
      saleId
      employeeId
      clientCreatedAt
      deletedAt
      outDate
      saleStepId
      Crm {
        id
        customerId
        sessions
        sessionsTaken
        Product {
          id
          name
        }
      }
    }
    AccountReceivable {
      id
      status
      type
      paid
      folio
      serial
      description
      subtotal
      discount
      taxes
      total
      payment
      balance
      clientCreatedAt
      timeLimit
      background
      saleId
      limitDate
      customerId
      exchangeRate
    }
    Transactions {
      id
      total
      paymentMethodId
      clientCreatedAt
      createdAt
      currency
      exchangeRate
      usdTotal
      transactionAccountId
      invoiceComplementId
      commentary
    }
  }
`;

const CREATE_SALE = gql`
  ${FRAGMENT_QUOTE}
  ${FRAGMENT_SALE}
  mutation FinalizeCart(
    $Products: [SaleProductData!]!
    $Transactions: [TransactionSaleData]!
    $pin: String
    $customerId: ID
    $isInvoice: Boolean
    $showTaxes: Boolean
    $category: Int!
    $type: Int!
    $taxesOption: Int
    $discountType: Int
    $branchInventoryId: ID
    $branchSaleId: ID
    $sellerId: ID
    $cashFlowId: ID
    $paymentMethod: String
    $paymentForm: String
    $useCfdi: String
    $bankAccount: Int
    $expirationDate: Date
    $commentary: String
    $relateUUID: String
    $relateType: String
    $calendarId: ID
    $folio: String
    $serial: String
    $operationDate: Date
  ) {
    finalizeCart(
      input: {
        pin: $pin
        Products: $Products
        type: $type
        taxesOption: $taxesOption
        discountType: $discountType
        branchInventoryId: $branchInventoryId
        branchSaleId: $branchSaleId
        showTaxes: $showTaxes
        category: $category
        cashFlowId: $cashFlowId
        isInvoice: $isInvoice
        Transactions: $Transactions
        customerId: $customerId
        sellerId: $sellerId
        paymentMethod: $paymentMethod
        paymentForm: $paymentForm
        useCfdi: $useCfdi
        commentary: $commentary
        showBankAccount: $bankAccount
        expireDate: $expirationDate
        relateUUID: $relateUUID
        relateType: $relateType
        calendarId: $calendarId
        folio: $folio
        serial: $serial
        operationDate: $operationDate
      }
    ) {
      Sale {
        ...CoreSaleFields
        Invoice {
          id
          uuid
        }
      }
      Invoice {
        id
        uuid
        serial
        folio
        paymentMethod
        type
        paymentForm
        useCfdi
        voucher
        subtotal
        discount
        taxes
        total
        createdAt
        updatedAt
        Sale {
          SaleProducts {
            id
            name
            productId
            quantity
            unitPrice
            withoutTaxes
            subtotal
            discount
            taxes
            total
            priceName
            commentary
            employeeId
            saleId
            clientCreatedAt
            Crm {
              id
            }
            deletedAt
            outDate
            saleStepId
          }
          AccountReceivable {
            id
            status
            type
            paid
            folio
            serial
            description
            subtotal
            discount
            taxes
            total
            payment
            balance
            clientCreatedAt
            timeLimit
            background
            saleId
            limitDate
            customerId
            exchangeRate
          }
          Transactions {
            id
            total
            paymentMethodId
            clientCreatedAt
            createdAt
            currency
            exchangeRate
            usdTotal
            transactionAccountId
            invoiceComplementId
            commentary
          }
        }
        status
      }
      Quote {
        ...CoreQuoteFields
      }
      Maintenance {
        id
        type
        subtype
        status
        deliveryStatus
        serial
        folio
        commentary
        currencyKey
        exchangeRate
        shipping
        subtotal
        discount
        taxes
        total
        customerId
        code
        sellerId
        cashierId
        deliveryManId
        commission
        MaintenanceProducts {
          id
          name
          productId
          quantity
          unitPrice
          withoutTaxes
          subtotal
          discount
          taxes
          total
          priceName
          commentary
          clientCreatedAt
          deletedAt
          outDate
          saleStepId
          cost
          iva
          ieps
        }
        createdAt
        clientCreatedAt
        updatedAt
        deletedAt
        usdTotal
        expenses
        pdfFileId
        Sale {
          id
          Invoice {
            uuid
          }
          Transactions {
            id
            total
            paymentMethodId
            clientCreatedAt
            createdAt
            currency
            exchangeRate
            usdTotal
            transactionAccountId
            invoiceComplementId
            commentary
          }
          AccountReceivable {
            id
            status
            type
            paid
            folio
            serial
            description
            subtotal
            discount
            taxes
            total
            payment
            balance
            clientCreatedAt
            timeLimit
            background
            saleId
            limitDate
            customerId
            exchangeRate
          }
        }
        cost
        folioSale
      }
    }
  }
`;

export default { CREATE_SALE, FRAGMENT_QUOTE, FRAGMENT_SALE };
