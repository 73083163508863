import React, { useRef } from 'react';
import FooterDialogComponent from '../../../presentationals/Dialog/Footer';
import CreateButtonEmployeeComponent from './CreateButton';
import ClearButtonCreateEmployeeComponent from './ClearButton';
import BodyEmployeeComponent from './Body';
var CreateEmployeeComponent = function () {
    var createButtonEmployeeRef = useRef(null);
    var bodyEmployeeRef = useRef(null);
    var handleCreate = function () {
        var _a;
        (_a = createButtonEmployeeRef.current) === null || _a === void 0 ? void 0 : _a.onPressEnter();
    };
    var handleGetValues = function () {
        var _a;
        return (_a = bodyEmployeeRef.current) === null || _a === void 0 ? void 0 : _a.getValues();
    };
    var handleClear = function () {
        var _a;
        (_a = bodyEmployeeRef.current) === null || _a === void 0 ? void 0 : _a.onClear();
    };
    return (React.createElement("div", { className: 'flex flex-1 flex-col h-full overflow-hidden basis-0' },
        React.createElement(BodyEmployeeComponent, { ref: bodyEmployeeRef, onPressEnter: handleCreate }),
        React.createElement(FooterDialogComponent, null,
            React.createElement("div", null),
            React.createElement("div", null,
                React.createElement(ClearButtonCreateEmployeeComponent, { onClick: handleClear }),
                React.createElement(CreateButtonEmployeeComponent, { ref: createButtonEmployeeRef, getValues: handleGetValues })))));
};
export default CreateEmployeeComponent;
