import React from 'react';

import { styled } from '@mui/system';

import CloseButton from './CloseButton';
import CreateButton from './CreateButton';

const RootComponent = styled('div')(({ theme }) => ({
  '&.root': {
    padding: theme.spacing(2),
    display: 'flex',
    justifyContent: 'flex-end',
    borderTop: '1px solid #d6d6d6',
  },
  '& .storeButton': {
    marginLeft: theme.spacing(2),
    padding: theme.spacing(1.5, 2.5, 1.5, 2.5),
    boxShadow: 'none',
    '&:hover': {
      boxShadow: 'none',
    },
  },
}));

const FooterItemEditFormProductComponent = () => {
  return (
    <RootComponent className='root'>
      <CloseButton />
      <CreateButton />
    </RootComponent>
  );
};

export default FooterItemEditFormProductComponent;
