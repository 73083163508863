import React, { useEffect } from 'react';

import Container from '../../presentationals/Module.v2/Container';
import { customerLabel } from '../../helpers';

import { filter } from '../../apollo/cache';

import GQL from '../sale.report.v2/_gql';
import Action from './_action';
import Filter from './_filter';

const customerVar = customerLabel({ textTransform: 'capitalize' });

const title = 'Seguimiento de orden de servicio';
const operation = ['sale', 'sales'];

const Rows = [
  { label: customerVar, key: 'Customer firstName && Customer lastName', filter: true },
  { label: 'Fecha de Emisión', key: 'clientCreatedAt', sort: 'text', format: 'dateTime' },
  { label: 'Estatus', key: 'status', sort: 'text', format: 'orderStatus' },
  { label: 'Folio', key: 'serial', prefix: 'OS', padStart: [5, '0'], sort: 'text', filter: true },
  { label: 'NV', key: 'folio2', ifValue: { key: 'status', value: 1, newValue: '-' } },
  { label: 'Comentario', key: 'commentary' },
  { label: 'Importe', key: 'total', sort: 'text', format: 'currency', align: 'right' },
  { label: 'Gastos Op.', key: 'expenses', sort: 'text', format: 'currency', align: 'right' },
];

const ServiceOrderv2Component = () => {
  useEffect(() => {
    filter({ category: [3, 7], status: [1, 2, 3, 4], limit: 0, order: ['clientCreatedAt', 'asc'] });
  }, []);

  return (
    <>
      <Container
        title={title}
        operation={operation}
        Rows={Rows}
        get={GQL.GET}
        noDelete
        noEdit
        filter
      />
      <Action />
      <Filter />
    </>
  );
};

export default ServiceOrderv2Component;
