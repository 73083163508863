var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
var addProductToCreatePurchaseOrderCart = function (data, cart) {
    var _a, _b;
    if (!data.Product)
        throw new Error('Ocurrió un error inesperado, contacta soporte técnico.');
    if (!((_a = data.Product) === null || _a === void 0 ? void 0 : _a.ProductPresentations))
        throw new Error('Este producto no cuenta con presentaciones registradas.');
    var ProductPresentations = __spreadArray([
        data.Product.PresentationBase
    ], (((_b = data.Product) === null || _b === void 0 ? void 0 : _b.ProductPresentations) || []), true);
    var ProductPresentation = ProductPresentations.find(function (el) { return el.id === data.productPresentationId; });
    if (!ProductPresentation)
        throw new Error('Este producto no cuenta con una presentación seleccionada.');
    var Product = data.Product;
    var ProductInCart = cart.find(function (el) { return el.ProductPresentation.id === data.productPresentationId; });
    if (ProductInCart) {
        var cartWithoutProduct = cart.filter(function (el) { return el.ProductPresentation.id !== data.productPresentationId; });
        return __spreadArray([
            __assign(__assign({}, ProductInCart), { quantity: ProductInCart.quantity + parseFloat(data.quantity) })
        ], cartWithoutProduct, true);
    }
    return __spreadArray([
        {
            Product: Product,
            ProductPresentation: ProductPresentation,
            cost: parseFloat(data.cost || '0'),
            percentAdjust: parseFloat(data.percentAdjust || '0'),
            quantity: parseFloat(data.quantity || '0'),
            salePrice: parseFloat(data.salePrice || '0'),
            updateSalePrice: data.updateSalePrice,
            withoutTaxes: 0,
            taxes: 0,
            total: 0,
            price: parseFloat(data.cost || '0'),
        }
    ], cart, true);
};
export default addProductToCreatePurchaseOrderCart;
