import React, { useEffect, useState } from 'react';
import getColorsFromProductAttributes from './helpers/getColorsFromProductAttributes';
import getSecondaryColorsFromProductAttributes from './helpers/getSecondaryColorsFromProductAttributes';
import getTextAttributesFromProductAttributes from './helpers/getTextAttributesFromProductAttributes';
import ColorEcommerceProductComponent from './Color';
import SecondaryColorEcommerceProductComponent from './SecondaryColor';
import TextSettingsEcommenceProductComponent from './TextSettings';
import AvailableForShippingBodyEcommerceProductComponent from './AvailableForShipping';
import ShippingSettingBodyEcommerceProductComponent from './ShippingSetting';
var BodyEcommerceProductComponent = function (props) {
    var _a = useState([]), Colors = _a[0], setColors = _a[1];
    var _b = useState([]), SecondaryColors = _b[0], setSecondaryColors = _b[1];
    var _c = useState([]), TextAttributes = _c[0], setTextAttributes = _c[1];
    useEffect(function () {
        if (props.ProductAttributes) {
            var currentColors = getColorsFromProductAttributes(props.ProductAttributes);
            setColors(currentColors);
            var currentSecondaryColors = getSecondaryColorsFromProductAttributes(props.ProductAttributes);
            setSecondaryColors(currentSecondaryColors);
            var currentTextAttributes = getTextAttributesFromProductAttributes(props.ProductAttributes);
            setTextAttributes(currentTextAttributes);
        }
    }, [props.ProductAttributes]);
    return (React.createElement("div", { className: 'flex flex-1 flex-col rounded bg-slate-200 p-4' },
        React.createElement("div", { className: 'flex flex-1 flex-col rounded bg-slate-50 p-4' },
            React.createElement(ColorEcommerceProductComponent, { Colors: Colors }),
            React.createElement("div", { className: 'my-2' }),
            React.createElement(SecondaryColorEcommerceProductComponent, { Colors: SecondaryColors }),
            React.createElement("div", { className: 'my-2' }),
            React.createElement(TextSettingsEcommenceProductComponent, { TextAttributes: TextAttributes, Colors: Colors }),
            React.createElement("div", { className: 'my-2' }),
            React.createElement(AvailableForShippingBodyEcommerceProductComponent, { isAvailable: props.toShipping }),
            props.toShipping && (React.createElement(ShippingSettingBodyEcommerceProductComponent, { ProductMeasumeremts: props.ProductMeasurements })))));
};
export default BodyEcommerceProductComponent;
