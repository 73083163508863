import React from 'react';
import { useReactiveVar } from '@apollo/client';
import DialogComponent from '../../../presentationals/Dialog';
import { inputError, isCreateProductIngredientPriceOpen, productPromotionPriceForm, } from '../../../apollo/cache';
import BodyProductPromotionPriceComponent from '../../promotion/CreateProductPromotionPrice/Body';
import FooterDialogComponent from '../../../presentationals/Dialog/Footer';
import CreateProductIngredientPriceButtonComponent from './CreateButton';
import getDefaultProductPromotionPriceValues from '../../promotion/CreateProductPromotionPrice/Body/helpers/getDefaultValues';
var CreateProductIngredientPriceComponent = function () {
    var isOpen = useReactiveVar(isCreateProductIngredientPriceOpen);
    var handleClose = function () {
        var defaultValues = getDefaultProductPromotionPriceValues();
        productPromotionPriceForm(defaultValues);
        inputError({});
        isCreateProductIngredientPriceOpen(false);
    };
    return (React.createElement(DialogComponent, { fullScreen: true, open: isOpen, title: 'Nueva tarifa', onClose: handleClose },
        React.createElement("div", { className: 'flex flex-1 flex-col overflow-hidden' },
            React.createElement(BodyProductPromotionPriceComponent, null)),
        React.createElement(FooterDialogComponent, null,
            React.createElement("div", null),
            React.createElement(CreateProductIngredientPriceButtonComponent, null))));
};
export default CreateProductIngredientPriceComponent;
