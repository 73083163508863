var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useRef } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import ButtonFooterDialogComponent from '../Button';
import ConfirmRequestDialogComponent from './ConfirmDialog';
var ActionRequestFooterButtonComponent = function (props) {
    var confirmRequestDialogRef = useRef(null);
    var handleClick = function () {
        var _a;
        (_a = confirmRequestDialogRef.current) === null || _a === void 0 ? void 0 : _a.open();
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(ButtonFooterDialogComponent, __assign({}, props, { onClick: handleClick }), props.children),
        React.createElement(ConfirmRequestDialogComponent, { onClick: props.onClick, loading: props.loading || false, ref: confirmRequestDialogRef, variant: 'contained', startIcon: props.color === 'error' ? React.createElement(DeleteIcon, null) : undefined, color: props.color !== 'error' ? 'success' : 'error' })));
};
export default ActionRequestFooterButtonComponent;
