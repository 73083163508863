var getRouteFromInvoiceComplementMenuValue = function (value) {
    switch (value) {
        case 0:
            return '.';
        case 1:
            return 'cancel';
        default:
            return false;
    }
};
export default getRouteFromInvoiceComplementMenuValue;
