import React, { useEffect, useState } from 'react';

import { useMutation, useQuery, useReactiveVar } from '@apollo/client';

// import TextField from '../../../../FormComponents/TextField';
import SelectField from '../../../../FormComponents/SelectField';
import FormDialog from '../../../../FormDialog';

import { inputError } from '../../../../../apollo/cache';

import GQL from '../_gql';
import getInputErrorFormat from '../../../../../containers/sale.v2/Action/helpers/getInputErrorFormat';
import setInputErrorFormat from '../../../../../containers/sale.v2/Action/helpers/setInputErrorFormat';
import LoadingProgress from '../../../../LoadingProgress';

const defaultValues = {
  codeConfirmation: 0,
  pettyCash: false,
  pettyCashAmount: '',
  dinerQuantity: '',
  tableQuantity: '',
};

const codeConfirmationOptions = [
  { value: 0, label: 'NINGUNO' },
  { value: 1, label: 'DESCUENTOS' },
  { value: 2, label: 'CANCELACIONES' },
  { value: 3, label: 'DESCUENTOS Y CANCELACIONES' },
  { value: 4, label: 'NO SOLICITAR NINGUNA CONTRASEÑA' },
];

// const yesNoOptions = [
//   { value: false, label: 'No' },
//   { value: true, label: 'Sí' },
// ];

const Component = props => {
  const errors = useReactiveVar(inputError);
  const [values, setValues] = useState(defaultValues);

  const { data: dataCompany, loading: loadingData } = useQuery(GQL.GET_BY_ID);
  const [updateCompany, { loading }] = useMutation(GQL.UPDATE_BRANCH_CONFIG);
  console.log(dataCompany);
  useEffect(() => {
    if (dataCompany && dataCompany.branchConfig)
      setValues(values => ({ ...values, ...dataCompany.branchConfig }));
  }, [dataCompany]);

  const handleAction = async () => {
    try {
      if (
        values.pettyCashAmount &&
        (Number.isNaN(parseFloat(values.pettyCashAmount)) || parseFloat(values.pettyCashAmount) < 0)
      )
        throw new Error(setInputErrorFormat('pettyCash', 'Ingrese un valor válido.'));

      if (
        values.dinerQuantity &&
        (Number.isNaN(parseFloat(values.dinerQuantity)) || parseFloat(values.dinerQuantity) < 0)
      )
        throw new Error(setInputErrorFormat('dinerQuantity', 'Ingrese un valor mayor a cero.'));

      if (
        values.tableQuantity &&
        (Number.isNaN(parseFloat(values.tableQuantity)) || parseFloat(values.tableQuantity) < 0)
      )
        throw new Error(setInputErrorFormat('tableQuantity', 'Ingrese un valor mayor a cero.'));

      await updateCompany({
        variables: {
          codeConfirmation: parseInt(values.codeConfirmation),
          pettyCash: values.pettyCash,
          pettyCashAmount: parseFloat(values.pettyCashAmount),
          dinerQuantity: values.dinerQuantity ? parseInt(values.dinerQuantity) : null,
          tableQuantity: values.tableQuantity ? parseInt(values.tableQuantity) : null,
        },
      });
      props.handleClose();
    } catch (e) {
      getInputErrorFormat(e);
    }
  };

  const handleClose = () => {
    setValues(defaultValues);
    props.handleClose();
  };

  const handleChange = (name, value) => {
    setValues(values => ({ ...values, [name]: value }));
  };

  return (
    <FormDialog
      isOpen={props.isOpen || false}
      handleClose={handleClose}
      title='Configuración'
      handleAction={handleAction}
      isLoading={loading}
    >
      {loadingData ? (
        <LoadingProgress />
      ) : (
        <>
          <SelectField
            name='codeConfirmation'
            label='Solicitud en código de confirmación'
            onChange={handleChange}
            options={codeConfirmationOptions}
            value={values.codeConfirmation}
            error={errors.codeConfirmation}
          />
          {/* <SelectField
            name='pettyCash'
            label='Contemplar el fondo de caja al realizar corte'
            onChange={handleChange}
            options={yesNoOptions}
            value={values.pettyCash}
            error={errors.pettyCash}
          /> */}
          {/* <TextField
            name='pettyCashAmount'
            label='Fondo inicial en caja'
            onChange={handleChange}
            value={values.pettyCashAmount}
            error={errors.pettyCashAmount}
            type='number'
          />
          <TextField
            name='dinerQuantity'
            label='Número de comensales'
            onChange={handleChange}
            value={values.dinerQuantity}
            error={errors.dinerQuantity}
            type='number'
          />
          <TextField
            name='tableQuantity'
            label='Número de mesas'
            onChange={handleChange}
            value={values.tableQuantity}
            error={errors.tableQuantity}
            type='number'
          /> */}
        </>
      )}
    </FormDialog>
  );
};

export default Component;
