import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import BodyCancelInvoiceComplementComponent from './Body';
import useApolloLazyQuery from '../../../../hooks/useApolloLazyQuery';
import GQL_INVOICE_COMPLEMENT from '../../../../apollo/gql/invoiceComplement';
import LoadingProgress from '../../../../presentationals/LoadingProgress';
import ErrorDataComponent from '../../../../presentationals/ErrorData';
import { dateFormat } from '../../../../helpers';
var CancelInvoiceComplementComponent = function () {
    var params = useParams();
    var _a = useApolloLazyQuery(GQL_INVOICE_COMPLEMENT.GET_INVOICE_COMPLEMENT_BY_ID), getData = _a[0], _b = _a[1], loading = _b.loading, data = _b.data;
    useEffect(function () {
        if (params.invoiceComplementId)
            getData({ variables: { id: params.invoiceComplementId } });
    }, [params]);
    if (loading)
        return React.createElement(LoadingProgress, null);
    if (!data)
        return React.createElement(ErrorDataComponent, null);
    if (data.deletedAt)
        return (React.createElement("div", { className: 'flex px-4 py-3' },
            "El complemento de pago fue cancelado el ",
            dateFormat(data.deletedAt),
            "."));
    return React.createElement(BodyCancelInvoiceComplementComponent, null);
};
export default CancelInvoiceComplementComponent;
