import React from 'react';

import Table from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Grid from '@mui/material/Grid';

import TableBody from './Body';
import Form from './Form';

const ProductPromotionProductComponent = props => {
  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={5}>
        <Form id={props.id} />
      </Grid>
      <Grid item xs={12} md={7}>
        <TableContainer className='border border-solid border-[#d8d8d8] mt-1 mb-2 min-h-[200px]'>
          <Table size='small'>
            <TableHead>
              <TableRow className='bg-[#f6f6f6]'>
                <TableCell padding='none' />
                <TableCell>Descripción</TableCell>
                <TableCell>Presentación</TableCell>
                <TableCell align='center'>Cant.</TableCell>
                <TableCell align='center'>Recibido</TableCell>
                <TableCell align='right'>Descuento</TableCell>
                <TableCell align='right'>P. Unitario</TableCell>
                <TableCell align='right'>Importe</TableCell>
              </TableRow>
            </TableHead>
            <TableBody id={props.id} handleCloseAll={props.handleCloseAll} />
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  );
};

export default ProductPromotionProductComponent;
