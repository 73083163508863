import React, { useState } from 'react';

import { useQuery, useMutation } from '@apollo/client';
import Typography from '@mui/material/Typography';
import Switch from '@mui/material/Switch';
import Grid from '@mui/material/Grid';

import FormDialog from '../../../presentationals/FormDialog';
import Autocomplete from '../../../presentationals/FormComponents/Autocomplete';
import DateField from '../../../presentationals/FormComponents/DateField';
import Snackbar from '../../../presentationals/Snackbar';

import { parseError } from '../../../helpers';

import GQL from '../_gql';
import GQL_CUSTOMER from '../../customer/_gql';
import GQL_SALE from '../../sale.v2/_gql';
import CONFIG from '../../../config/config.json';
import getSession from '../../../helpers/getSession';
import useSnackbarDialog from '../../../store/snackbar/snackbarDialog';

// import ProductComponent from './Product';

const defaultValues = {
  customer: null,
  date: true,
  expireDate: new Date(),
};

const DuplicateDialogComponent = props => {
  const { setSnackbar } = useSnackbarDialog();
  const [values, setValues] = useState(defaultValues);
  const [customers, setCustomers] = useState([]);

  const [duplicateQuote, { loading }] = useMutation(GQL.DUPLICATE_QUOTE, {
    update(cache, { data }) {
      cache.modify({
        fields: {
          quotes(cachedQuotesDetails) {
            const newQuoteRef = cache.writeFragment({
              data: { ...data.duplicateQuote },
              fragment: GQL_SALE.FRAGMENT_NEW_QUOTE,
            });
            return {
              ...cachedQuotesDetails,
              count: cachedQuotesDetails.count + 1,
              rows: [newQuoteRef, ...cachedQuotesDetails.rows],
            };
          },
        },
      });
    },
  });

  useQuery(GQL_CUSTOMER.GET_NAME_AND_PHONE, {
    variables: { limit: 0 },
    onCompleted: data => {
      const tmp = data.customers.rows.map(el => ({
        label: `${el.firstName} ${el.lastName || ''} ${el.phone || ''}`,
        value: el.id,
        priceName: el.priceName,
      }));
      setCustomers(tmp);
    },
  });

  const handleClose = () => {
    props.handleClose();
    setValues(defaultValues);
  };

  const handleAction = async () => {
    try {
      const session = getSession();
      if (!values.customer) {
        const e = new Error('El cliente es obligatorio.');
        e.name = 'customer';
        e.input = true;
        throw e;
      }

      const variables = {
        id: props.id,
        customerId: values.customer.value,
        expireDate: values.date ? values.expireDate : null,
      };

      const response = await duplicateQuote({ variables });

      if (parseInt(session?.Company?.id) === 71)
        window.open(`${CONFIG['restServer'].uri}/files/quotes/${response.data.duplicateQuote.id}`);

      handleClose();
      setValues(defaultValues);
    } catch (e) {
      console.log(e);
      if (e.input) {
        setErrors({ [e.name]: e.message });
        return;
      }
      const parseErrors = parseError(e);
      parseErrors.forEach(el => {
        if (el.name === 'BAD_USER_INPUT') setErrors(el.message);
        else
          setSnackbar({
            variables: {
              isOpen: true,
              time: 3000,
              label: el.message,
              severity: 'error',
            },
          });
      });
    }
  };

  const handleSwitchChange = event => {
    setValues(values => ({ ...values, date: event.target.checked }));
  };

  const handleAutocompleteChange = (name, value) => {
    setValues(values => ({ ...values, [name]: value }));
  };

  const handleDateChange = (name, value) => {
    setValues(values => ({ ...values, [name]: value }));
  };

  return (
    <>
      <FormDialog
        title=''
        isOpen={props.isOpen}
        isLoading={loading}
        fullScreen
        handleClose={handleClose}
        handleAction={handleAction}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant='h5'>Duplicar Cotización</Typography>
          </Grid>
          <Grid item xs={12} md={5}>
            <Autocomplete
              name='customer'
              label='Selecciona el cliente correspondiente para esta nueva cotización'
              value={values.customer}
              onChange={handleAutocompleteChange}
              options={customers}
              noFormat
              variant='outlined'
            />
            <Typography variant='subtitle1'>Expira:</Typography>
            <Switch checked={values.date} onChange={handleSwitchChange} name='checkedA' />
            {values.date && (
              <DateField
                name='expireDate'
                label='Fecha de exiración'
                value={values.expireDate}
                onChange={handleDateChange}
                variant='outlined'
                noFormat
              />
            )}
          </Grid>
          <Grid item xs={12} md={7}>
            {/* <ProductComponent /> */}
          </Grid>
        </Grid>
      </FormDialog>
      <Snackbar horizontal='center' />
    </>
  );
};

export default DuplicateDialogComponent;
