import React from 'react';
import useApolloQuery from '../../../../hooks/useApolloQuery';
import GQL_COMPANY from '../../../../apollo/gql/company';
import LoadingProgress from '../../../../presentationals/LoadingProgress';
import ErrorDataComponent from '../../../../presentationals/ErrorData';
import SettingsAllowedModulesComponent from './Settings';
var AllowedCompanyModulesComponent = function () {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m;
    var _o = useApolloQuery(GQL_COMPANY.GET_ALLOWED_MODULES), data = _o.data, loading = _o.loading;
    if (loading)
        return React.createElement(LoadingProgress, null);
    if (!data)
        return React.createElement(ErrorDataComponent, null);
    return (React.createElement("div", { className: 'flex flex-1 px-4 py-3 flex-col basis-0 overflow-auto' },
        React.createElement("div", { className: 'text-base font-medium mb-1 flex' }, "Modulos de la plataforma"),
        React.createElement("div", { className: 'text-sm sm:text-base text-gray-600 mb-2' },
            "En esta secci\u00F3n, tendr\u00E1s la capacidad de elegir entre diversos m\u00F3dulos disponibles en el sistema. Este proceso implica una evaluaci\u00F3n cuidadosa de las necesidades espec\u00EDficas y objetivos de la empresa, ya que la selecci\u00F3n de m\u00F3dulos afecta directamente la funcionalidad y el alcance del sistema.",
            React.createElement("br", null),
            "Podr\u00E1s habilitar y deshabilitar estas opciones cuantas veces desees."),
        React.createElement("div", { className: 'flex flex-1 flex-col rounded bg-slate-200 p-4' },
            React.createElement("div", { className: 'grid grid-cols-1 sm:grid-cols-3 sm:gap-4 flex-1 flex-col rounded bg-slate-50 p-4' },
                React.createElement("div", { className: 'flex flex-1 flex-col' },
                    React.createElement("div", { className: 'flex text-xl font-medium' }, "Punto de venta"),
                    React.createElement("div", { className: 'flex' }, "Selecciona los m\u00F3dulos que estar\u00E1n disponibles para creaci\u00F3n y gesti\u00F3n."),
                    React.createElement("div", { className: 'mt-2' },
                        React.createElement(SettingsAllowedModulesComponent, { module: 'sale', isAllowed: ((_a = data.sale) === null || _a === void 0 ? void 0 : _a.isAllowed) || false, bankAccountId: ((_b = data.sale) === null || _b === void 0 ? void 0 : _b.bankAccountId) || null, label: 'Notas de venta' }),
                        React.createElement(SettingsAllowedModulesComponent, { module: 'serviceOrder', isAllowed: ((_c = data.serviceOrder) === null || _c === void 0 ? void 0 : _c.isAllowed) || false, bankAccountId: ((_d = data.serviceOrder) === null || _d === void 0 ? void 0 : _d.bankAccountId) || null, label: '\u00D3rdenes de servicio' }),
                        React.createElement(SettingsAllowedModulesComponent, { module: 'maintenance', isAllowed: ((_e = data.maintenance) === null || _e === void 0 ? void 0 : _e.isAllowed) || false, bankAccountId: ((_f = data.maintenance) === null || _f === void 0 ? void 0 : _f.bankAccountId) || null, label: 'Mantenimientos' }),
                        React.createElement(SettingsAllowedModulesComponent, { module: 'rent', isAllowed: ((_g = data.rent) === null || _g === void 0 ? void 0 : _g.isAllowed) || false, bankAccountId: ((_h = data.rent) === null || _h === void 0 ? void 0 : _h.bankAccountId) || null, label: 'Rentas' }),
                        React.createElement(SettingsAllowedModulesComponent, { module: 'quotation', isAllowed: ((_j = data.quotation) === null || _j === void 0 ? void 0 : _j.isAllowed) || false, bankAccountId: ((_k = data.quotation) === null || _k === void 0 ? void 0 : _k.bankAccountId) || null, label: 'Cotizaciones' }),
                        React.createElement(SettingsAllowedModulesComponent, { module: 'invoice', isAllowed: ((_l = data.invoice) === null || _l === void 0 ? void 0 : _l.isAllowed) || false, bankAccountId: ((_m = data.invoice) === null || _m === void 0 ? void 0 : _m.bankAccountId) || null, label: 'Facturas electr\u00F3nicas' })))))));
};
export default AllowedCompanyModulesComponent;
