import React, { useState } from 'react';
import { useReactiveVar } from '@apollo/client';
import { Switch, Typography } from '@mui/material';
import { inputError, invoiceSaleV2 } from '../../../../../../../apollo/cache';
import { relateInvoiceOptions } from '../../../../../../../helpers/invoice.options';
import SelectFieldComponent from '../../../../../../../presentationals/FormComponents/SelectField';
import TextFieldComponent from '../../../../../../../presentationals/FormComponents/TextField';

const RelateCfdiInvoiceFormPaymentFormBodyConfirmDialogSaleV2Component = () => {
  const invoiceSaleV2Var = useReactiveVar(invoiceSaleV2);
  const errors = useReactiveVar(inputError);
  const [isDisabled, setIsDisabled] = useState(true);
  const handleSwitch = event => {
    if (!event.target.checked)
      invoiceSaleV2({ ...invoiceSaleV2Var, relateType: '', relateUUID: '' });
    setIsDisabled(!event.target.checked);
  };
  const handleChange = (name, value) => {
    if (Object.keys(errors).length > 0) inputError({});
    invoiceSaleV2({ ...invoiceSaleV2Var, [name]: value });
  };
  return (
    <div>
      <div className='flex flex-row items-center'>
        <Typography variant='subtitle1'>Relacionar CFDi</Typography>
        <Switch checked={!isDisabled} onChange={handleSwitch} name='switchShowRelateFields' />
      </div>
      <TextFieldComponent
        disabled={isDisabled}
        name='relateUUID'
        label='UUID de CFDi a relacionar'
        value={invoiceSaleV2Var.relateUUID}
        error={errors.relateUUID}
        onChange={handleChange}
      />
      <SelectFieldComponent
        disabled={isDisabled}
        name='relateType'
        label='Tipo de relación'
        value={invoiceSaleV2Var.relateType}
        error={errors.relateType}
        options={relateInvoiceOptions}
        onChange={handleChange}
      />
    </div>
  );
};

export default RelateCfdiInvoiceFormPaymentFormBodyConfirmDialogSaleV2Component;
