import { CircularProgress } from '@mui/material';
import React, { forwardRef, useImperativeHandle, useState } from 'react';
var LoadingSearchSaleGlobalInvoiceCustomerFieldComponent = forwardRef(function (props, ref) {
    var _a = useState(false), loading = _a[0], setLoading = _a[1];
    var handleLoading = function (loading) {
        setLoading(loading);
    };
    useImperativeHandle(ref, function () { return ({
        setLoading: handleLoading,
    }); });
    if (!loading)
        return React.createElement("div", null);
    return React.createElement(CircularProgress, { size: 28 });
});
export default LoadingSearchSaleGlobalInvoiceCustomerFieldComponent;
