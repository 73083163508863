import React, { useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import BodyCreateEmployeeComponent from '../../Create/Body';
import FooterDialogComponent from '../../../../presentationals/Dialog/Footer';
import EditButtonGeneralEditEmployeeComponent from './EditButton';
import DeleteButtonGeneralEditEmployeeComponent from './DeleteButton';
import useApolloLazyQuery from '../../../../hooks/useApolloLazyQuery';
import GQL_EMPLOYEE from '../../../../apollo/gql/employee';
import LoadingProgress from '../../../../presentationals/LoadingProgress';
import ErrorDataComponent from '../../../../presentationals/ErrorData';
import { inputError } from '../../../../apollo/cache';
var GeneralEditEmployeeComponent = function () {
    var params = useParams();
    var bodyEmployeeRef = useRef(null);
    var editButtonEmployeeRef = useRef(null);
    var _a = useApolloLazyQuery(GQL_EMPLOYEE.GET_EMPLOYEE_BY_ID), getById = _a[0], _b = _a[1], loading = _b.loading, data = _b.data;
    useEffect(function () {
        return function () {
            inputError({});
        };
    }, []);
    useEffect(function () {
        if (params.employeeId)
            getById({ variables: { id: params.employeeId } });
    }, [params]);
    var handleEdit = function () {
        var _a;
        (_a = editButtonEmployeeRef.current) === null || _a === void 0 ? void 0 : _a.onPressEnter();
    };
    var handleGetValues = function () {
        var _a;
        return (_a = bodyEmployeeRef.current) === null || _a === void 0 ? void 0 : _a.getValues();
    };
    if (loading)
        return React.createElement(LoadingProgress, null);
    if (!data)
        return React.createElement(ErrorDataComponent, null);
    return (React.createElement("div", { className: 'flex flex-1 flex-col h-full overflow-hidden basis-0' },
        React.createElement(BodyCreateEmployeeComponent, { ref: bodyEmployeeRef, defaultValues: data, onPressEnter: handleEdit }),
        React.createElement(FooterDialogComponent, null,
            React.createElement("div", null,
                React.createElement(DeleteButtonGeneralEditEmployeeComponent, null)),
            React.createElement(EditButtonGeneralEditEmployeeComponent, { getValues: handleGetValues, ref: editButtonEmployeeRef }))));
};
export default GeneralEditEmployeeComponent;
