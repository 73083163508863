import React from 'react';
import { useReactiveVar } from '@apollo/client';
import CustomerInfo from './CustomerInfo';
import CartInfo from './CartInfo';
import TotalInfo from './TotalInfo';
import CommentaryInfo from './CommentaryInfo';
import OperantionDateSaleV2Component from './OperationDate';
import SaleBranchBillingInformationComponent from '../../../Action/Branch/Form/Body/BillingInformation';
import { formatSaleV2 } from '../../../../../apollo/cache';

const SummaryFormBodyConfirmDialogSaleV2Component = props => {
  const formatSaleV2Var = useReactiveVar(formatSaleV2);
  return (
    <div className='flex flex-1 flex-col'>
      <div className='flex flex-col'>
        {formatSaleV2Var[0].toString() === '-1' ? (
          <div className='my-2 flex'>
            <SaleBranchBillingInformationComponent />
            <div>
              <OperantionDateSaleV2Component />
            </div>
          </div>
        ) : (
          <div />
        )}
        <CustomerInfo noCustomerEdit={props.noCustomerEdit} />
      </div>
      <CartInfo />
      <div className='flex justify-between pt-2 border-0 border-t-[1px] border-solid border-gray-200'>
        <CommentaryInfo />
        <TotalInfo />
      </div>
    </div>
  );
};

export default SummaryFormBodyConfirmDialogSaleV2Component;
