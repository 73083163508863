import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { Menu } from '@mui/material';
import SelectBranchInventoryReportMenuItemsComponent from './Items';
import useInventoryReportStore from '../../../../store/inventoryReport';
var SelectBranchInventoryReportMenuComponent = forwardRef(function (props, ref) {
    var setBranchId = useInventoryReportStore(function (state) { return state.setBranchId; });
    var _a = useState(null), anchorEl = _a[0], setAnchorEl = _a[1];
    var handleOpen = function (currentTarget) {
        setAnchorEl(currentTarget);
    };
    var handleClose = function () {
        setAnchorEl(null);
    };
    var handleClick = function (id) {
        setBranchId(id);
        handleClose();
    };
    useImperativeHandle(ref, function () { return ({
        open: handleOpen,
    }); });
    return (React.createElement(Menu, { id: 'selectBranchInventoryReportMenu', anchorEl: anchorEl, open: !!anchorEl, onClose: handleClose, MenuListProps: {
            'aria-labelledby': 'branch-inventory-report-button',
        }, anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'right',
        }, transformOrigin: {
            vertical: 'top',
            horizontal: 'right',
        }, slotProps: {
            paper: {
                className: 'w-52',
            },
        } },
        React.createElement(SelectBranchInventoryReportMenuItemsComponent, { onClick: handleClick })));
});
export default SelectBranchInventoryReportMenuComponent;
