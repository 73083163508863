var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { forwardRef, useEffect, useImperativeHandle, useMemo, } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import SearchIcon from '@mui/icons-material/SearchOutlined';
import IconButtonComponent from '../../../../IconButton';
import GQL_PRODUCT from '../../../../../apollo/gql/product';
import useApolloLazyQuery from '../../../../../hooks/useApolloLazyQuery';
import useProductFieldStore, { useProductFieldStoreInstance, } from '../../../../../store/productField';
import CONSTANTS_ERROR from '../../../../../constants/error';
var SearchProductFieldButtonComponent = forwardRef(function (props, ref) {
    var storeInstance = useProductFieldStoreInstance();
    var setPopoverOpen = useProductFieldStore().setPopoverOpen;
    var _a = useFormContext(), control = _a.control, setError = _a.setError, clearErrors = _a.clearErrors;
    var onChange = useController({
        name: props.name,
        control: control,
    }).field.onChange;
    var GET = useMemo(function () { return GQL_PRODUCT.CUSTOMIZED_GET(); }, []);
    var _b = useApolloLazyQuery(GET, {
        variables: props.variables,
    }), getProducts = _b[0], loading = _b[1].loading;
    useEffect(function () {
        props.onLoading && props.onLoading(loading);
    }, [loading]);
    var handleAction = function (anchorEl) { return __awaiter(void 0, void 0, void 0, function () {
        var _a, value, searchBy, setValue, data, error_1;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    _b.trys.push([0, 2, , 3]);
                    _a = storeInstance.getState(), value = _a.value, searchBy = _a.searchBy, setValue = _a.setValue;
                    return [4 /*yield*/, getProducts({ variables: { name: [searchBy[1], value], limit: 0 } })];
                case 1:
                    data = (_b.sent()).data;
                    if (!data) {
                        throw new Error(CONSTANTS_ERROR.SERVER_ERROR);
                    }
                    else if (data.count === 0) {
                        onChange(undefined);
                        if (searchBy[1] === 'barcode')
                            setValue('');
                        throw new Error(CONSTANTS_ERROR.PRODUCT_NOT_FOUND);
                    }
                    else if (data.count === 1) {
                        clearErrors(props.name);
                        onChange(data.rows[0].id);
                        setValue(data.rows[0].name);
                    }
                    else if (data.count > 1) {
                        clearErrors(props.name);
                        setPopoverOpen(anchorEl);
                    }
                    return [3 /*break*/, 3];
                case 2:
                    error_1 = _b.sent();
                    if (error_1 instanceof Error)
                        setError(props.name, {
                            message: error_1.message,
                        }, {
                            shouldFocus: true,
                        });
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    var handleClick = function () { return __awaiter(void 0, void 0, void 0, function () {
        var target;
        return __generator(this, function (_a) {
            target = props.inputBaseTarget;
            if (!target.current)
                return [2 /*return*/];
            handleAction(target.current);
            return [2 /*return*/];
        });
    }); };
    useImperativeHandle(ref, function () { return ({
        onEnter: handleAction,
    }); });
    return (React.createElement(IconButtonComponent, { action: handleClick, icon: React.createElement(SearchIcon, null), label: 'Buscar', className: 'flex p-1', disabled: loading }));
});
export default SearchProductFieldButtonComponent;
