import React from 'react';
import { IconButton } from '@mui/material';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
var DeleteProductAdjustInventoryButtonComponent = function (props) {
    var handleClick = function (e) {
        e.stopPropagation();
        if (!props.productPresentationId)
            return;
        props.onClick(props.index, props.productPresentationId);
    };
    return (React.createElement("div", null,
        React.createElement(IconButton, { disabled: props.loading, onClick: handleClick, "aria-label": 'Eliminar', size: 'small' },
            React.createElement(DeleteOutlineIcon, null))));
};
export default DeleteProductAdjustInventoryButtonComponent;
