var getItemSurveyResultBackground = function (SurveyCustomerAnswers) {
    return SurveyCustomerAnswers.reduce(function (background, el) {
        var answer = parseFloat(el.answer);
        switch (el.questionType) {
            case 2:
                if (answer < 8 && answer > 4)
                    return background === '' ? 'bg-amber-300' : background;
                if (answer <= 4)
                    return 'bg-red-300';
                return background;
            case 3:
                if (answer === 1)
                    return background === '' ? 'bg-amber-300' : background;
                if (answer === 0)
                    return 'bg-red-300';
                return background;
            default:
                return background;
        }
    }, '');
};
export default getItemSurveyResultBackground;
