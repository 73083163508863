import React from 'react';
import { Link } from 'react-router-dom';
import { filter, filterForm, isMenuOpen, searchV3 } from '../../../../../../apollo/cache';
var LinkMenuDashboardComponent = function (props) {
    var handleClick = function () {
        filter({});
        filterForm(false);
        searchV3('');
        !props.dontCloseMenu && isMenuOpen(false);
    };
    return (React.createElement("div", { className: 'pb-2' },
        React.createElement(Link, { className: 'no-underline text-[#1376c7] hover:text-sky-800 font-medium', onClick: handleClick, to: props.url }, props.primary),
        React.createElement("div", { className: 'text-sm text-gray-600' }, props.secondary)));
};
export default LinkMenuDashboardComponent;
