var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React from 'react';
import { useReactiveVar } from '@apollo/client';
import SelectFieldComponent from '../../../../../presentationals/FormComponents/SelectField';
import { inputError } from '../../../../../apollo/cache';
import useGetBranches from '../../../../../hooks/useGetBranches';
var BranchFieldProductPromotionPriceComponent = function (props) {
    var errors = useReactiveVar(inputError);
    var _a = useGetBranches(undefined, 'select'), data = _a.data, loading = _a.loading;
    return (React.createElement(SelectFieldComponent, { name: 'branchId', label: 'Sucursal', value: data && data.rows.length > 0 ? props.value : '', error: errors.branchId, isLoading: loading, onChange: props.onChange, options: __spreadArray([
            { value: -1, label: props.defaultOptionLabel || 'Cualquier sucursal' }
        ], data.rows, true) }));
};
export default BranchFieldProductPromotionPriceComponent;
