var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { useReactiveVar } from '@apollo/client';
import SelectFieldComponent from '../SelectField';
import { inputError } from '../../../apollo/cache';
import useGetBranches from '../../../hooks/useGetBranches';
var SelectBranchFieldComponent = forwardRef(function (props, ref) {
    var errors = useReactiveVar(inputError);
    var _a = useState(false), isDefaultValueApplied = _a[0], setIsDefaultValueApplied = _a[1];
    var _b = useState(''), value = _b[0], setValue = _b[1];
    var _c = useState([]), options = _c[0], setOptions = _c[1];
    var _d = useGetBranches(), data = _d.data, loading = _d.loading;
    useEffect(function () {
        if (props.defaultValue && options.length > 0 && !isDefaultValueApplied) {
            var isInArray = options.find(function (el) { return el.value === props.defaultValue; });
            if (isInArray)
                setValue(props.defaultValue);
            setIsDefaultValueApplied(true);
        }
    }, [isDefaultValueApplied, options, props.defaultValue]);
    useEffect(function () {
        if (data) {
            var rows = data.rows;
            var options_1 = rows.map(function (el) { return ({ label: el.name, value: el.id }); });
            var allOptions = !props.disallowAllOptions
                ? [{ value: -1, label: 'Todas las sucursales' }]
                : [];
            setOptions(__spreadArray(__spreadArray([], allOptions, true), options_1, true));
        }
    }, [data]);
    var handleChange = function (name, value) {
        setValue(value);
        props.onChange(name, value);
    };
    var handleClear = function () {
        setIsDefaultValueApplied(false);
        setValue('');
    };
    useImperativeHandle(ref, function () { return ({
        clear: handleClear,
    }); });
    return (React.createElement(SelectFieldComponent, { name: props.name || 'branchId', label: props.label || 'Sucursal', disabled: props.disabled || loading, onChange: handleChange, value: value, error: errors.branchId, options: options }));
});
export default SelectBranchFieldComponent;
