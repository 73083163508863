import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { Menu } from '@mui/material';
import ItemPaymentFormsSalesGlobalInvoiceFieldComponent from './Item';
var PaymentFormsSalesGlobalInvoiceFieldComponent = forwardRef(function (props, ref) {
    var _a = useState(null), anchorEl = _a[0], setAnchorEl = _a[1];
    var handleOpen = function (anchorEl) {
        setAnchorEl(anchorEl);
    };
    var handleClose = function () {
        setAnchorEl(null);
    };
    var handleClick = function (name, value) {
        props.onChange(name, value);
        handleClose();
    };
    useImperativeHandle(ref, function () { return ({
        open: handleOpen,
    }); });
    return (React.createElement(Menu, { id: 'DateRangePaymentFormsFieldMenu', anchorEl: anchorEl, open: !!anchorEl, onClose: handleClose, anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
        }, transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
        }, slotProps: {
            paper: {
                className: 'w-52',
            },
        } },
        React.createElement(ItemPaymentFormsSalesGlobalInvoiceFieldComponent, { name: 'Todas las formas de pago', value: undefined, onClick: handleClick }),
        React.createElement(ItemPaymentFormsSalesGlobalInvoiceFieldComponent, { name: 'Efectivo', value: null, onClick: handleClick })));
});
export default PaymentFormsSalesGlobalInvoiceFieldComponent;
