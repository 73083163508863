import React from 'react';
import { Button } from '@mui/material';
import { isManageAccountOpen } from '../../../../../apollo/cache';
var ManageAccountButtonComponent = function () {
    var handleClick = function () {
        isManageAccountOpen(true);
    };
    return (React.createElement(Button, { fullWidth: true, color: 'inherit', variant: 'outlined', size: 'small', onClick: handleClick, className: 'mt-3 normal-case' }, "Gestionar mi cuenta"));
};
export default ManageAccountButtonComponent;
