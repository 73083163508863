import React from 'react';
import { RouterProvider } from 'react-router-dom';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider, StyledEngineProvider, createTheme } from '@mui/material/styles';
import './App.css';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import Snackbar from './presentationals/Snackbar';
import SocketHandlerComponent from './socket/handler';
import router from './routes';
var theme = createTheme({
    components: {
        MuiLink: {
            defaultProps: {
                underline: 'none',
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    boxShadow: 'none',
                    '&:hover': {
                        boxShadow: 'none',
                    },
                },
            },
        },
        MuiFab: {
            styleOverrides: {
                root: {
                    boxShadow: 'none',
                    '&:hover': {
                        boxShadow: 'none',
                    },
                },
            },
        },
    },
    palette: {
        primary: {
            main: '#0083e8',
        },
        secondary: {
            main: '#e91e63',
        },
    },
    typography: {
        fontFamily: [
            'Roboto',
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
    },
});
var Main = function () { return (React.createElement(StyledEngineProvider, { injectFirst: true },
    React.createElement(ThemeProvider, { theme: theme },
        React.createElement(CssBaseline, null),
        React.createElement(Snackbar, null),
        React.createElement(SocketHandlerComponent, null),
        React.createElement(RouterProvider, { router: router })))); };
export default Main;
