import { Link } from '@mui/material';
import React from 'react';
import CONFIG from '../../../../config/config.json';
var DownloadCfdiFormInvoiceOnlineComponent = function (props) {
    var handleClick = function (e) {
        e.preventDefault();
        props.handleBack && props.handleBack();
    };
    return (React.createElement("div", { className: 'mt-6' },
        React.createElement("div", null,
            React.createElement(Link, { href: "".concat(CONFIG.restServer.uri, "/files/invoices/").concat(props.uuid, "/pdf"), target: '_blank', className: 'mr-8' },
                React.createElement("img", { src: "".concat(CONFIG.googleDrive.fileUri, "1J2ZjcMxJ5OopUCbJr3v9KS_7ORFnOUuL"), alt: 'Descargar PDF', className: 'w-28' })),
            React.createElement(Link, { href: "".concat(CONFIG.restServer.uri, "/files/invoices/").concat(props.uuid, "/xml"), target: '_blank' },
                React.createElement("img", { src: "".concat(CONFIG.googleDrive.fileUri, "1qUuzLOZDeKEKZfwUuFOiqIRhKp04xt37"), alt: 'Descargar XML', className: 'w-28' }))),
        React.createElement("div", { className: 'mt-6 mb-4' },
            "Tu factura electr\u00F3nica con UUID ",
            React.createElement("strong", null, props.uuid),
            " fue generada exitosamente. Puedes descargarla en formato PDF y XML dando clic en las im\u00E1genes."),
        props.handleBack && (React.createElement(Link, { href: '#', underline: 'hover', onClick: handleClick },
            '<',
            " Deseo emitir otra factura electr\u00F3nica."))));
};
export default DownloadCfdiFormInvoiceOnlineComponent;
