var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import VariationSaleV2Component from './Variation';
var VariationsSaleV2Component = forwardRef(function (props, ref) {
    var _a = useState([]), SelectedProductIngredients = _a[0], setSelectedProductIngredients = _a[1];
    useEffect(function () {
        if (props.initialProductIngredients)
            setSelectedProductIngredients(props.initialProductIngredients);
    }, [props.initialProductIngredients]);
    useEffect(function () {
        if (props.ProductVariations && !props.initialProductIngredients)
            setSelectedProductIngredients(props.ProductVariations.map(function (el) { return ({
                productVariationId: el.id,
                ProductIngredients: [],
            }); }));
    }, [props.ProductVariations, props.initialProductIngredients]);
    var handleClick = function (productIngredientId, productVariationId) {
        // const isInArray = SelectedProductIngredients.reduce((isInArray, el) => {
        //   if (el.productVariationId === productVariationId) {
        //     const productIngredientFilter = el.ProductIngredients.filter(
        //       id => id === productIngredientId,
        //     );
        //     if (productIngredientFilter.length > 0) return true;
        //   }
        //   return isInArray;
        // }, false);
        productVariationId;
        var newArray = SelectedProductIngredients.map(function (el) { return (__assign(__assign({}, el), { ProductIngredients: el.productVariationId === productVariationId
                ? [productIngredientId]
                : el.ProductIngredients })); });
        // const newArray = isInArray
        //   ? SelectedProductIngredients.map(el => ({
        //       ...el,
        //       ProductIngredients: el.ProductIngredients.filter(id => id !== productIngredientId),
        //     }))
        //   : SelectedProductIngredients.map(el => ({
        //       ...el,
        //       ProductIngredients: [...el.ProductIngredients, productIngredientId],
        //     }));
        setSelectedProductIngredients(newArray);
    };
    var getSelectedProductIngredients = function () {
        return SelectedProductIngredients;
    };
    useImperativeHandle(ref, function () { return ({
        getSelectedProductIngredients: getSelectedProductIngredients,
    }); });
    return (React.createElement(React.Fragment, null, props.ProductVariations.map(function (el) {
        var _a;
        return (React.createElement(VariationSaleV2Component, { key: el.id, SelectedProductIngredients: (_a = SelectedProductIngredients.find(function (elCh) { return elCh.productVariationId === el.id; })) === null || _a === void 0 ? void 0 : _a.ProductIngredients, productVariationName: el.name, productVariationId: el.id, ProductIngredients: el.ProductIngredients, onChange: handleClick }));
    })));
});
export default VariationsSaleV2Component;
