var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
var getSalesBySellerFromSaleProducts = function (data) {
    return data.reduce(function (array, el, i) {
        var inArray = array.find(function (elCh) { return elCh.name === el.seller; });
        if (el.status === 0)
            return array;
        return inArray
            ? array.map(function (elCh) {
                return elCh.name === el.seller
                    ? __assign(__assign({}, elCh), { qty: !elCh.qty.includes(el.folio) ? __spreadArray(__spreadArray([], elCh.qty, true), [el.folio], false) : elCh.qty, total: elCh.total + el.total }) : elCh;
            })
            : __spreadArray(__spreadArray([], array, true), [
                {
                    id: i,
                    name: el.seller,
                    qty: [el.folio],
                    total: el.total,
                },
            ], false);
    }, []);
};
export default getSalesBySellerFromSaleProducts;
