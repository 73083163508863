var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React from 'react';
import { useApolloClient } from '@apollo/client';
import ButtonFooterDialogComponent from '../../../../../presentationals/Dialog/Footer/Button';
import { currentPaymentAccountReceivable, inputError, isAssignPaymentFormOpen, } from '../../../../../apollo/cache';
import setInputErrorFormat from '../../../../sale.v2/Action/helpers/setInputErrorFormat';
import getInputErrorFormat from '../../../../sale.v2/Action/helpers/getInputErrorFormat';
var CreateAssingPaymentButtonComponent = function (props) {
    var client = useApolloClient();
    var handleClick = function () {
        try {
            inputError({});
            var accountReceivableId_1 = isAssignPaymentFormOpen();
            if (!accountReceivableId_1)
                return;
            var currentPaymentAccountReceivableVar = currentPaymentAccountReceivable();
            var inArray = currentPaymentAccountReceivableVar.find(function (el) { return el.accountReceivableId === accountReceivableId_1; });
            var value_1 = parseFloat(props.value || '0');
            if (value_1 < 0)
                throw new Error(setInputErrorFormat('payment', 'La cantidad proporcionada debe ser mayor o igual a cero.'));
            if (!inArray) {
                currentPaymentAccountReceivable(__spreadArray(__spreadArray([], currentPaymentAccountReceivableVar, true), [
                    { accountReceivableId: accountReceivableId_1, currentPayment: value_1 },
                ], false));
            }
            else {
                var newArray = currentPaymentAccountReceivableVar.map(function (el) {
                    return el.accountReceivableId === accountReceivableId_1 ? __assign(__assign({}, el), { currentPayment: value_1 }) : el;
                });
                currentPaymentAccountReceivable(newArray);
            }
            client.cache.modify({
                id: client.cache.identify({ __typename: 'AccountReceivable', id: accountReceivableId_1 }),
                fields: {
                    balance: function (cachedBalance, _a) {
                        var readField = _a.readField;
                        var total = readField('total');
                        var payment = readField('payment');
                        if (typeof total === 'number' && typeof payment === 'number')
                            return total - payment - value_1;
                        return cachedBalance;
                    },
                },
            });
            props.onClick();
        }
        catch (e) {
            getInputErrorFormat(e);
        }
    };
    return (React.createElement(ButtonFooterDialogComponent, { onClick: handleClick }, "Asignar pago"));
};
export default CreateAssingPaymentButtonComponent;
