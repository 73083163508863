var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { useEffect, useMemo, useState } from 'react';
import { useReactiveVar } from '@apollo/client';
import useApolloLazyQuery from '../../../hooks/useApolloLazyQuery';
import GQL_SALE from '../../../apollo/gql/sale';
import { filter } from '../../../apollo/cache';
import { currencyFormat, dateTimeFormat } from '../../../helpers';
import getSaleStatus from '../../../helpers/getSaleDeliveryStatus';
var useGetRents = function (props) {
    var filterVar = useReactiveVar(filter);
    var GET_SALES = useMemo(function () {
        return GQL_SALE.GET_SALES('clientCreatedAt folio customer calendarStart calendarEnd calendarAddress deliveryStatus total');
    }, []);
    var _a = useState([]), data = _a[0], setData = _a[1];
    var _b = useApolloLazyQuery(GET_SALES), get = _b[0], queryResult = _b[1];
    useEffect(function () {
        get({
            variables: __assign(__assign({}, filterVar), { category: [7], status: [1, 2, 3, 4], deliveryStatus: [props.status], limit: 0, order: ['Calendars.start', 'asc'], start: '1900-01-01' }),
        });
    }, [filterVar, props.status]);
    useEffect(function () {
        if (queryResult.data) {
            var data_1 = queryResult.data.rows.map(function (el) { return ({
                id: el.id,
                createdAt: dateTimeFormat(el.clientCreatedAt, 'dd/MM/yyyy HH:mm'),
                folio: el.folio,
                customer: el.customer,
                deliveryStatus: getSaleStatus(el.deliveryStatus),
                total: currencyFormat(el.total),
                start: el.calendarStart ? "".concat(el.calendarStart) : undefined,
                end: el.calendarEnd ? "".concat(el.calendarEnd) : undefined,
            }); });
            setData(data_1);
        }
    }, [queryResult.data]);
    return __assign(__assign({}, queryResult), { data: data });
};
export default useGetRents;
