var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useEffect, useRef, useState } from 'react';
import { useReactiveVar } from '@apollo/client';
import getDefaultAddProductRentValues from '../helpers/getDefaultValues';
import ProductFieldComponent from '../../../../../../presentationals/FormComponents/ProductField';
import TextFieldComponent from '../../../../../../presentationals/FormComponents/TextField';
import { inputError } from '../../../../../../apollo/cache';
import FooterDialogComponent from '../../../../../../presentationals/Dialog/Footer';
import ButtonFooterDialogComponent from '../../../../../../presentationals/Dialog/Footer/Button';
import SelectTaxBehaviorFieldComponent from '../../../../../../presentationals/FormComponents/SelectTaxBehaviorField';
import useApolloLazyQuery from '../../../../../../hooks/useApolloLazyQuery';
import GQL_PRODUCT from '../../../../../../apollo/gql/product';
import DateFieldComponent from '../../../../../../presentationals/FormComponents/DateField';
var BodyRentProductComponent = function (props) {
    var _a;
    var productFieldRef = useRef(null);
    var errors = useReactiveVar(inputError);
    var _b = useApolloLazyQuery(GQL_PRODUCT.GET_PRODUCT_BY_ID), getProduct = _b[0], loading = _b[1].loading;
    var defaultValues = getDefaultAddProductRentValues();
    var _c = useState(defaultValues), values = _c[0], setValues = _c[1];
    useEffect(function () {
        if (props.defaultValues)
            setValues(props.defaultValues);
    }, [props.defaultValues]);
    useEffect(function () {
        var f = function () { return __awaiter(void 0, void 0, void 0, function () {
            var data;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, getProduct({ variables: { id: values.productId } })];
                    case 1:
                        data = (_a.sent()).data;
                        setValues(function (values) { return (__assign(__assign({}, values), { unitPrice: (data === null || data === void 0 ? void 0 : data.price.toString()) || '' })); });
                        return [2 /*return*/];
                }
            });
        }); };
        if (values.productId && !props.defaultValues)
            f();
    }, [values.productId]);
    var handleProductChange = function (name, value) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            setValues(function (values) {
                var _a;
                return (__assign(__assign({}, values), (_a = {}, _a[name] = value, _a)));
            });
            return [2 /*return*/];
        });
    }); };
    var handleProductClear = function () {
        setValues(function (values) { return (__assign(__assign({}, values), { productId: null })); });
    };
    var handleChange = function (name, value) {
        setValues(function (values) {
            var _a;
            return (__assign(__assign({}, values), (_a = {}, _a[name] = value, _a)));
        });
    };
    var handleDateChange = function (name, value) {
        setValues(function (values) {
            var _a;
            return (__assign(__assign({}, values), (_a = {}, _a[name] = value, _a)));
        });
    };
    var handleButtonClick = function () {
        props.Button.onClick(values);
    };
    return (React.createElement("div", { className: 'flex flex-1 overflow-hidden flex-col' },
        React.createElement("div", { className: 'flex flex-1 overflow-auto' },
            React.createElement("div", { className: 'w-full sm:w-1/3 p-3' },
                React.createElement(ProductFieldComponent, { searchBy: 'select', ref: productFieldRef, name: 'productId', label: 'Producto', initialValue: (_a = props.defaultValues) === null || _a === void 0 ? void 0 : _a.productId, disabled: !!props.defaultValues || loading, onChange: handleProductChange, onClear: handleProductClear }),
                React.createElement(TextFieldComponent, { label: 'Cantidad', name: 'qty', disabled: loading, value: values.qty, onChange: handleChange, error: errors.qty, type: 'number' }),
                React.createElement(TextFieldComponent, { label: 'Precio unitario', name: 'unitPrice', disabled: loading, value: values.unitPrice, onChange: handleChange, error: errors.unitPrice, type: 'number' }),
                React.createElement(SelectTaxBehaviorFieldComponent, { value: values.taxesOption, onChange: handleChange }),
                props.allowCommentary && (React.createElement(TextFieldComponent, { label: 'Comentario', name: 'commentary', disabled: loading, value: values.commentary, onChange: handleChange, error: errors.commentary })),
                React.createElement(DateFieldComponent, { name: 'outDate', label: 'Fecha de salida', value: values.outDate || null, onChange: handleDateChange }))),
        React.createElement(FooterDialogComponent, null,
            props.DeleteButton ? (React.createElement(ButtonFooterDialogComponent, { color: props.DeleteButton.color, onClick: props.DeleteButton.onClick, loading: props.loading }, props.DeleteButton.label)) : (React.createElement("div", null)),
            React.createElement(ButtonFooterDialogComponent, { onClick: handleButtonClick, loading: props.loading }, props.Button.label))));
};
export default BodyRentProductComponent;
