import React from 'react';
import SelectFieldComponent from '../../../../../../../presentationals/FormComponents/SelectField';
var CreditNoteDevolutionTypeFieldComponent = function (props) {
    var devolutionTypeOptions = [
        { label: 'Por cantidad de unidades', value: '1' },
        { label: 'Por descuento a importe', value: '2' },
    ];
    return (React.createElement(SelectFieldComponent, { name: 'devolutionType', label: 'Tipo de devoluci\u00F3n', value: props.value, options: devolutionTypeOptions, onChange: props.onChange }));
};
export default CreditNoteDevolutionTypeFieldComponent;
