import React from 'react';
import { capitalize, currencyFormat } from '../../../../../helpers';
import ImageProductItemProductListComponent from './Image';
var ProductItemProductListComponent = function (props) {
    return (React.createElement("div", { className: 'flex flex-col text-white items-center py-2' },
        React.createElement("div", { className: 'flex flex-1 justify-center h-56 max-h-56 rounded overflow-hidden' },
            React.createElement(ImageProductItemProductListComponent, { fileId: props.fileId })),
        React.createElement("div", { className: 'flex flex-col text-center mt-2' },
            React.createElement("div", { className: 'text-[#ffe000] tracking-tighter leading-none font-medium' }, props.name),
            props.description && (React.createElement("div", { className: 'text-sm text-gray-100 leading-none mt-1' }, capitalize(props.description))),
            React.createElement("div", { className: 'text-white text-lg font-semibold' },
                "$",
                currencyFormat(props.price)))));
};
export default ProductItemProductListComponent;
