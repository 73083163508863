import React, { useEffect, useState } from 'react';

import { useReactiveVar } from '@apollo/client';

import TableSortLabel from '@mui/material/TableSortLabel';

import { sort, filter } from '../../../../apollo/cache';

const TableSortLabelComponent = props => {
  const [direction, setDirection] = useState('asc');

  const sortVar = useReactiveVar(sort);
  const filterVar = useReactiveVar(filter);

  useEffect(() => {
    if (sortVar.key && sortVar.key !== props.id) setDirection('asc');
  }, [sortVar]);

  const handleSort = () => {
    if (direction === 'asc') {
      sort({ direction: 'desc', key: props.id });
      filter({ ...filterVar, order: [props.id, 'desc'] });
      setDirection('desc');
    }
    if (direction === 'desc') {
      sort({ direction: 'asc', key: props.id });
      filter({ ...filterVar, order: [props.id, 'asc'] });
      setDirection('asc');
    }
  };
  return (
    <TableSortLabel active direction={direction} onClick={handleSort}>
      <strong>{props.label}</strong>
    </TableSortLabel>
  );
};

export default TableSortLabelComponent;
