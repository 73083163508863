import React, { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import { Popover } from '@mui/material';
import SearchCustomerGlobalInvoiceFieldComponent from './TextField';
import TableCustomerGlobalInvoiceComponent from './Table';
import useGetSession from '../../../../../hooks/useGetSession';
var SearchCustomerSalesGlobalInvoiceFieldComponent = forwardRef(function (props, ref) {
    var _a;
    var searchCustomerGlobalInvoiceField = useRef(null);
    var tableCustomerGlobalInvoiceRef = useRef(null);
    var _b = useState(null), anchorEl = _b[0], setAnchorEl = _b[1];
    var session = useGetSession();
    var customerLabel = ((_a = session === null || session === void 0 ? void 0 : session.Company) === null || _a === void 0 ? void 0 : _a.activity) === 3 ? 'paciente' : 'cliente';
    var handleOpen = function (anchorEl) {
        setAnchorEl(anchorEl);
    };
    var handleClose = function () {
        setAnchorEl(null);
    };
    var handleChange = function (searchBy, value) {
        var _a;
        (_a = tableCustomerGlobalInvoiceRef.current) === null || _a === void 0 ? void 0 : _a.search(searchBy, value);
    };
    var handleLoading = function (loading) {
        var _a;
        (_a = searchCustomerGlobalInvoiceField.current) === null || _a === void 0 ? void 0 : _a.setLoading(loading);
    };
    var handleSelect = function (name, id) {
        props.onChange(name, id);
        setAnchorEl(null);
    };
    useImperativeHandle(ref, function () { return ({
        open: handleOpen,
    }); });
    return (React.createElement(Popover, { id: 'SearchCustomerPopover', open: !!anchorEl, anchorEl: anchorEl, onClose: handleClose, anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'right',
        }, transformOrigin: {
            vertical: 'top',
            horizontal: 'right',
        }, slotProps: {
            paper: {
                className: 'w-full sm:w-96',
            },
        } },
        React.createElement("div", { className: 'flex flex-1 flex-col' },
            React.createElement("div", { className: 'flex flex-col p-2 pt-1 border-0 border-b border-solid border-gray-300' },
                React.createElement("div", { className: 'text-base font-medium text-sky-600 mb-1' },
                    "B\u00FAsqueda del ",
                    customerLabel),
                React.createElement(SearchCustomerGlobalInvoiceFieldComponent, { ref: searchCustomerGlobalInvoiceField, onChange: handleChange })),
            React.createElement(TableCustomerGlobalInvoiceComponent, { onLoading: handleLoading, onSelect: handleSelect, ref: tableCustomerGlobalInvoiceRef, disableAllCustomers: props.disableAllCustomers }))));
});
export default SearchCustomerSalesGlobalInvoiceFieldComponent;
