import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import OpenAddProductPromotionDialogButton from './OpenAddProductPromotionDialogButton';
import ItemProductTableEditPromotionComponent from './Item';
import useApolloLazyQuery from '../../../../hooks/useApolloLazyQuery';
import GQL_PROMOTION from '../../../../apollo/gql/promotion';
import LoadingProgress from '../../../../presentationals/LoadingProgress';
import ErrorDataComponent from '../../../../presentationals/ErrorData';
import CreateProductPromotionPriceComponent from '../../CreateProductPromotionPrice';
import AppProductPromotionComponent from '../../AddProductPromotion';
import EditProductPromotionComponent from '../../EditProductPromotion';
import EditProductPromotionPriceComponent from '../../EditProductPromotionPrice';
var ContentEditPromotionComponent = function () {
    var params = useParams();
    var _a = useApolloLazyQuery(GQL_PROMOTION.GET_BY_ID), getById = _a[0], _b = _a[1], loading = _b.loading, data = _b.data;
    useEffect(function () {
        if (params.promotionId)
            getById({ variables: { id: params.promotionId } });
    }, [params]);
    if (loading)
        return React.createElement(LoadingProgress, null);
    if (!data)
        return React.createElement(ErrorDataComponent, null);
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: 'p-3 flex flex-1 flex-col' },
            React.createElement("div", { className: 'flex items-center' },
                React.createElement("div", { className: 'text-base font-medium mb-1 flex flex-1' }, "Contenido de la promoci\u00F3n"),
                React.createElement(OpenAddProductPromotionDialogButton, null)),
            React.createElement("div", { className: 'text-sm sm:text-base text-gray-600' }, "Aqu\u00ED tienes el control total para mejorar tus promociones, podr\u00E1s a\u00F1adir nuevos productos a la promoci\u00F3n y realizar ajustes a los ya registrados. Adem\u00E1s, disfruta de la flexibilidad de modificar los precios seg\u00FAn la plataforma digital y la sucursal."),
            React.createElement("div", { className: 'pt-4 flex flex-1 flex-col basis-0 overflow-auto w-full sm:w-2/3' },
                React.createElement(TableContainer, { className: 'rounded border border-solid border-[#d8d8d8]' },
                    React.createElement(Table, { stickyHeader: true, size: 'small' },
                        React.createElement(TableHead, null,
                            React.createElement(TableRow, { className: 'bg-[#f6f6f6]' },
                                React.createElement(TableCell, null, "Producto"),
                                React.createElement(TableCell, null, "Presentaci\u00F3n"),
                                React.createElement(TableCell, { align: 'center' }, "Cantidad"),
                                React.createElement(TableCell, null, "Precio est\u00E1ndar"))),
                        React.createElement(TableBody, null, data.ProductPromotions.map(function (el, i) { return (React.createElement(ItemProductTableEditPromotionComponent, { key: el.id, index: i, ProductPromotion: el })); })))))),
        React.createElement(CreateProductPromotionPriceComponent, null),
        React.createElement(EditProductPromotionPriceComponent, null),
        React.createElement(EditProductPromotionComponent, null),
        React.createElement(AppProductPromotionComponent, null)));
};
export default ContentEditPromotionComponent;
