import React, { useRef } from 'react';
import ButtonFooterDialogComponent from '../../../../../presentationals/Dialog/Footer/Button';
import getInputErrorFormat from '../../../../sale.v2/Action/helpers/getInputErrorFormat';
import ConfirmAdjustInventoryDialogComponent from '../../ConfirmDialog';
var CreateAdjustInventoryButtonComponent = function (props) {
    var confirmAdjustInventoryDialogRef = useRef(null);
    var handleClick = function () {
        var _a;
        try {
            (_a = confirmAdjustInventoryDialogRef.current) === null || _a === void 0 ? void 0 : _a.open();
        }
        catch (error) {
            getInputErrorFormat(error);
        }
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(ButtonFooterDialogComponent, { onClick: handleClick }, "Almacenar ajuste"),
        React.createElement(ConfirmAdjustInventoryDialogComponent, { onCreated: props.onCreated, onCreate: props.onClick, ref: confirmAdjustInventoryDialogRef })));
};
export default CreateAdjustInventoryButtonComponent;
