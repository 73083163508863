import { useMutation } from '@apollo/client';
import getInputErrorFormat from '../containers/sale.v2/Action/helpers/getInputErrorFormat';
import getResponseWithData from '../containers/sale.v2/ConfirmDialog/Footer/CreateButton/helpers/getResponseWithData';

import GQL from '../containers/sale.v2/ConfirmDialog/Footer/CreateButton/_gql';
import getQueryName from '../helpers/getQueryName';

const useSaleV2Mutation = () => {
  const [f, { data, loading, error }] = useMutation(GQL.CREATE_SALE, {
    update(cache, { data }) {
      try {
        const response = getResponseWithData(data.finalizeCart);
        if (!response) return;
        const queryName = getQueryName(response[0]);
        if (!queryName) return;
        cache.modify({
          fields: {
            [queryName[0]](existingRefs) {
              const newRef = cache.writeFragment({
                data: { ...response[1] },
                fragment: GQL[queryName[1]],
              });
              return {
                ...existingRefs,
                count: parseInt(existingRefs.count) + 1,
                rows: [newRef, ...existingRefs.rows],
              };
            },
          },
        });
      } catch (e) {
        getInputErrorFormat(e);
      }
    },
  });

  return [f, { data, loading, error }];
};

export default useSaleV2Mutation;
