import React from 'react';
import useGetSaleByParamSaleId from '../../../../hooks/useGetSaleByParamSaleId';
import LoadingProgress from '../../../../presentationals/LoadingProgress';
import BodyGeneralRentComponent from '../../../rent/Item/General/Body';
var GeneralServiceOrderComponent = function () {
    var _a = useGetSaleByParamSaleId(), data = _a.data, loading = _a.loading;
    if (loading || !data)
        return React.createElement(LoadingProgress, null);
    return (React.createElement("div", { className: 'flex flex-1 flex-col' },
        React.createElement(BodyGeneralRentComponent, { Sale: data, createXMLProductsAllowed: true })));
};
export default GeneralServiceOrderComponent;
