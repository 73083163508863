import React from 'react';
import GeneralCompanyComponent from '../containers/company/Edit/General';
import ImageCompanyComponent from '../containers/company/Edit/Image';
import EcommerceCompanyComponent from '../containers/company/Edit/Ecommerce';
import QueueManagerCompanyComponent from '../containers/company/Edit/QueueManager';
import AllowedCompanyModulesComponent from '../containers/company/Edit/AllowedModules';
import TwilioWhatsappApiComponent from '../containers/company/Edit/TwilioWhatsappApi';
var getCompanyChildrenRoutes = function () {
    return [
        { index: true, element: React.createElement(GeneralCompanyComponent, null) },
        {
            path: 'image',
            element: React.createElement(ImageCompanyComponent, null),
        },
        {
            path: 'ecommerce',
            element: React.createElement(EcommerceCompanyComponent, null),
        },
        {
            path: 'queueManager',
            element: React.createElement(QueueManagerCompanyComponent, null),
        },
        {
            path: 'allowedModules',
            element: React.createElement(AllowedCompanyModulesComponent, null),
        },
        {
            path: 'twilioWhatsappApi',
            element: React.createElement(TwilioWhatsappApiComponent, null),
        },
    ];
};
export default getCompanyChildrenRoutes;
