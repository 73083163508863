import React, { useState } from 'react';

import Typography from '@mui/material/Typography';

import FilterDrawer from '../../presentationals/FilterDrawer.v2';
import SelectField from '../../presentationals/FormComponents/SelectField';

import { filter } from '../../apollo/cache';

const FilterDialog = () => {
  const [values, setValues] = useState({ status: '' });

  const handleAction = (e, data) => {
    filter({ status: values.status || null, branchId: data.branchId });
  };

  const handleChange = (name, value) => {
    setValues(values => ({ ...values, [name]: value }));
  };

  return (
    <FilterDrawer handleAction={handleAction} showDates={false} showBranches>
      <Typography variant='subtitle1'> Estatus: </Typography>
      <SelectField
        name='status'
        variant='outlined'
        onChange={handleChange}
        size='medium'
        value={values.status}
        options={[
          { value: 0, label: 'Sin Código' },
          { value: 1, label: 'Vigente' },
          { value: 2, label: 'Expirado' },
          { value: 3, label: 'Finalizado' },
        ]}
      />
    </FilterDrawer>
  );
};

export default FilterDialog;
