import React from 'react';
import useGetTaxRegime from '../../../../../../../../../hooks/useGetTaxRegime';
import SelectFieldComponent from '../../../../../../../../../presentationals/FormComponents/SelectField';
var TaxRegimeSelectFieldHelpersBodyCreateFormCustomerActionSaleV2Component = function (props) {
    var getUseTaxRegime = useGetTaxRegime();
    var handleChange = function (name, value) {
        props.onChange(name, value);
    };
    if (getUseTaxRegime.length === 0)
        return React.createElement("div", null);
    return (React.createElement(SelectFieldComponent, { label: 'Regimen fiscal', name: 'taxRegime', value: props.value, onChange: handleChange, options: getUseTaxRegime }));
};
export default TaxRegimeSelectFieldHelpersBodyCreateFormCustomerActionSaleV2Component;
