import React from 'react';
import LinkMenuDashboardComponent from '../../helpers/Link';
import useGetSession from '../../../../../../hooks/useGetSession';
var InventoryManagementLinkBlockThreeMenuDashboardComponent = function () {
    var _a, _b;
    var session = useGetSession();
    (_a = session.Company) === null || _a === void 0 ? void 0 : _a.id;
    return (React.createElement(LinkMenuDashboardComponent, { url: ['181', '114', '213', '112', '4'].includes(((_b = session.Company) === null || _b === void 0 ? void 0 : _b.id.toString()) || '')
            ? 'report/inventory'
            : 'report/product/inventory', primary: 'Gesti\u00F3n del inventario de mercanc\u00EDa', secondary: 'Accede y administra el inventario de la mercanc\u00EDa de la empresa, obt\u00E9n un control de las existencias y da seguimiento a las entradas y salidas.' }));
};
export default InventoryManagementLinkBlockThreeMenuDashboardComponent;
