var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React, { useEffect, useState } from 'react';
var SummarySurveyResultsComponent = function (props) {
    var _a = useState([]), Summary = _a[0], setSummary = _a[1];
    useEffect(function () {
        if (props.data) {
            var defaultSummary = [];
            var FilteredSurveys = props.data.reduce(function (array, el) {
                var SurveyCustomerAnswers = el.SurveyCustomerAnswers.filter(function (elCh) {
                    return [2, 3].includes(elCh.questionType);
                });
                return __spreadArray(__spreadArray([], array, true), SurveyCustomerAnswers, true);
            }, defaultSummary);
            var SurveysSummary = FilteredSurveys.reduce(function (array, el) {
                var isInArray = array.find(function (elCh) { return elCh.surveyQuestionId === el.surveyQuestionId; });
                if (isInArray)
                    return array.map(function (elCh) {
                        return elCh.surveyQuestionId === el.surveyQuestionId
                            ? __assign(__assign({}, elCh), { answer: (parseFloat(elCh.answer) + parseFloat(el.answer)).toString() }) : elCh;
                    });
                return __spreadArray(__spreadArray([], array, true), [el], false);
            }, defaultSummary);
            setSummary(SurveysSummary.map(function (el) { return (__assign(__assign({}, el), { answer: (parseFloat(el.answer) / props.data.length).toFixed(2).toString() })); }));
        }
    }, [props.data]);
    if (Summary.length === 0)
        return React.createElement("div", null, "De momento no cuentas con encuestas aplicadas.");
    return (React.createElement("div", { className: 'flex flex-1 flex-col rounded border-solid border-slate-300 px-4 py-3 overflow-auto' }, Summary.map(function (el, i) { return (React.createElement("div", { key: i, className: "flex flex-col ".concat(i === Summary.length - 1
            ? ''
            : 'border-0 border-b border-solid border-slate-300 pb-2 mb-2') },
        React.createElement("div", null, el.question),
        React.createElement("div", { className: 'font-medium' }, el.answer))); })));
};
export default SummarySurveyResultsComponent;
