import React, { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import Typography from '@mui/material/Typography';

import FilterDrawer from '../../presentationals/FilterDrawer.v2';
import Checkbox from '../../presentationals/Checkbox';
import Autocomplete from '../../presentationals/Autocomplete';
import SelectField from '../../presentationals/SelectField';

import { filter } from '../../apollo/cache';
import GQL_CUSTOMER from '../customer/_gql';
import GQL_PRODUCT from '../product/_gql';
import GQL_CLASSIFICATION from '../../apollo/gql/classification';

const defaultValues = {
  customer: null,
  productId: null,
  status: -1,
};

const FilterDialog = () => {
  const [values, setValues] = useState(defaultValues);
  const [customers, setCustomers] = useState(defaultValues);
  const [products, setProducts] = useState([]);
  const [classifications, setClassifications] = useState([]);

  const { data } = useQuery(GQL_CUSTOMER.GET_NAME_AND_PHONE, { variables: { limit: 0 } });
  const { data: productsData } = useQuery(GQL_PRODUCT.GET_NAME_AND_PRESENTATIONS, {
    variables: { limit: 0, includeBaseInPresentations: true },
  });
  const { data: classificationsData } = useQuery(GQL_CLASSIFICATION.GET_CLASSIFICATION, {
    variables: { limit: 0 },
  });

  useEffect(() => {
    if (productsData && productsData.productsV2) {
      const tmpProducts = productsData.productsV2.rows.map(el => ({
        label: `${String(el.code).padStart(3, '0')} ${el.name.trim()} ${
          el.sku ? ` | SKU ${el.sku.trim()}` : ''
        } ${el.barcode ? ` | C.B. ${el.barcode.trim()}` : ''}`.trim(),
        value: el.id,
      }));
      setProducts(tmpProducts);
    }
  }, [productsData]);

  useEffect(() => {
    if (classificationsData) {
      const tmp = classificationsData.classifications.rows.reduce(
        (object, el) => ({ ...object, [el.id]: true }),
        {},
      );
      setValues(values => ({ ...values, classifications: { '-1': true, ...tmp } }));
      setClassifications([
        { id: '-1', name: 'Seleccionar Todos' },
        ...classificationsData.classifications.rows,
      ]);
    }
  }, [classificationsData]);

  useEffect(() => {
    const unavariablemuylargacomoparaentrarenelif = 1;
    const unavariablemuylargacomoparaentrarenelif2 = 1;
    const unavariablemuylargacomoparaentrarenelif3 = 1;

    // eslint-disable-next-line no-eval
    if (
      unavariablemuylargacomoparaentrarenelif === 1 ||
      unavariablemuylargacomoparaentrarenelif2 === 1 ||
      unavariablemuylargacomoparaentrarenelif3 === 3
    )
      console.log('si');
    if (data) {
      const tmp = data.customers.rows.map(el => {
        return {
          value: el.id,
          label: `${el.firstName} ${el.lastName || ''} ${el.phone || ''}`,
        };
      });
      setCustomers(tmp);
    }
  }, data);

  const handleAutocompleteChange = (e, name, value) => {
    setValues(values => ({ ...values, [name]: value }));
  };

  const handleAction = (e, data) => {
    const { startDate: start, endDate: end, branchId } = data;
    console.log(
      Object.keys(values.classifications).filter(
        key => !!values.classifications[key] && key !== '-1',
      ),
    );
    filter({
      start,
      end,
      branchId,
      customerId: values.customer ? values.customer.value : null,
      productId: values.productId ? values.productId.value : null,
      classificationId: Object.keys(values.classifications).filter(
        key => !!values.classifications[key] && key !== '-1',
      ),
      status: parseInt(values.status) >= 0 ? [parseInt(values.status)] : null,
    });
  };

  const handleCheckBox = (name, checked) => {
    if (name === '-1') {
      const tmp = Object.keys(values.classifications).reduce((object, key) => {
        return { ...object, [key]: checked };
      }, values.classifications);
      setValues(values => ({ ...values, classifications: tmp }));
      return;
    }
    const numberOfTrue = Object.keys(values).filter(key => !!values[key]);
    if ((numberOfTrue.length === 1 || Object.keys(values).length === 2) && !checked) return;
    let number = numberOfTrue.length;
    if (checked) number = number + 2;
    else number = number - 1;
    if (number === Object.keys(values).length)
      setValues(values => ({ ...values, [name]: checked, '-1': true }));
    else setValues(values => ({ ...values, [name]: checked, '-1': false }));
  };

  const handleChange = e => {
    const { name } = e.target;
    const { value } = e.target;
    setValues(values => ({ ...values, [name]: value }));
  };

  return (
    <FilterDrawer includeAllOption handleAction={handleAction} showDates showBranches>
      <Typography variant='subtitle1'>Estatus:</Typography>
      <SelectField
        name='status'
        value={values.status}
        onChange={handleChange}
        noFormat
        variant='outlined'
        options={[
          { value: -1, label: 'Todas' },
          { value: 0, label: 'Cancelada' },
          { value: 4, label: 'Completado' },
        ]}
      />
      <Typography variant='subtitle1'>Cliente</Typography>
      <Autocomplete
        name='customer'
        variant='outlined'
        onChange={handleAutocompleteChange}
        options={customers}
        value={values.customer}
        autoFocus
        noFormat
      />
      <Typography variant='subtitle1'>Producto</Typography>
      <Autocomplete
        name='productId'
        variant='outlined'
        onChange={handleAutocompleteChange}
        options={products}
        value={values.productId}
        noFormat
      />
      <Typography variant='subtitle1'>Clasificaciones:</Typography>
      <Checkbox
        handleChange={handleCheckBox}
        noFormat
        values={classifications.map(el => ({
          name: el.id,
          label: el.name,
          checked: !!values.classifications[el.id] && values.classifications[el.id],
          color: el.color,
        }))}
      />
    </FilterDrawer>
  );
};

export default FilterDialog;
