import React from 'react';

import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';

const Component = ({ params, action, ...props }) => {
  const handleClick = e => action(e, params);

  return (
    <Tooltip title={props.label}>
      <span>
        <IconButton onClick={handleClick} aria-label={props.label} {...props} size='large'>
          {props.icon}
        </IconButton>
      </span>
    </Tooltip>
  );
};

export default Component;
