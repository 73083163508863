import React, { useEffect, useState } from 'react';
import { Tabs, Tab } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import RepartitionIcon from '@mui/icons-material/Repartition';
import ChecklistRtlIcon from '@mui/icons-material/ChecklistRtl';
import DeliveryDiningIcon from '@mui/icons-material/DeliveryDining';
import AddCardIcon from '@mui/icons-material/AddCard';
import TocIcon from '@mui/icons-material/Toc';
import getMenuValueFromPath from './getMenuValueFromPath';
import getRouteFromMenuValue from './getRouteFromMenuValue';
import useGetSaleByParamSaleId from '../../../../hooks/useGetSaleByParamSaleId';
var MenuItemRentLayoutComponent = function () {
    var _a = useGetSaleByParamSaleId(), data = _a.data, loading = _a.loading;
    var navigate = useNavigate();
    var location = useLocation();
    var _b = useState(0), value = _b[0], setValue = _b[1];
    useEffect(function () {
        if (data) {
            var value_1 = getMenuValueFromPath(location.pathname, data.deliveryStatus);
            setValue(value_1);
            if ([4, 5].includes(data.deliveryStatus)) {
                var route = getRouteFromMenuValue(data.deliveryStatus - 1);
                if (route)
                    navigate(route);
            }
        }
    }, [data]);
    var handleChange = function (e, value) {
        setValue(value);
        var route = getRouteFromMenuValue(value);
        if (route)
            navigate(route);
    };
    return (React.createElement(Tabs, { value: value, onChange: handleChange, variant: 'scrollable', scrollButtons: 'auto', className: 'flex bg-slate-100 border-0 border-y border-[#d6d6d6] border-solid' },
        React.createElement(Tab, { disabled: loading, icon: React.createElement(RepartitionIcon, null), label: 'Datos generales' }),
        React.createElement(Tab, { disabled: loading, icon: React.createElement(TocIcon, null), label: 'Contenido' }),
        React.createElement(Tab, { disabled: loading, icon: React.createElement(DeliveryDiningIcon, null), label: 'Entrega de productos' }),
        React.createElement(Tab, { disabled: loading, icon: React.createElement(ChecklistRtlIcon, null), label: 'Recolecci\u00F3n de productos' }),
        data && data.deliveryStatus === 5 && (React.createElement(Tab, { icon: React.createElement(AddCardIcon, null), label: 'Cobro de faltantes' }))));
};
export default MenuItemRentLayoutComponent;
