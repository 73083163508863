import React, { useEffect } from 'react';

import { useReactiveVar } from '@apollo/client';

import { exportCustomXls, filter } from '../../apollo/cache';

import XLS from './xls.doc';

const XlsExportComponent = () => {
  const exportXlsVar = useReactiveVar(exportCustomXls);
  const filterVar = useReactiveVar(filter);

  useEffect(() => {
    if (exportXlsVar) {
      XLS(filterVar);
      exportCustomXls(false);
    }
  }, [exportXlsVar]);

  return <div />;
};

export default XlsExportComponent;
