import React from 'react';
import BalanceDetailedReportComponent from './Balance';
import TransactionDetailedReportComponent from './Transaction';
import DetailSaleDetailedReportComponent from './Sale';
import DetailedReportLayoutComponent from '../../../routes/CompanyLayout/DetailedReportLayout';
import CashFlowDetailedReportComponent from './CashFlow';
var DetailedReportComponent = function () {
    return (React.createElement(React.Fragment, null,
        React.createElement(DetailedReportLayoutComponent, null),
        React.createElement("div", { className: 'w-full h-full flex flex-1 flex-col p-2 sm:flex-row overflow-auto whitespace-nowrap' },
            React.createElement("div", { className: 'block sm:flex sm:flex-col sm:flex-1 sm:mr-3 overflow-hidden' },
                React.createElement(BalanceDetailedReportComponent, null),
                React.createElement(TransactionDetailedReportComponent, null),
                React.createElement(CashFlowDetailedReportComponent, null)),
            React.createElement("div", { className: 'block sm:flex sm:flex-col sm:flex-1 overflow-hidden' },
                React.createElement(DetailSaleDetailedReportComponent, null)))));
};
export default DetailedReportComponent;
