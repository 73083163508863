import { gql } from '@apollo/client';

const GET = gql`
	query ProductMovementLogReport($name: String, $limit: Int, $offset: Int, $start: Date, $end: Date, $productId: ID, $branchId: ID $classificationId: [ID]) {
		productMovementLogReport(like: $name, limit: $limit, offset: $offset, start: $start, end: $end, productId: $productId, branchId: $branchId classificationId: $classificationId) {
			count
			rows {
				id
				Product {
					id
					name
					code
					UnitBase {
						name
					}
					PresentationBase {
						name
					}
					Classification {
						name
					}
				}
				start
				input
				output
				finish
				costTotal
			}
		}
	}
`;

export default { GET };
