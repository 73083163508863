import React, { useState, useEffect } from 'react';

import { useQuery, useReactiveVar } from '@apollo/client';

import AddIcon from '@mui/icons-material/AddOutlined';
import CloseIcon from '@mui/icons-material/CloseOutlined';

import Grid from '@mui/material/Grid';

import Autocomplete from '../../../../presentationals/FormComponents/Autocomplete';
import TextField from '../../../../presentationals/FormComponents/TextField';
import DateField from '../../../../presentationals/FormComponents/DateField';
import SelectField from '../../../../presentationals/FormComponents/SelectField';
import IconButton from '../../../../presentationals/IconButton';

import { clearForm, form, step, handleContinue } from '../../../../apollo/cache';

import { capitalize } from '../../../../helpers';

import GQL_PAYMENT_METHOD from '../../../paymentMethod/_gql';

const defaultValues = {
  paymentMethodId: -1,
  currency: 'MXN',
  exchangeRate: 1,
  transactionAccount: null,
  newTransactionAccount: '',
  date: new Date(),
};

const currencyOptions = [
  { value: 'MXN', label: 'Pesos Mexicanos (MXN)' },
  { value: 'USD', label: 'Dólares Americanos (USD)' },
];

const Component = props => {
  const clearFormVar = useReactiveVar(clearForm);
  const stepVar = useReactiveVar(step);
  const handleContinueVar = useReactiveVar(handleContinue);

  const [values, setValues] = useState(defaultValues);
  const [errors, setErrors] = useState({});
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [transactionAccounts, setTransactionAccounts] = useState([]);
  const [newTrasactionAccount, setNewTransactionAccount] = useState(false);

  const { data } = useQuery(GQL_PAYMENT_METHOD.GET_AUTOCOMPLETE, {
    variables: { limit: 0 },
  });
  const { data: dataTransactionAccounts } = useQuery(GQL_PAYMENT_METHOD.GET_TRANSACTION_ACCOUNTS);

  const mainFormValidation = () => {
    try {
      setErrors({});
      if (
        values.currency !== 'MXN' &&
        (Number.isNaN(parseFloat(values.exchangeRate)) || parseFloat(values.exchangeRate) <= 0)
      ) {
        const totalError = new Error('El precio del dólar es inválido.');
        totalError.name = 'exchangeRate';
        totalError.input = true;
        throw totalError;
      }

      step(stepVar + 1);
      form(values);
    } catch (e) {
      setErrors({ [e.name]: e.message });
    }
  };

  useEffect(() => {
    if (stepVar === 0 && handleContinueVar) {
      mainFormValidation();
      handleContinue(false);
    }
  }, [stepVar, handleContinueVar]);

  useEffect(() => {
    if (clearFormVar && stepVar === 0) {
      setValues(defaultValues);
      form({});
      clearForm(false);
    }
  }, [clearFormVar]);

  useEffect(() => {
    if (dataTransactionAccounts) {
      const tmpTransactionAccounts = dataTransactionAccounts.transactionAccounts.rows.map(el => ({
        value: el.id,
        label: el.name,
      }));
      setTransactionAccounts(tmpTransactionAccounts);
    }
  }, [dataTransactionAccounts]);

  useEffect(() => {
    if (data) {
      const tmp = data.paymentMethods.rows.map(tmp => ({
        value: tmp.id,
        label: capitalize(tmp.name),
      }));
      setPaymentMethods([{ value: -1, label: 'EFECTIVO' }, ...tmp]);
    }
  }, [data]);

  useEffect(() => {
    if (props.accountReceivableId)
      getTransactions({ variables: { accountReceivableId: props.accountReceivableId } });
  }, [props.accountReceivableId]);

  const handleChange = (name, value) => {
    setValues(values => ({ ...values, [name]: value }));
  };

  const handleAddTransaction = () => {
    setNewTransactionAccount(newTrasactionAccount => !newTrasactionAccount);
  };

  const handleDateChange = (name, value) => {
    setValues(values => ({ ...values, [name]: value }));
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} md={5}>
          <SelectField
            value={values.currency}
            error={errors.currency}
            name='currency'
            label='Moneda'
            onChange={handleChange}
            options={currencyOptions}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12} md={5}>
          {values.currency !== 'MXN' && (
            <TextField
              value={values.exchangeRate}
              error={errors.exchangeRate}
              name='exchangeRate'
              label='Precio del Dólar'
              onChange={handleChange}
              type='number'
            />
          )}
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12} md={5}>
          <DateField
            noFormat
            size='small'
            variant='outlined'
            name='date'
            label='Fecha de Pago'
            onChange={handleDateChange}
            value={values.date}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12} md={5}>
          <SelectField
            value={values.paymentMethodId}
            error={errors.paymentMethodId}
            name='paymentMethodId'
            label='Método de Pago'
            onChange={handleChange}
            options={paymentMethods}
          />
        </Grid>
      </Grid>
      <Grid container alignItems='flex-end' spacing={2}>
        <Grid item xs={11} md={5}>
          {newTrasactionAccount ? (
            <TextField
              name='newTransactionAccount'
              label='¿Cuál será el nombre de la nueva cuenta?'
              value={values.newTransactionAccount}
              onChange={handleChange}
            />
          ) : (
            <Autocomplete
              name='transactionAccount'
              label='Cuenta (Opcional)'
              value={values.transactionAccount}
              options={transactionAccounts}
              onChange={handleChange}
            />
          )}
        </Grid>
        <Grid item xs={1} md={4}>
          <IconButton
            color='secondary'
            icon={
              newTrasactionAccount ? <CloseIcon fontSize='small' /> : <AddIcon fontSize='small' />
            }
            action={handleAddTransaction}
            size='large'
          />
        </Grid>
      </Grid>
    </>
  );
};

export default Component;
