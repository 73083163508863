import React from 'react';

import { DialogTitle as DialogTitleMui } from '@mui/material';

import { styled } from '@mui/system';

import CloseButton from './CloseButton';

const DialogTitle = styled(DialogTitleMui)(({ theme }) => ({
  '&.root': {
    padding: theme.spacing(2),
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
}));

const HeaderSearchDialogProductKeyFieldHelpersBodyCreateProductDialogProductFormChooseProductSaleV2Component = () => {
    return (
      <DialogTitle className='root'>
        <div>Búsqueda de clave de producto y servicio (SAT)</div>
        <div>
          <CloseButton />
        </div>
      </DialogTitle>
    );
  };
export default HeaderSearchDialogProductKeyFieldHelpersBodyCreateProductDialogProductFormChooseProductSaleV2Component;
