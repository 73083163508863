import React from 'react';
import { useReactiveVar } from '@apollo/client';
import { inputError, isCreateRateOpen } from '../../../../../apollo/cache';
import DialogComponent from '../../../../../presentationals/Dialog';
import BodyCreateNameRateComponent from './Body';
var CreateNameRateDialogComponent = function () {
    var isOpen = useReactiveVar(isCreateRateOpen);
    var handleClose = function () {
        inputError({});
        isCreateRateOpen(false);
    };
    return (React.createElement(DialogComponent, { fullScreen: true, open: isOpen, title: 'Nuevo nombre de tarifa', onClose: handleClose },
        React.createElement(BodyCreateNameRateComponent, null)));
};
export default CreateNameRateDialogComponent;
