import React from 'react';

import { useReactiveVar } from '@apollo/client';

import { List } from '@mui/material';

import { productListSaleV2 } from '../../../../../../apollo/cache';

import Item from './Item';

const TableSearchFormCustomerActionSaleV2Component = () => {
  const productListSaleV2Var = useReactiveVar(productListSaleV2);
  return (
    <List component='nav' aria-labelledby='nested-customer-list'>
      {productListSaleV2Var.map((el, i) => (
        <Item key={el.id} {...el} background={i % 2 > 0 ? 'bg-inherit' : 'bg-gray-100'} />
      ))}
    </List>
  );
};

export default TableSearchFormCustomerActionSaleV2Component;
