import React from 'react';
import ApplySurveyComponent from '../containers/surveys/ApplySurvey';
import SurveyResultsComponent from '../containers/surveys/SurveyResults';
import EditSurveyComponent from '../containers/surveys/Edit';
var getSurveyChildrenRoutes = function () {
    return [
        { index: true, element: React.createElement(ApplySurveyComponent, null) },
        { path: 'results', element: React.createElement(SurveyResultsComponent, null) },
        { path: 'edit', element: React.createElement(EditSurveyComponent, null) },
    ];
};
export default getSurveyChildrenRoutes;
