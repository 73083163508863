var getCashClosingMenuValueFromPath = function (pathname) {
    var pathnameParts = pathname.split('/');
    var path = pathnameParts[pathnameParts.length - 1];
    switch (path) {
        case 'general':
            return 0;
        case 'totalbyfolio':
            return 1;
        case 'totalbyclassification':
            return 2;
        case 'totalbyproduct':
            return 3;
        case 'totalbyemployee':
            return 4;
        default:
            return 0;
    }
};
export default getCashClosingMenuValueFromPath;
