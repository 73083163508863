var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React, { useEffect, useState } from 'react';
import { useReactiveVar } from '@apollo/client';
import { Card } from '@mui/material';
import CompanyButton from './CompanyButton';
import HeaderModuleV3Component from '../../../presentationals/Module.v3/Header';
import useApolloQuery from '../../../hooks/useApolloQuery';
import LoadingProgress from '../../../presentationals/LoadingProgress';
import { filter } from '../../../apollo/cache';
import { objectArraySort } from '../../../helpers';
import CreateCompanyComponent from '../../company/Create';
import AppbarComponent from '../Menu/Appbar';
import GQL_SESSION from '../../../apollo/gql/session';
import CopyrightComponent from '../../../presentationals/Module.v2/Copyright';
var ChooseCompanyComponent = function () {
    var filterVar = useReactiveVar(filter);
    var _a = useState([]), companies = _a[0], setCompanies = _a[1];
    var _b = useApolloQuery(GQL_SESSION.GET_BRANCH_SESSION), data = _b.data, loading = _b.loading;
    useEffect(function () {
        if (data) {
            var sortedCompanies = objectArraySort(__spreadArray([], data, true), 'companyName');
            var like_1 = filterVar.like ? filterVar.like[1].toLowerCase() : '';
            var filteredCompanies = sortedCompanies.filter(function (el) {
                return el.companyName.toLowerCase().includes(like_1);
            });
            setCompanies(filteredCompanies);
        }
    }, [data, filterVar]);
    return (React.createElement("div", { className: 'flex flex-1 flex-col h-screen' },
        React.createElement(AppbarComponent, null),
        React.createElement("div", { className: 'flex flex-1 flex-col bg-gray-50 py-3 px-4' },
            React.createElement(HeaderModuleV3Component, { title: 'Empresas', hideMenu: true, isLoading: loading }),
            React.createElement(Card, { className: 'mt-2 flex flex-1 basis-0 overflow-hidden' }, loading ? (React.createElement(LoadingProgress, null)) : (React.createElement("div", { className: 'flex flex-1 flex-wrap content-start overflow-y-auto px-2' }, companies.map(function (el) { return (React.createElement(CompanyButton, { key: el.companyId, id: el.companyId, name: el.companyName, role: el.Role.name, activity: el.activity, logoFileId: el.logoFileId })); })))),
            React.createElement("div", { className: 'flex justify-center pt-2' },
                React.createElement(CopyrightComponent, null))),
        React.createElement(CreateCompanyComponent, null)));
};
export default ChooseCompanyComponent;
