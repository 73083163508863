import React from 'react';

import getInputErrorFormat from '../../../Action/helpers/getInputErrorFormat';
import setInputErrorFormat from '../../../Action/helpers/setInputErrorFormat';
import {
  cartItems,
  customerSaleV2,
  formatSaleV2,
  inputError,
  invoiceSaleV2,
  branchSaleV2,
  saleTotalToPay,
  employeeSaleV2,
  pinSaleV2,
  discountTypeSaleV2,
  totalsSaleV2,
  form,
  productListSaleV2,
  editProductSaleV2,
  productSaleV2,
  isSaleConfirmDialogOpen,
  commentarySaleV2,
  quotationSaleV2,
  calendarId,
  saleV2FolioField,
  sessionCalendarForm,
  operationDateSaleV2,
  cashFlowSalev2Id,
} from '../../../../../apollo/cache';

import getIfStockProductIsBelowZero from '../../../../../helpers/getIfStockProductIsBelowZero';
import getSettings from '../../../../../helpers/getSettings';
import ticket from '../../../../cashSale/Header/ticket';

import Button from '../../../../../presentationals/Dialog/Footer/Button';
import getDefaultProductFormValues from '../../../ChooseProduct/ProductDialog/Create/Body/helpers/getDefaultValues';
import getDefaultCustomerFormValues from '../../../Action/Customer/Form/Create/Body/helpers/defaultValues';
import setSettings from '../../../../../helpers/setSettings';
import getResponseWithData from './helpers/getResponseWithData';
import getUrlToPrintDocument from './helpers/getUrlToPrintDocument';
import getIfSatRequirementisOk from './helpers/getIfSatRequirementisOk';

import useSaleV2Mutation from '../../../../../hooks/useSaleV2Mutation';
import getIdToPrintDocument from './helpers/getIdToPrintDocument';
import useGetCompanySettings from '../../../../../hooks/useGetCompanySettings';
import getSession from '../../../../../helpers/getSession';
import saleTicket187 from '../../../../../presentationals/PDF/saleTicket.187.80mm';

const CreateButtonFooterConfirmDialogSaleV2Component = () => {
  const { data: companySettigs } = useGetCompanySettings();
  const [createSale, { loading }] = useSaleV2Mutation();

  const handleClick = async () => {
    try {
      const session = getSession();
      const receivablePublicCustomer = session.Company.id.toString() === '3';
      const settings = getSettings();
      const formatSaleV2Var = formatSaleV2();
      const invoiceSaleV2Var = invoiceSaleV2();
      const cartItemsVar = cartItems();
      const customerIdVar = customerSaleV2();
      const branchSaleV2Var = branchSaleV2();
      const saleTotalToPayVar = saleTotalToPay();
      const employeeSaleV2Var = employeeSaleV2();
      const pinSaleV2Var = pinSaleV2();
      const discountTypeVar = discountTypeSaleV2();
      const totalsSaleV2Var = totalsSaleV2();
      const quotationSaleV2Var = quotationSaleV2();
      const commentarySaleV2Var = commentarySaleV2();
      const calendarIdVar = calendarId();
      const saleV2FolioFieldVar = saleV2FolioField();
      const operationDateVar = operationDateSaleV2();
      const cashFlowSalev2IdVar = cashFlowSalev2Id();

      inputError({});

      const decision = getIfStockProductIsBelowZero(cartItemsVar);
      const satRequirement = getIfSatRequirementisOk(cartItemsVar);

      if (
        parseInt(formatSaleV2Var[0]) !== -2 &&
        decision &&
        !companySettigs.company.belowZeroInventory &&
        pinSaleV2Var.length === 0
      )
        throw new Error(
          setInputErrorFormat(
            'pin',
            'Alguno de los productos del carrito no cuenta con inventario, para realizar la venta, proporciona un PIN válido.',
          ),
        );
      if (cartItemsVar.length === 0) {
        isSaleConfirmDialogOpen(false);
        throw new Error('Añade al menos un producto al carrito.');
      }
      if (formatSaleV2Var[2] && !satRequirement)
        throw new Error(
          'Alguno de los productos del carrito no cuenta con las claves del SAT configuradas.',
        );
      if (formatSaleV2Var[2] && invoiceSaleV2Var.paymentMethod.length === 0)
        throw new Error(setInputErrorFormat('paymentMethod', 'Selecciona el método de pago.'));
      if (formatSaleV2Var[2] && invoiceSaleV2Var.paymentForm.length === 0)
        throw new Error(setInputErrorFormat('paymentForm', 'Selecciona la forma de pago.'));
      if (formatSaleV2Var[2] && invoiceSaleV2Var.useCfdi.length === 0)
        throw new Error(setInputErrorFormat('useCfdi', 'Selecciona el uso del CFDi.'));
      if (
        formatSaleV2Var[2] &&
        invoiceSaleV2Var.relateUUID.length === 0 &&
        invoiceSaleV2Var.relateType.length > 0
      )
        throw new Error(setInputErrorFormat('relateUUID', 'Proporciona el UUID a relacionar'));

      if (
        formatSaleV2Var[2] &&
        invoiceSaleV2Var.relateUUID.length > 0 &&
        invoiceSaleV2Var.relateType.length === 0
      )
        throw new Error(setInputErrorFormat('relateType', 'Selecciona el tipo de relación'));

      const totalPaid = saleTotalToPayVar.reduce(
        (total, el) => parseFloat(total) + parseFloat(el.total || 0),
        0,
      );
      if (
        !receivablePublicCustomer &&
        parseInt(formatSaleV2Var[0]) !== -2 &&
        totalPaid < parseFloat(totalsSaleV2Var[3]) &&
        !customerIdVar.id
      )
        throw new Error(
          'Completa el total a pagar. Si deseas hacer una venta a crédito, selecciona un cliente.',
        );

      const variables = {
        operationDate: operationDateVar,
        customerId: customerIdVar.id,
        isInvoice: formatSaleV2Var[2],
        category: parseFloat(formatSaleV2Var[0]),
        type: 1,
        cashFlowId: cashFlowSalev2IdVar,
        expirationDate: quotationSaleV2Var.expirationDate,
        bankAccount: quotationSaleV2Var.bankAccount,
        taxesOption: parseFloat(formatSaleV2Var[1]),
        showTaxes: formatSaleV2Var[3],
        discountType: parseInt(discountTypeVar),
        branchInventoryId: branchSaleV2Var.branchInventory,
        branchSaleId: branchSaleV2Var.branchSale,
        pin: pinSaleV2Var,
        sellerId: parseFloat(employeeSaleV2Var) > 0 ? employeeSaleV2Var : null,
        Transactions: saleTotalToPayVar
          .filter(el => parseFloat(el.total) > 0)
          .map(el => ({
            paymentMethodId: parseInt(el.id) !== -1 ? el.id : null,
            total: parseFloat(el.total),
          })),
        Products: cartItemsVar.map(el => ({
          productId: el.productId,
          serialNumber: el.serialNumber,
          quantity: parseFloat(el.quantity),
          discount:
            parseInt(discountTypeVar) === 1 ? parseFloat(el.discountRate) : parseFloat(el.discount),
          price: parseFloat(el.unitPrice),
          commentary: el.commentary,
          employeeId: el.employeeId,
          ProductIngredients: el.SelectedProductIngredients
            ? el.SelectedProductIngredients.reduce((newArray, elCh) => {
                return [
                  ...newArray,
                  ...(elCh?.ProductIngredients
                    ? elCh.ProductIngredients.map(productIngredientId => ({
                        productIngredientId,
                        quantity: 1,
                        price: 0,
                      }))
                    : []),
                ];
              }, [])
            : undefined,
        })),
        commentary: commentarySaleV2Var,
        paymentMethod: invoiceSaleV2Var.paymentMethod,
        paymentForm: invoiceSaleV2Var.paymentForm,
        useCfdi: invoiceSaleV2Var.useCfdi,
        relateUUID: invoiceSaleV2Var.relateUUID,
        relateType: invoiceSaleV2Var.relateType,
        calendarId: calendarIdVar,
        serial: saleV2FolioFieldVar.folio,
      };

      const { data } = await createSale({ variables });

      const response = getResponseWithData(data.finalizeCart);

      const isInCrm =
        response && response[1] && response[1].SaleProducts
          ? response[1].SaleProducts.map(el => el.Crm).filter(tmp => tmp)
          : [];

      isInCrm.length > 0 && sessionCalendarForm({ isOpen: true, Crms: isInCrm });
      if (settings.formatToPrint === 1 && [1, 3].includes(parseInt(formatSaleV2Var[0])))
        if (session.Company.id.toString() === '187') saleTicket187(response[1].id);
        else ticket(response[1].id);
      if (
        settings.formatToPrint === 2 ||
        (settings.formatToPrint === 1 && ![1, 3].includes(parseInt(formatSaleV2Var[0])))
      ) {
        const formatToPrint = formatSaleV2Var[2] ? -1 : formatSaleV2Var[0];
        const idToPrint = getIdToPrintDocument(formatSaleV2Var[0], formatSaleV2Var[2], response[1]);
        window.open(getUrlToPrintDocument(formatToPrint, idToPrint));
      }

      const defaultProductFormValues = getDefaultProductFormValues();
      const defaultCustomerFormValues = getDefaultCustomerFormValues();

      form({});
      totalsSaleV2([0, 0, 0, 0]);
      saleTotalToPay(saleTotalToPayVar.map(el => ({ ...el, total: '' })));
      cartItems([]);
      productListSaleV2([]);
      discountTypeSaleV2(1);
      editProductSaleV2(false);
      productSaleV2(defaultProductFormValues);
      customerSaleV2(defaultCustomerFormValues);
      invoiceSaleV2({
        paymentMethod: 'PUE',
        paymentForm: '',
        useCfdi: '',
        relateType: '',
        relateUUID: '',
      });
      pinSaleV2('');
      operationDateSaleV2(null);
      employeeSaleV2('');
      commentarySaleV2('');
      inputError({});
      setSettings('defaultFormatSaleV2', formatSaleV2Var);
      isSaleConfirmDialogOpen(false);
      saleV2FolioField({ serial: '', folio: '' });
      if (session?.Company?.id.toString() === '3')
        formatSaleV2(['1', 1, formatSaleV2[2], formatSaleV2[3]]);
    } catch (e) {
      getInputErrorFormat(e);
    }
  };
  return (
    <Button disabled={loading} color='success' onClick={handleClick}>
      Almacenar
    </Button>
  );
};

export default CreateButtonFooterConfirmDialogSaleV2Component;
