import React from 'react';
import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from '@mui/material';
var GlobalInvoiceTypeFieldComponent = function (props) {
    var handleRadioChange = function (event) {
        props.onChange(props.name, parseInt(event.target.value));
    };
    return (React.createElement(FormControl, null,
        React.createElement(FormLabel, { className: 'text-gray-900 mb-1', id: 'globalInvoiceType' }, "\u00BFQu\u00E9 tipo de factura global dese\u00E1s emitir?"),
        React.createElement(RadioGroup, { onChange: handleRadioChange, value: props.value, name: props.name },
            React.createElement(FormControlLabel, { value: 2, control: React.createElement(Radio, { className: 'p-0 ml-4 mr-1 mb-1' }), label: 'Concepto y totales personalizados' }),
            React.createElement(FormControlLabel, { value: 3, control: React.createElement(Radio, { className: 'p-0 ml-4 mr-1 mb-1' }), label: 'Conceptos y totales de ventas seleccionadas' }),
            React.createElement(FormControlLabel, { value: 4, control: React.createElement(Radio, { className: 'p-0 ml-4 mr-1 mb-1' }), label: 'Importar productos desde un documento xls' }))));
};
export default GlobalInvoiceTypeFieldComponent;
