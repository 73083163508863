var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useState } from 'react';
import DialogComponent from '../../../presentationals/Dialog';
import { filter } from '../../../apollo/cache';
import SelectFieldComponent from '../../../presentationals/FormComponents/SelectField';
import Button from '../../../presentationals/Dialog/Footer/Button';
import FooterDialogComponent from '../../../presentationals/Dialog/Footer';
import useFilterButtonDialog from '../../../store/filterButton/filterButtonDialog';
var branchTypeOptions = [
    { value: 0, label: 'Todas las sucursales' },
    { value: 1, label: 'Punto de venta' },
    { value: 2, label: 'Almacen' },
    { value: 3, label: 'Franquicia' },
];
var defaultValues = {
    type: 0,
};
var isLoading = false;
var FilterBranchComponent = function () {
    var open = useFilterButtonDialog(function (state) { return state.open; });
    var setOpen = useFilterButtonDialog(function (state) { return state.setOpen; });
    var _a = useState(defaultValues), values = _a[0], setValues = _a[1];
    var handleClose = function () {
        setOpen(false);
    };
    var handleChange = function (name, value) {
        setValues(function (values) {
            var _a;
            return (__assign(__assign({}, values), (_a = {}, _a[name] = value, _a)));
        });
    };
    var handleClear = function () {
        setValues(defaultValues);
    };
    var handleClick = function () {
        try {
            var currentFilter = filter();
            filter(__assign(__assign({}, currentFilter), { type: values.type || undefined }));
            handleClose();
        }
        catch (error) { }
    };
    return (React.createElement(DialogComponent, { fullScreen: true, open: open, title: 'Filtro para b\u00FAsqueda de sucursales', onClose: handleClose },
        React.createElement("div", { className: 'grid flex-1 grid-cols-1 md:grid-cols-3 md:gap-4 px-4 py-1' },
            React.createElement("div", null,
                React.createElement(SelectFieldComponent, { name: 'type', label: 'Tipo de sucursal', options: branchTypeOptions, onChange: handleChange, value: values.type }))),
        React.createElement(FooterDialogComponent, null,
            React.createElement("div", null),
            React.createElement("div", null,
                React.createElement(Button, { disabled: isLoading, className: 'mr-2', color: 'inherit', onClick: handleClear }, "Limpiar"),
                React.createElement(Button, { disabled: isLoading, color: 'success', onClick: handleClick }, "Aplicar filtro")))));
};
export default FilterBranchComponent;
