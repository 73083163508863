import React from 'react';
import { Outlet } from 'react-router-dom';
import GoBackButtonComponent from '../../presentationals/GoBackButton';
import MenuInvoiceComplementLayout from './Menu';
var InvoiceComplementLayout = function () {
    return (React.createElement("div", { className: 'flex flex-1 flex-col' },
        React.createElement("div", { className: 'flex items-center px-4 py-0' },
            React.createElement("div", { className: 'mr-3' },
                React.createElement(GoBackButtonComponent, null)),
            React.createElement("div", { className: 'text-lg text-gray-700 tracking-tight' }, "Visualizaci\u00F3n del complemento de pago")),
        React.createElement(MenuInvoiceComplementLayout, null),
        React.createElement("div", { className: 'flex flex-1 flex-col h-full overflow-hidden' },
            React.createElement(Outlet, null))));
};
export default InvoiceComplementLayout;
