import getProductColumnsToExport from './getColumnsToExport';
import getIngredientColumnsToExport from './getIngredientColumnsToExport';
import getPresentationColumnsToExport from './getPresentationColumnsToExport';
var getProductColumKeysByType = function (type) {
    if (type === 1)
        return getProductColumnsToExport().map(function (el) { return el.value; });
    if (type === 2)
        return getIngredientColumnsToExport().map(function (el) { return el.value; });
    if (type === 3)
        return getPresentationColumnsToExport().map(function (el) { return el.value; });
    return [];
};
export default getProductColumKeysByType;
