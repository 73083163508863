import { gql } from '@apollo/client';

const FRAGMENT_NEW_ACCOUNT_RECEIVABLE = gql`
  fragment FragmentNewAccountReceivable on AccountReceivable {
    id
    folio
    total
    payment
    balance
    clientCreatedAt
    limitDate
    timeLimit
    background
    customerId
    saleId
    exchangeRate
    customer
    seller
    uuid
  }
`;

const GET = gql`
  query AccountReceivables(
    $order: [String]
    $limit: Int
    $offset: Int
    $status: [Int]
    $branchId: ID
    $customerId: ID
    $saleId: ID
    $chargeId: ID
    $paid: Boolean
    $name: [String]
    $paymentMethod: String
    $timeStatus: Int
    $sellerId: ID
  ) {
    accountReceivables(
      order: $order
      limit: $limit
      offset: $offset
      status: $status
      branchId: $branchId
      customerId: $customerId
      saleId: $saleId
      chargeId: $chargeId
      paid: $paid
      like: $name
      paymentMethod: $paymentMethod
      timeStatus: $timeStatus
      sellerId: $sellerId
    ) {
      id
      folio
      total
      payment
      balance
      clientCreatedAt
      limitDate
      timeLimit
      background
      customerId
      saleId
      exchangeRate
      customer
      seller
      uuid
    }
  }
`;

const GET_BY_ID = gql`
  query AccountReceivable($id: ID!) {
    accountReceivable(id: $id) {
      id
      status
      type
      paid
      folio
      serial
      description
      subtotal
      discount
      taxes
      total
      payment
      balance
      clientCreatedAt
      timeLimit
      limitDate
      customer
      customerPhone
      Sale {
        id
        category
        serial
        folio
        commentary
        subtotal
        discount
        taxes
        total
        totalPaid
        pdfFileId
        cashier
        SaleProducts {
          name
          quantity
          withoutTaxes
          subtotal
          commentary
          serialNumber
          Product {
            name
          }
        }
        clientCreatedAt
      }
      Charge {
        id
        subtotal
        taxes
        total
        commentary
        paid
        serial
        folio
        createdAt
      }
      Branch {
        id
        addressLine1
        addressLine2
        zipCode
        city
        state
        phone
        name
      }
      Company {
        id
        name
      }
      Customer {
        id
        addressLine1
        addressLine2
        zipCode
        city
        state
      }
      Transactions {
        id
        total
        paymentMethodId
        clientCreatedAt
        PaymentMethod {
          name
        }
      }
    }
  }
`;

const GET_CUSTOMER_ACCOUNT_RECEIVABLES = gql`
  query CustomerAccountReceivables($customerId: ID!) {
    customerAccountReceivables(customerId: $customerId) {
      total
      balance
      totalTransaction
      Branch {
        id
        addressLine1
        addressLine2
        zipCode
        city
        state
        phone
        name
        Company {
          id
          name
        }
      }
      AccountReceivables {
        Sale {
          serial
          Invoice {
            folio
            serial
          }
        }
        clientCreatedAt
        limitDate
        total
        balance
      }
      Customer {
        id
        firstName
        lastName
        addressLine1
        addressLine2
        zipCode
        city
        state
        phone
        CustomerBillingInformation {
          identifier
          businessName
          email
          altEmail
          otherEmail
        }
      }
      Transactions {
        id
        total
        paymentMethodId
        PaymentMethod {
          name
        }
        Sale {
          serial
          Invoice {
            folio
            serial
          }
        }
        clientCreatedAt
      }
    }
  }
`;

const UPDATE = gql`
  mutation UpdateAccountReceivable($id: ID!, $timeLimit: Int) {
    updateAccountReceivable(id: $id, input: { timeLimit: $timeLimit }) {
      id
      clientCreatedAt
      timeLimit
      limitDate
      folio
      serial
      total
      payment
      balance
      Sale {
        folio
        serial
      }
      Charge {
        folio
        serial
      }
      Customer {
        firstName
        lastName
      }
    }
  }
`;

const SEND_SALE = gql`
  mutation SendSale($id: ID!, $emails: [String!]) {
    sendSale(id: $id, emails: $emails)
  }
`;

const CREATE_INVOICE_COMPLEMENT = gql`
  mutation CreateInvoiceComplement($Transactions: [ID!], $accountReceivableId: ID!) {
    createInvoiceComplement(
      Transactions: $Transactions
      accountReceivableId: $accountReceivableId
    ) {
      id
      uuid
      serial
      folio
      currency
      exchangeRate
      total
      createdAt
      branchId
      companyId
      employeeId
      invoiceId
      saleId
    }
  }
`;

const DELETE_TRANSACTION = gql`
  mutation DeleteTransaction($id: ID!) {
    deleteTransaction(id: $id) {
      id
    }
  }
`;

const GET_CUSTOMER_TRANSACTIONS = gql`
  query TransactionsCustomer($customerId: ID!) {
    transactionsCustomer(customerId: $customerId) {
      id
      total
      clientCreatedAt
      PaymentMethod {
        name
      }
      Sale {
        id
        Invoice {
          uuid
          folio
          serial
        }
      }
    }
  }
`;

const CREATE_INVOICE_COMPLEMENT_MANY = gql`
  mutation CreateInvoiceComplements($customerId: ID!, $Transactions: [ID]) {
    createInvoiceComplements(input: { customerId: $customerId, Transactions: $Transactions }) {
      id
      uuid
    }
  }
`;

const LIQUIDATE_RECEIVABLE = gql`
  mutation LiquidateReceivable($id: ID!) {
    liquidateReceivable(id: $id) {
      id
    }
  }
`;

export default {
  GET,
  GET_BY_ID,
  UPDATE,
  SEND_SALE,
  GET_CUSTOMER_ACCOUNT_RECEIVABLES,
  CREATE_INVOICE_COMPLEMENT,
  DELETE_TRANSACTION,
  GET_CUSTOMER_TRANSACTIONS,
  CREATE_INVOICE_COMPLEMENT_MANY,
  FRAGMENT_NEW_ACCOUNT_RECEIVABLE,
  LIQUIDATE_RECEIVABLE,
};
