import React from 'react';
import { useReactiveVar } from '@apollo/client';
import DialogComponent from '../../../Dialog';
import { isProductDialogOpen } from '../../../../apollo/cache';
import BodyProductFieldDialogComponent from './Body';
var ProductFieldDialogComponent = function () {
    var isProductDialogOpenVar = useReactiveVar(isProductDialogOpen);
    var handleClose = function () {
        isProductDialogOpen([false, '', '', null]);
    };
    return (React.createElement(DialogComponent, { fullScreen: true, open: isProductDialogOpenVar[0], title: "B\u00FAsqueda de ".concat(isProductDialogOpenVar[1].toLowerCase()), onClose: handleClose },
        React.createElement(BodyProductFieldDialogComponent, null)));
};
export default ProductFieldDialogComponent;
