import React from 'react';
import PasswordIcon from '@mui/icons-material/Password';
import PatternIcon from '@mui/icons-material/Pattern';
import WebUpdatePasswordEditEmployeeComponent from './Web';
import PosUpdatePasswordEditEmployeeComponent from './Pos';
import ListItemPermissionEditRoleComponent from '../../../role/Edit/Permission/helpers/ListItem';
var UpdatePasswordEditEmployeeComponent = function () {
    return (React.createElement("div", { className: 'flex flex-1 flex-col overflow-auto basis-0' },
        React.createElement("div", { className: 'flex flex-col' },
            React.createElement(ListItemPermissionEditRoleComponent, { icon: React.createElement(PasswordIcon, null), name: 'Contrase\u00F1a aplicaci\u00F3n web', secondary: 'Permite acceder a la plataforma administrativa disponible en aplicaci\u00F3n\n          m\u00F3vil o plataforma web.' },
                React.createElement(WebUpdatePasswordEditEmployeeComponent, null)),
            React.createElement(ListItemPermissionEditRoleComponent, { icon: React.createElement(PatternIcon, null), name: 'Contrase\u00F1a para punto de venta', secondary: 'Permite acceder a la aplicaci\u00F3n POS disponible en versi\u00F3n m\u00F3vil y de escritorio, adem\u00E1s, puede realizar cancelaciones, descuentos, autorizaciones si el\n        usuario cuenta con permisos suficientes.' },
                React.createElement(PosUpdatePasswordEditEmployeeComponent, null)))));
};
export default UpdatePasswordEditEmployeeComponent;
