import React, { useEffect, useState } from 'react';

import { useLazyQuery, useMutation } from '@apollo/client';

import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import Countdown from 'react-countdown';

import useSound from 'use-sound';

import useTheme from '@mui/material/styles/useTheme';
import useMediaQuery from '@mui/material/useMediaQuery';

import Grid from '@mui/material/Grid';
import DeleteSweepIcon from '@mui/icons-material/DeleteSweep';

import _notification from '../../../../assets/static/_notification.mp3';

import IconButton from '../../../../presentationals/IconButton';
import { parseError } from '../../../../helpers';

import GQL_CALENDAR from '../../../calendar/_gql';

import GQL from '../../_gql';

import subscribeSocket from '../../../../socket/helpers/subscribe';
import unsubscribeSocket from '../../../../socket/helpers/unsubscribe';
import getPlaneWidth from './helpers/getWidth';
import getPlaneHeight from './helpers/getHeight';
import useSnackbarDialog from '../../../../store/snackbar/snackbarDialog';

const getItemStyle = (isDragging, draggableStyle, tag, isSmallScreen, temporizer, color) => ({
  userSelect: 'none',
  background: isDragging ? 'grey' : 'transparent',
  fontSize: isSmallScreen ? 9 : 13,
  width: 120,
  margin: 6,
  color: temporizer ? color.color : tag ? '#fff' : '#292929',
  ...draggableStyle,
});
const getListStyle = (calendar, temporizer, color) => ({
  background: temporizer
    ? color.background
    : calendar && calendar[0]
      ? calendar[0].Tag
        ? calendar[0].Tag.color
        : '#fff'
      : '#fff',
  color: temporizer
    ? color.color
    : calendar && calendar[0]
      ? calendar[0].Tag
        ? '#fff'
        : '#292929'
      : '#292929',
});

const defaultColor = { background: '#fff', color: '#292929' };

const PlaneButton = props => {
  const Theme = useTheme();
  const isSmallScreen = useMediaQuery(Theme.breakpoints.down('lg'));

  let countdownApi = null;

  const [playSound] = useSound(_notification);

  const [date, setDate] = useState(Date.now());
  const [color, setColor] = useState(defaultColor);
  const [repeat, setRepeat] = useState(null);
  const [width, setWidth] = useState(null);
  const [height, setHeight] = useState(null);

  const setRef = countdown => {
    if (countdown) countdownApi = countdown.getApi();
  };

  useEffect(() => {
    if (props.width) setWidth(getPlaneWidth(parseFloat(props.width)));
  }, [props.width]);

  useEffect(() => {
    if (props.height) setHeight(getPlaneHeight(parseFloat(props.height)));
  }, [props.height]);

  useEffect(() => {
    if (props.Calendars && props.Calendars.length > 0 && props.temporizer && countdownApi) {
      countdownApi.start();
    } else if (props.temporizer && countdownApi) {
      setColor(defaultColor);
      countdownApi.stop();
      setDate(Date.now());
    }
  }, [props.Calendars, props.temporizer, countdownApi]);

  const handlePlay = () => {
    playSound();
  };

  useEffect(() => {
    let time = 0;
    if (repeat === 1) {
      const timerID = setInterval(() => {
        handlePlay();
        time = time + 1;
        setRepeat(null);
        if (time === 5) clearInterval(timerID);
      }, 1000);
    }
  }, [repeat]);

  const handleClear = e => {
    if (parseInt(props.type) === 2)
      props.handleClear(e, { id: props.id, isAvailable: !props.isAvailable });
  };

  const renderer = ({ minutes, seconds }) => {
    return (
      <span>
        {`${minutes}`.padStart(2, '00')}:{`${seconds}`.padStart(2, '00')}
      </span>
    );
  };

  const onComplete = () => {
    setColor({ background: '#640000', color: '#fff' });
    setRepeat(1);
  };

  if (!width || !height) return <div />;

  return (
    <Droppable key={props.id} droppableId={props.id}>
      {provided => (
        <div
          ref={provided.innerRef}
          className={`flex flex-col text-center rounded-none border border-solid border-black shadow-none m-0 p-1 hover:bg-[#f5f5f5] ${width} ${height} ${
            props.type === 0 ? 'bg-[#808080]' : 'bg-[#fff]'
          }`}
          style={getListStyle(props.Calendars, false, color)}
          {...provided.droppableProps}
        >
          <div style={{ fontSize: isSmallScreen ? 9 : 13 }}>{props.name}</div>
          {props.Calendars &&
            props.Calendars.map((item, index) => (
              <div
                style={{ width: '100%', display: 'flex', justifyContent: 'center' }}
                key={item.id}
              >
                <Draggable draggableId={item.id} index={index}>
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      style={getItemStyle(
                        snapshot.isDragging,
                        provided.draggableProps.style,
                        item.Tag,
                        isSmallScreen,
                        // props.temporizer,
                        false,
                        color,
                      )}
                    >
                      <div>
                        {item.Customer
                          ? `${item.Customer.firstName.trim()} ${item.Customer.lastName.trim()}`
                          : '-'}
                      </div>
                    </div>
                  )}
                </Draggable>
              </div>
            ))}
          {parseInt(props.type) === 2 && !props.isAvailable && (
            <div style={{ fontSize: isSmallScreen ? 9 : 13, width: '100%' }}>
              <IconButton
                label='Limpiar zona'
                icon={<DeleteSweepIcon />}
                style={{ color: props.Calendars && props.Calendars[0] ? '#fff' : '#292929' }}
                action={handleClear}
                size='large'
              />
            </div>
          )}
          {props.temporizer && parseFloat(props.temporizer) > 0 && (
            <div style={{ fontSize: isSmallScreen ? 9 : 40, width: '100%' }}>
              <Countdown
                autoStart={false}
                ref={setRef}
                renderer={renderer}
                onComplete={onComplete}
                date={date + parseFloat(props.temporizer) * 60000}
              />
            </div>
          )}
          {provided.placeholder}
        </div>
      )}
    </Droppable>
  );
};

const PlaneComponent = ({ branchId, ...props }) => {
  const { setSnackbar } = useSnackbarDialog();

  const [numRows, setNumRows] = useState(0);
  const [dataSubsCalendarId, setDataSubsCalendarId] = useState(null);
  const [dataSubsPlaneZoneId, setDataSubsPlaneZoneId] = useState(null);

  const [setCalendarZone] = useMutation(GQL_CALENDAR.SET_CALENDAR_ZONE, {
    update(cache, { data: response }) {
      try {
        const planeZoneId =
          response.setCalendarZone.PlaneZone && response.setCalendarZone.PlaneZone.id;
        const oldQuery = cache.readQuery({
          query: GQL.GET_PLANE_ZONE,
          variables: { calendarsInZone: true },
        });
        const PlaneZone = oldQuery.planeZones.rows.find(el => {
          const Calendars = el.Calendars.filter(
            elCh => parseInt(elCh.id) === parseInt(response.setCalendarZone.id),
          );
          return Calendars.length > 0;
        });
        cache.modify({
          id: `PlaneZone:${PlaneZone.id}`,
          fields: {
            Calendars(cachedCalendar, { readField }) {
              const newCachedCalendar = cachedCalendar.filter(
                el => readField('id', el) !== response.setCalendarZone.id,
              );
              return newCachedCalendar;
            },
          },
        });
        if (planeZoneId)
          cache.modify({
            id: `PlaneZone:${planeZoneId}`,
            fields: {
              Calendars(cachedCalendar) {
                return [
                  ...cachedCalendar,
                  {
                    __typename: 'Calendar',
                    id: response.setCalendarZone.id,
                    title: response.setCalendarZone.title,
                    start: response.setCalendarZone.start,
                    end: response.setCalendarZone.end,
                    diagnosis: response.setCalendarZone.diagnosis,
                    Tag: response.setCalendarZone.Tag,
                    status: response.setCalendarZone.status,
                    Customer: response.setCalendarZone.Customer,
                  },
                ];
              },
            },
          });
      } catch (e) {
        console.log(e);
      }
    },
  });

  const [getPlaneZone, { data }] = useLazyQuery(GQL.GET_PLANE_ZONE, {
    fetchPolicy: 'network-only',
  });
  const [updateAvailable] = useMutation(GQL.UPDATE_AVAILABLE_PLANE_ZONE, {
    update(cache, { data: response }) {
      try {
        const { id: idUpdated, isAvailable } = response.updateAvailablePlaneZone;
        cache.modify({
          fields: {
            planeZones(existingPlaneZonesRefs, { readField }) {
              const rows = existingPlaneZonesRefs.rows.map(el =>
                readField('id', el) === idUpdated ? { ...el, isAvailable } : el,
              );
              return {
                ...existingPlaneZonesRefs,
                rows,
              };
            },
          },
        });
      } catch (e) {
        console.log(e);
      }
    },
  });
  const [deleteZone] = useMutation(GQL.DELETE_PLANE_ZONE, {
    update(cache, { data: response }) {
      try {
        const idToDelete = response.deletePlaneZone.id;
        cache.modify({
          fields: {
            planeZones(existingPlaneZonesRefs, { readField }) {
              const rows = existingPlaneZonesRefs.rows.filter(
                el => readField('id', el) !== idToDelete,
              );
              return {
                ...existingPlaneZonesRefs,
                count: existingPlaneZonesRefs.count - 1,
                rows,
              };
            },
          },
        });
      } catch (e) {
        console.log(e);
      }
    },
  });

  useEffect(() => {
    const f = data => {
      setDataSubsCalendarId(data.calendarId);
      setDataSubsPlaneZoneId(data.planeZoneId);
    };
    subscribeSocket('EVENT_ZONE', f);
    return () => {
      unsubscribeSocket('EVENT_ZONE', f);
    };
  }, []);

  useEffect(() => {
    if (data) {
      const tmpNumRows = data.planeZones.rows.reduce(
        (value, el) => (el.y > value ? el.y : value),
        0,
      );
      setNumRows(tmpNumRows + 1);
    }
  }, [data]);

  useEffect(() => {
    if (branchId) {
      const time = dataSubsCalendarId ? 3000 : 500;
      setTimeout(() => {
        getPlaneZone({ variables: { calendarsInZone: true } });
      }, time);
    }
  }, [branchId, dataSubsCalendarId, dataSubsPlaneZoneId]);

  const handleAction = async (e, params) => {
    try {
      if (props.handleClick) {
        props.handleClick(params);
        return;
      }
      await deleteZone({ variables: { id: params.id } });
    } catch (e) {
      console.log(e);
    }
  };

  const handleClear = async (e, params) => {
    try {
      await updateAvailable({ variables: { id: params.id, isAvailable: params.isAvailable } });
    } catch (e) {
      console.log(e);
    }
  };

  const onDragEnd = async result => {
    try {
      const { source, destination } = result;
      if (!destination) return;

      const sInd = +source.droppableId;
      const dInd = +destination.droppableId;

      if (sInd !== dInd)
        await setCalendarZone({ variables: { calendarId: result.draggableId, planeZoneId: dInd } });
    } catch (e) {
      const parseErrors = parseError(e);
      parseErrors.forEach(el => {
        if (el.name === 'BAD_USER_INPUT') console.log(el.message);
        else
          setSnackbar({
            variables: {
              isOpen: true,
              time: 3000,
              label: el.message,
              severity: 'error',
            },
          });
      });
    }
  };
  return (
    <Grid container className='mt-1'>
      <Grid item xs={12}>
        <DragDropContext onDragEnd={onDragEnd}>
          {[...Array(numRows).keys()].map(i => (
            <Grid key={i} item xs={12} className='flex h-[66.5px] sm:h-36'>
              {data &&
                data.planeZones.rows.map(el => {
                  if (i === el.y)
                    return (
                      <PlaneButton
                        double={props.double}
                        key={el.id}
                        id={el.id}
                        name={el.name}
                        Calendars={el.Calendars}
                        handleAction={handleAction}
                        handleClear={handleClear}
                        limit={el.limit}
                        width={el.width}
                        height={el.height}
                        type={el.type}
                        isAvailable={el.isAvailable}
                        temporizer={el.temporizer}
                      />
                    );
                  return false;
                })}
            </Grid>
          ))}
        </DragDropContext>
      </Grid>
    </Grid>
  );
};

export default PlaneComponent;
