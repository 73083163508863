import React from 'react';
import { InputAdornment } from '@mui/material';
import OutlinedField from '../../../../../../../../presentationals/FormComponents/OutlinedField';
var UtilityPercentFieldProductComponent = function (props) {
    var handleChange = function (name, value) {
        props.onChange(name, value);
    };
    return (React.createElement(OutlinedField, { name: 'percentAdjust', label: 'Porcentaje de utilidad sobre precio de compra', endAdornment: React.createElement(InputAdornment, { position: 'end' }, "%"), value: props.value, error: props.error, onChange: handleChange }));
};
export default UtilityPercentFieldProductComponent;
