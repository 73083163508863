var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { useReactiveVar } from '@apollo/client';
import { useEffect, useState } from 'react';
import { filter } from '../apollo/cache';
import useApolloLazyQuery from './useApolloLazyQuery';
import GQL_SALE from '../apollo/gql/sale';
import { currencyFormat } from '../helpers';
var useGetSaleByEmployee = function () {
    var filterVar = useReactiveVar(filter);
    var _a = useState([]), data = _a[0], setData = _a[1];
    var _b = useApolloLazyQuery(GQL_SALE.GET_EMPLOYEE_SALES), getData = _b[0], queryResult = _b[1];
    useEffect(function () {
        console.log('FilterVar: ', filterVar);
        getData({
            variables: __assign(__assign({}, filterVar), { name: filterVar.like ? filterVar.like[1] : undefined }),
        });
    }, [filterVar]);
    useEffect(function () {
        if (queryResult.data) {
            var data_1 = queryResult.data.rows.map(function (el) { return ({
                id: el.id,
                name: "".concat(el.firstName, " ").concat(el.lastName),
                count: el.count.toString(),
                total: currencyFormat(el.total),
            }); });
            setData(data_1);
            console.log('DATA: ', data_1);
        }
    }, [queryResult.data]);
    return __assign(__assign({}, queryResult), { data: data });
};
export default useGetSaleByEmployee;
