import React, { forwardRef, useImperativeHandle } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import DeleteItemOnlineOrderCartButtonComponent from './DeleteButton';
import RHFQtyFieldComponent from '../../../presentationals/RHForm/QtyField';
import OnlineOrderCartItemPriceComponent from './ItemPrice';
var OnlineOrderCartComponent = forwardRef(function (props, ref) {
    var control = useFormContext().control;
    var _a = useFieldArray({
        control: control,
        name: 'Products',
    }), values = _a.fields, remove = _a.remove, append = _a.append;
    var handleDelete = function (i) {
        remove(i);
    };
    var handleAppend = function (data) {
        append(data);
    };
    useImperativeHandle(ref, function () { return ({
        append: handleAppend,
    }); });
    if (values.length === 0)
        return React.createElement("div", { className: 'px-4' }, "De momento no cuentas con productos en el carrito");
    return (React.createElement("div", { className: 'flex flex-1 flex-col' }, values.map(function (field, i) { return (React.createElement("div", { key: field.id, tabIndex: i, className: "flex flex-row gap-2 items-center px-4 py-3 hover:rounded-lg hover:bg-slate-200 ".concat(i % 2 === 0 ? 'bg-slate-100' : '') },
        React.createElement("div", { className: 'flex' },
            React.createElement(RHFQtyFieldComponent, { size: 'small', name: "Products.".concat(i, ".qty") })),
        React.createElement("div", { className: 'flex flex-1 flex-col' },
            React.createElement("div", { className: 'leading-none tracking-tighter text-base' }, field.product.name),
            React.createElement("div", { className: 'mt-1 leading-none tracking-tighter text-sm text-gray-600' }, field.commentary)),
        React.createElement("div", { className: 'flex flex-col' },
            React.createElement(OnlineOrderCartItemPriceComponent, { name: "Products.".concat(i, ".qty"), unitPrice: field.product.unitPrice })),
        React.createElement("div", null,
            React.createElement(DeleteItemOnlineOrderCartButtonComponent, { onClick: handleDelete, index: i })))); })));
});
export default OnlineOrderCartComponent;
