import React, { useState } from 'react';
import { Skeleton } from '@mui/material';
import CONFIG from '../../../../../config/config.json';
var ImageComponent = function (props) {
    var _a = useState(false), imageLoaded = _a[0], setImageLoaded = _a[1];
    var handleImageLoaded = function () {
        setImageLoaded(true);
    };
    return (React.createElement("div", { className: "relative items-center overflow-hidden ".concat(props.width ? "w-".concat(props.width) : 'w-full'), style: {
            height: props.height || '288px',
        } },
        !imageLoaded && (React.createElement(Skeleton, { variant: 'rounded', className: 'absolute left-0 top-0 w-full h-full' })),
        React.createElement("img", { src: "".concat(CONFIG['api-file-service'].url, "/files/").concat(props.src), alt: props.alt, loading: 'lazy', className: "object-cover rounded opacity-".concat(imageLoaded ? 100 : 0, " w-full h-full transition-opacity duration-500"), onLoad: handleImageLoaded })));
};
export default ImageComponent;
