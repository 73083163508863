import { gql } from '@apollo/client';

const FRAGMENT_BRANCH = gql`
  fragment fragmentBranch on Branch {
    id
    name
    prefix
    addressLine1
    addressLine2
    municipality
    state
    city
    zipCode
    defaultBilling
    taxRegime
    businessName
    identifier
    reference
    phone
    altPhone
    type
    startHour
    endHour
    Company {
      id
      name
    }
  }
`;

const GET = gql`
  ${FRAGMENT_BRANCH}
  query Branches(
    $limit: Int
    $offset: Int
    $name: String
    $order: [String]
    $justLinked: Boolean
    $type: Int
  ) {
    branches(
      limit: $limit
      offset: $offset
      name: $name
      order: $order
      justLinked: $justLinked
      type: $type
    ) {
      count
      rows {
        ...fragmentBranch
      }
    }
  }
`;

const GET_BY_ID = gql`
  ${FRAGMENT_BRANCH}
  query Branch($id: ID!) {
    branch(id: $id) {
      ...fragmentBranch
    }
  }
`;

const DELETE = gql`
  mutation DeleteBranch($id: ID!) {
    deleteBranch(id: $id) {
      id
    }
  }
`;

const CREATE = gql`
  ${FRAGMENT_BRANCH}
  mutation CreateBranch(
    $name: String!
    $prefix: String!
    $addressLine1: String!
    $addressLine2: String!
    $zipCode: String!
    $reference: String
    $phone: String!
    $altPhone: String
    $startHour: Float
    $endHour: Float
    $type: Int
    $defaultBilling: Boolean
    $taxRegime: String
    $businessName: String
    $identifier: String
    $municipality: String
    $state: String
    $city: String
  ) {
    createBranch(
      input: {
        name: $name
        prefix: $prefix
        addressLine1: $addressLine1
        addressLine2: $addressLine2
        zipCode: $zipCode
        reference: $reference
        phone: $phone
        altPhone: $altPhone
        startHour: $startHour
        endHour: $endHour
        type: $type
        defaultBilling: $defaultBilling
        taxRegime: $taxRegime
        businessName: $businessName
        identifier: $identifier
        municipality: $municipality
        state: $state
        city: $city
      }
    ) {
      ...fragmentBranch
    }
  }
`;
const UPDATE = gql`
  ${FRAGMENT_BRANCH}
  mutation UpdateBranch(
    $id: ID!
    $name: String!
    $prefix: String!
    $iva: Int!
    $addressLine1: String!
    $addressLine2: String!
    $zipCode: String!
    $reference: String
    $phone: String!
    $altPhone: String
    $startHour: Float
    $endHour: Float
    $type: Int
    $defaultBilling: Boolean
    $taxRegime: String
    $businessName: String
    $identifier: String
    $municipality: String
    $state: String
    $city: String
  ) {
    updateBranch(
      id: $id
      input: {
        name: $name
        prefix: $prefix
        iva: $iva
        addressLine1: $addressLine1
        addressLine2: $addressLine2
        zipCode: $zipCode
        reference: $reference
        phone: $phone
        altPhone: $altPhone
        startHour: $startHour
        endHour: $endHour
        type: $type
        defaultBilling: $defaultBilling
        taxRegime: $taxRegime
        businessName: $businessName
        identifier: $identifier
        municipality: $municipality
        state: $state
        city: $city
      }
    ) {
      ...fragmentBranch
    }
  }
`;

const UPDATE_BRANCH_BILLING_INFORMATION = gql`
  mutation UpdateBranchBillingInformation(
    $id: ID!
    $taxRegime: String!
    $businessName: String!
    $identifier: String!
  ) {
    updateBranchBillingInformation(
      id: $id
      input: { taxRegime: $taxRegime, businessName: $businessName, identifier: $identifier }
    ) {
      id
      taxRegime
      businessName
      identifier
    }
  }
`;

const CREATE_CREDENTIALS = gql`
  mutation GenerateLocalCredentials($id: ID!) {
    generateLocalCredentials(id: $id) {
      token
      secret
    }
  }
`;

const GET_PLANE_ZONE = gql`
  query PlaneZones($branchId: ID, $calendarsInZone: Boolean, $type: Int) {
    planeZones(branchId: $branchId, calendarsInZone: $calendarsInZone, type: $type) {
      count
      rows {
        id
        name
        type
        x
        y
        z
        width
        height
        limit
        isAvailable
        temporizer
        Calendars {
          id
          Customer {
            firstName
            lastName
          }
          Tag {
            color
          }
        }
      }
    }
  }
`;

const CREATE_PLANE_ZONE = gql`
  mutation CreatePlaneZone(
    $name: String!
    $type: Int!
    $x: Float!
    $y: Float!
    $z: Float
    $width: Float!
    $height: Float!
    $limit: Int!
  ) {
    createPlaneZone(
      input: {
        name: $name
        type: $type
        x: $x
        y: $y
        z: $z
        width: $width
        height: $height
        limit: $limit
      }
    ) {
      id
      name
      type
      x
      y
      z
      width
      height
      limit
    }
  }
`;

const DELETE_PLANE_ZONE = gql`
  mutation DeletePlaneZone($id: ID!) {
    deletePlaneZone(id: $id) {
      id
    }
  }
`;

const SUBSCRIBE_TO_PLANE_ZONE = gql`
  subscription SubscribeToPlaneZone($wsToken: String!) {
    subscribeToPlaneZone(wsToken: $wsToken) {
      calendarId
      planeZoneId
    }
  }
`;

const UPDATE_AVAILABLE_PLANE_ZONE = gql`
  mutation UpdateAvailablePlaneZone($id: ID!, $isAvailable: Boolean!) {
    updateAvailablePlaneZone(id: $id, isAvailable: $isAvailable) {
      id
      isAvailable
    }
  }
`;

export default {
  SUBSCRIBE_TO_PLANE_ZONE,
  GET,
  GET_BY_ID,
  DELETE,
  CREATE,
  UPDATE,
  CREATE_CREDENTIALS,
  GET_PLANE_ZONE,
  CREATE_PLANE_ZONE,
  DELETE_PLANE_ZONE,
  UPDATE_AVAILABLE_PLANE_ZONE,
  FRAGMENT_BRANCH,
  UPDATE_BRANCH_BILLING_INFORMATION,
};
