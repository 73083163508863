function removeTypename(obj) {
    if (Array.isArray(obj))
        return obj.map(function (item) { return removeTypename(item); });
    if (obj !== null && typeof obj === 'object') {
        var newObj_1 = {};
        Object.keys(obj).forEach(function (key) {
            if (key !== '__typename')
                newObj_1[key] = removeTypename(obj[key]);
        });
        return newObj_1;
    }
    return obj;
}
export default removeTypename;
