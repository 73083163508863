import React from 'react';
import { useParams } from 'react-router-dom';
import BocadosDelCieloProductListComponent from './BocadosDelCielo';
import HeaderProductListComponent from './Header';
import BodyProductListComponent from './Body';
var ProductListComponent = function () {
    var params = useParams();
    if (params.ecName === 'bocadosdelcielo' && !params.companyId)
        return React.createElement(BocadosDelCieloProductListComponent, null);
    return (React.createElement("div", { className: 'w-full h-screen flex flex-1 flex-col bg-gray-900 overflow-auto basis-0' },
        React.createElement(HeaderProductListComponent, null),
        React.createElement(BodyProductListComponent, null)));
};
export default ProductListComponent;
