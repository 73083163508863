import React, { forwardRef, useImperativeHandle } from 'react';
import ButtonFooterDialogComponent from '../../../../presentationals/Dialog/Footer/Button';
import setInputErrorFormat from '../../../sale.v2/Action/helpers/setInputErrorFormat';
import getInputErrorFormat from '../../../sale.v2/Action/helpers/getInputErrorFormat';
import { inputError } from '../../../../apollo/cache';
var ActionCashFlowButtonComponent = forwardRef(function (props, ref) {
    var handleClick = function () {
        try {
            inputError({});
            var values = props.getValues();
            if (!values)
                throw new Error('Ocurrió un error inesperado.');
            if (!values.action)
                throw new Error(setInputErrorFormat('action', 'Especifíca la acción a realizar.'));
            if (values.description === '')
                throw new Error(setInputErrorFormat('description', 'Proporciona el motivo del movimiento de caja.'));
            if (values.total === '' || isNaN(parseFloat(values.total)))
                throw new Error(setInputErrorFormat('total', 'Proporciona el importe del movimiento de caja.'));
            props.onClick(values);
        }
        catch (error) {
            getInputErrorFormat(error);
        }
    };
    useImperativeHandle(ref, function () { return ({
        onPressEnter: handleClick,
    }); });
    return (React.createElement(ButtonFooterDialogComponent, { loading: props.loading, onClick: handleClick }, props.label));
});
export default ActionCashFlowButtonComponent;
