import { Card } from '@mui/material';
import React, { forwardRef, useImperativeHandle, useRef } from 'react';
import CurrencyCreatePurchaseOrderComponent from './Currency';
import DiscountCreatePurchaseOrderComponent from './Discount';
import GeneralCreatePurchaseOrderComponent from './General';
import BranchCreatePurchaseOrderComponent from './Branch';
var HeaderCreatePurchaseOrderComponent = forwardRef(function (props, ref) {
    var generalCreatePurchaseOrderRef = useRef(null);
    var discountCreatePurchaseOrderRef = useRef(null);
    var currencyCreatePurchaseOrderRef = useRef(null);
    var branchCreatePurchaseOrderRef = useRef(null);
    var getValues = function () {
        var _a, _b, _c, _d;
        return ({
            General: (_a = generalCreatePurchaseOrderRef.current) === null || _a === void 0 ? void 0 : _a.getValues(),
            Discount: (_b = discountCreatePurchaseOrderRef.current) === null || _b === void 0 ? void 0 : _b.getValues(),
            Currency: (_c = currencyCreatePurchaseOrderRef.current) === null || _c === void 0 ? void 0 : _c.getValues(),
            Branch: (_d = branchCreatePurchaseOrderRef.current) === null || _d === void 0 ? void 0 : _d.getValues(),
        });
    };
    useImperativeHandle(ref, function () { return ({
        getValues: getValues,
    }); });
    return (React.createElement(Card, { className: 'flex flex-row md:w-full bg-white md:mt-0 overflow-x-auto rounded md:overflow-hidden border-0 border-b-[3px] border-solid border-[#3f51b5] md:mr-4' },
        React.createElement(GeneralCreatePurchaseOrderComponent, { onChange: props.onChange, ref: generalCreatePurchaseOrderRef }),
        React.createElement(DiscountCreatePurchaseOrderComponent, { onChange: props.onChange, ref: discountCreatePurchaseOrderRef }),
        React.createElement(CurrencyCreatePurchaseOrderComponent, { onChange: props.onChange, ref: currencyCreatePurchaseOrderRef }),
        React.createElement(BranchCreatePurchaseOrderComponent, { onChange: props.onChange, ref: branchCreatePurchaseOrderRef })));
});
export default HeaderCreatePurchaseOrderComponent;
