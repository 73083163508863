var getSaleDeliveryStatus = function (status) {
    switch (status) {
        case 0:
            return 'Pendiente de entrega';
        case 4:
            return 'Entregado al cliente';
        case 5:
            return 'Recolectado y por cobrar';
        case 6:
            return 'Proceso finalizado';
        default:
            return 'Indefinido';
    }
};
export default getSaleDeliveryStatus;
