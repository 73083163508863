import React from 'react';
import SelectFieldComponent from '../../../../../presentationals/FormComponents/SelectField';
var SelectClosedResponseTextFieldComponent = function (props) {
    var options = [
        { value: '2', label: 'Bueno' },
        { value: '1', label: 'Regular' },
        { value: '0', label: 'Malo' },
    ];
    return (React.createElement(SelectFieldComponent, { name: 'question', label: props.label, value: props.value || '', onChange: props.onChange, options: options }));
};
export default SelectClosedResponseTextFieldComponent;
