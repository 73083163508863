function displayTextWidth(text, font) {
  const canvas =
    displayTextWidth.canvas || (displayTextWidth.canvas = document.createElement('canvas'));
  const context = canvas.getContext('2d');
  context.font = font;
  const metrics = context.measureText(text);
  return metrics.width;
}

export default displayTextWidth;
