import React, { useEffect, useState } from 'react';
import { useReactiveVar } from '@apollo/client';
import { isProductPromotionOpen } from '../../../apollo/cache';
import DialogComponent from '../../../presentationals/Dialog';
import useApolloLazyQuery from '../../../hooks/useApolloLazyQuery';
import GQL_PROMOTION from '../../../apollo/gql/promotion';
import MenuEditProductPromotionComponent from './Menu';
import LoadingProgress from '../../../presentationals/LoadingProgress';
import GeneralEditProductPromotionComponent from '../ProductPromotion/Edit';
import RatesEditProductPromotionComponent from './Rates';
var EditProductPromotionComponent = function () {
    var isOpen = useReactiveVar(isProductPromotionOpen);
    var _a = useState(0), currentForm = _a[0], setCurrentForm = _a[1];
    var _b = useApolloLazyQuery(GQL_PROMOTION.GET_PRODUCT_PROMOTION_BY_ID), getById = _b[0], _c = _b[1], loading = _c.loading, data = _c.data;
    useEffect(function () {
        if (isOpen)
            getById({ variables: { id: isOpen } });
    }, [isOpen]);
    var handleClose = function () {
        isProductPromotionOpen(false);
    };
    var handleMenu = function (e, value) {
        setCurrentForm(value);
    };
    return (React.createElement(DialogComponent, { fullScreen: true, open: !!isOpen, title: 'Configuraci\u00F3n del producto', onClose: handleClose, "aria-labelledby": 'edit-product-promotion', "aria-describedby": 'edit-product-promotion-and-more' },
        React.createElement("div", { className: 'flex flex-1 flex-col overflow-hidden' },
            React.createElement(MenuEditProductPromotionComponent, { onChange: handleMenu, value: currentForm }),
            loading || !data ? (React.createElement(LoadingProgress, null)) : currentForm === 0 ? (React.createElement(GeneralEditProductPromotionComponent, null)) : currentForm === 1 ? (React.createElement(RatesEditProductPromotionComponent, { Prices: data.ProductPromotionPrices })) : (React.createElement("div", null)))));
};
export default EditProductPromotionComponent;
