import React, { useState, useEffect } from 'react';

import { useLazyQuery, useMutation } from '@apollo/client';

import FormDialog from '../../presentationals/FormDialog';
import TextField from '../../presentationals/FormComponents/TextField';

import Cart from './cart';
import GQL from './_gql';
import UpdateCustomerQuoteComponent from './UpdateCustomer';

const defaultValues = {
  commentary: '',
};

const FormComponent = props => {
  const [codeToClear, setCodeToClear] = useState(null);
  const [values, setValues] = useState(defaultValues);

  const [getQuotation, { data }] = useLazyQuery(GQL.GET_BY_ID);

  const [updateCommentary, { loading }] = useMutation(GQL.UPDATE_COMMENTARY, {
    update(cache, { data }) {
      cache.modify({
        id: `Quote:${props.id}`,
        fields: {
          commentary() {
            return data.updateQuoteCommentary.commentary;
          },
        },
      });
    },
  });

  useEffect(() => {
    if (props.id) getQuotation({ variables: { id: props.id } });
  }, [props.id]);

  useEffect(() => {
    if (data) setValues(values => ({ ...values, commentary: data.quote.commentary }));
  }, [data]);

  const handleClose = () => {
    setCodeToClear(null);
    props.handleClose();
  };

  const handleUpdateCommentary = async () => {
    try {
      await updateCommentary({ variables: { id: props.id, commentary: values.commentary } });
      handleClose();
      // setValues(defaultValues);
    } catch (e) {
      console.log(e.message);
    }
  };

  const handleChange = (name, value) => {
    setValues(values => ({ ...values, [name]: value }));
  };

  return (
    <FormDialog
      isOpen={props.isOpen}
      isLoading={loading}
      title='Productos'
      disableEscape
      fullScreen
      handleClose={handleClose}
      handleAction={handleUpdateCommentary}
      actionLabel='Actualizar'
    >
      <Cart codeToClear={codeToClear} id={props.id} />
      <TextField
        onChange={handleChange}
        name='commentary'
        label='Comentarios'
        value={values.commentary}
        multiline
        minRows={2}
      />
      <UpdateCustomerQuoteComponent quoteId={props.id} />
    </FormDialog>
  );
};

export default FormComponent;
