var getDefaultCreateGeneralPurchaseOrderValues = function () { return ({
    action: 1,
    providerId: null,
    pursacheDate: new Date(),
    docFolio: '',
    paymentForm: '01',
    amountPaid: '',
    showTaxesOptions: false,
    taxesOption: 1,
}); };
export default getDefaultCreateGeneralPurchaseOrderValues;
