import React, { useEffect } from 'react';

import { useReactiveVar } from '@apollo/client';

import Collapse from '@mui/material/Collapse';

import {
  actionForm,
  step,
  steps,
  purchaseOrderId,
  handleContinueConfiguration,
} from '../../../apollo/cache';

import FormDialog from '../../../presentationals/FormDialog';
import Stepper from '../../../presentationals/FormComponents/Stepper';
import FooterAction from '../../../presentationals/FormComponents/FooterAction';

import Main from './Main';
import Products from './Products';

const CreateFormComponent = () => {
  const actionFormVar = useReactiveVar(actionForm);
  const stepVar = useReactiveVar(step);

  useEffect(() => {
    steps(['Datos Generales', 'Productos']);
  }, []);

  useEffect(() => {
    if (actionFormVar.action === 'closeOrderForm') purchaseOrderId(actionFormVar.id);
  }, [actionFormVar]);

  const handleClose = () => {
    handleContinueConfiguration({});
    purchaseOrderId(null);
    actionForm({ ...actionForm(), isOpen: false, action: '' });
    step(0);
  };

  return (
    <FormDialog
      isOpen={(actionFormVar.action === 'closeOrderForm' && actionFormVar.isOpen) || false}
      handleClose={handleClose}
      title='Orden de Compra'
      noActions
      fullScreen
      noPadding
    >
      <Stepper />
      <div className='py-1 px-3'>
        <Collapse in={stepVar === 0}>
          <Main />
        </Collapse>
        <Collapse in={stepVar === 1}>
          <Products />
        </Collapse>
      </div>
      <FooterAction />
    </FormDialog>
  );
};

export default CreateFormComponent;
