import React, { useEffect, useState } from 'react';

import { useReactiveVar } from '@apollo/client';

import ReceiptIcon from '@mui/icons-material/ReceiptOutlined';
import PrintIcon from '@mui/icons-material/Print';
import EditIcon from '@mui/icons-material/EditOutlined';
import DoneAllIcon from '@mui/icons-material/DoneAllOutlined';

import { actions, filter } from '../../apollo/cache';

import PDF from './_pdf';
import FormDialog from './_form';
import FormReceptionDialog from './_formReception';
import AuthDialog from './auth.dialog';
import AuthDialog3 from './confirm.dialog';
import getSession from '../../helpers/getSession';
import transferTicket from '../../presentationals/PDF/transfer.80mm';

const ActionComponent = () => {
  const filterVar = useReactiveVar(filter);
  const [isOpenEdit, setIsOpenEdit] = useState([false, null]);
  const [isOpenAuth, setIsOpenAuth] = useState([false, null]);
  const [isOpenAuth2, setIsOpenAuth2] = useState([false, null]);
  const [isOpenAuth3, setIsOpenAuth3] = useState([false, null]);

  const handleTicket = (e, { id }) => transferTicket(id);
  const handlePDF = (e, { id }) => PDF(id);

  const handleEdit = (e, { id }) => setIsOpenEdit([true, id]);
  const handleCloseEdit = () => setIsOpenEdit([false, null]);

  const handleAuth = (e, { id }) => setIsOpenAuth([true, id]);
  const handleCloseAuth = () => setIsOpenAuth([false, null]);

  const handleAuth2 = (e, { id, companyId }) => {
    const session = getSession();
    if (!session) return;
    if (parseInt(session?.Company?.id) !== parseInt(companyId)) setIsOpenAuth2([true, id]);
    else setIsOpenAuth3([true, id]);
  };
  const handleCloseAuth2 = () => setIsOpenAuth2([false, null]);
  const handleCloseConfirm = () => setIsOpenAuth3([false, null]);

  useEffect(() => {
    const session = getSession();
    if (!session) return;
    actions(
      [
        {
          icon: <DoneAllIcon />,
          label: 'Autorizar',
          onClick: handleAuth,
          disable: { key: 'status', value: [2, 3] },
          hide1: {
            key: 'Branch id',
            notValue: filterVar.branchId ? filterVar.branchId : session?.Company?.Branch.id,
          },
        },
        {
          icon: <DoneAllIcon />,
          label: 'Recibir Traspaso',
          onClick: handleAuth2,
          disable: { key: 'status', value: [1, 3] },
          hide1: {
            key: 'BranchTransfer id',
            notValue: filterVar.branchId ? filterVar.branchId : session?.Company?.Branch.id,
          },
        },
        {
          icon: <ReceiptIcon />,
          label: 'Imprimir Ticket',
          onClick: handleTicket,
        },
        {
          icon: <PrintIcon />,
          label: 'Imprimir Traspaso',
          onClick: handlePDF,
        },
        {
          icon: <EditIcon />,
          label: 'Editar Traspaso',
          onClick: handleEdit,
          disable: { key: 'status', value: [2, 3] },
          hide1: {
            key: 'Branch id',
            notValue: filterVar.branchId ? filterVar.branchId : session?.Company?.Branch.id,
          },
        },
      ].filter(el => el),
    );
    return () => {
      actions([]);
    };
  }, [filterVar]);

  return (
    <>
      <FormDialog isOpen={isOpenEdit[0]} id={isOpenEdit[1]} handleClose={handleCloseEdit} />
      <AuthDialog isOpen={isOpenAuth[0]} id={isOpenAuth[1]} handleClose={handleCloseAuth} />
      <AuthDialog3 isOpen={isOpenAuth3[0]} id={isOpenAuth3[1]} handleClose={handleCloseConfirm} />
      <FormReceptionDialog
        isOpen={isOpenAuth2[0]}
        id={isOpenAuth2[1]}
        handleClose={handleCloseAuth2}
      />
    </>
  );
};

export default ActionComponent;
