import React, { useEffect } from 'react';
import { useReactiveVar } from '@apollo/client';
import { boxForm, isEditFormOpen } from '../../../apollo/cache';
import DialogComponent from '../../../presentationals/Dialog';
import useApolloLazyQuery from '../../../hooks/useApolloLazyQuery';
import GQL from '../_gql';
import LoadingProgress from '../../../presentationals/LoadingProgress';
import getDefaultBoxValues from '../helpers/getDefaultBoxValues';
import BodyCreateBoxComponent from '../Create/Body';
import FooterDialogComponent from '../../../presentationals/Dialog/Footer';
import DeleteButtonBoxComponent from './DeleteButton';
import EditButtonBoxComponent from './EditButton';
var EditBoxComponent = function () {
    var isOpen = useReactiveVar(isEditFormOpen);
    var _a = useApolloLazyQuery(GQL.GET_BY_ID), getById = _a[0], _b = _a[1], loading = _b.loading, data = _b.data;
    useEffect(function () {
        if (isOpen)
            getById({ variables: { id: isOpen } });
    }, [isOpen]);
    useEffect(function () {
        if (data && isOpen) {
            var defaultValues = getDefaultBoxValues(data);
            boxForm(defaultValues);
        }
    }, [data, isOpen]);
    var handleClose = function () {
        var defaultValues = getDefaultBoxValues();
        boxForm(defaultValues);
        isEditFormOpen(false);
    };
    return (React.createElement(DialogComponent, { title: 'Configuraci\u00F3n de la caja o paquete', fullScreen: true, open: !!isOpen, onClose: handleClose }, loading ? (React.createElement(LoadingProgress, null)) : (React.createElement(React.Fragment, null,
        React.createElement(BodyCreateBoxComponent, null),
        React.createElement(FooterDialogComponent, null,
            React.createElement(DeleteButtonBoxComponent, null),
            React.createElement(EditButtonBoxComponent, null))))));
};
export default EditBoxComponent;
