import React, { useState } from 'react';
import FilterDrawer from '../../presentationals/FilterDrawer.v2';
import { filter } from '../../apollo/cache';
import SelectFieldComponent from '../../presentationals/FormComponents/SelectField';
import useGetSatPaymentForm from '../../hooks/useGetSatPaymentForm';

const defaultValues = {
  paymentForm: -1,
  status: -1,
  paymentMethod: -1,
};

const FilterInvoiceReportDialogComponent = () => {
  const getPaymentFormOptions = useGetSatPaymentForm();
  const [values, setValues] = useState(defaultValues);

  const handleAction = (e, data) => {
    const { startDate: start, endDate: end, branchId } = data;
    const { name } = filter();
    filter({
      name: name || undefined,
      start,
      end,
      branchId: branchId === -1 ? null : branchId,
      paymentForm: values.paymentForm === -1 ? null : values.paymentForm,
      status: values.status !== -1 ? parseInt(values.status) : null,
      paymentMethod: values.paymentMethod !== -1 ? values.paymentMethod : null,
    });
  };

  const handleChange = (name, value) => {
    setValues(values => ({ ...values, [name]: value }));
  };

  return (
    <FilterDrawer handleAction={handleAction} showDates showBranches includeAllOption>
      <SelectFieldComponent
        label='Forma de pago'
        name='paymentForm'
        value={values.paymentForm}
        onChange={handleChange}
        options={[{ value: -1, label: 'Todas las formas de pago' }, ...getPaymentFormOptions]}
      />
      <SelectFieldComponent
        label='Método de Pago'
        name='paymentMethod'
        value={values.paymentMethod}
        onChange={handleChange}
        variant='outlined'
        options={[
          { value: -1, label: 'Todas' },
          { value: 'PUE', label: 'PUE' },
          { value: 'PPD', label: 'PPD' },
        ]}
      />
      <SelectFieldComponent
        label='Estatus:'
        name='status'
        value={values.status}
        onChange={handleChange}
        variant='outlined'
        options={[
          { value: -1, label: 'Todas' },
          { value: 1, label: 'Activas' },
          { value: 0, label: 'Canceladas' },
        ]}
      />
    </FilterDrawer>
  );
};

export default FilterInvoiceReportDialogComponent;
