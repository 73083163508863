import React, { useEffect, useState } from 'react';

import { useReactiveVar } from '@apollo/client';
import { List } from '@mui/material';

import { searchProductKeyField } from '../../../../../../../../../../apollo/cache';
import Item from './Item';

const TableSearchDialogUnitKeyFieldHelpersBodyCreateProductDialogProductFormChooseProductSaleV2Component = () => {
    const searchProductKeyFieldVar = useReactiveVar(searchProductKeyField);

    const [data, setData] = useState([]);
    const [result, setResult] = useState([]);

    useEffect(() => {
      const f = async () => {
        try {
          const response = await fetch(
            'https://raw.githubusercontent.com/bambucode/catalogos_sat_JSON/master/c_ClaveProdServ.json',
          );
          const jsonResponse = await response.json();
          setData(
            jsonResponse.map(el => ({
              label: el.descripcion,
              id: el.id,
            })),
          );
        } catch (e) {
          console.log('Error', e);
        }
      };
      f();
    }, []);

    useEffect(() => {
      if (searchProductKeyFieldVar.length > 0 && data.length > 0) {
        const searchString = searchProductKeyFieldVar
          .toLowerCase()
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '');
        const currentProductKey = data.filter(
          el =>
            el.label
              .normalize('NFD')
              .replace(/[\u0300-\u036f]/g, '')
              .toLowerCase()
              .includes(searchString) || el.id.toLowerCase().includes(searchString),
        );
        setResult(currentProductKey);
      }
    }, [searchProductKeyFieldVar, data]);

    return (
      <div className='flex flex-1 flex-col overflow-y-auto'>
        <List component='nav' aria-labelledby='nested-customer-list'>
          {result.map((el, i) => (
            <Item key={el.id} {...el} background={i % 2 > 0 ? 'bg-inherit' : 'bg-gray-100'} />
          ))}
        </List>
      </div>
    );
  };

export default TableSearchDialogUnitKeyFieldHelpersBodyCreateProductDialogProductFormChooseProductSaleV2Component;
