import React from 'react';
import BarcodeLabelItemComponent from './Item';
var BarcodeLabelPurchaseOrderComponent = function (props) {
    if (props.Products.length === 0)
        return React.createElement("div", null);
    return (React.createElement("div", { className: 'flex flex-col mt-3' },
        React.createElement("div", { className: 'text-lg text-gray-700 tracking-tight' }, "Impresi\u00F3n de etiquetas"),
        React.createElement("div", { className: 'flex sm:grid sm:grid-cols-5 sm:gap-2 mt-2' }, props.Products.map(function (el) { return (React.createElement(BarcodeLabelItemComponent, { key: el.ProductPresentation.id, Product: el.Product, ProductPresentation: el.ProductPresentation, quantity: el.quantity })); }))));
};
export default BarcodeLabelPurchaseOrderComponent;
