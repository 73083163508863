import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import DialogComponent from '../../../../../presentationals/Dialog';
import SearchProductPurchaseOrderFormComponent from '../../SearchProductForm';
import FooterDialogComponent from '../../../../../presentationals/Dialog/Footer';
import DeleteCreatePurchaseOrderItemButtonComponent from './DeleteButton';
import EditCreatePurchaseOrderItemButtonComponent from './EditButton';
import { inputError } from '../../../../../apollo/cache';
var EditCreatePurchaseOrderItemDialogComponent = forwardRef(function (props, ref) {
    var searchProductPurchaseOrderFormRef = useRef(null);
    var _a = useState(false), isOpen = _a[0], setIsOpen = _a[1];
    var _b = useState(-1), index = _b[0], setIndex = _b[1];
    var _c = useState(null), defaultValues = _c[0], setDefaultValues = _c[1];
    useEffect(function () {
        var _a;
        if (isOpen) {
            var action = props.getAction();
            (_a = searchProductPurchaseOrderFormRef.current) === null || _a === void 0 ? void 0 : _a.getAction(action);
        }
    }, [isOpen]);
    var handleOpen = function (item) {
        setIsOpen(true);
        setIndex(item.index);
        setDefaultValues({
            cost: item.cost.toString(),
            percentAdjust: item.percentAdjust.toString(),
            Product: item.Product,
            productPresentationId: item.ProductPresentation.id,
            quantity: item.quantity.toString(),
            salePrice: item.salePrice.toString(),
            updateSalePrice: item.updateSalePrice,
        });
    };
    var handleClose = function () {
        var _a;
        inputError();
        setIsOpen(false);
        setIndex(-1);
        setDefaultValues(null);
        (_a = searchProductPurchaseOrderFormRef.current) === null || _a === void 0 ? void 0 : _a.clearForm();
    };
    useImperativeHandle(ref, function () { return ({
        open: handleOpen,
    }); });
    var handleGetValues = function () { var _a; return (_a = searchProductPurchaseOrderFormRef.current) === null || _a === void 0 ? void 0 : _a.getValues(); };
    var handleEdit = function (index, item) {
        handleClose();
        props.onEdit(index, item);
    };
    var handleDelete = function (index) {
        handleClose();
        props.onDelete(index);
    };
    return (React.createElement(DialogComponent, { fullScreen: true, disablePortal: true, open: !!isOpen, title: 'Editar producto de la compra', onClose: handleClose },
        React.createElement("div", { className: 'flex flex-1 flex-col' },
            React.createElement("div", { className: 'flex flex-1 px-4 sm:grid sm:grid-cols-3' },
                React.createElement(SearchProductPurchaseOrderFormComponent, { form: 'editProductPurchaseOrder', defaultValues: defaultValues || undefined, ref: searchProductPurchaseOrderFormRef })),
            React.createElement(FooterDialogComponent, null,
                React.createElement(DeleteCreatePurchaseOrderItemButtonComponent, { index: index, onClick: handleDelete }),
                React.createElement(EditCreatePurchaseOrderItemButtonComponent, { index: index, getValues: handleGetValues, onClick: handleEdit })))));
});
export default EditCreatePurchaseOrderItemDialogComponent;
