import React, { useEffect, useState } from 'react';
import { useReactiveVar, useQuery } from '@apollo/client';

import Typography from '@mui/material/Typography';

import FilterDrawer from '../../presentationals/FilterDrawer.v2';
import Autocomplete from '../../presentationals/Autocomplete';
import { filter, searchField } from '../../apollo/cache';

import GQL_PRODUCT from '../product/_gql';

const defaultValues = {
  productId: null,
};

const FilterDialog = () => {
  const searchFieldVar = useReactiveVar(searchField);
  const filterVar = useReactiveVar(filter);

  const [values, setValues] = useState(defaultValues);
  const [products, setProducts] = useState([]);

  const { data: productsData } = useQuery(GQL_PRODUCT.GET_NAME_AND_PRESENTATIONS, {
    variables: { limit: 0, includeBaseInPresentations: true },
  });

  useEffect(() => {
    if (productsData && productsData.productsV2) {
      const tmpProducts = productsData.productsV2.rows.map(el => ({
        label: `${String(el.code).padStart(3, '0')} ${el.name.trim()} ${
          el.sku ? ` | SKU ${el.sku.trim()}` : ''
        } ${el.barcode ? ` | C.B. ${el.barcode.trim()}` : ''}`.trim(),
        value: el.id,
      }));
      setProducts(tmpProducts);
    }
  }, [productsData]);

  const handleAction = (e, data) => {
    const { startDate: start, endDate: end, branchId } = data;
    filter({ start, end, productId: values.productId ? values.productId.value : null, branchId });
  };

  useEffect(() => {
    if (searchFieldVar && searchFieldVar.trim() !== '')
      filter({ ...filterVar, like: searchFieldVar });
  }, [searchFieldVar]);

  const handleAutocompleteChange = (e, name, value) => {
    setValues(values => ({ ...values, [name]: value }));
  };

  return (
    <FilterDrawer handleAction={handleAction} showDates showBranches>
      <Typography variant='subtitle1'>Producto</Typography>
      <Autocomplete
        name='productId'
        variant='outlined'
        onChange={handleAutocompleteChange}
        options={products}
        value={values.productId}
        noFormat
      />
    </FilterDrawer>
  );
};

export default FilterDialog;
