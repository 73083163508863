import { gql } from '@apollo/client';

const GET = gql`
  query Promotions($limit: Int, $offset: Int, $start: Date, $end: Date, $branchId: [ID]) {
    promotions(limit: $limit, offset: $offset, start: $start, end: $end, branchId: $branchId) {
      count
      rows {
        id
        name
        fileId
        Branch {
          name
        }
        createdAt
        start
        end
        availableDays
      }
    }
  }
`;

const GET_BY_ID = gql`
  query Promotion($id: ID!) {
    promotion(id: $id) {
      id
      name
      Branch {
        name
      }
      createdAt
      start
      end
      availableDays
      total
      quantity
      chargedQuantity
      startTime
      endTime
      type
      ClassificationPromotions {
        id
        classificationId
        price
        quantity
        Classification {
          name
        }
      }
      ProductPromotions {
        id
        productId
        productPresentationId
        price
        quantity
        Product {
          name
        }
        ProductSize {
          name
        }
      }
    }
  }
`;

const DELETE = gql`
  mutation DeletePromotion($id: ID!) {
    deletePromotion(id: $id) {
      id
    }
  }
`;

const CREATE = gql`
  mutation CreatePromotion(
    $branchId: ID
    $name: String!
    $availableDays: [Int]
    $start: Date
    $end: Date
    $total: Float
    $type: Int
    $quantity: Float
    $chargedQuantity: Float
    $startTime: String
    $endTime: String
    $ProductPromotions: [ProductPromotionData]
    $ClassificationPromotions: [ClassificationPromotionData]
  ) {
    createPromotion(
      input: {
        branchId: $branchId
        name: $name
        availableDays: $availableDays
        start: $start
        end: $end
        total: $total
        type: $type
        quantity: $quantity
        chargedQuantity: $chargedQuantity
        startTime: $startTime
        endTime: $endTime
        ProductPromotions: $ProductPromotions
        ClassificationPromotions: $ClassificationPromotions
      }
    ) {
      id
      name
      Branch {
        name
      }
      createdAt
      start
      end
      availableDays
    }
  }
`;

const CREATE_PRODUCT_PROMOTION = gql`
  mutation CreateProductPromotion(
    $promotionId: ID!
    $productId: ID!
    $productPresentationId: ID!
    $quantity: Float!
    $price: Float!
  ) {
    createProductPromotion(
      promotionId: $promotionId
      input: {
        promotionId: $promotionId
        productId: $productId
        productPresentationId: $productPresentationId
        quantity: $quantity
        price: $price
      }
    ) {
      id
      productId
      productPresentationId
      price
      quantity
      Product {
        name
      }
      ProductSize {
        name
      }
    }
  }
`;

const DELETE_PRODUCT_PROMOTION = gql`
  mutation DeleteProductPromotion($id: ID!) {
    deleteProductPromotion(id: $id) {
      id
    }
  }
`;

const UPDATE_FILE = gql`
  mutation UpdatePromotionFile($id: ID!, $file: Upload!) {
    updatePromotionFile(id: $id, file: $file) {
      id
    }
  }
`;

const DELETE_FILE = gql`
  mutation DeletePromotionFile($id: ID!, $fileId: ID!) {
    deletePromotionFile(id: $id, fileId: $fileId) {
      id
    }
  }
`;

const UPDATE = gql`
  mutation UpdatePromotion(
    $id: ID!
    $name: String!
    $availableDays: [Int]
    $start: Date
    $end: Date
    $total: Float
    $quantity: Float
    $chargedQuantity: Float
    $startTime: String
    $endTime: String
  ) {
    updatePromotion(
      id: $id
      input: {
        name: $name
        availableDays: $availableDays
        start: $start
        end: $end
        total: $total
        quantity: $quantity
        chargedQuantity: $chargedQuantity
        startTime: $startTime
        endTime: $endTime
      }
    ) {
      id
      name
      fileId
      createdAt
      start
      end
      availableDays
    }
  }
`;

export default {
  GET,
  GET_BY_ID,
  DELETE,
  CREATE,
  CREATE_PRODUCT_PROMOTION,
  DELETE_PRODUCT_PROMOTION,
  UPDATE_FILE,
  DELETE_FILE,
  UPDATE,
};
