import React, { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import DialogComponent from '../../../../../../presentationals/Dialog';
import FooterDialogComponent from '../../../../../../presentationals/Dialog/Footer';
import ActionBranchCreatePurchaseOrderButtonComponent from './ActionButton';
import BodyBranchCreatePurchaseOrderDialogComponent from './Body';
var BranchCreatePurchaseOrderDialogComponent = forwardRef(function (props, ref) {
    var bodyBranchCreatePurchaseOrderDialogRef = useRef(null);
    var _a = useState(false), open = _a[0], setOpen = _a[1];
    var handleClose = function () {
        setOpen(false);
    };
    var handleAction = function (values) {
        props.getValues(values);
        props.onChange({ Branch: values });
        handleClose();
    };
    var handleOpen = function () {
        setOpen(true);
    };
    var handleGetValues = function () { var _a; return (_a = bodyBranchCreatePurchaseOrderDialogRef.current) === null || _a === void 0 ? void 0 : _a.getValues(); };
    useImperativeHandle(ref, function () { return ({
        open: handleOpen,
    }); });
    return (React.createElement(DialogComponent, { title: 'Sucursal', fullScreen: true, open: open, onClose: handleClose },
        React.createElement("div", { className: 'flex flex-1 flex-col' },
            React.createElement(BodyBranchCreatePurchaseOrderDialogComponent, { ref: bodyBranchCreatePurchaseOrderDialogRef, defaultValues: props.defaultValues }),
            React.createElement(FooterDialogComponent, null,
                React.createElement("div", null),
                React.createElement(ActionBranchCreatePurchaseOrderButtonComponent, { getValues: handleGetValues, onClick: handleAction })))));
});
export default BranchCreatePurchaseOrderDialogComponent;
