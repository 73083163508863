import React, { useEffect, useState } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import AllowSwitchComponent from '../helpers/AllowModuleSwitch';
import SelectTransactionAccountComponent from './SelectTransactionAccount';
var SettingsAllowedModulesComponent = function (props) {
    var _a = useState(false), isExpanded = _a[0], setIsExpanded = _a[1];
    useEffect(function () {
        if (!props.isAllowed)
            setIsExpanded(false);
    }, [props.isAllowed]);
    var handleChange = function (event, isExpanded) {
        if (!props.isAllowed)
            return;
        setIsExpanded(isExpanded);
    };
    return (React.createElement(Accordion, { className: 'border-0 border-t border-solid border-gray-200', onChange: handleChange, expanded: isExpanded, disableGutters: true },
        React.createElement(AccordionSummary, { expandIcon: props.isAllowed && React.createElement(ExpandMoreIcon, { className: 'p-0' }), "aria-controls": "".concat(props.label, "-content"), id: "".concat(props.label, "-header") },
            React.createElement(AllowSwitchComponent, { module: props.module, isAllowed: props.isAllowed, label: props.label })),
        React.createElement(AccordionDetails, null,
            React.createElement("div", { className: 'font-medium text-lg mb-1' }, "Configuraci\u00F3n de ".concat(props.label.toLowerCase())),
            React.createElement(SelectTransactionAccountComponent, { module: props.module, id: props.bankAccountId }))));
};
export default SettingsAllowedModulesComponent;
