import React, { useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import BodyCreateclassificationComponent from '../../Create/Body';
import FooterDialogComponent from '../../../../presentationals/Dialog/Footer';
import EditButtonClassificationComponent from './EditButton';
import GQL_CLASSIFICATION from '../../../../apollo/gql/classification';
import useApolloLazyQuery from '../../../../hooks/useApolloLazyQuery';
import LoadingProgress from '../../../../presentationals/LoadingProgress';
import ErrorDataComponent from '../../../../presentationals/ErrorData';
import { classificationForm } from '../../../../apollo/cache';
import getDefaultClassificationValues from '../../helpers/getDefaultClassificationValues';
var GeneralEditClassificationComponent = function () {
    var params = useParams();
    var _a = useApolloLazyQuery(GQL_CLASSIFICATION.GET_CLASSIFICATION_BY_ID), getClassification = _a[0], _b = _a[1], loading = _b.loading, data = _b.data;
    useEffect(function () {
        if (params.classificationId)
            getClassification({ variables: { id: params.classificationId } });
    }, [params]);
    useEffect(function () {
        if (data && params) {
            var defaultValues = getDefaultClassificationValues(data);
            classificationForm(defaultValues);
        }
    }, [data, params]);
    var editButtonClassificationRef = useRef(null);
    var handleCreate = function () {
        var _a;
        (_a = editButtonClassificationRef.current) === null || _a === void 0 ? void 0 : _a.onPressEnter();
    };
    if (loading)
        return React.createElement(LoadingProgress, null);
    if (!data)
        return React.createElement(ErrorDataComponent, null);
    return (React.createElement("div", { className: 'flex flex-1 flex-col h-screen overflow-hidden mt-1' },
        React.createElement(BodyCreateclassificationComponent, { onPressEnter: handleCreate }),
        React.createElement(FooterDialogComponent, null,
            React.createElement("div", null),
            React.createElement(EditButtonClassificationComponent, { ref: editButtonClassificationRef }))));
};
export default GeneralEditClassificationComponent;
