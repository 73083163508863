var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useEffect, useState } from 'react';
import { useReactiveVar } from '@apollo/client';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import FooterDialogComponent from '../../../../../presentationals/Dialog/Footer';
import ConfirmPickupButtonComponent from '../ConfirmPickupButton';
import ItemPickupRentComponent from './Item';
import { PickupItemForm, isEditPickupItemOpen } from '../../../../../apollo/cache';
import setNewPickupItem from './helpers/setNewPickupItem';
import getInputErrorFormat from '../../../../sale.v2/Action/helpers/getInputErrorFormat';
import { currencyFormat } from '../../../../../helpers';
import PrintOptionFooterConfirmDialogSaleV2Component from '../../../../sale.v2/ConfirmDialog/Footer/PrintOption';
var BodyPickupRentComponent = function (props) {
    var PickupItemFormVar = useReactiveVar(PickupItemForm);
    var isEditPickupItemOpenVar = useReactiveVar(isEditPickupItemOpen);
    var _a = useState([]), data = _a[0], setData = _a[1];
    useEffect(function () {
        var f = function () {
            try {
                var newData = setNewPickupItem(data, PickupItemFormVar);
                setData(newData);
                PickupItemForm({});
            }
            catch (error) {
                getInputErrorFormat(error);
            }
        };
        if (Object.keys(PickupItemFormVar).length > 0 && !isEditPickupItemOpenVar)
            f();
    }, [PickupItemFormVar, isEditPickupItemOpenVar]);
    useEffect(function () {
        if (props.SaleProducts)
            setData(props.SaleProducts.map(function (el) { return (__assign(__assign({}, el), { receivedQty: el.quantity.toString(), commentary: el.commentary })); }));
    }, [props.SaleProducts]);
    return (React.createElement("div", { className: 'flex flex-1 flex-col' },
        React.createElement("div", { className: 'flex flex-1 overflow-auto' },
            React.createElement(TableContainer, null,
                React.createElement(Table, { size: 'small' },
                    React.createElement(TableHead, null,
                        React.createElement(TableRow, null,
                            React.createElement(TableCell, null),
                            React.createElement(TableCell, { className: 'text-center bg-slate-400', colSpan: 2 }, "Cantidad"),
                            React.createElement(TableCell, { className: 'text-center bg-slate-300', colSpan: 3 })),
                        React.createElement(TableRow, null,
                            React.createElement(TableCell, null, "Producto"),
                            React.createElement(TableCell, { className: 'text-center bg-slate-400' }, "Entregado"),
                            React.createElement(TableCell, { className: 'text-center bg-slate-400' }, "Recibido"),
                            React.createElement(TableCell, { className: 'bg-slate-200' }, "Comentarios"))),
                    React.createElement(TableBody, null, data.map(function (el) { return (React.createElement(ItemPickupRentComponent, { key: el.id, receivedQty: el.receivedQty, commentary: el.commentary, productSaleId: el.id },
                        React.createElement(TableCell, null, el.name),
                        React.createElement(TableCell, { className: 'text-center bg-slate-400' }, currencyFormat(el.quantity)),
                        React.createElement(TableCell, { className: "text-center bg-slate-400 ".concat(el.quantity.toString() !== el.receivedQty ? 'font-semibold' : '') }, el.receivedQty ? currencyFormat(el.receivedQty) : '-'),
                        React.createElement(TableCell, { className: 'bg-slate-200' }, el.commentary || '-'))); }))))),
        React.createElement(FooterDialogComponent, null,
            React.createElement("div", null,
                React.createElement(PrintOptionFooterConfirmDialogSaleV2Component, null)),
            React.createElement(ConfirmPickupButtonComponent, { Products: data }))));
};
export default BodyPickupRentComponent;
