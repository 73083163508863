import React, { useState, useEffect } from 'react';

import { useReactiveVar, useMutation, useLazyQuery } from '@apollo/client';

import EditIcon from '@mui/icons-material/EditOutlined';
import SaveIcon from '@mui/icons-material/SaveOutlined';
import CloseIcon from '@mui/icons-material/DeleteOutline';

import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Typography from '@mui/material/Typography';

import {
  form,
  handleContinue,
  step,
  actionForm,
  purchaseOrderId,
  handleContinueConfiguration,
  adjustPricePurchase,
  editPurchaseProduct,
  isStoreLoading,
  productsToPrint,
} from '../../../../../../apollo/cache';
import { currencyFormat } from '../../../../../../helpers';
import getSession from '../../../../../../helpers/getSession';

import IconButton from '../../../../../../presentationals/IconButton';
import TextField from '../../../../../../presentationals/FormComponents/TextField';

import GQL_ACCOUNT_PAYABLE from '../../../../../payable/_gql';
import GQL from '../../../../_gql';
import Totals from './Totals';

const defaultValues = {
  id: null,
  receivedQuantity: 0,
  discount: 0,
  unitPrice: 0,
  adjustPrice: false,
  percentAdjust: 0,
  price: 0,
};

const defaultTotals = {
  subtotal: 0,
  iva: 0,
  ieps: 0,
  total: 0,
};

const BodyTableComponent = () => {
  const handleContinueVar = useReactiveVar(handleContinue);
  const stepVar = useReactiveVar(step);
  const formVar = useReactiveVar(form);
  const purchaseOrderIdVar = useReactiveVar(purchaseOrderId);

  const [rows, setRows] = useState([]);
  const [errors, setErrors] = useState({});
  const [purchaseOrderDetailId, setPurchaseOrderDetailId] = useState(null);
  const [values, setValues] = useState(defaultValues);
  const [totals, setTotals] = useState(defaultTotals);
  const [products, setProducts] = useState([]);

  const [getPurchaseOrderId, { data: purchaseOrderData }] = useLazyQuery(GQL.GET_BY_ID);
  const [closePurchaseOrder, { loading: closeLoading }] = useMutation(GQL.CLOSE_PURCHASE_ORDER_V2, {
    update(cache, { data }) {
      cache.modify({
        fields: {
          accountPayables(cachedAccountPayable) {
            const AccountPayable = { ...data.closePurchaseOrderV2.AccountPayable };
            const newAccountPayableRef = cache.writeFragment({
              data: AccountPayable,
              fragment: GQL_ACCOUNT_PAYABLE.FRAGMENT_NEW_ACCOUNT_PAYABLE,
            });
            return {
              ...cachedAccountPayable,
              count: cachedAccountPayable.count + 1,
              rows: [newAccountPayableRef, ...cachedAccountPayable.rows],
            };
          },
        },
      });
    },
  });

  useEffect(() => {
    if (
      purchaseOrderData?.purchaseOrder?.PurchaseOrderDetails &&
      purchaseOrderData.purchaseOrder.PurchaseOrderDetails.length > 0
    ) {
      const tmp = purchaseOrderData.purchaseOrder.PurchaseOrderDetails.map(el => {
        if (!el.Product) return false;
        return {
          name: el?.Product?.name || el.concept,
          total: parseInt(el.quantity),
          Presentation: el.ProductPresentation
            ? {
                id: el.ProductPresentation.id,
                name: el.ProductPresentation.name,
                sku: el.ProductPresentation.sku,
                barcode: el.ProductPresentation.barcode,
                altBarcode: el.ProductPresentation.altBarcode,
              }
            : null,
        };
      }).filter(tmp => tmp);
      setProducts(tmp);
    }
  }, [purchaseOrderData]);

  useEffect(() => {
    isStoreLoading(closeLoading);
  }, [closeLoading]);

  const storePursache = async () => {
    try {
      const session = await getSession();
      const Products = rows.map(el => {
        return {
          receivedQuantity: parseFloat(el.receivedQuantity),
          unitPrice: parseFloat(el.unitPrice),
          discount: parseFloat(el.discount),
          purchaseOrderDetailId: el.id,
          // adjustPrice: el.adjustPrice,
          // percentAdjust: parseFloat(el.percentAdjust),
          price: el.price,
        };
      });
      const variables = {
        id: purchaseOrderIdVar,
        date: formVar.pursacheDate,
        subFolio: formVar.subFolio,
        paymentForm: formVar.paymentForm,
        description: formVar.description,
        type: formVar.type,
        Products,
      };
      await closePurchaseOrder({ variables });

      if (parseInt(session?.Company.id) === 71 && products.length > 0)
        productsToPrint([true, products]);

      form({});
      actionForm({ ...actionForm(), isOpen: false, action: '' });
      step(0);
      setRows([]);
      purchaseOrderId(null);
      handleContinueConfiguration({});
    } catch (e) {
      setErrors({ [e.name]: e.message });
    }
  };

  useEffect(() => {
    if (purchaseOrderIdVar) getPurchaseOrderId({ variables: { id: purchaseOrderIdVar } });
  }, [purchaseOrderIdVar]);

  useEffect(() => {
    if (purchaseOrderData) {
      let subtotal = 0;
      let iva = 0;
      let ieps = 0;
      const data = purchaseOrderData.purchaseOrder.PurchaseOrderDetails.map(el => {
        const total = el.total - el.ivaTotal - el.iepsTotal;
        subtotal = subtotal + total;
        iva = iva + el.ivaTotal;
        ieps = ieps + el.iepsTotal;
        return {
          id: el.id,
          product:
            parseInt(el.type) === 1
              ? el.Product
                ? el.Product.name
                : ''
              : String(el.concept || '').trim(),
          quantity: el.quantity,
          presentation: el.ProductPresentation ? el.ProductPresentation.name : '',
          receivedQuantity: el.receivedQuantity ? el.receivedQuantity : el.quantity,
          unitPrice: el.unitPrice ? el.unitPrice : 0,
          discount: el.discount ? el.discount : 0,
          total,
        };
      });
      setRows(data);
      setTotals({ subtotal, iva, ieps, total: subtotal + iva + ieps });
    }
  }, [purchaseOrderData]);

  useEffect(() => {
    if (handleContinueVar && stepVar === 1) {
      storePursache();
      handleContinue(false);
    }
  }, [handleContinueVar, stepVar]);

  const handleChange = (name, value) => {
    setValues(values => ({ ...values, [name]: value }));
  };

  const handleOpenEdit = (e, params) => {
    setPurchaseOrderDetailId(params.id);
    setValues(values => ({ ...values, ...params }));
    editPurchaseProduct(true);
  };

  const handleCancel = () => {
    setPurchaseOrderDetailId(null);
    setValues(defaultValues);
    setErrors({});
    editPurchaseProduct(false);
    adjustPricePurchase(false);
  };

  const handleStore = async () => {
    try {
      if (
        Number.isNaN(parseFloat(values.receivedQuantity)) ||
        parseFloat(values.receivedQuantity) < 0
      ) {
        const e = new Error('Proporciona una cantidad.');
        e.name = 'receivedQuantity';
        throw e;
      }
      if (Number.isNaN(parseFloat(values.unitPrice)) || parseFloat(values.unitPrice) < 0) {
        const e = new Error('Proporciona un precio unitario.');
        e.name = 'unitPrice';
        throw e;
      }
      if (Number.isNaN(parseFloat(values.discount)) || parseFloat(values.discount) < 0) {
        const e = new Error('Proporciona un descuento.');
        e.name = 'discount';
        throw e;
      }
      let subtotal = 0;
      let iva = 0;
      let ieps = 0;
      const tmp = rows.map(el => {
        if (purchaseOrderDetailId !== el.id) {
          subtotal = subtotal + el.total;
          iva = iva + el.ivaTotal;
          ieps = ieps + el.iepsTotal;
          return el;
        }
        const total = el.quantity * parseFloat(values.unitPrice) - parseFloat(values.discount);
        subtotal = subtotal + total;
        const tmpIva = el.iva ? parseFloat(el.iva) / 100 : 0;
        const tmpIeps = el.ieps ? parseFloat(el.ieps) / 100 : 0;
        iva = iva + total * tmpIva;
        ieps = ieps + total * tmpIeps;
        return {
          ...el,
          receivedQuantity: values.receivedQuantity,
          unitPrice: values.unitPrice,
          discount: values.discount,
          total,
          adjustPrice: values.adjustPrice,
          percentAdjust: parseFloat(values.percentAdjust),
          price: values.adjustPrice ? parseFloat(values.price) : null,
        };
      });
      setRows(tmp);
      setTotals({ subtotal, iva, ieps, total: subtotal + iva + ieps });
      handleCancel();
    } catch (e) {
      console.log(e);
      setErrors({ [e.name]: e.message });
    }
  };

  const handleChangeUnitPrice = (name, value) => {
    const session = getSession();
    let { price } = values;
    if (value && parseFloat(value) > 0)
      price =
        parseInt(session?.Company.companyId) === 71
          ? parseFloat(value) / ((100 - parseFloat(values.percentAdjust || 0)) / 100)
          : parseFloat(value) * (1 + parseFloat(values.percentAdjust || 0) / 100);

    setValues(values => ({ ...values, [name]: value, price }));
  };

  return (
    <TableBody>
      {errors.table && (
        <TableRow>
          <TableCell colSpan={5}>
            <Typography color='error' align='center' gutterBottom>
              {errors.table}
            </Typography>
          </TableCell>
        </TableRow>
      )}
      {rows.map((el, i) => (
        <TableRow key={i}>
          <TableCell padding='none'>
            <div className='flex'>
              {purchaseOrderDetailId !== el.id ? (
                <IconButton
                  label='Editar'
                  size='small'
                  action={handleOpenEdit}
                  params={{
                    id: el.id,
                  }}
                  icon={<EditIcon fontSize='small' />}
                />
              ) : (
                <>
                  <IconButton
                    size='small'
                    label='Guardar Cambios'
                    action={handleStore}
                    icon={<SaveIcon fontSize='small' />}
                  />
                  <IconButton
                    size='small'
                    label='Cancelar'
                    action={handleCancel}
                    icon={<CloseIcon fontSize='small' />}
                  />
                </>
              )}
            </div>
          </TableCell>
          <TableCell>{el.product}</TableCell>
          <TableCell>{el.presentation}</TableCell>
          <TableCell align='center'>{currencyFormat(el.quantity)}</TableCell>
          <TableCell align='center'>
            {purchaseOrderDetailId !== el.id ? (
              currencyFormat(el.receivedQuantity)
            ) : (
              <TextField
                name='receivedQuantity'
                type='number'
                onChange={handleChange}
                value={values.receivedQuantity}
                error={errors.receivedQuantity}
              />
            )}
          </TableCell>
          <TableCell align='right'>
            {purchaseOrderDetailId !== el.id ? (
              `$${currencyFormat(el.discount)}`
            ) : (
              <TextField
                name='discount'
                type='number'
                onChange={handleChange}
                value={values.discount}
                error={errors.discount}
              />
            )}
          </TableCell>
          <TableCell align='right'>
            {purchaseOrderDetailId !== el.id ? (
              `$${currencyFormat(el.unitPrice)}`
            ) : (
              <TextField
                name='unitPrice'
                type='number'
                onChange={handleChangeUnitPrice}
                value={values.unitPrice}
                error={errors.unitPrice}
              />
            )}
          </TableCell>
          {/*{purchaseOrderDetailId === el.id && (
            <TableCell>
              <FormControlLabel
                control={
                  <Switch
                    checked={values.adjustPrice}
                    onChange={handleSwitchChange}
                    name='adjustPrice'
                  />
                }
              />
            </TableCell>
          )}
          {values.adjustPrice && (
            <TableCell align='right'>
              <TextField
                name='percentAdjust'
                type='number'
                onChange={handleChangePercent}
                value={values.percentAdjust}
                error={errors.percentAdjust}
              />
            </TableCell>
          )}
          {values.adjustPrice && (
            <TableCell align='right'>{currencyFormat(values.price)}</TableCell>
          )}*/}
          <TableCell align='right'>{`$${currencyFormat(el.total)}`}</TableCell>
        </TableRow>
      ))}
      <Totals totals={totals} />
    </TableBody>
  );
};

export default BodyTableComponent;
