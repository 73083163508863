import React from 'react';
import { useNavigate } from 'react-router-dom';
import DeleteIcon from '@mui/icons-material/Delete';
var getManufactureActions = function () {
    var navigate = useNavigate();
    return [
        {
            icon: React.createElement(DeleteIcon, null),
            label: 'Cancelar manufactura',
            onClick: function (identifier) {
                navigate("".concat(identifier, "/cancel"));
            },
        },
    ];
};
export default getManufactureActions;
