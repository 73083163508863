import React, { useEffect } from 'react';
import { Skeleton } from '@mui/material';
import useTransferProductsToBranchStore from '../../../../../../../store/inventoryReport/transferProductsToBranchStore';
import useApolloLazyQuery from '../../../../../../../hooks/useApolloLazyQuery';
import GQL_BRANCH from '../../../../../../../apollo/gql/branch';
var DestinationBranchLabelComponent = function () {
    var destinationBranchId = useTransferProductsToBranchStore(function (state) { return state.destinationBranchId; });
    var _a = useApolloLazyQuery(GQL_BRANCH.GET_BRANCH_BY_ID), getBranch = _a[0], _b = _a[1], data = _b.data, loading = _b.loading;
    useEffect(function () {
        if (destinationBranchId)
            getBranch({ variables: { id: destinationBranchId } });
    }, [destinationBranchId]);
    if (loading)
        return React.createElement(Skeleton, { className: 'tracking-tighter leading-none' });
    return (React.createElement("div", { className: 'tracking-tighter leading-none' }, destinationBranchId && data ? data.name : 'Indefinido'));
};
export default DestinationBranchLabelComponent;
