var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import { useReactiveVar } from '@apollo/client';
import GlobalInvoiceTypeFieldComponent from './InvoiceTypeField';
import getDefaultGlobalInvoiceValues from './helpers/getDefaultValues';
import TextFieldComponent from '../../../presentationals/FormComponents/TextField';
import { inputError } from '../../../apollo/cache';
import SatUnitKeyComponent from '../../../presentationals/FormComponents/SatUnitKeyField';
import SatProductKeyComponent from '../../../presentationals/FormComponents/SatProductKeyField';
import SelectPaymentFormFieldComponent from '../../../presentationals/FormComponents/SelectPaymentFormField';
import DateFieldComponent from '../../../presentationals/FormComponents/DateField';
import SelectUseCfdiFieldComponent from '../../../presentationals/FormComponents/SelectUseCfdiField';
import CustomerFieldComponent from '../../../presentationals/FormComponents/CustomerField';
import SelectPaymentMethodFieldComponent from '../../../presentationals/FormComponents/SelectPaymentMethodField';
import AdjuntGlobalInvoiceProductsXlsButtonComponent from './AdjuntXlsButton';
var FormGlobalInvoiveComponent = forwardRef(function (props, ref) {
    var customerFieldRef = useRef(null);
    var errors = useReactiveVar(inputError);
    var _a = useState(getDefaultGlobalInvoiceValues), values = _a[0], setValues = _a[1];
    var handleRadioChange = function (name, value) {
        if (name === 'type' && props.onGlobalInvoiceTypeChange)
            props.onGlobalInvoiceTypeChange(value);
        setValues(function (values) {
            var _a;
            return (__assign(__assign({}, values), (_a = {}, _a[name] = value, _a)));
        });
    };
    var handleChange = function (name, value) {
        setValues(function (values) {
            var _a;
            return (__assign(__assign({}, values), (_a = {}, _a[name] = value, _a)));
        });
    };
    var handleDateChange = function (name, value) {
        setValues(function (values) {
            var _a;
            return (__assign(__assign({}, values), (_a = {}, _a[name] = value, _a)));
        });
    };
    var handleCustomerChange = function (name, value) {
        props.onCustomerChange && props.onCustomerChange(value);
        setValues(function (values) {
            var _a;
            return (__assign(__assign({}, values), (_a = {}, _a[name] = value, _a)));
        });
    };
    var handleSetCustomer = function (props) {
        var _a;
        (_a = customerFieldRef.current) === null || _a === void 0 ? void 0 : _a.updateValue(props.value);
        setValues(function (values) { return (__assign(__assign({}, values), { customer: props.value || null })); });
    };
    var handleClear = function () {
        var _a;
        var defaultValues = getDefaultGlobalInvoiceValues();
        setValues(defaultValues);
        (_a = customerFieldRef.current) === null || _a === void 0 ? void 0 : _a.handleClear();
    };
    var handleFileChange = function (name, file) {
        setValues(function (values) {
            var _a;
            return (__assign(__assign({}, values), (_a = {}, _a[name] = file, _a)));
        });
    };
    var handleGetValues = function () { return values; };
    useImperativeHandle(ref, function () { return ({
        getValues: handleGetValues,
        setCustomer: handleSetCustomer,
        clear: handleClear,
    }); });
    return (React.createElement("div", { className: 'flex flex-1 flex-col sm:top-0 sm:left-1 sm:right-1 sm:bottom-0 sm:absolute' },
        React.createElement(GlobalInvoiceTypeFieldComponent, { name: 'type', value: values.type, onChange: handleRadioChange }),
        values.type === 2 && (React.createElement(React.Fragment, null,
            React.createElement(TextFieldComponent, { name: 'concept', label: 'Concepto de la factura', value: values.concept, onChange: handleChange, error: errors.concept }),
            React.createElement(TextFieldComponent, { name: 'unitPrice', label: 'Importe antes de impuestos', type: 'number', onChange: handleChange, value: values.unitPrice, error: errors.unitPrice }),
            React.createElement(TextFieldComponent, { name: 'ivaPercent', label: 'Porcentaje de IVA', type: 'number', onChange: handleChange, value: values.ivaPercent, error: errors.ivaPercent }),
            React.createElement(SatUnitKeyComponent, { name: 'satUnitKey', onChange: handleChange, value: values.satUnitKey, error: errors.satUnitKey }),
            React.createElement(SatProductKeyComponent, { name: 'satProductKey', onChange: handleChange, value: values.satProductKey, error: errors.satProductKey }))),
        React.createElement(DateFieldComponent, { label: 'Fecha de emisi\u00F3n', value: values.date, onChange: handleDateChange }),
        React.createElement(CustomerFieldComponent, { ref: customerFieldRef, onChange: handleCustomerChange, helperText: 'Si no se selecciona un cliente, la factura se emitir\u00E1 a p\u00FAblico en general.' }),
        React.createElement(SelectPaymentFormFieldComponent, { name: 'paymentForm', onChange: handleChange, defaultValue: values.paymentForm }),
        React.createElement(SelectPaymentMethodFieldComponent, { name: 'paymentMethod', onChange: handleChange }),
        React.createElement(SelectUseCfdiFieldComponent, { name: 'useCfdi', onChange: handleChange, error: errors.useCfdi, value: values.useCfdi }),
        React.createElement(TextFieldComponent, { name: 'commentary', label: 'Comentario para el documento', value: values.commentary, error: errors.commentary, onChange: handleChange, rows: 3, multiline: true }),
        values.type === 4 && (React.createElement(AdjuntGlobalInvoiceProductsXlsButtonComponent, { name: 'file', onChange: handleFileChange }))));
});
export default FormGlobalInvoiveComponent;
