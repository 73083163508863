import { TableRow } from '@mui/material';
import React from 'react';
import { isEditDescriptionHistoryCustomerFormOpen } from '../../../../../../apollo/cache';
var TableRowHistoryCustomerComponent = function (props) {
    var handleClick = function () {
        isEditDescriptionHistoryCustomerFormOpen([props.eventId, props.description]);
    };
    return React.createElement(TableRow, { onClick: handleClick }, props.children);
};
export default TableRowHistoryCustomerComponent;
