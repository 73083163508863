import React, { useEffect } from 'react';

import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdfOutlined';
import CodeIcon from '@mui/icons-material/CodeOutlined';
import EmailIcon from '@mui/icons-material/EmailOutlined';

import { actions } from '../../apollo/cache';
import CONFIG from '../../config/config.json';

const ActionComponent = () => {
  const handlePDF = (e, { uuid }) => {
    window.open(`${CONFIG['restServer'].uri}/files/invoices/${uuid}/pdf`);
  };

  const handleXML = (e, data) => {
    window.open(`${CONFIG.restServer.uri}/files/invoices/${data.uuid}/xml`);
  };

  const handleReSend = (e, data) => {
    // data.Sale && setIsOpenReSend([true, data.Sale.id, data.id]);
    console.log(data.id);
  };

  useEffect(() => {
    actions([
      {
        icon: <EmailIcon />,
        label: 'Reenviar PDF',
        onClick: handleReSend,
      },
      {
        icon: <CodeIcon />,
        label: 'Descargar XML',
        onClick: handleXML,
      },
      {
        icon: <PictureAsPdfIcon />,
        label: 'Consultar PDF',
        onClick: handlePDF,
      },
    ]);
    return () => {
      actions([]);
    };
  }, []);

  return <div />;
};

export default ActionComponent;
