import React from 'react';
import { TableCell, TableRow } from '@mui/material';
import getQuestionTypeById from './helpers/getQuestionTypeById';
import DeleteSurveyQuestionButtonComponent from './DeleteButton';
var ItemEditSurveyQuestionComponent = function (props) {
    return (React.createElement(TableRow, null,
        React.createElement(TableCell, null, props.question),
        React.createElement(TableCell, null, getQuestionTypeById(props.type)),
        React.createElement(TableCell, { align: 'right' },
            React.createElement(DeleteSurveyQuestionButtonComponent, { id: props.id }))));
};
export default ItemEditSurveyQuestionComponent;
