var getPresentationColumnsToExport = function () {
    return [
        { value: 'presentationId', label: 'Identificador de la presentación' },
        { value: 'presentationName', label: 'Nombre de la presentación' },
        { value: 'presentationPrice', label: 'Precio de venta' },
        { value: 'presentationQuantity', label: 'Cantidad proporcional a la base' },
        { value: 'presentationLimit', label: 'Límite uso' },
        { value: 'presentationForPursache', label: '¿La presentación está disponible para compra?' },
        { value: 'presentationForSale', label: '¿La presentación está disponible para venta?' },
        { value: 'presentationSku', label: 'SKU' },
        { value: 'presentationBarcode', label: 'Código de barras' },
        { value: 'presentationDidiPrice', label: 'Precio de venta en Didi' },
        { value: 'presentationRappiPrice', label: 'Precio de venta en Rappi' },
        { value: 'presentationUberPrice', label: 'Precio de venta en Uber' },
        { value: 'presentationShippingPrice', label: 'Precio de venta en servicio a domicilio' },
        { value: 'presentationPickupPrice', label: 'Precio de venta en servicio a ordene y recoja' },
        { value: 'presentationPickupPrice', label: 'Precio de venta en servicio a ordene y recoja' },
    ];
};
export default getPresentationColumnsToExport;
