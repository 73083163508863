var getSurveyMenuValueFromPath = function (pathname) {
    var pathnameParts = pathname.split('/');
    var path = pathnameParts[pathnameParts.length - 1];
    switch (path) {
        case 'apply':
            return 0;
        case 'results':
            return 1;
        case 'edit':
            return 2;
        default:
            return 0;
    }
};
export default getSurveyMenuValueFromPath;
