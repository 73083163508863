import React, { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import BranchCreatePurchaseOrderDialogComponent from './Dialog';
import getDefaultCreateBranchPurchaseOrderValues from './Dialog/Body/helpers/getDefaultValues';
import BranchSubtitlePurchaseOrderComponent from './BranchSubtitle';
var BranchCreatePurchaseOrderComponent = forwardRef(function (props, ref) {
    var branchCreatePurchaseOrderDialogRef = useRef(null);
    var defaultValues = getDefaultCreateBranchPurchaseOrderValues();
    var _a = useState(defaultValues), values = _a[0], setValues = _a[1];
    var handleClick = function () {
        var _a;
        (_a = branchCreatePurchaseOrderDialogRef.current) === null || _a === void 0 ? void 0 : _a.open();
    };
    var handleGetValues = function (values) {
        setValues(values);
    };
    var getValues = function () { return values; };
    useImperativeHandle(ref, function () { return ({
        getValues: getValues,
    }); });
    return (React.createElement(React.Fragment, null,
        React.createElement(BranchSubtitlePurchaseOrderComponent, { branchId: values.branchId, onClick: handleClick }),
        React.createElement(BranchCreatePurchaseOrderDialogComponent, { getValues: handleGetValues, onChange: props.onChange, defaultValues: values, ref: branchCreatePurchaseOrderDialogRef })));
});
export default BranchCreatePurchaseOrderComponent;
