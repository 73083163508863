import GET_CALENDARS from './getCalendars';
import FRAGMENT_CALENDAR from './fragmentCalendar';
import CREATE_CALENDARS from './createCalendars';
import CREATE_CALENDAR from './createCalendar';
import GET_CALENDAR_BY_ID from './getCalendarById';
import UPDATE_CALENDAR_FILE from './updateCalendarFile';
import DELETE_CALENDAR_FILE from './deleteCalendarFile';
import GET_CALENDAR_SETTINGS from './getCalendarSettings';
import UPDATE_CALENDAR_SETTINGS from './updateCalendarSettings';
import EMPLOYEE_CALENDAR_SETTINGS from './employeeCalendarSettings';
import UPDATE_EMPLOYEE_CALENDAR_SETTINGS from './updateEmployeeCalendarSettings';
var GQL_CALENDAR = {
    CREATE_CALENDAR: CREATE_CALENDAR,
    CREATE_CALENDARS: CREATE_CALENDARS,
    DELETE_CALENDAR_FILE: DELETE_CALENDAR_FILE,
    EMPLOYEE_CALENDAR_SETTINGS: EMPLOYEE_CALENDAR_SETTINGS,
    FRAGMENT_CALENDAR: FRAGMENT_CALENDAR,
    GET_CALENDAR_BY_ID: GET_CALENDAR_BY_ID,
    GET_CALENDAR_SETTINGS: GET_CALENDAR_SETTINGS,
    GET_CALENDARS: GET_CALENDARS,
    UPDATE_CALENDAR_FILE: UPDATE_CALENDAR_FILE,
    UPDATE_CALENDAR_SETTINGS: UPDATE_CALENDAR_SETTINGS,
    UPDATE_EMPLOYEE_CALENDAR_SETTINGS: UPDATE_EMPLOYEE_CALENDAR_SETTINGS,
};
export default GQL_CALENDAR;
