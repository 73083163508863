import React from 'react';

import ButtonMUI from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';

const ButtonComponent = ({ isLoading = false, onClick, disabled = false, ...props }) => {
  const handleClick = e => {
    e.preventDefault();
    onClick(e, props.params);
  };
  return (
    <ButtonMUI
      variant={props.variant || 'contained'}
      color={props.color || 'primary'}
      onClick={handleClick}
      disabled={disabled || isLoading}
      {...props}
    >
      {isLoading && <CircularProgress size={18} color='inherit' className='mr-3' />}
      {props.label}
    </ButtonMUI>
  );
};

export default ButtonComponent;
