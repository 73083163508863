import React from 'react';
import InvoiceComplementReportComponent from '../containers/invoiceComplementReport';
import GeneralInvoiceComplementComponent from '../containers/invoiceComplementReport/InvoiceComplement/General';
import CancelInvoiceComplementComponent from '../containers/invoiceComplementReport/InvoiceComplement/Cancel';
import InvoiceComplementLayout from './InvoiceComplementLayout';
var getInvoiceComplementChildrenRoutes = function () {
    return [
        { index: true, element: React.createElement(InvoiceComplementReportComponent, null) },
        {
            path: ':invoiceComplementId',
            element: React.createElement(InvoiceComplementLayout, null),
            children: [
                { index: true, element: React.createElement(GeneralInvoiceComplementComponent, null) },
                { path: 'cancel', element: React.createElement(CancelInvoiceComplementComponent, null) },
            ],
        },
    ];
};
export default getInvoiceComplementChildrenRoutes;
