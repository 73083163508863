var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import useTransferProductsToBranchStore from '../../../../store/inventoryReport/transferProductsToBranchStore';
import ItemBodyProductsToTransferFormComponent from './Item';
import CreateTransferProductButtonComponent from './CreateButton';
import BranchToTransferBodyProductsToTransferFormComponent from './BranchToTransfer';
import ProductsToTransferViewButton from './ViewButton';
var BodyProductsToTransferFormComponent = function (props) {
    var products = useTransferProductsToBranchStore(function (state) { return state.products; });
    return (React.createElement("div", { className: 'flex flex-1 flex-col h-full' },
        React.createElement("div", { className: 'flex flex-row justify-between py-1 px-4 bg-gray-200 items-center' },
            React.createElement("div", { className: 'leading-0 text-lgfont-medium pb-1' }, "Transferencia de productos"),
            React.createElement(ProductsToTransferViewButton, null)),
        React.createElement(BranchToTransferBodyProductsToTransferFormComponent, null),
        React.createElement("div", { className: 'flex flex-1 flex-col basis-0 overflow-auto' },
            React.createElement(TableContainer, null,
                React.createElement(Table, { size: 'small', stickyHeader: true },
                    React.createElement(TableHead, null,
                        React.createElement(TableRow, null,
                            React.createElement(TableCell, null, "Producto"),
                            React.createElement(TableCell, { className: 'text-center' }, "Cantidad"),
                            React.createElement(TableCell, null))),
                    React.createElement(TableBody, null, products.map(function (el) { return (React.createElement(ItemBodyProductsToTransferFormComponent, __assign({ key: el.presentationId }, el))); }))))),
        React.createElement("div", { className: 'flex flex-row justify-end px-3 py-2' },
            React.createElement(CreateTransferProductButtonComponent, { onCreate: props.onCreate }))));
};
export default BodyProductsToTransferFormComponent;
