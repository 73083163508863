import React from 'react';
import ReceivableComponent from '../containers/receivable/Receivable';
import CustomerReceivablesComponent from '../containers/receivable/CustomerReceivables';
var getAccountReceivableChildrenRoutes = function () {
    return [
        {
            children: [
                { index: true, element: React.createElement(CustomerReceivablesComponent, null) },
                { path: ':accountReceivableId', element: React.createElement(ReceivableComponent, null) },
            ],
        },
    ];
};
export default getAccountReceivableChildrenRoutes;
