var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useEffect, useState } from 'react';
import PaymentsIcon from '@mui/icons-material/Payments';
import ListItemPermissionEditRoleComponent from '../helpers/ListItem';
import SwitchPermissionFieldComponent from '../helpers/SwitchPermissionField';
import SelectPermissionFieldComponent from '../helpers/SelectPermissionField';
import getDefaultBranchOptions from '../helpers/getDefaultBrachOptions';
var defaultReceivablePermissionValues = {
    createNewReceivable: false,
    getReceivable: 0,
};
var ReceivablePermissionEditRoleComponent = function (props) {
    var defaultBranchOptions = getDefaultBranchOptions();
    var _a = useState(defaultReceivablePermissionValues), values = _a[0], setValues = _a[1];
    useEffect(function () {
        setValues(function (values) { return (__assign(__assign({}, values), props.Permissions)); });
    }, [props.Permissions]);
    return (React.createElement(ListItemPermissionEditRoleComponent, { icon: React.createElement(PaymentsIcon, null), name: 'Cobranza / Cuentas por cobrar' },
        React.createElement("div", { className: 'mx-4 py-3 grid grid-cols-1 md:grid-cols-3 gap-4' },
            React.createElement("div", { className: 'flex flex-col' },
                React.createElement(SwitchPermissionFieldComponent, __assign({}, props, { name: 'createNewReceivable', label: 'Crear cuentas por cobrar', defaultValue: values.createNewReceivable })),
                React.createElement(SelectPermissionFieldComponent, __assign({}, props, { name: 'getReceivable', label: 'Consultar datos generales y contenido de las cuentas por cobrar', defaultValue: values.getReceivable, options: defaultBranchOptions }))))));
};
export default ReceivablePermissionEditRoleComponent;
