import { Alert } from '@mui/material';
import React from 'react';
import { isCreateProductLimitInventoryOpen } from '../../../../../../apollo/cache';
var NoProductLimitInventoryAlertComponent = function () {
    var handleOpenCreateProductPrice = function (e) {
        e.preventDefault();
        isCreateProductLimitInventoryOpen(true);
    };
    return (React.createElement(Alert, { severity: 'info', className: 'mt-3 w-full sm:w-1/2' },
        "De momento, no cuentas con l\u00EDmites de inventario configurados, por lo que no podr\u00E1s recibir notificaciones ni podr\u00E1s crear requisiciones de forma autom\u00E1tica. Si lo deseas, puedes",
        ' ',
        React.createElement("a", { href: '#', className: 'text-black font-medium', onClick: handleOpenCreateProductPrice }, "registrar un nuevo l\u00EDmite de inventario."),
        "."));
};
export default NoProductLimitInventoryAlertComponent;
