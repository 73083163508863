import React, { useEffect } from 'react';

import { useLazyQuery } from '@apollo/client';

import Table from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';

import FormDialog from '../../presentationals/FormDialog';
import { dateTimeFormat, currencyFormat } from '../../helpers';

import GQL from '../purchaseOrderProducts.report/_gql';

const Component = props => {
  const [getQuery, { data, loading }] = useLazyQuery(GQL.GET);

  useEffect(() => {
    if (props.productId && props.branchId)
      getQuery({
        variables: {
          limit: 0,
          productId: props.productId,
          branchId: props.branchId,
          start: props.start,
          end: props.end,
        },
      });
  }, [props.productId, props.branchId, props.start, props.end]);

  const handleClose = () => {
    props.handleClose();
  };

  return (
    <FormDialog
      title='Detalle por Producto'
      isOpen={props.isOpen}
      handleClose={handleClose}
      isLoading={loading}
      hideAction
      noActions
      noPadding
    >
      <TableContainer className='mb-2'>
        <Table size='small'>
          <TableHead>
            <TableRow className='bg-[#f6f6f6]'>
              <TableCell>Fecha</TableCell>
              <TableCell>Folio</TableCell>
              <TableCell>Producto</TableCell>
              <TableCell>Proveedor</TableCell>
              <TableCell align='center'>Cant.</TableCell>
              <TableCell align='right'>Importe</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data &&
              data.purchaseOrderProducts.rows.map((el, i) => (
                <TableRow key={i}>
                  <TableCell>{dateTimeFormat(el.createdAt)}</TableCell>
                  <TableCell>{`${el.PurchaseOrder.folio}-${el.PurchaseOrder.serial}`}</TableCell>
                  <TableCell>{el.Product.name}</TableCell>
                  <TableCell>{el.PurchaseOrder.Provider.name}</TableCell>
                  <TableCell align='center'>{currencyFormat(el.quantity)}</TableCell>
                  <TableCell align='right'>{currencyFormat(el.total)}</TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </FormDialog>
  );
};

export default Component;
