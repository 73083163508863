import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useReactiveVar } from '@apollo/client';
import { isSameDay } from 'date-fns';
import { filter } from '../../../../apollo/cache';
import getSpanishFormatDate from '../../../../helpers/getSpanishFormatDate';
import useGetBranches from '../../../../hooks/useGetBranches';
import getSession from '../../../../helpers/getSession';
var DateSalesSummaryIncomeReportComponent = function () {
    var _a = useGetBranches(), Branches = _a.data, loading = _a.loading;
    var filterVar = useReactiveVar(filter);
    var _b = useState(true), isToday = _b[0], setIsToday = _b[1];
    var _c = useState(null), branchName = _c[0], setBranchName = _c[1];
    useEffect(function () {
        var _a;
        if (Branches && Branches.rows && Branches.rows.length > 0) {
            var currentDate = new Date();
            setIsToday(!filterVar.start ||
                !!(isSameDay(currentDate, new Date(filterVar.start)) &&
                    isSameDay(currentDate, filterVar.end)));
            var session = getSession();
            var selectedBranchId_1 = filterVar.branchId
                ? filterVar.branchId
                : (_a = session === null || session === void 0 ? void 0 : session.Company) === null || _a === void 0 ? void 0 : _a.Branch.id;
            var BranchesArray = Branches.rows;
            var Branch = BranchesArray.find(function (el) { return el.id === selectedBranchId_1; });
            setBranchName(Branch ? Branch.name : 'Indefinido');
        }
    }, [filterVar, Branches]);
    if (!branchName || loading)
        return React.createElement("div", null);
    return (React.createElement("div", { className: 'flex flex-col' },
        React.createElement("div", { className: 'flex justify-between items-end text-sm' },
            React.createElement("div", { className: 'font-bold text-base text-white' }, branchName),
            React.createElement(Link, { to: '../sale/ticket', className: 'no-underline hover:underline text-white' }, "Ver m\u00E1s")),
        React.createElement("div", { className: 'text-base text-[#37a8ff]' }, isToday
            ? getSpanishFormatDate()
            : isSameDay(filterVar.start, filterVar.end)
                ? getSpanishFormatDate(filterVar.start.toString())
                : "".concat(getSpanishFormatDate(filterVar.start.toString()), " al\n      ").concat(getSpanishFormatDate(filterVar.end.toString())))));
};
export default DateSalesSummaryIncomeReportComponent;
