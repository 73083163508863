import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { useParams } from 'react-router-dom';
import React from 'react';
import useDateRangeFieldStore from '../../../../../store/filterInputs/dateRangeFieldStore';
import useApolloQuery from '../../../../../hooks/useApolloQuery';
import GQL_SALE from '../../../../../apollo/gql/sale';
import { dateTimeFormat } from '../../../../../helpers';
import convertToCurrencyLabel from '../../../../../helpers/convertToCurrencyLabel';
import LoadingProgress from '../../../../../presentationals/LoadingProgress';
import ErrorDataComponent from '../../../../../presentationals/ErrorData';
var DetailedReportSalesDetailComponent = function () {
    var dates = useDateRangeFieldStore(function (state) { return state.value; });
    var params = useParams();
    var _a = useApolloQuery(GQL_SALE.GET_SALE_PRODUCTS, {
        variables: { start: dates[0], end: dates[1], branchId: params.branchId },
    }), data = _a.data, loading = _a.loading;
    if (loading)
        return React.createElement(LoadingProgress, null);
    if (!data)
        return React.createElement(ErrorDataComponent, null);
    return (React.createElement("div", { className: 'flex flex-1 flex-col sm:basis-0 overflow-auto' },
        React.createElement(TableContainer, null,
            React.createElement(Table, { stickyHeader: true, size: 'small' },
                React.createElement(TableHead, null,
                    React.createElement(TableRow, null,
                        React.createElement(TableCell, null, "Creado el"),
                        React.createElement(TableCell, null, "Folio"),
                        React.createElement(TableCell, null, "Producto"),
                        React.createElement(TableCell, { className: 'text-center' }, "Cantidad"),
                        React.createElement(TableCell, null, "Importe"))),
                React.createElement(TableBody, null, data.map(function (el, i) { return (React.createElement(TableRow, { key: i },
                    React.createElement(TableCell, null, dateTimeFormat(el.clientCreatedAt)),
                    React.createElement(TableCell, null, el.folio),
                    React.createElement(TableCell, null, el.product),
                    React.createElement(TableCell, { className: 'text-center' }, el.quantity),
                    React.createElement(TableCell, null, convertToCurrencyLabel(el.total)))); }))))));
};
export default DetailedReportSalesDetailComponent;
