import { Popover } from '@mui/material';
import React, { forwardRef, useImperativeHandle, useState } from 'react';
import BodyDiscountPopoverComponent from './Body';
var RHFDiscountPopoverComponent = forwardRef(function (props, ref) {
    var _a = useState(null), popover = _a[0], setPopover = _a[1];
    var handleClose = function () {
        setPopover(null);
    };
    var handleOpen = function (anchorEl) {
        setPopover(anchorEl);
    };
    useImperativeHandle(ref, function () { return ({
        open: handleOpen,
    }); });
    return (React.createElement(Popover, { id: "DiscountPopover".concat(props.name), open: !!popover, anchorEl: popover, onClose: handleClose, anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'right',
        }, transformOrigin: {
            vertical: 'top',
            horizontal: 'right',
        }, slotProps: {
            paper: {
                className: 'w-64',
            },
        } },
        React.createElement(BodyDiscountPopoverComponent, { onClick: handleClose, name: props.name })));
});
export default RHFDiscountPopoverComponent;
