var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import { useReactiveVar } from '@apollo/client';
import SubtitleDialogComponent from '../../../../presentationals/Dialog/helpers/Subtitle';
import { boxForm, inputError } from '../../../../apollo/cache';
import TextFieldComponent from '../../../../presentationals/FormComponents/TextField';
var BodyCreateBoxComponent = function () {
    var values = useReactiveVar(boxForm);
    var errors = useReactiveVar(inputError);
    var handleChange = function (name, value) {
        var _a;
        boxForm(__assign(__assign({}, values), (_a = {}, _a[name] = value, _a)));
    };
    return (React.createElement("div", { className: 'flex-1 px-6 py-4 flex flex-col md:grid md:grid-cols-4 md:gap-4 overflow-auto' },
        React.createElement("div", { className: 'mb-4 md:mb-0' },
            React.createElement(SubtitleDialogComponent, { label: 'Datos generales' }),
            React.createElement(TextFieldComponent, { label: 'Nombre', name: 'name', value: values.name, error: errors.name, onChange: handleChange }),
            React.createElement(TextFieldComponent, { label: 'Alto (cm)', name: 'height', value: values.height, error: errors.height, onChange: handleChange }),
            React.createElement(TextFieldComponent, { label: 'Largo (cm)', name: 'length', value: values.length, error: errors.length, onChange: handleChange }),
            React.createElement(TextFieldComponent, { label: 'Ancho (cm)', name: 'width', value: values.width, error: errors.width, onChange: handleChange }),
            React.createElement(TextFieldComponent, { label: 'Peso m\u00E1ximo (kg)', name: 'weight', value: values.weight, error: errors.weight, onChange: handleChange }))));
};
export default BodyCreateBoxComponent;
