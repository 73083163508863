import { Card } from '@mui/material';
import React, { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import PaymentFormSalesGlobalInvoiceFieldComponent from './PaymentFormField';
import CustomerSalesGlobalInvoiceFieldComponent from './CustomerField';
import TableSalesGlobalInvoiveComponent from './Table';
import ApplySearchSalesGlobalInvoiceButton from './ApplyButton';
import DateRangeSalesGlobalInvoiceFieldComponent from './DateRangeField';
import getDefaultPaymentFormSalesGlobalInvoice from './PaymentFormField/helpers/getDefaultPaymentFormSalesGlobalInvoice';
import getDefaultDateRangeSalesGlobalInvoice from './DateRangeField/helpers/getDefaultDateRangeSalesGlobalInvoice';
import getDefaultCustomerSalesGlobalInvoice from './CustomerField/helpers/getDefaultCustomerSalesGlobalInvoice';
import BranchFieldComponent from '../../../presentationals/FilterInputs/BranchField';
var SalesGlobalInvoiveComponent = forwardRef(function (props, ref) {
    var dateRangeSalesGlobalInvoiceFieldRef = useRef(null);
    var paymentFormSalesGlobalInvoiceFieldRef = useRef(null);
    var customerSalesGlobalInvoiceFieldRef = useRef(null);
    var tableSalesGlobalInvoiveRef = useRef(null);
    var _a = useState(true), allow = _a[0], setAllow = _a[1];
    var handleGetCustomer = function () {
        var _a;
        var defaultCustomer = getDefaultCustomerSalesGlobalInvoice();
        return ((_a = customerSalesGlobalInvoiceFieldRef.current) === null || _a === void 0 ? void 0 : _a.getCustomer()) || defaultCustomer;
    };
    var handleClick = function () {
        var _a, _b, _c;
        var dates = ((_a = dateRangeSalesGlobalInvoiceFieldRef.current) === null || _a === void 0 ? void 0 : _a.getDate()) ||
            getDefaultDateRangeSalesGlobalInvoice();
        var paymentForm = ((_b = paymentFormSalesGlobalInvoiceFieldRef.current) === null || _b === void 0 ? void 0 : _b.getPaymentForm()) ||
            getDefaultPaymentFormSalesGlobalInvoice();
        var customer = handleGetCustomer();
        (_c = tableSalesGlobalInvoiveRef.current) === null || _c === void 0 ? void 0 : _c.filter(dates, paymentForm, customer);
    };
    var handleGetSales = function () { var _a; return ((_a = tableSalesGlobalInvoiveRef.current) === null || _a === void 0 ? void 0 : _a.getSales()) || []; };
    var handleSetCustomer = function (id) {
        var _a, _b, _c, _d;
        (_a = customerSalesGlobalInvoiceFieldRef.current) === null || _a === void 0 ? void 0 : _a.setCustomer(id);
        var dates = ((_b = dateRangeSalesGlobalInvoiceFieldRef.current) === null || _b === void 0 ? void 0 : _b.getDate()) ||
            getDefaultDateRangeSalesGlobalInvoice();
        var paymentForm = ((_c = paymentFormSalesGlobalInvoiceFieldRef.current) === null || _c === void 0 ? void 0 : _c.getPaymentForm()) ||
            getDefaultPaymentFormSalesGlobalInvoice();
        (_d = tableSalesGlobalInvoiveRef.current) === null || _d === void 0 ? void 0 : _d.filter(dates, paymentForm, { name: '', value: id });
    };
    var handleSetGlobalInvoiceType = function (type) {
        if (type === 4)
            setAllow(false);
        else
            setAllow(true);
    };
    useImperativeHandle(ref, function () { return ({
        getCustomer: handleGetCustomer,
        getSales: handleGetSales,
        setCustomer: handleSetCustomer,
        setGlobalInvoiceType: handleSetGlobalInvoiceType,
    }); });
    return (React.createElement(Card, { className: 'sm:flex sm:flex-1 sm:flex-col mt-3 sm:mt-0' },
        React.createElement("div", { className: 'flex flex-col' },
            React.createElement("div", { className: 'flex flex-row px-3 py-2' },
                React.createElement("div", { className: 'text-base font-medium text-sky-600' }, "B\u00FAsqueda de ventas")),
            React.createElement("div", { className: 'flex flex-row border-0 border-t border-[#d6d6d6] border-solid px-1 py-1 justify-between items-center' },
                React.createElement("div", { className: 'flex flex-col sm:flex-row items-start' },
                    React.createElement(DateRangeSalesGlobalInvoiceFieldComponent, { disabled: !allow, ref: dateRangeSalesGlobalInvoiceFieldRef }),
                    React.createElement(PaymentFormSalesGlobalInvoiceFieldComponent, { disabled: !allow, ref: paymentFormSalesGlobalInvoiceFieldRef }),
                    React.createElement(CustomerSalesGlobalInvoiceFieldComponent, { disabled: !allow, onChange: props.onCustomerChange, ref: customerSalesGlobalInvoiceFieldRef }),
                    React.createElement(BranchFieldComponent, null)),
                React.createElement("div", { className: 'mr-2' },
                    React.createElement(ApplySearchSalesGlobalInvoiceButton, { disabled: !allow, onClick: handleClick })))),
        React.createElement(TableSalesGlobalInvoiveComponent, { disabled: !allow, ref: tableSalesGlobalInvoiveRef })));
});
export default SalesGlobalInvoiveComponent;
