import React from 'react';
import { Typography } from '@mui/material';
import Item from './Item';
import FormColorComponent from './Form';
import AddButtonColorEcommerceProductComponent from './AddButton';
var ColorEcommerceProductComponent = function (props) {
    return (React.createElement("div", null,
        React.createElement("div", { className: 'flex flex-1 flex-row justify-between items-center mb-1' },
            React.createElement(Typography, { variant: 'h6' }, "Configuraci\u00F3n de color"),
            React.createElement(AddButtonColorEcommerceProductComponent, null)),
        props.Colors.length === 0 ? (React.createElement("div", { className: 'flex flex-1 my-1 text-base text-gray-700' }, "De momento no cuentas con colores configurados.")) : (React.createElement("div", { className: 'flex flex-1 flex-wrap flex-row' }, props.Colors.map(function (el) { return (React.createElement(Item, { key: el.id, id: el.id, fileId: el.fileId, background: el.attribute, label: el.attribute })); }))),
        React.createElement(FormColorComponent, null)));
};
export default ColorEcommerceProductComponent;
