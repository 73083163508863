import React from 'react';

import CloseIcon from '@mui/icons-material/Close';
import { IconButton } from '@mui/material';

import { isEditPurchaseOrderItemOpen } from '../../../../../../../apollo/cache';

const CloseButtonHeaderItemEditFormProductComponent = () => {
  const handleClose = () => {
    isEditPurchaseOrderItemOpen(false);
  };
  return (
    <IconButton onClick={handleClose}>
      <CloseIcon />
    </IconButton>
  );
};

export default CloseButtonHeaderItemEditFormProductComponent;
