import React from 'react';
// import { useReactiveVar } from '@apollo/client';
import { useParams } from 'react-router-dom';
import LoadingProgress from '../../presentationals/LoadingProgress';
import ModuleV3Component from '../../presentationals/Module.v3';
import ErrorDataComponent from '../../presentationals/ErrorData';
// import { filter } from '../../apollo/cache';
// import { Obj } from '../../types/general';
// import RedirectEditFormToRouteComponent from '../../presentationals/RedirectEditFormToRoute';
import useGetSaleProductPresentationRotation from '../saleProductRotationReport/helpers/useGetSaleProductPresentationRotation';
import FilterSaleProductPresentationRotationReportComponent from '../saleProductRotationReport/Filter';
var EmployeeDetiledSaleReportComponent = function () {
    // const filterVar = useReactiveVar(filter);
    var params = useParams();
    // const [queryParams, setQueryParams] = useState<Obj | undefined>(undefined);
    var _a = useGetSaleProductPresentationRotation({
        employeeId: params.employeeId,
    }), data = _a.data, loading = _a.loading;
    var header = [
        { Header: 'SKU', accessor: 'sku' },
        { Header: 'Producto', accessor: 'product' },
        { Header: 'Cantidad', accessor: 'quantity' },
        { Header: 'Importe', accessor: 'total' },
    ];
    // useEffect(() => {
    //   if (filterVar.start && filterVar.end)
    //     setQueryParams({ start: filterVar.start.toString(), end: filterVar.end.toString() });
    // }, [filterVar]);
    if (loading)
        return React.createElement(LoadingProgress, null);
    if (!data)
        return React.createElement(ErrorDataComponent, null);
    return (React.createElement(React.Fragment, null,
        React.createElement(ModuleV3Component, { title: 'Productos', searchLabel: 'productos', filter: true, data: data, header: header, hideAdd: true, isLoading: loading, showReturnButton: true }),
        React.createElement(FilterSaleProductPresentationRotationReportComponent, null)));
};
export default EmployeeDetiledSaleReportComponent;
