import React from 'react';
import { Outlet } from 'react-router-dom';
import MenuCompanyLayoutComponent from './Menu';
var CompanyLayoutComponent = function () {
    return (React.createElement("div", { className: 'flex flex-1 flex-col' },
        React.createElement("div", { className: 'flex items-center px-4 py-2' },
            React.createElement("div", { className: 'text-2xl text-gray-700 tracking-tight' }, "Configuraci\u00F3n de la empresa")),
        React.createElement(MenuCompanyLayoutComponent, null),
        React.createElement("div", { className: 'flex flex-1 flex-col h-full overflow-hidden' },
            React.createElement(Outlet, null))));
};
export default CompanyLayoutComponent;
