import React from 'react';
import { useReactiveVar } from '@apollo/client';
import { isECUpdateColorFormOpen, isECUpdateSecColorFormOpen, } from '../../../../../../../apollo/cache';
import FooterDialogComponent from '../../../../../../../presentationals/Dialog/Footer';
import DeleteColorButtonEcommerceEditFormComponent from './DeleteButton';
import DialogComponent from '../../../../../../../presentationals/Dialog';
import BodyFormColorEcommerceEditFormComponent from './Body';
var FormColorEcommerceProductComponent = function () {
    var isECUpdateColorFormOpenVar = useReactiveVar(isECUpdateColorFormOpen);
    if (!isECUpdateColorFormOpenVar[1])
        return React.createElement("div", null);
    var handleClose = function () {
        isECUpdateColorFormOpen([false, null]);
        isECUpdateSecColorFormOpen([false, null]);
    };
    return (React.createElement(DialogComponent, { title: 'Configuraci\u00F3n del color', fullScreen: true, open: isECUpdateColorFormOpenVar[0], onClose: handleClose },
        React.createElement(BodyFormColorEcommerceEditFormComponent, { productAttributeId: isECUpdateColorFormOpenVar[1] }),
        React.createElement(FooterDialogComponent, null,
            React.createElement("div", null),
            React.createElement(DeleteColorButtonEcommerceEditFormComponent, { productAttributeId: isECUpdateColorFormOpenVar[1] }))));
};
export default FormColorEcommerceProductComponent;
