import React, { useState, useEffect } from 'react';

import { useLazyQuery } from '@apollo/client';

import { startOfDay, endOfDay } from 'date-fns';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import FormDialog from '../../presentationals/FormDialog';
import DateField from '../../presentationals/DateField';

import ProductsXlsx from './products.xlsx';

import GQL from './_gql';

const defaultValues = {
  startDate: startOfDay(new Date()),
  endDate: endOfDay(new Date()),
};

const Component = props => {
  const [values, setValues] = useState(defaultValues);
  const [loading, setLoading] = useState(false);

  const [getAdjustInventoriesProducts, { data, loading: loadingData }] = useLazyQuery(
    GQL.GET_ADJUST_INVENTORIES_PRODUCTS,
  );

  const handleClose = () => {
    setLoading(false);
    props.handleClose();
  };

  const handleDateChange = (name, value) => {
    setValues(values => ({ ...values, [name]: value }));
  };

  const handleAction = async () => {
    await getAdjustInventoriesProducts({
      variables: { start: values.startDate, end: values.endDate },
    });
  };

  useEffect(() => {
    setLoading(true);
    if (data) {
      ProductsXlsx(data.adjustInventoriesProducts || []);
      props.handleClose();
    }
    setLoading(false);
  }, [data]);

  return (
    <FormDialog
      title='Descargar archivo'
      isOpen={props.isOpen || false}
      isLoading={loading || loadingData}
      handleClose={handleClose}
      handleAction={handleAction}
    >
      <Typography variant='subtitle1'>Rango de fechas:</Typography>
      <Grid container spacing={1}>
        <Grid item xs={12} md={6}>
          <DateField
            variant='outlined'
            name='startDate'
            value={values.startDate}
            onChange={handleDateChange}
            noFormat
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <DateField
            variant='outlined'
            name='endDate'
            value={values.endDate}
            onChange={handleDateChange}
            noFormat
          />
        </Grid>
      </Grid>
    </FormDialog>
  );
};

export default Component;
