var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import { useReactiveVar } from '@apollo/client';
import SelectFieldComponent from '../../../../presentationals/FormComponents/SelectField';
import { inputError } from '../../../../apollo/cache';
import TextFieldComponent from '../../../../presentationals/FormComponents/TextField';
import SwitchComponent from '../../../../presentationals/FormComponents/Switch';
import UtilityPercentFieldProductComponent from '../../../sale.v2/ChooseProduct/ProductDialog/Create/Body/helpers/UtilityPercentField';
import getSalePriceByCostAndUtilityPercent from '../../../../helpers/getSalePriceByCostAndUtilityPercent';
import ProductSearchProductPurchaseOrderFieldComponent from './ProductField';
import getPercentAdjustByCostAndSalePrice from '../../../../helpers/getPercentAdjustByCostAndSalePrice';
var SearchProductPurchaseOrderFormComponent = forwardRef(function (props, ref) {
    var _a;
    var productSearchProductPurchaseOrderFieldRef = useRef(null);
    var defaultValues = {
        Product: null,
        productPresentationId: '',
        percentAdjust: '',
        quantity: '1',
        cost: '',
        salePrice: '',
        updateSalePrice: false,
    };
    var errors = useReactiveVar(inputError);
    var _b = useState(props.defaultValues || defaultValues), values = _b[0], setValues = _b[1];
    var _c = useState([]), ProductPresentations = _c[0], setProductPresentations = _c[1];
    var _d = useState(false), isForSale = _d[0], setIsForSale = _d[1];
    var _e = useState(1), action = _e[0], setAction = _e[1];
    var handleChange = function (name, value) {
        var _a, _b, _c;
        inputError({});
        if (name === 'cost' && parseFloat(values.percentAdjust) > 0) {
            var price = getSalePriceByCostAndUtilityPercent(parseFloat(value), parseFloat(values.percentAdjust));
            setValues(__assign(__assign({}, values), (_a = {}, _a[name] = value, _a.salePrice = price.toFixed(2).toString(), _a)));
            return;
        }
        if (name === 'percentAdjust' && parseFloat(values.cost) > 0) {
            var price = getSalePriceByCostAndUtilityPercent(parseFloat(values.cost), parseFloat(value));
            setValues(__assign(__assign({}, values), (_b = {}, _b[name] = value, _b.salePrice = price.toFixed(2).toString(), _b)));
            return;
        }
        if (name === 'salePrice' && parseFloat(values.cost) > 0) {
            var percentAdjust = getPercentAdjustByCostAndSalePrice(parseFloat(values.cost), parseFloat(value));
            setValues(__assign(__assign({}, values), (_c = {}, _c[name] = value, _c.percentAdjust = percentAdjust.toFixed(2).toString(), _c)));
            return;
        }
        setValues(function (values) {
            var _a;
            return (__assign(__assign({}, values), (_a = {}, _a[name] = value, _a)));
        });
    };
    var handleSwitchChange = function (name, value) {
        setValues(function (values) {
            var _a;
            return (__assign(__assign({}, values), (_a = {}, _a[name] = value, _a)));
        });
    };
    var clearForm = function () {
        var _a;
        (_a = productSearchProductPurchaseOrderFieldRef.current) === null || _a === void 0 ? void 0 : _a.clear();
        setValues(defaultValues);
    };
    var handleProductClear = function () {
        setValues(function (values) { return (__assign(__assign({}, values), { Product: null })); });
    };
    var handleProductChange = function (data) {
        if (data) {
            setProductPresentations(ProductPresentations);
            setIsForSale(data.isForSale);
            setValues(function (values) { return (__assign(__assign({}, values), { productPresentationId: data.productPresentationId, Product: data.Product, cost: props.defaultValues ? props.defaultValues.cost : data.cost, salePrice: props.defaultValues ? props.defaultValues.salePrice : data.salePrice, percentAdjust: props.defaultValues ? props.defaultValues.percentAdjust : data.percentAdjust })); });
        }
        else {
            setProductPresentations([]);
            setValues(function (values) { return (__assign(__assign({}, values), { productPresentationId: '' })); });
            setIsForSale(false);
        }
    };
    var getAction = function (action) {
        setAction(action);
    };
    useImperativeHandle(ref, function () { return ({
        getValues: function () { return values; },
        clearForm: clearForm,
        getAction: getAction,
    }); });
    return (React.createElement("div", { className: "flex flex-col flex-1 py-3 ".concat(props.isPositionAbsolute ? 'sm:top-0 sm:left-1 sm:right-1 sm:bottom-0 sm:absolute' : '') },
        React.createElement("div", { className: 'text-base font-medium text-sky-600 mb-2' }, "Producto de la compra"),
        React.createElement(ProductSearchProductPurchaseOrderFieldComponent, { id: props.form, onClear: handleProductClear, onChange: handleProductChange, initialValue: (_a = values.Product) === null || _a === void 0 ? void 0 : _a.id, ref: productSearchProductPurchaseOrderFieldRef }),
        values.Product && ProductPresentations.length > 1 && (React.createElement(SelectFieldComponent, { name: 'productPresentationId', label: 'Selecciona la presentaci\u00F3n del producto', onChange: handleChange, options: ProductPresentations.map(function (el) { return ({ value: el.id, label: el.name }); }), value: values.productPresentationId, error: errors.productPresentationId })),
        React.createElement(TextFieldComponent, { name: 'quantity', label: 'Cantidad', onChange: handleChange, value: values.quantity, error: errors.quantity }),
        React.createElement(TextFieldComponent, { name: 'cost', label: 'Precio unitario de compra', onChange: handleChange, value: values.cost, error: errors.cost }),
        action === 3 && isForSale && (React.createElement(React.Fragment, null,
            React.createElement(SwitchComponent, { name: 'updateSalePrice', label: '\u00BFDesea actualizar el precio de venta?', onChange: handleSwitchChange, defaultValue: values.updateSalePrice }),
            values.updateSalePrice && (React.createElement(React.Fragment, null,
                React.createElement(TextFieldComponent, { name: 'salePrice', label: 'Precio de venta por unidad', onChange: handleChange, value: values.salePrice, error: errors.salePrice, helperText: 'Formula: [costo \u00F7 (100 \u2013 % de utilidad)] x 100' }),
                React.createElement(UtilityPercentFieldProductComponent, { onChange: handleChange, value: values.percentAdjust, error: errors.percentAdjust })))))));
});
export default SearchProductPurchaseOrderFormComponent;
