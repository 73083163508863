import { gql } from '@apollo/client';

const GET = gql`
  query TransferDetails(
    $branchId: [ID], $classificationId: [ID], $productId: ID, $start: Date, $end: Date $limit: Int $offset: Int, $name: String
  ) {
    transferDetails(
      branchId: $branchId, classificationId: $classificationId, productId: $productId, start: $start, end: $end, limit: $limit offset: $offset, like: $name
    ) {
      count
      rows {
        id
        Product {
          id
          name
          price
          code
          PresentationBase {
            id
            name
          }
          Classification {
            id
            name
          }
        }
        TransferProducts {
          cost
          unitCost
          quantity
          Branch {
            id
            name
          }
        }
      }
      totals {
        quantity
        unitCost
        cost
      }
    }
  }
`;

const GET_PRODUCT_TRANSFER = gql`
  query TransferProductDetails($limit: Int, $start: Date, $end: Date, $branchTransferId: ID, $productId: ID) {
    transferProductDetails(limit: $limit, start: $start, end: $end, branchTransferId: $branchTransferId, productId: $productId) {
      count
      rows {
        id
        status
        total
        cost
        productId
        productUnitId
        quantity
        createdAt
        Product {
          name
        }
        ProductUnit {
          name
        }
        ProductPresentation {
          name
        }
        Transfer {
          id
          serial
          folio
        }
      }
    }
  }
`;

export default { GET, GET_PRODUCT_TRANSFER };
