import { useReactiveVar } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import { form, inputError, isAssignPaymentFormOpen } from '../../../../apollo/cache';
import DialogComponent from '../../../../presentationals/Dialog';
import TextFieldComponent from '../../../../presentationals/FormComponents/TextField';
import FooterDialogComponent from '../../../../presentationals/Dialog/Footer';
import CreateAssingPaymentButtonComponent from './CreateButton';
var AssignPaymentDialogComponent = function () {
    var errors = useReactiveVar(inputError);
    var formVar = useReactiveVar(form);
    var isOpen = useReactiveVar(isAssignPaymentFormOpen);
    var _a = useState(''), value = _a[0], setValue = _a[1];
    useEffect(function () {
        console.log(1, formVar);
        if (formVar.currentPayment)
            setValue(formVar.currentPayment);
    }, [formVar]);
    var handleClose = function () {
        isAssignPaymentFormOpen(false);
        form({});
        setValue('');
    };
    var handleChange = function (name, value) {
        setValue(value);
    };
    return (React.createElement(DialogComponent, { open: !!isOpen, title: 'Asignar abono a cuenta por cobrar', onClose: handleClose, fullScreen: true },
        React.createElement("div", { className: 'flex flex-1 flex-col' },
            React.createElement("div", { className: 'grid grid-cols-1 sm:grid-cols-4 flex-1 flex-col px-4 py-3' },
                React.createElement(TextFieldComponent, { label: 'Importe abonado', name: 'payment', value: value, error: errors.payment, onChange: handleChange, type: 'number' })),
            React.createElement(FooterDialogComponent, null,
                React.createElement("div", null),
                React.createElement(CreateAssingPaymentButtonComponent, { onClick: handleClose, value: value })))));
};
export default AssignPaymentDialogComponent;
