import React from 'react';
import { Card } from '@mui/material';
import { useParams } from 'react-router-dom';
import FooterModuleV3Component from '../../../presentationals/Module.v3/Footer';
import GoBackButtonComponent from '../../../presentationals/GoBackButton';
import AccountBalanceButtonComponent from './AccountBalanceButton';
import PaymentButtonComponent from './PaymentButton';
import InvoiceComplementButtonComponent from './InvoiceComplementButton';
import CustomerInformationAccountReceivableComponent from './CustomerInformation';
import AccountReceivableInformationComponent from './AccountReceivableInformation';
import MultiPaymentDialogComponent from './MultiPaymentDialog';
import CreateTransactionAccountDialogComponent from '../../../presentationals/TransactionAccount/CreateDialog';
import AssignPaymentDialogComponent from './AssingPaymentDialog';
import InvoiceComplementDialogComponent from './InvoiceComplementDialog';
var CustomerReceivablesComponent = function () {
    var params = useParams();
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: 'flex flex-1 flex-col' },
            React.createElement("div", { className: 'flex items-center px-4 py-0 border-0 border-b border-[#d6d6d6] border-solid' },
                React.createElement("div", { className: 'flex flex-1 items-center' },
                    React.createElement("div", { className: 'mr-3' },
                        React.createElement(GoBackButtonComponent, { route: '../customer/receivable' })),
                    React.createElement("div", { className: 'text-lg text-gray-700 tracking-tight' }, "Cuentas por cobrar de un cliente")),
                React.createElement("div", null,
                    React.createElement(AccountBalanceButtonComponent, { customerId: params.customerId }),
                    React.createElement(PaymentButtonComponent, { customerId: params.customerId }),
                    React.createElement(InvoiceComplementButtonComponent, { customerId: params.customerId }))),
            React.createElement("div", { className: 'p-3 flex flex-1 flex-col h-full basis-0 overflow-auto' },
                React.createElement(CustomerInformationAccountReceivableComponent, null),
                React.createElement(Card, { className: 'mt-4 flex flex-1 flex-col' },
                    React.createElement(AccountReceivableInformationComponent, null)),
                React.createElement(FooterModuleV3Component, null))),
        React.createElement(MultiPaymentDialogComponent, null),
        React.createElement(CreateTransactionAccountDialogComponent, null),
        React.createElement(AssignPaymentDialogComponent, null),
        React.createElement(InvoiceComplementDialogComponent, null)));
};
export default CustomerReceivablesComponent;
