import React, { useEffect, useState } from 'react';
import { useReactiveVar } from '@apollo/client';
import List from '@mui/material/List';
import DialogComponent from '../../../../../presentationals/Dialog';
import { formatSaleV2, isSaleFormatFormOpen } from '../../../../../apollo/cache';
import Item from './Item';
import TaxesOption from './TaxesOption';
import DuplicateFormat from './DuplicateFormat';
import getSession from '../../../../../helpers/getSession';
import getSaleFormatByCompany from './helpers/getSaleFormatByCompany';
import FolioFormatActionSaleV2Component from './Folio';
import useApolloQuery from '../../../../../hooks/useApolloQuery';
import GQL_COMPANY from '../../../../../apollo/gql/company';
var FormFormatActionSaleV2Component = function () {
    var _a = useState({}), saleFormatToShow = _a[0], setSaleFormatToShow = _a[1];
    var _b = useState(null), companyId = _b[0], setCompanyId = _b[1];
    var isSaleFormatFormOpenVar = useReactiveVar(isSaleFormatFormOpen);
    var _c = useApolloQuery(GQL_COMPANY.GET_ALLOWED_MODULES), data = _c.data, loading = _c.loading;
    useEffect(function () {
        var f = function () {
            var _a;
            var session = getSession();
            if (!(session === null || session === void 0 ? void 0 : session.Company))
                return;
            if (!data)
                return;
            setCompanyId(session.Company.id.toString());
            var response = getSaleFormatByCompany(session === null || session === void 0 ? void 0 : session.Company.id, data);
            setSaleFormatToShow(response);
            if (((_a = session === null || session === void 0 ? void 0 : session.Company) === null || _a === void 0 ? void 0 : _a.id.toString()) === '116') {
                var formatSaleV2Var = formatSaleV2();
                formatSaleV2([7, formatSaleV2Var[1], formatSaleV2Var[2], formatSaleV2Var[3]]);
            }
        };
        f();
    }, [data]);
    if (loading)
        return React.createElement("div", null);
    return (React.createElement(DialogComponent, { hideHeaderCloseButton: true, open: isSaleFormatFormOpenVar },
        React.createElement(List, { component: 'nav', "aria-label": 'SaleFormatList' }, Object.keys(saleFormatToShow).map(function (key) { return (React.createElement(Item, { key: key, id: key, label: saleFormatToShow[key] })); })),
        companyId === '116' ? React.createElement(FolioFormatActionSaleV2Component, null) : React.createElement("div", null),
        React.createElement(TaxesOption, null),
        React.createElement(DuplicateFormat, null)));
};
export default FormFormatActionSaleV2Component;
