var getProductTableSettingColumnLabel = function (key) {
    switch (key) {
        case 'code':
            return 'Código';
        case 'sku':
            return 'SKU';
        case 'prefix':
            return 'Prefijo';
        case 'name':
            return 'Nombre';
        case 'stock':
            return 'Stock';
        case 'price':
            return 'Precio';
        case 'cost':
            return 'Costo';
        case 'classification':
            return 'Clasificación';
        case 'presentation':
            return 'Presentación base';
        case 'barcode':
            return 'Código de barras';
        case 'productKey':
            return 'Clave SAT';
        case 'unitKey':
            return 'Unidad SAT';
        case 'iva':
            return 'IVA';
        case 'ieps':
            return 'IEPS';
        default:
            return 'Indefinido';
    }
};
export default getProductTableSettingColumnLabel;
