import React from 'react';
import { Button } from '@mui/material';
import { isSaleConfirmDialogOpen } from '../../../../apollo/cache';
var FinishButtonHeaderSaleV2Component = function () {
    var handleClick = function () {
        isSaleConfirmDialogOpen(true);
    };
    return (React.createElement(Button, { variant: 'contained', size: 'small', color: 'secondary', className: 'mb-2 sm:mb-0 md:h-full text-base py-2 px-0 sm:text-lg sm:py-4 sm:px-6', onClick: handleClick }, "Finalizar"));
};
export default FinishButtonHeaderSaleV2Component;
