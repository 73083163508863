import React from 'react';
import ButtonFooterDialogComponent from '../../../../../../../presentationals/Dialog/Footer/Button';
import getInputErrorFormat from '../../../../../../sale.v2/Action/helpers/getInputErrorFormat';
import setInputErrorFormat from '../../../../../../sale.v2/Action/helpers/setInputErrorFormat';
import { inputError } from '../../../../../../../apollo/cache';
var ActionGeneralCreatePurchaseOrderButtonComponent = function (props) {
    var handleClick = function () {
        try {
            inputError({});
            var values = props.getValues();
            if (!values)
                return;
            if (!values.providerId)
                throw new Error(setInputErrorFormat('providerId', 'Selecciona el proveedor de la compra.'));
            if (!values.paymentForm)
                throw new Error(setInputErrorFormat('code', 'Selecciona la forma de pago de la compra.'));
            props.onClick(values);
        }
        catch (error) {
            getInputErrorFormat(error);
        }
    };
    return (React.createElement(ButtonFooterDialogComponent, { onClick: handleClick }, "Actualizar informaci\u00F3n"));
};
export default ActionGeneralCreatePurchaseOrderButtonComponent;
