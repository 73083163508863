import React from 'react';
import ClassificationComponent from '../containers/classification';
import CreateClassificationLayoutComponent from './Classification';
import ClassificationLayoutComponent from './Classification/CreateClassificationLayout/ClassificationLayout';
import GeneralEditClassificationComponent from '../containers/classification/Edit/General';
import ImageEditClassificationComponent from '../containers/classification/Edit/Image';
import DeleteClassificationComponent from '../containers/classification/Edit/Delete';
var getClassificationChildrenRoutes = function () {
    return [
        { index: true, element: React.createElement(ClassificationComponent, null) },
        {
            path: 'create',
            element: React.createElement(CreateClassificationLayoutComponent, null),
        },
        {
            path: ':classificationId',
            element: React.createElement(ClassificationLayoutComponent, null),
            children: [
                { index: true, element: React.createElement(GeneralEditClassificationComponent, null) },
                { path: 'image', element: React.createElement(ImageEditClassificationComponent, null) },
                { path: 'delete', element: React.createElement(DeleteClassificationComponent, null) },
            ],
        },
    ];
};
export default getClassificationChildrenRoutes;
