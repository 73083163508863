import React from 'react';

import Container from '../../presentationals/Module.v2/Container';
import Snackbar from '../../presentationals/Snackbar';

import GQL from './_gql';
import Filter from './_filter';
import Form from './_form';
import Action from './_action';

const Rows = [
  { label: 'Fecha', key: 'createdAt', sort: 'text', format: 'dateTime' },
  { label: 'Producto', key: 'Product name', sort: 'text' },
  { label: 'Unidad', key: 'ProductPresentation name', sort: 'text' },
  { label: 'Cantidad', key: 'quantity', sort: 'text' },
  { label: 'Empleado', key: 'Employee firstName && Employee lastName', sort: 'text' },
];
const title = 'Transformación de insumos';
const operation = ['transformation', 'transformations'];

const ProductComponent = () => {
  return <>
    <Container
      title={title}
      operation={operation}
      Rows={Rows}
      create={GQL.CREATE}
      get={GQL.GET}
      delete={GQL.DELETE}
      noEdit
      filter
    />
    <Filter />
    <Form />
    <Snackbar />
    <Action />
  </>;
};

export default ProductComponent;
