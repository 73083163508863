var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useEffect, useState } from 'react';
import AssessmentIcon from '@mui/icons-material/AssessmentOutlined';
import ListItemPermissionEditRoleComponent from '../helpers/ListItem';
import SelectPermissionFieldComponent from '../helpers/SelectPermissionField';
import getDefaultBranchOptions from '../helpers/getDefaultBrachOptions';
var defaultMaintenancePermissionValues = {
    saleProductsRotation: 0,
    generalSaleReport: 0,
    employeeSales: 0,
    customerSales: 0,
    transactionSales: 0,
    salesReport: 0,
    invoices: 0,
    invoiceComplements: 0,
    creditNote: 0,
};
var SaleReportsPermissionEditRoleComponent = function (props) {
    var defaultBranchOptions = getDefaultBranchOptions();
    var _a = useState(defaultMaintenancePermissionValues), values = _a[0], setValues = _a[1];
    useEffect(function () {
        setValues(function (values) { return (__assign(__assign({}, values), props.Permissions)); });
    }, [props.Permissions]);
    return (React.createElement(ListItemPermissionEditRoleComponent, { icon: React.createElement(AssessmentIcon, null), name: 'Reportes de ventas' },
        React.createElement("div", { className: 'mx-4 py-3 grid grid-cols-1 md:grid-cols-3 gap-4' },
            React.createElement("div", { className: 'flex flex-col' },
                React.createElement(SelectPermissionFieldComponent, __assign({}, props, { name: 'generalSaleReport', label: 'Consultar informes, gr\u00E1ficas y estad\u00EDsticas', defaultValue: values.generalSaleReport, options: defaultBranchOptions })),
                React.createElement(SelectPermissionFieldComponent, __assign({}, props, { name: 'salesReport', label: 'Consultar ventas por ticket', defaultValue: values.salesReport, options: defaultBranchOptions })),
                React.createElement(SelectPermissionFieldComponent, __assign({}, props, { name: 'saleProductsRotation', label: 'Consultar ventas de productos acumulados', defaultValue: values.saleProductsRotation, options: defaultBranchOptions })),
                React.createElement(SelectPermissionFieldComponent, __assign({}, props, { name: 'employeeSales', label: 'Consultar ventas por empleado', defaultValue: values.employeeSales, options: defaultBranchOptions })),
                React.createElement(SelectPermissionFieldComponent, __assign({}, props, { name: 'customerSales', label: 'Consultar ventas por cliente', defaultValue: values.customerSales, options: defaultBranchOptions })),
                React.createElement(SelectPermissionFieldComponent, __assign({}, props, { name: 'transactionSales', label: 'Consultar ventas por forma de pago', defaultValue: values.transactionSales, options: defaultBranchOptions })),
                React.createElement(SelectPermissionFieldComponent, __assign({}, props, { name: 'invoices', label: 'Consultar facturas', defaultValue: values.invoices, options: defaultBranchOptions })),
                React.createElement(SelectPermissionFieldComponent, __assign({}, props, { name: 'invoiceComplements', label: 'Consultar complementos de pago', defaultValue: values.invoiceComplements, options: defaultBranchOptions })),
                React.createElement(SelectPermissionFieldComponent, __assign({}, props, { name: 'creditNote', label: 'Consultar notas de cr\u00E9dito', defaultValue: values.creditNote, options: defaultBranchOptions }))))));
};
export default SaleReportsPermissionEditRoleComponent;
