import { gql } from '@apollo/client';
import FRAGMENT_PRODUCT_INGREDIENT from '../../apollo/gql/product/fragmentProductIngredient';
import FRAGMENT_PRODUCT from '../../apollo/gql/product/fragmentProduct';

const FRAGMENT_CORE_PRODUCT_FIELDS = gql`
  fragment CoreProductFields on Product {
    id
    code
    type
    name
    price
    sku
    barcode
    altBarcode
    minimum
    maximum
    prefix
    iva
    ieps
    adjustPrice
    percentAdjust
    isRequisition
    fileId
    unitKey
    productKey
    quantityDefaultPresentation
    weight
    length
    height
    width
    toShipping
    Prices {
      key
      name
      price
    }
    Inventory {
      quantity
    }
  }
`;

const GET = gql`
  ${FRAGMENT_PRODUCT}
  query Products(
    $limit: Int
    $offset: Int
    $name: [String]
    $type: [Int]
    $classificationId: [ID]
    $order: [String]
  ) {
    products(
      limit: $limit
      offset: $offset
      name: $name
      type: $type
      classificationId: $classificationId
      order: $order
    ) {
      count
      rows {
        ...FragmentProduct
      }
    }
  }
`;

const GET_RECEPTIONS = gql`
  query ProductsReceptions($type: [Int], $limit: Int, $transferId: ID) {
    productsLite(type: $type, limit: $limit, transferId: $transferId) {
      count
      rows {
        id
        name
      }
    }
  }
`;

const GET_BY_ID = gql`
  ${FRAGMENT_CORE_PRODUCT_FIELDS}
  ${FRAGMENT_PRODUCT_INGREDIENT}
  query Product($id: ID!) {
    product(id: $id) {
      ...CoreProductFields
      description
      typeProductionTime
      productionTimeLimit
      productKey
      unitKey
      providerId
      classificationId
      Commentaries
      lastPurchaseDate
      currentInventory
      public
      ProductAttributes {
        id
        type
        attribute
        productPresentationId
        principalAttributeId
        priceType
        price
        ProductAttributeFiles {
          fileId
        }
      }
      ProductPrices {
        price
        Rate {
          id
          name
        }
        rateId
      }
      PresentationBase {
        name
        cost
      }
      ProductPresentations {
        id
        name
        forPursache
        forSale
        quantity
        price
        limit
        printer
        fileId
        ProductVariations {
          id
          name
        }
        ProductIngredients {
          ...FragmentProductIngredient
        }
      }
      PresentationBase {
        id
        name
        forPursache
        forSale
        quantity
        limit
        printer
        fileId
        price
        cost
        ProductVariations {
          id
          name
        }
        ProductIngredients {
          ...FragmentProductIngredient
        }
      }
      Files {
        id
      }
    }
  }
`;

const GET_INGREDIENTS = gql`
  query ProductIngredients($id: ID!, $productSizeId: ID) {
    product(id: $id, productSizeId: $productSizeId) {
      ProductIngredients {
        id
        type
        price
        quantity
        portion
        Ingredient {
          name
        }
      }
    }
  }
`;

const GET_PRICES = gql`
  query Prices {
    prices
  }
`;

const GET_FILES = gql`
  query Product($id: ID!) {
    product(id: $id) {
      Files {
        id
      }
    }
  }
`;

const GET_BY_TYPE = gql`
  query ProductsType($type: [Int], $limit: Int) {
    products(type: $type, limit: $limit) {
      count
      rows {
        id
        name
        Classification {
          name
        }
      }
    }
  }
`;

const GET_NAME_AND_UNITS = gql`
  query ProductsUnits($limit: Int, $type: [Int]) {
    products(limit: $limit, type: $type) {
      count
      rows {
        id
        name
        sku
        Units {
          key
          name
        }
        ProductUnits {
          id
          name
        }
        ProductPresentations {
          id
          name
        }
      }
    }
  }
`;

const GET_NAME_AND_PRESENTATIONS = gql`
  query ProductsAndPresentations(
    $limit: Int
    $type: [Int]
    $includeBaseInPresentations: Boolean
    $branchId: ID
    $order: [String]
  ) {
    productsV2(
      limit: $limit
      type: $type
      includeBaseInPresentations: $includeBaseInPresentations
      branchId: $branchId
      order: $order
    ) {
      count
      rows {
        id
        name
        sku
        barcode
        code
        iva
        ieps
        Classification {
          name
        }
        ProductPresentations {
          id
          name
          cost
          barcode
        }
        Inventory {
          quantity
          PresentationBase {
            name
          }
        }
      }
    }
  }
`;

const GET_PRODUCT_UNITS = gql`
  query ProductsUnits($id: ID!) {
    product(id: $id) {
      ProductUnits {
        id
        name
        quantity
        UnitCompound {
          name
        }
      }
    }
  }
`;

const GET_PROPERTIES_BY_PRODUCT_ID = gql`
  query Product($id: ID!) {
    product(id: $id) {
      Properties {
        name
        key
        values {
          label
          key
        }
      }
    }
  }
`;

const GET_COMMENTARIES_BY_PRODUCT_ID = gql`
  query Product($id: ID!) {
    product(id: $id) {
      Commentaries
    }
  }
`;

const GET_PRICES_BY_PRODUCT_ID = gql`
  query Product($id: ID!) {
    product(id: $id) {
      Prices {
        key
        name
        price
      }
    }
  }
`;

const GET_UNITS_BY_PRODUCT_ID = gql`
  query Product($id: ID!) {
    product(id: $id) {
      ProductUnits {
        id
        name
        quantity
        UnitCompound {
          name
        }
      }
    }
  }
`;

const GET_PACKAGE_BY_PRODUCT_ID = gql`
  query Product($id: ID!) {
    product(id: $id) {
      Package {
        name
        productId
        quantity
        price
        altPrice
        Classification {
          name
        }
      }
    }
  }
`;

const DELETE = gql`
  mutation Delete($id: ID!) {
    deleteProduct(id: $id) {
      id
    }
  }
`;

const CREATE = gql`
  mutation Create(
    $type: Int!
    $name: String!
    $description: String!
    $sessions: Int
    $sessionLimit: Int
    $typeProductionTime: Int
    $productionTimeLimit: Float
    $price: Float!
    $cost: Float!
    $sku: String
    $barcode: String
    $classificationId: ID!
    $productKey: String!
    $unitKey: String!
    $unitBase: String!
    $altBarcode: String
    $maximum: Float
    $minimum: Float
  ) {
    createProduct(
      input: {
        type: $type
        name: $name
        description: $description
        sessions: $sessions
        sessionLimit: $sessionLimit
        typeProductionTime: $typeProductionTime
        productionTimeLimit: $productionTimeLimit
        price: $price
        cost: $cost
        sku: $sku
        barcode: $barcode
        productKey: $productKey
        unitKey: $unitKey
        classificationId: $classificationId
        unitBase: $unitBase
        altBarcode: $altBarcode
        maximum: $maximum
        minimum: $minimum
      }
    ) {
      id
      type
      name
      description
      sessions
      sessionLimit
      typeProductionTime
      productionTimeLimit
      price
      sku
      barcode
      cost
      productKey
      unitKey
      altBarcode
      Classification {
        name
      }
    }
  }
`;

const UPDATE_PRODUCT = gql`
  mutation UpdateProductV2(
    $id: ID!
    $name: String
    $type: Int
    $price: Float
    $cost: Float
    $description: String
    $sku: String
    $barcode: String
    $productKey: String
    $unitKey: String!
    $unitBase: String
    $classificationId: ID
    $providerId: ID
    $iva: Float
    $ieps: Float
    $percentAdjust: Float
    $adjustPrice: Boolean
    $prefix: String
  ) {
    updateProductV2(
      id: $id
      input: {
        name: $name
        type: $type
        price: $price
        cost: $cost
        description: $description
        sku: $sku
        barcode: $barcode
        productKey: $productKey
        unitKey: $unitKey
        unitBase: $unitBase
        classificationId: $classificationId
        iva: $iva
        ieps: $ieps
        providerId: $providerId
        adjustPrice: $adjustPrice
        percentAdjust: $percentAdjust
        prefix: $prefix
      }
    ) {
      id
      type
      name
      price
      sku
      barcode
      prefix
      iva
      ieps
      adjustPrice
      percentAdjust
      description
      productKey
      providerId
      unitKey
      classificationId
      PresentationBase {
        id
        name
        cost
      }
    }
  }
`;

const UPDATE_PROPERTIES = gql`
  mutation UpdateProps($id: ID!, $Properties: [ProductPropertiesData]!) {
    updateProductProperties(id: $id, Properties: $Properties) {
      id
      Properties {
        key
        name
        values {
          key
          label
        }
      }
    }
  }
`;

const UPDATE_PACKAGE = gql`
  mutation UpdateProductPackage($id: ID!, $Package: [PackageItemData]!) {
    updateProductPackage(id: $id, Package: $Package) {
      id
      Package {
        productId
        name
        quantity
        price
        altPrice
      }
    }
  }
`;
const UPDATE_FILE = gql`
  mutation UpdateFiles($id: ID!, $file: Upload!, $type: String!) {
    updateFiles(id: $id, file: $file, type: $type) {
      id
    }
  }
`;

const UPDATE_PRODUCT_COMMENTARIES = gql`
  mutation UpdateProductCommentaries($id: ID!, $Commentaries: [String]!) {
    updateProductCommentaries(id: $id, Commentaries: $Commentaries) {
      Commentaries
    }
  }
`;

const UPDATE_PRODUCT_PRICES = gql`
  mutation UpdateProductPrices($id: ID!, $Prices: [PriceData]!) {
    updateProductPrices(id: $id, Prices: $Prices) {
      Prices {
        name
        key
        price
      }
    }
  }
`;

const UPDATE_PRODUCT_UNITS = gql`
  mutation UpdateProductUnits($id: ID!, $Units: [UnitData]!) {
    updateProductUnits(id: $id, Units: $Units) {
      Units {
        key
        name
        portion
        unitKey
      }
    }
  }
`;

const DELETE_FILE = gql`
  mutation DeleteFiles($id: ID!, $fileId: ID!, $type: String!) {
    deleteFiles(id: $id, fileId: $fileId, type: $type) {
      id
    }
  }
`;

const GET_NAME_PRICE = gql`
  query ProductsPrice($type: [Int], $limit: Int, $lite: Boolean) {
    products(type: $type, limit: $limit, lite: $lite) {
      count
      rows {
        id
        name
        price
        cost
        sku
        barcode
        altBarcode
        iva
        fileId
        ieps
        unitKey
        productKey
        Prices {
          key
          name
          price
        }
      }
    }
  }
`;

const GET_PRODUCTS_CASH_SALE = gql`
  query ProductsCashSale($limit: Int) {
    productsCashSale(limit: $limit) {
      count
      rows {
        id
        name
        price
        cost
        lastCostDate
        sku
        barcode
        altBarcode
        iva
        fileId
        ieps
        unitKey
        productKey
        quantityDefaultPresentation
        Prices {
          key
          name
          price
        }
        Inventory {
          quantity
        }
      }
    }
  }
`;

const GET_NAME = gql`
  query ProductsName($type: [Int], $limit: Int) {
    productsLite(type: $type, limit: $limit) {
      count
      rows {
        id
        name
      }
    }
  }
`;

const GET_UNITS = gql`
  query Units {
    units {
      id
      nombre
    }
  }
`;

const CREATE_PRODUCT_INGREDIENT = gql`
  mutation CreateProductIngredient(
    $id: ID!
    $ingredientId: ID!
    $price: Float!
    $type: Int!
    $quantity: Float!
    $portion: Int!
    $productVariationId: ID
    $productSizeId: ID!
  ) {
    createProductIngredient(
      id: $id
      input: {
        ingredientId: $ingredientId
        price: $price
        type: $type
        quantity: $quantity
        portion: $portion
        productVariationId: $productVariationId
        productSizeId: $productSizeId
      }
    ) {
      id
    }
  }
`;

const DELETE_PRODUCT_INGREDIENT = gql`
  mutation DeleteProductIngredient($id: ID!) {
    deleteProductIngredient(id: $id) {
      id
    }
  }
`;

const CREATE_PRODUCT_COMPOUND = gql`
  mutation CreateProductCompound(
    $productId: ID!
    $compoundId: ID!
    $quantity: Float!
    $session: Int!
  ) {
    createProductCompound(
      input: {
        productId: $productId
        compoundId: $compoundId
        quantity: $quantity
        session: $session
      }
    ) {
      id
      type
      name
      sku
      description
      price
      cost
      sessions
      sessionLimit
      productKey
      unitKey
      classificationId
      ProductCompounds {
        id
        productId
        compoundId
        Compound {
          id
          type
          name
        }
        deletedAt
        session
      }
    }
  }
`;

const DELETE_PRODUCT_COMPOUND = gql`
  mutation DeleteProductCompound($id: ID!) {
    deleteProductCompound(id: $id) {
      id
    }
  }
`;

const CREATE_PRODUCT_UNIT = gql`
  mutation CreateProductUnit(
    $name: String!
    $quantity: Float!
    $productId: ID!
    $unitCompoundId: ID
  ) {
    createProductUnit(
      input: {
        name: $name
        quantity: $quantity
        productId: $productId
        unitCompoundId: $unitCompoundId
      }
    ) {
      id
      name
      quantity
    }
  }
`;

const DELETE_PRODUCT_UNIT = gql`
  mutation DeleteProductUnit($id: ID!) {
    createProductUnit(id: $id) {
      id
      name
      quantity
    }
  }
`;

const GET_PRODUCT_VARIATION = gql`
  query Product($id: ID!) {
    product(id: $id) {
      id
      ProductVariations {
        id
        name
      }
    }
  }
`;

const CREATE_PRODUCT_SIZE = gql`
  mutation CreateProductSize(
    $productId: ID!
    $name: String!
    $price: Float!
    $quantity: Float!
    $limit: Float!
  ) {
    createProductSize(
      input: {
        productId: $productId
        name: $name
        price: $price
        quantity: $quantity
        limit: $limit
      }
    ) {
      id
      name
      price
      quantity
      limit
      productId
    }
  }
`;

const GET_PRODUCT_SIZES = gql`
  query Product($id: ID!) {
    product(id: $id) {
      id
      ProductUnits {
        id
        name
        unitCompoundId
      }
      ProductSizes {
        id
        name
        price
        quantity
      }
    }
  }
`;

const CREATE_PRODUCT_VARIATION = gql`
  mutation CreateProductVariation(
    $productId: ID!
    $name: String!
    $limitVariation: Int!
    $productSizeId: ID!
  ) {
    createProductVariation(
      input: {
        productId: $productId
        name: $name
        limitVariation: $limitVariation
        productSizeId: $productSizeId
      }
    ) {
      id
      name
    }
  }
`;

const GET_SIZE = gql`
  query ProductSize($id: ID!) {
    productSize(id: $id) {
      id
      name
      productId
      price
      quantity
      limit
    }
  }
`;

const UPDATE_PRODUCT_SIZE = gql`
  mutation UpdateProductSize(
    $id: ID!
    $productId: ID!
    $name: String!
    $price: Float!
    $quantity: Float!
    $limit: Float!
  ) {
    updateProductSize(
      id: $id
      input: {
        productId: $productId
        name: $name
        price: $price
        quantity: $quantity
        limit: $limit
      }
    ) {
      id
      name
      productId
      price
      quantity
      limit
    }
  }
`;

const DELETE_PRODUCT_SIZE = gql`
  mutation DeleteProductSize($id: ID!) {
    deleteProductSize(id: $id) {
      id
    }
  }
`;

const GET_VARIATION = gql`
  query ProductVariation($id: ID!) {
    productVariation(id: $id) {
      id
      name
    }
  }
`;

const UPDATE_PRODUCT_VARIATION = gql`
  mutation UpdateProductVariation(
    $id: ID!
    $name: String!
    $limitVariation: Int!
    $productSizeId: ID!
  ) {
    updateProductVariation(
      id: $id
      name: $name
      limitVariation: $limitVariation
      productSizeId: $productSizeId
    ) {
      id
      name
    }
  }
`;

const DELETE_PRODUCT_VARIATION = gql`
  mutation DeleteProductVariation($id: ID!) {
    deleteProductVariation(id: $id) {
      id
    }
  }
`;

const UPDATE_PRODUCT_UNIT = gql`
  mutation UpdateProductUnit($id: ID!, $unitCompoundId: ID, $quantity: Float!, $name: String!) {
    updateProductUnit(
      id: $id
      input: { unitCompoundId: $unitCompoundId, quantity: $quantity, name: $name }
    ) {
      id
    }
  }
`;

const GQL = {
  UPDATE_PRODUCT_UNIT,
  GET,
  GET_BY_ID,
  DELETE,
  CREATE,
  UPDATE_PRODUCT,
  UPDATE_PROPERTIES,
  UPDATE_PACKAGE,
  UPDATE_FILE,
  GET_PROPERTIES_BY_PRODUCT_ID,
  GET_FILES,
  DELETE_FILE,
  GET_PACKAGE_BY_PRODUCT_ID,
  GET_BY_TYPE,
  GET_NAME_PRICE,
  GET_COMMENTARIES_BY_PRODUCT_ID,
  UPDATE_PRODUCT_COMMENTARIES,
  GET_PRICES_BY_PRODUCT_ID,
  UPDATE_PRODUCT_PRICES,
  GET_UNITS_BY_PRODUCT_ID,
  UPDATE_PRODUCT_UNITS,
  GET_UNITS,
  GET_PRICES,
  GET_NAME_AND_UNITS,
  GET_INGREDIENTS,
  GET_NAME,
  CREATE_PRODUCT_INGREDIENT,
  DELETE_PRODUCT_INGREDIENT,
  CREATE_PRODUCT_COMPOUND,
  DELETE_PRODUCT_COMPOUND,
  GET_PRODUCT_UNITS,
  CREATE_PRODUCT_UNIT,
  DELETE_PRODUCT_UNIT,
  GET_PRODUCT_VARIATION,
  CREATE_PRODUCT_SIZE,
  GET_PRODUCT_SIZES,
  CREATE_PRODUCT_VARIATION,
  GET_SIZE,
  UPDATE_PRODUCT_SIZE,
  DELETE_PRODUCT_SIZE,
  GET_VARIATION,
  UPDATE_PRODUCT_VARIATION,
  DELETE_PRODUCT_VARIATION,
  GET_NAME_AND_PRESENTATIONS,
  GET_RECEPTIONS,
  GET_PRODUCTS_CASH_SALE,
  FRAGMENT_PRODUCT,
};

export default GQL;
