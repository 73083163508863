import React from 'react';

import { useReactiveVar } from '@apollo/client';

import { customerCalendarCheck } from '../../../../apollo/cache';

import CheckOutForm from './CheckOutForm';

const CustomerCalendarCheckComponent = () => {
  const customerCalendarCheckVar = useReactiveVar(customerCalendarCheck);

  return (
    !!customerCalendarCheckVar.isOpen &&
    customerCalendarCheckVar.type === 'CUSTOMER_CHECK_OUT' && <CheckOutForm />
  );
};

export default CustomerCalendarCheckComponent;
