import React from 'react';
import { Link } from '@mui/material';
import { isCreatedInvoiceComplementOpen } from '../../../../../../apollo/cache';
var DownloadInvoiceComplementButtonComplement = function (props) {
    var handleClick = function (e) {
        if (e)
            e.preventDefault();
        isCreatedInvoiceComplementOpen(props.invoiceComplementUuid);
    };
    if (!props.invoiceComplementUuid)
        return React.createElement("div", null);
    return React.createElement(Link, { onClick: handleClick }, "Descargar complemento de pago");
};
export default DownloadInvoiceComplementButtonComplement;
