var getCashFlowActionLabel = function (action) {
    switch (action) {
        case 1:
            return 'Retiro';
        case 2:
            return 'Depósito';
        case 3:
            return 'Caja fuerte';
        default:
            return 'Indefinido';
    }
};
export default getCashFlowActionLabel;
