import React from 'react';
import ItemSalesSummaryIncomeReportComponent from './Item';
import DateSalesSummaryIncomeReportComponent from './Date';
import { capitalize } from '../../../helpers';
var SalesSummaryIncomeReportComponent = function (props) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p;
    return (React.createElement("div", { className: 'flex bg-slate-700 flex-col rounded-lg p-4' },
        React.createElement(DateSalesSummaryIncomeReportComponent, null),
        React.createElement("div", { className: 'flex flex-row flex-wrap sm:flex-nowrap overflow-x-auto' },
            React.createElement(ItemSalesSummaryIncomeReportComponent, { border: true, total: (_a = props.TotalSales) === null || _a === void 0 ? void 0 : _a.transaction, label: 'Ingresos' }),
            React.createElement(ItemSalesSummaryIncomeReportComponent, { border: true, qty: (_c = (_b = props.TotalSales) === null || _b === void 0 ? void 0 : _b.Total) === null || _c === void 0 ? void 0 : _c.count, total: (_e = (_d = props.TotalSales) === null || _d === void 0 ? void 0 : _d.Total) === null || _e === void 0 ? void 0 : _e.total, label: 'Ventas' }),
            React.createElement(ItemSalesSummaryIncomeReportComponent, { border: true, total: (_f = props.TotalSales) === null || _f === void 0 ? void 0 : _f.salesAvg, label: 'Promedio vta' }),
            React.createElement(ItemSalesSummaryIncomeReportComponent, { border: true, qty: (_h = (_g = props.TotalSales) === null || _g === void 0 ? void 0 : _g.Discount) === null || _h === void 0 ? void 0 : _h.count, total: (_k = (_j = props.TotalSales) === null || _j === void 0 ? void 0 : _j.Discount) === null || _k === void 0 ? void 0 : _k.total, label: 'Descuentos' }),
            React.createElement(ItemSalesSummaryIncomeReportComponent, { border: true, qty: (_m = (_l = props.TotalSales) === null || _l === void 0 ? void 0 : _l.Cancelled) === null || _m === void 0 ? void 0 : _m.count, total: (_p = (_o = props.TotalSales) === null || _o === void 0 ? void 0 : _o.Cancelled) === null || _p === void 0 ? void 0 : _p.total, label: 'Cancelaciones' }),
            props.TotalCashFlows &&
                props.TotalCashFlows.map(function (el, i) {
                    var _a, _b;
                    return (React.createElement(ItemSalesSummaryIncomeReportComponent, { key: el.action, qty: el.Total.count, total: el.Total.total, label: capitalize(el.name), border: !!(((_a = props.TotalCashFlows) === null || _a === void 0 ? void 0 : _a.length) && i < ((_b = props.TotalCashFlows) === null || _b === void 0 ? void 0 : _b.length)) }));
                }))));
};
export default SalesSummaryIncomeReportComponent;
