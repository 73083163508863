var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { gql } from '@apollo/client';
import FRAGMENT_ADJUST_INVENTORY from './fragmentAdjustInventory';
import FRAGMENT_PRODUCT_INVENTORY from '../productInventory/fragmentProductInventory';
var CREATE_ADJUST_INVENTORY = gql(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  ", "\n  ", "\n  mutation CreateAdjustInventoryV3(\n    $isZero: Boolean\n    $type: Int!\n    $Products: [AdjustInventoryProductData]!\n    $branchId: ID!\n  ) {\n    createAdjustInventoryV3(\n      input: { isZero: $isZero, Products: $Products, type: $type, branchId: $branchId }\n    ) {\n      ...FragmentAdjustInventory\n      ProductInventories {\n        ...FragmentProductInventory\n      }\n    }\n  }\n"], ["\n  ", "\n  ", "\n  mutation CreateAdjustInventoryV3(\n    $isZero: Boolean\n    $type: Int!\n    $Products: [AdjustInventoryProductData]!\n    $branchId: ID!\n  ) {\n    createAdjustInventoryV3(\n      input: { isZero: $isZero, Products: $Products, type: $type, branchId: $branchId }\n    ) {\n      ...FragmentAdjustInventory\n      ProductInventories {\n        ...FragmentProductInventory\n      }\n    }\n  }\n"])), FRAGMENT_ADJUST_INVENTORY, FRAGMENT_PRODUCT_INVENTORY);
export default CREATE_ADJUST_INVENTORY;
var templateObject_1;
