var getIndexByHour = function (hour) {
    if (hour >= 3 && hour < 4)
        return 0;
    if (hour >= 4 && hour < 5)
        return 1;
    if (hour >= 5 && hour < 6)
        return 2;
    if (hour >= 6 && hour < 7)
        return 3;
    if (hour >= 7 && hour < 8)
        return 4;
    if (hour >= 8 && hour < 9)
        return 5;
    if (hour >= 9 && hour < 10)
        return 6;
    if (hour >= 10 && hour < 11)
        return 7;
    if (hour >= 11 && hour < 12)
        return 8;
    if (hour >= 12 && hour < 13)
        return 9;
    if (hour >= 13 && hour < 14)
        return 10;
    if (hour >= 14 && hour < 15)
        return 11;
    if (hour >= 15 && hour < 16)
        return 12;
    if (hour >= 16 && hour < 17)
        return 13;
    if (hour >= 17 && hour < 18)
        return 14;
    if (hour >= 18 && hour < 19)
        return 15;
    if (hour >= 19 && hour < 20)
        return 16;
    if (hour >= 20 && hour < 21)
        return 17;
    if (hour >= 21 && hour < 22)
        return 18;
    if (hour >= 22 && hour < 23)
        return 19;
    if (hour >= 23 && hour < 24)
        return 20;
    if (hour >= 0 && hour < 1)
        return 21;
    if (hour >= 1 && hour < 2)
        return 22;
    if (hour >= 2 && hour < 3)
        return 23;
    return -1;
};
export default getIndexByHour;
