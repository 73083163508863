var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import Item from './Item';
import useApolloQuery from '../../../../../hooks/useApolloQuery';
import GQL_CALENDAR from '../../../../../apollo/gql/calendar';
import LoadingProgress from '../../../../../presentationals/LoadingProgress';
import ErrorDataComponent from '../../../../../presentationals/ErrorData';
var AgendaBodyFormCalendarComponent = function () {
    var _a = useApolloQuery(GQL_CALENDAR.EMPLOYEE_CALENDAR_SETTINGS), data = _a.data, loadingData = _a.loading;
    if (loadingData)
        return React.createElement(LoadingProgress, null);
    if (!data)
        return React.createElement(ErrorDataComponent, null);
    return (React.createElement("div", { className: 'flex flex-1 flex-col overflow-auto' }, Array.from(Array(data.scheduleAvailability.qty).keys()).map(function (key) { return (React.createElement(Item, __assign({ key: key }, data.scheduleAvailability.scheduleSettings[key]))); })));
};
export default AgendaBodyFormCalendarComponent;
