import React from 'react';
import { TableRow } from '@mui/material';
import { isItemDetailDialogOpen } from '../../../../../apollo/cache';
var RowBodyCartSaleV2Component = function (props) {
    var handleClick = function () {
        isItemDetailDialogOpen(props.index);
    };
    return (React.createElement(TableRow, { hover: true, className: "cursor-pointer ".concat(props.background), onClick: handleClick }, props.children));
};
export default RowBodyCartSaleV2Component;
