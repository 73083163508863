var getProductGqlColumn = function (key) {
    switch (key) {
        case 'classification':
            return 'Classification { id name }';
        case 'stock':
            return 'Inventory { id quantity }';
        case 'presentation':
            return 'PresentationBase { id name cost }';
        default:
            return key;
    }
};
export default getProductGqlColumn;
