import React from 'react';

import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';

const Component = props => (
  <div className='flex flex-1 flex-col basis-0 overflow-auto'>
    <TableContainer>
      <Table stickyHeader size='small'>
        {props.children}
      </Table>
    </TableContainer>
  </div>
);

export default Component;
