import React from 'react';

import { Card } from '@mui/material';

import ProductFormComponent from './ProductForm';
import AddButtonComponent from './AddButton';
import ProductImageComponent from './ProductImage';
import CartFormComponent from './CartForm';
import ProductDialog from './ProductDialog';
import Total from './Total';
import UnitKeyDialog from './ProductDialog/Create/Body/helpers/UnitKeyField/SearchDialog';
import ProductKeyDialog from './ProductDialog/Create/Body/helpers/ProductKeyField/SearchDialog';

const ChooseProductSaleV2Component = () => {
  return (
    <>
      <Card className='mt-2 sm:mt-4 flex flex-row p-2 md:p-3 overflow-auto'>
        <div>
          <ProductImageComponent />
        </div>
        <div className='flex flex-1 flex-col pl-2'>
          <div>
            <ProductFormComponent />
          </div>
          <div>
            <CartFormComponent />
          </div>
        </div>
        <div className='flex flex-col border-[1px] border-solid border-gray-400 rounded ml-2 mt-1 mb-1 p-2 pt-1'>
          <Total />
        </div>
        <div>
          <AddButtonComponent />
        </div>
      </Card>
      <ProductDialog />
      <UnitKeyDialog />
      <ProductKeyDialog />
    </>
  );
};

export default ChooseProductSaleV2Component;
