var getDefaultBranchSettingsValues = function (data) { return ({
    defaultTaxesTypeOption: data ? data.defaultTaxesTypeOption : 1,
    codeConfirmation: data ? data.codeConfirmation : 0,
    dinner: data ? data.dinner : 0,
    printReceiptAfterPayment: data ? data.printReceiptAfterPayment : true,
    resetFolio: data ? data.resetFolio : false,
    pettyCashAmount: data ? data.pettyCashAmount : '',
    withdrawalRequired: data ? data.withdrawalRequired : '',
    customerId: data ? data.customerId : '',
    tables: data ? "".concat(data.tables) : '',
}); };
export default getDefaultBranchSettingsValues;
