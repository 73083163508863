import { gql } from '@apollo/client';

const FRAGMENT_PAYMENT_METHOD = gql`
  fragment fragmentPaymentMethod on PaymentMethod {
    id
    name
    isCash
    isAccountPayable
    isUnique
    commission
    code
  }
`;

const GET = gql`
  query PaymentMethods($limit: Int, $offset: Int, $name: String, $order: [String]) {
    paymentMethods(limit: $limit, offset: $offset, name: $name, order: $order) {
      count
      rows {
        id
        name
        isCash
        isAccountPayable
        isUnique
        commission
        code
      }
    }
  }
`;

const GET_AUTOCOMPLETE = gql`
  query PaymentMethodsAuto($limit: Int, $offset: Int, $name: String) {
    paymentMethods(limit: $limit, offset: $offset, name: $name) {
      count
      rows {
        id
        name
      }
    }
  }
`;

const GET_BY_ID = gql`
  query PaymentMethod($id: ID!) {
    paymentMethod(id: $id) {
      id
      name
      isCash
      isAccountPayable
      isUnique
      code
      commission
    }
  }
`;

const DELETE = gql`
  mutation DeletePaymentMethod($id: ID!) {
    deletePaymentMethod(id: $id) {
      id
    }
  }
`;

const CREATE = gql`
  ${FRAGMENT_PAYMENT_METHOD}
  mutation CreatePaymentMethod(
    $name: String!
    $isCash: Boolean!
    $isAccountPayable: Boolean!
    $isUnique: Boolean!
    $commission: Float!
    $code: String!
  ) {
    createPaymentMethod(
      input: {
        name: $name
        isCash: $isCash
        isAccountPayable: $isAccountPayable
        isUnique: $isUnique
        commission: $commission
        code: $code
      }
    ) {
      ...fragmentPaymentMethod
    }
  }
`;
const UPDATE = gql`
  ${FRAGMENT_PAYMENT_METHOD}
  mutation UpdatePaymentMethod(
    $id: ID!
    $name: String!
    $isCash: Boolean!
    $isAccountPayable: Boolean!
    $isUnique: Boolean!
    $commission: Float!
    $code: String!
  ) {
    updatePaymentMethod(
      id: $id
      input: {
        name: $name
        isCash: $isCash
        isAccountPayable: $isAccountPayable
        isUnique: $isUnique
        commission: $commission
        code: $code
      }
    ) {
      ...fragmentPaymentMethod
    }
  }
`;

const GET_TRANSACTION_ACCOUNTS = gql`
  query TransactionAccounts {
    transactionAccounts {
      count
      rows {
        id
        name
      }
    }
  }
`;

const CREATE_TRANSACTION_ACCOUNT = gql`
  mutation CreateTransactionAccount($name: String) {
    createTransactionAccount(input: { name: $name }) {
      id
      name
    }
  }
`;

const FRAGMENT_CORE_TRANSACTION_ACCOUNT_FIELDS = gql`
  fragment CoreTransactionAccountFields on TransactionAccount {
    id
    name
  }
`;

export default {
  GET,
  GET_AUTOCOMPLETE,
  GET_BY_ID,
  DELETE,
  CREATE,
  UPDATE,
  CREATE_TRANSACTION_ACCOUNT,
  GET_TRANSACTION_ACCOUNTS,
  FRAGMENT_CORE_TRANSACTION_ACCOUNT_FIELDS,
  FRAGMENT_PAYMENT_METHOD,
};
