import React from 'react';
import BalanceComponent from './Balance';
import TransactionComponent from './Transaction';
import CashFlowComponent from './CashFlow';
import DetailSaleComponent from './Sale';
var DetailCashClosingComponent = function () {
    return (React.createElement("div", { className: 'w-full h-full flex flex-1 flex-col p-2 sm:flex-row overflow-auto whitespace-nowrap' },
        React.createElement("div", { className: 'block sm:flex sm:flex-col sm:flex-1 sm:mr-3' },
            React.createElement(BalanceComponent, null),
            React.createElement(TransactionComponent, null),
            React.createElement(CashFlowComponent, null)),
        React.createElement("div", { className: 'block sm:flex sm:flex-col sm:flex-1' },
            React.createElement(DetailSaleComponent, null))));
};
export default DetailCashClosingComponent;
