import React from 'react';
import AccountBalanceWalletOutlinedIcon from '@mui/icons-material/AccountBalanceWalletOutlined';
import { IconButton, Tooltip } from '@mui/material';
import getInputErrorFormat from '../../../sale.v2/Action/helpers/getInputErrorFormat';
import CONFIG from '../../../../config/config.json';
var AccountBalanceButtonComponent = function (props) {
    var handleClick = function () {
        try {
            if (!props.customerId)
                throw new Error('Proporciona el identificador del cliente.');
            window.open("".concat(CONFIG['restServer'].uri, "/files/customerAccountReceivables/").concat(props.customerId, "/pdf"));
        }
        catch (error) {
            getInputErrorFormat(error);
        }
    };
    return (React.createElement(Tooltip, { title: 'Imprimir estado de cuenta' },
        React.createElement(IconButton, { onClick: handleClick },
            React.createElement(AccountBalanceWalletOutlinedIcon, null))));
};
export default AccountBalanceButtonComponent;
