import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import FooterDialogComponent from '../../../../presentationals/Dialog/Footer';
import EditProductPresentationButtonComponent from './EditButton';
import LoadingProgress from '../../../../presentationals/LoadingProgress';
import { productPresentationForm } from '../../../../apollo/cache';
import useApolloQuery from '../../../../hooks/useApolloQuery';
import GQL_PRODUCT from '../../../../apollo/gql/product';
import DeleteButtonGeneralProductPresentationComponent from './DeleteButton';
import BodyCreateProductPresentationDialogComponent from '../../Item/Structure/CreatePresentation/Body';
import getDefaultProductPresentationValues from '../../Item/Structure/CreatePresentation/Body/helpers/getDefaultValues';
import ErrorDataComponent from '../../../../presentationals/ErrorData';
var GeneralProductPresentationComponent = function () {
    var params = useParams();
    var _a = useApolloQuery(GQL_PRODUCT.GET_PRODUCT_PRESENTATION_BY_ID, {
        variables: { id: params.productPresentationId },
    }), loading = _a.loading, data = _a.data;
    useEffect(function () {
        return function () {
            var defaultProductPresentationValues = getDefaultProductPresentationValues();
            productPresentationForm(defaultProductPresentationValues);
        };
    }, []);
    useEffect(function () {
        if (data)
            productPresentationForm({
                name: data.name,
                forPursache: data.forPursache,
                forRequisition: data.forRequisition,
                forSale: data.forSale,
                quantityInBaseUnit: data.quantity,
                price: data.price,
                uberPrice: data.uberPrice,
                didiPrice: data.didiPrice,
                rappiPrice: data.rappiPrice,
                shippingPrice: data.shippingPrice,
                pickupPrice: data.pickupPrice,
                limit: data.limit,
                printer: data.printer || -1,
                isDifferentPrices: data.uberPrice ||
                    data.didiPrice ||
                    data.rappiPrice ||
                    data.shippingPrice ||
                    data.pickupPrice,
            });
    }, [data]);
    if (loading)
        return React.createElement(LoadingProgress, null);
    if (!data)
        return React.createElement(ErrorDataComponent, null);
    return (React.createElement("div", { className: 'flex flex-1 flex-col w-full overflow-hidden basis-0' },
        React.createElement(BodyCreateProductPresentationDialogComponent, null),
        React.createElement(FooterDialogComponent, null,
            React.createElement(DeleteButtonGeneralProductPresentationComponent, null),
            React.createElement(EditProductPresentationButtonComponent, null))));
};
export default GeneralProductPresentationComponent;
