var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { useReactiveVar } from '@apollo/client';
import { useEffect, useState } from 'react';
import { filter } from '../../../apollo/cache';
import useApolloQuery from '../../../hooks/useApolloQuery';
import GQL from '../_gql';
import getYesNoAnswer from '../../../helpers/getYesNoAnswer';
import useGetPaymentForm from '../../../hooks/useGetSatPaymentForm';
var useGetPaymentMethodData = function (props) {
    var paymentFormOptions = useGetPaymentForm();
    var variables = useReactiveVar(filter);
    var _a = useState([]), data = _a[0], setData = _a[1];
    var _b = useApolloQuery(GQL.GET, props.getByFilter ? { variables: variables } : {}), response = _b.data, loading = _b.loading, refetch = _b.refetch;
    useEffect(function () {
        if (props.getByFilter)
            refetch(__assign(__assign({}, variables), { like: variables.like ? variables.like : undefined }));
    }, [variables, props.getByFilter]);
    useEffect(function () {
        if (response && paymentFormOptions) {
            var Rows = response.rows;
            var data_1 = Rows.map(function (el) {
                var code = paymentFormOptions.find(function (elCh) { return elCh.value === el.code; });
                return {
                    id: el.id,
                    name: el.name,
                    commission: "".concat(el.commission, "%"),
                    isCash: getYesNoAnswer(el.isCash),
                    isUnique: getYesNoAnswer(el.isUnique),
                    code: code ? code.label : '-',
                    isAccountPayable: getYesNoAnswer(el.isAccountPayable),
                };
            });
            setData(data_1);
        }
    }, [response, paymentFormOptions]);
    return { data: data, loading: loading };
};
export default useGetPaymentMethodData;
