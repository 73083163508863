var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useRef } from 'react';
import AdjustInventoryProductFormComponent from './Form';
import AdjustInventoryProductTableComponent from './Table';
import FooterDialogComponent from '../../../../presentationals/Dialog/Footer';
import CreateAdjustInventoryButtonComponent from './CreateButton';
import ReturnToMainFormAdjustInventoryButtonComponent from './ReturnButton';
var AdjustInventoryProductComponent = function (props) {
    var adjustInventoryProductTableRef = useRef(null);
    var handleAddProductByBarcode = function (Item) {
        var _a;
        (_a = adjustInventoryProductTableRef === null || adjustInventoryProductTableRef === void 0 ? void 0 : adjustInventoryProductTableRef.current) === null || _a === void 0 ? void 0 : _a.onAddProductByBarcode(Item);
    };
    var handleAddProduct = function (Item) {
        var _a;
        (_a = adjustInventoryProductTableRef === null || adjustInventoryProductTableRef === void 0 ? void 0 : adjustInventoryProductTableRef.current) === null || _a === void 0 ? void 0 : _a.onAddProduct(Item);
    };
    var handleGetProducts = function () {
        var _a;
        var Products = (_a = adjustInventoryProductTableRef === null || adjustInventoryProductTableRef === void 0 ? void 0 : adjustInventoryProductTableRef.current) === null || _a === void 0 ? void 0 : _a.getProducts();
        return __assign(__assign({}, props.MainValues), { Products: Products || [] });
    };
    return (React.createElement("div", { className: 'flex flex-1 flex-col' },
        React.createElement("div", { className: 'grid grid-cols-1 sm:grid-cols-3 sm:gap-3 flex-1 basis-0 overflow-auto px-4 py-3' },
            React.createElement("div", { className: 'flex flex-col' },
                React.createElement(AdjustInventoryProductFormComponent, { onAddProductByBarcode: handleAddProductByBarcode, onAddProduct: handleAddProduct, branchId: props.MainValues.branchId })),
            React.createElement("div", { className: 'flex flex-1 pt-4 sm:col-span-2' },
                React.createElement(AdjustInventoryProductTableComponent, { adjustType: parseInt(props.MainValues.adjustType), branchId: props.MainValues.branchId, ref: adjustInventoryProductTableRef }))),
        React.createElement(FooterDialogComponent, null,
            React.createElement("div", null,
                React.createElement(ReturnToMainFormAdjustInventoryButtonComponent, { onClick: props.onReturn })),
            React.createElement(CreateAdjustInventoryButtonComponent, { onCreated: props.onCreated, onClick: handleGetProducts }))));
};
export default AdjustInventoryProductComponent;
