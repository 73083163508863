var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { useReactiveVar } from '@apollo/client';
import TextFieldComponent from '../../../../../../../presentationals/FormComponents/TextField';
import getDefaultCreateCurrencyPurchaseOrderValues from './helpers/getDefaultValues';
import CurrencyPurchaseOrderFieldComponent from './CurrencyField';
import { inputError } from '../../../../../../../apollo/cache';
var BodyCurrencyCreatePurchaseOrderDialogComponent = forwardRef(function (props, ref) {
    var errors = useReactiveVar(inputError);
    var defaultValues = getDefaultCreateCurrencyPurchaseOrderValues();
    var _a = useState(defaultValues), values = _a[0], setValues = _a[1];
    useEffect(function () {
        if (props.defaultValues)
            setValues(props.defaultValues);
    }, [props.defaultValues]);
    var handleChange = function (name, value) {
        setValues(function (values) {
            var _a;
            return (__assign(__assign({}, values), (_a = {}, _a[name] = value, _a)));
        });
    };
    var handleGetValues = function () { return values; };
    useImperativeHandle(ref, function () { return ({
        getValues: handleGetValues,
    }); });
    return (React.createElement("div", { className: 'grid grid-cols-1 sm:grid-cols-4 flex-1 flex-col' },
        React.createElement("div", { className: 'flex flex-1 flex-col px-4 py-3' },
            React.createElement(CurrencyPurchaseOrderFieldComponent, { onChange: handleChange, value: values.currencyKey }),
            React.createElement(TextFieldComponent, { name: 'exchangeRate', label: 'Tipo de cambio', type: 'number', disabled: values.currencyKey === 'MXN', value: values.currencyKey === 'MXN' ? '1' : values.exchangeRate, onChange: handleChange, error: errors.exchangeRate }))));
});
export default BodyCurrencyCreatePurchaseOrderDialogComponent;
