import React, { useEffect, useRef } from 'react';
import HeaderModuleV3Component from '../../presentationals/Module.v3/Header';
import { searchProductsByOptions } from '../../presentationals/FormComponents/SearchProductBySelectField';
import SelectBranchInventoryReportInputComponent from './SelectBranchInput';
import BodyInventoryReportComponent from './Body';
import FooterModuleV3Component from '../../presentationals/Module.v3/Footer';
import OpenAdjustInventoryFromInventoryReportButtonComponent from './OpenAdjustInventoryFormButton';
import ProductAdjustInventoryFromInventoryReportComponent from './AddProductToAdjustInventory';
import ProductHistoryInventoryReportDialogComponent from './ProductHistory';
import AddProductToTransferInventoryReportComponent from './AddProductToTransfer';
import ProductsToTransferFormComponent from './ProductsToTransferForm';
import OpenProductToTransferFormButtonComponent from './OpenProductToTransferFormButton';
import AdjustInventoryFromInventoryReportFormComponent from './AdjustInventoryForm';
import useInventoryReportStore from '../../store/inventoryReport';
import SelectBranchInventoryDialogComponent from './SelectBranchDialog';
var InventoryReportComponent = function () {
    var setBranchId = useInventoryReportStore(function (state) { return state.setBranchId; });
    var productHistoryInventoryReportDialogRef = useRef(null);
    useEffect(function () {
        setBranchId();
    }, []);
    var handleShowProductHistory = function (productId) {
        var _a;
        (_a = productHistoryInventoryReportDialogRef.current) === null || _a === void 0 ? void 0 : _a.open(productId);
    };
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: 'flex flex-1 flex-col p-2' },
            React.createElement(HeaderModuleV3Component, { hideAdd: true, isLoading: false, searchLabel: 'por producto', title: 'Gesti\u00F3n de inventario', customizedActions: React.createElement(SelectBranchInventoryReportInputComponent, null), searchFieldOptions: {
                    name: 'searchProductBy',
                    options: searchProductsByOptions,
                    defaultValue: 'name',
                } }),
            React.createElement(BodyInventoryReportComponent, { onShowProductHistoryClick: handleShowProductHistory }),
            React.createElement(FooterModuleV3Component, null)),
        React.createElement("div", { className: 'absolute bottom-5 right-5 flex flex-row' },
            React.createElement(OpenProductToTransferFormButtonComponent, null),
            React.createElement(OpenAdjustInventoryFromInventoryReportButtonComponent, null)),
        React.createElement(ProductHistoryInventoryReportDialogComponent, { ref: productHistoryInventoryReportDialogRef }),
        React.createElement(ProductAdjustInventoryFromInventoryReportComponent, null),
        React.createElement(AddProductToTransferInventoryReportComponent, null),
        React.createElement(ProductsToTransferFormComponent, null),
        React.createElement(AdjustInventoryFromInventoryReportFormComponent, null),
        React.createElement(SelectBranchInventoryDialogComponent, null)));
};
export default InventoryReportComponent;
