var getRouteFromCashClosingMenuValue = function (value) {
    switch (value) {
        case 0:
            return '.';
        case 1:
            return 'totalbyfolio';
        case 2:
            return 'totalbyclassification';
        case 3:
            return 'totalbyproduct';
        case 4:
            return 'totalbyemployee';
        default:
            return false;
    }
};
export default getRouteFromCashClosingMenuValue;
