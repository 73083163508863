import React, { useState } from 'react';
import { useMutation } from '@apollo/client';

import TrashIcon from '@mui/icons-material/DeleteOutline';

import Badge from '@mui/material/Badge';

import FormDialog from '../../presentationals/FormDialog';
import ImageField from '../../presentationals/ImageField';
import IconButton from '../../presentationals/IconButton';

import config from '../../config/config.json';

import GQL from './_gql';
import useSnackbarDialog from '../../store/snackbar/snackbarDialog';

const defaultSnackbar = { isOpen: true, time: 3000 };

const ProductImage = props => {
  const { setSnackbar } = useSnackbarDialog();
  const [previewImage, setPreviewImage] = useState([null, null]);
  const [updateFiles, { loading }] = useMutation(GQL.UPDATE_FILE);

  const [deleteFile] = useMutation(GQL.DELETE_FILE);

  const handleClose = () => {
    setPreviewImage([null, null]);
    props.handleClose();
  };

  const handleAction = async () => {
    try {
      await updateFiles({ variables: { id: props.id, file: previewImage[1] } });
      handleClose();
      setSnackbar({
        ...defaultSnackbar,
        label: 'La imagenes fueron actualizadas con éxito.',
        severity: 'success',
      });
    } catch (e) {
      setSnackbar({
        ...defaultSnackbar,
        label: 'Ha ocurrido un error al cargar la imagen.',
        severity: 'error',
      });
    }
  };

  const handleImage = (uri, file) => {
    window.URL.revokeObjectURL(previewImage[0]);
    setPreviewImage([uri, file]);
  };

  const handleDeleteFile = async (e, fileId) => {
    try {
      await deleteFile({ variables: { id: props.id, fileId } });
    } catch (e) {
      setSnackbar({
        ...defaultSnackbar,
        label: 'Ha ocurrido un error al eliminar la imagen.',
        severity: 'error',
      });
    }
  };

  return (
    <FormDialog
      title='Imagen de la Promoción'
      isOpen={props.isOpen || false}
      isLoading={loading}
      handleClose={handleClose}
      handleAction={handleAction}
      hideAction={!previewImage}
    >
      <ImageField aspect={12 / 16} width={100} height={355} croppedImage={handleImage}>
        {props.fileId && (
          <Badge
            key={props.fileId}
            overlap='circular'
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            badgeContent={
              <IconButton
                params={props.fileId}
                action={handleDeleteFile}
                icon={<TrashIcon />}
                label='Eliminar'
                size='large'
              />
            }
          >
            <img
              src={`${config['api-file-service'].url}/files/${props.fileId}`}
              alt={`IMG-${props.fileId}`}
            />
          </Badge>
        )}
      </ImageField>
    </FormDialog>
  );
};

export default ProductImage;
