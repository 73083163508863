import React from 'react';
import { Popover } from '@mui/material';
import useTransferProductsToBranchStore from '../../../store/inventoryReport/transferProductsToBranchStore';
import BodyProductsToTransferFormComponent from './Body';
var ProductsToTransferFormComponent = function () {
    var anchorEl = useTransferProductsToBranchStore(function (state) { return state.anchorEl; });
    var setOpen = useTransferProductsToBranchStore(function (state) { return state.setOpen; });
    var handleClose = function () {
        setOpen(null);
    };
    return (React.createElement(Popover, { id: 'FormProductsToTransferFromInventoryReport', open: !!anchorEl, anchorEl: anchorEl, onClose: handleClose, anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'right',
        }, transformOrigin: {
            vertical: 'top',
            horizontal: 'right',
        }, slotProps: {
            root: {
                className: 'mb-8',
            },
            paper: {
                className: 'w-full h-96 sm:w-96 overflow-hidden',
            },
        } },
        React.createElement(BodyProductsToTransferFormComponent, { onCreate: handleClose })));
};
export default ProductsToTransferFormComponent;
