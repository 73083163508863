var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useEffect, useState } from 'react';
import RepartitionIcon from '@mui/icons-material/Repartition';
import ListItemPermissionEditRoleComponent from '../helpers/ListItem';
import SwitchPermissionFieldComponent from '../helpers/SwitchPermissionField';
import SelectPermissionFieldComponent from '../helpers/SelectPermissionField';
import getDefaultBranchOptions from '../helpers/getDefaultBrachOptions';
var defaultRentPermissionValues = {
    createNewRent: false,
    editRent: 0,
    closeRent: 0,
    getRent: 0,
    deleteRent: 0,
    allowedDeliveryRentStatus: [0, 1, 2, 3, 4, 5],
};
var RentPermissionEditRoleComponent = function (props) {
    var defaultBranchOptions = getDefaultBranchOptions();
    var _a = useState(defaultRentPermissionValues), values = _a[0], setValues = _a[1];
    useEffect(function () {
        setValues(function (values) { return (__assign(__assign({}, values), props.Permissions)); });
    }, [props.Permissions]);
    return (React.createElement(ListItemPermissionEditRoleComponent, { icon: React.createElement(RepartitionIcon, null), name: 'Rentas' },
        React.createElement("div", { className: 'mx-4 py-3 grid grid-cols-1 md:grid-cols-3 gap-4' },
            React.createElement("div", { className: 'flex flex-col' },
                React.createElement(SwitchPermissionFieldComponent, __assign({}, props, { name: 'createNewRent', label: 'Crear nuevas rentas', defaultValue: values.createNewRent })),
                React.createElement(SelectPermissionFieldComponent, __assign({}, props, { name: 'editRent', label: 'Editar contenido y comentario de una renta', defaultValue: values.editRent, options: defaultBranchOptions })),
                React.createElement(SelectPermissionFieldComponent, __assign({}, props, { name: 'closeRent', label: 'Cerrar renta', defaultValue: values.closeRent, options: defaultBranchOptions })),
                React.createElement(SelectPermissionFieldComponent, __assign({}, props, { name: 'getRent', label: 'Consultar datos generales y contenido de las rentas', defaultValue: values.getRent, options: defaultBranchOptions })),
                React.createElement(SelectPermissionFieldComponent, __assign({}, props, { name: 'deleteRent', label: 'Eliminar rentas', defaultValue: values.deleteRent, options: defaultBranchOptions }))))));
};
export default RentPermissionEditRoleComponent;
