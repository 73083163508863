import React from 'react';
import { useFormContext } from 'react-hook-form';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButtonComponent from '../../../../../../../presentationals/IconButton';
var DeleteProductListButtonComponent = function (props) {
    var _a = useFormContext(), getValues = _a.getValues, setValue = _a.setValue;
    var handleClick = function () {
        if (!props.productPresentationId)
            return;
        var values = getValues();
        var currentArray = values[props.arrayName];
        var newArray = currentArray.filter(function (el) { return el.productPresentationId !== props.productPresentationId; });
        setValue(props.arrayName, newArray);
    };
    return (React.createElement(IconButtonComponent, { label: 'Eliminar', disabled: !props.productPresentationId, action: handleClick, className: 'p-1', icon: React.createElement(DeleteIcon, { fontSize: 'small' }), size: 'large' }));
};
export default DeleteProductListButtonComponent;
