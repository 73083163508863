var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { useReactiveVar } from '@apollo/client';
import SelectBranchFieldComponent from '../../../../../presentationals/FormComponents/SelectBranchField';
import AutoRequisitionFieldComponent from './AutoRequisitionField';
import TextFieldComponent from '../../../../../presentationals/FormComponents/TextField';
import { inputError } from '../../../../../apollo/cache';
import useGetSession from '../../../../../hooks/useGetSession';
var BodyMainCreateRequisitionComponent = forwardRef(function (props, ref) {
    var defaultValues = {
        description: '',
        branchId: '',
        createAutoRequisition: false,
    };
    var session = useGetSession();
    var errors = useReactiveVar(inputError);
    var _a = useState(defaultValues), values = _a[0], setValues = _a[1];
    useEffect(function () {
        var _a;
        if ((_a = session === null || session === void 0 ? void 0 : session.Company) === null || _a === void 0 ? void 0 : _a.Branch) {
            var branchId_1 = session.Company.Branch.id.toString();
            setValues(function (values) { return (__assign(__assign({}, values), { branchId: branchId_1 })); });
        }
    }, [session]);
    var handleChange = function (name, value) {
        setValues(function (values) {
            var _a;
            return (__assign(__assign({}, values), (_a = {}, _a[name] = value, _a)));
        });
    };
    var handleCheckboxChange = function (name, value) {
        setValues(function (values) {
            var _a;
            return (__assign(__assign({}, values), (_a = {}, _a[name] = value, _a)));
        });
    };
    var getValues = function () { return values; };
    useImperativeHandle(ref, function () { return ({
        getValues: getValues,
    }); });
    return (React.createElement("div", { className: 'flex flex-1 flex-col px-4 py-3 basis-0 overflow-auto' },
        React.createElement("div", { className: 'grid flex-1 grid-cols-1 sm:grid-cols-4' },
            React.createElement("div", { className: 'flex flex-1 flex-col' },
                React.createElement(SelectBranchFieldComponent, { label: 'Sucursal a suministrar', disallowAllOptions: true, defaultValue: values.branchId, onChange: handleChange }),
                React.createElement(TextFieldComponent, { name: 'description', label: '\u00BFTienes alguna anotaci\u00F3n para esta requisici\u00F3n de suministro?', value: values.description, error: errors.description, multiline: true, rows: 4, onChange: handleChange }),
                React.createElement(AutoRequisitionFieldComponent, { value: values.createAutoRequisition, onChange: handleCheckboxChange })))));
});
export default BodyMainCreateRequisitionComponent;
