import React, { useState, useEffect } from 'react';

import { useMutation } from '@apollo/client';

import { format } from 'date-fns';
import locale from 'date-fns/locale/es';

import Chip from '@mui/material/Chip';

import FormDialog from '../../../../presentationals/FormDialog';
import TextField from '../../../../presentationals/TextField';

import GQL from '../../_gql';

const CommentaryDialog = props => {
  const [commentary, setCommentary] = useState('');

  const [updateQuoteProductCommentary, { loading }] = useMutation(
    GQL.UPDATE_QUOTE_PRODUCT_COMMENTARY,
    {
      update(cache, { data }) {
        try {
          cache.modify({
            id: `QuoteProduct:${data.updateQuoteProductCommentary.id}`,
            fields: {
              commentary() {
                return data.updateQuoteProductCommentary.commentary;
              },
            },
          });
        } catch (e) {
          console.log(e);
        }
      },
    },
  );

  useEffect(() => {
    setCommentary(props.commentary);
  }, [props.commentary]);

  const handleClose = () => {
    props.handleClose();
  };

  const handleAction = () => {
    try {
      updateQuoteProductCommentary({ variables: { id: props.id, commentary } });
      handleClose();
    } catch (e) {
      console.log(e.message);
    }
  };

  const handleChange = e => {
    const { value } = e.target;
    setCommentary(value);
  };

  const handleEdit = () => {
    setCommentary(
      `Correspondiente al mes de ${format(new Date(), 'MMMM', { locale })} de ${format(
        new Date(),
        'yyyy',
      )}`,
    );
  };

  return (
    <FormDialog
      title='Comentario del Producto'
      isOpen={props.isOpen || false}
      isLoading={loading}
      handleClose={handleClose}
      handleAction={handleAction}
      actionLabel='Actualizar'
    >
      <TextField
        label='Comentario'
        name='commentary'
        value={commentary}
        variant='outlined'
        onChange={handleChange}
        multiline
        noFormat
        autoFocus
        minRows={3}
      />
      <br />
      <Chip
        label={`Correspondiente al mes de ${format(new Date(), 'MMMM', { locale })} de ${format(
          new Date(),
          'yyyy',
        )}`}
        onClick={handleEdit}
      />
    </FormDialog>
  );
};

export default CommentaryDialog;
