import * as React from 'react';
import MuiSnackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import useSnackbarDialog from '../../store/snackbar/snackbarDialog';
var SnackbarComponent = function () {
    var setSnackbar = useSnackbarDialog().setSnackbar;
    var snackbar = useSnackbarDialog();
    var handleClose = function () {
        setSnackbar({ isOpen: false, time: 3000, label: '', severity: 'success' });
    };
    return (React.createElement(MuiSnackbar, { open: snackbar === null || snackbar === void 0 ? void 0 : snackbar.isOpen, autoHideDuration: (snackbar === null || snackbar === void 0 ? void 0 : snackbar.time) || 3000, anchorOrigin: {
            vertical: (snackbar === null || snackbar === void 0 ? void 0 : snackbar.vertical) || 'bottom',
            horizontal: (snackbar === null || snackbar === void 0 ? void 0 : snackbar.horizontal) || 'center',
        }, onClose: handleClose },
        React.createElement(MuiAlert, { elevation: 6, color: snackbar === null || snackbar === void 0 ? void 0 : snackbar.severity, variant: 'filled', onClose: handleClose }, snackbar === null || snackbar === void 0 ? void 0 : snackbar.label)));
};
export default SnackbarComponent;
