import { Card } from '@mui/material';
import React from 'react';
import DetailedReportSalesResumeComponent from './SaleResume';
import DetailedReportSalesDetailComponent from './SaleDetail';
var DetailSaleDetailedReportComponent = function () {
    return (React.createElement(Card, { className: 'mt-2 sm:mt-0 flex flex-1 flex-col overflow-hidden' },
        React.createElement("div", { className: 'flex flex-col bg-slate-700 text-white' },
            React.createElement("div", { className: 'font-semibold p-2' }, "Ventas"),
            React.createElement(DetailedReportSalesResumeComponent, null)),
        React.createElement(DetailedReportSalesDetailComponent, null)));
};
export default DetailSaleDetailedReportComponent;
