import React, { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import Typography from '@mui/material/Typography';
import FilterDrawer from '../../presentationals/FilterDrawer.v2';
import Autocomplete from '../../presentationals/FormComponents/Autocomplete';
import { filter } from '../../apollo/cache';
import GQL_PRODUCTS from '../product/_gql';
import GQL_CLASSIFICATION from '../../apollo/gql/classification';

const FilterDialog = () => {
  const [values, setValues] = useState({
    productId: null,
    providerId: null,
    classificationId: null,
  });
  const [products, setProducts] = useState([]);
  const [classifications, setClassifications] = useState([]);

  const { data: dataClassifications } = useQuery(GQL_CLASSIFICATION.GET_CLASSIFICATION, {
    variables: { limit: 0 },
  });
  const { data: dataProducts } = useQuery(GQL_PRODUCTS.GET_NAME_AND_PRESENTATIONS, {
    variables: { limit: 0 },
  });

  useEffect(() => {
    if (dataClassifications) {
      const tmp = dataClassifications.classifications.rows.map(el => ({
        label: el.name,
        value: el.id,
      }));
      setClassifications(tmp);
    }
  }, [dataClassifications]);

  useEffect(() => {
    if (dataProducts) {
      const tmp = dataProducts.productsV2.rows.map(el => ({
        label: `${String(el.code).padStart(3, '0')} ${el.name.trim()} ${
          el.sku ? ` | SKU ${el.sku.trim()}` : ''
        } ${el.barcode ? ` | C.B. ${el.barcode.trim()}` : ''}`.trim(),
        value: el.id,
      }));
      setProducts(tmp);
    }
  }, [dataProducts]);

  const handleAction = (e, data) => {
    const { startDate: start, endDate: end } = data;
    filter({
      classificationId: values.classificationId ? values.classificationId.value : null,
      productId: values.productId ? values.productId.value : null,
      start,
      end,
    });
  };

  const handleAutocompleteChange = (name, value) => {
    setValues(values => ({ ...values, [name]: value }));
  };

  return (
    <FilterDrawer handleAction={handleAction} showDates>
      <Typography variant='subtitle1'>Producto:</Typography>
      <Autocomplete
        name='productId'
        onChange={handleAutocompleteChange}
        options={products}
        value={values.productId}
        size='medium'
      />
      <br />
      <Typography variant='subtitle1'>Clasificación:</Typography>
      <Autocomplete
        name='classificationId'
        onChange={handleAutocompleteChange}
        options={classifications}
        value={values.classificationId}
        size='medium'
      />
    </FilterDrawer>
  );
};

export default FilterDialog;
