var getPurchaseOrderActionLabel = function (action) {
    switch (action) {
        case 1:
            return 'orden de compra';
        case 2:
        default:
            return 'compra';
    }
};
export default getPurchaseOrderActionLabel;
