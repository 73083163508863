import React, { useState, useEffect } from 'react';

import { useReactiveVar } from '@apollo/client';

import { filter } from '../../../../apollo/cache';

import FormDialog from '../../../FormDialog';
import TextField from '../../../FormComponents/TextField';

const SearchDialogComponent = props => {

  const filterVar = useReactiveVar(filter);

  const title = `Buscar ${props.title && props.title.toLowerCase()}`;

  const [value, setValue] = useState('');

  useEffect(() => {
    return () => {
      setValue('');
      filter({});
    };
  }, []);

  const handleClose = () => {
    props.handleClose();
  };
  const handleAction = () => {
    if (value && value !== '') {
      filter({ ...filterVar, name: value });
    } else {
      const newLocalFilter = Object.keys(filterVar).reduce((object, key) => {
        return key !== 'name' ? { ...object, [key]: filterVar[key] } : object;
      }, {});
      filter(newLocalFilter);
    }
    handleClose();
  };
  const handleChange = (name, value) => {
    setValue(value);
  };
  return (
    <FormDialog
        isOpen={props.isOpen}
        handleClose={handleClose}
        handleAction={handleAction}
        title=''
        actionLabel='Buscar'
    >
      <TextField
        name='search'
        label={title}
        onChange={handleChange}
        value={value}
      />
    </FormDialog>
  );
};

export default SearchDialogComponent;
