var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React, { createRef, forwardRef, useImperativeHandle, useMemo, useRef } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import getInputErrorFormat from '../../../sale.v2/Action/helpers/getInputErrorFormat';
import useApolloLazyQuery from '../../../../hooks/useApolloLazyQuery';
import GQL_SALE from '../../../../apollo/gql/sale';
import LoadingProgress from '../../../../presentationals/LoadingProgress';
import { dateTimeFormat } from '../../../../helpers';
import convertToCurrencyLabel from '../../../../helpers/convertToCurrencyLabel';
import CheckboxSaleGlobalInvoiceFieldComponent from './CheckboxField';
import TotalsTableSalesGlobalInvoiveComponent from './Totals';
import useBranchFieldStore from '../../../../store/filterInputs/branchFieldStore';
var TableSalesGlobalInvoiveComponent = forwardRef(function (props, ref) {
    var branchId = useBranchFieldStore(function (state) { return state.id; });
    var totalsTableSalesGlobalInvoiveRef = useRef(null);
    var _a = useApolloLazyQuery(GQL_SALE.GET_SALES('id clientCreatedAt folio customer taxes discount subtotal total')), getSales = _a[0], _b = _a[1], data = _b.data, loading = _b.loading;
    var checkboxSaleGlobalInvoiceFieldRefById = useMemo(function () {
        if (!data)
            return {};
        return data.rows.reduce(function (newArray, el) {
            var _a;
            return (__assign(__assign({}, newArray), (_a = {}, _a[el.id] = createRef(), _a)));
        }, {});
    }, [data]);
    var filter = function (dates, paymentForm, customer) { return __awaiter(void 0, void 0, void 0, function () {
        var e_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, getSales({
                            variables: {
                                status: [3, 4],
                                paymentMethodId: paymentForm.value,
                                start: dates[0],
                                end: dates[1],
                                isInvoice: false,
                                customerId: customer.value,
                                limit: 0,
                                offset: 0,
                                branchId: branchId || null,
                            },
                        })];
                case 1:
                    _a.sent();
                    return [3 /*break*/, 3];
                case 2:
                    e_1 = _a.sent();
                    getInputErrorFormat(e_1);
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    var handleCheckboxChange = function (id, isChecked) {
        var _a;
        var defaultArray = isChecked ? [id] : [];
        var array = Object.keys(checkboxSaleGlobalInvoiceFieldRefById).reduce(function (array, key) {
            var _a;
            var item = checkboxSaleGlobalInvoiceFieldRefById[key];
            var saleId = (_a = item === null || item === void 0 ? void 0 : item.current) === null || _a === void 0 ? void 0 : _a.checked();
            return saleId && saleId !== id ? __spreadArray(__spreadArray([], array, true), [saleId], false) : array;
        }, defaultArray);
        (_a = totalsTableSalesGlobalInvoiveRef.current) === null || _a === void 0 ? void 0 : _a.onSalesChange(array);
    };
    var handleSelectCheckbox = function (value, checked) {
        Object.keys(checkboxSaleGlobalInvoiceFieldRefById).forEach(function (key) {
            var _a;
            var item = checkboxSaleGlobalInvoiceFieldRefById[key];
            (_a = item === null || item === void 0 ? void 0 : item.current) === null || _a === void 0 ? void 0 : _a.setChecked(checked);
        });
    };
    var handleGetSales = function () {
        var array = Object.keys(checkboxSaleGlobalInvoiceFieldRefById).reduce(function (array, key) {
            var _a;
            var item = checkboxSaleGlobalInvoiceFieldRefById[key];
            var saleId = (_a = item === null || item === void 0 ? void 0 : item.current) === null || _a === void 0 ? void 0 : _a.checked();
            return saleId ? __spreadArray(__spreadArray([], array, true), [saleId], false) : array;
        }, []);
        return array;
    };
    useImperativeHandle(ref, function () { return ({
        filter: filter,
        getSales: handleGetSales,
    }); });
    if (loading)
        return React.createElement(LoadingProgress, null);
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: 'flex flex-1 flex-col border-0 border-t border-[#d6d6d6] border-solid' },
            React.createElement("div", { className: 'min-h-72 sm:min-h-full flex flex-1 flex-col overflow-auto basis-0' },
                React.createElement(TableContainer, null,
                    React.createElement(Table, { size: 'small', stickyHeader: true },
                        React.createElement(TableHead, null,
                            React.createElement(TableRow, null,
                                React.createElement(TableCell, { padding: 'checkbox' },
                                    React.createElement(CheckboxSaleGlobalInvoiceFieldComponent, { disabled: props.disabled, onChange: handleSelectCheckbox, id: -1 })),
                                React.createElement(TableCell, null, "Creado el"),
                                React.createElement(TableCell, null, "Cliente"),
                                React.createElement(TableCell, null, "Folio"),
                                React.createElement(TableCell, null, "Subtotal"),
                                React.createElement(TableCell, null, "Descuento"),
                                React.createElement(TableCell, null, "Impuestos"),
                                React.createElement(TableCell, null, "Importe"))),
                        React.createElement(TableBody, null, data &&
                            data.rows.map(function (el) { return (React.createElement(TableRow, { hover: !props.disabled, key: el.id },
                                React.createElement(TableCell, { padding: 'checkbox' },
                                    React.createElement(CheckboxSaleGlobalInvoiceFieldComponent, { disabled: props.disabled, ref: checkboxSaleGlobalInvoiceFieldRefById[el.id], onChange: handleCheckboxChange, id: el.id })),
                                React.createElement(TableCell, null, dateTimeFormat(el.clientCreatedAt)),
                                React.createElement(TableCell, null, el.customer),
                                React.createElement(TableCell, null, el.folio),
                                React.createElement(TableCell, null, convertToCurrencyLabel(el.subtotal)),
                                React.createElement(TableCell, null, convertToCurrencyLabel(el.discount)),
                                React.createElement(TableCell, null, convertToCurrencyLabel(el.taxes)),
                                React.createElement(TableCell, null, convertToCurrencyLabel(el.total)))); })))))),
        React.createElement(TotalsTableSalesGlobalInvoiveComponent, { ref: totalsTableSalesGlobalInvoiveRef, Sales: data })));
});
export default TableSalesGlobalInvoiveComponent;
