import React from 'react';
import { Divider } from '@mui/material';
import { dateFormat } from '../../../../helpers';
var WhatsNewItemComponent = function (props) {
    return (React.createElement("div", { className: 'flex flex-col' },
        React.createElement("div", { className: 'font-medium' }, dateFormat(props.date)),
        React.createElement(Divider, { className: 'my-1' }),
        React.createElement("ul", { className: 'px-3 my-1' }, props.lastUpdates.map(function (update) { return (React.createElement("li", { key: update, className: 'text-sm' }, update)); })),
        React.createElement("div", { className: 'mb-2' })));
};
export default WhatsNewItemComponent;
