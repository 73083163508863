import { useEffect, useState } from 'react';
import { useReactiveVar } from '@apollo/client';
import GQL_SALE from '../../../apollo/gql/sale';
import useApolloLazyQuery from '../../../hooks/useApolloLazyQuery';
import { filter } from '../../../apollo/cache';
import { currencyFormat } from '../../../helpers';
var useGetPromotionRotation = function () {
    var filterVar = useReactiveVar(filter);
    var _a = useState([]), data = _a[0], setData = _a[1];
    var _b = useApolloLazyQuery(GQL_SALE.GET_PROMOTION_ROTATION), getData = _b[0], _c = _b[1], loading = _c.loading, response = _c.data;
    useEffect(function () {
        if (response) {
            var data_1 = response.map(function (el) { return ({
                id: el.id,
                promotion: el.name,
                quantity: currencyFormat(el.count),
                total: currencyFormat(el.total),
            }); });
            setData(data_1);
        }
    }, [response]);
    useEffect(function () {
        if (Object.keys(filterVar).length > 0)
            getData({ variables: filterVar });
    }, [filterVar]);
    return { data: data, loading: loading };
};
export default useGetPromotionRotation;
