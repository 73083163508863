import React, { useEffect, useState, useRef } from 'react';
import { useReactiveVar } from '@apollo/client';

import {
  saleTotalToPay,
  invoiceSaleV2,
  inputError,
  formatSaleV2,
} from '../../../../../../apollo/cache';
import TextFieldComponent from '../../../../../../presentationals/FormComponents/TextField';
import useGetCompanySettings from '../../../../../../hooks/useGetCompanySettings';

const TextFieldPaymentFormBodyConfirmDialogSaleV2Component = () => {
  const { data: companySettigs } = useGetCompanySettings();
  const totalToPayInput = useRef();
  const invoiceSaleV2Var = useReactiveVar(invoiceSaleV2);
  const saleTotalToPayVar = useReactiveVar(saleTotalToPay);
  const formatSaleV2Var = useReactiveVar(formatSaleV2);
  const errors = useReactiveVar(inputError);
  const [value, setValue] = useState('');
  const [isDisabled, setIsDisabled] = useState(false);

  useEffect(() => {
    if (
      invoiceSaleV2Var.paymentMethod === 'PPD' &&
      companySettigs?.company?.blockPaymentInPpd &&
      formatSaleV2Var[0] === '-1'
    ) {
      setIsDisabled(true);
      setValue('');
      const newSaleTotalToPay = saleTotalToPayVar.map(el => ({ ...el, total: '' }));
      saleTotalToPay(newSaleTotalToPay);
    }
  }, [companySettigs, invoiceSaleV2Var]);

  useEffect(() => {
    const higherPayment = saleTotalToPayVar.reduce((code, el) => {
      if (!code) return { code: el.code, total: el.total ? parseFloat(el.total) : 0 };
      return parseFloat(el.total) > parseFloat(code.total)
        ? { code: el.code, total: parseFloat(el.total) }
        : code;
    }, null);
    if (higherPayment && higherPayment.total > 0) {
      const currentInvoiceSaleV2 = invoiceSaleV2();
      invoiceSaleV2({ ...currentInvoiceSaleV2, paymentForm: higherPayment.code });
    }
    const currentPaymentMethod = saleTotalToPayVar.find(el => el.selected);
    setValue(currentPaymentMethod ? `${currentPaymentMethod.total}` : '');
    totalToPayInput.current?.focus();
  }, [saleTotalToPayVar, totalToPayInput]);

  const handleChange = (name, value) => {
    const newSaleTotalToPay = saleTotalToPayVar.map(el =>
      el.selected ? { ...el, total: value } : el,
    );
    saleTotalToPay(newSaleTotalToPay);
    setValue(value);
  };

  return (
    <div className='flex'>
      <TextFieldComponent
        autoFocus
        disabled={isDisabled}
        inputRef={totalToPayInput}
        label='Importe a pagar'
        name='totalToPay'
        value={value}
        error={errors.totalToPay}
        onChange={handleChange}
      />
    </div>
  );
};

export default TextFieldPaymentFormBodyConfirmDialogSaleV2Component;
