import React, { useEffect } from 'react';
import { useReactiveVar } from '@apollo/client';
import BillingInformationEditBranchComponent from '../../../../../../../branch/Edit/BillingInformation';
import { branchSaleV2 } from '../../../../../../../../apollo/cache';
import DialogComponent from '../../../../../../../../presentationals/Dialog';
import useApolloLazyQuery from '../../../../../../../../hooks/useApolloLazyQuery';
import GQL_BRANCH from '../../../../../../../../apollo/gql/branch';
import LoadingProgress from '../../../../../../../../presentationals/LoadingProgress';
var EditBranchBillingInformationComponent = function (props) {
    var branchSale = useReactiveVar(branchSaleV2).branchSale;
    var _a = useApolloLazyQuery(GQL_BRANCH.GET_BRANCH_BY_ID), getBranch = _a[0], _b = _a[1], loading = _b.loading, data = _b.data;
    useEffect(function () {
        if (branchSale)
            getBranch({ variables: { id: branchSale } });
    }, [branchSale]);
    return (React.createElement(DialogComponent, { fullScreen: true, open: props.open, onClose: props.onClose, title: 'Editar datos de facturaci\u00F3n de la sucursal' }, loading ? (React.createElement(LoadingProgress, null)) : (React.createElement(BillingInformationEditBranchComponent, { onClose: props.onClose, defaultValues: data }))));
};
export default EditBranchBillingInformationComponent;
