import React, { useEffect, useState } from 'react';

import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/SaveOutlined';
import CloseIcon from '@mui/icons-material/Close';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import FormDialog from '../../presentationals/FormDialog';
import TextField from '../../presentationals/TextField';

import IconButton from './cart/table/helpers/iconButton';

const defaultValues = {
  productKey: '',
  unitKey: '',
  i: null,
};
const TableComponent = props => {
  const [itemToEdit, setItemToEdit] = useState(null);
  const [errors, setErrors] = useState({});
  const [values, setValues] = useState(defaultValues);
  const [Products, setProducts] = useState([]);

  useEffect(() => {
    if (props.Products) {
      const data = props.Products.map(el => {
        if (el.productId) return false;
        return {
          id: el.id,
          productKey: '',
          unitKey: '',
          name: el.name,
        };
      }).filter(tmp => tmp);
      setProducts(data);
    }
  }, [props.Products]);

  const handleEdit = (e, i) => {
    const row = props.Products[i];
    setItemToEdit(i);
    setValues({
      productKey: row.productKey,
      unitKey: row.unitKey,
      i,
    });
  };

  const handleSave = () => {
    const tmp = Products.map((el, i) => {
      if (parseInt(i) !== parseInt(values.i)) return el;
      return {
        ...el,
        productKey: values.productKey,
        unitKey: values.unitKey,
      };
    });
    setProducts(tmp);
    setItemToEdit(null);
    setErrors({});
    setValues(defaultValues);
  };

  const handleCancelEdit = () => {
    setItemToEdit(null);
    setErrors({});
    setValues(defaultValues);
  };

  const handleChange = e => {
    const { name, value } = e.target;
    setValues(values => ({ ...values, [name]: value }));
  };

  const handleClose = () => {
    setItemToEdit(null);
    setErrors({});
    setValues(defaultValues);
    props.handleClose();
  };

  const handleAction = () => {
    props.handleAction(null, true, Products);
  };

  return (
    <FormDialog
      isOpen={props.isOpen}
      title='Productos Fuera de Inventario'
      disableEscape
      handleClose={handleClose}
      handleAction={handleAction}
      width='lg'
    >
      <TableContainer>
        <Table size='small'>
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell align='center'>Producto</TableCell>
              <TableCell align='center'>Clave de Producto/Servicio</TableCell>
              <TableCell align='center'>Unidad</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Products &&
              Products.map((el, i) => (
                <TableRow key={i}>
                  <TableCell>
                    <div className='flex items-center'>
                      {itemToEdit !== i ? (
                        !props.noEdit && (
                          <IconButton
                            label='Editar'
                            size='small'
                            icon={<EditIcon />}
                            params={i}
                            action={handleEdit}
                          />
                        )
                      ) : (
                        <>
                          <IconButton
                            label='Guardar cambios'
                            size='small'
                            icon={<SaveIcon />}
                            params={i}
                            action={handleSave}
                          />
                          <IconButton
                            label='Cancelar'
                            size='small'
                            icon={<CloseIcon />}
                            params={i}
                            action={handleCancelEdit}
                          />
                        </>
                      )}
                    </div>
                  </TableCell>
                  <TableCell align='center'>
                    {el.Product ? el.Product.name.trim() : el.name ? el.name : ''}
                  </TableCell>
                  <TableCell align='center'>
                    {itemToEdit !== i ? (
                      el.productKey
                    ) : (
                      <div className='w-[130px]'>
                        <TextField
                          name='productKey'
                          onChange={handleChange}
                          value={values.productKey}
                          error={errors.productKey}
                        />
                      </div>
                    )}
                  </TableCell>
                  <TableCell align='center'>
                    {itemToEdit !== i ? (
                      el.unitKey
                    ) : (
                      <div className='w-[130px]'>
                        <TextField
                          name='unitKey'
                          onChange={handleChange}
                          value={values.unitKey}
                          error={errors.unitKey}
                        />
                      </div>
                    )}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </FormDialog>
  );
};

export default TableComponent;
