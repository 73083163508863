import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Alert, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, } from '@mui/material';
import OpenAddAddressLine2Button from './OpenAddAddressLine2Button';
import useApolloLazyQuery from '../../../../hooks/useApolloLazyQuery';
import GQL_BRANCH from '../../../../apollo/gql/branch';
import LoadingProgress from '../../../../presentationals/LoadingProgress';
import ErrorDataComponent from '../../../../presentationals/ErrorData';
import TableAddressLine2RowComponent from './TableRow';
import CreateAdressLine2DialogComponent from './CreateDialog';
import EditAdressLine2DialogComponent from './EditDialog';
import { currencyFormat } from '../../../../helpers';
var AdressesLine2BranchComponent = function () {
    var params = useParams();
    var _a = useApolloLazyQuery(GQL_BRANCH.GET_ADDRESSES_LINE_2), getData = _a[0], _b = _a[1], loading = _b.loading, data = _b.data;
    useEffect(function () {
        if (params.branchId)
            getData({ variables: { branchId: params.branchId } });
    }, [params.branchId]);
    if (loading)
        return React.createElement(LoadingProgress, null);
    if (!data)
        return React.createElement(ErrorDataComponent, null);
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: 'grid flex-1 grid-cols-1 sm:grid-cols-2' },
            React.createElement("div", { className: 'flex flex-1 flex-col px-4 pt-3 pb-5' },
                React.createElement("div", { className: 'flex flex-row items-center justify-between' },
                    React.createElement("div", { className: 'text-base font-medium mb-1 flex' }, "Gesti\u00F3n de colonias"),
                    React.createElement(OpenAddAddressLine2Button, null)),
                React.createElement("div", { className: 'text-sm sm:text-base text-gray-600 mb-2' }, "El registro de colonias te permitir\u00E1 llevar un control de los sectores con mayor afluencia en tu empresa, por ejemplo, colonias donde viven la mayor parte de tus clientes. sectores con mayor flujo de efectivo, entre otros datos."),
                React.createElement("div", { className: 'pt-4 flex flex-1 flex-col' }, data.length === 0 ? (React.createElement(Alert, { severity: 'info', className: 'w-full' }, "De momento no cuentas con colonias registradas.")) : (React.createElement("div", { className: 'flex flex-1 basis-0 overflow-auto' },
                    React.createElement(TableContainer, { className: 'rounded border border-solid border-[#d8d8d8]' },
                        React.createElement(Table, { stickyHeader: true, size: 'small' },
                            React.createElement(TableHead, null,
                                React.createElement(TableRow, { className: 'bg-[#f6f6f6]' },
                                    React.createElement(TableCell, null, "Nombre"),
                                    React.createElement(TableCell, null, "C\u00F3digo postal"),
                                    React.createElement(TableCell, null, "Precio de domicilio"))),
                            React.createElement(TableBody, null, data.map(function (el) { return (React.createElement(TableAddressLine2RowComponent, { key: el.id, id: el.id },
                                React.createElement(TableCell, null, el.name),
                                React.createElement(TableCell, null, el.zipCode),
                                React.createElement(TableCell, null, el.price ? currencyFormat(el.price) : 'No definido'))); }))))))))),
        React.createElement(CreateAdressLine2DialogComponent, null),
        React.createElement(EditAdressLine2DialogComponent, null)));
};
export default AdressesLine2BranchComponent;
