var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { useReactiveVar } from '@apollo/client';
import getDefaultCashFlowValues from '../helpers/getDefaultValues';
import CashFlowActionFieldComponent from './CashFlowActionField';
import TextFieldComponent from '../../../../presentationals/FormComponents/TextField';
import { inputError } from '../../../../apollo/cache';
var BodyFormCashFlowComponent = forwardRef(function (props, ref) {
    var defaultValues = getDefaultCashFlowValues();
    var errors = useReactiveVar(inputError);
    var _a = useState(defaultValues), values = _a[0], setValues = _a[1];
    useEffect(function () {
        if (props.defaultValues)
            setValues(props.defaultValues);
    }, [props.defaultValues]);
    var getValues = function () { return values; };
    useImperativeHandle(ref, function () { return ({
        getValues: getValues,
    }); });
    var handleChange = function (name, value) {
        setValues(function (values) {
            var _a;
            return (__assign(__assign({}, values), (_a = {}, _a[name] = value, _a)));
        });
    };
    var handleKeyPress = function (e) {
        if (e.key === 'Enter') {
            e.preventDefault();
            props.onPressEnter && props.onPressEnter();
        }
    };
    return (React.createElement("div", { className: 'grid grid-cols-1 sm:grid-cols-4 flex-1 flex-col basis-0 overflow-auto' },
        React.createElement("div", { className: 'flex flex-1 flex-col px-4 py-3' },
            React.createElement(CashFlowActionFieldComponent, { value: values.action, onChange: handleChange }),
            React.createElement(TextFieldComponent, { name: 'description', label: 'Motivo del movimiento de caja', value: values.description, error: errors.description, onChange: handleChange, onKeyDown: handleKeyPress }),
            React.createElement(TextFieldComponent, { name: 'total', label: 'Importe', type: 'number', value: values.total, error: errors.total, onChange: handleChange, onKeyDown: handleKeyPress }))));
});
export default BodyFormCashFlowComponent;
