import CREATE_MANUFACTURE from './createManufacture';
import DELETE_MANUFACTURE from './deleteManufacture';
import FRAGMENT_MANUFACTURE from './fragmentManufacture';
import GET_MANUFACTURES from './getManufactures';
var GQL_MANUFACTURE = {
    CREATE_MANUFACTURE: CREATE_MANUFACTURE,
    DELETE_MANUFACTURE: DELETE_MANUFACTURE,
    FRAGMENT_MANUFACTURE: FRAGMENT_MANUFACTURE,
    GET_MANUFACTURES: GET_MANUFACTURES,
};
export default GQL_MANUFACTURE;
