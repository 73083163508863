var getPercentAdjustByCostAndSalePrice = function (cost, salePrice, formula) {
    var formattedCost = isNaN(cost) ? 0 : cost;
    var formattedSalePrice = isNaN(salePrice) ? 0 : salePrice;
    if (formattedSalePrice === 0)
        return 0;
    switch (formula) {
        default:
            return 100 - (formattedCost * 100) / formattedSalePrice;
    }
};
export default getPercentAdjustByCostAndSalePrice;
