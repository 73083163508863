import { useReactiveVar } from '@apollo/client';
import React from 'react';
import { isPaymentFormOpen } from '../../../../apollo/cache';
import DialogComponent from '../../../../presentationals/Dialog';
import BodyPaymentDialogComponent from './Body';
var PaymentDialogComponent = function () {
    var isOpen = useReactiveVar(isPaymentFormOpen);
    var handleClose = function () {
        isPaymentFormOpen(false);
    };
    return (React.createElement(DialogComponent, { open: !!isOpen, title: 'Abonar a cuenta por cobrar', onClose: handleClose, fullScreen: true },
        React.createElement(BodyPaymentDialogComponent, { onSuccess: handleClose })));
};
export default PaymentDialogComponent;
