import React, { useState, useEffect } from 'react';
// import { useQuery } from '@apollo/client';
import AutocompleteField from '../../../../../../../../../presentationals/FormComponents/Autocomplete';

// import GQL from '../../../../../../../../employee/_gql';
import useGetEmployees from '../../../../../../../../../hooks/useGetEmployees';

const EmployeeIdAutocompletedHelpersBodyCreateFormCustomerActionSaleV2Component = props => {
  const [options, setOptions] = useState([]);
  const [value, setValue] = useState(null);
  const { data, loading } = useGetEmployees({ isSeller: true });

  useEffect(() => {
    if (data && data.rows) {
      const newData = data.rows.map(el => ({
        value: el.id,
        label: `${el.firstName} ${el.lastName}`,
      }));
      setOptions(newData);
    }
  }, [data]);

  useEffect(() => {
    if (props.value && options.length > 0) {
      if (props.value.value) {
        setValue(props.value);
        return;
      }
      const getValue = options.find(el => parseInt(el.value) === parseInt(props.value));
      setValue(getValue);
    } else {
      setValue(null);
    }
  }, [props.value, options]);

  return (
    <AutocompleteField
      label={props.label || 'Asesor de ventas'}
      name='employeeId'
      value={value}
      error={props.error}
      disabled={loading}
      onChange={props.handleChange}
      options={options}
    />
  );
};

export default EmployeeIdAutocompletedHelpersBodyCreateFormCustomerActionSaleV2Component;
