var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useEffect, useState } from 'react';
import { useReactiveVar } from '@apollo/client';
import FooterDialogComponent from '../../../../../../presentationals/Dialog/Footer';
import LimitInventoryActionButtonComponent from './ActionButton';
import getDefaultLimitInventoryValues from '../helpers/getDefaultValues';
import TextFieldComponent from '../../../../../../presentationals/FormComponents/TextField';
import { inputError } from '../../../../../../apollo/cache';
import BranchFieldProductPromotionPriceComponent from '../../../../../promotion/CreateProductPromotionPrice/Body/BranchField';
import LimitInventoryDeleteButtonComponent from './DeleteButton';
var BodyLimitInventoryFormComponent = function (props) {
    var errors = useReactiveVar(inputError);
    var defaultValues = getDefaultLimitInventoryValues();
    var _a = useState(defaultValues), values = _a[0], setValues = _a[1];
    useEffect(function () {
        if (props.defaultValues)
            setValues(__assign({}, props.defaultValues));
    }, [props.defaultValues]);
    var handleChange = function (name, value) {
        setValues(function (values) {
            var _a;
            return (__assign(__assign({}, values), (_a = {}, _a[name] = value, _a)));
        });
    };
    return (React.createElement("div", { className: 'flex flex-1 flex-col overflow-hidden' },
        React.createElement("div", { className: 'flex flex-1 flex-col w-1/3 overflow-y-auto px-4 py-2' },
            React.createElement(TextFieldComponent, { label: 'M\u00EDnimo en inventario', name: 'minimum', value: values.minimum, onChange: handleChange, error: errors.minimum, type: 'number' }),
            React.createElement(TextFieldComponent, { label: 'M\u00E1ximo en inventario', name: 'maximum', value: values.maximum, onChange: handleChange, error: errors.maximum, type: 'number' }),
            React.createElement(BranchFieldProductPromotionPriceComponent, { defaultOptionLabel: 'Selecciona una sucursal', value: values.branchId, onChange: handleChange })),
        React.createElement(FooterDialogComponent, null,
            props.deleteId ? React.createElement(LimitInventoryDeleteButtonComponent, { id: props.deleteId }) : React.createElement("div", null),
            React.createElement(LimitInventoryActionButtonComponent, { values: values, label: props.buttonLabel, loading: props.loading, onClick: props.onButtonClick }))));
};
export default BodyLimitInventoryFormComponent;
