import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { productPromotionPriceForm } from '../../../apollo/cache';
import BodyProductPromotionPriceComponent from '../../promotion/CreateProductPromotionPrice/Body';
import FooterDialogComponent from '../../../presentationals/Dialog/Footer';
import EditProductIngredientPriceButtonComponent from './EditButton';
import LoadingProgress from '../../../presentationals/LoadingProgress';
import DeleteProductIngredientPriceButtonComponent from './DeleteButton';
import getDefaultProductPromotionPriceValues from '../../promotion/CreateProductPromotionPrice/Body/helpers/getDefaultValues';
import GQL_PRODUCT from '../../../apollo/gql/product';
import useApolloQuery from '../../../hooks/useApolloQuery';
var EditProductIngredientPriceComponent = function () {
    var params = useParams();
    var _a = useApolloQuery(GQL_PRODUCT.GET_PRODUCT_INGREDIENT_PRICE_BY_ID, {
        variables: {
            id: params.componentRateId,
        },
    }), loading = _a.loading, data = _a.data;
    useEffect(function () {
        if (data)
            productPromotionPriceForm({
                price: data.price,
                type: data.type || -1,
                branchId: data.branchId || -1,
            });
    }, [data]);
    useEffect(function () {
        return function () {
            var defaultValues = getDefaultProductPromotionPriceValues();
            productPromotionPriceForm(defaultValues);
        };
    }, []);
    if (loading || !data)
        return React.createElement(LoadingProgress, null);
    return (React.createElement("div", { className: 'flex flex-1 flex-col overflow-hidden' },
        React.createElement(BodyProductPromotionPriceComponent, null),
        React.createElement(FooterDialogComponent, null,
            React.createElement(DeleteProductIngredientPriceButtonComponent, null),
            React.createElement(EditProductIngredientPriceButtonComponent, null))));
};
export default EditProductIngredientPriceComponent;
