import React from 'react';
import { useReactiveVar } from '@apollo/client';

import { productSaleV2 } from '../../../../apollo/cache';
import CONFIG from '../../../../config/config.json';

const ProductImageChooseProductSaleV2Component = () => {
  const productSaleV2Var = useReactiveVar(productSaleV2);

  if (!productSaleV2Var.fileId) return <div className='w-20 h-full bg-gray-300 rounded' />;

  return (
    <div className='w-20 h-full rounded overflow-hidden relative'>
      <img
        src={`${CONFIG['api-file-service'].url}/files/${productSaleV2Var.fileId}`}
        alt={productSaleV2Var.name}
        className='w-20 absolute'
      />
    </div>
  );
};

export default ProductImageChooseProductSaleV2Component;
