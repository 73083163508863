import DELETE_INVOICE_COMPLEMENT from './deleteInvoiceComplement';
import FRAGMENT_INVOICE_COMPLEMENT from './fragmentInvoiceComplement';
import GET_INVOICE_COMPLEMENT_BY_ID from './getInvoiceComplementById';
import GET_INVOICE_COMPLEMENTS from './getInvoiceComplements';
import SEND_INVOICE_COMPLEMENT_BY_EMAIL from './sendInvoiceComplementByEmail';
var GQL_INVOICE_COMPLEMENT = {
    DELETE_INVOICE_COMPLEMENT: DELETE_INVOICE_COMPLEMENT,
    FRAGMENT_INVOICE_COMPLEMENT: FRAGMENT_INVOICE_COMPLEMENT,
    GET_INVOICE_COMPLEMENTS: GET_INVOICE_COMPLEMENTS,
    GET_INVOICE_COMPLEMENT_BY_ID: GET_INVOICE_COMPLEMENT_BY_ID,
    SEND_INVOICE_COMPLEMENT_BY_EMAIL: SEND_INVOICE_COMPLEMENT_BY_EMAIL,
};
export default GQL_INVOICE_COMPLEMENT;
