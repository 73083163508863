import React, { useRef } from 'react';
import { dateFormat } from '../../../helpers';
import DateRangeCalendarFieldComponent from './Calendar';
import useDateRangeFieldStore from '../../../store/filterInputs/dateRangeFieldStore';
var DateRangeFieldComponent = function () {
    var value = useDateRangeFieldStore(function (state) { return state.value; });
    var setValue = useDateRangeFieldStore(function (state) { return state.setDateRange; });
    var dateRangeCalendarFieldRef = useRef(null);
    var handleClick = function (event) {
        var _a;
        (_a = dateRangeCalendarFieldRef.current) === null || _a === void 0 ? void 0 : _a.open(event.currentTarget);
    };
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { role: 'button', tabIndex: -1, onClick: handleClick, className: 'flex flex-row items-center justify-center cursor-pointer' },
            React.createElement("div", { className: 'flex flex-col hover:bg-[#ececec] rounded px-2 py-1' },
                React.createElement("div", { className: 'text-xs text-gray-600 leading-none tracking-tighter' }, "Del"),
                React.createElement("div", { className: 'leading-none tracking-tighter text-gray-800' }, dateFormat(value[0]))),
            React.createElement("div", { className: 'ml-2 flex flex-col hover:bg-[#ececec] rounded px-2 py-1' },
                React.createElement("div", { className: 'text-xs text-gray-600 leading-none tracking-tighter' }, "Al"),
                React.createElement("div", { className: 'leading-none tracking-tighter text-gray-800' }, dateFormat(value[1])))),
        React.createElement(DateRangeCalendarFieldComponent, { ref: dateRangeCalendarFieldRef, onChange: setValue })));
};
export default DateRangeFieldComponent;
