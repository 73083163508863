var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useState } from 'react';
import { useReactiveVar } from '@apollo/client';
import FooterDialogComponent from '../../../../../presentationals/Dialog/Footer';
import CreateSurveyQuestionComponent from './CreateButton';
import TextFieldComponent from '../../../../../presentationals/FormComponents/TextField';
import { inputError } from '../../../../../apollo/cache';
import SelectFieldComponent from '../../../../../presentationals/FormComponents/SelectField';
var BodyCreateSurveyQuestionComponent = function () {
    var defaultValues = {
        name: '',
        type: 1,
        sort: '',
    };
    var typeOptions = [
        { value: 1, label: 'Pregunta abierta' },
        { value: 2, label: 'Pregunta con rango numérico' },
        { value: 3, label: 'Pregunta con opción múltiple' },
        { value: 4, label: 'Pregunta con opción única' },
        { value: 101, label: 'Título' },
        { value: 102, label: 'Subtítulo' },
        { value: 103, label: 'Clasificación' },
    ];
    var errors = useReactiveVar(inputError);
    var _a = useState(defaultValues), values = _a[0], setValues = _a[1];
    var handleChange = function (name, value) {
        setValues(function (values) {
            var _a;
            return (__assign(__assign({}, values), (_a = {}, _a[name] = value, _a)));
        });
    };
    return (React.createElement("div", { className: 'flex flex-1 flex-col' },
        React.createElement("div", { className: 'grid grid-cols-1 sm:grid-cols-4 basis-0 overflow-auto flex-1 flex-col' },
            React.createElement("div", { className: 'flex flex-1 flex-col px-4 py-3' },
                React.createElement(SelectFieldComponent, { name: 'type', label: 'Tipo de pregunta o t\u00EDtulo', value: values.type, error: errors.type, onChange: handleChange, options: typeOptions }),
                React.createElement(TextFieldComponent, { name: 'name', label: values.type > 100 ? 'Nombre del título' : 'Nombre de la pregunta', value: values.name, error: errors.name, onChange: handleChange }),
                React.createElement(TextFieldComponent, { name: 'sort', label: 'Posici\u00F3n en el formulario', value: values.sort, error: errors.sort, onChange: handleChange }))),
        React.createElement(FooterDialogComponent, null,
            React.createElement("div", null),
            React.createElement(CreateSurveyQuestionComponent, { values: values }))));
};
export default BodyCreateSurveyQuestionComponent;
