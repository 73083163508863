import React from 'react';
import { isCalendarFormOpen, employeeSaleV2, calendarId, isEditCalendarFormOpen, } from '../../../../../apollo/cache';
import Button from '../../helpers/Button';
var ButtonCurrencyActionSaleV2Component = function () {
    var handleClick = function () {
        var calendarIdVar = calendarId();
        if (calendarIdVar) {
            isEditCalendarFormOpen(calendarIdVar);
            return;
        }
        var employeeSaleV2Var = employeeSaleV2();
        var newDate = new Date();
        isCalendarFormOpen([
            true,
            {
                start: newDate,
                end: newDate,
                forSale: true,
                employeeId: parseFloat(employeeSaleV2Var) > 0 ? employeeSaleV2Var : null,
            },
        ]);
    };
    return React.createElement(Button, { title: 'Agendar evento', subtitle: '', onClick: handleClick });
};
export default ButtonCurrencyActionSaleV2Component;
