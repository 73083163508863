import React from 'react';
import ButtonFooterDialogComponent from '../../../../../../../presentationals/Dialog/Footer/Button';
import getInputErrorFormat from '../../../../../../sale.v2/Action/helpers/getInputErrorFormat';
import { isEditProductLimitInventoryOpen } from '../../../../../../../apollo/cache';
import useApolloMutation from '../../../../../../../hooks/useApolloMutation';
import GQL_PRODUCT from '../../../../../../../apollo/gql/product';
var LimitInventoryDeleteButtonComponent = function (props) {
    var _a = useApolloMutation(GQL_PRODUCT.DELETE_BRANCH_PRODUCT_PRESENTATION_CONFIGURATION, {
        action: 'delete',
        name: 'BranchProductPresentationConfiguration',
    }), deleteLimitInventory = _a[0], loading = _a[1].loading;
    var handleClick = function () {
        try {
            deleteLimitInventory({ variables: { id: props.id } });
            isEditProductLimitInventoryOpen(false);
        }
        catch (error) {
            getInputErrorFormat(error);
        }
    };
    return (React.createElement(ButtonFooterDialogComponent, { color: 'error', loading: loading, onClick: handleClick }, "Eliminar"));
};
export default LimitInventoryDeleteButtonComponent;
