var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React, { useEffect, useState } from 'react';
import { Divider } from '@mui/material';
import { useReactiveVar } from '@apollo/client';
import FooterDialogComponent from '../../Dialog/Footer';
import SendInvoiceComplementByEmailButtonComponent from './SendButton';
import { isSendInvoiceComplementByEmailOpen } from '../../../apollo/cache';
import useApolloLazyQuery from '../../../hooks/useApolloLazyQuery';
import LoadingProgress from '../../LoadingProgress';
import ErrorDataComponent from '../../ErrorData';
import GQL_INVOICE_COMPLEMENT from '../../../apollo/gql/invoiceComplement';
import EmailFieldSendSaleComponent from '../../SendSaleByEmailDialog/Body/EmailField';
import ItemSendSaleComponent from '../../SendSaleByEmailDialog/Body/Item';
import GQL_CUSTOMER from '../../../apollo/gql/customer';
var BodySendSaleByEmailDialogComponent = function () {
    var invoiceComplementId = useReactiveVar(isSendInvoiceComplementByEmailOpen);
    var _a = useState([]), values = _a[0], setValues = _a[1];
    var _b = useApolloLazyQuery(GQL_INVOICE_COMPLEMENT.GET_INVOICE_COMPLEMENT_BY_ID), getData = _b[0], _c = _b[1], loading = _c.loading, data = _c.data;
    var _d = useApolloLazyQuery(GQL_CUSTOMER.GET_CUSTOMER_BY_ID), getCustomer = _d[0], _e = _d[1], loadingCustomer = _e.loading, customer = _e.data;
    useEffect(function () {
        if (data)
            getCustomer({ variables: { id: data.customerId } });
    }, [data]);
    useEffect(function () {
        if (customer === null || customer === void 0 ? void 0 : customer.CustomerBillingInformation) {
            var BillingInformation = customer === null || customer === void 0 ? void 0 : customer.CustomerBillingInformation;
            var email = BillingInformation.email || '';
            var altEmail = BillingInformation.altEmail || '';
            var otherEmail = BillingInformation.otherEmail || '';
            setValues([email, altEmail, otherEmail].filter(function (el) { return el; }));
        }
    }, [customer]);
    useEffect(function () {
        if (invoiceComplementId)
            getData({ variables: { id: invoiceComplementId } });
    }, [invoiceComplementId]);
    var handleAddClick = function (value) {
        setValues(function (values) { return __spreadArray(__spreadArray([], values, true), [value], false); });
    };
    var handleDelete = function (index) {
        setValues(function (values) { return values.filter(function (el, i) { return i !== index; }); });
    };
    if (loading || loadingCustomer)
        return React.createElement(LoadingProgress, null);
    if (!data)
        return React.createElement(ErrorDataComponent, null);
    return (React.createElement("div", { className: 'flex flex-1 flex-col' },
        React.createElement("div", { className: 'flex flex-1 flex-col px-4 py-3' },
            React.createElement("div", { className: 'text-lg' }, "Instrucciones"),
            React.createElement("div", { className: 'text-sm' }, "Proporciona los correos electr\u00F3nicos a los que quieres hacer llegar los documentos. En caso de no recibir el correo electr\u00F3nico, revisa la bandeja de correos no deseados."),
            React.createElement("div", { className: 'grid grid-cols-1 sm:grid-cols-3 flex-1 flex-col mt-4' },
                React.createElement("div", { className: 'flex flex-1 flex-col' },
                    React.createElement(EmailFieldSendSaleComponent, { onClick: handleAddClick }),
                    values.length > 0 && React.createElement(Divider, { className: 'mt-2' }),
                    values.map(function (label, i) { return (React.createElement(ItemSendSaleComponent, { key: i, index: i, label: label, onDelete: handleDelete })); })))),
        React.createElement(FooterDialogComponent, null,
            React.createElement("div", null),
            React.createElement(SendInvoiceComplementByEmailButtonComponent, { values: values }))));
};
export default BodySendSaleByEmailDialogComponent;
