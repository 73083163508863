import React from 'react';
import { useReactiveVar } from '@apollo/client';
import BodyFormCalendarComponent from '../../Create/Body';
import EditButtonGeneralEditCalendarComponent from './EditButton';
import FooterDialogComponent from '../../../../presentationals/Dialog/Footer';
import PostponeButtonGeneralEditCalendarComponent from './PostponeButton';
import PrintButtonEditCalendarComponent from './PrintButton';
import AttendanceButtonComponent from './AttendanceButton';
import InEventButtonComponent from './InEventButton';
import { calendarId } from '../../../../apollo/cache';
var GeneralEditCalendarComponent = function (props) {
    var saleCalendarId = useReactiveVar(calendarId);
    return (React.createElement("div", { className: 'flex flex-1 flex-col h-screen overflow-hidden mt-1' },
        React.createElement(BodyFormCalendarComponent, { disabled: props.disabled }),
        React.createElement(FooterDialogComponent, null,
            React.createElement("div", { className: 'flex' },
                !saleCalendarId && (React.createElement(React.Fragment, null,
                    React.createElement(InEventButtonComponent, { disabled: props.disabled }),
                    React.createElement(AttendanceButtonComponent, { disabled: props.disabled }),
                    React.createElement(PostponeButtonGeneralEditCalendarComponent, { disabled: props.disabled }))),
                React.createElement(PrintButtonEditCalendarComponent, { disabled: props.disabled })),
            React.createElement(EditButtonGeneralEditCalendarComponent, { disabled: props.disabled }))));
};
export default GeneralEditCalendarComponent;
