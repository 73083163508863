import React from 'react';
import { useController, useFormContext } from 'react-hook-form';
import RHFButtonComponent from '../../Button';
import RHFTextFieldComponent from '../../TextField';
var BodyDiscountPopoverComponent = function (props) {
    var control = useFormContext().control;
    var onChange = useController({
        name: props.name,
        control: control,
    }).field.onChange;
    var value = useController({
        name: 'discountInput',
        control: control,
    }).field.value;
    var handleClick = function () {
        onChange(value);
        props.onClick();
    };
    return (React.createElement("div", { className: 'flex max-h-64 overflow-y-auto overflow-x-hidden flex-col px-4 py-3' },
        React.createElement(RHFTextFieldComponent, { autoFocus: true, label: 'Descuento %', name: 'discountInput', type: 'number' }),
        React.createElement("div", { className: 'mt-2' },
            React.createElement(RHFButtonComponent, { type: 'button', onClick: handleClick, className: 'px-3 py-2', size: 'small' }, "Aplicar descuento"))));
};
export default BodyDiscountPopoverComponent;
