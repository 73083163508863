var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React, { useEffect, useMemo, useState } from 'react';
import { useReactiveVar } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { inputError } from '../../../../../../../apollo/cache';
import getProductIngredientTypeOptions from '../../../../../../../helpers/getProductIngredientTypeOptions';
import GQL_PRODUCT from '../../../../../../../apollo/gql/product';
import useApolloQuery from '../../../../../../../hooks/useApolloQuery';
import SelectFieldComponent from '../../../../../../../presentationals/FormComponents/SelectField';
var IngredientTypeFieldComponent = function (props) {
    var params = useParams();
    var errors = useReactiveVar(inputError);
    var _a = useState([]), typeOptions = _a[0], setTypeOptions = _a[1];
    var _b = useApolloQuery(GQL_PRODUCT.GET_PRODUCT_VARIATIONS, {
        variables: {
            id: params.productPresentationId,
        },
    }), loading = _b.loading, data = _b.data;
    var defaultTypeOptions = useMemo(getProductIngredientTypeOptions, []);
    useEffect(function () {
        if (data) {
            var newTypeOptions = data.ProductVariations.map(function (el) { return ({
                value: el.id,
                label: el.name,
            }); });
            setTypeOptions(__spreadArray(__spreadArray([], defaultTypeOptions, true), newTypeOptions, true));
        }
    }, [data, defaultTypeOptions]);
    if (loading)
        return React.createElement("div", null);
    return (React.createElement(SelectFieldComponent, { name: 'type', label: 'Tipo de componente', options: typeOptions, value: typeOptions.find(function (el) { return el.value === props.value; }) ? props.value : '', error: errors.type, onChange: props.onChange }));
};
export default IngredientTypeFieldComponent;
