import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { searchProductsByOptions } from '../../../../../../FormComponents/SearchProductBySelectField';
import ItemSearchCustomerByFieldComponent from '../../../../../../../containers/globalInvoice/Sales/CustomerField/SearchCustomer/TextField/SearchBy/Menu/Item';
import MenuComponent from '../../../../../../Menu';
import useProductFieldStore from '../../../../../../../store/productField';
var MenuPopoverSearchProductByFieldComponent = forwardRef(function (props, ref) {
    var setSearchBy = useProductFieldStore().setSearchBy;
    var _a = useState(null), anchorEl = _a[0], setAnchorEl = _a[1];
    var handleOpen = function (anchorEl) {
        setAnchorEl(anchorEl);
    };
    var handleClose = function () {
        setAnchorEl(null);
    };
    var handleClick = function (name, value) {
        setSearchBy(name, value);
        handleClose();
    };
    useImperativeHandle(ref, function () { return ({
        open: handleOpen,
    }); });
    return (React.createElement(MenuComponent, { id: 'PopoverSearchProductByFieldMenu', anchorEl: anchorEl, onClose: handleClose }, searchProductsByOptions.map(function (el) { return (React.createElement(ItemSearchCustomerByFieldComponent, { key: el.label, name: el.label, value: el.value, onClick: handleClick })); })));
});
export default MenuPopoverSearchProductByFieldComponent;
