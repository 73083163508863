import React from 'react';
import ProductsComponent from '../containers/product';
import GeneralProductComponent from '../containers/product/Item/General';
import ImageProductComponent from '../containers/product/Item/Image';
import CommentaryProductComponent from '../containers/product/Item/Commentary';
import BarcodeLabelProductComponent from '../containers/product/Item/BarcodeLabel';
import EcommerceProductComponent from '../containers/product/Item/Ecommerce';
import ProductLayoutComponent from './Product/ProductLayout';
import ProductPresentationLayoutComponent from './Product/ProductPresentationLayout';
import GeneralProductPresentationComponent from '../containers/product/ProductPresentation/General';
import ComponentProductPresentationComponent from '../containers/product/ProductPresentation/Component';
import RateProductPresentationComponent from '../containers/product/ProductPresentation/Rate';
import StructureProductComponent from '../containers/product/Item/Structure';
import ProductPresentationRateLayoutComponent from './Product/ProductPresentationRateLayout';
import ProductPresentationComponentLayoutComponent from './Product/ProductPresentationComponentLayout';
import GeneralProductIngredientComponent from '../containers/product/ProductIngredient/General';
import RatesProductIngredientComponent from '../containers/product/ProductIngredient/Rates';
import ProductPresentationComponentRateLayoutComponent from './Product/ProductPresentationComponentRateLayout';
import LimitInventoryProductPresentationComponent from '../containers/product/ProductPresentation/LimitInventory';
import CreateProductLayoutComponent from './Product/CreateProductLayout';
import CreateProductComponent from '../containers/product/Create';
var getProductChildrenRoutes = function () {
    return [
        { index: true, element: React.createElement(ProductsComponent, null) },
        {
            path: 'create',
            element: React.createElement(CreateProductLayoutComponent, null),
            children: [
                {
                    index: true,
                    element: React.createElement(CreateProductComponent, null),
                },
            ],
        },
        {
            path: ':productId',
            element: React.createElement(ProductLayoutComponent, null),
            children: [
                {
                    path: 'presentation',
                    element: React.createElement(StructureProductComponent, null),
                },
                { path: 'image', element: React.createElement(ImageProductComponent, null) },
                { path: 'commentary', element: React.createElement(CommentaryProductComponent, null) },
                { path: 'barcode', element: React.createElement(BarcodeLabelProductComponent, null) },
                { path: 'ecommerce', element: React.createElement(EcommerceProductComponent, null) },
                { index: true, element: React.createElement(GeneralProductComponent, null) },
            ],
        },
        {
            path: ':productId/presentation',
            children: [
                {
                    path: ':productPresentationId',
                    element: React.createElement(ProductPresentationLayoutComponent, null),
                    children: [
                        {
                            path: 'rate',
                            element: React.createElement(RateProductPresentationComponent, null),
                        },
                        {
                            path: 'limitInventory',
                            element: React.createElement(LimitInventoryProductPresentationComponent, null),
                        },
                        {
                            path: 'component',
                            element: React.createElement(ComponentProductPresentationComponent, null),
                        },
                        {
                            index: true,
                            element: React.createElement(GeneralProductPresentationComponent, null),
                        },
                    ],
                },
                {
                    path: ':productPresentationId',
                    children: [
                        {
                            path: 'rate/:rateId',
                            element: React.createElement(ProductPresentationRateLayoutComponent, null),
                        },
                        {
                            path: 'component',
                            children: [
                                {
                                    path: ':componentId',
                                    element: React.createElement(ProductPresentationComponentLayoutComponent, null),
                                    children: [
                                        { index: true, element: React.createElement(GeneralProductIngredientComponent, null) },
                                        {
                                            path: 'rate',
                                            element: React.createElement(RatesProductIngredientComponent, null),
                                        },
                                    ],
                                },
                                {
                                    path: ':componentId',
                                    children: [
                                        {
                                            path: 'rate/:componentRateId',
                                            element: React.createElement(ProductPresentationComponentRateLayoutComponent, null),
                                        },
                                    ],
                                },
                            ],
                        },
                    ],
                },
            ],
        },
    ];
};
export default getProductChildrenRoutes;
