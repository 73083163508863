import React, { useState } from 'react';
import SearchIcon from '@mui/icons-material/Edit';
import IconButtonComponent from '../../../../../../../../presentationals/IconButton';
import EditBranchBillingInformationComponent from '../EditDialog';
var OpenEditBranchBillingInformationComponent = function () {
    var _a = useState(false), open = _a[0], setOpen = _a[1];
    var handleClick = function () {
        setOpen(true);
    };
    var handleClose = function () {
        setOpen(false);
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(IconButtonComponent, { action: handleClick, size: 'small', className: 'p-0 m-0', icon: React.createElement(SearchIcon, { fontSize: 'small' }) }),
        React.createElement(EditBranchBillingInformationComponent, { open: open, onClose: handleClose })));
};
export default OpenEditBranchBillingInformationComponent;
