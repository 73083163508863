import React, { useEffect, useState } from 'react';

import { useReactiveVar } from '@apollo/client';

import { cartItems, formatSaleV2, inputError, pinSaleV2 } from '../../../../../apollo/cache';
import TextFieldComponent from '../../../../../presentationals/FormComponents/TextField';
import getIfStockProductIsBelowZero from '../../../../../helpers/getIfStockProductIsBelowZero';
import useGetCompanySettings from '../../../../../hooks/useGetCompanySettings';

const PinFieldFooterConfirmDialogSaleV2Component = () => {
  const { data: companySettigs, loading } = useGetCompanySettings();
  const formatSaleV2Var = useReactiveVar(formatSaleV2);
  const pinSaleV2Var = useReactiveVar(pinSaleV2);
  const cartItemsVar = useReactiveVar(cartItems);
  const errors = useReactiveVar(inputError);

  const [show, setShow] = useState(false);

  useEffect(() => {
    const decision = getIfStockProductIsBelowZero(cartItemsVar);
    setShow(decision);
  }, [cartItemsVar]);

  const handleChange = (name, value) => {
    // if (errors.pin) inputError({});
    pinSaleV2(value);
  };

  if (!companySettigs) return <div />;

  return (
    <div>
      {(errors.pin ||
        (parseInt(formatSaleV2Var[0]) !== -2 &&
          show &&
          !companySettigs.company.belowZeroInventory)) && (
        <TextFieldComponent
          defaultLabel='Pin de autorización'
          name='pin'
          disabled={loading}
          onChange={handleChange}
          error={errors.pin}
          helperText='Alguno de los productos del carrito no cuenta con inventario.'
          value={pinSaleV2Var}
        />
      )}
    </div>
  );
};

export default PinFieldFooterConfirmDialogSaleV2Component;
