import React from 'react';
import { IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
var ItemSendSaleComponent = function (props) {
    var handleDelete = function () {
        props.onDelete(props.index);
    };
    return (React.createElement("div", { className: 'flex flex-row justify-between items-center border-0 border-b border-solid border-gray-200' },
        React.createElement("div", { className: 'flex flex-1' }, props.label),
        React.createElement("div", null,
            React.createElement(IconButton, { onClick: handleDelete },
                React.createElement(DeleteIcon, null)))));
};
export default ItemSendSaleComponent;
