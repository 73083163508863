import React from 'react';

import { useReactiveVar } from '@apollo/client';

import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Divider from '@mui/material/Divider';

import { step, steps } from '../../../apollo/cache';

const StepperComponent = () => {
  const stepVar = useReactiveVar(step);
  const stepsVar = useReactiveVar(steps);
  return (
    <>
      <div className='h-[72px] w-full absolute bg-white z-[999]'>
        <Stepper className='h-[72px] px-3 pb-3 overflow-x-auto' activeStep={stepVar}>
          {stepsVar.map(label => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        <Divider />
      </div>
      <div className='flex flex-1 w-full h-[75px]' />
    </>
  );
};

export default StepperComponent;
