var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { useReactiveVar } from '@apollo/client';
import ActionPurchaseOrderFieldComponent from './ActionField';
import TextFieldComponent from '../../../../../../../presentationals/FormComponents/TextField';
import getSession from '../../../../../../../helpers/getSession';
import DateFieldComponent from '../../../../../../../presentationals/FormComponents/DateField';
import ProviderFieldProductComponent from '../../../../../../sale.v2/ChooseProduct/ProductDialog/Create/Body/helpers/ProviderField';
import PaymentMethodSatFieldComponent from '../../../../../../paymentMethod/Create/Body/PaymentMethodSatField';
import { inputError } from '../../../../../../../apollo/cache';
import getDefaultCreateGeneralPurchaseOrderValues from './helpers/getDefaultValues';
import SwitchComponent from '../../../../../../../presentationals/FormComponents/Switch';
import TaxesOptionsFieldComponent from './TaxesOptionsField';
import FormTitleLabelComponent from '../../../../../../../presentationals/FormTitleLabel';
var BodyGeneralCreatePurchaseOrderDialogComponent = forwardRef(function (props, ref) {
    var errors = useReactiveVar(inputError);
    var defaultValues = getDefaultCreateGeneralPurchaseOrderValues();
    var _a = useState(defaultValues), values = _a[0], setValues = _a[1];
    useEffect(function () {
        if (props.defaultValues)
            setValues(props.defaultValues);
    }, [props.defaultValues]);
    useEffect(function () {
        var _a;
        var session = getSession();
        if ((_a = session === null || session === void 0 ? void 0 : session.Company) === null || _a === void 0 ? void 0 : _a.Branch.id) {
            var branchId_1 = session.Company.Branch.id.toString();
            setValues(function (values) { return (__assign(__assign({}, values), { branchId: branchId_1 })); });
        }
    }, []);
    var handleChange = function (name, value) {
        setValues(function (values) {
            var _a;
            return (__assign(__assign({}, values), (_a = {}, _a[name] = value, _a)));
        });
    };
    var handleSwitchChange = function (name, value) {
        setValues(function (values) {
            var _a;
            return (__assign(__assign({}, values), (_a = {}, _a[name] = value, _a)));
        });
    };
    var handleRadioChange = function (name, value) {
        setValues(function (values) {
            var _a;
            return (__assign(__assign({}, values), (_a = {}, _a[name] = value, _a)));
        });
    };
    var handleDateChange = function (name, value) {
        setValues(function (values) {
            var _a;
            return (__assign(__assign({}, values), (_a = {}, _a[name] = value, _a)));
        });
    };
    var handleGetValues = function () { return values; };
    useImperativeHandle(ref, function () { return ({
        getValues: handleGetValues,
    }); });
    return (React.createElement("div", { className: 'flex-1 flex-col px-4 py-1 grid grid-cols-1 sm:grid-cols-3 sm:gap-4 overflow-auto' },
        React.createElement("div", { className: 'flex flex-1 flex-col' },
            React.createElement(FormTitleLabelComponent, null, "Datos generales"),
            React.createElement(ActionPurchaseOrderFieldComponent, { onChange: handleRadioChange, value: values.action }),
            React.createElement(ProviderFieldProductComponent, { label: 'Proveedor', onChange: handleChange, value: values.providerId || '', error: errors.providerId }),
            values.action === 3 && (React.createElement(React.Fragment, null,
                React.createElement(DateFieldComponent, { label: 'Fecha de compra', name: 'pursacheDate', onChange: handleDateChange, value: values.pursacheDate }),
                React.createElement(TextFieldComponent, { label: 'Folio del documento', name: 'docFolio', onChange: handleChange, value: values.docFolio }),
                React.createElement(PaymentMethodSatFieldComponent, { label: 'Forma de pago', value: values.paymentForm, onChange: handleChange }),
                React.createElement(TextFieldComponent, { label: 'Importe pagado', name: 'amountPaid', type: 'number', onChange: handleChange, value: values.amountPaid }))),
            React.createElement("div", { className: 'my-1' }),
            React.createElement(FormTitleLabelComponent, null, "Configuraci\u00F3n de impuestos"),
            React.createElement(SwitchComponent, { label: '\u00BFDeseas incluir impuestos en la compra?', name: 'showTaxesOptions', onChange: handleSwitchChange, defaultValue: values.showTaxesOptions }),
            values.showTaxesOptions && (React.createElement(TaxesOptionsFieldComponent, { onChange: handleChange, value: values.taxesOption })))));
});
export default BodyGeneralCreatePurchaseOrderDialogComponent;
