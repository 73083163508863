import React, { useEffect, useState } from 'react';
import AccountIcon from '@mui/icons-material/AccountCircle';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import { isMenuOpen } from '../../../../../apollo/cache';
import IconButton from '../../../../../presentationals/IconButton';
import getSession from '../../../../../helpers/getSession';
import getInputErrorFormat from '../../../../sale.v2/Action/helpers/getInputErrorFormat';
import { capitalize } from '../../../../../helpers';
var HeaderDrawerComponent = function () {
    var _a = useState(''), username = _a[0], setUsername = _a[1];
    useEffect(function () {
        var f = function () {
            try {
                var session = getSession();
                if (!session)
                    throw new Error('Sesión rechazada.');
                var name_1 = session.name.split(' ');
                if (name_1[0])
                    setUsername(capitalize(name_1[0]));
            }
            catch (error) {
                getInputErrorFormat(error);
            }
        };
        f();
    }, []);
    var handleClose = function () {
        isMenuOpen(false);
    };
    return (React.createElement("div", { className: 'sticky top-0 flex items-center justify-between border-0 border-b border-solid border-gray-200 bg-gray-50' },
        React.createElement("div", { className: 'flex items-center ml-5' },
            React.createElement(AccountIcon, { className: 'text-3xl mr-3' }),
            React.createElement(Typography, { className: 'text-xl font-semibold' }, "Hola, ".concat(username))),
        React.createElement(IconButton, { className: 'mr-2', color: 'inherit', action: handleClose, icon: React.createElement(CloseIcon, { fontSize: 'large' }), size: 'large' })));
};
export default HeaderDrawerComponent;
