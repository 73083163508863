import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import ErrorDataComponent from '../../../../presentationals/ErrorData';
import LoadingProgress from '../../../../presentationals/LoadingProgress';
import useApolloLazyQuery from '../../../../hooks/useApolloLazyQuery';
import GQL_TRANSACTION from '../../../../apollo/gql/transaction';
import { dateFormat } from '../../../../helpers';
import BodyInvoiceComplementComponent from '../../../receivable/CustomerReceivables/InvoiceComplementDialog/Body';
var InvoiceComplementTransactionComponent = function () {
    var params = useParams();
    var _a = useApolloLazyQuery(GQL_TRANSACTION.GET_TRANSACTION_BY_ID), getData = _a[0], _b = _a[1], data = _b.data, loading = _b.loading;
    useEffect(function () {
        getData({ variables: { id: params.transactionId } });
    }, [params.transactionId]);
    if (loading)
        return React.createElement(LoadingProgress, null);
    if (!data)
        return React.createElement(ErrorDataComponent, null);
    if (!params.transactionId)
        return React.createElement(ErrorDataComponent, null);
    if (data.status === 0)
        return (React.createElement("div", { className: 'px-4 py-3' },
            "No es posible emitir un complemento de pago ya que la transacci\u00F3n fue cancelada el d\u00EDa",
            ' ',
            dateFormat(data.deletedAt),
            "."));
    if (!data.invoiceComplementId)
        return (React.createElement("div", { className: 'px-4 py-3' }, "Esta transacci\u00F3n no est\u00E1 disponible para emitir un complemento de pago."));
    return React.createElement(BodyInvoiceComplementComponent, { customerId: data.customerId });
};
export default InvoiceComplementTransactionComponent;
