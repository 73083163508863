import React from 'react';
import { Card, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, } from '@mui/material';
import { dateTimeFormat } from '../../../../helpers';
import convertToCurrencyLabel from '../../../../helpers/convertToCurrencyLabel';
import DeletePaymentButtonComponent from './DeletePaymentButton';
var TransactionsTableAccounReceivableComponent = function (props) {
    return (React.createElement("div", { className: 'flex flex-1 flex-col' },
        React.createElement("div", { className: 'text-xl' }, "Abonos a la cuenta"),
        React.createElement(Card, { className: 'mt-1 flex flex-1 flex-col basis-0 overflow-auto' }, props.Transactions.length === 0 ? (React.createElement("div", { className: 'flex px-4 py-3' }, "De momento no cuentas con pagos vinculados a esta cuenta.")) : (React.createElement(TableContainer, null,
            React.createElement(Table, { stickyHeader: true, size: 'small' },
                React.createElement(TableHead, null,
                    React.createElement(TableRow, null,
                        React.createElement(TableCell, { className: 'bg-slate-300 whitespace-nowrap' }, "Creado el"),
                        React.createElement(TableCell, { className: 'bg-slate-300 whitespace-nowrap' }, "Pagado el"),
                        React.createElement(TableCell, { className: 'bg-slate-300 whitespace-nowrap' }, "Forma de pago"),
                        React.createElement(TableCell, { className: 'bg-slate-300', align: 'right' }, "Importe"),
                        React.createElement(TableCell, { className: 'bg-slate-300', padding: 'checkbox' }))),
                React.createElement(TableBody, null, props.Transactions.map(function (el) { return (React.createElement(TableRow, { key: el.id },
                    React.createElement(TableCell, null, dateTimeFormat(el.createdAt)),
                    React.createElement(TableCell, null, dateTimeFormat(el.clientCreatedAt, 'dd/MM/yyyy')),
                    React.createElement(TableCell, null, el.PaymentMethod ? el.PaymentMethod.name : 'Efectivo'),
                    React.createElement(TableCell, { align: 'right' },
                        "$",
                        convertToCurrencyLabel(el.total)),
                    React.createElement(TableCell, { padding: 'checkbox' },
                        React.createElement(DeletePaymentButtonComponent, { id: el.id })))); }))))))));
};
export default TransactionsTableAccounReceivableComponent;
