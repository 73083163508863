import React from 'react';
import { hourFormat } from '../../../../helpers';
var ItemUpcomingAppointmentsCalendarQueueManagerComponent = function (props) {
    if (!props.Calendar.Customer)
        return React.createElement("div", null);
    return (React.createElement("div", { className: "flex py-2 ".concat(!props.showHour ? 'text-7xl' : 'text-5xl', " leading-tight font-semibold items-center"), style: {
            color: props.textColor || '#000',
        } },
        props.showHour && (React.createElement("div", { className: 'w-1/5 text-right text-4xl' }, hourFormat(props.Calendar.start))),
        React.createElement("div", { className: 'pl-3' }, "".concat(props.Calendar.Customer.firstName, " ").concat(props.Calendar.Customer.lastName))));
};
export default ItemUpcomingAppointmentsCalendarQueueManagerComponent;
