import React, { useState } from 'react';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import useGetSession from '../../../../hooks/useGetSession';
import ProfileDialogComponent from '../ProfileDialog';
var MenuButton = function () {
    var session = useGetSession();
    var _a = useState([false, null]), profileOpen = _a[0], setProfileOpen = _a[1];
    var handleProfileOpen = function (e) {
        setProfileOpen([true, e.currentTarget]);
    };
    var handleProfileClose = function () {
        setProfileOpen([false, null]);
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(IconButton, { onClick: handleProfileOpen, "aria-controls": 'profile-menu', "aria-haspopup": 'true', className: 'ml-2 p-0', size: 'large' },
            React.createElement(Avatar, { alt: session.name, src: '#', className: 'bg-[#1376c7]' })),
        React.createElement(ProfileDialogComponent, { anchorEl: profileOpen[1], isOpen: profileOpen[0], onClose: handleProfileClose })));
};
export default MenuButton;
