var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import OutlinedInput from '@mui/material/OutlinedInput';
import Typography from '@mui/material/Typography';
var OutlinedFieldComponent = function (_a) {
    var onChange = _a.onChange, params = _a.params, error = _a.error, label = _a.label, helper = _a.helper, onKeyDown = _a.onKeyDown, props = __rest(_a, ["onChange", "params", "error", "label", "helper", "onKeyDown"]);
    var handleChange = function (e) {
        onChange && onChange(e.target.name, e.target.value, params);
    };
    return (React.createElement("div", { className: 'py-1' },
        React.createElement(Typography, { variant: 'subtitle1', className: 'leading-tight', gutterBottom: true }, label),
        React.createElement(OutlinedInput, __assign({ onChange: handleChange, fullWidth: props.fullWidth || true, autoFocus: props.autoFocus || false, error: !!error, autoComplete: 'off', size: props.size || 'small', margin: props.margin || 'dense' }, props, { inputProps: {
                onKeyDown: onKeyDown,
            }, startAdornment: props.startAdornment })),
        helper && React.createElement(Typography, { variant: 'caption' }, helper),
        error && (React.createElement(Typography, { variant: 'caption', color: 'error' }, error))));
};
export default OutlinedFieldComponent;
