var getDefaulPaymentMethod = function (values) { return ({
    id: (values === null || values === void 0 ? void 0 : values.id) || undefined,
    name: (values === null || values === void 0 ? void 0 : values.name) ? values.name : '',
    isCash: (values === null || values === void 0 ? void 0 : values.isCash) ? values.isCash : false,
    isAccountPayable: (values === null || values === void 0 ? void 0 : values.isAccountPayable) ? values.isAccountPayable : false,
    isUnique: (values === null || values === void 0 ? void 0 : values.isUnique) ? values.isUnique : false,
    commission: (values === null || values === void 0 ? void 0 : values.commission) ? values.commission : '',
    code: (values === null || values === void 0 ? void 0 : values.code) ? values.code : '',
}); };
export default getDefaulPaymentMethod;
