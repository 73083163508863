import React from 'react';
import { useReactiveVar } from '@apollo/client';
import TextFieldComponent from '../../../../../../../presentationals/FormComponents/TextField';
import { inputError } from '../../../../../../../apollo/cache';
import useApolloQuery from '../../../../../../../hooks/useApolloQuery';
import GQL_PRODUCT from '../../../../../../../apollo/gql/product';
var IngredientQuantityFieldComponent = function (props) {
    var _a;
    var _b = useApolloQuery(GQL_PRODUCT.GET_PRODUCT_BY_ID, {
        variables: { id: props.ingredientId },
    }), data = _b.data, loading = _b.loading;
    var errors = useReactiveVar(inputError);
    if (!data || loading)
        return React.createElement("div", null);
    return (React.createElement(TextFieldComponent, { label: "Cu\u00E1ntos ".concat(((_a = data === null || data === void 0 ? void 0 : data.PresentationBase) === null || _a === void 0 ? void 0 : _a.name) ? data.PresentationBase.name.toLowerCase() : '', "s contiene cada porci\u00F3n"), name: 'portionQty', value: props.value, error: errors.portionQty, type: 'number', onChange: props.onChange }));
};
export default IngredientQuantityFieldComponent;
