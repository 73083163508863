import React, { useEffect } from 'react';
import { useReactiveVar } from '@apollo/client';
import Collapse from '@mui/material/Collapse';
import { step, steps } from '../../../apollo/cache';
import FooterAction from '../../../presentationals/FormComponents/FooterAction';

import Main from './Main';
import Products from './Products';

const CreateFormComponent = () => {
  const stepVar = useReactiveVar(step);

  useEffect(() => {
    steps(['Datos Generales', 'Productos']);
  }, []);

  return (
    <div className='py-1 px-3'>
      <Collapse in={stepVar === 0}>
        <Main />
      </Collapse>
      <Collapse in={stepVar === 1}>
        <Products />
      </Collapse>
      <FooterAction />
    </div>
  );
};

export default CreateFormComponent;
