var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useState } from 'react';
import { Button } from '@mui/material';
import { Link } from 'react-router-dom';
import TextFieldComponent from '../../presentationals/FormComponents/TextField';
import logo from '../../assets/static/iso.png';
import Copyright from '../../presentationals/Module.v2/Copyright';
var defaultValues = {
    username: '',
};
var RecoverPasswordNoAuthComponent = function () {
    var _a = useState(defaultValues), values = _a[0], setValues = _a[1];
    var handleChange = function (name, value) {
        setValues(function (values) {
            var _a;
            return (__assign(__assign({}, values), (_a = {}, _a[name] = value, _a)));
        });
    };
    return (React.createElement("div", { className: 'flex flex-1 h-screen flex-col bg-slate-50' },
        React.createElement("div", { className: 'flex justify-center items-center p-10 md:pt-16 mt-14' },
            React.createElement("img", { src: logo, alt: 'Capitalike', className: 'w-20' })),
        React.createElement("div", { className: 'flex flex-1 items-center justify-center flex-col p-4' },
            React.createElement("div", { className: 'flex flex-1 items-center justify-center flex-col p-4' },
                React.createElement("div", { className: 'w-full sm:w-96' },
                    React.createElement("div", { className: 'leading-tight mb-20' },
                        "Ingresa tu n\u00FAmero de tel\u00E9fono o correo de recuperaci\u00F3n",
                        React.createElement("div", { className: 'mt-4' },
                            React.createElement(TextFieldComponent, { defaultLabel: 'Tel\u00E9fono o correo electr\u00F3nico', name: 'username', type: 'text', value: values.username, onChange: handleChange, 
                                //   onKeyDown={handleKeyDown}
                                //   error={errors.username}
                                //   disabled={loading}
                                size: 'medium' }))),
                    React.createElement(Button, { variant: 'contained', className: 'rounded-lg normal-case', size: 'large', fullWidth: true }, "Confirmar")),
                React.createElement("div", { className: 'w-40 mb-6 border-0 border-b border-solid border-gray-600 mt-5' }),
                React.createElement("div", { className: 'flex items-center' },
                    React.createElement("div", { className: 'text-sm mr-3' }, "\u00BFYa tienes una cuenta?"),
                    React.createElement(Link, { className: 'text-gray-600 text-sm font-medium no-underline hover:underline', to: '/login' }, "Iniciar sesi\u00F3n")),
                React.createElement("div", { className: 'flex justify-center p-6' },
                    React.createElement(Copyright, null))))));
};
export default RecoverPasswordNoAuthComponent;
