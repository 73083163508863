import { gql } from '@apollo/client';

const GET = gql`
  query AdjustInventoryProducts(
    $start: Date
    $end: Date
    $order: [String]
    $limit: Int
    $offset: Int
    $productId: ID
    $name: String
    $adjustInventoryId: ID
  ) {
    adjustInventoryProducts(
      start: $start
      end: $end
      order: $order
      limit: $limit
      offset: $offset
      productId: $productId
      like: $name
      adjustInventoryId: $adjustInventoryId
    ) {
      count
      rows {
        id
        createdAt
        AdjustInventory {
          folio
          serial
        }
        Product {
          name
          code
          cost
        }
        previousQuantity
        currentQuantity
        difference
        costDifference
      }
    }
  }
`;

export default { GET };
