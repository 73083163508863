import React from 'react';
import useGetSaleByParamSaleId from '../../../../hooks/useGetSaleByParamSaleId';
import LoadingProgress from '../../../../presentationals/LoadingProgress';
import BodyPickupRentComponent from './Body';
import EditPickupItemComponent from './Body/EditPickupItem';
var CloseRentComponent = function () {
    var _a = useGetSaleByParamSaleId(), data = _a.data, loading = _a.loading;
    if (loading || !data)
        return React.createElement(LoadingProgress, null);
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: 'flex flex-1 flex-col' },
            React.createElement("div", { className: 'flex flex-1 flex-col' },
                React.createElement(BodyPickupRentComponent, { SaleProducts: data === null || data === void 0 ? void 0 : data.SaleProducts }))),
        React.createElement(EditPickupItemComponent, null)));
};
export default CloseRentComponent;
