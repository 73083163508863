import React, { useMemo } from 'react';
import RHFSelectFieldComponent from '../../../../presentationals/RHForm/SelectField';
import RHFTextFieldComponent from '../../../../presentationals/RHForm/TextField';
import RHFBooleanCheckboxComponent from '../../../../presentationals/RHForm/BooleanCheckbox';
var SurveyQuestionComponent = function (props) {
    var options = useMemo(function () {
        return Array.from(Array(10).keys()).map(function (number) { return ({
            value: (number + 1).toString(),
            label: (number + 1).toString(),
        }); });
    }, []);
    var closedOptions = useMemo(function () { return [
        { value: '2', label: 'Bueno' },
        { value: '1', label: 'Regular' },
        { value: '0', label: 'Malo' },
    ]; }, []);
    if (props.type === 2 && props.name)
        return React.createElement(RHFSelectFieldComponent, { name: props.name, label: props.label, options: options });
    if (props.type === 3 && props.name)
        return (React.createElement(RHFSelectFieldComponent, { name: props.name, label: props.label, options: closedOptions }));
    if (props.type === 4 && props.name)
        return React.createElement(RHFBooleanCheckboxComponent, { name: props.name, label: props.label });
    if (props.type === 101)
        return React.createElement("div", { className: 'font-medium text-xl' }, props.label);
    if (props.type === 102)
        return React.createElement("div", { className: 'font-medium text-lg' }, props.label);
    if (props.type === 103)
        return React.createElement("div", { className: 'font-medium text-base' }, props.label);
    if (props.name)
        return React.createElement(RHFTextFieldComponent, { name: props.name, label: props.label });
    return React.createElement("div", null);
};
export default SurveyQuestionComponent;
