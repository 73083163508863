import React, { useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import FooterDialogComponent from '../../../../presentationals/Dialog/Footer';
import EditButtonGeneralEditBranchComponent from './EditButton';
import BodyEditBranchComponent from './Body';
import useApolloLazyQuery from '../../../../hooks/useApolloLazyQuery';
import GQL_BRANCH from '../../../../apollo/gql/branch';
import LoadingProgress from '../../../../presentationals/LoadingProgress';
import ErrorDataComponent from '../../../../presentationals/ErrorData';
import { getDefaultBranchEditForm } from '../../helpers/getDefaultBranchValues';
var GeneralEditBranchComponent = function () {
    var params = useParams();
    var bodyEditBranchRef = useRef(null);
    var editButtonGeneralEditBranchRef = useRef(null);
    var _a = useApolloLazyQuery(GQL_BRANCH.GET_BRANCH_BY_ID), getById = _a[0], _b = _a[1], loading = _b.loading, data = _b.data;
    useEffect(function () {
        if (params.branchId)
            getById({ variables: { id: params.branchId } });
    }, [params]);
    var handleEdit = function () {
        var _a;
        (_a = editButtonGeneralEditBranchRef.current) === null || _a === void 0 ? void 0 : _a.onPressEnter();
    };
    var handleGetValues = function () {
        var _a;
        return ((_a = bodyEditBranchRef.current) === null || _a === void 0 ? void 0 : _a.getValues()) || getDefaultBranchEditForm();
    };
    if (loading)
        return React.createElement(LoadingProgress, null);
    if (!data)
        return React.createElement(ErrorDataComponent, null);
    return (React.createElement("div", { className: 'flex flex-1 flex-col' },
        React.createElement(BodyEditBranchComponent, { ref: bodyEditBranchRef, onPressEnter: handleEdit, defaultValues: data }),
        React.createElement(FooterDialogComponent, null,
            React.createElement("div", null),
            React.createElement(EditButtonGeneralEditBranchComponent, { getValues: handleGetValues, ref: editButtonGeneralEditBranchRef }))));
};
export default GeneralEditBranchComponent;
