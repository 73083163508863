import React, { useState } from 'react';

import { addDays } from 'date-fns';
import { useReactiveVar } from '@apollo/client';
import { Switch, Typography } from '@mui/material';

import DateFieldComponent from '../../../../../../presentationals/FormComponents/DateField';
import { quotationSaleV2 } from '../../../../../../apollo/cache';

const QuotationFormPaymentFormBodyConfirmDialogSaleV2Component = () => {
  const quotationSaleV2Var = useReactiveVar(quotationSaleV2);
  const [showExpirationDate, setShowExpirationDate] = useState(false);
  const handleSwitch = event => {
    if (!event.target.checked) quotationSaleV2({ ...quotationSaleV2Var, expirationDate: null });
    setShowExpirationDate(event.target.checked);
    quotationSaleV2({ ...quotationSaleV2Var, expirationDate: addDays(new Date(), 30) });
  };
  const handleChange = (name, value) => {
    quotationSaleV2({ ...quotationSaleV2Var, [name]: value });
  };
  return (
    <>
      <div className='flex flex-row items-center'>
        <Typography variant='subtitle1'>¿Desea establecer una fecha de caducidad?</Typography>
        <Switch checked={showExpirationDate} onChange={handleSwitch} name='expirationDateSwitch' />
      </div>
      {showExpirationDate && (
        <DateFieldComponent
          label='Fecha de expiración'
          name='expirationDate'
          value={quotationSaleV2Var.expirationDate}
          size='small'
          onChange={handleChange}
        />
      )}
    </>
  );
};

export default QuotationFormPaymentFormBodyConfirmDialogSaleV2Component;
