import React from 'react';
import { useReactiveVar } from '@apollo/client';
import { Switch, Typography } from '@mui/material';
import { formatSaleV2 } from '../../../../../../../apollo/cache';
var ShowTaxesSwitchTaxesOptionFormFormatActionSaleV2Component = function () {
    var formatSaleV2Var = useReactiveVar(formatSaleV2);
    var handleSwitch = function (event) {
        formatSaleV2([
            formatSaleV2Var[0],
            formatSaleV2Var[1],
            formatSaleV2Var[2],
            event.target.checked,
        ]);
    };
    return !formatSaleV2Var[2] ? (React.createElement("div", { className: 'flex flex-row items-center' },
        React.createElement(Typography, { variant: 'subtitle1' }, "\u00BFMostrar impuestos en documento?"),
        React.createElement(Switch, { checked: formatSaleV2Var[3], onChange: handleSwitch, name: 'switchShowTaxes' }))) : (React.createElement("div", null));
};
export default ShowTaxesSwitchTaxesOptionFormFormatActionSaleV2Component;
