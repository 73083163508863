import React from 'react';
import { useReactiveVar } from '@apollo/client';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import ItemPresentationTableEditProductComponent from './Item';
import { productSaleV2 } from '../../../../../apollo/cache';
var PresentationTableEditProductComponent = function (props) {
    var productSaleV2Var = useReactiveVar(productSaleV2);
    return (React.createElement("div", { className: 'flex flex-1 basis-0 overflow-auto' },
        React.createElement(TableContainer, { className: 'rounded border border-solid border-[#d8d8d8]' },
            React.createElement(Table, { stickyHeader: true, size: 'small' },
                React.createElement(TableHead, null,
                    React.createElement(TableRow, { className: 'bg-[#f6f6f6]' },
                        React.createElement(TableCell, { colSpan: 3 }),
                        React.createElement(TableCell, { align: 'center', colSpan: 2, className: 'bg-[#ececec]' }, "Disponible")),
                    React.createElement(TableRow, { className: 'bg-[#f6f6f6]' },
                        React.createElement(TableCell, null, "Presentaci\u00F3n"),
                        ['1'].includes(productSaleV2Var.type) && React.createElement(TableCell, null, "Contenido"),
                        !['1'].includes(productSaleV2Var.type) && (React.createElement(TableCell, { className: 'whitespace-nowrap' }, "Insumos gratuitos")),
                        React.createElement(TableCell, null, "Precio"),
                        React.createElement(TableCell, { align: 'center', padding: 'none', className: 'bg-[#ececec]' }, "Venta"),
                        React.createElement(TableCell, { align: 'center', padding: 'none', className: 'bg-[#ececec]' }, "Compra"))),
                React.createElement(TableBody, null, props.Presentations.map(function (el, i) { return (React.createElement(ItemPresentationTableEditProductComponent, { presentationBase: props.presentationBase, key: el.id, Presentation: el, index: i })); }))))));
};
export default PresentationTableEditProductComponent;
