import React, { useState, useEffect } from 'react';
import { useReactiveVar, useQuery } from '@apollo/client';
import Grid from '@mui/material/Grid';
import Collapse from '@mui/material/Collapse';
import { clearForm, form, step, handleContinue } from '../../../../apollo/cache';
import Radio from '../../../../presentationals/FormComponents/Radio';
import TextField from '../../../../presentationals/FormComponents/TextField';
import Autocomplete from '../../../../presentationals/FormComponents/Autocomplete';
import DateField from '../../../../presentationals/FormComponents/DateField';
import SelectField from '../../../../presentationals/FormComponents/SelectField';
import { selectpaymentFormOptions } from '../../../../helpers/paymentForm.options';
import GQL_BRANCHES from '../../../branch/_gql';
import useGetSession from '../../../../hooks/useGetSession';
import GQL_PROVIDER from '../../../../apollo/gql/provider';
import CreatePurchaseOrderByDocumentComponent from './CreateByDocument';

const defaultValues = {
  action: '1',
  providerId: null,
  description: '',
  pursacheDate: new Date(),
  subFolio: '',
  paymentForm: '01',
  type: 1,
  branchId: '',
  currency: 'MXN',
  exchangeRate: 1,
  discountType: 1,
  discount: 0,
  bonus: 0,
};

const currencyOptions = [
  { value: 'MXN', label: 'Pesos Mexicanos (MXN)' },
  { value: 'USD', label: 'Dólares Americanos (USD)' },
];

const MainComponent = () => {
  const session = useGetSession();

  const [values, setValues] = useState({
    ...defaultValues,
    branchId: '',
  });
  const [errors, setErrors] = useState({});
  const [providers, setProviders] = useState([]);

  const clearFormVar = useReactiveVar(clearForm);
  const stepVar = useReactiveVar(step);
  const handleContinueVar = useReactiveVar(handleContinue);

  const { data: providersData } = useQuery(GQL_PROVIDER.GET_PROVIDERS, { variables: { limit: 0 } });
  const { data: dataBranches } = useQuery(GQL_BRANCHES.GET, { variables: { limit: 0 } });

  useEffect(() => {
    if (session?.Company?.Branch.id && dataBranches)
      setValues(values => ({ ...values, branchId: session?.Company?.Branch.id }));
  }, [session, dataBranches]);

  const mainFormValidation = () => {
    try {
      setErrors({});
      if (
        values.currency !== 'MXN' &&
        (!values.exchangeRate ||
          Number.isNaN(parseFloat(values.exchangeRate)) ||
          parseFloat(values.exchangeRate) <= 0)
      ) {
        const e = new Error('El valor del dólar es inválido.');
        e.name = 'exchangeRate';
        throw e;
      }
      if (
        (values.discount &&
          parseFloat(values.discount) > 0 &&
          (Number.isNaN(parseFloat(values.discount)) || parseFloat(values.discount) < 0)) ||
        (parseFloat(values.discountType) === 1 && parseFloat(values.discount) > 100)
      ) {
        const e = new Error('El descuento es inválido.');
        e.name = 'discount';
        throw e;
      }
      if (values.providerId === null) {
        const e = new Error('Selecciona el proveedor de la compra.');
        e.name = 'providerId';
        throw e;
      }
      step(stepVar + 1);
      form(values);
    } catch (e) {
      setErrors({ [e.name]: e.message });
    }
  };

  useEffect(() => {
    if (stepVar === 0 && handleContinueVar) {
      mainFormValidation();
      handleContinue(false);
    }
  }, [stepVar, handleContinueVar]);

  useEffect(() => {
    if (clearFormVar && stepVar === 0) {
      setValues(defaultValues);
      form({});
      clearForm(false);
    }
  }, [clearFormVar]);

  useEffect(() => {
    if (providersData && providersData.providers) {
      const tmp = providersData.providers.rows.map(el => ({ value: el.id, label: el.name }));
      setProviders(tmp);
    }
  }, [providersData]);

  const handleChange = (name, value) => {
    setValues(values => ({ ...values, [name]: value }));
  };
  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={12} md={5}>
          <Radio
            name='action'
            label='¿Que proceso deseas realizar?'
            value={values.action}
            error={errors.action}
            options={[
              { label: 'Emitir una orden de compra', value: '1' },
              { label: 'Capturar una compra', value: '3' },
            ]}
            onChange={handleChange}
          />
        </Grid>
      </Grid>
      {![61, 94].includes(parseInt(session?.Company?.Branch.id)) && (
        <Grid container spacing={1}>
          <Grid item xs={12} md={5}>
            <SelectField
              name='branchId'
              label={`¿A que sucursal se asignará esta ${
                values.action === '1' ? 'orden de compra' : 'compra'
              }?`}
              onChange={handleChange}
              value={values.branchId}
              error={errors.branchId}
              disabled={[61, 94].includes(parseInt(session?.Company?.Branch.id))}
              options={
                dataBranches
                  ? dataBranches.branches.rows
                      .map(el => {
                        return {
                          value: el.id,
                          label:
                            parseInt(session?.Company?.id) === parseInt(el.Company.id)
                              ? el.name
                              : el.name,
                        };
                      })
                      .filter(tmp => tmp)
                  : []
              }
            />
          </Grid>
        </Grid>
      )}
      <Grid container spacing={1}>
        <Grid item xs={12} md={5}>
          <SelectField
            name='currency'
            label='¿Cuál será la moneda a utilizar?'
            onChange={handleChange}
            options={currencyOptions}
            value={values.currency}
            error={errors.currency}
          />
        </Grid>
      </Grid>
      {values.currency !== 'MXN' && (
        <Grid container spacing={1}>
          <Grid item xs={12} md={5}>
            <TextField
              name='exchangeRate'
              label='¿Cuál es el valor del dólar?'
              onChange={handleChange}
              value={values.exchangeRate}
              error={errors.exchangeRate}
              type='number'
            />
          </Grid>
        </Grid>
      )}
      <Grid container spacing={1}>
        <Grid item xs={12} md={5}>
          <SelectField
            name='discountType'
            label='Tipo de Descuento General'
            type='number'
            onChange={handleChange}
            value={values.discountType}
            error={errors.discountType}
            options={[
              { value: 1, label: 'Por %' },
              { value: 2, label: 'Por $' },
            ]}
          />
        </Grid>
      </Grid>
      <Grid container spacing={1}>
        <Grid item xs={12} md={5}>
          <TextField
            name='discount'
            label={`Descuento (${parseInt(values.discountType) === 1 ? '%' : '$'})`}
            onChange={handleChange}
            value={values.discount}
            error={errors.discount}
            type='number'
          />
        </Grid>
      </Grid>
      <Grid container spacing={1}>
        <Grid item xs={12} md={5}>
          <Autocomplete
            name='providerId'
            label={`¿Quién será el proveedor de esta ${
              values.action === '1' ? 'orden de compra' : 'compra'
            }?`}
            onChange={handleChange}
            options={providers}
            value={values.providerId}
            error={errors.providerId}
          />
        </Grid>
      </Grid>
      <Collapse in={values.action === '3'}>
        <Grid container spacing={1}>
          <Grid item xs={12} md={5}>
            <DateField
              name='pursacheDate'
              label='Selecciona la fecha de compra'
              onChange={handleChange}
              value={values.pursacheDate}
              error={errors.pursacheDate}
            />
          </Grid>
        </Grid>
        <Grid container spacing={1}>
          <Grid item xs={12} md={5}>
            <TextField
              name='subFolio'
              label='Escribe el folio de tu compra'
              onChange={handleChange}
              value={values.subFolio}
              error={errors.subFolio}
            />
          </Grid>
        </Grid>
        <Grid container spacing={1}>
          <Grid item xs={12} md={5}>
            <SelectField
              name='paymentForm'
              label='Proporciona la forma de pago'
              onChange={handleChange}
              options={selectpaymentFormOptions}
              value={values.paymentForm}
              error={errors.paymentForm}
            />
          </Grid>
        </Grid>
      </Collapse>
      <Grid container spacing={1}>
        <Grid item xs={12} md={5}>
          <TextField
            name='description'
            label={`¿Tienes alguna anotación para esta ${
              values.action === '1' ? 'orden de compra' : 'compra'
            }?`}
            value={values.description}
            error={errors.description}
            multiline
            minRows={4}
            onChange={handleChange}
          />
        </Grid>
      </Grid>
      {session?.Company?.id.toString() === '187' && values.action.toString() === '1' && (
        <CreatePurchaseOrderByDocumentComponent values={values} />
      )}
    </>
  );
};

export default MainComponent;
