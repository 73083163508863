import React from 'react';
import { Typography } from '@mui/material';
import { useReactiveVar } from '@apollo/client';
import { totalsSaleV2 } from '../../../../apollo/cache';
import { currencyFormat } from '../../../../helpers';
var TotalFooterSaleV2Component = function () {
    var totalsSaleV2Var = useReactiveVar(totalsSaleV2);
    return (React.createElement("div", { className: 'flex flex-col h-full' },
        React.createElement("div", { className: 'flex flex-col flex-1 justify-end items-end' },
            React.createElement("div", { className: 'flex flex-row items-end justify-between' },
                React.createElement(Typography, { variant: 'subtitle1', className: 'mr-2' }, "Subtotal"),
                React.createElement(Typography, { variant: 'h5' },
                    "$",
                    currencyFormat(totalsSaleV2Var[0])))),
        React.createElement("div", { className: 'flex flex-col flex-1 justify-end items-end' },
            React.createElement("div", { className: 'flex flex-row items-end justify-between' },
                React.createElement(Typography, { variant: 'subtitle1', className: 'mr-2' }, "Dscto."),
                React.createElement(Typography, { variant: 'h5' },
                    "$",
                    currencyFormat(totalsSaleV2Var[1])))),
        React.createElement("div", { className: 'flex flex-col flex-1 justify-end items-end' },
            React.createElement("div", { className: 'flex flex-row items-end justify-between' },
                React.createElement(Typography, { variant: 'subtitle1', className: 'mr-2' }, "Impuestos"),
                React.createElement(Typography, { variant: 'h5' },
                    "$",
                    currencyFormat(totalsSaleV2Var[2])))),
        React.createElement("div", { className: 'flex flex-col flex-1 justify-end items-end' },
            React.createElement("div", { className: 'flex flex-row items-end' },
                React.createElement(Typography, { variant: 'h5', className: 'mr-2 mb-1' }, "Total"),
                React.createElement(Typography, { variant: 'h4', className: 'font-bold' },
                    "$",
                    currencyFormat(totalsSaleV2Var[3]))))));
};
export default TotalFooterSaleV2Component;
