import React, { useRef, useState } from 'react';
import { CircularProgress } from '@mui/material';
import ResetProductFieldButtonComponent from './ResetButton';
import SearchProductFieldButtonComponent from './SearchButton';
import IsProductFieldSelectedComponent from './IsSelected';
import SearchProductByFieldComponent from './SearchBy';
import ProductInputBaseFieldComponent from './InputBase';
var InputProductFieldComponent = function (props) {
    var inputRef = useRef(null);
    var searchProductFieldButtonRef = useRef(null);
    var _a = useState(false), loading = _a[0], setLoading = _a[1];
    var handleLoading = function (loading) {
        setLoading(loading);
    };
    var handleEnter = function (anchorEl) {
        var _a;
        (_a = searchProductFieldButtonRef.current) === null || _a === void 0 ? void 0 : _a.onEnter(anchorEl);
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(IsProductFieldSelectedComponent, { name: props.name }),
        React.createElement(SearchProductByFieldComponent, { disabled: loading || props.disabled }),
        React.createElement(ProductInputBaseFieldComponent, { ref: inputRef, placeholder: props.placeholder, disabled: loading || props.disabled, onEnter: handleEnter }),
        loading && React.createElement(CircularProgress, { size: 25 }),
        React.createElement(ResetProductFieldButtonComponent, { name: props.name, disabled: loading }),
        React.createElement(SearchProductFieldButtonComponent, { name: props.name, ref: searchProductFieldButtonRef, onLoading: handleLoading, variables: props.variables, inputBaseTarget: inputRef })));
};
export default InputProductFieldComponent;
