import React, { useEffect } from 'react';

import { useReactiveVar } from '@apollo/client';

import Grid from '@mui/material/Grid';

import { step, handleContinueConfiguration } from '../../../../apollo/cache';

import Form from './Form';
import Table from './Table';

const ProductComponent = () => {
  const stepVar = useReactiveVar(step);
  useEffect(() => {
    if (stepVar === 1) handleContinueConfiguration({ label: 'Guardar', color: 'secondary' });
  }, [stepVar]);
  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={5}>
        <Form />
      </Grid>
      <Grid item xs={12} md={7}>
        <Table />
      </Grid>
    </Grid>
  );
};

export default ProductComponent;
