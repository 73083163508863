import React, { useEffect, useState } from 'react';
import { Tabs, Tab } from '@mui/material';
import ReceiptIcon from '@mui/icons-material/Receipt';
import BusinessIcon from '@mui/icons-material/Business';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import MapIcon from '@mui/icons-material/Map';
import SettingsIcon from '@mui/icons-material/Settings';
import { useLocation, useNavigate } from 'react-router-dom';
import getBranchMenuValueFromPath from './getBranchMenuValueFromPath';
import getRouteFromBranchMenuValue from './getRouteFromBranchMenuValue';
var MenuEditBranchComponent = function () {
    var navigate = useNavigate();
    var location = useLocation();
    var _a = useState(0), value = _a[0], setValue = _a[1];
    useEffect(function () {
        var value = getBranchMenuValueFromPath(location.pathname);
        setValue(value);
    }, [location.pathname]);
    var handleChange = function (e, value) {
        var route = getRouteFromBranchMenuValue(value);
        if (route)
            navigate(route);
    };
    return (React.createElement(Tabs, { value: value, onChange: handleChange, variant: 'scrollable', scrollButtons: 'auto', className: 'bg-slate-200 border-0 border-b border-[#d6d6d6] border-solid' },
        React.createElement(Tab, { icon: React.createElement(BusinessIcon, null), label: 'Datos generales' }),
        React.createElement(Tab, { icon: React.createElement(ReceiptIcon, null), label: 'Datos fiscales' }),
        React.createElement(Tab, { icon: React.createElement(VpnKeyIcon, null), label: 'Credenciales API' }),
        React.createElement(Tab, { icon: React.createElement(MapIcon, null), label: 'Gesti\u00F3n de colonias' }),
        React.createElement(Tab, { icon: React.createElement(SettingsIcon, null), label: 'Configuraci\u00F3n' })));
};
export default MenuEditBranchComponent;
