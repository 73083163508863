import React, { useEffect } from 'react';
import CheckIcon from '@mui/icons-material/Check';
import { useController, useFormContext } from 'react-hook-form';
import { useProductFieldStoreInstance } from '../../../../../store/productField';
var IsProductFieldSelectedComponent = function (props) {
    var control = useFormContext().control;
    var storeInstance = useProductFieldStoreInstance();
    var value = useController({
        name: props.name,
        control: control,
    }).field.value;
    useEffect(function () {
        if (!value) {
            var setValue = storeInstance.getState().setValue;
            setValue('');
        }
    }, [value]);
    if (!value)
        return React.createElement("div", null);
    return React.createElement(CheckIcon, { color: 'success', fontSize: 'small' });
};
export default IsProductFieldSelectedComponent;
