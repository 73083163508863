import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
var SelectedSalesGlobalInvoiceFieldComponent = forwardRef(function (props, ref) {
    var _a = useState(0), qty = _a[0], setQty = _a[1];
    useEffect(function () {
        setQty(props.defaultQty);
    }, [props.defaultQty]);
    var updateQty = function (qty) {
        setQty(qty);
    };
    useImperativeHandle(ref, function () { return ({
        updateQty: updateQty,
    }); });
    return React.createElement("div", null, "".concat(qty, " seleccionados"));
});
export default SelectedSalesGlobalInvoiceFieldComponent;
