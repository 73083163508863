var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React, { useEffect, useState } from 'react';
import { useReactiveVar } from '@apollo/client';
import SelectFieldComponent from '../SelectField';
import useGetPaymentForm from '../../../hooks/useGetPaymentForm';
import { inputError } from '../../../apollo/cache';
var SelectPaymentFormFieldComponent = function (props) {
    var errors = useReactiveVar(inputError);
    var _a = useState(false), isDefaultValueApplied = _a[0], setIsDefaultValueApplied = _a[1];
    var _b = useState(''), value = _b[0], setValue = _b[1];
    var _c = useState([]), options = _c[0], setOptions = _c[1];
    var _d = useGetPaymentForm(), data = _d.data, loading = _d.loading;
    useEffect(function () {
        if (props.defaultValue && options.length > 0 && !isDefaultValueApplied) {
            var isInArray = options.find(function (el) { return el.value === props.defaultValue; });
            if (isInArray)
                setValue(props.defaultValue);
            setIsDefaultValueApplied(true);
        }
    }, [isDefaultValueApplied, options, props.defaultValue]);
    useEffect(function () {
        if (data) {
            var options_1 = data.rows.map(function (el) { return ({ label: el.name, value: el.id }); });
            var allOptions = props.chooseAllOptionsAllowed
                ? [
                    {
                        value: -2,
                        label: 'Todas las formas de pago',
                    },
                ]
                : [];
            setOptions(__spreadArray(__spreadArray(__spreadArray([], allOptions, true), [{ value: -1, label: 'Efectivo' }], false), options_1, true));
        }
    }, [data, props.chooseAllOptionsAllowed]);
    var handleChange = function (name, value) {
        setValue(value);
        props.onChange(name, value);
    };
    return (React.createElement(SelectFieldComponent, { name: props.name || 'paymentFormId', label: 'Forma de pago', disabled: loading, onChange: handleChange, value: value, error: errors.paymentFormId, options: options }));
};
export default SelectPaymentFormFieldComponent;
