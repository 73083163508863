import React, { useEffect, useState } from 'react';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Collapse, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import { useReactiveVar } from '@apollo/client';
import { permissionSearchField } from '../../../../../../apollo/cache';
var ListItemPermissionEditRoleComponent = function (props) {
    var permissionSearchFieldVar = useReactiveVar(permissionSearchField);
    var _a = useState(false), isOpen = _a[0], setIsOpen = _a[1];
    var _b = useState(true), show = _b[0], setShow = _b[1];
    useEffect(function () {
        var show = props.name.toLowerCase().includes(permissionSearchFieldVar.toLowerCase());
        setShow(show);
    }, [permissionSearchFieldVar]);
    var handleClick = function () {
        setIsOpen(function (isOpen) { return !isOpen; });
        props.onClick && props.onClick();
    };
    if (!show)
        return React.createElement("div", null);
    return (React.createElement(React.Fragment, null,
        React.createElement(ListItemButton, { onClick: handleClick, className: 'bg-slate-100 border-0 border-b border-[#d6d6d6] border-solid' },
            React.createElement(ListItemIcon, null, props.icon),
            React.createElement(ListItemText, { primary: props.name, secondary: props.secondary }),
            isOpen ? React.createElement(ExpandLessIcon, null) : React.createElement(ExpandMoreIcon, null)),
        React.createElement(Collapse, { in: isOpen, timeout: 'auto', unmountOnExit: true }, props.children)));
};
export default ListItemPermissionEditRoleComponent;
