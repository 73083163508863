import React, { useEffect, useState } from 'react';
import LinkMenuDashboardComponent from '../../helpers/Link';
import useApolloQuery from '../../../../../../hooks/useApolloQuery';
import GQL_COMPANY from '../../../../../../apollo/gql/company';
var SalesLinkBlockOneMenuDashboardComponent = function (props) {
    var _a = useState(false), show = _a[0], setShow = _a[1];
    var data = useApolloQuery(GQL_COMPANY.GET_ALLOWED_MODULES).data;
    useEffect(function () {
        if (data) {
            var allowedModuleKeys = Object.keys(data);
            var show_1 = allowedModuleKeys.reduce(function (show, key) { var _a, _b; return (((_a = data[key]) === null || _a === void 0 ? void 0 : _a.isAllowed) === true ? !!((_b = data[key]) === null || _b === void 0 ? void 0 : _b.isAllowed) : show); }, false);
            setShow(show_1);
        }
    }, [data]);
    if (!show || ![1, 3].includes(props.posModule))
        return null;
    return (React.createElement(LinkMenuDashboardComponent, { url: 'v2/sale', primary: 'Ventas', secondary: 'Emite ventas, facturas, cotizaciones y m\u00E1s.' }));
};
export default SalesLinkBlockOneMenuDashboardComponent;
