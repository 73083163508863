import React from 'react';
import Button from '../../../../../presentationals/Dialog/Footer/Button';
import { classificationForm, inputError } from '../../../../../apollo/cache';
import getDefaultClassificationValues from '../../../helpers/getDefaultClassificationValues';
var ClearButtonFooterCreateClassificationComponent = function () {
    var handleClick = function () {
        var defaultValues = getDefaultClassificationValues();
        classificationForm(defaultValues);
        inputError({});
    };
    return (React.createElement(Button, { color: 'inherit', className: 'ml-1', onClick: handleClick }, "Limpiar"));
};
export default ClearButtonFooterCreateClassificationComponent;
