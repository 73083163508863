var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import useApolloMutation from '../../../../hooks/useApolloMutation';
import GQL from '../../_gql';
import getInputErrorFormat from '../../../sale.v2/Action/helpers/getInputErrorFormat';
import Button from '../../../../presentationals/Button';
var CredentialsEditBranchComponent = function () {
    var params = useParams();
    var _a = useState(['', '']), credentials = _a[0], setCredentials = _a[1];
    var _b = useApolloMutation(GQL.CREATE_CREDENTIALS), createCredentials = _b[0], loading = _b[1].loading;
    var handleClick = function () { return __awaiter(void 0, void 0, void 0, function () {
        var data, error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, createCredentials({ variables: { id: params.branchId } })];
                case 1:
                    data = (_a.sent()).data;
                    if (data === null || data === void 0 ? void 0 : data.generateLocalCredentials)
                        setCredentials([data.generateLocalCredentials.token, data.generateLocalCredentials.secret]);
                    return [3 /*break*/, 3];
                case 2:
                    error_1 = _a.sent();
                    getInputErrorFormat(error_1);
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    return (React.createElement("div", { className: 'flex-1 px-6 py-4 flex flex-col md:grid md:grid-cols-2 md:gap-4 overflow-auto' },
        React.createElement("div", null,
            React.createElement("div", { className: 'mb-3 text-base' }, "Las credenciales podr\u00E1n ser utilizadas para configurar la aplicaci\u00F3n de escritorio, ten cuidado, si genera nuevas credenciales, cualquiera que hayas generado anteriormente ser\u00E1n dadas de baja."),
            React.createElement(Button, { color: 'success', isLoading: loading, onClick: handleClick, label: 'Generar nuevas credenciales' }),
            credentials[0] && (React.createElement(React.Fragment, null,
                React.createElement("div", { className: 'text-base mt-2' },
                    "Token: ",
                    React.createElement("br", null),
                    React.createElement("strong", null, credentials[0])),
                React.createElement("div", { className: 'text-base' },
                    "Secret: ",
                    React.createElement("br", null),
                    React.createElement("strong", null, credentials[1])),
                React.createElement("div", { className: 'text-base mt-3' }, "Copia y almacena estas credenciales en un lugar seguro pues no las podr\u00E1s recuperar."))))));
};
export default CredentialsEditBranchComponent;
