var getRouteFromSaleRotationMenuValue = function (value) {
    switch (value) {
        case 0:
            return 'product';
        case 1:
            return 'presentation';
        case 2:
            return 'ingredient';
        case 3:
            return 'promotion';
        case 4:
            return 'productAndIngredient';
        default:
            return false;
    }
};
export default getRouteFromSaleRotationMenuValue;
