import React from 'react';
import { Outlet } from 'react-router-dom';
import MenuSaleLayoutComponent from './Menu';
import GoBackButtonComponent from '../../presentationals/GoBackButton';
var SaleRotationLayoutComponent = function () {
    return (React.createElement("div", { className: 'flex flex-1 flex-col' },
        React.createElement("div", { className: 'flex items-center px-4 py-2' },
            React.createElement("div", { className: 'text-xl text-gray-700 tracking-tight mr-3 items-center' },
                React.createElement(GoBackButtonComponent, { route: -1 }),
                "Reporte de rotaciones en ventas")),
        React.createElement(MenuSaleLayoutComponent, null),
        React.createElement("div", { className: 'flex flex-1 flex-col h-full overflow-hidden' },
            React.createElement(Outlet, null))));
};
export default SaleRotationLayoutComponent;
