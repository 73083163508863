import React, { useEffect, useState } from 'react';

import useSound from 'use-sound';

import { useApolloClient, useLazyQuery, useMutation } from '@apollo/client';

import NotificationsIcon from '@mui/icons-material/Notifications';

import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';

import _bell from '../../../../assets/static/_bell.mp3';

import GQL from './_gql';
import Notifications from './NotificationDialog';
import GQL_NOTIFICATION from '../../../../apollo/gql/notifications';
import subscribeSocket from '../../../../socket/helpers/subscribe';
import unsubscribeSocket from '../../../../socket/helpers/unsubscribe';

const NotificationButton = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [notifications, setNotifications] = useState(0);

  const client = useApolloClient();
  const [playSound] = useSound(_bell);
  const [play, setPlay] = useState(true);

  const [viewedAllNotification] = useMutation(GQL_NOTIFICATION.VIEW_ALL, {
    update(cache) {
      cache.modify({
        fields: {
          notifications(existingNotificationRefs) {
            existingNotificationRefs.rows.map(el =>
              cache.modify({
                id: cache.identify(el),
                fields: { status: () => 0 },
              }),
            );
            return existingNotificationRefs;
          },
        },
      });
    },
  });
  const [getNotifications, { data: dataCount }] = useLazyQuery(GQL_NOTIFICATION.GET_ALL);

  useEffect(() => {
    const f = data => {
      setPlay(true);
      setNotifications(notifications => notifications + 1);
      client.cache.modify({
        fields: {
          notifications(cachedNotifications) {
            const newNotificationRef = client.cache.writeFragment({
              data: {
                __typename: 'Notification',
                ...data,
                customerId: data.customerId || null,
                dataId: data.dataId || null,
                from: data.from || null,
                saleId: data.saleId || null,
              },
              fragment: GQL.FRAGMENT_NOTIFICATION,
            });
            return {
              ...cachedNotifications,
              rows: [newNotificationRef, ...cachedNotifications.rows],
            };
          },
        },
      });
    };
    subscribeSocket('ERP_GENERAL', f);
    return () => {
      unsubscribeSocket('ERP_GENERAL', f);
    };
  }, []);

  useEffect(() => {
    if (play) {
      playSound();
      setPlay(false);
    }
  }, [play]);

  useEffect(() => {
    getNotifications();
  }, []);

  useEffect(() => {
    if (dataCount && dataCount.notifications) {
      const tmp = dataCount.notifications.rows.filter(el => el.status === 1);
      setNotifications(tmp.length);
    }
  }, [dataCount]);

  const openNotifications = async e => {
    setAnchorEl(e.currentTarget);
    if (notifications > 0) await viewedAllNotification();
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton color='default' onClick={openNotifications} size='large'>
        <Badge badgeContent={notifications} color='secondary'>
          <NotificationsIcon />
        </Badge>
      </IconButton>
      <Notifications anchorEl={anchorEl} data={dataCount} handleClose={handleClose} />
    </>
  );
};

export default NotificationButton;
