var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useEffect, useState } from 'react';
import { IconButton } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import useApolloLazyQuery from '../../../../hooks/useApolloLazyQuery';
import GQL_CUSTOMER from '../../../../apollo/gql/customer';
import CreateCustomerAddressComponent from '../Create';
import CustomerAddressItemComponent from './Item';
var BodyCustomerAddessPopoverComponent = function (props) {
    var _a = useApolloLazyQuery(GQL_CUSTOMER.GET_CUSTOMER_ADDRESSES), getCustomerAddresses = _a[0], _b = _a[1], loading = _b.loading, data = _b.data;
    var _c = useState(false), createAddress = _c[0], setCreateAddress = _c[1];
    useEffect(function () {
        if (props.customerId)
            getCustomerAddresses({ variables: { customerId: props.customerId } });
    }, [props.customerId]);
    var handleCreate = function () {
        setCreateAddress(true);
    };
    var handleReturn = function () {
        setCreateAddress(false);
    };
    var handleEdit = function (data) {
        console.log(data);
    };
    if (createAddress && props.customerId)
        return (React.createElement(CreateCustomerAddressComponent, { customerId: props.customerId, onCreate: props.onSelect, onReturn: handleReturn }));
    return (React.createElement("div", { className: 'flex flex-1 flex-col' },
        React.createElement("div", { className: 'flex items-center px-4 py-3' },
            React.createElement("div", { className: 'flex-1 leading-none font-medium' }, "Domicilios del cliente"),
            props.customerId && (React.createElement(IconButton, { onClick: handleCreate, disabled: loading, color: 'secondary', className: 'p-0' },
                React.createElement(AddIcon, null)))),
        React.createElement("div", { className: 'flex flex-1 flex-col' }, data && data.length > 0 ? (data.map(function (el, i) { return (React.createElement(CustomerAddressItemComponent, __assign({ key: el.id }, el, { index: i, onSelect: props.onSelect, onEdit: handleEdit }))); })) : (React.createElement("div", { className: 'mt-2 px-4' }, "El cliente no cuenta con domicilios registrados.")))));
};
export default BodyCustomerAddessPopoverComponent;
