import React, { useRef } from 'react';
import FoodBankOutlinedIcon from '@mui/icons-material/FoodBankOutlined';
import { useController, useFormContext } from 'react-hook-form';
import OnlineOrderMenuButtonComponent from '../helpers/MenuButton';
import RHFBranchPopoverComponent from '../../../presentationals/RHForm/BranchPopover';
var OnlineOrderBranchButtonComponent = function () {
    var rhFBranchPopoverRef = useRef(null);
    var control = useFormContext().control;
    var value = useController({
        name: 'branch',
        control: control,
    }).field.value;
    var handleClick = function (e) {
        var _a;
        (_a = rhFBranchPopoverRef.current) === null || _a === void 0 ? void 0 : _a.open(e.currentTarget);
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(OnlineOrderMenuButtonComponent, { onClick: handleClick, label: 'Sucursal', Icon: FoodBankOutlinedIcon, value: value ? value.name : 'Predeterminada' }),
        React.createElement(RHFBranchPopoverComponent, { name: 'branch', ref: rhFBranchPopoverRef })));
};
export default OnlineOrderBranchButtonComponent;
