import React from 'react';

import { useReactiveVar } from '@apollo/client';

import { ListItemButton, ListItemText } from '@mui/material';

import { employeeSaleV2, isSaleEmployeeFormOpen } from '../../../../../../apollo/cache';

const ItemFormFormatActionSalV2Component = props => {
  const employeeSaleV2Var = useReactiveVar(employeeSaleV2);
  const handleClick = () => {
    employeeSaleV2(props.id);
    isSaleEmployeeFormOpen(false);
  };

  return (
    <ListItemButton
      onClick={handleClick}
      className={employeeSaleV2Var === props.id ? 'bg-gray-200' : undefined}
    >
      <ListItemText primary={props.label} />
    </ListItemButton>
  );
};

export default ItemFormFormatActionSalV2Component;
