import React from 'react';
import ItemVariationsSaleV2Component from './Item';
var VariationSaleV2Component = function (props) {
    var handleClick = function (productIngredientId, productVariationId) {
        props.onChange(productIngredientId, productVariationId);
    };
    return (React.createElement("div", { className: 'flex flex-col border-0 border-b border-solid border-slate-400 px-4 pt-3 pb-5' },
        React.createElement("div", { className: 'text-lg' }, props.productVariationName),
        React.createElement("div", { className: 'text-base text-slate-600' }, "Selecciona un(a) ".concat(props.productVariationName)),
        React.createElement("div", { className: 'flex flex-row flex-wrap mt-3' }, props.ProductIngredients.map(function (elCh) {
            var _a;
            return (React.createElement(ItemVariationsSaleV2Component, { key: elCh.id, productIngredientId: elCh.id, productVariationId: props.productVariationId, name: elCh.Ingredient.name, onClick: handleClick, selected: !!((_a = props.SelectedProductIngredients) === null || _a === void 0 ? void 0 : _a.includes(elCh.id)) }));
        }))));
};
export default VariationSaleV2Component;
