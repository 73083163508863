import React, { useCallback } from 'react';

import { useReactiveVar } from '@apollo/client';

import { currencySaleV2, isSaleCurrencyFormOpen } from '../../../../../apollo/cache';

import Button from '../../helpers/Button';

import getCurrencyFormatLabel from '../../../../../helpers/getCurrencyLabel';

const ButtonCurrencyActionSaleV2Component = () => {
  const currencySaleV2Var = useReactiveVar(currencySaleV2);

  const currencyFormatLabel = useCallback(
    () => getCurrencyFormatLabel(currencySaleV2Var[0]),
    [currencySaleV2Var[0]],
  );

  const handleClick = () => {
    isSaleCurrencyFormOpen(true);
  };
  return <Button title='Moneda' subtitle={currencyFormatLabel} onClick={handleClick} />;
};

export default ButtonCurrencyActionSaleV2Component;
