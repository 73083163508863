import React, { useEffect } from 'react';

import { useReactiveVar } from '@apollo/client';

import Collapse from '@mui/material/Collapse';

import { step, steps, handleContinueConfiguration, purchaseOrderId } from '../../../apollo/cache';

import FormDialog from '../../../presentationals/FormDialog';
import Stepper from '../../../presentationals/FormComponents/Stepper';
import FooterAction from '../../../presentationals/FormComponents/FooterAction';

import Main from './Main';
import Table from './Table';

const ProductsComponent = props => {
  const stepVar = useReactiveVar(step);

  useEffect(() => {
    steps(['Datos Generales', 'Productos']);
  }, []);

  useEffect(() => {
    if (stepVar === 1) handleContinueConfiguration({ label: 'Guardar', color: 'secondary' });
  }, [stepVar]);

  const handleClose = () => {
    handleContinueConfiguration({});
    step(0);
    purchaseOrderId(null);
    props.handleClose();
  };

  return (
    <FormDialog
      isOpen={props.isOpen || false}
      handleClose={handleClose}
      title='Lista de Productos'
      fullScreen
      noPadding
      noActions
    >
      <Stepper />
      <div className='my-1 mx-3'>
        <Collapse in={stepVar === 0}>
          <Main />
        </Collapse>
        <Collapse in={stepVar === 1}>
          <Table id={props.id} handleCloseAll={handleClose} />
        </Collapse>
      </div>
      <FooterAction />
    </FormDialog>
  );
};

export default ProductsComponent;
