var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useMemo } from 'react';
import ModuleV3Component from '../../presentationals/Module.v3';
import useGetCashFlows from './helpers/useGetCashFlows';
import getSearchCashFlowByOptions from './helpers/getSearchCashFlowByOptions';
import getCashFlowTotalsFromData from './helpers/getCashFlowTotalsFromData';
import convertToCurrencyLabel from '../../helpers/convertToCurrencyLabel';
import FilterCashFlowDialogComponent from './Filter';
import RedirectCreateFormToRouteComponent from '../../presentationals/RedirectCreateFormToRoute';
import RedirectEditFormToRouteComponent from '../../presentationals/RedirectEditFormToRoute';
var CashFlowComponent = function () {
    var _a = useGetCashFlows(), data = _a.data, loading = _a.loading;
    var searchCashFlowByOptions = getSearchCashFlowByOptions();
    var Header = useMemo(function () {
        var headers = [
            { Header: 'Creado el', accessor: 'createdAt' },
            { Header: 'Acción', accessor: 'action' },
            { Header: 'Descripción', accessor: 'description' },
            { Header: 'Importe', accessor: 'total' },
            { Header: 'Empleado', accessor: 'employee' },
            { Header: 'Sucursal', accessor: 'branch' },
        ];
        var totals = getCashFlowTotalsFromData(data);
        return headers.map(function (el) {
            var accessor = el.accessor;
            return __assign(__assign({}, el), { Footer: Object.keys(totals).includes(el.accessor)
                    ? convertToCurrencyLabel(totals[accessor])
                    : '' });
        });
    }, [data]);
    return (React.createElement(React.Fragment, null,
        React.createElement(ModuleV3Component, { filter: true, searchFieldOptions: {
                name: 'searchCashFlowBy',
                options: searchCashFlowByOptions,
                defaultValue: 'description',
            }, title: 'Movimientos de caja', header: Header, data: data, isLoading: loading }),
        React.createElement(RedirectCreateFormToRouteComponent, null),
        React.createElement(RedirectEditFormToRouteComponent, null),
        React.createElement(FilterCashFlowDialogComponent, null)));
};
export default CashFlowComponent;
