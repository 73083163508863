import React, { useState, useEffect } from 'react';
import { useQuery } from '@apollo/client';

import Typography from '@mui/material/Typography';

import FilterDrawer from '../../presentationals/FilterDrawer.v2';
import Autocomplete from '../../presentationals/Autocomplete';

import { customerLabel } from '../../helpers';
import { filter } from '../../apollo/cache';
import GQL_CUSTOMER from '../customer/_gql';

const customerVar = customerLabel({ textTransform: 'capitalize' });

const defaultValues = {
  customer: null,
};

const FilterDialog = () => {
  const [values, setValues] = useState(defaultValues);
  const [customers, setCustomers] = useState([]);

  const { data } = useQuery(GQL_CUSTOMER.GET_NAME_AND_PHONE, { variables: { limit: 0 } });

  useEffect(() => {
    if (data) {
      const tmp = data.customers.rows.map(el => {
        return {
          value: el.id,
          label: `${el.firstName} ${el.lastName || ''} ${el.phone || ''}`,
        };
      });
      setCustomers(tmp);
    }
  }, data);

  const handleAutocompleteChange = (e, name, value) => {
    setValues(values => ({ ...values, [name]: value }));
  };

  const handleAction = (e, data) => {
    const { startDate: start, endDate: end, branchId } = data;
    filter({ start, end, branchId, customerId: values.customer ? values.customer.value : null });
  };

  return (
    <FilterDrawer optionalDates handleAction={handleAction}>
      <Typography variant='subtitle1'>{`${customerVar}:`}</Typography>
      <Autocomplete
        name='customer'
        variant='outlined'
        onChange={handleAutocompleteChange}
        options={customers}
        value={values.customer}
        autoFocus
        noFormat
      />
    </FilterDrawer>
  );
};

export default FilterDialog;
