import React from 'react';
import CreateQueueManagerDigitalContentButtonComponent from './CreateButton';
import AvailabilityFieldDigitalContentQueueManagerCompanyComponent from './AvailabilityField';
import TimerFieldDigitalContentQueueManagerCompanyComponent from './TimerField';
import ImageComponent from '../../../../product/Item/Image/Item';
import DeleteDigitalContentButtonComponent from './DeleteButton';
var DigitalContentQueueManagerCompanyComponent = function (props) {
    return (React.createElement("div", null,
        React.createElement("div", { className: 'flex flex-1 flex-col mt-6' },
            React.createElement("div", { className: 'flex text-xl font-medium' }, "Contenido digital"),
            React.createElement("div", { className: 'mt-2' }),
            React.createElement(AvailabilityFieldDigitalContentQueueManagerCompanyComponent, { value: props.digitalContentAvailability }),
            React.createElement("div", { className: 'w-1/4' },
                !props.digitalContentAvailability && (React.createElement(TimerFieldDigitalContentQueueManagerCompanyComponent, { value: props.digitalContentTimer })),
                React.createElement("div", { className: 'my-4' }),
                React.createElement(CreateQueueManagerDigitalContentButtonComponent, null)),
            props.DigitalContent.length === 0 ? (React.createElement("div", { className: 'mt-2 text-base font-light' }, "De momento no cuentas con contenido digital.")) : (props.DigitalContent.map(function (fileId) { return (React.createElement("div", { key: fileId },
                React.createElement(ImageComponent, { src: fileId, alt: "digital-content-".concat(fileId) }),
                React.createElement(DeleteDigitalContentButtonComponent, { fileId: fileId }))); })))));
};
export default DigitalContentQueueManagerCompanyComponent;
