import React from 'react';
import AppsIcon from '@mui/icons-material/Apps';
import { IconButton } from '@mui/material';
import { isAppsMenuOpen } from '../../../../../apollo/cache';
var AppsButtonComponent = function () {
    var handleClick = function (e) {
        isAppsMenuOpen([true, e.currentTarget]);
    };
    return (React.createElement(IconButton, { color: 'default', onClick: handleClick, size: 'large' },
        React.createElement(AppsIcon, null)));
};
export default AppsButtonComponent;
