import React, { useMemo } from 'react';
import useGetPurchaseOrders from '../../hooks/useGetPurchaseOrders';
import ItemCategoriesPurchaseOrderComponent from './ItemCategories';
import ModuleV3Component from '../../presentationals/Module.v3';
import getPurchaseOrderHeaderColumns from './helpers/getPurchaseOrderHeaderColumns';
import TableSettingsPurchaseOrderComponent from './TableSettings';
import RedirectEditFormToRouteComponent from '../../presentationals/RedirectEditFormToRoute';
import RedirectCreateFormToRouteComponent from '../../presentationals/RedirectCreateFormToRoute';
var PurchaseOrderComponent = function () {
    var _a = useGetPurchaseOrders({ getByFilter: true }), data = _a.data, loading = _a.loading;
    console.log('Loading: ', loading);
    console.log('Data: ', data);
    var header = useMemo(function () { return getPurchaseOrderHeaderColumns(); }, []);
    return (React.createElement(React.Fragment, null,
        React.createElement(ModuleV3Component, { tableSetting: true, filter: true, title: 'Orden de compra', data: data, header: header, isLoading: loading, ItemCategories: React.createElement(ItemCategoriesPurchaseOrderComponent, { isLoading: loading }) }),
        React.createElement(TableSettingsPurchaseOrderComponent, null),
        React.createElement(RedirectEditFormToRouteComponent, null),
        React.createElement(RedirectCreateFormToRouteComponent, null)));
};
export default PurchaseOrderComponent;
