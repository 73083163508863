var getTransactionStatus = function (status) {
    switch (status) {
        case 0:
            return 'Cancelado';
        case 1:
            return 'Activo';
        default:
            return 'Indefinido';
    }
};
export default getTransactionStatus;
