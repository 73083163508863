import React, { useState } from 'react';

import { useMutation } from '@apollo/client';

import LinearProgress from '@mui/material/LinearProgress';
import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';

import TextField from '../../presentationals/FormComponents/TextField';

import { parseError } from '../../helpers';

import GQL from './_gql';
import useSnackbarDialog from '../../store/snackbar/snackbarDialog';

const defaultValues = {
  deleteJustification: '',
};

const DeleteDialog = props => {
  const { setSnackbar } = useSnackbarDialog();
  const [values, setValues] = useState(defaultValues);
  const [errors, setErrors] = useState({});

  const [cancelPurchaseOrder, { loading }] = useMutation(GQL.CANCEL_PURCHASE_ORDER, {
    update(cache, { data }) {
      cache.evict({ id: `PurchaseOrder:${data.cancelPurchaseOrder.id}` });
      cache.gc();
    },
  });

  const handleAction = async () => {
    try {
      if (!values.deleteJustification || values.deleteJustification.trim() === '') {
        const e = new Error('Escriba una justificación');
        e.name = 'deleteJustification';
        e.fields = true;
        throw e;
      }

      await cancelPurchaseOrder({
        variables: {
          id: props.id,
          deleteJustification: values.deleteJustification,
        },
      });

      setValues(defaultValues);
      props.handleClose();
    } catch (e) {
      if (e.fields) {
        setErrors({ [e.name]: e.message });
        return;
      }
      const parseErrors = parseError(e);
      parseErrors.forEach(el => {
        if (el.name === 'BAD_USER_INPUT') {
          const key = Object.keys(el.message)[0];
          setSnackbar({ ...defaultSnackbar, label: el.message[key], severity: 'error' });
        } else {
          setSnackbar({ ...defaultSnackbar, label: el.message, severity: 'error' });
        }
      });
    }
  };

  const handleClose = () => {
    setValues(defaultValues);
    props.handleClose();
  };

  const handleChange = (name, value) => {
    setValues(values => ({ ...values, [name]: value }));
  };

  return (
    <Dialog
      open={props.isOpen || false}
      fullWidth={true}
      maxWidth='xs'
      aria-labelledby='Confirmar'
      aria-describedby='Dialogo de confirmación para validar un registro.'
    >
      {loading && <LinearProgress color='secondary' />}
      <DialogContent>
        <TextField
          name='deleteJustification'
          label='¿Por qué necesitar cancelar esta orden de compra?'
          onChange={handleChange}
          value={values.deleteJustification}
          error={errors.deleteJustification}
          rows={2}
          multiline
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color='primary'>
          Cerrar
        </Button>
        <Button onClick={handleAction} disabled={loading} color='primary' autoFocus>
          Continuar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteDialog;
