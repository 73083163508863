import CONFIG from '../../../../../../config/config.json';

const getUrlToPrintDocument = (key, id) => {
  const url = CONFIG['restServer'].uri;
  switch (parseInt(key)) {
    case -2:
      return `${url}/files/quotes/${id}`;
    case -1:
      return `${url}/files/invoices/${id}/pdf`;
    case 3:
    case 5:
      return `${url}/files/serviceOrders/${id}`;
    case 6:
      return `${url}/files/sales/${id}?category=1`;
    default:
      return `${url}/files/sales/${id}`;
  }
};

export default getUrlToPrintDocument;
