import { gql } from '@apollo/client';

const GET = gql`
  query Requisitions(
    $like: String
    $limit: Int
    $offset: Int
    $status: [Int]
    $start: Date
    $end: Date
    $order: [String]
    $branchId: [ID]
  ) {
    requisitions(
      branchId: $branchId
      like: $like
      limit: $limit
      offset: $offset
      status: $status
      start: $start
      end: $end
      order: $order
    ) {
      count
      rows {
        id
        folio
        serial
        status
        date
        type
        customerId
        commentary
        Customer {
          firstName
          lastName
        }
        description
        Employee {
          firstName
          lastName
        }
        AuthEmployee {
          firstName
          lastName
        }
        DeleteEmployee {
          firstName
          lastName
        }
        companyId
        branchId
        createdAt
        deletedAt
        Company {
          id
          name
        }
        Branch {
          id
          name
        }
        BranchRequisition {
          name
          zipCode
          city
          state
          phone
          altPhone
          addressLine1
          addressLine2
        }
      }
    }
  }
`;

const GET_BY_ID = gql`
  query Requisition($id: ID!) {
    requisition(id: $id) {
      id
      folio
      serial
      status
      date
      type
      customerId
      commentary
      Customer {
        firstName
        lastName
      }
      description
      Employee {
        firstName
        lastName
      }
      AuthEmployee {
        firstName
        lastName
      }
      DeleteEmployee {
        firstName
        lastName
      }
      companyId
      branchId
      createdAt
      deletedAt
      Company {
        id
        name
      }
      Branch {
        id
        name
        phone
      }
      BranchRequisition {
        name
        zipCode
        city
        state
        phone
        altPhone
        addressLine1
        addressLine2
      }
      RequisitionProducts {
        id
        status
        concept
        quantity
        baseQuantity
        receivedQuantity
        sendQuantity
        Product {
          id
          name
          sku
          barcode
        }
        ProductPresentation {
          id
          name
        }
        currentQuantity
        Branch {
          name
        }
        createdAt
        productPresentationId
        productId
      }
    }
  }
`;

const CREATE = gql`
  mutation CreateRequisition(
    $description: String
    $branchId: ID
    $customerId: ID
    $type: Int!
    $branchRequisitionId: ID
    $Products: [RequisitionProductData]
  ) {
    createRequisition(
      input: {
        description: $description
        branchId: $branchId
        branchRequisitionId: $branchRequisitionId
        Products: $Products
        customerId: $customerId
        type: $type
      }
    ) {
      id
      folio
      serial
      type
      customerId
      commentary
      Customer {
        firstName
        lastName
      }
      status
      date
      type
      description
      Employee {
        firstName
        lastName
      }
      AuthEmployee {
        firstName
        lastName
      }
      DeleteEmployee {
        firstName
        lastName
      }
      companyId
      branchId
      createdAt
      deletedAt
      Company {
        name
      }
      Branch {
        name
      }
      BranchRequisition {
        name
      }
      RequisitionProducts {
        id
        status
        concept
        quantity
        baseQuantity
        receivedQuantity
        Product {
          id
          name
          sku
        }
        ProductPresentation {
          id
          name
        }
        currentQuantity
        Branch {
          name
        }
        createdAt
        productPresentationId
        productId
      }
    }
  }
`;

const DELETE_ITEM = gql`
  mutation DeleteRequisitionProduct($requisitionId: ID!, $requisitionProductId: ID!) {
    deleteRequisitionProduct(
      requisitionProductId: $requisitionProductId
      requisitionId: $requisitionId
    ) {
      id
    }
  }
`;

const ADD_ITEM = gql`
  mutation AddRequisitionProduct(
    $requisitionId: ID!
    $quantity: Float!
    $productId: ID
    $productPresentationId: ID
  ) {
    addRequisitionProduct(
      requisitionId: $requisitionId
      input: {
        quantity: $quantity
        productId: $productId
        productPresentationId: $productPresentationId
      }
    ) {
      id
      status
      concept
      quantity
      baseQuantity
      receivedQuantity
      sendQuantity
      Product {
        id
        name
      }
      ProductPresentation {
        id
        name
      }
      currentQuantity
      Branch {
        name
      }
      createdAt
      productPresentationId
      productId
    }
  }
`;

const UPDATE_ITEM = gql`
  mutation UpdateRequisitionProduct(
    $requisitionId: ID!
    $requisitionProductId: ID!
    $quantity: Float
  ) {
    updateRequisitionProduct(
      requisitionId: $requisitionId
      requisitionProductId: $requisitionProductId
      input: { quantity: $quantity }
    ) {
      id
      status
      concept
      quantity
      baseQuantity
      receivedQuantity
      sendQuantity
      Product {
        name
      }
      ProductPresentation {
        name
      }
      currentQuantity
      Branch {
        id
        name
      }
      createdAt
      productPresentationId
      productId
    }
  }
`;

const CLOSE_REQUISITION = gql`
  mutation CloseRequisition($id: ID!, $Products: [RequisitionProductCloseData]) {
    closeRequisition(id: $id, Products: $Products) {
      id
      folio
      serial
      status
      date
      type
      description
      commentary
      Employee {
        firstName
        lastName
      }
      AuthEmployee {
        firstName
        lastName
      }
      DeleteEmployee {
        firstName
        lastName
      }
      companyId
      branchId
      createdAt
      deletedAt
      Company {
        name
      }
      Branch {
        name
      }
      BranchRequisition {
        name
      }
      RequisitionProducts {
        id
        status
        concept
        quantity
        baseQuantity
        receivedQuantity
        Product {
          id
          name
        }
        ProductPresentation {
          id
          name
        }
        currentQuantity
        Branch {
          name
        }
        createdAt
        productPresentationId
        productId
      }
    }
  }
`;

const AUTHORIZE_REQUISITION = gql`
  mutation AuthorizeRequisition($id: ID!) {
    authorizeRequisition(id: $id) {
      id
      folio
      serial
      status
      date
      type
      description
      commentary
      Employee {
        firstName
        lastName
      }
      AuthEmployee {
        firstName
        lastName
      }
      DeleteEmployee {
        firstName
        lastName
      }
      companyId
      branchId
      createdAt
      deletedAt
      Company {
        name
      }
      Branch {
        name
      }
      BranchRequisition {
        name
      }
      RequisitionProducts {
        id
        status
        concept
        quantity
        baseQuantity
        receivedQuantity
        Product {
          id
          name
        }
        ProductPresentation {
          id
          name
        }
        currentQuantity
        Branch {
          name
        }
        createdAt
        productPresentationId
        productId
      }
    }
  }
`;

const CANCEL_REQUISITION = gql`
  mutation CancelRequisition($id: ID!, $deleteJustification: String!) {
    cancelRequisition(id: $id, deleteJustification: $deleteJustification) {
      id
    }
  }
`;

const FRAGMENT_NEW_REQUISITION_PRODUCT = gql`
  fragment NewRequisitionProduct on RequisitionProduct {
    id
    status
    concept
    quantity
    baseQuantity
    receivedQuantity
    Product {
      id
      name
    }
    ProductPresentation {
      id
      name
    }
    currentQuantity
    Branch {
      name
    }
    createdAt
    productPresentationId
    productId
  }
`;

const FRAGMENT_CORE_REQUISITION_FIELDS = gql`
  fragment NewRequitision on Requisition {
    id
    folio
    serial
    status
    date
    type
    description
    Employee {
      firstName
      lastName
    }
    AuthEmployee {
      firstName
      lastName
    }
    DeleteEmployee {
      firstName
      lastName
    }
    companyId
    branchId
    createdAt
    deletedAt
    Company {
      name
    }
    Branch {
      id
      name
    }
    BranchRequisition {
      name
    }
  }
`;

const SEND_REQUISITION = gql`
  mutation SendRequisition($id: ID!, $emails: [String]) {
    sendRequisition(id: $id, emails: $emails)
  }
`;

const UPDATE_COMMENTARY = gql`
  mutation UpdateRequisitionCommentary($id: ID!, $commentary: String) {
    updateRequisitionCommentary(id: $id, commentary: $commentary) {
      id
      commentary
    }
  }
`;

export default {
  GET,
  GET_BY_ID,
  CREATE,
  ADD_ITEM,
  DELETE_ITEM,
  CLOSE_REQUISITION,
  CANCEL_REQUISITION,
  UPDATE_ITEM,
  AUTHORIZE_REQUISITION,
  FRAGMENT_NEW_REQUISITION_PRODUCT,
  FRAGMENT_CORE_REQUISITION_FIELDS,
  SEND_REQUISITION,
  UPDATE_COMMENTARY,
};
