import React, { useEffect } from 'react';

import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Typography from '@mui/material/Typography';

import { sort } from '../../../../apollo/cache';

import TableSortLabel from './TableSortLabel';

const Component = props => {
  useEffect(() => {
    return () => {
      sort({});
    };
  }, []);

  return (
    <TableHead>
      <TableRow>
        {props.Rows.map(el => (
          <TableCell className='bg-white' key={el.key} align={el.align || 'left'}>
            <Typography variant='body2' noWrap={true}>
              {el.sort ? (
                <TableSortLabel id={el.key} label={el.label} />
              ) : (
                <strong>{el.label}</strong>
              )}
            </Typography>
          </TableCell>
        ))}
        <TableCell className='bg-white' />
      </TableRow>
    </TableHead>
  );
};

export default Component;
