import React from 'react';

import { Divider, Table, TableCell, TableHead, TableRow, Typography } from '@mui/material';

import TableBody from './TableBody';

const CartInfoSummaryFormBodyConfirmDialogSaleV2Component = () => {
  return (
    <div className='flex flex-col flex-1'>
      <Divider className='mb-2 mt-3' />
      <Typography variant='h6'>Carrito de compras</Typography>
      <div className='h-full flex-1 flex basis-0 overflow-x-hidden overflow-y-auto'>
        <div className='w-full'>
          <Table stickyHeader size='small'>
            <TableHead>
              <TableRow>
                <TableCell>Producto</TableCell>
                <TableCell align='center'>Cant.</TableCell>
                <TableCell align='right'>P. Unitario</TableCell>
                <TableCell align='right'>Dscto.</TableCell>
                <TableCell align='right'>Impuestos</TableCell>
                <TableCell align='right'>Importe</TableCell>
              </TableRow>
            </TableHead>
            <TableBody />
          </Table>
        </div>
      </div>
    </div>
  );
};

export default CartInfoSummaryFormBodyConfirmDialogSaleV2Component;
