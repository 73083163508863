import React, { useState, useEffect } from 'react';

import { startOfDay, endOfDay } from 'date-fns';

import { useQuery, useReactiveVar } from '@apollo/client';

import CloseIcon from '@mui/icons-material/Close';

import Drawer from '@mui/material/Drawer';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Grid from '@mui/material/Grid';
import Switch from '@mui/material/Switch';

import { filterForm } from '../../apollo/cache';

import Button from '../Button';
import DateField from '../DateField';
import SelectField from '../SelectField';

import GQL from './_gql';
import getSession from '../../helpers/getSession';

const defaultValues = {
  startDate: startOfDay(new Date()),
  endDate: endOfDay(new Date()),
  date: endOfDay(new Date()),
  branchId: '',
  useDates: false,
};

const FilterDrawer = props => {
  const filterFormVar = useReactiveVar(filterForm);
  const [values, setValues] = useState(defaultValues);
  const [branches, setBranches] = useState([]);

  const { data } = useQuery(GQL.GET_BRANCHES, { variables: { justLinked: true } });

  useEffect(() => {
    if (data && data.branches.count > 0) {
      const tmpBranches = props.includeAllOption
        ? [
            { value: -1, label: 'Todas las Sucursales' },
            ...data.branches.rows.map(el => ({ value: el.id, label: el.name })),
          ]
        : data.branches.rows.map(el => ({ value: el.id, label: el.name }));
      setBranches([...tmpBranches]);
      const session = getSession();
      const isInArray = tmpBranches.find(el => el.value === session?.Company?.Branch.id);
      if (isInArray) setValues(values => ({ ...values, branchId: isInArray.value }));
    }
  }, [data]);

  const handleClose = () => filterForm(false);

  const handleClick = e => {
    props.handleAction &&
      props.handleAction(e, {
        ...values,
        startDate:
          props.optionalDates && values.useDates
            ? values.startDate
            : props.showDates
            ? values.startDate
            : null,
        endDate:
          props.optionalDates && values.useDates
            ? values.endDate
            : props.showDates
            ? values.endDate
            : null,
        date:
          props.optionalDates && values.useDates
            ? values.date
            : props.showOneDate
            ? values.date
            : null,
        branchId: values.branchId !== '' ? values.branchId : null,
      });
    handleClose();
  };

  const handleDateChange = (name, value) => {
    setValues(values => ({ ...values, [name]: value }));
  };

  const handleSwitchChange = e => {
    const { name } = e.target;
    setValues(values => ({ ...values, [name]: e.target.checked }));
  };

  const handleChange = e => {
    const { name } = e.target;
    const { value } = e.target;
    setValues(values => ({ ...values, [name]: value }));
  };

  return (
    <Drawer
      anchor='right'
      open={filterFormVar}
      onClose={handleClose}
      classes={{ paper: 'w-full sm:w-1/3 z-[1000]', root: 'z-[1299]' }}
    >
      <div className='flex items-center justify-between mt-3 mb-1 mx-2'>
        <Typography variant='h6'>Filtrar Información</Typography>
        <IconButton aria-label='close' onClick={handleClose} size='medium'>
          <CloseIcon fontSize='inherit' />
        </IconButton>
      </div>
      <Divider />
      <div className='flex flex-1 flex-col overflow-auto'>
        {(props.showDates || props.showOneDate || props.optionalDates) && (
          <div className='flex flex-col'>
            <div className='p-3'>
              {props.optionalDates && (
                <>
                  <Typography variant='subtitle1'>Usar Fechas</Typography>
                  <Switch checked={values.useDates} onChange={handleSwitchChange} name='useDates' />
                </>
              )}
              {((props.showDates && values.useDates) || props.showDates) && (
                <div>
                  <Typography variant='subtitle1'>Rango de fechas:</Typography>
                  <Grid container spacing={1}>
                    <Grid item xs={12} md={6}>
                      <DateField
                        variant='outlined'
                        name='startDate'
                        value={values.startDate}
                        onChange={handleDateChange}
                        noFormat
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <DateField
                        variant='outlined'
                        name='endDate'
                        value={values.endDate}
                        onChange={handleDateChange}
                        noFormat
                      />
                    </Grid>
                  </Grid>
                </div>
              )}
              {((props.showOneDate && values.useDates) || props.showOneDate) && (
                <div>
                  <Typography variant='subtitle1'>Fecha:</Typography>
                  <Grid container spacing={1}>
                    <Grid item xs={12} md={12}>
                      <DateField
                        variant='outlined'
                        name='date'
                        value={values.date}
                        onChange={handleDateChange}
                        noFormat
                      />
                    </Grid>
                  </Grid>
                </div>
              )}
            </div>
            <Divider />
          </div>
        )}
        {props.showBranches && branches.length > 0 && (
          <div className='flex flex-col'>
            <div className='p-3'>
              <Typography variant='subtitle1'>Sucursales:</Typography>
              <SelectField
                name='branchId'
                variant='outlined'
                value={values.branchId}
                onChange={handleChange}
                options={branches}
                noFormat
              />
            </div>
            <Divider />
          </div>
        )}
        <div className='flex flex-1 flex-col p-3'>{props.children}</div>
      </div>
      <Divider />
      <div className='p-4'>
        <Button
          label='Aplicar Filtro'
          size='large'
          color='secondary'
          variant='outlined'
          onClick={handleClick}
        />
      </div>
    </Drawer>
  );
};

export default FilterDrawer;
