import React, { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import DateRangePaymentFormsFieldComponent from './PaymentForms';
import getDefaultPaymentFormSalesGlobalInvoice from './helpers/getDefaultPaymentFormSalesGlobalInvoice';
var PaymentFormSalesGlobalInvoiceFieldComponent = forwardRef(function (props, ref) {
    var defaultPaymentForm = getDefaultPaymentFormSalesGlobalInvoice();
    var paymentFormsSalesGlobalInvoiceFieldRef = useRef(null);
    var _a = useState(defaultPaymentForm), paymentForm = _a[0], setPaymentForm = _a[1];
    var handleClick = function (event) {
        var _a;
        if (props.disabled)
            return;
        (_a = paymentFormsSalesGlobalInvoiceFieldRef.current) === null || _a === void 0 ? void 0 : _a.open(event.currentTarget);
    };
    var handleChange = function (name, value) {
        setPaymentForm({ name: name, value: value });
    };
    var getPaymentForm = function () { return paymentForm; };
    useImperativeHandle(ref, function () { return ({
        getPaymentForm: getPaymentForm,
    }); });
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { role: 'button', tabIndex: -1, onClick: handleClick, className: 'flex flex-col justify-center cursor-pointer hover:bg-[#ececec] hover:rounded px-2 py-1 sm:ml-2' },
            React.createElement("div", { className: 'text-xs text-gray-600 leading-none tracking-tighter' }, "Forma de pago"),
            React.createElement("div", { className: 'leading-none tracking-tighter text-gray-800' }, paymentForm.name)),
        React.createElement(DateRangePaymentFormsFieldComponent, { ref: paymentFormsSalesGlobalInvoiceFieldRef, onChange: handleChange })));
});
export default PaymentFormSalesGlobalInvoiceFieldComponent;
