import React from 'react';

import { useReactiveVar } from '@apollo/client';

import { editPurchaseOrderItemForm as setValues, inputError } from '../../../../../../apollo/cache';

import TextField from '../../../../../../presentationals/FormComponents/TextField';

const BodyItemEditFormProductComponent = () => {
  const values = useReactiveVar(setValues);
  const errors = useReactiveVar(inputError);

  const handleChange = (name, value) => {
    setValues({ ...values, [name]: value });
  };

  return (
    <div className='px-4 pb-4 flex-1 grid grid-cols-4'>
      <div>
        <TextField
          label='Cantidad'
          name='qty'
          onChange={handleChange}
          value={values.qty}
          error={errors.qty}
          type='number'
        />
        <TextField
          label='Descuento'
          name='discount'
          onChange={handleChange}
          value={values.discount}
          error={errors.discount}
          type='number'
        />
        <TextField
          label='Precio unitario'
          name='price'
          onChange={handleChange}
          value={values.price}
          error={errors.price}
          type='number'
        />
      </div>
    </div>
  );
};

export default BodyItemEditFormProductComponent;
