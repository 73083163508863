import React from 'react';
import CreateCashFlowLayoutComponent from './CashFlow/CreateCashFlowLayout';
import CashFlowComponent from '../containers/cashFlow';
import CashFlowLayoutComponent from './CashFlow/CashFlowLayout';
var getCashFlowChildrenRoutes = function () {
    return [
        { index: true, element: React.createElement(CashFlowComponent, null) },
        {
            path: 'create',
            element: React.createElement(CreateCashFlowLayoutComponent, null),
        },
        {
            path: ':cashFlowId',
            element: React.createElement(CashFlowLayoutComponent, null),
        },
    ];
};
export default getCashFlowChildrenRoutes;
