import { useReactiveVar } from '@apollo/client';
import React from 'react';
import { productSaleV2 } from '../../../../../apollo/cache';
var PresentationDescriptionEditProductComponent = function () {
    var productSaleV2Var = useReactiveVar(productSaleV2);
    return (React.createElement("div", { className: 'text-sm sm:text-base text-gray-600' },
        "Al hablar de presentaciones del producto, nos referimos a las distintas opciones de empaque, tama\u00F1o, cantidad o formato en el que el producto puede ser ofrecido al consumidor o adquirido de un proveedor.",
        ' ',
        ['2', '3', '6'].includes(productSaleV2Var.type)
            ? 'Las presentaciones pueden estar compuestas de insumos / materia prima.'
            : undefined));
};
export default PresentationDescriptionEditProductComponent;
