var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useEffect, useState } from 'react';
import EqualizerIcon from '@mui/icons-material/EqualizerOutlined';
import ListItemPermissionEditRoleComponent from '../helpers/ListItem';
import SwitchPermissionFieldComponent from '../helpers/SwitchPermissionField';
import getDefaultBranchOptions from '../helpers/getDefaultBrachOptions';
import SelectPermissionFieldComponent from '../helpers/SelectPermissionField';
var defaultCashClosingPermissionValues = {
    generalSaleReportDayFilter: false,
    generalSaleReport: 0,
};
var GeneralSaleReportPermissionEditRoleComponent = function (props) {
    var defaultBranchOptions = getDefaultBranchOptions();
    var _a = useState(defaultCashClosingPermissionValues), values = _a[0], setValues = _a[1];
    useEffect(function () {
        setValues(function (values) { return (__assign(__assign({}, values), props.Permissions)); });
    }, [props.Permissions]);
    return (React.createElement(ListItemPermissionEditRoleComponent, { icon: React.createElement(EqualizerIcon, null), name: 'Informes, gr\u00E1ficas y estad\u00EDsticas' },
        React.createElement("div", { className: 'mx-4 py-3 grid grid-cols-1 md:grid-cols-3 gap-4' },
            React.createElement("div", { className: 'flex flex-col' },
                React.createElement(SelectPermissionFieldComponent, __assign({}, props, { name: 'generalSaleReport', label: 'Consulta de Informes, gr\u00E1ficas y estad\u00EDsticas', defaultValue: values.generalSaleReport, options: defaultBranchOptions })),
                React.createElement(SwitchPermissionFieldComponent, __assign({}, props, { name: 'generalSaleReportDayFilter', label: 'Permitir consultas en cualquier rango de fechas', defaultValue: values.generalSaleReportDayFilter, disabled: values.generalSaleReport === 0 }))))));
};
export default GeneralSaleReportPermissionEditRoleComponent;
