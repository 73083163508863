import React, { useEffect, useState } from 'react';
import { TableCell, TableRow } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import convertToCurrencyLabel from '../../../../helpers/convertToCurrencyLabel';
import getAccountReceivableBackgroundColor from './helpers/getBackgroundColor';
import AccountReceivableRowComponent from './AccountReceivableRow';
var ItemBodyReceivableComponent = function (props) {
    var _a;
    var navigate = useNavigate();
    var _b = useState(null), background = _b[0], setBackground = _b[1];
    useEffect(function () {
        var background = getAccountReceivableBackgroundColor(props.CustomerAccountReveivable.background);
        setBackground(background);
    }, [props.CustomerAccountReveivable.background]);
    var handleClick = function () {
        navigate("../customer/".concat(props.CustomerAccountReveivable.id, "/receivable"));
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(TableRow, { onClick: handleClick },
            React.createElement(TableCell, { className: background || (props.index % 2 === 0 ? 'bg-slate-100' : '') }, props.CustomerAccountReveivable.name),
            React.createElement(TableCell, { className: "".concat(background || (props.index % 2 === 0 ? 'bg-slate-200' : 'bg-slate-100'), " font-semibold border-t-slate-800 text-right") }, "$".concat(convertToCurrencyLabel(props.CustomerAccountReveivable.total))),
            React.createElement(TableCell, { className: "".concat(background || (props.index % 2 === 0 ? 'bg-slate-200' : 'bg-slate-100'), " font-semibold border-t-slate-800 text-right") }, "$".concat(convertToCurrencyLabel(props.CustomerAccountReveivable.payment))),
            React.createElement(TableCell, { className: "".concat(background || (props.index % 2 === 0 ? 'bg-slate-200' : 'bg-slate-100'), " font-semibold border-t-slate-800 text-right") }, "$".concat(convertToCurrencyLabel(props.CustomerAccountReveivable.balance)))),
        ((_a = props.CustomerAccountReveivable) === null || _a === void 0 ? void 0 : _a.AccountReceivables) &&
            props.CustomerAccountReveivable.AccountReceivables.map(function (elCh) { return (React.createElement(AccountReceivableRowComponent, { customerId: props.CustomerAccountReveivable.id, accountReceivableId: elCh.id, key: "account-receivable-".concat(elCh.id) },
                React.createElement(TableCell, { className: "".concat(background || (props.index % 2 === 0 ? 'bg-slate-100' : ''), " pl-10") }, elCh.folio),
                React.createElement(TableCell, { className: "".concat(background || (props.index % 2 === 0 ? 'bg-slate-200' : 'bg-slate-100'), " font-medium border-t-slate-800 text-right") }, "$".concat(convertToCurrencyLabel(elCh.total))),
                React.createElement(TableCell, { className: "".concat(background || (props.index % 2 === 0 ? 'bg-slate-200' : 'bg-slate-100'), " font-medium border-t-slate-800 text-right") }, "$".concat(convertToCurrencyLabel(elCh.payment))),
                React.createElement(TableCell, { className: "".concat(background || (props.index % 2 === 0 ? 'bg-slate-200' : 'bg-slate-100'), " font-medium border-t-slate-800 text-right") }, "$".concat(convertToCurrencyLabel(elCh.balance))))); })));
};
export default ItemBodyReceivableComponent;
