import React from 'react';
import CreateEmployeeLayoutComponent from './Employee/CreateEmployeeLayout';
import EmployeeComponent from '../containers/employee';
import EmployeeLayoutComponent from './Employee/EmployeeLayout';
import GeneralEditEmployeeComponent from '../containers/employee/Edit/General';
import WorkingHoursEditEmployeeComponent from '../containers/employee/Edit/WorkingHours';
import UpdatePasswordEditEmployeeComponent from '../containers/employee/Edit/UpdatePassword';
var getEmployeeChildrenRoutes = function () {
    return [
        { index: true, element: React.createElement(EmployeeComponent, null) },
        {
            path: 'create',
            element: React.createElement(CreateEmployeeLayoutComponent, null),
        },
        {
            path: ':employeeId',
            element: React.createElement(EmployeeLayoutComponent, null),
            children: [
                { index: true, element: React.createElement(GeneralEditEmployeeComponent, null) },
                { path: 'workingHours', element: React.createElement(WorkingHoursEditEmployeeComponent, null) },
                { path: 'password', element: React.createElement(UpdatePasswordEditEmployeeComponent, null) },
            ],
        },
    ];
};
export default getEmployeeChildrenRoutes;
