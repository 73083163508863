import { gql } from '@apollo/client';

const FRAGMENT_NEW_MAINTENANCE = gql`
  fragment NewMaintenance on Maintenance {
    id
    type
    subtype
    status
    deliveryStatus
    serial
    folio
    commentary
    currencyKey
    exchangeRate
    shipping
    subtotal
    discount
    taxes
    total
    customerId
    code
    sellerId
    cashierId
    deliveryManId
    commission
    Sale {
      invoiceId
    }
    AccountReceivable {
      id
      total
      payment
      balance
      Sale {
        id
        Invoice {
          uuid
        }
      }
    }
    Customer {
      firstName
      lastName
    }
    createdAt
    clientCreatedAt
    usdTotal
    expenses
    pdfFileId
    folioSale
  }
`;

const GET = gql`
  ${FRAGMENT_NEW_MAINTENANCE}
  query Maintenances(
    $limit: Int
    $offset: Int
    $order: [String]
    $name: String
    $start: Date
    $end: Date
    $customerId: ID
  ) {
    maintenances(
      limit: $limit
      offset: $offset
      order: $order
      like: $name
      start: $start
      end: $end
      customerId: $customerId
    ) {
      count
      rows {
        ...NewMaintenance
      }
    }
  }
`;

const GET_BY_ID = gql`
  query Maintenance($id: ID!) {
    maintenance(id: $id) {
      id
      type
      subtype
      status
      deliveryStatus
      serial
      folio
      commentary
      currencyKey
      exchangeRate
      shipping
      subtotal
      discount
      taxes
      total
      customerId
      code
      sellerId
      cashierId
      deliveryManId
      commission
      cost
      Customer {
        firstName
        lastName
      }
      createdAt
      clientCreatedAt
      usdTotal
      expenses
      pdfFileId
      AccountReceivable {
        id
        total
        balance
      }
      MaintenanceExpenses {
        id
        name
        quantity
        unitPrice
        withoutTaxes
        subtotal
        discount
        taxes
        total
        outDate
        Product {
          name
        }
      }
      MaintenanceProducts {
        id
        name
        productId
        quantity
        unitPrice
        withoutTaxes
        subtotal
        discount
        taxes
        total
        priceName
        commentary
        cost
        iva
        ieps
        Product {
          name
          unitKey
          productKey
        }
        ProductPresentation {
          name
        }
        clientCreatedAt
        deletedAt
        outDate
        saleStepId
        SaleStep {
          name
        }
      }
    }
  }
`;

const ADD_PRODUCT_MAINTENANCE = gql`
  mutation AddProductMaintenance(
    $id: ID!
    $productId: ID
    $quantity: Float!
    $discount: Float
    $price: Float
    $iva: Float
    $ieps: Float
    $saleStepId: ID
    $name: String
  ) {
    addProductMaintenance(
      id: $id
      Product: {
        productId: $productId
        quantity: $quantity
        discount: $discount
        price: $price
        iva: $iva
        ieps: $ieps
        saleStepId: $saleStepId
        name: $name
      }
    ) {
      id
      name
      productId
      quantity
      unitPrice
      withoutTaxes
      subtotal
      discount
      commentary
      taxes
      total
      cost
    }
  }
`;

const UPDATE_MAINTENANCE_PRODUCT = gql`
  mutation UpdateMaintenanceProduct(
    $id: ID!
    $maintenanceId: ID!
    $productId: ID
    $quantity: Float!
    $price: Float
    $outDate: Date
    $pin: String
  ) {
    updateMaintenanceProduct(
      id: $id
      maintenanceId: $maintenanceId
      pin: $pin
      input: { productId: $productId, quantity: $quantity, price: $price, outDate: $outDate }
    ) {
      id
      productId
      quantity
      unitPrice
      withoutTaxes
      subtotal
      discount
      commentary
      taxes
      total
      outDate
    }
  }
`;

const DELETE_PRODUCT = gql`
  mutation DeleteMaintenanceProduct($id: ID!) {
    deleteMaintenanceProduct(id: $id) {
      id
      subtotal
      taxes
      total
    }
  }
`;

const FRAGMENT_NEW_MAINTENANCE_PRODUCT = gql`
  fragment NewMaintenanceProduct on MaintenanceProducts {
    id
    name
    productId
    quantity
    unitPrice
    withoutTaxes
    subtotal
    discount
    commentary
    taxes
    total
  }
`;

const SEND_TO_PRODUCTION = gql`
  mutation ProductionMaintenance($id: ID!) {
    productionMaintenance(id: $id) {
      id
      type
      subtype
      status
      deliveryStatus
      serial
      folio
      commentary
      currencyKey
      exchangeRate
      shipping
      subtotal
      discount
      taxes
      total
      customerId
      code
      sellerId
      cashierId
      deliveryManId
      commission
      Customer {
        firstName
        lastName
      }
      createdAt
      clientCreatedAt
      usdTotal
      expenses
      pdfFileId
      AccountReceivable {
        id
        status
        type
        paid
        folio
        serial
        description
        subtotal
        discount
        taxes
        total
        payment
        balance
        clientCreatedAt
      }
    }
  }
`;

const UPDATE_MAINTENANCE_COMMENTARY = gql`
  mutation UpdateMaintenanceCommentary($id: ID!, $commentary: String!) {
    updateMaintenanceCommentary(id: $id, commentary: $commentary) {
      id
      commentary
    }
  }
`;

const UPDATE_MAINTENANCE_PRODUCT_COMMENTARY = gql`
  mutation UpdateMaintenanceProductCommentary($id: ID!, $commentary: String!) {
    updateMaintenanceProductCommentary(id: $id, commentary: $commentary) {
      id
      commentary
    }
  }
`;

const CREATE_MAINTENANCE_EXPENSE = gql`
  mutation CreateMaintenanceExpense(
    $maintenanceId: ID!
    $name: String!
    $quantity: Float!
    $price: Float!
    $discount: Float
    $iva: Float
    $ieps: Float
    $outDate: Date
    $type: Int
    $productId: ID
  ) {
    createMaintenanceExpense(
      maintenanceId: $maintenanceId
      input: {
        name: $name
        quantity: $quantity
        price: $price
        discount: $discount
        iva: $iva
        ieps: $ieps
        outDate: $outDate
        type: $type
        productId: $productId
      }
    ) {
      id
      name
      quantity
      unitPrice
      withoutTaxes
      subtotal
      discount
      taxes
      total
      outDate
      Product {
        name
      }
    }
  }
`;

const DELETE_MAINTENANCE_EXPENSE = gql`
  mutation DeleteMaintenanceExpense($id: ID!) {
    deleteMaintenanceExpense(id: $id) {
      id
      name
      quantity
      unitPrice
      withoutTaxes
      subtotal
      discount
      taxes
      total
      outDate
    }
  }
`;

const CLOSE_MAINTENANCE = gql`
  mutation CloseMaintenance($id: ID!, $Transactions: [TransactionSaleData]) {
    closeMaintenance(input: { id: $id, Transactions: $Transactions }) {
      id
      Sale {
        id
        serial
        paid
        folio
        subtotal
        discount
        taxes
        total
        category
        pdfFileId
        Customer {
          id
          firstName
          lastName
          addressLine1
          addressLine2
          zipCode
          city
          state
          phone
          priceName
        }
        AccountReceivable {
          id
          folio
          serial
          payment
          balance
        }
      }
    }
  }
`;

const CLOSE_MAINTENANCE_INVOICE = gql`
  mutation CloseMaintenanceInvoice(
    $id: ID!
    $Transactions: [TransactionSaleData]
    $paymentMethod: String!
    $paymentMethodId: ID
    $useCfdi: String!
    $identifier: String!
    $businessName: String!
    $addressLine1: String!
    $addressLine2: String!
    $zipCode: String!
    $city: String!
    $municipality: String!
    $state: String!
    $country: String!
    $email: String!
    $phone: String
    $relateUUID: String
    $relateType: String
    $Products: [ProductInvoice]
  ) {
    closeMaintenanceInvoice(
      input: {
        id: $id
        Transactions: $Transactions
        paymentMethod: $paymentMethod
        paymentMethodId: $paymentMethodId
        useCfdi: $useCfdi
        relateUUID: $relateUUID
        relateType: $relateType
        Products: $Products
        CustomerBillingInformation: {
          identifier: $identifier
          businessName: $businessName
          addressLine1: $addressLine1
          addressLine2: $addressLine2
          zipCode: $zipCode
          city: $city
          municipality: $municipality
          state: $state
          country: $country
          email: $email
          phone: $phone
        }
      }
    ) {
      id
      Sale {
        id
        serial
        paid
        folio
        subtotal
        discount
        taxes
        total
        category
        pdfFileId
        Customer {
          id
          firstName
          lastName
          addressLine1
          addressLine2
          zipCode
          city
          state
          phone
          priceName
        }
        AccountReceivable {
          id
          folio
          serial
          payment
          balance
        }
        Invoice {
          uuid
        }
      }
    }
  }
`;

const GET_MAINTENANCE_EXPENSES = gql`
  query MaintenanceExpenses($maintenanceId: ID!, $limit: Int) {
    maintenanceExpenses(maintenanceId: $maintenanceId, limit: $limit) {
      count
      rows {
        id
        name
        quantity
        unitPrice
        withoutTaxes
        subtotal
        discount
        taxes
        total
        outDate
        Product {
          name
        }
      }
    }
  }
`;

const FRAGMENT_NEW_MAINTENANCE_EXPENSE_PRODUCT = gql`
  fragment NewMaintenanceExpense on MaintenanceExpense {
    id
    name
    quantity
    unitPrice
    withoutTaxes
    subtotal
    discount
    taxes
    total
    outDate
  }
`;

const CLONE_MAINTENANCE_SALE = gql`
  mutation CloneMaintenanceSaleInvoice(
    $id: ID!
    $commentary: String
    $Transactions: [TransactionSaleData]
    $paymentMethod: String
    $paymentMethodId: ID
    $paymentForm: String
    $useCfdi: String
    $relateUUID: String
    $relateType: String
    $ProductsInvoice: [ProductInvoice]
    $applyExtraTaxes: Boolean
    $isInvoice: Boolean
    $limitDays: Int
  ) {
    cloneMaintenanceSale(
      id: $id
      input: {
        commentary: $commentary
        Transactions: $Transactions
        paymentMethod: $paymentMethod
        paymentMethodId: $paymentMethodId
        useCfdi: $useCfdi
        relateUUID: $relateUUID
        relateType: $relateType
        isInvoice: $isInvoice
        applyExtraTaxes: $applyExtraTaxes
        paymentForm: $paymentForm
        ProductsInvoice: $ProductsInvoice
        limitDays: $limitDays
      }
    ) {
      id
      serial
      Sale {
        id
        serial
        paid
        folio
        subtotal
        discount
        taxes
        total
        category
        pdfFileId
        currencyKey
        exchangeRate
        shipping
        customerId
        code
        sellerId
        cashierId
        deliveryManId
        commission
        invoiceId
        createdAt
        clientCreatedAt
        usdTotal
        expenses
        pdfFileId
        Transactions {
          id
          total
          paymentMethodId
          clientCreatedAt
          createdAt
          currency
          exchangeRate
          usdTotal
          transactionAccountId
          invoiceComplementId
          commentary
          TransactionAccount {
            id
            name
          }
          PaymentMethod {
            id
            name
          }
        }
        Customer {
          id
          firstName
          lastName
          addressLine1
          addressLine2
          zipCode
          city
          state
          phone
          priceName
        }
        AccountReceivable {
          id
          folio
          serial
          payment
          balance
          total
        }
        Invoice {
          uuid
          serial
        }
      }
    }
  }
`;

const GET_XLS_DATA = gql`
  query Maintenances($limit: Int, $start: Date, $end: Date) {
    maintenances(limit: $limit, start: $start, end: $end) {
      count
      rows {
        id
        status
        serial
        folio
        folioSale
        commentary
        expenses
        Customer {
          firstName
          lastName
        }
        createdAt
        MaintenanceExpenses {
          id
          name
          quantity
          withoutTaxes
          subtotal
          discount
          taxes
          total
          outDate
          Product {
            name
          }
        }
        AccountReceivable {
          id
          total
          payment
          balance
        }
      }
    }
  }
`;

const CANCEL_MAINTENANCE = gql`
  mutation CancelMaintenance(
    $id: ID!
    $userPassword: String!
    $reasonCancel: Int
    $commentary: String
    $reason: String
    $relatedFolio: String
  ) {
    cancelMaintenance(
      id: $id
      input: {
        userPassword: $userPassword
        reasonCancel: $reasonCancel
        commentary: $commentary
        reason: $reason
        relatedFolio: $relatedFolio
      }
    ) {
      id
    }
  }
`;

export default {
  GET,
  GET_BY_ID,
  ADD_PRODUCT_MAINTENANCE,
  UPDATE_MAINTENANCE_PRODUCT,
  DELETE_PRODUCT,
  FRAGMENT_NEW_MAINTENANCE_PRODUCT,
  SEND_TO_PRODUCTION,
  UPDATE_MAINTENANCE_COMMENTARY,
  UPDATE_MAINTENANCE_PRODUCT_COMMENTARY,
  CREATE_MAINTENANCE_EXPENSE,
  DELETE_MAINTENANCE_EXPENSE,
  GET_MAINTENANCE_EXPENSES,
  FRAGMENT_NEW_MAINTENANCE_EXPENSE_PRODUCT,
  CLOSE_MAINTENANCE,
  CLOSE_MAINTENANCE_INVOICE,
  CLONE_MAINTENANCE_SALE,
  GET_XLS_DATA,
  CANCEL_MAINTENANCE,
  FRAGMENT_NEW_MAINTENANCE,
};
