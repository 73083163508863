import React, { useState, useEffect } from 'react';

import { useReactiveVar } from '@apollo/client';

import { customerSaleV2, isSaleCustomerFormOpen } from '../../../../../apollo/cache';
import SubtitleButtonActionSaleV2Component from '../../helpers/Button/subtitle';
import useGetSession from '../../../../../hooks/useGetSession';

const ButtonCustomerActionSaleV2Component = () => {
  const customerSaleV2Var = useReactiveVar(customerSaleV2);
  const [saleCustomerLabel, setSaleCustomerLabel] = useState('');
  const session = useGetSession();
  const customerLabel = session?.Company?.activity === 3 ? 'Paciente' : 'Cliente';
  useEffect(() => {
    const newCustomer =
      customerSaleV2Var && customerSaleV2Var.id
        ? `${customerSaleV2Var.firstName} ${customerSaleV2Var.lastName}`.trim()
        : 'Público en General';
    setSaleCustomerLabel(newCustomer);
  }, [customerSaleV2Var]);
  const handleClick = () => {
    isSaleCustomerFormOpen(true);
  };
  return (
    <div
      role='button'
      tabIndex={0}
      onClick={handleClick}
      className='flex border-0 border-r border-solid items-center border-gray-200 px-6 sm:pt-3 sm:pb-5'
    >
      <div className='flex flex-col'>
        <div className='text-sky-600 font-medium'>{customerLabel}</div>
        <SubtitleButtonActionSaleV2Component label={saleCustomerLabel} noLimit />
        {customerSaleV2Var?.phone && (
          <SubtitleButtonActionSaleV2Component label={customerSaleV2Var.phone} />
        )}
        {customerSaleV2Var?.email && (
          <SubtitleButtonActionSaleV2Component label={customerSaleV2Var.email} noLimit />
        )}
      </div>
      {customerSaleV2Var &&
        (customerSaleV2Var.identifier !== '' ||
          customerSaleV2Var.businessName !== '' ||
          customerSaleV2Var.businessZipCode) && (
          <div className='flex flex-col ml-4'>
            <div className='text-sky-600 font-medium'>Datos de facturación</div>
            <SubtitleButtonActionSaleV2Component label={customerSaleV2Var.identifier} />
            <SubtitleButtonActionSaleV2Component label={customerSaleV2Var.businessName} noLimit />
            {customerSaleV2Var.businessZipCode && (
              <SubtitleButtonActionSaleV2Component
                label={`CP ${customerSaleV2Var.businessZipCode}`}
                noLimit
              />
            )}
          </div>
        )}
    </div>
  );
};

export default ButtonCustomerActionSaleV2Component;
