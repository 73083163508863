var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React, { useEffect, useState } from 'react';
import { useReactiveVar } from '@apollo/client';
import { Popover } from '@mui/material';
import { moduleMoreOptionsItems, exportXls, exportPdf } from '../../../../../apollo/cache';
import Item from './Item';
var PopoverMenuHeaderModuleV3Component = function (props) {
    var CacheItems = useReactiveVar(moduleMoreOptionsItems);
    var _a = useState([]), Items = _a[0], setItems = _a[1];
    useEffect(function () {
        var defaultItems = [
            {
                label: 'Exportar pdf',
                imageSrc: 'https://storage.capitalike.com/files/23184',
                onClick: function () {
                    exportPdf({ export: true });
                },
            },
            {
                label: 'Exportar xlsx',
                imageSrc: 'https://storage.capitalike.com/files/23185',
                onClick: function () {
                    exportXls({ export: true });
                },
            },
        ].filter(function (el) { return el; });
        setItems(__spreadArray(__spreadArray([], defaultItems, true), CacheItems, true));
    }, [CacheItems]);
    return (React.createElement(Popover, { id: props.id, open: props.open, anchorEl: props.anchorEl, onClose: props.onClose, anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'right',
        }, transformOrigin: {
            vertical: 'top',
            horizontal: 'right',
        }, slotProps: {
            paper: {
                className: "p-4 w-3/4 ".concat(Items.length > 4 ? 'md:w-1/3' : 'md:w-1/6'),
            },
        } },
        React.createElement("div", { className: "grid ".concat(Items.length > 4 ? 'grid-cols-4' : "grid-cols-".concat(Items.length), " gap-2") }, Items.map(function (el, i) { return (React.createElement(Item, __assign({ key: i }, el))); }))));
};
export default PopoverMenuHeaderModuleV3Component;
