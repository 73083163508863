import React, { useEffect } from 'react';
import { useReactiveVar } from '@apollo/client';
import { LinearProgress } from '@mui/material';
import { isSaleBranchFormOpen, branchSaleV2 } from '../../../../../apollo/cache';
import Dialog from '../../../../../presentationals/Dialog';
import getSession from '../../../../../helpers/getSession';
import Body from './Body';
import Footer from './Footer';
import useApolloQuery from '../../../../../hooks/useApolloQuery';
import GQL_BRANCH from '../../../../../apollo/gql/branch';
var FormBranchActionSaleV2Component = function () {
    var isSaleBranchFormOpenVar = useReactiveVar(isSaleBranchFormOpen);
    var _a = useApolloQuery(GQL_BRANCH.GET_BRANCHES, {
        variables: { limit: 0, offset: 0 },
    }), data = _a.data, loading = _a.loading;
    useEffect(function () {
        var session = getSession();
        if (data && data.rows && (session === null || session === void 0 ? void 0 : session.Company)) {
            var branchId_1 = session.Company.Branch.id;
            var isAvailable = data.rows.find(function (el) { return parseInt(el.id.toString()) === parseInt(branchId_1.toString()); });
            if (isAvailable)
                branchSaleV2({
                    branchSale: branchId_1,
                    branchInventory: branchId_1,
                });
        }
    }, [data]);
    var handleClose = function () {
        isSaleBranchFormOpen(false);
    };
    return (React.createElement(Dialog, { fullScreen: true, title: 'Configuraci\u00F3n de la sucursal', onClose: handleClose, open: isSaleBranchFormOpenVar },
        loading ? React.createElement(LinearProgress, { color: 'secondary' }) : React.createElement("div", null),
        data ? React.createElement(Body, { Branches: data.rows }) : React.createElement("div", null),
        React.createElement(Footer, null)));
};
export default FormBranchActionSaleV2Component;
