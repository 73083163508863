import React from 'react';
import ModuleV3Component from '../../presentationals/Module.v3';
import useGetSaleByEmployee from '../../hooks/useGetSaleByEmployee';
import FilterSaleEmployeesDialog from './Filter';
import getEmployeeSalesReportActions from './helpers/actions';
var EmployeeSalesReportComponent = function () {
    var _a = useGetSaleByEmployee(), data = _a.data, loading = _a.loading;
    var employeeSalesReportActions = getEmployeeSalesReportActions();
    var header = [
        { Header: 'Empleado', accessor: 'name' },
        { Header: '#Ventas', accessor: 'count' },
        { Header: 'Total', accessor: 'total' },
    ];
    return (React.createElement(React.Fragment, null,
        React.createElement(ModuleV3Component, { showReturnButton: true, title: 'Ventas por empleado', data: data, header: header, isLoading: loading, actions: employeeSalesReportActions, hideAdd: true, filter: true }),
        React.createElement(FilterSaleEmployeesDialog, null)));
};
export default EmployeeSalesReportComponent;
