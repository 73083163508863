import { gql } from '@apollo/client';

const GET = gql`
  query CustomerSales(
    $name: String
    $limit: Int
    $offset: Int
    $start: Date
    $end: Date
    $branches: [ID]
    $customerId: ID
  ) {
    customerSales(
      like: $name
      limit: $limit
      offset: $offset
      start: $start
      end: $end
      branches: $branches
      customerId: $customerId
    ) {
      count
      rows {
        id
        Customer {
          firstName
          lastName
        }
        Sales {
          Branch {
            id
            name
          }
          quantity
          total
        }
        invoiceId
        Invoice {
          uuid
        }
      }
      totals {
        Branch {
          id
          name
        }
        quantity
        total
      }
    }
  }
`;

const GET_V2 = gql`
  query SalesByCustomer(
    $name: String
    $limit: Int
    $offset: Int
    $start: Date
    $end: Date
    $branchId: ID
    $paid: Boolean
  ) {
    salesByCustomer(
      like: $name
      limit: $limit
      offset: $offset
      start: $start
      end: $end
      branchId: $branchId
      paid: $paid
    ) {
      count
      rows {
        id
        firstName
        lastName
        quantity
        total
        discount
        taxes
        Sales {
          id
          serial
          folio
          paid
          currencyKey
          exchangeRate
          subtotal
          discount
          taxes
          total
          folio2
          clientCreatedAt
          invoiceId
          Invoice {
            uuid
          }
        }
      }
    }
  }
`;

export default { GET, GET_V2 };
