import React from 'react';

import Container from '../../presentationals/Module.v2/Container';

import GQL from './_gql';
import Filter from './_filter';
import Action from './_action';

const companiesData = localStorage.getItem('companies')
  ? JSON.parse(localStorage.getItem('companies'))
  : [];
const companyId = companiesData.length > 0 ? parseInt(companiesData[0].companyId) : null;

const commentaryColumn = ![85].includes(companyId)
  ? [{ label: 'Comentario', key: 'commentary', sort: 'text', wrap: true }]
  : [];
const Rows = [
  { label: 'Cliente', key: 'Customer firstName && Customer lastName', sort: 'text' },
  { label: 'Estatus', key: 'status', sort: 'text', format: 'quoteStatus' },
  { label: 'Folio', key: 'serial', prefix: 'C', padStart: [5, '0'], strcat: '-' },
  ...commentaryColumn,
  { label: 'Fecha de Creación', key: 'createdAt', sort: 'text', format: 'dateTime' },
  { label: 'Expiración', key: 'expireDate', sort: 'text', format: 'date' },
  { label: 'Subtotal', key: 'subtotal', format: 'currency', sort: 'number' },
  { label: 'Descuento', key: 'discount', format: 'currency', sort: 'number' },
  { label: 'IVA', key: 'taxes', format: 'currency', sort: 'number' },
  { label: 'Total', key: 'total', format: 'currency', sort: 'number' },
  { label: 'Comisión', key: 'commission', format: 'currency', sort: 'number' },
  { label: 'Cajero', key: 'Cashier firstName && Cashier lastName', sort: 'text' },
];
const title = 'Seguimiento de cotizaciones';
const operation = ['quote', 'quotes'];

const ProductComponent = () => {
  return (
    <>
      <Container
        title={title}
        operation={operation}
        Rows={Rows}
        get={GQL.GET}
        noDelete
        noEdit
        noCreate
        filter
      />
      <Filter />
      <Action />
    </>
  );
};

export default ProductComponent;
