import { gql } from '@apollo/client';

const GET = gql`
	query TransferProductDetails($order: [String], $name: String, $sku: String, $limit: Int, $offset: Int, $start: Date, $end: Date, $branchTransferId: ID, $productId: ID) {
		transferProductDetails(order: $order, like: $name, sku: $sku, limit: $limit, offset: $offset,start: $start, end: $end, branchTransferId: $branchTransferId, productId: $productId) {
			count
			rows {
				id
				quantity
				total
				createdAt
				Product {
					name
					sku
					code
					Classification {
						name
					}
				}
				Transfer {
					id
					folio
					serial
					Branch {
						name
					}
					BranchTransfer {
						name
					}
					Employee {
						firstName
						lastName
					}
				}
			}
		}
	}
`;

export default { GET };
