var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useEffect, useState } from 'react';
import { useReactiveVar } from '@apollo/client';
import CategoryIcon from '@mui/icons-material/Category';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import Inventory2Icon from '@mui/icons-material/Inventory2';
import { List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import { isItemDetailDialogOpen, cartItems, discountTypeSaleV2, } from '../../../../../../apollo/cache';
import LoadingProgress from '../../../../../../presentationals/LoadingProgress';
import CONFIG from '../../../../../../config/config.json';
import Title from '../../../../Action/Customer/Form/Create/Body/helpers/title';
import getInputErrorFormat from '../../../../Action/helpers/getInputErrorFormat';
import { currencyFormat } from '../../../../../../helpers';
import TaxesForm from './TaxesForm';
import CartForm from './CartForm';
import History from './History';
import Rates from './Rates';
import useApolloLazyQuery from '../../../../../../hooks/useApolloLazyQuery';
import ErrorDataComponent from '../../../../../../presentationals/ErrorData';
import GQL_PRODUCT from '../../../../../../apollo/gql/product';
var BodyItemDetailDialogCartSaleV2Component = function () {
    var index = useReactiveVar(isItemDetailDialogOpen);
    var cartItemsVar = useReactiveVar(cartItems);
    var discountTypeSaleV2Var = discountTypeSaleV2();
    var _a = useApolloLazyQuery(GQL_PRODUCT.GET_PRODUCT_BY_ID), getData = _a[0], _b = _a[1], loading = _b.loading, data = _b.data;
    var _c = useState({}), item = _c[0], setItem = _c[1];
    useEffect(function () {
        var f = function () { return __awaiter(void 0, void 0, void 0, function () {
            var currentItem, data_1, discount, e_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 3, , 4]);
                        if (!(typeof index === 'number' && index >= 0 && cartItemsVar.length > 0)) return [3 /*break*/, 2];
                        currentItem = cartItemsVar.find(function (el, i) { return i === index; });
                        if (!currentItem)
                            return [2 /*return*/, false];
                        return [4 /*yield*/, getData({ variables: { id: currentItem.productId } })];
                    case 1:
                        data_1 = (_a.sent()).data;
                        if (!data_1)
                            throw new Error('No encontramos registros del producto.');
                        discount = parseInt(discountTypeSaleV2Var.toString()) === 1
                            ? parseFloat(currentItem.discountRate.toString())
                            : parseFloat(currentItem.discount.toString());
                        setItem(__assign(__assign(__assign({}, data_1), currentItem), { discount: discount }));
                        _a.label = 2;
                    case 2: return [3 /*break*/, 4];
                    case 3:
                        e_1 = _a.sent();
                        getInputErrorFormat(e_1);
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        }); };
        f();
    }, [index, cartItemsVar]);
    if (loading || Object.keys(item).length === 0)
        return React.createElement(LoadingProgress, null);
    if (!data || typeof index !== 'number')
        return React.createElement(ErrorDataComponent, null);
    return (React.createElement("div", { className: 'flex-1 grid grid-cols-1 md:grid-cols-4 gap-4 px-4 py-3' },
        React.createElement("div", null,
            React.createElement(Title, { label: 'Datos del producto' }),
            item.fileId && (React.createElement("img", { src: "".concat(CONFIG['api-file-service'].url, "/files/").concat(item.fileId), alt: item.name, className: 'w-1/2' })),
            React.createElement(List, null,
                React.createElement(ListItem, null,
                    React.createElement(ListItemIcon, null,
                        React.createElement(CategoryIcon, null)),
                    React.createElement(ListItemText, { primary: item.name, secondary: 'Nombre' })),
                item.sku && (React.createElement(ListItem, null,
                    React.createElement(ListItemIcon, null),
                    React.createElement(ListItemText, { primary: item.sku, secondary: 'C\u00F3digo SKU' }))),
                item.barcode && (React.createElement(ListItem, null,
                    React.createElement(ListItemIcon, null),
                    React.createElement(ListItemText, { primary: item.barcode, secondary: 'C\u00F3digo de barras' }))),
                React.createElement(ListItem, null,
                    React.createElement(ListItemIcon, null,
                        React.createElement(AttachMoneyIcon, null)),
                    React.createElement(ListItemText, { primary: "$".concat(currencyFormat(item.price)), secondary: 'Precio de venta base' })),
                !!item.PresentationBase.cost && (React.createElement(ListItem, null,
                    React.createElement(ListItemIcon, null),
                    React.createElement(ListItemText, { primary: "$".concat(currencyFormat(item.PresentationBase.cost)), secondary: 'Precio de compra' }))),
                React.createElement(ListItem, null,
                    React.createElement(ListItemIcon, null,
                        React.createElement(Inventory2Icon, null)),
                    React.createElement(ListItemText, { primary: "".concat(item.stock, " ").concat(item.PresentationBase.name, "S"), secondary: 'Stock' })))),
        React.createElement("div", null,
            React.createElement(Title, { label: 'Impuestos y facturaci\u00F3n' }),
            React.createElement(TaxesForm, { id: item.productId, iva: data === null || data === void 0 ? void 0 : data.iva, ieps: data === null || data === void 0 ? void 0 : data.ieps, productKey: data === null || data === void 0 ? void 0 : data.productKey, unitKey: data === null || data === void 0 ? void 0 : data.unitKey })),
        React.createElement("div", null,
            React.createElement(Title, { label: 'Carrito de compras' }),
            React.createElement(CartForm, { index: index, quantity: item.quantity, unitPrice: item.unitPrice, discount: item.discount, commentary: item.commentary, employeeId: item.employeeId })),
        React.createElement("div", { className: 'flex flex-1 flex-col' },
            React.createElement("div", { className: 'flex flex-1 flex-col' },
                React.createElement("div", null,
                    React.createElement(Title, { label: 'Tarifas del producto' })),
                React.createElement(Rates, { productId: item.productId, index: index })),
            React.createElement("div", { className: 'flex flex-1 flex-col' },
                React.createElement("div", null,
                    React.createElement(Title, { label: 'Historial de venta' })),
                React.createElement(History, { productId: item.productId, index: index })))));
};
export default BodyItemDetailDialogCartSaleV2Component;
