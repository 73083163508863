import React from 'react';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { IconButton, Tooltip } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { inputError } from '../../apollo/cache';
var GoBackButtonComponent = function (props) {
    var navigate = useNavigate();
    var handleClick = function () {
        typeof props.route === 'number'
            ? navigate(props.route)
            : navigate(props.route || '..', { relative: props.relative || undefined });
        inputError({});
    };
    return (React.createElement(Tooltip, { title: 'Volver a la secci\u00F3n anterior' },
        React.createElement(IconButton, { onClick: handleClick },
            React.createElement(ArrowBackIcon, { className: "".concat(props.darkTheme ? 'text-white' : '') }))));
};
export default GoBackButtonComponent;
