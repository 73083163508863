import React, { useEffect } from 'react';
import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import useApolloLazyQuery from '../../../../hooks/useApolloLazyQuery';
import LoadingProgress from '../../../../presentationals/LoadingProgress';
import ErrorDataComponent from '../../../../presentationals/ErrorData';
import GQL_ADJUST_INVENTORY from '../../../../apollo/gql/adjustInventory';
var BodyItemAdjustInventoryComponent = function (props) {
    var _a = useApolloLazyQuery(GQL_ADJUST_INVENTORY.GET_ADJUST_INVENTORY_BY_ID), getProducts = _a[0], _b = _a[1], loading = _b.loading, data = _b.data;
    useEffect(function () {
        if (props.id)
            getProducts({ variables: { id: props.id } });
    }, [props.id]);
    if (loading)
        return React.createElement(LoadingProgress, null);
    if (!data)
        return React.createElement(ErrorDataComponent, null);
    return (React.createElement(Table, { size: 'small', className: 'mb-4' },
        React.createElement(TableHead, null,
            React.createElement(TableRow, null,
                React.createElement(TableCell, null, "Producto"),
                React.createElement(TableCell, { align: 'center' }, "Cant. Anterior"),
                React.createElement(TableCell, { align: 'center' }, "Cant. Actual"),
                React.createElement(TableCell, { align: 'right' }, "Diferencia"))),
        React.createElement(TableBody, null, data.AdjustInventoryProductV2s.map(function (el) { return (React.createElement(TableRow, { hover: true, key: el.id },
            React.createElement(TableCell, null, el.Product.name),
            React.createElement(TableCell, { align: 'center' }, el.previousQuantity),
            React.createElement(TableCell, { align: 'center' }, el.currentQuantity),
            React.createElement(TableCell, { align: 'right' }, el.currentQuantity - el.previousQuantity))); }))));
};
export default BodyItemAdjustInventoryComponent;
