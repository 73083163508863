import React, { useEffect, useRef, useState } from 'react';
import { useReactiveVar } from '@apollo/client';
import Button from '../helpers/Button';
import GeneralDiscountDialogComponent from './GeneralDiscountDialog';
import { cartItems } from '../../../../apollo/cache';
var ButtonGeneralDiscountActionSaleV2Component = function () {
    var cartItemsVar = useReactiveVar(cartItems);
    var generalDiscountDialogRef = useRef(null);
    var _a = useState('No aplicado'), subtitle = _a[0], setSubtitle = _a[1];
    useEffect(function () {
        if (cartItemsVar) {
            var defaultValue = [null, false];
            var isGeneralDiscount = cartItemsVar.reduce(function (isGeneralDiscount, el, i) {
                if (i === 0)
                    return [el.discountRate, true];
                return isGeneralDiscount[0] === el.discountRate
                    ? isGeneralDiscount
                    : [el.discountRate, false];
            }, defaultValue);
            setSubtitle(!isGeneralDiscount[1] || isGeneralDiscount[0] === 0
                ? 'No aplicado'
                : "".concat(isGeneralDiscount[0], "% dscto."));
        }
    }, [cartItemsVar]);
    var handleClick = function () {
        var _a;
        (_a = generalDiscountDialogRef.current) === null || _a === void 0 ? void 0 : _a.open();
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(Button, { title: 'Dscto. general', subtitle: subtitle, onClick: handleClick }),
        React.createElement(GeneralDiscountDialogComponent, { ref: generalDiscountDialogRef })));
};
export default ButtonGeneralDiscountActionSaleV2Component;
