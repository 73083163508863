import { gql } from '@apollo/client';

const GET = gql`
  query ProductMovementLogs(
    $start: Date
    $end: Date
    $order: [String]
    $limit: Int
    $offset: Int
    $productId: ID
    $name: String
    $branchId: ID
  ) {
    productMovementLogs(
      start: $start
      end: $end
      order: $order
      limit: $limit
      offset: $offset
      productId: $productId
      like: $name
      branchId: $branchId
    ) {
      count
      rows {
        id
        createdAt
        input
        output
        balance
        description
        folio
        Product {
          name
          code
          id
        }
        UnitBase {
          name
        }
        PresentationBase {
          name
        }
      }
    }
  }
`;

export default { GET };
