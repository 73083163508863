import React from 'react';
import AdjustInventoryLayoutComponent from './AdjustInventoryLayout';
import CancelAdjustInventoryComponent from '../containers/inventory.v2/Item/Cancel';
import GeneralAdjustInventoryComponent from '../containers/inventory.v2/Item/General';
import AdjustInventoryComponent from '../containers/inventory.v2';
var getAdjustInventoryChildrenRoutes = function () {
    return [
        { index: true, element: React.createElement(AdjustInventoryComponent, null) },
        // {
        //   path: 'create',
        //   element: <CreateAdjustInventoryComponent />,
        // },
        {
            path: ':adjustInventoryId',
            element: React.createElement(AdjustInventoryLayoutComponent, null),
            children: [
                { index: true, element: React.createElement(GeneralAdjustInventoryComponent, null) },
                { path: 'cancel', element: React.createElement(CancelAdjustInventoryComponent, null) },
            ],
        },
    ];
};
export default getAdjustInventoryChildrenRoutes;
