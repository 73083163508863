var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useEffect, useState } from 'react';
import ListAltIcon from '@mui/icons-material/ListAlt';
import ListItemPermissionEditRoleComponent from '../helpers/ListItem';
import SwitchPermissionFieldComponent from '../helpers/SwitchPermissionField';
var defaultBranchPermissionValues = {
    createNewRoles: false,
    editRoleName: false,
    editRolePrivileges: false,
    getRoles: false,
    deleteRoles: false,
};
var RolePermissionEditRoleComponent = function (props) {
    var _a = useState(defaultBranchPermissionValues), values = _a[0], setValues = _a[1];
    useEffect(function () {
        setValues(function (values) { return (__assign(__assign({}, values), props.Permissions)); });
    }, [props.Permissions]);
    return (React.createElement(ListItemPermissionEditRoleComponent, { icon: React.createElement(ListAltIcon, null), name: 'Roles de empleados' },
        React.createElement("div", { className: 'mx-4 py-3 grid grid-cols-1 md:grid-cols-3 gap-4' },
            React.createElement("div", { className: 'flex flex-col' },
                React.createElement(SwitchPermissionFieldComponent, __assign({}, props, { name: 'createNewRoles', label: 'Crear nuevos roles', defaultValue: values.createNewRoles })),
                React.createElement(SwitchPermissionFieldComponent, __assign({}, props, { name: 'editRoleName', label: 'Editar nombre del rol', defaultValue: values.editRoleName })),
                React.createElement(SwitchPermissionFieldComponent, __assign({}, props, { name: 'editRolePrivileges', label: 'Editar privilegios de roles', defaultValue: values.editRolePrivileges })),
                React.createElement(SwitchPermissionFieldComponent, __assign({}, props, { name: 'getRoles', label: 'Consulta de datos', defaultValue: values.getRoles })),
                React.createElement(SwitchPermissionFieldComponent, __assign({}, props, { name: 'deleteRoles', label: 'Dar de baja roles', defaultValue: values.deleteRoles }))))));
};
export default RolePermissionEditRoleComponent;
