import React, { useEffect, useState } from 'react';
import convertWordToPlural from '../../../../../helpers/convertWordToPlural';
import PrintProductRequisitionBarcodeButtonComponent from './PrintButton';
var ItemPrintProductRequisitionBarcodeComponent = function (props) {
    var _a = useState(''), presentation = _a[0], setPresentation = _a[1];
    useEffect(function () {
        if (props.Product.ProductPresentation) {
            var presentation_1 = props.Product.quantity > 1
                ? convertWordToPlural(props.Product.ProductPresentation.name.toUpperCase(), {
                    uppercase: true,
                })
                : props.Product.ProductPresentation.name.toUpperCase();
            setPresentation(presentation_1);
        }
    }, [props.Product.ProductPresentation, props.Product.quantity]);
    return (React.createElement("div", { className: "flex items-center justify-between ".concat(props.index < props.length - 1 ? 'border-0 border-b border-solid border-slate-300' : '') },
        React.createElement("div", null,
            props.Product.Product.name,
            " (",
            props.Product.quantity,
            " ",
            presentation,
            ")"),
        React.createElement(PrintProductRequisitionBarcodeButtonComponent, { name: "".concat(props.Product.Product.name, " ").concat(props.Product.ProductPresentation.name), ProductPresentation: props.Product.ProductPresentation, quantity: props.Product.quantity })));
};
export default ItemPrintProductRequisitionBarcodeComponent;
