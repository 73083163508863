import React, { useEffect, useState } from 'react';

import { useQuery } from '@apollo/client';

import Typography from '@mui/material/Typography';

import FilterDrawer from '../../presentationals/FilterDrawer.v2';
import Autocomplete from '../../presentationals/Autocomplete';
import Checkbox from '../../presentationals/Checkbox';

import { filter } from '../../apollo/cache';

import GQL_BRANCHES from '../branch/_gql';
import GQL_PRODUCT from '../product/_gql';

const defaultValues = {
  productId: null,
};

const FilterDialog = () => {
  const [valuesBranches, setValuesBranches] = useState({});
  const [products, setProducts] = useState([]);
  const [values, setValues] = useState(defaultValues);
  const [branches, setBranches] = useState([]);

  const { data: dataBranches } = useQuery(GQL_BRANCHES.GET, { variables: { limit: 0 } });

  const { data: productsData } = useQuery(GQL_PRODUCT.GET_NAME_AND_PRESENTATIONS, {
    variables: { limit: 0, includeBaseInPresentations: true },
  });

  useEffect(() => {
    if (productsData && productsData.productsV2) {
      const tmpProducts = productsData.productsV2.rows.map(el => ({
        label: `${String(el.code).padStart(3, '0')} ${el.name.trim()} ${
          el.sku ? ` | SKU ${el.sku.trim()}` : ''
        } ${el.barcode ? ` | C.B. ${el.barcode.trim()}` : ''}`.trim(),
        value: el.id,
      }));
      setProducts(tmpProducts);
    }
  }, [productsData]);

  useEffect(() => {
    if (dataBranches) {
      const tmp = dataBranches.branches.rows.reduce(
        (object, el) => ({ ...object, [el.id]: true }),
        {},
      );
      setValuesBranches({ ...tmp, '-1': true });
      setBranches([{ id: '-1', name: 'Seleccionar Todos' }, ...dataBranches.branches.rows]);
      filter({ ...filter(), branchId: Object.keys(tmp).filter(key => !!tmp[key]) });
    }
  }, [dataBranches]);

  const handleAction = (e, data) => {
    const { startDate: start, endDate: end } = data;
    filter({
      branchId: Object.keys(valuesBranches).filter(key => !!valuesBranches[key] && key !== '-1'),
      start,
      end,
      productId: values.productId ? values.productId.value : null,
    });
  };

  const handleCheckBoxBranches = (name, checked) => {
    if (name === '-1') {
      const tmp = Object.keys(valuesBranches).reduce((object, key) => {
        return { ...object, [key]: checked };
      }, valuesBranches);
      setValuesBranches(tmp);
      return;
    }
    const numberOfTrue = Object.keys(valuesBranches).filter(key => !!valuesBranches[key]);
    if ((numberOfTrue.length === 1 || Object.keys(valuesBranches).length === 2) && !checked) return;
    let number = numberOfTrue.length;
    if (checked) number = number + 2;
    else number = number - 1;
    if (number === Object.keys(valuesBranches).length)
      setValuesBranches(values => ({ ...values, [name]: checked, '-1': true }));
    else setValuesBranches(values => ({ ...values, [name]: checked, '-1': false }));
    // const numberOfTrue = Object.keys(valuesBranches).filter(key => !!valuesBranches[key]);
    // if (numberOfTrue.length === 1 && !checked) return;
    // setValuesBranches(values => ({ ...values, [name]: checked }));
  };

  const handleAutocompleteChange = (e, name, value) => {
    setValues(values => ({ ...values, [name]: value }));
  };

  return (
    <FilterDrawer handleAction={handleAction} showDates>
      <Typography variant='subtitle1'>Sucursales:</Typography>
      <Checkbox
        handleChange={handleCheckBoxBranches}
        noFormat
        values={branches.map(el => ({
          name: el.id,
          label: el.name,
          checked: !!valuesBranches[el.id] && valuesBranches[el.id],
          color: el.color,
        }))}
      />
      <Typography variant='subtitle1'>Producto</Typography>
      <Autocomplete
        name='productId'
        variant='outlined'
        onChange={handleAutocompleteChange}
        options={products}
        value={values.productId}
        noFormat
      />
    </FilterDrawer>
  );
};

export default FilterDialog;
