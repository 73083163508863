import React from 'react';
import { useParams } from 'react-router-dom';
import useGetDetailedSaleProductIngredientRotation from '../../../hooks/useGetDetailedSaleProductIngredientRotation';
import LoadingProgress from '../../../presentationals/LoadingProgress';
import ErrorDataComponent from '../../../presentationals/ErrorData';
import ModuleV3Component from '../../../presentationals/Module.v3';
import FilterSaleProductPresentationRotationReportComponent from '../Filter';
import useGetItemProduct from '../../product/Item/helpers/useGetItemProduct';
var ItemSaleProductIngredientRotationReportComponent = function () {
    var params = useParams();
    var _a = useGetDetailedSaleProductIngredientRotation(params.productId), data = _a.data, loading = _a.loading;
    var Product = useGetItemProduct().data;
    var header = [
        { Header: 'SKU', accessor: 'sku' },
        { Header: 'Componente', accessor: 'product' },
        { Header: 'Unidad', accessor: 'presentation' },
        { Header: 'Ocasiones vendidas', accessor: 'portion' },
        { Header: 'Cantidad', accessor: 'quantity' },
        { Header: 'Importe', accessor: 'total' },
    ];
    if (loading)
        return React.createElement(LoadingProgress, null);
    if (!data)
        return React.createElement(ErrorDataComponent, null);
    return (React.createElement(React.Fragment, null,
        React.createElement(ModuleV3Component, { route: -1, showReturnButton: true, title: Product ? "Detalle de ".concat(Product.name.toLowerCase()) : '', searchLabel: 'productos', filter: true, data: data, header: header, hideAdd: true, isLoading: loading }),
        React.createElement(FilterSaleProductPresentationRotationReportComponent, null)));
};
export default ItemSaleProductIngredientRotationReportComponent;
