import React from 'react';
import { Card, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, } from '@mui/material';
import convertToCurrencyLabel from '../../../../helpers/convertToCurrencyLabel';
var ProductsTableAccounReceivableComponent = function (props) {
    return (React.createElement("div", { className: 'flex flex-1 flex-col mt-2' },
        React.createElement("div", { className: 'text-xl' }, "Productos de la cuenta"),
        React.createElement(Card, { className: 'mt-1 flex flex-1 flex-col basis-0 overflow-auto' }, props.SaleProducts.length === 0 ? (React.createElement("div", { className: 'flex px-4 py-3' }, "De momento no cuentas con productos vinculados a esta cuenta.")) : (React.createElement(TableContainer, null,
            React.createElement(Table, { stickyHeader: true, size: 'small' },
                React.createElement(TableHead, null,
                    React.createElement(TableRow, null,
                        React.createElement(TableCell, { className: 'whitespace-nowrap bg-slate-300' }, "Producto"),
                        React.createElement(TableCell, { className: 'bg-slate-300', align: 'center' }, "Cantidad"),
                        React.createElement(TableCell, { className: 'bg-slate-300', align: 'right' }, "P. Unitario"),
                        React.createElement(TableCell, { className: 'bg-slate-300', align: 'right' }, "Subtotal"),
                        React.createElement(TableCell, { className: 'bg-slate-300', align: 'right' }, "Dscto."),
                        React.createElement(TableCell, { className: 'bg-slate-300', align: 'right' }, "Importe"))),
                React.createElement(TableBody, null, props.SaleProducts.map(function (el) { return (React.createElement(TableRow, { key: el.id },
                    React.createElement(TableCell, null,
                        React.createElement("div", null, el.Product.name),
                        React.createElement("div", { className: 'text-sm' }, el.commentary)),
                    React.createElement(TableCell, { align: 'center' }, convertToCurrencyLabel(el.quantity)),
                    React.createElement(TableCell, { align: 'right' },
                        "$",
                        convertToCurrencyLabel(el.unitPrice)),
                    React.createElement(TableCell, { align: 'right' },
                        "$",
                        convertToCurrencyLabel(el.subtotal)),
                    React.createElement(TableCell, { align: 'right' },
                        "$",
                        convertToCurrencyLabel(el.discount)),
                    React.createElement(TableCell, { align: 'right' },
                        "$",
                        convertToCurrencyLabel(el.total)))); }))))))));
};
export default ProductsTableAccounReceivableComponent;
