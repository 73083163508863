var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import getInputErrorFormat from '../../../sale.v2/Action/helpers/getInputErrorFormat';
import addProductToCreatePurchaseOrderCart from './helpers/addProductToCreatePurchaseOrderCart';
import ItemCartCreatePurchaseOrderComponent from './Item';
import useApolloMutation from '../../../../hooks/useApolloMutation';
import GQL_SALE from '../../../../apollo/gql/sale';
import LoadingProgress from '../../../../presentationals/LoadingProgress';
import getTotalizedCartVariables from './helpers/getTotalizedCartVariables';
import convertTotalizeCartToCreatePurchaseOrderCart from './helpers/convertTotalizeCartToCreatePurchaseOrderCart';
import EditCreatePurchaseOrderItemDialogComponent from './EditItemDialog';
import editProductToCreatePurchaseOrderCart from './helpers/editProductToCreatePurchaseOrderCart';
import TotalsCreatePurchaseOrderCartLabelComponent from './TotalsLabel';
var CreatePurchaseOrderCartComponent = forwardRef(function (props, ref) {
    var _a, _b, _c, _d, _e, _f;
    var editCreatePurchaseOrderItemDialogRef = useRef(null);
    var _g = useApolloMutation(GQL_SALE.TOTALIZE_CART), getTotalizedCart = _g[0], _h = _g[1], loading = _h.loading, data = _h.data;
    var _j = useState(null), settings = _j[0], setSettings = _j[1];
    var _k = useState([]), cart = _k[0], setCart = _k[1];
    useEffect(function () {
        var settings = props.getSettings();
        if (!settings)
            return;
        setSettings(settings);
    }, []);
    var handleTotalizeCart = function (cart, settings) { return __awaiter(void 0, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!settings)
                        throw new Error('Error interno al intentar almacenar la configuración, contacte soporte técnico.');
                    return [4 /*yield*/, getTotalizedCart({
                            variables: getTotalizedCartVariables(cart, settings),
                        })];
                case 1:
                    response = (_a.sent()).data;
                    setCart(convertTotalizeCartToCreatePurchaseOrderCart(response.totalizeCart.Products, cart));
                    return [2 /*return*/];
            }
        });
    }); };
    var handleAddProduct = function (data) { return __awaiter(void 0, void 0, void 0, function () {
        var newCart;
        return __generator(this, function (_a) {
            try {
                newCart = addProductToCreatePurchaseOrderCart(data, cart);
                handleTotalizeCart(newCart, settings);
            }
            catch (error) {
                getInputErrorFormat(error);
            }
            return [2 /*return*/];
        });
    }); };
    var totalizeCart = function (partialSettings) { return __awaiter(void 0, void 0, void 0, function () {
        var settings_1, error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    settings_1 = __assign(__assign({}, props.getSettings()), partialSettings);
                    if (!settings_1)
                        return [2 /*return*/];
                    setSettings(settings_1);
                    if (cart.length === 0)
                        return [2 /*return*/];
                    return [4 /*yield*/, handleTotalizeCart(cart, settings_1)];
                case 1:
                    _a.sent();
                    return [3 /*break*/, 3];
                case 2:
                    error_1 = _a.sent();
                    getInputErrorFormat(error_1);
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    var handleItemClick = function (item) {
        var _a;
        (_a = editCreatePurchaseOrderItemDialogRef.current) === null || _a === void 0 ? void 0 : _a.open(item);
    };
    var getCart = function () { return cart; };
    useImperativeHandle(ref, function () { return ({
        addProduct: handleAddProduct,
        totalizeCart: totalizeCart,
        getCart: getCart,
    }); });
    var onDeleteItem = function (index) { return __awaiter(void 0, void 0, void 0, function () {
        var newCart, error_2;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    newCart = cart.filter(function (el, i) { return i !== index; });
                    setCart(newCart);
                    return [4 /*yield*/, handleTotalizeCart(newCart, settings)];
                case 1:
                    _a.sent();
                    return [3 /*break*/, 3];
                case 2:
                    error_2 = _a.sent();
                    getInputErrorFormat(error_2);
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    var onEditItem = function (index, data) { return __awaiter(void 0, void 0, void 0, function () {
        var newCart, error_3;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    newCart = editProductToCreatePurchaseOrderCart(index, data, cart);
                    return [4 /*yield*/, handleTotalizeCart(newCart, settings)];
                case 1:
                    _a.sent();
                    return [3 /*break*/, 3];
                case 2:
                    error_3 = _a.sent();
                    getInputErrorFormat(error_3);
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    var handleGetAction = function () { var _a; return ((_a = settings === null || settings === void 0 ? void 0 : settings.General) === null || _a === void 0 ? void 0 : _a.action) || 1; };
    if (loading)
        return React.createElement(LoadingProgress, null);
    if (!settings)
        return React.createElement("div", null);
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: 'flex flex-1 flex-col' },
            React.createElement("div", { className: 'flex flex-1 flex-col overflow-auto basis-0' },
                React.createElement(TableContainer, null,
                    React.createElement(Table, { size: 'small', stickyHeader: true, className: 'overflow-y-auto' },
                        React.createElement(TableHead, null,
                            React.createElement(TableRow, null,
                                React.createElement(TableCell, null, "Producto"),
                                React.createElement(TableCell, null, "Cant."),
                                React.createElement(TableCell, null, "Costo unitario"),
                                ((_a = settings === null || settings === void 0 ? void 0 : settings.Discount) === null || _a === void 0 ? void 0 : _a.discount) && parseFloat(settings.Discount.discount) > 0 && (React.createElement(TableCell, null, "Descuento")),
                                ((_b = settings === null || settings === void 0 ? void 0 : settings.General) === null || _b === void 0 ? void 0 : _b.showTaxesOptions) && React.createElement(TableCell, null, "Impuestos"),
                                React.createElement(TableCell, null, "Importe"))),
                        React.createElement(TableBody, null, cart.map(function (el, i) {
                            var _a, _b, _c, _d;
                            return (React.createElement(ItemCartCreatePurchaseOrderComponent, __assign({ onClick: handleItemClick, index: i, key: "".concat(el.Product.id).concat(el.ProductPresentation.id).concat(el.quantity).concat(el.cost), discountType: ((_a = settings === null || settings === void 0 ? void 0 : settings.Discount) === null || _a === void 0 ? void 0 : _a.discountType) || 1, discount: ((_b = settings === null || settings === void 0 ? void 0 : settings.Discount) === null || _b === void 0 ? void 0 : _b.discount) ? parseFloat(settings.Discount.discount) : 0, showTaxes: ((_c = settings === null || settings === void 0 ? void 0 : settings.General) === null || _c === void 0 ? void 0 : _c.showTaxesOptions)
                                    ? (_d = settings === null || settings === void 0 ? void 0 : settings.General) === null || _d === void 0 ? void 0 : _d.showTaxesOptions
                                    : false }, el)));
                        }))))),
            React.createElement("div", { className: 'flex flex-col' },
                React.createElement(TotalsCreatePurchaseOrderCartLabelComponent, { label: 'Subtotal', value: ((_c = data === null || data === void 0 ? void 0 : data.totalizeCart) === null || _c === void 0 ? void 0 : _c.subtotal) || 0 }),
                ((_d = data === null || data === void 0 ? void 0 : data.totalizeCart) === null || _d === void 0 ? void 0 : _d.discount) ? (React.createElement(TotalsCreatePurchaseOrderCartLabelComponent, { label: 'Descuento', value: data.totalizeCart.discount })) : (React.createElement("div", null)),
                React.createElement(TotalsCreatePurchaseOrderCartLabelComponent, { label: 'Impuestos', value: ((_e = data === null || data === void 0 ? void 0 : data.totalizeCart) === null || _e === void 0 ? void 0 : _e.taxes) || 0 }),
                React.createElement(TotalsCreatePurchaseOrderCartLabelComponent, { boldValue: true, label: 'Total', value: ((_f = data === null || data === void 0 ? void 0 : data.totalizeCart) === null || _f === void 0 ? void 0 : _f.total) || 0 }))),
        React.createElement(EditCreatePurchaseOrderItemDialogComponent, { onDelete: onDeleteItem, onEdit: onEditItem, getAction: handleGetAction, ref: editCreatePurchaseOrderItemDialogRef })));
});
export default CreatePurchaseOrderCartComponent;
