var getRouteFromCustomerMenuValue = function (value) {
    switch (value) {
        case 0:
            return '.';
        case 1:
            return 'survey';
        case 2:
            return 'calendar';
        case 3:
            return 'autoReceivable';
        default:
            return false;
    }
};
export default getRouteFromCustomerMenuValue;
