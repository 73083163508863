import React from 'react';
import LinkMenuDashboardComponent from '../../helpers/Link';
import useGetSession from '../../../../../../hooks/useGetSession';
var GlobalInvoiceLinkMenuDashboardComponent = function () {
    var _a;
    var session = useGetSession();
    if (((_a = session.Company) === null || _a === void 0 ? void 0 : _a.id) && parseInt("".concat(session.Company.id)) !== 217)
        return React.createElement("div", null);
    return (React.createElement(LinkMenuDashboardComponent, { url: 'globalInvoice', primary: 'Factura global', secondary: 'Emite una factura donde incluyan un conjunto de ventas, o bien, con conceptos personalizados.' }));
};
export default GlobalInvoiceLinkMenuDashboardComponent;
