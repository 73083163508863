import React from 'react';
import TuneIcon from '@mui/icons-material/Tune';
import IconButtonComponent from '../../../../IconButton';
import useFilterButtonDialog from '../../../../../store/filterButton/filterButtonDialog';
var FilterButtonHeaderModuleV3Component = function (props) {
    var setOpen = useFilterButtonDialog(function (state) { return state.setOpen; });
    var handleClick = function () {
        setOpen(true);
    };
    return (React.createElement(IconButtonComponent, { action: handleClick, icon: React.createElement(TuneIcon, { fontSize: props.fontSize }), label: 'Ver Filtros', className: "flex ".concat(!props.size ? 'p-1' : ''), size: props.size, disabled: props.isLoading }));
};
export default FilterButtonHeaderModuleV3Component;
