import React, { useEffect } from 'react';
import { useLazyQuery, useReactiveVar } from '@apollo/client';
import { format } from 'date-fns';

import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import { filter } from '../../apollo/cache';
import { currencyFormat } from '../../helpers';
import FormDialog from '../../presentationals/FormDialog';
import GQL_SALE from '../sale.report.v2/_gql';

const Component = props => {
  const filterVar = useReactiveVar(filter);
  const [getSales, { data, loading }] = useLazyQuery(GQL_SALE.GET);

  useEffect(() => {
    if (props.customerId)
      getSales({
        variables: {
          start: filterVar.start,
          end: filterVar.end,
          limit: 0,
          customerId: props.customerId,
        },
      });
  }, [props.customerId]);

  return (
    <FormDialog
      title='Ventas'
      isOpen={props.isOpen || false}
      handleClose={props.handleClose}
      isLoading={loading}
      hideAction
    >
      <Grid container item xs={12} spacing={1}>
        <Table size='small' aria-label='purchases'>
          <TableHead>
            <TableRow>
              <TableCell>Fecha</TableCell>
              <TableCell>Folio</TableCell>
              <TableCell align='right'>Total</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data &&
              data.sales.rows.map(el => (
                <TableRow key={el.id}>
                  <TableCell>
                    {format(new Date(el.clientCreatedAt), 'dd/MM/yyyy HH:mm:ss')}
                  </TableCell>
                  <TableCell>{`${el.folio}-${el.serial}`}</TableCell>
                  <TableCell align='right'>{currencyFormat(el.total)}</TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </Grid>
    </FormDialog>
  );
};

export default Component;
