import React, { useEffect, useState } from 'react';
import GQL_QUOTATION from '../../../apollo/gql/quotation';
import useApolloQuery from '../../../hooks/useApolloQuery';
import CustomerFieldComponent from '../../../presentationals/FormComponents/CustomerField';
import UpdateCustomerQuoteButtonComponent from './UpdateButton';
var UpdateCustomerQuoteComponent = function (props) {
    var _a = useApolloQuery(GQL_QUOTATION.GET_QUOTATION, {
        variables: { id: props.quoteId },
    }), data = _a.data, loading = _a.loading;
    var _b = useState(null), customerId = _b[0], setCustomerId = _b[1];
    useEffect(function () {
        if (data === null || data === void 0 ? void 0 : data.customerId)
            setCustomerId(data.customerId);
    }, [data]);
    var handleChange = function (name, value) {
        setCustomerId(value || null);
    };
    if (loading || !data)
        return React.createElement("div", null);
    return (React.createElement("div", { className: 'flex items-end gap-2' },
        React.createElement(CustomerFieldComponent, { defaultValue: data.customerId, onChange: handleChange }),
        React.createElement(UpdateCustomerQuoteButtonComponent, { customerId: customerId, quoteId: props.quoteId })));
};
export default UpdateCustomerQuoteComponent;
