import React from 'react';
import TransactionReportComponent from '../containers/transaction';
import TransactionLayoutComponent from './TransactionLayout';
import GeneralTransactionComponent from '../containers/transaction/Transaction/General';
import InvoiceComplementTransactionComponent from '../containers/transaction/Transaction/InvoiceComplement';
import CancelTransactionComponent from '../containers/transaction/Transaction/Cancel';
var getTransactionChildrenRoutes = function () {
    return [
        { index: true, element: React.createElement(TransactionReportComponent, null) },
        {
            path: ':transactionId',
            element: React.createElement(TransactionLayoutComponent, null),
            children: [
                { index: true, element: React.createElement(GeneralTransactionComponent, null) },
                { path: 'invoiceComplement', element: React.createElement(InvoiceComplementTransactionComponent, null) },
                { path: 'cancel', element: React.createElement(CancelTransactionComponent, null) },
            ],
        },
    ];
};
export default getTransactionChildrenRoutes;
