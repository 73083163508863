import React, { useEffect, useState } from 'react';

import PrintIcon from '@mui/icons-material/Print';
import ListAltIcon from '@mui/icons-material/ListAltOutlined';
import LockIcon from '@mui/icons-material/LockOutlined';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import ImageIcon from '@mui/icons-material/ImageOutlined';
import AssignmentReturnOutlinedIcon from '@mui/icons-material/AssignmentReturnOutlined';
import ReceiptIcon from '@mui/icons-material/ReceiptOutlined';

import purchaseOrderTicket from '../../presentationals/PDF/purchaseOrder.80mm';

import { actions, actionForm } from '../../apollo/cache';

import Ticket from './_pdf';
import ProductsForm from './Products';
import CloseOrderForm from './CloseForm';
import DeleteOrderForm from './_delete.form';
import FileDialog from './file.dialog';
import CreditNoteProduct from './CreditNoteProductForm';
import CreditNote from './creditNote.dialog';

const ActionComponent = () => {
  const [isOpenDeleteOrder, setIsOpenDeleteOrder] = useState([false, null]);
  const [isOpenProducts, setIsOpenProducts] = useState([false, null]);
  const [isOpenImage, setIsOpenImage] = useState([false, null]);
  const [isOpenCreditNoteProduct, setIsOpenCreditNoteProduct] = useState([false, null]);
  const [isOpenCreditNote, setIsOpenCreditNote] = useState([false, null]);

  const handleTicket = (e, { id }) => Ticket(id);

  const handleOpenProducts = (e, { id }) => {
    setIsOpenProducts([true, id]);
  };
  const handleCloseProducts = () => setIsOpenProducts([false, null]);

  const handleFormCloseOrder = (e, { id }) =>
    actionForm({ ...actionForm(), isOpen: true, action: 'closeOrderForm', id });
  const handleDelete = (e, { id }) => setIsOpenDeleteOrder([true, id]);
  const handleCloseDelete = () => setIsOpenDeleteOrder([false, null]);

  const handleImage = (e, { id }) => setIsOpenImage([true, id]);
  const handleCloseImage = () => setIsOpenImage([false, null]);

  const handleOpenCreditNoteProduct = (e, { id }) => setIsOpenCreditNoteProduct([true, id]);
  const handleCloseCreditNoteProduct = () => setIsOpenCreditNoteProduct([false, null]);

  const handleOpenCreditNote = (e, { id }) => setIsOpenCreditNote([true, id]);
  const handleCloseCreditNote = () => setIsOpenCreditNote([false, null]);

  const handleTicket80mm = async (e, { id }) => {
    await purchaseOrderTicket(id);
  };

  useEffect(() => {
    actions([
      {
        icon: <PrintIcon />,
        label: 'Imprimir Documento',
        onClick: handleTicket,
      },
      {
        icon: <ReceiptIcon />,
        label: 'Imprimir Ticket',
        onClick: handleTicket80mm,
      },
      {
        icon: <ListAltIcon />,
        label: 'Editar Productos',
        onClick: handleOpenProducts,
        disable: { key: 'status', value: 3 },
      },
      {
        icon: <AssignmentReturnOutlinedIcon />,
        label: 'Nota de Crédito Por Producto',
        onClick: handleOpenCreditNoteProduct,
        disable: { key: 'status', value: 1 },
      },
      {
        icon: <AssignmentReturnOutlinedIcon />,
        label: 'Nota de Crédito',
        onClick: handleOpenCreditNote,
        disable: { key: 'status', value: 1 },
      },
      {
        icon: <LockIcon />,
        label: 'Cerrar Orden de Compra',
        onClick: handleFormCloseOrder,
        disable: { key: 'status', value: 3 },
      },
      {
        icon: <ImageIcon />,
        label: 'Imagen',
        onClick: handleImage,
      },
      {
        icon: <DeleteIcon />,
        label: 'Eliminar Orden de Compra',
        onClick: handleDelete,
      },
    ]);
  }, []);

  return (
    <>
      <ProductsForm
        isOpen={isOpenProducts[0]}
        handleClose={handleCloseProducts}
        id={isOpenProducts[1]}
      />
      <CloseOrderForm />
      <DeleteOrderForm
        isOpen={isOpenDeleteOrder[0]}
        id={isOpenDeleteOrder[1]}
        handleClose={handleCloseDelete}
      />
      <FileDialog isOpen={isOpenImage[0]} id={isOpenImage[1]} handleClose={handleCloseImage} />
      <CreditNoteProduct
        isOpen={isOpenCreditNoteProduct[0]}
        id={isOpenCreditNoteProduct[1]}
        handleClose={handleCloseCreditNoteProduct}
      />
      <CreditNote
        isOpen={isOpenCreditNote[0]}
        id={isOpenCreditNote[1]}
        handleClose={handleCloseCreditNote}
      />
    </>
  );
};

export default ActionComponent;
