var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { useReactiveVar } from '@apollo/client';
import React, { useEffect, useRef, useState } from 'react';
import { cartItems, isItemDetailDialogOpen } from '../../../../../apollo/cache';
import useApolloLazyQuery from '../../../../../hooks/useApolloLazyQuery';
import GQL_PRODUCT from '../../../../../apollo/gql/product';
import getInputErrorFormat from '../../../Action/helpers/getInputErrorFormat';
import LoadingProgress from '../../../../../presentationals/LoadingProgress';
import ErrorDataComponent from '../../../../../presentationals/ErrorData';
import ItemIngredientSaleV2Component from './Item';
import VariationsSaleV2Component from './Variations';
import FooterDialogComponent from '../../../../../presentationals/Dialog/Footer';
import ActionComponentProductSaleV2Component from './ActionButton';
var ComponentsItemDetailSaleV2Component = function () {
    var variationRef = useRef(null);
    var index = useReactiveVar(isItemDetailDialogOpen);
    var cartItemsVar = useReactiveVar(cartItems);
    var _a = useState([]), Presentations = _a[0], setPresentations = _a[1];
    var _b = useState(null), presentationId = _b[0], setPresentationId = _b[1];
    var _c = useState([]), ProductVariations = _c[0], setProductVariations = _c[1];
    var _d = useState(undefined), initialProductIngredients = _d[0], setInitialProductIngredients = _d[1];
    var _e = useApolloLazyQuery(GQL_PRODUCT.GET_PRODUCT_BY_ID), getData = _e[0], _f = _e[1], loading = _f.loading, data = _f.data;
    useEffect(function () {
        if (cartItemsVar && typeof index === 'number') {
            var SelectedProductIngredients = cartItemsVar.reduce(function (item, el, i) { return (i === index ? el.SelectedProductIngredients : item); }, undefined);
            setInitialProductIngredients(SelectedProductIngredients);
        }
    }, [cartItemsVar, index]);
    useEffect(function () {
        if (data) {
            var Presentations_1 = __spreadArray([data.PresentationBase], (data.ProductPresentations || []), true);
            if (Presentations_1.length === 1)
                setPresentationId(Presentations_1[0].id);
            setPresentations(Presentations_1);
        }
    }, [data]);
    var setProductVariationsByPresentationId = function (presentationId) {
        var Presentation = Presentations.find(function (el) { return el.id === presentationId; });
        if (!Presentation)
            return;
        var ProductVariations = Presentation.ProductIngredients.reduce(function (ProductVariations, el) {
            var isInArray = ProductVariations.find(function (elCh) { return el.ProductVariation && elCh.id === el.ProductVariation.id; });
            return isInArray
                ? ProductVariations
                : el.ProductVariation
                    ? __spreadArray(__spreadArray([], ProductVariations, true), [__assign({}, el.ProductVariation)], false) : ProductVariations;
        }, []).map(function (el) { return (__assign(__assign({}, el), { ProductIngredients: Presentation.ProductIngredients.filter(function (elCh) { return elCh.ProductVariation && elCh.ProductVariation.id === el.id; }) })); });
        setProductVariations(ProductVariations);
    };
    useEffect(function () {
        if (presentationId && Presentations.length > 0)
            setProductVariationsByPresentationId(presentationId);
    }, [presentationId, Presentations]);
    useEffect(function () {
        var f = function () { return __awaiter(void 0, void 0, void 0, function () {
            var currentItem, e_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        currentItem = cartItemsVar.find(function (el, i) { return i === index; });
                        if (!currentItem)
                            throw new Error('No encontramos registros de este producto en nuestra base de datos.');
                        return [4 /*yield*/, getData({ variables: { id: currentItem.productId } })];
                    case 1:
                        _a.sent();
                        return [3 /*break*/, 3];
                    case 2:
                        e_1 = _a.sent();
                        getInputErrorFormat(e_1);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        }); };
        if (typeof index === 'number' && index >= 0 && cartItemsVar.length > 0)
            f();
    }, [index, cartItemsVar]);
    var handleChoosePresentation = function (id) {
        setPresentationId(id);
        setProductVariationsByPresentationId(id);
    };
    var handleClick = function () {
        var _a;
        return ((_a = variationRef === null || variationRef === void 0 ? void 0 : variationRef.current) === null || _a === void 0 ? void 0 : _a.getSelectedProductIngredients()) || [];
    };
    if (loading)
        return React.createElement(LoadingProgress, null);
    if (!data)
        return React.createElement(ErrorDataComponent, null);
    return (React.createElement("div", { className: 'flex flex-1 flex-col' },
        React.createElement("div", { className: 'flex flex-1 flex-col' },
            React.createElement("div", { className: 'flex flex-col px-4 pt-3 pb-5 border-0 border-b border-solid border-slate-400' },
                React.createElement("div", { className: 'text-lg' }, "Presentaci\u00F3n del producto."),
                React.createElement("div", { className: 'text-base text-slate-600' }, "Selecciona una presetaci\u00F3n del producto."),
                React.createElement("div", { className: 'flex flex-row flex-wrap mt-3' }, Presentations.map(function (el) { return (React.createElement(ItemIngredientSaleV2Component, { key: el.id, id: el.id, name: el.name, onClick: handleChoosePresentation, selected: presentationId === el.id })); }))),
            React.createElement("div", { className: 'flex flex-col flex-1' },
                React.createElement(VariationsSaleV2Component, { initialProductIngredients: initialProductIngredients, ref: variationRef, ProductVariations: ProductVariations }))),
        React.createElement(FooterDialogComponent, null,
            React.createElement("div", null),
            React.createElement(ActionComponentProductSaleV2Component, { ProductVariations: ProductVariations, index: index, onClick: handleClick }))));
};
export default ComponentsItemDetailSaleV2Component;
