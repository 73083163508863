import React, { useEffect } from 'react';

import ReceiptIcon from '@mui/icons-material/ReceiptOutlined';

import { actions } from '../../apollo/cache';

import Ticket from '../cashSale/Header/ticket';

const ActionComponent = () => {
  const handleTicket = (e, { saleId }) => Ticket(saleId);

  useEffect(() => {
    actions([
      {
        icon: <ReceiptIcon />,
        label: 'Consultar Ticket',
        onClick: handleTicket,
      },
    ]);
    return () => {
      actions([]);
    };
  }, []);

  return <div />;
};

export default ActionComponent;
