import { useEffect } from 'react';
import { useReactiveVar } from '@apollo/client';
import { inputError } from '../apollo/cache';
var useInputError = function () {
    var inputErrorVar = useReactiveVar(inputError);
    useEffect(function () {
        inputError({});
        return function () {
            inputError({});
        };
    }, []);
    return inputErrorVar;
};
export default useInputError;
