import { createUploadLink } from 'apollo-upload-client';

import config from '../config/config.json';

const httpLink = new createUploadLink({
  uri: config.apolloServer.uri,
  credentials: 'same-origin', //include if different backend domain
  headers: {
    'Apollo-Require-Preflight': 'true',
  },
});

export default httpLink;
