import React from 'react';
import { useReactiveVar } from '@apollo/client';
import SelectFieldComponent from '../../../../../presentationals/FormComponents/SelectField';
import useGetPaymentForm from '../../../../../hooks/useGetSatPaymentForm';
import { inputError } from '../../../../../apollo/cache';
var PaymentMethodSatFieldComponent = function (props) {
    var errors = useReactiveVar(inputError);
    var paymentFormOptions = useGetPaymentForm();
    var handleChange = function (name, value) {
        props.onChange(name, value);
    };
    return (React.createElement(SelectFieldComponent, { label: props.label || 'Código del SAT', name: 'code', value: paymentFormOptions.length > 0 ? props.value : '', error: errors.code, onChange: handleChange, options: paymentFormOptions }));
};
export default PaymentMethodSatFieldComponent;
