import React, { forwardRef, useImperativeHandle, useRef } from 'react';
import { Paper } from '@mui/material';
import SearchCustomerByGlobalInvoiceFieldComponent from './SearchBy';
import HelperTextSearchCustomerGlobalInvoiceFieldComponent from './HelperText';
import InputSearchSaleGlobalInvoiceCustomerFieldComponent from './Input';
import LoadingSearchSaleGlobalInvoiceCustomerFieldComponent from './Loading';
var SearchCustomerGlobalInvoiceFieldComponent = forwardRef(function (props, ref) {
    var loadingSearchSaleGlobalInvoiceCustomerFieldRef = useRef(null);
    var searchCustomerByGlobalInvoiceFieldRef = useRef(null);
    var inputSearchSaleGlobalInvoiceCustomerFieldRef = useRef(null);
    var helperTextSearchCustomerGlobalInvoiceFieldRef = useRef(null);
    var handleSearchByChange = function (name, value) {
        var _a, _b;
        var input = ((_a = inputSearchSaleGlobalInvoiceCustomerFieldRef.current) === null || _a === void 0 ? void 0 : _a.getValue()) || '';
        (_b = helperTextSearchCustomerGlobalInvoiceFieldRef.current) === null || _b === void 0 ? void 0 : _b.setLabel(name);
        props.onChange(value, input);
    };
    var handleInputChange = function (value) {
        var _a;
        var searchBy = ((_a = searchCustomerByGlobalInvoiceFieldRef.current) === null || _a === void 0 ? void 0 : _a.getValue()) || '';
        props.onChange(searchBy, value);
    };
    var handleLoading = function (loading) {
        var _a;
        (_a = loadingSearchSaleGlobalInvoiceCustomerFieldRef.current) === null || _a === void 0 ? void 0 : _a.setLoading(loading);
    };
    useImperativeHandle(ref, function () { return ({
        setLoading: handleLoading,
    }); });
    return (React.createElement("div", { className: 'flex flex-col' },
        React.createElement(Paper, { className: 'w-full flex px-2 py-1 items-center', variant: 'outlined' },
            React.createElement(SearchCustomerByGlobalInvoiceFieldComponent, { ref: searchCustomerByGlobalInvoiceFieldRef, onChange: handleSearchByChange }),
            React.createElement(InputSearchSaleGlobalInvoiceCustomerFieldComponent, { ref: inputSearchSaleGlobalInvoiceCustomerFieldRef, onChange: handleInputChange }),
            React.createElement(LoadingSearchSaleGlobalInvoiceCustomerFieldComponent, { ref: loadingSearchSaleGlobalInvoiceCustomerFieldRef })),
        React.createElement(HelperTextSearchCustomerGlobalInvoiceFieldComponent, { ref: helperTextSearchCustomerGlobalInvoiceFieldRef })));
});
export default SearchCustomerGlobalInvoiceFieldComponent;
