import React, { useEffect } from 'react';

import Container from '../../presentationals/Module.v2/Container';
import { customerLabel } from '../../helpers';

import { filter } from '../../apollo/cache';

import Action from './_action';
import Filter from './_filter';
import GQL_INVOICE from '../../apollo/gql/invoice';

const customerVar = customerLabel({ textTransform: 'capitalize' });

const Rows = [
  { label: 'Fecha', key: 'clientCreatedAt', sort: 'text', format: 'dateTime' },
  { label: 'Factura', key: 'relateFolio', strcat: ' ' },
  { label: 'Folio', key: 'folio' },
  { label: 'UUID', key: 'uuid' },
  { label: customerVar, key: 'customer', filter: true },
  { label: 'Forma de Pago', key: 'paymentForm', sort: 'text' },
  { label: 'Descuento', key: 'discount', sort: 'text', format: 'currency' },
  { label: 'Subtotal', key: 'subtotal', sort: 'text', format: 'currency' },
  { label: 'IVA', key: 'taxes', sort: 'text', format: 'currency' },
  { label: 'Total', key: 'total', sort: 'text', format: 'currency' },
];
const title = 'Notas de crédito';
const operation = ['invoice', 'invoices'];

const CreditNoteReportComponent = () => {
  useEffect(() => {
    filter({ type: 4 });
  }, []);
  return (
    <>
      <Container
        title={title}
        operation={operation}
        Rows={Rows}
        get={GQL_INVOICE.GET_INVOICES}
        noDelete
        filter
      />
      <Action />
      <Filter />
    </>
  );
};

export default CreditNoteReportComponent;
