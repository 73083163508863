import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import useApolloLazyQuery from '../../../../hooks/useApolloLazyQuery';
import GQL_CUSTOMER from '../../../../apollo/gql/customer';
import SkeletonCustomerInformationAccountReceivableComponent from './Skeleton';
import ErrorDataComponent from '../../../../presentationals/ErrorData';
import { addressFormat } from '../../../../helpers';
var CustomerInformationAccountReceivableComponent = function () {
    var params = useParams();
    var _a = useApolloLazyQuery(GQL_CUSTOMER.GET_CUSTOMER_BY_ID), getData = _a[0], _b = _a[1], loading = _b.loading, data = _b.data;
    useEffect(function () {
        if (params.customerId)
            getData({ variables: { id: params.customerId } });
    }, [params.customerId]);
    if (loading)
        return React.createElement(SkeletonCustomerInformationAccountReceivableComponent, null);
    if (!data)
        return React.createElement(ErrorDataComponent, null);
    return (React.createElement("div", { className: 'flex flex-col' },
        React.createElement("div", { className: 'text-3xl' }, "".concat(data.firstName, " ").concat(data.lastName)),
        React.createElement("div", { className: 'text-xl' }, data.phone),
        React.createElement("div", { className: 'text-xl' }, addressFormat(data))));
};
export default CustomerInformationAccountReceivableComponent;
