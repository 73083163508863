import React from 'react';
import useGetProviders from '../../../../../../../../hooks/useGetProviders';
import SelectFieldComponent from '../../../../../../../../presentationals/FormComponents/SelectField';
var ProviderFieldProductComponent = function (props) {
    var _a = useGetProviders(null, 'select'), data = _a.data, loading = _a.loading, error = _a.error;
    var handleChange = function (name, value) {
        props.onChange(name, value);
    };
    if (!!error || data.rows.length === 0)
        return React.createElement("div", null);
    return (React.createElement(SelectFieldComponent, { label: props.label || 'Proveedor principal', name: 'providerId', value: data.rows.length > 0 ? props.value || '' : '', error: props.error, onChange: handleChange, disabled: loading, options: data.rows }));
};
export default ProviderFieldProductComponent;
