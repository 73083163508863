import React from 'react';
import useBranchFieldStore from '../../../../store/filterInputs/branchFieldStore';
import GQL_BRANCH from '../../../../apollo/gql/branch';
import useApolloQuery from '../../../../hooks/useApolloQuery';
import { capitalize } from '../../../../helpers';
var LabelBranchFieldComponent = function () {
    var branchId = useBranchFieldStore(function (state) { return state.id; });
    var _a = useApolloQuery(GQL_BRANCH.GET_BRANCH_BY_ID, {
        variables: { id: branchId },
    }), loading = _a.loading, data = _a.data;
    return (React.createElement("div", { className: 'leading-none tracking-tighter text-gray-800' }, loading ? 'Cargando...' : data ? capitalize(data.name) : 'Todas las sucursales'));
};
export default LabelBranchFieldComponent;
