import { Card } from '@mui/material';
import React from 'react';
var SuscriptionBoxMainComponent = function () {
    return (React.createElement(Card, { className: 'flex flex-col bg-slate-100 text-gray-800 px-4 py-3 h-40 items-center justify-center' },
        React.createElement("div", { className: 'flex flex-1 flex-col items-center justify-center mb-4' },
            React.createElement("div", { className: 'text-2xl tracking-tighter mb-2' }, "Suscripci\u00F3n"),
            React.createElement("div", { className: 'text-sm tracking-tighter text-center' },
                "Actualmente est\u00E1s suscripto al ",
                React.createElement("b", null, "paquete gold"),
                " en un ",
                React.createElement("b", null, "plan anual"),
                ".")),
        React.createElement("div", { className: 'text-sm tracking-tighter' },
            "Restan ",
            React.createElement("b", null, "100 d\u00EDas"),
            " de suscripci\u00F3n")));
};
export default SuscriptionBoxMainComponent;
