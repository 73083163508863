import React, { forwardRef, useImperativeHandle, useState } from 'react';
import DialogComponent from '../../../../../presentationals/Dialog';
import BodyGeneralDiscountDialogComponent from './Body';
var GeneralDiscountDialogComponent = forwardRef(function (props, ref) {
    var _a = useState(false), open = _a[0], setOpen = _a[1];
    var handleOpen = function () {
        setOpen(true);
    };
    var handleClose = function () {
        setOpen(false);
    };
    useImperativeHandle(ref, function () { return ({
        open: handleOpen,
    }); });
    return (React.createElement(DialogComponent, { fullScreen: true, title: 'Aplicar descuento general', open: open, onClose: handleClose },
        React.createElement(BodyGeneralDiscountDialogComponent, { onApply: handleClose })));
});
export default GeneralDiscountDialogComponent;
