import React, { useEffect, useState } from 'react';

import LinearProgress from '@mui/material/LinearProgress';

import DeleteDialog from '../../DeleteDialog';

import PdfExport from '../PdfExport';
import XlsExport from '../XlsExport';

import Pagination from './Pagination';
import Table from './TableContainer';
import Head from './Head';
import Body from './Body';
import { actions } from '../../../apollo/cache';

const TableComponent = props => {
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    return () => {
      actions([]);
    };
  }, []);

  const handleLoading = isLoading => {
    setIsLoading(isLoading);
  };

  return (
    <>
      {isLoading && <LinearProgress color='secondary' />}
      <Table>
        <Head Rows={props.Rows} />
        <Body
          handleLoading={handleLoading}
          Rows={props.Rows}
          operation={props.operation}
          gql={props.get}
          edit={props.edit}
          noEdit={!props.edit}
          noDelete={!props.delete}
          extraParams={props.extraParams}
        />
      </Table>
      {!props.noPagination && <Pagination />}
      {props.delete && <DeleteDialog delete={props.delete} operation={props.operation[1]} />}
      <PdfExport title={props.title} gql={props.get} keys={props.Rows} />
      <XlsExport title={props.title} gql={props.get} keys={props.Rows} />
    </>
  );
};

export default TableComponent;
