import React, { useState } from 'react';
import { useReactiveVar } from '@apollo/client';
import TextFieldComponent from '../../../../presentationals/FormComponents/TextField';
import { inputError } from '../../../../apollo/cache';
import FooterDialogComponent from '../../../../presentationals/Dialog/Footer';
import DeleteCashFlowButtonComponent from '../../Form/DeleteButton';
var BodyDeleteCashFlowComponent = function () {
    var errors = useReactiveVar(inputError);
    var _a = useState(''), value = _a[0], setValue = _a[1];
    var handleChange = function (name, value) {
        setValue(value);
    };
    return (React.createElement("div", { className: 'flex flex-1 flex-col' },
        React.createElement("div", { className: 'grid grid-cols-1 sm:grid-cols-4 flex-1 flex-col basis-0 overflow-auto' },
            React.createElement("div", { className: 'flex flex-1 flex-col px-4 py-3' },
                React.createElement(TextFieldComponent, { name: 'pin', label: 'Pin de empleado con privilegios', value: value, error: errors.pin, onChange: handleChange, type: 'password' }))),
        React.createElement(FooterDialogComponent, null,
            React.createElement("div", null),
            React.createElement(DeleteCashFlowButtonComponent, { pin: value }))));
};
export default BodyDeleteCashFlowComponent;
