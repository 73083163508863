var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React, { useEffect, useState } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import RHFSelectFieldComponent from '../../../../../presentationals/RHForm/SelectField';
import useApolloLazyQuery from '../../../../../hooks/useApolloLazyQuery';
import GQL_PRODUCT from '../../../../../apollo/gql/product';
import CONSTANTS_ERROR from '../../../../../constants/error';
import getInputErrorFormat from '../../../../sale.v2/Action/helpers/getInputErrorFormat';
var ProductPresentationFieldProductManufactureListComponent = function (props) {
    var _a = useFormContext(), control = _a.control, resetField = _a.resetField;
    var value = useController({
        name: "".concat(props.formName, ".productId"),
        control: control,
    }).field.value;
    var onChange = useController({
        name: "".concat(props.formName, ".productPresentationId"),
        control: control,
    }).field.onChange;
    var _b = useApolloLazyQuery(GQL_PRODUCT.GET_PRODUCT_BY_ID), getProductPresentations = _b[0], loading = _b[1].loading;
    var _c = useState([]), options = _c[0], setOptions = _c[1];
    useEffect(function () {
        if (value) {
            getProductPresentations({ variables: { id: value } })
                .then(function (_a) {
                var data = _a.data;
                if (!data)
                    throw new Error(CONSTANTS_ERROR.PRODUCT_NOT_FOUND);
                var ProductPresentations = __spreadArray([data.PresentationBase], data.ProductPresentations, true).map(function (el) { return ({
                    value: el.id,
                    label: el.name,
                }); });
                setOptions(ProductPresentations);
                onChange(ProductPresentations[0].value);
            })
                .catch(function (e) { return getInputErrorFormat(e); });
        }
        else {
            resetField("".concat(props.formName, ".productPresentationId"));
            setOptions([]);
        }
    }, [value]);
    return (React.createElement(RHFSelectFieldComponent, { label: 'Presentaci\u00F3n del producto', disabled: loading, name: "".concat(props.formName, ".productPresentationId"), options: options }));
};
export default ProductPresentationFieldProductManufactureListComponent;
