import React from 'react';
import { Checkbox, FormControlLabel } from '@mui/material';
import SelectFieldComponent from '../../../../presentationals/FormComponents/SelectField';
import TextFieldComponent from '../../../../presentationals/FormComponents/TextField';
import SelectClosedResponseTextFieldComponent from './SelectClosedResponseTextField';
var ItemApplySurveyComponent = function (props) {
    var options = Array.from(Array(10).keys()).map(function (number) { return ({
        value: (number + 1).toString(),
        label: number + 1,
    }); });
    var handleChange = function (name, value) {
        props.onChange(props.id, value);
    };
    var handleCheckboxChange = function (e, isChecked) {
        props.onChange(props.id, isChecked);
    };
    if (props.type === 2)
        return (React.createElement(SelectFieldComponent, { name: 'question', label: props.label, value: props.value || '', onChange: handleChange, options: options }));
    if (props.type === 3)
        return (React.createElement(SelectClosedResponseTextFieldComponent, { label: props.label, value: props.value || '', onChange: handleChange }));
    if (props.type === 4)
        return (React.createElement(FormControlLabel, { control: React.createElement(Checkbox, { checked: !!props.value, onChange: handleCheckboxChange }), label: props.label }));
    if (props.type === 101)
        return React.createElement("div", { className: 'font-medium text-xl' }, props.label);
    if (props.type === 102)
        return React.createElement("div", { className: 'font-medium text-lg' }, props.label);
    if (props.type === 103)
        return React.createElement("div", { className: 'font-medium text-base' }, props.label);
    return (React.createElement(TextFieldComponent, { name: 'question', label: props.label, value: props.value || '', onChange: handleChange }));
};
export default ItemApplySurveyComponent;
