import getSaleTableColumnConfiguration from '../../../helpers/getSaleTableColumnConfiguration';
import getSaleServerColumnName from './getServerColumnName';
var getSaleColumnsToQuery = function () {
    var settings = getSaleTableColumnConfiguration();
    var data = Object.keys(settings)
        .filter(function (el) { return settings[el]; })
        .map(function (el) { return getSaleServerColumnName(el); });
    return data.join(' ');
};
export default getSaleColumnsToQuery;
