import React, { forwardRef, useImperativeHandle, useState } from 'react';
import Button from '@mui/material/Button';
import ReportOutlinedIcon from '@mui/icons-material/ReportOutlined';
import DialogComponent from '../../..';
var ConfirmRequestDialogComponent = forwardRef(function (props, ref) {
    var _a = useState(false), open = _a[0], setOpen = _a[1];
    var handleClose = function () {
        setOpen(false);
    };
    var handleOpen = function () {
        setOpen(true);
    };
    useImperativeHandle(ref, function () { return ({
        open: handleOpen,
        close: handleClose,
    }); });
    return (React.createElement(DialogComponent, { open: open, inherit: true, hideHeaderCloseButton: true },
        React.createElement("div", { className: 'flex flex-col ' },
            React.createElement("div", { className: 'text-center text-orange-500 mt-2' },
                React.createElement(ReportOutlinedIcon, { className: ' text-[100px]' })),
            React.createElement("div", { className: 'text-xl text-center mt-5' },
                "\u00BFEst\u00E1s seguro que deseas continuar con esta acci\u00F3n?",
                React.createElement("div", { className: 'text-sm text-slate-700 text-center' }, "Esta opci\u00F3n es irreversible")),
            React.createElement("div", { className: 'flex flex-row' },
                React.createElement("div", { className: 'flex flex-1 ml-5 mt-10 mb-5' },
                    React.createElement(Button, { variant: props.variant || 'contained', onClick: handleClose, color: 'inherit', className: ' text-black', disabled: props.loading }, "No, lo pensar\u00E9 de nuevo")),
                React.createElement("div", { className: 'mr-5 mt-10 mb-5' },
                    React.createElement(Button, { variant: 'contained', disabled: props.loading, color: props.color || 'success', onClick: props.onClick, startIcon: props.startIcon }, "Si, estoy seguro"))))));
});
export default ConfirmRequestDialogComponent;
