var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useEffect, useState } from 'react';
import { useReactiveVar } from '@apollo/client';
import FooterDialogComponent from '../../../../../presentationals/Dialog/Footer';
import CancelPaymentButtonComponent from './CancelButton';
import TextFieldComponent from '../../../../../presentationals/FormComponents/TextField';
import getSatCancelPaymentOptions from '../../../../../helpers/getSATCancelPaymentOptions';
import getDefaultCancelPaymentValues from './helpers/getDefaultValues';
import { inputError } from '../../../../../apollo/cache';
import SelectFieldComponent from '../../../../../presentationals/FormComponents/SelectField';
import useApolloLazyQuery from '../../../../../hooks/useApolloLazyQuery';
import GQL_TRANSACTION from '../../../../../apollo/gql/transaction';
import LoadingProgress from '../../../../../presentationals/LoadingProgress';
import ErrorDataComponent from '../../../../../presentationals/ErrorData';
import { dateFormat } from '../../../../../helpers';
var BodyCancelPaymentDialogComponent = function (props) {
    var errors = useReactiveVar(inputError);
    var satCancelPaymentOptions = getSatCancelPaymentOptions();
    var defaultValues = getDefaultCancelPaymentValues();
    var _a = useState(defaultValues), values = _a[0], setValues = _a[1];
    var _b = useApolloLazyQuery(GQL_TRANSACTION.GET_TRANSACTION_BY_ID), getData = _b[0], _c = _b[1], loading = _c.loading, data = _c.data;
    useEffect(function () {
        if (props.transactionId)
            getData({ variables: { id: props.transactionId } });
    }, [props.transactionId]);
    var handleChange = function (name, value) {
        setValues(function (values) {
            var _a;
            return (__assign(__assign({}, values), (_a = {}, _a[name] = value, _a)));
        });
    };
    if (loading)
        return React.createElement(LoadingProgress, null);
    if (!data)
        return React.createElement(ErrorDataComponent, null);
    if (data.status === 0)
        return (React.createElement("div", { className: 'px-4 py-3' },
            "La transacci\u00F3n fue cancelada el d\u00EDa ",
            dateFormat(data.deletedAt),
            "."));
    return (React.createElement("div", { className: 'flex flex-1 flex-col' },
        React.createElement("div", { className: 'grid grid-cols-1 sm:grid-cols-4 flex-1 flex-col basis-0 overflow-auto px-4 py-3' },
            React.createElement("div", { className: 'flex flex-1 flex-col' },
                data.invoiceComplementId && (React.createElement(React.Fragment, null,
                    React.createElement(SelectFieldComponent, { label: 'Motivo de cancelaci\u00F3n', name: 'reason', value: values.reason, error: errors.reason, onChange: handleChange, options: satCancelPaymentOptions }),
                    React.createElement(TextFieldComponent, { label: 'UUID relacionado', name: 'relatedFolio', disabled: values.reason !== '01', value: values.relatedFolio, error: errors.relatedFolio, onChange: handleChange }))),
                React.createElement(TextFieldComponent, { label: 'Contrase\u00F1a con privilegios', name: 'userPassword', value: values.userPassword, error: errors.userPassword, onChange: handleChange, type: 'password' }))),
        React.createElement(FooterDialogComponent, null,
            React.createElement("div", null),
            React.createElement(CancelPaymentButtonComponent, { values: values, transactionId: props.transactionId, accountReceivableId: props.accountReceivableId, isComplement: !!data.invoiceComplementId }))));
};
export default BodyCancelPaymentDialogComponent;
