import jsPDF from 'jspdf';

import client from '../../apollo';

import { phoneFormat, dateFormat, hourFormat } from '../../helpers';

import GQL from '../../containers/calendar/_gql';
import GQL_PRINT from '../../containers/print/_gql';
import { getCompanyData } from './cashClosing.80mm';

const getCalendar = async id => {
  try {
    const { data } = await client.mutate({
      mutation: GQL.GET_BY_ID,
      variables: { id },
    });
    return data.calendar;
  } catch (e) {
    console.log(e);
    return e;
  }
};

const print = async (id, type) => {
  try {
    const { data } = await client.mutate({
      mutation: GQL_PRINT.PRINT,
      variables: { id, type },
    });
    return data.print;
  } catch (e) {
    console.log(e);
    return e;
  }
};

export default async calendarId => {
  const companyData = await getCompanyData();
  if (parseInt(companyData.printClient) === 2) {
    await print(calendarId, 'calendar');
    return;
  }

  const data = await getCalendar(calendarId);

  const { Branch, Customer, start } = data;

  const addressLineBranch = `${Branch.addressLine1}, ${Branch.addressLine2}`.trim();

  const doc = new jsPDF({
    unit: 'mm',
    format: [200, 600],
  });

  doc.setProperties({
    title: `doc_${data.title}`,
  });

  let breakline = 10;

  doc.setFontSize(10);
  doc.setFont('times', 'bold');
  doc.text(`${Branch.name}`, 35, breakline, 'center');
  doc.setFontSize(8);
  doc.setFont('times', 'normal');

  breakline += 3;
  doc.text(doc.splitTextToSize(addressLineBranch, 60), 35, breakline, 'center');

  breakline = addressLineBranch.length >= 60 ? breakline + 6 : breakline + 3;
  doc.text(`${Branch.city}, ${Branch.state}, C.P. ${Branch.zipCode}`, 35, breakline, 'center');

  breakline += 3;
  doc.text(`Tel. ${phoneFormat(Branch.phone)}`, 35, breakline, 'center');

  breakline += 5;
  doc.text('Paciente', 5, breakline, 'left');

  breakline += 4;
  doc.setFontSize(12);
  const customerName = `${Customer.firstName} ${Customer.lastName || ''}`;
  doc.text(customerName, 5, breakline, 'left');

  breakline += 7;
  doc.setFontSize(8);
  doc.text('Siguiente Cita', 5, breakline, 'left');

  breakline += 4;
  doc.setFontSize(12);
  doc.text(`${dateFormat(start)} ${hourFormat(start)}`, 5, breakline, 'left');

  breakline += 7;
  doc.setFontSize(10);
  doc.text('Te invitamos a llegar puntual a tu cita.', 5, breakline, 'left');
  breakline += 4;
  doc.setFontSize(10);
  doc.text('Para cancelaciones, hacerlo 24 horas antes.', 5, breakline, 'left');

  window.open(doc.output('bloburl'), '_blank');
};
