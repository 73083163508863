import React, { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import GeneralCreatePurchaseOrderDialogComponent from './Dialog';
import getDefaultCreateGeneralPurchaseOrderValues from './Dialog/Body/helpers/getDefaultValues';
import ActionSubtitlePurchaseOrderComponent from './ActionSubtitle';
import ProviderSubtitlePurchaseOrderComponent from './ProviderSubtitle';
var GeneralCreatePurchaseOrderComponent = forwardRef(function (props, ref) {
    var generalCreatePurchaseOrderDialogRef = useRef(null);
    var defaultValues = getDefaultCreateGeneralPurchaseOrderValues();
    var _a = useState(defaultValues), values = _a[0], setValues = _a[1];
    var handleClick = function () {
        var _a;
        (_a = generalCreatePurchaseOrderDialogRef.current) === null || _a === void 0 ? void 0 : _a.open();
    };
    var handleGetValues = function (values) {
        setValues(values);
    };
    var getValues = function () { return values; };
    useImperativeHandle(ref, function () { return ({
        getValues: getValues,
    }); });
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { role: 'button', tabIndex: 0, onClick: handleClick, className: 'flex border-0 border-r border-solid items-center py-2 border-gray-200 px-3 sm:px-6 sm:py-2 cursor-pointer' },
            React.createElement("div", { className: 'flex flex-col' },
                React.createElement("div", { className: 'text-sky-600 font-medium leading-none mb-1 whitespace-nowrap' }, "Configuraci\u00F3n General"),
                React.createElement(ActionSubtitlePurchaseOrderComponent, { action: values.action }),
                values.providerId && (React.createElement(ProviderSubtitlePurchaseOrderComponent, { providerId: values.providerId })))),
        React.createElement(GeneralCreatePurchaseOrderDialogComponent, { onChange: props.onChange, getValues: handleGetValues, defaultValues: values, ref: generalCreatePurchaseOrderDialogRef })));
});
export default GeneralCreatePurchaseOrderComponent;
