var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { create } from 'zustand';
var useSnackbarDialog = create()(function (set) { return ({
    isOpen: false,
    time: null,
    label: '',
    severity: 'success',
    vertical: 'bottom',
    horizontal: 'center',
    setSnackbar: function (props) {
        return set(function (state) { return (__assign(__assign({}, state), props)); });
    },
}); });
export var getSnackbarDialogState = useSnackbarDialog.getState;
export default useSnackbarDialog;
