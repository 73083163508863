import React, { useEffect, useState } from 'react';
import Title from '../../../../../../sale.v2/Action/Customer/Form/Create/Body/helpers/title';
import Switch from './Switch';
import getSession from '../../../../../../../helpers/getSession';
import useGetSession from '../../../../../../../hooks/useGetSession';
var FormElementVisivilityDialogSettingButtonFooterFormCalendarComponent = function () {
    var _a, _b;
    var _c = useState('Recordatorio'), reminderLabel = _c[0], setReminderLabel = _c[1];
    var session = useGetSession();
    var adviserLabel = ((_a = session === null || session === void 0 ? void 0 : session.Company) === null || _a === void 0 ? void 0 : _a.activity) === 3 ? 'terapeuta' : 'asesor';
    var customerLabel = ((_b = session === null || session === void 0 ? void 0 : session.Company) === null || _b === void 0 ? void 0 : _b.activity) === 3 ? 'paciente' : 'cliente';
    useEffect(function () {
        var _a;
        var session = getSession();
        if (((_a = session === null || session === void 0 ? void 0 : session.Company) === null || _a === void 0 ? void 0 : _a.id.toString()) === '116')
            setReminderLabel('Fecha de evento');
    }, []);
    return (React.createElement("div", { className: 'px-4 py-2' },
        React.createElement(Title, { label: 'Visualizaci\u00F3n de elementos del formulario' }),
        React.createElement("div", { className: 'grid grid-cols-1 md:grid-cols-4 gap-2' },
            React.createElement("div", null,
                React.createElement(Switch, { name: 'title', label: 'A\u00F1ade un t\u00EDtulo' }),
                React.createElement(Switch, { name: 'diagnosis', label: 'A\u00F1adir diagn\u00F3stico' }),
                React.createElement(Switch, { name: 'adviser', label: "Seleccionar ".concat(adviserLabel) })),
            React.createElement("div", null,
                React.createElement(Switch, { name: 'customer', label: "A\u00F1ade un ".concat(customerLabel) }),
                React.createElement(Switch, { name: 'description', label: 'A\u00F1adir descripci\u00F3n' }),
                React.createElement(Switch, { name: 'sessions', label: 'Crear varias sesiones del mismo evento' })),
            React.createElement("div", null,
                React.createElement(Switch, { name: 'reminder', label: reminderLabel }),
                React.createElement(Switch, { name: 'permissions', label: 'Visibilidad' })),
            React.createElement("div", null,
                React.createElement(Switch, { name: 'address', label: 'A\u00F1adir domicilio' }),
                React.createElement(Switch, { name: 'tagId', label: 'Etiqueta' })))));
};
export default FormElementVisivilityDialogSettingButtonFooterFormCalendarComponent;
