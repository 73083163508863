var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import ButtonFooterDialogComponent from '../../../../../presentationals/Dialog/Footer/Button';
import getInputErrorFormat from '../../../../sale.v2/Action/helpers/getInputErrorFormat';
import GQL_REQUISITION from '../../../../../apollo/gql/requisition';
import useApolloLazyQuery from '../../../../../hooks/useApolloLazyQuery';
import GQL_PRODUCT from '../../../../../apollo/gql/product';
import useSnackbarDialog from '../../../../../store/snackbar/snackbarDialog';
var CreateRequisitionButtonComponent = function (props) {
    var setSnackbar = useSnackbarDialog().setSnackbar;
    var navigate = useNavigate();
    var createRequisition = useMutation(GQL_REQUISITION.CREATE_REQUISITION, {
        update: function (cache, _a) {
            var data = _a.data;
            cache.modify({
                fields: {
                    requisitions: function (cachedRequisitions) {
                        var newRequisitionRef = cache.writeFragment({
                            data: __assign({}, data.createRequisition),
                            fragment: GQL_REQUISITION.FRAGMENT_REQUISITION,
                        });
                        return __assign(__assign({}, cachedRequisitions), { rows: __spreadArray(__spreadArray([], cachedRequisitions.rows, true), [newRequisitionRef], false) });
                    },
                },
            });
        },
    })[0];
    var getProductById = useApolloLazyQuery(GQL_PRODUCT.GET_PRODUCT_BY_BARCODE)[0];
    var handleClick = function () { return __awaiter(void 0, void 0, void 0, function () {
        var values, variables, error_1;
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    _b.trys.push([0, 3, , 4]);
                    values = props.onClick();
                    if (values.Products.length === 0)
                        throw new Error('Para continuar, debes añadir al menos un producto a la lista.');
                    _a = {
                        type: 2,
                        branchId: values.branchId,
                        description: values.description || ''
                    };
                    return [4 /*yield*/, Promise.all(values.Products.map(function (el) { return __awaiter(void 0, void 0, void 0, function () {
                            var Product;
                            return __generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0:
                                        if (el.productPresentationId)
                                            return [2 /*return*/, {
                                                    productPresentationId: el.productPresentationId,
                                                    quantity: el.quantity,
                                                }];
                                        return [4 /*yield*/, getProductById({
                                                variables: { barcode: el.barcode },
                                            })];
                                    case 1:
                                        Product = (_a.sent()).data;
                                        return [2 /*return*/, {
                                                productPresentationId: Product === null || Product === void 0 ? void 0 : Product.ProductPresentation.id,
                                                quantity: el.quantity,
                                            }];
                                }
                            });
                        }); }))];
                case 1:
                    variables = (_a.Products = _b.sent(),
                        _a);
                    return [4 /*yield*/, createRequisition({ variables: variables })];
                case 2:
                    _b.sent();
                    props.onCreated();
                    setSnackbar({
                        isOpen: true,
                        label: 'La requisición de mercancia se creó exitosamente.',
                        severity: 'success',
                    });
                    navigate('..');
                    return [3 /*break*/, 4];
                case 3:
                    error_1 = _b.sent();
                    getInputErrorFormat(error_1);
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    }); };
    return (React.createElement(ButtonFooterDialogComponent, { onClick: handleClick }, "Almacenar requisici\u00F3n"));
};
export default CreateRequisitionButtonComponent;
