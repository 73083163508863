import { useReactiveVar } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import { branchSaleV2, isSaleBranchFormOpen } from '../../../../../apollo/cache';
import useGetBranches from '../../../../../hooks/useGetBranches';
import Button from '../../helpers/Button';
var ButtonBranchActionSaleV2Component = function () {
    var branchSale = useReactiveVar(branchSaleV2).branchSale;
    var _a = useState(''), currentBranch = _a[0], setCurrentBranch = _a[1];
    var data = useGetBranches(null, 'select').data;
    useEffect(function () {
        if (data.rows && branchSale) {
            var Branches = data.rows;
            var currentBranch_1 = Branches.find(function (el) { return parseInt(el.value) === parseInt(branchSale.toString()); });
            if (!currentBranch_1)
                return;
            setCurrentBranch(currentBranch_1.label);
        }
    }, [data, branchSale]);
    var handleClick = function () {
        isSaleBranchFormOpen(true);
    };
    return React.createElement(Button, { title: 'Sucursal', subtitle: currentBranch, onClick: handleClick });
};
export default ButtonBranchActionSaleV2Component;
