import React from 'react';

import useTheme from '@mui/material/styles/useTheme';
import useMediaQuery from '@mui/material/useMediaQuery';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import getUrlQueries from '../../helpers/getUrlQueries';
import FormDialog from '../../presentationals/FormDialog';
import Plane from '../branch/PlaneZone/Plane';

import Time from '../../presentationals/Time';
import Agenda from './Agenda';
import useGetSession from '../../hooks/useGetSession';

const Component = props => {
  const session = useGetSession();
  const Theme = useTheme();
  const isSmallScreen = useMediaQuery(Theme.breakpoints.down('lg'));
  const screenQuery = getUrlQueries('screen');

  const handleClose = () => {
    props.handleClose();
  };

  return (
    <FormDialog
      title='Mapa de Sucursal'
      isOpen={props.isOpen}
      handleClose={handleClose}
      hideClose={screenQuery}
      hideAction
      fullScreen
      rightContent={
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Typography align='right' variant={isSmallScreen ? 'h6' : 'h3'}>
            <strong>
              <Time />
            </strong>
          </Typography>
        </div>
      }
    >
      <Grid container spacing={0}>
        <Grid item xs={12} md={8} align='center'>
          {session?.Company && <Plane branchId={session.Company.Branch.id} double />}
        </Grid>
        {!isSmallScreen && (
          <Grid item xs={12} md={4}>
            <Agenda />
          </Grid>
        )}
      </Grid>
    </FormDialog>
  );
};

export default Component;
