var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useState } from 'react';
import { useReactiveVar } from '@apollo/client';
import FooterDialogComponent from '../../Dialog/Footer';
import ActionTransactionAccountButtonComponent from './ActionButton';
import getDefaultTransactionAccountValues from './helpers/getDefaultValues';
import TextFieldComponent from '../../FormComponents/TextField';
import { inputError } from '../../../apollo/cache';
var FormTransactionAccountComponent = function (props) {
    var errors = useReactiveVar(inputError);
    var _a = useState(getDefaultTransactionAccountValues), values = _a[0], setValues = _a[1];
    var handleChange = function (name, value) {
        setValues(function (values) {
            var _a;
            return (__assign(__assign({}, values), (_a = {}, _a[name] = value, _a)));
        });
    };
    return (React.createElement("div", { className: 'flex flex-1 flex-col' },
        React.createElement("div", { className: 'grid grid-cols-1 sm:grid-cols-4 gap-4 flex-1 flex-col px-4 py-3' },
            React.createElement("div", { className: 'flex flex-1 flex-col overflow-y-auto' },
                React.createElement(TextFieldComponent, { label: 'Nombre del titular', name: 'name', value: values.name, error: errors.name, onChange: handleChange }),
                React.createElement(TextFieldComponent, { label: 'Banco', name: 'bank', value: values.bank, error: errors.bank, onChange: handleChange }),
                React.createElement(TextFieldComponent, { label: 'N\u00FAmero de cuenta ', name: 'bankAccount', value: values.bankAccount, error: errors.bankAccount, onChange: handleChange }),
                React.createElement(TextFieldComponent, { label: 'CLABE', name: 'clabe', value: values.clabe, error: errors.clabe, onChange: handleChange }))),
        React.createElement(FooterDialogComponent, null,
            React.createElement("div", null),
            React.createElement(ActionTransactionAccountButtonComponent, { label: props.buttonLabel, onClick: props.onButtonClick, values: values, loading: props.loading }))));
};
export default FormTransactionAccountComponent;
