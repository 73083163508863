import React, { useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import useApolloLazyQuery from '../../../../hooks/useApolloLazyQuery';
import GQL_BRANCH from '../../../../apollo/gql/branch';
import LoadingProgress from '../../../../presentationals/LoadingProgress';
import ErrorDataComponent from '../../../../presentationals/ErrorData';
import BodyBranchSettingsComponent from './Body';
import FooterDialogComponent from '../../../../presentationals/Dialog/Footer';
import EditBranchSettingsComponent from './EditButton';
import getDefaultBranchSettings from './helpers/getDefaultBranchSettingsValues';
var BranchSettingsComponent = function () {
    var params = useParams();
    var bodyBranchSettingsRef = useRef(null);
    var _a = useApolloLazyQuery(GQL_BRANCH.GET_BRANCH_CONFIG_BY_BRANCH_ID), getSettings = _a[0], _b = _a[1], loading = _b.loading, data = _b.data;
    useEffect(function () {
        if (params.branchId)
            getSettings({ variables: { id: params.branchId } });
    }, [params.branchId]);
    var handleEditButtonClick = function () { var _a; return ((_a = bodyBranchSettingsRef.current) === null || _a === void 0 ? void 0 : _a.getValues()) || getDefaultBranchSettings(); };
    if (loading)
        return React.createElement(LoadingProgress, null);
    if (!data)
        return React.createElement(ErrorDataComponent, null);
    return (React.createElement("div", { className: 'flex flex-1 flex-col' },
        React.createElement(BodyBranchSettingsComponent, { ref: bodyBranchSettingsRef, defaultValues: data }),
        React.createElement(FooterDialogComponent, null,
            React.createElement("div", null),
            React.createElement(EditBranchSettingsComponent, { onClick: handleEditButtonClick }))));
};
export default BranchSettingsComponent;
