var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { eachDayOfInterval } from 'date-fns';
import { filter } from '../../../../apollo/cache';
import { dateTimeFormat, objectArraySort } from '../../../../helpers';
var getDetailedSalesByDayArray = function (props) {
    var filterVar = filter();
    var interval = eachDayOfInterval({
        start: filterVar.start,
        end: filterVar.end,
    });
    var defaultDays = interval.map(function (date) { return ({
        day: dateTimeFormat(date, "d'/'MMM"),
        total: 0,
        qty: 0,
    }); });
    var defaultValues = props.branches
        .filter(function (el) { return props.multiBranchPermissions || el.id === props.sessionBranchId; })
        .map(function (el) { return ({
        id: el.id,
        name: el.name,
        days: defaultDays,
        total: 0,
        qty: 0,
    }); });
    var rows = props.Sales.reduce(function (array, sale) {
        return array.map(function (el) {
            return el.id === sale.branchId
                ? __assign(__assign({}, el), { days: el.days.map(function (day) {
                        return day.day === dateTimeFormat(sale.clientCreatedAt, "d'/'MMM")
                            ? __assign(__assign({}, day), { total: day.total + sale.total, qty: day.qty + 1 }) : day;
                    }) }) : el;
        });
    }, defaultValues).map(function (el) {
        var totals = el.days.reduce(function (array, elCh) { return ({
            total: array.total + elCh.total,
            qty: array.qty + elCh.qty,
        }); }, { total: 0, qty: 0 });
        return __assign(__assign({}, el), totals);
    });
    return objectArraySort(rows, 'total', 'number', 'desc');
};
export default getDetailedSalesByDayArray;
