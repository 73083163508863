import React, { useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import FooterDialogComponent from '../../../../presentationals/Dialog/Footer';
import EditButtonGeneralEditRoleComponent from './EditButton';
import { inputError } from '../../../../apollo/cache';
import LoadingProgress from '../../../../presentationals/LoadingProgress';
import ErrorDataComponent from '../../../../presentationals/ErrorData';
import useApolloLazyQuery from '../../../../hooks/useApolloLazyQuery';
import GQL_ROLE from '../../../../apollo/gql/role';
import DeleteButtonGeneralEditRoleComponent from './DeleteButton';
import BodyRoleComponent from '../../Create/Body';
var GeneralEditRoleComponent = function () {
    var editButtonGeneralEditRoleRef = useRef(null);
    var bodyRoleRef = useRef(null);
    var params = useParams();
    var _a = useApolloLazyQuery(GQL_ROLE.GET_ROLE_BY_ID), getById = _a[0], _b = _a[1], loading = _b.loading, data = _b.data;
    useEffect(function () {
        return function () {
            inputError({});
        };
    });
    useEffect(function () {
        if (params.roleId)
            getById({ variables: { id: params.roleId } });
    }, [params]);
    var handleEdit = function () {
        var _a;
        (_a = editButtonGeneralEditRoleRef.current) === null || _a === void 0 ? void 0 : _a.onPressEnter();
    };
    var handleGetValues = function () {
        var _a;
        return (_a = bodyRoleRef.current) === null || _a === void 0 ? void 0 : _a.getValues();
    };
    if (loading)
        return React.createElement(LoadingProgress, null);
    if (!data)
        return React.createElement(ErrorDataComponent, null);
    return (React.createElement("div", { className: 'flex flex-1 flex-col h-screen overflow-hidden mt-1' },
        React.createElement(BodyRoleComponent, { ref: bodyRoleRef, defaultValues: data, onPressEnter: handleEdit }),
        React.createElement(FooterDialogComponent, null,
            React.createElement("div", null,
                React.createElement(DeleteButtonGeneralEditRoleComponent, { roleName: data.name })),
            React.createElement(EditButtonGeneralEditRoleComponent, { getValues: handleGetValues, ref: editButtonGeneralEditRoleRef }))));
};
export default GeneralEditRoleComponent;
