import React from 'react';
import useGetBoxData from './helpers/useGetBoxData';
import ModuleV3Component from '../../presentationals/Module.v3';
import CreateBoxComponent from './Create';
import EditBoxComponent from './Edit';
var BoxComponent = function () {
    var _a = useGetBoxData({ getByFilter: true }), data = _a.data, loading = _a.loading;
    var header = [
        { Header: 'Nombre', accessor: 'name' },
        { Header: 'Medidas', accessor: 'size' },
        { Header: 'Peso máximo', accessor: 'weight' },
    ];
    return (React.createElement(React.Fragment, null,
        React.createElement(ModuleV3Component, { title: 'Cajas y paquetes para env\u00EDos', data: data, header: header, isLoading: loading }),
        React.createElement(CreateBoxComponent, null),
        React.createElement(EditBoxComponent, null)));
};
export default BoxComponent;
