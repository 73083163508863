import React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { FixedSizeList } from 'react-window';
import './style.css';
import { FormLabel } from '@mui/material';

function renderRow(props) {
  const { data, index, style } = props;
  return React.cloneElement(data[index], {
    style: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      display: 'block',
      ...style,
    },
  });
}

const DivRef = other =>
  React.forwardRef((props2, ref2) => <div ref={ref2} {...props2} {...other} />);

const ListboxComponent = React.forwardRef((props, ref) => {
  const { children, ...other } = props;
  const itemCount = Array.isArray(children) ? children.length : 0;
  const itemSize = 48;
  const outerElementType = React.useMemo(() => {
    return DivRef(other);
  }, []); // eslint-disable-line
  return (
    <div ref={ref}>
      <FixedSizeList
        style={{ padding: 0, height: Math.min(8, itemCount) * itemSize, maxHeight: 'auto' }}
        itemData={children}
        height={250}
        width='100%'
        outerElementType={outerElementType}
        innerElementType='ul'
        itemSize={itemSize}
        overscanCount={5}
        itemCount={itemCount}
      >
        {renderRow}
      </FixedSizeList>
    </div>
  );
});

const AutocompleteComponent = ({
  onChange,
  helperText,
  error,
  label,
  onFocus,
  onBlur,
  extra,
  disabled,
  ...props
}) => {
  const handleChange = (e, newValue) => {
    const tmp = newValue || (props.defaultValue ? props.defaultValue : null);
    onChange(props.name, tmp);
  };
  return (
    <div className='flex flex-col'>
      <FormLabel htmlFor={props.name}>{label}</FormLabel>
      <div className='flex justify-between items-center'>
        <div className='flex flex-1'>
          <Autocomplete
            id={props.name}
            disableListWrap
            value={props.value}
            onChange={handleChange}
            onFocus={onFocus}
            onBlur={onBlur}
            ListboxComponent={ListboxComponent}
            options={props.options || []}
            getOptionLabel={option => option.label}
            className='w-full'
            noOptionsText='No encontramos resultados'
            disabled={disabled}
            renderInput={params => (
              <TextField
                {...params}
                variant={props.variant || 'outlined'}
                fullWidth
                autoFocus={props.autoFocus}
                error={!!error}
                helperText={error || helperText}
                size={props.size || 'small'}
              />
            )}
          />
        </div>
        <div>{extra || ''}</div>
      </div>
    </div>
  );
};

export default AutocompleteComponent;
