import React, { useRef } from 'react';
import ClearButtonFooterCreateProviderComponent from './Footer/ClearButton';
import CreateButtonFooterCreateProviderComponent from './Footer/CreateButton';
import FooterDialogComponent from '../../../presentationals/Dialog/Footer';
import BodyProviderComponent from './Body';
var CreateProviderComponent = function () {
    var bodyProviderRef = useRef(null);
    var createButtonFooterCreateProviderRef = useRef(null);
    var handleCreate = function () {
        var _a;
        (_a = createButtonFooterCreateProviderRef.current) === null || _a === void 0 ? void 0 : _a.onPressEnter();
    };
    var handleGetValues = function () {
        var _a;
        return (_a = bodyProviderRef.current) === null || _a === void 0 ? void 0 : _a.getValues();
    };
    var handleClear = function () {
        var _a;
        (_a = bodyProviderRef.current) === null || _a === void 0 ? void 0 : _a.onClear();
    };
    return (React.createElement("div", { className: 'flex flex-1 flex-col overflow-hidden' },
        React.createElement(BodyProviderComponent, { ref: bodyProviderRef, onPressEnter: handleCreate }),
        React.createElement(FooterDialogComponent, null,
            React.createElement("div", null),
            React.createElement("div", null,
                React.createElement(ClearButtonFooterCreateProviderComponent, { onClick: handleClear }),
                React.createElement(CreateButtonFooterCreateProviderComponent, { getValues: handleGetValues, ref: createButtonFooterCreateProviderRef })))));
};
export default CreateProviderComponent;
