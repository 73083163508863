import React from 'react';
import LinkMenuDashboardComponent from '../../helpers/Link';
import useGetSession from '../../../../../../hooks/useGetSession';
var ReceivableLinkBlockTwoMenuDashboardComponent = function () {
    var _a;
    var session = useGetSession();
    var customerLabel = ((_a = session === null || session === void 0 ? void 0 : session.Company) === null || _a === void 0 ? void 0 : _a.activity) === 3 ? 'pacientes' : 'clientes';
    return (React.createElement(LinkMenuDashboardComponent, { url: 'customer/receivable', primary: 'Cuentas por cobrar / Cobranza', secondary: "Revisa y da seguimiento a las deudas de tus ".concat(customerLabel, ".") }));
};
export default ReceivableLinkBlockTwoMenuDashboardComponent;
