var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { useFormContext, useWatch } from 'react-hook-form';
var RHFBooleanCheckboxComponent = function (_a) {
    var config = _a.config, props = __rest(_a, ["config"]);
    var _b = useFormContext(), isSubmitting = _b.formState.isSubmitting, register = _b.register;
    var checked = useWatch({
        name: props.name,
    });
    return (React.createElement(FormControlLabel, { disabled: props.disabled || isSubmitting, control: React.createElement(Checkbox, __assign({}, register(props.name, config), { checked: checked, disabled: props.disabled || isSubmitting })), label: props.label }));
};
export default RHFBooleanCheckboxComponent;
