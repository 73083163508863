import React, { useEffect } from 'react';

import SearchIcon from '@mui/icons-material/Search';

import { IconButton } from '@mui/material';

import useApolloLazyQuery from '../../../../../../../../hooks/useApolloLazyQuery';

import GQL from '../../../../../../../product.v2/_gql';
import getInputErrorFormat from '../../../../../../Action/helpers/getInputErrorFormat';
import {
  editProductSaleV2,
  productListSaleV2,
  productSaleV2,
  searchProductField,
} from '../../../../../../../../apollo/cache';

const SearchButtonItemTableSearchFormCustomerActionSaleV2Component = props => {
  const [get, { data, loading }] = useApolloLazyQuery(GQL.GET_BY_ID);

  useEffect(() => {
    if (data && !loading) {
      productSaleV2({
        ...data,
        unitBase: data.PresentationBase.name,
        cost: data.PresentationBase.cost,
      });
      searchProductField(data.name);
      productListSaleV2([]);
      editProductSaleV2(true);
    }
  }, [loading, data]);

  const handleClick = e => {
    try {
      e.stopPropagation();
      get({ variables: { id: props.id } });
    } catch (e) {
      getInputErrorFormat(e);
    }
  };
  return (
    <IconButton
      disabled={loading}
      edge='end'
      aria-label='edit'
      onClick={handleClick}
      className='mr-1'
    >
      <SearchIcon />
    </IconButton>
  );
};

export default SearchButtonItemTableSearchFormCustomerActionSaleV2Component;
