import React, { useEffect, useState } from 'react';

import { useLazyQuery } from '@apollo/client';

import Menu from '@mui/material/Menu';
import LinearProgress from '@mui/material/LinearProgress';

import CustomerCalendarCheck from '../../CustomerCalendarCheck';

import GQL_NOTIFICATION from '../../../../../apollo/gql/notifications';

import MenuItem from './MenuItem';

const NofiticationDialogComponent = props => {
  const [notifications, setNotifications] = useState([]);
  const [getNotifications, { data, loading }] = useLazyQuery(GQL_NOTIFICATION.GET_ALL);
  useEffect(() => {
    if (props.anchorEl && !props.data) getNotifications();
  }, [props.anchorEl]);

  useEffect(() => {
    if (props.data) setNotifications(props.data.notifications.rows);
  }, [props.data]);

  useEffect(() => {
    if (data) setNotifications(data.notifications.rows);
  }, [data]);

  return (
    <>
      <Menu
        id='notification-menu'
        anchorEl={props.anchorEl}
        keepMounted
        classes={{
          paper: 'bg-slate-100 w-[320px]',
        }}
        open={Boolean(props.anchorEl)}
        onClose={props.handleClose}
      >
        {loading && <LinearProgress color='secondary' />}
        <div className='bg-white mx-3 my-1 overflow-auto'>
          {notifications.length > 0 ? (
            notifications.map(el => (
              <MenuItem
                key={el.id}
                id={el.id}
                dataId={el.dataId}
                type={el.type}
                title={el.title}
                message={el.message}
                saleId={el.saleId}
                customerId={el.customerId}
                createdAt={el.createdAt}
                status={el.status}
                from={el.from}
              />
            ))
          ) : (
            <div className='p-2 text-center'>Sin notificaciones para mostrar</div>
          )}
        </div>
      </Menu>
      <CustomerCalendarCheck />
    </>
  );
};

export default NofiticationDialogComponent;
