var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { TableCell, TableRow } from '@mui/material';
import React, { useEffect } from 'react';
import useApolloLazyQuery from '../../../../../../hooks/useApolloLazyQuery';
import GQL_PRODUCT from '../../../../../../apollo/gql/product';
import DeleteProductAdjustInventoryButtonComponent from '../../../../../inventory.v2/CreateForm/Products/Table/DeleteButton';
var TableRowProductsRequisitionComponent = function (props) {
    var _a = useApolloLazyQuery(GQL_PRODUCT.GET_PRODUCT_BY_BARCODE), getData = _a[0], _b = _a[1], loading = _b.loading, data = _b.data, error = _b.error;
    useEffect(function () {
        if (error)
            props.onError(props.index);
    }, [error]);
    useEffect(function () {
        var variables = props.barcode
            ? { barcode: props.barcode }
            : props.productPresentationId
                ? { productPresentationId: props.productPresentationId }
                : null;
        if (!variables || !props.branchId)
            return;
        getData({
            variables: __assign(__assign({}, variables), { branchId: props.branchId }),
        });
    }, [props.branchId]);
    return (React.createElement(TableRow, { hover: true },
        React.createElement(TableCell, null,
            React.createElement(DeleteProductAdjustInventoryButtonComponent, { loading: loading, onClick: props.onDelete, index: props.index, productPresentationId: (data === null || data === void 0 ? void 0 : data.ProductPresentation) ? data === null || data === void 0 ? void 0 : data.ProductPresentation.id : null })),
        React.createElement(TableCell, { className: 'whitespace-nowrap' },
            React.createElement("div", null, data ? data.name : ''),
            React.createElement("div", { className: 'text-xs text-slate-600' }, data ? "".concat(data.sku, " ").concat(data.barcode).trim() : '')),
        React.createElement(TableCell, { className: 'whitespace-nowrap' }, (data === null || data === void 0 ? void 0 : data.ProductPresentation) ? data.ProductPresentation.name : ''),
        React.createElement(TableCell, { align: 'center' }, (data === null || data === void 0 ? void 0 : data.Inventory) ? data.Inventory.quantity : 0),
        React.createElement(TableCell, { align: 'center' }, props.quantity || 0)));
};
export default TableRowProductsRequisitionComponent;
