import React from 'react';

import { Button } from '@mui/material';

import { isEditPurchaseOrderItemOpen } from '../../../../../../../apollo/cache';

const CloseButtonFooterItemEditFormProductComponent = () => {
  const handleClick = () => {
    isEditPurchaseOrderItemOpen(false);
  };
  return (
    <Button color='inherit' onClick={handleClick}>
      Cerrar
    </Button>
  );
};

export default CloseButtonFooterItemEditFormProductComponent;
