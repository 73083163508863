import React, { useEffect, useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import LoadingProgress from '../../../presentationals/LoadingProgress';
import ErrorDataComponent from '../../../presentationals/ErrorData';
import getRentRows from './helpers/getRentRows';
import ItemBodyRentComponent from './Item';
var BodyRentComponent = function (props) {
    var _a = useState([]), data = _a[0], setData = _a[1];
    useEffect(function () {
        if (props.data) {
            var data_1 = getRentRows(props.data, props.deliveryStatus);
            setData(data_1);
        }
    }, [props.data, props.deliveryStatus]);
    if (props.loading)
        return React.createElement(LoadingProgress, null);
    if (!props.data)
        return React.createElement(ErrorDataComponent, null);
    return (React.createElement(TableContainer, null,
        React.createElement(Table, { className: 'sticky', stickyHeader: true, size: 'small' },
            React.createElement(TableHead, null,
                [5, 6].includes(props.deliveryStatus) && (React.createElement(TableRow, null,
                    React.createElement(TableCell, null),
                    React.createElement(TableCell, { className: 'bg-slate-200', align: 'center', colSpan: 2 }, "Hora"),
                    React.createElement(TableCell, { colSpan: 4 }))),
                React.createElement(TableRow, null,
                    React.createElement(TableCell, { className: 'bg-slate-300 font-medium' }, "D\u00EDa"),
                    [0, 5, 6].includes(props.deliveryStatus) && (React.createElement(TableCell, { className: 'bg-slate-200' }, "Entrega")),
                    [4, 5, 6].includes(props.deliveryStatus) && (React.createElement(TableCell, { className: 'bg-slate-200' }, "Recolecci\u00F3n")),
                    React.createElement(TableCell, null, "Folio"),
                    React.createElement(TableCell, null, "Cliente"),
                    React.createElement(TableCell, null, "Importe"))),
            React.createElement(TableBody, null, data.map(function (el) {
                return el.Rents.map(function (elCh, i) { return (React.createElement(ItemBodyRentComponent, { key: elCh.id, index: i, deliveryStatus: props.deliveryStatus, numberOfRents: el.Rents.length, day: el.day, Rent: elCh })); });
            })))));
};
export default BodyRentComponent;
