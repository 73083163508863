import { gql } from '@apollo/client';

const FRAGMENT_NEW_PRODUCTION = gql`
  fragment NewProduction on Production {
    id
    quantity
    Employee {
      firstName
      lastName
    }
    createdAt
    Product {
      id
      name
    }
    ProductPresentation {
      id
      name
    }
  }
`;

const CREATE = gql`
  ${FRAGMENT_NEW_PRODUCTION}
  mutation CreateTransformation(
    $productId: ID!
    $productPresentationId: ID!
    $quantity: Float!
    $Products: [ProductProduction]
  ) {
    createTransformation(
      input: {
        productId: $productId
        productPresentationId: $productPresentationId
        quantity: $quantity
        Products: $Products
      }
    ) {
      ...NewProduction
    }
  }
`;

const GET = gql`
  ${FRAGMENT_NEW_PRODUCTION}
  query Transformations($limit: Int, $offset: Int, $name: String, $productId: ID, $branchId: ID) {
    transformations(
      limit: $limit
      offset: $offset
      like: $name
      productId: $productId
      branchId: $branchId
    ) {
      count
      rows {
        ...NewProduction
      }
    }
  }
`;

const GET_BY_ID = gql`
  ${FRAGMENT_NEW_PRODUCTION}
  query Production($id: ID!) {
    production(id: $id) {
      ...NewProduction
      ProductionDetails {
        id
        quantity
        Product {
          id
          name
        }
        ProductPresentation {
          id
          name
        }
      }
    }
  }
`;

const DELETE = gql`
  mutation DeleteTransformation($id: ID!) {
    deleteTransformation(id: $id) {
      id
    }
  }
`;

const GQL = {
  GET,
  CREATE,
  FRAGMENT_NEW_PRODUCTION,
  GET_BY_ID,
  DELETE,
};

export default GQL;
