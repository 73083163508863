import React, { useEffect, useRef } from 'react';
import { useReactiveVar } from '@apollo/client';
import FooterDialogComponent from '../../../../../presentationals/Dialog/Footer';
import PaymentFormMultiPaymentDialogComponent from '../../../CustomerReceivables/MultiPaymentDialog/Body/PaymentForm';
import CreatePaymentButton from './CreateButton';
import GQL_ACCOUNT_RECEIVABLE from '../../../../../apollo/gql/accountReceivable';
import useApolloLazyQuery from '../../../../../hooks/useApolloLazyQuery';
import { isPaymentFormOpen } from '../../../../../apollo/cache';
import LoadingProgress from '../../../../../presentationals/LoadingProgress';
import ErrorDataComponent from '../../../../../presentationals/ErrorData';
var BodyPaymentDialogComponent = function (props) {
    var accountReceivableId = useReactiveVar(isPaymentFormOpen);
    var paymentFormRef = useRef(null);
    var _a = useApolloLazyQuery(GQL_ACCOUNT_RECEIVABLE.GET_ACCOUNT_RECEIVABLE_BY_ID), getData = _a[0], _b = _a[1], loading = _b.loading, data = _b.data;
    useEffect(function () {
        if (accountReceivableId)
            getData({ variables: { id: accountReceivableId } });
    }, [accountReceivableId]);
    var handleClick = function () { return (paymentFormRef === null || paymentFormRef === void 0 ? void 0 : paymentFormRef.current) && paymentFormRef.current.getData(); };
    if (loading)
        return React.createElement(LoadingProgress, null);
    if (!data)
        return React.createElement(ErrorDataComponent, null);
    return (React.createElement("div", { className: 'flex flex-1 flex-col' },
        React.createElement("div", { className: 'grid flex-1 grid-cols-1 sm:grid-cols-4 gap-1 px-4 py-3 basis-0 overflow-y-auto' },
            React.createElement("div", { className: 'flex flex-1 flex-col' },
                React.createElement(PaymentFormMultiPaymentDialogComponent, { totalPayment: data.balance, showPaymentField: true, ref: paymentFormRef }))),
        React.createElement(FooterDialogComponent, null,
            React.createElement("div", null),
            React.createElement(CreatePaymentButton, { onClick: handleClick, onSuccess: props.onSuccess }))));
};
export default BodyPaymentDialogComponent;
