import React, { useState } from 'react';
import Main from './Main';
import Products from './Products';
var CreateRequisitionDialogComponent = function () {
    var _a = useState(0), step = _a[0], setStep = _a[1];
    var _b = useState(null), values = _b[0], setValues = _b[1];
    var handleNextStep = function (values) {
        setValues(values);
        setStep(step + 1);
    };
    var handleReturnStep = function () {
        setStep(step - 1);
    };
    var handleCreated = function () {
        handleReturnStep();
    };
    return (React.createElement("div", { className: 'flex flex-1' },
        step === 0 && React.createElement(Main, { onContinue: handleNextStep }),
        step === 1 && values && (React.createElement(Products, { onCreated: handleCreated, onReturn: handleReturnStep, values: values }))));
};
export default CreateRequisitionDialogComponent;
