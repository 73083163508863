import React from 'react';
import SsidChartIcon from '@mui/icons-material/SsidChart';
import TitleDrawerMenuDashboardComponent from '../dashboard/Menu/Items/helpers/Title';
import LinkMenuDashboardComponent from '../dashboard/Menu/Items/helpers/Link';
import UnderConstructionAlertComponent from '../../presentationals/UnderConstructionAlert';
var PurchaseReportsComponent = function () {
    return (React.createElement("div", { className: 'flex flex-1 flex-col py-2 px-3 overflow-auto' },
        React.createElement(UnderConstructionAlertComponent, null),
        React.createElement("div", { className: 'flex mt-2' },
            React.createElement(TitleDrawerMenuDashboardComponent, { icon: React.createElement(SsidChartIcon, null), title: 'Informes de compras' })),
        React.createElement("div", { className: 'flex flex-1 flex-col overflow-y-auto' },
            React.createElement(LinkMenuDashboardComponent, { url: '/panel/report/product/transaction', primary: 'Historial de movimientos por producto', secondary: 'Visualiza el historial de movimientos (entradas y salidas) por producto en un periodo' }),
            React.createElement(LinkMenuDashboardComponent, { url: '/panel/report/product/movement', primary: 'Resumen de entradas y salidas de productos', secondary: 'Consulta el resumen de los movimientos de productos en un periodo' }),
            React.createElement(LinkMenuDashboardComponent, { url: '/panel/report/purchaseOrder/product', primary: 'Compras por folio detallada', secondary: 'Reporte que muestra el desglose de los conceptos de las compras en un periodo' }),
            React.createElement(LinkMenuDashboardComponent, { url: '/panel/report/purchaseOrder/product/rotation', primary: 'Compras por productos acumulados', secondary: 'Reporte de los productos comprados en un periodo por concepto' }),
            React.createElement(LinkMenuDashboardComponent, { url: '/panel/report/purchaseOrder/transaction', primary: 'Compras por forma de pago', secondary: 'Reporte detallado que muestra las compras con su forma de pago durante un periodo' }),
            React.createElement(LinkMenuDashboardComponent, { url: '/panel/report/inventory/transfer', primary: 'Traspasos de productos detallado', secondary: 'Reporte que muestra el desglose de los conceptos de los traspasos en un periodo' }),
            React.createElement(LinkMenuDashboardComponent, { url: '/panel/report/inventory/transfer/branch', primary: 'Traspasos de productos entre sucursales', secondary: 'Reporte de los productos traspasados a detalle entre sucursales en un periodo' }),
            React.createElement(LinkMenuDashboardComponent, { url: '/panel/report/inventory/adjust/product', primary: 'Ajustes de inventario por producto', secondary: 'Reporte que muestra el desglose de los conceptos de los ajustes de inventario en un periodo' }))));
};
export default PurchaseReportsComponent;
