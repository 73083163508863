import React, { useMemo } from 'react';
import SelectFieldComponent from '../../../../../../../../presentationals/FormComponents/SelectField';
import getProductTypes from '../../../../../../../product/helpers/getProductTypes';
var ProductTypeFieldProductComponent = function (props) {
    var typeOptions = useMemo(function () { return getProductTypes(); }, []);
    var handleChange = function (name, value) {
        props.onChange(name, value);
    };
    return (React.createElement(SelectFieldComponent, { label: 'Tipo de producto', name: 'type', value: props.value || '', error: props.error, onChange: handleChange, disabled: props.isLoading, options: typeOptions }));
};
export default ProductTypeFieldProductComponent;
