import React from 'react';

import Container from '../../presentationals/Module.v2/Container';

import GQL from './_gql';
import Filter from './_filter';
import Action from './_action';

const title = 'Resument de entradas y salidas de productos';
const operation = ['productMovementLogReport', 'productMovementLogReport'];
const Rows = [
  { label: 'COD', key: 'Product code' },
  { label: 'Producto', key: 'Product name', filter: true },
  { label: 'Clasificación', key: 'Product Classification name', filter: true },
  { label: 'Unidad', key: 'Product PresentationBase name', filter: true },
  { label: 'Cant. Inicial', key: 'start', align: 'right', format: 'number', filter: true },
  { label: 'Entrada', key: 'input', align: 'right', format: 'number', filter: true },
  { label: 'Salida', key: 'output', align: 'right', format: 'number', filter: true },
  { label: 'Cant. Final', key: 'finish', align: 'right', format: 'number', filter: true },
  { label: 'Importe', key: 'costTotal', align: 'right', format: 'number', filter: true },
];

const ProductMovementComponent = () => {
  return <>
    <Container
      title={title}
      operation={operation}
      Rows={Rows}
      get={GQL.GET}
      noCreate
      noDelete
      noEdit
      filter
    />
    <Action />
    <Filter />
  </>;
};

export default ProductMovementComponent;
