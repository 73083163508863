import { useEffect, useState } from 'react';
import GQL_SURVEY from '../../../apollo/gql/survey';
import useApolloQuery from '../../../hooks/useApolloQuery';
import { dateTimeFormat } from '../../../helpers';
var useGetSurveys = function () {
    var _a = useState([]), data = _a[0], setData = _a[1];
    var _b = useApolloQuery(GQL_SURVEY.GET_SURVEYS, {}), response = _b.data, loading = _b.loading;
    useEffect(function () {
        if (response) {
            var data_1 = response.map(function (el) { return ({
                id: el.id,
                createdAt: dateTimeFormat(el.createdAt),
                name: el.name,
            }); });
            setData(data_1);
        }
    }, [response]);
    return { data: data, loading: loading };
};
export default useGetSurveys;
