import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Divider } from '@mui/material';
import useApolloLazyQuery from '../../../../hooks/useApolloLazyQuery';
import GQL_INVOICE_COMPLEMENT from '../../../../apollo/gql/invoiceComplement';
import LoadingProgress from '../../../../presentationals/LoadingProgress';
import ErrorDataComponent from '../../../../presentationals/ErrorData';
import { dateFormat } from '../../../../helpers';
import convertToCurrencyLabel from '../../../../helpers/convertToCurrencyLabel';
import DownloadInvoiceComplementButtonComponent from './DownloadButton';
import SendInvoiceComplementByEmailDialogComponent from '../../../../presentationals/SendInvoiceComplementByEmailDialog';
import SendInvoiceComplementByEmailButtonComponent from './SendByEmailButton';
var GeneralInvoiceComplementComponent = function () {
    var params = useParams();
    var _a = useApolloLazyQuery(GQL_INVOICE_COMPLEMENT.GET_INVOICE_COMPLEMENT_BY_ID), getData = _a[0], _b = _a[1], loading = _b.loading, data = _b.data;
    useEffect(function () {
        if (params.invoiceComplementId)
            getData({ variables: { id: params.invoiceComplementId } });
    }, [params]);
    if (loading)
        return React.createElement(LoadingProgress, null);
    if (!data)
        return React.createElement(ErrorDataComponent, null);
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: 'flex flex-1 flex-col' },
            React.createElement("div", { className: 'grid grid-cols-1 sm:grid-cols-3 flex-1 flex-col px-4 py-2 basis-0 overflow-auto' },
                React.createElement("div", { className: 'flex flex-1 flex-col' },
                    data.status === 0 && React.createElement("div", { className: 'text-5xl text-red-600 mb-2' }, "CANCELADO"),
                    React.createElement("div", { className: 'text-lg mt-2' }, "Folio"),
                    React.createElement("div", { className: 'text-3xl font-medium' }, data.folio),
                    React.createElement("div", { className: 'text-base mt-2' }, "Fecha de emisi\u00F3n"),
                    React.createElement("div", { className: 'font-medium' }, dateFormat(data.createdAt)),
                    React.createElement("div", { className: 'text-base mt-2' }, "UUID"),
                    React.createElement("div", { className: 'font-medium' }, data.uuid),
                    React.createElement("div", { className: 'text-base mt-2' }, "Cliente"),
                    React.createElement("div", { className: 'font-medium' }, data.customer),
                    React.createElement("div", { className: 'text-base mt-2' }, "Importe"),
                    React.createElement("div", { className: 'text-xl' }, "$".concat(convertToCurrencyLabel(data.total))),
                    React.createElement(Divider, { className: 'mt-2 mb-3' }),
                    React.createElement("div", { className: 'grid grid-cols-2 gap-4' },
                        React.createElement(DownloadInvoiceComplementButtonComponent, { invoiceComplementUuid: data.uuid }),
                        React.createElement(SendInvoiceComplementByEmailButtonComponent, { invoiceComplementId: data.id }))))),
        React.createElement(SendInvoiceComplementByEmailDialogComponent, null)));
};
export default GeneralInvoiceComplementComponent;
