import React from 'react';

import CreateButton from './CreateButton';
import PinField from './PinField';
import PrintOption from './PrintOption';
import FooterDialogComponent from '../../../../presentationals/Dialog/Footer';
import DocumentPreviewSaleV2Component from './DocumentPreview';

const FooterConfirmDialogSaleV2Component = () => {
  return (
    <FooterDialogComponent>
      <div className='hidden md:flex'>
        <div>
          <PrintOption />
        </div>
        <DocumentPreviewSaleV2Component />
      </div>
      <div className='flex'>
        <div className='mr-2'>
          <PinField />
        </div>
        <CreateButton />
      </div>
    </FooterDialogComponent>
  );
};

export default FooterConfirmDialogSaleV2Component;
