import getSession from '../../../../helpers/getSession';
var getDefaultManufactureValues = function () {
    var _a;
    var session = getSession();
    var ProductManufactureForm = {
        qty: '',
        productId: null,
        productPresentationId: null,
    };
    return {
        branchId: (_a = session === null || session === void 0 ? void 0 : session.Company) === null || _a === void 0 ? void 0 : _a.Branch.id,
        productsToSubtract: [],
        productsToAdd: [],
        waste: '',
        addProductManufactureForm: ProductManufactureForm,
        subtractProductManufactureForm: ProductManufactureForm,
    };
};
export default getDefaultManufactureValues;
