var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { useEffect, useState } from 'react';
import { useReactiveVar } from '@apollo/client';
import useApolloLazyQuery from '../../../hooks/useApolloLazyQuery';
import { filter } from '../../../apollo/cache';
import GQL_CASH_CLOSINGS from '../../../apollo/gql/cashClosing';
import { dateTimeFormat } from '../../../helpers';
import convertToCurrencyLabel from '../../../helpers/convertToCurrencyLabel';
var useGetCashClosings = function () {
    var filterVar = useReactiveVar(filter);
    var _a = useState([]), data = _a[0], setData = _a[1];
    var _b = useApolloLazyQuery(GQL_CASH_CLOSINGS.GET_CASH_CLOSINGS), getData = _b[0], _c = _b[1], response = _c.data, loading = _c.loading;
    useEffect(function () {
        getData({
            variables: __assign(__assign({}, filterVar), { limit: 0 }),
        });
    }, [filterVar]);
    useEffect(function () {
        if (response) {
            var newData = response.map(function (el) { return ({
                id: el.id,
                clientCreatedAt: dateTimeFormat(el.clientCreatedAt, 'dd/MM/yyyy HH:mm'),
                closedAt: el.closedAt ? dateTimeFormat(el.closedAt, 'dd/MM/yyyy HH:mm') : '-',
                pettyCashAmount: convertToCurrencyLabel(el.pettyCashAmount || 0),
                realBalance: convertToCurrencyLabel(el.realBalance || 0),
                virtualBalance: convertToCurrencyLabel(el.virtualBalance || 0),
                difference: convertToCurrencyLabel(el.difference || 0),
                description: el.description,
                cashClosingEmployee: el.cashClosingEmployee,
            }); });
            setData(newData);
        }
    }, [response]);
    return { data: data, loading: loading };
};
export default useGetCashClosings;
