import React from 'react';
import SaleReportComponent from '../containers/sale.report';
import SaleLayoutComponent from './SaleLayout';
import ProductsSaleComponent from '../containers/sale.report/Item/Product';
import GeneralSaleComponent from '../containers/sale.report/Item/General';
import InvoiceSaleComponent from '../containers/sale.report/Item/Invoice';
import CancelSaleComponent from '../containers/sale.report/Item/Cancel';
var getSaleChildrenRoutes = function () {
    return [
        { index: true, element: React.createElement(SaleReportComponent, null) },
        {
            path: ':saleId',
            element: React.createElement(SaleLayoutComponent, null),
            children: [
                { index: true, element: React.createElement(GeneralSaleComponent, null) },
                { path: 'products', element: React.createElement(ProductsSaleComponent, null) },
                { path: 'invoice', element: React.createElement(InvoiceSaleComponent, null) },
                { path: 'cancel', element: React.createElement(CancelSaleComponent, null) },
            ],
        },
    ];
};
export default getSaleChildrenRoutes;
