var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useEffect, useMemo, useState } from 'react';
import getDefaultColorQueueManagerValues from './helpers/getDefaultValues';
import ColorFieldQueueManagerCompanyComponent from './ColorField';
import UpdateQueueManagerColorsButtonComponent from './UpdateButton';
var ColorQueueManagerCompanyComponent = function (props) {
    var defaultValues = useMemo(getDefaultColorQueueManagerValues, []);
    var _a = useState(defaultValues), values = _a[0], setValues = _a[1];
    useEffect(function () {
        if (props.Colors)
            setValues(props.Colors);
    }, [props.Colors]);
    var handleChange = function (name, value) {
        setValues(function (values) {
            var _a;
            return (__assign(__assign({}, values), (_a = {}, _a[name] = value, _a)));
        });
    };
    return (React.createElement("div", null,
        React.createElement("div", { className: 'flex flex-1 flex-col mt-6' },
            React.createElement("div", { className: 'flex text-xl font-medium' }, "Configuraci\u00F3n de colores"),
            React.createElement("div", { className: 'flex text-base font-light' }, "Los colores deben ser especificados en formato hexadecimal. Ej. #ff0000"),
            React.createElement("div", { className: 'flex flex-1 flex-row' },
                React.createElement("div", { className: 'flex flex-col' },
                    React.createElement(ColorFieldQueueManagerCompanyComponent, { label: 'Cabecera lado izquierdo', section: 'leftHeader', backgroundColor: values.leftHeaderBackgroundColor, textColor: values.leftHeaderTextColor, onChange: handleChange }),
                    React.createElement(ColorFieldQueueManagerCompanyComponent, { label: 'Cabecera lado derecho', section: 'rigthHeader', backgroundColor: values.rigthHeaderBackgroundColor, textColor: values.rigthHeaderTextColor, onChange: handleChange }),
                    React.createElement(ColorFieldQueueManagerCompanyComponent, { label: 'Cuerpo lado izquierdo', section: 'leftBody', backgroundColor: values.leftBodyBackgroundColor, textColor: values.leftBodyTextColor, onChange: handleChange }),
                    React.createElement(ColorFieldQueueManagerCompanyComponent, { label: 'Pie de p\u00E1gina', section: 'footer', backgroundColor: values.footerBackgroundColor, textColor: values.footerTextColor, onChange: handleChange }),
                    React.createElement(UpdateQueueManagerColorsButtonComponent, { values: values })),
                React.createElement("div", { className: 'ml-4 mt-3 border-gray-300 rounded flex flex-1 flex-col border border-solid' },
                    React.createElement("div", { className: 'flex flex-1 flex-col' },
                        React.createElement("div", { className: 'flex h-2/6' },
                            React.createElement("div", { className: 'w-5/12 px-2 items-center justify-center flex border-0 border-r border-b border-solid border-slate-200', style: {
                                    backgroundColor: values.leftHeaderBackgroundColor || undefined,
                                    color: values.leftHeaderTextColor || undefined,
                                } }, "Cabecera lado izquierdo"),
                            React.createElement("div", { className: 'w-7/12 px-2 items-center justify-center flex', style: {
                                    backgroundColor: values.rigthHeaderBackgroundColor || undefined,
                                    color: values.rigthHeaderTextColor || undefined,
                                } }, "Cabecera lado derecho")),
                        React.createElement("div", { className: 'flex flex-1' },
                            React.createElement("div", { className: 'w-5/12 px-2 items-center justify-center flex', style: {
                                    backgroundColor: values.leftBodyBackgroundColor || undefined,
                                    color: values.leftBodyTextColor || undefined,
                                } }, "Cuerpo lado izquierdo"),
                            React.createElement("div", { className: 'w-7/12 px-2 items-center justify-center flex bg-slate-200' }, "Contenido digital"))),
                    React.createElement("div", { className: 'flex justify-center items-center py-2 border-0 border-t border-solid border-slate-200', style: {
                            backgroundColor: values.footerBackgroundColor || undefined,
                            color: values.footerTextColor || undefined,
                        } }, "Pie de p\u00E1gina"))))));
};
export default ColorQueueManagerCompanyComponent;
