import React from 'react';
import PdfOnlineInvoiceButtonComponent from './PdfButton';
import XmlOnlineInvoiceButtonComponent from './XmlButton';
var DocOnlineInvoiceComponent = function (props) {
    return (React.createElement("div", { className: 'flex p-4' },
        React.createElement("div", { className: 'flex mr-4' },
            React.createElement(PdfOnlineInvoiceButtonComponent, { uuid: props.uuid })),
        React.createElement("div", { className: 'flex' },
            React.createElement(XmlOnlineInvoiceButtonComponent, { uuid: props.uuid }))));
};
export default DocOnlineInvoiceComponent;
