var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React, { useEffect, useState } from 'react';
import useApolloQuery from '../../../hooks/useApolloQuery';
import GQL_PAYMENT_FORM from '../../../apollo/gql/paymentForm';
import SelectFieldComponent from '../SelectField';
var SelectTransactionAccountFieldComponent = function (props) {
    var _a = useState(false), isDefaultValueApplied = _a[0], setIsDefaultValueApplied = _a[1];
    var _b = useState(''), value = _b[0], setValue = _b[1];
    var _c = useState([]), options = _c[0], setOptions = _c[1];
    var _d = useApolloQuery(GQL_PAYMENT_FORM.GET_TRANSACTION_ACCOUNTS), data = _d.data, loading = _d.loading;
    useEffect(function () {
        if (props.defaultValue && options.length > 0 && !isDefaultValueApplied) {
            var isInArray = options.find(function (el) { return el.value === props.defaultValue; });
            if (isInArray)
                setValue(props.defaultValue);
            setIsDefaultValueApplied(true);
        }
    }, [isDefaultValueApplied, options, props.defaultValue]);
    useEffect(function () {
        if (data) {
            var options_1 = data.rows.map(function (el) { return ({
                label: "".concat(el.bank ? "".concat(el.bank, " | ") : '').concat(el.name).concat(el.bankAccount ? " | ".concat(el.bankAccount) : ''),
                value: el.id,
            }); });
            var allOptions = props.chooseAllOptionsAllowed
                ? [
                    {
                        value: -1,
                        label: props.defaultAllOptionsAllowedLabel || 'Todas las cuentas',
                    },
                ]
                : [];
            setOptions(__spreadArray(__spreadArray([], allOptions, true), options_1, true));
        }
    }, [data, props.chooseAllOptionsAllowed]);
    var handleChange = function (name, value) {
        setValue(value);
        props.onChange(name, value);
    };
    return (React.createElement("div", { className: 'w-full truncate' },
        React.createElement(SelectFieldComponent, { name: 'transactionAccountId', label: props.label || 'Cuenta destino', disabled: props.disabled || loading, onChange: handleChange, value: value, options: options })));
};
export default SelectTransactionAccountFieldComponent;
