import React from 'react';
import { useReactiveVar } from '@apollo/client';
import DialogComponent from '../../../presentationals/Dialog';
import { isCreateFormOpen } from '../../../apollo/cache';
import BodyCreateSurveyComponent from './Body';
var CreateSurveyDialogComponent = function () {
    var isOpen = useReactiveVar(isCreateFormOpen);
    var handleClose = function () {
        isCreateFormOpen(false);
    };
    return (React.createElement(DialogComponent, { fullScreen: true, open: isOpen, title: 'Crear encuesta', onClose: handleClose },
        React.createElement(BodyCreateSurveyComponent, null)));
};
export default CreateSurveyDialogComponent;
