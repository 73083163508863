import React from 'react';
import { useParams } from 'react-router-dom';
import BodyProductIngredientRatesComponent from '../../ProductIngredientRates/Body';
import useApolloQuery from '../../../../hooks/useApolloQuery';
import GQL_PRODUCT from '../../../../apollo/gql/product';
import LoadingProgress from '../../../../presentationals/LoadingProgress';
import CreateProductIngredientPriceComponent from '../../CreateProductIngredientPrice';
import ErrorDataComponent from '../../../../presentationals/ErrorData';
var RatesProductIngredientComponent = function () {
    var params = useParams();
    var _a = useApolloQuery(GQL_PRODUCT.GET_PRODUCT_INGREDIENT_BY_ID, {
        variables: { id: params.componentId },
    }), loading = _a.loading, data = _a.data;
    if (loading)
        return React.createElement(LoadingProgress, null);
    if (!data)
        return React.createElement(ErrorDataComponent, null);
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: 'px-3 py-2 flex flex-1 w-full sm:w-1/2' },
            React.createElement(BodyProductIngredientRatesComponent, { ProductIngredientPrices: data.ProductIngredientPrices })),
        React.createElement(CreateProductIngredientPriceComponent, null)));
};
export default RatesProductIngredientComponent;
