import React, { useState } from 'react';
import { useReactiveVar } from '@apollo/client';
import DeleteCashFlowButtonComponent from '../DeleteButton';
import TextFieldComponent from '../../../../presentationals/FormComponents/TextField';
import { inputError } from '../../../../apollo/cache';
var DeleteCashFlowFooterActionComponent = function () {
    var errors = useReactiveVar(inputError);
    var _a = useState(''), value = _a[0], setValue = _a[1];
    var handleChange = function (name, value) {
        setValue(value);
    };
    return (React.createElement("div", { className: 'flex flex-row items-center' },
        React.createElement("div", { className: 'mr-2' },
            React.createElement(DeleteCashFlowButtonComponent, { pin: value })),
        React.createElement(TextFieldComponent, { name: 'pin', placeholder: 'Pin con privilegios', value: value, error: errors.pin, onChange: handleChange, type: 'password' })));
};
export default DeleteCashFlowFooterActionComponent;
