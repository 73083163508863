import React, { useEffect, useState } from 'react';
import { useReactiveVar } from '@apollo/client';
import { Button } from '@mui/material';
import { searchField } from '../../../../../../../apollo/cache';
import TextField from '../../../../../../../presentationals/FormComponents/TextField';
import SelectField from '../../../../../../../presentationals/FormComponents/SelectField';
var filterOptions = {
    name: 'Nombre de contacto',
    phone: 'Teléfono',
    email: 'Correo electrónico',
    identifier: 'RFC',
    businessName: 'Razón social',
};
var FormSearchFormCustomerActionSaleV2Component = function () {
    var searchFieldVar = useReactiveVar(searchField);
    var _a = useState(['name', '']), value = _a[0], setValue = _a[1];
    var handleChange = function (name, value) {
        switch (name) {
            case 'search':
                setValue(function (values) { return [values[0], value]; });
                break;
            case 'filter':
                setValue([value, '']);
                break;
            default:
                break;
        }
    };
    useEffect(function () {
        if (searchFieldVar)
            setValue(searchFieldVar);
    }, [searchFieldVar]);
    var handleClick = function () {
        searchField(value);
    };
    var handleKeyDown = function (e) {
        if (e.key === 'Enter') {
            e.preventDefault();
            handleClick();
        }
    };
    return (React.createElement("div", { className: 'flex flex-1 flex-col md:flex-row md:items-center px-5 pt-3 pb-1' },
        React.createElement("div", { className: 'flex pr-2' },
            React.createElement(SelectField, { defaultLabel: 'Filtro', name: 'filter', value: value[0], onChange: handleChange, options: Object.keys(filterOptions).map(function (key) { return ({
                    label: filterOptions[key],
                    value: key,
                }); }) })),
        React.createElement("div", { className: 'flex flex-1 pr-2' },
            React.createElement(TextField, { autoFocus: true, name: 'search', defaultLabel: "B\u00FAsqueda por ".concat(filterOptions[value[0]].toLowerCase()), value: value[1], onChange: handleChange, onKeyDown: handleKeyDown })),
        React.createElement("div", { className: 'mb-1' },
            React.createElement(Button, { color: 'secondary', variant: 'contained', onClick: handleClick }, "Buscar"))));
};
export default FormSearchFormCustomerActionSaleV2Component;
