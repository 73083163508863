import React, { useState, useEffect, Fragment } from 'react';

import { useMutation, useLazyQuery } from '@apollo/client';

import ListAltIcon from '@mui/icons-material/ListAltOutlined';
import EditIcon from '@mui/icons-material/EditOutlined';

import Typography from '@mui/material/Typography';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';

import dateFormat from 'date-fns/format';
import parseISO from 'date-fns/parseISO';

import Table from '../../presentationals/Module.v2/Table/TableContainer';
import Head from '../../presentationals/Module.v2/Table/Head';
import TableCell from '../../presentationals/Module.v2/Table/Body/TableCell';

import FormDialog from '../../presentationals/FormDialog';
import Snackbar from '../../presentationals/Snackbar';
import TextField from '../../presentationals/TextField';

import { parseError } from '../../helpers';

import GQL from './_gql';

import useSnackbarDialog from '../../store/snackbar/snackbarDialog';

const defaultSnackbar = { isOpen: true, time: 3000 };

const defaultValues = {
  total: 0,
};

const Rows = [{ label: 'Cliente' }, { label: 'Fecha' }];

const Customer = props => {
  const { setSnackbar } = useSnackbarDialog();

  const [values, setValues] = useState(defaultValues);
  const [errors, setErrors] = useState({});
  const [isPayment, setIsPayment] = useState(0);
  const [reportData, setReportData] = useState([]);

  const [payCalendarControl, { loading }] = useMutation(GQL.PAY_CALENDAR_CONTROL);
  const [getReport, { loading: loadingReport }] = useLazyQuery(GQL.CALENDAT_CONTROLS_REPORT, {
    onCompleted: data => {
      if (data && data.calendarControlPayments && parseInt(data.calendarControlPayments.count) > 0)
        setReportData(data.calendarControlPayments.rows);
    },
  });

  useEffect(() => {
    if (props.total && parseFloat(props.total) > 0) setValues({ total: parseFloat(props.total) });
  }, [props.total]);

  useEffect(() => {
    if (props.id) getReport({ variables: { calendarControlId: props.id } });
  }, [props.id]);

  const handleClose = () => {
    setErrors({});
    props.handleClose();
  };

  const handleAction = async () => {
    try {
      if (
        !values.total ||
        Number.isNaN(parseFloat(values.total)) ||
        parseFloat(values.total) < parseFloat(props.total)
      ) {
        const e = new Error('El total es menor al monto asignado.');
        e.name = 'total';
        e.fields = true;
      }
      await payCalendarControl({
        variables: {
          id: props.id,
          total: parseFloat(values.total),
        },
      });
      handleClose();
    } catch (e) {
      console.log(e);
      if (e.fields) {
        setErrors({ [e.name]: e.message });
        return;
      }
      const parseErrors = parseError(e);
      parseErrors.forEach(el => {
        if (el.name === 'BAD_USER_INPUT') {
          const key = Object.keys(el.message)[0];
          setSnackbar({ ...defaultSnackbar, label: el.message[key], severity: 'error' });
        } else {
          setSnackbar({ ...defaultSnackbar, label: el.message, severity: 'error' });
        }
      });
    }
  };

  const handleChange = e => {
    const { name, value } = e.target;
    setValues(values => ({ ...values, [name]: value }));
  };

  const handleTask = (e, value) => {
    setIsPayment(value);
  };

  return (
    <>
      <FormDialog
        title=''
        isOpen={props.isOpen}
        isLoading={loading || loadingReport}
        handleClose={handleClose}
        handleAction={handleAction}
        // width={parseInt(isPayment) === 0 ? 'sm' : 'md'}
      >
        <Tabs value={isPayment} indicatorColor='primary' textColor='primary' onChange={handleTask}>
          <Tab icon={<EditIcon />} label='Pagar' />
          <Tab icon={<ListAltIcon />} label='Reporte' />
        </Tabs>
        {parseInt(isPayment) === 0 ? (
          <div>
            <Typography variant='h5' gutterBottom>
              ¡Pagar!
            </Typography>
            <Typography variant='subtitle1'>
              Estas a punto de pagar. Se aplicará un movimiento de caja (ingreso).
            </Typography>
            <br />
            <TextField
              label='Total'
              name='total'
              onChange={handleChange}
              value={values.total}
              error={errors.total}
              variant='outlined'
              type='number'
              noFormat
            />
          </div>
        ) : (
          <Table>
            <Head Rows={Rows} />
            <TableBody>
              {reportData.map((el, i) => {
                return (
                  <Fragment key={i}>
                    <TableRow>
                      <TableCell>
                        <strong>{`${el.CashFlow.description} (${el.Calendars.length})`}</strong>
                      </TableCell>
                      <TableCell colSpan={2}>
                        <strong>
                          {dateFormat(parseISO(el.CashFlow.clientCreatedAt), 'dd/MM/yyyy HH:mm:ss')}
                        </strong>
                      </TableCell>
                    </TableRow>
                    {el.Calendars.map(tmp => {
                      return (
                        <TableRow key={tmp.id}>
                          <TableCell key={tmp.id}>
                            {`${tmp.Customer.firstName} ${tmp.Customer.lastName}`.trim()}
                          </TableCell>
                          <TableCell key={`${tmp.id}-b`}>
                            {tmp.checkOut && tmp.checkOut.trim() !== ''
                              ? dateFormat(parseISO(tmp.checkOut), 'dd/MM/yyyy HH:mm:ss')
                              : '-'}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </Fragment>
                );
              })}
            </TableBody>
          </Table>
        )}
      </FormDialog>
      <Snackbar horizontal='center' />
    </>
  );
};

export default Customer;
