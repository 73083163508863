import CLOSE_REQUISITION from './closeRequisition';
import CREATE_AUTO_REQUISITION from './createAutoRequisition';
import CREATE_REQUISITION from './createRequisition';
import CREATE_REQUISITION_PRODUCT from './createRequisitionProduct';
import DELETE_REQUISITION_PRODUCT from './deleteRequisitionProduct';
import FRAGMENT_REQUISITION from './fragmentRequisition';
import FRAGMENT_REQUISITION_PRODUCT from './fragmentRequisitionProduct';
import GET_REQUISITION_BY_ID from './getRequisitionById';
import GET_REQUISITION_PRODUCT_BY_ID from './getRequisitionProductById';
import UPDATE_REQUISITION_PRODUCT from './updateRequisitionProduct';
var GQL_REQUISITION = {
    CLOSE_REQUISITION: CLOSE_REQUISITION,
    CREATE_AUTO_REQUISITION: CREATE_AUTO_REQUISITION,
    CREATE_REQUISITION: CREATE_REQUISITION,
    CREATE_REQUISITION_PRODUCT: CREATE_REQUISITION_PRODUCT,
    DELETE_REQUISITION_PRODUCT: DELETE_REQUISITION_PRODUCT,
    FRAGMENT_REQUISITION: FRAGMENT_REQUISITION,
    FRAGMENT_REQUISITION_PRODUCT: FRAGMENT_REQUISITION_PRODUCT,
    GET_REQUISITION_BY_ID: GET_REQUISITION_BY_ID,
    GET_REQUISITION_PRODUCT_BY_ID: GET_REQUISITION_PRODUCT_BY_ID,
    UPDATE_REQUISITION_PRODUCT: UPDATE_REQUISITION_PRODUCT,
};
export default GQL_REQUISITION;
