import React from 'react';
import { Link } from 'react-router-dom';
import { Toolbar, AppBar } from '@mui/material';
// import logo from '../../../../assets/static/logo.png';
import MenuButton from './menu.button';
import ProfileButton from './profile.button';
import NotificationButton from './notification.button';
import useGetSession from '../../../../hooks/useGetSession';
import AppsButtonComponent from './AppsButton';
var AppbarComponent = function () {
    var session = useGetSession();
    return (React.createElement(AppBar, { position: 'sticky', className: 'bg-slate-50 border-0 border-b border-solid border-gray-200 shadow-none' },
        React.createElement(Toolbar, null,
            React.createElement("div", { className: 'flex flex-1 items-center' },
                session.status === 'loggedIn' && React.createElement(MenuButton, null),
                React.createElement(Link, { to: '..' },
                    React.createElement("img", { src: 'https://storage.capitalike.com/files/23183', alt: 'Capitalike', className: 'h-5 ml-3 hidden md:flex' }))),
            React.createElement("div", { className: 'flex' },
                session.status === 'loggedIn' && React.createElement(NotificationButton, null),
                session.status === 'loggedIn' && React.createElement(AppsButtonComponent, null),
                React.createElement(ProfileButton, null)))));
};
export default AppbarComponent;
