var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useEffect, useMemo, useState } from 'react';
import { useMutation, useReactiveVar } from '@apollo/client';
import { inputError } from '../../../../apollo/cache';
import TextFieldComponent from '../../../../presentationals/FormComponents/TextField';
import SelectFieldComponent from '../../../../presentationals/FormComponents/SelectField';
import getCompanyActivityOptions from '../../../../helpers/getCompanyActivityOptions';
import SwitchComponent from '../../../../presentationals/FormComponents/Switch';
import getInputErrorFormat from '../../../sale.v2/Action/helpers/getInputErrorFormat';
import setInputErrorFormat from '../../../sale.v2/Action/helpers/setInputErrorFormat';
import getSession from '../../../../helpers/getSession';
import GQL_COMPANY from '../../../../apollo/gql/company';
import useGetCompanySettings from '../../../../hooks/useGetCompanySettings';
import LoadingProgress from '../../../../presentationals/LoadingProgress';
import FooterDialogComponent from '../../../../presentationals/Dialog/Footer';
import ButtonFooterDialogComponent from '../../../../presentationals/Dialog/Footer/Button';
import useSnackbarDialog from '../../../../store/snackbar/snackbarDialog';
var defaultValues = {
    name: '',
    activity: '',
    phone: '',
    printClient: '',
    posModule: '',
    belowZeroInventory: true,
    blockPaymentInPpd: false,
    autoPaymentInPue: false,
    percentEMoney: '0',
};
var printClientsOptions = [
    { label: 'Web', value: 1 },
    { label: 'Local', value: 2 },
];
var posModuleOptions = [
    { label: 'Web', value: 1 },
    { label: 'Local', value: 2 },
    { label: 'Web y local', value: 3 },
];
var GeneralCompanyComponent = function () {
    var snackbar = useSnackbarDialog(function (state) { return state.setSnackbar; });
    var _a = useGetCompanySettings(), data = _a.data, loading = _a.loading;
    var errors = useReactiveVar(inputError);
    var _b = useState(defaultValues), values = _b[0], setValues = _b[1];
    var activityOptions = useMemo(getCompanyActivityOptions, []);
    var _c = useMutation(GQL_COMPANY.UPDATE_COMPANY), updateCompany = _c[0], loadingUpdate = _c[1].loading;
    useEffect(function () {
        if (data === null || data === void 0 ? void 0 : data.company)
            setValues({
                name: data.company.name,
                activity: data.company.activity,
                phone: data.company.phone,
                printClient: data.company.printClient,
                posModule: data.company.posModule,
                belowZeroInventory: data.company.belowZeroInventory,
                blockPaymentInPpd: data.company.blockPaymentInPpd,
                autoPaymentInPue: data.company.autoPaymentInPue,
                percentEMoney: data.company.percentEMoney,
            });
    }, [data]);
    var handleChange = function (name, value) {
        setValues(function (values) {
            var _a;
            return (__assign(__assign({}, values), (_a = {}, _a[name] = value, _a)));
        });
    };
    var handleSwitchChange = function (name, value) {
        setValues(function (values) {
            var _a;
            return (__assign(__assign({}, values), (_a = {}, _a[name] = value, _a)));
        });
    };
    var handleClick = function () { return __awaiter(void 0, void 0, void 0, function () {
        var session, error_1;
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    _b.trys.push([0, 2, , 3]);
                    inputError({});
                    if (values.name === '')
                        throw new Error(setInputErrorFormat('name', 'Escribe el nombre de la empresa.'));
                    if (values.phone === '')
                        throw new Error(setInputErrorFormat('phone', 'Escribe el teléfono de la empresa.'));
                    session = getSession();
                    return [4 /*yield*/, updateCompany({
                            variables: __assign(__assign({ id: (_a = session === null || session === void 0 ? void 0 : session.Company) === null || _a === void 0 ? void 0 : _a.id }, values), { percentEMoney: parseFloat(values.percentEMoney) }),
                        })];
                case 1:
                    _b.sent();
                    snackbar({ isOpen: true, label: 'Los datos de la empresa fueron actualizados con éxito.' });
                    return [3 /*break*/, 3];
                case 2:
                    error_1 = _b.sent();
                    getInputErrorFormat(error_1);
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    if (loading || !data)
        return React.createElement(LoadingProgress, null);
    return (React.createElement("div", { className: 'flex flex-1 flex-col overflow-hidden' },
        React.createElement("div", { className: 'gap-4 grid grid-cols-1 sm:grid-cols-4 flex-col flex-1 px-4 py-3 overflow-auto basis-0' },
            React.createElement("div", null,
                React.createElement("div", { className: 'flex text-xl font-medium' }, "Configuraci\u00F3n general"),
                React.createElement(TextFieldComponent, { label: 'Nombre', name: 'name', value: values.name, error: errors.name, onChange: handleChange }),
                React.createElement(SelectFieldComponent, { label: 'Giro / Actividad', name: 'activity', value: values.activity, error: errors.activity, onChange: handleChange, options: activityOptions }),
                React.createElement(TextFieldComponent, { label: 'Tel\u00E9fono', name: 'phone', value: values.phone, error: errors.phone, onChange: handleChange }),
                React.createElement(SelectFieldComponent, { label: 'Modalidad para impresi\u00F3n de tickets', name: 'printClient', value: values.printClient, error: errors.printClient, onChange: handleChange, options: printClientsOptions }),
                React.createElement(SelectFieldComponent, { label: 'Modalidad para punto de venta', name: 'posModule', value: values.posModule, error: errors.posModule, onChange: handleChange, options: posModuleOptions })),
            React.createElement("div", null,
                React.createElement("div", { className: 'flex text-xl font-medium' }, "Configuraci\u00F3n de punto de venta"),
                React.createElement(SwitchComponent, { className: 'mt-2', name: 'belowZeroInventory', label: 'Vender con productos sin inventario', defaultValue: values.belowZeroInventory, onChange: handleSwitchChange }),
                React.createElement(SwitchComponent, { className: 'mt-2', name: 'blockPaymentInPpd', label: 'Bloquear aplicaci\u00F3n de pagos en m\u00F3dulo de ventas cuando el m\u00E9todo de pago sea PPD', defaultValue: values.blockPaymentInPpd, onChange: handleSwitchChange }),
                React.createElement(SwitchComponent, { className: 'mt-2', name: 'autoPaymentInPue', label: 'Autocompletar el pago en el m\u00F3dulo de ventas cuando el m\u00E9todo de pago sea PUE', defaultValue: values.autoPaymentInPue, onChange: handleSwitchChange }),
                React.createElement(TextFieldComponent, { label: 'Porcentaje de monedero electr\u00F3nico generado por venta', name: 'percentEMoney', value: values.percentEMoney, error: errors.percentEMoney, onChange: handleChange }))),
        React.createElement(FooterDialogComponent, null,
            React.createElement("div", null),
            React.createElement(ButtonFooterDialogComponent, { color: 'success', loading: loadingUpdate, onClick: handleClick }, "Actualizar informaci\u00F3n"))));
};
export default GeneralCompanyComponent;
