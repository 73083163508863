var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useEffect, useState } from 'react';
import { useReactiveVar } from '@apollo/client';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Table, TableBody, TableCell, TableHead, TableRow, } from '@mui/material';
import ProductInventoryReportComponent from '../Product';
import GQL_PRODUCT_INVENTORY from '../../../../apollo/gql/productInventory';
import useApolloLazyQuery from '../../../../hooks/useApolloLazyQuery';
import LoadingProgress from '../../../../presentationals/LoadingProgress';
import ErrorDataComponent from '../../../../presentationals/ErrorData';
import getInputErrorFormat from '../../../sale.v2/Action/helpers/getInputErrorFormat';
import { filter } from '../../../../apollo/cache';
var ClassificationInventoryReportComponent = function (props) {
    var _a = useState(false), isExpanded = _a[0], setIsExpanded = _a[1];
    var filterVar = useReactiveVar(filter);
    var _b = useApolloLazyQuery(GQL_PRODUCT_INVENTORY.GET_PRODUCT_INVENTORIES, {
        fetchPolicy: 'cache-and-network',
    }), getData = _b[0], _c = _b[1], loading = _c.loading, data = _c.data;
    var getDataFromServer = function () { return __awaiter(void 0, void 0, void 0, function () {
        var searchBy, error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    searchBy = filterVar.like ? { searchBy: filterVar.like } : {};
                    return [4 /*yield*/, getData({
                            variables: __assign({ branchId: props.branchId, classificationId: props.Classification.id }, searchBy),
                        })];
                case 1:
                    _a.sent();
                    return [3 /*break*/, 3];
                case 2:
                    error_1 = _a.sent();
                    getInputErrorFormat(error_1);
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    useEffect(function () {
        if (isExpanded)
            getDataFromServer();
    }, [filterVar, isExpanded, props.branchId]);
    var handleChange = function (event, isExpanded) {
        return setIsExpanded(isExpanded);
    };
    return (React.createElement(Accordion, { onChange: handleChange, className: 'border-0 border-t border-solid border-gray-200' },
        React.createElement(AccordionSummary, { expandIcon: React.createElement(ExpandMoreIcon, null), "aria-controls": "".concat(props.Classification.name, "-content"), id: "".concat(props.Classification.name, "-header") }, props.Classification.name),
        React.createElement(AccordionDetails, { className: 'p-0 pb-3' }, loading ? (React.createElement(LoadingProgress, null)) : !data ? (React.createElement(ErrorDataComponent, null)) : (React.createElement(Table, { size: 'small', className: 'border-0 border-t border-solid border-gray-200' },
            React.createElement(TableHead, null,
                React.createElement(TableRow, null,
                    React.createElement(TableCell, null, "Producto"),
                    React.createElement(TableCell, null, "Cantidad"),
                    React.createElement(TableCell, null, "Costo"))),
            React.createElement(TableBody, null, data.map(function (elCh, i) { return (React.createElement(ProductInventoryReportComponent, __assign({ index: i, key: elCh.ProductPresentation.id }, elCh, { onShowProductHistoryButtonClick: props.onShowProductHistoryClick }))); })))))));
};
export default ClassificationInventoryReportComponent;
