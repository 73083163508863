var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React, { useEffect, useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { useParams, useSearchParams } from 'react-router-dom';
import LoadingProgress from '../../../presentationals/LoadingProgress';
import GQL_ATTENDANCE from '../../../apollo/gql/attendance';
import useApolloLazyQuery from '../../../hooks/useApolloLazyQuery';
import GQL_EMPLOYEE from '../../../apollo/gql/employee';
import setDefaultDates from '../helpers/setDefaultDates';
import { dateFormat, hourFormat } from '../../../helpers';
import sumTimes from '../../../helpers/sumTimes';
import ErrorDataComponent from '../../../presentationals/ErrorData';
var AttendanceByEmployeeComponent = function () {
    var defaultTime = '00:00:00';
    var _a = useSearchParams(), searchParams = _a[0], setSearchParams = _a[1];
    var start = searchParams.get('start');
    var end = searchParams.get('end');
    var params = useParams();
    var _b = useState(['', '']), filterLabel = _b[0], setFilterLabel = _b[1];
    var _c = useState([defaultTime, defaultTime]), totals = _c[0], setTotals = _c[1];
    var _d = useApolloLazyQuery(GQL_ATTENDANCE.GET_ATTENDANCES), get = _d[0], _e = _d[1], data = _e.data, loading = _e.loading;
    var _f = useApolloLazyQuery(GQL_EMPLOYEE.GET_EMPLOYEE_BY_ID), getEmployee = _f[0], _g = _f[1], employee = _g.data, loadingEmployee = _g.loading;
    useEffect(function () {
        if (!start && !end) {
            var defaultDates = setDefaultDates();
            setSearchParams({ start: defaultDates.start.toString(), end: defaultDates.end.toString() });
        }
        if (start && end) {
            var startLabel = dateFormat(start);
            var endLabel = dateFormat(end);
            setFilterLabel([startLabel || '', endLabel || '']);
        }
    }, [start, end]);
    useEffect(function () {
        if (data) {
            var totals_1 = data.rows.reduce(function (totals, el) {
                return [
                    sumTimes(totals[0], el.delay !== '0' ? el.delay : defaultTime, {
                        padStart: 1,
                    }),
                    sumTimes(totals[1], el.totalWorkHours !== '0' ? el.totalWorkHours : defaultTime, {
                        padStart: 1,
                    }),
                ];
            }, [defaultTime, defaultTime]);
            setTotals(totals_1);
        }
    }, [data]);
    useEffect(function () {
        if (params.employeeId && start && end) {
            getEmployee({ variables: { id: params.employeeId } });
            get({
                variables: {
                    employeeId: params.employeeId,
                    start: start,
                    end: end,
                },
            });
        }
    }, [params.employeeId, start, end]);
    console.log(data);
    if (loading || loadingEmployee)
        return React.createElement(LoadingProgress, null);
    if (!data || !employee)
        return React.createElement(ErrorDataComponent, null);
    return (React.createElement("div", { className: 'flex flex-1 flex-col' },
        React.createElement("div", { className: 'flex flex-col bg-slate-200' },
            React.createElement("div", { className: 'flex flex-col px-4 py-3' },
                React.createElement("div", { className: 'text-xl' },
                    "Asistencias del ",
                    filterLabel[0],
                    " al ",
                    filterLabel[1])),
            React.createElement("div", { className: 'flex flex-col px-4 py-3 bg-slate-100 border-0 border-y border-slate-400 border-solid' },
                React.createElement("div", null, "Empleado"),
                React.createElement("div", { className: 'tracking-tighter text-2xl' }, "".concat(employee.firstName, " ").concat(employee.lastName)))),
        React.createElement("div", { className: 'flex-1 flex flex-col basis-0 overflow-auto' },
            React.createElement(TableContainer, null,
                React.createElement(Table, { size: 'small', stickyHeader: true },
                    React.createElement(TableHead, null,
                        React.createElement(TableRow, null,
                            React.createElement(TableCell, null, "D\u00EDa"),
                            React.createElement(TableCell, { className: 'bg-slate-200' }, "Entrada"),
                            React.createElement(TableCell, { className: 'bg-slate-200' }, "Salida"),
                            React.createElement(TableCell, null, "Retardo"),
                            React.createElement(TableCell, null, "Tiempo trabajado"),
                            React.createElement(TableCell, null, "Incidencia"))),
                    React.createElement(TableBody, null,
                        (data === null || data === void 0 ? void 0 : data.rows) &&
                            __spreadArray([], data.rows, true).reverse().map(function (el) { return (React.createElement(TableRow, { key: el.id },
                                React.createElement(TableCell, null, dateFormat(el.checkIn)),
                                React.createElement(TableCell, null, hourFormat(el.checkIn)),
                                React.createElement(TableCell, null, el.checkOut ? hourFormat(el.checkOut) : '-'),
                                React.createElement(TableCell, null, el.delay !== '0' ? el.delay : '-'),
                                React.createElement(TableCell, null, el.totalWorkHours !== '0' ? el.totalWorkHours : '-'),
                                React.createElement(TableCell, null, el.lastIncident || '-'))); }),
                        React.createElement(TableRow, null,
                            React.createElement(TableCell, { colSpan: 3 }),
                            React.createElement(TableCell, null, totals[0]),
                            React.createElement(TableCell, null, totals[1]),
                            React.createElement(TableCell, null))))))));
};
export default AttendanceByEmployeeComponent;
