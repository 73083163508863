import { gql } from '@apollo/client';

const UPDATE_AUTH_SALE_CONFIG = gql`
  mutation UpdateAuthSaleConfig($webAuth: Boolean, $userPassword: String!) {
    updateAuthSaleConfig(input: { webAuth: $webAuth, userPassword: $userPassword }) {
      webAuth
    }
  }
`;

const UPDATE_BRANCH_CONFIG = gql`
  mutation UpdateBranchConfig($branchId: ID!, $codeConfirmation: Int!) {
    updateBranchConfig(input: { branchId: $branchId, optionCodeConfirmation: $codeConfirmation }) {
      id
      codeConfirmation
    }
  }
`;

const GET_BY_ID = gql`
  query BranchConfig {
    branchConfig {
      branchId
      codeConfirmation
    }
  }
`;

export default {
  UPDATE_AUTH_SALE_CONFIG,
  UPDATE_BRANCH_CONFIG,
  GET_BY_ID,
};
