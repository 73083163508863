import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import PlaceOutlinedIcon from '@mui/icons-material/PlaceOutlined';
import OnlineOrderMenuButtonComponent from '../helpers/MenuButton';
var OnlineOrderAddressButtonComponent = forwardRef(function (props, ref) {
    var control = useFormContext().control;
    var _a = useState('No definido'), addressLabel = _a[0], setAddressLabel = _a[1];
    var type = useController({
        name: 'type',
        control: control,
    }).field.value;
    var customer = useController({
        name: 'customer',
        control: control,
    }).field.value;
    var onChange = useController({
        name: 'addressId',
        control: control,
    }).field.onChange;
    var onShippingChange = useController({
        name: 'shipping',
        control: control,
    }).field.onChange;
    var handleClick = function (e) {
        props.onClick(e, customer === null || customer === void 0 ? void 0 : customer.id);
    };
    var handleSelectCustomerAddress = function (data) {
        onChange(data.id);
        setAddressLabel("".concat(data.addressLine1, " ").concat(data.addressLine2));
        onShippingChange(data.rate);
    };
    useImperativeHandle(ref, function () { return ({
        onSelect: handleSelectCustomerAddress,
    }); });
    return (React.createElement(OnlineOrderMenuButtonComponent, { disabled: type === 2, onClick: handleClick, label: 'Domicilio', Icon: PlaceOutlinedIcon, value: type === 2 ? 'No aplica' : addressLabel }));
});
export default OnlineOrderAddressButtonComponent;
