import React from 'react';
import useGetSessionRole from '../../../../../hooks/useGetSessionRole';
var StockSaleV2FieldComponent = function (props) {
    var _a, _b;
    var _c = useGetSessionRole(), data = _c.data, loading = _c.loading, isOwner = _c.isOwner;
    if (!data || loading)
        return React.createElement("div", null);
    return isOwner ||
        (((_b = (_a = data === null || data === void 0 ? void 0 : data.Permissions) === null || _a === void 0 ? void 0 : _a.Product) === null || _b === void 0 ? void 0 : _b.showProductStock) &&
            data.Permissions.Product.showProductStock > 0) ? (React.createElement("div", { className: 'flex flex-1 border-solid border-0 border-r-[1px] border-gray-400 p-2' }, "Stock: ".concat(props.stock, " ").concat(props.presentationName).trim())) : (React.createElement("div", null));
};
export default StockSaleV2FieldComponent;
