import React from 'react';
import AddButtonHeaderModuleV3Component from '../AddButton';
import TableSettingButtonComponent from '../TableSettingButton';
import MenuHeaderModuleV3Component from '../Menu';
var HeaderOptionsComponent = function (props) {
    return (React.createElement("div", { className: 'flex' },
        props.customizedActions && props.customizedActions,
        !props.hideAdd && (React.createElement(AddButtonHeaderModuleV3Component, { customizedCreateOpenForm: props.customizedCreateOpenForm, isLoading: props.isLoading })),
        props.tableSetting && React.createElement(TableSettingButtonComponent, { isLoading: props.isLoading }),
        !props.hideMenu && React.createElement(MenuHeaderModuleV3Component, { isLoading: props.isLoading })));
};
export default HeaderOptionsComponent;
