import React from 'react';
import { useReactiveVar } from '@apollo/client';
import { Menu } from '@mui/material';
import { isAppsMenuOpen } from '../../../apollo/cache';
import CompanyMenuButtonComponent from './CompanyButton';
import CalendarMenuButtonComponent from './CalendarButton';
var AppsMenuDialogComponent = function () {
    var isOpen = useReactiveVar(isAppsMenuOpen);
    var handleClose = function () {
        isAppsMenuOpen([false, null]);
    };
    return (React.createElement(Menu, { id: 'apps-menu', anchorEl: isOpen[1], anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
        }, transformOrigin: {
            vertical: 'top',
            horizontal: 'right',
        }, keepMounted: true, open: isOpen[0], onClose: handleClose, classes: { paper: 'bg-slate-100 w-[280px] mt-10' } },
        React.createElement("div", { className: 'bg-white mx-3 my-1 grid grid-cols-2' },
            React.createElement(CompanyMenuButtonComponent, null),
            React.createElement(CalendarMenuButtonComponent, null))));
};
export default AppsMenuDialogComponent;
