import React, { useEffect, useState } from 'react';

import { useReactiveVar } from '@apollo/client';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableHead from '@mui/material/TableHead';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import EventIcon from '@mui/icons-material/EventOutlined';

import IconButton from '../../presentationals/IconButton';
import FormDialog from '../../presentationals/FormDialog';
import { sessionCalendarForm, isCalendarFormOpen } from '../../apollo/cache';
import { capitalize } from '../../helpers';

import PrevCalendarForm from './prevCalendar.dialog';

const SessionCalendarComponent = () => {
  const sessionCalendarFormVar = useReactiveVar(sessionCalendarForm);

  const [crms, setCrms] = useState([]);

  useEffect(() => {
    if (sessionCalendarFormVar.Crms.length > 0) setCrms(sessionCalendarFormVar.Crms);
  }, [sessionCalendarFormVar.Crms]);

  const handleCalendar = (e, { id, customerId }) => {
    isCalendarFormOpen([true, { crmId: id, customerId }]);
  };

  const handleClose = () => sessionCalendarForm({ isOpen: false, Crms: [] });

  return (
    <FormDialog
      title='Agendar siguiente sesión'
      isOpen={sessionCalendarFormVar.isOpen}
      isLoading={false}
      hideAction
      handleClose={handleClose}
    >
      <>
        {crms.length > 0 && (
          <TableContainer className='mt-3 rounded'>
            <Table size='small'>
              <TableHead>
                <TableRow>
                  <TableCell>Producto</TableCell>
                  <TableCell align='center'>Sesiones</TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {crms.map((el, i) => (
                  <TableRow key={i} hover>
                    <TableCell>{capitalize(el.Product.name)}</TableCell>
                    <TableCell align='center'>{el.sessions}</TableCell>
                    <TableCell align='right'>
                      <IconButton
                        label='Siguiente Cita'
                        params={{
                          id: el.id,
                          customerId: el.customerId,
                        }}
                        action={handleCalendar}
                        icon={<EventIcon fontSize='small' />}
                        disabled={el.disabled}
                        size='large'
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
        <PrevCalendarForm crms={crms} customerId={crms && crms[0] && crms[0].customerId} />
      </>
    </FormDialog>
  );
};

export default SessionCalendarComponent;
