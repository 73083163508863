import React, { useEffect, useRef } from 'react';
import { useReactiveVar } from '@apollo/client';
import FooterDialogComponent from '../../../../presentationals/Dialog/Footer';
import DeleteButtonGeneralProductPromotionComponent from './DeleteButton';
import EditButtonGeneralProductPromotionComponent from './EditButton';
import BodyProductPromotionComponent from '../Body';
import useApolloLazyQuery from '../../../../hooks/useApolloLazyQuery';
import GQL_PROMOTION from '../../../../apollo/gql/promotion';
import { isProductPromotionOpen } from '../../../../apollo/cache';
import LoadingProgress from '../../../../presentationals/LoadingProgress';
import ErrorDataComponent from '../../../../presentationals/ErrorData';
var GeneralEditProductPromotionComponent = function () {
    var bodyProductPromotionRef = useRef(null);
    var isOpen = useReactiveVar(isProductPromotionOpen);
    var _a = useApolloLazyQuery(GQL_PROMOTION.GET_PRODUCT_PROMOTION_BY_ID), getById = _a[0], _b = _a[1], loading = _b.loading, data = _b.data;
    useEffect(function () {
        if (isOpen)
            getById({ variables: { id: isOpen } });
    }, [isOpen]);
    var handleGetValues = function () { var _a; return (_a = bodyProductPromotionRef.current) === null || _a === void 0 ? void 0 : _a.getValues(); };
    if (loading)
        return React.createElement(LoadingProgress, null);
    if (!data)
        return React.createElement(ErrorDataComponent, null);
    return (React.createElement("div", { className: 'flex flex-1 flex-col' },
        React.createElement("div", { className: 'flex flex-1 flex-col overflow-hidden px-3 py-2 ' },
            React.createElement(BodyProductPromotionComponent, { ref: bodyProductPromotionRef, defaultValues: {
                    price: data.price.toString(),
                    productId: data.productId,
                    productPresentationId: data.productPresentationId,
                    qty: data.quantity.toString(),
                } })),
        React.createElement(FooterDialogComponent, null,
            React.createElement(DeleteButtonGeneralProductPromotionComponent, null),
            React.createElement(EditButtonGeneralProductPromotionComponent, { onClick: handleGetValues }))));
};
export default GeneralEditProductPromotionComponent;
