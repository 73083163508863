import { gql } from '@apollo/client';

const FRAGMENT_BOX = gql`
  fragment fragmentBox on Box {
    id
    name
    weight
    length
    height
    width
    quantity
  }
`;

const GET = gql`
  query Boxes($offset: Int, $limit: Int, $like: String) {
    boxes(offset: $offset, limit: $limit, like: $like) {
      count
      rows {
        id
        name
        weight
        length
        height
        width
        quantity
      }
    }
  }
`;

const GET_BY_ID = gql`
  ${FRAGMENT_BOX}
  query Box($id: ID!) {
    box(id: $id) {
      ...fragmentBox
    }
  }
`;

const CREATE = gql`
  ${FRAGMENT_BOX}
  mutation CreateBox(
    $name: String!
    $weight: Float!
    $length: Float!
    $height: Float!
    $width: Float!
  ) {
    createBox(
      input: { name: $name, weight: $weight, length: $length, height: $height, width: $width }
    ) {
      ...fragmentBox
    }
  }
`;

const UPDATE = gql`
  ${FRAGMENT_BOX}
  mutation UpdateBox(
    $id: ID!
    $name: String!
    $weight: Float!
    $length: Float!
    $height: Float!
    $width: Float!
  ) {
    updateBox(
      id: $id
      input: { name: $name, weight: $weight, length: $length, height: $height, width: $width }
    ) {
      ...fragmentBox
    }
  }
`;

const DELETE = gql`
  ${FRAGMENT_BOX}
  mutation DeleteBox($id: ID!) {
    deleteBox(id: $id) {
      ...fragmentBox
    }
  }
`;

const Box = { GET, GET_BY_ID, CREATE, UPDATE, DELETE, FRAGMENT_BOX };
export default Box;
