import React from 'react';
import { useReactiveVar } from '@apollo/client';
import { inputError, isCreateProductPriceOpen, productPromotionPriceForm, } from '../../../../../apollo/cache';
import getDefaultProductPromotionPriceValues from '../../../../promotion/CreateProductPromotionPrice/Body/helpers/getDefaultValues';
import DialogComponent from '../../../../../presentationals/Dialog';
import BodyProductPromotionPriceComponent from '../../../../promotion/CreateProductPromotionPrice/Body';
import FooterDialogComponent from '../../../../../presentationals/Dialog/Footer';
import CreateButtonRateProductPresentationComponent from './CreateButton';
var CreateRateProductPresentationComponent = function () {
    var isOpen = useReactiveVar(isCreateProductPriceOpen);
    var handleClose = function () {
        var defaultValues = getDefaultProductPromotionPriceValues();
        productPromotionPriceForm(defaultValues);
        inputError({});
        isCreateProductPriceOpen(false);
    };
    return (React.createElement(DialogComponent, { fullScreen: true, open: isOpen, title: 'Nueva tarifa', onClose: handleClose },
        React.createElement("div", { className: 'flex flex-1 flex-col overflow-hidden' },
            React.createElement(BodyProductPromotionPriceComponent, { showRatesNames: true })),
        React.createElement(FooterDialogComponent, null,
            React.createElement("div", null),
            React.createElement(CreateButtonRateProductPresentationComponent, null))));
};
export default CreateRateProductPresentationComponent;
