import { gql } from '@apollo/client';

const GET_INVENTORIES = gql`
  query Inventories($name: String, $limit: Int, $offset: Int) {
    inventories(like: $name, limit: $limit, offset: $offset) {
      count
      rows {
        id
        quantity
        action
        type
        Product {
          id
          name
          price
          cost
          Classification {
            id
            name
          }
        }
      }
    }
  }
`;

const GET_ADJUST_INVENTORIES_PRODUCTS = gql`
  query AdjustInventoriesProducts($start: Date, $end: Date) {
    adjustInventoriesProducts(start: $start, end: $end) {
      Product {
        sku
        name
        cost
      }
      currentQuantity
      previousQuantity
    }
  }
`;
export default {
  GET_ADJUST_INVENTORIES_PRODUCTS,
  GET_INVENTORIES,
};
