var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React from 'react';
import axios from 'axios';
import { useMutation } from '@apollo/client';
import ButtonFooterDialogComponent from '../../../../../../presentationals/Dialog/Footer/Button';
import getInputErrorFormat from '../../../../../sale.v2/Action/helpers/getInputErrorFormat';
import GQL from '../../../../_gql';
import setInputErrorFormat from '../../../../../sale.v2/Action/helpers/setInputErrorFormat';
import CONFIG from '../../../../../../config/config.json';
import getSession from '../../../../../../helpers/getSession';
import { actionForm, handleContinueConfiguration, step } from '../../../../../../apollo/cache';
import useSnackbarDialog from '../../../../../../store/snackbar/snackbarDialog';
var CreatePurchaseOrderByDocumentButtonComponent = function (props) {
    var setSnackbar = useSnackbarDialog().setSnackbar;
    var createPurchaseOrder = useMutation(GQL.CREATE, {
        update: function (cache, _a) {
            var data = _a.data;
            cache.modify({
                fields: {
                    purchaseOrders: function (cachedPurchaseOrder) {
                        var newPurchaseOrderRef = cache.writeFragment({
                            data: __assign({}, data.createPurchaseOrderV2),
                            fragment: GQL.FRAGMENT_NEW_PURCHASE_ORDER,
                        });
                        return __assign(__assign({}, cachedPurchaseOrder), { count: cachedPurchaseOrder.count + 1, rows: __spreadArray([newPurchaseOrderRef], cachedPurchaseOrder.rows, true) });
                    },
                },
            });
        },
    })[0];
    var handleClick = function () { return __awaiter(void 0, void 0, void 0, function () {
        var variables, data, session, token, authorization, response, e_1;
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    _b.trys.push([0, 3, , 4]);
                    if (!props.file)
                        throw new Error('Adjunta el documento con los productos comprados.');
                    if (props.values.currency !== 'MXN' &&
                        (!props.values.exchangeRate ||
                            Number.isNaN(parseFloat(props.values.exchangeRate)) ||
                            parseFloat(props.values.exchangeRate) <= 0))
                        throw new Error(setInputErrorFormat('exchangeRate', 'El valor del dólar es inválido.'));
                    if ((props.values.discount &&
                        parseFloat(props.values.discount) > 0 &&
                        (Number.isNaN(parseFloat(props.values.discount)) ||
                            parseFloat(props.values.discount) < 0)) ||
                        (parseFloat(props.values.discountType) === 1 && parseFloat(props.values.discount) > 100))
                        throw new Error(setInputErrorFormat('discount', 'El descuento es inválido.'));
                    if (props.values.providerId === null)
                        throw new Error(setInputErrorFormat('providerId', 'Selecciona el proveedor de la compra.'));
                    variables = {
                        date: props.values.action === '3' ? props.values.pursacheDate : null,
                        status: parseInt(props.values.action),
                        branchId: props.values.branchId,
                        providerId: props.values.providerId.value,
                        description: props.values.description,
                        Products: [],
                        subFolio: props.values.action === '3' ? props.values.subFolio : null,
                        paymentForm: props.values.action === '3' ? props.values.paymentForm : null,
                        type: props.values.action === '3' ? props.values.type : null,
                        currency: props.values.currency,
                        exchangeRate: props.values.currency === 'MXN' ? 1 : parseFloat(props.values.exchangeRate),
                        bonus: props.values.bonus && parseFloat(props.values.bonus) > 0
                            ? parseFloat(props.values.bonus)
                            : 0,
                    };
                    return [4 /*yield*/, createPurchaseOrder({ variables: variables })];
                case 1:
                    data = (_b.sent()).data;
                    session = getSession();
                    token = session === null || session === void 0 ? void 0 : session.accessToken;
                    authorization = token ? { authorization: "Bearer ".concat(token) } : {};
                    return [4 /*yield*/, axios.put("".concat(CONFIG.restServer.uri, "/purchaseOrders/importProducts"), {
                            purchaseOrderId: data.createPurchaseOrderV2.id,
                            file: props.file,
                        }, {
                            headers: __assign(__assign({}, authorization), { 'Content-Type': 'multipart/form-data' }),
                        })];
                case 2:
                    response = _b.sent();
                    if ((_a = response.data) === null || _a === void 0 ? void 0 : _a.error) {
                        setSnackbar({
                            isOpen: true,
                            label: response.data.message,
                            severity: 'error',
                        });
                        return [2 /*return*/];
                    }
                    setSnackbar({
                        isOpen: true,
                        label: 'La orden de compra fue emitida con éxito.',
                        severity: 'success',
                    });
                    actionForm(__assign(__assign({}, actionForm()), { isOpen: false, action: '' }));
                    handleContinueConfiguration({});
                    step(0);
                    return [3 /*break*/, 4];
                case 3:
                    e_1 = _b.sent();
                    getInputErrorFormat(e_1);
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    }); };
    return (React.createElement(ButtonFooterDialogComponent, { onClick: handleClick }, "Crear compra con documento"));
};
export default CreatePurchaseOrderByDocumentButtonComponent;
