var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import { useReactiveVar } from '@apollo/client';
import DialogComponent from '../../../../../../presentationals/Dialog';
import { PickupItemForm, inputError, isEditPickupItemOpen } from '../../../../../../apollo/cache';
import FooterDialogComponent from '../../../../../../presentationals/Dialog/Footer';
import EditPickupItemButtonComponent from './EditButton';
import TextFieldComponent from '../../../../../../presentationals/FormComponents/TextField';
var EditPickupItemComponent = function () {
    var values = useReactiveVar(PickupItemForm);
    var errors = useReactiveVar(inputError);
    var isOpen = useReactiveVar(isEditPickupItemOpen);
    var handleClose = function () {
        isEditPickupItemOpen(false);
        inputError({});
    };
    var handleChange = function (name, value) {
        var _a;
        PickupItemForm(__assign(__assign({}, values), (_a = {}, _a[name] = value, _a)));
    };
    return (React.createElement(DialogComponent, { fullScreen: true, title: 'Actualizar cantidad recolectada del producto', open: !!isOpen, onClose: handleClose },
        React.createElement("div", { className: 'flex flex-1 flex-col' },
            React.createElement("div", { className: 'flex flex-1 flex-col sm:w-1/3 px-4 py-2' },
                React.createElement(TextFieldComponent, { autoFocus: true, label: 'Cantidad de piezas recuperadas', name: 'qty', value: values.qty || '', error: errors.qty, onChange: handleChange }),
                React.createElement(TextFieldComponent, { label: 'Comentario', name: 'commentary', value: values.commentary || '', error: errors.commentary, onChange: handleChange })),
            React.createElement(FooterDialogComponent, null,
                React.createElement("div", null),
                React.createElement(EditPickupItemButtonComponent, { values: values })))));
};
export default EditPickupItemComponent;
