import React, { useEffect } from 'react';
import { Skeleton } from '@mui/material';
import useInventoryReportStore from '../../../../../../store/inventoryReport';
import GQL_BRANCH from '../../../../../../apollo/gql/branch';
import useApolloLazyQuery from '../../../../../../hooks/useApolloLazyQuery';
var OriginBranchButtonComponent = function () {
    var branchId = useInventoryReportStore(function (state) { return state.branchId; });
    var _a = useApolloLazyQuery(GQL_BRANCH.GET_BRANCH_BY_ID), getBranch = _a[0], _b = _a[1], data = _b.data, loading = _b.loading;
    useEffect(function () {
        if (branchId)
            getBranch({ variables: { id: branchId } });
    }, [branchId]);
    if (loading)
        return React.createElement(Skeleton, { className: 'tracking-tighter leading-none' });
    return React.createElement("div", { className: 'tracking-tighter leading-none' }, (data === null || data === void 0 ? void 0 : data.name) || 'Indefinido');
};
export default OriginBranchButtonComponent;
