import React, { useState } from 'react';
import { Tab, Tabs } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DownloadIcon from '@mui/icons-material/Download';
var MenuOnlineInvoiceComponent = function (props) {
    var _a = useState(0), value = _a[0], setValue = _a[1];
    var handleChange = function (e, value) {
        setValue(value);
        props.onChange(value);
    };
    return (React.createElement(Tabs, { value: value, onChange: handleChange, variant: 'scrollable', scrollButtons: 'auto', className: 'flex bg-slate-100 border-0 border-y border-[#d6d6d6] border-solid' },
        React.createElement(Tab, { icon: React.createElement(AddIcon, null), label: 'Generar' }),
        React.createElement(Tab, { icon: React.createElement(DownloadIcon, null), label: 'Descargar' })));
};
export default MenuOnlineInvoiceComponent;
