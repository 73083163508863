import React from 'react';
import { FormGroup, Typography } from '@mui/material';
import useApolloQuery from '../../../../../../../../../../hooks/useApolloQuery';
import GQL from '../../../../../../../../../Tag/_gql';
import Item from './Item';
var TagsItemAgendaSettingsScheduleAvailabilityDialogSettingButtonFooterFormCalendarComponent = function (props) {
    var data = useApolloQuery(GQL.GET).data;
    return (React.createElement("div", { className: 'mx-2' },
        React.createElement(FormGroup, null,
            React.createElement(Typography, { variant: 'subtitle1' }, "Etiquetas disponibles"),
            React.createElement("div", { className: 'grid grid-cols-1  md:grid-cols-4 gap-2 mx-4' }, data &&
                data.map(function (el) { return (React.createElement(Item, { key: el.id, index: props.index, id: el.id, name: el.name, color: el.color })); })))));
};
export default TagsItemAgendaSettingsScheduleAvailabilityDialogSettingButtonFooterFormCalendarComponent;
