import React, { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import ButtonActionSaleV2Component from '../../../../sale.v2/Action/helpers/Button';
import DiscountCreatePurchaseOrderDialogComponent from './Dialog';
import getDefaultCreateDiscountPurchaseOrderValues from './Dialog/Body/helpers/getDefaultValues';
import convertToCurrencyLabel from '../../../../../helpers/convertToCurrencyLabel';
var DiscountCreatePurchaseOrderComponent = forwardRef(function (props, ref) {
    var discountCreatePurchaseOrderDialogRef = useRef(null);
    var defaultValues = getDefaultCreateDiscountPurchaseOrderValues();
    var _a = useState(defaultValues), values = _a[0], setValues = _a[1];
    var handleClick = function () {
        var _a;
        (_a = discountCreatePurchaseOrderDialogRef.current) === null || _a === void 0 ? void 0 : _a.open();
    };
    var handleGetValues = function (values) {
        setValues(values);
    };
    var getValues = function () { return values; };
    useImperativeHandle(ref, function () { return ({
        getValues: getValues,
    }); });
    return (React.createElement(React.Fragment, null,
        React.createElement(ButtonActionSaleV2Component, { onClick: handleClick, title: 'Descuento', subtitle: parseFloat(values.discount || '0') === 0
                ? 'No aplicado'
                : values.discountType === 1
                    ? "".concat(values.discount, " % dscto")
                    : "$".concat(convertToCurrencyLabel(parseFloat(values.discount)), " dscto") }),
        React.createElement(DiscountCreatePurchaseOrderDialogComponent, { ref: discountCreatePurchaseOrderDialogRef, getValues: handleGetValues, onChange: props.onChange, defaultValues: values })));
});
export default DiscountCreatePurchaseOrderComponent;
