var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useEffect, useState } from 'react';
import IronIcon from '@mui/icons-material/Iron';
import ListItemPermissionEditRoleComponent from '../helpers/ListItem';
import SwitchPermissionFieldComponent from '../helpers/SwitchPermissionField';
import SelectPermissionFieldComponent from '../helpers/SelectPermissionField';
import getDefaultBranchOptions from '../helpers/getDefaultBrachOptions';
var defaultProductPermissionValues = {
    createNewProduct: false,
    editProduct: false,
    showProductCost: false,
    showProductStock: 0,
    editProductPrice: 0,
    deleteProduct: false,
    editTaxes: false,
};
var ProductPermissionEditRoleComponent = function (props) {
    var defaultBranchOptions = getDefaultBranchOptions();
    var _a = useState(defaultProductPermissionValues), values = _a[0], setValues = _a[1];
    useEffect(function () {
        setValues(function (values) { return (__assign(__assign({}, values), props.Permissions)); });
    }, [props.Permissions]);
    return (React.createElement(ListItemPermissionEditRoleComponent, { icon: React.createElement(IronIcon, null), name: 'Productos' },
        React.createElement("div", { className: 'mx-4 py-3 grid grid-cols-1 md:grid-cols-3 gap-4' },
            React.createElement("div", { className: 'flex flex-col' },
                React.createElement(SwitchPermissionFieldComponent, __assign({}, props, { name: 'createNewProduct', label: 'Crear productos', defaultValue: values.createNewProduct })),
                React.createElement(SwitchPermissionFieldComponent, __assign({}, props, { name: 'editProduct', label: 'Editar informaci\u00F3n general', defaultValue: values.editProduct })),
                React.createElement(SwitchPermissionFieldComponent, __assign({}, props, { name: 'showProductCost', label: 'Visualizar costo de productos', defaultValue: values.showProductCost })),
                React.createElement(SelectPermissionFieldComponent, __assign({}, props, { name: 'showProductStock', label: 'Visualizar el stock de los productos', defaultValue: values.showProductStock, options: defaultBranchOptions })),
                React.createElement(SwitchPermissionFieldComponent, __assign({}, props, { name: 'deleteProduct', label: 'Dar de baja productos', defaultValue: values.deleteProduct })),
                React.createElement(SwitchPermissionFieldComponent, __assign({}, props, { name: 'editTaxes', label: 'Editar datos de facturaci\u00F3n e impuestos del producto', defaultValue: values.editTaxes })),
                React.createElement(SelectPermissionFieldComponent, __assign({}, props, { name: 'editProductPrice', label: 'Editar el precio de un producto', defaultValue: values.editProductPrice, options: defaultBranchOptions }))))));
};
export default ProductPermissionEditRoleComponent;
