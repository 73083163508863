import React from 'react';

import Container from '../../presentationals/Module.v2/Container';

import Filter from './_filter';
import GQL from './_gql';

const Rows = [
  { label: 'Fecha', key: 'createdAt', sort: 'text', format: 'dateTime' },
  { label: 'Folio', key: 'Transfer folio && Transfer serial', strcat: '-' },
  { label: 'SKU', key: 'Product sku' },
  { label: 'COD', key: 'Product code' },
  { label: 'Producto', key: 'Product name', sort: 'text' },
  { label: 'Clasificación', key: 'Product Classification name', sort: 'text' },
  { label: 'Cant.', key: 'quantity', sort: 'text', align: 'center' },
  { label: 'Importe', key: 'total', sort: 'text', align: 'right', format: 'currency' },
  { label: 'Origen', key: 'Transfer Branch name', align: 'center' },
  { label: 'Destino', key: 'Transfer BranchTransfer name', sort: 'text' },
  {
    label: 'Responsable',
    key: 'Transfer Employee firstName && Transfer Employee lastName',
    strcat: ' ',
    sort: 'text',
  },
];
const title = 'Traspasos de productos detallado';
const operation = ['transferProductDetails', 'transferProductDetails'];

const Component = () => {
  return (
    <>
      <Container title={title} operation={operation} Rows={Rows} get={GQL.GET} filter noDelete />
      <Filter />
    </>
  );
};

export default Component;
