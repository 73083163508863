var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import { useParams } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import { IconButton, Tooltip } from '@mui/material';
import { isCreateProductVariationOpen, productVariationForm, } from '../../../../../../../apollo/cache';
var CreateIngredientTypeButtonComponent = function () {
    var params = useParams();
    var handleClick = function () {
        var values = productVariationForm();
        productVariationForm(__assign(__assign({}, values), { productPresentationId: parseInt(params.producPresentationId) }));
        isCreateProductVariationOpen(true);
    };
    return (React.createElement("div", { className: 'flex items-center ml-1 mb-1' },
        React.createElement(Tooltip, { title: 'Nuevo tipo' },
            React.createElement(IconButton, { onClick: handleClick, "aria-label": 'new-ingredient-type' },
                React.createElement(AddIcon, null)))));
};
export default CreateIngredientTypeButtonComponent;
