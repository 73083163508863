import React from 'react';
import { TableCell } from '@mui/material';
import TableRowItemLimitInventoryProductPresentationComponent from './TableRow';
import { currencyFormat } from '../../../../../../helpers';
var ItemTableLimitInventoryProductPresentationComponent = function (props) {
    return (React.createElement(TableRowItemLimitInventoryProductPresentationComponent, { productPresentationLimitInventoryId: props.ProductPresentationLimitInventory.id, background: props.index % 2 > 0 ? 'bg-inherit' : 'bg-gray-100' },
        React.createElement(TableCell, null, currencyFormat(props.ProductPresentationLimitInventory.minimum)),
        React.createElement(TableCell, null, currencyFormat(props.ProductPresentationLimitInventory.maximum)),
        React.createElement(TableCell, null, props.ProductPresentationLimitInventory.branchName)));
};
export default ItemTableLimitInventoryProductPresentationComponent;
