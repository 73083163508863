import React from 'react';
import { Tabs, Tab } from '@mui/material';
import PriceChangeOutlinedIcon from '@mui/icons-material/PriceChangeOutlined';
import CategoryIcon from '@mui/icons-material/Category';
var MenuEditProductPromotionComponent = function (props) {
    return (React.createElement(Tabs, { value: props.value, onChange: props.onChange, variant: 'scrollable', scrollButtons: 'auto', className: 'flex bg-slate-200 border-0 border-b border-[#d6d6d6] border-solid' },
        React.createElement(Tab, { icon: React.createElement(CategoryIcon, null), label: 'Datos generales' }),
        React.createElement(Tab, { icon: React.createElement(PriceChangeOutlinedIcon, null), label: 'Tarifas' })));
};
export default MenuEditProductPromotionComponent;
