import React from 'react';
import { useReactiveVar } from '@apollo/client';
import { inputError } from '../../../../../../../apollo/cache';
import SelectFieldComponent from '../../../../../../../presentationals/FormComponents/SelectField';
var printerOptions = [
    { label: 'Predeterminada en la clasificación', value: -1 },
    { label: 'No imprimir', value: 0 },
    { label: 'Impresora 1', value: 1 },
    { label: 'Impresora 2', value: 2 },
    { label: 'Impresora 3', value: 3 },
    { label: 'Impresora 4', value: 4 },
    { label: 'Impresora 5', value: 5 },
];
var PrinterPresentationFieldComponent = function (props) {
    var errors = useReactiveVar(inputError);
    return (React.createElement(SelectFieldComponent, { name: 'printer', label: 'Impresora de almac\u00E9n/producci\u00F3n asignada', value: props.value, error: errors.printer, onChange: props.onChange, options: printerOptions }));
};
export default PrinterPresentationFieldComponent;
