import React from 'react';
import { useReactiveVar } from '@apollo/client';
import { Button } from '@mui/material';
import {
  branchSaleV2,
  cartItems,
  currencySaleV2,
  discountTypeSaleV2,
  formatSaleV2,
  isSaleFormatFormOpen,
  totalsSaleV2,
} from '../../../../../../apollo/cache';
import getInputErrorFormat from '../../../helpers/getInputErrorFormat';
import getProductToCheckCart from '../../../../helpers/getProductToCheckCart';
import useApolloMutation from '../../../../../../hooks/useApolloMutation';
import SelectFieldComponent from '../../../../../../presentationals/FormComponents/SelectField';
import GQL from '../../../../ChooseProduct/AddButton/_gql';
import getTotalizedCartProducts from '../../../../helpers/getTotalizedCartProducts';
import IsInvoice from './IsInvoice';
import ShowTaxesSwitch from './ShowTaxesSwitch';
import getSaleTaxesOptions from '../../../../../../helpers/getSaleTaxesOptions';

const TaxesOptionFormFormatActionSaleV2Component = () => {
  const formatSaleV2Var = useReactiveVar(formatSaleV2);
  const taxesOptions = getSaleTaxesOptions();

  const [checkCart, { loading }] = useApolloMutation(GQL.CHECK_CART);

  const handleClick = async () => {
    try {
      const currencySaleV2Var = currencySaleV2();
      const branchSaleV2Var = branchSaleV2();
      const cartItemsVar = cartItems();
      const discountTypeSaleV2Var = discountTypeSaleV2();

      const taxesOption = formatSaleV2Var[1] === '' ? 1 : parseInt(formatSaleV2Var[1]);
      const isInvoice = formatSaleV2Var[2];

      formatSaleV2([formatSaleV2Var[0], taxesOption, formatSaleV2Var[2], formatSaleV2Var[3]]);
      isSaleFormatFormOpen(false);

      if (cartItemsVar.length === 0) return;

      const newCart = getProductToCheckCart(cartItemsVar);

      const { data } = await checkCart({
        variables: {
          isInvoice,
          taxesOption,
          discountType: parseInt(discountTypeSaleV2Var),
          shipping: 0,
          branchId: parseInt(branchSaleV2Var.branchInventory),
          currencyKey: parseFloat(currencySaleV2Var[0]),
          exchangeRate: parseFloat(currencySaleV2Var[1]),
          category: parseInt(formatSaleV2Var[0]),
          Products: newCart,
          Transactions: [],
          showTaxes: formatSaleV2Var[3],
        },
      });

      const cart = getTotalizedCartProducts(data.totalizeCart.Products, cartItemsVar, {
        taxesOption,
        isInvoice,
        showTaxes: formatSaleV2Var[3],
      });

      totalsSaleV2([
        data.totalizeCart.subtotal,
        data.totalizeCart.discount,
        data.totalizeCart.taxes,
        data.totalizeCart.total,
      ]);

      cartItems(cart);
    } catch (e) {
      getInputErrorFormat(e);
    }
  };

  const handleChange = (name, value) => {
    formatSaleV2([formatSaleV2Var[0], value, formatSaleV2Var[2], formatSaleV2Var[3]]);
  };

  const handleKeyDown = e => {
    if (e.keyCode === 13) {
      e.preventDefault();
      handleClick();
    }
  };

  return (
    <div className='p-5 pb-8 bg-gray-100'>
      {['3', '4', '5', '6'].includes(formatSaleV2Var[0]) && <IsInvoice />}
      {['-2', '1', '3', '4', '5', '6', '7'].includes(`${formatSaleV2Var[0]}`) && (
        <ShowTaxesSwitch />
      )}
      <SelectFieldComponent
        autoFocus
        label='¿Qué deseás hacer con los impuestos?'
        name='taxesOption'
        value={formatSaleV2Var[1]}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        options={taxesOptions}
      />
      <Button
        disabled={loading}
        onClick={handleClick}
        color='secondary'
        variant='contained'
        className='mt-1 shadow-none hover:shadow-none'
      >
        Continuar
      </Button>
    </div>
  );
};

export default TaxesOptionFormFormatActionSaleV2Component;
