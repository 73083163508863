import React from 'react';
import { useReactiveVar } from '@apollo/client';
import { isSendSaleByEmailOpen } from '../../apollo/cache';
import DialogComponent from '../Dialog';
import BodySendSaleByEmailDialogComponent from './Body';
var SendSaleByEmailDialogComponent = function () {
    var isOpen = useReactiveVar(isSendSaleByEmailOpen);
    var handleClose = function () {
        isSendSaleByEmailOpen(false);
    };
    return (React.createElement(DialogComponent, { title: 'Enviar documentos por correo electr\u00F3nico', fullScreen: true, open: !!isOpen, onClose: handleClose },
        React.createElement(BodySendSaleByEmailDialogComponent, null)));
};
export default SendSaleByEmailDialogComponent;
