var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useState } from 'react';
import { useReactiveVar } from '@apollo/client';
import getCancelSaleReasons from './helpers/getCancelSaleReasons';
import getCancelSatReasons from './helpers/getCancelSatReasons';
import SelectFieldComponent from '../../../../presentationals/FormComponents/SelectField';
import SwitchComponent from '../../../../presentationals/FormComponents/Switch';
import useGetSaleByParamSaleId from '../../../../hooks/useGetSaleByParamSaleId';
import LoadingProgress from '../../../../presentationals/LoadingProgress';
import { inputError } from '../../../../apollo/cache';
import TextFieldComponent from '../../../../presentationals/FormComponents/TextField';
import FooterDialogComponent from '../../../../presentationals/Dialog/Footer';
import CancelSaleButtonComponent from './CancelButton';
import { dateFormat } from '../../../../helpers';
var CancelSaleComponent = function (props) {
    var errors = useReactiveVar(inputError);
    var defaultValues = {
        userPassword: '',
        reason: '01',
        relatedFolio: '',
        noReturnInventory: true,
        reasonCancel: '',
        commentary: '',
        cancelOnlyInvoice: false,
    };
    var saleReasonsOptions = getCancelSaleReasons();
    var satReasonsOptions = getCancelSatReasons();
    var _a = useState(defaultValues), values = _a[0], setValues = _a[1];
    var _b = useGetSaleByParamSaleId(), data = _b.data, loading = _b.loading;
    var handleSwitchChange = function (name, value) {
        setValues(function (values) {
            var _a;
            return (__assign(__assign({}, values), (_a = {}, _a[name] = value, _a)));
        });
    };
    var handleChange = function (name, value) {
        setValues(function (values) {
            var _a;
            return (__assign(__assign({}, values), (_a = {}, _a[name] = value, _a)));
        });
    };
    if (loading || !data)
        return React.createElement(LoadingProgress, null);
    if (data.status === 0)
        return React.createElement("div", { className: 'p-3' },
            "La venta fue cancelada el d\u00EDa ",
            dateFormat(data.updatedAt));
    return (React.createElement("div", { className: 'flex flex-col flex-1' },
        React.createElement("div", { className: 'flex flex-1 flex-col p-3 basis-0 overflow-y-auto w-full sm:w-1/3' },
            React.createElement("div", { className: 'flex items-center' },
                React.createElement("div", { className: 'text-base font-medium mb-1 flex flex-1' },
                    "Cancelar la ",
                    props.module || 'venta')),
            React.createElement("div", { className: 'text-sm sm:text-base text-gray-600' },
                "Est\u00E1s a punto de cancelar una ",
                props.module || 'venta',
                ". Solicitamos una contrase\u00F1a con privilegios para realizar esta acci\u00F3n."),
            data.Invoice && data.category !== -1 && (React.createElement("div", { className: 'mt-2' },
                React.createElement(SwitchComponent, { name: 'cancelOnlyInvoice', label: "\u00BFDeseas cancelar solo la factura electr\u00F3nica y dejar la ".concat(props.module || 'venta', " habilita?"), onChange: handleSwitchChange, defaultValue: values.cancelOnlyInvoice }))),
            React.createElement(SwitchComponent, { name: 'noReturnInventory', label: '\u00BFDeseas devolver los productos a su inventario?', onChange: handleSwitchChange, defaultValue: values.noReturnInventory }),
            React.createElement(SelectFieldComponent, { label: 'Raz\u00F3n interna de cancelaci\u00F3n', name: 'reasonCancel', value: values.reasonCancel, error: errors.reasonCancel, options: saleReasonsOptions, onChange: handleChange }),
            data.invoiceId && (React.createElement(SelectFieldComponent, { label: 'Motivo de cancelaci\u00F3n (SAT)', name: 'reason', value: values.reason, onChange: handleChange, options: satReasonsOptions, error: errors.reason })),
            data.invoiceId && values.reason === '01' && (React.createElement(TextFieldComponent, { label: 'UUID relacionado (SAT)', name: 'relatedFolio', value: values.relatedFolio, error: errors.relatedFolio, onChange: handleChange })),
            React.createElement(TextFieldComponent, { label: 'Comentarios', name: 'commentary', value: values.commentary, error: errors.commentary, onChange: handleChange, multiline: true, rows: 3 }),
            React.createElement(TextFieldComponent, { label: 'Contrase\u00F1a autorizada', name: 'userPassword', value: values.userPassword, onChange: handleChange, error: errors.userPassword, type: 'password' })),
        React.createElement(FooterDialogComponent, null,
            React.createElement("div", null),
            React.createElement(CancelSaleButtonComponent, { saleId: data.id, invoiceId: data.invoiceId, values: values }))));
};
export default CancelSaleComponent;
