import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';
import { actions } from '../../apollo/cache';
import FileDialog from './file.dialog';
var ActionComponent = function () {
    var navigate = useNavigate();
    var _a = useState([false, null, null]), isOpenFile = _a[0], setIsOpenFile = _a[1];
    var handleCloseFile = function () { return setIsOpenFile([false, null, null]); };
    var handleEditPromotion = function (e, _a) {
        var id = _a.id;
        navigate(id);
    };
    useEffect(function () {
        actions([
            {
                icon: React.createElement(EditIcon, null),
                label: 'Editar promoción',
                onClick: handleEditPromotion,
            },
        ].filter(function (el) { return el; }));
        return function () {
            actions([]);
        };
    }, []);
    return (React.createElement(FileDialog, { isOpen: isOpenFile[0], handleClose: handleCloseFile, id: isOpenFile[1], fileId: isOpenFile[2] }));
};
export default ActionComponent;
