import jsPDF from 'jspdf';
import { format } from 'date-fns';

import client from '../../apollo';

import rowFormat from '../Table/helpers/Format';

const currentDate = new Date();

const getQuery = async (gql, variables) => {
  try {
    const { data } = await client.mutate({
      mutation: gql,
      variables: { ...variables, limit: 0, offset: 0 },
    });
    return data[Object.keys(data)[0]].rows;
  } catch (e) {
    console.log(e);
    return e;
  }
};

const alignText = (x, width, align) => {
  let tmpX = x;
  switch (align) {
    case 'right':
      tmpX = x + width - 2;
      break;
    case 'center':
      tmpX = width / (2 + x);
      break;
    default:
      tmpX = x;
      break;
  }

  return tmpX;
};

const header = (doc, title, keys) => {
  let rowsLine = 1;

  doc.setFontSize(14);
  doc.text(title.toUpperCase(), 10, 20);
  doc.text(`${format(currentDate, 'dd-MMM-yyyy HH:mm')}`, 285, 20, 'right');

  const width = 275 / keys.length;
  let x = 10;

  doc.setFontSize(10);
  keys.forEach(({ label, align }) => {
    const tmpX = alignText(x, width, align);

    const labelFix = doc.splitTextToSize(label, width - 2);
    rowsLine = labelFix.length > rowsLine ? labelFix.length : rowsLine;

    doc.text(tmpX, 30, labelFix, align);
    x = x + width;
  });
  const breakLine = 31 + 2 * rowsLine;
  doc.line(10, breakLine, 285, breakLine);
  return breakLine;
};

export default async (title, gql, vars, keys) => {
  const data = await getQuery(gql, vars);

  const doc = new jsPDF('landscape');
  let breakLine = header(doc, title, keys);

  const width = 275 / parseInt(keys.length);
  // let breakLine = 33;
  const heightBreakLine = 4;
  let rowsLine = 1;

  data.forEach(row => {
    breakLine = breakLine + heightBreakLine;
    let x = 10;
    keys.forEach(({ key, strcat, align = 'left', format: dataFormat, ...el }) => {
      let dataFormatTmp = dataFormat;
      let value = key.includes(' ')
        ? key.includes(' && ')
          ? key.split(' && ').reduce((stringPincipal, elPincipal) => {
              const res = elPincipal.split(' ').reduce((string, elCh) => {
                const tmpData = string[elCh] || el.ifNull || '-';
                return tmpData;
              }, row);
              return stringPincipal !== '' ? `${stringPincipal}${strcat || ' '}${res}` : res;
            }, '')
          : key.split(' ').reduce((string, elCh) => {
              const tmpData = string[elCh] || el.ifNull || '-';
              return tmpData;
            }, row)
        : row[key];

      if (dataFormatTmp === 'currency') {
        dataFormatTmp = null;
        value = !Number.isNaN(parseFloat(value)) ? parseFloat(value) : 0;
      }

      const label = doc.splitTextToSize(rowFormat(value, dataFormatTmp), width - 2);
      rowsLine = label.length > rowsLine ? label.length : rowsLine;

      const tmpX = alignText(x, width, align);
      doc.text(tmpX, breakLine, label, align);

      x = x + width;
    });

    breakLine = breakLine + 3 * rowsLine;
    doc.line(10, breakLine, 285, breakLine);

    rowsLine = 1;

    if (breakLine >= 200) {
      doc.addPage('L');
      breakLine = header(doc, title, keys);
    }
  });

  doc.save(`${title}.pdf`);
};
