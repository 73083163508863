var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useEffect, useState } from 'react';
import { useReactiveVar } from '@apollo/client';
import { Button } from '@mui/material';
import TextFieldComponent from '../../../../../../../presentationals/FormComponents/TextField';
import SelectFieldComponent from '../../../../../../../presentationals/FormComponents/SelectField';
import Checkbox from '../../../../../../../presentationals/Checkbox';
import { cartItems, commentarySaleV2, currencySaleV2, formatSaleV2, inputError, isDuplicateSaleOpen, isSaleFormatFormOpen, totalsSaleV2, } from '../../../../../../../apollo/cache';
import { saleFormatArray1 } from '../../../../../../../helpers/getSaleFormatLabel';
import getInputErrorFormat from '../../../../helpers/getInputErrorFormat';
import useGetBranches from '../../../../../../../hooks/useGetBranches';
import getSession from '../../../../../../../helpers/getSession';
import GQL_CHECK_CART from '../../../../../ChooseProduct/AddButton/_gql';
import GQL from '../../../../../../sale.report.v2/_gql';
import useApolloLazyQuery from '../../../../../../../hooks/useApolloLazyQuery';
import setInputErrorFormat from '../../../../helpers/setInputErrorFormat';
import useApolloMutation from '../../../../../../../hooks/useApolloMutation';
import getProductToCheckCart from '../../../../../helpers/getProductToCheckCart';
import getTotalizedCartProducts from '../../../../../helpers/getTotalizedCartProducts';
import DialogComponent from '../../../../../../../presentationals/Dialog';
var defaultValues = {
    format: -1,
    branchId: '',
    folio: '',
    formatToDuplicate: true,
    customer: false,
    product: true,
    commentary: true,
};
var FormDuplicateFormatFormFormatActionSaleV2Component = function () {
    var session = getSession();
    var isDuplicateSaleOpenVar = useReactiveVar(isDuplicateSaleOpen);
    var inputErrorVar = useReactiveVar(inputError);
    var _a = useState(defaultValues), values = _a[0], setValues = _a[1];
    var _b = useApolloLazyQuery(GQL.GET_BY_ID), getSale = _b[0], loading = _b[1].loading;
    var data = useGetBranches({}, 'select').data;
    var _c = useApolloMutation(GQL_CHECK_CART.CHECK_CART), checkCart = _c[0], loadingCheckCart = _c[1].loading;
    useEffect(function () {
        var _a;
        var branchId = (_a = session === null || session === void 0 ? void 0 : session.Company) === null || _a === void 0 ? void 0 : _a.Branch.id;
        if (data.rows && data.rows.length > 0 && branchId) {
            var Rows = data.rows;
            var currentBranch_1 = Rows.find(function (el) { return el.value === branchId; });
            if (currentBranch_1)
                setValues(function (values) { return (__assign(__assign({}, values), { branchId: "".concat(currentBranch_1.value) })); });
        }
    }, [data]);
    var handleChange = function (name, value) {
        setValues(function (values) {
            var _a;
            return (__assign(__assign({}, values), (_a = {}, _a[name] = value, _a)));
        });
    };
    var handleClose = function () {
        isDuplicateSaleOpen(false);
    };
    var handleClick = function () { return __awaiter(void 0, void 0, void 0, function () {
        var data1, currencySaleV2Var, newCart, data_1, cart, e_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 3, , 4]);
                    inputError({});
                    if (values.folio === '')
                        throw new Error(setInputErrorFormat('folio', 'Proporciona un folio.'));
                    return [4 /*yield*/, getSale({
                            variables: { category: values.format, branchId: values.branchId, folio: values.folio },
                        })];
                case 1:
                    data1 = (_a.sent()).data;
                    currencySaleV2Var = currencySaleV2();
                    commentarySaleV2(data1.commentary);
                    formatSaleV2([values.format, 2, values.format === -1, values.format === -1]);
                    newCart = getProductToCheckCart(data1.SaleProducts.map(function (el) { return ({
                        unitPrice: el.unitPrice,
                        quantity: el.quantity,
                        productId: el.productId,
                        employeeId: el.employeeId,
                        cost: el.unitPrice,
                        discount: el.discount,
                        commentary: el.commentary || '',
                    }); }));
                    return [4 /*yield*/, checkCart({
                            variables: {
                                isInvoice: values.format === -1,
                                taxesOption: 2,
                                discountType: 2,
                                shipping: 0,
                                branchId: values.branchId,
                                currencyKey: currencySaleV2Var[0],
                                exchangeRate: currencySaleV2Var[1],
                                category: values.format,
                                Products: newCart,
                                Transactions: [],
                                showTaxes: values.format === -1,
                            },
                        })];
                case 2:
                    data_1 = (_a.sent()).data;
                    cart = getTotalizedCartProducts(data_1.totalizeCart.Products, data1, {
                        taxesOption: 2,
                        isInvoice: values.format === -1,
                        showTaxes: values.format === -1,
                    });
                    totalsSaleV2([
                        data_1.totalizeCart.subtotal,
                        data_1.totalizeCart.discount,
                        data_1.totalizeCart.taxes,
                        data_1.totalizeCart.total,
                    ]);
                    cartItems(cart);
                    handleClose();
                    isSaleFormatFormOpen(false);
                    return [3 /*break*/, 4];
                case 3:
                    e_1 = _a.sent();
                    getInputErrorFormat(e_1);
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    }); };
    return (React.createElement(DialogComponent, { onClose: handleClose, open: isDuplicateSaleOpenVar },
        React.createElement("div", { className: 'p-4 pt-0' },
            React.createElement(SelectFieldComponent, { label: 'Formato', name: 'format', value: values.format, onChange: handleChange, options: saleFormatArray1
                    .filter(function (el) { return [1, -1].includes(el.id); })
                    .map(function (el) { return ({
                    label: el.value,
                    value: el.id,
                }); }) }),
            React.createElement(SelectFieldComponent, { label: 'Sucursal', name: 'branchId', onChange: handleChange, value: values.branchId, options: data.rows }),
            React.createElement(TextFieldComponent, { label: 'Folio', name: 'folio', error: inputErrorVar === null || inputErrorVar === void 0 ? void 0 : inputErrorVar.folio, value: values.folio, onChange: handleChange }),
            React.createElement(Checkbox, { label: 'Valores a duplicar', noFormat: true, handleChange: handleChange, values: [
                    {
                        name: 'formatToDuplicate',
                        disabled: true,
                        label: 'Formato',
                        checked: values.formatToDuplicate,
                    },
                    { name: 'customer', label: 'Cliente', checked: values.customer, disabled: true },
                    { name: 'product', label: 'Productos', checked: values.product },
                    {
                        name: 'commentary',
                        label: 'Anotaciones o comentarios',
                        checked: values.commentary,
                    },
                ] }),
            React.createElement(Button, { disabled: loading || loadingCheckCart, fullWidth: true, onClick: handleClick, color: 'secondary', variant: 'contained', className: 'mt-1 shadow-none hover:shadow-none' }, "Duplicar"))));
};
export default FormDuplicateFormatFormFormatActionSaleV2Component;
