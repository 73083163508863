import React from 'react';
import EditIcon from '@mui/icons-material/Edit';
import IconButtonComponent from '../../../../../presentationals/IconButton';
import { isEditDiagnosisHistoryCustomerFormOpen } from '../../../../../apollo/cache';
var EditDiagnosisButtonHistoryCalendarComponent = function (props) {
    var handleClick = function () {
        isEditDiagnosisHistoryCustomerFormOpen([true, props.name, props.Events]);
    };
    return (React.createElement(IconButtonComponent, { color: 'default', icon: React.createElement(EditIcon, null), action: handleClick, size: 'large' }));
};
export default EditDiagnosisButtonHistoryCalendarComponent;
