var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useState } from 'react';
import { useMutation, useReactiveVar } from '@apollo/client';
import { Link, useNavigate } from 'react-router-dom';
import { Button } from '@mui/material';
import logo from '../../assets/static/iso.png';
import TextFieldComponent from '../../presentationals/FormComponents/TextField';
import getInputErrorFormat from '../sale.v2/Action/helpers/getInputErrorFormat';
import { inputError } from '../../apollo/cache';
import Copyright from '../../presentationals/Module.v2/Copyright';
import GQL_SESSION from '../../apollo/gql/session';
import setSession from '../../helpers/setSession';
import reconnectSocket from '../../socket/helpers/reconnect';
var defaultValues = {
    username: '',
    password: '',
};
var LoginComponent = function () {
    var navigate = useNavigate();
    var errors = useReactiveVar(inputError);
    var _a = useMutation(GQL_SESSION.ERP_LOGIN), login = _a[0], loading = _a[1].loading;
    var _b = useState(defaultValues), values = _b[0], setValues = _b[1];
    var handleChange = function (name, value) {
        setValues(function (values) {
            var _a;
            return (__assign(__assign({}, values), (_a = {}, _a[name] = value, _a)));
        });
    };
    var handleClick = function () { return __awaiter(void 0, void 0, void 0, function () {
        var data, branchesQty, defaultSession, e_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 5, , 6]);
                    return [4 /*yield*/, login({ variables: values })];
                case 1:
                    data = (_a.sent()).data;
                    branchesQty = data.login.Branches ? data.login.Branches.length : 0;
                    defaultSession = {
                        name: data.login.name,
                        email: data.login.email,
                        phone: data.login.phone,
                        accessToken: data.login.accessToken,
                        refreshToken: data.login.refreshToken,
                        status: 'pending',
                    };
                    if (!(branchesQty === 1)) return [3 /*break*/, 3];
                    return [4 /*yield*/, setSession(__assign(__assign({}, defaultSession), { wsToken: data.login.wsToken, status: 'loggedIn', Company: {
                                id: data.login.Branches[0].companyId,
                                employeeId: data.login.Branches[0].employeeId,
                                name: data.login.Branches[0].companyName,
                                activity: data.login.Branches[0].activity,
                                logo: data.login.Branches[0].logoFileId,
                                Role: {
                                    id: data.login.Branches[0].Role.id,
                                    name: data.login.Branches[0].Role.name,
                                },
                                Branch: {
                                    id: data.login.Branches[0].id,
                                    name: data.login.Branches[0].name,
                                },
                            } }))];
                case 2:
                    _a.sent();
                    reconnectSocket(data.login.wsToken);
                    navigate('/panel', { replace: true });
                    return [2 /*return*/];
                case 3: return [4 /*yield*/, setSession(defaultSession)];
                case 4:
                    _a.sent();
                    navigate('/companies', { replace: true });
                    return [3 /*break*/, 6];
                case 5:
                    e_1 = _a.sent();
                    getInputErrorFormat(e_1);
                    return [3 /*break*/, 6];
                case 6: return [2 /*return*/];
            }
        });
    }); };
    var handleKeyDown = function (e) {
        if (e.key === 'Enter')
            handleClick();
    };
    return (React.createElement("div", { className: 'flex flex-1 h-screen flex-col bg-slate-50' },
        React.createElement("div", { className: 'flex justify-center items-center p-10 md:pt-16' },
            React.createElement("img", { src: logo, alt: 'Capitalike', className: 'w-20' })),
        React.createElement("div", { className: 'flex flex-1 items-center justify-center flex-col p-4' },
            React.createElement("div", { className: 'w-full sm:w-96' },
                React.createElement(TextFieldComponent, { defaultLabel: 'Tel\u00E9fono o correo electr\u00F3nico', name: 'username', value: values.username, onChange: handleChange, onKeyDown: handleKeyDown, error: errors.username, disabled: loading, size: 'medium' }),
                React.createElement(TextFieldComponent, { defaultLabel: 'Contrase\u00F1a', name: 'password', value: values.password, onChange: handleChange, onKeyDown: handleKeyDown, error: errors.password, disabled: loading, size: 'medium', margin: 'dense', type: 'password' }),
                React.createElement("div", { className: 'flex justify-end mb-10' },
                    React.createElement(Link, { className: 'text-gray-700 text-sm no-underline hover:underline', to: 'password/recovery' }, "\u00BFOlvidaste tu contrase\u00F1a?")),
                React.createElement(Button, { variant: 'contained', className: 'rounded-lg normal-case', size: 'large', fullWidth: true, onClick: handleClick, disabled: loading }, "Continuar"),
                React.createElement("div", { className: 'text-sm mt-2' },
                    "Al continuar, aceptas los",
                    ' ',
                    React.createElement(Link, { to: 'terms', className: 'text-gray-700 underline' }, "t\u00E9rminos y condiciones")))),
        React.createElement("div", { className: 'flex flex-col justify-center items-center p-4' },
            React.createElement("div", { className: 'w-40 mb-6 border-0 border-b border-solid border-gray-600' }),
            React.createElement("div", { className: 'flex items-center' },
                React.createElement("div", { className: 'text-sm mr-3' }, "\u00BFA\u00FAn no te has registrado?"),
                React.createElement(Link, { className: 'text-gray-600 text-sm font-medium no-underline hover:underline', to: 'signup' }, "Crear ahora"))),
        React.createElement("div", { className: 'flex justify-center p-6' },
            React.createElement(Copyright, null))));
};
export default LoginComponent;
