import React, { useEffect, useState } from 'react';
import useGetAttendanceDetail from './helpers/useGetAttendanceDetail';
import ModuleV3Component from '../../presentationals/Module.v3';
import RedirectEditFormToRouteComponent from '../../presentationals/RedirectEditFormToRoute';
import FilterAttendanceComponent from './Filter';
import { filter } from '../../apollo/cache';
var AttendanceDetailComponent = function () {
    var filterVar = filter();
    var _a = useGetAttendanceDetail(), data = _a.data, loading = _a.loading;
    var _b = useState(undefined), queryParams = _b[0], setQueryParams = _b[1];
    useEffect(function () {
        if (filterVar.start && filterVar.end)
            setQueryParams({ start: filterVar.start.toString(), end: filterVar.end.toString() });
    }, [filterVar]);
    var header = [
        { Header: 'Nombre de empleado', accessor: 'employee' },
        { Header: 'Retardos', accessor: 'delayQty' },
        { Header: 'Retardo total', accessor: 'delay' },
        { Header: 'Horas extras', accessor: 'extraHours' },
        { Header: 'Faltas', accessor: 'absent' },
        { Header: 'Horas trabajadas', accessor: 'workHours' },
        { Header: 'Total de horas trabajadas', accessor: 'totalWorkHours' },
    ];
    return (React.createElement(React.Fragment, null,
        React.createElement(ModuleV3Component, { title: 'Informe de asistencias', data: data, header: header, isLoading: loading, hideAdd: true, filter: true }),
        React.createElement(FilterAttendanceComponent, null),
        React.createElement(RedirectEditFormToRouteComponent, { path: 'employee/', queryParams: queryParams })));
};
export default AttendanceDetailComponent;
