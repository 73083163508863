var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useEffect } from 'react';
import { useReactiveVar } from '@apollo/client';
import useApolloMutation from '../../../../hooks/useApolloMutation';
import GQL_CART from '../../../sale.v2/ChooseProduct/AddButton/_gql';
import LoadingProgress from '../../../../presentationals/LoadingProgress';
import FooterDialogComponent from '../../../../presentationals/Dialog/Footer';
import PrintOptionFooterConfirmDialogSaleV2Component from '../../../sale.v2/ConfirmDialog/Footer/PrintOption';
import SummaryFormBodyConfirmDialogSaleV2Component from '../../../sale.v2/ConfirmDialog/Body/SummaryForm';
import InvoiceFormPaymentFormBodyConfirmDialogSaleV2Component from '../../../sale.v2/ConfirmDialog/Body/PaymentForm/InvoiceForm';
import CreateButtonCloseInvoiceComponent from './CreateButton';
import getDefaultTotalToPay from '../../../sale.v2/helpers/getDefaultTotalToPay';
import { cartItems, commentarySaleV2, customerSaleV2, formatSaleV2, inputError, invoiceSaleV2, pinSaleV2, saleTotalToPay, totalsSaleV2, } from '../../../../apollo/cache';
import getDefaultCustomerValues from '../../../sale.v2/Action/Customer/Form/Create/Body/helpers/defaultValues';
import getProductToCheckCart from '../../../sale.v2/helpers/getProductToCheckCart';
import getTotalizedCartProducts from '../../../sale.v2/helpers/getTotalizedCartProducts';
import getInputErrorFormat from '../../../sale.v2/Action/helpers/getInputErrorFormat';
import useGetSaleByParamSaleId from '../../../../hooks/useGetSaleByParamSaleId';
import InvoiceCreatedComponent from './InvoiceCreated';
import { dateFormat } from '../../../../helpers';
var InvoiceSaleComponent = function () {
    var _a = useGetSaleByParamSaleId(), data = _a.data, loading = _a.loading;
    var formatSaleV2Var = useReactiveVar(formatSaleV2);
    var _b = useApolloMutation(GQL_CART.CHECK_CART), checkCart = _b[0], loadingCheckCart = _b[1].loading;
    var handleClose = function () {
        var defaultTotalToPay = getDefaultTotalToPay();
        saleTotalToPay(defaultTotalToPay);
        customerSaleV2(getDefaultCustomerValues());
        cartItems([]);
        commentarySaleV2('');
        totalsSaleV2([0, 0, 0, 0]);
        invoiceSaleV2({
            paymentMethod: 'PUE',
            paymentForm: '',
            useCfdi: '',
            relateType: '',
            relateUUID: '',
        });
        pinSaleV2('');
        inputError({});
    };
    var getCart = function (Products) { return __awaiter(void 0, void 0, void 0, function () {
        var newCart, dataCart, cart, e_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    newCart = getProductToCheckCart(Products.map(function (el) { return ({
                        price: el.unitPrice,
                        quantity: el.quantity,
                        cost: 0,
                        discount: el.discount,
                        commentary: el.commentary,
                        productId: el.Product.id,
                        employeeId: null,
                        discountRate: el.discount,
                    }); }));
                    return [4 /*yield*/, checkCart({
                            variables: {
                                isInvoice: formatSaleV2Var[2],
                                taxesOption: 1,
                                discountType: 2,
                                shipping: 0,
                                currencyKey: 'MXN',
                                exchangeRate: 1,
                                category: 1,
                                Products: newCart,
                                Transactions: [],
                                showTaxes: formatSaleV2Var[3],
                            },
                        })];
                case 1:
                    dataCart = (_a.sent()).data;
                    cart = getTotalizedCartProducts(dataCart.totalizeCart.Products, Products, {
                        taxesOption: 1,
                        isInvoice: formatSaleV2Var[2],
                        showTaxes: formatSaleV2Var[3],
                    });
                    totalsSaleV2([
                        dataCart.totalizeCart.subtotal,
                        dataCart.totalizeCart.discount,
                        dataCart.totalizeCart.taxes,
                        dataCart.totalizeCart.total,
                    ]);
                    cartItems(cart);
                    return [3 /*break*/, 3];
                case 2:
                    e_1 = _a.sent();
                    getInputErrorFormat(e_1);
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    useEffect(function () {
        if (data) {
            customerSaleV2(getDefaultCustomerValues(data.Customer));
            commentarySaleV2(data.commentary);
            getCart(data.SaleProducts);
        }
    }, [data, formatSaleV2Var]);
    useEffect(function () {
        formatSaleV2([1, formatSaleV2Var[1], true, formatSaleV2Var[3]]);
        return function () { return handleClose(); };
    }, []);
    if (loading || !data)
        return React.createElement(LoadingProgress, null);
    if (data.status === 0 && !data.invoiceId)
        return (React.createElement("div", { className: 'p-3' },
            "La factura no puede ser emitida ya que la venta fue cancelada el d\u00EDa",
            ' ',
            dateFormat(data.updatedAt)));
    if (data.invoiceId && data.Invoice)
        return (React.createElement(InvoiceCreatedComponent, { status: data.status, updatedAt: data.updatedAt, Invoice: data.Invoice }));
    return (React.createElement("div", { className: 'flex flex-col flex-1' },
        React.createElement("div", { className: 'grid grid-cols-1 md:grid-cols-4 gap-4 flex-1 px-4  py-0' },
            React.createElement("div", { className: 'flex-1 flex flex-col basis-0' },
                React.createElement(InvoiceFormPaymentFormBodyConfirmDialogSaleV2Component, null)),
            React.createElement("div", { className: 'col-span-3 h-full hidden md:flex flex-1  flex-col' }, !loadingCheckCart && React.createElement(SummaryFormBodyConfirmDialogSaleV2Component, null))),
        React.createElement(FooterDialogComponent, null,
            React.createElement("div", { className: 'hidden md:block' },
                React.createElement(PrintOptionFooterConfirmDialogSaleV2Component, null)),
            React.createElement("div", { className: 'flex' },
                React.createElement(CreateButtonCloseInvoiceComponent, { onClose: handleClose })))));
};
export default InvoiceSaleComponent;
