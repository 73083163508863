import React from 'react';
import { useReactiveVar } from '@apollo/client';
import { inputError } from '../../../../../../../apollo/cache';
import TextFieldComponent from '../../../../../../../presentationals/FormComponents/TextField';
var LimitPresentationFieldComponent = function (props) {
    var errors = useReactiveVar(inputError);
    // const productSaleV2Var = useReactiveVar(productSaleV2);
    // if (parseFloat(productSaleV2Var.type) === 1) return <div />;
    return (React.createElement(TextFieldComponent, { name: 'limit', label: '\u00BFCu\u00E1ntos insumos gratuitos tendr\u00E1 esta presentaci\u00F3n?', value: props.value, error: errors.limit, onChange: props.onChange, type: 'number' }));
};
export default LimitPresentationFieldComponent;
