var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useEffect, useState } from 'react';
import InventoryOutlinedIcon from '@mui/icons-material/InventoryOutlined';
import ListItemPermissionEditRoleComponent from '../helpers/ListItem';
import SwitchPermissionFieldComponent from '../helpers/SwitchPermissionField';
var defaultInventoryAdjustPermissionValues = {
    createNewInventoryAdjust: false,
    getInventoryAdjust: false,
    affectInventory: false,
};
var InventoryAdjustPermissionEditRoleComponent = function (props) {
    var _a = useState(defaultInventoryAdjustPermissionValues), values = _a[0], setValues = _a[1];
    useEffect(function () {
        setValues(function (values) { return (__assign(__assign({}, values), props.Permissions)); });
    }, [props.Permissions]);
    return (React.createElement(ListItemPermissionEditRoleComponent, { icon: React.createElement(InventoryOutlinedIcon, null), name: 'Ajuste de inventario' },
        React.createElement("div", { className: 'mx-4 py-3 grid grid-cols-1 md:grid-cols-3 gap-4' },
            React.createElement("div", { className: 'flex flex-col' },
                React.createElement(SwitchPermissionFieldComponent, __assign({}, props, { name: 'createNewInventoryAdjust', label: 'Crear ajuste de inventario', defaultValue: values.createNewInventoryAdjust })),
                React.createElement(SwitchPermissionFieldComponent, __assign({}, props, { name: 'getInventoryAdjust', label: 'Consultar ajustes de inventario', defaultValue: values.getInventoryAdjust })),
                React.createElement(SwitchPermissionFieldComponent, __assign({}, props, { name: 'affectInventory', label: '\u00BFEl ajuste afectar\u00E1 el inventario?', defaultValue: values.affectInventory }))))));
};
export default InventoryAdjustPermissionEditRoleComponent;
