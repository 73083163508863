import { gql } from '@apollo/client';
import FRAGMENT_PERMISSIONS from '../../apollo/gql/role/fragmentPermissions';
import FRAGMENT_ROLE_WITH_PERMISSIONS from '../../apollo/gql/role/fragmentRoleWithPermissions';

const FRAGMENT_ROLE_PERMISSION = gql`
  ${FRAGMENT_PERMISSIONS}
  fragment FragmentRolePermission on RolePermissionsResponse {
    roleId
    Permissions {
      ...FragmentPermissions
    }
  }
`;

const GET = gql`
  query Roles($name: String, $order: [String]) {
    roles(name: $name, order: $order) {
      count
      rows {
        id
        name
      }
    }
  }
`;

const GET_BY_ID = gql`
  ${FRAGMENT_ROLE_WITH_PERMISSIONS}
  query Role($id: ID!) {
    role(id: $id) {
      ...FragmentRoleWithPermissions
    }
  }
`;

const DELETE = gql`
  ${FRAGMENT_ROLE_WITH_PERMISSIONS}
  mutation DeleteRole($id: ID!) {
    deleteRole(id: $id) {
      ...FragmentRoleWithPermissions
    }
  }
`;

const CREATE = gql`
  ${FRAGMENT_ROLE_WITH_PERMISSIONS}
  mutation CreateRole($name: String!) {
    createRole(input: { name: $name }) {
      ...FragmentRoleWithPermissions
    }
  }
`;
const UPDATE = gql`
  ${FRAGMENT_ROLE_WITH_PERMISSIONS}
  mutation UpdateRole($id: ID!, $name: String!) {
    updateRole(id: $id, input: { name: $name }) {
      ...FragmentRoleWithPermissions
    }
  }
`;

const GET_PERMISSIONS = gql`
  ${FRAGMENT_ROLE_PERMISSION}
  query RolePermission($roleId: ID!, $module: String) {
    rolePermission(roleId: $roleId, module: $module) {
      ...FragmentRolePermission
    }
  }
`;

const UPDATE_PERMISSION = gql`
  ${FRAGMENT_ROLE_WITH_PERMISSIONS}
  mutation UpdateRolePermissions($roleId: ID!, $Permissions: String!) {
    updateRolePermissions(roleId: $roleId, Permissions: $Permissions) {
      ...FragmentRoleWithPermissions
    }
  }
`;

const UPDATE_ROLE_PERMISSION = gql`
  ${FRAGMENT_ROLE_WITH_PERMISSIONS}
  mutation UpdateRolePermission($roleId: ID!, $Permission: PermissionData!) {
    updateRolePermission(roleId: $roleId, Permission: $Permission) {
      ...FragmentRoleWithPermissions
    }
  }
`;

export default {
  GET,
  GET_BY_ID,
  DELETE,
  CREATE,
  UPDATE,
  GET_PERMISSIONS,
  UPDATE_PERMISSION,
  UPDATE_ROLE_PERMISSION,
};
