import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { InputBase } from '@mui/material';
var InputSearchSaleGlobalInvoiceCustomerFieldComponent = forwardRef(function (props, ref) {
    var _a = useState(''), value = _a[0], setValue = _a[1];
    useEffect(function () {
        var timer = setTimeout(function () {
            props.onChange(value);
        }, 500);
        return function () { return clearTimeout(timer); };
    }, [value]);
    var handleChange = function (event) {
        var value = event.target.value;
        setValue(value);
    };
    var getValue = function () { return value; };
    useImperativeHandle(ref, function () { return ({
        getValue: getValue,
    }); });
    return (React.createElement(InputBase, { autoFocus: true, value: value, className: 'flex flex-1 ml-1', inputProps: { 'aria-label': 'customer-search-field' }, onChange: handleChange }));
});
export default InputSearchSaleGlobalInvoiceCustomerFieldComponent;
