import { TableRow } from '@mui/material';
import React from 'react';
import { isEditCalendarFormOpen } from '../../../../../../apollo/cache';
var TableRowPostponeCalendarComponent = function (props) {
    var handleClick = function () {
        isEditCalendarFormOpen(parseInt(props.eventId));
    };
    return (React.createElement(TableRow, { className: props.index % 2 ? 'bg-gray-200' : '', onClick: handleClick }, props.children));
};
export default TableRowPostponeCalendarComponent;
