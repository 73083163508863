import React from 'react';
import { useParams } from 'react-router-dom';
import CONFIG from '../../../../../../config/config.json';
var ImageProductItemProductListComponent = function (props) {
    var params = useParams();
    var defaultImage = params.ecName &&
        [
            'bocadosdelcielodrinks',
            'bsburger',
            'makaosushi',
            'bocadosdelcielomariscos',
            'mrpancake',
            'muchimania',
            'mrpancakereposteria',
            'shirosbobas',
            'tacosdonpolo',
            'theramenfactory',
        ].includes(params.ecName)
        ? '27707'
        : '33893';
    return (React.createElement("img", { src: "".concat(CONFIG['api-file-service'].url, "/files/").concat(props.fileId || defaultImage), alt: "product-".concat(props.fileId || defaultImage), className: 'object-cover w-3/5 transition-opacity duration-500 rounded-3xl' }));
};
export default ImageProductItemProductListComponent;
