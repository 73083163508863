import React from 'react';
import { useNavigate } from 'react-router-dom';
import { TableCell, TableRow } from '@mui/material';
import { dateTimeFormat } from '../../../../../helpers';
import getSurveyType from '../../../../../helpers/getSurveyType';
var ItemSurveyCustomerComponent = function (props) {
    var navigate = useNavigate();
    var handleClick = function () {
        navigate("".concat(props.id));
    };
    return (React.createElement(TableRow, { hover: true, className: 'cursor-pointer', onClick: handleClick },
        React.createElement(TableCell, null, dateTimeFormat(props.createdAt)),
        React.createElement(TableCell, null, getSurveyType(props.Survey.type)),
        React.createElement(TableCell, null, props.Survey.name),
        React.createElement(TableCell, null, props.Branch.name)));
};
export default ItemSurveyCustomerComponent;
