import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Button from '../../../../../presentationals/Dialog/Footer/Button';
import { inputError, isEditFormOpen, workingHoursForm } from '../../../../../apollo/cache';
import getInputErrorFormat from '../../../../sale.v2/Action/helpers/getInputErrorFormat';
import setInputErrorFormat from '../../../../sale.v2/Action/helpers/setInputErrorFormat';
import useApolloMutation from '../../../../../hooks/useApolloMutation';
import GQL from '../../../_gql';
import useSnackbarDialog from '../../../../../store/snackbar/snackbarDialog';
var EditButtonWorkingHoursEmployeeComponent = function () {
    var setSnackbar = useSnackbarDialog().setSnackbar;
    var params = useParams();
    var navigate = useNavigate();
    var _a = useApolloMutation(GQL.UPDATE_SCHEDULE), updateSchedule = _a[0], loading = _a[1].loading;
    var handleClick = function () {
        try {
            inputError({});
            var workingHoursFormVar_1 = workingHoursForm();
            var newWorkingHours = Array.from(Array(7).keys()).map(function (day) {
                var isInArray = workingHoursFormVar_1.find(function (elCh) { return elCh.day === day; });
                return isInArray || { day: day, entry: -1, out: -1 };
            });
            newWorkingHours.forEach(function (el) {
                if (el.entry >= el.out && el.entry !== -1)
                    throw new Error(setInputErrorFormat("".concat(el.day), 'La hora de entrada debe ser menor a la hora de salida.'));
            });
            if (!params.employeeId)
                throw new Error('Proporciona el identificador del empleado.');
            updateSchedule({ variables: { id: params.employeeId, schedule: newWorkingHours } });
            workingHoursForm([]);
            isEditFormOpen(false);
            setSnackbar({
                isOpen: true,
                label: 'El horario laboral se actualizó correctamente.',
                severity: 'success',
            });
            navigate('../../../employee');
        }
        catch (error) {
            getInputErrorFormat(error);
        }
    };
    return (React.createElement(Button, { disabled: loading, className: 'ml-2', onClick: handleClick }, "Guardar cambios"));
};
export default EditButtonWorkingHoursEmployeeComponent;
