var getDefaultColorQueueManagerValues = function () { return ({
    leftHeaderBackgroundColor: '',
    leftHeaderTextColor: '',
    rigthHeaderBackgroundColor: '',
    rigthHeaderTextColor: '',
    leftBodyBackgroundColor: '',
    leftBodyTextColor: '',
    footerBackgroundColor: '',
    footerTextColor: '',
}); };
export default getDefaultColorQueueManagerValues;
