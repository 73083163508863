import React, { useState } from 'react';
import { Button } from '@mui/material';
import Form from './Form';
var AddButtonSecondaryColorEcommerceProductComponent = function () {
    var _a = useState(false), isOpen = _a[0], setIsOpen = _a[1];
    var handleClick = function () {
        setIsOpen(true);
    };
    var handleClose = function () {
        setIsOpen(false);
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(Button, { variant: 'contained', color: 'secondary', size: 'small', onClick: handleClick }, "A\u00F1adir nuevo color"),
        React.createElement(Form, { isOpen: isOpen, onClose: handleClose })));
};
export default AddButtonSecondaryColorEcommerceProductComponent;
