var getDefaultEmployeeForm = function (values) { return ({
    id: (values === null || values === void 0 ? void 0 : values.id) ? values.id : undefined,
    password: (values === null || values === void 0 ? void 0 : values.password) ? values.password : '',
    userPassword: (values === null || values === void 0 ? void 0 : values.userPassword) ? values.userPassword : '',
    firstName: (values === null || values === void 0 ? void 0 : values.firstName) ? values.firstName : '',
    lastName: (values === null || values === void 0 ? void 0 : values.lastName) ? values.lastName : '',
    email: (values === null || values === void 0 ? void 0 : values.email) ? values.email : '',
    phone: (values === null || values === void 0 ? void 0 : values.phone) ? values.phone : '',
    roleId: (values === null || values === void 0 ? void 0 : values.roleId) ? values.roleId : '',
    branchId: (values === null || values === void 0 ? void 0 : values.branchId) ? values.branchId : '',
    position: (values === null || values === void 0 ? void 0 : values.position) ? values.position : '',
    isSeller: (values === null || values === void 0 ? void 0 : values.isSeller) ? values.isSeller : false,
}); };
export default getDefaultEmployeeForm;
