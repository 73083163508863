var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { useReactiveVar } from '@apollo/client';
import ProductFieldComponent from '../../../../presentationals/FormComponents/ProductField';
import useApolloLazyQuery from '../../../../hooks/useApolloLazyQuery';
import GQL_PRODUCT from '../../../../apollo/gql/product';
import SelectFieldComponent from '../../../../presentationals/FormComponents/SelectField';
import { inputError } from '../../../../apollo/cache';
import TextFieldComponent from '../../../../presentationals/FormComponents/TextField';
var BodyProductPromotionComponent = forwardRef(function (props, ref) {
    var errors = useReactiveVar(inputError);
    var defaultValues = {
        productId: null,
        productPresentationId: null,
        price: '',
        qty: '',
    };
    var productFieldRef = useRef(null);
    var _a = useState(defaultValues), values = _a[0], setValues = _a[1];
    var _b = useState([]), ProductPresentations = _b[0], setProductPresentations = _b[1];
    var getProductById = useApolloLazyQuery(GQL_PRODUCT.GET_PRODUCT_BY_ID)[0];
    useEffect(function () {
        var _a;
        if (ProductPresentations.length > 1 && ((_a = props.defaultValues) === null || _a === void 0 ? void 0 : _a.productPresentationId)) {
            var productPresentationId_1 = props.defaultValues.productPresentationId;
            var ProductPresentation = ProductPresentations.find(function (el) { return el.id === productPresentationId_1; });
            if (!ProductPresentation)
                return;
            setValues(function (values) {
                var _a;
                return (__assign(__assign({}, values), { productPresentationId: ((_a = props.defaultValues) === null || _a === void 0 ? void 0 : _a.productPresentationId)
                        ? props.defaultValues.productPresentationId
                        : null }));
            });
        }
    }, [ProductPresentations]);
    var handleProductChange = function (name, value) { return __awaiter(void 0, void 0, void 0, function () {
        var data_1, ProductPresentations_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!value) return [3 /*break*/, 2];
                    return [4 /*yield*/, getProductById({ variables: { id: value } })];
                case 1:
                    data_1 = (_a.sent()).data;
                    if (data_1) {
                        ProductPresentations_1 = __spreadArray([
                            data_1.PresentationBase
                        ], data_1.ProductPresentations, true);
                        // setProductPresentations(ProductPresentations.filter(el => el.forSale));
                        setProductPresentations(ProductPresentations_1);
                        setValues(function (values) { return (__assign(__assign({}, values), { productPresentationId: data_1.PresentationBase.id })); });
                    }
                    else {
                        setProductPresentations([]);
                        setValues(function (values) { return (__assign(__assign({}, values), { productPresentationId: '', price: '0' })); });
                    }
                    _a.label = 2;
                case 2:
                    setValues(function (values) {
                        var _a;
                        return (__assign(__assign({}, values), (_a = {}, _a[name] = value, _a)));
                    });
                    return [2 /*return*/];
            }
        });
    }); };
    useEffect(function () {
        if (props.defaultValues) {
            setValues(props.defaultValues);
            if (props.defaultValues.productId)
                handleProductChange('productId', props.defaultValues.productId);
        }
    }, [props.defaultValues]);
    var handleProductClear = function () {
        setValues(function (values) { return (__assign(__assign({}, values), { productId: null })); });
    };
    var handleChange = function (name, value) {
        inputError({});
        setValues(function (values) {
            var _a;
            return (__assign(__assign({}, values), (_a = {}, _a[name] = value, _a)));
        });
    };
    var handleGetProducts = function () { return values; };
    useImperativeHandle(ref, function () { return ({
        getValues: handleGetProducts,
    }); });
    return (React.createElement("div", { className: 'flex flex-1' },
        React.createElement("div", { className: 'flex-1 grid grid-cols-1 sm:grid-cols-3 overflow-auto' },
            React.createElement("div", { className: 'flex flex-1 flex-col' },
                React.createElement(ProductFieldComponent, { ref: productFieldRef, name: 'productId', label: 'Producto', searchBy: 'select', initialValue: values.productId, onChange: handleProductChange, onClear: handleProductClear }),
                values.productId && ProductPresentations.length > 1 && (React.createElement(SelectFieldComponent, { name: 'productPresentationId', label: 'Selecciona la presentaci\u00F3n del producto', onChange: handleChange, options: ProductPresentations.map(function (el) { return ({ value: el.id, label: el.name }); }), value: values.productPresentationId, error: errors.productPresentationId })),
                React.createElement(TextFieldComponent, { label: 'Cantidad', name: 'qty', value: values.qty, onChange: handleChange, error: errors.qty, type: 'number' }),
                React.createElement(TextFieldComponent, { label: 'Precio unitario', name: 'price', value: values.price, onChange: handleChange, error: errors.price, type: 'number' })))));
});
export default BodyProductPromotionComponent;
