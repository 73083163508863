var useCfdiOptions = [
    { value: 'G01', label: 'Adquisición de mercancias' },
    { value: 'G02', label: 'Devoluciones, descuentos o bonificaciones' },
    { value: 'G03', label: 'Gastos en general' },
    { value: 'I01', label: 'Construcciones' },
    { value: 'I02', label: 'Mobilario y equipo de oficina por inversiones' },
    { value: 'I03', label: 'Equipo de transporte' },
    { value: 'I04', label: 'Equipo de computo y accesorios' },
    { value: 'I05', label: 'Dados, troqueles, moldes, matrices y herramental' },
    { value: 'I06', label: 'Comunicaciones telefónicas' },
    { value: 'I07', label: 'Comunicaciones satelitales' },
    { value: 'I08', label: 'Otra maquinaria y equipo' },
    { value: 'D01', label: 'Honorarios médicos, dentales y gastos hospitalarios.' },
    { value: 'D02', label: 'Gastos médicos por incapacidad o discapacidad' },
    { value: 'D03', label: 'Gastos funerales.' },
    { value: 'D04', label: 'Donativos.' },
    {
        value: 'D05',
        label: 'Intereses reales efectivamente pagados por créditos hipotecarios (casa habitación).',
    },
    { value: 'D06', label: 'Aportaciones voluntarias al SAR.' },
    { value: 'D07', label: 'Primas por seguros de gastos médicos.' },
    { value: 'D08', label: 'Gastos de transportación escolar obligatoria.' },
    {
        value: 'D09',
        label: 'Depósitos en cuentas para el ahorro, primas que tengan como base planes de pensiones.',
    },
    { value: 'D10', label: 'Pagos por servicios educativos (colegiaturas)' },
    { value: 'P01', label: 'Por definir' },
    { value: 'S01', label: 'Sin Efectos Fiscales' },
];
var paymentMethodOptions = [
    { value: 'PUE', label: 'PUE' },
    { value: 'PPD', label: 'PPD' },
];
var yesNoOptions = [
    { value: true, label: 'SI' },
    { value: false, label: 'NO' },
];
var relateInvoiceOptions = [
    {
        value: '01',
        label: 'Nota de crédito de los documentos relacionados',
    },
    {
        value: '02',
        label: 'Nota de débito de los documentos relacionados',
    },
    {
        value: '03',
        label: 'Devolución de mercancía sobre facturas o traslados previos',
    },
    {
        value: '04',
        label: 'Sustitución de los CFDI previos',
    },
    {
        value: '05',
        label: 'Traslados de mercancias facturados previamente',
    },
    {
        value: '06',
        label: 'Factura generada por los traslados previos',
    },
    {
        value: '07',
        label: 'CFDI por aplicación de anticipo',
    },
    {
        value: '08',
        label: 'Factura generada por pagos en parcialvalueades',
    },
    {
        value: '09',
        label: 'Factura generada por pagos difervalueos',
    },
];
var getIvaOptions = function () {
    var ivaOptions = [
        { label: '16%', value: 16 },
        { label: '8%', value: 8 },
        { label: '0%', value: 0 },
        { label: 'EXENTO', value: -1 },
    ];
    return ivaOptions;
};
export { useCfdiOptions, paymentMethodOptions, yesNoOptions, relateInvoiceOptions, getIvaOptions };
