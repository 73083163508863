import { format, isSameDay } from 'date-fns';
import locale from 'date-fns/locale/es';
var getCashClosingDatesLabel = function (createdAt, closedAt) {
    if (!closedAt)
        return format(new Date(createdAt), "EEEE, d 'DE' MMMM hh:mmaaa 'A LA FECHA ACTUAL'", {
            locale: locale,
        }).toUpperCase();
    if (isSameDay(createdAt, closedAt))
        return "".concat(format(new Date(createdAt), "EEEE, d 'DE' MMMM 'DE LAS' hh:mmaaa", { locale: locale }), " A LAS ").concat(format(new Date(closedAt), 'hh:mmaaa', { locale: locale })).toUpperCase();
    return "".concat(format(new Date(createdAt), "EEEE, d 'DE' MMMM hh:mmaaa", { locale: locale }), " A ").concat(format(new Date(closedAt), "EEEE, d 'DE' MMMM hh:mmaaa", { locale: locale })).toUpperCase();
};
export default getCashClosingDatesLabel;
