import React, { useEffect, useState } from 'react';
import LinkMenuDashboardComponent from '../../helpers/Link';
import getSession from '../../../../../../helpers/getSession';
var EasportCrmLinkBlockOneMenuDashboardComponent = function () {
    var _a = useState(undefined), companyId = _a[0], setCompanyId = _a[1];
    useEffect(function () {
        var _a;
        var session = getSession();
        setCompanyId((_a = session === null || session === void 0 ? void 0 : session.Company) === null || _a === void 0 ? void 0 : _a.id);
    }, []);
    if (!companyId || companyId !== 25)
        return null;
    return (React.createElement(LinkMenuDashboardComponent, { url: 'report/crm', primary: 'CRM', secondary: 'Revisa la situaci\u00F3n actual de tus clientes' }));
};
export default EasportCrmLinkBlockOneMenuDashboardComponent;
