var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useState, useEffect } from 'react';
import { useReactiveVar } from '@apollo/client';
import { isValid } from 'date-fns';
import { calendarForm as setValues, inputError, isEditCalendarFormOpen, isCalendarFormOpen, } from '../../../../../apollo/cache';
import TextField from '../../../../../presentationals/FormComponents/TextField';
import CustomerField from './CustomerField';
import DateFieldComponent from '../../../../../presentationals/FormComponents/DateField';
import SelectFieldComponent from '../../../../../presentationals/FormComponents/SelectField';
import { calendarVisibilityOptions } from '../../../../../helpers';
import TagField from './TagField';
import getSession from '../../../../../helpers/getSession';
import getInputErrorFormat from '../../../../sale.v2/Action/helpers/getInputErrorFormat';
import setInputErrorFormat from '../../../../sale.v2/Action/helpers/setInputErrorFormat';
import getCalendarStatus from './helpers/getCalendarStatus';
import SelectHourFieldComponent from '../../../../../presentationals/FormComponents/SelectHourField';
import AdviserFieldComponent from './AdviserField';
import useApolloQuery from '../../../../../hooks/useApolloQuery';
import GQL_CALENDAR from '../../../../../apollo/gql/calendar';
import LoadingProgress from '../../../../../presentationals/LoadingProgress';
import ErrorDataComponent from '../../../../../presentationals/ErrorData';
var FormBodyFormCalendarComponent = function (props) {
    var _a;
    var isEditCalendarFormOpenVar = useReactiveVar(isEditCalendarFormOpen);
    var isCalendarFormOpenVar = isCalendarFormOpen();
    var values = useReactiveVar(setValues);
    var errors = useReactiveVar(inputError);
    var _b = useState(['Inicio', 'Fin', 'Recordatorio']), calendarLabels = _b[0], setCalendarLabels = _b[1];
    var _c = useState(null), statusLabel = _c[0], setStatusLabel = _c[1];
    var _d = useApolloQuery(GQL_CALENDAR.GET_CALENDAR_SETTINGS), calendarSettings = _d.data, loadingCalendarSettings = _d.loading;
    useEffect(function () {
        var f = function () { return __awaiter(void 0, void 0, void 0, function () {
            var session;
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, getSession()];
                    case 1:
                        session = _b.sent();
                        if (((_a = session === null || session === void 0 ? void 0 : session.Company) === null || _a === void 0 ? void 0 : _a.id.toString()) === '116')
                            setCalendarLabels(['Entrega', 'Recolección', 'Evento']);
                        return [2 /*return*/];
                }
            });
        }); };
        f();
    }, []);
    useEffect(function () {
        if (isCalendarFormOpenVar && isCalendarFormOpenVar[1] && isCalendarFormOpenVar[1].start)
            setValues(__assign(__assign({}, values), { start: new Date(isCalendarFormOpenVar[1].start), end: new Date(isCalendarFormOpenVar[1].start) }));
    }, [isCalendarFormOpenVar]);
    useEffect(function () {
        if (values.status) {
            var statusLabel_1 = getCalendarStatus(values.status);
            setStatusLabel(statusLabel_1);
        }
    }, [values]);
    var handleChange = function (name, value) {
        var _a;
        try {
            if (errors && !!errors[name])
                inputError({});
            if (name === 'start') {
                if (!isValid(value))
                    throw new Error(setInputErrorFormat('start', 'Verifica la fecha proporcionada.'));
                var newDate = value;
                setValues(__assign(__assign({}, values), { start: newDate, end: newDate }));
                return;
            }
            if (name === 'end' && !isValid(value))
                throw new Error(setInputErrorFormat('end', 'Verifica la fecha proporcionada.'));
            if (name === 'startHour') {
                var newValue = value;
                setValues(__assign(__assign({}, values), { startHour: newValue, endHour: newValue + 1 }));
                return;
            }
            setValues(__assign(__assign({}, values), (_a = {}, _a[name] = value, _a)));
        }
        catch (error) {
            getInputErrorFormat(error);
        }
    };
    if (loadingCalendarSettings)
        return React.createElement(LoadingProgress, null);
    if (!calendarSettings)
        return React.createElement(ErrorDataComponent, null);
    return (React.createElement("div", { className: 'flex flex-1 flex-col basis-0 overflow-auto p-4 md:pt-0 md:pr-0' },
        statusLabel && (React.createElement("div", { className: 'mb-2' },
            React.createElement("div", null, "Estatus"),
            React.createElement("div", { className: 'text-2xl font-semibold' }, statusLabel))),
        calendarSettings.AvailableCalendarOptions.title && (React.createElement(TextField, { label: 'A\u00F1ade un t\u00EDtulo', name: 'title', disabled: props.disabled, value: values.title, error: errors.title, onChange: handleChange })),
        calendarSettings.AvailableCalendarOptions.customer && (React.createElement(CustomerField, { error: errors, disabled: props.disabled, handleChange: handleChange })),
        React.createElement("div", { className: 'grid grid-cols-3 gap-2' },
            React.createElement("div", { className: 'col-span-2' },
                React.createElement(DateFieldComponent, { label: calendarLabels[0], name: 'start', value: values.start, error: errors.start, disablePast: true, disabled: props.disabled, onChange: handleChange })),
            React.createElement("div", null,
                React.createElement(SelectHourFieldComponent, { initialValue: values.startHour, name: 'startHour', label: 'Hora', onChange: handleChange }))),
        React.createElement("div", { className: 'grid grid-cols-3 gap-2' },
            React.createElement("div", { className: 'col-span-2' },
                React.createElement(DateFieldComponent, { label: calendarLabels[1], name: 'end', disablePast: true, disabled: props.disabled, value: values.end, error: errors.end, onChange: handleChange })),
            React.createElement("div", null,
                React.createElement(SelectHourFieldComponent, { initialValue: values.endHour, name: 'endHour', label: 'Hora', onChange: handleChange }))),
        calendarSettings.AvailableCalendarOptions.reminder && (React.createElement("div", { className: 'grid grid-cols-3 gap-2' },
            React.createElement("div", { className: 'col-span-2' },
                React.createElement(DateFieldComponent, { label: calendarLabels[2], name: 'reminder', value: values.reminder, disabled: props.disabled, onChange: handleChange })),
            React.createElement("div", null,
                React.createElement(SelectHourFieldComponent, { initialValue: values.reminderHour, name: 'reminderHour', label: 'Hora', onChange: handleChange })))),
        calendarSettings.AvailableCalendarOptions.address && (React.createElement(TextField, { label: 'A\u00F1adir domicilio', name: 'address', disabled: props.disabled, value: values.address, onChange: handleChange })),
        calendarSettings.AvailableCalendarOptions.diagnosis && (React.createElement(TextField, { value: values.diagnosis, name: 'diagnosis', label: 'A\u00F1ade un diagn\u00F3stico', onChange: handleChange, disabled: props.disabled })),
        calendarSettings.AvailableCalendarOptions.description && (React.createElement(TextField, { value: values.description, name: 'description', label: 'A\u00F1ade una descripci\u00F3n', onChange: handleChange, disabled: props.disabled })),
        calendarSettings.AvailableCalendarOptions.permissions && (React.createElement(SelectFieldComponent, { value: values.permissions, name: 'permissions', label: 'Visibilidad', onChange: handleChange, disabled: props.disabled, options: calendarVisibilityOptions })),
        calendarSettings.AvailableCalendarOptions.adviser && (React.createElement(AdviserFieldComponent, { value: values.adviserId ? values.adviserId.value : null, onChange: handleChange })),
        calendarSettings.AvailableCalendarOptions.sessions &&
            !isEditCalendarFormOpenVar &&
            !((_a = isCalendarFormOpenVar[1]) === null || _a === void 0 ? void 0 : _a.forSale) && (React.createElement(TextField, { value: values.sessions || '', name: 'sessions', label: 'N\u00FAmero de veces que se dar\u00E1 de alta el evento', type: 'number', onChange: handleChange, disabled: props.disabled })),
        calendarSettings.AvailableCalendarOptions.tagId && (React.createElement(TagField, { disabled: props.disabled, error: errors, handleChange: handleChange, value: values.tagId }))));
};
export default FormBodyFormCalendarComponent;
