var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useEffect, useRef } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import useApolloLazyQuery from '../../../../hooks/useApolloLazyQuery';
import GQL_REQUISITION from '../../../../apollo/gql/requisition';
import LoadingProgress from '../../../../presentationals/LoadingProgress';
import ErrorDataComponent from '../../../../presentationals/ErrorData';
import TableRowEditProductRequisitionComponent from './TableRow';
import EditRequisitionProductDialogComponent from './EditProductDialog';
import OpenAddRequisitionProductDialogButtonComponent from './OpenAddDialogButton';
import CreateRequisitionProductDialogComponent from './CreateProductDialog';
var BodyEditRequisitionProductsDialogComponent = function (props) {
    var editRequisitionProductDialogRef = useRef(null);
    var createRequisitionProductDialogRef = useRef(null);
    var _a = useApolloLazyQuery(GQL_REQUISITION.GET_REQUISITION_BY_ID), getData = _a[0], _b = _a[1], data = _b.data, loading = _b.loading;
    useEffect(function () {
        if (props.requisitionId)
            getData({ variables: { id: props.requisitionId } });
    }, [props.requisitionId]);
    var handleClick = function (requisitionProductId) {
        var _a;
        (_a = editRequisitionProductDialogRef.current) === null || _a === void 0 ? void 0 : _a.open(requisitionProductId);
    };
    var handleOpenDialogClick = function () {
        var _a;
        (_a = createRequisitionProductDialogRef.current) === null || _a === void 0 ? void 0 : _a.open();
    };
    if (loading)
        return React.createElement(LoadingProgress, null);
    if (!data || !props.requisitionId)
        return React.createElement(ErrorDataComponent, null);
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: 'grid grid-cols-1 sm:grid-cols-2 flex-1 flex-col basis-0 overflow-auto' },
            React.createElement("div", { className: 'flex flex-1 flex-col px-4 py-3' },
                React.createElement("div", { className: 'flex items-center' },
                    React.createElement("div", { className: 'text-base font-medium mb-1 flex flex-1' }, "Productos de la requisici\u00F3n de suministro"),
                    React.createElement(OpenAddRequisitionProductDialogButtonComponent, { onClick: handleOpenDialogClick })),
                React.createElement("div", { className: 'text-sm sm:text-base text-gray-600' }, "A\u00F1ade, modifica o elimina los productos de la requisici\u00F3n de suministro antes de hacer el cierre de esta."),
                React.createElement(TableContainer, { className: 'bg-slate-50 rounded border border-solid border-gray-300 mt-3' },
                    React.createElement(Table, { stickyHeader: true, size: 'small' },
                        React.createElement(TableHead, null,
                            React.createElement(TableRow, { className: 'bg-[#f6f6f6]' },
                                React.createElement(TableCell, { className: 'whitespace-nowrap' }, "Barcode"),
                                React.createElement(TableCell, { className: 'whitespace-nowrap' }, "SKU"),
                                React.createElement(TableCell, { className: 'whitespace-nowrap' }, "Descripci\u00F3n"),
                                React.createElement(TableCell, { className: 'whitespace-nowrap' }, "Presentaci\u00F3n"),
                                React.createElement(TableCell, { align: 'center' }, "Stock actual"),
                                React.createElement(TableCell, { align: 'center' }, "Cantidad solicitada"))),
                        React.createElement(TableBody, null, data.RequisitionProducts.map(function (el) { return (React.createElement(TableRowEditProductRequisitionComponent, __assign({ key: el.id }, el, { onClick: handleClick }))); })))))),
        React.createElement(CreateRequisitionProductDialogComponent, { requisitionId: props.requisitionId, ref: createRequisitionProductDialogRef }),
        React.createElement(EditRequisitionProductDialogComponent, { requisitionId: props.requisitionId, ref: editRequisitionProductDialogRef })));
};
export default BodyEditRequisitionProductsDialogComponent;
