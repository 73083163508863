var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React, { useEffect, useState } from 'react';
import getTotalTransactions from '../helpers/getTotalTransactions';
import { capitalize } from '../../../helpers';
import ItemSalesSummaryIncomeReportComponent from '../../incomeReport/SalesSummary/Item';
import getTotalExchange from '../helpers/getTotalExchange';
var TotalTransactionByPaymentFormComponent = function (props) {
    var _a = useState([]), data = _a[0], setData = _a[1];
    useEffect(function () {
        if (props.data) {
            var total_1 = getTotalTransactions(props.data);
            var totalExchange = getTotalExchange(props.data);
            var data_1 = props.data.reduce(function (array, el) {
                var paymentMethodId = el.paymentMethodId || -1;
                var isInArray = array.find(function (elCh) { return elCh.id === paymentMethodId; });
                if (el.status === 0)
                    return array;
                if (isInArray)
                    return array.map(function (elCh) {
                        return elCh.id === paymentMethodId
                            ? __assign(__assign({}, elCh), { Total: {
                                    total: parseFloat(el.total) + elCh.Total.total,
                                    count: elCh.Total.count + 1,
                                    percent: ((parseFloat(el.total) + elCh.Total.total) / total_1) * 100,
                                } }) : elCh;
                    });
                return __spreadArray(__spreadArray([], array, true), [
                    {
                        id: paymentMethodId,
                        name: capitalize(el.paymentMethod),
                        Total: {
                            total: parseFloat(el.total),
                            count: 1,
                            percent: (parseFloat(el.total) / total_1) * 100,
                        },
                    },
                ], false);
            }, []);
            setData(__spreadArray(__spreadArray([
                { id: -2, name: 'Total', Total: { total: total_1, count: props.data.length, percent: 0 } }
            ], data_1, true), [
                {
                    id: -3,
                    name: 'Cambio en efectivo',
                    Total: { total: Math.abs(totalExchange[0]), count: totalExchange[1], percent: 0 },
                },
            ], false));
        }
    }, [props.data]);
    return (React.createElement("div", { className: 'flex flex-col overflow-x-auto h-[80px] bg-gray-700 rounded p-2 pt-1 mb-1 mt-2' },
        React.createElement("div", { className: 'text-base text-white font-medium' }, "Totales por forma de pago"),
        React.createElement("div", { className: 'flex flex-row' }, props.data.length > 0 ? (data.map(function (el) { return (React.createElement(ItemSalesSummaryIncomeReportComponent, { key: el.id, border: true, qty: el.Total.count, total: el.Total.total, percent: el.Total.percent, label: el.name })); })) : (React.createElement("div", { className: 'text-slate-50' }, "Los totales no cuentan con suficientes datos para mostrar.")))));
};
export default TotalTransactionByPaymentFormComponent;
