import React from 'react';
import { Button } from '@mui/material';
import useAddProductToTransfer from '../../../store/inventoryReport/addProductToTransferStore';
import RHFormComponent from '../../../presentationals/RHForm/Form/indext';
import RHFTextFieldComponent from '../../../presentationals/RHForm/TextField';
import useTransferProductsToBranchStore from '../../../store/inventoryReport/transferProductsToBranchStore';
import DialogComponent from '../../../presentationals/Dialog';
var AddProductToTransferInventoryReportComponent = function () {
    var setClose = useAddProductToTransfer(function (state) { return state.setClose; });
    var open = useAddProductToTransfer(function (state) { return state.open; });
    var currentQty = useAddProductToTransfer(function (state) { return state.currentQty; });
    var presentationId = useAddProductToTransfer(function (state) { return state.presentationId; });
    var addProduct = useTransferProductsToBranchStore(function (state) { return state.addProduct; });
    var handleSubmit = function (values) {
        if (presentationId)
            addProduct({ presentationId: presentationId, qty: parseFloat(values.qty) });
        setClose();
    };
    return (React.createElement(DialogComponent, { hideHeaderCloseButton: true, maxWidth: 'xs', open: open, onClose: setClose },
        React.createElement(RHFormComponent, { onSubmit: handleSubmit, defaultValues: { qty: "".concat(currentQty) }, className: 'pt-2 pb-3 px-4' },
            React.createElement(RHFTextFieldComponent, { autoFocus: true, name: 'qty', config: {
                    min: {
                        value: 1,
                        message: 'El valor debe ser mayor a 0.',
                    },
                    required: true,
                }, label: 'Cantidad a traspasar' }),
            React.createElement("div", null,
                React.createElement(Button, { variant: 'contained', color: 'success', className: 'normal-case mt-2', type: 'submit' }, "A\u00F1adir a la lista")))));
};
export default AddProductToTransferInventoryReportComponent;
