import React, { useEffect, useState } from 'react';
import EditIcon from '@mui/icons-material/Edit';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { useReactiveVar } from '@apollo/client';
import { isPaymentFormOpen } from '../../../../../../apollo/cache';
import useApolloLazyQuery from '../../../../../../hooks/useApolloLazyQuery';
import GQL_ACCOUNT_RECEIVABLE from '../../../../../../apollo/gql/accountReceivable';
import LoadingProgress from '../../../../../../presentationals/LoadingProgress';
import ErrorDataComponent from '../../../../../../presentationals/ErrorData';
import convertToCurrencyLabel from '../../../../../../helpers/convertToCurrencyLabel';
import { dateFormat } from '../../../../../../helpers';
import TableRowOpenAccountReceivableComponent from './TableRow';
var OpenAccountReceivableComponent = function () {
    var customerId = useReactiveVar(isPaymentFormOpen);
    var _a = useState(0), total = _a[0], setTotal = _a[1];
    var _b = useState(0), payment = _b[0], setPayment = _b[1];
    var _c = useState(0), currentPayment = _c[0], setCurrentPayment = _c[1];
    var _d = useState(0), balance = _d[0], setBalance = _d[1];
    var _e = useApolloLazyQuery(GQL_ACCOUNT_RECEIVABLE.GET_ACCOUNT_RECEIVABLES), getData = _e[0], _f = _e[1], loading = _f.loading, data = _f.data;
    useEffect(function () {
        if (data) {
            var totals = data.reduce(function (total, el) { return [
                total[0] + el.total,
                total[1] + el.payment,
                total[2] + (el.currentPayment || 0),
                total[3] + el.balance,
            ]; }, [0, 0, 0, 0]);
            setTotal(totals[0]);
            setPayment(totals[1]);
            setCurrentPayment(totals[2]);
            setBalance(totals[3]);
        }
    }, [data]);
    useEffect(function () {
        if (customerId)
            getData({ variables: { customerId: customerId } });
    }, [customerId]);
    if (loading)
        return React.createElement(LoadingProgress, null);
    if (!data)
        return React.createElement(ErrorDataComponent, null);
    return (React.createElement(TableContainer, null,
        React.createElement(Table, { className: 'sticky', stickyHeader: true, size: 'small' },
            React.createElement(TableHead, null,
                React.createElement(TableRow, null,
                    React.createElement(TableCell, { colSpan: 3 }),
                    React.createElement(TableCell, { className: 'bg-slate-300' }),
                    React.createElement(TableCell, { className: 'bg-slate-400 font-medium text-center', colSpan: 2 }, "Abonos"),
                    React.createElement(TableCell, { className: 'bg-slate-300' })),
                React.createElement(TableRow, null,
                    React.createElement(TableCell, { className: 'font-medium' }, "Creado el"),
                    React.createElement(TableCell, { className: 'font-medium whitespace-nowrap' }, "Folio de venta"),
                    React.createElement(TableCell, { className: 'font-medium' }, "Vendedor"),
                    React.createElement(TableCell, { className: 'bg-slate-300 font-medium text-right' }, "Cargo"),
                    React.createElement(TableCell, { className: 'bg-slate-400 font-medium text-right' }, "Total"),
                    React.createElement(TableCell, { className: 'bg-slate-400 font-medium text-right' }, "Actual"),
                    React.createElement(TableCell, { className: 'bg-slate-300 font-medium text-right' }, "Saldo"))),
            React.createElement(TableBody, null,
                data.map(function (el) { return (React.createElement(TableRowOpenAccountReceivableComponent, { accountReceivableId: el.id, currentPayment: el.currentPayment || el.balance, key: el.id },
                    React.createElement(TableCell, null, dateFormat(el.clientCreatedAt)),
                    React.createElement(TableCell, null, el.folio),
                    React.createElement(TableCell, null, el.seller),
                    React.createElement(TableCell, { className: 'bg-slate-200 text-right' },
                        "$",
                        convertToCurrencyLabel(el.total)),
                    React.createElement(TableCell, { className: 'bg-slate-300 text-right' },
                        "$",
                        convertToCurrencyLabel(el.payment)),
                    React.createElement(TableCell, { className: 'bg-slate-400 text-right pr-3' },
                        React.createElement("div", { className: 'flex flex-1 flex-row items-end justify-center' },
                            React.createElement(EditIcon, { fontSize: 'small', className: 'mr-1' }),
                            React.createElement("span", null,
                                "$",
                                convertToCurrencyLabel(el.currentPayment || 0)))),
                    React.createElement(TableCell, { className: 'bg-slate-200 text-right' },
                        "$",
                        convertToCurrencyLabel(el.balance)))); }),
                React.createElement(TableRow, { className: 'sticky', style: { insetBlockEnd: 0 } },
                    React.createElement(TableCell, { className: 'text-right font-bold bg-white', colSpan: 3 }),
                    React.createElement(TableCell, { className: 'font-bold bg-white text-right' },
                        "$",
                        convertToCurrencyLabel(total)),
                    React.createElement(TableCell, { className: 'font-bold bg-white text-right' },
                        "$",
                        convertToCurrencyLabel(payment)),
                    React.createElement(TableCell, { className: 'font-bold text-right bg-slate-400' },
                        "$",
                        convertToCurrencyLabel(currentPayment)),
                    React.createElement(TableCell, { className: 'font-bold bg-white text-right' },
                        "$",
                        convertToCurrencyLabel(balance)))))));
};
export default OpenAccountReceivableComponent;
