const getTotalizedCartProducts = (
  Products,
  OldProducts,
  { taxesOption = 1, isInvoice = false, showTaxes = false },
) =>
  Products.map((el, i) => {
    return {
      productId: el.productId,
      name: el.Product?.name,
      productKey: el.Product?.productKey,
      unitKey: el.Product?.unitKey,
      stock: el.stock,
      type: el.Product?.type,
      sku: el.Product?.sku,
      quantity: el.quantity,
      employeeId: el.employeeId,
      discount: el.discount,
      discountRate: el.discountRate,
      unitPrice: parseInt(taxesOption) === 1 ? el.unitPrice : el.withoutTaxes,
      withoutTaxes: el.withoutTaxes,
      serialNumber: el.serialNumber,
      unitPriceToShow: !isInvoice && !showTaxes ? el.unitPrice : el.withoutTaxes,
      taxes: el.taxes,
      subtotal: el.subtotal,
      total: el.total,
      commentary: el.commentary,
      SelectedProductIngredients: OldProducts[i]?.SelectedProductIngredients
        ? OldProducts[i].SelectedProductIngredients
        : undefined,
    };
  });
export default getTotalizedCartProducts;
