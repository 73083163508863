import React, { useEffect } from 'react';

import { useLazyQuery, useMutation } from '@apollo/client';
import format from 'date-fns/format';
import locale from 'date-fns/locale/es';

import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import { currencyFormat } from '../../../../helpers/general';

import IconButton from './helpers/iconButton';

import GQL from '../../_gql';

const TableComponent = props => {
  const [getMaintenanceExpenses, { data: dataMaintenanceExpenses }] = useLazyQuery(
    GQL.GET_MAINTENANCE_EXPENSES,
  );
  const [deleteMaintenanceExpense] = useMutation(GQL.DELETE_MAINTENANCE_EXPENSE, {
    update(cache, { data }) {
      cache.evict({
        id: cache.identify({
          __typename: 'MaintenanceExpense',
          id: data.deleteMaintenanceExpense.id,
        }),
      });
      cache.gc();
    },
  });

  useEffect(() => {
    if (props.id)
      getMaintenanceExpenses({
        variables: { limit: 0, maintenanceId: props.id },
      });
  }, [props.id]);

  const handleDeleteItem = async (e, id) => {
    try {
      await deleteMaintenanceExpense({ variables: { id } });
    } catch (e) {
      console.log(e);
    }
  };
  return (
    <TableContainer>
        <Table size='small'>
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell>Gasto</TableCell>
              <TableCell align='center'>Salida</TableCell>
              <TableCell align='center'>Cant.</TableCell>
              <TableCell align='center'>P. Unitario</TableCell>
              <TableCell align='center'>Subtotal</TableCell>
              <TableCell align='center'>Descuento</TableCell>
              <TableCell align='right'>IVA</TableCell>
              <TableCell align='right'>Total</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {dataMaintenanceExpenses &&
              dataMaintenanceExpenses.maintenanceExpenses &&
              dataMaintenanceExpenses.maintenanceExpenses.rows.map((el, i) => (
                <TableRow key={i}>
                  <TableCell>
                    <IconButton
                      label='Editar'
                      icon={<DeleteOutlineIcon />}
                      params={el.id}
                      action={handleDeleteItem}
                      size='small'
                    />
                  </TableCell>
                  <TableCell>
                    {el.Product ? el.Product.name : el.name ? el.name.trim() : ''}
                  </TableCell>
                  <TableCell align='center'>
                    {el.outDate ? format(new Date(el.outDate), 'dd/MM/yyyy', { locale }) : '-'}
                  </TableCell>
                  <TableCell align='center'>{el.quantity}</TableCell>
                  <TableCell align='center'>{currencyFormat(el.withoutTaxes)}</TableCell>
                  <TableCell align='center'>{currencyFormat(el.subtotal)}</TableCell>
                  <TableCell align='center'>{currencyFormat(el.discount)}</TableCell>
                  <TableCell align='right'>{currencyFormat(el.taxes)}</TableCell>
                  <TableCell align='right'>{currencyFormat(el.total)}</TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
  );
};

export default TableComponent;
