var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
var SelectFieldComponent = function (_a) {
    var label = _a.label, error = _a.error, options = _a.options, helperText = _a.helperText, onChange = _a.onChange, params = _a.params, defaultLabel = _a.defaultLabel, className = _a.className, isLoading = _a.isLoading, props = __rest(_a, ["label", "error", "options", "helperText", "onChange", "params", "defaultLabel", "className", "isLoading"]);
    var handleChange = function (e) {
        onChange(e.target.name, e.target.value, params);
    };
    return (React.createElement("div", { className: "py-1 w-full ".concat(className) },
        label && (React.createElement(Typography, { variant: 'subtitle1', className: 'leading-tight mb-2 mt-' }, label)),
        React.createElement(TextField, __assign({}, props, { select: true, label: defaultLabel, variant: props.variant || 'outlined', fullWidth: props.fullWidth || true, autoFocus: props.autoFocus || false, onChange: handleChange, value: props.value, error: typeof error === 'string', helperText: error || helperText, autoComplete: 'off', disabled: props.disabled || isLoading, size: props.size || 'small' }), options.map(function (el) { return (React.createElement(MenuItem, { key: el.value, value: el.value }, el.label)); }))));
};
export default SelectFieldComponent;
