import GET_PRODUCT_BY_ID from './getProductById';
import CUSTOMIZED_GET_PRODUCTS from './customizedGetProducts';
import CREATE_PRODUCT from './createProduct';
import GET_PRODUCT_PRESENTATION_PRICES_BY_ID from './getProductPresentationPrices';
import FRAGMENT_PRODUCT from './fragmentProduct';
import FRAGMENT_PRODUCT_PRESENTATION_PRICES from './fragmentProductPresentationPrices';
import UPDATE_PRODUCT from './updateProduct';
import DELETE_PRODUCT from './deleteProduct';
import CREATE_PRODUCT_PRESENTATION_PRICE from './createProductPresentationPrice';
import GET_PRODUCT_PRESENTATION_PRICE_BY_ID from './getProductPresentationPriceById';
import UPDATE_PRODUCT_PRESENTATION_PRICE from './updateProductPresentationPrice';
import DELETE_PRODUCT_PRESENTATION_PRICE from './deleteProductPresentationPrice';
import GET_PRODUCT_PRESENTATION_BY_ID from './getProductPresentation';
import GET_PRODUCT_INGREDIENT_BY_ID from './getProductIngredientById';
import FRAGMENT_PRODUCT_INGREDIENT_PRICES from './fragmentProductIngredientPrices';
import CREATE_PRODUCT_INGREDIENT_PRICE from './createProductIngredientPrice';
import GET_PRODUCT_INGREDIENT_PRICE_BY_ID from './getProductIngredientPriceById';
import DELETE_PRODUCT_INGREDIENT_PRICE from './deleteProductIngredientPrice';
import UPDATE_PRODUCT_INGREDIENT_PRICE from './updateProductIngredientPrice';
import CREATE_PRODUCT_PRESENTATION from './createProductPresentation';
import FRAGMENT_PRODUCT_PRESENTATION from './fragmentProductPresentation';
import CREATE_PRODUCT_VARIATION from './createProductVariation';
import CREATE_PRODUCT_INGREDIENT from './createProductIngredient';
import UPDATE_PRODUCT_FILE from './updateProductFile';
import FRAGMENT_PRODUCT_FILE from './fragmentProductFile';
import DELETE_PRODUCT_FILE from './deleteProductFile';
import FRAGMENT_PRODUCT_INGREDIENT from './fragmentProductIngredient';
import UPDATE_PRODUCT_COMMENTARIES from './updateProductCommentaries';
import FRAGMENT_PRODUCT_COMMENTARIES from './fragmentProductCommentaries';
import UPDATE_PRODUCT_PRESENTATION from './updateProductPresentation';
import UPDATE_PRODUCT_INGREDIENT from './updateProductIngredient';
import UPDATE_PRODUCT_PUBLIC from './updateProductPublic';
import GET_PRODUCT_ECOMMERCE_BY_ID from './getProductEcommerceById';
import CREATE_PRODUCT_ATTRIBUTE from './createProductAttribute';
import FRAGMENT_PRODUCT_ATTRIBUTE from './fragmentProductAttribute';
import DELETE_PRODUCT_ATTRIBUTE from './deleteProductAttribute';
import DELETE_PRODUCT_ATTRIBUTE_FILE from './deleteProductAttributeFile';
import FRAGMENT_PRODUCT_ATTRIBUTE_FILE from './fragmentProductAttributeFile';
import GET_PRODUCT_ATTRIBUTE_FILES from './getProductAttributeFiles';
import CREATE_PRODUCT_ATTRIBUTE_FILE from './createProductAttributeFile';
import UPDATE_PRODUCT_VARIATION from './updateProductVariation';
import DELETE_PRODUCT_VARIATION from './deleteProductVariation';
import GET_PRODUCT_VARIATION from './getProductVariation';
import GET_PRODUCT_VARIATIONS from './getProductVariations';
import FRAGMENT_PRODUCT_VARIATION from './fragmentProductVariation';
import DELETE_PRODUCT_INGREDIENT from './deleteProductIngredient';
import DELETE_PRODUCT_PRESENTATION from './deleteProductPresentation';
import UPDATE_PRODUCT_ATTRIBUTE from './updateProductAttribute';
import UPDATE_PRODUCT_ECOMMERCE_SHIPPING from './updateProductEcommerceShipping.tsx';
import UPDATE_PRODUCT_ECOMMERCE_MEASUREMENT from './updateProductEcommerceMeasurement';
import GET_PRODUCT_BY_BARCODE from './getProductByBarcode';
import UPDATE_BRANCH_PRODUCT_PRESENTATION_CONFIGURATION from './updateBranchProductPresentationConfiguration';
import GET_BRANCH_PRODUCT_PRESENTATION_CONFIGURATIONS from './getBranchProductPresentationConfigurations';
import FRAGMENT_BRANCH_PRODUCT_PRESENTATION_CONFIGURATION from './fragmentBranchProductPresentationConfiguration';
import GET_BRANCH_PRODUCT_PRESENTATION_CONFIGURATION_BY_ID from './getBranchProductPresentationConfigurationById';
import DELETE_BRANCH_PRODUCT_PRESENTATION_CONFIGURATION from './deleteBranchProductPresentationConfiguration';
import GET_PRODUCT_BY_PRODUCT_PRESENTATION_ID from './getProductByProductPresentationId';
var GQL_PRODUCT = {
    CREATE_PRODUCT: CREATE_PRODUCT,
    CREATE_PRODUCT_ATTRIBUTE: CREATE_PRODUCT_ATTRIBUTE,
    CREATE_PRODUCT_ATTRIBUTE_FILE: CREATE_PRODUCT_ATTRIBUTE_FILE,
    CREATE_PRODUCT_INGREDIENT: CREATE_PRODUCT_INGREDIENT,
    CREATE_PRODUCT_INGREDIENT_PRICE: CREATE_PRODUCT_INGREDIENT_PRICE,
    CREATE_PRODUCT_PRESENTATION: CREATE_PRODUCT_PRESENTATION,
    CREATE_PRODUCT_PRESENTATION_PRICE: CREATE_PRODUCT_PRESENTATION_PRICE,
    CREATE_PRODUCT_VARIATION: CREATE_PRODUCT_VARIATION,
    CUSTOMIZED_GET: CUSTOMIZED_GET_PRODUCTS,
    DELETE_BRANCH_PRODUCT_PRESENTATION_CONFIGURATION: DELETE_BRANCH_PRODUCT_PRESENTATION_CONFIGURATION,
    DELETE_PRODUCT: DELETE_PRODUCT,
    DELETE_PRODUCT_ATTRIBUTE: DELETE_PRODUCT_ATTRIBUTE,
    DELETE_PRODUCT_ATTRIBUTE_FILE: DELETE_PRODUCT_ATTRIBUTE_FILE,
    DELETE_PRODUCT_FILE: DELETE_PRODUCT_FILE,
    DELETE_PRODUCT_INGREDIENT: DELETE_PRODUCT_INGREDIENT,
    DELETE_PRODUCT_INGREDIENT_PRICE: DELETE_PRODUCT_INGREDIENT_PRICE,
    DELETE_PRODUCT_PRESENTATION: DELETE_PRODUCT_PRESENTATION,
    DELETE_PRODUCT_PRESENTATION_PRICE: DELETE_PRODUCT_PRESENTATION_PRICE,
    DELETE_PRODUCT_VARIATION: DELETE_PRODUCT_VARIATION,
    FRAGMENT_BRANCH_PRODUCT_PRESENTATION_CONFIGURATION: FRAGMENT_BRANCH_PRODUCT_PRESENTATION_CONFIGURATION,
    FRAGMENT_PRODUCT: FRAGMENT_PRODUCT,
    FRAGMENT_PRODUCT_ATTRIBUTE: FRAGMENT_PRODUCT_ATTRIBUTE,
    FRAGMENT_PRODUCT_ATTRIBUTE_FILE: FRAGMENT_PRODUCT_ATTRIBUTE_FILE,
    FRAGMENT_PRODUCT_COMMENTARIES: FRAGMENT_PRODUCT_COMMENTARIES,
    FRAGMENT_PRODUCT_FILE: FRAGMENT_PRODUCT_FILE,
    FRAGMENT_PRODUCT_INGREDIENT: FRAGMENT_PRODUCT_INGREDIENT,
    FRAGMENT_PRODUCT_INGREDIENT_PRICES: FRAGMENT_PRODUCT_INGREDIENT_PRICES,
    FRAGMENT_PRODUCT_PRESENTATION: FRAGMENT_PRODUCT_PRESENTATION,
    FRAGMENT_PRODUCT_PRESENTATION_PRICES: FRAGMENT_PRODUCT_PRESENTATION_PRICES,
    FRAGMENT_PRODUCT_VARIATION: FRAGMENT_PRODUCT_VARIATION,
    GET_BRANCH_PRODUCT_PRESENTATION_CONFIGURATION_BY_ID: GET_BRANCH_PRODUCT_PRESENTATION_CONFIGURATION_BY_ID,
    GET_BRANCH_PRODUCT_PRESENTATION_CONFIGURATIONS: GET_BRANCH_PRODUCT_PRESENTATION_CONFIGURATIONS,
    GET_PRODUCT_BY_BARCODE: GET_PRODUCT_BY_BARCODE,
    GET_PRODUCT_BY_ID: GET_PRODUCT_BY_ID,
    GET_PRODUCT_BY_PRODUCT_PRESENTATION_ID: GET_PRODUCT_BY_PRODUCT_PRESENTATION_ID,
    GET_PRODUCT_ECOMMERCE_BY_ID: GET_PRODUCT_ECOMMERCE_BY_ID,
    GET_PRODUCT_INGREDIENT_BY_ID: GET_PRODUCT_INGREDIENT_BY_ID,
    GET_PRODUCT_INGREDIENT_PRICE_BY_ID: GET_PRODUCT_INGREDIENT_PRICE_BY_ID,
    GET_PRODUCT_PRESENTATION_BY_ID: GET_PRODUCT_PRESENTATION_BY_ID,
    GET_PRODUCT_PRESENTATION_PRICES_BY_ID: GET_PRODUCT_PRESENTATION_PRICES_BY_ID,
    GET_PRODUCT_PRESENTATION_PRICE_BY_ID: GET_PRODUCT_PRESENTATION_PRICE_BY_ID,
    GET_PRODUCT_ATTRIBUTE_FILES: GET_PRODUCT_ATTRIBUTE_FILES,
    GET_PRODUCT_VARIATION: GET_PRODUCT_VARIATION,
    GET_PRODUCT_VARIATIONS: GET_PRODUCT_VARIATIONS,
    UPDATE_BRANCH_PRODUCT_PRESENTATION_CONFIGURATION: UPDATE_BRANCH_PRODUCT_PRESENTATION_CONFIGURATION,
    UPDATE_PRODUCT: UPDATE_PRODUCT,
    UPDATE_PRODUCT_ATTRIBUTE: UPDATE_PRODUCT_ATTRIBUTE,
    UPDATE_PRODUCT_COMMENTARIES: UPDATE_PRODUCT_COMMENTARIES,
    UPDATE_PRODUCT_ECOMMERCE_MEASUREMENT: UPDATE_PRODUCT_ECOMMERCE_MEASUREMENT,
    UPDATE_PRODUCT_ECOMMERCE_SHIPPING: UPDATE_PRODUCT_ECOMMERCE_SHIPPING,
    UPDATE_PRODUCT_FILE: UPDATE_PRODUCT_FILE,
    UPDATE_PRODUCT_INGREDIENT: UPDATE_PRODUCT_INGREDIENT,
    UPDATE_PRODUCT_INGREDIENT_PRICE: UPDATE_PRODUCT_INGREDIENT_PRICE,
    UPDATE_PRODUCT_PRESENTATION: UPDATE_PRODUCT_PRESENTATION,
    UPDATE_PRODUCT_PRESENTATION_PRICE: UPDATE_PRODUCT_PRESENTATION_PRICE,
    UPDATE_PRODUCT_PUBLIC: UPDATE_PRODUCT_PUBLIC,
    UPDATE_PRODUCT_VARIATION: UPDATE_PRODUCT_VARIATION,
};
export default GQL_PRODUCT;
