import React, { useState, useEffect } from 'react';
import { useQuery, useReactiveVar } from '@apollo/client';

import Grid from '@mui/material/Grid';

import { clearForm, form, step, handleContinue } from '../../../../apollo/cache';

import TextField from '../../../../presentationals/FormComponents/TextField';
import DateField from '../../../../presentationals/FormComponents/DateField';
import SelectField from '../../../../presentationals/FormComponents/SelectField';

import GQL_PAYMENT_METHOD from '../../../paymentMethod/_gql';

const defaultValues = {
  total: 0,
  subtotal: 0,
  discount: 0,
  iva: 0,
  ieps: 0,
  folio: '',
  date: new Date(),
  userPassword: '',
  paymentMethodId: 0,
};

const MainComponent = () => {
  const [values, setValues] = useState(defaultValues);
  const [errors, setErrors] = useState({});
  const [paymentMethods, setPaymentMethods] = useState([]);

  const clearFormVar = useReactiveVar(clearForm);
  const stepVar = useReactiveVar(step);
  const handleContinueVar = useReactiveVar(handleContinue);

  useQuery(GQL_PAYMENT_METHOD.GET, {
    variables: { limit: 0 },
    onCompleted: data => {
      const tmp = data.paymentMethods.rows.map(el => ({
        label: el.name,
        value: el.id,
      }));
      setPaymentMethods([{ label: 'EFECTIVO', value: 0 }, ...tmp]);
    },
  });

  const mainFormValidation = () => {
    try {
      setErrors({});
      if (!values.folio || values.folio.trim() === '') {
        const e = new Error('Folio es obligatorio');
        e.name = 'folio';
        e.fields = true;
        throw e;
      }
      if (
        !values.subtotal ||
        Number.isNaN(parseFloat(values.subtotal)) ||
        parseFloat(values.subtotal) <= 0
      ) {
        const e = new Error('El subtotal debe de ser mayor a cero');
        e.name = 'subtotal';
        e.fields = true;
        throw e;
      }
      if (Number.isNaN(parseFloat(values.discount)) || parseFloat(values.discount) < 0) {
        const e = new Error('El descuento debe de ser mayor o igual a cero');
        e.name = 'discount';
        e.fields = true;
        throw e;
      }
      if (Number.isNaN(parseFloat(values.iva)) || parseFloat(values.iva) < 0) {
        const e = new Error('El IVA debe de ser mayor 0 igual a cero');
        e.name = 'iva';
        e.fields = true;
        throw e;
      }
      if (Number.isNaN(parseFloat(values.ieps)) || parseFloat(values.ieps) < 0) {
        const e = new Error('El IEPS debe de ser mayor 0 igual a cero');
        e.name = 'ieps';
        e.fields = true;
        throw e;
      }
      if (
        !values.total ||
        Number.isNaN(parseFloat(values.total)) ||
        parseFloat(values.total) <= 0
      ) {
        const e = new Error('El total debe de ser mayor a cero');
        e.name = 'total';
        e.fields = true;
        throw e;
      }
      step(stepVar + 1);
      form(values);
    } catch (e) {
      setErrors({ [e.name]: e.message });
    }
  };

  useEffect(() => {
    if (stepVar === 0 && handleContinueVar) {
      mainFormValidation();
      handleContinue(false);
    }
  }, [stepVar, handleContinueVar]);

  useEffect(() => {
    if (clearFormVar && stepVar === 0) {
      setValues(defaultValues);
      form({});
      clearForm(false);
    }
  }, [clearFormVar]);

  const handleChange = (name, value) => {
    setValues(values => ({ ...values, [name]: value }));
  };

  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={12} md={5}>
          <TextField
            name='folio'
            label='Ingrese el folio de la nota de crédito'
            autoFocus
            onChange={handleChange}
            value={values.folio}
            error={errors.folio}
          />
        </Grid>
      </Grid>
      <Grid container spacing={1}>
        <Grid item xs={12} md={5}>
          <TextField
            name='subtotal'
            label='Ingrese el subtotal de la nota de crédito'
            onChange={handleChange}
            value={values.subtotal}
            error={errors.subtotal}
            type='number'
          />
        </Grid>
      </Grid>
      <Grid container spacing={1}>
        <Grid item xs={12} md={5}>
          <TextField
            name='discount'
            label='Ingrese el descuento de la nota de crédito'
            onChange={handleChange}
            value={values.discount}
            error={errors.discount}
            type='number'
          />
        </Grid>
      </Grid>
      <Grid container spacing={1}>
        <Grid item xs={12} md={5}>
          <TextField
            name='iva'
            label='Ingrese el IVA de la nota de crédito'
            onChange={handleChange}
            value={values.iva}
            error={errors.iva}
            type='number'
          />
        </Grid>
      </Grid>
      <Grid container spacing={1}>
        <Grid item xs={12} md={5}>
          <TextField
            name='ieps'
            label='Ingrese el IEPS de la nota de crédito'
            onChange={handleChange}
            value={values.ieps}
            error={errors.ieps}
            type='number'
          />
        </Grid>
      </Grid>
      <Grid container spacing={1}>
        <Grid item xs={12} md={5}>
          <TextField
            name='total'
            label='Ingrese el total de la nota de crédito'
            onChange={handleChange}
            value={values.total}
            error={errors.total}
            type='number'
          />
        </Grid>
      </Grid>
      <Grid container spacing={1}>
        <Grid item xs={12} md={5}>
          <SelectField
            name='paymentMethodId'
            label='Ingrese la forma de pago de la nota de crédito'
            onChange={handleChange}
            value={values.paymentMethodId}
            error={errors.paymentMethodId}
            options={paymentMethods}
          />
        </Grid>
      </Grid>
      <Grid container spacing={1}>
        <Grid item xs={12} md={5}>
          <DateField
            name='date'
            label='Ingrese la fecha de la nota de crédito'
            onChange={handleChange}
            value={values.date}
            error={errors.date}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default MainComponent;
