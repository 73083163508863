import React from 'react';
import { FormControl, FormControlLabel, FormHelperText, FormLabel, Radio, RadioGroup, Tooltip, } from '@mui/material';
import useApolloQuery from '../../../../../../hooks/useApolloQuery';
import GQL from '../../../../../Tag/_gql';
import AddButton from './AddButton';
import DeleteButton from './DeleteButton';
var TagFieldBodyFormCalendarComponent = function (props) {
    var _a;
    var _b = useApolloQuery(GQL.GET), loading = _b.loading, data = _b.data;
    var handleChange = function (e) {
        var _a = e.target, name = _a.name, value = _a.value;
        props.handleChange(name, value);
    };
    return (React.createElement("div", { className: 'w-full' },
        React.createElement(FormControl, { error: !!props.error, component: 'fieldset' },
            React.createElement(FormLabel, { htmlFor: 'tagId' }, "Etiqueta"),
            React.createElement(RadioGroup, { row: true, "aria-label": 'tagId', name: 'tagId', onChange: handleChange, defaultValue: 'top' }, data &&
                data.map(function (el) { return (React.createElement(FormControlLabel, { checked: el.id === props.value, key: el.id, value: el.id, disabled: props.disabled || loading, control: React.createElement(Tooltip, { title: el.name },
                        React.createElement(Radio, { className: 'py-1', style: { color: el.color || '#1976d2' } })), label: '' })); })),
            React.createElement(FormHelperText, null, (_a = props.error) === null || _a === void 0 ? void 0 : _a.tagId)),
        !props.hideActions && (React.createElement("div", { className: 'flex' },
            React.createElement(AddButton, null),
            props.value && React.createElement(DeleteButton, { disabled: props.disabled, id: props.value })))));
};
export default TagFieldBodyFormCalendarComponent;
