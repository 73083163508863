import getSettings from './getSettings';
import setSettings from './setSettings';
var setDefaultTaxedOptions = function (option) {
    var settings = getSettings();
    var newDefaultFormatSaleV2 = [
        settings.defaultFormatSaleV2[0],
        option,
        settings.defaultFormatSaleV2[2],
        settings.defaultFormatSaleV2[3],
    ];
    setSettings('defaultFormatSaleV2', newDefaultFormatSaleV2);
};
export default setDefaultTaxedOptions;
