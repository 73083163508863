import React, { useEffect } from 'react';
import { productSaleV2 } from '../../../apollo/cache';
import CreateProductDialogProductFormChooseProductSaleV2Component from '../../sale.v2/ChooseProduct/ProductDialog/Create';
import getDefaultProductValues from '../../sale.v2/ChooseProduct/ProductDialog/Create/Body/helpers/getDefaultValues';
var CreateProductComponent = function () {
    useEffect(function () {
        var defaultValues = getDefaultProductValues();
        productSaleV2(defaultValues);
    }, []);
    return React.createElement(CreateProductDialogProductFormChooseProductSaleV2Component, null);
};
export default CreateProductComponent;
