import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import ItemCustomerReceivablesComponent from './Item';
import convertToCurrencyLabel from '../../../../helpers/convertToCurrencyLabel';
import useApolloLazyQuery from '../../../../hooks/useApolloLazyQuery';
import GQL_ACCOUNT_RECEIVABLE from '../../../../apollo/gql/accountReceivable';
import LoadingProgress from '../../../../presentationals/LoadingProgress';
import ErrorDataComponent from '../../../../presentationals/ErrorData';
var AccountReceivableInformationComponent = function () {
    var params = useParams();
    var _a = useState(0), total = _a[0], setTotal = _a[1];
    var _b = useState(0), payment = _b[0], setPayment = _b[1];
    var _c = useState(0), balance = _c[0], setBalance = _c[1];
    var _d = useApolloLazyQuery(GQL_ACCOUNT_RECEIVABLE.GET_ACCOUNT_RECEIVABLES), getData = _d[0], _e = _d[1], loading = _e.loading, data = _e.data;
    useEffect(function () {
        if (data) {
            var totals = data.reduce(function (total, el) { return [total[0] + el.total, total[1] + el.payment, total[2] + el.balance]; }, [0, 0, 0]);
            setTotal(totals[0]);
            setPayment(totals[1]);
            setBalance(totals[2]);
        }
    }, [data]);
    useEffect(function () {
        if (params.customerId)
            getData({ variables: { customerId: params.customerId } });
    }, [params]);
    if (loading)
        return React.createElement(LoadingProgress, null);
    if (!data)
        return React.createElement(ErrorDataComponent, null);
    return (React.createElement(TableContainer, null,
        React.createElement(Table, { className: 'sticky', stickyHeader: true, size: 'small' },
            React.createElement(TableHead, null,
                React.createElement(TableRow, null,
                    React.createElement(TableCell, { className: 'font-medium' }, "Creado el"),
                    React.createElement(TableCell, { className: 'font-medium whitespace-nowrap' }, "L\u00EDmite de pago"),
                    React.createElement(TableCell, { className: 'font-medium whitespace-nowrap' }, "Folio de venta"),
                    React.createElement(TableCell, { className: 'font-medium' }, "Vendedor"),
                    React.createElement(TableCell, { className: 'bg-slate-300 font-medium text-right' }, "Cargo"),
                    React.createElement(TableCell, { className: 'bg-slate-300 font-medium text-right' }, "Abono"),
                    React.createElement(TableCell, { className: 'bg-slate-300 font-medium text-right' }, "Saldo"))),
            React.createElement(TableBody, null,
                data.map(function (el, i) { return (React.createElement(ItemCustomerReceivablesComponent, { key: el.id, AccountReceivable: el, index: i })); }),
                React.createElement(TableRow, { className: 'sticky', style: { insetBlockEnd: 0 } },
                    React.createElement(TableCell, { className: 'text-right font-bold bg-white', colSpan: 4 }),
                    React.createElement(TableCell, { className: 'font-bold bg-white text-right' },
                        "$",
                        convertToCurrencyLabel(total)),
                    React.createElement(TableCell, { className: 'font-bold bg-white text-right' },
                        "$",
                        convertToCurrencyLabel(payment)),
                    React.createElement(TableCell, { className: 'font-bold bg-white text-right' },
                        "$",
                        convertToCurrencyLabel(balance)))))));
};
export default AccountReceivableInformationComponent;
