import React from 'react';
import RoleComponent from '../containers/role';
import RoleLayoutComponent from './Role/RoleLayout';
import GeneralEditRoleComponent from '../containers/role/Edit/General';
import PermissionEditRoleComponent from '../containers/role/Edit/Permission';
import CreateRoleLayoutComponent from './Role/CreateRoleLayoute';
var getRoleChildrenRoutes = function () {
    return [
        { index: true, element: React.createElement(RoleComponent, null) },
        {
            path: 'create',
            element: React.createElement(CreateRoleLayoutComponent, null),
        },
        {
            path: ':roleId',
            element: React.createElement(RoleLayoutComponent, null),
            children: [
                { index: true, element: React.createElement(GeneralEditRoleComponent, null) },
                { path: 'permission', element: React.createElement(PermissionEditRoleComponent, null) },
            ],
        },
    ];
};
export default getRoleChildrenRoutes;
