import { TableRow } from '@mui/material';
import React from 'react';
import { isProductPromotionOpen } from '../../../../../../apollo/cache';
var TableRowItemProductTableEditPromotionComponent = function (props) {
    var handleClick = function () {
        isProductPromotionOpen(props.productPromotionId);
    };
    return (React.createElement(TableRow, { hover: true, className: "cursor-pointer ".concat(props.background), onClick: handleClick }, props.children));
};
export default TableRowItemProductTableEditPromotionComponent;
