import React, { useEffect, useState } from 'react';

import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';

import { currencyFormat } from '../../../../../../../helpers';

const TotalsComponent = props => {
  const [colSpan, setColSpan] = useState(5);
  useEffect(() => {
    const tmp = props.action === '3' ? 5 : 4;
    setColSpan(tmp);
  }, [props.action]);
  return (
    <>
      <TableRow>
        <TableCell colSpan={colSpan} align='right' className='bg-[#f5f5f5]'>
          Subtotal:
        </TableCell>
        <TableCell align='right' className='bg-[#f5f5f5]'>
          {`$${currencyFormat(props.totals.subtotal)}`}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell colSpan={colSpan} align='right' className='bg-[#f5f5f5]'>
          IVA:
        </TableCell>
        <TableCell align='right' className='bg-[#f5f5f5]'>
          {`$${currencyFormat(props.totals.iva)}`}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell colSpan={colSpan} align='right' className='bg-[#f5f5f5]'>
          IEPS:
        </TableCell>
        <TableCell align='right' className='bg-[#f5f5f5]'>
          {`$${currencyFormat(props.totals.ieps)}`}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell colSpan={colSpan} align='right' className='bg-[#f5f5f5]'>
          <strong>Total:</strong>
        </TableCell>
        <TableCell align='right' className='bg-[#f5f5f5]'>
          <strong>{`$${currencyFormat(props.totals.total)}`}</strong>
        </TableCell>
      </TableRow>
    </>
  );
};

export default TotalsComponent;
