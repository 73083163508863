const getQueryName = typeName => {
  try {
    switch (typeName.toLowerCase()) {
      case 'quote':
        return ['quotes', 'FRAGMENT_QUOTE'];
      case 'sale':
        return ['sales', 'FRAGMENT_SALE'];
      default:
        return null;
    }
  } catch (e) {
    return e;
  }
};

export default getQueryName;
