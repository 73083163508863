var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import 'date-fns';
import React from 'react';
import localeES from 'date-fns/locale/es';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { FormLabel, TextField } from '@mui/material';
var TextFieldC = function (textFieldProps, error, helperText, fullWidth, size, disabled) {
    return (React.createElement(TextField, __assign({}, textFieldProps, { error: !!error, helperText: error || helperText, autoComplete: 'off', fullWidth: fullWidth || true, size: size || 'small', disabled: disabled || false })));
};
var DateFieldComponent = function (_a) {
    var label = _a.label, error = _a.error, onChange = _a.onChange, helperText = _a.helperText, size = _a.size, fullWidth = _a.fullWidth, value = _a.value, disabled = _a.disabled, props = __rest(_a, ["label", "error", "onChange", "helperText", "size", "fullWidth", "value", "disabled"]);
    var handleChange = function (date) {
        onChange(props.name, date);
    };
    return (React.createElement("div", { className: 'w-full' },
        React.createElement(FormLabel, { htmlFor: props.name }, label),
        React.createElement(LocalizationProvider, { dateAdapter: AdapterDateFns, adapterLocale: localeES },
            React.createElement(DatePicker, __assign({}, props, { value: value, onChange: handleChange, disabled: disabled, format: 'dd MMMM yyyy', slots: {
                    textField: function (textFieldProps) {
                        return TextFieldC(textFieldProps, error, helperText, fullWidth, size, disabled);
                    },
                } })))));
};
export default DateFieldComponent;
