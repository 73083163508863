import React from 'react';

import { ListItem, ListItemText } from '@mui/material';

import ListItemButton from './ListItemButton';
import SearchButton from './SearchButton';
import { currencyFormat } from '../../../../../../../helpers';

const ItemTableSearchFormCustomerActionSaleV2Component = props => {
  return (
    <ListItemButton id={props.id} background={props.background}>
      <ListItem secondaryAction={<SearchButton id={props.id} />}>
        <ListItemText
          primary={props.name}
          secondary={`Precio: $${currencyFormat(props.price)} | SKU: ${
            props.sku ? props.sku : '-'
          } | Código de barras: ${props.barcode !== '' ? props.barcode : '-'}`}
        />
      </ListItem>
    </ListItemButton>
  );
};

export default ItemTableSearchFormCustomerActionSaleV2Component;
