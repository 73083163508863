var getTotalizedCartVariables = function (cart, settings) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j;
    var discount = ((_a = settings === null || settings === void 0 ? void 0 : settings.Discount) === null || _a === void 0 ? void 0 : _a.discount) ? parseFloat(settings.Discount.discount) : 0;
    return {
        taxesOption: ((_b = settings.General) === null || _b === void 0 ? void 0 : _b.showTaxesOptions) && ((_c = settings.General) === null || _c === void 0 ? void 0 : _c.taxesOption)
            ? (_d = settings.General) === null || _d === void 0 ? void 0 : _d.taxesOption
            : 1,
        discountType: (_e = settings.Discount) === null || _e === void 0 ? void 0 : _e.discountType,
        shipping: 0,
        branchId: (_f = settings.Branch) === null || _f === void 0 ? void 0 : _f.branchId,
        currencyKey: (_g = settings.Currency) === null || _g === void 0 ? void 0 : _g.currencyKey,
        exchangeRate: parseFloat(((_h = settings.Currency) === null || _h === void 0 ? void 0 : _h.exchangeRate) || '0'),
        category: 1,
        isInvoice: false,
        Transactions: [],
        showTaxes: !!((_j = settings.General) === null || _j === void 0 ? void 0 : _j.showTaxesOptions),
        Products: cart.map(function (el) {
            var _a, _b;
            return ({
                productId: el.Product.id,
                discount: ((_a = settings.Discount) === null || _a === void 0 ? void 0 : _a.discountType)
                    ? ((_b = settings.Discount) === null || _b === void 0 ? void 0 : _b.discountType) === 1
                        ? discount
                        : discount / cart.length
                    : 0,
                productPresentationId: el.ProductPresentation.id,
                price: parseFloat(el.cost.toString()),
                quantity: parseFloat(el.quantity.toString()),
            });
        }),
    };
};
export default getTotalizedCartVariables;
