import React from 'react';
import { useReactiveVar } from '@apollo/client';
import { Dialog } from '@mui/material';
import { isEditPurchaseOrderItemOpen } from '../../../../../apollo/cache';
import Header from './Header';
import Body from './Body';
import Footer from './Footer';
var ItemEditFormProductComponent = function () {
    var isEditPurchaseOrderItemOpenVar = useReactiveVar(isEditPurchaseOrderItemOpen);
    return (React.createElement(Dialog, { fullScreen: true, open: isEditPurchaseOrderItemOpenVar },
        React.createElement(Header, null),
        React.createElement(Body, null),
        React.createElement(Footer, null)));
};
export default ItemEditFormProductComponent;
