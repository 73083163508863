var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { forwardRef, useImperativeHandle, useRef } from 'react';
import { useReactiveVar } from '@apollo/client';
import IngredientTypeFieldComponent from './IngredientTypeField';
import CreateIngredientTypeButtonComponent from './CreateIngredientTypeButton';
import EditIngredientTypeButtonComponent from './EditIngredientTypeButton';
import IngredientQuantityFieldComponent from './IngredientQuantityField';
import FieldContainerProductIngredientComponent from './FieldContainer';
import { ingredientProductForm, inputError } from '../../../../../../apollo/cache';
import SubtitleDialogComponent from '../../../../../../presentationals/Dialog/helpers/Subtitle';
import ProductFieldComponent from '../../../../../../presentationals/FormComponents/ProductField';
import TextFieldComponent from '../../../../../../presentationals/FormComponents/TextField';
import SwitchComponent from '../../../../../../presentationals/FormComponents/Switch';
import CreateProductVariationComponent from '../../../../ProductVariation/Create';
import EditProductVariationComponent from '../../../../ProductVariation/Edit';
var BodyProductIngredientComponent = forwardRef(function (props, ref) {
    var productFieldRef = useRef(null);
    var errors = useReactiveVar(inputError);
    var values = useReactiveVar(ingredientProductForm);
    var handleChange = function (name, value) {
        var _a;
        ingredientProductForm(__assign(__assign({}, values), (_a = {}, _a[name] = value, _a)));
    };
    var handleProductFieldChange = function (name, value) {
        var _a;
        ingredientProductForm(__assign(__assign({}, values), (_a = {}, _a[name] = value, _a)));
    };
    var handleClear = function () {
        var _a;
        productFieldRef && ((_a = productFieldRef.current) === null || _a === void 0 ? void 0 : _a.handleClear());
    };
    var handleChangeSwitch = function (name, isActive) {
        var _a;
        ingredientProductForm(__assign(__assign({}, values), (_a = {}, _a[name] = isActive, _a)));
    };
    useImperativeHandle(ref, function () { return ({
        handleClear: handleClear,
    }); });
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: 'flex-1 py-2 px-4 grid grid-cols-1 md:grid-cols-4 gap-4 overflow-auto basis-0' },
            React.createElement("div", null,
                React.createElement(SubtitleDialogComponent, { label: 'Datos generales' }),
                React.createElement(FieldContainerProductIngredientComponent, null,
                    React.createElement(ProductFieldComponent, { label: 'Insumo/materia prima', name: 'ingredientId', ref: productFieldRef, onChange: handleProductFieldChange, initialValue: values.ingredientId })),
                React.createElement("div", { className: 'flex flex-row items-end' },
                    React.createElement(IngredientTypeFieldComponent, { value: values.type, onChange: handleChange }),
                    parseInt(values.type) > 5 ? (React.createElement(EditIngredientTypeButtonComponent, null)) : (React.createElement(CreateIngredientTypeButtonComponent, null))),
                React.createElement(FieldContainerProductIngredientComponent, null,
                    React.createElement(TextFieldComponent, { label: 'Cantidad de porciones por default que contiene el producto', name: 'portion', value: values.portion, error: errors.portion, type: 'number', onChange: handleChange })),
                React.createElement(FieldContainerProductIngredientComponent, null,
                    React.createElement(IngredientQuantityFieldComponent, { value: values.portionQty, ingredientId: values.ingredientId, onChange: handleChange })),
                React.createElement(FieldContainerProductIngredientComponent, null,
                    React.createElement(SwitchComponent, { label: 'Se utiliza \u00FAnicamente en \u00F3rdenes para llevar', name: 'toGo', defaultValue: values.toGo, onChange: handleChangeSwitch }))),
            React.createElement("div", null,
                React.createElement(SubtitleDialogComponent, { label: 'Informaci\u00F3n comercial' }),
                React.createElement(TextFieldComponent, { label: 'Precio de cada porci\u00F3n', name: 'portionPrice', value: values.portionPrice, error: errors.portionPrice, onChange: handleChange, type: 'number' }),
                React.createElement(TextFieldComponent, { label: 'Precio de cada porci\u00F3n extra', name: 'extra', value: values.extra, error: errors.extra, onChange: handleChange, type: 'number' }),
                React.createElement(TextFieldComponent, { label: 'N\u00FAmero de porciones gratuitas', name: 'freePortionQty', value: values.freePortionQty, error: errors.freePortionQty, type: 'number', onChange: handleChange }))),
        React.createElement(CreateProductVariationComponent, null),
        React.createElement(EditProductVariationComponent, null)));
});
export default BodyProductIngredientComponent;
