var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { currencyFormat } from '../../../../helpers';
import getPurchaseOrderTableColumnConfiguration from '../../../../helpers/getPurchaseOrderTableColumnConfiguration';
var getPurchaseOrderRowsToShowInTable = function (rows) {
    var columnsSetting = getPurchaseOrderTableColumnConfiguration();
    return rows.map(function (el) {
        var provider = columnsSetting.provider ? { provider: el.Provider.name || '-' } : {};
        return __assign(__assign({ id: el.id }, provider), { total: currencyFormat(el.total || 0) });
    });
};
export default getPurchaseOrderRowsToShowInTable;
