var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
var addRequisitionProductByBarcode = function (Item, Rows) {
    var isInArray = Rows.find(function (el) { return el.barcode === Item.barcode; });
    if (isInArray)
        return __spreadArray([
            __assign(__assign({}, isInArray), { barcode: Item.barcode, quantity: isInArray.quantity + Item.quantity })
        ], Rows.filter(function (el) { return el.barcode !== Item.barcode; }), true);
    return __spreadArray([
        {
            barcode: Item.barcode,
            quantity: Item.quantity,
        }
    ], Rows, true);
};
export default addRequisitionProductByBarcode;
