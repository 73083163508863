var getSaleServerColumnName = function (column) {
    switch (column) {
        case 'closedAt':
            return 'clientClosingAt';
        case 'createdAt':
            return 'clientCreatedAt';
        default:
            return column;
    }
};
export default getSaleServerColumnName;
