import React from 'react';

import Button from './Button';
import Form from './Form';

const FormatActionSaleV2Component = () => {
  return (
    <>
      <Button />
      <Form />
    </>
  );
};

export default FormatActionSaleV2Component;
