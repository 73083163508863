import React, { forwardRef } from 'react';
import { InputBase } from '@mui/material';
import useProductFieldStore from '../../../../../store/productField';
var ProductInputBaseFieldComponent = forwardRef(function (props, ref) {
    var _a = useProductFieldStore(), value = _a.value, setValue = _a.setValue;
    var handleChange = function (e) {
        setValue(e.target.value);
    };
    var handleKeyDown = function (e) {
        if (e.key === 'Enter')
            props.onEnter && props.onEnter(e.currentTarget);
    };
    return (React.createElement(InputBase, { ref: ref, placeholder: props.placeholder, disabled: props.disabled, onChange: handleChange, onKeyDown: handleKeyDown, value: value, className: 'flex flex-1 ml-1', inputProps: { 'aria-label': 'product-field' }, autoComplete: 'off' }));
});
export default ProductInputBaseFieldComponent;
