var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React, { useEffect, useState } from 'react';
import SelectFieldComponent from '../../../../presentationals/FormComponents/SelectField';
import useApolloQuery from '../../../../hooks/useApolloQuery';
import GQL from '../../../paymentMethod/_gql';
var PaymentFormFieldComponent = function (props) {
    var defaultPaymentMethods = [
        { value: '0', label: 'Todas las disponibles' },
        { value: '-1', label: 'Efectivo' },
    ];
    var _a = useState([]), options = _a[0], setOptions = _a[1];
    var _b = useApolloQuery(GQL.GET), data = _b.data, loading = _b.loading;
    useEffect(function () {
        var paymentForms = (data === null || data === void 0 ? void 0 : data.rows)
            ? data.rows.map(function (el) { return ({ value: el.id, label: el.name }); })
            : [];
        setOptions(__spreadArray(__spreadArray([], defaultPaymentMethods.filter(function (el) { return props.noAllOptions && el.value !== '0'; }), true), paymentForms, true));
    }, [data]);
    return (React.createElement(SelectFieldComponent, { disabled: loading, name: 'paymentForm', label: 'Forma de pago', onChange: props.onChange, value: options.length === 0 ? '' : props.value, options: options, size: props.size || 'medium' }));
};
export default PaymentFormFieldComponent;
