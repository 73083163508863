import React from 'react';
import { dateFormat } from '../../../../../helpers';
import CommentaryRentFieldComponent from './CommentaryField';
import PrintSaleButtonComponent from '../../helpers/PrintSaleButton';
import FooterDialogComponent from '../../../../../presentationals/Dialog/Footer';
import PrintOptionFooterConfirmDialogSaleV2Component from '../../../../sale.v2/ConfirmDialog/Footer/PrintOption';
import SendEmailSaleButtonComponent from '../../helpers/SendEmailSaleButton';
import CreateXMLProductsButtonComponent from '../../helpers/CreateXMLProductsButton';
import OpenEditCalendarButtonComponent from './OpenEditCalendarButton';
var BodyGeneralRentComponent = function (props) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j;
    return (React.createElement("div", { className: 'flex flex-1 flex-col' },
        React.createElement("div", { className: 'flex flex-1 flex-col overflow-y-auto basis-0' },
            React.createElement("div", { className: 'w-full sm:w-1/3 p-4' },
                props.Sale.status === 0 && React.createElement("div", { className: 'text-5xl text-red-600 mb-2' }, "CANCELADO"),
                React.createElement("div", { className: 'text-base' }, "Folio"),
                React.createElement("div", { className: 'text-3xl' }, props.Sale.folio),
                React.createElement("div", { className: 'text-base mt-2' }, "Fecha de emisi\u00F3n"),
                React.createElement("div", { className: 'font-medium' }, dateFormat(props.Sale.clientCreatedAt)),
                React.createElement("div", { className: 'text-base mt-2' }, "Cliente"),
                React.createElement("div", { className: 'font-medium' }, "".concat(props.Sale.Customer.firstName, " ").concat(props.Sale.Customer.lastName)),
                ((_b = (_a = props.Sale) === null || _a === void 0 ? void 0 : _a.Customer) === null || _b === void 0 ? void 0 : _b.phone) && React.createElement("div", null, props.Sale.Customer.phone),
                ((_d = (_c = props.Sale) === null || _c === void 0 ? void 0 : _c.Calendar) === null || _d === void 0 ? void 0 : _d.address) && (React.createElement(React.Fragment, null,
                    React.createElement("div", { className: 'text-base mt-2' }, "Domicilio para entrega y recolecci\u00F3n"),
                    React.createElement("div", { className: 'rounded p-2 border border-solid' },
                        React.createElement("div", { className: 'font-medium' }, props.Sale.Calendar.address)))),
                ((_e = props.Sale) === null || _e === void 0 ? void 0 : _e.Calendar) && (React.createElement(React.Fragment, null,
                    React.createElement("div", { className: 'text-base mt-2' }, "Fechas de entrega y recolecci\u00F3n"),
                    React.createElement("div", { className: 'rounded p-2 border border-solid mt-1' },
                        React.createElement("div", { className: 'flex justify-between items-center' },
                            React.createElement("div", { className: 'text-base' }, "Fecha de entrega"),
                            React.createElement(OpenEditCalendarButtonComponent, { calendarId: props.Sale.Calendar.id })),
                        React.createElement("div", { className: 'font-medium' }, dateFormat((_g = (_f = props.Sale) === null || _f === void 0 ? void 0 : _f.Calendar) === null || _g === void 0 ? void 0 : _g.start)),
                        React.createElement("div", { className: 'text-base mt-2' }, "Fecha de recolecci\u00F3n"),
                        React.createElement("div", { className: 'font-medium' }, dateFormat((_j = (_h = props.Sale) === null || _h === void 0 ? void 0 : _h.Calendar) === null || _j === void 0 ? void 0 : _j.end))))),
                React.createElement("div", { className: 'mt-2' }),
                React.createElement(CommentaryRentFieldComponent, { noUpdate: props.Sale.status === 0, value: props.Sale.commentary }),
                React.createElement("div", { className: 'mt-2 sm:mt-4' }),
                React.createElement("div", { className: 'flex flex-col sm:flex-row' },
                    React.createElement("div", { className: 'flex flex-1' },
                        React.createElement(PrintSaleButtonComponent, { label: 'Imprimir comprobante', type: props.Sale.invoiceId ? 'invoice' : 'sale', saleId: props.Sale.Invoice ? props.Sale.Invoice.uuid : props.Sale.id })),
                    React.createElement("div", { className: 'flex flex-1 mt-2 sm:mt-0 pl-0 sm:pl-2' },
                        React.createElement(SendEmailSaleButtonComponent, { label: 'Enviar por email', saleId: props.Sale.id }))),
                props.createXMLProductsAllowed && React.createElement(CreateXMLProductsButtonComponent, null))),
        React.createElement(FooterDialogComponent, null,
            React.createElement("div", null,
                React.createElement(PrintOptionFooterConfirmDialogSaleV2Component, null)))));
};
export default BodyGeneralRentComponent;
