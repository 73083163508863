function sumTimes(startTime, timeToAdd, settings) {
    var _a = startTime.split(':').map(Number), startHours = _a[0], startMinutes = _a[1], startSeconds = _a[2];
    var _b = timeToAdd.split(':').map(Number), addHours = _b[0], addMinutes = _b[1], addSeconds = _b[2];
    var newSeconds = (startSeconds + addSeconds) % 60;
    var carryMinutes = Math.floor((startSeconds + addSeconds) / 60);
    var newMinutes = (startMinutes + addMinutes + carryMinutes) % 60;
    var carryHours = Math.floor((startMinutes + addMinutes + carryMinutes) / 60);
    var newHours = startHours + addHours + carryHours;
    var formattedTime = "".concat(newHours.toString().padStart(2, '0'), ":").concat(newMinutes
        .toString()
        .padStart((settings === null || settings === void 0 ? void 0 : settings.padStart) || 2, '0'), ":").concat(newSeconds.toString().padStart(2, '0'));
    return formattedTime;
}
export default sumTimes;
