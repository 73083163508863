var getDefaultCalendarValues = function (values) { return ({
    title: (values === null || values === void 0 ? void 0 : values.title) ? values.title : '',
    customer: (values === null || values === void 0 ? void 0 : values.customer) ? values.customer : null,
    start: (values === null || values === void 0 ? void 0 : values.start) ? values.start : new Date(),
    startHour: (values === null || values === void 0 ? void 0 : values.startHour) ? values.startHour : undefined,
    end: (values === null || values === void 0 ? void 0 : values.end) ? values.end : new Date(),
    endHour: (values === null || values === void 0 ? void 0 : values.endHour) ? values.endHour : undefined,
    reminder: (values === null || values === void 0 ? void 0 : values.reminder) ? values.reminder : null,
    reminderHour: (values === null || values === void 0 ? void 0 : values.reminderHour) ? values.reminderHour : undefined,
    address: (values === null || values === void 0 ? void 0 : values.address) ? values.address : '',
    diagnosis: (values === null || values === void 0 ? void 0 : values.diagnosis) ? values.diagnosis : '',
    description: (values === null || values === void 0 ? void 0 : values.description) ? values.description : '',
    permissions: (values === null || values === void 0 ? void 0 : values.permissions) ? values.permissions : 1,
    tagId: (values === null || values === void 0 ? void 0 : values.tagId) ? values.tagId : null,
    crmId: (values === null || values === void 0 ? void 0 : values.crmId) ? values.crmId : null,
    adviserId: (values === null || values === void 0 ? void 0 : values.adviserId) ? values.adviserId : null,
    sessions: (values === null || values === void 0 ? void 0 : values.sessions) ? values.sessions : '1',
}); };
export default getDefaultCalendarValues;
