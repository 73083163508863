import React, { useEffect, useState } from 'react';
import BlockOne from './BlockOne';
import BlockTwo from './BlockTwo';
import BlockThree from './BlockThree';
import BlockFour from './BlockFour';
import useGetCompanySettings from '../../../../hooks/useGetCompanySettings';
import LoadingProgress from '../../../../presentationals/LoadingProgress';
var ItemsMenuDashboardComponent = function () {
    var _a = useGetCompanySettings(), dataSettings = _a.data, loadingSettings = _a.loading;
    var _b = useState(2), posModule = _b[0], setPosModule = _b[1];
    useEffect(function () {
        if (dataSettings && dataSettings.company)
            setPosModule(dataSettings.company.posModule || 2);
    }, [dataSettings]);
    if (loadingSettings)
        return React.createElement(LoadingProgress, null);
    return (React.createElement("div", { className: 'grid grid-cols-1 md:grid-cols-4 gap-4' },
        React.createElement(BlockOne, { posModule: posModule }),
        React.createElement(BlockTwo, { posModule: posModule }),
        React.createElement(BlockThree, null),
        React.createElement(BlockFour, null)));
};
export default ItemsMenuDashboardComponent;
