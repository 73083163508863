import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { IconButton } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
var CreateCustomerSurverButtonComponent = function () {
    var params = useParams();
    var navigate = useNavigate();
    var handleClick = function () {
        navigate("/panel/survey?customerId=".concat(params.customerId, "&title=Entrevista"));
    };
    return (React.createElement(IconButton, { color: 'secondary', onClick: handleClick, "aria-label": 'new-survey' },
        React.createElement(AddIcon, null)));
};
export default CreateCustomerSurverButtonComponent;
