var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import EditIcon from '@mui/icons-material/Edit';
import { IconButton } from '@mui/material';
import convertToCurrencyLabel from '../../../../../helpers/convertToCurrencyLabel';
var CustomerAddressItemComponent = function (props) {
    var handleClick = function () {
        var index = props.index, onSelect = props.onSelect, customerAddress = __rest(props, ["index", "onSelect"]);
        props.onSelect(customerAddress);
    };
    var handleEdit = function (e) {
        e.stopPropagation();
        var index = props.index, onSelect = props.onSelect, customerAddress = __rest(props, ["index", "onSelect"]);
        props.onEdit(customerAddress);
    };
    return (React.createElement("div", { className: "flex flex-row items-center py-1 pl-2 gap-2 cursor-pointer hover:bg-slate-200 ".concat(props.index % 2 === 0 ? 'bg-slate-100' : ''), role: 'button', tabIndex: -1, onClick: handleClick },
        React.createElement("div", { className: 'flex flex-1 leading-none tracking-tighter' },
            props.addressLine1,
            " ",
            props.addressLine2),
        React.createElement("div", { className: 'whitespace-nowrap' },
            "$ ",
            convertToCurrencyLabel(props.rate)),
        React.createElement("div", null,
            React.createElement(IconButton, { className: 'p-1', onClick: handleEdit },
                React.createElement(EditIcon, null)))));
};
export default CustomerAddressItemComponent;
