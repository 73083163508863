var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useEffect, useMemo, useState } from 'react';
import { useReactiveVar } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { Button, Typography } from '@mui/material';
import { inputError } from '../../../../../../apollo/cache';
import GQL_PRODUCT from '../../../../../../apollo/gql/product';
import useApolloMutation from '../../../../../../hooks/useApolloMutation';
import successfulSnackbar from '../../../../../../helpers/successfulSnackbar';
import setInputErrorFormat from '../../../../../sale.v2/Action/helpers/setInputErrorFormat';
import getInputErrorFormat from '../../../../../sale.v2/Action/helpers/getInputErrorFormat';
import TextFieldComponent from '../../../../../../presentationals/FormComponents/TextField';
import getDefaultProductMeasurements from './helpers/getDefaultValues';
var ShippingSettingBodyEcommerceProductComponent = function (props) {
    var params = useParams();
    var errors = useReactiveVar(inputError);
    var defaultValues = useMemo(getDefaultProductMeasurements, []);
    var _a = useState(defaultValues), values = _a[0], setValues = _a[1];
    var _b = useApolloMutation(GQL_PRODUCT.UPDATE_PRODUCT_ECOMMERCE_MEASUREMENT), update = _b[0], loading = _b[1].loading;
    useEffect(function () {
        setValues(function (values) { return (__assign(__assign({}, values), props.ProductMeasumeremts)); });
    }, [props.ProductMeasumeremts]);
    var handleChange = function (name, value) {
        setValues(function (values) {
            var _a;
            return (__assign(__assign({}, values), (_a = {}, _a[name] = value, _a)));
        });
    };
    var handleClick = function () { return __awaiter(void 0, void 0, void 0, function () {
        var e_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    if (values.weight === '')
                        throw new Error(setInputErrorFormat('weight', 'Proporciona el peso del producto'));
                    if (values.length === '')
                        throw new Error(setInputErrorFormat('length', 'Proporciona la medida del producto'));
                    if (values.width === '')
                        throw new Error(setInputErrorFormat('width', 'Proporciona la medida del producto'));
                    if (values.height === '')
                        throw new Error(setInputErrorFormat('height', 'Proporciona la medida del producto'));
                    return [4 /*yield*/, update({
                            variables: {
                                id: params.productId,
                                weight: parseFloat(values.weight),
                                length: parseFloat(values.length),
                                width: parseFloat(values.width),
                                height: parseFloat(values.height),
                            },
                        })];
                case 1:
                    _a.sent();
                    successfulSnackbar('Las medidas se almacenaron con éxito');
                    return [3 /*break*/, 3];
                case 2:
                    e_1 = _a.sent();
                    getInputErrorFormat(e_1);
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    return (React.createElement("div", { className: 'flex flex-col mt-2' },
        React.createElement(Typography, { variant: 'subtitle2' }, "Peso y medidas del producto"),
        React.createElement("div", { className: 'grid grid-cols-1 md:grid-cols-3' },
            React.createElement("div", null,
                React.createElement(TextFieldComponent, { name: 'weight', label: 'Peso (kg)', value: values.weight, error: errors.weight, onChange: handleChange, type: 'number' }),
                React.createElement(TextFieldComponent, { name: 'length', label: 'Largo (cm)', value: values.length, error: errors.length, onChange: handleChange, type: 'number' }),
                React.createElement(TextFieldComponent, { name: 'width', label: 'Ancho (cm)', value: values.width, error: errors.width, onChange: handleChange, type: 'number' }),
                React.createElement(TextFieldComponent, { name: 'height', label: 'Alto (cm)', value: values.height, error: errors.height, onChange: handleChange, type: 'number' }))),
        React.createElement("div", null,
            React.createElement(Button, { disabled: loading, fullWidth: false, variant: 'contained', color: 'success', className: 'mt-2', onClick: handleClick }, "Almacenar nuevas medidas y peso"))));
};
export default ShippingSettingBodyEcommerceProductComponent;
