import React from 'react';
import EmployeeSalesReportComponent from '../containers/employeeSalesReport';
import EmployeeDetiledSaleReportComponent from '../containers/employeeDetailedSaleReport';
var getEmployeeSalesReportChildrenRoutes = function () {
    return [
        { index: true, element: React.createElement(EmployeeSalesReportComponent, null) },
        {
            path: ':employeeId',
            element: React.createElement(EmployeeDetiledSaleReportComponent, null),
        },
    ];
};
export default getEmployeeSalesReportChildrenRoutes;
