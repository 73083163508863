var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useEffect, useState } from 'react';
import ChangeCircleOutlinedIcon from '@mui/icons-material/ChangeCircleOutlined';
import ListItemPermissionEditRoleComponent from '../helpers/ListItem';
import SelectPermissionFieldComponent from '../helpers/SelectPermissionField';
export var defaultTransformationPermissionValues = {
    createTransformation: 0,
    getTransformation: 0,
    deleteTransformation: 0,
};
var defaultOptions = [
    { value: 0, label: 'Ninguna' },
    { value: 1, label: 'Sucursal predeterminada' },
    { value: 2, label: 'Todas las sucursales' },
];
var TransformationPermissionEditRoleComponent = function (props) {
    var _a = useState(defaultTransformationPermissionValues), values = _a[0], setValues = _a[1];
    useEffect(function () {
        setValues(function (values) { return (__assign(__assign({}, values), props.Permissions)); });
    }, [props.Permissions]);
    return (React.createElement(ListItemPermissionEditRoleComponent, { icon: React.createElement(ChangeCircleOutlinedIcon, null), name: 'Transformaci\u00F3n de productos' },
        React.createElement("div", { className: 'mx-4 py-3 grid grid-cols-1 md:grid-cols-3 gap-4' },
            React.createElement("div", { className: 'flex flex-col' },
                React.createElement(SelectPermissionFieldComponent, __assign({}, props, { name: 'createTransformation', label: 'Transformar productos', defaultValue: values.createTransformation, options: defaultOptions })),
                React.createElement(SelectPermissionFieldComponent, __assign({}, props, { name: 'getTransformation', label: 'Consultar historial de transformaciones', defaultValue: values.getTransformation, options: defaultOptions })),
                React.createElement(SelectPermissionFieldComponent, __assign({}, props, { name: 'deleteTransformation', label: 'Eliminar transformaciones', defaultValue: values.deleteTransformation, options: defaultOptions }))))));
};
export default TransformationPermissionEditRoleComponent;
