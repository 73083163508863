import React from 'react';
import { Outlet } from 'react-router-dom';
import GoBackButtonComponent from '../../../presentationals/GoBackButton';
import MenuCashClosingLayoutComponent from '../Menu';
var DetailCashClosingLayout = function () {
    return (React.createElement("div", { className: 'flex flex-1 flex-col' },
        React.createElement("div", { className: 'flex items-center px-4 py-0 border-0 border-b border-solid border-gray-200' },
            React.createElement("div", { className: 'mr-3' },
                React.createElement(GoBackButtonComponent, { relative: 'path' })),
            React.createElement("div", { className: 'text-lg text-gray-700 tracking-tight' }, "Detalles de corte de caja")),
        React.createElement(MenuCashClosingLayoutComponent, null),
        React.createElement("div", { className: 'flex flex-1 flex-col h-full w-full overflow-auto basis-0' },
            React.createElement(Outlet, null))));
};
export default DetailCashClosingLayout;
