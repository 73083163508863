import getSettings from '../../../../../../helpers/getSettings';
var getDefaultAddProductRentValues = function () {
    var settings = getSettings();
    var taxesOption = settings.defaultFormatSaleV2[1];
    return {
        qty: '',
        unitPrice: '',
        productId: null,
        taxesOption: taxesOption,
        commentary: '',
        outDate: undefined,
    };
};
export default getDefaultAddProductRentValues;
