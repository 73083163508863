var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { gql } from '@apollo/client';
import jsPDF from 'jspdf';
import { format, parseISO } from 'date-fns';
import locale from 'date-fns/locale/es';
import client from '../../apollo';
import sortByType from '../../helpers/sortByType';
// const customerVar = customerLabel({ textTransform: 'capitalize' });
var GET_ADJUST_INVENTORY_BY_ID = gql(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  query AdjustInventory($id: ID!) {\n    adjustInventory(id: $id) {\n      id\n      type\n      createdAt\n      folio\n      serial\n      Employee {\n        id\n        firstName\n        lastName\n      }\n      Branch {\n        id\n        name\n        addressLine1\n        addressLine2\n        city\n        state\n        zipCode\n        phone\n      }\n      AdjustInventoryProductV2s {\n        id\n        previousQuantity\n        currentQuantity\n        productId\n        commentary\n        Product {\n          id\n          name\n        }\n        ProductPresentation {\n          name\n        }\n      }\n    }\n  }\n"], ["\n  query AdjustInventory($id: ID!) {\n    adjustInventory(id: $id) {\n      id\n      type\n      createdAt\n      folio\n      serial\n      Employee {\n        id\n        firstName\n        lastName\n      }\n      Branch {\n        id\n        name\n        addressLine1\n        addressLine2\n        city\n        state\n        zipCode\n        phone\n      }\n      AdjustInventoryProductV2s {\n        id\n        previousQuantity\n        currentQuantity\n        productId\n        commentary\n        Product {\n          id\n          name\n        }\n        ProductPresentation {\n          name\n        }\n      }\n    }\n  }\n"])));
var getAdjustInventory = function (id) { return __awaiter(void 0, void 0, void 0, function () {
    var data, e_1;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                return [4 /*yield*/, client.mutate({
                        mutation: GET_ADJUST_INVENTORY_BY_ID,
                        variables: { id: id },
                    })];
            case 1:
                data = (_a.sent()).data;
                return [2 /*return*/, data.adjustInventory];
            case 2:
                e_1 = _a.sent();
                console.log(e_1);
                return [3 /*break*/, 3];
            case 3: return [2 /*return*/];
        }
    });
}); };
var adjustInventoryTicket = function (id) { return __awaiter(void 0, void 0, void 0, function () {
    var data, folio, serial, type, createdAt, Employee, Branch, AdjustInventoryProductV2s, products, doc, breakline, types, employee;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, getAdjustInventory(id)];
            case 1:
                data = _a.sent();
                folio = data.folio, serial = data.serial, type = data.type, createdAt = data.createdAt, Employee = data.Employee, Branch = data.Branch, AdjustInventoryProductV2s = data.AdjustInventoryProductV2s;
                products = sortByType(AdjustInventoryProductV2s, 'id', 'number', 'asc');
                doc = new jsPDF({
                    unit: 'mm',
                    format: [200, 600],
                });
                doc.setProperties({
                    title: "doc_".concat(folio, "-").concat(serial, "_").concat(format(parseISO(createdAt), 'dd_MM_yyyy', { locale: locale })),
                });
                breakline = 10;
                types = ['', 'SUSTITUCIÓN', 'ENTRADA', 'SALIDA'];
                doc.setFontSize(13);
                doc.setFont('times', 'bold');
                doc.text("".concat(types[type]), 35, breakline, { align: 'center' });
                doc.setFont('times', 'normal');
                breakline += 8;
                doc.text('SUCURSAL:', 5, breakline, { align: 'left' });
                breakline += 5;
                doc.setFont('times', 'bold');
                doc.text("".concat(Branch.name), 5, breakline, { align: 'left' });
                doc.setFont('times', 'normal');
                breakline += 8;
                doc.text("FOLIO: ".concat(folio, "-").concat(serial), 5, breakline, { align: 'left' });
                breakline += 5;
                employee = Employee ? "".concat(Employee.firstName, " ").concat(Employee.lastName) : '';
                doc.text('RESPONSABLE:', 5, breakline, { align: 'left' });
                breakline += 5;
                doc.setFont('times', 'bold');
                doc.text("".concat(employee), 5, breakline, { align: 'left' });
                doc.setFont('times', 'normal');
                breakline += 8;
                doc.text("".concat(format(parseISO(createdAt), 'dd/MM/yyyy HH:ss', { locale: locale })), 5, breakline, {
                    align: 'left',
                });
                breakline += 5;
                doc.line(5, breakline, 66, breakline);
                breakline += 4;
                doc.setFont('times', 'bold');
                doc.text('Cant.', 5, breakline, { align: 'left' });
                doc.text('Producto', 20, breakline, { align: 'left' });
                doc.setFont('times', 'normal');
                breakline += 1;
                doc.line(5, breakline, 66, breakline);
                breakline += 5;
                products.forEach(function (el) {
                    var splitName = doc.splitTextToSize("".concat(el.Product.name).concat(el.ProductPresentation ? " - ".concat(el.ProductPresentation.name) : ''), 50);
                    doc.text("".concat(Number.isInteger(el.currentQuantity) ? el.currentQuantity : parseFloat(el.currentQuantity).toFixed(2)), 5, breakline, { align: 'left' });
                    doc.text(splitName, 20, breakline, { align: 'left' });
                    breakline = breakline - 5 + (splitName.length > 1 ? splitName.length * 5.5 : 6);
                    doc.line(5, breakline, 66, breakline);
                    breakline += 5;
                });
                window.open(doc.output('bloburl'), '_blank');
                return [2 /*return*/];
        }
    });
}); };
export default adjustInventoryTicket;
var templateObject_1;
