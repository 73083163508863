import React, { useEffect, useState } from 'react';
import { useReactiveVar } from '@apollo/client';
import { InputAdornment } from '@mui/material';
import OutlinedFieldComponent from '../../../../../../../presentationals/FormComponents/OutlinedField';
import { inputError, productSaleV2 } from '../../../../../../../apollo/cache';
var QuantityPresentationFieldComponent = function (props) {
    var errors = useReactiveVar(inputError);
    var productSaleV2Var = useReactiveVar(productSaleV2);
    var _a = useState(''), unit = _a[0], setUnit = _a[1];
    useEffect(function () {
        if (productSaleV2Var)
            setUnit("".concat(productSaleV2Var.unitBase ? productSaleV2Var.unitBase.toLowerCase() : '', "s"));
    }, [productSaleV2Var]);
    if (parseFloat(productSaleV2Var.type) !== 1)
        return React.createElement("div", null);
    return (React.createElement(OutlinedFieldComponent, { name: 'quantityInBaseUnit', label: "\u00BFCuant\u00E1s ".concat(unit, " contiene esta presentaci\u00F3n?"), value: props.value, error: errors.quantityInBaseUnit, onChange: props.onChange, endAdornment: React.createElement(InputAdornment, { position: 'end' }, unit) }));
};
export default QuantityPresentationFieldComponent;
