import React, { useState, useEffect } from 'react';

import { useMutation, useQuery, useLazyQuery, useReactiveVar } from '@apollo/client';

import Grid from '@mui/material/Grid';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import Divider from '@mui/material/Divider';

import TextField from '../../presentationals/TextField';
import SelectField from '../../presentationals/SelectField';
import FormDialog from '../../presentationals/FormDialog';
import DateField from '../../presentationals/DateField';

import GQL_BRANCHES from '../branch/_gql';

import GQL from './_gql';
import Cart from './cart';
import PDF from './_pdf';
import useGetSession from '../../hooks/useGetSession';
import getInputErrorFormat from '../sale.v2/Action/helpers/getInputErrorFormat';
import setInputErrorFormat from '../sale.v2/Action/helpers/setInputErrorFormat';
import { inputError } from '../../apollo/cache';

const defaultValues = {
  Products: [],
  description: '',
  branchTransferId: '',
  branchId: '',
  print: true,
  date: new Date(),
};

const FormComponent = props => {
  const errors = useReactiveVar(inputError);
  const session = useGetSession();
  const [values, setValues] = useState({ ...defaultValues, branchId: '' });
  const [codeToClear, setCodeToClear] = useState(null);

  const { data: dataBranches } = useQuery(GQL_BRANCHES.GET, { variables: { limit: 0 } });

  const [getTransfer] = useLazyQuery(GQL.GET_BY_ID, {
    onCompleted: data => {
      if (data && data.transfer) {
        const Products = data.transfer.TransferProducts.map(el => {
          return {
            product: {
              label: `${el.Product.name.trim()} ${
                el.Product.sku ? ` | SKU ${el.Product.sku.trim()}` : ''
              } ${el.Product.barcode ? ` | C.B. ${el.Product.barcode.trim()}` : ''}`.trim(),
              value: el.Product.id,
            },
            unitPrice: el.Product.cost,
            productPresentation: {
              label: el.ProductPresentation.name,
              value: el.ProductPresentation.id,
            },
            quantity: el.quantity,
            total: el.total,
            subtotal: el.subtotal,
            productPriceId: el.productPriceId,
            cost: el.cost,
            iva: el.Product.iva ? el.Product.iva : 0,
            ieps: el.Product.ieps ? el.Product.ieps : 0,
            inventory: 0,
          };
        });
        setValues({
          Products,
          description: data.transfer.description,
          branchId: data.transfer.Branch.id,
          branchTransferId: data.transfer.BranchTransfer.id,
          print: true,
          date: data.transfer.date,
        });
      }
    },
  });

  const [createTransfer, { loading }] = useMutation(GQL.CREATE, {
    update(cache, { data: response }) {
      try {
        const dataResponse = response.createTransferV3;
        const oldQuery = cache.readQuery({ query: GQL.GET });
        cache.writeQuery({
          query: GQL.GET,
          data: {
            transfers: {
              __typename: 'Transfers',
              count: oldQuery.transfers.count + 1,
              rows: [dataResponse, ...oldQuery.transfers.rows],
            },
          },
        });
      } catch (e) {
        console.log(e);
      }
    },
  });

  const [editTransfer, { loading: loadingEdit }] = useMutation(GQL.EDIT);

  useEffect(() => {
    if (session?.Company && session?.Company?.Branch.id && dataBranches)
      setValues(values => ({ ...values, branchId: session?.Company?.Branch.id }));
  }, [session, dataBranches]);

  useEffect(() => {
    props.id && getTransfer({ variables: { id: props.id } });
  }, [props.id]);

  const handleClose = () => {
    setValues(defaultValues);
    setCodeToClear(null);
    props.handleClose();
  };

  const handleAction = async () => {
    try {
      if (!values.branchTransferId)
        throw new Error(setInputErrorFormat('branchTransferId', 'Selecciona una sucursal.'));

      const variables = {
        branchId: values.branchId,
        branchTransferId: values.branchTransferId,
        description: values.description,
        date: values.date,
        Products: values.Products.map(el => ({
          quantity: el.quantity,
          productPriceId: el.productPriceId,
          productId: el.product ? el.product.value : null,
          productPresentationId: el.productPresentation ? el.productPresentation.value : null,
        })),
      };

      let id;
      if (props.id) {
        const { data } = await editTransfer({ variables: { ...variables, id: props.id } });
        id = data.editTransfer.id;
      } else {
        const { data } = await createTransfer({ variables });
        id = data.createTransferV3.id;
      }
      if (values.print) PDF(id);
      setValues(defaultValues);
      setCodeToClear(null);
      inputError({});
      props.handleClose();
    } catch (e) {
      getInputErrorFormat(e);
    }
  };

  const getCart = data => {
    setValues(values => ({ ...values, Products: data }));
  };

  const handleChange = e => {
    const { name, value } = e.target;
    setValues(values => ({ ...values, [name]: value }));
  };

  const handleDateChange = (name, value) => {
    setValues(values => ({ ...values, [name]: value }));
  };

  const handleChangeSwitch = e => {
    setValues(values => ({ ...values, [e.target.name]: e.target.checked }));
  };

  return (
    <FormDialog
      isOpen={props.isOpen}
      isLoading={loading || loadingEdit}
      title='Traspaso de Productos'
      disableEscape
      // width='md'
      fullScreen
      handleClose={handleClose}
      handleAction={handleAction}
      extraMessage={
        <FormControlLabel
          control={<Switch checked={values.print} onChange={handleChangeSwitch} name='print' />}
          label='Imprimir al Guardar'
        />
      }
    >
      <Grid container justifyContent='flex-end' spacing={1}>
        <Grid item xs={12} md={3}>
          <SelectField
            noFormat
            name='branchId'
            label='Suc. Origen'
            variant='outlined'
            size='small'
            onChange={handleChange}
            value={values.branchId}
            error={errors.branchId}
            disabled={session && [41, 64].includes(parseInt(session?.Company?.id))}
            options={
              dataBranches
                ? dataBranches.branches.rows
                    .map(el => {
                      return {
                        value: el.id,
                        label: el.name,
                      };
                    })
                    .filter(tmp => tmp)
                : []
            }
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <SelectField
            noFormat
            name='branchTransferId'
            label='Suc. Destino'
            variant='outlined'
            size='small'
            onChange={handleChange}
            value={values.branchTransferId}
            error={errors.branchTransferId}
            options={
              dataBranches
                ? dataBranches.branches.rows.map(el => ({
                    value: el.id,
                    label: el.name,
                  }))
                : []
            }
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <DateField
            variant='outlined'
            name='date'
            onChange={handleDateChange}
            size='small'
            value={values.date}
            error={errors.date}
            noFormat
          />
        </Grid>
      </Grid>
      <br />
      <Divider />
      <br />
      <Cart
        getCart={getCart}
        codeToClear={codeToClear}
        id={props.id}
        products={values.Products}
        branchId={values.branchId}
      />
      <br />
      <TextField
        name='description'
        label='Comentarios'
        variant='outlined'
        onChange={handleChange}
        value={values.description}
        error={errors.description}
        multiline
        noFormat
      />
    </FormDialog>
  );
};

export default FormComponent;
