import React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import MuiDialogTitle from '@mui/material/DialogTitle';
import MuiDialogContent from '@mui/material/DialogContent';
import MuiDialogActions from '@mui/material/DialogActions';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import IconButton from '../IconButton';

const DialogTitle = props => {
  const handleClick = () => {
    console.log('Hey');
  };
  return (
    <MuiDialogTitle className='p-0 m-0 text-ellipsis border-0 border-b border-solid border-[#f5f5f5]'>
      <Grid container alignItems='center'>
        <Grid
          item
          className='flex items-center'
          md={(!props.noActions && props.rightContent) || props.noActions ? 6 : 12}
          xs={10}
        >
          <Typography className='m-2' variant='h6'>
            {props.children}
          </Typography>
          <IconButton
            color='primary'
            icon={<HelpOutlineIcon fontSize='small' />}
            size='small'
            action={handleClick}
          />
        </Grid>
        {!props.noActions && props.rightContent && (
          <Grid item md={6} className='pr-2'>
            {props.rightContent}
          </Grid>
        )}
        {props.noActions && (
          <Grid item md={6} xs={2} className='flex items-center justify-end pr-3'>
            <IconButton
              label='Cerrar'
              icon={<CloseIcon fontSize='small' />}
              size='small'
              action={props.handleClose}
            />
          </Grid>
        )}
      </Grid>
    </MuiDialogTitle>
  );
};

const DialogContent = props => {
  return <MuiDialogContent className='m-0 p-3 bg-white'>{props.children}</MuiDialogContent>;
};

const DialogActions = props => (
  <MuiDialogActions className='border-0 border-t border-solid border-[#f5f5f5] p-1'>
    {props.children}
  </MuiDialogActions>
);

export { DialogTitle, DialogContent, DialogActions };
