import React, { useEffect, useState } from 'react';
var ProductSubtitleLabelItemCartCreatePurchaseOrderComponent = function (props) {
    var _a = useState(''), label = _a[0], setLabel = _a[1];
    useEffect(function () {
        if (props.sku && props.barcode)
            setLabel("SKU: ".concat(props.sku, " | C\u00F3digo de barras: ").concat(props.barcode));
        else if (props.sku)
            setLabel("SKU: ".concat(props.sku));
        else if (props.barcode)
            setLabel("C\u00F3digo de barras: ".concat(props.barcode));
    }, [props]);
    return React.createElement("div", { className: 'text-xs text-gray-500' }, label);
};
export default ProductSubtitleLabelItemCartCreatePurchaseOrderComponent;
