import React, { useRef } from 'react';
import MenuCountryCodeFieldComponent from './Menu';
import LabelCountryCodeFieldComponent from './Label';
var CountryCodeFieldComponent = function (props) {
    var menuCountryCodeFieldRef = useRef(null);
    var handleClick = function (event) {
        var _a;
        if (props.disabled)
            return;
        (_a = menuCountryCodeFieldRef.current) === null || _a === void 0 ? void 0 : _a.open(event.currentTarget);
    };
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { role: 'button', tabIndex: -1, onClick: handleClick, className: 'flex flex-col justify-center mr-2 cursor-pointer' },
            React.createElement("div", { className: 'text-xs text-gray-400 leading-none tracking-tighter' }, "Prefijo:"),
            React.createElement(LabelCountryCodeFieldComponent, { name: props.name })),
        React.createElement(MenuCountryCodeFieldComponent, { name: props.name, ref: menuCountryCodeFieldRef })));
};
export default CountryCodeFieldComponent;
