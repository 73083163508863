import React from 'react';

import Container from '../../presentationals/Module.v2/Container';

import Filter from './_filter';
import GQL from './_gql';

const Rows = [
  { label: 'Fecha', key: 'createdAt', sort: 'text', format: 'dateTime' },
  { label: 'Folio', key: 'PurchaseOrder folio && PurchaseOrder serial', strcat: '-' },
  { label: 'Factura', key: 'PurchaseOrder subFolio', strcat: '-' },
  { label: 'Proveedor', key: 'PurchaseOrder Provider name', strcat: '-' },
  { label: 'SKU', key: 'Product sku', strcat: '-' },
  { label: 'COD', key: 'Product code', strcat: '-', sort: 'text' },
  { label: 'Producto', key: 'Product name', strcat: '-', sort: 'text' },
  { label: 'Clasificación', key: 'Product Classification name', strcat: '-', sort: 'text' },
  { label: 'Cant.', key: 'quantity', strcat: '-', sort: 'text', align: 'center' },
  {
    label: 'P.Unitario',
    key: 'unitPrice',
    strcat: '-',
    align: 'center',
    sort: 'text',
    format: 'currency',
  },
  { label: 'Subtotal', key: 'subtotal', strcat: '-', format: 'currency' },
  { label: 'Descuento', key: 'discount', strcat: '-', format: 'currency' },
  { label: 'IVA', key: 'iva', strcat: '-', format: 'currency' },
  { label: 'Importe', key: 'total', sort: 'text', format: 'currency', align: 'right' },
];
const title = 'Compras por folio detallada';
const operation = ['purchaseOrderProducts', 'purchaseOrderProducts'];

const Component = () => {
  return (
    <>
      <Container title={title} operation={operation} Rows={Rows} get={GQL.GET} filter noDelete />
      <Filter />
    </>
  );
};

export default Component;
