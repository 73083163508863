import React, { useEffect } from 'react';
import { useReactiveVar } from '@apollo/client';
import ImageComponent from './Item';
import UploadButtonImageProductComponent from './UploadButton';
import useApolloLazyQuery from '../../../../hooks/useApolloLazyQuery';
import GQL_CALENDAR from '../../../../apollo/gql/calendar';
import DeleteButtonImageProductComponent from './DeleteButton';
import LoadingProgress from '../../../../presentationals/LoadingProgress';
import { isEditCalendarFormOpen } from '../../../../apollo/cache';
var ImageCalendarComponent = function () {
    var isEditCalendarFormOpenVar = useReactiveVar(isEditCalendarFormOpen);
    var _a = useApolloLazyQuery(GQL_CALENDAR.GET_CALENDAR_BY_ID), getEvent = _a[0], _b = _a[1], loading = _b.loading, data = _b.data;
    useEffect(function () {
        if (isEditCalendarFormOpenVar)
            getEvent({ variables: { id: isEditCalendarFormOpenVar } });
    }, [isEditCalendarFormOpenVar]);
    if (loading || !data)
        return React.createElement(LoadingProgress, null);
    return (React.createElement("div", { className: 'flex flex-1 px-4 py-3 flex-col ' },
        React.createElement("div", { className: 'text-base font-medium mb-1 flex' }, "Im\u00E1genes del evento"),
        React.createElement("div", { className: 'text-sm sm:text-base text-gray-600 mb-2' }, "Personaliza tu evento agregando im\u00E1genes. Puedes subir fotos que representen la tem\u00E1tica, los participantes, o cualquier detalle relevante del evento."),
        React.createElement("div", null,
            React.createElement(UploadButtonImageProductComponent, null)),
        React.createElement("div", { className: 'mt-3 flex-1 flex flex-col sm:grid sm:grid-cols-6 sm:gap-4 overflow-auto' }, (data === null || data === void 0 ? void 0 : data.Files) &&
            data.Files.map(function (el) { return (React.createElement("div", { key: el.id, className: 'flex flex-col' },
                React.createElement(ImageComponent, { width: 'full', src: el.id.toString(), alt: "calendar-".concat(el.id) }),
                React.createElement(DeleteButtonImageProductComponent, { fileId: el.id }))); }))));
};
export default ImageCalendarComponent;
