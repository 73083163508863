import React, { useEffect, useState } from 'react';

import { useReactiveVar } from '@apollo/client';

import { Typography } from '@mui/material';

import { discountTypeSaleV2, form } from '../../../../apollo/cache';
import { currencyFormat } from '../../../../helpers/general';

const TotalChooseProductSaleV2Component = () => {
  const formVar = useReactiveVar(form);
  const discountTypeSaleV2Var = useReactiveVar(discountTypeSaleV2);
  const [total, setTotal] = useState(0);
  useEffect(() => {
    if (Object.keys(formVar).length > 0) {
      const qty = !Number.isNaN(parseFloat(formVar.qty)) ? parseFloat(formVar.qty) : 0;
      const discount = !Number.isNaN(parseFloat(formVar.discount))
        ? parseFloat(formVar.discount)
        : 0;
      const unitPrice = !Number.isNaN(parseFloat(formVar.unitPrice))
        ? parseFloat(formVar.unitPrice)
        : 0;

      const subtotal = qty * unitPrice;

      const tmpDiscount = discountTypeSaleV2Var === 1 ? (discount / 100) * subtotal : discount;

      const total = subtotal - tmpDiscount;

      setTotal(currencyFormat(total));
    } else {
      setTotal(0);
    }
  }, [formVar, discountTypeSaleV2Var]);
  return (
    <>
      <Typography variant='subtitle1'>Importe:</Typography>
      <Typography variant='h4'>{`$${total}`}</Typography>
    </>
  );
};

export default TotalChooseProductSaleV2Component;
