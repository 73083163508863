import React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import OpenAddProductPresentationLimitIntentorieDialogButton from './OpenAddProductPresentationLimitIntentorieDialogButton';
import NoProductLimitInventoryAlertComponent from './NoProductLimitInventoryAlert';
import ItemTableLimitInventoryProductPresentationComponent from './Item';
var BodyLimitInventoryProductPresentationComponent = function (props) {
    return (React.createElement("div", { className: 'p-3 flex flex-1 flex-col' },
        React.createElement("div", { className: 'flex items-center' },
            React.createElement("div", { className: 'text-base font-medium mb-1 flex flex-1' }, "L\u00EDmite de inventario por presentaci\u00F3n del producto"),
            React.createElement(OpenAddProductPresentationLimitIntentorieDialogButton, null)),
        React.createElement("div", { className: 'text-sm sm:text-base text-gray-600' }, "Personaliza los m\u00E1ximos y m\u00EDnimos de productos que deben existir en tu inventario. Con esto, recibir\u00E1s notificaciones que te ayudar\u00E1n a tener el control total de la existencia de tus productos."),
        props.ProductPresentationLimitInventories.length === 0 ? (React.createElement(NoProductLimitInventoryAlertComponent, null)) : (React.createElement("div", { className: 'pt-4 flex flex-1 flex-col basis-0 overflow-auto w-full sm:w-1/2' },
            React.createElement(TableContainer, { className: 'rounded flex border border-solid border-[#d8d8d8]' },
                React.createElement(Table, { stickyHeader: true, size: 'small' },
                    React.createElement(TableHead, null,
                        React.createElement(TableRow, { className: 'bg-[#f6f6f6]' },
                            React.createElement(TableCell, null, "M\u00EDnimo"),
                            React.createElement(TableCell, null, "M\u00E1ximo"),
                            React.createElement(TableCell, null, "Sucursal"))),
                    React.createElement(TableBody, null, props.ProductPresentationLimitInventories.map(function (el, i) { return (React.createElement(ItemTableLimitInventoryProductPresentationComponent, { key: el.id, index: i, ProductPresentationLimitInventory: el })); }))))))));
};
export default BodyLimitInventoryProductPresentationComponent;
