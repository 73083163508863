import React, { useState, useEffect } from 'react';

import { useReactiveVar } from '@apollo/client';

import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';

import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';

import { currencyFormat } from '../../../../../helpers';
import IconButton from '../../../../../presentationals/IconButton';

import { item, step, handleContinue } from '../../../../../apollo/cache';

import Totals from './Totals';
import ConfirmDialog from './confirm.dialog';

const BodyTableComponent = props => {
  const itemVar = useReactiveVar(item);
  const stepVar = useReactiveVar(step);
  const handleContinueVar = useReactiveVar(handleContinue);

  const [rows, setRows] = useState([]);
  const [isOpenConfirm, setIsOpenConfirm] = useState([false, null, []]);

  useEffect(() => {
    if (Object.keys(itemVar).length > 0) setRows(rows => [...rows, itemVar]);
  }, [itemVar]);

  useEffect(() => {
    if (handleContinueVar && stepVar === 1) {
      const productsTmp = rows.map(el => ({
        id: el.id,
        quantity: parseFloat(el.quantity),
      }));
      setIsOpenConfirm([true, props.id, productsTmp]);
      handleContinue(false);
    }
  }, [handleContinueVar, stepVar]);

  const handleDelete = async (e, i) => {
    const tmp = rows.filter((el, ii) => i !== ii);
    setRows(tmp);
  };

  const handleCloseConfirm = () => setIsOpenConfirm([false, null, []]);

  return (
    <TableBody>
      {rows.map((el, i) => (
        <TableRow key={i}>
          <TableCell padding='none'>
            <div className='flex'>
              <IconButton
                label='Eliminar'
                size='small'
                action={handleDelete}
                params={i}
                icon={<DeleteOutlineIcon fontSize='small' />}
              />
            </div>
          </TableCell>
          <TableCell>{el.product}</TableCell>
          <TableCell>{el.presentation}</TableCell>
          <TableCell align='center'>{currencyFormat(el.quantity)}</TableCell>
          <TableCell align='center'>{currencyFormat(el.receivedQuantity)}</TableCell>
          <TableCell align='right'>{`$${currencyFormat(el.discount)}`}</TableCell>
          <TableCell align='right'>{`$${currencyFormat(el.unitPrice)}`}</TableCell>
          <TableCell align='right'>{`$${currencyFormat(el.total)}`}</TableCell>
        </TableRow>
      ))}
      <Totals />
      <ConfirmDialog
        isOpen={isOpenConfirm[0]}
        products={isOpenConfirm[2]}
        id={isOpenConfirm[1]}
        handleClose={handleCloseConfirm}
        handleCloseAll={props.handleCloseAll}
      />
    </TableBody>
  );
};

export default BodyTableComponent;
