import { objectArraySort } from '../../../helpers';

const getProductListFormatted = data => {
  const tmpProduct = data.map(el => ({
    id: el.id,
    name: el.name,
    barcode: el.barcode,
    sku: el.sku,
    price: el.price,
  }));
  return objectArraySort(tmpProduct, 'name');
};

export default getProductListFormatted;
