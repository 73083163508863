import React from 'react';
import { useReactiveVar } from '@apollo/client';
import { Button } from '@mui/material';
import TextFieldComponent from '../../../../TextField';
import { inputError } from '../../../../../../apollo/cache';
import SelectFieldComponent from '../../../../SelectField';
import { searchProductsByOptions } from '../../../../SearchProductBySelectField';
var FormDialogProductFieldComponent = function (props) {
    var errors = useReactiveVar(inputError);
    var handleKeyDown = function (e) {
        if (e.key === 'Enter') {
            e.preventDefault();
            props.onClick();
        }
    };
    return (React.createElement("div", { className: 'flex items-center' },
        props.searchBy && (React.createElement("div", { className: 'flex mr-1' },
            React.createElement(SelectFieldComponent, { name: 'searchBy', value: props.searchBy, defaultLabel: 'B\u00FAsqueda por', options: searchProductsByOptions, onChange: props.onChange }))),
        React.createElement("div", { className: 'flex flex-1 pr-2' },
            React.createElement(TextFieldComponent, { name: 'search', error: errors.search, defaultLabel: props.label || 'Búsqueda del producto', value: props.value, onChange: props.onChange, onKeyDown: handleKeyDown })),
        React.createElement("div", { className: 'mb-1' },
            React.createElement(Button, { disabled: props.isLoading, color: 'secondary', variant: 'contained', onClick: props.onClick }, props.buttonLabel || 'Buscar'))));
};
export default FormDialogProductFieldComponent;
