import React from 'react';
import Button from '../../../../../presentationals/Dialog/Footer/Button';
import getDefaultCompanyValues from '../../../helpers/getDefaultCompanyValues';
import { companyForm } from '../../../../../apollo/cache';
var ClearButtonFooterCreateCompanyComponent = function () {
    var handleClick = function () {
        var defaultCompanyValues = getDefaultCompanyValues();
        companyForm(defaultCompanyValues);
    };
    return (React.createElement(Button, { color: 'inherit', className: 'ml-1', onClick: handleClick }, "Limpiar"));
};
export default ClearButtonFooterCreateCompanyComponent;
