var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { useMutation } from '@apollo/client';
import getResponseWithData from '../containers/sale.v2/ConfirmDialog/Footer/CreateButton/helpers/getResponseWithData';
import GQL from '../containers/quotes/_gql';
import GQL_SALE from '../containers/sale.v2/_gql';
import GQL_RECEIVABLE from '../containers/receivable.v2/_gql';
import GQL_TRANSACTION from '../containers/transaction/_gql';
import getInputErrorFormat from '../containers/sale.v2/Action/helpers/getInputErrorFormat';
var useCloseQuotationMutation = function () {
    var _a = useMutation(GQL.SELL_QUOTE, {
        update: function (cache, _a) {
            var _b, _c;
            var data = _a.data;
            try {
                var response_1 = getResponseWithData(data);
                if (!response_1)
                    return;
                cache.modify({
                    id: cache.identify({ __typename: 'Quote', id: response_1[1].id }),
                    fields: {
                        status: function () {
                            return 2;
                        },
                    },
                });
                cache.modify({
                    fields: {
                        sales: function (existingRefs) {
                            var newRef = cache.writeFragment({
                                data: __assign({}, response_1[1].Sale),
                                fragment: GQL_SALE.FRAGMENT_NEW_SALE,
                            });
                            return __assign(__assign({}, existingRefs), { count: parseInt(existingRefs.count) + 1, rows: __spreadArray([newRef], existingRefs.rows, true) });
                        },
                    },
                });
                var AccountReceivableCache_1 = __assign(__assign({ __typename: 'AccountReceivable' }, response_1[1].Sale.AccountReceivable), { Customer: response_1[1].Sale.Customer, Sale: response_1[1].Sale, Charge: null, subtotal: response_1[1].Sale.subtotal, discount: response_1[1].Sale.discount, taxes: response_1[1].Sale.taxes, total: response_1[1].Sale.total, saleId: response_1[1].Sale.id, customerId: response_1[1].Sale.Customer.id, invoiceId: ((_b = response_1[1].Sale) === null || _b === void 0 ? void 0 : _b.Invoice) ? (_c = response_1[1].Sale) === null || _c === void 0 ? void 0 : _c.Invoice.id : null, Seller: response_1[1].Sale.Seller });
                response_1[1].Sale.Transactions.forEach(function (el) {
                    cache.modify({
                        fields: {
                            transactions: function (existingRefs) {
                                var newRef = cache.writeFragment({
                                    data: __assign(__assign({}, el), { Sale: response_1[1].Sale, AccountReceivable: AccountReceivableCache_1 }),
                                    fragment: GQL_TRANSACTION.FRAGMENT_NEW_TRANSACTION,
                                });
                                return __assign(__assign({}, existingRefs), { count: parseInt(existingRefs.count) + 1, rows: __spreadArray([newRef], existingRefs.rows, true) });
                            },
                        },
                    });
                });
                if (!response_1[1].Sale.paid)
                    cache.modify({
                        fields: {
                            accountReceivables: function (existingRefs) {
                                var newRef = cache.writeFragment({
                                    data: AccountReceivableCache_1,
                                    fragment: GQL_RECEIVABLE.FRAGMENT_NEW_ACCOUNT_RECEIVABLE,
                                });
                                return __assign(__assign({}, existingRefs), { count: parseInt(existingRefs.count) + 1, rows: __spreadArray([newRef], existingRefs.rows, true) });
                            },
                        },
                    });
            }
            catch (e) {
                getInputErrorFormat(e);
            }
        },
    }), f = _a[0], _b = _a[1], data = _b.data, loading = _b.loading, error = _b.error;
    return [f, { data: data, loading: loading, error: error }];
};
export default useCloseQuotationMutation;
