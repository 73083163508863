import React, { useEffect, useState } from 'react';
import { useController, useFormContext, useWatch } from 'react-hook-form';
import convertToCurrencyLabel from '../../../helpers/convertToCurrencyLabel';
var OnlineOrderTotalComponent = function () {
    var control = useFormContext().control;
    var _a = useState(0), total = _a[0], setTotal = _a[1];
    var type = useController({
        name: 'type',
        control: control,
    }).field.value;
    var shipping = useController({
        name: 'shipping',
        control: control,
    }).field.value;
    var Products = useWatch({
        name: 'Products',
        control: control,
    });
    useEffect(function () {
        var subtotal = Products.reduce(function (subtotal, el) { return el.qty * el.product.unitPrice + subtotal; }, 0);
        setTotal(subtotal);
    }, [type, Products]);
    return (React.createElement("div", { className: 'flex flex-col border-0 border-t border-solid border-slate-200 px-4 py-5 text-2xl bg-slate-50' },
        type === 3 && (React.createElement(React.Fragment, null,
            React.createElement("div", { className: 'flex flex-row justify-between' },
                React.createElement("div", null, "Subtotal"),
                React.createElement("div", null,
                    "$ ",
                    convertToCurrencyLabel(total))),
            React.createElement("div", { className: 'flex flex-row justify-between mb-2' },
                React.createElement("div", null, "Env\u00EDo"),
                React.createElement("div", null,
                    "$ ",
                    convertToCurrencyLabel(shipping))))),
        React.createElement("div", { className: 'flex flex-row justify-between font-semibold text-3xl' },
            React.createElement("div", null, "Total"),
            React.createElement("div", null,
                "$ ",
                convertToCurrencyLabel(total + shipping)))));
};
export default OnlineOrderTotalComponent;
