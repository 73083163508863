import React, { forwardRef, useImperativeHandle, useState } from 'react';
import Main from './Main';
import Products from './Products';
import DialogComponent from '../../../presentationals/Dialog';
var CreateAdjustInventoryDialogComponent = forwardRef(function (props, ref) {
    var _a = useState(0), step = _a[0], setStep = _a[1];
    var _b = useState(false), open = _b[0], setOpen = _b[1];
    var _c = useState(null), values = _c[0], setValues = _c[1];
    var handleClose = function () {
        setOpen(false);
    };
    var handleNextStep = function (values) {
        setValues(values);
        setStep(step + 1);
    };
    var handleReturnStep = function () {
        setStep(step - 1);
    };
    var handleOpen = function () {
        setOpen(true);
    };
    var handleCreated = function () {
        handleReturnStep();
        handleClose();
    };
    useImperativeHandle(ref, function () { return ({
        open: handleOpen,
    }); });
    return (React.createElement(DialogComponent, { open: open, onClose: handleClose, title: 'Crear ajuste de inventario', fullScreen: true },
        React.createElement("div", { className: 'flex flex-1' },
            step === 0 && React.createElement(Main, { onContinue: handleNextStep }),
            step === 1 && values && (React.createElement(Products, { onCreated: handleCreated, onReturn: handleReturnStep, MainValues: values })))));
});
export default CreateAdjustInventoryDialogComponent;
