import React, { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import DialogComponent from '../../../../../presentationals/Dialog';
import BodyEditRequisitionProductDialogComponent from './Body';
import FooterDialogComponent from '../../../../../presentationals/Dialog/Footer';
import DeleteRequisitionProductButtonComponent from './DeleteButton';
import EditRequisitionProductButtonComponent from './EditButton';
var EditRequisitionProductDialogComponent = forwardRef(function (props, ref) {
    var bodyEditRequisitionProductDialogRef = useRef(null);
    var _a = useState(null), open = _a[0], setOpen = _a[1];
    var handleClose = function () {
        setOpen(null);
    };
    var handleOpen = function (id) {
        setOpen(id);
    };
    useImperativeHandle(ref, function () { return ({
        open: handleOpen,
    }); });
    var handleGetValues = function () { var _a; return (_a = bodyEditRequisitionProductDialogRef.current) === null || _a === void 0 ? void 0 : _a.getValues(); };
    return (React.createElement(DialogComponent, { open: !!open, title: 'Editar producto de la requisici\u00F3n', onClose: handleClose, fullScreen: true },
        React.createElement("div", { className: 'flex flex-1 flex-col' },
            React.createElement(BodyEditRequisitionProductDialogComponent, { ref: bodyEditRequisitionProductDialogRef, id: open }),
            React.createElement(FooterDialogComponent, null,
                React.createElement(DeleteRequisitionProductButtonComponent, { productRequisitionId: open }),
                React.createElement(EditRequisitionProductButtonComponent, { getValues: handleGetValues, onCreated: handleClose, requisitionId: props.requisitionId, productRequisitionId: open })))));
});
export default EditRequisitionProductDialogComponent;
