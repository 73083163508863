var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { useReactiveVar } from '@apollo/client';
import { useEffect, useState } from 'react';
import { filter } from '../../../apollo/cache';
import GQL from '../_gql';
import useApolloQuery from '../../../hooks/useApolloQuery';
import getFormattedAddressBranch from '../../branch/helpers/getFormattedAddressBranch';
var useGetCustomers = function (props) {
    var variables = useReactiveVar(filter);
    var _a = useApolloQuery(GQL.GET, props.getByFilter ? { variables: variables } : {}), response = _a.data, loading = _a.loading, refetch = _a.refetch;
    var _b = useState([]), data = _b[0], setData = _b[1];
    useEffect(function () {
        if (props.getByFilter)
            refetch(__assign(__assign({}, variables), { like: variables.like ? variables.like : undefined, birthMonth: variables.birthMonth ? parseInt(variables.birthMonth) : undefined }));
    }, [variables, props.getByFilter]);
    useEffect(function () {
        if (response) {
            var Rows = response.rows;
            var data_1 = Rows.map(function (el) {
                var address = getFormattedAddressBranch(el);
                return {
                    id: el.id,
                    code: el.code || '-',
                    name: "".concat(el.firstName, " ").concat(el.lastName),
                    phone: el.phone || '-',
                    priceName: el.priceName || '-',
                    creditLimit: el.creditLimit ? el.creditLimit.toString() : '-',
                    creditDays: el.creditDays ? el.creditDays.toString() : '-',
                    address: address,
                };
            });
            setData(data_1);
        }
    }, [response]);
    return { data: data, loading: loading };
};
export default useGetCustomers;
