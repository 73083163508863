import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import WorkingDaysFieldComponent from '../../employee/Edit/WorkingHours/WorkingDaysField';
import FooterDialogComponent from '../../../presentationals/Dialog/Footer';
import EditCustomerCalendarButtonComponent from './EditButton';
import useGetSession from '../../../hooks/useGetSession';
import { workingHoursForm } from '../../../apollo/cache';
import GQL_CUSTOMER from '../../../apollo/gql/customer';
import WorkingCalendarFieldComponent from './WorkingCalendarField';
import useApolloQuery from '../../../hooks/useApolloQuery';
import ApplyCalendarByCustomerComponent from './ApplyByCustomerComponent';
import ApplyCalendarComponent from './ApplyGeneralComponent';
var CalendarCustomerComponent = function () {
    var _a;
    var params = useParams();
    var session = useGetSession();
    var customerLabel = ((_a = session === null || session === void 0 ? void 0 : session.Company) === null || _a === void 0 ? void 0 : _a.activity) === 3 ? 'pacientes' : 'clientes';
    var _b = useApolloQuery(GQL_CUSTOMER.GET_CUSTOMER_BY_ID, {
        variables: {
            id: params.customerId,
        },
    }), loading = _b.loading, data = _b.data;
    useEffect(function () {
        if (data) {
            var currentSchedule = data.schedule ? JSON.parse(data.schedule) : [];
            workingHoursForm(currentSchedule.filter(function (el) { return el.entry >= 0; }));
        }
    }, [data]);
    return (React.createElement("div", { className: 'flex flex-1 flex-col' },
        React.createElement("div", { className: 'px-4 py-3 flex-1 grid grid-cols-1 sm:grid-cols-3 basis-0 overflow-auto' },
            React.createElement("div", { className: 'flex flex-1 flex-col col-span-2' },
                React.createElement("div", { className: 'text-lg' }, "Configuraci\u00F3n de citas"),
                React.createElement("div", { className: 'text-sm text-gray-600' },
                    "Gestiona y configura citas recurrentes para ",
                    customerLabel,
                    ". Permite establecer horarios regulares y repetitivos para citas, facilitando la planificaci\u00F3n y organizaci\u00F3n del tiempo."),
                React.createElement("div", { className: 'mt-3' },
                    React.createElement(WorkingDaysFieldComponent, null)),
                React.createElement("div", { className: 'mt-3' },
                    React.createElement(WorkingCalendarFieldComponent, null)))),
        React.createElement(FooterDialogComponent, null,
            React.createElement("div", { className: 'flex' },
                React.createElement(ApplyCalendarByCustomerComponent, null),
                React.createElement(ApplyCalendarComponent, null)),
            React.createElement(EditCustomerCalendarButtonComponent, { disabled: loading }))));
};
export default CalendarCustomerComponent;
