var getSaleGlobalInvoiceTotals = function (data, checked) {
    return data.rows.reduce(function (totals, el) {
        return !checked || checked.includes(el.id)
            ? [
                totals[0] + el.subtotal,
                totals[1] + el.taxes,
                totals[2] + el.total,
                totals[3] + el.discount,
            ]
            : totals;
    }, [0, 0, 0, 0]);
};
export default getSaleGlobalInvoiceTotals;
