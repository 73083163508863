import React from 'react';

import Typography from '@mui/material/Typography';

import FormDialog from '../../presentationals/FormDialog';
import Button from '../../presentationals/Button';

import CONFIG from '../../config/config.json';

const InvoiceDialogComponent = props => {
  const handleClose = () => {
    props.handleClose();
  };
  const printPdf = () => {
    window.open(`${CONFIG.restServer.uri}/files/invoices/complements/${props.uuid}/pdf`);
  };
  return (
    <FormDialog isOpen={props.isOpen} handleClose={handleClose} noActions>
      <Typography variant='h5' gutterBottom>
        ¡El complemento de pago fue generado con éxito!
      </Typography>
      <Button label='Generar PDF' onClick={printPdf} />
    </FormDialog>
  );
};

export default InvoiceDialogComponent;
