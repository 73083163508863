var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useEffect, useMemo, useState } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import useApolloLazyQuery from '../../../../../hooks/useApolloLazyQuery';
import getInputErrorFormat from '../../../../../containers/sale.v2/Action/helpers/getInputErrorFormat';
import GQL_PRODUCT from '../../../../../apollo/gql/product';
import ItemTableCustomerGlobalInvoiceComponent from '../../../../../containers/globalInvoice/Sales/CustomerField/SearchCustomer/Table/Item';
import useProductFieldStore from '../../../../../store/productField';
var TableProductFieldPopoverComponent = function (props) {
    var control = useFormContext().control;
    var onChange = useController({
        name: props.name,
        control: control,
    }).field.onChange;
    var _a = useProductFieldStore(), value = _a.value, searchBy = _a.searchBy, setPopoverOpen = _a.setPopoverOpen, setValue = _a.setValue;
    var _b = useState([]), products = _b[0], setProducts = _b[1];
    var GET = useMemo(function () { return GQL_PRODUCT.CUSTOMIZED_GET(); }, []);
    var _c = useApolloLazyQuery(GET), getProducts = _c[0], loading = _c[1].loading;
    useEffect(function () {
        props.onLoading(loading);
    }, [loading]);
    var search = function (searchBy, value) { return __awaiter(void 0, void 0, void 0, function () {
        var data, e_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    if (value.trim() === '') {
                        setProducts([]);
                        return [2 /*return*/];
                    }
                    return [4 /*yield*/, getProducts({ variables: { name: [searchBy, value], limit: 0 } })];
                case 1:
                    data = (_a.sent()).data;
                    setProducts(data ? data === null || data === void 0 ? void 0 : data.rows.map(function (el) { return ({ id: el.id, name: el.name }); }) : []);
                    return [3 /*break*/, 3];
                case 2:
                    e_1 = _a.sent();
                    getInputErrorFormat(e_1);
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    useEffect(function () {
        search(searchBy[1], value);
    }, [value, searchBy]);
    var handleSelect = function (name, value) {
        onChange(value);
        setValue(name);
        setPopoverOpen(null);
    };
    return (React.createElement("div", { className: 'flex max-h-96 overflow-y-auto overflow-x-hidden flex-col' }, products.map(function (el, i) { return (React.createElement(ItemTableCustomerGlobalInvoiceComponent, { key: el.id, id: el.id, name: el.name, index: i + 1, onClick: handleSelect })); })));
};
export default TableProductFieldPopoverComponent;
