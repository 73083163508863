import React from 'react';
import GoBackButtonComponent from '../../../presentationals/GoBackButton';
import DateRangeFieldComponent from '../../../presentationals/FilterInputs/DateRangeField';
var DetailedReportLayoutComponent = function () {
    return (React.createElement("div", { className: 'flex sm:items-center flex-col sm:flex-row sm:px-4 py-0 border-0 border-b border-[#d6d6d6] border-solid' },
        React.createElement("div", { className: 'flex sm:flex-1 flex-row items-center px-2 sm:px-0' },
            React.createElement("div", { className: 'mr-3' },
                React.createElement(GoBackButtonComponent, null)),
            React.createElement("div", { className: 'text-lg text-gray-700 tracking-tight' }, "Informe de operaciones")),
        React.createElement("div", { className: 'flex flex-row  border-0 border-t sm:border-t-0 border-[#d6d6d6] border-solid px-2 sm:px-0' },
            React.createElement(DateRangeFieldComponent, null))));
};
export default DetailedReportLayoutComponent;
