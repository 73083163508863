import React, { useState } from 'react';

import SearchIcon from '@mui/icons-material/SearchOutlined';

import IconButton from '../../../IconButton';

import Dialog from './dialog';

const SearchButtonComponent = props => {
  const [isOpen, setIsOpen] = useState(false);
  const handleSearch = () => {
    setIsOpen(true);
  };
  const handleClose = () => {
    setIsOpen(false);
  };
  return (
    <>
      <div className='flex sm:hidden'>
        <IconButton label='Buscar' action={handleSearch} icon={<SearchIcon />} size='large' />
      </div>

      <Dialog
        handleClose={handleClose}
        isOpen={isOpen}
        name={props.altSearchButtonKey}
        title={props.title}
      />
    </>
  );
};

export default SearchButtonComponent;
