var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
var getNewWorkingDaysArray = function (day, currentArray) {
    var isInArray = currentArray.find(function (el) { return el.day === day; });
    return isInArray
        ? currentArray.filter(function (el) { return el.day !== day; })
        : __spreadArray(__spreadArray([], currentArray, true), [{ day: day, entry: 8, out: 18 }], false);
};
export default getNewWorkingDaysArray;
