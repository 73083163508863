var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { useEffect, useState } from 'react';
import { useReactiveVar } from '@apollo/client';
import useApolloLazyQuery from '../../../hooks/useApolloLazyQuery';
import GQL from '../../productInventory.report/_gql';
import { count, filter } from '../../../apollo/cache';
import useGetSessionRole from '../../../hooks/useGetSessionRole';
import getSession from '../../../helpers/getSession';
// import GQL_PRODUCT_INVENTORY from '../../../apollo/gql/productInventory';
var useGetProductInventory = function () {
    var filterVar = useReactiveVar(filter);
    var _a = useGetSessionRole(), Role = _a.data, isOwner = _a.isOwner;
    var _b = useState({ count: 0, rows: [] }), data = _b[0], setData = _b[1];
    var _c = useApolloLazyQuery(GQL.GET_V2), getData = _c[0], _d = _c[1], ProductInventory = _d.data, loading = _d.loading;
    useEffect(function () {
        getData({
            variables: __assign(__assign({}, filterVar), { limit: filterVar.limit || 25, offset: filterVar.offset || 0 }),
        });
    }, [filterVar]);
    useEffect(function () {
        if (ProductInventory && Role) {
            count(ProductInventory.count);
            if (isOwner || (Role.Permissions.Branch && Role.Permissions.Branch.getBranches === 2)) {
                setData(ProductInventory);
                return;
            }
            if (!Role.Permissions.Branch ||
                (Role.Permissions.Branch && !Role.Permissions.Branch.getBranches)) {
                setData({ count: 0, rows: [] });
                return;
            }
            if (Role.Permissions.Branch.getBranches === 1) {
                var session = getSession();
                if (!(session === null || session === void 0 ? void 0 : session.Company))
                    return;
                var branchId_1 = session.Company.Branch.id;
                var rows = ProductInventory.rows.map(function (el) { return (__assign(__assign({}, el), { Inventories: el.Inventories.filter(function (elCh) { return elCh.Branch.id === branchId_1; }) })); });
                setData(__assign(__assign({}, ProductInventory), { rows: rows }));
            }
        }
    }, [ProductInventory, Role]);
    return { data: data, loading: loading };
};
export default useGetProductInventory;
