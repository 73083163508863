import React, { useState, useEffect } from 'react';

import { useReactiveVar, useMutation } from '@apollo/client';

import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';

import { format } from 'date-fns';
import locale from 'date-fns/locale/es';

import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Typography from '@mui/material/Typography';

import {
  item,
  form,
  handleContinue,
  step,
  actionForm,
  purchaseOrderId,
  handleContinueConfiguration,
  isStoreLoading,
} from '../../../../../../apollo/cache';
import { currencyFormat } from '../../../../../../helpers';
import IconButton from '../../../../../../presentationals/IconButton';

import GQL from '../../../../../transaction/_gql';
import GQL_PAYMENT_METHOD from '../../../../../paymentMethod/_gql';
import Totals from './Totals';
import getInputErrorFormat from '../../../../../sale.v2/Action/helpers/getInputErrorFormat';
import payment80mm from '../../../../../../presentationals/PDF/payment.80mm';
import useBranchConfig from '../../../../../../hooks/useBranchConfig';

const BodyTableComponent = () => {
  const { data: branchConfig } = useBranchConfig();
  const itemVar = useReactiveVar(item);
  const handleContinueVar = useReactiveVar(handleContinue);
  const stepVar = useReactiveVar(step);
  const purchaseOrderIdVar = useReactiveVar(purchaseOrderId);

  const [rows, setRows] = useState([]);
  const [errors, setErrors] = useState({});
  const [total, setTotal] = useState(0);

  const [createTransactionAccount, { loading: loadingTransactionAccount }] = useMutation(
    GQL_PAYMENT_METHOD.CREATE_TRANSACTION_ACCOUNT,
    {
      update(cache, { data: response }) {
        cache.modify({
          fields: {
            transactionAccounts(cachedTransactionAccounts) {
              const newTransactionAccountRef = cache.writeFragment({
                data: { ...response.createTransactionAccount },
                fragment: GQL_PAYMENT_METHOD.FRAGMENT_CORE_TRANSACTION_ACCOUNT_FIELDS,
              });
              return {
                ...cachedTransactionAccounts,
                count: cachedTransactionAccounts.count + 1,
                rows: [...cachedTransactionAccounts.rows, newTransactionAccountRef],
              };
            },
          },
        });
      },
    },
  );

  const [createPaymentRelationSale, { loading: loadingCreate }] = useMutation(
    GQL.CREATE_PAYMENT_RELATED_SALE,
    {
      update(cache, { data: response }) {
        const payment = response.createPaymentRelationSale.Transactions.reduce(
          (total, el) => total + parseFloat(el.total),
          0,
        );

        response.createPaymentRelationSale.Transactions.forEach(el => {
          cache.modify({
            id: cache.identify({ __typename: 'AccountReceivable', id: el.AccountReceivable.id }),
            fields: {
              payment(cachedPayment) {
                return cachedPayment + parseFloat(el.total);
              },
              balance(cachedBalance) {
                return cachedBalance - parseFloat(el.total);
              },
            },
          });
          cache.modify({
            fields: {
              transactions(existingTransactionsRefs) {
                const newTransactionRef = cache.writeFragment({
                  data: { ...el },
                  fragment: GQL.FRAGMENT_NEW_TRANSACTION,
                });
                return {
                  ...existingTransactionsRefs,
                  count: existingTransactionsRefs.count + 1,
                  rows: [...existingTransactionsRefs.rows, newTransactionRef],
                };
              },
            },
          });
        });
        cache.modify({
          id: cache.identify({ __typename: 'Totals', id: '-1' }),
          fields: {
            qty(cachedQty) {
              return cachedQty + 1;
            },
            amount(cachedAmount) {
              return cachedAmount + payment;
            },
          },
        });
        cache.modify({
          id: cache.identify({
            __typename: 'Totals',
            id: response.createPaymentRelationSale.paymentMethodId,
          }),
          fields: {
            qty(cachedQty) {
              return cachedQty + 1;
            },
            amount(cachedAmount) {
              return cachedAmount + payment;
            },
          },
        });
      },
    },
  );

  useEffect(() => {
    isStoreLoading(loadingCreate || loadingTransactionAccount);
  }, [loadingTransactionAccount, loadingCreate]);

  const storePursache = async () => {
    try {
      if (rows.length === 0) {
        const e = new Error('Añade al menos un abono a la orden de compra.');
        e.name = 'table';
        throw e;
      }

      let transactionAccountId = form().transactionAccount ? form().transactionAccount.value : null;

      if (form().newTransactionAccount) {
        const responseTransactionAccount = await createTransactionAccount({
          variables: { name: form().newTransactionAccount },
        });
        transactionAccountId = responseTransactionAccount.data.createTransactionAccount.id;
      }

      const { data } = await createPaymentRelationSale({
        variables: {
          paymentMethodId: parseInt(form().paymentMethodId) !== -1 ? form().paymentMethodId : null,
          customerId: actionForm()?.data[0]?.customerId,
          date: form().date ? form().date : null,
          transactionAccountId,
          Transactions: rows.map(el => ({
            accountReceivableId: el.accountReceivableId,
            total: parseFloat(el.total),
            paymentMethodId:
              parseInt(form().paymentMethodId) !== -1 ? form().paymentMethodId : null,
            currency: form().currency,
            exchangeRate: form().currency === 'MXN' ? 1 : parseFloat(form().exchangeRate),
            transactionAccountId,
            date: form().date ? form().date : null,
          })),
        },
      });
      if (
        data?.createPaymentRelationSale?.Transactions &&
        data.createPaymentRelationSale.Transactions.length > 0 &&
        branchConfig?.printReceiptAfterPayment
      )
        payment80mm(data.createPaymentRelationSale.Transactions[0].AccountReceivable.id);

      form({});
      actionForm({ ...actionForm(), isOpen: false, action: '', id: null, data: null });
      step(0);
      setRows([]);
      handleContinueConfiguration({});
    } catch (e) {
      getInputErrorFormat(e);
    }
  };

  useEffect(() => {
    if (purchaseOrderIdVar) getPurchaseOrderId({ variables: { id: purchaseOrderIdVar } });
  }, [purchaseOrderIdVar]);

  useEffect(() => {
    if (handleContinueVar && stepVar === 1) {
      storePursache();
      handleContinue(false);
    }
  }, [handleContinueVar, stepVar]);

  useEffect(() => {
    if (Object.keys(itemVar).length > 0) {
      const isExist = rows.find(
        tmp => parseInt(tmp.accountReceivableId) === parseInt(itemVar.accountReceivableId),
      );
      if (isExist) {
        setErrors(errors => ({ ...errors, table: 'La cuenta ya se agregó a la tabla.' }));
        return;
      }
      setRows(rows => [...rows, itemVar]);
      setTotal(total => parseFloat(total) + parseFloat(itemVar.total));
      item({});
      setErrors({});
    }
  }, [itemVar]);

  const handleDelete = async (e, i) => {
    const paymentData = rows.find((el, ii) => i === ii);
    setTotal(total => parseFloat(total) - parseFloat(paymentData.total));
    const tmp = rows.filter((el, ii) => i !== ii);
    setRows(tmp);
  };

  return (
    <TableBody>
      {errors.table && (
        <TableRow>
          <TableCell colSpan={6}>
            <Typography color='error' align='center' gutterBottom>
              {errors.table}
            </Typography>
          </TableCell>
        </TableRow>
      )}
      {rows.map((el, i) => (
        <TableRow key={i}>
          <TableCell>
            <IconButton
              label='Eliminar'
              size='small'
              icon={<DeleteOutlineIcon />}
              params={i}
              action={handleDelete}
            />
          </TableCell>
          <TableCell>{el.folio}</TableCell>
          <TableCell>{format(new Date(el.date), "EEEE, d 'de' MMMM", { locale })}</TableCell>
          <TableCell align='right'>{`$${currencyFormat(el.total)}`}</TableCell>
        </TableRow>
      ))}
      {rows.length > 0 && <Totals total={total} />}
    </TableBody>
  );
};

export default BodyTableComponent;
