import React, { useState, useEffect } from 'react';

import { useQuery, useMutation, useLazyQuery } from '@apollo/client';

import FormDialog from '../../presentationals/FormDialog';
import TextField from '../../presentationals/TextField';
import SelectField from '../../presentationals/SelectField';
import DateField from '../../presentationals/DateField';
import PaymentTicket from '../../presentationals/PDF/payment.80mm';
import { parseError, capitalize, currencyFormat, dateTimeFormat } from '../../helpers';

import GQL_TRANSACTION from '../transaction/_gql';
import GQL_PAYMENT_METHOD from '../paymentMethod/_gql';
import useBranchConfig from '../../hooks/useBranchConfig';
import useSnackbarDialog from '../../store/snackbar/snackbarDialog';

const defaultValues = {
  total: '',
  paymentMethodId: -1,
  date: new Date(),
};

const Component = props => {
  const { setSnackbar } = useSnackbarDialog();

  const { data: branchConfig } = useBranchConfig();
  const [values, setValues] = useState(defaultValues);
  const [errors, setErrors] = useState({});
  const [paymentMethods, setPaymentMethods] = useState([]);
  const { data, loading } = useQuery(GQL_PAYMENT_METHOD.GET_AUTOCOMPLETE, {
    variables: { limit: 0 },
  });
  const [getTransactions, { data: dataTransactions }] = useLazyQuery(GQL_TRANSACTION.GET);
  const [createTransaction, { loading: loadingCreate }] = useMutation(GQL_TRANSACTION.CREATE, {
    update(cache, { data: response }) {
      const payment = parseFloat(response.createTransaction.total);
      cache.modify({
        id: `AccountReceivable:${response.createTransaction.AccountReceivable.id}`,
        fields: {
          payment(cachedPayment) {
            return cachedPayment + payment;
          },
          balance(cachedBalance) {
            return cachedBalance - payment;
          },
        },
      });
    },
  });

  useEffect(() => {
    if (data) {
      const tmp = data.paymentMethods.rows.map(tmp => ({
        value: tmp.id,
        label: capitalize(tmp.name),
      }));
      setPaymentMethods([{ value: -1, label: 'EFECTIVO' }, ...tmp]);
    }
  }, [data]);

  useEffect(() => {
    if (props.accountReceivableId)
      getTransactions({ variables: { accountReceivableId: props.accountReceivableId } });
  }, [props.accountReceivableId]);

  const handleClose = () => {
    props.handleClose();
  };

  const handleChange = e => {
    const { name } = e.target;
    const { value } = e.target;
    setValues(values => ({ ...values, [name]: value }));
  };

  const handleDateChange = (name, value) => {
    setValues(values => ({ ...values, [name]: value }));
  };

  const handleAction = async () => {
    try {
      if (values.total === '' || parseFloat(values.total) <= 0) {
        const totalError = new Error('La cantidad de abono tiene que ser mayor a cero.');
        totalError.name = 'total';
        totalError.input = true;
        throw totalError;
      }
      const variables = {
        accountReceivableId: props.accountReceivableId,
        total: parseFloat(values.total),
        paymentMethodId: parseInt(values.paymentMethodId) !== -1 ? values.paymentMethodId : null,
        date: values.date ? values.date : null,
      };
      await createTransaction({ variables });
      setValues(defaultValues);
      if (branchConfig?.printReceiptAfterPayment) PaymentTicket(props.accountReceivableId);
      handleClose();
    } catch (e) {
      if (e.input) {
        setErrors({ [e.name]: e.message });
        return;
      }
      const parseErrors = parseError(e);
      parseErrors.forEach(el => {
        if (el.name === 'BAD_USER_INPUT') setErrors(el.message);
        else
          setSnackbar({
            variables: {
              isOpen: true,
              time: 3000,
              label: el.message,
              severity: 'error',
            },
          });
      });
    }
  };

  return (
    <FormDialog
      title='Abono a cuenta por cobrar'
      isOpen={props.isOpen}
      handleClose={handleClose}
      handleAction={handleAction}
      isLoading={loading || loadingCreate}
    >
      <DateField
        size='small'
        name='date'
        label='Fecha de Pago'
        onChange={handleDateChange}
        value={values.date}
      />
      <TextField
        value={values.total}
        error={errors.total}
        name='total'
        label='Cantidad'
        onChange={handleChange}
        required
        autoFocus
      />
      <SelectField
        value={values.paymentMethodId}
        error={errors.paymentMethodId}
        name='paymentMethodId'
        label='Método de Pago'
        onChange={handleChange}
        options={paymentMethods}
        required
      />
      <br />
      Esta vista es temporal.
      {dataTransactions &&
        dataTransactions.transactions.rows.map(el => (
          <div>{`${dateTimeFormat(el.clientCreatedAt)}, $${currencyFormat(el.total)}`}</div>
        ))}
    </FormDialog>
  );
};

export default Component;
