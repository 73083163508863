import React from 'react';
import ServiceOrderReportComponent from '../containers/serviceOrder';
import ServiceOrderLayoutComponent from './ServiceOrderLayout';
import GeneralServiceOrderComponent from '../containers/serviceOrder/Item/General';
import ProductsServiceOrderComponent from '../containers/serviceOrder/Item/Products';
import CloseServiceOrderComponent from '../containers/serviceOrder/Item/Close';
import ExpensesServiceOrderComponent from '../containers/serviceOrder/Item/Expenses';
import CancelSaleComponent from '../containers/sale.report/Item/Cancel';
var getServiceOrderChildrenRoutes = function () {
    return [
        { index: true, element: React.createElement(ServiceOrderReportComponent, null) },
        {
            path: ':saleId',
            element: React.createElement(ServiceOrderLayoutComponent, null),
            children: [
                { index: true, element: React.createElement(GeneralServiceOrderComponent, null) },
                { path: 'products', element: React.createElement(ProductsServiceOrderComponent, null) },
                { path: 'close', element: React.createElement(CloseServiceOrderComponent, null) },
                { path: 'expenses', element: React.createElement(ExpensesServiceOrderComponent, null) },
                { path: 'cancel', element: React.createElement(CancelSaleComponent, { module: 'orden de servicio' }) },
            ],
        },
    ];
};
export default getServiceOrderChildrenRoutes;
