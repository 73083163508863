var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useEffect, useRef, useState } from 'react';
import PrintIcon from '@mui/icons-material/Print';
import ReceiptIcon from '@mui/icons-material/ReceiptOutlined';
import ListAltIcon from '@mui/icons-material/ListAltOutlined';
import LockIcon from '@mui/icons-material/LockOutlined';
import QrCode2Icon from '@mui/icons-material/QrCode2';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedInOutlined';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import EmailIcon from '@mui/icons-material/EmailOutlined';
import ChatOutlinedIcon from '@mui/icons-material/ChatOutlined';
import DeleteDialog from '../../presentationals/DeleteDialog';
import { actions, deleteForm, isCloseRequisitionOpen } from '../../apollo/cache';
import CancelForm from './cancel.form';
import GQL from './_gql';
import ReSendDialog from './reSend.dialog';
import CommentaryDialog from './commentary.dialog';
import CONFIG from '../../config/config.json';
import CloseRequisitionComponent from './CloseRequisition';
import EditRequisitionProductsDialogComponent from './EditProducts';
import PrintProductRequisitionBarcodeDialogComponent from './PrintBarcode';
import requisitionTicket from '../../presentationals/PDF/requisition.80mm';
var ActionComponent = function () {
    var printProductRequisitionBarcodeDialogRef = useRef(null);
    var editRequisitionProductsDialogRef = useRef(null);
    var _a = useState([false, null]), isOpenCancel = _a[0], setIsOpenCancel = _a[1];
    var _b = useState([false, null]), isOpenReSend = _b[0], setIsOpenReSend = _b[1];
    var _c = useState([false, null, '']), isOpenCommentary = _c[0], setIsOpenCommentary = _c[1];
    var handleOpenProducts = function (e, _a) {
        var _b;
        var id = _a.id;
        (_b = editRequisitionProductsDialogRef.current) === null || _b === void 0 ? void 0 : _b.open(id);
    };
    var handleFormCloseOrder = function (e, _a) {
        var id = _a.id;
        return isCloseRequisitionOpen(id);
    };
    var handleAuth = function (e, _a) {
        var id = _a.id;
        return deleteForm({ isOpen: true, params: { id: id } });
    };
    var handleCancel = function (e, _a) {
        var id = _a.id;
        return setIsOpenCancel([true, id]);
    };
    var handleCloseCancel = function () { return setIsOpenCancel([false, null]); };
    var handleTicket = function (e, _a) {
        var id = _a.id;
        return __awaiter(void 0, void 0, void 0, function () {
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, requisitionTicket(id)];
                    case 1:
                        _b.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    var handlePDF = function (e, _a) {
        var id = _a.id;
        return window.open("".concat(CONFIG['restServer'].uri, "/files/requisitions/").concat(id, "/pdf"));
    };
    var handleReSend = function (e, data) {
        setIsOpenReSend([true, data.id]);
    };
    var handleCloseReSend = function () { return setIsOpenReSend([false, null]); };
    var handleOpenCommentary = function (e, _a) {
        var id = _a.id, commentary = _a.commentary;
        return setIsOpenCommentary([true, id, commentary]);
    };
    var handleCloseCommentary = function () { return setIsOpenCommentary([false, null, '']); };
    var handlePrintBarcode = function (e, _a) {
        var _b;
        var id = _a.id;
        (_b = printProductRequisitionBarcodeDialogRef.current) === null || _b === void 0 ? void 0 : _b.open(id);
    };
    useEffect(function () {
        actions([
            {
                icon: React.createElement(ReceiptIcon, null),
                label: 'Imprimir Ticket',
                onClick: handleTicket,
            },
            {
                icon: React.createElement(PrintIcon, null),
                label: 'Imprimir PDF',
                onClick: handlePDF,
            },
            {
                icon: React.createElement(EmailIcon, null),
                label: 'Reenviar PDF',
                onClick: handleReSend,
            },
            {
                icon: React.createElement(QrCode2Icon, null),
                label: 'Imprimir código de barras',
                onClick: handlePrintBarcode,
            },
            {
                icon: React.createElement(ListAltIcon, null),
                label: 'Editar Productos',
                onClick: handleOpenProducts,
                disable: { key: 'status', value: [3, 2, 4] },
            },
            {
                icon: React.createElement(AssignmentTurnedInIcon, null),
                label: 'Autorizar',
                onClick: handleAuth,
                disable: { key: 'status', value: [2, 3, 4] },
            },
            {
                icon: React.createElement(LockIcon, null),
                label: 'Cerrar Orden de Compra',
                onClick: handleFormCloseOrder,
                disable: { key: 'status', value: [3, 1, 4] },
            },
            {
                icon: React.createElement(DeleteOutlineIcon, null),
                label: 'Cancelar Requisición',
                onClick: handleCancel,
                disable: { key: 'status', value: [3, 4] },
            },
            {
                icon: React.createElement(ChatOutlinedIcon, null),
                label: 'Agregar Comentario',
                onClick: handleOpenCommentary,
            },
        ]);
    }, []);
    return (React.createElement(React.Fragment, null,
        React.createElement(EditRequisitionProductsDialogComponent, { ref: editRequisitionProductsDialogRef }),
        React.createElement(ReSendDialog, { id: isOpenReSend[1], isOpen: isOpenReSend[0], handleClose: handleCloseReSend }),
        React.createElement(DeleteDialog, { operation: 'requisition', delete: GQL.AUTHORIZE_REQUISITION }),
        React.createElement(CancelForm, { isOpen: isOpenCancel[0], handleClose: handleCloseCancel, id: isOpenCancel[1] }),
        React.createElement(CommentaryDialog, { isOpen: isOpenCommentary[0], id: isOpenCommentary[1], commentary: isOpenCommentary[2], handleClose: handleCloseCommentary }),
        React.createElement(CloseRequisitionComponent, null),
        React.createElement(PrintProductRequisitionBarcodeDialogComponent, { ref: printProductRequisitionBarcodeDialogRef })));
};
export default ActionComponent;
