var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { useMutation } from '@apollo/client';
import getResponseWithData from '../containers/sale.v2/ConfirmDialog/Footer/CreateButton/helpers/getResponseWithData';
import GQL from '../containers/invoice.report.v2/_gql';
import getInputErrorFormat from '../containers/sale.v2/Action/helpers/getInputErrorFormat';
var useCloseSaleMutation = function () {
    var _a = useMutation(GQL.CREATE, {
        update: function (cache, _a) {
            var data = _a.data;
            try {
                var response_1 = getResponseWithData(data);
                if (!response_1)
                    return;
                cache.modify({
                    id: cache.identify({ __typename: 'Sale', id: response_1[1].Sale.id }),
                    fields: {
                        Invoice: function () {
                            return {
                                id: response_1[1].id,
                                uuid: response_1[1].uuid,
                                folio: response_1[1].folio,
                                serial: response_1[1].serial,
                            };
                        },
                        invoiceId: function () {
                            return response_1[1].id;
                        },
                        folio: function () {
                            return response_1[1].folio;
                        },
                        serial: function () {
                            return response_1[1].serial;
                        },
                        folio2: function () {
                            return response_1[1].folio2;
                        },
                    },
                });
                cache.modify({
                    fields: {
                        invoices: function (existingRefs) {
                            var newRef = cache.writeFragment({
                                data: __assign({}, response_1[1]),
                                fragment: GQL.FRAGMENT_INVOICE,
                            });
                            return __assign(__assign({}, existingRefs), { count: parseInt(existingRefs.count) + 1, rows: __spreadArray([newRef], existingRefs.rows, true) });
                        },
                    },
                });
            }
            catch (e) {
                getInputErrorFormat(e);
            }
        },
    }), f = _a[0], _b = _a[1], data = _b.data, loading = _b.loading, error = _b.error;
    return [f, { data: data, loading: loading, error: error }];
};
export default useCloseSaleMutation;
