var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { useEffect, useMemo, useState } from 'react';
import { useReactiveVar } from '@apollo/client';
import useApolloLazyQuery from '../../../hooks/useApolloLazyQuery';
import GQL_SALE from '../../../apollo/gql/sale';
import { filter } from '../../../apollo/cache';
import { currencyFormat, dateTimeFormat } from '../../../helpers';
import getSaleStatus from '../../../helpers/getSaleStatus';
import getSaleColumnsToQuery from './getColumnsToQuery';
var useGetSales = function () {
    var filterVar = useReactiveVar(filter);
    var GET_SALES = useMemo(function () {
        var data = getSaleColumnsToQuery();
        return GQL_SALE.GET_SALES(data);
    }, []);
    var _a = useState([]), data = _a[0], setData = _a[1];
    var _b = useApolloLazyQuery(GET_SALES), get = _b[0], queryResult = _b[1];
    useEffect(function () {
        get({
            variables: __assign(__assign({}, filterVar), { status: filterVar.status || [4], limit: 0, order: ['clientCreatedAt', 'desc'] }),
        });
    }, [filterVar]);
    useEffect(function () {
        if (queryResult.data) {
            var data_1 = queryResult.data.rows.map(function (el) { return ({
                id: el.id,
                createdAt: dateTimeFormat(el.clientCreatedAt, 'dd/MM/yyyy HH:mm'),
                closedAt: el.clientClosingAt ? dateTimeFormat(el.clientClosingAt, 'dd/MM/yyyy HH:mm') : '-',
                folio: el.folio,
                customer: el.customer,
                status: getSaleStatus(el.status),
                commission: currencyFormat(el.commission),
                shipping: currencyFormat(el.shipping),
                subtotal: currencyFormat(el.subtotal),
                discount: currencyFormat(el.discount),
                taxes: currencyFormat(el.taxes),
                total: currencyFormat(el.total),
                cashier: el.cashier,
                seller: el.seller,
                branch: el.branch ? el.branch : '-',
            }); });
            setData(data_1);
        }
    }, [queryResult.data]);
    return __assign(__assign({}, queryResult), { data: data });
};
export default useGetSales;
