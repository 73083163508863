import React, { forwardRef, useImperativeHandle, useState } from 'react';
import useGetSession from '../../../../../../../hooks/useGetSession';
var HelperTextSearchCustomerGlobalInvoiceFieldComponent = forwardRef(function (props, ref) {
    var _a;
    var _b = useState('Nombre'), label = _b[0], setLabel = _b[1];
    var session = useGetSession();
    var customerLabel = ((_a = session === null || session === void 0 ? void 0 : session.Company) === null || _a === void 0 ? void 0 : _a.activity) === 3 ? 'paciente' : 'cliente';
    var handleLabelChange = function (label) {
        setLabel(label);
    };
    useImperativeHandle(ref, function () { return ({
        setLabel: handleLabelChange,
    }); });
    return (React.createElement("div", { className: 'flex tracking-tighter text-xs text-gray-500' }, "Escribe el ".concat(label.toLowerCase(), " del ").concat(customerLabel, " para realizar la b\u00FAsqueda.")));
});
export default HelperTextSearchCustomerGlobalInvoiceFieldComponent;
