import React from 'react';
import { useReactiveVar } from '@apollo/client';
import { isInvoiceComplementOpen } from '../../../../apollo/cache';
import DialogComponent from '../../../../presentationals/Dialog';
import BodyInvoiceComplementComponent from './Body';
var InvoiceComplementDialogComponent = function () {
    var isOpen = useReactiveVar(isInvoiceComplementOpen);
    var handleClose = function () {
        isInvoiceComplementOpen(false);
    };
    return (React.createElement(DialogComponent, { fullScreen: true, open: !!isOpen, title: 'Generar complemento de pago', onClose: handleClose },
        React.createElement(BodyInvoiceComplementComponent, { customerId: isOpen || undefined })));
};
export default InvoiceComplementDialogComponent;
