import React, { useState, useEffect } from 'react';
import { useLazyQuery } from '@apollo/client';

import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import { currencyFormat } from '../../helpers/general';
import FormDialog from '../../presentationals/FormDialog';
import TextFieldComponent from '../../presentationals/FormComponents/TextField';
import useApolloMutation from '../../hooks/useApolloMutation';
import GQL_SALE from '../../apollo/gql/sale';
import getInputErrorFormat from '../sale.v2/Action/helpers/getInputErrorFormat';
import LoadingProgress from '../../presentationals/LoadingProgress';
import useSnackbarDialog from '../../store/snackbar/snackbarDialog';

const Component = ({ saleId, ...props }) => {
  const { setSnackbar } = useSnackbarDialog();

  const defaultTotals = {
    subtotal: 0,
    discount: 0,
    taxes: 0,
    total: 0,
  };
  const [products, setProducts] = useState([]);
  const [totals, setTotals] = useState(defaultTotals);
  const [commentary, setCommentary] = useState('');

  const [updateCommentary, { loading }] = useApolloMutation(GQL_SALE.UPDATE_SALE_COMMENTARY);
  const [getProducts, { data: saleData, loading: loadingSale }] = useLazyQuery(
    GQL_SALE.GET_SALE_BY_ID,
  );

  useEffect(() => {
    if (saleId !== null) getProducts({ variables: { id: saleId } });
  }, [saleId]); // eslint-disable-line

  useEffect(() => {
    if (saleData && saleData.sale) {
      const { commentary, SaleProducts } = saleData.sale;
      setCommentary(commentary);
      setProducts(SaleProducts);
      setTotals({
        ...defaultTotals,
        subtotal: saleData.sale.subtotal,
        discount: saleData.sale.discount,
        taxes: saleData.sale.taxes,
        total: saleData.sale.total,
      });
    }
  }, [saleData]);

  const handleChange = (name, value) => {
    setCommentary(value);
  };

  const handleUpdateCommentary = async () => {
    try {
      await updateCommentary({ variables: { id: saleId, commentary } });
      setSnackbar({
        isOpen: true,
        label: 'El comentario se actualizó exitosamente.',
        severity: 'success',
        time: 3000,
      });
    } catch (error) {
      getInputErrorFormat(error);
    }
  };
  return (
    <FormDialog
      title='Productos'
      isOpen={props.isOpen || false}
      handleClose={() => props.handleClose()}
      isLoading={loading}
      width='md'
      actionLabel='Actualizar comentario'
      handleAction={handleUpdateCommentary}
    >
      {loadingSale ? (
        <LoadingProgress />
      ) : (
        <>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Producto</TableCell>
                <TableCell>Cant.</TableCell>
                <TableCell align='center'>Precio Unitario</TableCell>
                <TableCell align='center'>Subtotal</TableCell>
                <TableCell align='center'>Descuento</TableCell>
                <TableCell align='right'>Total</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {products.map(el => (
                <TableRow hover key={el.id}>
                  <TableCell>{el.Product ? el.Product.name : el.name ? el.name : ''}</TableCell>
                  <TableCell>{el.quantity}</TableCell>
                  <TableCell align='center'>{currencyFormat(el.unitPrice)}</TableCell>
                  <TableCell align='center'>{currencyFormat(el.subtotal)}</TableCell>
                  <TableCell align='center'>{currencyFormat(el.discount)}</TableCell>
                  <TableCell align='right'>{currencyFormat(el.total)}</TableCell>
                </TableRow>
              ))}
              <TableRow>
                <TableCell colSpan={5} align='right'>
                  <strong>Total:</strong>
                </TableCell>
                <TableCell align='right'>
                  <strong>{currencyFormat(totals.total)}</strong>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
          <Grid item xs={12}>
            <TextFieldComponent
              name='commmentary'
              label='Anotaciones del documento'
              multiline
              rows={3}
              value={commentary}
              onChange={handleChange}
            />
          </Grid>
        </>
      )}
    </FormDialog>
  );
};

export default Component;
