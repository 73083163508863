import FRAGMENT_PERMISSIONS from './fragmentPermissions';
import GET_SESSION_ROLE from './getSessionRole';
import FRAGMENT_ROLE from './fragmentRole';
import FRAGMENT_ROLE_WITH_PERMISSIONS from './fragmentRoleWithPermissions';
import GET_ROLE_BY_ID from './getRoleById';
var GQL_ROLE = {
    FRAGMENT_PERMISSIONS: FRAGMENT_PERMISSIONS,
    FRAGMENT_ROLE: FRAGMENT_ROLE,
    FRAGMENT_ROLE_WITH_PERMISSIONS: FRAGMENT_ROLE_WITH_PERMISSIONS,
    GET_SESSION_ROLE: GET_SESSION_ROLE,
    GET_ROLE_BY_ID: GET_ROLE_BY_ID,
};
export default GQL_ROLE;
