import React from 'react';
import SelectFieldComponent from '../../../../../../../presentationals/FormComponents/SelectField';
var CreditNoteTypeFieldComponent = function (props) {
    var typeOptions = [
        { label: 'Bonificación', value: '1' },
        { label: 'Devolución', value: '2' },
    ];
    return (React.createElement(SelectFieldComponent, { name: 'type', label: 'Acci\u00F3n', value: props.value, options: typeOptions, onChange: props.onChange }));
};
export default CreditNoteTypeFieldComponent;
