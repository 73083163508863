var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { capitalize } from '../../../../helpers';
var getPaymentMethodsFromTransanctions = function (data) {
    return data.reduce(function (array, el) {
        var paymentMethodId = el.paymentMethodId || -1;
        var isInArray = array.find(function (elCh) { return elCh.value.toString() === paymentMethodId.toString(); });
        return isInArray
            ? array
            : __spreadArray(__spreadArray([], array, true), [{ value: paymentMethodId, label: capitalize(el.paymentMethod) }], false);
    }, []);
};
export default getPaymentMethodsFromTransanctions;
