var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React from 'react';
import { Button } from '@mui/material';
import useCreateProductAttribute from '../../../helpers/useCreateProductAttribute';
import GQL_PRODUCT from '../../../../../../../../apollo/gql/product';
import useApolloMutation from '../../../../../../../../hooks/useApolloMutation';
import getInputErrorFormat from '../../../../../../../sale.v2/Action/helpers/getInputErrorFormat';
import getProportionTextVariablesFormat from '../helpers/getVariablesFormat';
import successfulSnackbar from '../../../../../../../../helpers/successfulSnackbar';
var EditButtonProportionFieldEcommerceEditFormComponent = function (props) {
    var _a = useCreateProductAttribute(), createProductAttribute = _a.createProductAttribute, loading = _a.loading;
    var _b = useApolloMutation(GQL_PRODUCT.UPDATE_PRODUCT_ATTRIBUTE), updateProductAttribute = _b[0], loadingUpdate = _b[1].loading;
    var handleClick = function () { return __awaiter(void 0, void 0, void 0, function () {
        var att12, att12Value, att13, att13Value, att14, att14Value, att15, att15Value, att16, att16Value, att17, att17Value, error_1;
        var _a, _b, _c, _d, _e, _f;
        return __generator(this, function (_g) {
            switch (_g.label) {
                case 0:
                    _g.trys.push([0, 25, , 26]);
                    att12 = (_a = props.TextAttributes) === null || _a === void 0 ? void 0 : _a.find(function (el) { return el.type === 12; });
                    att12Value = getProportionTextVariablesFormat(props.values.att12, 12);
                    if (!(att12 && parseFloat(att12.attribute) !== props.values.att12)) return [3 /*break*/, 2];
                    return [4 /*yield*/, updateProductAttribute({
                            variables: __assign({ id: att12.id }, att12Value),
                        })];
                case 1:
                    _g.sent();
                    return [3 /*break*/, 4];
                case 2:
                    if (!!att12) return [3 /*break*/, 4];
                    return [4 /*yield*/, createProductAttribute({
                            variables: att12Value,
                        })];
                case 3:
                    _g.sent();
                    _g.label = 4;
                case 4:
                    att13 = (_b = props.TextAttributes) === null || _b === void 0 ? void 0 : _b.find(function (el) { return el.type === 13; });
                    att13Value = getProportionTextVariablesFormat(props.values.att13, 13);
                    if (!(att13 && parseFloat(att13.attribute) !== props.values.att13)) return [3 /*break*/, 6];
                    return [4 /*yield*/, updateProductAttribute({
                            variables: __assign({ id: att13.id }, att13Value),
                        })];
                case 5:
                    _g.sent();
                    return [3 /*break*/, 8];
                case 6:
                    if (!!att13) return [3 /*break*/, 8];
                    return [4 /*yield*/, createProductAttribute({
                            variables: att13Value,
                        })];
                case 7:
                    _g.sent();
                    _g.label = 8;
                case 8:
                    att14 = (_c = props.TextAttributes) === null || _c === void 0 ? void 0 : _c.find(function (el) { return el.type === 14; });
                    att14Value = getProportionTextVariablesFormat(props.values.att14, 14);
                    if (!(att14 && parseFloat(att14.attribute) !== props.values.att14)) return [3 /*break*/, 10];
                    return [4 /*yield*/, updateProductAttribute({
                            variables: __assign({ id: att14.id }, att14Value),
                        })];
                case 9:
                    _g.sent();
                    return [3 /*break*/, 12];
                case 10:
                    if (!!att14) return [3 /*break*/, 12];
                    return [4 /*yield*/, createProductAttribute({
                            variables: att14Value,
                        })];
                case 11:
                    _g.sent();
                    _g.label = 12;
                case 12:
                    att15 = (_d = props.TextAttributes) === null || _d === void 0 ? void 0 : _d.find(function (el) { return el.type === 15; });
                    att15Value = getProportionTextVariablesFormat(props.values.att15, 15);
                    if (!(att15 && parseFloat(att15.attribute) !== props.values.att15)) return [3 /*break*/, 14];
                    return [4 /*yield*/, updateProductAttribute({
                            variables: __assign({ id: att15.id }, att15Value),
                        })];
                case 13:
                    _g.sent();
                    return [3 /*break*/, 16];
                case 14:
                    if (!!att15) return [3 /*break*/, 16];
                    return [4 /*yield*/, createProductAttribute({
                            variables: att15Value,
                        })];
                case 15:
                    _g.sent();
                    _g.label = 16;
                case 16:
                    att16 = (_e = props.TextAttributes) === null || _e === void 0 ? void 0 : _e.find(function (el) { return el.type === 16; });
                    att16Value = getProportionTextVariablesFormat(props.values.att16, 16);
                    if (!(att16 && parseFloat(att16.attribute) !== props.values.att16)) return [3 /*break*/, 18];
                    return [4 /*yield*/, updateProductAttribute({
                            variables: __assign({ id: att16.id }, att16Value),
                        })];
                case 17:
                    _g.sent();
                    return [3 /*break*/, 20];
                case 18:
                    if (!!att16) return [3 /*break*/, 20];
                    return [4 /*yield*/, createProductAttribute({
                            variables: att16Value,
                        })];
                case 19:
                    _g.sent();
                    _g.label = 20;
                case 20:
                    att17 = (_f = props.TextAttributes) === null || _f === void 0 ? void 0 : _f.find(function (el) { return el.type === 17; });
                    att17Value = getProportionTextVariablesFormat(props.values.att17, 17);
                    if (!(att17 && parseFloat(att17.attribute) !== props.values.att17)) return [3 /*break*/, 22];
                    return [4 /*yield*/, updateProductAttribute({
                            variables: __assign({ id: att17.id }, att17Value),
                        })];
                case 21:
                    _g.sent();
                    return [3 /*break*/, 24];
                case 22:
                    if (!!att17) return [3 /*break*/, 24];
                    return [4 /*yield*/, createProductAttribute({
                            variables: att17Value,
                        })];
                case 23:
                    _g.sent();
                    _g.label = 24;
                case 24:
                    successfulSnackbar('Las medidas del cuadro de texto se actualizaron exitosamente.');
                    return [3 /*break*/, 26];
                case 25:
                    error_1 = _g.sent();
                    getInputErrorFormat(error_1);
                    return [3 /*break*/, 26];
                case 26: return [2 /*return*/];
            }
        });
    }); };
    return (React.createElement(Button, { disabled: loading || loadingUpdate, variant: 'contained', color: 'success', size: 'small', onClick: handleClick }, "Almacenar configuraci\u00F3n de medidas"));
};
export default EditButtonProportionFieldEcommerceEditFormComponent;
