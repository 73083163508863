var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React, { useEffect, useState } from 'react';
import DialogComponent from '../../../presentationals/Dialog';
import Button from '../../../presentationals/Dialog/Footer/Button';
import FooterDialogComponent from '../../../presentationals/Dialog/Footer';
import getInputErrorFormat from '../../sale.v2/Action/helpers/getInputErrorFormat';
import useFilterButtonDialog from '../../../store/filterButton/filterButtonDialog';
import ClassificationsCheckboxFieldComponent from '../../employeeSalesReport/Filter/ClassificationsCheckboxField';
import { filter } from '../../../apollo/cache';
import useApolloQuery from '../../../hooks/useApolloQuery';
import GQL_CLASSIFICATION from '../../../apollo/gql/classification';
var FilterProductComponent = function () {
    var open = useFilterButtonDialog(function (state) { return state.open; });
    var setOpen = useFilterButtonDialog(function (state) { return state.setOpen; });
    var _a = useState({ classificationId: [] }), values = _a[0], setValues = _a[1];
    var _b = useApolloQuery(GQL_CLASSIFICATION.GET_CLASSIFICATION, {
        variables: { limit: 0 },
    }), data = _b.data, loading = _b.loading;
    useEffect(function () {
        if (open && data) {
            var filterVar = filter();
            setValues({
                classificationId: filterVar.classificationId
                    ? filterVar.classificationId
                    : data.rows.map(function (el) { return el.id; }),
            });
        }
    }, [open, data]);
    var handleClose = function () {
        setOpen(false);
    };
    var handleClick = function () {
        try {
            var currentFilter = filter();
            filter(__assign(__assign({}, currentFilter), { classificationId: values.classificationId }));
            handleClose();
        }
        catch (error) {
            getInputErrorFormat(error);
        }
    };
    var handleCheckboxChange = function (classificationId, checked) {
        if (checked)
            setValues(function (values) { return (__assign(__assign({}, values), { classificationId: __spreadArray(__spreadArray([], values.classificationId, true), [classificationId], false) })); });
        else
            setValues(function (values) { return (__assign(__assign({}, values), { classificationId: values.classificationId.filter(function (el) { return el !== classificationId; }) })); });
    };
    var handleSelectAll = function (classificationIds) {
        setValues(function (values) { return (__assign(__assign({}, values), { classificationId: classificationIds })); });
    };
    return (React.createElement(DialogComponent, { fullScreen: true, open: open, title: 'Filtro para b\u00FAsqueda de productos', onClose: handleClose },
        React.createElement("div", { className: 'grid flex-1 grid-cols-1 md:grid-cols-3 md:gap-4 px-4 py-1' },
            React.createElement(ClassificationsCheckboxFieldComponent, { defaultValues: values.classificationId, onChange: handleCheckboxChange, onSelectAll: handleSelectAll })),
        React.createElement(FooterDialogComponent, null,
            React.createElement("div", null),
            React.createElement("div", null,
                React.createElement(Button, { disabled: loading, color: 'success', onClick: handleClick }, "Aplicar filtro")))));
};
export default FilterProductComponent;
