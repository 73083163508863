import React from 'react';
import { useReactiveVar } from '@apollo/client';
import { Switch, Typography } from '@mui/material';
import { formatSaleV2 } from '../../../../../../../apollo/cache';
var IsInvoiceTaxesOptionFormFormatActionSaleV2Component = function () {
    var formatSaleV2Var = useReactiveVar(formatSaleV2);
    var handleSwitch = function (e) {
        formatSaleV2([formatSaleV2Var[0], formatSaleV2Var[1], e.target.checked, formatSaleV2Var[3]]);
    };
    return (React.createElement("div", { className: 'flex flex-row items-center' },
        React.createElement(Typography, { variant: 'subtitle1' }, "\u00BFDesea emitir factura electr\u00F3nica?"),
        React.createElement(Switch, { checked: formatSaleV2Var[2], onChange: handleSwitch, name: 'switchInvoice' })));
};
export default IsInvoiceTaxesOptionFormFormatActionSaleV2Component;
