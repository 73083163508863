var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { Checkbox, FormControl, FormControlLabel, FormGroup, FormLabel } from '@mui/material';
import FieldErrorComponent from '../Error';
var CheckboxGroupComponent = function (props) {
    var _a;
    var _b = useFormContext(), control = _b.control, _c = _b.formState, errors = _c.errors, isSubmitting = _c.isSubmitting;
    var _d = useController({
        name: props.name,
        control: control,
        defaultValue: ((_a = props.config) === null || _a === void 0 ? void 0 : _a.value) || [],
        rules: props.config,
    }).field, ref = _d.ref, values = _d.value, onChange = _d.onChange, inputProps = __rest(_d, ["ref", "value", "onChange"]);
    var handleChange = function (value) {
        var newArray = __spreadArray([], values, true);
        if (newArray.length > 0) {
            var index = newArray.findIndex(function (x) { return x === value; });
            if (index === -1)
                newArray.push(value);
            else
                newArray.splice(index, 1);
        }
        else {
            newArray.push(value);
        }
        onChange(newArray);
    };
    return (React.createElement(FormControl, { disabled: props.disabled || isSubmitting, error: !!errors[props.name], component: 'fieldset', variant: 'outlined', className: 'w-full py-1' },
        props.label && React.createElement(FormLabel, { component: 'legend' }, props.label),
        React.createElement(FormGroup, null,
            React.createElement("div", { className: 'flex flex-wrap' }, props.options.map(function (el) { return (React.createElement(FormControlLabel, { key: el.value, label: el.label, control: React.createElement(Checkbox, __assign({ checked: values.includes(el.value), inputRef: ref, onChange: function () { return handleChange(el.value); }, disabled: props.disabled || isSubmitting }, inputProps)) })); }))),
        React.createElement(FieldErrorComponent, { name: props.name })));
};
export default CheckboxGroupComponent;
