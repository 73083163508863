import React from 'react';
import { useController, useFormContext } from 'react-hook-form';
var LabelCountryCodeFieldComponent = function (props) {
    var control = useFormContext().control;
    var value = useController({
        name: props.name,
        control: control,
    }).field.value;
    return React.createElement("div", { className: 'text-sm leading-none tracking-tighter text-gray-600' }, value);
};
export default LabelCountryCodeFieldComponent;
