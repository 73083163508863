import React from 'react';
import { DialogTitle } from '@mui/material';
import CloseButton from './CloseButton';
var HeaderConfirmDialogSaleV2Component = function () {
    return (React.createElement(DialogTitle, { className: 'flex py-2 px-4 justify-between items-center' },
        React.createElement("div", null, "Agendar una cita o evento"),
        React.createElement("div", null,
            React.createElement(CloseButton, null))));
};
export default HeaderConfirmDialogSaleV2Component;
