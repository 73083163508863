import React, { forwardRef, useImperativeHandle, useState } from 'react';
import DialogComponent from '../../../presentationals/Dialog';
import BodyItemAdjustInventoryComponent from './Body';
var AdjustInventoryProductsComponent = forwardRef(function (props, ref) {
    var _a = useState(null), open = _a[0], setOpen = _a[1];
    var handleClose = function () {
        setOpen(null);
    };
    var handleOpen = function (id) {
        setOpen(id);
    };
    useImperativeHandle(ref, function () { return ({
        open: handleOpen,
    }); });
    return (React.createElement(DialogComponent, { title: 'Ajuste de inventario', open: !!open, onClose: handleClose },
        React.createElement(BodyItemAdjustInventoryComponent, { id: open })));
});
export default AdjustInventoryProductsComponent;
