import React, { useState, useEffect } from 'react';

import { useLazyQuery, useReactiveVar } from '@apollo/client';

import Grid from '@mui/material/Grid';

import TextField from '../../../../../presentationals/FormComponents/TextField';
import Autocomplete from '../../../../../presentationals/FormComponents/Autocomplete';
import Button from '../../../../../presentationals/Button';

import { item, clearForm, step } from '../../../../../apollo/cache';

import GQL from '../../../_gql';

const defaultValues = {
  quantity: 1,
  productId: null,
};

const ProductFormComponent = props => {
  const clearFormVar = useReactiveVar(clearForm);
  const stepVar = useReactiveVar(step);

  const [values, setValues] = useState(defaultValues);
  const [products, setProducts] = useState([]);
  const [errors, setErrors] = useState({});

  const [getProducts, { data: productsData }] = useLazyQuery(GQL.GET_BY_ID);

  useEffect(() => {
    if (clearFormVar && stepVar === 1) {
      setValues(defaultValues);
      clearForm(false);
    }
  }, [clearFormVar]);

  useEffect(() => {
    if (props.id) getProducts({ variables: { id: props.id } });
  }, [props.id]);

  useEffect(() => {
    if (productsData && productsData.purchaseOrder) {
      const tmpProducts = productsData.purchaseOrder.PurchaseOrderDetails.map(el => ({
        label: `${el.Product?.code ? String(el.Product.code).padStart(3, '0') : ''} ${
          el.Product?.sku ? el.Product.sku.trim() : ''
        } ${el.Product?.name.trim() || el.concept} ${
          el.Product?.barcode ? el.Product.barcode.trim() : ''
        } ${el.Product?.altBarcode ? el.Product.altBarcode.trim() : ''}`.trim(),
        value: el.id,
        name: el.Product?.name || el.concept,
        presentation: el.ProductPresentation?.name || '',
        quantity: el.quantity,
        receivedQuantity: el.receivedQuantity,
        discount: el.discount,
        unitPrice: el.unitPrice,
        total: el.total,
        id: el.id,
      }));
      setProducts(tmpProducts);
    }
  }, [productsData]);

  const handleAdd = async () => {
    try {
      setErrors({});
      if (!values.productId) {
        const e = new Error('Selecciona un producto.');
        e.name = 'productId';
        throw e;
      }
      if (Number.isNaN(parseFloat(values.quantity)) || parseFloat(values.quantity) <= 0) {
        const e = new Error('Proporciona una cantidad.');
        e.name = 'quantity';
        throw e;
      }
      const data = {
        quantity: values.quantity,
        originalQuantity: values.productId.quantity,
        product: values.productId.name,
        presentation: values.productId.presentation,
        receivedQuantity: values.productId.receivedQuantity,
        discount: values.productId.discount,
        unitPrice: values.productId.unitPrice,
        total: values.productId.total,
        id: values.productId.id,
      };
      item(data);
      setValues(defaultValues);
    } catch (e) {
      setErrors({ [e.name]: e.message });
    }
  };
  const handleChange = (name, value) => {
    setErrors({});
    setValues(values => ({ ...values, [name]: value }));
  };

  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Autocomplete
            name='productId'
            label='Buscar producto por nombre o código'
            onChange={handleChange}
            options={products}
            value={values.productId}
            error={errors.productId}
          />
        </Grid>
      </Grid>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <TextField
            name='quantity'
            label='Cantidad'
            type='number'
            onChange={handleChange}
            value={values.quantity}
            error={errors.quantity}
          />
        </Grid>
      </Grid>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Button className='mt-1' onClick={handleAdd} label='Añadir producto' />
        </Grid>
      </Grid>
    </>
  );
};

export default ProductFormComponent;
