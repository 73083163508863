import useApolloMutation from '../../../../../../hooks/useApolloMutation';
import GQL_PRODUCT from '../../../../../../apollo/gql/product';
var useDeleteProductAttribute = function () {
    var _a = useApolloMutation(GQL_PRODUCT.DELETE_PRODUCT_ATTRIBUTE, {
        name: 'ProductAttribute',
        action: 'delete',
    }), deleteProductAttribute = _a[0], loading = _a[1].loading;
    return { deleteProductAttribute: deleteProductAttribute, loading: loading };
};
export default useDeleteProductAttribute;
