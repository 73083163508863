import React from 'react';
import { useReactiveVar } from '@apollo/client';
import { calendarSettings } from '../../../../../../../../apollo/cache';
import Item from './Item';
var AgendaSettingsScheduleAvailabilityDialogSettingButtonFooterFormCalendarComponent = function () {
    var _a;
    var calendarSettingsVar = useReactiveVar(calendarSettings);
    return ((_a = calendarSettingsVar.scheduleAvailability) === null || _a === void 0 ? void 0 : _a.qty) ? (React.createElement("div", null, Array.from(Array(calendarSettingsVar.scheduleAvailability.qty).keys()).map(function (key) { return (React.createElement(Item, { key: key, index: key })); }))) : (React.createElement("div", null));
};
export default AgendaSettingsScheduleAvailabilityDialogSettingButtonFooterFormCalendarComponent;
