const getIdToPrintDocument = (key, isInvoice, data) => {
  if (isInvoice)
    switch (parseInt(key)) {
      case -1:
        return data.uuid;
      case 3:
      case 5:
        return data.Invoice.uuid;
      case 6:
        return data.Sale.Invoice.uuid;
      default:
        return data.id;
    }
  switch (parseInt(key)) {
    case -1:
      return data.uuid;
    case 6:
      return data.Sale.id;
    default:
      return data.id;
  }
};

export default getIdToPrintDocument;
