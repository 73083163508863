import React from 'react';

import TextFieldMUI from '@mui/material/TextField';
import Grid from '@mui/material/Grid';

const TextField = ({ noFormat, icon, error, extra, ...props }) => {
  return <Grid container spacing={0} alignItems='flex-end'>
    <Grid item xs={noFormat ? false : 1}>
      {icon || ''}
    </Grid>
    <Grid item xs={noFormat ? 12 : 10}>
      <TextFieldMUI
        name={props.name}
        label={props.label}
        variant={props.variant || 'standard'}
        fullWidth={props.fullWidth || true}
        autoFocus={props.autoFocus || false}
        onChange={props.onChange}
        value={props.value}
        error={!!error}
        helperText={error && error}
        autoComplete='off'
        size={props.size || 'small'}
        {...props}
      />
    </Grid>
    <Grid item xs={noFormat ? false : 1}>
      {extra || ''}
    </Grid>
  </Grid>;
};

export default TextField;
