import React, { useState } from 'react';

import { useMutation, useReactiveVar } from '@apollo/client';

import useTheme from '@mui/material/styles/useTheme';
import CloseIcon from '@mui/icons-material/Close';
import { Dialog, IconButton, Button, useMediaQuery } from '@mui/material';

import TextFieldComponent from '../../presentationals/FormComponents/TextField';

import GQL from './_gql';
import { inputError } from '../../apollo/cache';
import getInputErrorFormat from '../sale.v2/Action/helpers/getInputErrorFormat';

const defaultValues = {
  name: '',
  color: '#000000',
};

const CreateTagComponent = props => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const errors = useReactiveVar(inputError);
  const [values, setValues] = useState(defaultValues);

  const [createTag, { loading }] = useMutation(GQL.CREATE, {
    update(cache, { data: response }) {
      cache.modify({
        fields: {
          tags(existingTagsRefs) {
            return [...existingTagsRefs, response.createTag];
          },
        },
      });
    },
  });

  const handleClose = () => {
    props.handleClose();
  };

  const handleChange = (name, value) => {
    setValues(values => ({ ...values, [name]: value }));
  };

  const handleCreate = async () => {
    try {
      if (values.name === '') {
        const nameError = new Error('Este campo no puede ser vacío.');
        nameError.name = 'name';
        throw nameError;
      }
      if (values.color === '') {
        const colorError = new Error('Este campo no puede ser vacío.');
        colorError.name = 'color';
        throw colorError;
      }
      await createTag({
        variables: {
          name: values.name,
          color: values.color,
        },
      });
      handleClose();
      inputError({});
    } catch (e) {
      getInputErrorFormat(e);
    }
  };

  return (
    <Dialog
      open={props.isOpen}
      maxWidth='sm'
      fullWidth
      fullScreen={isSmallScreen}
      onClose={props.handleClose}
    >
      <div className='flex flex-row p-4 items-center border-[#60aff0] border-0 border-b-4 border-solid'>
        <div className='flex-1'>
          <div className='text-2xl text-[#1376c7] font-semibold'>Nueva etiqueta</div>
          <div className='text-base leading-4 text-gray-700'>
            Las etiquetas ayudan a distinguir diferentes tipos de citas o eventos.
          </div>
        </div>
        <IconButton onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </div>
      <div className='flex flex-1 p-4 flex-col mb-2'>
        <div className='grid md:grid-cols-2'>
          <TextFieldComponent
            label='Nombre'
            name='name'
            value={values.name}
            error={errors.name}
            onChange={handleChange}
          />
        </div>
        <div className='grid md:grid-cols-2'>
          <TextFieldComponent
            label='Color'
            name='color'
            type='color'
            value={values.color}
            error={errors.color}
            onChange={handleChange}
          />
        </div>
      </div>
      <div className='flex justify-end py-2 px-4 border-0 border-t border-[#d6d6d6] border-solid'>
        <Button
          disabled={loading}
          color='success'
          variant='contained'
          className='px-5 py-3'
          onClick={handleCreate}
        >
          Almacenar
        </Button>
      </div>
    </Dialog>
  );
};

export default CreateTagComponent;
