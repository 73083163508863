import { gql } from '@apollo/client';

const FRAGMENT_NEW_EMPLOYEE = gql`
  fragment fragmentNewEmployee on Employee {
    id
    firstName
    lastName
    phone
    email
    branchId
    roleId
  }
`;

const FRAGMENT_EMPLOYEE = gql`
  fragment fragmentEmployee on Employee {
    id
    firstName
    lastName
    phone
    email
    Branch {
      id
      name
    }
    Role {
      id
      name
    }
  }
`;

const GET = gql`
  query Employees(
    $limit: Int
    $offset: Int
    $like: [String]
    $order: [String]
    $branchId: [ID]
    $isSeller: Boolean
  ) {
    employees(
      limit: $limit
      offset: $offset
      like: $like
      order: $order
      branchId: $branchId
      isSeller: $isSeller
    ) {
      count
      rows {
        id
        firstName
        lastName
        phone
        email
        position
        Branch {
          id
          name
        }
        Role {
          id
          name
        }
      }
    }
  }
`;

const GET_NAME = gql`
  query Employees($isSeller: Boolean, $limit: Int) {
    employees(isSeller: $isSeller, limit: $limit) {
      count
      rows {
        id
        firstName
        lastName
      }
    }
  }
`;

const GET_USERS_CHAT = gql`
  query UsersChat {
    employees {
      count
      rows {
        id
        firstName
        lastName
        user
        phone
        email
      }
    }
  }
`;

const GET_BY_ID = gql`
  query Employee($id: ID!) {
    employee(id: $id) {
      id
      firstName
      lastName
      phone
      email
      branchId
      roleId
      restDays
      schedule
      position
      isSeller
    }
  }
`;

const DELETE = gql`
  mutation DeleteEmployee($id: ID!) {
    deleteEmployee(id: $id) {
      id
    }
  }
`;

const CREATE = gql`
  mutation CreateEmployee(
    $password: String!
    $userPassword: String!
    $firstName: String!
    $lastName: String!
    $email: String
    $phone: String!
    $roleId: ID!
    $startHour: Float
    $endHour: Float
    $branchId: ID
    $position: String
    $isSeller: Boolean
  ) {
    createEmployee(
      input: {
        password: $password
        userPassword: $userPassword
        firstName: $firstName
        lastName: $lastName
        email: $email
        phone: $phone
        roleId: $roleId
        startHour: $startHour
        endHour: $endHour
        branchId: $branchId
        position: $position
        isSeller: $isSeller
      }
    ) {
      id
      firstName
      lastName
      email
      phone
      startHour
      endHour
      position
      isSeller
      Branch {
        id
        name
      }
      Role {
        id
        name
      }
    }
  }
`;
const UPDATE = gql`
  mutation UpdateEmployee(
    $id: ID!
    $firstName: String
    $lastName: String
    $email: String
    $phone: String
    $roleId: ID
    $startHour: Float
    $endHour: Float
    $branchId: ID
    $position: String
    $isSeller: Boolean
    $userPassword: String
  ) {
    updateEmployee(
      id: $id
      input: {
        firstName: $firstName
        lastName: $lastName
        email: $email
        phone: $phone
        roleId: $roleId
        startHour: $startHour
        endHour: $endHour
        branchId: $branchId
        position: $position
        isSeller: $isSeller
        userPassword: $userPassword
      }
    ) {
      id
      firstName
      lastName
      phone
      email
      position
      isSeller
      roleId
      branchId
      Branch {
        id
        name
      }
      Role {
        id
        name
      }
    }
  }
`;
const GET_PROFILEPICTURE = gql`
  query EmployeeProfilePicture($id: ID!) {
    employee(id: $id) {
      profilePictureFileId
    }
  }
`;
const UPDATE_PROFILEPICTURE = gql`
  mutation UpdateProfilePictureEmployee($id: ID!, $file: Upload!) {
    updateProfilePictureEmployee(id: $id, file: $file) {
      id
    }
  }
`;
const DELETE_PROFILEPICTURE = gql`
  mutation DeleteProfilePictureEmployee($id: ID!, $profilePictureFileId: ID!) {
    deleteProfilePictureEmployee(id: $id, profilePictureFileId: $profilePictureFileId) {
      id
    }
  }
`;

const UPDATE_REST_DAYS = gql`
  mutation UpdateRestDays($id: ID!, $restDays: [Int]) {
    updateRestDays(id: $id, input: { restDays: $restDays }) {
      id
      firstName
      lastName
      phone
      email
      startHour
      endHour
      restDays
      Branch {
        id
        name
      }
      Role {
        id
        name
      }
    }
  }
`;

const UPDATE_SCHEDULE = gql`
  mutation UdateSchedule($id: ID!, $schedule: [ScheduleData]) {
    updateSchedule(id: $id, schedule: $schedule) {
      id
      firstName
      lastName
      phone
      email
      branchId
      roleId
      startHour
      endHour
      restDays
      schedule
    }
  }
`;

const UPDATE_PASSWORD = gql`
  mutation UpdateEmployeePassword($id: ID!, $newPassword: String!, $confirmationPassword: String!) {
    updateEmployeePassword(
      id: $id
      newPassword: $newPassword
      confirmationPassword: $confirmationPassword
    )
  }
`;

const UPDATE_PIN = gql`
  mutation UpdateEmployee($id: ID!, $userPassword: String) {
    updateEmployee(id: $id, userPassword: $userPassword) {
      id
    }
  }
`;

export default {
  GET,
  GET_BY_ID,
  DELETE,
  CREATE,
  UPDATE,
  GET_NAME,
  GET_USERS_CHAT,
  GET_PROFILEPICTURE,
  UPDATE_PROFILEPICTURE,
  DELETE_PROFILEPICTURE,
  UPDATE_REST_DAYS,
  UPDATE_SCHEDULE,
  UPDATE_PASSWORD,
  FRAGMENT_EMPLOYEE,
  FRAGMENT_NEW_EMPLOYEE,
  UPDATE_PIN,
};
