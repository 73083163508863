var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import { useReactiveVar } from '@apollo/client';
import { workingHoursForm } from '../../../../apollo/cache';
import ItemWorkingCalendarFieldComponent from './Item';
var WorkingCalendarFieldComponent = function () {
    var workingHoursVar = useReactiveVar(workingHoursForm);
    return (React.createElement("div", { className: 'flex flex-col' }, workingHoursVar.map(function (el) { return (React.createElement(ItemWorkingCalendarFieldComponent, __assign({}, el, { key: el.day }))); })));
};
export default WorkingCalendarFieldComponent;
