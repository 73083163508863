import React from 'react';
import { useReactiveVar } from '@apollo/client';
import DialogComponent from '../../../../presentationals/Dialog';
import { isCreateFormOpen } from '../../../../apollo/cache';
import BodyCreateSurveyQuestionComponent from './Body';
var CreateSurveyQuestionDialogComponent = function () {
    var isOpen = useReactiveVar(isCreateFormOpen);
    var handleClose = function () {
        isCreateFormOpen(false);
    };
    return (React.createElement(DialogComponent, { open: isOpen, fullScreen: true, title: 'Configura tu cuestionario o entrevista', onClose: handleClose },
        React.createElement(BodyCreateSurveyQuestionComponent, null)));
};
export default CreateSurveyQuestionDialogComponent;
