var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useEffect, useState } from 'react';
import { useApolloClient } from '@apollo/client';
import useSound from 'use-sound';
import CompanyLogoCalendarQueueManagerComponent from './CompanyLogo';
import InTurnAppointmentsCalendarQueueManagerComponent from './InTurnAppointments';
import NotificationCalendarQueueManagerComponent from './Notification';
import useApolloQuery from '../../hooks/useApolloQuery';
import GQL_CALENDAR_QUEUE_MANAGER from '../../apollo/gql/calendarQueueManager';
import LoadingProgress from '../../presentationals/LoadingProgress';
import Time from '../../presentationals/Time';
import BodyCalendarQueueManagerComponent from './Body';
import subscribeSocket from '../../socket/helpers/subscribe';
import unsubscribeSocket from '../../socket/helpers/unsubscribe';
import getApolloQueryArgs from '../../apollo/helpers/getApolloQueryArgs';
import CreateCalendarSocketQueueManager from './Socket/createCalendar';
import NotificationSound from '../../assets/static/_bell.mp3';
var CalendarQueueManagerComponent = function () {
    var playSound = useSound(NotificationSound)[0];
    var _a = useState(false), play = _a[0], setPlay = _a[1];
    var client = useApolloClient();
    var _b = useApolloQuery(GQL_CALENDAR_QUEUE_MANAGER.GET_CALENDAR_QUEUE_MANAGER), data = _b.data, loading = _b.loading;
    useEffect(function () {
        if (play) {
            playSound();
            setPlay(false);
        }
    }, [play]);
    useEffect(function () {
        var f = function (data) {
            if (data.status === 4)
                setPlay(true);
            var calendarRef = client.cache.identify({ __typename: 'Calendar', id: data.calendarId });
            client.cache.modify({
                id: calendarRef,
                fields: {
                    status: function () { return data.status; },
                },
            });
            client.cache.modify({
                fields: {
                    calendars: function (existingRefs, _a) {
                        var fieldName = _a.fieldName, storeFieldName = _a.storeFieldName, readField = _a.readField;
                        var args = getApolloQueryArgs(storeFieldName, fieldName);
                        if (args.status && args.status.find(function (key) { return key !== data.status; }))
                            return __assign(__assign({}, existingRefs), { rows: existingRefs.rows.filter(function (existingRef) { return data.calendarId !== readField('id', existingRef); }) });
                        return __assign(__assign({}, existingRefs), { rows: __assign(__assign({}, existingRefs.rows), { calendarRef: calendarRef }) });
                    },
                },
            });
        };
        subscribeSocket('STATUS_CALENDAR', f);
        return function () {
            unsubscribeSocket('STATUS_CALENDAR', f);
        };
    }, []);
    if (loading || !data)
        return (React.createElement("div", { className: 'w-screen h-screen flex flex-col' },
            React.createElement(LoadingProgress, null)));
    return (React.createElement("div", { className: 'w-screen h-screen flex flex-col' },
        React.createElement("div", { className: 'flex-1 flex flex-col overflow-hidden' },
            React.createElement("div", { className: 'flex' },
                React.createElement(InTurnAppointmentsCalendarQueueManagerComponent, { Color: data.colors }),
                React.createElement("div", { className: 'w-5/12 flex flex-1 justify-end items-center', style: {
                        color: data.colors.rigthHeaderTextColor || '#000',
                        backgroundColor: data.colors.rigthHeaderBackgroundColor || '#fff',
                    } },
                    React.createElement(Time, { className: 'text-5xl mr-4', format: 'h:mm aaa' }),
                    React.createElement(CompanyLogoCalendarQueueManagerComponent, { fileId: data.logoFileId }))),
            React.createElement(BodyCalendarQueueManagerComponent, { Color: data.colors, digitalContentTimer: data.digitalContentTimer, digitalContentAvailability: data.digitalContentAvailability, DigitalContent: data.digitalContent })),
        React.createElement(NotificationCalendarQueueManagerComponent, { Color: data.colors, Notifications: data.notifications }),
        React.createElement(CreateCalendarSocketQueueManager, null)));
};
export default CalendarQueueManagerComponent;
