import React from 'react';
import { useController, useFormContext } from 'react-hook-form';
import CloseIcon from '@mui/icons-material/Close';
import IconButtonComponent from '../../../../IconButton';
import useProductFieldStore from '../../../../../store/productField';
var ResetProductFieldButtonComponent = function (props) {
    var control = useFormContext().control;
    var onChange = useController({
        name: props.name,
        control: control,
    }).field.onChange;
    var _a = useProductFieldStore(), setValue = _a.setValue, value = _a.value;
    var handleClick = function () {
        setValue('');
        onChange(undefined);
    };
    if (!value)
        return React.createElement("div", null);
    return (React.createElement(IconButtonComponent, { action: handleClick, icon: React.createElement(CloseIcon, null), label: 'Limpiar', className: 'flex p-1', disabled: props.disabled }));
};
export default ResetProductFieldButtonComponent;
