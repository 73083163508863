var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import getInputErrorFormat from '../../../../../sale.v2/Action/helpers/getInputErrorFormat';
import getApolloQueryArgs from '../../../../../../apollo/helpers/getApolloQueryArgs';
var updateStatusCalendarApolloCache = function (cache, _a) {
    var data = _a.data;
    try {
        cache.modify({
            fields: {
                calendars: function (existingRefs, _a) {
                    var fieldName = _a.fieldName, storeFieldName = _a.storeFieldName, readField = _a.readField;
                    var args = getApolloQueryArgs(storeFieldName, fieldName);
                    var isPostponeCalendar = args.status && args.status.find(function (key) { return key === 6; });
                    if (isPostponeCalendar)
                        return __assign(__assign({}, existingRefs), { rows: existingRefs.rows.filter(function (existingRef) { return data.updateStatusCalendar.id !== readField('id', existingRef); }) });
                    var object = cache.identify({
                        __typename: 'Calendar',
                        id: data.updateStatusCalendar.id,
                    });
                    return __assign(__assign({}, existingRefs), { rows: __assign(__assign({}, existingRefs.rows), { object: object }) });
                },
            },
        });
    }
    catch (error) {
        getInputErrorFormat(error);
    }
};
export default updateStatusCalendarApolloCache;
