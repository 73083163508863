import { gql } from '@apollo/client';

const GET = gql`
  query Quotes(
    $limit: Int
    $offset: Int
    $start: Date
    $end: Date
    $status: [Int]
    $customerId: ID
    $cashierId: ID
    $sellerId: ID
    $branchId: ID
    $order: [String]
  ) {
    quotes(
      limit: $limit
      offset: $offset
      start: $start
      end: $end
      status: $status
      customerId: $customerId
      cashierId: $cashierId
      sellerId: $sellerId
      branchId: $branchId
      order: $order
    ) {
      count
      totals {
        id
        name
        qty
        amount
      }
      rows {
        id
        status
        serial
        folio
        commentary
        currencyKey
        exchangeRate
        subtotal
        discount
        taxes
        total
        ingredients
        commission
        branchId
        saleId
        customerId
        couponId
        sellerId
        cashierId
        companyId
        createdAt
        expireDate
        pdfFileId
        Customer {
          firstName
          lastName
        }
        Cashier {
          firstName
          lastName
        }
        TechnicalServiceOrder {
          serial
        }
      }
    }
  }
`;

const GET_BY_ID = gql`
  query Quote($id: ID!) {
    quote(id: $id) {
      id
      status
      serial
      folio
      commentary
      currencyKey
      exchangeRate
      subtotal
      discount
      taxes
      total
      ingredients
      commission
      fileId
      expireDate
      showTaxes
      QuoteProducts {
        id
        quantity
        unitPrice
        withoutTaxes
        subtotal
        discount
        discountRate
        taxes
        total
        commentary
        priceName
        priceKey
        ingredients
        cost
        status
        quoteId
        saleProductId
        productId
        branchId
        companyId
        promotionId
        productPromotionId
        productPresentationId
        iva
        ieps
        Product {
          cost
          sku
          name
          fileId
          unitKey
          productKey
        }
        ProductPresentation {
          name
        }
      }
      branchId
      saleId
      customerId
      couponId
      sellerId
      cashierId
      companyId
      createdAt
      Customer {
        id
        firstName
        lastName
        addressLine1
        addressLine2
        zipCode
        city
        state
        phone
        CustomerBillingInformation {
          identifier
          businessName
          email
          altEmail
          otherEmail
        }
      }
      Company {
        id
        name
        logoFileId
      }
      Branch {
        addressLine1
        addressLine2
        zipCode
        city
        state
        phone
        altPhone
        name
      }
      Seller {
        firstName
        lastName
      }
      Cashier {
        firstName
        lastName
      }
    }
  }
`;

const DELETE = gql`
  mutation DeleteQuote($id: ID!, $reason: Int, $commentary: String) {
    deleteQuote(id: $id, reason: $reason, commentary: $commentary) {
      id
    }
  }
`;

const ADD_PRODUCT = gql`
  mutation UpdateQuote(
    $id: ID!
    $productId: ID!
    $quantity: Float!
    $discount: Float
    $price: Float
    $iva: Float
    $ieps: Float
  ) {
    updateQuote(
      id: $id
      Product: {
        productId: $productId
        quantity: $quantity
        discount: $discount
        price: $price
        iva: $iva
        ieps: $ieps
      }
    ) {
      id
      quantity
      unitPrice
      withoutTaxes
      subtotal
      discount
      taxes
      total
      commentary
      priceName
      priceKey
      ingredients
      cost
      status
      quoteId
      saleProductId
      productId
      branchId
      companyId
      promotionId
      productPromotionId
      productPresentationId
    }
  }
`;

const DELETE_PRODUCT = gql`
  mutation DeleteQuoteProduct($id: ID!) {
    deleteQuoteProduct(id: $id) {
      id
      subtotal
      taxes
      total
    }
  }
`;

const GENERATE_SALE = gql`
  mutation GenerateSaleQuote($id: ID!, $pin: String, $Transactions: [TransactionSaleData]!) {
    generateSaleQuote(input: { id: $id, pin: $pin, Transactions: $Transactions }) {
      id
      Sale {
        id
        serial
        paid
        folio
        subtotal
        discount
        taxes
        total
        category
        pdfFileId
        Customer {
          id
          firstName
          lastName
          addressLine1
          addressLine2
          zipCode
          city
          state
          phone
          priceName
        }
        AccountReceivable {
          id
          folio
          serial
          payment
          balance
        }
        SaleProducts {
          Crm {
            id
            customerId
            sessions
            sessionsTaken
            Product {
              name
            }
          }
        }
      }
    }
  }
`;

const GENERATE_SALE_INVOICE = gql`
  mutation GenerateSaleInvoiceQuote(
    $id: ID!
    $pin: String
    $Transactions: [TransactionSaleData]!
    $paymentMethod: String!
    $paymentMethodId: ID
    $useCfdi: String!
    $applyExtraTaxes: Boolean
    $identifier: String!
    $businessName: String!
    $addressLine1: String!
    $addressLine2: String!
    $zipCode: String!
    $city: String!
    $municipality: String!
    $state: String!
    $country: String!
    $email: String!
    $phone: String
    $relateUUID: String
    $relateType: String
  ) {
    generateSaleInvoiceQuote(
      input: {
        id: $id
        pin: $pin
        Transactions: $Transactions
        paymentMethod: $paymentMethod
        paymentMethodId: $paymentMethodId
        useCfdi: $useCfdi
        applyExtraTaxes: $applyExtraTaxes
        relateUUID: $relateUUID
        relateType: $relateType
        CustomerBillingInformation: {
          identifier: $identifier
          businessName: $businessName
          addressLine1: $addressLine1
          addressLine2: $addressLine2
          zipCode: $zipCode
          city: $city
          municipality: $municipality
          state: $state
          country: $country
          email: $email
          phone: $phone
        }
      }
    ) {
      id
      Sale {
        id
        serial
        paid
        folio
        subtotal
        discount
        taxes
        total
        category
        Customer {
          id
          firstName
          lastName
          addressLine1
          addressLine2
          zipCode
          city
          state
          phone
          priceName
        }
        AccountReceivable {
          id
          folio
          serial
          payment
          balance
        }
        SaleProducts {
          Crm {
            id
            customerId
            sessions
            sessionsTaken
            Product {
              name
            }
          }
        }
        Invoice {
          uuid
          serial
          folio
        }
      }
    }
  }
`;

const FRAGMENT_NEW_QUOTE_PRODUCT = gql`
  fragment NewQuoteProduct on QuoteProducts {
    id
    quantity
    unitPrice
    withoutTaxes
    subtotal
    discount
    taxes
    total
    commentary
    priceName
    priceKey
    ingredients
    cost
    status
    quoteId
    saleProductId
    productId
    branchId
    companyId
    promotionId
    productPromotionId
    productPresentationId
    Product {
      name
    }
  }
`;

const SEND_QUOTE = gql`
  mutation SendQuote($id: ID!, $emails: [String!]) {
    sendQuote(id: $id, emails: $emails)
  }
`;

const SEND_QUOTE_WHATSAPP = gql`
  mutation SendQuoteWhatsapp($id: ID!, $phone: String) {
    sendQuoteWhatsapp(id: $id, phone: $phone)
  }
`;

const UPDATE_COMMENTARY = gql`
  mutation UpdateQuoteCommentary($id: ID!, $commentary: String!) {
    updateQuoteCommentary(id: $id, commentary: $commentary) {
      id
      commentary
    }
  }
`;

const UPDATE_PRODUCT = gql`
  mutation UpdataQuoteProduct(
    $id: ID!
    $quantity: Float!
    $unitPrice: Float!
    $iva: Float
    $ieps: Float
  ) {
    updataQuoteProduct(
      id: $id
      input: { quantity: $quantity, unitPrice: $unitPrice, iva: $iva, ieps: $ieps }
    ) {
      id
      quantity
      unitPrice
      withoutTaxes
      subtotal
      discount
      taxes
      total
      commentary
      priceName
      priceKey
      ingredients
      cost
      status
      quoteId
      saleProductId
      productId
      branchId
      companyId
      promotionId
      productPromotionId
      productPresentationId
      Quote {
        total
        taxes
        subtotal
      }
    }
  }
`;

const ADD_FILE = gql`
  mutation AddFileQuote($id: ID!, $Image: Upload!) {
    addFileQuote(id: $id, Image: $Image) {
      fileId
    }
  }
`;

const DELETE_FILE = gql`
  mutation DeleteFileQuote($id: ID!) {
    deleteFileQuote(id: $id) {
      fileId
    }
  }
`;

const DUPLICATE_QUOTE = gql`
  mutation DuplicateQuote($id: ID!, $customerId: ID!, $expireDate: Date) {
    duplicateQuote(id: $id, customerId: $customerId, expireDate: $expireDate) {
      id
      status
      serial
      folio
      commentary
      currencyKey
      exchangeRate
      subtotal
      discount
      taxes
      total
      ingredients
      commission
      branchId
      saleId
      customerId
      couponId
      sellerId
      cashierId
      companyId
      createdAt
      expireDate
      pdfFileId
      Customer {
        firstName
        lastName
      }
      Cashier {
        firstName
        lastName
      }
    }
  }
`;

const UPDATE_QUOTE_PRODUCT_COMMENTARY = gql`
  mutation UpdateQuoteProductCommentary($id: ID!, $commentary: String!) {
    updateQuoteProductCommentary(id: $id, commentary: $commentary) {
      id
      commentary
    }
  }
`;

const UPDATE_QUOTE_TECHNICAL_SERVICE_ORDER = gql`
  mutation UpdateQuoteTechnicalServiceOrder($id: ID!, $technicalServiceOrderId: ID!) {
    updateQuoteTechnicalServiceOrder(id: $id, technicalServiceOrderId: $technicalServiceOrderId) {
      id
    }
  }
`;

const SEND_TO_BUILDING = gql`
  mutation SendToBuilding($quoteId: ID!, $Products: [QuoteBuildingProductData]) {
    sendToBuilding(id: $quoteId, Products: $Products) {
      id
      status
      serial
      folio
      commentary
      currencyKey
      exchangeRate
      subtotal
      discount
      taxes
      total
      ingredients
      commission
      branchId
      saleId
      customerId
      couponId
      sellerId
      cashierId
      companyId
      createdAt
      expireDate
      pdfFileId
      Customer {
        firstName
        lastName
      }
      Cashier {
        firstName
        lastName
      }
      TechnicalServiceOrder {
        serial
      }
    }
  }
`;

const SELL_QUOTE = gql`
  mutation SellQuote(
    $id: ID!
    $isInvoice: Boolean!
    $pin: String
    $Transactions: [TransactionSaleData]
    $paymentMethod: String
    $paymentForm: String
    $useCfdi: String
    $applyExtraTaxes: Boolean
    $relateUUID: String
    $relateType: String
    $customerId: ID
    $branchSaleId: ID
    $branchInventoryId: ID
  ) {
    sellQuote(
      id: $id
      input: {
        isInvoice: $isInvoice
        pin: $pin
        Transactions: $Transactions
        paymentMethod: $paymentMethod
        paymentForm: $paymentForm
        useCfdi: $useCfdi
        applyExtraTaxes: $applyExtraTaxes
        relateUUID: $relateUUID
        relateType: $relateType
        customerId: $customerId
        branchSaleId: $branchSaleId
        branchInventoryId: $branchInventoryId
      }
    ) {
      id
      Sale {
        id
        serial
        paid
        folio
        subtotal
        discount
        taxes
        total
        category
        branchId
        pdfFileId
        Seller {
          id
          firstName
          lastName
        }
        Transactions {
          id
          total
          paymentMethodId
          clientCreatedAt
          createdAt
          currency
          exchangeRate
          usdTotal
          transactionAccountId
          invoiceComplementId
          commentary
          TransactionAccount {
            id
            name
          }
          PaymentMethod {
            id
            name
          }
        }
        Customer {
          id
          firstName
          lastName
          addressLine1
          addressLine2
          zipCode
          city
          state
          phone
          priceName
          creditDays
        }
        AccountReceivable {
          id
          folio
          serial
          payment
          balance
          limitDate
          clientCreatedAt
          timeLimit
          background
          exchangeRate
        }
        SaleProducts {
          Crm {
            id
            customerId
            sessions
            sessionsTaken
            Product {
              name
            }
          }
        }
        Invoice {
          uuid
          serial
          folio
        }
      }
    }
  }
`;

export default {
  GET,
  GET_BY_ID,
  DELETE,
  ADD_PRODUCT,
  DELETE_PRODUCT,
  FRAGMENT_NEW_QUOTE_PRODUCT,
  GENERATE_SALE,
  GENERATE_SALE_INVOICE,
  SEND_QUOTE,
  UPDATE_PRODUCT,
  ADD_FILE,
  DELETE_FILE,
  UPDATE_COMMENTARY,
  DUPLICATE_QUOTE,
  UPDATE_QUOTE_PRODUCT_COMMENTARY,
  UPDATE_QUOTE_TECHNICAL_SERVICE_ORDER,
  SEND_TO_BUILDING,
  SEND_QUOTE_WHATSAPP,
  SELL_QUOTE,
};
