import React from 'react';
import { useReactiveVar } from '@apollo/client';

import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';

import { currencyFormat } from '../../../../../../helpers';

import { form } from '../../../../../../apollo/cache';

const TotalsComponent = () => {
  const formVar = useReactiveVar(form);

  return (
    <>
      <TableRow>
        <TableCell colSpan={7} align='right' className='bg-[#f5f5f5]'>
          Subtotal:
        </TableCell>
        <TableCell align='right' className='bg-[#f5f5f5]'>
          {`$${currencyFormat(formVar.subtotal)}`}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell colSpan={7} align='right' className='bg-[#f5f5f5]'>
          IVA:
        </TableCell>
        <TableCell align='right' className='bg-[#f5f5f5]'>
          {`$${currencyFormat(formVar.iva)}`}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell colSpan={7} align='right' className='bg-[#f5f5f5]'>
          IEPS:
        </TableCell>
        <TableCell align='right' className='bg-[#f5f5f5]'>
          {`$${currencyFormat(formVar.ieps)}`}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell colSpan={7} align='right' className='bg-[#f5f5f5]'>
          <strong>Total:</strong>
        </TableCell>
        <TableCell align='right' className='bg-[#f5f5f5]'>
          <strong>{`$${currencyFormat(formVar.total)}`}</strong>
        </TableCell>
      </TableRow>
    </>
  );
};

export default TotalsComponent;
