import React, { useState } from 'react';

import { useMutation, useReactiveVar } from '@apollo/client';

import Typography from '@mui/material/Typography';

import FormDialog from '../../presentationals/FormDialog';
import GQL from './_gql';
import getInputErrorFormat from './Action/helpers/getInputErrorFormat';
import TextFieldComponent from '../../presentationals/FormComponents/TextField';
import { isPettyCashOpen } from '../../apollo/cache';

const defaultValues = {
  pettyCashAmount: '',
};

const PettyCash = () => {
  const isOpen = useReactiveVar(isPettyCashOpen);
  const [values, setValues] = useState(defaultValues);

  const [handleCreatePettyCash, { loading }] = useMutation(GQL.CREATE_PETTY_CASH, {
    update: cache => {
      cache.writeQuery({
        query: GQL.GET_OPEN_PETTY_CASH,
        data: { openPettyCash: true, __typename: 'OpenPettyCash' },
      });
    },
  });

  const resetValues = () => {
    setValues(defaultValues);
  };

  const handleAction = async () => {
    try {
      const variables = {
        pettyCashAmount: !isNaN(parseFloat(values.pettyCashAmount))
          ? parseFloat(values.pettyCashAmount)
          : 0,
      };
      await handleCreatePettyCash({ variables });
      resetValues();
      isPettyCashOpen(false);
    } catch (e) {
      getInputErrorFormat(e);
    }
  };

  const handleChange = (name, value) => {
    setValues(values => ({ ...values, [name]: value }));
  };

  return (
    <FormDialog
      isOpen={isOpen}
      isLoading={loading}
      handleAction={handleAction}
      hideClose
      actionLabel='iniciar jornada'
    >
      <div>
        <Typography variant='h5' gutterBottom>
          ¡Encontramos un detalle!
        </Typography>
        <Typography variant='subtitle1'>
          Detectamos que recientemente se realizó un corte de caja, para realizar una venta, debes
          iniciar una jornada laboral.
        </Typography>
        <Typography variant='subtitle1'>
          Si deseas contemplar la cantidad inicial en caja chica al finalizar el corte de caja,
          puedes propocionarla a continuación, de lo contrario, dejar vacío el campo.
        </Typography>
        <TextFieldComponent
          label='Cantidad inicial en caja chica'
          name='pettyCashAmount'
          value={values.pettyCashAmount}
          onChange={handleChange}
          type='number'
        />
      </div>
    </FormDialog>
  );
};

export default PettyCash;
