var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import RHFormComponent from '../../../../presentationals/RHForm/Form/indext';
import RHFTextFieldComponent from '../../../../presentationals/RHForm/TextField';
import FooterFormComponent from '../../../../presentationals/RHForm/Form/Footer';
import RHFButtonComponent from '../../../../presentationals/RHForm/Button';
import getInputErrorFormat from '../../../sale.v2/Action/helpers/getInputErrorFormat';
import useApolloMutation from '../../../../hooks/useApolloMutation';
import useSnackbarDialog from '../../../../store/snackbar/snackbarDialog';
import GQL_ADJUST_INVENTORY from '../../../../apollo/gql/adjustInventory';
var CancelAdjustInventoryComponent = function () {
    var formRef = useRef(null);
    var params = useParams();
    var navigate = useNavigate();
    var setSnackbar = useSnackbarDialog(function (state) { return state.setSnackbar; });
    var _a = useApolloMutation(GQL_ADJUST_INVENTORY.DELETE_ADJUST_INVENTORY, {
        action: 'delete',
        mutationName: 'undoAdjustInventory',
    }), deleteManufacture = _a[0], loading = _a[1].loading;
    var handleSubmit = function (values) { return __awaiter(void 0, void 0, void 0, function () {
        var error_1;
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    _b.trys.push([0, 2, , 3]);
                    if (values.confirmation !== 'acepto')
                        throw new Error('La palabra de confirmación es incorrecta.');
                    (_a = formRef.current) === null || _a === void 0 ? void 0 : _a.reset();
                    return [4 /*yield*/, deleteManufacture({
                            variables: {
                                id: params.adjustInventoryId,
                            },
                        })];
                case 1:
                    _b.sent();
                    setSnackbar({
                        isOpen: true,
                        label: 'El ajuste de inventario se eliminó exitosamente.',
                        severity: 'success',
                    });
                    navigate('../../');
                    return [3 /*break*/, 3];
                case 2:
                    error_1 = _b.sent();
                    getInputErrorFormat(error_1);
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    return (React.createElement(RHFormComponent, { ref: formRef, onSubmit: handleSubmit, defaultValues: { confirmation: '' } },
        React.createElement("div", { className: 'grid grid-cols-1 sm:grid-cols-3 flex-1 px-4 py-3' },
            React.createElement("div", { className: 'flex flex-1 flex-col' },
                React.createElement("div", { className: 'text-base font-medium mb-1' }, "Cancelar ajuste de inventario"),
                React.createElement("div", { className: 'text-sm sm:text-base text-gray-600 leading-none mt-1 mb-2' },
                    "Para eliminar este registro es necesario que escribas en la palabra",
                    ' ',
                    React.createElement("strong", null, "acepto"),
                    "."),
                React.createElement(RHFTextFieldComponent, { autoFocus: true, name: 'confirmation', label: 'Palabra de confirmaci\u00F3n' }))),
        React.createElement(FooterFormComponent, null,
            React.createElement("div", null),
            React.createElement(RHFButtonComponent, { disabled: loading }, "Cancelar"))));
};
export default CancelAdjustInventoryComponent;
