import React, { useEffect, useState } from 'react';
import { Tabs, Tab } from '@mui/material';
import GridViewOutlinedIcon from '@mui/icons-material/GridViewOutlined';
import AppsIcon from '@mui/icons-material/Apps';
import PriceChangeOutlinedIcon from '@mui/icons-material/PriceChangeOutlined';
import { useLocation, useNavigate } from 'react-router-dom';
import ProductionQuantityLimitsIcon from '@mui/icons-material/ProductionQuantityLimits';
import getRouteFromProductPresentationMenuValue from './getRouteFromMenuValue';
import getProductPresentationMenuValueFromPath from './getMenuValueFromPath';
var MenuProducPresentationtLayoutComponent = function () {
    var navigate = useNavigate();
    var location = useLocation();
    var _a = useState(0), value = _a[0], setValue = _a[1];
    useEffect(function () {
        var value = getProductPresentationMenuValueFromPath(location.pathname);
        setValue(value);
    }, []);
    var handleChange = function (e, value) {
        setValue(value);
        var route = getRouteFromProductPresentationMenuValue(value);
        if (route)
            navigate(route);
    };
    return (React.createElement(Tabs, { value: value, onChange: handleChange, variant: 'scrollable', scrollButtons: 'auto', className: 'flex bg-slate-200 border-0 border-b border-[#d6d6d6] border-solid' },
        React.createElement(Tab, { icon: React.createElement(GridViewOutlinedIcon, null), label: 'Datos generales' }),
        React.createElement(Tab, { icon: React.createElement(AppsIcon, null), label: 'Componentes' }),
        React.createElement(Tab, { icon: React.createElement(PriceChangeOutlinedIcon, null), label: 'Tarifas' }),
        React.createElement(Tab, { icon: React.createElement(ProductionQuantityLimitsIcon, null), label: 'L\u00EDmite de inventario' })));
};
export default MenuProducPresentationtLayoutComponent;
