var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useEffect, useState } from 'react';
import PointOfSaleIcon from '@mui/icons-material/PointOfSale';
import ListItemPermissionEditRoleComponent from '../helpers/ListItem';
import SwitchPermissionFieldComponent from '../helpers/SwitchPermissionField';
import getDefaultBranchOptions from '../helpers/getDefaultBrachOptions';
import SelectPermissionFieldComponent from '../helpers/SelectPermissionField';
import getDefaultDateFilterOptions from '../helpers/getDefaultDateFilterOptions';
import CheckboxPermissionFieldComponent from '../helpers/CheckboxPermissionField';
var CashFlowPermissionEditRoleComponent = function (props) {
    var defaultCashFlowPermissionValues = {
        createNewCashFlow: false,
        getCashFlow: 0,
        getFilterCashFlow: 1,
        deleteCashFlow: 0,
        getCashFlowActionAllowed: [],
    };
    var getCashFlowActionAllowedOptions = [
        { name: 1, label: 'Retiro' },
        { name: 2, label: 'Depósito' },
        { name: 3, label: 'Caja fuerte' },
    ];
    var defaultBranchOptions = getDefaultBranchOptions();
    var defaultDateFilterOptions = getDefaultDateFilterOptions();
    var _a = useState(defaultCashFlowPermissionValues), values = _a[0], setValues = _a[1];
    useEffect(function () {
        setValues(function (values) { return (__assign(__assign({}, values), props.Permissions)); });
    }, [props.Permissions]);
    return (React.createElement(ListItemPermissionEditRoleComponent, { icon: React.createElement(PointOfSaleIcon, null), name: 'Movimientos de caja' },
        React.createElement("div", { className: 'mx-4 py-3 grid grid-cols-1 md:grid-cols-3 gap-4' },
            React.createElement("div", { className: 'flex flex-col' },
                React.createElement(SwitchPermissionFieldComponent, __assign({}, props, { name: 'createNewCashFlow', label: 'Crear movimientos de caja', defaultValue: values.createNewCashFlow })),
                React.createElement(SelectPermissionFieldComponent, __assign({}, props, { name: 'getCashFlow', label: 'Consulta de movimientos de caja', defaultValue: values.getCashFlow, options: defaultBranchOptions })),
                React.createElement(SelectPermissionFieldComponent, __assign({}, props, { name: 'getFilterCashFlow', label: 'Rango de fechas para consulta de datos', defaultValue: values.getFilterCashFlow, options: defaultDateFilterOptions })),
                React.createElement(SelectPermissionFieldComponent, __assign({}, props, { name: 'deleteCashFlow', label: 'Eliminar movimientos de caja', defaultValue: values.deleteCashFlow, options: defaultBranchOptions })),
                React.createElement(CheckboxPermissionFieldComponent, __assign({}, props, { name: 'getCashFlowActionAllowed', label: 'Tipos de movimientos de caja habilitados para consulta (web)', defaultValue: values.getCashFlowActionAllowed, options: getCashFlowActionAllowedOptions }))))));
};
export default CashFlowPermissionEditRoleComponent;
