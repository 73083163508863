import React, { useEffect, useState } from 'react';

import { useReactiveVar } from '@apollo/client';

import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import EditIcon from '@mui/icons-material/Edit';

import { Button, IconButton, InputBase, Paper } from '@mui/material';

import useApolloLazyQuery from '../../../../hooks/useApolloLazyQuery';

import GQL from '../../../product.v2/_gql';
import {
  isSaleProductFormOpen,
  productListSaleV2,
  searchProductField,
  productSaleV2,
  editProductSaleV2,
  addToCartSaleV2,
} from '../../../../apollo/cache';
import getProductListFormatted from '../../helpers/getProductListFormatted';
import getDefaultValues from '../ProductDialog/Create/Body/helpers/getDefaultValues';
import SelectFieldComponent from '../../../../presentationals/FormComponents/SelectField';
import getLocalStorageSearchProductBy from '../../../../helpers/getLocalStorageSearchProductBy';
import setLocalStorageSearchProductBy from '../../../../helpers/setLocalStorageSearchProductBy';
import getInputErrorFormat from '../../Action/helpers/getInputErrorFormat';
import { searchProductsByOptions } from '../../../../presentationals/FormComponents/SearchProductBySelectField';
import GQL_PRODUCT_INVENTORY from '../../../../apollo/gql/productInventory';

const ProductFormChooseProductSaleV2Component = () => {
  const searchProductFieldVar = useReactiveVar(searchProductField);
  const productSaleV2Var = useReactiveVar(productSaleV2);
  const [searchBy, setSearchBy] = useState('');
  const [value, setValue] = useState('');
  const [serialNumber, setSerialNumber] = useState('');
  const [getData, { loading }] = useApolloLazyQuery(GQL.GET_LITE, { variables: { lite: true } });
  const [get, { loading: loadingById }] = useApolloLazyQuery(GQL.GET_BY_ID);
  const [getSerialNumbers, { loadingSerialNumbers, data: dataSerialNumbers }] = useApolloLazyQuery(
    GQL_PRODUCT_INVENTORY.GET_SERIAL_NUMBER_BY_PRODUCT_ID,
  );

  useEffect(() => {
    setSearchBy(getLocalStorageSearchProductBy());
    return () => {
      searchProductField('');
    };
  }, []);

  useEffect(() => {
    setValue(searchProductFieldVar);
  }, [searchProductFieldVar]);

  useEffect(() => {
    if (productSaleV2Var?.id) getSerialNumbers({ variables: { productId: productSaleV2Var.id } });
  }, [productSaleV2Var]);

  const handleChange = e => {
    const { value } = e.target;
    setValue(value);
  };
  const handleSerialNumberChange = (name, value) => {
    setSerialNumber(value);
    productSaleV2({ ...productSaleV2Var, serialNumber: value });
  };

  const handleClick = async action => {
    try {
      if (value.length > 0) {
        const { data } = await getData({ variables: { name: [searchBy, value], limit: 999 } });
        if (data.rows.length === 1) {
          const { data: response } = await get({ variables: { id: data.rows[0].id } });
          const Product = { ...response, cost: response.PresentationBase.cost };
          productSaleV2({ ...Product, unitBase: response.PresentationBase.name });
          searchProductField(response.name);
          setValue(response.name);
          if (action !== 'enter') {
            isSaleProductFormOpen(true);
            const tmpProduct1 = getProductListFormatted([Product]);
            productListSaleV2(tmpProduct1);
          }
        } else {
          isSaleProductFormOpen(true);
          const tmpProduct = getProductListFormatted(data.rows);
          productListSaleV2(tmpProduct);
          searchProductField(value);
          setValue(value);
        }
      } else {
        isSaleProductFormOpen(true);
        productListSaleV2([]);
      }
    } catch (e) {
      getInputErrorFormat(e);
    }
  };
  const handleKeyDown = e => {
    if (e.keyCode === 13) {
      e.preventDefault();
      if (productSaleV2Var.id) {
        addToCartSaleV2(true);
        return;
      }
      handleClick('enter');
    }
  };
  const handleEdit = () => {
    editProductSaleV2(true);
    isSaleProductFormOpen(true);
  };
  const handleReset = () => {
    productSaleV2(getDefaultValues());
    searchProductField('');
    setValue('');
    productListSaleV2([]);
  };
  const handleSelectChange = (name, value) => {
    setSearchBy(value);
    setLocalStorageSearchProductBy(value);
  };
  return (
    <div className='flex flex-1 items-center mb-1'>
      <div className='flex flex-1 pr-2 items-center'>
        <div className='flex mr-1'>
          <SelectFieldComponent
            name='searchBy'
            value={searchBy}
            defaultLabel='Búsqueda por'
            options={searchProductsByOptions}
            onChange={handleSelectChange}
          />
        </div>
        <div className='flex flex-1'>
          <Paper variant='outlined' className='px-2 w-full flex h-10 items-center border-[#c5c5c5]'>
            <InputBase
              placeholder='Búsqueda del producto'
              autoFocus
              size='small'
              name='search'
              value={value}
              autoComplete='off'
              className='flex py-0 my-0 flex-1'
              inputProps={{ 'aria-label': 'search-field' }}
              onChange={handleChange}
              onKeyDown={handleKeyDown}
            />
            {value.length > 0 && (
              <IconButton size='small' onClick={handleReset} aria-label='search' className='flex'>
                <ClearIcon />
              </IconButton>
            )}
            {productSaleV2Var.id && (
              <IconButton size='small' onClick={handleEdit} aria-label='search' className='flex'>
                <EditIcon />
              </IconButton>
            )}
          </Paper>
        </div>
        {productSaleV2Var.isSerialized && (
          <div className='flex pl-1 w-44'>
            <SelectFieldComponent
              disabled={loadingSerialNumbers}
              defaultLabel='Número de serie'
              name='serialNumber'
              onChange={handleSerialNumberChange}
              value={serialNumber}
              options={
                dataSerialNumbers
                  ? dataSerialNumbers.map(el => ({
                      value: el.serialNumber,
                      label: el.serialNumber,
                    }))
                  : []
              }
            />
          </div>
        )}
      </div>
      <div className='h-full bg-slate-200'>
        <Button
          disabled={loading || loadingById}
          size='small'
          color='secondary'
          variant='contained'
          className='py-2'
          onClick={handleClick}
        >
          <SearchIcon />
        </Button>
      </div>
    </div>
  );
};

export default ProductFormChooseProductSaleV2Component;
