import React, { useEffect } from 'react';
import { useLazyQuery, useReactiveVar } from '@apollo/client';

import LinearProgress from '@mui/material/LinearProgress';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import { filter } from '../../../apollo/cache';
import { currencyFormat } from '../../../helpers';

import GQL from '../_gql';

const DataTable = () => {
  const filterVar = useReactiveVar(filter);

  const [getMore, { data: response, loading }] = useLazyQuery(GQL.GET);

  useEffect(() => {
    getMore({ variables: { ...filterVar } });
  }, [filterVar]);

  return (
    <>
      {loading && <LinearProgress color='secondary' />}
      <Table size='small'>
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell align='right'>Total</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell className='border-none py-1 h-[50px] hover:bg-trasnparent'>Ventas</TableCell>
            <TableCell className='border-none py-1 h-[50px] hover:bg-trasnparent' align='right'>
              {`$ ${
                response && response.incomeStatement
                  ? currencyFormat(response.incomeStatement.sales)
                  : 0
              } MXN`}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className='border-none py-1 h-[50px] hover:bg-trasnparent'>
              Compras
            </TableCell>
            <TableCell className='border-none py-1 h-[50px] hover:bg-trasnparent' align='right'>
              {`$ ${
                response && response.incomeStatement
                  ? currencyFormat(response.incomeStatement.purchaseOrders)
                  : 0
              } MXN`}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className='border-none py-1 h-[50px] bg-[#d3d3d3]'>Utilidad Bruta</TableCell>
            <TableCell className='border-none py-1 h-[50px] bg-[#d3d3d3]' align='right'>
              {`$ ${
                response && response.incomeStatement
                  ? currencyFormat(response.incomeStatement.grossProfit)
                  : 0
              } MXN`}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className='border-none py-1 h-[50px] hover:bg-trasnparent'>
              Gastos Operativos
            </TableCell>
            <TableCell className='border-none py-1 h-[50px] hover:bg-trasnparent' align='right'>
              {`$ ${
                response && response.incomeStatement
                  ? currencyFormat(response.incomeStatement.operatingExpenses)
                  : 0
              } MXN`}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className='border-none py-1 h-[50px] bg-[#d3d3d3]'>
              Utilidad Antes de Impuestos
            </TableCell>
            <TableCell className='border-none py-1 h-[50px] bg-[#d3d3d3]' align='right'>
              {`$ ${
                response && response.incomeStatement
                  ? currencyFormat(response.incomeStatement.ebitda)
                  : 0
              } MXN`}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className='border-none py-1 h-[50px] hover:bg-trasnparent'>
              Impuestos
            </TableCell>
            <TableCell className='border-none py-1 h-[50px] hover:bg-trasnparent' align='right'>
              {`$ ${
                response && response.incomeStatement
                  ? currencyFormat(response.incomeStatement.taxes)
                  : 0
              } MXN`}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className='border-none py-1 h-[50px] bg-[#d3d3d3]'>Utilidad Neta</TableCell>
            <TableCell className='border-none py-1 h-[50px] bg-[#d3d3d3]' align='right'>
              {`$ ${
                response && response.incomeStatement
                  ? currencyFormat(response.incomeStatement.netProfit)
                  : 0
              } MXN`}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </>
  );
};

export default DataTable;
