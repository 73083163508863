var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { gql } from '@apollo/client';
import getPurchaseOrderTableColumnConfiguration from '../../../helpers/getPurchaseOrderTableColumnConfiguration';
import getPurchaseOrderGqlColumn from './helpers/getPurchaseOrderGqlColumn';
var CUSTOMIZED_GET_PURCHASE_ORDERS = function (customizedColumns) {
    var settings = customizedColumns
        ? __assign(__assign({}, customizedColumns), { total: true }) : getPurchaseOrderTableColumnConfiguration();
    var settingsKeys = Object.keys(settings);
    var columns = settingsKeys
        .filter(function (key) { return settings[key]; })
        .map(function (key) { return getPurchaseOrderGqlColumn(key); })
        .join(' ');
    return gql(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  query PurchaseOrders(\n    $branchId: ID\n    $paymentForm: String\n    $name: String\n    $limit: Int\n    $offset: Int\n    $status: Int\n    $start: Date\n    $end: Date\n    $order: [String]\n    $providerId: ID\n    $type: Int\n  ) {\n    purchaseOrders(\n      branchId: $branchId\n      paymentForm: $paymentForm\n      like: $name\n      limit: $limit\n      offset: $offset\n      status: $status\n      start: $start\n      end: $end\n      order: $order\n      providerId: $providerId\n      type: $type\n    ) {\n        count\n        rows {\n          id\n          total\n          ", "\n        }\n      }\n    }\n    "], ["\n  query PurchaseOrders(\n    $branchId: ID\n    $paymentForm: String\n    $name: String\n    $limit: Int\n    $offset: Int\n    $status: Int\n    $start: Date\n    $end: Date\n    $order: [String]\n    $providerId: ID\n    $type: Int\n  ) {\n    purchaseOrders(\n      branchId: $branchId\n      paymentForm: $paymentForm\n      like: $name\n      limit: $limit\n      offset: $offset\n      status: $status\n      start: $start\n      end: $end\n      order: $order\n      providerId: $providerId\n      type: $type\n    ) {\n        count\n        rows {\n          id\n          total\n          ", "\n        }\n      }\n    }\n    "])), columns);
};
export default CUSTOMIZED_GET_PURCHASE_ORDERS;
var templateObject_1;
