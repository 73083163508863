import React from 'react';
import SettingsIcon from '@mui/icons-material/Settings';
import IconButtonComponent from '../../../presentationals/IconButton';
import useSelectBranchesDialogStore from '../../../store/multiCompanyReport/selectBranchesDialogStore';
var SelectBranchesButtonComponent = function () {
    var setOpen = useSelectBranchesDialogStore(function (state) { return state.setOpen; });
    var handleClick = function () {
        setOpen(true);
    };
    return (React.createElement(IconButtonComponent, { action: handleClick, icon: React.createElement(SettingsIcon, null), size: 'large', label: 'Configurar sucursales a mostrar' }));
};
export default SelectBranchesButtonComponent;
