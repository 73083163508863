import React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { IconButton } from '@mui/material';
import { isCalendarFormOpen } from '../../../../../apollo/cache';
var CloseButtonHeaderConfirmDialogSaleV2Component = function () {
    var handleClose = function () {
        isCalendarFormOpen([false, {}]);
    };
    return (React.createElement(IconButton, { onClick: handleClose },
        React.createElement(CloseIcon, null)));
};
export default CloseButtonHeaderConfirmDialogSaleV2Component;
