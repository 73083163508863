import { transform } from './general';
import getSession from './getSession';

export const customerLabel = props => {
  const session = getSession();
  let label = session?.Company?.activity === 3 ? 'paciente' : 'cliente';
  label = props && props.term === 'plural' ? `${label}s` : label;
  return transform(label, props && props.textTransform);
};

export const activityLabel = activity => {
  const session = getSession();
  const companyActivity = activity
    ? parseInt(activity)
    : session?.Company?.activity
    ? parseInt(session?.Company?.activity)
    : 1;
  switch (companyActivity) {
    case 1:
      return 'Comercio';
    case 2:
      return 'Restaurante';
    case 3:
      return 'Salud';
    case 4:
      return 'Food Truck';
    default:
      return '';
  }
};
