import React, { useRef } from 'react';
import { Card } from '@mui/material';
import OpenAccountReceivableComponent from './OpenAccountReceivable';
import FooterDialogComponent from '../../../../../presentationals/Dialog/Footer';
import CreateMultiPaymentButton from './CreateButton';
import PaymentFormMultiPaymentDialogComponent from './PaymentForm';
var BodyMultiPaymentDialogComponent = function (props) {
    var paymentFormRef = useRef(null);
    var handleClick = function () { return (paymentFormRef === null || paymentFormRef === void 0 ? void 0 : paymentFormRef.current) && paymentFormRef.current.getData(); };
    return (React.createElement("div", { className: 'flex flex-1 flex-col' },
        React.createElement("div", { className: 'grid flex-1 grid-cols-1 sm:grid-cols-4 gap-1 px-4 py-3 basis-0 overflow-y-auto' },
            React.createElement("div", { className: 'flex flex-1 flex-col' },
                React.createElement(PaymentFormMultiPaymentDialogComponent, { ref: paymentFormRef })),
            React.createElement("div", { className: 'col-span-3' },
                React.createElement("div", { className: 'text-base' }, "Selecciona una o m\u00E1s cuentas por cobrar para asignar el importe a abonar correspondiente."),
                React.createElement(Card, { className: 'flex flex-1 flex-row basis-0 overflow-y-auto mt-1' },
                    React.createElement(OpenAccountReceivableComponent, null)))),
        React.createElement(FooterDialogComponent, null,
            React.createElement("div", null),
            React.createElement(CreateMultiPaymentButton, { onClick: handleClick, onSuccess: props.onSuccess }))));
};
export default BodyMultiPaymentDialogComponent;
