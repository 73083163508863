var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useEffect, useState } from 'react';
import ProductionQuantityLimitsOutlinedIcon from '@mui/icons-material/ProductionQuantityLimitsOutlined';
import ListItemPermissionEditRoleComponent from '../helpers/ListItem';
import SwitchPermissionFieldComponent from '../helpers/SwitchPermissionField';
import SelectPermissionFieldComponent from '../helpers/SelectPermissionField';
import getDefaultBranchOptions from '../helpers/getDefaultBrachOptions';
var defaultRequisitionPermissionValues = {
    createNewRequisition: false,
    editRequisition: 0,
    closeRequisition: 0,
    getRequisitions: 0,
    deleteRequisition: 0,
};
var RequisitionPermissionEditRoleComponent = function (props) {
    var defaultBranchOptions = getDefaultBranchOptions();
    var _a = useState(defaultRequisitionPermissionValues), values = _a[0], setValues = _a[1];
    useEffect(function () {
        setValues(function (values) { return (__assign(__assign({}, values), props.Permissions)); });
    }, [props.Permissions]);
    return (React.createElement(ListItemPermissionEditRoleComponent, { icon: React.createElement(ProductionQuantityLimitsOutlinedIcon, null), name: 'Requisici\u00F3n de mercanc\u00EDa' },
        React.createElement("div", { className: 'mx-4 py-3 grid grid-cols-1 md:grid-cols-3 gap-4' },
            React.createElement("div", { className: 'flex flex-col' },
                React.createElement(SwitchPermissionFieldComponent, __assign({}, props, { name: 'createNewRequisition', label: 'Crear requisiciones de mercanc\u00EDa', defaultValue: values.createNewRequisition })),
                React.createElement(SelectPermissionFieldComponent, __assign({}, props, { name: 'editRequisition', label: 'Editar contenido de las requisiciones de mercanc\u00EDa', defaultValue: values.editRequisition, options: defaultBranchOptions })),
                React.createElement(SelectPermissionFieldComponent, __assign({}, props, { name: 'closeRequisition', label: 'Cerrar requisiciones de mercanc\u00EDa', defaultValue: values.closeRequisition, options: defaultBranchOptions })),
                React.createElement(SelectPermissionFieldComponent, __assign({}, props, { name: 'getRequisitions', label: 'Consultar datos generales y contenido de las requisiciones de mercanc\u00EDa', defaultValue: values.getRequisitions, options: defaultBranchOptions })),
                React.createElement(SelectPermissionFieldComponent, __assign({}, props, { name: 'deleteRequisition', label: 'Eliminar requisiciones de mercanc\u00EDa', defaultValue: values.deleteRequisition, options: defaultBranchOptions }))))));
};
export default RequisitionPermissionEditRoleComponent;
