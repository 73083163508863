var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useEffect, useState } from 'react';
import LoadingProgress from '../../../presentationals/LoadingProgress';
import ErrorDataComponent from '../../../presentationals/ErrorData';
import BodyModuleV3Component from '../../../presentationals/Module.v3/Body';
import RedirectEditFormToRouteComponent from '../../../presentationals/RedirectEditFormToRoute';
import { dateTimeFormat } from '../../../helpers';
var ListRentComponent = function (props) {
    var _a = useState([]), data = _a[0], setData = _a[1];
    useEffect(function () {
        var data = props.data.map(function (el) {
            return __assign(__assign({}, el), { start: el.start ? dateTimeFormat(el.start, 'dd/MM/yyyy HH:mm') : '-', end: el.end ? dateTimeFormat(el.end, 'dd/MM/yyyy HH:mm') : '-' });
        });
        setData(data);
    }, [props.data]);
    var Header = [
        { Header: 'Creado el', accessor: 'createdAt' },
        { Header: 'Folio', accessor: 'folio' },
        { Header: 'Cliente', accessor: 'customer' },
        { Header: 'Estatus', accessor: 'deliveryStatus' },
        { Header: 'Importe', accessor: 'total' },
        { Header: 'Entrega', accessor: 'start' },
        { Header: 'Recolección', accessor: 'end' },
    ];
    if (props.loading)
        return React.createElement(LoadingProgress, null);
    if (!props.data)
        return React.createElement(ErrorDataComponent, null);
    return (React.createElement(React.Fragment, null,
        React.createElement(BodyModuleV3Component, { isLoading: props.loading, header: Header, data: data }),
        React.createElement(RedirectEditFormToRouteComponent, { path: '/panel/rent/' })));
};
export default ListRentComponent;
