import React, { useEffect } from 'react';

import { useReactiveVar, useMutation } from '@apollo/client';

import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Typography from '@mui/material/Typography';

import {
  form,
  handleContinue,
  step,
  actionForm,
  purchaseOrderId,
  handleContinueConfiguration,
  isStoreLoading,
  purchaseOrderCartItems,
  productsToPrint,
  inputError,
} from '../../../../../../apollo/cache';

import getSession from '../../../../../../helpers/getSession';

import useApolloLazyQuery from '../../../../../../hooks/useApolloLazyQuery';
import GQL_ACCOUNT_PAYABLE from '../../../../../payable/_gql';
import GQL from '../../../../_gql';
import Totals from './Totals';
import Item from './Item';
import getInputErrorFormat from '../../../../../sale.v2/Action/helpers/getInputErrorFormat';

const BodyTableComponent = props => {
  const errors = useReactiveVar(inputError);
  const handleContinueVar = useReactiveVar(handleContinue);
  const stepVar = useReactiveVar(step);
  const formVar = useReactiveVar(form);
  const purchaseOrderIdVar = useReactiveVar(purchaseOrderId);
  const purchaseOrderCartItemsVar = useReactiveVar(purchaseOrderCartItems);

  const [getPurchaseOrderId, { data }] = useApolloLazyQuery(GQL.GET_BY_ID);
  const [createPurchaseOrder, { loading: loadingCreate }] = useMutation(GQL.CREATE, {
    update(cache, { data }) {
      if (data.createPurchaseOrderV2.status === 3 && data.createPurchaseOrderV2.AccountPayable)
        cache.modify({
          fields: {
            accountPayables(cachedAccountPayable) {
              const AccountPayable = {
                ...data.createPurchaseOrderV2.AccountPayable,
                Provider: data.createPurchaseOrderV2.Provider,
                PurchaseOrder: data.createPurchaseOrderV2,
              };
              const newAccountPayableRef = cache.writeFragment({
                data: AccountPayable,
                fragment: GQL_ACCOUNT_PAYABLE.FRAGMENT_NEW_ACCOUNT_PAYABLE,
              });
              return {
                ...cachedAccountPayable,
                count: cachedAccountPayable.count + 1,
                rows: [newAccountPayableRef, ...cachedAccountPayable.rows],
              };
            },
          },
        });
      cache.modify({
        fields: {
          purchaseOrders(cachedPurchaseOrder) {
            const newPurchaseOrderRef = cache.writeFragment({
              data: { ...data.createPurchaseOrderV2 },
              fragment: GQL.FRAGMENT_NEW_PURCHASE_ORDER,
            });
            return {
              ...cachedPurchaseOrder,
              count: cachedPurchaseOrder.count + 1,
              rows: [newPurchaseOrderRef, ...cachedPurchaseOrder.rows],
            };
          },
        },
      });
      if (data.createPurchaseOrderV2.status === 3)
        data.createPurchaseOrderV2.PurchaseOrderDetails.forEach(el => {
          el.ProductPresentation &&
            cache.modify({
              id: `ProductPresentation:${el.ProductPresentation.id}`,
              fields: {
                cost() {
                  return el.ProductPresentation.unitPrice;
                },
              },
            });
        });
    },
  });

  useEffect(() => {
    isStoreLoading(loadingCreate);
  }, [loadingCreate]);

  const storePursache = async () => {
    try {
      inputError({});
      const session = await getSession();
      if (purchaseOrderCartItemsVar.length === 0) {
        const e = new Error('Añade al menos un producto a la orden de compra.');
        e.name = 'table';
        throw e;
      }
      let products = [];
      const Products = purchaseOrderCartItemsVar.map(el => {
        const discount = el.discount !== '' ? parseFloat(el.discount) : null;
        const unitPrice = el.price !== '' ? parseFloat(el.price) : null;
        products = [
          ...products,
          {
            name: el.productId ? el.productId.name : el.concept,
            total: parseInt(el.quantity),
            serialNumber: el.serialNumber,
            Presentation: el.productPresentationId
              ? {
                  id: el.productPresentationId.value,
                  name: el.productPresentationId.label,
                  sku: el.productPresentationId.sku,
                  barcode: el.productPresentationId.barcode,
                  altBarcode: el.productPresentationId.altBarcode,
                }
              : {},
          },
        ];
        return {
          iva: parseInt(el.type) === 2 ? parseFloat(el.iva) : null,
          ieps: parseInt(el.type) === 2 ? parseFloat(el.ieps) : null,
          concept: parseInt(el.type) === 2 ? el.concept.trim() : null,
          type: el.type,
          productId: parseInt(el.type) === 1 ? el.productId : null,
          productPresentationId: parseInt(el.type) === 1 ? el.productPresentationId.value : null,
          quantity: el.serialNumber.length > 0 ? 1 : parseFloat(el.quantity),
          discount,
          unitPrice,
          serialNumber: el.serialNumber,
          price: el.priceSell,
          adjustPrice: el.adjustPrice,
          percentAdjust: el.percentAdjust,
        };
      });
      const variables = {
        date: formVar.action === '3' ? formVar.pursacheDate : null,
        status: parseInt(formVar.action),
        branchId: formVar.branchId,
        providerId: formVar.providerId.value,
        description: formVar.description,
        Products,
        subFolio: formVar.action === '3' ? formVar.subFolio : null,
        paymentForm: formVar.action === '3' ? formVar.paymentForm : null,
        type: formVar.action === '3' ? formVar.type : null,
        currency: formVar.currency,
        exchangeRate: formVar.currency === 'MXN' ? 1 : parseFloat(formVar.exchangeRate),
        bonus: formVar.bonus && parseFloat(formVar.bonus) > 0 ? parseFloat(formVar.bonus) : 0,
      };
      await createPurchaseOrder({ variables });

      if (parseInt(session?.Company?.id) === 71 && formVar.action === '3')
        productsToPrint([true, products]);

      form({});
      actionForm({ ...actionForm(), isOpen: false, action: '' });
      step(0);
      purchaseOrderCartItems([]);
      handleContinueConfiguration({});
    } catch (e) {
      getInputErrorFormat(e);
    }
  };

  useEffect(() => {
    if (purchaseOrderIdVar) getPurchaseOrderId({ variables: { id: purchaseOrderIdVar } });
  }, [purchaseOrderIdVar]);

  useEffect(() => {
    if (data) {
      const tmpPurchaseOrderDetail = data.PurchaseOrderDetails.map(el => {
        const total = parseFloat(el.quantity) * parseFloat(el.unitPrice) - parseFloat(el.discount);
        return {
          id: el.id,
          product:
            parseInt(el.type) === 1
              ? el.Product
                ? el.Product.name
                : ''
              : String(el.concept || '').trim(),
          quantity: el.quantity,
          discount: el.discount,
          price: el.unitPrice,
          iva: el.iva,
          serialNumber: el.serialNumber,
          ieps: el.ieps,
          concept: el.concept,
          total,
          productPresentation: el.ProductPresentation ? el.ProductPresentation.name : '',
        };
      });
      purchaseOrderCartItems(tmpPurchaseOrderDetail);
    }
  }, [data]);

  useEffect(() => {
    if (handleContinueVar && stepVar === 1) {
      storePursache();
      handleContinue(false);
    }
  }, [handleContinueVar, stepVar]);
  return (
    <TableBody>
      {errors.table && (
        <TableRow>
          <TableCell colSpan={6}>
            <Typography color='error' align='center' gutterBottom>
              {errors.table}
            </Typography>
          </TableCell>
        </TableRow>
      )}
      {purchaseOrderCartItemsVar.map((el, i) => (
        <Item
          key={i}
          index={i}
          noEdit={props.noEdit}
          id={el.id}
          productId={el.productId}
          concept={el.concept}
          product={el.product}
          serialNumber={el.serialNumber}
          productPresentationId={el.productPresentationId}
          productPresentation={el.productPresentation}
          quantity={el.quantity}
          discount={el.discount}
          price={el.price}
          total={el.total}
        />
      ))}
      {purchaseOrderCartItemsVar.length > 0 && <Totals />}
    </TableBody>
  );
};

export default BodyTableComponent;
