var getRouteFromCompanyMenuValue = function (value) {
    switch (value) {
        case 0:
            return '.';
        case 1:
            return 'image';
        case 2:
            return 'ecommerce';
        case 3:
            return 'queueManager';
        case 4:
            return 'allowedModules';
        case 5:
            return 'twilioWhatsappApi';
        default:
            return false;
    }
};
export default getRouteFromCompanyMenuValue;
