import React, { useState, useEffect } from 'react';

import CreditCardIcon from '@mui/icons-material/CreditCardOutlined';
import EventIcon from '@mui/icons-material/EventOutlined';
import EditIcon from '@mui/icons-material/EditOutlined';

import { actions, isCalendarFormOpen } from '../../apollo/cache';

import Payment from './payment.dialog';
import Form from './form.dialog';

const ActionComponent = () => {
  const [isOpenPayment, setIsOpenPayment] = useState([false, null]);
  const [isOpenForm, setIsOpenForm] = useState([false, null]);

  const handlePayment = (e, data) => {
    setIsOpenPayment([true, data.accountReceivableId]);
  };

  const handleForm = (e, { id }) => setIsOpenForm([true, id]);
  const handleCalendar = (e, { id, customerId }) =>
    isCalendarFormOpen([true, { crmId: id, customerId }]);

  const handleClosePayment = () => setIsOpenPayment([false, null]);
  const handleCloseForm = () => setIsOpenForm([false, null]);

  useEffect(() => {
    actions(
      [
        {
          icon: <CreditCardIcon />,
          label: 'Abonar',
          onClick: handlePayment,
          disable: { key: 'AccountReceivable paid', value: true },
        },
        {
          icon: <EventIcon />,
          label: 'Nueva Cita',
          onClick: handleCalendar,
        },
        {
          icon: <EditIcon />,
          label: 'Editar',
          onClick: handleForm,
        },
      ].filter(el => el),
    );
    return () => {
      actions([]);
    };
  }, []);

  return (
    <>
      <Payment
        isOpen={isOpenPayment[0]}
        handleClose={handleClosePayment}
        accountReceivableId={isOpenPayment[1]}
      />
      <Form isOpen={isOpenForm[0]} handleClose={handleCloseForm} crmId={isOpenForm[1]} />
    </>
  );
};

export default ActionComponent;
