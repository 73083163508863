import React, { useEffect, useState } from 'react';
import { useReactiveVar } from '@apollo/client';
import { Divider, Typography } from '@mui/material';
import { customerSaleV2 } from '../../../../../../apollo/cache';
import ChangeCustomerButton from './ChangeCustomer';
import useGetTaxRegime from '../../../../../../hooks/useGetTaxRegime';
import useApolloLazyQuery from '../../../../../../hooks/useApolloLazyQuery';
import GQL_CUSTOMER from '../../../../../../apollo/gql/customer';
import useGetSession from '../../../../../../hooks/useGetSession';
var CustomerInfoSummaryFormBodyConfirmDialogSaleV2Component = function (props) {
    var _a, _b, _c, _d, _e;
    var customerSaleV2Var = useReactiveVar(customerSaleV2);
    var session = useGetSession();
    var customerLabel = ((_a = session === null || session === void 0 ? void 0 : session.Company) === null || _a === void 0 ? void 0 : _a.activity) === 3 ? 'paciente' : 'cliente';
    var _f = useApolloLazyQuery(GQL_CUSTOMER.GET_CUSTOMER_BY_ID), getData = _f[0], _g = _f[1], loading = _g.loading, customer = _g.data;
    var _h = useState({}), data = _h[0], setData = _h[1];
    var TaxRegimes = useGetTaxRegime();
    useEffect(function () {
        if (customerSaleV2Var.id)
            getData({ variables: { id: customerSaleV2Var.id } });
        var taxRegime = TaxRegimes.length > 0
            ? TaxRegimes.find(function (el) {
                return parseInt(el.value.toString()) === parseInt(customerSaleV2Var.taxRegime.toString());
            })
            : undefined;
        setData({
            name: "".concat(customerSaleV2Var.firstName, " ").concat(customerSaleV2Var.lastName).trim().length > 0
                ? "".concat(customerSaleV2Var.firstName, " ").concat(customerSaleV2Var.lastName).trim()
                : 'Público en general',
            phone: customerSaleV2Var.phone,
            email: [customerSaleV2Var.email, customerSaleV2Var.altEmail, customerSaleV2Var.otherEmail]
                .reduce(function (email, el) { return (el.length > 0 ? (email.length > 0 ? "".concat(email, ", ").concat(el) : el) : email); }, '')
                .trim(),
            businessName: customerSaleV2Var.businessName,
            identifier: customerSaleV2Var.identifier,
            taxRegime: taxRegime ? taxRegime.label : 'Indefinido',
        });
    }, [customerSaleV2Var, TaxRegimes]);
    if (loading)
        return (React.createElement("div", null,
            React.createElement(Divider, { className: 'mb-2' }),
            React.createElement("div", { className: 'font-medium text-lg' },
                "Consultando datos del ",
                customerLabel)));
    return (React.createElement("div", null,
        React.createElement(Divider, { className: 'mb-2' }),
        React.createElement("div", { className: 'flex flex-row' },
            React.createElement("div", { className: 'font-medium text-lg' },
                "Datos del ",
                customerLabel),
            !props.noCustomerEdit && React.createElement(ChangeCustomerButton, { name: data.name })),
        React.createElement(Typography, null,
            React.createElement("strong", null, "Nombre de contacto:"),
            " ",
            customer ? customer.code : '',
            " ",
            data.name),
        ((_b = data.phone) === null || _b === void 0 ? void 0 : _b.length) > 0 && (React.createElement(Typography, null,
            React.createElement("strong", null, "Tel\u00E9fono:"),
            " ",
            data.phone)),
        ((_c = data.email) === null || _c === void 0 ? void 0 : _c.length) > 0 && (React.createElement(Typography, null,
            React.createElement("strong", null, "Correo electr\u00F3nico:"),
            " ",
            data.email)),
        ((_d = data.businessName) === null || _d === void 0 ? void 0 : _d.length) > 0 && (React.createElement(Typography, null,
            React.createElement("strong", null, "Raz\u00F3n social:"),
            " ",
            data.businessName)),
        ((_e = data.identifier) === null || _e === void 0 ? void 0 : _e.length) > 0 && (React.createElement(Typography, null,
            React.createElement("strong", null, "RFC:"),
            " ",
            data.identifier)),
        data.taxRegime && (React.createElement(Typography, null,
            React.createElement("strong", null, "Regimen fiscal:"),
            " ",
            data.taxRegime))));
};
export default CustomerInfoSummaryFormBodyConfirmDialogSaleV2Component;
