import React from 'react';
import { useReactiveVar } from '@apollo/client';
import { isCreatePresentationProductOpen } from '../../../../../apollo/cache';
import DialogComponent from '../../../../../presentationals/Dialog';
import BodyCreatePresentationProductDialogComponent from './Body';
import FooterDialogComponent from '../../../../../presentationals/Dialog/Footer';
import CreatePresentationButtonComponent from './Body/CreateButton';
var CreateProductPresentationDialogComponent = function () {
    var isOpen = useReactiveVar(isCreatePresentationProductOpen);
    var handleClose = function () {
        isCreatePresentationProductOpen(false);
    };
    return (React.createElement(DialogComponent, { fullScreen: true, open: isOpen, title: 'Nueva presentaci\u00F3n', onClose: handleClose },
        React.createElement("div", { className: 'flex flex-1 flex-col overflow-hidden' },
            React.createElement(BodyCreatePresentationProductDialogComponent, null),
            React.createElement(FooterDialogComponent, null,
                React.createElement("div", null),
                React.createElement(CreatePresentationButtonComponent, null)))));
};
export default CreateProductPresentationDialogComponent;
