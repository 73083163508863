import React from 'react';
import { Outlet } from 'react-router-dom';
import GoBackButtonComponent from '../../presentationals/GoBackButton';
import MenuLayoutComponent from './Menu';
import useQueryParams from '../../hooks/useQueryParams';
var SurveyLayoutComponent = function () {
    var _a;
    var query = useQueryParams();
    return (React.createElement("div", { className: 'flex flex-1 flex-col' },
        React.createElement("div", { className: 'flex items-center px-4 py-0' },
            React.createElement("div", { className: 'mr-3' },
                React.createElement(GoBackButtonComponent, { relative: 'path', route: query.get('customerId')
                        ? "/panel/survey?customerId=".concat(query.get('customerId')).concat(query.get('title') ? "&title=".concat(query.get('title')) : '')
                        : undefined })),
            React.createElement("div", { className: 'text-lg text-gray-700 tracking-tight' }, "Visualizaci\u00F3n de la ".concat(query.get('title') ? (_a = query.get('title')) === null || _a === void 0 ? void 0 : _a.toLowerCase() : 'encuesta'))),
        React.createElement(MenuLayoutComponent, null),
        React.createElement("div", { className: 'flex flex-1 flex-col h-full overflow-hidden' },
            React.createElement(Outlet, null))));
};
export default SurveyLayoutComponent;
