var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import { Paper } from '@mui/material';
import SearchButton from './SearchButton';
import FilterButton from './FilterButton';
import Input from './Input';
import { filter, searchV3 } from '../../../../apollo/cache';
import SelectSearchInputComponent from './SelectInput';
import setDefaultSearchBy from './helpers/setDefaultSearchBy';
var SearchInputHeaderModuleV3Component = function (props) {
    var handleClick = function () {
        var currentFilter = filter();
        var like = searchV3();
        var searchBy = setDefaultSearchBy(props.searchFieldOptions);
        filter(__assign(__assign({}, currentFilter), { like: [searchBy, like] }));
    };
    return (React.createElement(Paper, { className: 'w-full md:w-1/3 flex px-1 py-1', variant: 'outlined' },
        props.searchFieldOptions && (React.createElement(SelectSearchInputComponent, __assign({}, props.searchFieldOptions, { onClick: handleClick }))),
        React.createElement(Input, { isLoading: !!props.isLoading, label: props.label, onPressEnter: handleClick }),
        React.createElement(SearchButton, { isLoading: !!props.isLoading, onClick: handleClick }),
        props.filter && React.createElement(FilterButton, { isLoading: !!props.isLoading })));
};
export default SearchInputHeaderModuleV3Component;
