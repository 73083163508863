var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useEffect, useState } from 'react';
import EngineeringIcon from '@mui/icons-material/Engineering';
import ListItemPermissionEditRoleComponent from '../helpers/ListItem';
import SwitchPermissionFieldComponent from '../helpers/SwitchPermissionField';
import SelectPermissionFieldComponent from '../helpers/SelectPermissionField';
import getDefaultBranchOptions from '../helpers/getDefaultBrachOptions';
var defaultMaintenancePermissionValues = {
    createNewMaintenance: false,
    closeMaintenance: 0,
    getMaintenance: 0,
    deleteMaintenance: 0,
};
var MaintenancePermissionEditRoleComponent = function (props) {
    var defaultBranchOptions = getDefaultBranchOptions();
    var _a = useState(defaultMaintenancePermissionValues), values = _a[0], setValues = _a[1];
    useEffect(function () {
        setValues(function (values) { return (__assign(__assign({}, values), props.Permissions)); });
    }, [props.Permissions]);
    return (React.createElement(ListItemPermissionEditRoleComponent, { icon: React.createElement(EngineeringIcon, null), name: '\u00D3rdenes de mantenimiento' },
        React.createElement("div", { className: 'mx-4 py-3 grid grid-cols-1 md:grid-cols-3 gap-4' },
            React.createElement("div", { className: 'flex flex-col' },
                React.createElement(SwitchPermissionFieldComponent, __assign({}, props, { name: 'createNewMaintenance', label: 'Crear nuevas \u00F3rdenes de mantenimiento', defaultValue: values.createNewMaintenance })),
                React.createElement(SelectPermissionFieldComponent, __assign({}, props, { name: 'closeMaintenance', label: 'Cerrar orden de mantenimiento', defaultValue: values.closeMaintenance, options: defaultBranchOptions })),
                React.createElement(SelectPermissionFieldComponent, __assign({}, props, { name: 'getMaintenance', label: 'Consultar datos generales y contenido de una orden de mantenimiento', defaultValue: values.getMaintenance, options: defaultBranchOptions })),
                React.createElement(SelectPermissionFieldComponent, __assign({}, props, { name: 'deleteMaintenance', label: 'Eliminar \u00F3rdenes de mantenimiento', defaultValue: values.deleteMaintenance, options: defaultBranchOptions }))))));
};
export default MaintenancePermissionEditRoleComponent;
