var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import { useParams } from 'react-router-dom';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import LoadingProgress from '../../../../presentationals/LoadingProgress';
import useGetSurveyCustomers from '../../../../hooks/useSurveyCustomers';
import EmptyDataComponent from '../../../../presentationals/NoRecords';
import ItemSurveyCustomerComponent from './Item';
var BodySurveyCustomerComponent = function () {
    var params = useParams();
    var _a = useGetSurveyCustomers({ customerId: params.customerId }), data = _a.data, loading = _a.loading;
    if (loading)
        return React.createElement(LoadingProgress, null);
    if (data.length === 0)
        return React.createElement(EmptyDataComponent, null);
    return (React.createElement("div", { className: 'flex flex-1 flex-col' },
        React.createElement(TableContainer, null,
            React.createElement(Table, { stickyHeader: true, size: 'small' },
                React.createElement(TableHead, null,
                    React.createElement(TableRow, null,
                        React.createElement(TableCell, null, "Creado el"),
                        React.createElement(TableCell, null, "Tipo"),
                        React.createElement(TableCell, null, "Entrevista / encuesta"),
                        React.createElement(TableCell, null, "Sucursal"))),
                React.createElement(TableBody, null, data.map(function (el) { return (React.createElement(ItemSurveyCustomerComponent, __assign({ key: el.id }, el))); }))))));
};
export default BodySurveyCustomerComponent;
