import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Divider } from '@mui/material';
import useApolloLazyQuery from '../../../../../hooks/useApolloLazyQuery';
import GQL_TRANSACTION from '../../../../../apollo/gql/transaction';
import LoadingProgress from '../../../../../presentationals/LoadingProgress';
import ErrorDataComponent from '../../../../../presentationals/ErrorData';
import { dateFormat, dateTimeFormat } from '../../../../../helpers';
import convertToCurrencyLabel from '../../../../../helpers/convertToCurrencyLabel';
import PrintSaleButtonComponent from '../../../../rent/Item/helpers/PrintSaleButton';
import PrintButtonTransactionComponent from './PrintButton';
import FooterDialogComponent from '../../../../../presentationals/Dialog/Footer';
import PrintOptionFooterConfirmDialogSaleV2Component from '../../../../sale.v2/ConfirmDialog/Footer/PrintOption';
import DownloadInvoiceComplementButtonComplement from './DownloadInvoiceComplementButton';
var BodyTransactionComponent = function () {
    var params = useParams();
    var _a = useApolloLazyQuery(GQL_TRANSACTION.GET_TRANSACTION_BY_ID), getData = _a[0], _b = _a[1], data = _b.data, loading = _b.loading;
    useEffect(function () {
        getData({ variables: { id: params.transactionId } });
    }, [params.transactionId]);
    if (loading)
        return React.createElement(LoadingProgress, null);
    if (!data)
        return React.createElement(ErrorDataComponent, null);
    return (React.createElement("div", { className: 'flex flex-1 flex-col' },
        React.createElement("div", { className: 'grid grid-cols-1 sm:grid-cols-3 flex-1 flex-col px-4 py-2 basis-0 overflow-auto' },
            React.createElement("div", { className: 'flex flex-1 flex-col' },
                React.createElement("div", { className: 'text-base font-medium mt-2' }, "Datos de la transacci\u00F3n"),
                data.status === 0 && React.createElement("div", { className: 'text-5xl text-red-600 mb-2' }, "CANCELADO"),
                React.createElement("div", { className: 'text-base mt-2' }, "Fecha de emisi\u00F3n"),
                React.createElement("div", { className: 'font-medium' }, dateTimeFormat(data.createdAt, "EEEE, d 'de' MMMM HH:mm")),
                React.createElement("div", { className: 'text-base mt-2' }, "Fecha de pago"),
                React.createElement("div", { className: 'font-medium' }, dateFormat(data.clientCreatedAt)),
                React.createElement("div", { className: 'text-base mt-2' }, "Cliente"),
                React.createElement("div", { className: 'font-medium' }, data.customer),
                React.createElement("div", { className: 'text-base mt-2' }, "Forma de pago"),
                React.createElement("div", { className: 'font-medium' }, data.paymentMethod),
                React.createElement("div", { className: 'text-base mt-2' }, "Importe del pago"),
                React.createElement("div", { className: 'text-xl' }, "$".concat(convertToCurrencyLabel(data.total))),
                React.createElement(DownloadInvoiceComplementButtonComplement, { invoiceComplementUuid: data.invoiceComplementUuid }),
                React.createElement(Divider, { className: 'mt-2 mb-1' }),
                React.createElement("div", { className: 'text-base font-medium mt-2' }, "Datos de la venta"),
                React.createElement("div", { className: 'text-base mt-2' }, "Folio"),
                React.createElement("div", { className: 'font-medium' }, data.saleFolio),
                React.createElement("div", { className: 'text-base mt-2' }, "Importe"),
                React.createElement("div", { className: 'text-xl' }, "$".concat(convertToCurrencyLabel(parseFloat(data.saleTotal)))),
                React.createElement(Divider, { className: 'mt-2 mb-3' }),
                React.createElement("div", { className: 'grid grid-cols-2 gap-4' },
                    React.createElement(PrintSaleButtonComponent, { label: 'Imprimir comprobante de venta', type: data.invoiceUuid ? 'invoice' : 'sale', saleId: data.invoiceUuid ? data.invoiceUuid : data.saleId }),
                    React.createElement(PrintButtonTransactionComponent, { accountReceivableId: data.accountReceivableId, label: 'Imprimir ticket de abonos' })))),
        React.createElement(FooterDialogComponent, null,
            React.createElement("div", null,
                React.createElement(PrintOptionFooterConfirmDialogSaleV2Component, null)))));
};
export default BodyTransactionComponent;
