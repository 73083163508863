import getSession from './getSession';
export var saleFormatArray = {
    '-2': 'Cotización',
    '-1': 'Factura',
    1: 'Nota de venta',
    3: 'Orden de Servicio',
    6: 'Mantenimiento',
    7: 'Renta',
};
export var saleFormatArray1 = [
    { id: -2, value: 'Cotización' },
    { id: -1, value: 'Factura' },
    { id: 1, value: 'Nota de venta' },
    { id: 3, value: 'Orden de Servicio' },
    { id: 6, value: 'Mantenimiento' },
    { id: 7, value: 'Renta' },
];
export var getSaleFormatArray = function (value) {
    var _a;
    var parsedNumber = value;
    var session = getSession();
    var response = saleFormatArray1.find(function (el) { return el.id === parsedNumber; });
    if (response && ((_a = session === null || session === void 0 ? void 0 : session.Company) === null || _a === void 0 ? void 0 : _a.id.toString()) === '116' && parsedNumber === 7)
        return 'Presupuesto';
    if (response)
        return response.value;
    return 'Indefinido';
};
var getSaleFormatLabel = function (value, format) {
    var newValue = getSaleFormatArray(value);
    switch (format) {
        case 'lowercase':
            return newValue.toLowerCase();
        default:
            return newValue;
    }
};
export default getSaleFormatLabel;
