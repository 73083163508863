var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import SearchInput from './SearchInput';
import Title from './Title';
import HeaderOptionsComponent from './Options';
import GoBackButtonComponent from '../../GoBackButton';
var HeaderModuleV3Component = function (props) {
    return (React.createElement("div", { className: 'flex-row justify-between items-center sm:flex' },
        React.createElement("div", { className: 'flex items-center' },
            props.showReturnButton && (React.createElement("div", null,
                React.createElement(GoBackButtonComponent, { route: props.route }))),
            !props.hideTitle && (React.createElement("div", { className: 'flex flex-1 pr-6' },
                React.createElement(Title, { label: props.title }))),
            React.createElement("div", { className: 'flex sm:hidden' },
                React.createElement(HeaderOptionsComponent, __assign({}, props)))),
        React.createElement("div", { className: 'flex flex-1 justify-between items-center' },
            !props.hideSearch ? (React.createElement("div", { className: 'flex flex-1 items-center' },
                React.createElement(SearchInput, { searchFieldOptions: props.searchFieldOptions, isLoading: props.isLoading, label: props.searchLabel || props.title, filter: props.filter }))) : (React.createElement("div", { className: 'flex flex-1' })),
            React.createElement("div", { className: 'hidden sm:block justify-end' },
                React.createElement(HeaderOptionsComponent, __assign({}, props))))));
};
export default HeaderModuleV3Component;
