import React from 'react';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { TableCell, TableRow } from '@mui/material';
import IconButtonComponent from '../../../../../presentationals/IconButton';
import useApolloQuery from '../../../../../hooks/useApolloQuery';
import GQL_PRODUCT from '../../../../../apollo/gql/product';
import convertToCurrencyLabel from '../../../../../helpers/convertToCurrencyLabel';
import useTransferProductsToBranchStore from '../../../../../store/inventoryReport/transferProductsToBranchStore';
var ItemBodyProductsToTransferFormComponent = function (props) {
    var deleteProduct = useTransferProductsToBranchStore(function (state) { return state.deleteProduct; });
    var data = useApolloQuery(GQL_PRODUCT.GET_PRODUCT_BY_PRODUCT_PRESENTATION_ID, {
        variables: { productPresentationId: props.presentationId },
    }).data;
    var handleDelete = function () {
        deleteProduct(props.presentationId);
    };
    if (!data)
        return React.createElement(TableRow, null);
    return (React.createElement(TableRow, null,
        React.createElement(TableCell, null,
            React.createElement("div", { className: 'text-sm' }, "".concat(data.name, " ").concat(data.ProductPresentation.name)),
            React.createElement("div", { className: 'text-xs text-gray-500' }, data.sku)),
        React.createElement(TableCell, { className: 'text-sm text-center' }, convertToCurrencyLabel(props.qty)),
        React.createElement(TableCell, null,
            React.createElement("div", { className: 'flex flex-row justify-end' },
                React.createElement(IconButtonComponent, { size: 'small', params: props.presentationId, action: handleDelete, icon: React.createElement(DeleteOutlineIcon, { fontSize: 'small' }) })))));
};
export default ItemBodyProductsToTransferFormComponent;
