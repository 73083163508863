import React from 'react';

import CloseIcon from '@mui/icons-material/Close';
import { IconButton } from '@mui/material';

import { isSatProductKeyDialogOpen } from '../../../../../../../../../../../apollo/cache';

const CloseButtonHeaderSearchDialogProducttKeyFieldHelpersBodyCreateProductDialogProductFormChooseProductSaleV2Component = () => {
    const handleClose = () => {
      isSatProductKeyDialogOpen(false);
    };
    return (
      <IconButton onClick={handleClose}>
        <CloseIcon />
      </IconButton>
    );
  };

export default CloseButtonHeaderSearchDialogProducttKeyFieldHelpersBodyCreateProductDialogProductFormChooseProductSaleV2Component;
