var getAccountReceivableBackgroundColor = function (value) {
    switch (value) {
        case 'danger':
            return 'bg-red-300';
        case 'warning':
            return 'bg-amber-300';
        case 'regular':
        default:
            return '';
    }
};
export default getAccountReceivableBackgroundColor;
