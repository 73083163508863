import { redirect } from 'react-router-dom';
import getSession from '../../helpers/getSession';
var companyLayoutLoader = function () {
    var session = getSession();
    if (!session || (session && !session.accessToken))
        throw redirect('/');
    if (session.status === 'loggedIn')
        throw redirect('/panel');
    return true;
};
export default companyLayoutLoader;
