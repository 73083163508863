var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useEffect, useState } from 'react';
import PlaylistAddCheckOutlinedIcon from '@mui/icons-material/PlaylistAddCheckOutlined';
import ListItemPermissionEditRoleComponent from '../helpers/ListItem';
import SwitchPermissionFieldComponent from '../helpers/SwitchPermissionField';
var defaultProductionermissionValues = {
    getProductions: 0,
    createProduction: false,
    deleteProduction: false,
};
var ProductionPermissionEditRoleComponent = function (props) {
    // const defaultBranchOptions = getDefaultBranchOptions();
    var _a = useState(defaultProductionermissionValues), values = _a[0], setValues = _a[1];
    useEffect(function () {
        setValues(function (values) { return (__assign(__assign({}, values), props.Permissions)); });
    }, [props.Permissions]);
    return (React.createElement(ListItemPermissionEditRoleComponent, { icon: React.createElement(PlaylistAddCheckOutlinedIcon, null), name: 'Producci\u00F3n' },
        React.createElement("div", { className: 'mx-4 py-3 grid grid-cols-1 md:grid-cols-3 gap-4' },
            React.createElement("div", { className: 'flex flex-col' },
                React.createElement(SwitchPermissionFieldComponent, __assign({}, props, { name: 'createProduction', label: 'Crear nuevas producciones', defaultValue: values.createProduction })),
                React.createElement(SwitchPermissionFieldComponent, __assign({}, props, { name: 'deleteProduction', label: 'Eliminar producciones', defaultValue: values.deleteProduction }))))));
};
export default ProductionPermissionEditRoleComponent;
