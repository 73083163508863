var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { List } from '@mui/material';
import getSatProductKeys from '../../../../../helpers/getSatProductKeys';
import getInputErrorFormat from '../../../../../containers/sale.v2/Action/helpers/getInputErrorFormat';
import ItemSearchSatUnitKeyTableComponent from '../../../SatUnitKeyField/SearchDialog/Table/Item';
var SearchSatProductKeyTableComponent = forwardRef(function (props, ref) {
    var _a = useState([]), data = _a[0], setData = _a[1];
    var _b = useState([]), result = _b[0], setResult = _b[1];
    useEffect(function () {
        var f = function () { return __awaiter(void 0, void 0, void 0, function () {
            var response, e_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, getSatProductKeys()];
                    case 1:
                        response = _a.sent();
                        setData(response);
                        return [3 /*break*/, 3];
                    case 2:
                        e_1 = _a.sent();
                        getInputErrorFormat(e_1);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        }); };
        f();
    }, []);
    var handleGetValue = function (value) { return __awaiter(void 0, void 0, void 0, function () {
        var serverData, _a, searchString, currentUnitKey;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    if (!(data.length > 0)) return [3 /*break*/, 1];
                    _a = data;
                    return [3 /*break*/, 3];
                case 1: return [4 /*yield*/, getSatProductKeys()];
                case 2:
                    _a = _b.sent();
                    _b.label = 3;
                case 3:
                    serverData = _a;
                    if (value.length === 0) {
                        setResult([]);
                        return [2 /*return*/];
                    }
                    searchString = value.toLowerCase();
                    currentUnitKey = serverData.filter(function (el) {
                        return el.label.toLowerCase().includes(searchString) ||
                            el.id.toString().toLowerCase().includes(searchString);
                    });
                    setResult(currentUnitKey);
                    return [2 /*return*/];
            }
        });
    }); };
    var handleClick = function (value) {
        props.onClick(value);
    };
    useImperativeHandle(ref, function () { return ({
        getValue: handleGetValue,
    }); });
    return (React.createElement("div", { className: 'flex flex-1 flex-col overflow-y-auto' },
        React.createElement(List, { component: 'nav', "aria-labelledby": 'nested-customer-list' }, result.map(function (el, i) { return (React.createElement(ItemSearchSatUnitKeyTableComponent, __assign({ key: el.id }, el, { onClick: handleClick, background: i % 2 > 0 ? 'bg-inherit' : 'bg-gray-100' }))); }))));
});
export default SearchSatProductKeyTableComponent;
