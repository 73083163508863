import React, { useEffect, useState } from 'react';
import { Tabs, Tab } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import BadgeIcon from '@mui/icons-material/Badge';
import RequestQuoteIcon from '@mui/icons-material/RequestQuote';
import AssignmentIcon from '@mui/icons-material/Assignment';
import getCustomerMenuValueFromPath from './getCustomerMenuValueFromPath';
import getRouteFromCustomerMenuValue from './getRouteFromCustomerMenuValue';
var MenuCustomerComponent = function () {
    var navigate = useNavigate();
    var location = useLocation();
    var _a = useState(0), value = _a[0], setValue = _a[1];
    useEffect(function () {
        var value = getCustomerMenuValueFromPath(location.pathname);
        setValue(value);
    }, [location.pathname]);
    var handleChange = function (e, value) {
        var route = getRouteFromCustomerMenuValue(value);
        if (route)
            navigate(route);
    };
    return (React.createElement(Tabs, { value: value, onChange: handleChange, variant: 'scrollable', scrollButtons: 'auto', className: 'bg-slate-100 border-0 border-b border-[#d6d6d6] border-solid' },
        React.createElement(Tab, { icon: React.createElement(BadgeIcon, null), label: 'Datos generales' }),
        React.createElement(Tab, { icon: React.createElement(AssignmentIcon, null), label: 'Entrevista' }),
        React.createElement(Tab, { icon: React.createElement(CalendarMonthIcon, null), label: 'Configuraci\u00F3n de citas' }),
        React.createElement(Tab, { icon: React.createElement(RequestQuoteIcon, null), label: 'Facturaci\u00F3n mensual' })));
};
export default MenuCustomerComponent;
