var getTypeByProductTypeFilterOption = function (value) {
    switch (value) {
        case 1:
            return 0;
        case 2:
            return 1;
        case 5:
            return 2;
        case 6:
            return 3;
        case 7:
        case 4:
            return 4;
        default:
            return 0;
    }
};
export default getTypeByProductTypeFilterOption;
