import React from 'react';

import CloseIcon from '@mui/icons-material/Close';
import { IconButton } from '@mui/material';

import { isChooseBillingInformationDialogOpen } from '../../../../../../../../../../../apollo/cache';

const CloseButtonHeaderHeaderSearchDialogChooseBillingInformationBodyCreateFormCustomerComponent = () => {
    const handleClose = () => {
      isChooseBillingInformationDialogOpen(false);
    };
    return (
      <IconButton onClick={handleClose}>
        <CloseIcon />
      </IconButton>
    );
  };

export default CloseButtonHeaderHeaderSearchDialogChooseBillingInformationBodyCreateFormCustomerComponent;
