import React from 'react';
import Button from '../../../../../presentationals/Dialog/Footer/Button';
import { boxForm } from '../../../../../apollo/cache';
import getDefaultBoxValues from '../../../helpers/getDefaultBoxValues';
var ClearButtonFooterCreateBoxComponent = function () {
    var handleClick = function () {
        var defaultValues = getDefaultBoxValues();
        boxForm(defaultValues);
    };
    return (React.createElement(Button, { color: 'inherit', className: 'ml-1', onClick: handleClick }, "Limpiar"));
};
export default ClearButtonFooterCreateBoxComponent;
