import React, { useEffect, useState } from 'react';
import { CartesianGrid, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis, } from 'recharts';
import { eachDayOfInterval } from 'date-fns';
import { filter } from '../../../apollo/cache';
import getTrasactionReportChatData from './helpers/getData';
var TransactionReportChartComponent = function (props) {
    var _a = useState([]), data = _a[0], setData = _a[1];
    useEffect(function () {
        var filterVar = filter();
        var start = filterVar.start || new Date();
        var end = filterVar.end || new Date();
        var dates = eachDayOfInterval({ start: start, end: end });
        if (props.data) {
            var data_1 = getTrasactionReportChatData(props.data, dates);
            setData(data_1);
        }
    }, [props.data]);
    return (React.createElement("div", { className: 'w-full h-96 sm:h-full' },
        React.createElement(ResponsiveContainer, { width: '100%', height: '99%' },
            React.createElement(LineChart, { layout: 'vertical', data: data, margin: { right: 10, left: 20, top: 20 } },
                React.createElement(CartesianGrid, { strokeDasharray: '3 3' }),
                React.createElement(XAxis, { type: 'number', reversed: true }),
                React.createElement(YAxis, { dataKey: 'date', orientation: 'right', type: 'category' }),
                React.createElement(Tooltip, null),
                React.createElement(Legend, null),
                data.length > 0 &&
                    Object.keys(data[0]).map(function (key) {
                        return key !== 'date' && (React.createElement(Line, { key: key, dataKey: key, stroke: "#".concat(Math.floor(Math.random() * 16777215).toString(16)) }));
                    })))));
};
export default TransactionReportChartComponent;
