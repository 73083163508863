import React, { useRef } from 'react';
import { useReactiveVar } from '@apollo/client';
import BodyProductIngredientComponent from './Body';
import CreateButtonProductIngredientComponent from './CreateButton';
import { isCreateProductComponentOpen } from '../../../../../apollo/cache';
import DialogComponent from '../../../../../presentationals/Dialog';
import FooterDialogComponent from '../../../../../presentationals/Dialog/Footer';
var CreateProductIngredientComponent = function () {
    var productIngredientFieldRef = useRef(null);
    var isOpen = useReactiveVar(isCreateProductComponentOpen);
    var handleClose = function () {
        var _a;
        isCreateProductComponentOpen(false);
        (_a = productIngredientFieldRef === null || productIngredientFieldRef === void 0 ? void 0 : productIngredientFieldRef.current) === null || _a === void 0 ? void 0 : _a.handleClear();
    };
    return (React.createElement(DialogComponent, { fullScreen: true, open: isOpen, title: 'Nuevo componente', onClose: handleClose },
        React.createElement("div", { className: 'flex flex-1 flex-col' },
            React.createElement(BodyProductIngredientComponent, { ref: productIngredientFieldRef }),
            React.createElement(FooterDialogComponent, null,
                React.createElement("div", null),
                React.createElement(CreateButtonProductIngredientComponent, { onCreate: handleClose })))));
};
export default CreateProductIngredientComponent;
