import React from 'react';
// import { useNavigate } from 'react-router-dom';
import Container from '../../presentationals/Module.v2/Container';
import GQL from './_gql';
import Action from './_action';
import Form from './CreateForm';
import Filter from './_filter';
import PrintPresentationalsDialog from './printPresentations.dialog';

const title = 'Orden de compra';
const operation = ['purchaseOrder', 'purchaseOrders'];

const Rows = [
  { label: 'Proveedor', key: 'Provider name' },
  { label: 'Fecha', key: 'date', sort: 'text', format: 'dateTime' },
  { label: 'Captura', key: 'createdAt', sort: 'text', format: 'dateTime' },
  { label: 'Folio', key: 'serial', prefix: 'OC', sort: 'text' },
  { label: '# Compra', key: 'subFolio', sort: 'text' },
  { label: 'Responsable', key: 'Employee firstName && Employee lastName' },
  { label: 'Moneda', key: 'currency', sort: 'text' },
  { label: 'Subtotal', key: 'subtotal', sort: 'text', format: 'currency', align: 'right' },
  { label: 'Descuento', key: 'discount', sort: 'text', format: 'currency', align: 'right' },
  { label: 'Bonificación', key: 'bonus', sort: 'text', format: 'currency', align: 'right' },
  { label: 'IVA', key: 'ivaTotal', sort: 'text', format: 'currency', align: 'right' },
  { label: 'IEPS', key: 'iepsTotal', sort: 'text', format: 'currency', align: 'right' },
  { label: 'Importe', key: 'total', sort: 'text', format: 'currency', align: 'right' },
  { label: 'Anotación', key: 'description', wrap: true },
];

const purchaseOrderV2Component = () => {
  // const navigate = useNavigate();
  // const handleOpenCreate = () => {
  //   navigate('/panel/purchaseOrder/create', { replace: true });
  // };
  return (
    <>
      <Container
        title={title}
        operation={operation}
        Rows={Rows}
        get={GQL.GET}
        create={GQL.CREATE}
        // handleCustomCreate={handleOpenCreate}
        delete={GQL.DELETE}
        noEdit
        filter
      />
      <Form />
      <Action />
      <Filter />
      <PrintPresentationalsDialog />
    </>
  );
};

export default purchaseOrderV2Component;
