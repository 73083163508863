import React from 'react';

import Grid from '@mui/material/Grid';

import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { FixedSizeList } from 'react-window';

import './style.css';

function renderRow(props) {
  const { data, index, style } = props;

  return React.cloneElement(data[index], {
    style: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      display: 'block',
      ...style,
    },
  });
}

const DivRef = other =>
  React.forwardRef((props2, ref2) => <div ref={ref2} {...props2} {...other} />);

const ListboxComponent = React.forwardRef((props, ref) => {
  const { children, ...other } = props;
  const itemCount = Array.isArray(children) ? children.length : 0;
  const itemSize = 48;

  const outerElementType = React.useMemo(() => {
    return DivRef(other);
  }, []); // eslint-disable-line

  return (
    <div ref={ref}>
      <FixedSizeList
        style={{ padding: 0, height: Math.min(8, itemCount) * itemSize, maxHeight: 'auto' }}
        itemData={children}
        height={250}
        width='100%'
        outerElementType={outerElementType}
        innerElementType='ul'
        itemSize={itemSize}
        overscanCount={5}
        itemCount={itemCount}
      >
        {renderRow}
      </FixedSizeList>
    </div>
  );
});

// const defaultValue = { value: '', label: '' };

const AutocompleteField = ({
  noFormat,
  icon,
  helperText,
  onFocus,
  onBlur,
  onChange,
  error,
  extra,
  ...props
}) => {
  const handleChange = (e, newValue) => {
    const tmp = newValue || (props.defaultValue ? props.defaultValue : null);
    onChange(e, props.name, tmp);
  };

  return (
    <Grid container spacing={0} alignItems='flex-end'>
      <Grid item xs={noFormat ? false : 1}>
        {icon || ''}
      </Grid>
      <Grid item xs={noFormat ? 12 : 10}>
        <Autocomplete
          id={props.name}
          disableListWrap
          value={props.value}
          onChange={handleChange}
          onFocus={onFocus}
          onBlur={onBlur}
          ListboxComponent={ListboxComponent}
          options={props.options}
          getOptionLabel={option => option.label}
          noOptionsText='No encontramos resultados'
          renderInput={params => (
            <TextField
              {...params}
              variant={props.variant}
              label={props.label}
              fullWidth
              autoFocus={props.autoFocus}
              error={!!error}
              helperText={error || helperText}
              size={props.size || 'small'}
            />
          )}
        />
      </Grid>
      <Grid item xs={noFormat ? false : 1}>
        {extra || ''}
      </Grid>
    </Grid>
  );
};

// error={typeof error === 'string' ? true : false}
export default AutocompleteField;
