var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import { useReactiveVar } from '@apollo/client';
import SubtitleDialogComponent from '../../../../presentationals/Dialog/helpers/Subtitle';
import { classificationForm, inputError } from '../../../../apollo/cache';
import TextFieldComponent from '../../../../presentationals/FormComponents/TextField';
import SelectFieldComponent from '../../../../presentationals/FormComponents/SelectField';
import RadioComponent from '../../../../presentationals/FormComponents/Radio';
export var printerOptions = [
    { label: 'No imprimir', value: 0 },
    { label: 'Impresora 1', value: 1 },
    { label: 'Impresora 2', value: 2 },
    { label: 'Impresora 3', value: 3 },
    { label: 'Impresora 4', value: 4 },
    { label: 'Impresora 5', value: 5 },
];
var toSellOptions = [
    { value: true, label: 'Módulo de compra y venta' },
    { value: false, label: 'Solo módulo de compras' },
];
var BodyCreateclassificationComponent = function (props) {
    var values = useReactiveVar(classificationForm);
    var errors = useReactiveVar(inputError);
    var handleChange = function (name, value) {
        var _a;
        classificationForm(__assign(__assign({}, values), (_a = {}, _a[name] = value, _a)));
    };
    var handleRadioChange = function (name, value) {
        var _a;
        classificationForm(__assign(__assign({}, values), (_a = {}, _a[name] = value === 'true', _a)));
    };
    var handleKeyPress = function (e) {
        if (e.key === 'Enter') {
            e.preventDefault();
            props.onPressEnter && props.onPressEnter();
        }
    };
    return (React.createElement("div", { className: 'flex-1 px-6 py-4 flex flex-col md:grid md:grid-cols-4 md:gap-4 overflow-auto' },
        React.createElement("div", { className: 'mb-4 md:mb-0' },
            React.createElement(SubtitleDialogComponent, { label: 'Datos generales' }),
            React.createElement(TextFieldComponent, { label: 'Nombre', name: 'name', value: values.name, error: errors.name, onChange: handleChange, onKeyDown: handleKeyPress }),
            React.createElement(SelectFieldComponent, { label: 'Impresora asignada', name: 'printer', value: values.printer, error: errors.printer, onChange: handleChange, options: printerOptions }),
            React.createElement(RadioComponent, { name: 'toSell', label: '\u00BFDonde estar\u00E1 disponible esta clasificaci\u00F3n?', onChange: handleRadioChange, value: values.toSell, options: toSellOptions, error: false }))));
};
export default BodyCreateclassificationComponent;
