import { gql } from '@apollo/client';

const FRAGMENT_BILLING_INFORMATION = gql`
  fragment fragmentBillingInformation on CustomerBillingInformation {
    id
    identifier
    businessName
    addressLine1
    addressLine2
    zipCode
    city
    municipality
    state
    country
    email
    phone
    applyExtraTaxes
    paymentMethod
    paymentMethodId
    paymentForm
    useCfdi
    altEmail
    otherEmail
    tradename
    isrRetTax
    taxRegime
  }
`;

const GET = gql`
  query Customers($like: [String], $order: [String], $birthMonth: Int, $branchId: ID) {
    customers(like: $like, order: $order, birthMonth: $birthMonth, branchId: $branchId) {
      count
      rows {
        id
        code
        firstName
        lastName
        phone
        phone2
        addressLine1
        addressLine2
        zipCode
        city
        state
        priceName
        creditLimit
        creditDays
      }
    }
  }
`;

const GET_BY_ID = gql`
  ${FRAGMENT_BILLING_INFORMATION}
  query Customer($id: ID!) {
    customer(id: $id) {
      id
      firstName
      lastName
      phone
      phone2
      addressLine1
      addressLine2
      zipCode
      reference
      priceName
      creditLimit
      creditDays
      employeeId
      city
      state
      birthday
      rateId
      schoolName
      schoolGrade
      schoolBlock
      mainBranch
      admissionDate
      CustomerBillingInformation {
        ...fragmentBillingInformation
      }
      Calendars {
        id
        diagnosis
        status
        start
      }
      Crms {
        id
        status
        sessions
        sessionsTaken
        Product {
          name
        }
        createdAt
        AccountReceivable {
          total
          payment
          balance
        }
      }
      Employee {
        firstName
        lastName
      }
    }
  }
`;

const DELETE = gql`
  mutation Delete($id: ID!) {
    deleteCustomer(id: $id) {
      id
    }
  }
`;

const GET_NAME_AND_PHONE = gql`
  ${FRAGMENT_BILLING_INFORMATION}
  query Customers {
    customers {
      count
      rows {
        id
        firstName
        lastName
        phone
        phone2
        priceName
        employeeId
        rateId
        CustomerBillingInformation {
          ...fragmentBillingInformation
        }
      }
    }
  }
`;

const GET_BILLING_INFORMATION_BY_ID = gql`
  ${FRAGMENT_BILLING_INFORMATION}
  query Customer($id: ID!) {
    customer(id: $id) {
      CustomerBillingInformation {
        ...fragmentBillingInformation
      }
    }
  }
`;

const GET_BILLING_INFORMATIONS = gql`
  ${FRAGMENT_BILLING_INFORMATION}
  query CustomerBillingInformations($like: String, $likeKey: String) {
    customerBillingInformations(like: $like, likeKey: $likeKey) {
      ...fragmentBillingInformation
    }
  }
`;

const UPDATE_CUSTOMER_BILLING_INFO = gql`
  ${FRAGMENT_BILLING_INFORMATION}
  mutation UpdateCustomerBillingInformation(
    $id: ID!
    $identifier: String!
    $businessName: String!
    $addressLine1: String
    $addressLine2: String
    $zipCode: String
    $city: String
    $municipality: String
    $state: String
    $country: String
    $email: String!
    $phone: String
    $applyExtraTaxes: Boolean
    $paymentMethod: String
    $paymentMethodId: String
    $useCfdi: String
    $altEmail: String
    $otherEmail: String
    $tradename: String
  ) {
    updateCustomerBillingInformation(
      id: $id
      input: {
        identifier: $identifier
        businessName: $businessName
        addressLine1: $addressLine1
        addressLine2: $addressLine2
        zipCode: $zipCode
        city: $city
        municipality: $municipality
        state: $state
        country: $country
        email: $email
        phone: $phone
        applyExtraTaxes: $applyExtraTaxes
        paymentMethod: $paymentMethod
        paymentMethodId: $paymentMethodId
        useCfdi: $useCfdi
        altEmail: $altEmail
        otherEmail: $otherEmail
        tradename: $tradename
      }
    ) {
      ...fragmentBillingInformation
    }
  }
`;

const FRAGMENT_NEW_CUSTOMER = gql`
  fragment NewCustomer on Customer {
    id
    firstName
    lastName
    phone
    phone2
    addressLine1
    addressLine2
    zipCode
    reference
    city
    state
    priceName
    creditLimit
    employeeId
    creditDays
  }
`;

const GET_CHECK_IN = gql`
  query CustomerCheckIn($phone: String!) {
    customerCheckIn(phone: $phone) {
      id
      firstName
      lastName
    }
  }
`;

const GET_RATES = gql`
  query Rates($branchId: ID) {
    rates(branchId: $branchId) {
      rows {
        id
        name
      }
    }
  }
`;

const GET_CUSTOMER_BY_PHONE = gql`
  ${FRAGMENT_BILLING_INFORMATION}
  query CustomerBillingInformationByPhone($phone: String!) {
    customerBillingInformationByPhone(phone: $phone) {
      id
      phone
      CustomerBillingInformation {
        ...fragmentBillingInformation
      }
    }
  }
`;

export default {
  GET,
  GET_BY_ID,
  DELETE,
  GET_NAME_AND_PHONE,
  UPDATE_CUSTOMER_BILLING_INFO,
  GET_BILLING_INFORMATION_BY_ID,
  GET_BILLING_INFORMATIONS,
  FRAGMENT_NEW_CUSTOMER,
  GET_CHECK_IN,
  GET_RATES,
  GET_CUSTOMER_BY_PHONE,
  FRAGMENT_BILLING_INFORMATION,
};
