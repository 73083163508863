import React from 'react';

import Table from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import TableBody from './Body';

const ProductPromotionProductComponent = () => {
  return (
    <TableContainer className='mb-2'>
      <Table size='small'>
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell>Descripción</TableCell>
            <TableCell align='center'>Unidad</TableCell>
            <TableCell align='center'>% de Descuento</TableCell>
            <TableCell align='center'>Cant.</TableCell>
            <TableCell align='right'>Precio</TableCell>
          </TableRow>
        </TableHead>
        <TableBody />
      </Table>
    </TableContainer>
  );
};

export default ProductPromotionProductComponent;
