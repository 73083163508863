var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React, { useMemo, useRef } from 'react';
import { useMutation } from '@apollo/client';
import CreateContainerComponent from '../../../presentationals/CreateContainer';
import RHFormComponent from '../../../presentationals/RHForm/Form/indext';
import RHFSelectBranchFieldComponent from '../../../presentationals/RHForm/SelectBranchField';
import getDefaultManufactureValues from './helpers/getDefaultValues';
import ProductManufactureListComponent from './ProductsList';
import RHFButtonComponent from '../../../presentationals/RHForm/Button';
import RHFTextFieldComponent from '../../../presentationals/RHForm/TextField';
import FooterFormComponent from '../../../presentationals/RHForm/Form/Footer';
import getInputErrorFormat from '../../sale.v2/Action/helpers/getInputErrorFormat';
import GQL_MANUFACTURE from '../../../apollo/gql/manufacture';
import useSnackbarDialog from '../../../store/snackbar/snackbarDialog';
var CreateManufactureComponent = function () {
    var formRef = useRef(null);
    var setSnackbar = useSnackbarDialog(function (state) { return state.setSnackbar; });
    var defaultValues = useMemo(function () { return getDefaultManufactureValues(); }, []);
    var _a = useMutation(GQL_MANUFACTURE.CREATE_MANUFACTURE, {
        update: function (cache, _a) {
            var data = _a.data;
            try {
                cache.modify({
                    fields: {
                        manufactures: function (existingRefs) {
                            var newRef = cache.writeFragment({
                                data: data.createManufacture,
                                fragment: GQL_MANUFACTURE.FRAGMENT_MANUFACTURE,
                            });
                            return __spreadArray(__spreadArray([], existingRefs, true), [newRef], false);
                        },
                    },
                });
            }
            catch (error) {
                getInputErrorFormat(error);
            }
        },
    }), createManufacture = _a[0], loading = _a[1].loading;
    var handleSubmit = function (data) { return __awaiter(void 0, void 0, void 0, function () {
        var error_1;
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    _b.trys.push([0, 2, , 3]);
                    if (data.productsToSubtract.length === 0)
                        throw new Error('Añade al menos un producto a la lista de productos a descontar.');
                    if (data.productsToAdd.length === 0)
                        throw new Error('Añade al menos un producto a la lista de productos a manufacturar.');
                    return [4 /*yield*/, createManufacture({
                            variables: {
                                branchId: data.branchId,
                                waste: data.waste,
                                Products: data.productsToSubtract.map(function (el) { return ({
                                    productPresentationId: el.productPresentationId,
                                    quantity: el.qty,
                                }); }),
                                ProductsToManufacture: data.productsToAdd.map(function (el) { return ({
                                    productPresentationId: el.productPresentationId,
                                    quantity: el.qty,
                                }); }),
                            },
                        })];
                case 1:
                    _b.sent();
                    setSnackbar({
                        isOpen: true,
                        label: 'La producción / manufactura se realizó exitosamente.',
                        severity: 'success',
                    });
                    (_a = formRef.current) === null || _a === void 0 ? void 0 : _a.reset();
                    return [3 /*break*/, 3];
                case 2:
                    error_1 = _b.sent();
                    getInputErrorFormat(error_1);
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    return (React.createElement(CreateContainerComponent, { title: 'Producci\u00F3n / manufactura' },
        React.createElement(RHFormComponent, { ref: formRef, disableEnter: true, defaultValues: defaultValues, onSubmit: handleSubmit },
            React.createElement("div", { className: 'flex flex-1 flex-col basis-0 overflow-auto px-3 pt-2 pb-4' },
                React.createElement("div", { className: 'w-full sm:w-1/5' },
                    React.createElement(RHFSelectBranchFieldComponent, { label: 'Sucursal', name: 'branchId' })),
                React.createElement("div", { className: 'flex flex-1 flex-col sm:flex-row gap-0 sm:gap-4' },
                    React.createElement(ProductManufactureListComponent, { formName: 'subtractProductManufactureForm', name: 'productsToSubtract', label: 'Productos a descontar' }),
                    React.createElement(ProductManufactureListComponent, { formName: 'addProductManufactureForm', name: 'productsToAdd', label: 'Productos a a\u00F1adir' })),
                React.createElement("div", { className: 'flex w-full mt-2' },
                    React.createElement(RHFTextFieldComponent, { label: 'Escribe las mermas generadas durante el proceso', name: 'waste', rows: 2, multiline: true }))),
            React.createElement(FooterFormComponent, null,
                React.createElement("div", null),
                React.createElement(RHFButtonComponent, { disabled: loading }, "Almacenar")))));
};
export default CreateManufactureComponent;
