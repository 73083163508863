import React, { useEffect, useState } from 'react';
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import useApolloLazyQuery from '../../../../../../hooks/useApolloLazyQuery';
import GQL_BRANCH from '../../../../../../apollo/gql/branch';
import LoadingProgress from '../../../../../../presentationals/LoadingProgress';
import CheckboxComponent from '../../../../../../presentationals/RHForm/Checkbox';
import SelectAllBranchesCheckboxMultiCompanyReportComponent from './SelectAllCheckbox';
var ItemMultiCompanyReportComponent = function (props) {
    var _a = useState(true), expanded = _a[0], setExpanded = _a[1];
    var _b = useApolloLazyQuery(GQL_BRANCH.GET_BRANCHES), getBranches = _b[0], _c = _b[1], loading = _c.loading, data = _c.data;
    useEffect(function () {
        getBranches({ variables: { companyId: props.companyId } });
    }, []);
    var handleChange = function () {
        setExpanded(function (expanded) { return !expanded; });
    };
    return (React.createElement(Accordion, { expanded: expanded, onChange: handleChange, className: 'border-0 border-t border-solid border-gray-200' },
        React.createElement(AccordionSummary, { expandIcon: React.createElement(ExpandMoreIcon, null), "aria-controls": "".concat(props.companyName, "-content"), id: "".concat(props.companyName, "-header"), classes: {
                root: 'h-12',
                expanded: 'h-12',
            } },
            React.createElement(SelectAllBranchesCheckboxMultiCompanyReportComponent, { name: 'branches', label: props.companyName, disabled: !expanded, availableBranches: data ? data.rows.map(function (el) { return el.id; }) : [] })),
        React.createElement(AccordionDetails, { className: 'px-7 pb-3' }, loading ? (React.createElement(LoadingProgress, null)) : (data &&
            data.rows.map(function (el) { return (React.createElement(CheckboxComponent, { key: el.id, name: 'branches', label: el.name, value: el.id })); })))));
};
export default ItemMultiCompanyReportComponent;
