import React, { useState, useEffect } from 'react';
import { useQuery, useReactiveVar } from '@apollo/client';
import Grid from '@mui/material/Grid';
import TextField from '../../../../../presentationals/TextField';
import LinearForm from '../../../../../presentationals/LinearForm';
import Autocomplete from '../../../../../presentationals/Autocomplete';
import { item, clearForm } from '../../../../../apollo/cache';
import GQL_CLASSIFICATION from '../../../../../apollo/gql/classification';

const defaultValues = {
  // quantity: 1,
  price: '',
  classificationId: null,
  discountPercent: 0,
};

const ClassificationPromotionFormComponent = () => {
  const clearFormVar = useReactiveVar(clearForm);

  const [values, setValues] = useState(defaultValues);
  const [classifications, setClassifications] = useState([]);
  const [errors, setErrors] = useState({});
  const [focus, setFocus] = useState(false);
  const [itemsVisibility, setItemsVisibility] = useState(true);

  const { data } = useQuery(GQL_CLASSIFICATION.GET_CLASSIFICATION, { variables: { limit: 0 } });

  useEffect(() => {
    if (clearFormVar) {
      setValues(defaultValues);
      clearForm(false);
    }
  }, [clearFormVar]);

  useEffect(() => {
    if (!focus)
      setTimeout(() => {
        setItemsVisibility(true);
      }, 500);
  }, [focus]);

  useEffect(() => {
    if (data && data.classifications) {
      const tmpClassifications = data.classifications.rows.map(el => ({
        label: el.name.trim(),
        value: el.id,
      }));
      setClassifications(tmpClassifications);
    }
  }, [data]);

  const handleAdd = () => {
    try {
      setErrors({});
      if (!values.classificationId) {
        const e = new Error('Selecciona una clasificación.');
        e.name = 'classificationId';
        throw e;
      }

      if (
        values.discountPercent &&
        (parseFloat(values.discountPercent) < 0 || parseFloat(values.discountPercent) > 100)
      ) {
        const e = new Error('El porcentaje de descuento debe ser un número entre 0 y 100.');
        e.name = 'discountPercent';
        throw e;
      }
      if (Number.isNaN(parseInt(values.price)) || parseInt(values.price) < 0) {
        const e = new Error('Proporciona un precio.');
        e.name = 'price';
        throw e;
      }
      item({ ...values });
      setValues(defaultValues);
    } catch (e) {
      console.log(e.message);
      setErrors({ [e.name]: e.message });
    }
  };

  const handleAutocomplete = (e, name, value) => {
    value === '' || !value ? setFocus(true) : setFocus(false);
    (value === '' || !value) && setItemsVisibility(false);
    setErrors({});
    setValues(values => ({ ...values, [name]: value }));
  };

  const handleChange = e => {
    const { name, value } = e.target;
    setErrors({});
    setValues(values => ({ ...values, [name]: value }));
  };

  const handleAutocompleteFocus = () => {
    !values.classificationId && setFocus(true);
    !values.classificationId && setItemsVisibility(false);
  };

  const handleAutocompleteBlur = () => {
    setFocus(false);
  };

  return (
    <div className='my-3'>
      <LinearForm handleAdd={handleAdd}>
        <Grid container spacing={1}>
          <Grid item xs={12} md={focus ? 12 : 7}>
            <Autocomplete
              name='classificationId'
              label='Clasificación'
              variant='outlined'
              onFocus={handleAutocompleteFocus}
              onBlur={handleAutocompleteBlur}
              onChange={handleAutocomplete}
              options={classifications}
              value={values.classificationId}
              error={errors.classificationId}
              noFormat
            />
          </Grid>
          <Grid item xs={12} md={focus ? false : !itemsVisibility ? false : 5}>
            {itemsVisibility && (
              <Grid container spacing={1}>
                <Grid item md={6}>
                  <TextField
                    name='price'
                    label='Precio'
                    type='number'
                    variant='outlined'
                    onChange={handleChange}
                    value={values.price}
                    error={errors.price}
                    noFormat
                  />
                </Grid>
                <Grid item md={6}>
                  <TextField
                    name='discountPercent'
                    label='% de Descuento'
                    type='number'
                    variant='outlined'
                    onChange={handleChange}
                    value={values.discountPercent}
                    error={errors.discountPercent}
                    noFormat
                  />
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
      </LinearForm>
    </div>
  );
};

export default ClassificationPromotionFormComponent;
