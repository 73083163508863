var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React from 'react';
import CreditCardIcon from '@mui/icons-material/CreditCardOutlined';
import { Avatar, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import { branchSaleV2, cartItems, currencySaleV2, discountTypeSaleV2, formatSaleV2, isItemDetailDialogOpen, totalsSaleV2, } from '../../../../../../../../apollo/cache';
import GQL from '../../../../../../ChooseProduct/AddButton/_gql';
import useApolloMutation from '../../../../../../../../hooks/useApolloMutation';
import { currencyFormat } from '../../../../../../../../helpers';
import getTotalizedCartProducts from '../../../../../../helpers/getTotalizedCartProducts';
import getInputErrorFormat from '../../../../../../Action/helpers/getInputErrorFormat';
var HistoryItemRatesSaleHistoryBodyItemDetailDialogCartSaleV2Component = function (props) {
    var _a = useApolloMutation(GQL.CHECK_CART), checkCart = _a[0], loading = _a[1].loading;
    var handleClick = function () { return __awaiter(void 0, void 0, void 0, function () {
        var formatSaleV2Var, currencySaleV2Var, branchSaleV2Var, cartItemsVar, discountTypeSaleV2Var_1, newCart, data, cart, e_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    formatSaleV2Var = formatSaleV2();
                    currencySaleV2Var = currencySaleV2();
                    branchSaleV2Var = branchSaleV2();
                    cartItemsVar = cartItems();
                    discountTypeSaleV2Var_1 = discountTypeSaleV2();
                    newCart = cartItemsVar.map(function (el, i) { return ({
                        price: i === props.index
                            ? props.price
                                ? parseFloat(props.price.toString())
                                : 0
                            : el.unitPrice
                                ? parseFloat(el.unitPrice.toString())
                                : 0,
                        quantity: el.quantity ? parseFloat(el.quantity.toString()) : 0,
                        cost: el.cost ? parseFloat(el.cost.toString()) : 0,
                        discount: parseInt(discountTypeSaleV2Var_1.toString()) === 1
                            ? parseFloat(el.discountRate.toString())
                            : parseFloat(el.discount.toString()),
                        commentary: el.commentary,
                        productId: el.productId,
                        employeeId: el.employeeId,
                    }); });
                    return [4 /*yield*/, checkCart({
                            variables: {
                                isInvoice: formatSaleV2Var[2],
                                taxesOption: formatSaleV2Var[1],
                                discountType: parseInt(discountTypeSaleV2Var_1.toString()),
                                shipping: 0,
                                branchId: parseInt(branchSaleV2Var.branchInventory.toString()),
                                currencyKey: parseFloat(currencySaleV2Var[0].toString()),
                                exchangeRate: parseFloat(currencySaleV2Var[1].toString()),
                                category: parseInt(formatSaleV2Var[0].toString()),
                                Products: newCart,
                                Transactions: [],
                                showTaxes: formatSaleV2Var[3],
                            },
                        })];
                case 1:
                    data = (_a.sent()).data;
                    cart = getTotalizedCartProducts(data.totalizeCart.Products, cartItemsVar, {
                        isInvoice: formatSaleV2Var[2],
                        taxesOption: formatSaleV2Var[1],
                        showTaxes: formatSaleV2Var[3],
                    });
                    totalsSaleV2([
                        data.totalizeCart.subtotal,
                        data.totalizeCart.discount,
                        data.totalizeCart.taxes,
                        data.totalizeCart.total,
                    ]);
                    cartItems(cart);
                    isItemDetailDialogOpen(null);
                    return [3 /*break*/, 3];
                case 2:
                    e_1 = _a.sent();
                    getInputErrorFormat(e_1);
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    return (React.createElement(ListItem, { disabled: loading, button: true, onClick: handleClick },
        React.createElement(ListItemIcon, null,
            React.createElement(Avatar, null,
                React.createElement(CreditCardIcon, null))),
        React.createElement(ListItemText, { primary: "$".concat(currencyFormat(props.price)), secondary: props.date.toString() })));
};
export default HistoryItemRatesSaleHistoryBodyItemDetailDialogCartSaleV2Component;
