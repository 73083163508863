import React from 'react';
import useGetSessionRole from '../../../../../hooks/useGetSessionRole';
import convertToCurrencyLabel from '../../../../../helpers/convertToCurrencyLabel';
var CostSaleV2FieldComponent = function (props) {
    var _a, _b;
    var _c = useGetSessionRole(), data = _c.data, loading = _c.loading, isOwner = _c.isOwner;
    if (!data || loading)
        return React.createElement("div", null);
    return isOwner || ((_b = (_a = data === null || data === void 0 ? void 0 : data.Permissions) === null || _a === void 0 ? void 0 : _a.Product) === null || _b === void 0 ? void 0 : _b.showProductCost) ? (React.createElement("div", { className: 'flex flex-1 p-2' }, "Costo: $".concat(convertToCurrencyLabel(props.cost)))) : (React.createElement("div", null));
};
export default CostSaleV2FieldComponent;
