import React, { useState, useEffect } from 'react';

import { useQuery, useReactiveVar } from '@apollo/client';

import Grid from '@mui/material/Grid';
import Collapse from '@mui/material/Collapse';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import Typography from '@mui/material/Typography';

import TextField from '../../../../presentationals/TextField';
import SelectField from '../../../../presentationals/SelectField';
import Checkbox from '../../../../presentationals/Checkbox';
import DateField from '../../../../presentationals/DateField';

import { clearForm, step, handleContinue, form, steps } from '../../../../apollo/cache';

import { hourOptions } from '../../../../helpers';

import GQL_BRANCHES from '../../../branch/_gql';

import defaultSteps from '../helpers/defaultSteps';

const defaultValues = {
  name: '',
  start: null,
  end: null,
  availableDays: [],
  branchId: [],
  promotionType: 1,
  total: 0,
  quantity: 0,
  chargedQuantity: 0,
  startTime: 0,
  endTime: 1,
};

const CreatePromotionComponent = () => {
  const clearFormVar = useReactiveVar(clearForm);
  const stepVar = useReactiveVar(step);
  const handleContinueVar = useReactiveVar(handleContinue);

  const [allowDates, setAllowDates] = useState(false);
  const [values, setValues] = useState(defaultValues);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (values.promotionType) steps(defaultSteps(values.promotionType));
  }, [values.promotionType]);

  const { data } = useQuery(GQL_BRANCHES.GET, { variables: { limit: 0 } });

  const promotionFormValidation = () => {
    try {
      setErrors({});
      if (values.name === '') {
        const e = new Error('Proporciona el nombre de la promoción.');
        e.name = 'name';
        throw e;
      }
      if (values.availableDays.length === 0) {
        const e = new Error('Selecciona al menos un día para la promoción.');
        e.name = 'availableDays';
        throw e;
      }
      if (values.branchId.length === 0) {
        const e = new Error('Selecciona al menos una sucursal para la promoción.');
        e.name = 'branchId';
        throw e;
      }
      if (parseInt(values.promotionType) === 3 && parseFloat(values.total) <= 0) {
        const e = new Error('El total no puede ser menor o igual a cero.');
        e.name = 'total';
        throw e;
      }
      if (parseInt(values.promotionType) === 2 && parseFloat(values.quantity) <= 0) {
        const e = new Error('La cantidad de productos debe ser mayor a cero.');
        e.name = 'quantity';
        throw e;
      }
      if (parseInt(values.promotionType) === 2 && parseFloat(values.chargedQuantity) <= 0) {
        const e = new Error('La cantidad de productos a cobrar debe ser mayor a cero.');
        e.name = 'chargedQuantity';
        throw e;
      }
      step(1);
      form(values);
    } catch (e) {
      setErrors({ [e.name]: e.message });
    }
  };

  useEffect(() => {
    if (stepVar === 0 && handleContinueVar) {
      promotionFormValidation();
      handleContinue(false);
    }
  }, [stepVar, handleContinueVar]);

  useEffect(() => {
    if (data) {
      const tmp = data.branches.rows.map(el => el.id);
      setValues(values => ({ ...values, branchId: tmp }));
    }
  }, [data]);

  useEffect(() => {
    if (clearFormVar) {
      setValues(defaultValues);
      setAllowDates(false);
      clearForm(false);
    }
  }, [clearFormVar]);

  const handleSwitchChange = e => {
    setValues(values => ({
      ...values,
      start: !e.target.checked ? null : values.start,
      end: !e.target.checked ? null : values.end,
    }));
    setAllowDates(e.target.checked);
  };

  const handleCheckBox = (name, checked) => {
    const availableDays = checked
      ? [...values.availableDays, parseInt(name)]
      : values.availableDays.filter(el => parseInt(el) !== parseInt(name));
    setValues(values => ({ ...values, availableDays }));
  };

  const handleBranchCheckBox = (name, checked) => {
    const branchId = checked
      ? [...values.branchId, name]
      : values.branchId.filter(el => parseInt(el) !== parseInt(name));
    setValues(values => ({ ...values, branchId }));
  };

  const handleDateChange = (name, value) => {
    setValues(values => ({ ...values, [name]: value }));
  };

  const handleChange = e => {
    if (e.target.name === 'startTime') {
      setValues({ ...values, startTime: e.target.value, endTime: e.target.value + 1 });
      return;
    }
    setValues(values => ({ ...values, [e.target.name]: e.target.value }));
  };

  return (
    <>
      <Grid container spacing={1} className='my-1'>
        <Grid item xs={12} md={6}>
          <Typography variant='subtitle1'>¿Cuál será el nombre de la promoción?</Typography>
          <TextField
            name='name'
            variant='outlined'
            value={values.name}
            error={errors.name}
            onChange={handleChange}
            noFormat
          />
        </Grid>
      </Grid>
      <Grid container spacing={1} className='my-1'>
        <Grid item xs={12}>
          <Typography variant='subtitle1'>¿Qué días estará disponible?</Typography>
          <Checkbox
            handleChange={handleCheckBox}
            noFormat
            row
            values={[
              { name: '1', label: 'Lunes', checked: values.availableDays.includes(1) },
              { name: '2', label: 'Martes', checked: values.availableDays.includes(2) },
              { name: '3', label: 'Miércoles', checked: values.availableDays.includes(3) },
              { name: '4', label: 'Jueves', checked: values.availableDays.includes(4) },
              { name: '5', label: 'Viernes', checked: values.availableDays.includes(5) },
              { name: '6', label: 'Sábado', checked: values.availableDays.includes(6) },
              { name: '0', label: 'Domingo', checked: values.availableDays.includes(0) },
            ]}
          />
          {errors.availableDays && (
            <Typography variant='caption' color='error'>
              {errors.availableDays}
            </Typography>
          )}
        </Grid>
      </Grid>
      {data && data.branches.rows.length > 1 && (
        <Grid container spacing={1} className='my-1'>
          <Grid item xs={12}>
            <Typography variant='subtitle1'>¿En cuáles sucursales estará disponible?</Typography>
            <Checkbox
              handleChange={handleBranchCheckBox}
              noFormat
              row
              values={
                data &&
                data.branches.rows.map(el => ({
                  name: el.id,
                  label: el.name,
                  checked: values.branchId.includes(el.id),
                }))
              }
            />
            {errors.branchId && (
              <Typography variant='caption' color='error'>
                {errors.branchId}
              </Typography>
            )}
          </Grid>
        </Grid>
      )}
      <Grid container spacing={1} className='my-1'>
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Switch checked={allowDates} onChange={handleSwitchChange} name='allowDates' />
            }
            label='¿Deseás configurar una fecha de lanzamiento y expiración?'
          />
        </Grid>
        <Grid item xs={12} md={8}>
          <Collapse in={allowDates}>
            <Grid container spacing={1}>
              <Grid item xs={12} md={6}>
                <DateField
                  name='start'
                  label='Lanzamiento'
                  variant='outlined'
                  size='small'
                  value={values.start}
                  onChange={handleDateChange}
                  noFormat
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <DateField
                  name='end'
                  label='Expiración'
                  variant='outlined'
                  value={values.end}
                  onChange={handleDateChange}
                  size='small'
                  noFormat
                  helperText='La expiración no es obligatoria'
                />
              </Grid>
            </Grid>
            <br />
            <Grid container spacing={1}>
              <Grid item xs={12} md={6}>
                <SelectField
                  name='startTime'
                  label='Hora de inicio'
                  variant='outlined'
                  value={values.startTime}
                  onChange={handleChange}
                  size='small'
                  noFormat
                  options={hourOptions}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <SelectField
                  name='endTime'
                  label='Hora final'
                  variant='outlined'
                  value={values.endTime}
                  onChange={handleChange}
                  size='small'
                  noFormat
                  options={hourOptions}
                />
              </Grid>
            </Grid>
          </Collapse>
        </Grid>
      </Grid>
      <Grid container spacing={1} className='my-1'>
        <Grid item xs={12} md={6}>
          <Typography variant='subtitle1'>¿Qué tipo de contenido tendrá la promoción?</Typography>
          <SelectField
            name='promotionType'
            variant='outlined'
            value={values.promotionType}
            error={errors.promotionType}
            onChange={handleChange}
            size='small'
            options={[
              { value: 1, label: 'Productos' },
              { value: 2, label: 'Clasificación de productos' },
              { value: 3, label: 'Compra minima' },
            ]}
            noFormat
          />
        </Grid>
        {parseInt(values.promotionType) === 3 && (
          <Grid item xs={12} md={6}>
            <Typography variant='subtitle1'>Total</Typography>
            <TextField
              name='total'
              variant='outlined'
              value={values.total}
              error={errors.total}
              onChange={handleChange}
              size='small'
              type='number'
              noFormat
            />
          </Grid>
        )}
      </Grid>
      {parseInt(values.promotionType) === 2 && (
        <Grid container spacing={1} className='my-1'>
          <Grid item xs={12} md={6}>
            <Typography variant='subtitle1'>Cantidad de productos</Typography>
            <TextField
              name='quantity'
              variant='outlined'
              value={values.quantity}
              error={errors.quantity}
              onChange={handleChange}
              size='small'
              type='number'
              noFormat
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant='subtitle1'>Cantidad de productos a cobrar</Typography>
            <TextField
              name='chargedQuantity'
              variant='outlined'
              value={values.chargedQuantity}
              error={errors.chargedQuantity}
              onChange={handleChange}
              size='small'
              type='number'
              noFormat
            />
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default CreatePromotionComponent;
