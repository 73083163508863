var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useEffect, useState } from 'react';
import { Alert, AlertTitle } from '@mui/material';
import BusinessIcon from '@mui/icons-material/Business';
import ListItemPermissionEditRoleComponent from '../helpers/ListItem';
import SwitchPermissionFieldComponent from '../helpers/SwitchPermissionField';
import SelectPermissionFieldComponent from '../helpers/SelectPermissionField';
export var defaultBranchPermissionValues = {
    createNewBranches: false,
    editGeneralBranchesData: 0,
    editBranchBillingInformation: 0,
    createDesktopAppCredentials: 0,
    getBranches: 0,
    createBranchBillingInformation: 0,
    deleteBranchBillingInformation: 0,
    getBranchBillingInformation: 0,
};
var defaultOptions = [
    { value: 0, label: 'Ninguna' },
    { value: 1, label: 'Sucursal predeterminada' },
    { value: 2, label: 'Todas las sucursales' },
];
var BranchPermissionEditRoleComponent = function (props) {
    var _a = useState(defaultBranchPermissionValues), values = _a[0], setValues = _a[1];
    useEffect(function () {
        setValues(function (values) { return (__assign(__assign({}, values), props.Permissions)); });
    }, [props.Permissions]);
    return (React.createElement(ListItemPermissionEditRoleComponent, { icon: React.createElement(BusinessIcon, null), name: 'Sucursales' },
        React.createElement("div", { className: 'mx-4 py-3 grid grid-cols-1 md:grid-cols-3 gap-4' },
            React.createElement("div", { className: 'flex flex-col' },
                React.createElement(SwitchPermissionFieldComponent, __assign({}, props, { name: 'createNewBranches', label: 'Crear nuevas sucursales', defaultValue: values.createNewBranches })),
                React.createElement(SelectPermissionFieldComponent, __assign({}, props, { name: 'editGeneralBranchesData', label: 'Editar datos generales de una sucursal', defaultValue: values.editGeneralBranchesData, options: defaultOptions })),
                React.createElement(SelectPermissionFieldComponent, __assign({}, props, { name: 'createDesktopAppCredentials', label: 'Generar credenciales API para la aplicaci\u00F3n de escritorio', defaultValue: values.createDesktopAppCredentials, options: defaultOptions })),
                React.createElement(SelectPermissionFieldComponent, __assign({}, props, { name: 'getBranches', label: 'Consulta de datos', defaultValue: values.getBranches, options: defaultOptions })),
                React.createElement(SelectPermissionFieldComponent, __assign({}, props, { name: 'getBranchBillingInformation', label: 'Consultar datos fiscales de sucursales', defaultValue: values.getBranchBillingInformation, options: defaultOptions })),
                React.createElement(SelectPermissionFieldComponent, __assign({}, props, { name: 'createBranchBillingInformation', label: 'Crear nuevos datos fiscales de sucursales', defaultValue: values.createBranchBillingInformation, options: defaultOptions })),
                React.createElement(SelectPermissionFieldComponent, __assign({}, props, { name: 'editBranchBillingInformation', label: 'Editar datos fiscales de sucursales', defaultValue: values.editBranchBillingInformation, options: defaultOptions })),
                React.createElement(SelectPermissionFieldComponent, __assign({}, props, { name: 'deleteBranchBillingInformation', label: 'Eliminar datos fiscales de sucursales', defaultValue: values.deleteBranchBillingInformation, options: defaultOptions })))),
        React.createElement(Alert, { severity: 'warning', className: 'mx-4 mb-5' },
            React.createElement(AlertTitle, null, "\u00A1Aviso importante!"),
            "Solo los ",
            React.createElement("strong", null, "propietarios"),
            " de una empresa pueden",
            React.createElement("strong", null, " dar de baja"),
            " una sucursal.")));
};
export default BranchPermissionEditRoleComponent;
