import React from 'react';

import AddCircleIcon from '@mui/icons-material/AddOutlined';

import { actionForm } from '../../../../apollo/cache';

import IconButton from '../../../IconButton';

const CreateButtonComponent = props => {
  const handleCreate = () => {
    if (props.handleCustomCreate) {
      props.handleCustomCreate();
      return;
    }
    const variables = { isOpen: true, action: 'create' };
    actionForm({ ...actionForm(), ...variables });
  };
  return (
    <IconButton
      color='secondary'
      label='Crear'
      action={handleCreate}
      icon={<AddCircleIcon />}
      size='large'
    />
  );
};

export default CreateButtonComponent;
