import React from 'react';

import Container from '../../presentationals/Module.v2/Container';

import Filter from './_filter';
import Action from './_action';
import GQL from './_gql';

const Rows = [
  { label: 'Fecha', key: 'createdAt', sort: 'text', format: 'dateTime' },
  { label: 'Acción', key: 'action', strcat: '-', format: 'orderAuthorizationAction' },
  { label: 'Descripción', key: 'description' },
  { label: 'Código', key: 'code', strcat: '-' },
  { label: 'Estado', key: 'status', format: 'orderAuthorizationStatus' },
  { label: 'Vigencia', key: 'deadline', strcat: '-', format: 'dateTime' },
  {
    label: 'Solicitó',
    key: 'Employee firstName && Employee lastName',
    sort: 'text',
    align: 'center',
  },
  {
    label: 'Autorizó',
    key: 'AuthEmployee firstName && AuthEmployee lastName',
    strcat: '-',
    sort: 'text',
  },
  { label: 'Sucursal', key: 'Branch name', align: 'center' },
];
const title = 'Autorizaciones';
const operation = ['orderAuthorizations', 'orderAuthorizations'];

const OrderAuthorizationComponent = () => {
  return (
    <>
      <Container
        title={title}
        operation={operation}
        Rows={Rows}
        get={GQL.GET}
        filter
        noDelete
        config
      />
      <Action />
      <Filter />
    </>
  );
};

export default OrderAuthorizationComponent;
