import React, { useState } from 'react';
import BodyInvoiceComplementComponent from '../../../receivable/CustomerReceivables/InvoiceComplementDialog/Body';
import CustomerFieldComponent from '../../../../presentationals/FormComponents/CustomerField';
var BodyCreateInvoiceComplementComponent = function () {
    var _a = useState(undefined), customerId = _a[0], setCustomerId = _a[1];
    var handleChange = function (name, value) {
        setCustomerId(value);
    };
    return (React.createElement("div", { className: 'flex flex-1 flex-col' },
        React.createElement("div", { className: 'w-full sm:w-1/3 px-4 pt-3' },
            React.createElement(CustomerFieldComponent, { onChange: handleChange })),
        customerId ? (React.createElement(BodyInvoiceComplementComponent, { customerId: customerId })) : (React.createElement("div", { className: 'flex flex-1 justify-center items-center p-4' },
            React.createElement("div", { className: 'flex flex-col items-center' },
                React.createElement("div", { className: 'text-center' }, "En espera de la selecci\u00F3n de un cliente para mostrar las transacciones disponibles para realizar un complemento de pago."))))));
};
export default BodyCreateInvoiceComplementComponent;
