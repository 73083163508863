import React, { useState } from 'react';

import { useReactiveVar } from '@apollo/client';

import TablePagination from '@mui/material/TablePagination';

import { count, filter } from '../../../../apollo/cache';

const TablePaginationComponent = () => {
  const countVar = useReactiveVar(count);
  const filterVar = useReactiveVar(filter);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);

  const labelDisplayedRows = ({ from, to, count }) => `${from}-${to} de ${count}`;
  const handleChangePage = (e, newPage) => {
    setPage(newPage);
    filter({ ...filterVar, limit: rowsPerPage, offset: newPage });
  };

  const handleChangeRowsPerPage = e => {
    setRowsPerPage(parseInt(e.target.value));
    setPage(0);
    filter({ ...filterVar, limit: parseInt(e.target.value), offset: 0 });
  };
  return (
    <div className='border-0 border-t border-solid border-[#e0e0e0] bg-white'>
      <TablePagination
        rowsPerPageOptions={[25, 50, 100]}
        component='div'
        labelRowsPerPage='Filas por página:'
        labelDisplayedRows={labelDisplayedRows}
        count={countVar || 0}
        rowsPerPage={rowsPerPage}
        page={countVar > 0 ? page : 0}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        classes={{
          root: 'h-12 items-center justify-end text-right overflow-hidden',
          actions: 'h-12 items-center flex',
        }}
      />
    </div>
  );
};

export default TablePaginationComponent;
