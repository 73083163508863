import React from 'react';
import LinkMenuDashboardComponent from '../../helpers/Link';
import GQL_COMPANY from '../../../../../../apollo/gql/company';
import useApolloQuery from '../../../../../../hooks/useApolloQuery';
var MaintenanceLinkBlockOneMenuDashboardComponent = function (props) {
    var _a;
    var data = useApolloQuery(GQL_COMPANY.GET_ALLOWED_MODULES).data;
    if (!data || (data && !((_a = data.maintenance) === null || _a === void 0 ? void 0 : _a.isAllowed)) || ![1, 3].includes(props.posModule))
        return null;
    return (React.createElement(LinkMenuDashboardComponent, { url: 'maintenance', primary: '\u00D3rdenes de mantenimiento', secondary: 'Consulta, edita y da seguimiento a orden de mantenimiento.' }));
};
export default MaintenanceLinkBlockOneMenuDashboardComponent;
