import React, { useState, useEffect } from 'react';
import { useMutation } from '@apollo/client';
import FileCopyIcon from '@mui/icons-material/FileCopyOutlined';
import ListAltIcon from '@mui/icons-material/ListAltOutlined';
import PrintIcon from '@mui/icons-material/Print';
import AttachMoneyIcon from '@mui/icons-material/AttachMoneyOutlined';
import EmailIcon from '@mui/icons-material/EmailOutlined';
import ImageIcon from '@mui/icons-material/ImageOutlined';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import KeyIcon from '@mui/icons-material/Key';
import { actions, isCloseQuotationOpen } from '../../apollo/cache';
import ProductsDialog from './products.dialog';
import ReSendDialog from './reSend.dialog';
import DuplicateDialog from './DuplicateQuotation';
import FileDialog from './file.dialog';
import SendWhatsappDialog from './sendWhatsapp.dialog';
import CancelForm from './cancel.form';
import CONFIG from '../../config/config.json';
import CloseQuotationComponent from './CloseQuotation';
import GQL_QUOTATION from '../../apollo/gql/quotation';
import getInputErrorFormat from '../sale.v2/Action/helpers/getInputErrorFormat';

const defaultDialog = [false, null];

const ActionComponent = () => {
  const [authQuotation] = useMutation(GQL_QUOTATION.AUTH_QUOTATION);
  const [isOpenProducts, setIsOpenProducts] = useState([false, null]);
  const [isOpenReSend, setIsOpenReSend] = useState([false, null]);
  const [isOpenImage, setIsOpenImage] = useState([false, null]);
  const [isOpenDuplicate, setIsOpenDuplicate] = useState([false, null]);
  const [isOpenSendWhatsapp, setIsOpenSendWhatsapp] = useState([false, null]);
  const [isOpenCancelQuote, setIsOpenCancelQuote] = useState(defaultDialog);

  const handleProducts = (e, { id }) => setIsOpenProducts([true, id]);

  const handleCloseProducts = () => setIsOpenProducts([false, null]);

  const handlePDF = (e, { id }) => {
    window.open(`${CONFIG['restServer'].uri}/files/quotes/${id}`);
  };

  const handleAuthQuotation = async (e, { id }) => {
    try {
      await authQuotation({ variables: { id } });
    } catch (error) {
      getInputErrorFormat(error);
    }
  };
  const handleGenerateSale = (e, { id }) => isCloseQuotationOpen([true, id]);
  // const handleCloseGenerateSale = () => setIsOpenGenerateSale([false, null]);

  const handleReSend = (e, { id }) => setIsOpenReSend([true, id]);
  const handleCloseReSend = () => setIsOpenReSend([false, null]);

  const handleSendWhtasapp = (e, { id }) => setIsOpenSendWhatsapp([true, id]);
  const handleCloseSendWhatsapp = () => setIsOpenSendWhatsapp([false, null]);

  const handleImage = (e, { id }) => setIsOpenImage([true, id]);
  const handleCloseImage = () => setIsOpenImage([false, null]);

  const handleDuplicate = (e, { id }) => setIsOpenDuplicate([true, id]);
  const handleCloseDuplicate = () => setIsOpenDuplicate([false, null]);

  const handleCancel = (e, { id }) => setIsOpenCancelQuote([true, id]);
  const handleCloseCancel = () => setIsOpenCancelQuote([false, null]);

  useEffect(() => {
    actions(
      [
        {
          icon: <KeyIcon />,
          label: 'Autorizar cotización',
          onClick: handleAuthQuotation,
          disable: { key: 'status', notValue: 1 },
        },
        {
          icon: <FileCopyIcon />,
          label: 'Duplicar Cotización',
          onClick: handleDuplicate,
        },
        {
          icon: <EmailIcon />,
          label: 'Reenviar PDF',
          onClick: handleReSend,
        },
        {
          icon: <WhatsAppIcon />,
          label: 'Reenviar por Whatsapp',
          onClick: handleSendWhtasapp,
        },
        {
          icon: <ListAltIcon />,
          label: 'Productos',
          onClick: handleProducts,
          disable: { key: 'status', value: 3 },
        },
        {
          icon: <PrintIcon />,
          label: 'Imprimir PDF',
          onClick: handlePDF,
        },
        {
          icon: <AttachMoneyIcon />,
          label: 'Vender',
          onClick: handleGenerateSale,
          disable: { key: 'status', value: 3 },
        },
        {
          icon: <ImageIcon />,
          label: 'Imagen',
          onClick: handleImage,
        },
        {
          icon: <DeleteOutlineIcon />,
          label: 'Cancelar Cotización',
          onClick: handleCancel,
          disable: { key: 'status', value: 0 },
        },
      ].filter(el => el),
    );
    return () => {
      actions([]);
    };
  }, []);

  return (
    <>
      <ReSendDialog id={isOpenReSend[1]} isOpen={isOpenReSend[0]} handleClose={handleCloseReSend} />
      <SendWhatsappDialog
        id={isOpenSendWhatsapp[1]}
        isOpen={isOpenSendWhatsapp[0]}
        handleClose={handleCloseSendWhatsapp}
      />
      <ProductsDialog
        isOpen={isOpenProducts[0]}
        handleClose={handleCloseProducts}
        id={isOpenProducts[1]}
      />
      <FileDialog isOpen={isOpenImage[0]} id={isOpenImage[1]} handleClose={handleCloseImage} />
      <DuplicateDialog
        isOpen={isOpenDuplicate[0]}
        id={isOpenDuplicate[1]}
        handleClose={handleCloseDuplicate}
      />

      <CancelForm
        isOpen={isOpenCancelQuote[0]}
        handleClose={handleCloseCancel}
        id={isOpenCancelQuote[1]}
      />
      <CloseQuotationComponent />
    </>
  );
};

export default ActionComponent;
