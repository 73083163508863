import React, { useState, useEffect } from 'react';
import { useLazyQuery } from '@apollo/client';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import { currencyFormat } from '../../helpers';

import FormDialog from '../../presentationals/FormDialog';
import GQL from '../purchaseOrder.v2/_gql';

const Component = ({ purchaseOrderId, ...props }) => {
  const [data, setData] = useState({});

  const [getProducts] = useLazyQuery(GQL.GET_BY_ID, {
    onCompleted: data => {
      setData(data.purchaseOrder);
    },
  });

  useEffect(() => {
    if (purchaseOrderId !== null) getProducts({ variables: { id: purchaseOrderId } });
  }, [purchaseOrderId]); // eslint-disable-line

  return (
    <FormDialog
      title='Productos'
      isOpen={props.isOpen || false}
      handleClose={() => props.handleClose()}
      width='md'
      hideAction
    >
      <Table size='small'>
        <TableHead>
          <TableRow>
            <TableCell>Producto</TableCell>
            <TableCell>Presentación</TableCell>
            <TableCell align='center'>Cant.</TableCell>
            <TableCell align='right'>Desc.</TableCell>
            <TableCell align='right'>P. Unitario</TableCell>
            <TableCell align='right'>Importe</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data &&
            data.PurchaseOrderDetails &&
            data.PurchaseOrderDetails.map(el => (
              <TableRow hover key={el.id}>
                <TableCell>{el.Product.name}</TableCell>
                <TableCell>{el.ProductPresentation.name}</TableCell>
                <TableCell align='center'>{currencyFormat(el.quantity)}</TableCell>
                <TableCell align='right'>${currencyFormat(el.discount)}</TableCell>
                <TableCell align='right'>${currencyFormat(el.unitPrice)}</TableCell>
                <TableCell align='right'>${currencyFormat(el.total)}</TableCell>
              </TableRow>
            ))}
          <TableRow>
            <TableCell colSpan={5} align='right'>
              Subtotal:
            </TableCell>
            <TableCell align='right'>
              ${currencyFormat(data && data.subtotal ? data.subtotal : 0)}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell colSpan={5} align='right'>
              Descuento:
            </TableCell>
            <TableCell align='right'>
              ${currencyFormat(data && data.discount ? data.discount : 0)}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell colSpan={5} align='right'>
              Impuestos:
            </TableCell>
            <TableCell align='right'>
              ${currencyFormat(data && data.taxes ? data.taxes : 0)}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell colSpan={5} align='right'>
              <strong>Total:</strong>
            </TableCell>
            <TableCell align='right'>
              <strong>${currencyFormat(data && data.total ? data.total : 0)}</strong>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </FormDialog>
  );
};

export default Component;
