var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useRef, useState } from 'react';
import { Link, Switch, Typography } from '@mui/material';
import { useReactiveVar } from '@apollo/client';
import ProductPresentationField from './ProductPresentationField';
import PriceTypeField from './PriceTypeField';
import { createECFormColor } from '../../../../../../../../../apollo/cache';
import TextFieldComponent from '../../../../../../../../../presentationals/FormComponents/TextField';
import getInputErrorFormat from '../../../../../../../../sale.v2/Action/helpers/getInputErrorFormat';
var BodyFormAddButtonColorEcommerceEditFormComponent = function () {
    var inputRef = useRef(null);
    var values = useReactiveVar(createECFormColor);
    var _a = useState(true), isHexa = _a[0], setIsHexa = _a[1];
    var handleClick = function (e) {
        e.preventDefault();
        (inputRef === null || inputRef === void 0 ? void 0 : inputRef.current) && inputRef.current.click();
    };
    var handleChange = function (name, value) {
        var _a;
        createECFormColor(__assign(__assign({}, values), (_a = {}, _a[name] = value, _a)));
    };
    var handleChangeFile = function (e) {
        var fileObj = e.target.files && e.target.files[0];
        if (!fileObj)
            return;
        e.target.value = '';
        createECFormColor(__assign(__assign({}, values), { file: fileObj }));
    };
    var handleSwitch = function (event) {
        try {
            var isChecked = event.target.checked;
            if (!isChecked)
                createECFormColor(__assign(__assign({}, values), { attribute: '' }));
            else
                createECFormColor(__assign(__assign({}, values), { file: null }));
            setIsHexa(isChecked);
        }
        catch (e) {
            getInputErrorFormat(e);
        }
    };
    return (React.createElement("div", { className: 'flex-1 grid grid-cols-3 p-4' },
        React.createElement("div", null,
            React.createElement(TextFieldComponent, { onChange: handleChange, value: values.name, name: 'name', label: 'Nombre' }),
            React.createElement("div", { className: 'flex flex-row items-center' },
                React.createElement(Typography, { variant: 'subtitle1' }, "C\u00F3digo hexadecimal o imagen"),
                React.createElement(Switch, { checked: isHexa, onChange: handleSwitch, name: 'switchShowTaxes' })),
            isHexa ? (React.createElement(TextFieldComponent, { onChange: handleChange, value: values.attribute, name: 'attribute', label: 'C\u00F3digo', placeholder: '#ffffff' })) : (React.createElement("div", { className: 'flex border-solid border border-gray-400 rounded p-10 items-center justify-center' },
                React.createElement(Link, { href: '#', onClick: handleClick, className: 'text-sm' }, "A\u00F1adir imagen PNG 32x32"),
                React.createElement("input", { type: 'file', ref: inputRef, className: 'hidden', onChange: handleChangeFile }))),
            React.createElement(TextFieldComponent, { onChange: handleChange, value: values.price, name: 'price', label: 'Precio' }),
            React.createElement(PriceTypeField, { onChange: handleChange, value: values.priceType.toString() }),
            React.createElement(ProductPresentationField, { onChange: handleChange, value: values.presentationId.toString() }))));
};
export default BodyFormAddButtonColorEcommerceEditFormComponent;
