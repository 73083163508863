import React, { useEffect, useState } from 'react';
import SelectFieldComponent from '../SelectField';
import getLocalStorageSearchProductBy from '../../../helpers/getLocalStorageSearchProductBy';
import setLocalStorageSearchProductBy from '../../../helpers/setLocalStorageSearchProductBy';
export var searchProductsByOptions = [
    { value: 'name', label: 'Nombre' },
    { value: 'sku', label: 'SKU' },
    { value: 'barcode', label: 'Código de barras' },
];
var SearchProductBySelectFieldComponent = function (props) {
    var _a = useState(''), value = _a[0], setValue = _a[1];
    useEffect(function () {
        var searchByValue = getLocalStorageSearchProductBy();
        setValue(searchByValue);
    }, []);
    var handleChange = function (name, value) {
        setLocalStorageSearchProductBy(value);
        setValue(value);
        props.onChange && props.onChange(name, value);
    };
    return (React.createElement(SelectFieldComponent, { name: 'searchBy', label: 'B\u00FAsqueda por', options: searchProductsByOptions, onChange: handleChange, disabled: props.disabled, value: value }));
};
export default SearchProductBySelectFieldComponent;
