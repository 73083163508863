import React, { useState, useEffect } from 'react';

import { useReactiveVar } from '@apollo/client';

import Grid from '@mui/material/Grid';

import TextField from '../../../../../presentationals/FormComponents/TextField';
import SelectField from '../../../../../presentationals/FormComponents/SelectField';
import Button from '../../../../../presentationals/Button';

import { item, clearForm, form, step } from '../../../../../apollo/cache';

const defaultValues = {
  quantity: 1,
  price: 0,
  discount: 0,
  concept: '',
  iva: 16,
  ieps: 0,
};

const ivaOptions = [
  { label: '16%', value: 16 },
  { label: '8%', value: 8 },
  { label: '0%', value: 0 },
  { label: 'EXENTO', value: -1 },
];

const ProductFormComponent = () => {
  const clearFormVar = useReactiveVar(clearForm);
  const formVar = useReactiveVar(form);
  const stepVar = useReactiveVar(step);

  const [values, setValues] = useState(defaultValues);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (clearFormVar && stepVar === 1) {
      setValues(defaultValues);
      clearForm(false);
    }
  }, [clearFormVar]);

  const handleAdd = async () => {
    try {
      setErrors({});
      if (!values.concept || values.concept.trim() === '') {
        const e = new Error('Proporciona un concepto.');
        e.name = 'concept';
        throw e;
      }
      if (Number.isNaN(parseFloat(values.quantity)) || parseFloat(values.quantity) <= 0) {
        const e = new Error('Proporciona una cantidad.');
        e.name = 'quantity';
        throw e;
      }
      if (Number.isNaN(parseFloat(values.quantity)) || parseFloat(values.quantity) <= 0) {
        const e = new Error('Proporciona una cantidad.');
        e.name = 'quantity';
        throw e;
      }
      if (Number.isNaN(parseFloat(values.price)) || parseFloat(values.price) < 0) {
        const e = new Error('Proporciona un precio unitario.');
        e.name = 'price';
        throw e;
      }
      if (Number.isNaN(parseFloat(values.ieps)) || parseFloat(values.ieps) < 0) {
        const e = new Error('El IEPS es inválido.');
        e.name = 'ieps';
        throw e;
      }

      const total =
        parseFloat(values.quantity) * parseFloat(values.price) - parseFloat(values.discount);

      item({ ...values, total });
      setValues(defaultValues);
    } catch (e) {
      console.log(e.message);
      setErrors({ [e.name]: e.message });
    }
  };
  const handleChange = (name, value) => {
    setErrors({});
    setValues(values => ({ ...values, [name]: value }));
  };
  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <TextField
            name='concept'
            label='Concepto'
            onChange={handleChange}
            value={values.concept}
            error={errors.concept}
          />
        </Grid>
      </Grid>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <TextField
            name='quantity'
            label='Cantidad'
            type='number'
            onChange={handleChange}
            value={values.quantity}
            error={errors.quantity}
          />
        </Grid>
      </Grid>
      {formVar.action === '3' && (
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <TextField
              name='discount'
              label='Descuento'
              type='number'
              onChange={handleChange}
              value={values.discount}
              error={errors.discount}
            />
          </Grid>
        </Grid>
      )}
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <TextField
            name='price'
            label='Precio unitario de compra'
            type='number'
            onChange={handleChange}
            value={values.price}
            error={errors.price}
          />
        </Grid>
      </Grid>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <SelectField
            name='iva'
            label='IVA'
            type='number'
            onChange={handleChange}
            value={values.iva}
            error={errors.iva}
            options={ivaOptions}
          />
        </Grid>
      </Grid>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <TextField
            name='ieps'
            label='IEPS'
            type='number'
            onChange={handleChange}
            value={values.ieps}
            error={errors.ieps}
          />
        </Grid>
      </Grid>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Button className='mt-1' onClick={handleAdd} label='Añadir producto' />
        </Grid>
      </Grid>
    </>
  );
};

export default ProductFormComponent;
