import React, { useEffect, useState } from 'react';
import { useReactiveVar } from '@apollo/client';
import useGetSaleProductsAndIngredientsRotation from './helpers/useGetSaleProductsAndIngredientsRotation';
import LoadingProgress from '../../presentationals/LoadingProgress';
import ModuleV3Component from '../../presentationals/Module.v3';
import FilterSaleProductPresentationRotationReportComponent from './Filter';
import ErrorDataComponent from '../../presentationals/ErrorData';
import { filter } from '../../apollo/cache';
import RedirectEditFormToRouteComponent from '../../presentationals/RedirectEditFormToRoute';
var SaleProductsAndIngredientRotationReportComponent = function () {
    var filterVar = useReactiveVar(filter);
    var _a = useState(undefined), queryParams = _a[0], setQueryParams = _a[1];
    var _b = useGetSaleProductsAndIngredientsRotation(), data = _b.data, loading = _b.loading;
    var header = [
        { Header: 'SKU', accessor: 'sku' },
        { Header: 'Producto', accessor: 'product' },
        { Header: 'Cantidad', accessor: 'quantity' },
        { Header: 'Importe', accessor: 'total' },
    ];
    useEffect(function () {
        if (filterVar.start && filterVar.end)
            setQueryParams({ start: filterVar.start.toString(), end: filterVar.end.toString() });
    }, [filterVar]);
    if (loading)
        return React.createElement(LoadingProgress, null);
    if (!data)
        return React.createElement(ErrorDataComponent, null);
    return (React.createElement(React.Fragment, null,
        React.createElement(ModuleV3Component, { title: 'Todos', searchLabel: 'todos', filter: true, data: data, header: header, hideAdd: true, isLoading: loading }),
        React.createElement(FilterSaleProductPresentationRotationReportComponent, null),
        React.createElement(RedirectEditFormToRouteComponent, { queryParams: queryParams })));
};
export default SaleProductsAndIngredientRotationReportComponent;
