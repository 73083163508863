import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import WorkingDaysFieldComponent from './WorkingDaysField';
import WorkingHoursFieldComponent from './WorkingHoursField';
import FooterDialogComponent from '../../../../presentationals/Dialog/Footer';
import EditButtonWorkingHoursEmployeeComponent from './EditButton';
import BreakTimeComponent from './BreakTime';
import LoadingProgress from '../../../../presentationals/LoadingProgress';
import ErrorDataComponent from '../../../../presentationals/ErrorData';
import useApolloLazyQuery from '../../../../hooks/useApolloLazyQuery';
import GQL_EMPLOYEE from '../../../../apollo/gql/employee';
import { workingHoursForm } from '../../../../apollo/cache';
var WorkingHoursEditEmployeeComponent = function () {
    var params = useParams();
    var _a = useApolloLazyQuery(GQL_EMPLOYEE.GET_EMPLOYEE_BY_ID), getById = _a[0], _b = _a[1], loading = _b.loading, data = _b.data;
    useEffect(function () {
        if (params.employeeId)
            getById({ variables: { id: params.employeeId } });
    }, [params]);
    useEffect(function () {
        if (data) {
            var currentSchedule = data.schedule ? JSON.parse(data.schedule) : [];
            workingHoursForm(currentSchedule.filter(function (el) { return el.entry >= 0; }));
        }
    }, [data]);
    if (loading)
        return React.createElement(LoadingProgress, null);
    if (!data)
        return React.createElement(ErrorDataComponent, null);
    return (React.createElement("div", { className: 'flex flex-1 flex-col' },
        React.createElement("div", { className: 'px-4 py-3 flex-1 flex flex-col basis-0 overflow-auto' },
            React.createElement("div", { className: 'text-lg' }, "Horario laboral"),
            React.createElement("div", { className: 'text-sm text-gray-600' }, "Configura el horario laboral del empleado para dar lectura del informe de asistencias."),
            React.createElement("div", { className: 'mt-3' },
                React.createElement(WorkingDaysFieldComponent, null)),
            React.createElement("div", { className: 'mt-3' },
                React.createElement(WorkingHoursFieldComponent, null)),
            React.createElement("div", { className: 'text-lg mt-5' }, "Descanso en horario laboral"),
            React.createElement("div", { className: 'text-sm text-gray-600' }, "Configura el descanso / horario de comida durante el horario laboral del empleado."),
            React.createElement(BreakTimeComponent, null)),
        React.createElement(FooterDialogComponent, null,
            React.createElement("div", null),
            React.createElement(EditButtonWorkingHoursEmployeeComponent, null))));
};
export default WorkingHoursEditEmployeeComponent;
