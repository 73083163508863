import React, { useEffect, useState } from 'react';
import { TableCell, TableRow } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { dateTimeFormat } from '../../../../../helpers';
import convertToCurrencyLabel from '../../../../../helpers/convertToCurrencyLabel';
import getAccountReceivableBackgroundColor from '../../../Body/Item/helpers/getBackgroundColor';
var ItemCustomerReceivablesComponent = function (props) {
    var navigate = useNavigate();
    var _a = useState(null), background = _a[0], setBackground = _a[1];
    useEffect(function () {
        var background = getAccountReceivableBackgroundColor(props.AccountReceivable.background);
        setBackground(background);
    }, [props.AccountReceivable.background]);
    var handleClick = function () {
        navigate("".concat(props.AccountReceivable.id));
    };
    return (React.createElement(TableRow, { onClick: handleClick },
        React.createElement(TableCell, { className: background || (props.index % 2 === 0 ? 'bg-slate-100' : '') }, dateTimeFormat(props.AccountReceivable.clientCreatedAt, 'dd/MM/yyyy')),
        React.createElement(TableCell, { className: background || (props.index % 2 === 0 ? 'bg-slate-100' : '') }, props.AccountReceivable.limitDate
            ? dateTimeFormat(props.AccountReceivable.limitDate, 'dd/MM/yyyy')
            : '-'),
        React.createElement(TableCell, { className: background || (props.index % 2 === 0 ? 'bg-slate-100' : '') }, props.AccountReceivable.folio),
        React.createElement(TableCell, { className: background || (props.index % 2 === 0 ? 'bg-slate-100' : '') }, props.AccountReceivable.seller),
        React.createElement(TableCell, { className: "".concat(background || (props.index % 2 === 0 ? 'bg-slate-200' : 'bg-slate-100'), " text-right") },
            "$",
            convertToCurrencyLabel(props.AccountReceivable.total)),
        React.createElement(TableCell, { className: "".concat(background || (props.index % 2 === 0 ? 'bg-slate-200' : 'bg-slate-100'), " text-right") },
            "$",
            convertToCurrencyLabel(props.AccountReceivable.payment)),
        React.createElement(TableCell, { className: "".concat(background || (props.index % 2 === 0 ? 'bg-slate-200' : 'bg-slate-100'), " text-right") },
            "$",
            convertToCurrencyLabel(props.AccountReceivable.balance))));
};
export default ItemCustomerReceivablesComponent;
