import React, { useEffect, useState } from 'react';
import { Area, AreaChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis, } from 'recharts';
import { Link } from '@mui/material';
import getFormattedSaleByDayData from './helpers/getFormattedData';
var SaleByDayChartIncomeReportComponent = function (props) {
    var _a = useState([]), data = _a[0], setData = _a[1];
    var _b = useState(true), chartByTotal = _b[0], setCharByTotal = _b[1];
    useEffect(function () {
        if (props.Sales && props.Sales.length > 0) {
            var data_1 = getFormattedSaleByDayData(props.Sales, chartByTotal);
            setData(data_1);
        }
    }, [props.Sales, chartByTotal]);
    var handleClick = function () {
        setCharByTotal(function (chartByTotal) { return !chartByTotal; });
    };
    return (React.createElement("div", { className: 'flex flex-1 mt-2 w-full max-w-full sm:mt-0 flex-col min-h-[300px] sm:min-h-0 overflow-hidden' },
        React.createElement("div", { className: 'flex justify-between items-end' },
            React.createElement("div", { className: 'text-lg' }, "Ventas por d\u00EDa de la semana"),
            React.createElement(Link, { onClick: handleClick, className: 'text-sky-600 no-underline hover:underline text-sm' }, "Ver por ".concat(chartByTotal ? 'promedio' : 'totales'))),
        React.createElement("div", { className: 'flex flex-1 max-w-full overflow-hidden bg-slate-50 rounded-lg mt-2 border-solid border-slate-200 p-4' },
            React.createElement(ResponsiveContainer, { width: '100%', height: '100%' },
                React.createElement(AreaChart, { height: 400, data: data, margin: {
                        top: 10,
                        right: 30,
                        left: 0,
                        bottom: 0,
                    } },
                    React.createElement(CartesianGrid, { strokeDasharray: '3 3' }),
                    React.createElement(XAxis, { dataKey: 'name' }),
                    React.createElement(YAxis, null),
                    React.createElement(Tooltip, null),
                    React.createElement(Area, { type: 'monotone', name: 'Periodo anterior', dataKey: 'lastPeriod', stroke: '#e60000', fill: '#e60000', strokeWidth: 2, fillOpacity: 0.5, dot: { stroke: '#e60000', strokeWidth: 2 } }),
                    React.createElement(Area, { type: 'monotone', name: 'Periodo actual', dataKey: 'currentPeriod', stroke: '#007800', strokeWidth: 2, fill: '#007800', fillOpacity: 0.8, dot: { stroke: '#006400', strokeWidth: 2 } }))))));
};
export default SaleByDayChartIncomeReportComponent;
