import React, { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import ButtonActionSaleV2Component from '../../../../sale.v2/Action/helpers/Button';
import CurrencyCreatePurchaseOrderDialogComponent from './Dialog';
import getDefaultCreateCurrencyPurchaseOrderValues from './Dialog/Body/helpers/getDefaultValues';
import getCurrencyFormatLabel from '../../../../../helpers/getCurrencyLabel';
var CurrencyCreatePurchaseOrderComponent = forwardRef(function (props, ref) {
    var currencyCreatePurchaseOrderDialogRef = useRef(null);
    var defaultValues = getDefaultCreateCurrencyPurchaseOrderValues();
    var _a = useState(defaultValues), values = _a[0], setValues = _a[1];
    var handleClick = function () {
        var _a;
        (_a = currencyCreatePurchaseOrderDialogRef.current) === null || _a === void 0 ? void 0 : _a.open();
    };
    var handleGetValues = function (values) {
        setValues(values);
    };
    var getValues = function () { return values; };
    useImperativeHandle(ref, function () { return ({
        getValues: getValues,
    }); });
    return (React.createElement(React.Fragment, null,
        React.createElement(ButtonActionSaleV2Component, { title: 'Moneda', subtitle: getCurrencyFormatLabel(values.currencyKey), onClick: handleClick }),
        React.createElement(CurrencyCreatePurchaseOrderDialogComponent, { getValues: handleGetValues, onChange: props.onChange, defaultValues: values, ref: currencyCreatePurchaseOrderDialogRef })));
});
export default CurrencyCreatePurchaseOrderComponent;
