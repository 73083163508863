import React, { useState, useEffect } from 'react';

import { useReactiveVar } from '@apollo/client';

import Typography from '@mui/material/Typography';

import FilterDrawer from '../../presentationals/FilterDrawer.v2';
import Checkbox from '../../presentationals/Checkbox';

import { filter, searchField } from '../../apollo/cache';

import SelectField from '../../presentationals/FormComponents/SelectField';

const defaultValues = {
  status: -1,
};

const statusOptions = [
  { label: 'Todas', value: -1 },
  { label: 'Canceladas', value: 0 },
  { label: 'Abiertas', value: 1 },
  { label: 'En Proceso', value: 2 },
  { label: 'Cerradas', value: 3 },
];

const defaultOptions = {
  '-1': false,
  '-2': false,
};

const FilterDialog = () => {
  const searchFieldVar = useReactiveVar(searchField);
  const filterVar = useReactiveVar(filter);
  const [values, setValues] = useState(defaultValues);
  const [optionValues, setOptionValues] = useState(defaultOptions);

  const handleAction = (e, data) => {
    const { startDate: start, endDate: end, branchId } = data;
    const options = Object.keys(optionValues)
      .map(key => optionValues[key] && key !== '0' && parseInt(key))
      .filter(tmp => tmp);
    const status = values.status !== -1 ? [values.status] : null;
    filter({
      start,
      end,
      branchId,
      status,
      options,
      order: ['clientCreatedAt', 'asc'],
      category: [3, 7],
    });
  };

  useEffect(() => {
    if (searchFieldVar && searchFieldVar.trim() !== '')
      filter({ ...filterVar, like: searchFieldVar });
  }, [searchFieldVar]);

  const handleChange = (name, value) => {
    setValues(values => ({ ...values, [name]: value }));
  };

  const handleCheckBox = (name, checked) => {
    const numberOfTrue = Object.keys(optionValues).filter(key => !!optionValues[key]);
    let number = numberOfTrue.length;
    if (checked) number = number + 2;
    else number = number - 1;
    if (number === Object.keys(optionValues).length)
      setOptionValues(values => ({ ...values, [name]: checked, 0: true }));
    else setOptionValues(values => ({ ...values, [name]: checked, 0: false }));
  };

  return (
    <FilterDrawer handleAction={handleAction} showDates showBranches>
      <Typography variant='subtitle1'>Estatus del Registro:</Typography>
      <SelectField
        name='status'
        onChange={handleChange}
        options={statusOptions}
        size='medium'
        value={values.status}
      />
      <Typography variant='subtitle1'>Opciones:</Typography>
      <Checkbox
        handleChange={handleCheckBox}
        noFormat
        values={[
          { name: '-1', label: 'Descuentos', checked: !!optionValues['-1'] && optionValues['-1'] },
          { name: '-2', label: 'Cortesias', checked: !!optionValues['-2'] && optionValues['-2'] },
        ]}
      />
    </FilterDrawer>
  );
};

export default FilterDialog;
