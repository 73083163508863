import React, { useRef } from 'react';
import LabelBranchFieldComponent from './Label';
import PopoverBranchFieldComponent from './Popover';
var BranchFieldComponent = function (props) {
    var branchFieldRef = useRef(null);
    var handleClick = function (event) {
        var _a;
        (_a = branchFieldRef.current) === null || _a === void 0 ? void 0 : _a.open(event.currentTarget);
    };
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { role: 'button', tabIndex: -1, onClick: handleClick, className: 'flex flex-row items-center justify-center cursor-pointer' },
            React.createElement("div", { className: 'flex flex-col hover:bg-[#ececec] rounded px-2 py-1' },
                React.createElement("div", { className: 'text-xs text-gray-600 leading-none tracking-tighter' }, "Sucursal"),
                React.createElement(LabelBranchFieldComponent, null))),
        React.createElement(PopoverBranchFieldComponent, { allowAllBranches: props.allowAllBranches, ref: branchFieldRef })));
};
export default BranchFieldComponent;
