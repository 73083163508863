import { Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import useGetEmployees from '../../../../../hooks/useGetEmployees';
var EmployeeLabelBodyCartSaleComponent = function (props) {
    var _a = useState(''), employeeName = _a[0], setEmployeeName = _a[1];
    var _b = useGetEmployees({ isSeller: true }), data = _b.data, loading = _b.loading;
    useEffect(function () {
        if (data && data.rows) {
            var currentEmployee = data.rows.find(function (el) { return el.id === props.employeeId; });
            if (currentEmployee)
                setEmployeeName("".concat(currentEmployee.firstName, " ").concat(currentEmployee.lastName));
        }
    }, [data, props.employeeId]);
    if (loading)
        return React.createElement("div", null);
    return (React.createElement("div", null,
        React.createElement(Typography, { variant: 'caption' }, employeeName)));
};
export default EmployeeLabelBodyCartSaleComponent;
