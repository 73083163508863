import React, { useEffect, useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import LoadingProgress from '../../../presentationals/LoadingProgress';
import ItemBodyReceivableComponent from './Item';
import convertToCurrencyLabel from '../../../helpers/convertToCurrencyLabel';
var BodyReceivableComponent = function (props) {
    var _a = useState(0), total = _a[0], setTotal = _a[1];
    useEffect(function () {
        if (props.data) {
            var total_1 = props.data.reduce(function (total, el) { return total + el.balance; }, 0);
            setTotal(total_1);
        }
    }, [props.data]);
    if (props.loading || !props.data)
        return React.createElement(LoadingProgress, null);
    return (React.createElement(TableContainer, null,
        React.createElement(Table, { className: 'sticky', stickyHeader: true, size: 'small' },
            React.createElement(TableHead, null,
                React.createElement(TableRow, null,
                    React.createElement(TableCell, null, "Cliente"),
                    React.createElement(TableCell, { className: 'bg-slate-300 font-medium text-right' }, "Cargo"),
                    React.createElement(TableCell, { className: 'bg-slate-300 font-medium text-right' }, "Abono"),
                    React.createElement(TableCell, { className: 'bg-slate-300 font-medium text-right' }, "Saldo"))),
            React.createElement(TableBody, null,
                props.data.map(function (el, i) { return (React.createElement(ItemBodyReceivableComponent, { key: el.id, index: i, CustomerAccountReveivable: el })); }),
                React.createElement(TableRow, { className: 'sticky', style: { insetBlockEnd: 0 } },
                    React.createElement(TableCell, { className: 'text-right font-bold bg-white', colSpan: 3 }, "Saldo total"),
                    React.createElement(TableCell, { className: 'font-bold bg-white text-right' },
                        "$",
                        convertToCurrencyLabel(total)))))));
};
export default BodyReceivableComponent;
