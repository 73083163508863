var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import { useReactiveVar } from '@apollo/client';
import { createECFormSecondaryColor } from '../../../../../../../../../apollo/cache';
import TextFieldComponent from '../../../../../../../../../presentationals/FormComponents/TextField';
var BodyFormAddButtonColorEcommerceEditFormComponent = function () {
    var values = useReactiveVar(createECFormSecondaryColor);
    var handleChange = function (name, value) {
        var _a;
        createECFormSecondaryColor(__assign(__assign({}, values), (_a = {}, _a[name] = value, _a)));
    };
    return (React.createElement("div", { className: 'flex-1 grid grid-cols-3 p-4' },
        React.createElement("div", null,
            React.createElement(TextFieldComponent, { onChange: handleChange, value: values.name, name: 'name', label: 'Nombre' }),
            React.createElement(TextFieldComponent, { onChange: handleChange, value: values.attribute, name: 'attribute', label: 'C\u00F3digo', placeholder: '#ffffff' }))));
};
export default BodyFormAddButtonColorEcommerceEditFormComponent;
