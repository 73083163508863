import React from 'react';

import { useReactiveVar } from '@apollo/client';

import { actions } from '../../../../apollo/cache';
import IconButton from '../../../IconButton';

function ActionButtonComponent(props) {
  const actionsVar = useReactiveVar(actions);

  const __function = (e, { onClick, ...params }) => {
    if (typeof onClick === 'function') {
      onClick(e, params);
    } else if (typeof onClick === 'object') {
      const tmpKey = Object.keys(onClick).reduce((string, key) => {
        const _split = key.split(':');
        const tmpKey = _split[0];
        const tmpValue = _split[1];
        return tmpValue === `${params[tmpKey]}` ? key : string;
      }, '');
      onClick[tmpKey](e, params);
    }
  };
  return (
    actionsVar &&
    actionsVar.map(el => {
      const tmp = el.disable
        ? el.disable.key.includes(' ')
          ? el.disable.key.split(' ').reduce((string, elCh) => {
              const tmpData = string[elCh] || el.ifNull || '-';
              return tmpData;
            }, props.data)
          : props.data[el.disable.key]
        : null;
      const tmp1 = el.disable1
        ? el.disable1.key.includes(' ')
          ? el.disable1.key.split(' ').reduce((string, elCh) => {
              const tmpData = string[elCh] || el.ifNull || '-';
              return tmpData;
            }, props.data)
          : props.data[el.disable1.key]
        : null;
      const tmp2 = el.disable2
        ? el.disable2.key.includes(' ')
          ? el.disable2.key.split(' ').reduce((string, elCh) => {
              const tmpData = string[elCh] || el.ifNull || '-';
              return tmpData;
            }, props.data)
          : props.data[el.disable2.key]
        : null;
      const tmpHide = el.hide
        ? el.hide.key.includes(' ')
          ? el.hide.key.split(' ').reduce((string, elCh) => {
              const tmpData = string[elCh] || el.ifNull || '-';
              return tmpData;
            }, props.data)
          : props.data[el.hide.key]
        : null;
      const tmpHide1 = el.hide1
        ? el.hide1.key.includes(' ')
          ? el.hide1.key.split(' ').reduce((string, elCh) => {
              const tmpData = string[elCh] || el.ifNull || '-';
              return tmpData;
            }, props.data)
          : props.data[el.hide1.key]
        : null;

      const disabled = el.disable
        ? Array.isArray(el.disable.value)
          ? el.disable.value.includes(tmp)
          : tmp === el.disable.value
        : false;

      const disabled1 = el.disable1
        ? Array.isArray(el.disable1.value)
          ? el.disable1.value.includes(tmp1)
          : tmp1 === el.disable1.value
        : false;
      const disabled2 = el.disable2
        ? Array.isArray(el.disable2.notValue)
          ? !el.disable2.notValue.includes(tmp2)
          : tmp2 !== el.disable2.notValue
        : false;

      return (
        <IconButton
          key={el.label}
          label={el.label}
          disabled={
            (el.disable && disabled) || (el.disable1 && disabled1) || (el.disable2 && disabled2)
          }
          hide={
            (el.hide && tmpHide === el.hide.value) || (el.hide1 && tmpHide1 !== el.hide1.notValue)
          }
          action={__function}
          params={{ id: props.data.id, ...el.params, ...props.data, onClick: el.onClick }}
          className='p-1'
          icon={el.icon}
          size='large'
        />
      );
    })
  );
}

export default ActionButtonComponent;
