import React, { useEffect, useState } from 'react';
import { Typography } from '@mui/material';
import IsTextFieldEcommerceEditFormComponent from './IsTextField';
import OrientationFieldEcommerceEditFormComponent from './OrientationField';
import TypographyFieldEcommerceEditFormComponent from './TypographyField';
import FontSizeFieldEcommerceEditFormComponent from './FontSizeField';
import EmojiFieldEcommerceEditFormComponent from './EmojiField';
import EmojiPositionFieldEcommerceEditFormComponent from './EmojiPositionField';
import PreviewFieldEcommerceEditFormComponent from './PreviewField';
import ColorFieldEcommerceEditFormComponent from './ColorField';
import ProportionFieldEcommerceEditFormComponent from './ProportionField';
var TextSettingsEcommenceProductComponent = function (props) {
    var _a = useState(undefined), isText = _a[0], setIsText = _a[1];
    var _b = useState(undefined), orientation = _b[0], setOrientation = _b[1];
    var _c = useState(undefined), typography = _c[0], setTypography = _c[1];
    var _d = useState(undefined), fontSize = _d[0], setFontSize = _d[1];
    var _e = useState(undefined), emoji = _e[0], setEmoji = _e[1];
    var _f = useState(undefined), emojiPosition = _f[0], setEmojiPosition = _f[1];
    var _g = useState(undefined), preview = _g[0], setPreview = _g[1];
    var _h = useState(undefined), TextColors = _h[0], setTextColors = _h[1];
    var _j = useState(undefined), Proportions = _j[0], setProportions = _j[1];
    useEffect(function () {
        setIsText(props.TextAttributes.find(function (el) { return el.type === 3; }));
        setOrientation(props.TextAttributes.find(function (el) { return el.type === 5; }));
        setTypography(props.TextAttributes.find(function (el) { return el.type === 4; }));
        setFontSize(props.TextAttributes.find(function (el) { return el.type === 6; }));
        setEmoji(props.TextAttributes.find(function (el) { return el.type === 9; }));
        setEmojiPosition(props.TextAttributes.find(function (el) { return el.type === 10; }));
        setPreview(props.TextAttributes.find(function (el) { return el.type === 11; }));
        setProportions(props.TextAttributes.filter(function (el) { return [12, 13, 14, 15, 16, 17].includes(el.type); }));
        setTextColors(props.TextAttributes.filter(function (el) { return el.type === 18; }));
    }, [props.TextAttributes]);
    return (React.createElement("div", null,
        React.createElement(Typography, { variant: 'h6' }, "Configuraci\u00F3n de texto"),
        React.createElement(IsTextFieldEcommerceEditFormComponent, { productAttributeId: isText === null || isText === void 0 ? void 0 : isText.id }),
        !!isText && (React.createElement(React.Fragment, null,
            React.createElement(OrientationFieldEcommerceEditFormComponent, { productAttributeId: orientation === null || orientation === void 0 ? void 0 : orientation.id }),
            React.createElement(TypographyFieldEcommerceEditFormComponent, { productAttributeId: typography === null || typography === void 0 ? void 0 : typography.id }),
            React.createElement(FontSizeFieldEcommerceEditFormComponent, { productAttributeId: fontSize === null || fontSize === void 0 ? void 0 : fontSize.id }),
            React.createElement(EmojiFieldEcommerceEditFormComponent, { productAttributeId: emoji === null || emoji === void 0 ? void 0 : emoji.id }),
            emoji && (React.createElement(EmojiPositionFieldEcommerceEditFormComponent, { productAttributeId: emojiPosition === null || emojiPosition === void 0 ? void 0 : emojiPosition.id })),
            React.createElement(PreviewFieldEcommerceEditFormComponent, { productAttributeId: preview === null || preview === void 0 ? void 0 : preview.id }),
            React.createElement(ColorFieldEcommerceEditFormComponent, { TextAttributes: TextColors, Colors: props.Colors }),
            React.createElement(ProportionFieldEcommerceEditFormComponent, { TextAttributes: Proportions })))));
};
export default TextSettingsEcommenceProductComponent;
