import useApolloQuery from './useApolloQuery';
import GQL_SURVEY from '../apollo/gql/survey';
var useSurveyCustomer = function (id) {
    var _a = useApolloQuery(GQL_SURVEY.GET_SURVEY_CUSTOMER_BY_ID, {
        variables: {
            id: id,
        },
    }), data = _a.data, loading = _a.loading;
    return { data: data, loading: loading };
};
export default useSurveyCustomer;
