import React from 'react';

import Container from '../../presentationals/Module.v2/Container';

import Filter from './_filter';

import Table from './Table';

const Component = () => {
  return (
    <>
      <Container title='Estado de resultados' filter noTable>
        <Table />
      </Container>
      <Filter />
    </>
  );
};

export default Component;
