var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { Button } from '@mui/material';
import React, { useEffect, useState } from 'react';
import TextFieldComponent from '../../../presentationals/FormComponents/TextField';
import useAdjustInventoryStore from '../../../store/inventoryReport/adjustInventoryStore';
import useApolloLazyQuery from '../../../hooks/useApolloLazyQuery';
import GQL_PRODUCT from '../../../apollo/gql/product';
import getInputErrorFormat from '../../sale.v2/Action/helpers/getInputErrorFormat';
import CONSTANTS_ERROR from '../../../constants/error';
import useAddProductToAdjustInventoryStore from '../../../store/inventoryReport/addProductToAdjustInventoryStore';
import DialogComponent from '../../../presentationals/Dialog';
var AddProductToAdjustInventoryFromInventoryReportComponent = function () {
    var _a = useAddProductToAdjustInventoryStore(function (state) { return state; }), open = _a.open, presentationId = _a.presentationId, currentQty = _a.currentQty, setClose = _a.setClose;
    var _b = useState(''), value = _b[0], setValue = _b[1];
    useEffect(function () {
        setValue(currentQty.toString());
    }, [currentQty]);
    var addProduct = useAdjustInventoryStore(function (state) { return state.addProduct; });
    var _c = useApolloLazyQuery(GQL_PRODUCT.GET_PRODUCT_BY_PRODUCT_PRESENTATION_ID), getProductByPresentationId = _c[0], _d = _c[1], loading = _d.loading, data = _d.data;
    useEffect(function () {
        if (presentationId)
            getProductByPresentationId({ variables: { productPresentationId: presentationId } });
    }, [presentationId]);
    var handleClose = function () {
        setClose();
    };
    var handleClick = function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            try {
                if (!presentationId)
                    return [2 /*return*/];
                if (!data)
                    throw new Error(CONSTANTS_ERROR.UNDEFINED_PRODUCT_ID);
                if (!data.ProductPresentation)
                    throw new Error(CONSTANTS_ERROR.UNDEFINED_PRODUCT_ID);
                addProduct({
                    productPresentationId: presentationId,
                    currentInvQty: parseFloat(value),
                    barcode: data.ProductPresentation.barcode,
                    diffQty: 0,
                    productId: data.id,
                    productName: data.name,
                    productPresentationName: data.ProductPresentation.name,
                    sku: data.sku || '',
                    totalCost: 0,
                });
                handleClose();
            }
            catch (e) {
                getInputErrorFormat(e);
            }
            return [2 /*return*/];
        });
    }); };
    var handleChange = function (name, value) {
        setValue(value);
    };
    var handleKeyPress = function (e) {
        if (e.key === 'Enter') {
            e.preventDefault();
            handleClick();
        }
    };
    return (React.createElement(DialogComponent, { hideHeaderCloseButton: true, maxWidth: 'xs', open: open, onClose: handleClose },
        React.createElement("div", { className: 'flex flex-1 flex-col pt-4 pb-3 px-4' },
            React.createElement("div", { className: 'leading-none font-medium text-xl mb-2' }, data === null || data === void 0 ? void 0 : data.name),
            React.createElement(TextFieldComponent, { label: 'Ajuste de inventario', name: 'qty', value: value, onChange: handleChange, onKeyDown: handleKeyPress, autoFocus: true }),
            React.createElement("div", null,
                React.createElement(Button, { variant: 'contained', color: 'success', className: 'normal-case mt-2', onClick: handleClick, disabled: loading }, "A\u00F1adir a la lista")))));
};
export default AddProductToAdjustInventoryFromInventoryReportComponent;
