import React, { useState } from 'react';
import { useQuery } from '@apollo/client';

import Typography from '@mui/material/Typography';

import FilterDrawer from '../../presentationals/FilterDrawer.v2';
import { filter } from '../../apollo/cache';

import Autocomplete from '../../presentationals/FormComponents/Autocomplete';

import PRODUCT_GQL from '../product/_gql';
import GQL_ADJUST_INVENTORY from '../../apollo/gql/adjustInventory';

const defaultValues = {
  adjustInventoryId: null,
  productId: null,
};

const FilterDialog = () => {
  const [adjustInventories, setAdjustInventories] = useState([]);
  const [products, setProducts] = useState([]);
  const [values, setValues] = useState(defaultValues);

  const handleAction = (e, data) => {
    const { startDate: start, endDate: end, branchId } = data;
    const productId = values.productId ? values.productId.value : null;
    const adjustInventoryId = values.adjustInventoryId ? values.adjustInventoryId.value : null;
    filter({ start, end, branchId, productId, adjustInventoryId });
  };

  useQuery(GQL_ADJUST_INVENTORY.GET_ADJUST_INVENTORIES, {
    onCompleted: data => {
      const tmp = data.adjustInventories.rows.map(el => ({
        label: `${el.folio}-${el.serial}`.trim(),
        value: el.id,
      }));
      setAdjustInventories(tmp);
    },
  });

  useQuery(PRODUCT_GQL.GET_NAME_AND_PRESENTATIONS, {
    variables: { limit: 0 },
    onCompleted: data => {
      const tmp = data.productsV2.rows.map(el => ({
        label: `${String(el.code).padStart(3, '0')} ${el.name.trim()} ${
          el.sku ? ` | SKU ${el.sku.trim()}` : ''
        } ${el.barcode ? ` | C.B. ${el.barcode.trim()}` : ''}`.trim(),
        value: el.id,
      }));
      setProducts(tmp);
    },
  });

  const handleAutocompleteChange = (name, value) => {
    setValues(values => ({ ...values, [name]: value }));
  };

  return (
    <FilterDrawer handleAction={handleAction} showDates>
      <Typography variant='subtitle1'>Ajuste:</Typography>
      <Autocomplete
        name='adjustInventoryId'
        onChange={handleAutocompleteChange}
        options={adjustInventories}
        value={values.adjustInventoryId}
        size='medium'
      />
      <Typography variant='subtitle1'>Producto:</Typography>
      <Autocomplete
        name='productId'
        onChange={handleAutocompleteChange}
        options={products}
        value={values.productId}
        size='medium'
      />
    </FilterDrawer>
  );
};

export default FilterDialog;
