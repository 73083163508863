import React, { useState, useEffect } from 'react';
import { useQuery } from '@apollo/client';

import Typography from '@mui/material/Typography';

import FilterDrawer from '../../presentationals/FilterDrawer.v2';
import Autocomplete from '../../presentationals/Autocomplete';
import GQL_CUSTOMER from '../customer/_gql';

import { filter } from '../../apollo/cache';

const defaultValues = {
  customer: null,
};

const FilterDialog = () => {
  const [values, setValues] = useState(defaultValues);
  const [customers, setCustomers] = useState([]);

  const { data } = useQuery(GQL_CUSTOMER.GET_NAME_AND_PHONE, { variables: { limit: 0 } });

  useEffect(() => {
    if (data) {
      const tmp = data.customers.rows.map(el => {
        return {
          value: el.id,
          label: `${el.firstName} ${el.lastName || ''} ${el.phone || ''}`,
        };
      });
      setCustomers(tmp);
    }
  }, [data]);

  const handleAction = (e, data) => {
    const { startDate: start, endDate: end } = data;
    filter({
      start,
      end,
      customerId: values.customer ? values.customer.value : null,
    });
  };

  const handleAutocompleteChange = (e, name, value) => {
    setValues(values => ({ ...values, [name]: value }));
  };

  return (
    <FilterDrawer handleAction={handleAction} showDates>
      <Typography variant='subtitle1'>Cliente</Typography>
      <Autocomplete
        name='customer'
        variant='outlined'
        onChange={handleAutocompleteChange}
        options={customers}
        value={values.customer}
        autoFocus
        noFormat
      />
    </FilterDrawer>
  );
};

export default FilterDialog;
