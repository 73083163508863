var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import getTransactionTableColumnConfiguration from '../../../../helpers/getTransactionTableColumnConfiguration';
var getTransactionReportHeaderColumns = function () {
    var defaultHeader = [
        { Header: 'Creado el', accessor: 'createdAt' },
        { Header: 'Fecha de pago', accessor: 'clientCreatedAt' },
        { Header: 'Estatus', accessor: 'status' },
        {
            Header: 'Datos de la venta',
            headerClassName: 'bg-slate-200 text-center border-0 border-b border-solid border-slate-50',
            columns: [
                {
                    Header: 'Folio',
                    accessor: 'saleFolio',
                    headerClassName: 'bg-slate-200 text-center',
                    cellClassName: 'text-center border-0 border-b border-solid border-slate-200',
                    cellBackground: 'bg-slate-100',
                },
                {
                    Header: 'Importe',
                    accessor: 'saleTotal',
                    headerClassName: 'bg-slate-200 text-right',
                    cellClassName: 'text-right border-0 border-b border-solid border-slate-200',
                    cellBackground: 'bg-slate-100',
                },
            ],
        },
        { Header: 'Complemento', accessor: 'invoiceComplementUuid' },
        { Header: 'Cliente', accessor: 'customer' },
        { Header: 'Cuenta', accessor: 'transactionAccount' },
        { Header: 'Moneda', accessor: 'currency' },
        { Header: 'Forma de pago', accessor: 'paymentForm' },
        { Header: 'Importe', accessor: 'total' },
    ];
    var settings = getTransactionTableColumnConfiguration();
    var allowedColumns = Object.keys(settings).filter(function (key) { return settings[key]; });
    var columns = defaultHeader.filter(function (el) {
        if (el.accessor)
            return allowedColumns.includes(el.accessor);
        var subHeader = el.columns
            ? el.columns.filter(function (elCh) { return allowedColumns.includes(elCh.accessor); })
            : [];
        return subHeader.length > 0;
    });
    return columns.map(function (el) {
        return el.accessor
            ? el
            : __assign(__assign({}, el), { columns: el.columns
                    ? el.columns.filter(function (elCh) { return allowedColumns.includes(elCh.accessor); })
                    : [] });
    });
};
export default getTransactionReportHeaderColumns;
