import React from 'react';
import { useParams } from 'react-router-dom';
import BodyCreditNoteComponent from './Body';
import ErrorDataComponent from '../../../../presentationals/ErrorData';
var CreditNoteInvoiceReportComponent = function () {
    var params = useParams();
    if (!params.invoiceId)
        return React.createElement(ErrorDataComponent, null);
    return (React.createElement("div", { className: 'flex flex-1 p-3 basis-0 overflow-auto' },
        React.createElement(BodyCreditNoteComponent, { invoiceId: params.invoiceId })));
};
export default CreditNoteInvoiceReportComponent;
