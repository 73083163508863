var getDefaultProductValues = function (values) { return ({
    id: values && values.id ? values.id : undefined,
    name: values && values.name ? values.name : '',
    type: values && values.type ? "".concat(values.type) : '1',
    prefix: values && values.prefix ? values.prefix : '',
    classificationId: values && values.classificationId ? values.classificationId : '',
    sku: values && values.sku ? values.sku : '',
    barcode: values && values.barcode ? values.barcode : '',
    price: values && values.price && values.price >= 0 ? "".concat(values.price) : '',
    cost: values && values.cost && values.cost >= 0 ? "".concat(values.cost) : '',
    providerId: values && values.providerId ? values.providerId : '',
    percentAdjust: values && values.percentAdjust ? "".concat(values.percentAdjust) : '',
    iva: values && (values.iva !== null || values.iva !== '') ? "".concat(values.iva) : '16',
    ieps: values && values.ieps ? "".concat(values.ieps) : '',
    productKey: values && values.productKey ? values.productKey : '',
    unitKey: values && values.unitKey ? values.unitKey : '',
    description: values && values.description ? values.description : '',
    unitBase: values && values.unitBase ? values.unitBase : 'PIEZA',
    isSerialized: values && values.isSerialized ? values.isSerialized : false,
    serialNumber: (values === null || values === void 0 ? void 0 : values.serialNumber) ? values.serialNumber : '',
}); };
export default getDefaultProductValues;
