var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useReactiveVar } from '@apollo/client';
import { productSaleV2, inputError, isSaleProductFormOpen, searchProductField, isCreateFormOpen, } from '../../../../../../../apollo/cache';
import setInputErrorFormat from '../../../../../Action/helpers/setInputErrorFormat';
import getInputErrorFormat from '../../../../../Action/helpers/getInputErrorFormat';
import setCreateBody from './helpers/setCreateBody';
import Button from '../../../../../../../presentationals/Dialog/Footer/Button';
import useUpdateProductMutation from '../../../../../../../hooks/useUpdateProductMutation';
import useCreateProductMutation from '../../../../../../../hooks/useCreateProductMutation';
var CreateButtonFooterCreateFormProductActionSaleV2Component = forwardRef(function (props, ref) {
    var navigate = useNavigate();
    var values = useReactiveVar(productSaleV2);
    var _a = useState(false), isLoading = _a[0], setIsLoading = _a[1];
    var create = useCreateProductMutation().create;
    var edit = useUpdateProductMutation().edit;
    var handleClick = function () { return __awaiter(void 0, void 0, void 0, function () {
        var variables, response, e_1;
        var _a, _b;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0:
                    _c.trys.push([0, 5, 6, 7]);
                    setIsLoading(true);
                    inputError({});
                    if (values.name === '')
                        throw new Error(setInputErrorFormat('name', 'Proporciona el nombre del producto.'));
                    if (values.price && isNaN(parseFloat(values.price)))
                        throw new Error(setInputErrorFormat('price', 'Proporciona un precio de venta.'));
                    if (values.type === '')
                        throw new Error(setInputErrorFormat('type', 'Selecciona el tipo de producto.'));
                    if (values.classificationId === '')
                        throw new Error(setInputErrorFormat('classificationId', 'Selecciona una clasificación.'));
                    variables = setCreateBody(values);
                    if (!values.id) return [3 /*break*/, 2];
                    return [4 /*yield*/, edit({
                            variables: __assign(__assign({}, variables), { id: values.id }),
                        })];
                case 1:
                    _c.sent();
                    return [3 /*break*/, 4];
                case 2: return [4 /*yield*/, create({ variables: variables })];
                case 3:
                    response = _c.sent();
                    if (!response.data)
                        return [2 /*return*/];
                    productSaleV2(__assign(__assign(__assign({}, values), response.data.createProductV2), { price: variables.price ? variables.price.toString() : '0', cost: variables.cost ? variables.cost.toString() : '0', providerId: ((_b = (_a = response.data) === null || _a === void 0 ? void 0 : _a.createProductV2) === null || _b === void 0 ? void 0 : _b.providerId)
                            ? response.data.createProductV2.providerId
                            : '' }));
                    searchProductField(values.name);
                    _c.label = 4;
                case 4:
                    isCreateFormOpen(false);
                    isSaleProductFormOpen(false);
                    if (!props.noReturn)
                        navigate('..');
                    return [3 /*break*/, 7];
                case 5:
                    e_1 = _c.sent();
                    getInputErrorFormat(e_1);
                    return [3 /*break*/, 7];
                case 6:
                    setIsLoading(false);
                    return [7 /*endfinally*/];
                case 7: return [2 /*return*/];
            }
        });
    }); };
    useImperativeHandle(ref, function () { return ({
        onPressEnter: handleClick,
    }); });
    return (React.createElement(Button, { disabled: isLoading, color: 'success', className: 'ml-2', onClick: handleClick }, values && values.id ? 'Editar' : 'Almacenar'));
});
export default CreateButtonFooterCreateFormProductActionSaleV2Component;
