var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useMemo, useRef } from 'react';
import { useMutation } from '@apollo/client';
import RHFormComponent from '../../presentationals/RHForm/Form/indext';
import RHFButtonComponent from '../../presentationals/RHForm/Button';
import OnlineOrderTypeButtonComponent from './TypeButton';
import OnlineOrderCustomerButtonComponent from './CustomerButton';
import OnlineOrderAddressButtonComponent from './AddressButton';
import OnlineOrderDiscountButtonComponent from './DiscountButton';
import RHFProductFieldComponent from '../../presentationals/RHForm/ProductField';
import RHFTextFieldComponent from '../../presentationals/RHForm/TextField';
import RHFQtyFieldComponent from '../../presentationals/RHForm/QtyField';
import OnlineOrderCartComponent from './Cart';
import OnlineOrderTotalComponent from './Total';
import AddToOnlineOrderCartButtonComponent from './AddToCartButton';
import CustomerAddessPopoverComponent from '../../presentationals/RHForm/CustomerAddressPopover';
import getInputErrorFormat from '../sale.v2/Action/helpers/getInputErrorFormat';
import GQL_SALE from '../../apollo/gql/sale';
import useSnackbarDialog from '../../store/snackbar/snackbarDialog';
import OnlineOrderBranchButtonComponent from './BranchButton';
import getSession from '../../helpers/getSession';
var OnlineOrderComponent = function () {
    var rhFormRef = useRef(null);
    var snackbar = useSnackbarDialog(function (state) { return state.setSnackbar; });
    var customerAddessPopoverRef = useRef(null);
    var onlineOrderCartRef = useRef(null);
    var onlineOrderAddressButtonRef = useRef(null);
    var _a = useMutation(GQL_SALE.CREATE_ONLINE_ORDER), createOnlineOrder = _a[0], loading = _a[1].loading;
    var defaultValues = useMemo(function () {
        var _a;
        var session = getSession();
        return {
            type: 2,
            qty: 1,
            productId: null,
            customer: null,
            branch: ((_a = session === null || session === void 0 ? void 0 : session.Company) === null || _a === void 0 ? void 0 : _a.Branch)
                ? {
                    id: session.Company.Branch.id,
                    name: session.Company.Branch.name,
                }
                : null,
            addressId: null,
            discountInput: 0,
            discount: 0,
            commentary: '',
            Products: [],
            shipping: 0,
        };
    }, []);
    var handleAppend = function (data) {
        var _a;
        (_a = onlineOrderCartRef.current) === null || _a === void 0 ? void 0 : _a.append(data);
    };
    var handleSubmit = function (data) { return __awaiter(void 0, void 0, void 0, function () {
        var error_1;
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    _b.trys.push([0, 2, , 3]);
                    if (!data.customer)
                        throw new Error('Selecciona un cliente para el pedido.');
                    if (data.type === 3 && !data.addressId)
                        throw new Error('Selecciona el domicilio al que se enviará el pedido.');
                    if (data.type === 3 && data.shipping <= 0)
                        throw new Error('El costo de envío debe ser mayor a cero.');
                    if (data.Products.length === 0)
                        throw new Error('Añade al menos un producto al carrito.');
                    return [4 /*yield*/, createOnlineOrder({
                            variables: {
                                type: data.type,
                                Products: data.Products.map(function (el) { return ({
                                    productPresentationId: el.product.productPresentationId,
                                    quantity: el.qty,
                                    commentary: el.commentary,
                                }); }),
                                description: '',
                                discount: data.discount,
                                customerId: data.customer.id,
                                customerAddressId: data.addressId,
                                discountType: 1,
                                branchId: data.branch ? data.branch.id : undefined,
                            },
                        })];
                case 1:
                    _b.sent();
                    (_a = rhFormRef.current) === null || _a === void 0 ? void 0 : _a.reset();
                    snackbar({ isOpen: true, label: 'El pedido fue enviado exitosamente' });
                    return [3 /*break*/, 3];
                case 2:
                    error_1 = _b.sent();
                    getInputErrorFormat(error_1);
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    var handleCustomerAddressClick = function (event, customerId) {
        var _a;
        (_a = customerAddessPopoverRef.current) === null || _a === void 0 ? void 0 : _a.open(event.currentTarget, customerId);
    };
    var handleCustomerAddressSelected = function (data) {
        var _a;
        (_a = onlineOrderAddressButtonRef.current) === null || _a === void 0 ? void 0 : _a.onSelect(data);
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(RHFormComponent, { id: 'OnlineOrderForm', disableEnter: true, onSubmit: handleSubmit, defaultValues: defaultValues },
            React.createElement("div", { className: 'block sm:flex flex-1 flex-col px-4 py-3 overflow-auto' },
                React.createElement("div", { className: 'flex flex-row items-center mb-2' },
                    React.createElement("div", { className: 'flex flex-1 text-4xl tracking-tighter' }, "Pedidos en l\u00EDnea"),
                    React.createElement("div", null,
                        React.createElement(RHFButtonComponent, { disabled: loading, className: 'hidden sm:block', form: 'OnlineOrderForm', color: 'secondary' }, "Enviar pedido"))),
                React.createElement("div", { className: 'flex flex-1 flex-col sm:flex-row gap-4' },
                    React.createElement("div", { className: 'sm:basis-0 overflow-auto sm:overflow-visible flex flex-row sm:flex-col bg-white rounded-lg border border-solid border-slate-200' },
                        React.createElement(OnlineOrderTypeButtonComponent, null),
                        React.createElement(OnlineOrderCustomerButtonComponent, null),
                        React.createElement(OnlineOrderAddressButtonComponent, { ref: onlineOrderAddressButtonRef, onClick: handleCustomerAddressClick }),
                        React.createElement(OnlineOrderBranchButtonComponent, null),
                        React.createElement(OnlineOrderDiscountButtonComponent, null)),
                    React.createElement("div", { className: 'flex flex-1 gap-4 justify-between flex-col sm:flex-row' },
                        React.createElement("div", { className: 'flex flex-col' },
                            React.createElement("div", { className: 'text-4xl tracking-tighter mb-2' }, "B\u00FAsqueda del producto"),
                            React.createElement(RHFQtyFieldComponent, { name: 'qty' }),
                            React.createElement("div", { className: 'my-1' }),
                            React.createElement(RHFProductFieldComponent, { name: 'productId' }),
                            React.createElement("div", { className: 'my-1' }),
                            React.createElement(RHFTextFieldComponent, { name: 'commentary', placeholder: 'Comentarios para el producto', multiline: true, rows: 3 }),
                            React.createElement("div", { className: 'mt-2' },
                                React.createElement(AddToOnlineOrderCartButtonComponent, { onClick: handleAppend }))),
                        React.createElement("div", { className: 'flex flex-col' },
                            React.createElement("div", { className: 'flex flex-1 flex-col bg-white rounded-lg border border-solid border-slate-200 w-full sm:w-[424px] overflow-hidden' },
                                React.createElement("div", { className: 'text-4xl tracking-tighter px-4 py-3' }, "Resumen"),
                                React.createElement("div", { className: 'flex flex-1 leading-none mt-2 pb-3' },
                                    React.createElement(OnlineOrderCartComponent, { ref: onlineOrderCartRef })),
                                React.createElement(OnlineOrderTotalComponent, null)),
                            React.createElement(RHFButtonComponent, { disabled: loading, className: 'mt-2 sm:hidden', form: 'OnlineOrderForm', color: 'secondary' }, "Enviar pedido")))))),
        React.createElement(CustomerAddessPopoverComponent, { onSelect: handleCustomerAddressSelected, ref: customerAddessPopoverRef, name: 'addressId' })));
};
export default OnlineOrderComponent;
