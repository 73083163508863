var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useEffect, useState } from 'react';
import { useReactiveVar } from '@apollo/client';
import TextFieldComponent from '../../../../../../presentationals/FormComponents/TextField';
import { inputError } from '../../../../../../apollo/cache';
import FooterDialogComponent from '../../../../../../presentationals/Dialog/Footer';
import ButtonFooterDialogComponent from '../../../../../../presentationals/Dialog/Footer/Button';
import getDefaultExpenseServiceOrderValues from './helpers/getDefaultValues';
import DateFieldComponent from '../../../../../../presentationals/FormComponents/DateField';
var BodyExpenseServiceOrderComponent = function (props) {
    var errors = useReactiveVar(inputError);
    var defaultValues = getDefaultExpenseServiceOrderValues();
    var _a = useState(defaultValues), values = _a[0], setValues = _a[1];
    useEffect(function () {
        if (props.defaultValues)
            setValues(props.defaultValues);
    }, [props.defaultValues]);
    var handleChange = function (name, value) {
        setValues(function (values) {
            var _a;
            return (__assign(__assign({}, values), (_a = {}, _a[name] = value, _a)));
        });
    };
    var handleDateChange = function (name, value) {
        setValues(function (values) {
            var _a;
            return (__assign(__assign({}, values), (_a = {}, _a[name] = value, _a)));
        });
    };
    var handleButtonClick = function () {
        props.Button && props.Button.onClick(values);
    };
    return (React.createElement("div", { className: 'flex flex-1 overflow-hidden flex-col' },
        React.createElement("div", { className: 'flex flex-1 overflow-auto' },
            React.createElement("div", { className: 'w-full sm:w-1/3 p-3' },
                React.createElement(TextFieldComponent, { label: 'Nombre del gasto', name: 'name', disabled: props.disabled, value: values.name, onChange: handleChange, error: errors.name }),
                React.createElement(TextFieldComponent, { label: 'Cantidad', name: 'qty', disabled: props.disabled, value: values.qty, onChange: handleChange, error: errors.qty, type: 'number' }),
                React.createElement(TextFieldComponent, { label: 'Precio unitario', name: 'unitPrice', disabled: props.disabled, value: values.unitPrice, onChange: handleChange, error: errors.unitPrice, type: 'number' }),
                React.createElement(DateFieldComponent, { name: 'outDate', label: 'Fecha de salida', disabled: props.disabled, value: values.outDate || null, onChange: handleDateChange }))),
        React.createElement(FooterDialogComponent, null,
            props.DeleteButton ? (React.createElement(ButtonFooterDialogComponent, { color: props.DeleteButton.color, onClick: props.DeleteButton.onClick, loading: props.loading }, props.DeleteButton.label)) : (React.createElement("div", null)),
            props.Button ? (React.createElement(ButtonFooterDialogComponent, { onClick: handleButtonClick, loading: props.loading }, props.Button.label)) : (React.createElement("div", null)))));
};
export default BodyExpenseServiceOrderComponent;
