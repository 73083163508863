var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import XLSX from 'xlsx';
import { format } from 'date-fns';
import client from '../../../../apollo';
import GQL_ACCOUNT_RECEIVABLE from '../../../../apollo/gql/accountReceivable';
import { filter } from '../../../../apollo/cache';
import { dateTimeFormat } from '../../../../helpers';
var receivableXlsDoc = function () { return __awaiter(void 0, void 0, void 0, function () {
    var filterVar, filename, wb, response, values, ws;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                filterVar = filter();
                filename = "CXC_".concat(format(new Date(), 'dd-MMM-yyyy_HH-mm'), ".xlsx");
                wb = XLSX.utils.book_new();
                return [4 /*yield*/, client.query({
                        query: GQL_ACCOUNT_RECEIVABLE.GET_CUSTOMER_ACCOUNT_RECEIVABLES,
                        variables: {
                            withAccountReceivables: true,
                            branchId: filterVar.branchId,
                        },
                    })];
            case 1:
                response = (_a.sent()).data;
                values = response.customersAccountReceivables.reduce(function (newArray, el) {
                    var AccountReceivables = el.AccountReceivables.map(function (el) { return ({
                        createdAt: dateTimeFormat(el.clientCreatedAt, 'dd/MM/yyyy'),
                        limitDate: dateTimeFormat(el.limitDate, 'dd/MM/yyyy'),
                        folio: el.folio,
                        seller: el.seller,
                        total: el.total,
                        payment: el.payment,
                        balance: el.balance,
                    }); });
                    return __spreadArray(__spreadArray(__spreadArray(__spreadArray([], newArray, true), [
                        {
                            createdAt: el.name,
                            limitDate: '',
                            folio: '',
                            seller: '',
                            total: el.total,
                            payment: el.payment,
                            balance: el.balance,
                        }
                    ], false), AccountReceivables, true), [
                        {
                            createdAt: '',
                            limitDate: '',
                            folio: '',
                            seller: '',
                            total: '',
                            payment: '',
                            balance: '',
                        },
                    ], false);
                }, []);
                ws = XLSX.utils.json_to_sheet(values);
                XLSX.utils.sheet_add_aoa(ws, [['Creado el', 'Límite de pago', 'Folio de venta', 'Vendedor', 'Cargo', 'Abono', 'Saldo']], { origin: 'A1' });
                XLSX.utils.book_append_sheet(wb, ws, 'Cuentas por cobrar');
                XLSX.writeFile(wb, filename);
                return [2 /*return*/];
        }
    });
}); };
export default receivableXlsDoc;
