import React from 'react';
import useGetSaleByParamSaleId from '../../../../hooks/useGetSaleByParamSaleId';
import LoadingProgress from '../../../../presentationals/LoadingProgress';
import BodyProductsRentComponent from '../../../rent/Item/Products/Body';
import AddRentProductComponent from '../../../rent/Item/Products/AddProduct';
import EditRentProductComponent from '../../../rent/Item/Products/EditProduct';
import OpenAddProductButtonComponent from '../../../rent/Item/Products/OpenAddProductButton';
var ProductsServiceOrderComponent = function () {
    var _a = useGetSaleByParamSaleId(), data = _a.data, loading = _a.loading;
    if (loading || !data)
        return React.createElement(LoadingProgress, null);
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: 'flex flex-1 flex-col p-3' },
            React.createElement("div", { className: 'flex items-center' },
                React.createElement("div", { className: 'text-base font-medium mb-1 flex flex-1' }, "Productos de la orden de servicio"),
                [1, 3].includes(data.status) && React.createElement(OpenAddProductButtonComponent, null)),
            React.createElement("div", { className: 'text-sm sm:text-base text-gray-600' }, "Revisar y analizar los detalles de los productos incluidos en la orden de servicio emitida. Podr\u00E1s a\u00F1adir o actualizar los registros mientras el documento no se encuentre en estatus finalizado."),
            React.createElement("div", { className: 'rounded mt-3 flex flex-1 overflow-y-auto basis-0' },
                React.createElement(BodyProductsRentComponent, { deliveryStatus: [1, 3].includes(data.status) ? 0 : 1, module: 'orden de servicio', SaleProducts: data.SaleProducts, subtotal: data.subtotal, taxes: data.taxes, total: data.total }))),
        React.createElement(AddRentProductComponent, { module: 'orden de servicio' }),
        React.createElement(EditRentProductComponent, { module: 'orden de servicio', allowCommentary: true })));
};
export default ProductsServiceOrderComponent;
