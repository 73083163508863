var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { useMutation } from '@apollo/client';
import getInputErrorFormat from '../containers/sale.v2/Action/helpers/getInputErrorFormat';
import { capitalizeAlt } from '../helpers';
var cacheResponse = function (isArray, existingRefs, newRef) {
    return isArray
        ? __spreadArray(__spreadArray([], existingRefs, true), [newRef], false) : __assign(__assign({}, existingRefs), { count: existingRefs.count + 1, rows: __spreadArray([newRef], existingRefs.rows, true) });
};
var useApolloMutation = function (gql, params, variables) {
    var _a = useMutation(gql, {
        variables: variables,
        update: function (cache, _a) {
            var _b;
            var data = _a.data;
            try {
                if ((params === null || params === void 0 ? void 0 : params.action) === 'delete') {
                    cache.evict({
                        id: cache.identify(data[params.mutationName || "delete".concat(capitalizeAlt(params.functionName || params.name))]),
                    });
                    cache.gc();
                    return;
                }
                if (!(params === null || params === void 0 ? void 0 : params.name))
                    return;
                var isChildren = params.parent
                    ? { id: cache.identify({ __typename: params.parent[1], id: params.parent[0] }) }
                    : {};
                var queryName_1 = params.parent && params.parent[0] ? "".concat(capitalizeAlt(params.name), "s") : params.name;
                cache.modify(__assign(__assign({}, isChildren), { fields: (_b = {},
                        _b[queryName_1] = function (existingRefs, _a) {
                            var fieldName = _a.fieldName, storeFieldName = _a.storeFieldName;
                            var fragmentName = params.fragmentName ? { fragmentName: params.fragmentName } : {};
                            var newRef = cache.writeFragment(__assign({ data: __assign({}, data[Object.keys(data)[0]]), fragment: params.fragment }, fragmentName));
                            //Could be better
                            if (params === null || params === void 0 ? void 0 : params.where) {
                                var args = JSON.parse(storeFieldName.replace("".concat(fieldName, "("), '').slice(0, -1));
                                if (args &&
                                    args[params.where[1]] &&
                                    parseInt(args[params.where[1]]) === parseInt(params.where[0]))
                                    return cacheResponse(params.parent || params.where, existingRefs, newRef);
                            }
                            return cacheResponse(params.parent || params.where || params.newModel, existingRefs, newRef);
                        },
                        _b) }));
            }
            catch (e) {
                console.log(e);
                getInputErrorFormat(e);
            }
        },
    }), f = _a[0], _b = _a[1], data = _b.data, loading = _b.loading, error = _b.error;
    return [f, { data: data, loading: loading, error: error }];
};
export default useApolloMutation;
