import React, { useEffect, useState } from 'react';
import SsidChartIcon from '@mui/icons-material/SsidChart';
import TitleDrawerMenuDashboardComponent from '../dashboard/Menu/Items/helpers/Title';
import LinkMenuDashboardComponent from '../dashboard/Menu/Items/helpers/Link';
import UnderConstructionAlertComponent from '../../presentationals/UnderConstructionAlert';
import getSession from '../../helpers/getSession';
var PosReportsComponent = function () {
    var _a = useState('/panel/pos/report/sale/rotation/product'), rotationRoute = _a[0], setRotationRoute = _a[1];
    useEffect(function () {
        var _a;
        var session = getSession();
        if (!(session === null || session === void 0 ? void 0 : session.Company))
            return;
        setRotationRoute(((_a = session === null || session === void 0 ? void 0 : session.Company) === null || _a === void 0 ? void 0 : _a.id.toString()) === '118'
            ? '/panel/pos/report/sale/rotation/productAndIngredient'
            : '/panel/pos/report/sale/rotation/product');
    }, []);
    return (React.createElement("div", { className: 'flex flex-1 flex-col py-2 px-3 w-full  basis-0 overflow-y-auto' },
        React.createElement(UnderConstructionAlertComponent, null),
        React.createElement("div", { className: 'flex mt-2' },
            React.createElement(TitleDrawerMenuDashboardComponent, { icon: React.createElement(SsidChartIcon, null), title: 'Informes de ventas' })),
        React.createElement("div", { className: 'flex flex-1 flex-col overflow-y-auto' },
            React.createElement(LinkMenuDashboardComponent, { url: '/panel/pos/report/income', primary: 'Informes, gr\u00E1ficas y estad\u00EDsticas', secondary: 'Si lo que buscas son resultados precisos de los movimientos de la empresa, puedes acceder a este m\u00F3dulo.' }),
            React.createElement(LinkMenuDashboardComponent, { url: '/panel/pos/report/sale/ticket', primary: 'Ventas por ticket', secondary: 'Consulta las ventas realizadas en la empresa por ticket en un periodo.' }),
            React.createElement(LinkMenuDashboardComponent, { url: '/panel/pos/report/sale/product', primary: 'Ventas de productos desglosados', secondary: 'Reporte que muestra el desglose de los conceptos de los ticket vendidos en un periodo' }),
            React.createElement(LinkMenuDashboardComponent, { url: rotationRoute, primary: 'Ventas de productos acumulados', secondary: 'Reporte de los productos vendidos en un periodo por concepto' }),
            React.createElement(LinkMenuDashboardComponent, { url: '/panel/pos/report/sale/employee', primary: 'Ventas por empleado', secondary: 'Reporte las ventas filtradas por empleado' }),
            React.createElement(LinkMenuDashboardComponent, { url: '/panel/pos/report/sale/customer', primary: 'Ventas por cliente', secondary: 'Reporte las ventas filtradas por cliente' }),
            React.createElement(LinkMenuDashboardComponent, { url: '/panel/pos/report/transaction', primary: 'Ventas por forma de pago', secondary: 'Reporte detallado que muestra las ventas con su forma de pago durante un periodo' }),
            React.createElement(LinkMenuDashboardComponent, { url: '/panel/pos/report/sale/invoice', primary: 'Facturaci\u00F3n electr\u00F3nica', secondary: 'Reporte que muestra las facturas emitidas en un periodo' }),
            React.createElement(LinkMenuDashboardComponent, { url: '/panel/pos/report/sale/invoice/complement', primary: 'Complementos de pago', secondary: 'Reporte que muestra los complementos de pago emitidas en un periodo' }),
            React.createElement(LinkMenuDashboardComponent, { url: '/panel/pos/report/sale/invoice/creditNote', primary: 'Notas de cr\u00E9dito', secondary: 'Reporte que muestra las notas de cr\u00E9dito emitidas en un periodo' }))));
};
export default PosReportsComponent;
