var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React from 'react';
import ItemTableBranchComponent from './Item';
import GQL_BRANCH from '../../../../../apollo/gql/branch';
import useApolloQuery from '../../../../../hooks/useApolloQuery';
import LoadingProgress from '../../../../LoadingProgress';
var TableBranchFieldComponent = function (props) {
    var defaultBranches = {
        id: undefined,
        name: 'Todas las sucursales',
    };
    var _a = useApolloQuery(GQL_BRANCH.GET_BRANCHES), data = _a.data, loading = _a.loading;
    if (loading)
        React.createElement(LoadingProgress, null);
    return (React.createElement("div", { className: 'flex max-h-96 overflow-y-auto overflow-x-hidden flex-col' }, data &&
        (props.allowAllBranches ? __spreadArray([defaultBranches], data.rows, true) : data.rows).map(function (el, i) { return (React.createElement(ItemTableBranchComponent, { key: el.id || -1, id: el.id, index: i + 1, onClick: props.onSelect })); })));
};
export default TableBranchFieldComponent;
