import React, { useEffect } from 'react';
import useApolloLazyQuery from '../../../../hooks/useApolloLazyQuery';
import GQL_REQUISITION from '../../../../apollo/gql/requisition';
import LoadingProgress from '../../../../presentationals/LoadingProgress';
import ErrorDataComponent from '../../../../presentationals/ErrorData';
import ItemPrintProductRequisitionBarcodeComponent from './Item';
var BodyPrintProductRequisitionBarcodeDialogComponent = function (props) {
    var _a = useApolloLazyQuery(GQL_REQUISITION.GET_REQUISITION_BY_ID), getData = _a[0], _b = _a[1], data = _b.data, loading = _b.loading;
    useEffect(function () {
        if (props.requisitionId)
            getData({ variables: { id: props.requisitionId } });
    }, [props.requisitionId]);
    if (loading)
        return React.createElement(LoadingProgress, null);
    if (!data || !props.requisitionId)
        return React.createElement(ErrorDataComponent, null);
    return (React.createElement("div", { className: 'flex flex-col px-4 py-3 mx-4 my-3 w-full sm:w-1/3 rounded border border-solid border-slate-300' }, data.RequisitionProducts.map(function (el, i) { return (React.createElement(ItemPrintProductRequisitionBarcodeComponent, { key: el.id, Product: el, index: i, length: data.RequisitionProducts.length })); })));
};
export default BodyPrintProductRequisitionBarcodeDialogComponent;
