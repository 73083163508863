import React from 'react';

import Button from '../../../../../../../presentationals/Dialog/Footer/Button';
import { isSaleBranchFormOpen } from '../../../../../../../apollo/cache';

const CreateButtonFooterCreateFormBranchActionSaleV2Component = () => {
  const handleClick = () => {
    isSaleBranchFormOpen(false);
  };
  return (
    <Button color='secondary' className='ml-1' onClick={handleClick}>
      Continuar
    </Button>
  );
};

export default CreateButtonFooterCreateFormBranchActionSaleV2Component;
