import React from 'react';
import { useNavigate } from 'react-router-dom';
import TocIcon from '@mui/icons-material/Toc';
var getEmployeeSalesReportActions = function () {
    var navigate = useNavigate();
    return [
        {
            icon: React.createElement(TocIcon, null),
            label: 'Contenido',
            onClick: function (identifier) {
                navigate("".concat(identifier));
            },
        },
    ];
};
export default getEmployeeSalesReportActions;
