import React from 'react';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import IconButtonComponent from '../../../../../presentationals/IconButton';
var ProductsToTransferViewButton = function () {
    var handleClick = function () {
        console.log(123);
    };
    return (React.createElement(IconButtonComponent, { className: 'hidden', size: 'small', action: handleClick, icon: React.createElement(ManageSearchIcon, null) }));
};
export default ProductsToTransferViewButton;
