var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React, { useEffect, useState } from 'react';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import FormDialogProductFieldComponent from '../../../../presentationals/FormComponents/ProductField/Dialog/Body/Form';
import IconButtonComponent from '../../../../presentationals/IconButton';
import getInputErrorFormat from '../../../sale.v2/Action/helpers/getInputErrorFormat';
import { inputError } from '../../../../apollo/cache';
import setInputErrorFormat from '../../../sale.v2/Action/helpers/setInputErrorFormat';
import FooterDialogComponent from '../../../../presentationals/Dialog/Footer';
import EditButtonProductCommentaryComponent from './EditButton';
import useGetItemProduct from '../helpers/useGetItemProduct';
import LoadingProgress from '../../../../presentationals/LoadingProgress';
var CommentaryProductComponent = function () {
    var _a = useGetItemProduct(), data = _a.data, loading = _a.loading;
    var _b = useState([]), commentaries = _b[0], setCommentaries = _b[1];
    var _c = useState(''), value = _c[0], setValue = _c[1];
    useEffect(function () {
        if (data === null || data === void 0 ? void 0 : data.Commentaries)
            setCommentaries(data.Commentaries);
    }, [data === null || data === void 0 ? void 0 : data.Commentaries]);
    var handleChange = function (name, value) {
        setValue(value);
    };
    var handleClick = function () {
        try {
            inputError({});
            if (value === '')
                throw new Error(setInputErrorFormat('search', 'Escribe un comentario.'));
            setCommentaries(function (commentaries) { return __spreadArray(__spreadArray([], commentaries, true), [value], false); });
            setValue('');
        }
        catch (error) {
            getInputErrorFormat(error);
        }
    };
    var handleDelete = function (e, params) {
        setCommentaries(function (commentaries) { return commentaries.filter(function (el, i) { return i !== params; }); });
    };
    if (loading || !data)
        return React.createElement(LoadingProgress, null);
    return (React.createElement("div", { className: 'flex flex-1 flex-col' },
        React.createElement("div", { className: 'flex flex-1 flex-col px-4 pt-3 w-full' },
            React.createElement("div", { className: 'text-base font-medium mb-1 flex ' }, "Comentarios del producto"),
            React.createElement("div", { className: 'text-sm sm:text-base text-gray-600 mb-2' }, "Los comentarios en el producto te facilitar\u00E1n el proceso de venta al seleccionar opciones de comentarios predefinidas en lugar de tener que escribirlas manualmente. Normalmente, se agregan las opciones de comentarios m\u00E1s comunes, lo que agiliza y mejora la experiencia del usuario."),
            React.createElement("div", { className: 'flex' },
                React.createElement("div", { className: 'sm:w-1/2' },
                    React.createElement(FormDialogProductFieldComponent, { label: 'Escribe un comentario', buttonLabel: 'A\u00F1adir', value: value, onChange: handleChange, onClick: handleClick }))),
            React.createElement("div", { className: 'flex flex-1 flex-col mt-2 basis-0 overflow-y-auto' }, commentaries.length === 0 ? (React.createElement("div", { className: 'text-gray-600' }, "De momento no cuentas con ning\u00FAn comentario predefinido.")) : (commentaries.map(function (el, i) { return (React.createElement("div", { key: i, className: "flex py-1 px-2 items-center  ".concat(i % 2 === 0 ? 'bg-gray-300' : 'bg-gray-50') },
                React.createElement("div", { className: 'flex flex-1' }, el),
                React.createElement("div", { className: 'flex' },
                    React.createElement(IconButtonComponent, { size: 'small', icon: React.createElement(DeleteOutlineIcon, null), params: i, action: handleDelete })))); })))),
        React.createElement(FooterDialogComponent, null,
            React.createElement("div", null),
            React.createElement(EditButtonProductCommentaryComponent, { Commentaries: commentaries }))));
};
export default CommentaryProductComponent;
