import { inputError } from '../../../../apollo/cache';
import { parseError } from '../../../../helpers';
import { getSnackbarDialogState } from '../../../../store/snackbar/snackbarDialog';

const getInputErrorFormat = e => {
  const { setSnackbar } = getSnackbarDialogState();
  try {
    const serverError = parseError(e);

    if (serverError && serverError[0].name === 'INVENTORY_ERROR') {
      inputError({ pin: serverError[0].message });
      return;
    }

    if (serverError && serverError[0].name === 'BAD_USER_INPUT') {
      const inputErrorKey = Object.keys(serverError[0].message)[0];
      const response = { [inputErrorKey]: serverError[0].message[inputErrorKey] };
      inputError(response);
      return;
    }

    const unformattedError = e.message;
    const error = JSON.parse(unformattedError);
    inputError({ [error.name]: error.message });
  } catch (er) {
    setSnackbar({ isOpen: true, label: e.message, severity: 'warning', time: 3000 });
  }
};

export default getInputErrorFormat;
