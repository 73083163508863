var getSaleStatus = function (status) {
    switch (status) {
        case 0:
            return 'Cancelado';
        case 1:
        case 3:
            return 'Abierto';
        case 4:
            return 'Finalizado';
        default:
            return 'Indefinido';
    }
};
export default getSaleStatus;
