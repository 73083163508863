var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React, { useEffect, useState } from 'react';
import { Stack, Typography } from '@mui/material';
import ChipComponent from '../Chip';
import useApolloQuery from '../../../hooks/useApolloQuery';
import GQL_BRANCH from '../../../apollo/gql/branch';
import ErrorDataComponent from '../../ErrorData';
var CheckboxBranchFieldComponent = function (props) {
    var _a = useApolloQuery(GQL_BRANCH.GET_BRANCHES), data = _a.data, loading = _a.loading;
    var _b = useState([]), checked = _b[0], setChecked = _b[1];
    var _c = useState(true), firstTime = _c[0], setFirstTime = _c[1];
    useEffect(function () {
        if (firstTime) {
            setChecked(props.defaultValues);
            setFirstTime(false);
        }
    }, [props.defaultValues]);
    var handleChange = function (name, value) {
        var inArray = checked.includes(value);
        var newArray = !inArray ? __spreadArray(__spreadArray([], checked, true), [value], false) : checked.filter(function (id) { return id !== value; });
        setChecked(newArray);
        props.onChange(props.name, newArray);
    };
    if (loading)
        return React.createElement("div", null);
    if (!data)
        return React.createElement(ErrorDataComponent, null);
    return (React.createElement("div", { className: 'flex flex-col' },
        React.createElement(Typography, { variant: 'subtitle1', className: 'mb-1' }, "Selecciona una o varias sucursales:"),
        React.createElement(Stack, { direction: 'row', spacing: 1 }, data.rows.map(function (el) { return (React.createElement(ChipComponent, { key: el.id, checked: checked.includes(el.id.toString()), onClick: handleChange, name: 'branchId', value: el.id.toString(), label: el.name })); }))));
};
export default CheckboxBranchFieldComponent;
