import React from 'react';
import { useReactiveVar } from '@apollo/client';
import ButtonFooterDialogComponent from '../../../../../presentationals/Dialog/Footer/Button';
import { branchSaleV2, cartItems, commentarySaleV2, customerSaleV2, discountTypeSaleV2, formatSaleV2, inputError, invoiceSaleV2, isSaleConfirmDialogOpen, operationDateSaleV2, } from '../../../../../apollo/cache';
import CONFIG from '../../../../../config/config.json';
import getSession from '../../../../../helpers/getSession';
import getIfSatRequirementisOk from '../CreateButton/helpers/getIfSatRequirementisOk';
import setInputErrorFormat from '../../../Action/helpers/setInputErrorFormat';
import getInputErrorFormat from '../../../Action/helpers/getInputErrorFormat';
var DocumentPreviewSaleV2Component = function () {
    var formatSaleV2Var = useReactiveVar(formatSaleV2);
    var handleClick = function () {
        var _a;
        try {
            inputError({});
            var session = getSession();
            if (!session)
                return;
            var invoiceSaleV2Var = invoiceSaleV2();
            var cartItemsVar = cartItems();
            var customerIdVar = customerSaleV2();
            var branchSaleV2Var = branchSaleV2();
            var discountTypeVar_1 = discountTypeSaleV2();
            var commentarySaleV2Var = commentarySaleV2();
            var operationDateVar = operationDateSaleV2();
            var satRequirement = getIfSatRequirementisOk(cartItemsVar);
            if (cartItemsVar.length === 0) {
                isSaleConfirmDialogOpen(false);
                throw new Error('Añade al menos un producto al carrito.');
            }
            if (formatSaleV2Var[2] && !satRequirement)
                throw new Error('Alguno de los productos del carrito no cuenta con las claves del SAT configuradas.');
            if (formatSaleV2Var[2] && invoiceSaleV2Var.paymentMethod.length === 0)
                throw new Error(setInputErrorFormat('paymentMethod', 'Selecciona el método de pago.'));
            if (formatSaleV2Var[2] && invoiceSaleV2Var.paymentForm.length === 0)
                throw new Error(setInputErrorFormat('paymentForm', 'Selecciona la forma de pago.'));
            if (formatSaleV2Var[2] && invoiceSaleV2Var.useCfdi.length === 0)
                throw new Error(setInputErrorFormat('useCfdi', 'Selecciona el uso del CFDi.'));
            if (formatSaleV2Var[2] &&
                invoiceSaleV2Var.relateUUID.length === 0 &&
                invoiceSaleV2Var.relateType.length > 0)
                throw new Error(setInputErrorFormat('relateUUID', 'Proporciona el UUID a relacionar'));
            if (formatSaleV2Var[2] &&
                invoiceSaleV2Var.relateUUID.length > 0 &&
                invoiceSaleV2Var.relateType.length === 0)
                throw new Error(setInputErrorFormat('relateType', 'Selecciona el tipo de relación'));
            var companyId = (_a = session.Company) === null || _a === void 0 ? void 0 : _a.id;
            var body = {
                companyId: companyId,
                operationDate: operationDateVar,
                customerId: customerIdVar.id,
                taxesOption: parseFloat(formatSaleV2Var[1].toString()),
                showTaxes: formatSaleV2Var[3],
                discountType: parseInt(discountTypeVar_1.toString()),
                branchId: branchSaleV2Var.branchSale,
                Products: cartItemsVar.map(function (el) { return ({
                    productId: el.productId,
                    quantity: parseFloat(el.quantity),
                    discount: parseInt(discountTypeVar_1.toString()) === 1
                        ? parseFloat(el.discountRate)
                        : parseFloat(el.discount),
                    price: parseFloat(el.unitPrice),
                    commentary: el.commentary,
                    employeeId: el.employeeId,
                }); }),
                commentary: commentarySaleV2Var,
                paymentMethod: invoiceSaleV2Var.paymentMethod,
                paymentForm: invoiceSaleV2Var.paymentForm,
                useCfdi: invoiceSaleV2Var.useCfdi,
            };
            window.open("".concat(CONFIG['restServer'].uri, "/files/invoices/pdf/preview?body=").concat(JSON.stringify(body)), '_black');
        }
        catch (e) {
            getInputErrorFormat(e);
        }
    };
    if (formatSaleV2Var[0].toString() !== '-1')
        return React.createElement("div", null);
    return (React.createElement(ButtonFooterDialogComponent, { className: 'ml-2', color: 'inherit', onClick: handleClick }, "Previsualizar documento"));
};
export default DocumentPreviewSaleV2Component;
