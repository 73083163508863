var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import getSession from './getSession';
export var getDefaultSettings = function () { return ({
    discountType: '%',
    belowZeroInventory: true,
    formatToPrint: 0,
    defaultFormatSaleV2: [1, 1, false, false],
    agenda: {
        print: true,
        scheduleAvailability: {
            qty: 0,
            scheduleSettings: [],
        },
        visibility: {
            title: true,
            customer: true,
            reminder: true,
            address: true,
            diagnosis: true,
            description: true,
            permissions: true,
            adviser: false,
            tagId: true,
            sessions: false,
        },
    },
}); };
var getSettings = function () {
    var _a;
    var defaultSettings = getDefaultSettings();
    var session = getSession();
    var localStorageSettings = localStorage.getItem('settings');
    var currentSettings = localStorageSettings ? JSON.parse(localStorageSettings) : {};
    var companyId = (_a = session === null || session === void 0 ? void 0 : session.Company) === null || _a === void 0 ? void 0 : _a.id;
    var currentDefaultFormatSaleV2 = currentSettings.defaultFormatSaleV2
        ? [
            (companyId === null || companyId === void 0 ? void 0 : companyId.toString()) === '116'
                ? '7'
                : (companyId === null || companyId === void 0 ? void 0 : companyId.toString()) === '3'
                    ? '1'
                    : currentSettings.defaultFormatSaleV2[0],
            currentSettings.defaultFormatSaleV2[1],
            currentSettings.defaultFormatSaleV2[2],
            currentSettings.defaultFormatSaleV2[3],
        ]
        : [
            (companyId === null || companyId === void 0 ? void 0 : companyId.toString()) === '116'
                ? '7'
                : (companyId === null || companyId === void 0 ? void 0 : companyId.toString()) === '3'
                    ? '1'
                    : defaultSettings.defaultFormatSaleV2[0],
            defaultSettings.defaultFormatSaleV2[1],
            defaultSettings.defaultFormatSaleV2[2],
            defaultSettings.defaultFormatSaleV2[3],
        ];
    return __assign(__assign(__assign({}, defaultSettings), currentSettings), { belowZeroInventory: localStorage.getItem('belowZeroInventory') &&
            localStorage.getItem('belowZeroInventory') === 'true', formatToPrint: currentSettings.formatToPrint ? parseInt(currentSettings.formatToPrint) : 0, defaultFormatSaleV2: currentDefaultFormatSaleV2 });
};
export default getSettings;
