var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useState } from 'react';
import { isEditFormOpen } from '../../../../../apollo/cache';
import ModuleV3ActionComponent from './ActionButton';
var RowTableBodyModuleV3Component = function (props) {
    var _a;
    var _b = useState(false), selected = _b[0], setSelected = _b[1];
    var handleClick = function () {
        isEditFormOpen(props.identifier);
    };
    var handleMouseOver = function () {
        setSelected(true);
    };
    var handleMouseOut = function () {
        setSelected(false);
    };
    return (React.createElement("div", __assign({}, props.getRowProps({
        style: __assign(__assign({}, props.style), { boxShadow: selected ? '0px 0px 5px 2px grey' : undefined, zIndex: selected ? 999 : undefined }),
    }), { role: 'button', tabIndex: props.index, onClick: !props.actions ? handleClick : undefined, onMouseEnter: handleMouseOver, onMouseLeave: handleMouseOut, className: "tr flex ".concat(props.index % 2 ? 'bg-gray-100' : '', " hover:!bg-gray-200 relative") }),
        selected && props.actions && props.actions.length > 0 && props.cells.length > 0 && (React.createElement("div", { style: {
                minWidth: (_a = props.cells[0].getCellProps().style) === null || _a === void 0 ? void 0 : _a.width,
            }, className: 'absolute h-full flex flex-1 items-center !bg-gray-200 px-2' }, props.actions.map(function (el) { return (React.createElement(ModuleV3ActionComponent, __assign({ key: el.label }, el, { identifier: props.identifier }))); }))),
        props.cells.map(function (cell) {
            return (React.createElement("div", __assign({}, cell.getCellProps(), { className: "td px-2 leading-none flex overflow-hidden ".concat(cell.column.cellClassName || '', " ").concat(selected ? 'bg-transparent' : cell.column.cellBackground) }),
                React.createElement("div", { className: 'flex h-full justify-center flex-col' }, cell.render('Cell'))));
        })));
};
export default RowTableBodyModuleV3Component;
