import React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { Link } from 'react-router-dom';
import convertToCurrencyLabel from '../../../helpers/convertToCurrencyLabel';
var SaleByClassificationSummaryComponent = function (props) {
    return (React.createElement("div", { className: 'flex flex-1 mt-4 flex-col overflow-hidden min-h-[300px] basis-0' },
        React.createElement("div", { className: 'flex justify-between items-end' },
            React.createElement("div", { className: 'text-xl' }, "Ventas por clasificaci\u00F3n"),
            React.createElement(Link, { to: '../sale/rotation/product', className: ' text-blue-600 no-underline hover:underline' }, "Ver m\u00E1s")),
        React.createElement("div", { className: 'flex flex-1 max-w-full flex-col overflow-hidden bg-slate-50 rounded-lg mt-2 border-solid border-slate-200' },
            React.createElement(TableContainer, null,
                React.createElement(Table, { stickyHeader: true, size: 'small' },
                    React.createElement(TableHead, null,
                        React.createElement(TableRow, null,
                            React.createElement(TableCell, { className: 'bg-slate-50' }, "Clasificaci\u00F3n"),
                            React.createElement(TableCell, { className: 'bg-slate-50' }, "#"),
                            React.createElement(TableCell, { className: 'bg-slate-50' }, "Total"))),
                    React.createElement(TableBody, null, props.TotalClassifications &&
                        props.TotalClassifications.map(function (el) { return (React.createElement(TableRow, { key: el.id },
                            React.createElement(TableCell, null, el.name),
                            React.createElement(TableCell, null, el.quantity),
                            React.createElement(TableCell, null,
                                "$",
                                convertToCurrencyLabel(el.total)))); })))),
            props.TotalClassifications && props.TotalClassifications.length === 0 && (React.createElement("div", { className: 'flex flex-1 items-center text-center justify-center pb-4' }, "De momento no cuentas con productos vendidos.")))));
};
export default SaleByClassificationSummaryComponent;
