import React from 'react';
import FormComponent from '../../../../presentationals/RHForm/Form/indext';
import ButtonComponent from '../../../../presentationals/RHForm/Button';
import BodyFormComponent from '../../../../presentationals/RHForm/Form/Body';
import FooterFormComponent from '../../../../presentationals/RHForm/Form/Footer';
import CompaniesMultiCompanyReportComponent from './Companies';
import useSelectBranchesDialogStore from '../../../../store/multiCompanyReport/selectBranchesDialogStore';
var BodySelectBranchesDialogComponent = function () {
    var branches = useSelectBranchesDialogStore(function (state) { return state.branches; });
    var setOpen = useSelectBranchesDialogStore(function (state) { return state.setOpen; });
    var setBranches = useSelectBranchesDialogStore(function (state) { return state.setBranches; });
    var onSubmit = function (data) {
        setBranches(data.branches);
        setOpen(false);
    };
    return (React.createElement(FormComponent, { defaultValues: { branches: branches }, onSubmit: onSubmit },
        React.createElement(BodyFormComponent, null,
            React.createElement(CompaniesMultiCompanyReportComponent, null)),
        React.createElement(FooterFormComponent, null,
            React.createElement("div", null),
            React.createElement(ButtonComponent, null, "Finalizar configuraci\u00F3n"))));
};
export default BodySelectBranchesDialogComponent;
