import React from 'react';
import CustomerComponent from '../containers/customer';
import CreateCustomerLayoutComponent from './Customer/CreateCustomerLayout';
import CustomerLayoutComponent from './Customer/CustomerLayout';
import GeneralEditCustomerComponent from '../containers/customer/Edit';
import SurveyCustomerComponent from '../containers/customer/Survey';
import EditAppliedSurveyComponent from '../containers/surveys/EditAppliedSurvey';
import CalendarCustomerComponent from '../containers/customer/Calendar';
var getCustomerChildrenRoutes = function () {
    return [
        { index: true, element: React.createElement(CustomerComponent, null) },
        {
            path: 'create',
            element: React.createElement(CreateCustomerLayoutComponent, null),
        },
        {
            path: ':customerId',
            element: React.createElement(CustomerLayoutComponent, null),
            children: [
                { index: true, element: React.createElement(GeneralEditCustomerComponent, null) },
                { path: 'survey', element: React.createElement(SurveyCustomerComponent, null) },
                { path: 'calendar', element: React.createElement(CalendarCustomerComponent, null) },
            ],
        },
        {
            path: ':customerId/survey/:surveyId',
            element: React.createElement(EditAppliedSurveyComponent, null),
        },
    ];
};
export default getCustomerChildrenRoutes;
