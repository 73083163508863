import { Observable } from '@apollo/client';
import { onError } from '@apollo/client/link/error';

import getNewToken from './helpers/getNewToken';
import getNewWsToken from './helpers/getNewWsToken';

const errorLink = onError(({ graphQLErrors, networkError, operation, forward }) => {
  if (graphQLErrors) {
    const error = graphQLErrors[0];
    if (error.extensions.code === 'UNAUTHENTICATED')
      return new Observable(async observer => {
        try {
          const oldHeaders = operation.getContext().headers;
          const newToken = await getNewToken();
          operation.setContext(() => ({
            headers: {
              ...oldHeaders,
              authorization: newToken,
            },
          }));
          const subscriber = {
            next: observer.next.bind(observer),
            error: observer.error.bind(observer),
            complete: observer.complete.bind(observer),
          };
          forward(operation).subscribe(subscriber);
        } catch (e) {
          console.log('ErrorLink: ', e);
          observer.error(e);
        }
      });
  }

  if (
    graphQLErrors &&
    graphQLErrors[0] &&
    graphQLErrors[0].message &&
    graphQLErrors[0].message.includes('wsToken')
  )
    try {
      getNewWsToken();
    } catch (e) {
      console.log(`WS_ERROR: ${e.message}`);
    }

  if (
    networkError?.result?.errors &&
    networkError.result?.errors.length > 0 &&
    networkError.result?.errors[0].message.includes('wsToken')
  )
    try {
      getNewWsToken();
    } catch (e) {
      console.log(`WS_ERROR: ${e.message}`);
    }

  console.log(`[Network error]: ${JSON.stringify(networkError)}`);
});

export default errorLink;
