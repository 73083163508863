import React from 'react';

import Container from '../../presentationals/Module.v2/Container';
import { customerLabel } from '../../helpers';

import Filter from './Filter';
import Action from './_action';
import GQL from '../payable/_gql';

const customerVar = customerLabel({ textTransform: 'capitalize' });

const Rows = [
  { label: 'Fecha de Pago', key: 'clientCreatedAt', sort: 'text', format: 'dateTime' },
  { label: 'Fecha de Registo', key: 'createdAt', sort: 'text', format: 'dateTime' },
  {
    label: 'Folio',
    key: 'AccountPayable serial',
    prefix: 'I',
    padStart: [5, '0'],
    filter: true,
  },
  { label: 'Metodo', key: 'PaymentMethod name', sort: 'text', ifNull: 'Efectivo', filter: true },
  {
    label: 'Factura',
    key: 'AccountPayable PurchaseOrder subFolio',
  },
  {
    label: customerVar,
    key: 'AccountPayable Provider name',
    filter: true,
  },
  { label: 'Importe Factura', key: 'AccountPayable total', format: 'currency' },
  { label: 'Importe Pago', key: 'total', sort: 'text', format: 'currency' },
];
const title = 'Compras por forma de pago';
const operation = ['accountPayableTransactions', 'accountPayableTransactions'];

const TransactionV2Component = () => {
  return (
    <>
      <Container
        title={title}
        operation={operation}
        Rows={Rows}
        get={GQL.GET_ACCOUNT_PAYABLE_TRANSACTIONS}
        noDelete
        filter
      />
      <Action />
      <Filter />
    </>
  );
};

export default TransactionV2Component;
