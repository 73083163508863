import React, { useEffect, useState } from 'react';
import { useReactiveVar } from '@apollo/client';

import TextField from '../../../../presentationals/FormComponents/TextField';
import getProductPriceByCustomerPrice from '../../helpers/getProductPriceByCustomerPrice';
import { productSaleV2, customerSaleV2, form } from '../../../../apollo/cache';
import DiscountField from './DiscountField';
import StockSaleV2FieldComponent from './StockField';
import CostSaleV2FieldComponent from './CostField';

const defaultValues = {
  qty: 1,
  discount: 0,
  unitPrice: 0,
  inventory: 'No aplica',
  cost: 0,
  presentationName: '',
};

const CartFormChooseProductSaleV2Component = () => {
  const productSaleV2Var = useReactiveVar(productSaleV2);
  const formVar = useReactiveVar(form);
  const customerSaleV2Var = useReactiveVar(customerSaleV2);
  const [values, setValues] = useState(defaultValues);
  useEffect(() => {
    if (parseFloat(formVar.discount) >= 0)
      setValues(values => ({ ...values, discount: formVar.discount }));
  }, [formVar.discount]);
  useEffect(() => {
    if (productSaleV2Var.id) {
      const customer = customerSaleV2();
      const customerPriceName = customer.priceName === -1 ? null : customer.priceName;
      const unitPrice = getProductPriceByCustomerPrice(
        productSaleV2Var.ProductPresentationPrices,
        productSaleV2Var.price,
        customerPriceName,
      );
      const tmpValues = {
        ...values,
        cost: productSaleV2Var?.PresentationBase?.cost ? productSaleV2Var.PresentationBase.cost : 0,
        presentationName: productSaleV2Var?.PresentationBase?.name
          ? productSaleV2Var.PresentationBase.name
          : 'PIEZA',
        inventory: productSaleV2Var.currentInventory ? productSaleV2Var.currentInventory : 0,
        unitPrice,
      };
      form(tmpValues);
      setValues(tmpValues);
    } else {
      setValues(defaultValues);
    }
  }, [productSaleV2Var]);

  useEffect(() => {
    if (customerSaleV2Var?.priceName && productSaleV2Var.id) {
      const customerPriceName =
        customerSaleV2Var.priceName === -1 ? null : customerSaleV2Var.priceName;
      const unitPrice = getProductPriceByCustomerPrice(
        productSaleV2Var.ProductPresentationPrices,
        productSaleV2Var.price,
        customerPriceName,
      );
      const tmpValues = {
        ...values,
        cost: productSaleV2Var?.PresentationBase?.cost ? productSaleV2Var.PresentationBase.cost : 0,
        presentationName: productSaleV2Var?.PresentationBase?.name
          ? productSaleV2Var.PresentationBase.name
          : 'PIEZA',
        inventory: productSaleV2Var.currentInventory ? productSaleV2Var.currentInventory : 0,
        unitPrice,
      };
      form(tmpValues);
      setValues(tmpValues);
    }
  }, [customerSaleV2Var, productSaleV2Var]);

  const handleChange = (name, value) => {
    const tmpValues = {
      ...values,
      [name]: value,
    };
    setValues(tmpValues);
    form(tmpValues);
  };

  return (
    <div className='flex flex-1 items-center'>
      <div className='md:flex flex-1 rounded bg-gray-100 border-[1px] border-gray-400 border-solid mr-2 hidden'>
        <StockSaleV2FieldComponent
          stock={values.inventory}
          presentationName={values.presentationName}
        />
        <CostSaleV2FieldComponent cost={values.cost} />
      </div>
      <div className='flex justify-end'>
        <div className='flex pr-2'>
          <TextField
            defaultLabel='Cantidad'
            name='qty'
            type='numeric'
            value={values.qty}
            onChange={handleChange}
            className='w-36 md:w-auto'
          />
        </div>
        <div className='flex pr-2'>
          <DiscountField
            name='discount'
            defaultLabel='Descuento'
            value={values.discount}
            handleChange={handleChange}
          />
        </div>
        <div className='flex'>
          <TextField
            name='unitPrice'
            type='numeric'
            defaultLabel='Precio unitario'
            value={values.unitPrice}
            onChange={handleChange}
            className='w-36 md:w-auto'
          />
        </div>
      </div>
    </div>
  );
};

export default CartFormChooseProductSaleV2Component;
