import React from 'react';
import { IconButton, Tooltip } from '@mui/material';
import PaymentOutlinedIcon from '@mui/icons-material/PaymentOutlined';
import getInputErrorFormat from '../../../sale.v2/Action/helpers/getInputErrorFormat';
import { isPaymentFormOpen } from '../../../../apollo/cache';
var OpenCreatePaymentButtonComponent = function (props) {
    var handleClick = function () {
        try {
            isPaymentFormOpen(props.accountReceivableId);
        }
        catch (error) {
            getInputErrorFormat(error);
        }
    };
    return (React.createElement(Tooltip, { title: 'Abonar a cuenta' },
        React.createElement(IconButton, { onClick: handleClick },
            React.createElement(PaymentOutlinedIcon, null))));
};
export default OpenCreatePaymentButtonComponent;
