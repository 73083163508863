import React from 'react';
import ButtonFooterDialogComponent from '../../../../../presentationals/Dialog/Footer/Button';
import setInputErrorFormat from '../../../../sale.v2/Action/helpers/setInputErrorFormat';
import getInputErrorFormat from '../../../../sale.v2/Action/helpers/getInputErrorFormat';
var NextStepAdjustInventoryButtonComponent = function (props) {
    var handleClick = function () {
        try {
            var values = props.getValues();
            if (!values)
                return;
            if (!values.branchId)
                throw new Error(setInputErrorFormat('branchId', 'Selecciona una sucursal.'));
            props.onContinue(values);
        }
        catch (error) {
            getInputErrorFormat(error);
        }
    };
    return React.createElement(ButtonFooterDialogComponent, { onClick: handleClick }, "Continuar");
};
export default NextStepAdjustInventoryButtonComponent;
