var getSaleReportTableSettingColumnLabel = function (key) {
    switch (key) {
        case 'customer':
            return 'Cliente';
        case 'folio':
            return 'Folio';
        case 'status':
            return 'Estatus';
        case 'createdAt':
            return 'Creado el';
        case 'closedAt':
            return 'Finalizado el';
        case 'total':
            return 'Importe';
        case 'subtotal':
            return 'Subtotal';
        case 'taxes':
            return 'Impuestos';
        case 'commission':
            return 'Comisión';
        case 'shipping':
            return 'Envío';
        case 'cashier':
            return 'Cajero';
        case 'discount':
            return 'Descuento';
        case 'seller':
            return 'Vendedor';
        case 'commentary':
            return 'Comentario';
        case 'expenses':
            return 'Gastos';
        case 'branch':
            return 'Sucursal';
        default:
            return 'Indefinido';
    }
};
export default getSaleReportTableSettingColumnLabel;
