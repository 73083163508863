import { useEffect, useState } from 'react';
import getSession from '../helpers/getSession';
var defaultSession = {
    name: '',
    phone: '',
    email: '',
    status: 'pending',
    Company: null,
};
var useGetSession = function () {
    var _a = useState(defaultSession), session = _a[0], setSession = _a[1];
    useEffect(function () {
        var currenSession = getSession();
        if (currenSession)
            setSession({
                name: currenSession.name,
                phone: currenSession.phone,
                email: currenSession.email,
                status: currenSession.status,
                Company: currenSession.status === 'loggedIn' ? currenSession === null || currenSession === void 0 ? void 0 : currenSession.Company : null,
            });
    }, []);
    return session;
};
export default useGetSession;
