import React from 'react';
import SelectFieldComponent from '../SelectField';
var DinnerPreferenceFieldComponent = function (props) {
    var options = [
        { value: 0, label: 'Desactivado' },
        { value: 1, label: 'Número de comensal' },
        { value: 2, label: 'Separador lineal' },
    ];
    return (React.createElement(SelectFieldComponent, { name: 'dinner', label: 'Tipo de separador de productos por comensal', options: options, value: props.value, onChange: props.onChange }));
};
export default DinnerPreferenceFieldComponent;
