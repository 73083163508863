import React, { useEffect } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import GoBackButtonComponent from '../../../presentationals/GoBackButton';
import setDefaultDates from '../../attendanceDetail.report/helpers/setDefaultDates';
import useApolloLazyQuery from '../../../hooks/useApolloLazyQuery';
import GQL_SALE from '../../../apollo/gql/sale';
import ErrorDataComponent from '../../../presentationals/ErrorData';
import ModuleV3Component from '../../../presentationals/Module.v3';
import { dateTimeFormat } from '../../../helpers';
import LoadingProgress from '../../../presentationals/LoadingProgress';
import convertToCurrencyLabel from '../../../helpers/convertToCurrencyLabel';
var SalePromotionRotationDetailComponent = function () {
    var params = useParams();
    var _a = useSearchParams(), searchParams = _a[0], setSearchParams = _a[1];
    var start = searchParams.get('start');
    var end = searchParams.get('end');
    var _b = useApolloLazyQuery(GQL_SALE.GET_PROMOTION_SALES), getData = _b[0], _c = _b[1], loading = _c.loading, data = _c.data;
    var header = [
        { Header: 'Creado el', accessor: 'createdAt' },
        { Header: 'Folio', accessor: 'folio' },
        { Header: 'Importe', accessor: 'total' },
    ];
    useEffect(function () {
        if (!start && !end) {
            var defaultDates = setDefaultDates();
            setSearchParams({ start: defaultDates.start.toString(), end: defaultDates.end.toString() });
        }
    }, [start, end]);
    useEffect(function () {
        if (start && end && params.promotionId)
            getData({
                variables: {
                    start: new Date(start),
                    end: new Date(end),
                    promotionId: params.promotionId,
                },
            });
    }, [start, end, params]);
    if (loading)
        return React.createElement(LoadingProgress, null);
    if (!data)
        return React.createElement(ErrorDataComponent, null);
    return (React.createElement("div", { className: 'flex flex-1 flex-col' },
        React.createElement("div", { className: 'flex items-center px-4 py-0 border-0 border-b border-[#d6d6d6] border-solid' },
            React.createElement("div", { className: 'flex flex-1 items-center' },
                React.createElement("div", { className: 'mr-3' },
                    React.createElement(GoBackButtonComponent, { relative: 'path' })),
                React.createElement("div", { className: 'text-lg text-gray-700 tracking-tight' }, "Detalle de rotaci\u00F3n de la promoci\u00F3n")),
            React.createElement("div", { className: 'flex flex-row' })),
        React.createElement("div", { className: 'flex flex-1 flex-col' },
            React.createElement(ModuleV3Component, { data: data.map(function (el) { return ({
                    id: el.id,
                    createdAt: dateTimeFormat(el.clientCreatedAt),
                    folio: el.folio,
                    total: convertToCurrencyLabel(el.total),
                }); }), title: '', header: header, isLoading: loading }))));
};
export default SalePromotionRotationDetailComponent;
