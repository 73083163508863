import { gql } from '@apollo/client';

const CREATE = gql`
  mutation CreateSale(
    $type: Int!
    $commentary: String
    $discount: Float!
    $customerId: ID
    $Customer: CustomerSaleData
    $category: Int!
    $Products: [SaleProductData!]!
    $Transactions: [TransactionSaleData]!
    $calendarId: ID
    $discountType: Int
    $discount: Float
    $branchId: ID
  ) {
    createSale(
      input: {
        category: $category
        type: $type
        commentary: $commentary
        discount: $discount
        customerId: $customerId
        Customer: $Customer
        Products: $Products
        Transactions: $Transactions
        calendarId: $calendarId
        discountType: $discountType
        discount: $discount
        branchId: $branchId
      }
    ) {
      id
      serial
      paid
      folio
      subtotal
      discount
      taxes
      total
      Customer {
        id
        firstName
        lastName
        addressLine1
        addressLine2
        zipCode
        city
        state
        phone
        priceName
        isNew
      }
      Cashier {
        firstName
        lastName
      }
      clientCreatedAt
      AccountReceivable {
        id
        folio
        serial
        payment
        balance
      }
      SaleProducts {
        Crm {
          id
          customerId
          sessions
          sessionsTaken
          Product {
            name
          }
        }
      }
    }
  }
`;

const CREATE_SALE_INVOICE = gql`
  mutation CreateSaleInvoice(
    $type: Int!
    $category: Int!
    $commentary: String
    $discount: Float
    $shipping: Float
    $customerId: ID
    $Customer: CustomerSaleData
    $code: String
    $sellerId: ID
    $deliveryManId: ID
    $Products: [SaleProductData!]!
    $Transactions: [TransactionSaleData]!
    $toAccountPayable: Boolean
    $calendarId: ID
    $productSizeId: ID
    $discountType: Int
    $currencyKey: String
    $exchangeRate: Float
    $clientCreatedAt: Date
    $pin: String
    $paymentMethod: String!
    $paymentMethodId: ID
    $useCfdi: String!
    $applyExtraTaxes: Boolean
    $identifier: String!
    $businessName: String!
    $addressLine1: String!
    $addressLine2: String!
    $zipCode: String!
    $city: String!
    $municipality: String!
    $state: String!
    $country: String!
    $email: String!
    $phone: String
    $relateUUID: String
    $relateType: String
    $branchId: ID
    $operationDate: Date
  ) {
    createSaleInvoice(
      input: {
        type: $type
        category: $category
        commentary: $commentary
        discount: $discount
        shipping: $shipping
        customerId: $customerId
        Customer: $Customer
        code: $code
        sellerId: $sellerId
        deliveryManId: $deliveryManId
        Products: $Products
        Transactions: $Transactions
        toAccountPayable: $toAccountPayable
        calendarId: $calendarId
        productSizeId: $productSizeId
        discountType: $discountType
        currencyKey: $currencyKey
        exchangeRate: $exchangeRate
        clientCreatedAt: $clientCreatedAt
        pin: $pin
        paymentMethod: $paymentMethod
        paymentMethodId: $paymentMethodId
        useCfdi: $useCfdi
        applyExtraTaxes: $applyExtraTaxes
        relateUUID: $relateUUID
        relateType: $relateType
        branchId: $branchId
        operationDate: $operationDate
        CustomerBillingInformation: {
          identifier: $identifier
          businessName: $businessName
          addressLine1: $addressLine1
          addressLine2: $addressLine2
          zipCode: $zipCode
          city: $city
          municipality: $municipality
          state: $state
          country: $country
          email: $email
          phone: $phone
        }
      }
    ) {
      id
      serial
      paid
      folio
      subtotal
      discount
      taxes
      total
      category
      Invoice {
        uuid
        serial
        folio
      }
      Customer {
        id
        firstName
        lastName
        addressLine1
        addressLine2
        zipCode
        city
        state
        phone
        priceName
        isNew
        phone2
        reference
        creditLimit
        employeeId
        creditDays
      }
      Cashier {
        firstName
        lastName
      }
      clientCreatedAt
      AccountReceivable {
        id
        folio
        serial
        payment
        balance
      }
      SaleProducts {
        Crm {
          id
          customerId
          sessions
          sessionsTaken
          Product {
            name
          }
        }
      }
    }
  }
`;

const CREATE_QUOTE = gql`
  mutation CreateQuote(
    $commentary: String
    $discount: Float!
    $customerId: ID
    $Customer: CustomerSaleData
    $Products: [SaleProductData!]!
    $expireDate: Date
    $sellerId: ID
    $showBankAccount: Int
    $currencyKey: String
    $exchangeRate: Float
  ) {
    createQuote(
      input: {
        commentary: $commentary
        discount: $discount
        customerId: $customerId
        Customer: $Customer
        Products: $Products
        expireDate: $expireDate
        sellerId: $sellerId
        showBankAccount: $showBankAccount
        currencyKey: $currencyKey
        exchangeRate: $exchangeRate
      }
    ) {
      id
      serial
      folio
      subtotal
      discount
      taxes
      total
      expireDate
      pdfFileId
      Customer {
        id
        firstName
        lastName
        addressLine1
        addressLine2
        zipCode
        city
        state
        phone
        priceName
        isNew
        phone2
        reference
        creditLimit
        employeeId
        creditDays
      }
      Cashier {
        firstName
        lastName
      }
      createdAt
    }
  }
`;

const CREATE_V2 = gql`
  mutation CreateSaleV2(
    $type: Int!
    $commentary: String
    $discount: Float
    $discountType: Int
    $customerId: ID
    $Customer: CustomerSaleData
    $category: Int!
    $Products: [SaleProductData!]!
    $Transactions: [TransactionSaleData]!
    $calendarId: ID
    $currencyKey: String
    $exchangeRate: Float
    $clientCreatedAt: Date
    $pin: String
    $sellerId: ID
    $branchId: ID
  ) {
    createSaleV2(
      input: {
        category: $category
        type: $type
        commentary: $commentary
        discount: $discount
        discountType: $discountType
        customerId: $customerId
        Customer: $Customer
        Products: $Products
        Transactions: $Transactions
        calendarId: $calendarId
        currencyKey: $currencyKey
        exchangeRate: $exchangeRate
        clientCreatedAt: $clientCreatedAt
        pin: $pin
        sellerId: $sellerId
        branchId: $branchId
      }
    ) {
      id
      serial
      paid
      folio
      subtotal
      discount
      taxes
      total
      category
      pdfFileId
      pdfServiceOrderFileId
      pdfWorksAndRemodelingFilId
      Customer {
        id
        firstName
        lastName
        addressLine1
        addressLine2
        zipCode
        city
        state
        phone
        priceName
        isNew
        phone2
        reference
        creditLimit
        employeeId
        creditDays
      }
      Cashier {
        firstName
        lastName
      }
      clientCreatedAt
      AccountReceivable {
        id
        folio
        serial
        payment
        balance
      }
      SaleProducts {
        Crm {
          id
          customerId
          sessions
          sessionsTaken
          Product {
            name
          }
        }
      }
    }
  }
`;

const GET_BY_ID = gql`
  query Sale($id: ID!) {
    sale(id: $id) {
      id
      type
      service
      status
      category
      serial
      folio
      commentary
      currencyKey
      exchangeRate
      subtotal
      discount
      taxes
      total
      customerId
      code
      sellerId
      cashierId
      deliveryManId
      Seller {
        firstName
        lastName
      }
      Cashier {
        firstName
        lastName
      }
      DeliveryMan {
        firstName
        lastName
      }
      SaleProducts {
        id
        name
        productId
        quantity
        unitPrice
        withoutTaxes
        subtotal
        discount
        clientCreatedAt
        commentary
        taxes
        outDate
        total
        Product {
          cost
          name
        }
      }
      Customer {
        firstName
        lastName
        addressLine1
        addressLine2
        zipCode
        city
        state
        phone
        priceName
      }
      Branch {
        addressLine1
        addressLine2
        zipCode
        city
        state
        phone
        name
      }
      clientCreatedAt
      updatedAt
      Transactions {
        total
        PaymentMethod {
          name
        }
      }
    }
  }
`;

const GET_PRODUCTS_BY_ID = gql`
  query Sale($id: ID!) {
    sale(id: $id) {
      subtotal
      taxes
      discount
      total
      expenses
      serial
      SaleProducts {
        id
        name
        productId
        quantity
        unitPrice
        withoutTaxes
        subtotal
        discount
        commentary
        taxes
        total
        outDate
        Product {
          name
          cost
        }
      }
      SaleExpenses {
        id
        name
        quantity
        unitPrice
        withoutTaxes
        subtotal
        discount
        taxes
        total
        cost
        outDate
      }
    }
  }
`;
const GET_OPEN_PETTY_CASH = gql`
  query OpenPettyCash {
    openPettyCash
  }
`;

const CREATE_PETTY_CASH = gql`
  mutation CreatePettyCash($pettyCashAmount: Float!) {
    createPettyCash(input: { pettyCashAmount: $pettyCashAmount }) {
      id
    }
  }
`;

const GET_SALE_FOLIO = gql`
  query SalesFolio($paid: Boolean, $limit: Int) {
    sales(paid: $paid, limit: $limit) {
      count
      rows {
        id
        serial
        folio
        total
        totalPaid
        Customer {
          firstName
          lastName
        }
        Transactions {
          id
          total
        }
        AccountReceivable {
          id
          total
          payment
          balance
          Transactions {
            total
          }
        }
      }
    }
  }
`;

const FRAGMENT_NEW_QUOTE = gql`
  fragment NewQuote on Quote {
    id
    serial
    folio
    subtotal
    discount
    taxes
    total
    Customer {
      id
      firstName
      lastName
      addressLine1
      addressLine2
      zipCode
      city
      state
      phone
      priceName
    }
    Cashier {
      firstName
      lastName
    }
    createdAt
  }
`;

const GET_LAST_PRICE = gql`
  query SaleProducts(
    $customerId: ID
    $productId: ID
    $limit: Int
    $order: [String]
    $start: Date
    $end: Date
  ) {
    saleProducts(
      customerId: $customerId
      productId: $productId
      limit: $limit
      order: $order
      start: $start
      end: $end
    ) {
      id
      name
      clientCreatedAt
      quantity
      total
      discount
      subtotal
      taxes
      unitPrice
      withoutTaxes
      folio
      branch
      product
      presentation
      customer
    }
  }
`;

const FRAGMENT_NEW_SALE = gql`
  fragment NewSale on Sales {
    id
    serial
    paid
    folio
    subtotal
    discount
    taxes
    total
    category
    Customer {
      id
      firstName
      lastName
      addressLine1
      addressLine2
      zipCode
      city
      state
      phone
      priceName
    }
    Cashier {
      firstName
      lastName
    }
    clientCreatedAt
    AccountReceivable {
      id
      folio
      serial
      payment
      balance
    }
    SaleProducts {
      Crm {
        id
        customerId
        sessions
        sessionsTaken
        Product {
          name
        }
      }
    }
  }
`;

const GET_SALE_STEPS = gql`
  query SaleSteps($limit: Int, $order: [String]) {
    saleSteps(limit: $limit, order: $order) {
      count
      rows {
        id
        step
        name
      }
    }
  }
`;

const CREATE_BUILDING = gql`
  mutation CreateBuilding(
    $type: Int
    $commentary: String
    $discount: Float
    $customerId: ID
    $Customer: CustomerSaleData
    $Products: [BuildingProductData]
    $discountType: Int
    $currencyKey: String
    $exchangeRate: Float
    $clientCreatedAt: Date
  ) {
    createBuilding(
      input: {
        type: $type
        commentary: $commentary
        discount: $discount
        customerId: $customerId
        Customer: $Customer
        Products: $Products
        discountType: $discountType
        currencyKey: $currencyKey
        exchangeRate: $exchangeRate
        clientCreatedAt: $clientCreatedAt
      }
    ) {
      id
      type
      subtype
      status
      deliveryStatus
      serial
      folio
      commentary
      currencyKey
      exchangeRate
      shipping
      subtotal
      discount
      taxes
      total
      customerId
      code
      sellerId
      cashierId
      deliveryManId
      commission
      Seller {
        firstName
        lastName
      }
      Cashier {
        firstName
        lastName
      }
      DeliveryMan {
        firstName
        lastName
      }
      BuildingProducts {
        id
        name
      }
      Customer {
        firstName
        lastName
      }
      createdAt
      clientCreatedAt
      updatedAt
      usdTotal
      expenses
      pdfFileId
    }
  }
`;

const CREATE_TECHNICAL_SERVICE_ORDER = gql`
  mutation CreateTechnicalServiceOrder(
    $type: Int
    $commentary: String
    $discount: Float
    $customerId: ID
    $Customer: CustomerSaleData
    $Products: [TechnicalServiceOrderProductData]
    $discountType: Int
    $currencyKey: String
    $exchangeRate: Float
    $clientCreatedAt: Date
  ) {
    createTechnicalServiceOrder(
      input: {
        type: $type
        commentary: $commentary
        discount: $discount
        customerId: $customerId
        Customer: $Customer
        Products: $Products
        discountType: $discountType
        currencyKey: $currencyKey
        exchangeRate: $exchangeRate
        clientCreatedAt: $clientCreatedAt
      }
    ) {
      id
      type
      subtype
      status
      deliveryStatus
      serial
      folio
      commentary
      currencyKey
      exchangeRate
      shipping
      subtotal
      discount
      taxes
      total
      customerId
      code
      sellerId
      cashierId
      deliveryManId
      commission
      Seller {
        firstName
        lastName
      }
      Cashier {
        firstName
        lastName
      }
      DeliveryMan {
        firstName
        lastName
      }
      TechnicalServiceOrderProducts {
        id
        name
      }
      Customer {
        firstName
        lastName
      }
      createdAt
      clientCreatedAt
      updatedAt
      usdTotal
      expenses
      pdfFileId
    }
  }
`;

const CREATE_MAINTENANCE = gql`
  mutation CreateMaintenance(
    $type: Int
    $commentary: String
    $discount: Float
    $customerId: ID
    $Customer: CustomerSaleData
    $Products: [MaintenanceProductData]
    $discountType: Int
    $currencyKey: String
    $exchangeRate: Float
    $clientCreatedAt: Date
  ) {
    createMaintenance(
      input: {
        type: $type
        commentary: $commentary
        discount: $discount
        customerId: $customerId
        Customer: $Customer
        Products: $Products
        discountType: $discountType
        currencyKey: $currencyKey
        exchangeRate: $exchangeRate
        clientCreatedAt: $clientCreatedAt
      }
    ) {
      id
      type
      subtype
      status
      deliveryStatus
      serial
      folio
      commentary
      currencyKey
      exchangeRate
      shipping
      subtotal
      discount
      taxes
      total
      customerId
      code
      sellerId
      cashierId
      deliveryManId
      commission
      Seller {
        firstName
        lastName
      }
      Cashier {
        firstName
        lastName
      }
      DeliveryMan {
        firstName
        lastName
      }
      MaintenanceProducts {
        id
        name
        productId
        quantity
        unitPrice
        withoutTaxes
        subtotal
        discount
        taxes
        total
        priceName
        commentary
        cost
        iva
        ieps
        Product {
          name
        }
        ProductPresentation {
          name
        }
        clientCreatedAt
        deletedAt
        outDate
        saleStepId
        SaleStep {
          name
        }
      }
      Customer {
        firstName
        lastName
      }
      createdAt
      clientCreatedAt
      updatedAt
      usdTotal
      expenses
      pdfFileId
    }
  }
`;

const GET_TECHNICAL_SERVICE_ORDER_PRODUCT_EXPENSES = gql`
  query TechnicalServiceOrderProductConcepts($limit: Int, $offset: Int) {
    technicalServiceOrderProductConcepts(limit: $limit, offset: $offset) {
      count
      rows {
        id
        name
        productId
        quantity
        unitPrice
        withoutTaxes
        subtotal
        discount
        taxes
        total
        priceName
        commentary
        clientCreatedAt
        deletedAt
        outDate
        saleStepId
        cost
        iva
        ieps
      }
    }
  }
`;

const GET_TECHNICAL_SERVICE_PRODUCT_EXPENSES = gql`
  query TechnicalServiceOrderProductConcepts($limit: Int, $offset: Int) {
    technicalServiceOrderProductConcepts(limit: $limit, offset: $offset) {
      count
      rows {
        id
        name
        productId
        quantity
        unitPrice
        withoutTaxes
        subtotal
        discount
        taxes
        total
        priceName
        commentary
        clientCreatedAt
        deletedAt
        outDate
        saleStepId
        cost
        iva
        ieps
      }
    }
  }
`;

const CREATE_MAINTENANCE_SALE = gql`
  mutation CreateMaintenanceSale(
    $type: Int
    $commentary: String
    $discount: Float
    $customerId: ID
    $Customer: CustomerSaleData
    $Products: [MaintenanceProductData]
    $discountType: Int
    $currencyKey: String
    $exchangeRate: Float
    $clientCreatedAt: Date
    $Transactions: [TransactionSaleData]
  ) {
    createMaintenanceSale(
      input: {
        type: $type
        commentary: $commentary
        discount: $discount
        customerId: $customerId
        Customer: $Customer
        Products: $Products
        discountType: $discountType
        currencyKey: $currencyKey
        exchangeRate: $exchangeRate
        clientCreatedAt: $clientCreatedAt
        Transactions: $Transactions
      }
    ) {
      id
      type
      subtype
      status
      deliveryStatus
      serial
      folio
      commentary
      currencyKey
      exchangeRate
      shipping
      subtotal
      discount
      taxes
      total
      customerId
      code
      sellerId
      cashierId
      deliveryManId
      commission
      Seller {
        firstName
        lastName
      }
      Cashier {
        firstName
        lastName
      }
      DeliveryMan {
        firstName
        lastName
      }
      MaintenanceProducts {
        id
        name
        productId
        quantity
        unitPrice
        withoutTaxes
        subtotal
        discount
        taxes
        total
        priceName
        commentary
        cost
        iva
        ieps
        Product {
          name
        }
        ProductPresentation {
          name
        }
        clientCreatedAt
        deletedAt
        outDate
        saleStepId
        SaleStep {
          name
        }
      }
      Customer {
        firstName
        lastName
      }
      Sale {
        id
      }
      createdAt
      clientCreatedAt
      updatedAt
      usdTotal
      expenses
      pdfFileId
    }
  }
`;

const CREATE_MAINTENANCE_SALE_INVOICE = gql`
  mutation CreateMaintenanceSaleInvoice(
    $type: Int
    $commentary: String
    $discount: Float
    $customerId: ID
    $Customer: CustomerSaleData
    $Products: [MaintenanceProductData]
    $discountType: Int
    $currencyKey: String
    $exchangeRate: Float
    $clientCreatedAt: Date
    $Transactions: [TransactionSaleData]
    $paymentMethod: String!
    $paymentMethodId: ID
    $useCfdi: String!
    $identifier: String!
    $businessName: String!
    $addressLine1: String!
    $addressLine2: String!
    $zipCode: String!
    $city: String!
    $municipality: String!
    $state: String!
    $country: String!
    $email: String!
    $phone: String
    $relateUUID: String
    $relateType: String
    $ProductsInvoice: [ProductInvoice]
  ) {
    createMaintenanceSaleInvoice(
      input: {
        type: $type
        commentary: $commentary
        discount: $discount
        customerId: $customerId
        Customer: $Customer
        Products: $Products
        discountType: $discountType
        currencyKey: $currencyKey
        exchangeRate: $exchangeRate
        clientCreatedAt: $clientCreatedAt
        Transactions: $Transactions
        paymentMethod: $paymentMethod
        paymentMethodId: $paymentMethodId
        useCfdi: $useCfdi
        relateUUID: $relateUUID
        relateType: $relateType
        ProductsInvoice: $ProductsInvoice
        CustomerBillingInformation: {
          identifier: $identifier
          businessName: $businessName
          addressLine1: $addressLine1
          addressLine2: $addressLine2
          zipCode: $zipCode
          city: $city
          municipality: $municipality
          state: $state
          country: $country
          email: $email
          phone: $phone
        }
      }
    ) {
      id
      type
      subtype
      status
      deliveryStatus
      serial
      folio
      commentary
      currencyKey
      exchangeRate
      shipping
      subtotal
      discount
      taxes
      total
      customerId
      code
      sellerId
      cashierId
      deliveryManId
      commission
      Seller {
        firstName
        lastName
      }
      Cashier {
        firstName
        lastName
      }
      DeliveryMan {
        firstName
        lastName
      }
      MaintenanceProducts {
        id
        name
        productId
        quantity
        unitPrice
        withoutTaxes
        subtotal
        discount
        taxes
        total
        priceName
        commentary
        cost
        iva
        ieps
        Product {
          name
        }
        ProductPresentation {
          name
        }
        clientCreatedAt
        deletedAt
        outDate
        saleStepId
        SaleStep {
          name
        }
      }
      Customer {
        firstName
        lastName
      }
      Sale {
        id
        serial
        paid
        folio
        subtotal
        discount
        taxes
        total
        category
        pdfFileId
        Customer {
          id
          firstName
          lastName
          addressLine1
          addressLine2
          zipCode
          city
          state
          phone
          priceName
        }
        AccountReceivable {
          id
          folio
          serial
          payment
          balance
        }
        Invoice {
          uuid
        }
      }
      createdAt
      clientCreatedAt
      updatedAt
      usdTotal
      expenses
      pdfFileId
    }
  }
`;

const GET_PRODUCTS_BY_ID_XLS = gql`
  query Sale($id: ID!) {
    sale(id: $id) {
      id
      serial
      commentary
      SaleProducts {
        outDate
        quantity
        withoutTaxes
        subtotal
        discount
        taxes
        total
        Product {
          name
          cost
        }
      }
      SaleExpenses {
        name
        cost
        outDate
        quantity
        withoutTaxes
        subtotal
        discount
        taxes
        total
      }
    }
  }
`;

const GET_BUILDING_PRODUCT_EXPENSES = gql`
  query BuildingProductConcepts($limit: Int, $offset: Int) {
    buildingProductConcepts(limit: $limit, offset: $offset) {
      count
      rows {
        id
        name
        productId
        quantity
        unitPrice
        withoutTaxes
        subtotal
        discount
        taxes
        total
        priceName
        commentary
        clientCreatedAt
        deletedAt
        outDate
        saleStepId
        cost
        iva
        ieps
      }
    }
  }
`;

const GET_SALE_SERVICE_ORDER = gql`
  query SaleServiceOrder($id: ID!) {
    saleServiceOrder(id: $id) {
      id
      subtotal
      discount
      taxes
      total
      SaleProducts {
        id
        clientCreatedAt
        outDate
        name
        commentary
        quantity
        withoutTaxes
        subtotal
        discount
        taxes
        total
        Product {
          name
          cost
        }
      }
      SaleExpenses {
        id
        total
      }
    }
  }
`;

export default {
  CREATE,
  CREATE_SALE_INVOICE,
  CREATE_V2,
  GET_BY_ID,
  GET_OPEN_PETTY_CASH,
  CREATE_PETTY_CASH,
  GET_PRODUCTS_BY_ID,
  GET_SALE_FOLIO,
  CREATE_QUOTE,
  FRAGMENT_NEW_QUOTE,
  GET_LAST_PRICE,
  FRAGMENT_NEW_SALE,
  GET_SALE_STEPS,
  CREATE_BUILDING,
  GET_BUILDING_PRODUCT_EXPENSES,
  CREATE_TECHNICAL_SERVICE_ORDER,
  GET_TECHNICAL_SERVICE_ORDER_PRODUCT_EXPENSES,
  GET_TECHNICAL_SERVICE_PRODUCT_EXPENSES,
  CREATE_MAINTENANCE,
  GET_SALE_SERVICE_ORDER,
  CREATE_MAINTENANCE_SALE,
  CREATE_MAINTENANCE_SALE_INVOICE,
  GET_PRODUCTS_BY_ID_XLS,
};
