import React, { useRef } from 'react';
import { Popover } from '@mui/material';
import SearchProductFieldPopoverComponent from './TextField';
import TableProductFieldPopoverComponent from './Table';
import useProductFieldStore from '../../../../store/productField';
var RHFProductFieldPopoverComponent = function (props) {
    var _a = useProductFieldStore(), popoverOpen = _a.popoverOpen, setPopoverOpen = _a.setPopoverOpen;
    var searchProductFieldPopoverRef = useRef(null);
    var handleClose = function () {
        setPopoverOpen(null);
    };
    var handleLoading = function (loading) {
        var _a;
        (_a = searchProductFieldPopoverRef.current) === null || _a === void 0 ? void 0 : _a.setLoading(loading);
    };
    return (React.createElement(Popover, { id: "SearchProductPopover".concat(props.name), open: !!popoverOpen, anchorEl: popoverOpen, onClose: handleClose, anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
        }, transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
        }, slotProps: {
            paper: {
                className: 'w-full sm:w-96',
            },
        } },
        React.createElement("div", { className: 'flex flex-1 flex-col' },
            React.createElement("div", { className: 'flex flex-col p-2 pt-1 border-0 border-b border-solid border-gray-300' },
                React.createElement("div", { className: 'text-base font-medium text-sky-600 mb-1' }, "B\u00FAsqueda del producto"),
                React.createElement(SearchProductFieldPopoverComponent, { ref: searchProductFieldPopoverRef })),
            React.createElement(TableProductFieldPopoverComponent, { name: props.name, onLoading: handleLoading }))));
};
export default RHFProductFieldPopoverComponent;
