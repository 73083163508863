import jsPDF from 'jspdf';
import format from 'date-fns/format';
import locale from 'date-fns/locale/es';

import client from '../../apollo';
import { phoneFormat, currencyFormat } from '../../helpers';
import { paymentFormLabel } from '../../helpers/paymentForm.options';

import GQL from './_gql';

import config from '../../config/img.json';

const getDataFromServer = async id => {
  try {
    const { data } = await client.mutate({
      mutation: GQL.GET_BY_ID,
      variables: { id },
    });
    return data.purchaseOrder;
  } catch (e) {
    console.log(e);
  }
};

const arrayTypeOptions = ['-', 'Contado', 'Crédito'];

const Pdf = async (id, print = 1) => {
  const data = await getDataFromServer(id);

  const currentDate = format(new Date(), 'dd/MM/yyyy HH:mm:ss', { locale });
  const products = data.PurchaseOrderDetails.sort((a, b) => parseInt(a.id) - parseInt(b.id));

  // const folio = `${data.folio}-${data.serial}`;
  const folio = `${data.serial}`.padStart(5, '0');
  const zipCode = data.Branch.zipCode ? `${data.Branch.zipCode},` : '';
  const city = data.Branch.city ? `${data.Branch.city},` : '';
  const state = data.Branch.state ? `${data.Branch.state},` : '';
  const createdAt = format(new Date(data.createdAt), 'dd/MM/yyyy HH:mm', { locale });
  const pursacheAt = data.date ? format(new Date(data.date), 'dd/MM/yyyy', { locale }) : '';

  const doc = new jsPDF();

  doc.page = 1;

  const footer = () => {
    const fontSize = parseInt(data.Company.id) === 71 ? 8 : 10;
    doc.setFontSize(fontSize);
    //doc.setFont('times', 'normal');
    doc.setFont('ARIAL', 'normal');
    const finishBreackLine = parseInt(data.Company.id) === 71 ? 275 : 280;
    doc.text(
      `Este documento no tiene validez fiscal. Fecha de Impresión: ${currentDate}.`,
      10,
      finishBreackLine,
    );
    doc.text(`Página ${doc.page}`, 195, finishBreackLine, 'right');
    if (parseInt(data.Company.id) === 71) {
      doc.setTextColor(37, 150, 190);
      doc.setFontSize(20);
      doc.setFont('ARIAL', 'normal');
      doc.text('www.albercasturquesa.mx', 70, 288);
    }
    doc.page = doc.page + 1;
  };

  footer();

  doc.setProperties({
    title: `requisicion_de_compra_${folio}`,
  });

  if (parseInt(data.Company.id) === 71)
    doc.addImage(config['logoTurquesa'], 5, 10, 55, 40, 'center');

  let breakLine = 20;

  doc.setFontSize(9); //11
  //doc.setFont('times', 'bold');
  doc.setTextColor(0);
  doc.setFont('arial', 'bold');
  doc.text(data.Company.name, 105, breakLine, 'center');

  if (data.Company.name !== data.Branch.name) {
    doc.setFontSize(9);
    breakLine += 4;
    doc.text(data.Branch.name, 105, breakLine, 'center');
  }

  //doc.setFont('times', 'normal');
  doc.setFont('ARIAL', 'normal');
  doc.setFontSize(10);
  breakLine += 4;
  doc.text(
    doc.splitTextToSize(`${data.Branch.addressLine1}, ${data.Branch.addressLine2}`, 130),
    105,
    breakLine,
    'center',
  );
  breakLine += 4;
  doc.text(`CP ${zipCode} ${city} ${state} México`, 105, breakLine, 'center');

  if (data.Branch.phone || data.Branch.altPhone) {
    breakLine += 5;
    doc.text(
      `Tel. ${phoneFormat(data.Branch.phone || data.Branch.altPhone)}`,
      105,
      breakLine,
      'center',
    );
  }
  /*if (data.Branch.phone) {
    breakLine += 5;
    doc.text(`Tel. ${phoneFormat(data.Branch.phone)}`, 105, breakLine, 'center');
  }
  if (data.Branch.altPhone) {
    breakLine += 5;
    doc.text(`Cel. ${phoneFormat(data.Branch.altPhone)}`, 105, breakLine, 'center');
  }*/

  doc.setFontSize(14);
  //doc.setFont('times', 'bold');
  doc.setFont('arial', 'bold');

  breakLine += 10;
  doc.text('REQUISICIÓN DE COMPRA', 105, breakLine, 'center');

  doc.text(`OC ${folio}`, 195, 18, 'right');

  doc.setFontSize(10);

  //doc.setFont('times', 'bold');
  doc.setFont('arial', 'bold');
  doc.text('Fecha de Emisión', 195, 24, 'right');
  //doc.setFont('times', 'normal');
  doc.setFont('ARIAL', 'normal');
  doc.text(createdAt, 195, 28, 'right');

  //doc.setFont('times', 'bold');
  doc.setFont('arial', 'bold');
  doc.text('Fecha de Compra', 195, 33, 'right');
  //doc.setFont('times', 'normal');
  doc.setFont('ARIAL', 'normal');
  doc.text(pursacheAt, 195, 37, 'right');

  //doc.setFont('times', 'bold');
  doc.setFont('arial', 'bold');
  doc.text('Folio de Comprobante', 195, 42, 'right');
  //doc.setFont('times', 'normal');
  doc.setFont('ARIAL', 'normal');
  doc.text(data.subFolio || '', 195, 46, 'right');

  breakLine = data.Branch.phone || data.Branch.altPhone ? 57 : 53;

  doc.setFontSize(9);
  //doc.setFont('times', 'bold');
  doc.setFont('arial', 'bold');

  if (parseInt(data.Company.id) === 71) {
    doc.setDrawColor(37, 150, 190);
    doc.setFillColor(37, 150, 190);
  } else {
    doc.setDrawColor(30, 30, 30);
    doc.setFillColor(30, 30, 30);
  }
  doc.rect(10, breakLine - 3, 185, 4, 'FD');
  doc.setTextColor(255, 255, 255);
  doc.text('Datos del Proveedor', 12, breakLine);

  breakLine += 1.5;

  if (parseInt(data.Company.id) === 71) {
    doc.setDrawColor(255, 255, 255);
    doc.setFillColor(255, 255, 255);
  } else {
    doc.setDrawColor(244, 244, 244);
    doc.setFillColor(244, 244, 244);
  }
  doc.rect(10, breakLine, 185, 18, 'FD');

  doc.setTextColor(0);
  //doc.setFont('times', 'bold');
  doc.setFont('arial', 'bold');

  doc.text('Nombre Comercial', 12, breakLine + 4);
  doc.text('Razón Social', 12, breakLine + 8);
  doc.text('RFC', 12, breakLine + 12);
  doc.text('Teléfono', 12, breakLine + 16);

  //doc.setFont('times', 'normal');
  doc.setFont('ARIAL', 'normal');

  doc.text(data.Provider.name, 42, breakLine + 4);
  doc.text(data.Provider.businessName ? data.Provider.businessName : '-', 42, breakLine + 8);
  doc.text(data.Provider.identifier ? data.Provider.identifier : '-', 42, breakLine + 12);
  doc.text(data.Provider.phone ? data.Provider.phone : '-', 42, breakLine + 16);

  doc.setFontSize(9);
  //doc.setFont('times', 'bold');
  doc.setFont('arial', 'bold');

  breakLine += 24;

  if (parseInt(data.Company.id) === 71) {
    doc.setDrawColor(37, 150, 190);
    doc.setFillColor(37, 150, 190);
  } else {
    doc.setDrawColor(30, 30, 30);
    doc.setFillColor(30, 30, 30);
  }
  doc.rect(10, breakLine - 3, 185, 4, 'FD');
  doc.setTextColor(255, 255, 255);
  doc.text('Conceptos', 105, breakLine, 'center');

  breakLine += 5;

  if (parseInt(data.Company.id) === 71) {
    doc.setDrawColor(37, 150, 190);
    doc.setFillColor(37, 150, 190);
  } else {
    doc.setDrawColor(30, 30, 30);
    doc.setFillColor(30, 30, 30);
  }
  doc.rect(10, breakLine - 3, 185, 4, 'FD');
  doc.setTextColor(255, 255, 255);

  doc.text('Cantidad', 19, breakLine, 'center');
  doc.text('Unidad', 36, breakLine, 'center');
  doc.text('Descripción', 50, breakLine);
  doc.text('Costo Unitario', 127, breakLine, 'right');
  // doc.text(data.currency === 'MXN' ? 'Costo Unitario' : 'Costo Unitario (USD)', 127, breakLine, 'right');
  doc.text('IVA', 143, breakLine, 'right');
  // doc.text(data.currency === 'MXN' ? 'IVA' : 'IVA (USD)', 143, breakLine, 'right');
  doc.text('Descuento', 165, breakLine, 'right');
  // doc.text(data.currency === 'MXN' ? 'Descuento' : 'Descuento (USD)', 170, breakLine, 'right');
  // doc.text('Importe', 192, breakLine, 'right');
  doc.text(data.currency === 'MXN' ? 'Importe' : 'Importe (USD)', 192, breakLine, 'right');

  //doc.setFont('times', 'normal');
  doc.setFont('ARIAL', 'normal');
  doc.setTextColor(0);

  breakLine += 4;

  let difference = 0;

  products.forEach(el => {
    doc.setFontSize(9);
    const name = el.Product ? `${el.Product.sku} ${el.Product.name}` : el.concept || '';
    //const unit = el.ProductUnit ? el.ProductUnit.name : '-';
    const unit = el.ProductPresentation ? el.ProductPresentation.name : '-';
    const unitPrice = el.unitPrice ? parseFloat(el.unitPrice) : 0;
    const originalUnitPrice = el.originalUnitPrice ? parseFloat(el.originalUnitPrice) : 0;
    const discount = el.discount ? parseFloat(el.discount) : 0;
    const tmpQty = data.AuthEmployee ? parseFloat(el.receivedQuantity) : parseFloat(el.quantity);
    const total = tmpQty * unitPrice - discount;
    const total2 = parseFloat(el.quantity) * originalUnitPrice - discount;

    if (total > 0 && total2 > 0)
      difference = parseFloat(difference) + (parseFloat(total) - parseFloat(total2));

    if (parseInt(data.Company.id) === 71) {
      doc.setDrawColor(255, 255, 255);
      doc.setFillColor(255, 255, 255);
    } else {
      doc.setDrawColor(244, 244, 244);
      doc.setFillColor(244, 244, 244);
    }
    doc.rect(10, breakLine - 3, 185, 9, 'FD');

    doc.text(`${currencyFormat(tmpQty)}`, 19, breakLine, 'center');
    doc.text(unit, 36, breakLine, 'center');
    if (name.length < 40) doc.text(name, 50, breakLine);
    if (name.length >= 40 && name.length < 80) {
      doc.text(name.substring(0, 40), 50, breakLine);
      doc.text(name.substring(40, name.length), 50, breakLine + 4);
    }
    if (name.length >= 80 && name.length < 120) {
      doc.text(name.substring(0, 40), 50, breakLine);
      doc.text(name.substring(40, 80), 50, breakLine + 4);
      doc.text(name.substring(80, name.length), 50, breakLine + 8);
    }
    if (name.length >= 120 && name.length < 160) {
      doc.text(name.substring(0, 40), 50, breakLine);
      doc.text(name.substring(40, 80), 50, breakLine + 4);
      doc.text(name.substring(80, 120), 50, breakLine + 8);
      doc.text(name.substring(120, name.length), 50, breakLine + 12);
    }
    if (name.length >= 160 && name.length < 200) {
      doc.text(name.substring(0, 40), 50, breakLine);
      doc.text(name.substring(40, 80), 50, breakLine + 4);
      doc.text(name.substring(80, 120), 50, breakLine + 8);
      doc.text(name.substring(120, 160), 50, breakLine + 12);
      doc.text(name.substring(160, name.length), 50, breakLine + 16);
    }
    doc.text(
      currencyFormat(
        data.currency === 'MXN'
          ? el.unitPrice
          : parseFloat(el.unitPrice) / parseFloat(data.exchangeRate),
      ),
      127,
      breakLine,
      'right',
    );
    // doc.text(currencyFormat(el.ivaTotal), 143, breakLine, 'right');
    doc.text(
      currencyFormat(
        data.currency === 'MXN'
          ? el.ivaTotal
          : parseFloat(el.ivaTotal) / parseFloat(data.exchangeRate),
      ),
      143,
      breakLine,
      'right',
    );
    // doc.text(currencyFormat(el.discount), 170, breakLine, 'right');
    doc.text(
      currencyFormat(
        data.currency === 'MXN'
          ? el.discount
          : parseFloat(el.discount) / parseFloat(data.exchangeRate),
      ),
      165,
      breakLine,
      'right',
    );
    // doc.text(currencyFormat(el.total), 192, breakLine, 'right');
    doc.text(
      currencyFormat(
        data.currency === 'MXN' ? el.total : parseFloat(el.total) / parseFloat(data.exchangeRate),
      ),
      192,
      breakLine,
      'right',
    );
    if (name.length < 40) breakLine += 4;
    if (name.length >= 40 && name.length < 80) breakLine += 8;
    if (name.length >= 80 && name.length < 120) breakLine += 12;
    if (name.length >= 120 && name.length < 160) breakLine += 16;
    if (name.length >= 160 && name.length < 200) breakLine += 20;

    if (parseInt(data.Company.id) !== 71) {
      doc.setFontSize(8);
      //doc.setFont('times', 'bold');
      doc.setFont('arial', 'bold');
      doc.text('Cant. Solicitada:', 50, breakLine);
      //doc.setFont('times', 'normal');
      doc.setFont('ARIAL', 'normal');
      doc.text(currencyFormat(el.quantity), 79, breakLine);

      breakLine += 6;
    }

    if (parseInt(breakLine) >= 270) {
      doc.addPage();
      footer();
      breakLine = 20;
    }
  });

  //doc.setFont('times', 'normal');
  doc.setFont('ARIAL', 'normal');
  doc.setFontSize(10);

  if (parseInt(data.Company.id) === 71) {
    doc.setDrawColor(37, 150, 190);
    doc.setFillColor(37, 150, 190);
  } else {
    doc.setDrawColor(30, 30, 30);
    doc.setFillColor(30, 30, 30);
  }
  doc.rect(10, breakLine, 125, 4, 'FD');
  doc.rect(138, breakLine, 32, 28, 'FD');

  if (parseInt(data.Company.id) === 71) {
    doc.setDrawColor(255, 255, 255);
    doc.setFillColor(255, 255, 255);
  } else {
    doc.setDrawColor(244, 244, 244);
    doc.setFillColor(244, 244, 244);
  }
  doc.rect(171, breakLine, 24, 28, 'FD');

  doc.setTextColor(255, 255, 255);

  //doc.setFont('times', 'bold');
  doc.setFont('arial', 'bold');

  doc.text('Comentarios', 12, breakLine + 3);

  //doc.setFont('times', 'normal');
  doc.setFont('ARIAL', 'normal');
  // doc.text('Subtotal', 168, breakLine + 4, 'right');
  doc.text(data.currency === 'MXN' ? 'Subtotal' : 'Subtotal (USD)', 168, breakLine + 4, 'right');
  // doc.text('Descuento', 168, breakLine + 8, 'right');
  doc.text(data.currency === 'MXN' ? 'Descuento' : 'Descuento (USD)', 168, breakLine + 8, 'right');
  // doc.text('IVA', 168, breakLine + 12, 'right');
  doc.text(data.currency === 'MXN' ? 'IVA' : 'IVA (USD)', 168, breakLine + 12, 'right');
  // doc.text('IEPS', 168, breakLine + 16, 'right');
  doc.text(data.currency === 'MXN' ? 'IEPS' : 'IEPS (USD)', 168, breakLine + 16, 'right');
  // data.AuthEmployee && doc.text('Diferencia', 168, breakLine + 26, 'right');
  data.AuthEmployee &&
    doc.text(
      data.currency === 'MXN' ? 'Diferencia' : 'Diferencia (USD)',
      168,
      breakLine + 26,
      'right',
    );

  //doc.setFont('times', 'bold');
  doc.setFont('arial', 'bold');
  const totalText = data.currency === 'MXN' ? 'Total' : 'Total (USD)';
  doc.text(totalText, 168, breakLine + 21, 'right');

  doc.setTextColor(0);
  //doc.setFont('times', 'normal');
  doc.setFont('ARIAL', 'normal');
  // doc.text(currencyFormat(data.subtotal), 193, breakLine + 4, 'right');
  doc.text(
    currencyFormat(
      data.currency === 'MXN'
        ? data.subtotal
        : parseFloat(data.subtotal) / parseFloat(data.exchangeRate),
    ),
    193,
    breakLine + 4,
    'right',
  );
  // doc.text(currencyFormat(data.discount), 193, breakLine + 8, 'right');
  doc.text(
    currencyFormat(
      data.currency === 'MXN'
        ? data.discount
        : parseFloat(data.discount) / parseFloat(data.exchangeRate),
    ),
    193,
    breakLine + 8,
    'right',
  );
  // doc.text(currencyFormat(data.ivaTotal), 193, breakLine + 12, 'right');
  doc.text(
    currencyFormat(
      data.currency === 'MXN'
        ? data.ivaTotal
        : parseFloat(data.ivaTotal) / parseFloat(data.exchangeRate),
    ),
    193,
    breakLine + 12,
    'right',
  );
  // doc.text(currencyFormat(data.iepsTotal), 193, breakLine + 16, 'right');
  doc.text(
    currencyFormat(
      data.currency === 'MXN'
        ? data.iepsTotal
        : parseFloat(data.iepsTotal) / parseFloat(data.exchangeRate),
    ),
    193,
    breakLine + 16,
    'right',
  );
  // data.AuthEmployee && doc.text(currencyFormat(difference), 193, breakLine + 26, 'right');
  data.AuthEmployee &&
    doc.text(
      currencyFormat(
        data.currency === 'MXN'
          ? difference
          : parseFloat(difference) / parseFloat(data.exchangeRate),
      ),
      193,
      breakLine + 26,
      'right',
    );

  //doc.setFont('times', 'bold');
  doc.setFont('arial', 'bold');
  // doc.text(currencyFormat(data.total), 193, breakLine + 21, 'right');
  doc.text(
    currencyFormat(
      data.currency === 'MXN' ? data.total : parseFloat(data.total) / parseFloat(data.exchangeRate),
    ),
    193,
    breakLine + 21,
    'right',
  );

  const receptionText = data.description ? data.description : '';

  if (parseInt(data.Company.id) === 71) {
    doc.setDrawColor(255, 255, 255);
    doc.setFillColor(255, 255, 255);
  } else {
    doc.setDrawColor(244, 244, 244);
    doc.setFillColor(244, 244, 244);
  }
  doc.rect(10, breakLine + 4, 125, 24, 'FD');

  //doc.setFont('times', 'normal');
  doc.setFont('ARIAL', 'normal');
  doc.setTextColor(0);
  doc.text(doc.splitTextToSize(receptionText, 134), 12, breakLine + 8);

  //doc.setFont('times', 'bold');
  doc.setFont('arial', 'bold');
  doc.text('Forma de Pago:', 12, breakLine + 32);
  doc.text('Método de Pago:', 12, breakLine + 36);
  doc.text('Moneda:', 12, breakLine + 40);
  doc.text('Tipo de Cambio:', 12, breakLine + 44);
  doc.text('Emitido por:', 12, breakLine + 48);
  doc.text('Autorizado por:', 12, breakLine + 52);

  //doc.setFont('times', 'normal');

  doc.setFont('ARIAL', 'normal');
  doc.text(paymentFormLabel(data.paymentForm), 42, breakLine + 32);
  doc.text(arrayTypeOptions[data.type || 0], 42, breakLine + 36);
  doc.text(data.currency, 42, breakLine + 40);
  doc.text(currencyFormat(data.exchangeRate), 42, breakLine + 44);
  doc.text(`${data.Employee.firstName} ${data.Employee.lastName}`, 42, breakLine + 48);
  doc.text(
    data.AuthEmployee
      ? `${data.AuthEmployee.firstName} ${data.AuthEmployee.lastName}`
      : 'Pendiente Autorización',
    42,
    breakLine + 52,
  );

  doc.setDrawColor(0);
  doc.setFillColor(0);
  doc.setFontSize(10);

  doc.line(85, breakLine + 60, 125, breakLine + 60);
  doc.text('Nombre y Firma', 105, breakLine + 64, 'center');

  if (print === 1) window.open(doc.output('bloburl'), '_blank');
  // doc.save(`traspaso_${folio}_${data.Branch.name}_a_${data.BranchTransfer.name}.pdf`);
  if (print === 2) {
    const binary = doc.output();
    return binary ? btoa(binary) : false;
  }
};

export default Pdf;
