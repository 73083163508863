import React, { useState, useEffect } from 'react';

import { useLazyQuery, useMutation, useReactiveVar } from '@apollo/client';

import FormDialog from '../../presentationals/FormDialog';
import { inputError } from '../../apollo/cache';
import GQL from '../calendar/_gql';
import GQL_CRM from '../crm.report/_gql';
import getInputErrorFormat from './Action/helpers/getInputErrorFormat';
import RadioComponent from '../../presentationals/Radio';
import { eventFormat } from '../../helpers';

const PrevCalendarComponent = props => {
  const errors = useReactiveVar(inputError);
  const [isOpen, setIsOpen] = useState(false);
  const [pending, setPending] = useState(true);
  const [calendarId, setCalendarId] = useState(0);
  const [getCrmsByCustomerId, { data }] = useLazyQuery(GQL.GET);
  const [createRelation, { loading }] = useMutation(GQL_CRM.RELATION, {
    update(cache, { data }) {
      const { calendarId } = data.relateCrm;
      cache.evict({ id: `Calendar:${calendarId}` });
      cache.gc();
    },
  });
  useEffect(() => {
    if (props.customerId && props.crms)
      getCrmsByCustomerId({
        variables: {
          customerId: props.customerId,
          withCrm: false,
        },
      });
  }, [props.customerId, props.crms]);
  useEffect(() => {
    if (data && data.calendars.rows.length > 1 && pending) {
      setPending(false);
      setIsOpen(true);
    } else if (data && data.calendars.rows.length === 1 && pending) {
      setPending(false);
      const calendarId = parseInt(data.calendars.rows[0].id);
      props.crms.map(el => createRelation({ variables: { crmId: el.id, calendarId } }));
    }
  }, [data, props.crms, pending]);
  const handleClose = () => {
    setIsOpen(false);
  };
  const handleChange = e => {
    const { value } = e.target;
    setCalendarId(value);
  };
  const handleAction = async () => {
    try {
      if (!calendarId) {
        const error = new Error('Selecciona una cita previamente registrada.');
        error.name = 'calendarId';
        throw error;
      }
      await props.crms.map(el => createRelation({ variables: { crmId: el.id, calendarId } }));
      handleClose();
    } catch (e) {
      getInputErrorFormat(e);
    }
  };
  return (
    <FormDialog
      title='Relacionar cita a paquete'
      isOpen={isOpen}
      isLoading={loading}
      handleAction={handleAction}
      hideClose
      disableEscape
    >
      <RadioComponent
        value={calendarId}
        error={errors.calendarId}
        name='calendarId'
        handleChange={handleChange}
        options={
          data &&
          data.calendars.rows.map(el => ({
            tooltip: el.title,
            value: el.id,
            color: el.Tag ? el.Tag.color : '#fff',
            label: eventFormat(el.start, el.end),
          }))
        }
      />
    </FormDialog>
  );
};

export default PrevCalendarComponent;
