var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { useReactiveVar } from '@apollo/client';
import { useEffect, useState } from 'react';
import { filter } from '../../../apollo/cache';
import GQL from '../_gql';
import useApolloQuery from '../../../hooks/useApolloQuery';
var useGetEmployees = function (props) {
    var variables = useReactiveVar(filter);
    var _a = useApolloQuery(GQL.GET, props.getByFilter ? { variables: variables } : {}), response = _a.data, loading = _a.loading, refetch = _a.refetch;
    var _b = useState([]), data = _b[0], setData = _b[1];
    useEffect(function () {
        if (props.getByFilter)
            refetch(__assign(__assign({}, variables), { like: variables.like ? variables.like : undefined }));
    }, [variables, props.getByFilter]);
    useEffect(function () {
        if (response) {
            var Rows = response.rows;
            var data_1 = Rows.map(function (el) {
                var _a, _b;
                return {
                    id: el.id,
                    permissions: ((_a = el.Role) === null || _a === void 0 ? void 0 : _a.name) ? el.Role.name : '-',
                    name: "".concat(el.firstName, " ").concat(el.lastName),
                    phone: el.phone || '-',
                    email: el.email || '-',
                    branch: ((_b = el.Branch) === null || _b === void 0 ? void 0 : _b.name) ? el.Branch.name : '-',
                    position: el.position || '-',
                };
            });
            setData(data_1);
        }
    }, [response]);
    return { data: data, loading: loading };
};
export default useGetEmployees;
