import React from 'react';
import useGetSaleProductIngredientRotation from './helpers/useGetSaleProductIngredientRotation';
import LoadingProgress from '../../presentationals/LoadingProgress';
import ModuleV3Component from '../../presentationals/Module.v3';
import FilterSaleProductPresentationRotationReportComponent from './Filter';
import ErrorDataComponent from '../../presentationals/ErrorData';
import getSaleProductIngredientRotationReportActions from './helpers/actions';
var SaleProductIngredientRotationReportComponent = function () {
    var _a = useGetSaleProductIngredientRotation(), data = _a.data, loading = _a.loading;
    var actions = getSaleProductIngredientRotationReportActions();
    var header = [
        { Header: 'SKU', accessor: 'sku' },
        { Header: 'Componente', accessor: 'product' },
        { Header: 'Unidad', accessor: 'presentation' },
        { Header: 'Ocasiones vendidas', accessor: 'portion' },
        { Header: 'Cantidad', accessor: 'quantity' },
        { Header: 'Importe', accessor: 'total' },
    ];
    if (loading)
        return React.createElement(LoadingProgress, null);
    if (!data)
        return React.createElement(ErrorDataComponent, null);
    return (React.createElement(React.Fragment, null,
        React.createElement(ModuleV3Component, { actions: actions, title: 'Componentes por producto', searchLabel: 'ingredientes', filter: true, data: data, header: header, hideAdd: true, isLoading: loading }),
        React.createElement(FilterSaleProductPresentationRotationReportComponent, null)));
};
export default SaleProductIngredientRotationReportComponent;
