import { snackbar } from '../apollo/cache';
var successfulSnackbar = function (label) {
    snackbar({
        isOpen: true,
        time: 3000,
        label: label,
        severity: 'success',
    });
};
export default successfulSnackbar;
