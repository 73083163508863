import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useReactiveVar, useMutation } from '@apollo/client';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Typography from '@mui/material/Typography';
import { item, form, handleContinue, step, actionForm } from '../../../../../../apollo/cache';
import { currencyFormat } from '../../../../../../helpers';
import IconButton from '../../../../../../presentationals/IconButton';
import GQL from '../../../../_gql';

const BodyTableComponent = () => {
  const navigate = useNavigate();
  const itemVar = useReactiveVar(item);
  const handleContinueVar = useReactiveVar(handleContinue);
  const stepVar = useReactiveVar(step);

  const [rows, setRows] = useState([]);
  const [errors, setErrors] = useState({});

  const [createPromotion] = useMutation(GQL.CREATE, {
    update(cache, { data: response }) {
      try {
        cache.modify({
          fields: {
            promotions(existingPromotionsRefs) {
              return {
                ...existingPromotionsRefs,
                count: existingPromotionsRefs.count + 1,
                rows: [...existingPromotionsRefs.rows, response.createPromotion],
              };
            },
          },
        });
      } catch (e) {
        console.log(e.message);
      }
    },
  });

  const storePromotion = async () => {
    try {
      if (rows.length === 0) {
        const e = new Error('Configura al menos un producto para la promoción.');
        e.name = 'table';
        throw e;
      }
      const formVar = form();
      const startTime = formVar.startTime ? String(formVar.startTime).split('.') : ['0'];
      const endTime = formVar.endTime ? String(formVar.endTime).split('.') : ['0'];
      await createPromotion({
        variables: {
          ...formVar,
          type: parseInt(formVar.promotionType),
          total: parseFloat(formVar.total),
          branchId: formVar.branchId[0],
          startTime: formVar.start
            ? startTime[1]
              ? `${startTime[0].padStart(2, '0')}:${String(parseFloat(startTime[1]) * 6).padStart(
                  2,
                  '0',
                )}:00`
              : `${startTime[0].padStart(2, '0')}:00:00`
            : null,
          endTime: formVar.end
            ? endTime[1]
              ? `${endTime[0].padStart(2, '0')}:${String(parseFloat(endTime[1]) * 6).padStart(
                  2,
                  '0',
                )}:00`
              : `${endTime[0].padStart(2, '0')}:00:00`
            : null,
          ProductPromotions: rows.map(el => ({
            productId: el.productId.value,
            productPresentationId: el.productPresentationId.value,
            quantity: parseFloat(el.quantity),
            price: parseFloat(el.price),
            discountPercent: parseFloat(el.discountPercent),
            typeQuantity: parseInt(el.typeQuantity),
          })),
        },
      });
      form({});
      actionForm({ ...actionForm(), isOpen: false, action: '' });
      navigate('..');
    } catch (e) {
      console.log(e);
      setErrors({ [e.name]: e.message });
    }
  };

  useEffect(() => {
    const formVar = form();
    if (handleContinueVar && stepVar === 1 && [1, 3].includes(parseInt(formVar.promotionType))) {
      storePromotion();
      handleContinue(false);
    }
  }, [stepVar, handleContinueVar]);

  useEffect(() => {
    if (Object.keys(itemVar).length > 0) {
      setRows(rows => [...rows, itemVar]);
      item({});
      setErrors({});
    }
  }, [itemVar]);

  const handleDelete = (e, i) => {
    const tmp = rows.filter((el, ii) => i !== ii);
    setRows(tmp);
  };

  return (
    <TableBody>
      {errors.table && (
        <TableRow>
          <TableCell colSpan={5}>
            <Typography color='error' align='center' gutterBottom>
              {errors.table}
            </Typography>
          </TableCell>
        </TableRow>
      )}
      {rows.map((el, i) => (
        <TableRow key={i}>
          <TableCell>
            <IconButton
              label='Eliminar'
              size='small'
              icon={<DeleteOutlineIcon />}
              params={i}
              action={handleDelete}
            />
          </TableCell>
          <TableCell>{el.productId.label}</TableCell>
          <TableCell align='center'>{el.productPresentationId.label}</TableCell>
          <TableCell align='center'>{currencyFormat(el.discountPercent)}</TableCell>
          <TableCell align='center'>{currencyFormat(el.quantity)}</TableCell>
          <TableCell align='right'>{`$${currencyFormat(el.price)}`}</TableCell>
        </TableRow>
      ))}
    </TableBody>
  );
};

export default BodyTableComponent;
