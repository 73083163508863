import React from 'react';
import DialogComponent from '../../../presentationals/Dialog';
import useSelectBranchesDialogStore from '../../../store/multiCompanyReport/selectBranchesDialogStore';
import BodySelectBranchesDialogComponent from './Body';
var SelectBranchesDialogComponent = function () {
    var open = useSelectBranchesDialogStore(function (state) { return state.open; });
    var setOpen = useSelectBranchesDialogStore(function (state) { return state.setOpen; });
    var handleClose = function () { return setOpen(false); };
    return (React.createElement(DialogComponent, { fullScreen: true, title: 'Configuraci\u00F3n de sucursales a mostrar', onClose: handleClose, open: open },
        React.createElement(BodySelectBranchesDialogComponent, null)));
};
export default SelectBranchesDialogComponent;
