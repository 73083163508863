import React from 'react';

import Typography from '@mui/material/Typography';

import FormDialog from '../../../presentationals/FormDialog';
import Button from '../../../presentationals/Button';

import CONFIG from '../../../config/config.json';
import getSession from '../../../helpers/getSession';

const InvoiceDialogComponent = props => {
  const handleClose = () => {
    props.handleClose();
  };
  const printPdf = () => {
    const session = getSession();
    if ([74].includes(session?.Company.id))
      window.open(`${CONFIG.cfdiServer.uri}/v1/factura_electronica/${props.data.uuid}/pdf`);
    else window.open(`${CONFIG['restServer'].uri}/files/invoices/${props.data.uuid}/pdf`);
  };
  return (
    <FormDialog isOpen={props.isOpen} handleClose={handleClose} noActions>
      <Typography variant='h5' gutterBottom>
        ¡Factura electrónica generada con éxito!
      </Typography>
      <Button label='Generar PDF' onClick={printPdf} />
    </FormDialog>
  );
};

export default InvoiceDialogComponent;
