import React, { useState } from 'react';

import Form from './form';
import Table from './table';

const Cart = props => {
  const [cartItems, setCartItems] = useState([]);

  const addItemToCart = data => {
    setCartItems(cartItems => ([...cartItems, data]));
    props.getCart([...cartItems, data]);
  };

  const removeItemFromCart = position => {
    const tmpCartItems = cartItems
      .map((el, i) => (parseInt(i) !== parseInt(position)) && el)
      .filter(el => el);
    setCartItems(tmpCartItems);
    props.getCart(tmpCartItems);
  };

  return (
    <>
      <Form handleAdd={addItemToCart} codeToClear={props.codeToClear} />
      <Table
        cartItems={cartItems}
        removeItem={removeItemFromCart}
        codeToClear={props.codeToClear}
      />
    </>
  );
};

export default Cart;
