import DELETE_TRANSACTION from './deleteTransaction';
import DELETE_TRANSACTION_WITH_COMPLEMENT from './deleteTransactionWithComplement';
import FRAGMENT_TRANSACTION from './fragmentTransaction';
import GET_TRANSACTION_BY_ID from './getTransactionById';
import GET_TRANSACTIONS from './getTransactions';
var GQL_TRANSACTION = {
    DELETE_TRANSACTION: DELETE_TRANSACTION,
    DELETE_TRANSACTION_WITH_COMPLEMENT: DELETE_TRANSACTION_WITH_COMPLEMENT,
    FRAGMENT_TRANSACTION: FRAGMENT_TRANSACTION,
    GET_TRANSACTION_BY_ID: GET_TRANSACTION_BY_ID,
    GET_TRANSACTIONS: GET_TRANSACTIONS,
};
export default GQL_TRANSACTION;
