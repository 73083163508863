import React from 'react';
import CreateProviderLayoutComponent from './Provider/CreateProviderLayout';
import ProviderComponent from '../containers/provider.v2';
import ProviderLayoutComponent from './Provider/ProviderLayout';
var getProviderChildrenRoutes = function () {
    return [
        { index: true, element: React.createElement(ProviderComponent, null) },
        {
            path: 'create',
            element: React.createElement(CreateProviderLayoutComponent, null),
        },
        {
            path: ':providerId',
            element: React.createElement(ProviderLayoutComponent, null),
        },
    ];
};
export default getProviderChildrenRoutes;
