import React from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import convertToCurrencyLabel from '../../../../helpers/convertToCurrencyLabel';
var OnlineOrderCartItemPriceComponent = function (props) {
    var control = useFormContext().control;
    var qty = useWatch({
        name: props.name,
        control: control,
    });
    return (React.createElement("div", { className: 'text-right leading-none tracking-tighter text-base' },
        "$ ",
        convertToCurrencyLabel(props.unitPrice * qty)));
};
export default OnlineOrderCartItemPriceComponent;
