import { useReactiveVar } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import { formatSaleV2 } from '../../../../../apollo/cache';
import getPrintOptions from '../../../../../helpers/getPrintOptions';
import getSettings from '../../../../../helpers/getSettings';
import setSettings from '../../../../../helpers/setSettings';

import SelectFieldComponent from '../../../../../presentationals/FormComponents/SelectField';

const PrintOptionFooterConfirmDialogSaleV2Component = () => {
  const formatSaleV2Var = useReactiveVar(formatSaleV2);
  const settings = getSettings();
  const printOptions = getPrintOptions(formatSaleV2Var[0]);

  const [value, setValue] = useState(0);

  useEffect(() => {
    if (parseInt(settings.formatToPrint) >= 0) setValue(parseInt(settings.formatToPrint));
  }, [settings]);

  const handleChange = (name, value) => {
    setValue(parseInt(value));
    setSettings('formatToPrint', value);
  };
  return (
    <SelectFieldComponent
      defaultLabel='Comprobante de la operación'
      name='printFormat'
      value={value}
      options={printOptions}
      onChange={handleChange}
    />
  );
};

export default PrintOptionFooterConfirmDialogSaleV2Component;
