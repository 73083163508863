import { gql } from '@apollo/client';

const GET = gql`
  query EcSalesReport(
    $start: Date
    $end: Date
    $paid: Boolean
    $status: Int
    $limit: Int
    $offset: Int
  ) {
    ecSalesReport(
      start: $start
      end: $end
      paid: $paid
      status: $status
      limit: $limit
      offset: $offset
    ) {
      count
      rows {
        id
        clientCreatedAt
        paid
        status
        total
        shipping
        deliveryStatus
        Customer {
          firstName
          lastName
          firstName
          lastName
          addressLine1
          addressLine2
          zipCode
          city
          state
          reference
          email
        }
      }
    }
  }
`;

const GET_BY_ID = gql`
  query EcSale($id: ID!) {
    ecSale(id: $id) {
      id
      quantity
      total
      ProductPresentation {
        name
      }
      Product {
        id
        name
      }
      SaleProductAttributes {
        id
        attribute
        price
        clientCreatedAt
        ProductAttribute {
          id
          type
          name
        }
      }
      SaleProductIngredients {
        id
        price
        ProductIngredient {
          id
          price
          type
          quantity
          Ingredient {
            id
            name
          }
        }
        SaleProductAttributes {
          id
          attribute
          price
          clientCreatedAt
          ProductAttribute {
            id
            type
            name
            attribute
          }
        }
      }
    }
  }
`;

const UPDATE_DELIVERY_STATUS = gql`
  mutation UpdateECSaleDeliveryStatus($id: ID!, $deliveryStatus: Int!) {
    updateECSaleDeliveryStatus(id: $id, deliveryStatus: $deliveryStatus) {
      id
    }
  }
`;

const RE_SEND_EC_SALE = gql`
  query EcSaleReSendEmail($id: ID!) {
    ecSaleReSendEmail(id: $id)
  }
`;

const EC_SALE_TRACKING_NUMBER = gql`
  query EcSaleTrackingNumber($id: ID!) {
    ecSaleTrackingNumber(id: $id) {
      provider
      trackingNumber
      trackingUrl
    }
  }
`;

export default { GET, GET_BY_ID, UPDATE_DELIVERY_STATUS, RE_SEND_EC_SALE, EC_SALE_TRACKING_NUMBER };
