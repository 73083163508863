import React, { useEffect, Fragment, useState } from 'react';
import { useQuery, useLazyQuery, useReactiveVar } from '@apollo/client';

import LinearProgress from '@mui/material/LinearProgress';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import Typography from '@mui/material/Typography';

import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';

import Table from '../../presentationals/Module.v2/Table/TableContainer';
import Pagination from '../../presentationals/Module.v2/Table/Pagination';
import { searchField, filter, count, totals, exportCustomXls } from '../../apollo/cache';
import { currencyFormat } from '../../helpers';
import IconButton from './helpers/iconButton';

import TransferProductDialog from './transferProduct.dialog';

import GQL from './_gql';
import Xls from './_xls';

const defaultIsOpen = [false, { productId: null, branchTransferId: null, product: '' }];

const TableComponent = () => {
  const searchFieldVar = useReactiveVar(searchField);
  const filterVar = useReactiveVar(filter);
  const exportCustomXlsVar = useReactiveVar(exportCustomXls);

  const [isOpenDetails, setIsOpenDetails] = useState(defaultIsOpen);

  const { data, loading, refetch } = useQuery(GQL.GET, {
    notifyOnNetworkStatusChange: true,
  });

  const [getTransferProducts] = useLazyQuery(GQL.GET, {
    notifyOnNetworkStatusChange: true,
    onCompleted: dataR => {
      dataR.transferDetails && Xls(dataR.transferDetails.rows);
      exportCustomXls(false);
    },
  });

  useEffect(() => {
    if (Object.keys(filterVar).length > 0 && !!filterVar.branchId) refetch(filterVar);
  }, [filterVar, refetch]);

  useEffect(() => {
    filter({ ...filterVar, like: searchFieldVar });
  }, [searchFieldVar]);

  useEffect(() => {
    if (data && data.transferDetails) count(data.transferDetails.count);
  }, [data]);

  useEffect(() => {
    if (data && data.transferDetails) {
      count(data.transferDetails.count);
      if (data.transferDetails.totals) {
        const tmpTotals = [
          { label: 'Cantidad', value: `${currencyFormat(data.transferDetails.totals.quantity)}` },
          {
            label: 'Costo Unitario',
            value: `$${currencyFormat(data.transferDetails.totals.unitCost)}`,
          },
          { label: 'Costo Total', value: `$${currencyFormat(data.transferDetails.totals.cost)}` },
        ];
        totals(tmpTotals);
      }
    }
    return () => {
      totals(null);
    };
  }, [data]);

  useEffect(() => {
    if (exportCustomXlsVar) getTransferProducts({ variables: { ...filterVar, limit: 0 } });
  }, [exportCustomXlsVar]);
  const handleRows = (offset, limit) => {
    filter({ ...filterVar, limit, offset });
  };

  const handleDetails = data => {
    setIsOpenDetails([
      true,
      { productId: data.productId, branchTransferId: data.branchTransferId, product: data.product },
    ]);
  };

  const handleCloseDetails = () => setIsOpenDetails(defaultIsOpen);

  return (
    <>
      {loading && <LinearProgress color='secondary' />}
      <Table>
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell style={{ background: '#c9c9c9', borderColor: '#989898', minWidth: 10 }} />
            <TableCell />
            {data &&
              data.transferDetails.count > 0 &&
              data.transferDetails.rows[0].TransferProducts.map((el, i) => {
                return (
                  <TableCell
                    key={el.Branch.name}
                    align='center'
                    colSpan={4}
                    style={{
                      background: i % 2 !== 0 ? '#fafafa' : '#c9c9c9',
                      borderColor: i % 2 !== 0 ? '#ddd' : '#989898',
                      minWidth: 10,
                    }}
                  >
                    <Typography variant='body2' noWrap>
                      <strong>{el.Branch.name}</strong>
                    </Typography>
                  </TableCell>
                );
              })}
          </TableRow>
          <TableRow>
            <TableCell style={{ top: 33 }}>Producto</TableCell>
            <TableCell
              style={{
                top: 33,
                textAlign: 'center',
                background: '#c9c9c9',
                borderColor: '#989898',
              }}
            >
              Clasificación
            </TableCell>
            <TableCell style={{ top: 33 }}>Unidad</TableCell>
            {data &&
              data.transferDetails.count > 0 &&
              data.transferDetails.rows[0].TransferProducts.map((el, i) => {
                return (
                  <Fragment key={i}>
                    <TableCell
                      align='center'
                      style={{
                        background: i % 2 !== 0 ? '#fafafa' : '#c9c9c9',
                        borderColor: i % 2 !== 0 ? '#ddd' : '#989898',
                        top: 33,
                      }}
                    >
                      Cant
                    </TableCell>
                    <TableCell
                      align='right'
                      style={{
                        background: i % 2 !== 0 ? '#fafafa' : '#c9c9c9',
                        borderColor: i % 2 !== 0 ? '#ddd' : '#989898',
                        top: 33,
                      }}
                    >
                      CostUnit
                    </TableCell>
                    <TableCell
                      align='right'
                      style={{
                        background: i % 2 !== 0 ? '#fafafa' : '#c9c9c9',
                        borderColor: i % 2 !== 0 ? '#ddd' : '#989898',
                        top: 33,
                      }}
                    >
                      CostTot
                    </TableCell>
                    <TableCell
                      align='right'
                      style={{
                        background: i % 2 !== 0 ? '#fafafa' : '#c9c9c9',
                        borderColor: i % 2 !== 0 ? '#ddd' : '#989898',
                        top: 33,
                      }}
                    >
                      Detalles
                    </TableCell>
                  </Fragment>
                );
              })}
          </TableRow>
        </TableHead>
        <TableBody>
          {data &&
            data.transferDetails.rows.map(el => {
              return (
                <TableRow key={el.id}>
                  <TableCell>
                    <Typography variant='body2' noWrap>
                      {el.Product.name}
                    </Typography>
                  </TableCell>
                  <TableCell
                    style={{ textAlign: 'center', background: '#ddd', borderColor: '#989898' }}
                  >
                    <Typography variant='body2' noWrap>
                      {el.Product.Classification.name}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant='body2' noWrap>
                      {el.Product.PresentationBase ? `${el.Product.PresentationBase.name} ` : '-'}
                    </Typography>
                  </TableCell>
                  {el.TransferProducts.map((elCh, ii) => {
                    return (
                      <Fragment key={`${el.id}-${ii}`}>
                        <TableCell
                          align='center'
                          style={{
                            background: ii % 2 !== 0 ? '#fafafa' : '#ddd',
                            borderColor: ii % 2 !== 0 ? '#ddd' : '#989898',
                          }}
                        >
                          {currencyFormat(elCh.quantity)}
                        </TableCell>
                        <TableCell
                          align='right'
                          style={{
                            background: ii % 2 !== 0 ? '#fafafa' : '#ddd',
                            borderColor: ii % 2 !== 0 ? '#ddd' : '#989898',
                          }}
                        >
                          {currencyFormat(elCh.unitCost)}
                        </TableCell>
                        <TableCell
                          align='right'
                          style={{
                            background: ii % 2 !== 0 ? '#fafafa' : '#ddd',
                            borderColor: ii % 2 !== 0 ? '#ddd' : '#989898',
                          }}
                        >
                          {currencyFormat(elCh.cost)}
                        </TableCell>
                        <TableCell
                          align='right'
                          style={{
                            background: ii % 2 !== 0 ? '#fafafa' : '#ddd',
                            borderColor: ii % 2 !== 0 ? '#ddd' : '#989898',
                          }}
                        >
                          {el.Product.name !== 'TOTAL' && (
                            <IconButton
                              label='Ver Detalles'
                              icon={<SearchOutlinedIcon />}
                              params={ii}
                              action={() =>
                                handleDetails({
                                  productId: el.Product.id,
                                  branchTransferId: elCh.Branch.id,
                                  product: el.Product.name,
                                })
                              }
                              size='small'
                            />
                          )}
                        </TableCell>
                      </Fragment>
                    );
                  })}
                </TableRow>
              );
            })}
        </TableBody>
      </Table>
      <Pagination handleRows={handleRows} />
      <TransferProductDialog
        isOpen={isOpenDetails[0]}
        productId={isOpenDetails[1].productId}
        branchTransferId={isOpenDetails[1].branchTransferId}
        product={isOpenDetails[1].product}
        handleClose={handleCloseDetails}
      />
    </>
  );
};

export default TableComponent;
