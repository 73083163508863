var getDefaultindexsChartData = function () { return [
    {
        name: '3-4am',
        index: 0,
        total: 0,
        qty: 0,
    },
    {
        name: '4-5am',
        index: 1,
        total: 0,
        qty: 0,
    },
    {
        name: '5-6am',
        index: 2,
        total: 0,
        qty: 0,
    },
    {
        name: '6-7am',
        index: 3,
        total: 0,
        qty: 0,
    },
    {
        name: '7-8am',
        index: 4,
        total: 0,
        qty: 0,
    },
    {
        name: '8-9am',
        index: 5,
        total: 0,
        qty: 0,
    },
    {
        name: '9-10am',
        index: 6,
        total: 0,
        qty: 0,
    },
    {
        name: '10-11am',
        index: 7,
        total: 0,
        qty: 0,
    },
    {
        name: '11-12am',
        index: 8,
        total: 0,
        qty: 0,
    },
    {
        name: '12-1pm',
        index: 9,
        total: 0,
        qty: 0,
    },
    {
        name: '1-2pm',
        index: 10,
        total: 0,
        qty: 0,
    },
    {
        name: '2-3pm',
        index: 11,
        total: 0,
        qty: 0,
    },
    {
        name: '3-4pm',
        index: 12,
        total: 0,
        qty: 0,
    },
    {
        name: '4-5pm',
        index: 13,
        total: 0,
        qty: 0,
    },
    {
        name: '5-6pm',
        index: 14,
        total: 0,
        qty: 0,
    },
    {
        name: '6-7pm',
        index: 15,
        total: 0,
        qty: 0,
    },
    {
        name: '7-8pm',
        index: 16,
        total: 0,
        qty: 0,
    },
    {
        name: '8-9pm',
        index: 17,
        total: 0,
        qty: 0,
    },
    {
        name: '9-10pm',
        index: 18,
        total: 0,
        qty: 0,
    },
    {
        name: '10-11pm',
        index: 19,
        total: 0,
        qty: 0,
    },
    {
        name: '11-12am',
        index: 20,
        total: 0,
        qty: 0,
    },
    {
        name: '12-1am',
        index: 21,
        total: 0,
        qty: 0,
    },
    {
        name: '1-2am',
        index: 22,
        total: 0,
        qty: 0,
    },
    {
        name: '2-3am',
        index: 23,
        total: 0,
        qty: 0,
    },
]; };
export default getDefaultindexsChartData;
