var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import useApolloLazyQuery from '../../../hooks/useApolloLazyQuery';
import GQL_SURVEY from '../../../apollo/gql/survey';
import LoadingProgress from '../../../presentationals/LoadingProgress';
import ErrorDataComponent from '../../../presentationals/ErrorData';
import FooterDialogComponent from '../../../presentationals/Dialog/Footer';
import CreateApplySurveyComponent from './CreateButton';
import ItemApplySurveyComponent from './Item';
import CustomerFieldComponent from '../../../presentationals/FormComponents/CustomerField';
import useQueryParams from '../../../hooks/useQueryParams';
var ApplySurveyComponent = function () {
    var customerFieldRef = useRef(null);
    var params = useParams();
    var query = useQueryParams();
    var _a = useState(undefined), customerId = _a[0], setCustomerId = _a[1];
    var _b = useState({}), values = _b[0], setValues = _b[1];
    var _c = useApolloLazyQuery(GQL_SURVEY.GET_SURVEY_BY_ID), getData = _c[0], _d = _c[1], loading = _d.loading, data = _d.data;
    useEffect(function () {
        if (params.surveyId)
            getData({
                variables: {
                    id: params.surveyId,
                },
            });
    }, [params]);
    var handleChange = function (id, value) {
        setValues(function (values) {
            var _a;
            return (__assign(__assign({}, values), (_a = {}, _a[id] = value, _a)));
        });
    };
    var handleCustomerChange = function (name, value) {
        setCustomerId(value);
    };
    var handleCreate = function () {
        var _a;
        setValues({});
        (_a = customerFieldRef.current) === null || _a === void 0 ? void 0 : _a.handleClear();
    };
    if (loading)
        return React.createElement(LoadingProgress, null);
    if (!data)
        return React.createElement(ErrorDataComponent, null);
    return (React.createElement("div", { className: 'flex flex-1 flex-col basis-0 overflow-auto' },
        React.createElement("div", { className: 'grid grid-cols-1 sm:grid-cols-3 flex-1' },
            React.createElement("div", { className: 'flex flex-1 flex-col px-4 py-3' },
                React.createElement("div", { className: 'text-xl' }, data.name),
                data.customer && (React.createElement(CustomerFieldComponent, { ref: customerFieldRef, defaultValue: query.get('customerId') || undefined, onChange: handleCustomerChange })),
                data.SurveyQuestions.map(function (el) { return (React.createElement(ItemApplySurveyComponent, { key: el.id, label: el.question, type: el.type, onChange: handleChange, id: el.id, value: values[el.id] })); }))),
        React.createElement(FooterDialogComponent, null,
            React.createElement("div", null),
            React.createElement(CreateApplySurveyComponent, { customerId: customerId, answers: values, onCreate: handleCreate }))));
};
export default ApplySurveyComponent;
