import React, { useState, useEffect } from 'react';

import { useReactiveVar } from '@apollo/client';

import Grid from '@mui/material/Grid';

import { clearForm, form, step, handleContinue } from '../../../../apollo/cache';

import TextField from '../../../../presentationals/FormComponents/TextField';
import DateField from '../../../../presentationals/FormComponents/DateField';
import SelectField from '../../../../presentationals/FormComponents/SelectField';

import { selectpaymentFormOptions } from '../../../../helpers/paymentForm.options';

const paymentMethodOptions = [
  { value: 1, label: 'Contado' },
  { value: 2, label: 'Crédito' },
];

const defaultValues = {
  pursacheDate: new Date(),
  subFolio: '',
  paymentForm: '01',
  type: 1,
  description: '',
};

const MainComponent = () => {
  const [values, setValues] = useState(defaultValues);
  const [errors, setErrors] = useState({});

  const clearFormVar = useReactiveVar(clearForm);
  const stepVar = useReactiveVar(step);
  const handleContinueVar = useReactiveVar(handleContinue);

  const mainFormValidation = () => {
    try {
      setErrors({});
      if (values.subFolio === '') {
        const e = new Error('Proporcione un folio para su compra.');
        e.name = 'subFolio';
        throw e;
      }
      step(stepVar + 1);
      form(values);
    } catch (e) {
      setErrors({ [e.name]: e.message });
    }
  };

  useEffect(() => {
    if (stepVar === 0 && handleContinueVar) {
      mainFormValidation();
      handleContinue(false);
    }
  }, [stepVar, handleContinueVar]);

  useEffect(() => {
    if (clearFormVar && stepVar === 0) {
      setValues(defaultValues);
      form({});
      clearForm(false);
    }
  }, [clearFormVar]);

  const handleChange = (name, value) => {
    setValues(values => ({ ...values, [name]: value }));
  };

  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={12} md={6}>
          <DateField
            name='pursacheDate'
            label='Selecciona la fecha de compra'
            onChange={handleChange}
            value={values.pursacheDate}
            error={errors.pursacheDate}
          />
        </Grid>
      </Grid>
      <Grid container spacing={1}>
        <Grid item xs={12} md={6}>
          <TextField
            name='subFolio'
            label='Escribe el folio de tu compra'
            onChange={handleChange}
            value={values.subFolio}
            error={errors.subFolio}
          />
        </Grid>
      </Grid>
      <Grid container spacing={1}>
        <Grid item xs={12} md={6}>
          <SelectField
            name='paymentForm'
            label='Proporciona la forma de pago'
            onChange={handleChange}
            options={selectpaymentFormOptions}
            value={values.paymentForm}
            error={errors.paymentForm}
          />
        </Grid>
      </Grid>
      <Grid container spacing={1}>
        <Grid item xs={12} md={6}>
          <SelectField
            name='type'
            label='Método de pago'
            onChange={handleChange}
            options={paymentMethodOptions}
            value={values.type}
            error={errors.type}
          />
        </Grid>
      </Grid>
      <Grid container spacing={1}>
        <Grid item xs={12} md={6}>
          <TextField
            name='description'
            label='¿Tienes alguna anotación para esta compra?'
            value={values.description}
            error={errors.description}
            multiline
            minRows={4}
            onChange={handleChange}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default MainComponent;
