var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { gql } from '@apollo/client';
import FRAGMENT_MANUFACTURE from './fragmentManufacture';
import FRAGMENT_PRODUCT_INVENTORY from '../productInventory/fragmentProductInventory';
var CREATE_MANUFACTURE = gql(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  ", "\n  ", "\n  mutation CreateManufacture(\n    $branchId: ID\n    $waste: String!\n    $Products: [ProductsManufactureData]\n    $ProductsToManufacture: [ProductsManufactureData]\n  ) {\n    createManufacture(\n      input: {\n        branchId: $branchId\n        waste: $waste\n        Products: $Products\n        ProductsToManufacture: $ProductsToManufacture\n      }\n    ) {\n      ...FragmentManufacture\n      ProductInventories {\n        ...FragmentProductInventory\n      }\n    }\n  }\n"], ["\n  ", "\n  ", "\n  mutation CreateManufacture(\n    $branchId: ID\n    $waste: String!\n    $Products: [ProductsManufactureData]\n    $ProductsToManufacture: [ProductsManufactureData]\n  ) {\n    createManufacture(\n      input: {\n        branchId: $branchId\n        waste: $waste\n        Products: $Products\n        ProductsToManufacture: $ProductsToManufacture\n      }\n    ) {\n      ...FragmentManufacture\n      ProductInventories {\n        ...FragmentProductInventory\n      }\n    }\n  }\n"])), FRAGMENT_MANUFACTURE, FRAGMENT_PRODUCT_INVENTORY);
export default CREATE_MANUFACTURE;
var templateObject_1;
