import React from 'react';
import { useReactiveVar } from '@apollo/client';
import DialogComponent from '../../../presentationals/Dialog';
import { isCreateFormOpen } from '../../../apollo/cache';
import FooterCreateCompanyComponent from './Footer';
import BodyCreateCompanyComponent from './Body';
var CreateCompanyComponent = function () {
    var isOpen = useReactiveVar(isCreateFormOpen);
    var handleClose = function () {
        isCreateFormOpen(false);
    };
    return (React.createElement(DialogComponent, { title: 'Nueva empresa', fullScreen: true, open: isOpen, onClose: handleClose },
        React.createElement(BodyCreateCompanyComponent, null),
        React.createElement(FooterCreateCompanyComponent, null)));
};
export default CreateCompanyComponent;
