import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { dateFormat } from '../../../../helpers';
import DateRangeCalendarFieldComponent from './Calendar';
import getDefaultDateRangeSalesGlobalInvoice from './helpers/getDefaultDateRangeSalesGlobalInvoice';
var DateRangeSalesGlobalInvoiceFieldComponent = forwardRef(function (props, ref) {
    var _a = useState(true), isFirstTime = _a[0], setIsFirstTime = _a[1];
    var defaultValues = getDefaultDateRangeSalesGlobalInvoice();
    var dateRangeCalendarFieldRef = useRef(null);
    var _b = useState(defaultValues), value = _b[0], setValue = _b[1];
    useEffect(function () {
        if (props.defaultValues && isFirstTime) {
            setValue(props.defaultValues);
            setIsFirstTime(false);
        }
    }, [props.defaultValues]);
    var handleClick = function (event) {
        var _a;
        if (props.disabled)
            return;
        (_a = dateRangeCalendarFieldRef.current) === null || _a === void 0 ? void 0 : _a.open(event.currentTarget);
    };
    var handleChange = function (start, end) {
        setValue([start, end]);
        props.onChange && props.onChange(start, end);
    };
    var handleGetDate = function () { return value; };
    useImperativeHandle(ref, function () { return ({
        getDate: handleGetDate,
    }); });
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { role: 'button', tabIndex: -1, onClick: handleClick, className: 'flex flex-row items-center justify-center cursor-pointer' },
            React.createElement("div", { className: 'flex flex-col hover:bg-[#ececec] rounded px-2 py-1' },
                React.createElement("div", { className: 'text-xs text-gray-600 leading-none tracking-tighter' }, "Del"),
                React.createElement("div", { className: 'leading-none tracking-tighter text-gray-800' }, dateFormat(value[0]))),
            React.createElement("div", { className: 'ml-2 flex flex-col hover:bg-[#ececec] rounded px-2 py-1' },
                React.createElement("div", { className: 'text-xs text-gray-600 leading-none tracking-tighter' }, "Al"),
                React.createElement("div", { className: 'leading-none tracking-tighter text-gray-800' }, dateFormat(value[1])))),
        React.createElement(DateRangeCalendarFieldComponent, { ref: dateRangeCalendarFieldRef, onChange: handleChange })));
});
export default DateRangeSalesGlobalInvoiceFieldComponent;
