import React from 'react';
import { Button } from '@mui/material';
import SendSaleByEmailDialogComponent from '../../../../../presentationals/SendSaleByEmailDialog';
import { isSendSaleByEmailOpen } from '../../../../../apollo/cache';
var SendEmailSaleButtonComponent = function (props) {
    var handleClick = function () {
        isSendSaleByEmailOpen(props.saleId);
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(Button, { variant: 'outlined', color: 'primary', className: 'w-full py-4', onClick: handleClick }, props.label),
        React.createElement(SendSaleByEmailDialogComponent, null)));
};
export default SendEmailSaleButtonComponent;
