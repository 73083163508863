import React from 'react';
import { Alert } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { isCreatePresentationProductOpen } from '../../../../../apollo/cache';
var NoPresentationsAlertEditProductComponent = function (props) {
    var navigate = useNavigate();
    var handleOpenConfigRates = function (e) {
        e.preventDefault();
        navigate("".concat(props.PresentationBase.id, "/rate"));
    };
    var handleOpenConfigComponent = function (e) {
        e.preventDefault();
        navigate("".concat(props.PresentationBase.id, "/component"));
    };
    var handleOpenCreatePresentation = function (e) {
        e.preventDefault();
        isCreatePresentationProductOpen(true);
    };
    return (React.createElement(Alert, { severity: 'info', className: 'w-full sm:w-1/2' },
        "De momento, no cuentas con presentaciones registradas, esto significa que comercializar\u00E1s el producto en su unidad de medida base (",
        props.PresentationBase.name,
        ").",
        React.createElement("br", null),
        "Si lo deseas, puedes",
        ' ',
        React.createElement("a", { href: '#', className: 'text-black font-medium', onClick: handleOpenConfigRates }, "configurar tarifas"),
        ",",
        ' ',
        React.createElement("a", { href: '#', className: 'text-black font-medium', onClick: handleOpenConfigComponent }, "asignar componentes a este producto"),
        ' ',
        "o",
        ' ',
        React.createElement("a", { href: '#', className: 'text-black font-medium', onClick: handleOpenCreatePresentation }, "crear nuevas presentaciones"),
        "."));
};
export default NoPresentationsAlertEditProductComponent;
