import React, { useState, useEffect } from 'react';
import { useLazyQuery } from '@apollo/client';

import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';

import { currencyFormat } from '../../helpers/general';
import FormDialog from '../../presentationals/FormDialog';
import GQL from '../purchaseOrder.v2/_gql';

const defaultTotals = {
  subtotal: 0,
  discount: 0,
  taxes: 0,
  total: 0,
};

const Component = ({ purchaseOrderId, ...props }) => {
  const [products, setProducts] = useState([]);
  const [totals, setTotals] = useState(defaultTotals);

  const [getProducts, { data: purchaseOrderData }] = useLazyQuery(GQL.GET_BY_ID, {
    onCompleted: data => {
      setProducts(data.purchaseOrder.PurchaseOrderDetails);
      setTotals({
        ...defaultTotals,
        subtotal: data.purchaseOrder.subtotal,
        discount: data.purchaseOrder.discount,
        taxes: data.purchaseOrder.taxes,
        total: data.purchaseOrder.total,
      });
    },
  });

  useEffect(() => {
    if (purchaseOrderId !== null) getProducts({ variables: { id: purchaseOrderId } });
  }, [purchaseOrderId]); // eslint-disable-line

  return (
    <FormDialog
      title='Productos'
      isOpen={props.isOpen || false}
      handleClose={() => props.handleClose()}
      hideAction
      width='md'
    >
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Producto</TableCell>
            <TableCell>Unidad</TableCell>
            <TableCell>Cant.</TableCell>
            <TableCell align='center'>Precio Unitario</TableCell>
            <TableCell align='center'>Subtotal</TableCell>
            <TableCell align='center'>Descuento</TableCell>
            <TableCell align='right'>Total</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {products.map(el => (
            <TableRow hover key={el.id}>
              <TableCell>{el.Product ? el.Product.name : el.concept}</TableCell>
              <TableCell>{el.Product ? el.ProductPresentation.name : '-'}</TableCell>
              <TableCell>{el.quantity}</TableCell>
              <TableCell align='center'>{currencyFormat(el.unitPrice)}</TableCell>
              <TableCell align='center'>{currencyFormat(el.subtotal)}</TableCell>
              <TableCell align='center'>{currencyFormat(el.discount)}</TableCell>
              <TableCell align='right'>{currencyFormat(el.total)}</TableCell>
            </TableRow>
          ))}
          <TableRow>
            <TableCell colSpan={6} align='right'>
              Subtotal:
            </TableCell>
            <TableCell align='right'>{currencyFormat(totals.subtotal)}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell colSpan={6} align='right'>
              Dscto:
            </TableCell>
            <TableCell align='right'>{currencyFormat(totals.discount)}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell colSpan={6} align='right'>
              Impuestos:
            </TableCell>
            <TableCell align='right'>{currencyFormat(totals.taxes)}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell colSpan={6} align='right'>
              <strong>Total:</strong>
            </TableCell>
            <TableCell align='right'>
              <strong>{currencyFormat(totals.total)}</strong>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
      <Grid item xs={12}>
        <Typography variant='subtitle1'>Comentario:</Typography>
        {purchaseOrderData && purchaseOrderData.purchaseOrder.description}
      </Grid>
    </FormDialog>
  );
};

export default Component;
