import React, { useRef } from 'react';
import FooterDialogComponent from '../../../presentationals/Dialog/Footer';
import ActionCashFlowButtonComponent from './ActionButton';
import BodyFormCashFlowComponent from './Body';
import DeleteCashFlowFooterActionComponent from './Delete';
var FormCashFlowComponent = function (props) {
    var bodyFormCashFlowRef = useRef(null);
    var actionCashFlowButtonRef = useRef(null);
    var handleGetValues = function () { var _a; return (_a = bodyFormCashFlowRef.current) === null || _a === void 0 ? void 0 : _a.getValues(); };
    var handleCreate = function () {
        var _a;
        (_a = actionCashFlowButtonRef.current) === null || _a === void 0 ? void 0 : _a.onPressEnter();
    };
    return (React.createElement("div", { className: 'flex flex-1 flex-col' },
        React.createElement(BodyFormCashFlowComponent, { onPressEnter: handleCreate, defaultValues: props.defaultValues, ref: bodyFormCashFlowRef }),
        React.createElement(FooterDialogComponent, null,
            props.defaultValues ? React.createElement(DeleteCashFlowFooterActionComponent, null) : React.createElement("div", null),
            React.createElement(ActionCashFlowButtonComponent, { loading: props.loading, getValues: handleGetValues, label: props.Button.label, onClick: props.Button.onClick, ref: actionCashFlowButtonRef }))));
};
export default FormCashFlowComponent;
