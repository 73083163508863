var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { useReactiveVar } from '@apollo/client';
import FieldContainerProductIngredientComponent from '../../../../../product/ProductPresentation/Component/Create/Body/FieldContainer';
import DateFieldComponent from '../../../../../../presentationals/FormComponents/DateField';
import SelectPaymentFormFieldComponent from '../../../../../../presentationals/FormComponents/SelectPaymentFormField';
import SelectTransactionAccountFieldComponent from '../../../../../../presentationals/FormComponents/SelectTransactionAccountField';
import CreateTransactionAccountButtonComponent from '../../../../../../presentationals/FormComponents/SelectTransactionAccountField/AddButton';
import SelectCurrencyFieldComponent from '../../../../../../presentationals/FormComponents/SelectCurrencyField';
import TextFieldComponent from '../../../../../../presentationals/FormComponents/TextField';
import getDefaultPaymentValues from './helpers/getDefaultValues';
import { inputError } from '../../../../../../apollo/cache';
var PaymentFormMultiPaymentDialogComponent = forwardRef(function (props, ref) {
    var errors = useReactiveVar(inputError);
    var defaultValues = getDefaultPaymentValues();
    var _a = useState(defaultValues), values = _a[0], setValues = _a[1];
    useEffect(function () {
        if (props.showPaymentField && props.totalPayment)
            setValues(function (values) { return (__assign(__assign({}, values), { total: props.totalPayment ? props.totalPayment.toString() : '' })); });
    }, [props.showPaymentField, props.totalPayment]);
    var handleDateChange = function (name, value) {
        setValues(function (values) {
            var _a;
            return (__assign(__assign({}, values), (_a = {}, _a[name] = value, _a)));
        });
    };
    var handleChange = function (name, value) {
        setValues(function (values) {
            var _a;
            return (__assign(__assign({}, values), (_a = {}, _a[name] = value, _a)));
        });
    };
    var getData = function () {
        return values;
    };
    useImperativeHandle(ref, function () { return ({
        getData: getData,
    }); });
    return (React.createElement(React.Fragment, null,
        React.createElement(FieldContainerProductIngredientComponent, null,
            React.createElement(DateFieldComponent, { label: 'Fecha del pago', name: 'date', onChange: handleDateChange, value: values.date, error: errors.date })),
        React.createElement(FieldContainerProductIngredientComponent, null,
            React.createElement(SelectPaymentFormFieldComponent, { defaultValue: values.paymentFormId, onChange: handleChange })),
        props.showPaymentField && (React.createElement(FieldContainerProductIngredientComponent, null,
            React.createElement(TextFieldComponent, { name: 'total', label: 'Importe a abonar', value: values.total, onChange: handleChange, error: errors.total }))),
        React.createElement("div", { className: 'flex flex-row items-end' },
            React.createElement(SelectTransactionAccountFieldComponent, { defaultValue: values.transactionAccountId, onChange: handleChange }),
            React.createElement(CreateTransactionAccountButtonComponent, null)),
        React.createElement(FieldContainerProductIngredientComponent, null,
            React.createElement(SelectCurrencyFieldComponent, { defaultValue: values.currency, onChange: handleChange })),
        React.createElement(FieldContainerProductIngredientComponent, null,
            React.createElement(TextFieldComponent, { name: 'exchangeRate', label: 'Tipo de cambio', disabled: values.currency === 'MXN', value: values.currency === 'MXN' ? '1' : values.exchangeRate, onChange: handleChange, error: errors.exchangeRate, type: 'number' }))));
});
export default PaymentFormMultiPaymentDialogComponent;
