var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { useReactiveVar } from '@apollo/client';
import SubtitleDialogComponent from '../../../../presentationals/Dialog/helpers/Subtitle';
import TextFieldComponent from '../../../../presentationals/FormComponents/TextField';
import { inputError } from '../../../../apollo/cache';
import getDefaultRoleForm from '../../helpers/getDefaultBranchValues';
export var typeOptions = [
    { value: 1, label: 'Punto de venta' },
    { value: 2, label: 'Almacen' },
    { value: 3, label: 'Franquicia' },
];
var BodyRoleComponent = forwardRef(function (props, ref) {
    var defaultValues = getDefaultRoleForm();
    var _a = useState(defaultValues), values = _a[0], setValues = _a[1];
    var errors = useReactiveVar(inputError);
    useEffect(function () {
        if (props.defaultValues)
            setValues(getDefaultRoleForm(props.defaultValues));
    }, [props.defaultValues]);
    var handleChange = function (name, value) {
        var _a;
        setValues(__assign(__assign({}, values), (_a = {}, _a[name] = value, _a)));
    };
    var handleKeyPress = function (e) {
        if (e.key === 'Enter') {
            e.preventDefault();
            props.onPressEnter && props.onPressEnter();
        }
    };
    var getValues = function () { return values; };
    var handleClear = function () {
        setValues(defaultValues);
    };
    useImperativeHandle(ref, function () { return ({
        getValues: getValues,
        onClear: handleClear,
    }); });
    return (React.createElement("div", { className: 'flex-1 px-6 py-4 flex flex-col md:grid md:grid-cols-4 md:gap-4 overflow-auto' },
        React.createElement("div", { className: 'mb-4 md:mb-0' },
            React.createElement(SubtitleDialogComponent, { label: 'Datos generales' }),
            React.createElement(TextFieldComponent, { label: 'Nombre', name: 'name', value: values.name, error: errors.name, onChange: handleChange, onKeyDown: handleKeyPress }))));
});
export default BodyRoleComponent;
