import React, { useState, useEffect } from 'react';

import format from 'date-fns/format';
import localeEs from 'date-fns/locale/es';

const TimeComponent = props => {
  const [time, setTime] = useState(new Date());

  function tick() {
    setTime(new Date());
  }

  useEffect(() => {
    const timerID = setInterval(() => tick(), 1000);
    return function cleanup() {
      clearInterval(timerID);
    };
  });

  return (
    <div className={props.className}>
      {format(time, props.format || 'h:mm:ss aaa', { locale: localeEs })}
    </div>
  );
};

export default TimeComponent;
