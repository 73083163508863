import React, { useEffect, useState } from 'react';
import { Divider } from '@mui/material';
import ChecklistIcon from '@mui/icons-material/Checklist';
import GQL_COMPANY from '../../../../../../apollo/gql/company';
import useApolloQuery from '../../../../../../hooks/useApolloQuery';
import TitleDrawerMenuDashboardComponent from '../../helpers/Title';
import QuoteLinkBlockOneMenuDashboardComponent from '../../BlockOne/QuoteLink';
import ServiceOrderLinkBlockOneMenuDashboardComponent from '../../BlockOne/ServiceOrderLink';
import MaintenanceLinkBlockOneMenuDashboardComponent from '../../BlockOne/MaintenanceLink';
import RentLinkBlockTwoListItemsMenuDashboardComponent from '../RentLink';
import useGetSessionRole from '../../../../../../hooks/useGetSessionRole';
var TrackingSectionMenuComponent = function (props) {
    var _a, _b, _c, _d, _e, _f, _g, _h;
    var _j = useState(false), show = _j[0], setShow = _j[1];
    var _k = useGetSessionRole(), data = _k.data, isOwner = _k.isOwner;
    var allowedModules = useApolloQuery(GQL_COMPANY.GET_ALLOWED_MODULES).data;
    useEffect(function () {
        if (allowedModules) {
            var allowedModuleKeys = Object.keys(allowedModules);
            var show_1 = allowedModuleKeys
                .filter(function (key) { return ['maintenance', 'serviceOrder', 'rent', 'quotation'].includes(key); })
                .reduce(function (show, key) {
                var _a, _b;
                return allowedModules && ((_a = allowedModules[key]) === null || _a === void 0 ? void 0 : _a.isAllowed) === true
                    ? !!((_b = allowedModules[key]) === null || _b === void 0 ? void 0 : _b.isAllowed)
                    : show;
            }, false);
            setShow(show_1);
        }
    }, [allowedModules]);
    if (!show)
        return React.createElement("div", null);
    return (React.createElement(React.Fragment, null,
        React.createElement(TitleDrawerMenuDashboardComponent, { icon: React.createElement(ChecklistIcon, null), title: 'Seguimientos', posModule: props.posModule }),
        (isOwner || !!((_b = (_a = data.Permissions) === null || _a === void 0 ? void 0 : _a.Quotation) === null || _b === void 0 ? void 0 : _b.getQuotations)) && (React.createElement(QuoteLinkBlockOneMenuDashboardComponent, { posModule: props.posModule })),
        (isOwner || !!((_d = (_c = data.Permissions) === null || _c === void 0 ? void 0 : _c.ServiceOrder) === null || _d === void 0 ? void 0 : _d.getServiceOrder)) && (React.createElement(ServiceOrderLinkBlockOneMenuDashboardComponent, { posModule: props.posModule })),
        (isOwner || !!((_f = (_e = data.Permissions) === null || _e === void 0 ? void 0 : _e.Rent) === null || _f === void 0 ? void 0 : _f.getRent)) && (React.createElement(RentLinkBlockTwoListItemsMenuDashboardComponent, { posModule: props.posModule })),
        (isOwner || !!((_h = (_g = data.Permissions) === null || _g === void 0 ? void 0 : _g.Maintenance) === null || _h === void 0 ? void 0 : _h.getMaintenance)) && (React.createElement(MaintenanceLinkBlockOneMenuDashboardComponent, { posModule: props.posModule })),
        [1, 3].includes(props.posModule) && React.createElement(Divider, { className: 'my-2' })));
};
export default TrackingSectionMenuComponent;
