import React, { useState } from 'react';
import FolderOpenOutlinedIcon from '@mui/icons-material/FolderOpenOutlined';
import Container from '../../presentationals/Module.v2/Container';

import Action from './_action';
import Filter from './_filter';
import ZipDialog from './zip.dialog';
import GQL_INVOICE from '../../apollo/gql/invoice';
import getSearchSaleByOptions from '../../helpers/getSearchSaleByOptions';

const Rows = [
  { label: 'Fecha', key: 'createdAt', sort: 'text', format: 'dateTime' },
  { label: 'Folio', key: 'folio', strcat: '' },
  { label: 'UUID', key: 'uuid' },
  { label: 'Método', key: 'paymentMethod' },
  { label: 'Estatus', key: 'status', sort: 'text', format: 'orderStatus' },
  { label: 'RFC', key: 'identifier', filter: true },
  { label: 'Razón social', key: 'businessName', filter: true },
  { label: 'Forma de pago', key: 'paymentForm', sort: 'text' },
  { label: 'Descuento', key: 'discount', sort: 'text', format: 'currency' },
  { label: 'Subtotal', key: 'subtotal', sort: 'text', format: 'currency' },
  { label: 'IVA', key: 'taxes', sort: 'text', format: 'currency' },
  { label: 'Total', key: 'total', sort: 'text', format: 'currency' },
  { label: 'Comentario', key: 'commentary', sort: 'text' },
  { label: 'Cancelado el', key: 'deletedAt', sort: 'text' },
  { label: 'Sucursal', key: 'branch', sort: 'text' },
  { label: 'Empleado', key: 'employee', sort: 'text' },
];
const title = 'Facturación electrónica';
const operation = ['invoice', 'invoices'];

const InvoiceReportV2Component = () => {
  const searchByOptions = getSearchSaleByOptions();
  const [isOpen, setIsOpen] = useState(false);
  const handdleOpenDowloadZip = () => setIsOpen(true);
  const handleCloseDowloadZip = () => setIsOpen(false);
  return (
    <>
      <Container
        title={title}
        searchFieldOptions={{
          name: 'searchSaleBy',
          options: [
            ...searchByOptions.filter(el => el.value !== 'seller'),
            { value: 'total', label: 'Importe' },
          ],
          defaultValue: 'folio',
        }}
        operation={operation}
        Rows={Rows}
        get={GQL_INVOICE.GET_INVOICES}
        noDelete
        filter
        additionalAction={{
          handle: handdleOpenDowloadZip,
          label: 'Exportar | ZIP',
          icon: <FolderOpenOutlinedIcon />,
        }}
      />
      <Action />
      <Filter />
      <ZipDialog isOpen={isOpen} handleClose={handleCloseDowloadZip} />
    </>
  );
};

export default InvoiceReportV2Component;
