import React from 'react';

import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';

import { dateFormat, hourFormat, customerCheckTime } from '../../../helpers';

const ThirdStepComponent = props => {
  const eventStatus =
    props.eventCheckIn && props.eventStartDate
      ? customerCheckTime(props.eventCheckIn, props.eventStartDate)
      : [false, ''];
  return (
    <>
      <Typography variant='h5' gutterBottom className='text-center'>
        {`Bienvenido, ${props.customer}`}
      </Typography>
      <Typography variant='subtitle1' color='textSecondary' className='mb-2 text-center'>
        Nuestro operador lo acompañará al cubículo correspondiente.
      </Typography>
      <Paper variant='outlined' className='py-2 w-full'>
        <Grid container alignItems='center' spacing={1}>
          <Grid item xs={5}>
            <Typography variant='overline' display='block' className='text-sm ml-2'>
              Motivo de visita
            </Typography>
          </Grid>
          <Grid item xs={7}>
            <Typography variant='overline' display='block' className='text-base'>
              {props.tag}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={5}>
            <Typography variant='overline' display='block' className='text-sm ml-2'>
              Fecha programada
            </Typography>
          </Grid>
          <Grid item xs={7}>
            <Typography variant='overline' display='block' className='text-base'>
              {props.eventStartDate &&
                `${dateFormat(new Date(props.eventStartDate))} ${hourFormat(
                  new Date(props.eventStartDate),
                )}`}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={5}>
            <Typography variant='overline' display='block' className='text-sm ml-2'>
              Fecha de asistencia
            </Typography>
          </Grid>
          <Grid item xs={7}>
            <Typography variant='overline' display='block' className='text-base'>
              {props.eventCheckIn &&
                `${dateFormat(props.eventCheckIn)} ${hourFormat(props.eventCheckIn)}`}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            <Typography
              variant='overline'
              display='block'
              className={
                eventStatus[0]
                  ? 'font-semibold text-[#b10000] text-2xl ml-2 text-center'
                  : 'font-semibold text-black text-2xl ml-2 text-center'
              }
            >
              {eventStatus[1]}
            </Typography>
          </Grid>
        </Grid>
      </Paper>
    </>
  );
};

export default ThirdStepComponent;
