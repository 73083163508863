var getPlatformOptions = function () { return [
    { value: 1, label: 'En tienda' },
    { value: 2, label: 'Ordene y recoja' },
    { value: 3, label: 'Domicilio' },
    { value: 4, label: 'Drive' },
    { value: 5, label: 'Didi food' },
    { value: 6, label: 'Rappi' },
    { value: 7, label: 'Uber eats' },
]; };
export default getPlatformOptions;
