var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import ButtonFooterDialogComponent from '../../../../../../../presentationals/Dialog/Footer/Button';
import getInputErrorFormat from '../../../../../../sale.v2/Action/helpers/getInputErrorFormat';
import { inputError } from '../../../../../../../apollo/cache';
import setInputErrorFormat from '../../../../../../sale.v2/Action/helpers/setInputErrorFormat';
var ActionCurrencyCreatePurchaseOrderButtonComponent = function (props) {
    var handleClick = function () {
        try {
            inputError({});
            var values = props.getValues();
            if (!values)
                return;
            if (values.exchangeRate === '' ||
                isNaN(parseFloat(values.exchangeRate)) ||
                parseFloat(values.exchangeRate) <= 0)
                throw new Error(setInputErrorFormat('exchangeRate', 'Proporciona el tipo de cambio para tu moneda'));
            props.onClick(__assign(__assign({}, values), { exchangeRate: values.currencyKey === 'MXN' ? '1' : values.exchangeRate }));
        }
        catch (error) {
            getInputErrorFormat(error);
        }
    };
    return (React.createElement(ButtonFooterDialogComponent, { onClick: handleClick }, "Actualizar moneda"));
};
export default ActionCurrencyCreatePurchaseOrderButtonComponent;
