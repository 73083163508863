import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { Menu } from '@mui/material';
import ItemSearchCustomerByFieldComponent from './Item';
import getSearchCustomerByOptions from '../../../../../../../../helpers/getSearchCustomerByOptions';
var MenuSearchCustomerByGlobalInvoiceFieldComponent = forwardRef(function (props, ref) {
    var searchCustomerByOptions = getSearchCustomerByOptions();
    var _a = useState(null), anchorEl = _a[0], setAnchorEl = _a[1];
    var handleOpen = function (anchorEl) {
        setAnchorEl(anchorEl);
    };
    var handleClose = function () {
        setAnchorEl(null);
    };
    var handleClick = function (name, value) {
        props.onChange(name, value);
        handleClose();
    };
    useImperativeHandle(ref, function () { return ({
        open: handleOpen,
    }); });
    return (React.createElement(Menu, { id: 'SearchCustomerByFieldMenu', anchorEl: anchorEl, open: !!anchorEl, onClose: handleClose, anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
        }, transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
        } }, Object.keys(searchCustomerByOptions).map(function (key) { return (React.createElement(ItemSearchCustomerByFieldComponent, { key: key, name: searchCustomerByOptions[key], value: key, onClick: handleClick })); })));
});
export default MenuSearchCustomerByGlobalInvoiceFieldComponent;
