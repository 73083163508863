import React, { forwardRef, useImperativeHandle, useRef } from 'react';
import FooterDialogComponent from '../../../../../../presentationals/Dialog/Footer';
import ClearButtonFooterCreateFormCustomerActionSaleV2Component from './Footer/ClearButton';
import CreateButtonFooterCreateFormCustomerActionSaleV2Component from './Footer/CreateButton';
import BodyCreateFormCustomerActionSaleV2Component from './Body';
import DeleteButtonFooterCreateFormCustomerActionSaleV2Component from './Footer/DeleteButton';
import EditButtonGeneralEditCustomerComponent from '../../../../../customer/Edit/EditButton';
var CreateFormCustomerActionSaleV2Component = forwardRef(function (props, ref) {
    var _a;
    var bodyCustomerRef = useRef(null);
    var editButtonCustomerRef = useRef(null);
    var createButtonFooterCreateFormCustomerActionSaleV2Ref = useRef(null);
    var handleClear = function () {
        var _a;
        return (_a = bodyCustomerRef.current) === null || _a === void 0 ? void 0 : _a.onClear();
    };
    var handleGetValues = function () {
        var _a;
        return (_a = bodyCustomerRef.current) === null || _a === void 0 ? void 0 : _a.getValues();
    };
    var handleCreate = function () {
        var _a;
        return (_a = createButtonFooterCreateFormCustomerActionSaleV2Ref.current) === null || _a === void 0 ? void 0 : _a.onPressEnter();
    };
    useImperativeHandle(ref, function () { return ({
        onClear: handleClear,
    }); });
    return (React.createElement(React.Fragment, null,
        React.createElement(BodyCreateFormCustomerActionSaleV2Component, { ref: bodyCustomerRef, defaultValues: props.defaultValues, onPressEnter: handleCreate }),
        React.createElement(FooterDialogComponent, null,
            React.createElement("div", null,
                React.createElement(DeleteButtonFooterCreateFormCustomerActionSaleV2Component, null)),
            React.createElement("div", null,
                React.createElement(ClearButtonFooterCreateFormCustomerActionSaleV2Component, { onClick: handleClear }),
                ((_a = props.defaultValues) === null || _a === void 0 ? void 0 : _a.id) ? (React.createElement(EditButtonGeneralEditCustomerComponent, { getValues: handleGetValues, ref: editButtonCustomerRef, customerId: props.defaultValues.id })) : (React.createElement(CreateButtonFooterCreateFormCustomerActionSaleV2Component, { getValues: handleGetValues, noReturn: true }))))));
});
export default CreateFormCustomerActionSaleV2Component;
