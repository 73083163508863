import jsPDF from 'jspdf';

import { gql } from '@apollo/client';
import { format, parseISO } from 'date-fns';
import client from '../../apollo';

import { currencyFormat } from '../../helpers';
import GQL_PRINT from '../../containers/print/_gql';
import getSession from '../../helpers/getSession';
import GQL_COMPANY from '../../apollo/gql/company';

const GET_BY_ID = gql`
  query CashClosingTotals($id: ID!) {
    cashClosingTotals(id: $id) {
      id
      pettyCashAmount
      realBalance
      virtualBalance
      difference
      clientCreatedAt
      closedAt
      finalBalance
      Sales {
        sale {
          count
          total
        }
        discount {
          count
          total
        }
        shipping {
          count
          total
        }
        cancel {
          count
          total
        }
      }
      CashFlows {
        total
      }
      Transactions {
        name
        quantity
        total
      }
      CashClosingEmployee {
        firstName
        lastName
      }
      Branch {
        name
      }
    }
  }
`;

const getCashClosing = async id => {
  try {
    const { data } = await client.mutate({
      mutation: GET_BY_ID,
      variables: { id },
    });
    return data.cashClosingTotals;
  } catch (e) {
    console.log(e);
    return e;
  }
};

const print = async (id, type) => {
  try {
    const { data } = await client.mutate({
      mutation: GQL_PRINT.PRINT,
      variables: { id, type },
    });
    return data.print;
  } catch (e) {
    console.log(e);
    return e;
  }
};

export const getCompanyData = async () => {
  try {
    const session = await getSession();
    if (!session.Company) return false;
    const { data } = await client.mutate({
      mutation: GQL_COMPANY.GET_BY_ID,
      variables: { id: session.Company.id },
    });
    return data.company;
  } catch (e) {
    console.log(e);
    return e;
  }
};

const line = '==========================';

export default async (id, showOnline) => {
  const companyData = await getCompanyData();
  if (parseInt(companyData.printClient) === 2 && !showOnline) {
    await print(id, 'cashClosing');
    return;
  }
  const { clientCreatedAt: start, closedAt: end, ...data } = await getCashClosing(id);

  const clientCreatedAt = format(parseISO(start), 'dd/MM/yyyy HH:mm:ss');
  const closedAt = end
    ? format(parseISO(end), 'dd/MM/yyyy HH:mm:ss')
    : format(new Date(), 'dd/MM/yyyy HH:mm:ss');
  const Branch = data.Branch.name;
  const CashClosingEmployee = data.CashClosingEmployee
    ? `${data.CashClosingEmployee.firstName} ${data.CashClosingEmployee.lastName}`.trim()
    : '';

  const pettyCashAmount = currencyFormat(data.pettyCashAmount);
  const virtualBalance = currencyFormat(data.virtualBalance);
  const realBalance = currencyFormat(data.realBalance);
  const finalBalance = currencyFormat(data.finalBalance);

  const differenceLabel = data.difference < 0 ? 'FALTANTE' : 'SOBRANTE';
  const difference = currencyFormat(data.difference);

  const doc = new jsPDF({
    unit: 'mm',
    format: [200, 600],
  });

  doc.setProperties({ title: `corte_caja_${clientCreatedAt}` });

  let breakline = 7;

  doc.setFontSize(14);
  doc.setFont('times', 'bold');
  doc.text('CORTE DE CAJA', 3, breakline, 'left');
  doc.setFontSize(11);
  doc.setFont('times', 'normal');

  breakline += 5;
  doc.text(Branch, 3, breakline, 'left');

  breakline += 5;
  doc.text(CashClosingEmployee, 3, breakline, 'left');

  breakline += 5;
  doc.text(`DEL ${clientCreatedAt}`.trim(), 3, breakline, 'left');

  breakline += 5;
  doc.text(`AL ${closedAt}`.trim(), 3, breakline, 'left');

  breakline += 5;
  doc.text(line, 3, breakline, 'left');

  breakline += 5;
  doc.text('SALDO', 3, breakline, 'left');

  breakline += 5;
  doc.text('+ CAJA CHICA', 3, breakline, 'left');
  doc.text(`$ ${pettyCashAmount}`, 66, breakline, 'right');

  data.Transactions.forEach((el, i) => {
    breakline += 5;
    doc.text(
      `+ (${data.Transactions[i].quantity}) ${data.Transactions[i].name}`,
      3,
      breakline,
      'left',
    );
    doc.text(`$ ${currencyFormat(data.Transactions[i].total)}`, 66, breakline, 'right');
  });

  breakline += 10;
  doc.text('+ DEPÓSITOS', 3, breakline, 'left');
  doc.text(`$ ${currencyFormat(data.CashFlows[1].total)}`, 66, breakline, 'right');

  breakline += 5;
  doc.text('- RETIROS', 3, breakline, 'left');
  doc.text(`$ ${currencyFormat(data.CashFlows[0].total)}`, 66, breakline, 'right');

  breakline += 5;
  doc.text('- CAJA FUERTE', 3, breakline, 'left');
  doc.text(`$ ${currencyFormat(data.CashFlows[2].total)}`, 66, breakline, 'right');

  breakline += 2;
  doc.text('___________', 66, breakline, 'right');

  breakline += 5;
  doc.text('SALDO FINAL', 3, breakline, 'left');
  doc.text(`$ ${currencyFormat(finalBalance)}`, 66, breakline, 'right');

  breakline += 5;
  doc.text('SALDO EFECTIVO', 3, breakline, 'left');
  doc.text(`$ ${virtualBalance}`, 66, breakline, 'right');

  breakline += 5;
  doc.text(line, 3, breakline, 'left');

  breakline += 5;
  doc.setFont('times', 'bold');
  doc.text('DECLARACIÓN DEL CAJERO', 3, breakline, 'left');

  breakline += 5;
  doc.setFont('times', 'normal');
  doc.text('EFECTIVO', 3, breakline, 'left');
  doc.text(`$ ${realBalance}`, 66, breakline, 'right');

  breakline += 5;
  doc.text(line, 3, breakline, 'left');

  breakline += 5;
  doc.text(differenceLabel, 3, breakline, 'left');
  doc.text(`$ ${difference}`, 66, breakline, 'right');

  breakline += 5;
  doc.text(line, 3, breakline, 'left');

  breakline += 5;
  doc.text('TOTALES', 3, breakline, 'left');

  breakline += 5;
  doc.setFontSize(10);
  doc.text(`VENTA (${data.Sales.sale.count})`, 3, breakline, 'left');
  doc.text(`$ ${currencyFormat(data.Sales.sale.total)}`, 66, breakline, 'right');

  breakline += 5;
  doc.setFontSize(10);
  doc.text(`DESCUENTOS (${data.Sales.discount.count})`, 3, breakline, 'left');
  doc.text(`$ ${currencyFormat(data.Sales.discount.total)}`, 66, breakline, 'right');

  breakline += 5;
  doc.text(`CANCELACIONES (${data.Sales.cancel.count})`, 3, breakline, 'left');
  doc.text(`$ ${currencyFormat(data.Sales.cancel.total)}`, 66, breakline, 'right');

  breakline += 5;
  doc.text(`DOMICILIOS (${data.Sales.shipping.count})`, 3, breakline, 'left');
  doc.text(`$ ${currencyFormat(data.Sales.shipping.total)}`, 66, breakline, 'right');

  doc.autoPrint();

  window.open(doc.output('bloburl'));
};
