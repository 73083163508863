import React from 'react';
import { useReactiveVar } from '@apollo/client';
import TextField from '../../../../presentationals/TextField';
import { limitDays } from '../../../../apollo/cache';
var LimitDaysFieldCloseMaintenanceComponent = function () {
    var limitDaysVar = useReactiveVar(limitDays);
    var handleChange = function (e) {
        var value = e.target.value;
        limitDays(parseInt(value));
    };
    return (React.createElement(TextField, { name: 'limitDays', label: 'L\u00EDmite de D\u00EDas', onChange: handleChange, type: 'number', value: limitDaysVar, noFormat: false, variant: 'outlined', icon: undefined, error: undefined, extra: undefined }));
};
export default LimitDaysFieldCloseMaintenanceComponent;
