import React, { Fragment } from 'react';
import { TableBody, TableCell } from '@mui/material';
import { currencyFormat } from '../../../../helpers';
import ItemProductInventoryComponent from './Item';
var TableBodyProductInventoryComponent = function (props) {
    return (React.createElement(TableBody, null, props.rows.map(function (el) { return (React.createElement(ItemProductInventoryComponent, { key: el.id, onClick: props.onItemClick, productId: el.Product.id },
        React.createElement(TableCell, { className: 'whitespace-nowrap' }, el.Product.sku || '-'),
        React.createElement(TableCell, { className: 'whitespace-nowrap' }, el.Product.name || '-'),
        React.createElement(TableCell, { align: 'center', className: 'bg-[#ddd] border-0 border-b border-solid border-[#989898] whitespace-nowrap' }, el.Product.Classification.name),
        React.createElement(TableCell, { align: 'center' }, el.Product.PresentationBase ? el.Product.PresentationBase.name : '-'),
        el.Inventories.map(function (elCh, i) { return (React.createElement(Fragment, { key: i },
            React.createElement(TableCell, { align: 'center', style: {
                    background: i % 2 === 0 ? '#ddd' : '#fafafa',
                    borderColor: i % 2 === 0 ? '#989898' : '#ddd',
                } }, currencyFormat(elCh.quantity)),
            React.createElement(TableCell, { align: 'right', style: {
                    background: i % 2 === 0 ? '#ddd' : '#fafafa',
                    borderColor: i % 2 === 0 ? '#989898' : '#ddd',
                } }, currencyFormat(elCh.total)),
            React.createElement(TableCell, { align: 'right', style: {
                    background: i % 2 === 0 ? '#ddd' : '#fafafa',
                    borderColor: i % 2 === 0 ? '#989898' : '#ddd',
                } }, currencyFormat(elCh.cost)))); }))); })));
};
export default TableBodyProductInventoryComponent;
