import React, { useEffect, useState } from 'react';

import { useReactiveVar } from '@apollo/client';

import { Typography } from '@mui/material';

import { saleTotalToPay, totalsSaleV2 } from '../../../../../../apollo/cache';
import { currencyFormat } from '../../../../../../helpers';

const TotalInfoSummaryFormBodyConfirmDialogSaleV2Component = () => {
  const totalsSaleV2Var = useReactiveVar(totalsSaleV2);
  const saleTotalToPayVar = useReactiveVar(saleTotalToPay);

  const [exchange, setExchange] = useState(0);

  useEffect(() => {
    setExchange(0);
    const totalPaid = saleTotalToPayVar.reduce(
      (total, el) => parseFloat(total) + parseFloat(el.total || 0),
      0,
    );
    const currentExchange =
      totalPaid > totalsSaleV2Var[3] ? Math.abs(totalsSaleV2Var[3] - totalPaid) : 0;
    setExchange(currentExchange);
  }, [totalsSaleV2Var, saleTotalToPayVar]);

  return (
    <div className='flex flex-col py-2'>
      <div className='flex flex-row items-end justify-end'>
        <Typography variant='subtitle1' className='mr-2'>
          Subtotal
        </Typography>
        <Typography variant='h5'>${currencyFormat(totalsSaleV2Var[0])}</Typography>
      </div>
      <div className='flex flex-row items-end justify-end'>
        <Typography variant='subtitle1' className='mr-2'>
          Dscto.
        </Typography>
        <Typography variant='h5'>${currencyFormat(totalsSaleV2Var[1])}</Typography>
      </div>
      <div className='flex flex-row items-end justify-end'>
        <Typography variant='subtitle1' className='mr-2'>
          Impuestos
        </Typography>
        <Typography variant='h5'>${currencyFormat(totalsSaleV2Var[2])}</Typography>
      </div>
      <div className='flex flex-row items-end justify-end'>
        <Typography variant='h5' className='mr-2'>
          Total
        </Typography>
        <Typography variant='h4' className='font-bold'>
          ${currencyFormat(totalsSaleV2Var[3])}
        </Typography>
      </div>
      <div className='flex flex-row items-end justify-end'>
        <Typography variant='h5' className='mr-2'>
          Cambio
        </Typography>
        <Typography variant='h4' className='font-bold'>
          ${currencyFormat(exchange)}
        </Typography>
      </div>
    </div>
  );
};

export default TotalInfoSummaryFormBodyConfirmDialogSaleV2Component;
