import React, { useState } from 'react';
import useApolloQuery from '../../../hooks/useApolloQuery';
import GQL_CALENDAR from '../../../apollo/gql/calendar';
import ItemUpcomingAppointmentsCalendarQueueManagerComponent from './Item';
var UpcomingAppointmentsCalendarQueueManagerComponent = function (props) {
    var currentDate = useState(new Date())[0];
    var data = useApolloQuery(GQL_CALENDAR.GET_CALENDARS, {
        variables: {
            status: [1],
            limit: 100,
            order: ['start', 'ASC'],
            start: currentDate,
            isDateTime: true,
        },
    }).data;
    if (!data)
        return React.createElement("div", null);
    return (React.createElement("div", { className: 'flex flex-col px-4 py-2' },
        React.createElement("div", { className: 'leading-tight text-6xl font-medium', style: {
                color: props.textColor || '#000',
            } }, "Pr\u00F3ximas citas"),
        React.createElement("div", { className: 'flex flex-1' },
            React.createElement("div", { className: 'flex flex-1 flex-col' }, data.rows
                .slice(0, 5)
                .map(function (el) {
                return el.status === 1 && (React.createElement(ItemUpcomingAppointmentsCalendarQueueManagerComponent, { showHour: true, textColor: props.textColor, key: el.id, Calendar: el }));
            })))));
};
export default UpcomingAppointmentsCalendarQueueManagerComponent;
