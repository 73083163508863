import getDefaultCashClosingSaleResumeValues from './getDefaultValues';
var getSaleResumeBySales = function (data) {
    var defaultValues = getDefaultCashClosingSaleResumeValues();
    return data.reduce(function (object, el) { return ({
        sale: el.status > 0 ? object.sale + el.total : object.sale,
        saleQty: el.status > 0 ? object.saleQty + 1 : object.saleQty,
        discount: object.discount + el.discount,
        discountQty: object.discountQty + 1,
        cancelled: el.status === 0 ? object.cancelled + el.total : object.cancelled,
        cancelledQty: el.status === 0 ? object.cancelledQty + 1 : object.cancelledQty,
    }); }, defaultValues);
};
export default getSaleResumeBySales;
