var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { useReactiveVar } from '@apollo/client';
import { inputError } from '../../../../../apollo/cache';
import SubtitleDialogComponent from '../../../../../presentationals/Dialog/helpers/Subtitle';
import TextFieldComponent from '../../../../../presentationals/FormComponents/TextField';
import SelectFieldComponent from '../../../../../presentationals/FormComponents/SelectField';
import { typeOptions } from '../../../Create/Body';
import SelectHourFieldComponent from '../../../../../presentationals/FormComponents/SelectHourField';
import { getDefaultBranchEditForm } from '../../../helpers/getDefaultBranchValues';
var BodyEditBranchComponent = forwardRef(function (props, ref) {
    var defaultValues = getDefaultBranchEditForm();
    var _a = useState(defaultValues), values = _a[0], setValues = _a[1];
    var errors = useReactiveVar(inputError);
    useEffect(function () {
        return function () {
            inputError({});
        };
    }, []);
    useEffect(function () {
        if (props.defaultValues)
            setValues(getDefaultBranchEditForm(props.defaultValues));
    }, [props.defaultValues]);
    var handleChange = function (name, value) {
        var _a;
        setValues(__assign(__assign({}, values), (_a = {}, _a[name] = value, _a)));
    };
    var handleHourChange = function (name, value) {
        setValues(function (values) {
            var _a;
            return (__assign(__assign({}, values), (_a = {}, _a[name] = value, _a)));
        });
    };
    var handleKeyPress = function (e) {
        if (e.key === 'Enter') {
            e.preventDefault();
            props.onPressEnter && props.onPressEnter();
        }
    };
    var getValues = function () { return values; };
    var handleClear = function () {
        setValues(defaultValues);
    };
    useImperativeHandle(ref, function () { return ({
        getValues: getValues,
        onClear: handleClear,
    }); });
    return (React.createElement("div", { className: 'flex-1 px-6 py-4 flex flex-col md:grid md:grid-cols-4 md:gap-4 overflow-auto basis-0' },
        React.createElement("div", { className: 'mb-4 md:mb-0' },
            React.createElement(SubtitleDialogComponent, { label: 'Datos generales' }),
            React.createElement(TextFieldComponent, { label: 'Nombre', name: 'name', value: values.name, error: errors.name, onChange: handleChange, onKeyDown: handleKeyPress }),
            React.createElement(TextFieldComponent, { label: 'Prefijo', name: 'prefix', value: values.prefix, onChange: handleChange, onKeyDown: handleKeyPress }),
            React.createElement(TextFieldComponent, { label: 'Tel\u00E9fono', name: 'phone', value: values.phone, onChange: handleChange, onKeyDown: handleKeyPress }),
            React.createElement(TextFieldComponent, { label: 'Tel\u00E9fono Alternativo', name: 'altPhone', value: values.altPhone || '', onChange: handleChange, onKeyDown: handleKeyPress }),
            React.createElement(SelectFieldComponent, { label: 'Tipo', name: 'type', value: values.type, error: errors.type, onChange: handleChange, onKeyDown: handleKeyPress, options: typeOptions }),
            React.createElement(SubtitleDialogComponent, { label: 'Horario laboral', className: 'mt-4' }),
            React.createElement("div", { className: 'grid grid-cols-2 gap-4' },
                React.createElement(SelectHourFieldComponent, { initialValue: values.startHour, name: 'startHour', label: 'Apertura', onChange: handleHourChange }),
                React.createElement(SelectHourFieldComponent, { initialValue: values.endHour, name: 'endHour', label: 'Cierre', onChange: handleHourChange }))),
        React.createElement("div", { className: 'mb-4 md:mb-0' },
            React.createElement(SubtitleDialogComponent, { label: 'Domicilio' }),
            React.createElement(TextFieldComponent, { label: 'Calle y n\u00FAmero', name: 'addressLine1', value: values.addressLine1, onChange: handleChange, onKeyDown: handleKeyPress }),
            React.createElement(TextFieldComponent, { label: 'Colonia', name: 'addressLine2', value: values.addressLine2, onChange: handleChange, onKeyDown: handleKeyPress }),
            React.createElement(TextFieldComponent, { label: 'Ciudad', name: 'city', value: values.city, onChange: handleChange, onKeyDown: handleKeyPress }),
            React.createElement(TextFieldComponent, { label: 'Municipio', name: 'municipality', value: values.municipality || '', onChange: handleChange, onKeyDown: handleKeyPress }),
            React.createElement(TextFieldComponent, { label: 'Estado', name: 'state', value: values.state, onChange: handleChange, onKeyDown: handleKeyPress }),
            React.createElement(TextFieldComponent, { label: 'C\u00F3digo postal', name: 'zipCode', value: values.zipCode, onChange: handleChange, onKeyDown: handleKeyPress }))));
});
export default BodyEditBranchComponent;
