import React from 'react';

import Container from '../../presentationals/Module.v2/Container';

import GQL from './_gql';
import Filter from './_filter';

const title = 'Ajustes de inventario por producto';
const operation = ['adjustInventoryProducts', 'adjustInventoryProducts'];
const Rows = [
  { label: 'Fecha', key: 'createdAt', sort: 'text', format: 'dateTime', filter: true },
  {
    label: 'Folio',
    key: 'AdjustInventory folio && AdjustInventory serial',
    padSatart: [5, '0'],
    sort: 'text',
    filter: true,
  },
  { label: 'COD', key: 'Product code', sort: 'text', filter: true },
  { label: 'Producto', key: 'Product name', sort: 'text', filter: true },
  { label: 'Costo', key: 'Product cost', sort: 'text', filter: true, format: 'currency' },
  { label: 'Inv. Anterior', key: 'previousQuantity', align: 'center' },
  { label: 'Inv. Ajuste', key: 'currentQuantity', align: 'center' },
  { label: 'Diferencia', key: 'difference', align: 'center' },
  { label: 'Diff. Cost', key: 'costDifference', align: 'center', format: 'currency' },
];

const ProductMovementComponent = () => {
  return <>
    <Container
      title={title}
      operation={operation}
      Rows={Rows}
      get={GQL.GET}
      noCreate
      noDelete
      noEdit
      filter
    />
    <Filter />
  </>;
};

export default ProductMovementComponent;
