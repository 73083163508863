import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Card, } from '@mui/material';
import useApolloQuery from '../../../../../hooks/useApolloQuery';
import GQL_CASH_FLOW from '../../../../../apollo/gql/cashFlow';
import getCashClosingResumeArrayByCashClosings from './helpers/getCashClosingResumeArrayByCashClosings';
import LoadingProgress from '../../../../../presentationals/LoadingProgress';
import ItemCashClossinSummaryIncomeReportComponent from '../Item';
import ErrorDataComponent from '../../../../../presentationals/ErrorData';
import { dateTimeFormat } from '../../../../../helpers';
import getCashFlowActionLabel from '../../../../cashFlow/helpers/getCashFlowActionLabel';
import convertToCurrencyLabel from '../../../../../helpers/convertToCurrencyLabel';
var CashFlowComponent = function () {
    var params = useParams();
    var _a = useState([]), cashFlows = _a[0], setCashFlows = _a[1];
    var _b = useApolloQuery(GQL_CASH_FLOW.GET_CASH_FLOWS, {
        variables: { cashClosingId: params.cashClosingId },
    }), loading = _b.loading, data = _b.data;
    useEffect(function () {
        if (data) {
            var CashFlows = getCashClosingResumeArrayByCashClosings(data);
            setCashFlows(CashFlows);
        }
    }, [data]);
    if (!data)
        return React.createElement(ErrorDataComponent, null);
    return (React.createElement(Card, { className: 'mt-2 flex flex-1 flex-col overflow-hidden' },
        React.createElement("div", { className: 'flex flex-col bg-slate-700 text-white' },
            React.createElement("div", { className: 'font-semibold p-2' }, "Movimientos de caja"),
            React.createElement("div", { className: 'flex flex-row border-0 border-t border-b border-solid border-gray-400 p-2' }, cashFlows.map(function (el) { return (React.createElement("div", { className: 'flex flex-1' },
                React.createElement(ItemCashClossinSummaryIncomeReportComponent, { label: el.name, total: el.total }))); }))),
        loading ? (React.createElement(LoadingProgress, null)) : (React.createElement("div", { className: 'flex flex-1 flex-col sm:basis-0 overflow-auto' },
            React.createElement(TableContainer, null,
                React.createElement(Table, { stickyHeader: true, size: 'small' },
                    React.createElement(TableHead, null,
                        React.createElement(TableRow, null,
                            React.createElement(TableCell, null, "Creado el"),
                            React.createElement(TableCell, null, "Acci\u00F3n"),
                            React.createElement(TableCell, null, "Descripci\u00F3n"),
                            React.createElement(TableCell, null, "Importe"))),
                    React.createElement(TableBody, null, data.map(function (el, i) { return (React.createElement(TableRow, { key: i },
                        React.createElement(TableCell, null, dateTimeFormat(el.clientCreatedAt)),
                        React.createElement(TableCell, null, getCashFlowActionLabel(el.action)),
                        React.createElement(TableCell, null, el.description),
                        React.createElement(TableCell, null, convertToCurrencyLabel(el.total)))); }))))))));
};
export default CashFlowComponent;
