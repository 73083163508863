var getProductMenuValueFromPath = function (pathname, deliveryStatus) {
    switch (deliveryStatus) {
        case 5:
            return 4;
        case 4:
            return 3;
        default:
            break;
    }
    var pathnameParts = pathname.split('/');
    var path = pathnameParts[pathnameParts.length - 1];
    switch (path) {
        case 'general':
            return 0;
        case 'products':
            return 1;
        case 'delivery':
            return 2;
        case 'pickup':
            return 3;
        case 'close':
            return 4;
        default:
            return 0;
    }
};
export default getProductMenuValueFromPath;
