var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { InputBase } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
var PhoneInputBaseFieldComponent = function (_a) {
    var name = _a.name, config = _a.config, props = __rest(_a, ["name", "config"]);
    var control = useFormContext().control;
    return (React.createElement(Controller, { name: name, control: control, rules: {
            required: 'El número de teléfono es requerido',
            pattern: {
                value: /^[0-9]{10}$/,
                message: 'Formato de teléfono inválido',
            },
        }, render: function (_a) {
            var field = _a.field;
            return (React.createElement(InputBase, __assign({}, field, { placeholder: props.placeholder, disabled: props.disabled, className: 'flex flex-1 ml-1', inputProps: { 'aria-label': 'phone-field' }, autoComplete: 'off' })));
        } }));
};
export default PhoneInputBaseFieldComponent;
