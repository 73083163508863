import React from 'react';
import OperatingExpensesComponent from '../containers/operatingExpenses';
import CreateOperatingExpensesLayoutComponent from './OperatingExpensesLayout/CreateOperatingExpensesLayout';
var getOperatingExpensesChildrenRoutes = function () {
    return [
        { index: true, element: React.createElement(OperatingExpensesComponent, null) },
        {
            path: 'create',
            element: React.createElement(CreateOperatingExpensesLayoutComponent, null),
        },
    ];
};
export default getOperatingExpensesChildrenRoutes;
