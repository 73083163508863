import React from 'react';
import LoadingProgress from '../../../../presentationals/LoadingProgress';
import useGetSaleByParamSaleId from '../../../../hooks/useGetSaleByParamSaleId';
import BodyProductsRentComponent from '../../../rent/Item/Products/Body';
var ProductsSaleComponent = function () {
    var _a = useGetSaleByParamSaleId(), data = _a.data, loading = _a.loading;
    if (loading || !data)
        return React.createElement(LoadingProgress, null);
    return (React.createElement("div", { className: 'flex flex-1 flex-col p-3' },
        React.createElement("div", { className: 'flex items-center' },
            React.createElement("div", { className: 'text-base font-medium mb-1 flex flex-1' }, "Productos de la venta")),
        React.createElement("div", { className: 'text-sm sm:text-base text-gray-600' }, "Revisar y analizar los detalles de los productos incluidos en la venta emitida."),
        React.createElement("div", { className: 'rounded mt-3 overflow-hidden flex flex-1' },
            React.createElement(BodyProductsRentComponent, { SaleProducts: data.SaleProducts, subtotal: data.subtotal, taxes: data.taxes, total: data.total }))));
};
export default ProductsSaleComponent;
