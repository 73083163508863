var getCancelSaleReasons = function () { return [
    { value: 1, label: 'PRECIO' },
    { value: 2, label: 'CRÉDITO' },
    { value: 3, label: 'CALIDAD DEL PROYECTO' },
    { value: 9, label: 'TRATO AL CLIENTE' },
    { value: 4, label: 'TIEMPO DE ENTREGA DEL PROYECTO' },
    { value: 5, label: 'SITUACIÓN GEOGRAFICA' },
    { value: 6, label: 'MAL ENFOQUE DEL PROYECTO' },
    { value: 7, label: 'OTROS' },
    { value: 8, label: 'RAZÓN NO CLASIFICADO' },
]; };
export default getCancelSaleReasons;
