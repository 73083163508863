var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { useEffect } from 'react';
import { useReactiveVar } from '@apollo/client';
import GQL_TRANSACTION from '../../../apollo/gql/transaction';
import useApolloLazyQuery from '../../../hooks/useApolloLazyQuery';
import { filter } from '../../../apollo/cache';
var useGetTransactions = function () {
    var filterVar = useReactiveVar(filter);
    var _a = useApolloLazyQuery(GQL_TRANSACTION.GET_TRANSACTIONS), getData = _a[0], _b = _a[1], data = _b.data, loading = _b.loading;
    useEffect(function () {
        getData({
            variables: __assign(__assign({}, filterVar), { branchId: filterVar.branchId === '-1' ? null : filterVar.branchId, limit: 0, order: ['clientCreatedAt', 'desc'] }),
        });
    }, [filterVar]);
    return { data: data, loading: loading };
};
export default useGetTransactions;
