var getSalePriceByCostAndUtilityPercent = function (cost, utilityPercent, formula) {
    var formattedCost = isNaN(cost) ? 0 : cost;
    var formattedUtilityPercent = isNaN(utilityPercent) ? 0 : utilityPercent;
    if (formattedUtilityPercent === 0)
        return formattedCost;
    switch (formula) {
        default:
            if (utilityPercent >= 100)
                return formattedCost;
            return formattedCost / ((100 - utilityPercent) / 100);
    }
};
export default getSalePriceByCostAndUtilityPercent;
