import React from 'react';

import { useReactiveVar } from '@apollo/client';

import { Dialog } from '@mui/material';
import { isChooseBillingInformationDialogOpen } from '../../../../../../../../../apollo/cache';
import Header from './Header';
import Form from './Form';
import Table from './Table';

const SearchDialogChooseBillingInformationBodyCreateFormCustomerComponent = () => {
  const isChooseBillingInformationDialogOpenVar = useReactiveVar(
    isChooseBillingInformationDialogOpen,
  );
  return (
    <Dialog fullScreen open={isChooseBillingInformationDialogOpenVar}>
      <Header />
      <Form />
      <Table />
    </Dialog>
  );
};

export default SearchDialogChooseBillingInformationBodyCreateFormCustomerComponent;
