import React from 'react';
import ButtonFooterDialogComponent from '../../../../presentationals/Dialog/Footer/Button';
var ApplyChangesButtonTableSettingsProductComponent = function () {
    var handleChange = function () {
        window.location.reload();
    };
    return (React.createElement("div", { className: 'flex-col text-end' },
        React.createElement(ButtonFooterDialogComponent, { onClick: handleChange }, "Aplicar cambios"),
        React.createElement("div", { className: 'text-xs mt-1 text-gray-500' }, "Al aplicar cambios se refrescar\u00E1 el sitio web.")));
};
export default ApplyChangesButtonTableSettingsProductComponent;
