import { TableCell, TableRow } from '@mui/material';
import React from 'react';
var TableRowEditProductRequisitionComponent = function (props) {
    var _a;
    var handleClick = function () {
        props.onClick(props.id);
    };
    return (React.createElement(TableRow, { hover: true, onClick: handleClick },
        React.createElement(TableCell, { className: 'whitespace-nowrap' }, props.Product.barcode),
        React.createElement(TableCell, { className: 'whitespace-nowrap' }, props.Product.sku),
        React.createElement(TableCell, { className: 'whitespace-nowrap' }, props.Product.name),
        React.createElement(TableCell, { className: 'whitespace-nowrap' }, props.ProductPresentation.name),
        React.createElement(TableCell, { align: 'center' }, ((_a = props.Product.Inventory) === null || _a === void 0 ? void 0 : _a.quantity) || '-'),
        React.createElement(TableCell, { align: 'center' }, props.quantity)));
};
export default TableRowEditProductRequisitionComponent;
