import React from 'react';
import { useParams } from 'react-router-dom';
import LoadingProgress from '../../../presentationals/LoadingProgress';
import ErrorDataComponent from '../../../presentationals/ErrorData';
import ModuleV3Component from '../../../presentationals/Module.v3';
import FilterSaleProductPresentationRotationReportComponent from '../Filter';
import useApolloQuery from '../../../hooks/useApolloQuery';
import GQL_PRODUCT from '../../../apollo/gql/product';
import useGetDetailedProductPresentationSale from '../../../hooks/useGetDetailedProductPresentationSale';
var ItemSaleProductPresentationRotationReportComponent = function () {
    var params = useParams();
    var _a = useGetDetailedProductPresentationSale(params.productPresentationId), data = _a.data, loading = _a.loading;
    var Product = useApolloQuery(GQL_PRODUCT.GET_PRODUCT_BY_PRODUCT_PRESENTATION_ID, {
        variables: { productPresentationId: params.productPresentationId },
    }).data;
    var header = [
        { Header: 'Creado el', accessor: 'clientCreatedAt' },
        { Header: 'Folio', accessor: 'folio' },
        { Header: 'Cliente', accessor: 'customer' },
        { Header: 'SKU', accessor: 'sku' },
        { Header: 'Producto', accessor: 'product' },
        { Header: 'Presentación', accessor: 'presentation' },
        { Header: 'Clasificación', accessor: 'classification' },
        { Header: 'Cantidad', accessor: 'quantity' },
        { Header: 'Descuento', accessor: 'discount' },
        { Header: 'Subtotal', accessor: 'subtotal' },
        { Header: 'Impuestos', accessor: 'taxes' },
        { Header: 'Total', accessor: 'total' },
        { Header: 'Forma de pago', accessor: 'paymentMethod' },
        { Header: 'Estatus', accessor: 'status' },
    ];
    if (loading)
        return React.createElement(LoadingProgress, null);
    if (!data)
        return React.createElement(ErrorDataComponent, null);
    return (React.createElement(React.Fragment, null,
        React.createElement(ModuleV3Component, { route: -1, showReturnButton: true, title: Product ? "Detalle de ".concat(Product.name.toLowerCase()) : '', searchLabel: 'productos', filter: true, data: data, header: header, hideAdd: true, isLoading: loading }),
        React.createElement(FilterSaleProductPresentationRotationReportComponent, null)));
};
export default ItemSaleProductPresentationRotationReportComponent;
