var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useEffect, useState } from 'react';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import ListItemPermissionEditRoleComponent from '../helpers/ListItem';
import SwitchPermissionFieldComponent from '../helpers/SwitchPermissionField';
var defaultBoxPermissionValues = {
    createNewBox: false,
    editBox: false,
    getBoxes: false,
    deleteBox: false,
};
var BoxPermissionEditRoleComponent = function (props) {
    var _a = useState(defaultBoxPermissionValues), values = _a[0], setValues = _a[1];
    useEffect(function () {
        setValues(function (values) { return (__assign(__assign({}, values), props.Permissions)); });
    }, [props.Permissions]);
    return (React.createElement(ListItemPermissionEditRoleComponent, { icon: React.createElement(MailOutlineIcon, null), name: 'Cajas y paquetes para env\u00EDos' },
        React.createElement("div", { className: 'mx-4 py-3 grid grid-cols-1 md:grid-cols-3 gap-4' },
            React.createElement("div", { className: 'flex flex-col' },
                React.createElement(SwitchPermissionFieldComponent, __assign({}, props, { name: 'createNewBox', label: 'Crear nuevas cajas y paquetes para env\u00EDos', defaultValue: values.createNewBox })),
                React.createElement(SwitchPermissionFieldComponent, __assign({}, props, { name: 'editBox', label: 'Editar informaci\u00F3n general', defaultValue: values.editBox })),
                React.createElement(SwitchPermissionFieldComponent, __assign({}, props, { name: 'getBoxes', label: 'Consulta de datos', defaultValue: values.getBoxes })),
                React.createElement(SwitchPermissionFieldComponent, __assign({}, props, { name: 'deleteBox', label: 'Dar de baja cajas y paquetes para env\u00EDos', defaultValue: values.deleteBox }))))));
};
export default BoxPermissionEditRoleComponent;
