import React, { useEffect, useState } from 'react';
import { useReactiveVar } from '@apollo/client';
import useGetSaleProductPresentationRotation from './helpers/useGetSaleProductPresentationRotation';
import LoadingProgress from '../../presentationals/LoadingProgress';
import ModuleV3Component from '../../presentationals/Module.v3';
import FilterSaleProductPresentationRotationReportComponent from './Filter';
import ErrorDataComponent from '../../presentationals/ErrorData';
import { filter } from '../../apollo/cache';
import RedirectEditFormToRouteComponent from '../../presentationals/RedirectEditFormToRoute';
import getSaleProductPresentationRotationReportActions from './helpers/actions';
var SaleProductPresentationRotationReportComponent = function () {
    var filterVar = useReactiveVar(filter);
    var _a = useState(undefined), queryParams = _a[0], setQueryParams = _a[1];
    var actions = getSaleProductPresentationRotationReportActions();
    var _b = useGetSaleProductPresentationRotation(), data = _b.data, loading = _b.loading;
    var header = [
        { Header: 'SKU', accessor: 'sku' },
        { Header: 'Producto', accessor: 'product' },
        { Header: 'Cantidad', accessor: 'quantity' },
        { Header: 'Importe', accessor: 'total' },
    ];
    useEffect(function () {
        if (filterVar.start && filterVar.end)
            setQueryParams({ start: filterVar.start.toString(), end: filterVar.end.toString() });
    }, [filterVar]);
    if (loading)
        return React.createElement(LoadingProgress, null);
    if (!data)
        return React.createElement(ErrorDataComponent, null);
    return (React.createElement(React.Fragment, null,
        React.createElement(ModuleV3Component, { title: 'Productos por presentaci\u00F3n', searchLabel: 'productos', filter: true, data: data, header: header, actions: actions, hideAdd: true, isLoading: loading }),
        React.createElement(FilterSaleProductPresentationRotationReportComponent, null),
        React.createElement(RedirectEditFormToRouteComponent, { queryParams: queryParams })));
};
export default SaleProductPresentationRotationReportComponent;
