var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabelMui from '@mui/material/FormControlLabel';
import CheckboxMui from '@mui/material/Checkbox';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
var FormControlLabel = function (props) {
    var handleChange = function (e) {
        props.handleChange(e.target.name, e.target.checked, props.params);
    };
    return (React.createElement(FormControlLabelMui, { control: React.createElement(CheckboxMui, { checked: props.checked, disabled: props.disabled, onChange: handleChange, name: props.name, style: { color: props.color } }), label: props.label }));
};
var Checkbox = function (_a) {
    var noFormat = _a.noFormat, icon = _a.icon, props = __rest(_a, ["noFormat", "icon"]);
    var handleChange = function (name, checked, params) {
        props.handleChange(name, checked, params);
    };
    return (React.createElement(Grid, { container: true, spacing: 0, alignItems: 'flex-end', className: 'py-1' },
        React.createElement(Grid, { item: true, xs: noFormat ? false : 1 }, icon || ''),
        React.createElement(Grid, { item: true, xs: noFormat ? 12 : 10 },
            props.label && React.createElement(Typography, null, props.label),
            React.createElement(FormControl, { required: true, component: 'fieldset' },
                React.createElement(FormGroup, { row: props.row || false }, props.values &&
                    props.values.map(function (el, i) { return (React.createElement("div", { key: i },
                        el.extra || '',
                        React.createElement(FormControlLabel, { key: "".concat(el.name, "-").concat(el.label), checked: el.checked, disabled: el.disabled || false, name: el.name, label: el.label, params: el.params, handleChange: handleChange, color: el.color }))); }))))));
};
export default Checkbox;
