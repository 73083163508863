import React from 'react';
import FooterDialogComponent from '../../../../presentationals/Dialog/Footer';
import ClearButtonFooterCreateBoxComponent from './ClearButton';
import CreateButtonFooterCreateBoxComponent from './CreateButton';
var FooterCreateBoxComponent = function () {
    return (React.createElement(FooterDialogComponent, null,
        React.createElement("div", null),
        React.createElement("div", null,
            React.createElement(ClearButtonFooterCreateBoxComponent, null),
            React.createElement(CreateButtonFooterCreateBoxComponent, null))));
};
export default FooterCreateBoxComponent;
