import React from 'react';
import SearchIcon from '@mui/icons-material/Edit';
import { isSaleCustomerFormOpen, searchField } from '../../../../../../../apollo/cache';
import IconButton from '../../../../../../../presentationals/IconButton';
var ChangeCustomerCustomerInfoSummaryFormBodyConfirmDialogSaleV2Component = function (props) {
    var handleClick = function () {
        isSaleCustomerFormOpen(true);
        searchField(['name', props.name]);
    };
    return (React.createElement(IconButton, { action: handleClick, size: 'small', className: 'p-0 m-0', icon: React.createElement(SearchIcon, { fontSize: 'small' }) }));
};
export default ChangeCustomerCustomerInfoSummaryFormBodyConfirmDialogSaleV2Component;
