var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import FooterDialogComponent from '../../../presentationals/Dialog/Footer';
import useGetSession from '../../../hooks/useGetSession';
import GQL_CUSTOMER from '../../../apollo/gql/customer';
import EditCustomerAutoReceivableButtonComponent from './EditButton';
import ProductFieldComponent from '../../../presentationals/FormComponents/ProductField';
import useApolloQuery from '../../../hooks/useApolloQuery';
import TextFieldComponent from '../../../presentationals/FormComponents/TextField';
var AutoReceivableCustomerComponent = function () {
    var _a;
    var params = useParams();
    var session = useGetSession();
    var _b = useApolloQuery(GQL_CUSTOMER.CUSTOMER_AUTO_RECEIVABLE_CONFIG, {
        variables: {
            customerId: params.customerId,
        },
    }), loading = _b.loading, data = _b.data;
    var _c = useState({
        productId: null,
        productPrice: '',
    }), values = _c[0], setValues = _c[1];
    useEffect(function () {
        if (data)
            setValues(function (values) { return (__assign(__assign({}, values), { productId: data.productId, productPrice: "".concat(data.productPrice) })); });
    }, [data]);
    var customerLabel = ((_a = session === null || session === void 0 ? void 0 : session.Company) === null || _a === void 0 ? void 0 : _a.activity) === 3 ? 'pacientes' : 'clientes';
    var handleProductChange = function (name, value) {
        setValues(function (values) {
            var _a;
            return (__assign(__assign({}, values), (_a = {}, _a[name] = value, _a)));
        });
    };
    var handleChange = function (name, value) {
        setValues(function (values) {
            var _a;
            return (__assign(__assign({}, values), (_a = {}, _a[name] = value, _a)));
        });
    };
    var handleEdit = function () { return values; };
    return (React.createElement("div", { className: 'flex flex-1 flex-col' },
        React.createElement("div", { className: 'px-4 py-3 flex-1 grid grid-cols-1 sm:grid-cols-3 basis-0 overflow-auto' },
            React.createElement("div", { className: 'flex flex-1 flex-col col-span-2' },
                React.createElement("div", { className: 'text-lg' }, "Facturaci\u00F3n mensual"),
                React.createElement("div", { className: 'text-sm text-gray-600' },
                    "Gestiona y configura las facturas que se emiten mensualmente para ",
                    customerLabel,
                    "."),
                React.createElement("div", { className: 'mt-3 w-full sm:w-1/4' },
                    React.createElement(ProductFieldComponent, { label: 'Producto / servicio', name: 'productId', onChange: handleProductChange, initialValue: values.productId }),
                    React.createElement(TextFieldComponent, { label: 'Importe', name: 'productPrice', value: values.productPrice, onChange: handleChange, type: 'number' })))),
        React.createElement(FooterDialogComponent, null,
            React.createElement("div", null),
            React.createElement(EditCustomerAutoReceivableButtonComponent, { onEdit: handleEdit, disabled: loading }))));
};
export default AutoReceivableCustomerComponent;
