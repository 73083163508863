import React, { useEffect, useRef } from 'react';
import FooterDialogComponent from '../../../presentationals/Dialog/Footer';
import ClearButtonFooterCreateRoleComponent from './Footer/ClearButton';
import CreateButtonFooterCreateRoleComponent from './Footer/CreateButton';
import { inputError } from '../../../apollo/cache';
import BodyRoleComponent from './Body';
var CreateRoleComponent = function () {
    var bodyRoleRef = useRef(null);
    var createButtonFooterCreateRolRef = useRef(null);
    var handleCreate = function () {
        var _a;
        (_a = createButtonFooterCreateRolRef.current) === null || _a === void 0 ? void 0 : _a.onPressEnter();
    };
    var handleGetValues = function () {
        var _a;
        return (_a = bodyRoleRef.current) === null || _a === void 0 ? void 0 : _a.getValues();
    };
    var handleClear = function () {
        var _a;
        (_a = bodyRoleRef.current) === null || _a === void 0 ? void 0 : _a.onClear();
    };
    useEffect(function () {
        return function () {
            inputError({});
        };
    }, []);
    return (React.createElement("div", { className: 'flex flex-1 flex-col h-screen overflow-hidden mt-1' },
        React.createElement(BodyRoleComponent, { ref: bodyRoleRef, onPressEnter: handleCreate }),
        React.createElement(FooterDialogComponent, null,
            React.createElement("div", null),
            React.createElement("div", null,
                React.createElement(ClearButtonFooterCreateRoleComponent, { onClick: handleClear }),
                React.createElement(CreateButtonFooterCreateRoleComponent, { ref: createButtonFooterCreateRolRef, getValues: handleGetValues })))));
};
export default CreateRoleComponent;
