var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import TextFieldComponent from '../../../../../../presentationals/FormComponents/TextField';
import useApolloLazyQuery from '../../../../../../hooks/useApolloLazyQuery';
import GQL_REQUISITION from '../../../../../../apollo/gql/requisition';
import LoadingProgress from '../../../../../../presentationals/LoadingProgress';
var BodyEditRequisitionProductDialogComponent = forwardRef(function (props, ref) {
    var defaultValues = {
        quantity: '',
    };
    var _a = useApolloLazyQuery(GQL_REQUISITION.GET_REQUISITION_PRODUCT_BY_ID), getData = _a[0], _b = _a[1], loading = _b.loading, data = _b.data;
    var _c = useState(defaultValues), values = _c[0], setValues = _c[1];
    useEffect(function () {
        if (props.id)
            getData({ variables: { id: props.id } });
    }, [props.id]);
    useEffect(function () {
        if (data)
            setValues(function (values) { return (__assign(__assign({}, values), { quantity: data.quantity.toString() })); });
    }, [data]);
    var handleChange = function (name, value) {
        setValues(function (values) {
            var _a;
            return (__assign(__assign({}, values), (_a = {}, _a[name] = value, _a)));
        });
    };
    var handleGetValues = function () { return values; };
    useImperativeHandle(ref, function () { return ({
        getValues: handleGetValues,
    }); });
    if (loading)
        return React.createElement(LoadingProgress, null);
    // if (!data) return <ErrorDataComponent />;
    return (React.createElement("div", { className: 'grid grid-cols-1 sm:grid-cols-4 flex-1 flex-col px-3 py-4' },
        React.createElement("div", { className: 'flex flex-1 flex-col' },
            React.createElement(TextFieldComponent, { name: 'quantity', label: 'Cantidad a solicitar', onChange: handleChange, value: values.quantity }))));
});
export default BodyEditRequisitionProductDialogComponent;
