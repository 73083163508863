import { format } from 'date-fns';
var convertHourToHouseSelectFieldOption = function (unformattedHour) {
    var hour = format(unformattedHour, 'HH:mm');
    var splittedHour = hour.split(':');
    var minutes = parseFloat(splittedHour[1]) / 60 === 0.8333333333333334
        ? 0.8334
        : parseFloat(splittedHour[1]) / 60 === 0.3333333333333333
            ? 0.3334
            : parseFloat((parseFloat(splittedHour[1]) / 60).toFixed(4));
    return parseInt(splittedHour[0]) + parseFloat(minutes.toFixed(4));
};
export default convertHourToHouseSelectFieldOption;
