import { Checkbox } from '@mui/material';
import React, { forwardRef, useImperativeHandle, useState } from 'react';
var CheckAllPaymentInvoiceComplementButtonComponent = forwardRef(function (props, ref) {
    var _a = useState(false), checked = _a[0], setChecked = _a[1];
    var handleChange = function (event) {
        setChecked(event.target.checked);
        props.onChange(event.target.checked);
    };
    var handleItemChange = function (checked) {
        setChecked(checked);
    };
    useImperativeHandle(ref, function () { return ({
        handleItemChange: handleItemChange,
    }); });
    return (React.createElement(Checkbox, { checked: checked, onChange: handleChange, size: 'small', inputProps: { 'aria-label': 'checkAllInvoiceComplementPayments' } }));
});
export default CheckAllPaymentInvoiceComplementButtonComponent;
