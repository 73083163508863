import React, { useEffect } from 'react';

import { useReactiveVar } from '@apollo/client';

import { Button } from '@mui/material';
import {
  productSaleV2,
  formatSaleV2,
  currencySaleV2,
  cartItems,
  form,
  branchSaleV2,
  totalsSaleV2,
  searchProductField,
  discountTypeSaleV2,
  addToCartSaleV2,
} from '../../../../apollo/cache';

import useApolloMutation from '../../../../hooks/useApolloMutation';
import getInputErrorFormat from '../../Action/helpers/getInputErrorFormat';
import GQL from './_gql';
import getDefaultValues from '../ProductDialog/Create/Body/helpers/getDefaultValues';
import getProductFormatToCheckCart from '../helpers/getProductFormatToCheckCart';
import getTotalizedCartProducts from '../../helpers/getTotalizedCartProducts';

const AddButtonChooseProductSaleV2Component = () => {
  const addToCartSaleV2Var = useReactiveVar(addToCartSaleV2);
  const [checkCart, { loading }] = useApolloMutation(GQL.CHECK_CART);

  const handleClick = async () => {
    try {
      const formVar = form();
      const productSaleV2Var = productSaleV2();
      const formatSaleV2Var = formatSaleV2();
      const currencySaleV2Var = currencySaleV2();
      const cartItemsVar = cartItems();
      const branchSaleV2Var = branchSaleV2();
      const discountTypeSaleV2Var = discountTypeSaleV2();
      if (!productSaleV2Var.id)
        throw new Error('Para añadir a carrito es necesario seleccionar un producto.');
      if (productSaleV2Var.isSerialized && !productSaleV2Var.serialNumber)
        throw new Error('Selecciona una serie para vender.');
      const CurrentProducts = getProductFormatToCheckCart(cartItemsVar);
      const dataCheckCart = await checkCart({
        variables: {
          taxesOption: formatSaleV2Var[1],
          discountType: parseInt(discountTypeSaleV2Var),
          shipping: 0,
          branchId: parseInt(branchSaleV2Var.branchInventory),
          currencyKey: parseFloat(currencySaleV2Var[0]),
          exchangeRate: parseFloat(currencySaleV2Var[1]),
          category: parseInt(formatSaleV2Var[0]),
          isInvoice: formatSaleV2Var[2],
          Products: [
            ...CurrentProducts,
            {
              productId: productSaleV2Var.id,
              commentary: '',
              serialNumber: productSaleV2Var.serialNumber,
              cost: parseFloat(productSaleV2Var.cost),
              discount: parseFloat(formVar.discount),
              price: parseFloat(formVar.unitPrice),
              quantity:
                productSaleV2Var.serialNumber && productSaleV2Var.serialNumber.length > 0
                  ? 1
                  : parseFloat(formVar.qty),
            },
          ],
          Transactions: [],
          showTaxes: formatSaleV2Var[3],
        },
      });
      const cart = getTotalizedCartProducts(
        dataCheckCart.data.totalizeCart.Products,
        cartItemsVar,
        {
          isInvoice: formatSaleV2Var[2],
          taxesOption: formatSaleV2Var[1],
          showTaxes: formatSaleV2Var[3],
        },
      );
      const defaultProductSaleValues = getDefaultValues();
      totalsSaleV2([
        dataCheckCart.data.totalizeCart.subtotal,
        dataCheckCart.data.totalizeCart.discount,
        dataCheckCart.data.totalizeCart.taxes,
        dataCheckCart.data.totalizeCart.total,
      ]);
      cartItems(cart);
      form({});
      productSaleV2(defaultProductSaleValues);
      searchProductField('');
    } catch (e) {
      getInputErrorFormat(e);
    }
  };

  useEffect(() => {
    if (addToCartSaleV2Var) {
      handleClick();
      addToCartSaleV2(false);
    }
  }, [addToCartSaleV2Var]);

  return (
    <Button
      disabled={loading}
      variant='contained'
      color='success'
      size='small'
      onClick={handleClick}
      className='ml-2 h-full'
    >
      Añadir
    </Button>
  );
};

export default AddButtonChooseProductSaleV2Component;
