import React from 'react';
import { InputBase } from '@mui/material';
var InputProductFieldComponent = function (props) {
    var handleChange = function (event) {
        var value = event.target.value;
        props.onChange(value);
    };
    var handleKeyDown = function (e) {
        if (e.key === 'Enter' && props.onPressEnter)
            props.onPressEnter();
    };
    return (React.createElement(InputBase, { placeholder: props.placeholder, disabled: props.isLoading, value: props.value, className: 'flex flex-1 ml-1', inputProps: { 'aria-label': 'product-field' }, onChange: handleChange, onKeyDown: handleKeyDown }));
};
export default InputProductFieldComponent;
