import React, { forwardRef, useImperativeHandle, useState } from 'react';
import DialogComponent from '../../../presentationals/Dialog';
import BodyEditCashFlowComponent from './Body';
var EditCashFlowDialogComponent = forwardRef(function (props, ref) {
    var _a = useState(false), open = _a[0], setOpen = _a[1];
    var handleOpen = function () {
        setOpen(true);
    };
    useImperativeHandle(ref, function () { return ({
        open: handleOpen,
    }); });
    var handleClose = function () {
        setOpen(false);
    };
    return (React.createElement(DialogComponent, { title: 'Editar movimiento de caja', fullScreen: true, open: open, onClose: handleClose },
        React.createElement(BodyEditCashFlowComponent, { onClose: handleClose })));
});
export default EditCashFlowDialogComponent;
