import { gql } from '@apollo/client';

const GET = gql`
  query IncomeStatement($start: Date, $end: Date, $branchId: ID) {
    incomeStatement(start: $start, end: $end, branchId: $branchId) {
      sales
      purchaseOrders
      grossProfit
      operatingExpenses
      ebitda
      taxes
      netProfit
    }
  }
`;

export default { GET };
