import { ListItemIcon, ListItemText, MenuItem } from '@mui/material';
import React from 'react';
var MenuActionModuleV3ItemComponent = function (props) {
    var handleClick = function () {
        props.onClick && props.onClick(props.identifier);
    };
    return (React.createElement(MenuItem, { key: props.label, onClick: handleClick },
        React.createElement(ListItemIcon, null, props.icon),
        React.createElement(ListItemText, null, props.label)));
};
export default MenuActionModuleV3ItemComponent;
