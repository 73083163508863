import CREATE_CASH_CLOSING from './createCashClosing';
import FRAGMENT_CASH_CLOSING from './fragmentCashClosing';
import GET_CASH_CLOSING_BY_ID from './getCashClosingById';
import GET_CASH_CLOSINGS from './getCashClosings';
import GET_OPEN_PETTY_CASH from './getOpenPettyCash';
var GQL_CASH_CLOSINGS = {
    CREATE_CASH_CLOSING: CREATE_CASH_CLOSING,
    FRAGMENT_CASH_CLOSING: FRAGMENT_CASH_CLOSING,
    GET_CASH_CLOSING_BY_ID: GET_CASH_CLOSING_BY_ID,
    GET_CASH_CLOSINGS: GET_CASH_CLOSINGS,
    GET_OPEN_PETTY_CASH: GET_OPEN_PETTY_CASH,
};
export default GQL_CASH_CLOSINGS;
