import React, { useState, useEffect } from 'react';
import { useQuery, useMutation, useReactiveVar } from '@apollo/client';

import Grid from '@mui/material/Grid';
import Chip from '@mui/material/Chip';

import LinearForm from '../../../../presentationals/LinearForm';
import Autocomplete from '../../../../presentationals/Autocomplete';
import TextField from '../../../../presentationals/TextField';
import DateField from '../../../../presentationals/DateField';

import { discountType } from '../../../../apollo/cache';

import GQL_PRODUCT from '../../../product/_gql';
import GQL from '../../_gql';

const defaultValues = {
  quantity: 1,
  unitPrice: 0,
  discount: 0,
  productId: null,
  outDate: new Date(),
};

const Form = props => {
  const discountTypeVar = useReactiveVar(discountType);

  const [values, setValues] = useState(defaultValues);
  const [products, setProducts] = useState([]);
  const [errors, setErrors] = useState({});
  const [ivaPercent, setIvaPercent] = useState(0);
  const [iepsPercent, setIepsPercent] = useState(0);
  const [addTaxes, setAddTaxes] = useState(false);

  const [addProduct] = useMutation(GQL.ADD_PRODUCT, {
    update(cache, { data }) {
      try {
        cache.modify({
          id: cache.identify({ __typename: 'Sale', id: props.id }),
          fields: {
            SaleProducts: cachedSaleProducts => {
              const newSaleProductsRef = cache.writeFragment({
                data: { ...data.addProductServiceOrder },
                fragment: GQL.FRAGMENT_NEW_SALE_PRODUCT,
              });
              return [...cachedSaleProducts, newSaleProductsRef];
            },
            subtotal: cachedSubtotal => cachedSubtotal + data.addProductServiceOrder.subtotal,
            taxes: cachedTaxes => cachedTaxes + data.addProductServiceOrder.taxes,
            total: cachedTotal => cachedTotal + data.addProductServiceOrder.total,
          },
        });
      } catch (e) {
        console.log(e);
      }
    },
  });

  useQuery(GQL_PRODUCT.GET_NAME_PRICE, {
    variables: { limit: 0, lite: true },
    onCompleted: data => {
      const tmp = data.products.rows.map(el => ({
        label: `${el.name.trim()} ${el.sku ? ` | SKU ${el.sku.trim()}` : ''} ${
          el.barcode ? ` | C.B. ${el.barcode.trim()}` : ''
        }`.trim(),
        value: el.id,
        price: el.price,
        Prices: el.Prices,
        iva: el.iva && parseFloat(el.iva) > 0 ? parseFloat(el.iva) / 100 : 0,
        ieps: el.ieps && parseFloat(el.iva) > 0 ? parseFloat(el.ieps) / 100 : 0,
      }));
      setProducts(tmp);
    },
  });

  useEffect(() => {
    setValues(defaultValues);
    setIvaPercent(0);
    setIepsPercent(0);
    setAddTaxes(false);
  }, [props.codeToClear]);

  const handleAutocompleteChange = (e, name, value) => {
    const unitPriceWithTaxes = value ? parseFloat(value.price).toFixed(2) : 0;
    const unitPrice = value ? parseFloat(value.price).toFixed(2) : 0;
    const iva = value && value.iva ? parseFloat(value.iva) : 0;
    const ieps = value && value.ieps ? parseFloat(value.ieps) : 0;
    setIvaPercent(iva);
    setIepsPercent(ieps);
    setValues(values => ({
      ...values,
      [name]: value,
      unitPrice: parseFloat(unitPrice),
      unitPriceWithTaxes,
    }));
  };

  const handleChange = e => {
    const { name, value } = e.target;
    setValues(values => ({ ...values, [name]: value }));
  };
  const handleAddProduct = async () => {
    try {
      if (!values.productId) {
        const e = new Error('Selecciona un producto.');
        e.name = 'productId';
        throw e;
      }

      if (parseFloat(values.quantity) <= 0 || Number.isNaN(Number(values.quantity))) {
        const e = new Error('Proporciona la cantidad de producto.');
        e.name = 'quantity';
        throw e;
      }

      if (values.discount < 0) {
        const e = new Error('Proporciona un descuento mayor o igual a cero.');
        e.name = 'discount';
        throw e;
      }

      if (values.discount > 100 && discountTypeVar === '%') {
        const e = new Error('Proporciona un descuento menor al 100%.');
        e.name = 'discount';
        throw e;
      }

      if (
        !values.unitPrice ||
        parseFloat(values.unitPrice) < 0 ||
        Number.isNaN(Number(values.unitPrice))
      ) {
        const e = new Error('Proporciona el precio del producto.');
        e.name = 'unitPrice';
        throw e;
      }

      const amount = parseFloat(values.quantity) * parseFloat(values.unitPrice);

      let tmpDiscount = 0;

      if (discountTypeVar === '%') tmpDiscount = values.discount;
      else if (discountTypeVar === '$') tmpDiscount = (parseFloat(values.discount) / amount) * 100;

      const price = addTaxes
        ? parseFloat(values.unitPrice)
        : parseFloat(values.unitPrice) / (1 + parseFloat(ivaPercent) + parseFloat(iepsPercent));

      await addProduct({
        variables: {
          id: props.id,
          productId: values.productId.value,
          quantity: parseFloat(values.quantity),
          discount: parseFloat(tmpDiscount),
          // price: parseFloat(values.unitPrice) * (1 + ivaTax),
          price,
          iva: parseFloat(ivaPercent) * 100,
          ieps: parseFloat(iepsPercent) * 100,
          outDate: values.outDate ? values.outDate : null,
        },
      });
      setValues(defaultValues);
      setErrors({});
      setIvaPercent(0);
      setIepsPercent(0);
      setAddTaxes(false);
    } catch (e) {
      console.log(e);
      setErrors({ [e.name]: e.message });
    }
  };

  const handleIvaPercent = () => {
    const tmpIvaPercent = ivaPercent === 0 ? 0.16 : 0;
    setIvaPercent(tmpIvaPercent);
  };

  const handleIepsPercent = () => {
    const tmpIepsPercent = iepsPercent === 0 ? 0.08 : 0;
    setIepsPercent(tmpIepsPercent);
  };

  const handleTaxes = () => {
    setAddTaxes(addTaxes => !addTaxes);
  };

  const handleDateChange = (name, value) => {
    setValues(values => ({ ...values, [name]: value }));
  };

  return parseInt(props.status) !== 3 ? (
    <LinearForm handleAdd={handleAddProduct} align='center'>
      <Grid container spacing={1}>
        <Grid item xs={12} md={12}>
          <Autocomplete
            name='productId'
            label='Producto'
            variant='outlined'
            onChange={handleAutocompleteChange}
            options={products}
            value={values.productId}
            error={errors.productId}
            noFormat
          />
        </Grid>
        <Grid item xs={2} className='flex flex-1 justify-between items-center'>
          <Chip
            className='mt-1 h-[40px] flex flex-1'
            onClick={handleIvaPercent}
            label={`IVA ${ivaPercent * 100}%`}
            classes={{ label: 'px-2' }}
            color='primary'
            variant='outlined'
          />
          <Chip
            className='mt-1 h-[40px] flex flex-1'
            onClick={handleIepsPercent}
            label={`IEPS ${iepsPercent * 100}%`}
            classes={{ label: 'px-2' }}
            color='primary'
            variant='outlined'
          />
        </Grid>
        <Grid item xs={2} className='flex flex-1 justify-between items-center'>
          <Chip
            className='mt-1 h-[40px] flex flex-1'
            onClick={handleTaxes}
            label={addTaxes ? 'Añadir Impuestos' : 'Desglosar Impuestos'}
            classes={{ label: 'px-2' }}
            color='secondary'
            variant='outlined'
          />
        </Grid>
        <Grid item xs={4} md={2}>
          <TextField
            name='quantity'
            label='Cant.'
            type='number'
            variant='outlined'
            onChange={handleChange}
            value={values.quantity}
            error={errors.quantity}
            noFormat
          />
        </Grid>
        <Grid item xs={4} md={2}>
          <TextField
            name='discount'
            label={`Dscto. ${discountTypeVar}`}
            type='number'
            variant='outlined'
            onChange={handleChange}
            value={values.discount}
            error={errors.discount}
            noFormat
          />
        </Grid>
        <Grid item xs={4} md={2}>
          <TextField
            name='unitPrice'
            label='Precio Unitario'
            variant='outlined'
            type='number'
            onChange={handleChange}
            value={values.unitPrice}
            error={errors.unitPrice}
            noFormat
          />
        </Grid>
        <Grid item xs={4} md={2}>
          <DateField
            name='outDate'
            label='Fecha de Salida'
            variant='outlined'
            onChange={handleDateChange}
            value={values.outDate}
            error={errors.outDate}
            noFormat
            size='small'
          />
        </Grid>
      </Grid>
    </LinearForm>
  ) : (
    <div />
  );
};

export default Form;
