var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var getBranchFragment = function () { return ({
    name: '',
    prefix: '',
    phone: '',
    altPhone: '',
    type: 1,
    addressLine1: '',
    addressLine2: '',
    city: '',
    municipality: '',
    state: '',
    zipCode: '',
    startHour: undefined,
    endHour: undefined,
}); };
export var getDefaultBranchCreateForm = function () { return ({
    name: '',
    prefix: '',
    phone: '',
    altPhone: '',
    type: 1,
    addressLine1: '',
    addressLine2: '',
    city: '',
    municipality: '',
    state: '',
    zipCode: '',
    businessName: '',
    identifier: '',
    taxRegime: '',
}); };
export var getDefaultBranchEditForm = function (data) { return ({
    name: (data === null || data === void 0 ? void 0 : data.name) || '',
    prefix: (data === null || data === void 0 ? void 0 : data.prefix) || '',
    phone: (data === null || data === void 0 ? void 0 : data.phone) || '',
    altPhone: (data === null || data === void 0 ? void 0 : data.altPhone) || '',
    type: (data === null || data === void 0 ? void 0 : data.type) || 1,
    addressLine1: (data === null || data === void 0 ? void 0 : data.addressLine1) || '',
    addressLine2: (data === null || data === void 0 ? void 0 : data.addressLine2) || '',
    city: (data === null || data === void 0 ? void 0 : data.city) ? data.city : '',
    municipality: (data === null || data === void 0 ? void 0 : data.municipality) || '',
    state: (data === null || data === void 0 ? void 0 : data.state) || '',
    zipCode: (data === null || data === void 0 ? void 0 : data.zipCode) || '',
    startHour: (data === null || data === void 0 ? void 0 : data.startHour) || undefined,
    endHour: (data === null || data === void 0 ? void 0 : data.endHour) || undefined,
    reference: (data === null || data === void 0 ? void 0 : data.reference) || undefined,
}); };
export var getDefaultBranchBillingInformationEditValue = function (data) { return ({
    businessName: (data === null || data === void 0 ? void 0 : data.businessName) || '',
    identifier: (data === null || data === void 0 ? void 0 : data.identifier) || '',
    taxRegime: (data === null || data === void 0 ? void 0 : data.taxRegime) || '',
}); };
// export const getDefaultBranchEditForm = (): BranchInputs & { id: number | undefined } => ({
//   id: undefined,
//   ...getBranchFragment(),
// });
export var getDefaultBranchBillingInformationEditForm = function () { return ({
    id: undefined,
    businessName: '',
    identifier: '',
    taxRegime: '601',
}); };
var getDefaultBranchValues = function () { return (__assign(__assign({}, getBranchFragment()), getDefaultBranchBillingInformationEditForm())); };
export default getDefaultBranchValues;
