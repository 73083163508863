var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useRef, useState } from 'react';
import { useReactiveVar } from '@apollo/client';
import CreditNoteTypeFieldComponent from './TypeField';
import CreditNoteReferenceFieldComponent from './ReferenceField';
import TextFieldComponent from '../../../../../../presentationals/FormComponents/TextField';
import { inputError } from '../../../../../../apollo/cache';
import CreditNoteDevolutionTypeFieldComponent from './DevolutionTypeField';
import ProductFieldComponent from '../../../../../../presentationals/FormComponents/ProductField';
import CreditNoteQuantityFieldComponent from './QuantityField';
import ButtonComponent from '../../../../../../presentationals/Button';
var CreateCreditNoteFormComponent = function () {
    var productFieldRef = useRef(null);
    var defaultValues = {
        type: '1',
        reference: '01',
        commentary: '',
        bonus: '',
        devolutionType: '1',
        quantity: '1',
        totalToDiscount: '',
        productId: null,
    };
    var errors = useReactiveVar(inputError);
    var _a = useState(defaultValues), values = _a[0], setValues = _a[1];
    var handleChange = function (name, value) {
        setValues(function (values) {
            var _a;
            return (__assign(__assign({}, values), (_a = {}, _a[name] = value, _a)));
        });
    };
    var handleProductClear = function () {
        setValues(function (values) { return (__assign(__assign({}, values), { productId: null })); });
    };
    var handleAddToList = function () {
        console.log('heyy');
    };
    return (React.createElement("div", { className: 'flex flex-1 flex-col' },
        React.createElement(CreditNoteTypeFieldComponent, { onChange: handleChange, value: values.type }),
        values.type === '1' && (React.createElement(TextFieldComponent, { name: 'bonus', label: 'Importe a bonificar', onChange: handleChange, value: values.bonus, error: errors.bonus })),
        React.createElement(CreditNoteReferenceFieldComponent, { onChange: handleChange, value: values.reference }),
        React.createElement(TextFieldComponent, { name: 'commentary', label: 'Anotaciones para el documento', onChange: handleChange, value: values.commentary, rows: 2, multiline: true }),
        values.type === '2' && (React.createElement(React.Fragment, null,
            React.createElement(CreditNoteDevolutionTypeFieldComponent, { onChange: handleChange, value: values.devolutionType }),
            React.createElement(ProductFieldComponent, { ref: productFieldRef, searchBy: 'select', name: 'product', label: 'Producto', onChange: handleChange, onClear: handleProductClear }),
            values.devolutionType === '1' && (React.createElement(CreditNoteQuantityFieldComponent, { onChange: handleChange, value: values.quantity })),
            values.devolutionType === '2' && (React.createElement(TextFieldComponent, { name: 'totalToDiscount', label: 'Importe a descontar', onChange: handleChange, value: values.totalToDiscount })),
            React.createElement(ButtonComponent, { className: 'mt-2', onClick: handleAddToList, label: 'A\u00F1adir' })))));
};
export default CreateCreditNoteFormComponent;
