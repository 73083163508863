import { TableRow } from '@mui/material';
import React from 'react';
import { isEditFormOpen } from '../../../../../apollo/cache';
var BodyTableRowSaleExpenseComponent = function (props) {
    var handleClick = function () {
        isEditFormOpen(props.id);
    };
    return (React.createElement(TableRow, { className: props.index % 2 ? 'bg-slate-200' : '', onClick: handleClick }, props.children));
};
export default BodyTableRowSaleExpenseComponent;
