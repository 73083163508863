import React from 'react';

import Container from '../../presentationals/Module.v2/Container';

import Table from './_table';
import Filter from './_filter';

const title = 'Compras por productos acumulados';
const operation = ['purchaseOrderDetailReport', 'purchaseOrderDetailReport'];

const ClassificationComponent = () => {
  return (
    <>
      <Container noSearch title={title} noFilter noTable filter customXls>
        <Table title={title} operation={operation} />
      </Container>
      <Filter />
    </>
  );
};

export default ClassificationComponent;
