import React, { forwardRef, useImperativeHandle, useState } from 'react';
import TextFieldComponent from '../../../../../../../presentationals/FormComponents/TextField';
var QuantityBarcodeLabelInputComponent = forwardRef(function (props, ref) {
    var _a = useState(props.quantity.toString()), value = _a[0], setValue = _a[1];
    var handleChange = function (name, value) {
        setValue(value);
    };
    var handleGetValue = function () { return value; };
    useImperativeHandle(ref, function () { return ({
        getValue: handleGetValue,
    }); });
    return (React.createElement(TextFieldComponent, { name: 'quantity', label: 'Cantidad', value: value, onChange: handleChange }));
});
export default QuantityBarcodeLabelInputComponent;
