import { useParams } from 'react-router-dom';
import useApolloMutation from '../../../../../../hooks/useApolloMutation';
import GQL_PRODUCT from '../../../../../../apollo/gql/product';
var useCreateProductAttribute = function () {
    var params = useParams();
    var _a = useApolloMutation(GQL_PRODUCT.CREATE_PRODUCT_ATTRIBUTE, {
        parent: [params.productId, 'Product'],
        name: 'productAttribute',
        fragment: GQL_PRODUCT.FRAGMENT_PRODUCT_ATTRIBUTE,
    }, { productId: params.productId }), createProductAttribute = _a[0], loading = _a[1].loading;
    return { createProductAttribute: createProductAttribute, loading: loading };
};
export default useCreateProductAttribute;
