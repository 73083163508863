var getDefaultGlobalInvoiceValues = function () { return ({
    type: 2,
    concept: '',
    unitPrice: '',
    ivaPercent: '16',
    satUnitKey: '',
    satProductKey: '',
    paymentForm: -1,
    paymentMethod: 'PUE',
    date: new Date(),
    commentary: '',
    useCfdi: 'S01',
    customer: null,
}); };
export default getDefaultGlobalInvoiceValues;
