var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { useMutation } from '@apollo/client';
import GQL_PRODUCT from '../apollo/gql/product';
import getInputErrorFormat from '../containers/sale.v2/Action/helpers/getInputErrorFormat';
import getApolloQueryArgs from '../apollo/helpers/getApolloQueryArgs';
var useCreateProductMutation = function () {
    var _a = useMutation(GQL_PRODUCT.CREATE_PRODUCT, {
        update: function (cache, _a) {
            var data = _a.data;
            try {
                if (!data)
                    return;
                var response_1 = data.createProductV2;
                cache.modify({
                    fields: {
                        products: function (existingRefs, _a) {
                            var fieldName = _a.fieldName, storeFieldName = _a.storeFieldName;
                            var args = getApolloQueryArgs(storeFieldName, fieldName);
                            if (args.type && !args.type.includes(response_1.type))
                                return existingRefs;
                            var newRef = cache.writeFragment({
                                data: __assign({}, data.createProductV2),
                                fragment: GQL_PRODUCT.FRAGMENT_PRODUCT,
                                fragmentName: 'FragmentProduct',
                            });
                            return __assign(__assign({}, existingRefs), { count: existingRefs.count + 1, rows: __spreadArray([newRef], existingRefs.rows, true) });
                        },
                    },
                });
            }
            catch (error) {
                getInputErrorFormat(error);
            }
        },
    }), create = _a[0], _b = _a[1], loading = _b.loading, error = _b.error;
    return { create: create, loading: loading, error: error };
};
export default useCreateProductMutation;
