import React from 'react';
import FooterDialogComponent from '../../../../presentationals/Dialog/Footer';
import ClearButtonFooterCreateBranchComponent from './ClearButton';
import CreateButtonFooterCreateBranchComponent from './CreateButton';
var FooterCreateCompanyComponent = function () {
    return (React.createElement(FooterDialogComponent, null,
        React.createElement("div", null),
        React.createElement("div", null,
            React.createElement(ClearButtonFooterCreateBranchComponent, null),
            React.createElement(CreateButtonFooterCreateBranchComponent, null))));
};
export default FooterCreateCompanyComponent;
