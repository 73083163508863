import React from 'react';
import { useParams } from 'react-router-dom';
import BodyEcommerceProductComponent from './Body';
import LoadingProgress from '../../../../presentationals/LoadingProgress';
import useApolloQuery from '../../../../hooks/useApolloQuery';
import GQL_PRODUCT from '../../../../apollo/gql/product';
import IsPublicEcommerceProductComponent from './IsPublic';
var EcommerceProductComponent = function () {
    var params = useParams();
    var _a = useApolloQuery(GQL_PRODUCT.GET_PRODUCT_ECOMMERCE_BY_ID, {
        variables: { id: params.productId },
    }), data = _a.data, loading = _a.loading;
    if (loading || !data)
        return React.createElement(LoadingProgress, null);
    return (React.createElement("div", { className: 'px-4 py-3 flex flex-1 flex-col basis-0 overflow-auto' },
        React.createElement("div", { className: 'text-base font-medium mb-1' }, "Configuraci\u00F3n en ecommerce/tienda en l\u00EDnea"),
        React.createElement("div", { className: 'text-sm sm:text-base text-gray-600' }, "Aqu\u00ED podr\u00E1s configurar tu producto para la tienda en l\u00EDnea, una configuraci\u00F3n precisa y detallada de cada art\u00EDculo es el cimiento de una experiencia de compra excepcional para tus clientes y, en \u00FAltima instancia, la clave para el \u00E9xito de tu tienda en l\u00EDnea."),
        React.createElement("div", { className: 'my-2' },
            React.createElement(IsPublicEcommerceProductComponent, { value: data.public || false })),
        data.public && (React.createElement(BodyEcommerceProductComponent, { toShipping: data.toShipping, ProductMeasurements: {
                weight: data.weight || '',
                length: data.length || '',
                width: data.width || '',
                height: data.height || '',
            }, ProductAttributes: data.ProductAttributes }))));
};
export default EcommerceProductComponent;
