import React from 'react';
import { useReactiveVar } from '@apollo/client';
import { operationDateSaleV2 } from '../../../../../../apollo/cache';
import { dateFormat } from '../../../../../../helpers';
import EditOperationDateButtonComponent from './EditButton';
import EditOperationDateDialogComponent from './EditDialog';
var OperantionDateSaleV2Component = function () {
    var operationDateSaleV2Var = useReactiveVar(operationDateSaleV2);
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: 'flex flex-col items-end' },
            React.createElement("div", { className: 'font-medium' }, "Fecha de operaci\u00F3n"),
            React.createElement("div", { className: 'flex items-center' },
                React.createElement("div", { className: 'mr-2' },
                    React.createElement(EditOperationDateButtonComponent, null)),
                React.createElement("div", null, dateFormat(operationDateSaleV2Var || new Date())))),
        React.createElement(EditOperationDateDialogComponent, null)));
};
export default OperantionDateSaleV2Component;
