var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { useReactiveVar } from '@apollo/client';
import TextFieldComponent from '../../../../presentationals/FormComponents/TextField';
import { inputError } from '../../../../apollo/cache';
import SubtitleDialogComponent from '../../../../presentationals/Dialog/helpers/Subtitle';
import RoleFieldComponent from './RoleField';
import BranchFieldComponent from './BranchField';
import SwitchComponent from '../../../../presentationals/FormComponents/Switch';
import getDefaultEmployeeForm from '../../helpers/getDefaultEmployeeForm';
var BodyEmployeeComponent = forwardRef(function (props, ref) {
    var defaultValues = getDefaultEmployeeForm();
    var _a = useState(defaultValues), values = _a[0], setValues = _a[1];
    var errors = useReactiveVar(inputError);
    useEffect(function () {
        if (props.defaultValues)
            setValues(getDefaultEmployeeForm(props.defaultValues));
    }, [props.defaultValues]);
    var handleChange = function (name, value) {
        var _a;
        setValues(__assign(__assign({}, values), (_a = {}, _a[name] = value, _a)));
    };
    var handleSwitchChange = function (name, isActive) {
        var _a;
        getDefaultEmployeeForm(__assign(__assign({}, values), (_a = {}, _a[name] = isActive, _a)));
    };
    var handleKeyPress = function (e) {
        if (e.key === 'Enter') {
            e.preventDefault();
            props.onPressEnter && props.onPressEnter();
        }
    };
    var getValues = function () { return values; };
    var handleClear = function () {
        setValues(defaultValues);
    };
    useImperativeHandle(ref, function () { return ({
        getValues: getValues,
        onClear: handleClear,
    }); });
    return (React.createElement("div", { className: 'flex-1 grid grid-cols-1 md:grid-cols-4 md:gap-4 px-4 py-2 overflow-auto' },
        React.createElement("div", { className: 'mb-4 md:mb-0' },
            React.createElement(SubtitleDialogComponent, { label: 'Datos personales' }),
            React.createElement(TextFieldComponent, { name: 'firstName', label: 'Nombre', value: values.firstName, error: errors.firstName, onChange: handleChange, onKeyDown: handleKeyPress }),
            React.createElement(TextFieldComponent, { name: 'lastName', label: 'Apellidos', value: values.lastName, error: errors.lastName, onChange: handleChange, onKeyDown: handleKeyPress }),
            React.createElement(TextFieldComponent, { name: 'phone', label: 'Tel\u00E9fono', value: values.phone, error: errors.phone, onChange: handleChange, onKeyDown: handleKeyPress }),
            React.createElement(TextFieldComponent, { name: 'email', label: 'Correo electr\u00F3nico', value: values.email, error: errors.email, onChange: handleChange, onKeyDown: handleKeyPress })),
        React.createElement("div", { className: 'mb-4 md:mb-0' },
            React.createElement(SubtitleDialogComponent, { label: 'Responsabilidad en la empresa' }),
            React.createElement(TextFieldComponent, { name: 'position', label: 'Puesto o responsabilidad', value: values.position, onChange: handleChange, onKeyDown: handleKeyPress }),
            React.createElement(BranchFieldComponent, { onChange: handleChange, value: values.branchId }),
            React.createElement(SwitchComponent, { className: 'mt-2', name: 'isSeller', label: '\u00BFParticipa en procesos de venta?', defaultValue: values.isSeller, onChange: handleSwitchChange })),
        React.createElement("div", { className: 'mb-4 md:mb-0' },
            React.createElement(SubtitleDialogComponent, { label: 'Datos de la sesi\u00F3n y seguridad' }),
            !values.id && (React.createElement(TextFieldComponent, { name: 'password', label: 'Contrase\u00F1a web', value: values.password, error: errors.password, onChange: handleChange, onKeyDown: handleKeyPress, type: 'password' })),
            !values.id && (React.createElement(TextFieldComponent, { name: 'userPassword', label: 'Contrase\u00F1a de punto de venta', value: values.userPassword, error: errors.userPassword, onChange: handleChange, onKeyDown: handleKeyPress, type: 'password' })),
            React.createElement(RoleFieldComponent, { onChange: handleChange, value: values.roleId }))));
});
export default BodyEmployeeComponent;
