import React from 'react';
import Title from '../../../../../../sale.v2/Action/Customer/Form/Create/Body/helpers/title';
import AgendaSettings from './AgendaSettings';
import NumberOfScheludeSwitch from './NumberOfScheludeSwitch';
var ScheduleAvailabilityDialogSettingButtonFooterFormCalendarComponent = function () {
    return (React.createElement("div", { className: 'px-4 py-2' },
        React.createElement(Title, { label: 'Configuraci\u00F3n de la agenda' }),
        React.createElement(NumberOfScheludeSwitch, null),
        React.createElement(AgendaSettings, null)));
};
export default ScheduleAvailabilityDialogSettingButtonFooterFormCalendarComponent;
