import React from 'react';
import MultiCompanyReportComponent from '../containers/multiCompanyReport';
import DetailedReportComponent from '../containers/company/DetailedReport';
var getMultiCompanyReportChildrenRoutes = function () {
    return [
        { index: true, element: React.createElement(MultiCompanyReportComponent, null) },
        {
            path: ':branchId/detail',
            element: React.createElement(DetailedReportComponent, null),
        },
    ];
};
export default getMultiCompanyReportChildrenRoutes;
