import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Divider } from '@mui/material';
import useApolloLazyQuery from '../../../hooks/useApolloLazyQuery';
import GQL_ACCOUNT_RECEIVABLE from '../../../apollo/gql/accountReceivable';
import LoadingProgress from '../../../presentationals/LoadingProgress';
import GoBackButtonComponent from '../../../presentationals/GoBackButton';
import ErrorDataComponent from '../../../presentationals/ErrorData';
import FooterModuleV3Component from '../../../presentationals/Module.v3/Footer';
import { dateFormat } from '../../../helpers';
import convertToCurrencyLabel from '../../../helpers/convertToCurrencyLabel';
import SendEmailSaleButtonComponent from './SendEmailButton';
import SendSaleByEmailDialogComponent from '../../../presentationals/SendSaleByEmailDialog';
import SettingsReceivableComponent from './Settings';
import DownloadAccountReceivableFilesButtonComponent from './DownloadFilesButton';
import CreatePaymentButtonComponent from './CreatePaymentButton';
import PaymentDialogComponent from './PaymentDialog';
import TransactionsTableAccounReceivableComponent from './TransactionsTable';
import ProductsTableAccounReceivableComponent from './ProductsTable';
import CancelPaymentDialogComponent from './CancelPaymentDialog';
import CreateTransactionAccountDialogComponent from '../../../presentationals/TransactionAccount/CreateDialog';
var ReceivableComponent = function () {
    var params = useParams();
    var _a = useApolloLazyQuery(GQL_ACCOUNT_RECEIVABLE.GET_ACCOUNT_RECEIVABLE_BY_ID), getData = _a[0], _b = _a[1], loading = _b.loading, data = _b.data;
    useEffect(function () {
        if (params.accountReceivableId)
            getData({ variables: { id: params.accountReceivableId } });
    }, [params]);
    if (loading)
        return React.createElement(LoadingProgress, null);
    if (!data)
        return React.createElement(ErrorDataComponent, null);
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: 'flex flex-1 flex-col' },
            React.createElement("div", { className: 'flex items-center px-4 py-0 border-0 border-b border-[#d6d6d6] border-solid' },
                React.createElement("div", { className: 'flex flex-1 items-center' },
                    React.createElement("div", { className: 'mr-3' },
                        React.createElement(GoBackButtonComponent, { relative: 'route' })),
                    React.createElement("div", { className: 'text-lg text-gray-700 tracking-tight' }, "Cuenta por cobrar")),
                React.createElement("div", { className: 'flex flex-row' },
                    React.createElement(CreatePaymentButtonComponent, { accountReceivableId: data.id }),
                    React.createElement(SendEmailSaleButtonComponent, { saleId: data.Sale.id }),
                    React.createElement(DownloadAccountReceivableFilesButtonComponent, { saleId: data.Sale.id }))),
            React.createElement("div", { className: 'flex flex-1 flex-col' },
                React.createElement("div", { className: 'flex flex-1 flex-col px-4 py-3' },
                    React.createElement("div", { className: 'grid grid-cols-1 sm:grid-cols-4 flex-1 flex-col overflow-y-auto basis-0' },
                        React.createElement("div", { className: 'flex flex-1 flex-col pr-4' },
                            React.createElement("div", { className: 'text-base' }, "Folio"),
                            React.createElement("div", { className: 'text-3xl' }, data.folio),
                            React.createElement("div", { className: 'text-base mt-2' }, "Fecha de emisi\u00F3n"),
                            React.createElement("div", { className: 'font-medium' }, dateFormat(data.clientCreatedAt)),
                            React.createElement("div", { className: 'text-base mt-2' }, "Cliente"),
                            React.createElement("div", { className: 'font-medium' }, data.customer),
                            data.customerPhone && React.createElement("div", null, data.customerPhone),
                            React.createElement("div", { className: 'text-base mt-4' }, "Cargo"),
                            React.createElement("div", { className: 'text-xl' },
                                "$",
                                convertToCurrencyLabel(data.total)),
                            React.createElement("div", { className: 'text-base' }, "Abono"),
                            React.createElement("div", { className: 'text-xl' },
                                "$",
                                convertToCurrencyLabel(data.payment)),
                            React.createElement("div", { className: 'text-base' }, "Saldo"),
                            React.createElement("div", { className: 'text-3xl' },
                                "$",
                                convertToCurrencyLabel(data.balance)),
                            React.createElement(Divider, { className: 'my-3' }),
                            React.createElement(SettingsReceivableComponent, { commentary: data.Sale.commentary, timeLimit: data.timeLimit || data.customerCreditDays })),
                        React.createElement("div", { className: 'flex flex-1 col-span-3 flex-col' },
                            React.createElement(TransactionsTableAccounReceivableComponent, { Transactions: data.Transactions }),
                            React.createElement(ProductsTableAccounReceivableComponent, { SaleProducts: data.Sale.SaleProducts }))),
                    React.createElement(FooterModuleV3Component, null)))),
        React.createElement(SendSaleByEmailDialogComponent, null),
        React.createElement(PaymentDialogComponent, null),
        React.createElement(CancelPaymentDialogComponent, null),
        React.createElement(CreateTransactionAccountDialogComponent, null)));
};
export default ReceivableComponent;
