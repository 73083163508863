import { gql } from '@apollo/client';

const GET = gql`
  query PurchaseOrderDetailReport(
    $limit: Int, $offset: Int, $branchId: [ID] $start: Date $end: Date, $classificationId: ID, $productId: ID
  ) {
    purchaseOrderDetailReport(
      limit: $limit
      offset: $offset
      branchId: $branchId
      start: $start
      end: $end
      classificationId: $classificationId
      productId: $productId
    ) {
      count
      rows {
        id
        Product {
          id
          name
          code
          Classification {
            name
          }
          UnitBase {
            name
          }
          PresentationBase {
            name
          }
        }
        PurchaseOrderDetails {
          Branch {
            id
            name
          }
          quantity
          cost
        }
      }
    }
  }
`;

export default { GET };
