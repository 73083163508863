import React, { useRef } from 'react';
import MenuPopoverSearchProductByFieldComponent from './Menu';
import LabelPopoverSearchProductByFieldComponent from './Label';
var PopoverSearchProductByFieldComponent = function (props) {
    var menuSearchProductByFieldRef = useRef(null);
    var handleClick = function (event) {
        var _a;
        if (props.disabled)
            return;
        (_a = menuSearchProductByFieldRef.current) === null || _a === void 0 ? void 0 : _a.open(event.currentTarget);
    };
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { role: 'button', tabIndex: -1, onClick: handleClick, className: 'flex flex-col justify-center mr-2 cursor-pointer' },
            React.createElement("div", { className: 'text-xs text-gray-400 leading-none tracking-tighter' }, "B\u00FAsqueda por:"),
            React.createElement(LabelPopoverSearchProductByFieldComponent, null)),
        React.createElement(MenuPopoverSearchProductByFieldComponent, { ref: menuSearchProductByFieldRef })));
};
export default PopoverSearchProductByFieldComponent;
