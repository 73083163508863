import React, { useEffect } from 'react';
import { Skeleton } from '@mui/material';
import useInventoryReportStore from '../../../../store/inventoryReport';
import useApolloLazyQuery from '../../../../hooks/useApolloLazyQuery';
import GQL_BRANCH from '../../../../apollo/gql/branch';
var SelectBranchInventoryReportLabelComponent = function () {
    var branchId = useInventoryReportStore(function (state) { return state.branchId; });
    var _a = useApolloLazyQuery(GQL_BRANCH.GET_BRANCH_BY_ID), getBranch = _a[0], _b = _a[1], loading = _b.loading, data = _b.data;
    useEffect(function () {
        if (branchId)
            getBranch({ variables: { id: branchId } });
    }, [branchId]);
    if (loading)
        return React.createElement(Skeleton, { className: 'w-24 leading-none tracking-tighter' });
    return (React.createElement("div", { className: 'leading-none tracking-tighter text-gray-800' }, (data === null || data === void 0 ? void 0 : data.name) || 'Predefinida'));
};
export default SelectBranchInventoryReportLabelComponent;
