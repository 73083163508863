import React, { forwardRef, useImperativeHandle, useMemo, useState } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import ItemSearchCustomerByFieldComponent from '../../../../../../containers/globalInvoice/Sales/CustomerField/SearchCustomer/TextField/SearchBy/Menu/Item';
import MenuComponent from '../../../../../Menu';
import getCountryCodes from '../../../helpers/getCountryCodes';
var MenuCountryCodeFieldComponent = forwardRef(function (props, ref) {
    var _a = useState(null), anchorEl = _a[0], setAnchorEl = _a[1];
    var countryCodes = useMemo(getCountryCodes, []);
    var control = useFormContext().control;
    var onChange = useController({
        name: props.name,
        control: control,
    }).field.onChange;
    var handleOpen = function (anchorEl) {
        setAnchorEl(anchorEl);
    };
    var handleClose = function () {
        setAnchorEl(null);
    };
    var handleClick = function (name, value) {
        onChange(value);
        handleClose();
    };
    useImperativeHandle(ref, function () { return ({
        open: handleOpen,
    }); });
    return (React.createElement(MenuComponent, { id: 'SearchCountryCodeFieldMenu', anchorEl: anchorEl, onClose: handleClose }, countryCodes.map(function (el) { return (React.createElement(ItemSearchCustomerByFieldComponent, { key: el, name: el, value: el, onClick: handleClick })); })));
});
export default MenuCountryCodeFieldComponent;
