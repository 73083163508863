import XLSX from 'xlsx';
import { format } from 'date-fns';
import rowFormat from '../../../presentationals/Module.v2/Table/Body/format';

export default (title, keys, data) => {
  const filename = `${title}_${format(new Date(), 'dd-MMM-yyyy_HH-mm')}.xlsx`;
  const wb = XLSX.utils.book_new();

  const header = keys.map(el => el.label);

  const ws = XLSX.utils.json_to_sheet([], { header, skipHeader: true });

  const tmp = Object.keys(data).reduce((array, keyCh) => {
    const response = data[keyCh].data.reduce((array, el) => {
      const newObject = keys.reduce((newObject, { key, strcat, label, format: dataFormat }) => {
        let dataFormatFix = dataFormat;
        let value = key.includes(' ')
          ? key.includes(' && ')
            ? key.split(' && ').reduce((stringPincipal, elPincipal) => {
                const res = elPincipal.split(' ').reduce((string, elCh) => {
                  const tmpData = string[elCh] || '-';
                  return tmpData;
                }, el);
                return stringPincipal !== '' ? `${stringPincipal}${strcat || ' '}${res}` : res;
              }, '')
            : key.split(' ').reduce((string, elCh) => {
                const tmpData = string[elCh] || '-';
                return tmpData;
              }, el)
          : el[key];
        if (dataFormat === 'currency') {
          dataFormatFix = null;
          value = !Number.isNaN(parseFloat(value)) ? parseFloat(value) : 0;
        }
        return { ...newObject, [label]: rowFormat(value, dataFormatFix) };
      }, {});
      return [...array, newObject];
    }, []);
    return [
      ...array,
      {
        Folio: data[keyCh].customerName,
        'Fecha de Compra': '',
        'Fecha de Vencimiento': '',
        Factura: '',
        OC: '',
        Moneda: '',
        Descuento: '',
        Subtotal: '',
        IVA: '',
        Total: data[keyCh].total,
        Dls: '',
        Abonado: data[keyCh].payment,
        Saldo: data[keyCh].balance,
      },
      ...response,
    ];
  }, []);

  XLSX.utils.sheet_add_json(ws, tmp, { skipHeader: false, origin: 'A1' });

  XLSX.utils.book_append_sheet(wb, ws, title);
  XLSX.writeFile(wb, filename);
};
