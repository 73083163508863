var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useEffect, useState } from 'react';
import { useReactiveVar } from '@apollo/client';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import FooterDialogComponent from '../../../../../presentationals/Dialog/Footer';
import ConfirmPickupButtonComponent from '../ConfirmPickupButton';
import ItemPickupRentComponent from './Item';
import { CloseRentItemForm, isEditPickupItemOpen } from '../../../../../apollo/cache';
import setNewPickupItem from './helpers/setNewPickupItem';
import getInputErrorFormat from '../../../../sale.v2/Action/helpers/getInputErrorFormat';
import { currencyFormat } from '../../../../../helpers';
import PrintOptionFooterConfirmDialogSaleV2Component from '../../../../sale.v2/ConfirmDialog/Footer/PrintOption';
var BodyCloseRentComponent = function (props) {
    var CloseRentItemFormVar = useReactiveVar(CloseRentItemForm);
    var isEditPickupItemOpenVar = useReactiveVar(isEditPickupItemOpen);
    var _a = useState([]), data = _a[0], setData = _a[1];
    var _b = useState(0), total = _b[0], setTotal = _b[1];
    useEffect(function () {
        var f = function () {
            try {
                var newData = setNewPickupItem(data, CloseRentItemFormVar);
                setData(newData);
                CloseRentItemForm({});
            }
            catch (error) {
                getInputErrorFormat(error);
            }
        };
        if (Object.keys(CloseRentItemFormVar).length > 0 && !isEditPickupItemOpenVar)
            f();
    }, [CloseRentItemFormVar, isEditPickupItemOpenVar]);
    useEffect(function () {
        if (props.SaleProducts)
            setData(props.SaleProducts.map(function (el) { return (__assign(__assign({}, el), { receivedQty: el.SaleProductRent
                    ? el.SaleProductRent.pickupQuantity
                        ? el.SaleProductRent.pickupQuantity.toString()
                        : ''
                    : el.quantity.toString(), commentary: el.SaleProductRent ? el.SaleProductRent.commentary : '', unitCost: '', totalCost: '' })); }));
    }, [props.SaleProducts]);
    useEffect(function () {
        setTotal(data.reduce(function (total, el) { return parseFloat(el.totalCost || '0') + total; }, 0));
    }, [data]);
    return (React.createElement("div", { className: 'flex flex-1 flex-col' },
        React.createElement("div", { className: 'flex flex-1 overflow-auto' },
            React.createElement(TableContainer, null,
                React.createElement(Table, { size: 'small' },
                    React.createElement(TableHead, null,
                        React.createElement(TableRow, null,
                            React.createElement(TableCell, null),
                            React.createElement(TableCell, { className: 'text-center bg-slate-400', colSpan: 2 }, "Cantidad"),
                            React.createElement(TableCell, null),
                            React.createElement(TableCell, { className: 'text-center bg-slate-300', colSpan: 2 }, "Cargo por baja de producto")),
                        React.createElement(TableRow, null,
                            React.createElement(TableCell, null, "Producto"),
                            React.createElement(TableCell, { className: 'text-center bg-slate-400' }, "Entregado"),
                            React.createElement(TableCell, { className: 'text-center bg-slate-400' }, "Recibido"),
                            React.createElement(TableCell, null, "Comentario"),
                            React.createElement(TableCell, { className: 'bg-slate-200' }, "Precio unitario"),
                            React.createElement(TableCell, { className: 'bg-slate-200' }, "Importe"))),
                    React.createElement(TableBody, null,
                        data.map(function (el) { return (React.createElement(ItemPickupRentComponent, { key: el.id, productName: el.Product.name, receivedQty: el.receivedQty, unitCost: el.unitCost, productSaleId: el.id },
                            React.createElement(TableCell, null, el.name),
                            React.createElement(TableCell, { className: 'text-center bg-slate-400' }, currencyFormat(el.quantity)),
                            React.createElement(TableCell, { className: "text-center bg-slate-400 ".concat(el.quantity.toString() !== el.receivedQty ? 'font-semibold' : '') }, el.receivedQty ? currencyFormat(el.receivedQty) : '-'),
                            React.createElement(TableCell, null, el.commentary ? el.commentary : '-'),
                            React.createElement(TableCell, { className: 'bg-slate-200' }, el.unitCost ? currencyFormat(el.unitCost) : '-'),
                            React.createElement(TableCell, { className: 'bg-slate-200' }, el.totalCost ? currencyFormat(el.totalCost) : '-'))); }),
                        React.createElement(TableRow, null,
                            React.createElement(TableCell, { colSpan: 5, className: 'text-right font-semibold' }, "Total"),
                            React.createElement(TableCell, { className: 'bg-slate-400 font-semibold' }, currencyFormat(total))))))),
        React.createElement(FooterDialogComponent, null,
            React.createElement("div", null,
                React.createElement(PrintOptionFooterConfirmDialogSaleV2Component, null)),
            React.createElement(ConfirmPickupButtonComponent, { Products: data }))));
};
export default BodyCloseRentComponent;
