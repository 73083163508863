import React, { useEffect } from 'react';
import useApolloLazyQuery from '../../../../../../../../hooks/useApolloLazyQuery';
import GQL_PRODUCT from '../../../../../../../../apollo/gql/product';
import AddImageButtonFormColorEcommerceProductComponent from '../AddImageButton';
import ImageComponent from '../../../../../Image/Item';
import DeleteButtonBodyFormColorEcommerceEditFormComponent from './DeleteButton';
var BodyFormColorEcommerceEditFormComponent = function (props) {
    var _a = useApolloLazyQuery(GQL_PRODUCT.GET_PRODUCT_ATTRIBUTE_FILES), getData = _a[0], data = _a[1].data;
    useEffect(function () {
        if (props.productAttributeId)
            getData({ variables: { productAttributeId: props.productAttributeId } });
    }, [props.productAttributeId]);
    if (!data)
        return React.createElement("div", null);
    return (React.createElement("div", { className: 'flex flex-1 flex-col p-4' },
        React.createElement("div", { className: 'text-base font-medium mb-1 flex' }, "Im\u00E1genes del color"),
        React.createElement("div", { className: 'text-sm sm:text-base text-gray-600' },
            "Las im\u00E1genes son una pieza fundamental al presentar un producto. A trav\u00E9s de ellas, los clientes pueden visualizar y conectar de manera directa con lo que est\u00E1n adquiriendo. En esta secci\u00F3n podr\u00E1s ",
            React.createElement("strong", null, "a\u00F1adir im\u00E1genes seg\u00FAn el color"),
            " de tu producto. De lo contrario, se utilizar\u00E1n las im\u00E1genes predeterminadas."),
        React.createElement("div", { className: 'my-4' },
            React.createElement(AddImageButtonFormColorEcommerceProductComponent, { productAttributeId: props.productAttributeId })),
        React.createElement("div", { className: 'flex flex-1 flex-wrap' },
            data.length === 0 && (React.createElement("div", { className: 'text-base text-gray-700' }, "Este color no cuenta con im\u00E1genes.")),
            React.createElement("div", { className: 'flex-1 flex flex-col sm:grid sm:grid-cols-6 sm:gap-4 overflow-auto' }, data.map(function (el) { return (React.createElement("div", { key: el.id, className: 'flex flex-col' },
                React.createElement(ImageComponent, { width: 'full', src: el.fileId.toString(), alt: "product-".concat(el.fileId) }),
                React.createElement(DeleteButtonBodyFormColorEcommerceEditFormComponent, { id: el.id }))); })))));
};
export default BodyFormColorEcommerceEditFormComponent;
