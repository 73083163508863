import React from 'react';

import { useReactiveVar } from '@apollo/client';

import { isSaleConfirmDialogOpen } from '../../../apollo/cache';

import Dialog from '../../../presentationals/Dialog';
import Body from './Body';
import Footer from './Footer';

const ConfirmDialogSaleV2Component = () => {
  const isSaleConfirmDialogOpenVar = useReactiveVar(isSaleConfirmDialogOpen);
  const handleClose = () => {
    isSaleConfirmDialogOpen(false);
  };
  return (
    <Dialog
      title='Configuración del pago y confirmación del pedido'
      fullScreen
      onClose={handleClose}
      open={isSaleConfirmDialogOpenVar}
    >
      <Body />
      <Footer />
    </Dialog>
  );
};

export default ConfirmDialogSaleV2Component;
