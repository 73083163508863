import React from 'react';
import RHFTextFieldComponent from '../../../../presentationals/RHForm/TextField';
import AddProductManufactureToListButton from './AddButton';
import RHFProductFieldComponent from '../../../../presentationals/RHForm/ProductField';
import ProductPresentationFieldProductManufactureListComponent from './ProductPresentationField';
import TableProductListComponent from './Table';
var ProductManufactureListComponent = function (props) {
    return (React.createElement("div", { className: 'flex flex-1 flex-col mt-2' },
        React.createElement("div", { className: 'text-gray-600 mb-1' }, props.label),
        React.createElement("div", { className: 'flex flex-1 flex-col border border-gray-400 border-solid rounded' },
            React.createElement("div", { className: 'flex flex-col sm:flex-row items-start gap-2 pt-3 px-3' },
                React.createElement("div", { className: 'w-full sm:w-auto' },
                    React.createElement(RHFTextFieldComponent, { type: 'number', name: "".concat(props.formName, ".qty"), label: 'Cantidad' })),
                React.createElement(RHFProductFieldComponent, { name: "".concat(props.formName, ".productId"), label: 'Producto' }),
                React.createElement("div", { className: 'w-full sm:min-w-[200px] sm:w-[200px]' },
                    React.createElement(ProductPresentationFieldProductManufactureListComponent, { formName: props.formName })),
                React.createElement(AddProductManufactureToListButton, { formName: props.formName, arrayName: props.name })),
            React.createElement(TableProductListComponent, { arrayName: props.name }))));
};
export default ProductManufactureListComponent;
