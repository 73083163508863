import React, { useState, useEffect, Fragment } from 'react';

import { useReactiveVar } from '@apollo/client';

import LinearProgress from '@mui/material/LinearProgress';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import PrintIcon from '@mui/icons-material/Print';
import CreditCardIcon from '@mui/icons-material/CreditCardOutlined';

import CONFIG from '../../../../config/config.json';

import { filter, actionForm } from '../../../../apollo/cache';

import { currencyFormat } from '../../../../helpers';

import Table from '../../../../presentationals/Module.v2/Table/TableContainer';
import Head from '../../../../presentationals/Module.v2/Table/Head';
import TableCell from '../../../../presentationals/Module.v2/Table/Body/TableCell';
import format from '../../../../presentationals/Module.v2/Table/Body/format';
import ActionButton from '../../../../presentationals/Module.v2/Table/Body/ActionButton';
import IconButton from '../../../../presentationals/IconButton';

import GQL from '../../_gql';

import XlsExport from '../xlsExport';
import PdfExport from '../pdfExport';
import CreateFormPayment from '../../CreateFormPayment';
import useApolloLazyQuery from '../../../../hooks/useApolloLazyQuery';

const Rows = [
  {
    label: 'NV',
    key: 'folio',
    strcat: ' ',
    filter: true,
  },
  { label: 'Fecha Emisión', key: 'clientCreatedAt', sort: 'text', format: 'dateTime' },
  { label: 'Fecha Limite', key: 'limitDate', sort: 'text', format: 'dateTime' },
  {
    label: 'Vendedor',
    key: 'seller',
    ifNull: 'Venta de Mostrador',
    sort: 'text',
  },
  { label: 'Cargo', key: 'total', sort: 'text', format: 'currency', align: 'right' },
  { label: 'Abono', key: 'payment', sort: 'text', format: 'currency', align: 'right' },
  { label: 'Saldo', key: 'balance', sort: 'text', format: 'currency', align: 'right' },
];

const CxcTableComponent = props => {
  const filterVar = useReactiveVar(filter);

  const [data, setData] = useState({});

  const [getMore, { data: response, loading }] = useApolloLazyQuery(GQL.GET);

  useEffect(() => {
    getMore({ variables: { ...filterVar, limit: 0 } });
  }, [filterVar]);

  useEffect(() => {
    if (response) {
      const tmpKeys = response.reduce(
        (object, el) => ({
          ...object,
          [el.customerId]: {
            customerName: el.customer,
            data: [],
          },
        }),
        {},
      );
      const tmpData = response.reduce((object, el) => {
        return {
          ...object,
          [el.customerId]: {
            customerName: object[el.customerId].customerName,
            data: [...object[el.customerId].data, el],
          },
        };
      }, tmpKeys);

      Object.keys(tmpData).forEach(key => {
        const { total, balance, payment } = tmpData[key].data.reduce(
          (totals, tmp) => {
            return {
              total: parseFloat(totals.total) + parseFloat(tmp.total),
              payment: parseFloat(totals.payment) + parseFloat(tmp.payment),
              balance: parseFloat(totals.balance) + parseFloat(tmp.balance),
            };
          },
          { total: 0, payment: 0, balance: 0 },
        );
        tmpData[key] = { ...tmpData[key], total, balance, payment };
      });

      setData(tmpData);
    }
  }, [response]);

  const __function = (e, { onClick, ...params }) => {
    if (typeof onClick === 'function') onClick(e, params);
  };

  const handleCustomerAccountReceivables = (e, { id }) => {
    window.open(`${CONFIG['restServer'].uri}/files/customerAccountReceivables/${id}/pdf`);
    // Pdf(id);
  };

  const handlePayment = (e, { id, data }) => {
    actionForm({
      ...actionForm(),
      isOpen: true,
      action: 'createAccountReceivablePayments',
      id,
      data,
    });
  };

  return (
    <>
      {loading && <LinearProgress color='secondary' />}
      <Table>
        <Head Rows={Rows} customXls />
        <TableBody>
          {Object.keys(data)
            .sort((a, b) => {
              if (data[a].customerName > data[b].customerName) return 1;
              if (data[a].customerName < data[b].customerName) return -1;
              return 0;
            })
            .map((key, i) => {
              return (
                <Fragment key={`${key}${i}`}>
                  <TableRow>
                    <TableCell colSpan={4}>
                      <strong>{data[key].customerName}</strong>
                    </TableCell>
                    <TableCell colSpan={1} align='right'>
                      <strong>${currencyFormat(data[key].total)}</strong>
                    </TableCell>
                    <TableCell colSpan={1} align='right'>
                      <strong>${currencyFormat(data[key].payment)}</strong>
                    </TableCell>
                    <TableCell colSpan={1} align='right'>
                      <strong>${currencyFormat(data[key].balance)}</strong>
                    </TableCell>
                    <TableCell align='right' noWrap>
                      <IconButton
                        key='Estado de Cuenta'
                        label='Estado de Cuenta'
                        action={__function}
                        params={{
                          id: key,
                          onClick: handleCustomerAccountReceivables,
                        }}
                        icon={<PrintIcon />}
                        className='p-1'
                        size='large'
                      />
                      <IconButton
                        key='Abonos a cuenta'
                        label='Abonos a cuenta'
                        action={__function}
                        params={{
                          id: key,
                          data: data[key].data,
                          onClick: handlePayment,
                        }}
                        icon={<CreditCardIcon />}
                        className='p-1'
                        size='large'
                      />
                    </TableCell>
                  </TableRow>
                  {data[key].data.map((dataCh, ii) => {
                    return (
                      <TableRow
                        key={ii}
                        style={{
                          background: dataCh.background
                            ? dataCh.background === 'danger'
                              ? '#ff4500'
                              : dataCh.background === 'warning'
                              ? '#ffff00'
                              : '#ffffff'
                            : '#ffffff',
                        }}
                      >
                        {Rows.map(el => {
                          let dataFormatTmp = el.format;
                          let value = el.key.includes(' ')
                            ? el.key.includes(' && ')
                              ? el.key.split(' && ').reduce((stringPincipal, elPincipal) => {
                                  const res = elPincipal.split(' ').reduce((string, elCh) => {
                                    const tmpData = string[elCh] || '';
                                    return tmpData;
                                  }, dataCh);
                                  return stringPincipal !== ''
                                    ? `${stringPincipal}${el.strcat || ' '}${res}`
                                    : res;
                                }, '')
                              : el.key.split(' ').reduce((string, elCh) => {
                                  const tmpData = string[elCh] || '';
                                  return tmpData;
                                }, dataCh)
                            : dataCh[el.key];
                          if (String(value).trim() === '') value = el.ifNull || '-';
                          if (dataFormatTmp === 'currency') {
                            dataFormatTmp = null;
                            value = !Number.isNaN(parseFloat(value)) ? parseFloat(value) : 0;
                          }
                          if (el.padStart && dataFormatTmp !== 'currency')
                            value = `${value}`.padStart(el.padStart[0], el.padStart[1]);
                          if (el.prefix && dataFormatTmp !== 'currency')
                            value = `${el.prefix} ${value}`;
                          return (
                            <TableCell
                              key={el.key}
                              align={el.align}
                              noWrap={!el.wrap}
                              url={value}
                              format={el.format}
                            >
                              {format(value, el.format)}
                            </TableCell>
                          );
                        })}
                        <TableCell align='right' noWrap>
                          <ActionButton data={dataCh} />
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </Fragment>
              );
            })}
        </TableBody>
      </Table>
      <PdfExport />
      <XlsExport title={props.title} data={data} keys={Rows} />
      <CreateFormPayment />
    </>
  );
};

export default CxcTableComponent;
