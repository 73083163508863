import React, { useState } from 'react';

import MoreVertIcon from '@mui/icons-material/MoreVertOutlined';

import IconButton from '../../../IconButton';

import Menu from './Menu';

const MenuButtonComponent = props => {
  const [openMenu, setOpenMenu] = useState(null);
  const handleOpenMenu = e => {
    setOpenMenu(e.currentTarget);
  };
  const handleCloseMenu = () => {
    setOpenMenu(null);
  };
  return (
    <>
      <IconButton
        label='Más Opciones'
        action={handleOpenMenu}
        aria-controls='module-menu'
        aria-haspopup='true'
        icon={<MoreVertIcon />}
        size='large'
      />
      <Menu
        return={!!props.return}
        anchorEl={openMenu}
        onClose={handleCloseMenu}
        customXls={props.customXls}
        customPdf={props.customPdf}
        additionalAction={props.additionalAction}
      />
    </>
  );
};

export default MenuButtonComponent;
