import React, { useState, useEffect } from 'react';

import { useQuery, useReactiveVar } from '@apollo/client';

import AlarmIcon from '@mui/icons-material/Alarm';
import StoreIcon from '@mui/icons-material/Store';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import FilterListIcon from '@mui/icons-material/FilterList';
import { TableContainer } from '@mui/material';
import Typography from '@mui/material/Typography';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import { hourFormat, dateFormat } from '../../../helpers';
import statusLabel from '../../../helpers/calendar.status';
import DateField from '../../../presentationals/DateField';
import IconButton from '../../../presentationals/IconButton';

import { filterForm, filter, isEditCalendarFormOpen } from '../../../apollo/cache';

import GQL from '../_gql';
import subscribeSocket from '../../../socket/helpers/subscribe';
import unsubscribeSocket from '../../../socket/helpers/unsubscribe';

const FilterButton = () => {
  const handleOpenFilter = () => filterForm(true);
  return (
    <IconButton label='Filtrar' action={handleOpenFilter} icon={<FilterListIcon />} size='large' />
  );
};

const RowComponent = props => {
  const style = [4, 5].includes(parseInt(props.status))
    ? {
        backgroundColor: '#fff',
        outline: `1px solid ${props.Tag.color}`,
        outlineOffset: '-1px',
        color: props.Tag.color,
      }
    : { background: props.Tag.color, color: '#fff' };
  const handleClick = () => {
    props.handleClick(props.id);
  };
  return (
    <TableRow onClick={handleClick}>
      <TableCell component='th' scope='row' style={style}>
        {hourFormat(props.start)}
      </TableCell>
      <TableCell component='th' scope='row' style={style}>
        {props.Customer && `${props.Customer.firstName} ${props.Customer.lastName}`}
      </TableCell>
      <TableCell component='th' scope='row' style={style}>
        {props.status === 1 ? (
          <AlarmIcon />
        ) : props.status === 4 ? (
          <StoreIcon />
        ) : props.status === 5 ? (
          <CheckCircleOutlineIcon />
        ) : (
          statusLabel[props.status]
        )}
      </TableCell>
    </TableRow>
  );
};

const AgendaComponent = props => {
  const filterVar = useReactiveVar(filter);

  const [date, setDate] = useState(new Date());
  const [dataSubsCalendarId, setDataSubsCalendarId] = useState(null);
  const [dataSubsPlaneZoneId, setDataSubsPlaneZoneId] = useState(null);

  const { data, loading, refetch } = useQuery(GQL.GET, {
    variables: {
      start: date,
      end: date,
      tagId:
        filterVar && filterVar.tags
          ? Object.keys(filterVar.tags).filter(key => filterVar.tags[key])
          : [],
      limit: 99,
      withCrm: true,
      order: ['start', 'asc'],
    },
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    const f = data => {
      setDataSubsCalendarId(data.calendarId);
      setDataSubsPlaneZoneId(data.planeZoneId);
    };
    subscribeSocket('EVENT_ZONE', f);
    return () => {
      unsubscribeSocket('EVENT_ZONE', f);
    };
  }, []);

  useEffect(() => {
    if (filterVar.tags && Object.keys(filterVar.tags).length > 0)
      refetch({
        start: date,
        end: date,
        tagId: Object.keys(filterVar.tags).filter(key => filterVar.tags[key]),
        limit: 99,
        withCrm: true,
        order: ['start', 'asc'],
      });
  }, [filterVar, date, dataSubsCalendarId, dataSubsPlaneZoneId]);

  const handleDateChange = (name, value) => {
    setDate(value);
  };

  const handleEvent = eventId => {
    isEditCalendarFormOpen(eventId);
  };

  return (
    <>
      <div className='flex justify-between items-center'>
        <Typography variant='subtitle1' gutterBottom>
          {`${!props.justDate ? 'Agenda' : ''} ${dateFormat(date)}`}
        </Typography>
        <div className='flex justify-between items-center'>
          <FilterButton />
          <DateField variant='outlined' size='small' onChange={handleDateChange} value={date} />
        </div>
      </div>
      <div className='flex flex-1 overflow-scroll'>
        <TableContainer className='p-0'>
          <Table size='small' aria-label='a dense table'>
            <TableHead>
              <TableRow>
                <TableCell>Fecha</TableCell>
                <TableCell>Paciente</TableCell>
                <TableCell>Estatus</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data &&
                data.calendars.rows.map(el => {
                  return (
                    ((!filterVar.showAll && [1, 2, 4].includes(parseInt(el.status))) ||
                      filterVar.showAll) && (
                      <RowComponent
                        key={el.id}
                        id={el.id}
                        handleClick={handleEvent}
                        Tag={el.Tag}
                        status={el.status}
                        start={el.start}
                        Customer={el.Customer}
                      />
                    )
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      <div className='flex items-center'>
        <AlarmIcon />
        Pendiente |
        <StoreIcon />
        En Evento |
        <CheckCircleOutlineIcon />
        Finalizó Evento
        {loading && '| Cargando información'}
      </div>
    </>
  );
};

export default AgendaComponent;
