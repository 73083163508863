import CREATE_PRODUCT_PROMOTION_PRICE from './createProductPrice';
import CREATE_PROMOTION from './createPromotion';
import DELETE_PRODUCT_PROMOTION from './deleteProductPromotion';
import DELETE_PRODUCT_PROMOTION_PRICE from './deleteProductPromotionPrice';
import DELETE_PROMOTION from './deletePromotion';
import DELETE_PROMOTION_FILE from './deletePromotionFile';
import FRAGMENT_PRODUCT_PROMOTION_PRICES from './fragmentProductPromotionPrices';
import FRAGMENT_PROMOTION_FILE from './fragmentPromotionFile';
import GET_PROMOTION_BY_ID from './getById';
import GET_PRODUCT_PROMOTION_BY_ID from './getProductPromotionById';
import GET_PRODUCT_PROMOTION_PRICE_BY_ID from './getProductPromotionPriceById';
import UPDATE_PRODUCT_PROMOTION_PRICE from './updateProductPrice';
import UPDATE_PRODUCT_PROMOTION from './updateProductPromotion';
import UPDATE_PROMOTION_FILE from './updatePromotionFile';
var GQL_PROMOTION = {
    CREATE_PRODUCT_PROMOTION_PRICE: CREATE_PRODUCT_PROMOTION_PRICE,
    CREATE_PROMOTION: CREATE_PROMOTION,
    DELETE_PRODUCT_PROMOTION: DELETE_PRODUCT_PROMOTION,
    DELETE_PRODUCT_PROMOTION_PRICE: DELETE_PRODUCT_PROMOTION_PRICE,
    DELETE_PROMOTION: DELETE_PROMOTION,
    DELETE_PROMOTION_FILE: DELETE_PROMOTION_FILE,
    FRAGMENT_PRODUCT_PROMOTION_PRICES: FRAGMENT_PRODUCT_PROMOTION_PRICES,
    FRAGMENT_PROMOTION_FILE: FRAGMENT_PROMOTION_FILE,
    GET_BY_ID: GET_PROMOTION_BY_ID,
    GET_PRODUCT_PROMOTION_BY_ID: GET_PRODUCT_PROMOTION_BY_ID,
    GET_PRODUCT_PROMOTION_PRICE_BY_ID: GET_PRODUCT_PROMOTION_PRICE_BY_ID,
    UPDATE_PRODUCT_PROMOTION: UPDATE_PRODUCT_PROMOTION,
    UPDATE_PRODUCT_PROMOTION_PRICE: UPDATE_PRODUCT_PROMOTION_PRICE,
    UPDATE_PROMOTION_FILE: UPDATE_PROMOTION_FILE,
};
export default GQL_PROMOTION;
