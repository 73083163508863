var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useRef, useState } from 'react';
import { Button } from '@mui/material';
import { useMutation } from '@apollo/client';
import axios, { AxiosError } from 'axios';
import getInputErrorFormat from '../../sale.v2/Action/helpers/getInputErrorFormat';
import ConfirmRequestDialogComponent from '../../../presentationals/Dialog/Footer/ActionRequestButton/ConfirmDialog';
import CONSTANTS_ERROR from '../../../constants/error';
import setInputErrorFormat from '../../sale.v2/Action/helpers/setInputErrorFormat';
import { inputError } from '../../../apollo/cache';
import GQL_INVOICE from '../../../apollo/gql/invoice';
import CONFIG from '../../../config/config.json';
import useBranchFieldStore from '../../../store/filterInputs/branchFieldStore';
import getSession from '../../../helpers/getSession';
var CreateGlobalInvoiceButtonComponent = function (props) {
    var branchId = useBranchFieldStore(function (state) { return state.id; });
    var confirmRequestDialogRef = useRef(null);
    var createGlobalInvoice = useMutation(GQL_INVOICE.CREATE_GLOBAL_INVOICE)[0];
    var _a = useState(false), loading = _a[0], setLoading = _a[1];
    var handleClick = function () {
        var _a;
        try {
            inputError({});
            var values = props.onClick();
            if (values.type === 2 && values.concept === '')
                throw new Error(setInputErrorFormat('concept', CONSTANTS_ERROR.UNDEFINED_INVOICE_CONCEPT));
            if (values.type === 2 && values.unitPrice === '')
                throw new Error(setInputErrorFormat('unitPrice', CONSTANTS_ERROR.UNDEFINED_INVOICE_TOTAL));
            if (values.type === 2 && values.satUnitKey === '')
                throw new Error(setInputErrorFormat('satUnitKey', CONSTANTS_ERROR.UNDEFINED_SAT_UNIT_KEY));
            if (values.type === 2 && values.satProductKey === '')
                throw new Error(setInputErrorFormat('satProductKey', CONSTANTS_ERROR.UNDEFINED_SAT_PRODUCT_KEY));
            if (!values.date)
                throw new Error(CONSTANTS_ERROR.UNDEFINED_DATE);
            (_a = confirmRequestDialogRef.current) === null || _a === void 0 ? void 0 : _a.open();
        }
        catch (error) {
            getInputErrorFormat(error);
        }
    };
    var handleCreate = function () { return __awaiter(void 0, void 0, void 0, function () {
        var values, session, token, authorization, formData, data, error_1;
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    _b.trys.push([0, 5, 6, 7]);
                    setLoading(true);
                    values = props.onClick();
                    if (!(values.type === 4)) return [3 /*break*/, 2];
                    if (!values.file)
                        throw new Error(setInputErrorFormat('file', 'Adjunta un documento xls para continuar'));
                    session = getSession();
                    token = session === null || session === void 0 ? void 0 : session.accessToken;
                    authorization = token ? { authorization: "Bearer ".concat(token) } : {};
                    formData = new FormData();
                    formData.append('file', values.file); // 'file' es el nombre del campo en el servidor
                    formData.append('currencyKey', 'MXN');
                    formData.append('exchangeRate', '1');
                    formData.append('useCfdi', values.useCfdi);
                    formData.append('paymentMethod', values.paymentMethod);
                    values.paymentForm !== -1 && formData.append('paymentMethodId', "".concat(values.paymentForm));
                    formData.append('operationDate', values.date.toISOString());
                    formData.append('type', values.type.toString());
                    formData.append('commentary', values.commentary);
                    values.customer && formData.append('customerId', values.customer.toString());
                    branchId && formData.append('branchId', branchId.toString());
                    return [4 /*yield*/, axios
                            .post("".concat(CONFIG.restServer.uri, "/cfdi/global/file"), formData, {
                            headers: __assign(__assign({}, authorization), { 'Content-Type': 'multipart/form-data' }),
                        })
                            .then(function (_a) {
                            var _b, _c;
                            var data = _a.data;
                            if (data.errorCode) {
                                if ((_b = data.errorMessage) === null || _b === void 0 ? void 0 : _b.message)
                                    throw new Error((_c = data.errorMessage) === null || _c === void 0 ? void 0 : _c.message);
                                throw new Error('Error desconocido');
                            }
                            window.open("".concat(CONFIG.restServer.uri, "/files/invoices/").concat(data.uuid, "/pdf"));
                        })
                            .catch(function (error) {
                            var _a, _b;
                            if (error instanceof AxiosError && ((_b = (_a = error.response) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.message))
                                throw new Error(error.response.data.message);
                            throw new Error('Error desconocido');
                        })];
                case 1:
                    _b.sent();
                    return [3 /*break*/, 4];
                case 2: return [4 /*yield*/, createGlobalInvoice({
                        variables: {
                            currencyKey: 'MXN',
                            exchangeRate: 1,
                            useCfdi: values.useCfdi,
                            paymentMethod: values.paymentMethod,
                            paymentMethodId: values.paymentForm !== -1 ? values.paymentForm : null,
                            operationDate: values.date,
                            type: values.type,
                            commentary: values.commentary,
                            Sales: values.Sales,
                            customerId: values.customer,
                            branchId: branchId,
                            Concept: values.type === 2
                                ? {
                                    description: values.concept,
                                    unitPrice: parseFloat(values.unitPrice),
                                    quantity: 1,
                                    discount: 0,
                                    iva: parseFloat(values.ivaPercent),
                                    ieps: 0,
                                    unitKey: values.satUnitKey,
                                    productKey: values.satProductKey,
                                }
                                : null,
                        },
                    })];
                case 3:
                    data = (_b.sent()).data;
                    window.open("".concat(CONFIG.restServer.uri, "/files/invoices/").concat(data.createGlobalInvoice.uuid, "/pdf"));
                    _b.label = 4;
                case 4:
                    (_a = confirmRequestDialogRef.current) === null || _a === void 0 ? void 0 : _a.close();
                    props.onCreated();
                    return [3 /*break*/, 7];
                case 5:
                    error_1 = _b.sent();
                    getInputErrorFormat(error_1);
                    return [3 /*break*/, 7];
                case 6:
                    setLoading(false);
                    return [7 /*endfinally*/];
                case 7: return [2 /*return*/];
            }
        });
    }); };
    return (React.createElement(React.Fragment, null,
        React.createElement(Button, { fullWidth: true, variant: 'contained', onClick: handleClick, color: 'success' }, "Crear factura global"),
        React.createElement(ConfirmRequestDialogComponent, { color: 'success', loading: loading, onClick: handleCreate, ref: confirmRequestDialogRef })));
};
export default CreateGlobalInvoiceButtonComponent;
