import React, { useState, useEffect } from 'react';

import { useReactiveVar, useQuery } from '@apollo/client';

import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';

import { clearForm, form, step, handleContinue } from '../../../../apollo/cache';

import TextField from '../../../../presentationals/FormComponents/TextField';
import Autocomplete from '../../../../presentationals/FormComponents/Autocomplete';
import DateField from '../../../../presentationals/FormComponents/DateField';
import SelectField from '../../../../presentationals/FormComponents/SelectField';

import { selectpaymentFormOptions } from '../../../../helpers/paymentForm.options';
import GQL_BRANCHES from '../../../branch/_gql';
import GQL_SALES from '../../../sale.report.v2/_gql';

import ProviderDialog from './provider.dialog';
import getSession from '../../../../helpers/getSession';
import GQL_PROVIDER from '../../../../apollo/gql/provider';

const defaultValues = {
  providerId: null,
  description: '',
  pursacheDate: new Date(),
  subFolio: '',
  paymentForm: '01',
  type: 1,
  branchId: '',
  currency: 'MXN',
  exchangeRate: 1,
  action: '2',
  saleId: null,
};

const currencyOptions = [
  { value: 'MXN', label: 'Pesos Mexicanos (MXN)' },
  { value: 'USD', label: 'Dólares Americanos (USD)' },
];

const paymentMethodOptions = [
  { value: 1, label: 'Contado' },
  { value: 2, label: 'Crédito' },
];

const actionOptions = [
  { label: 'Capturar gasto operativo', value: '2' },
  { label: 'Capturar gasto de orden de servicio', value: '3' },
  { label: 'Capturar gasto de obra/remodelación', value: '4' },

  { label: 'Recurso Humano', value: '5' },
  { label: 'Gasto de Mantenimiento', value: '6' },
  { label: 'Materiales Auxiliares', value: '7' },
  { label: 'Instalación', value: '8' },
  { label: 'Gasto Administrativo', value: '9' },
];

const AddButton = props => {
  return (
    <IconButton aria-label='add' onClick={props.onClick} color='secondary' size='small'>
      <AddIcon fontSize='inherit' size='small' />
    </IconButton>
  );
};

const MainComponent = () => {
  const [values, setValues] = useState({ ...defaultValues });
  const [errors, setErrors] = useState({});
  const [providers, setProviders] = useState([]);
  const [isProviderOpen, setIsProviderOpen] = useState(false);
  const [branches, setBranches] = useState([]);
  const [serviceOrders, setServiceOrders] = useState([]);
  const [remodelings, setRemodelings] = useState([]);

  const clearFormVar = useReactiveVar(clearForm);
  const stepVar = useReactiveVar(step);
  const handleContinueVar = useReactiveVar(handleContinue);

  const { data: providersData } = useQuery(GQL_PROVIDER.GET_PROVIDERS, { variables: { limit: 0 } });
  const { data: dataBranches } = useQuery(GQL_BRANCHES.GET, { variables: { limit: 0 } });
  const { data: dataServiceOrders } = useQuery(GQL_SALES.GET_SERVICE_ORDERS);

  useEffect(() => {
    if (dataServiceOrders && dataServiceOrders.serviceOrders) {
      const { serviceOrder, remodeling } = dataServiceOrders.serviceOrders.rows.reduce(
        (object, el) => {
          if (parseInt(el.category) === 3)
            return {
              remodeling: object.remodeling,
              serviceOrder: [
                ...object.serviceOrder,
                {
                  value: el.id,
                  label: `OS-${el.serial.padStart(5, '0')} | ${el.Customer.firstName.trim()} ${
                    el.Customer.lastName
                  }`.trim(),
                },
              ],
            };
          return {
            serviceOrder: object.serviceOrder,
            remodeling: [
              ...object.remodeling,
              {
                value: el.id,
                label: `OR-${el.serial.padStart(5, '0')} | ${el.Customer.firstName.trim()} ${
                  el.Customer.lastName
                }`.trim(),
              },
            ],
          };
        },
        { serviceOrder: [], remodeling: [] },
      );
      setServiceOrders(serviceOrder);
      setRemodelings(remodeling);
    }
  }, [dataServiceOrders]);

  useEffect(() => {
    if (dataBranches) {
      const tmpBranches = dataBranches
        ? dataBranches.branches.rows
            .map(el => {
              return {
                value: el.id,
                label: el.name,
              };
            })
            .filter(tmp => tmp)
        : [];
      setBranches(tmpBranches);
    }
  }, [dataBranches]);

  useEffect(() => {
    if (branches.length > 0) {
      const session = getSession();
      setValues(values => ({ ...values, branchId: parseInt(session.Company.Branch.id) }));
    }
  }, [branches]);

  const mainFormValidation = () => {
    try {
      setErrors({});
      if (['2', '5', '6', '7', '8', '9'].includes(values.action) && values.providerId === null) {
        const e = new Error('Selecciona el proveedor de la compra.');
        e.name = 'providerId';
        throw e;
      }
      if (!values.type) {
        const e = new Error('Selecciona el método de pago.');
        e.name = 'type';
        throw e;
      }
      if (
        values.currency !== 'MXN' &&
        (!values.exchangeRate ||
          Number.isNaN(parseFloat(values.exchangeRate)) ||
          parseFloat(values.exchangeRate) <= 0)
      ) {
        const e = new Error('El valor del dólar es inválido.');
        e.name = 'exchangeRate';
        throw e;
      }
      if (
        ['2', '5', '6', '7', '8', '9'].includes(values.action) &&
        (!values.subFolio || values.subFolio.trim() === '')
      ) {
        const e = new Error('Folio es obligatorio.');
        e.name = 'subFolio';
        throw e;
      }
      if (['3', '4'].includes(values.action) && !values.saleId) {
        const e = new Error(
          `No se ha seleccionado una ${
            values.action === '3' ? 'orden de servicio' : 'obra/remodelación'
          }.`,
        );
        e.name = 'saleId';
        throw e;
      }

      step(stepVar + 1);
      form({ ...values });
    } catch (e) {
      console.log(e);
      setErrors({ [e.name]: e.message });
    }
  };

  useEffect(() => {
    if (stepVar === 0 && handleContinueVar) {
      mainFormValidation();
      handleContinue(false);
    }
  }, [stepVar, handleContinueVar]);

  useEffect(() => {
    if (clearFormVar && stepVar === 0) {
      setValues(defaultValues);
      form({});
      clearForm(false);
    }
  }, [clearFormVar]);

  useEffect(() => {
    if (providersData && providersData.providers) {
      const tmp = providersData.providers.rows.map(el => ({ value: el.id, label: el.name }));
      setProviders(tmp);
    }
  }, [providersData]);

  const handleChange = (name, value) => {
    if (name === 'action') setValues(values => ({ ...values, [name]: value, saleId: null }));
    else setValues(values => ({ ...values, [name]: value }));
  };

  const handleProvider = async response => {
    if (response && response.data) {
      const newProvider = {
        label: response.data.createProvider.name,
        value: response.data.createProvider.id,
      };
      await setProviders(providers => [newProvider, ...providers]);
      await setValues(values => ({ ...values, providerId: newProvider }));
    }
    setIsProviderOpen(isProviderOpen => !isProviderOpen);
  };

  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={12} md={5}>
          <SelectField
            name='action'
            label='¿Que proceso deseas realizar?'
            value={values.action}
            error={errors.action}
            options={actionOptions}
            onChange={handleChange}
          />
        </Grid>
      </Grid>

      <Grid container spacing={1}>
        <Grid item xs={12} md={5}>
          <SelectField
            name='branchId'
            label='¿A que sucursal se asignará este gasto'
            onChange={handleChange}
            value={values.branchId}
            error={errors.branchId}
            options={branches}
          />
        </Grid>
      </Grid>

      <Grid container spacing={1}>
        <Grid item xs={12} md={5}>
          <SelectField
            name='type'
            label='¿Cuál es el método de pago?'
            onChange={handleChange}
            options={paymentMethodOptions}
            value={values.type}
            error={errors.type}
          />
        </Grid>
      </Grid>
      <Grid container spacing={1}>
        <Grid item xs={12} md={5}>
          <SelectField
            name='currency'
            label='¿Cuál será la moneda a utilizar?'
            onChange={handleChange}
            options={currencyOptions}
            value={values.currency}
            error={errors.currency}
          />
        </Grid>
      </Grid>
      {values.currency !== 'MXN' && (
        <Grid container spacing={1}>
          <Grid item xs={12} md={5}>
            <TextField
              name='exchangeRate'
              label='¿Cuál es el valor del dólar?'
              onChange={handleChange}
              value={values.exchangeRate}
              error={errors.exchangeRate}
              type='number'
            />
          </Grid>
        </Grid>
      )}
      {['2', '5', '6', '7', '8', '9'].includes(values.action) && (
        <Grid container spacing={1}>
          <Grid item xs={12} md={5}>
            <Autocomplete
              name='providerId'
              label='¿Quién será el proveedor de este gasto operativo?'
              onChange={handleChange}
              options={providers}
              value={values.providerId}
              error={errors.providerId}
              extra={<AddButton onClick={handleProvider} />}
            />
          </Grid>
        </Grid>
      )}
      {['3', '4'].includes(values.action) && (
        <Grid container spacing={1}>
          <Grid item xs={12} md={5}>
            <Autocomplete
              name='saleId'
              label={`¿A qué ${
                values.action === '3' ? 'orden de servicio' : 'obra/remodelación'
              } pertenecerá?`}
              onChange={handleChange}
              options={values.action === '3' ? serviceOrders : remodelings}
              value={values.saleId}
              error={errors.saleId}
            />
          </Grid>
        </Grid>
      )}
      <Grid container spacing={1}>
        <Grid item xs={12} md={5}>
          <DateField
            name='pursacheDate'
            label='Selecciona la fecha de gasto'
            onChange={handleChange}
            value={values.pursacheDate}
            error={errors.pursacheDate}
          />
        </Grid>
      </Grid>
      <Grid container spacing={1}>
        <Grid item xs={12} md={5}>
          <TextField
            name='subFolio'
            label='Escribe el folio de tu gasto'
            onChange={handleChange}
            value={values.subFolio}
            error={errors.subFolio}
          />
        </Grid>
      </Grid>
      <Grid container spacing={1}>
        <Grid item xs={12} md={5}>
          <SelectField
            name='paymentForm'
            label='Proporciona la forma de pago'
            onChange={handleChange}
            options={selectpaymentFormOptions}
            value={values.paymentForm}
            error={errors.paymentForm}
          />
        </Grid>
      </Grid>
      <Grid container spacing={1}>
        <Grid item xs={12} md={5}>
          <TextField
            name='description'
            label='¿Tienes alguna anotación para este gasto operativo?'
            value={values.description}
            error={errors.description}
            multiline
            minRows={4}
            onChange={handleChange}
          />
        </Grid>
      </Grid>
      <ProviderDialog isOpen={isProviderOpen} handleClose={handleProvider} />
    </>
  );
};

export default MainComponent;
