import dateFormat from 'date-fns/format';
import parseISO from 'date-fns/parseISO';

import companyType from '../../../../helpers/session/companyType';
import { currencyFormat } from '../../../../helpers';

export const productType = [
  '-',
  'Unidad',
  'Compuesto',
  'Compuesto Personalizado',
  'Paquete por Eventos',
  'Componente',
];
export const productTypeV2 = [
  '-',
  'Producto para Venta Directa',
  'Producto Elaborado',
  'Compuesto Personalizado',
  'Paquete',
  companyType === 2 ? 'Ingrediente' : 'Insumo / Materia Prima',
  'Servicio',
  'Paquete',
];
export const productStatus = ['Stock Deshabilitado', 'Activo'];
export const yesNoType = value => (value ? 'SI' : 'NO');
export const printer = ['No Imprimir', 'Impresora 1', 'Impresora 2', 'Impresora 3'];
export const action = ['-', 'Retiro', 'Depósito', 'Caja Fuerte'];
export const orderStatus = ['Cancelada', 'Abierta', 'En Proceso', 'Cerrada', 'Completado'];
export const typeSale = [
  'Local',
  'Local',
  'Ordene y Recoja',
  'Domicilio',
  'Drive Thru',
  'Didi Food',
  'Rappi',
  'Uber Eats',
];
export const receivableType = ['-', 'Venta', 'Cargo'];
export const eventStatus = ['-', 'En Preparación', 'Finalizado'];
const daysOfWeek = ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado', '-'];
const orderAuthorizationStatus = ['Sin Código', 'Vigente', 'Expirado', 'Finalizado'];
const quoteStatus = ['Cancelado', 'No Autorizado', 'Abierto', 'Cerrado'];
const requisitionStatus = ['-', 'Abierto', 'Autorizado', 'Cerrado', 'Cancelado'];
const movementLogType = ['-', 'Cotización', 'Orden de Servicio', 'Cuenta por Cobrar'];
const orderAuthorizationAction = value => {
  switch (parseInt(value)) {
    case 1:
      return 'Cancelación de Cuenta';
    case 2:
      return 'Cancelación de Producto';
    case 3:
      return 'Descuento de Cuenta';
    case 4:
      return 'Descuento de Producto';
    case 5:
      return 'Autorización total';
    default:
      return 'Indefinido';
  }
};

const timeLimitFormat = value => {
  if (!value || parseInt(value) < 0) return '-';
  if (parseInt(value) > 1) return `${value} Días`;
  return `${value} Día`;
};

const promotionAvailableDays = value => {
  const array = value.split(',');
  return array.map(
    (key, i) =>
      `${daysOfWeek[key]}${i < array.length - 1 ? (i === array.length - 2 ? ' y ' : ', ') : ''}`,
  );
};

const format = (value, type) => {
  switch (type) {
    case 'date':
      return value && value !== '-' ? dateFormat(parseISO(value), 'dd/MM/yyyy') : '-';
    case 'dateTime':
      return value && value !== '-' ? dateFormat(parseISO(value), 'dd/MM/yyyy HH:mm:ss') : '-';
    case 'time':
      return value && value !== '-' ? dateFormat(parseISO(value), 'HH:mm:ss') : '-';
    case 'currency':
      return `$ ${currencyFormat(value)}`;
    case 'number':
      return currencyFormat(value);
    case 'currencyDls':
      return value ? `$ ${currencyFormat(value)}` : '-';
    case 'percent':
      return !Number.isNaN(parseInt(value)) ? `${currencyFormat(value)}%` : '-';
    case 'balance':
      return parseFloat(value) === 0 ? '-' : currencyFormat(value);
    case 'productType':
      return productType[value];
    case 'productTypeV2':
      return productTypeV2[value];
    case 'productStatus':
      return productStatus[value];
    case 'yesNoType':
      return yesNoType(value);
    case 'printer':
      return printer[value];
    case 'action':
      return action[value];
    case 'orderStatus':
      return orderStatus[value];
    case 'typeSale':
      return typeSale[value];
    case 'receivableType':
      return receivableType[value];
    case 'promotionAvailableDays':
      return promotionAvailableDays(value);
    case 'orderAuthorizationStatus':
      return orderAuthorizationStatus[value];
    case 'orderAuthorizationAction':
      return orderAuthorizationAction(value);
    case 'timeLimitFormat':
      return timeLimitFormat(value);
    case 'eventStatus':
      return eventStatus[value];
    case 'quoteStatus':
      return quoteStatus[value];
    case 'requisitionStatus':
      return requisitionStatus[value];
    case 'movementLogType':
      return movementLogType[value];
    default:
      return typeof value === 'undefined' || value === '' ? '-' : value;
  }
};

export default format;
