import React from 'react';
import GoBackButtonComponent from '../../../presentationals/GoBackButton';
import EditPaymentMethodComponent from '../../../containers/paymentMethod/Edit';
var PaymentMethodLayoutComponent = function () {
    return (React.createElement("div", { className: 'flex flex-1 flex-col' },
        React.createElement("div", { className: 'flex items-center px-4 py-0' },
            React.createElement("div", { className: 'mr-3' },
                React.createElement(GoBackButtonComponent, null)),
            React.createElement("div", { className: 'text-lg text-gray-700 tracking-tight' }, "Configuraci\u00F3n de la forma de pago")),
        React.createElement("div", { className: 'flex flex-1 flex-col h-full overflow-hidden' },
            React.createElement(EditPaymentMethodComponent, null))));
};
export default PaymentMethodLayoutComponent;
