import React, { useMemo } from 'react';
import { Outlet } from 'react-router-dom';
import MenuRentLayoutComponent from './Menu';
import GoBackButtonComponent from '../../../presentationals/GoBackButton';
import useGetSaleByParamSaleId from '../../../hooks/useGetSaleByParamSaleId';
import LoadingProgress from '../../../presentationals/LoadingProgress';
import ErrorDataComponent from '../../../presentationals/ErrorData';
import getRouteFromRentMenuValue from '../Menu/getRouteFromMenuValue';
import { rentPath } from '../../../apollo/cache';
var ItemRentLayoutComponent = function () {
    var _a = useGetSaleByParamSaleId(), data = _a.data, loading = _a.loading;
    var route = useMemo(function () {
        var rentPathVar = rentPath();
        var route = "/panel/rent/".concat(getRouteFromRentMenuValue(rentPathVar));
        return route;
    }, [data]);
    if (loading)
        return React.createElement(LoadingProgress, null);
    if (!data)
        return React.createElement(ErrorDataComponent, null);
    return (React.createElement("div", { className: 'flex flex-1 flex-col' },
        React.createElement("div", { className: 'flex items-center px-4 py-0' },
            React.createElement("div", { className: 'mr-3' },
                React.createElement(GoBackButtonComponent, { route: route })),
            React.createElement("div", { className: 'text-lg text-gray-700 tracking-tight' }, "Configuraci\u00F3n de la renta")),
        React.createElement(MenuRentLayoutComponent, null),
        React.createElement("div", { className: 'flex flex-1 flex-col h-full overflow-hidden' },
            React.createElement(Outlet, null))));
};
export default ItemRentLayoutComponent;
