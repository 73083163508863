var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useEffect, useState } from 'react';
import { useReactiveVar } from '@apollo/client';
import TextFieldComponent from '../../../../presentationals/FormComponents/TextField';
import { inputError } from '../../../../apollo/cache';
import FooterDialogComponent from '../../../../presentationals/Dialog/Footer';
import CancelButtonCalendarComponent from './CancelButton';
var defaultValues = {
    description: '',
};
var CancelCalendarComponent = function (props) {
    var errors = useReactiveVar(inputError);
    var _a = useState(defaultValues), values = _a[0], setValues = _a[1];
    useEffect(function () {
        setValues(function (values) { return (__assign(__assign({}, values), { description: props.description })); });
    }, [props.description]);
    var handleChange = function (name, value) {
        setValues(function (values) {
            var _a;
            return (__assign(__assign({}, values), (_a = {}, _a[name] = value, _a)));
        });
    };
    return (React.createElement("div", { className: 'flex flex-1 flex-col h-screen overflow-hidden mt-1' },
        React.createElement("div", { className: 'grid grid-cols-1 md:grid-cols-3 md:gap-4 flex-1 overflow-auto p-4 md:pt-0 md:pr-0' },
            React.createElement("div", null,
                React.createElement(TextFieldComponent, { label: 'Escribe el motivo de la cancelaci\u00F3n', name: 'description', value: values.description, error: errors.description, onChange: handleChange }))),
        React.createElement(FooterDialogComponent, null,
            React.createElement("div", null),
            React.createElement(CancelButtonCalendarComponent, { eventId: props.eventId, description: values.description }))));
};
export default CancelCalendarComponent;
