var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import { useParams } from 'react-router-dom';
import ButtonFooterDialogComponent from '../../../../../../presentationals/Dialog/Footer/Button';
import GQL_ACCOUNT_RECEIVABLE from '../../../../../../apollo/gql/accountReceivable';
import { inputError, isPaymentFormOpen } from '../../../../../../apollo/cache';
import useApolloLazyQuery from '../../../../../../hooks/useApolloLazyQuery';
import getInputErrorFormat from '../../../../../sale.v2/Action/helpers/getInputErrorFormat';
import payment80mm from '../../../../../../presentationals/PDF/payment.80mm';
import setInputErrorFormat from '../../../../../sale.v2/Action/helpers/setInputErrorFormat';
import GQL_TRANSACTION from '../../../../../../apollo/gql/transaction';
import getApolloQueryArgs from '../../../../../../apollo/helpers/getApolloQueryArgs';
import useBranchConfig from '../../../../../../hooks/useBranchConfig';
var CreatePaymentButton = function (props) {
    var branchConfig = useBranchConfig().data;
    var params = useParams();
    var _a = useState(false), loading = _a[0], setLoading = _a[1];
    var getData = useApolloLazyQuery(GQL_ACCOUNT_RECEIVABLE.GET_ACCOUNT_RECEIVABLE_BY_ID)[0];
    var createPaymentRelationSale = useMutation(GQL_ACCOUNT_RECEIVABLE.CREATE_PAYMENT_RELATED_SALE, {
        update: function (cache, _a) {
            var data = _a.data;
            data.createPaymentRelationSale.Transactions.forEach(function (el) {
                var newTransactionRef = cache.writeFragment({
                    data: __assign({}, el),
                    fragment: GQL_TRANSACTION.FRAGMENT_TRANSACTION,
                });
                cache.modify({
                    fields: {
                        transactions: function (existingTransactionsRefs) {
                            return __assign(__assign({}, existingTransactionsRefs), { count: existingTransactionsRefs.count + 1, rows: __spreadArray(__spreadArray([], existingTransactionsRefs.rows, true), [newTransactionRef], false) });
                        },
                    },
                });
                cache.modify({
                    fields: {
                        transactionsCustomer: function (existingTransactionsRefs, _a) {
                            var fieldName = _a.fieldName, storeFieldName = _a.storeFieldName;
                            var args = getApolloQueryArgs(storeFieldName, fieldName);
                            if (args.customerId === data.createPaymentRelationSale.customerId) {
                                var newTransactionRef_1 = cache.writeFragment({
                                    data: __assign({}, el),
                                    fragment: GQL_TRANSACTION.FRAGMENT_TRANSACTION,
                                });
                                return __spreadArray([newTransactionRef_1], existingTransactionsRefs, true);
                            }
                            return existingTransactionsRefs;
                        },
                    },
                });
                if (el.AccountReceivable.balance <= 0) {
                    cache.evict({
                        id: cache.identify({
                            __typename: 'AccountReceivable',
                            id: el.AccountReceivable.id,
                        }),
                    });
                    cache.gc();
                }
                else {
                    cache.modify({
                        id: cache.identify({ __typename: 'AccountReceivable', id: el.AccountReceivable.id }),
                        fields: {
                            Transactions: function (cachedTransactions) {
                                return __spreadArray(__spreadArray([], cachedTransactions, true), [newTransactionRef], false);
                            },
                        },
                    });
                }
            });
        },
    })[0];
    var handleClick = function () { return __awaiter(void 0, void 0, void 0, function () {
        var accountReceivableId, formData, data, response, error_1;
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    _b.trys.push([0, 3, 4, 5]);
                    setLoading(true);
                    inputError({});
                    accountReceivableId = isPaymentFormOpen();
                    formData = props.onClick();
                    if (!formData)
                        throw new Error('Ocurrio un error inesperado, reportar a soporte técnico.');
                    return [4 /*yield*/, getData({ variables: { id: accountReceivableId } })];
                case 1:
                    data = (_b.sent()).data;
                    if (!data)
                        throw new Error('Ocurrio un error inesperado, reportar a soporte técnico.');
                    if (!formData.total || isNaN(parseFloat(formData.total)) || parseFloat(formData.total) <= 0)
                        throw new Error(setInputErrorFormat('total', 'Proporciona una cantidad mayor a cero.'));
                    return [4 /*yield*/, createPaymentRelationSale({
                            variables: {
                                paymentMethodId: formData.paymentFormId.toString() !== '-1' ? formData.paymentFormId : null,
                                customerId: params.customerId,
                                date: formData.date,
                                transactionAccountId: formData.transactionAccountId,
                                Transactions: [
                                    {
                                        accountReceivableId: accountReceivableId,
                                        total: parseFloat(formData.total),
                                        paymentMethodId: formData.paymentFormId.toString() !== '-1' ? formData.paymentFormId : null,
                                        currency: formData.currency,
                                        exchangeRate: formData.currency === 'MXN' ? 1 : parseFloat(formData.exchangeRate),
                                        transactionAccountId: formData.transactionAccountId,
                                        date: formData.date,
                                    },
                                ],
                            },
                        })];
                case 2:
                    response = (_b.sent()).data;
                    if (((_a = response === null || response === void 0 ? void 0 : response.createPaymentRelationSale) === null || _a === void 0 ? void 0 : _a.Transactions) &&
                        response.createPaymentRelationSale.Transactions.length > 0 &&
                        (branchConfig === null || branchConfig === void 0 ? void 0 : branchConfig.printReceiptAfterPayment))
                        response.createPaymentRelationSale.Transactions.forEach(function (el) {
                            return payment80mm(el.AccountReceivable.id);
                        });
                    props.onSuccess();
                    return [3 /*break*/, 5];
                case 3:
                    error_1 = _b.sent();
                    getInputErrorFormat(error_1);
                    return [3 /*break*/, 5];
                case 4:
                    setLoading(false);
                    return [7 /*endfinally*/];
                case 5: return [2 /*return*/];
            }
        });
    }); };
    return (React.createElement(ButtonFooterDialogComponent, { loading: loading, onClick: handleClick }, "Aplicar pago"));
};
export default CreatePaymentButton;
