import React, { useEffect, useState } from 'react';
import { Tabs, Tab } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import ScheduleIcon from '@mui/icons-material/Schedule';
import BadgeIcon from '@mui/icons-material/Badge';
import KeyIcon from '@mui/icons-material/Key';
import getEmployeeMenuValueFromPath from './getEmployeeMenuValueFromPath';
import getRouteFromEmployeeMenuValue from './getRouteFromEmployeeMenuValue';
var MenuEmployeeComponent = function () {
    var navigate = useNavigate();
    var location = useLocation();
    var _a = useState(0), value = _a[0], setValue = _a[1];
    useEffect(function () {
        var value = getEmployeeMenuValueFromPath(location.pathname);
        setValue(value);
    }, [location.pathname]);
    var handleChange = function (e, value) {
        var route = getRouteFromEmployeeMenuValue(value);
        if (route)
            navigate(route);
    };
    return (React.createElement(Tabs, { value: value, onChange: handleChange, variant: 'scrollable', scrollButtons: 'auto', className: 'bg-slate-100 border-0 border-b border-[#d6d6d6] border-solid' },
        React.createElement(Tab, { icon: React.createElement(BadgeIcon, null), label: 'Datos generales' }),
        React.createElement(Tab, { icon: React.createElement(ScheduleIcon, null), label: 'Horario laboral' }),
        React.createElement(Tab, { icon: React.createElement(KeyIcon, null), label: 'Cambiar contrase\u00F1a' })));
};
export default MenuEmployeeComponent;
