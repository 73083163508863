import React, { useState, useEffect } from 'react';

import { useMutation, useLazyQuery, useQuery } from '@apollo/client';

import format from 'date-fns/format';

import Grid from '@mui/material/Grid';
import Collapse from '@mui/material/Collapse';

import config from '../../config/config.json';

import TextField from '../../presentationals/TextField';
import Button from '../../presentationals/Button';
import { dateFormat, hourFormat } from '../../helpers';

import GQL_CUSTOMER_CALENDAR from '../dashboard/Menu/CustomerCalendarCheck/_gql';
import GQL_CUSTOMER from '../customer/_gql';
import GQL_CALENDAR from '../calendar/_gql';
import GQL_COMPANY from '../company/_gql';

import GQL from './_gql';

import ThirdStep from './ThirdStep/index';
import useGetSession from '../../hooks/useGetSession';

const defaultValues = {
  username: '',
};

const CustomerCheckInComponent = () => {
  const session = useGetSession();

  const [values, setValues] = useState(defaultValues);
  const [errors, setErrors] = useState(defaultValues);
  const [phoneStep, setPhoneStep] = useState(true);
  const [step, setStep] = useState(false);
  const [manyCustomers, setManyCustomers] = useState(false);
  const [manyDates, setManyDates] = useState(false);
  const [logo, setLogo] = useState(false);

  const [updateStatusCalendarWithPhone, { loading, data }] = useMutation(
    GQL.UPDATE_STATUS_WITH_PHONE,
  );
  const [setCalendarZone, { loading: loadingZone }] = useMutation(
    GQL_CUSTOMER_CALENDAR.SET_CALENDAR_ZONE,
  );

  const handleClickUpdateStatus = async (e, params) => {
    try {
      const { id } = params;
      const status = parseInt(params.status) === 1 ? 4 : 5;
      await setCalendarZone({
        variables: { calendarId: id, planeZoneId: parseInt(params.status) === 1 ? 1 : null },
      });
      await updateStatusCalendarWithPhone({ variables: { id, status } });
      setManyDates(false);
      setValues(defaultValues);
      if (parseInt(params.status) !== 1) {
        setPhoneStep(true);
        return;
      }
      setStep(true);
      setTimeout(() => {
        setStep(false);
        setPhoneStep(true);
      }, 10000);
    } catch (e) {
      console.log(e);
    }
  };

  const [getCalendarByCustomer, { data: calendarData, loadingCalendars }] = useLazyQuery(
    GQL_CALENDAR.GET_BY_CUSTOMER_ID,
    { fetchPolicy: 'network-only' },
  );

  const { data: dataCompany } = useQuery(GQL_COMPANY.GET_BY_ID, {
    variables: { id: session?.Company?.id },
  });

  useEffect(() => {
    if (dataCompany)
      dataCompany.company.logoFileId &&
        setLogo(`${config['api-file-service'].url}/files/${dataCompany.company.logoFileId}`);
  }, [dataCompany]);

  const handleChooseCustomer = async (e, params) => {
    try {
      const { data } = await getCalendarByCustomer({ variables: { customerId: params } });
      setManyCustomers(false);
      const dates = data.calendarByCustomer.rows
        .map(el => {
          const start = format(new Date(el.start), 'yyyy-MM-dd');
          const today = format(new Date(), 'yyyy-MM-dd');
          return start === today && el;
        })
        .filter(el => el);
      if (dates.length > 0) {
        const newArray = dates.reverse().reduce(
          (array, el, i) => {
            if (i === 0) return [[...array[0], el], false];

            const start = format(new Date(el.start), 'hh:mmaaa');
            const end = format(new Date(dates[i - 1].end), 'hh:mmaaa');
            if (start === end && !array[1]) return [[...array[0], el], false];

            return [array[0], true];
          },
          [[], false],
        );

        newArray[0].forEach(el => {
          const status = parseInt(el.status) === 1 ? 4 : 5;
          setCalendarZone({
            variables: {
              calendarId: el.id,
              planeZoneId: parseInt(el.status) === 1 ? 1 : null,
            },
          });
          updateStatusCalendarWithPhone({ variables: { id: el.id, status } });
        });

        setValues(defaultValues);
        if (parseInt(dates[0].status) !== 1) {
          setPhoneStep(true);
          return;
        }

        setStep(true);
        setTimeout(() => {
          setStep(false);
          setPhoneStep(true);
        }, 10000);
        return;
      }
      setManyDates(true);
    } catch (e) {
      console.log(e);
    }
  };

  const [getCustomerByPhone, { data: customerData, loading: loadingCustomers }] = useLazyQuery(
    GQL_CUSTOMER.GET_CHECK_IN,
    { fetchPolicy: 'network-only' },
  );

  const customer =
    data &&
    `${data.updateStatusCalendarWithPhone.Customer.firstName} ${data.updateStatusCalendarWithPhone.Customer.lastName}`;

  const handleChange = e => {
    const { name } = e.target;
    const { value } = e.target;
    setValues(values => ({ ...values, [name]: value }));
  };

  const handleClick = async () => {
    try {
      if (values.username === '') {
        const usernameError = new Error('Este campo es obligatorio.');
        usernameError.name = 'username';
        throw usernameError;
      }
      const response = await getCustomerByPhone({ variables: { phone: values.username } });
      setPhoneStep(false);
      if (response.data.customerCheckIn.length === 1) {
        await handleChooseCustomer(null, response.data.customerCheckIn[0].id);
        return;
      }
      setManyCustomers(true);
    } catch (e) {
      if (e.name === 'username') {
        setErrors({ [e.name]: e.message });
        return;
      }
      console.log(e);
    }
  };

  const handleKey = (e, params) => {
    setValues(values => ({ username: `${values.username}${params}` }));
  };

  const handleDelete = () => {
    setValues(values => ({ username: values.username.slice(0, -1) }));
  };
  const handleDeleteAll = () => {
    setValues({ username: '' });
  };

  const handleReturnOne = () => {
    setManyCustomers(false);
    setPhoneStep(true);
  };

  const handleReturnTwo = () => {
    setManyDates(false);
    if (calendarData && calendarData.calendarByCustomer.rows.length === 0) {
      setValues(defaultValues);
      handleReturnOne();
      return;
    }
    setManyCustomers(true);
  };
  return (
    <div className='w-full h-[100vh] bg-slate-50 absolute top-0 left-0 z-[9999]'>
      <Grid container component='main' justifyContent='center'>
        <Grid
          item
          xs={12}
          className='pt-1 px-3 pb-0 flex flex-row items-center justify-center h-[20vh]'
        >
          {logo && <img src={logo} alt='Capitalike' className='h-full' />}
        </Grid>
        <Grid item xs={12} className='py-0 px-3 h-[80vh]'>
          <Collapse in={phoneStep} className='pt-1 h-[80vh] flex flex-col w-full'>
            <div className='pt-1 h-[80vh] flex flex-col w-full'>
              <div className='h-[5vh] w-full text-center'>
                <div className='font-semibold text-xl tracking-tight -leading-[1px] mb-1 text-[#666]'>
                  Hola, proporciona tu teléfono de 10 dígitos.
                </div>
              </div>
              <div className='h-[10vh] w-full text-center'>
                <TextField
                  variant='outlined'
                  label=''
                  name='username'
                  value={values.username}
                  error={errors.username}
                  onChange={handleChange}
                  size='medium'
                  InputProps={{
                    classes: { input: 'text-3xl' },
                  }}
                  noFormat
                />
              </div>
              <div className='h-[55vh] w-full text-center'>
                <Grid container spacing={1} className='flex flex-1 flex-row grow h-[55vh]'>
                  {[1, 2, 3, 4, 5, 6, 7, 8, 9, 0].map(el => (
                    <Grid item xs={4} key={el}>
                      <Button
                        variant='outlined'
                        className='h-full text-5xl'
                        onClick={handleKey}
                        params={el}
                        label={el}
                        fullWidth
                      />
                    </Grid>
                  ))}
                  <Grid item xs={4}>
                    <Button
                      isLoading={loading || loadingCustomers || loadingCalendars || loadingZone}
                      variant='outlined'
                      className='h-full text-5xl'
                      onClick={handleDelete}
                      label='<-'
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Button
                      isLoading={loading || loadingCustomers || loadingCalendars || loadingZone}
                      variant='outlined'
                      className='h-full text-5xl'
                      onClick={handleDeleteAll}
                      label='BORRAR TODO'
                      fullWidth
                    />
                  </Grid>
                </Grid>
              </div>
              <div className='h-[10vh] w-full text-center'>
                <Button
                  isLoading={loading || loadingCustomers || loadingCalendars || loadingZone}
                  fullWidth
                  onClick={handleClick}
                  className='my-[0.5vh] h-[9vh] text-xl rounded bg-[#2ab859] shadow-none hover:bg-[#26a851]'
                  label='Continuar'
                />
              </div>
            </div>
          </Collapse>
          <Collapse in={manyCustomers}>
            <Grid container spacing={1}>
              <Grid item xs={12} align='center'>
                <div className='font-semibold text-xl tracking-tight -leading-[1px] mb-1 text-[#666]'>
                  {customerData && customerData.customerCheckIn.length > 0 ? (
                    'Ahora, confirma tu nombre.'
                  ) : (
                    <div>
                      No encontramos un registro del teléfono proporcionado.
                      <br />
                      <br />
                      Vuelva a intentarlo, por favor.
                    </div>
                  )}
                </div>
              </Grid>
              <Grid item xs={12} align='center'>
                <Grid container spacing={1}>
                  {customerData &&
                    customerData.customerCheckIn.map(
                      el =>
                        el.id && (
                          <Grid item xs={12} key={el.id}>
                            <Button
                              isLoading={
                                loading || loadingCustomers || loadingCalendars || loadingZone
                              }
                              variant='outlined'
                              className='h-full text-5xl'
                              onClick={handleChooseCustomer}
                              params={el.id}
                              label={`${el.firstName} ${el.lastName}`}
                              fullWidth
                            />
                          </Grid>
                        ),
                    )}
                </Grid>
              </Grid>
              <Grid item xs={12} align='center'>
                <Button
                  isLoading={loading || loadingCustomers || loadingCalendars || loadingZone}
                  fullWidth
                  onClick={handleReturnOne}
                  className='my-[0.5vh] h-[9vh] text-xl rounded bg-[#2ab859] shadow-none hover:bg-[#26a851]'
                  label='Regresar'
                />
              </Grid>
            </Grid>
          </Collapse>
          <Collapse in={manyDates}>
            <Grid container spacing={1}>
              <Grid item xs={12} align='center'>
                <div className='font-semibold text-xl tracking-tight -leading-[1px] mb-1 text-[#666]'>
                  {calendarData && calendarData.calendarByCustomer.rows.length > 0 ? (
                    'Por último, selecciona el evento al que estás acudiendo.'
                  ) : (
                    <div>
                      Detectamos que no tiene un evento reservado.
                      <br />
                      <br />
                      Solicite uno al operador.
                    </div>
                  )}
                </div>
              </Grid>
              <Grid item xs={12} align='center'>
                <Grid container spacing={1}>
                  {calendarData &&
                    calendarData.calendarByCustomer.rows.map(
                      el =>
                        el.id && (
                          <Grid item xs={12} key={el.id}>
                            <Button
                              isLoading={
                                loading || loadingCustomers || loadingCalendars || loadingZone
                              }
                              variant='outlined'
                              className={
                                el.status !== 4
                                  ? 'h-full text-5xl'
                                  : 'h-full text-5xl text-[#ffbf00] border-[#ffbf00] hover:border-[#ffbf00] hover:bg-[#ffbf00] hover:shadow-none'
                              }
                              onClick={handleClickUpdateStatus}
                              params={{ id: el.id, status: el.status }}
                              label={`${el.Tag ? el.Tag.name : ''}, ${dateFormat(
                                el.start,
                              )} ${hourFormat(el.start)}`}
                              fullWidth
                            />
                          </Grid>
                        ),
                    )}
                </Grid>
              </Grid>
              <Grid item xs={12} align='center'>
                <Button
                  isLoading={loading || loadingCustomers || loadingCalendars || loadingZone}
                  fullWidth
                  onClick={handleReturnTwo}
                  className='my-[0.5vh] h-[9vh] text-xl rounded bg-[#2ab859] shadow-none hover:bg-[#26a851]'
                  label='Regresar'
                />
              </Grid>
            </Grid>
          </Collapse>
          <Collapse in={step} className='w-full'>
            <ThirdStep
              customer={customer}
              tag={data && data.updateStatusCalendarWithPhone.Tag.name}
              eventStartDate={data && data.updateStatusCalendarWithPhone.start}
              eventCheckIn={data && data.updateStatusCalendarWithPhone.checkIn}
              eventCheckOut={data && data.updateStatusCalendarWithPhone.checkOut}
            />
          </Collapse>
        </Grid>
      </Grid>
    </div>
  );
};

export default CustomerCheckInComponent;
