var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { forwardRef, useEffect, useImperativeHandle } from 'react';
import { useParams } from 'react-router-dom';
import useApolloLazyQuery from '../../../../hooks/useApolloLazyQuery';
import GQL_SURVEY from '../../../../apollo/gql/survey';
import LoadingProgress from '../../../../presentationals/LoadingProgress';
import ErrorDataComponent from '../../../../presentationals/ErrorData';
import SummarySurveyResultsComponent from './Summary';
import DetailSurveyResultsComponent from './Detail';
var BodySurveyResultsComponent = forwardRef(function (props, ref) {
    var params = useParams();
    var _a = useApolloLazyQuery(GQL_SURVEY.GET_SURVEY_CUSTOMERS), getData = _a[0], _b = _a[1], data = _b.data, loading = _b.loading;
    useEffect(function () {
        if (params.surveyId)
            getData({ variables: { surveyId: params.surveyId } });
    }, [params]);
    var getDataFromServer = function (values) {
        getData({ variables: __assign({ surveyId: params.surveyId }, values) });
    };
    useImperativeHandle(ref, function () { return ({
        getData: getDataFromServer,
    }); });
    if (loading)
        return React.createElement(LoadingProgress, null);
    if (!data)
        return React.createElement(ErrorDataComponent, null);
    return (React.createElement("div", { className: 'flex-1 grid grid-cols-1 sm:grid-cols-3 gap-4 pt-4' },
        React.createElement("div", { className: 'flex flex-1 flex-col' },
            React.createElement("div", { className: 'text-base font-medium mb-1 flex' }, "Resumen de la encuesta"),
            React.createElement("div", { className: 'pt-2 flex flex-1 flex-col' },
                React.createElement(SummarySurveyResultsComponent, { data: data }))),
        data.length > 0 && (React.createElement("div", { className: 'flex flex-1 flex-col sm:col-span-2' },
            React.createElement("div", { className: 'text-base font-medium mb-1 flex' }, "Encuestas realizadas"),
            React.createElement("div", { className: 'pt-2 flex flex-1 flex-col' },
                React.createElement(DetailSurveyResultsComponent, { data: data }))))));
});
export default BodySurveyResultsComponent;
