import React, { useState, useEffect } from 'react';

import { useReactiveVar } from '@apollo/client';

import Grid from '@mui/material/Grid';

import Button from '../../../../../presentationals/Button';
import TextField from '../../../../../presentationals/FormComponents/TextField';
import SelectField from '../../../../../presentationals/FormComponents/SelectField';
import { item, clearForm, form, step, actionForm } from '../../../../../apollo/cache';

const defaultValues = {
  accountReceivableId: '',
  total: 0,
};

const ProductFormComponent = () => {
  const actionFormVar = useReactiveVar(actionForm);
  const clearFormVar = useReactiveVar(clearForm);
  const stepVar = useReactiveVar(step);

  const [values, setValues] = useState(defaultValues);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (clearFormVar && stepVar === 1) {
      setValues(defaultValues);
      clearForm(false);
    }
  }, [clearFormVar]);

  useEffect(() => {
    if (values.accountReceivableId) {
      const dataA = actionFormVar.data.find(
        tmp => parseInt(tmp.id) === parseInt(values.accountReceivableId),
      );
      if (dataA)
        setValues(values => ({
          ...values,
          total: parseFloat(parseFloat(dataA.balance).toFixed(2)),
        }));
    }
  }, [values.accountReceivableId]);

  const handleAdd = async () => {
    try {
      setErrors({});
      if (values.accountReceivableId === '' || !values.accountReceivableId) {
        const e = new Error('Selecciona una nota.');
        e.name = 'accountReceivableId';
        throw e;
      }
      if (values.total === '' || parseFloat(values.total) <= 0) {
        const totalError = new Error('La cantidad de abono tiene que ser mayor a cero.');
        totalError.name = 'total';
        totalError.input = true;
        throw totalError;
      }
      const accountReceivableData = actionFormVar.data.find(
        tmp => tmp.id === values.accountReceivableId,
      );
      const { folio } = accountReceivableData;
      item({ ...values, folio, date: form().date });
      setValues(defaultValues);
    } catch (e) {
      setErrors({ [e.name]: e.message });
    }
  };
  const handleChange = (name, value) => {
    setValues(values => ({ ...values, [name]: value }));
  };

  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <SelectField
            name='accountReceivableId'
            label='Nota'
            type='number'
            onChange={handleChange}
            value={values.accountReceivableId}
            error={errors.accountReceivableId}
            options={
              actionFormVar.data
                ? actionFormVar.data.map(el => ({
                    value: el.id,
                    label: el.folio,
                  }))
                : []
            }
          />
        </Grid>
      </Grid>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <TextField
            name='total'
            label='Abono'
            type='number'
            onChange={handleChange}
            value={values.total}
            error={errors.total}
          />
        </Grid>
      </Grid>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Button onClick={handleAdd} label='Añadir' />
        </Grid>
      </Grid>
    </>
  );
};

export default ProductFormComponent;
