import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from '@mui/material';
import React from 'react';
var ActionPurchaseOrderFieldComponent = function (props) {
    var handleRadioChange = function (event) {
        props.onChange('action', parseInt(event.target.value));
    };
    return (React.createElement(FormControl, { className: 'mt-1' },
        React.createElement(FormLabel, { className: 'text-gray-900', id: 'action' }, "\u00BFQue proceso deseas realizar?"),
        React.createElement(RadioGroup, { onChange: handleRadioChange, row: true, value: props.value, "aria-labelledby": 'actionLabel', name: 'action' },
            React.createElement(FormControlLabel, { value: 1, control: React.createElement(Radio, null), label: 'Emitir una orden de compra' }),
            React.createElement(FormControlLabel, { value: 3, control: React.createElement(Radio, null), label: 'Capturar una compra' }))));
};
export default ActionPurchaseOrderFieldComponent;
