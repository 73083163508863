var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useMutation, useReactiveVar } from '@apollo/client';
import DialogComponent from '../../../../../presentationals/Dialog';
import { inputError, isEditRentProductOpen } from '../../../../../apollo/cache';
import getInputErrorFormat from '../../../../sale.v2/Action/helpers/getInputErrorFormat';
import setInputErrorFormat from '../../../../sale.v2/Action/helpers/setInputErrorFormat';
import GQL_SALE from '../../../../../apollo/gql/sale';
import BodyRentProductComponent from '../AddProduct/Body';
import useApolloLazyQuery from '../../../../../hooks/useApolloLazyQuery';
import LoadingProgress from '../../../../../presentationals/LoadingProgress';
var EditRentProductComponent = function (props) {
    var _a;
    var params = useParams();
    var productSaleId = useReactiveVar(isEditRentProductOpen);
    var _b = useApolloLazyQuery(GQL_SALE.GET_SALE_PRODUCT), getSaleProduct = _b[0], _c = _b[1], loadingSaleProduct = _c.loading, data = _c.data;
    var _d = useMutation(GQL_SALE.DELETE_SALE_PRODUCT, {
        update: function (cache, _a) {
            var response = _a.data;
            try {
                if (data)
                    cache.modify({
                        id: cache.identify({ __typename: 'Sale', id: params.saleId }),
                        fields: {
                            SaleProducts: function (existingRefs, _a) {
                                var readField = _a.readField;
                                var newCachedSaleProducts = existingRefs.filter(function (el) { return readField('id', el) !== response.deleteSaleProduct.id; });
                                return newCachedSaleProducts;
                            },
                            subtotal: function (existingRef) {
                                return existingRef - data.subtotal;
                            },
                            taxes: function (existingRef) {
                                return existingRef - data.taxes;
                            },
                            total: function (existingRef) {
                                return existingRef - data.total;
                            },
                        },
                    });
            }
            catch (error) {
                getInputErrorFormat(error);
            }
        },
    }), deleteRentProduct = _d[0], loadingDeleteProduct = _d[1].loading;
    var _e = useMutation(GQL_SALE.EDIT_SALE_PRODUCT, {
        update: function (cache, _a) {
            var cacheData = _a.data;
            try {
                if (data)
                    cache.modify({
                        id: cache.identify({ __typename: 'Sale', id: params.saleId }),
                        fields: {
                            subtotal: function (existingRef) {
                                return existingRef - data.subtotal + cacheData.updateSaleProduct.subtotal;
                            },
                            taxes: function (existingRef) {
                                return existingRef - data.taxes + cacheData.updateSaleProduct.taxes;
                            },
                            total: function (existingRef) {
                                return existingRef - data.total + cacheData.updateSaleProduct.total;
                            },
                        },
                    });
            }
            catch (error) {
                getInputErrorFormat(error);
            }
        },
    }), editRentProduct = _e[0], loading = _e[1].loading;
    useEffect(function () {
        if (productSaleId)
            getSaleProduct({ variables: { id: productSaleId } });
    }, [productSaleId]);
    var handleClose = function () {
        isEditRentProductOpen(false);
    };
    var handleEdit = function (values) { return __awaiter(void 0, void 0, void 0, function () {
        var error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    inputError({});
                    if (!values.productId)
                        throw new Error(setInputErrorFormat('productId', "Selecciona un producto para la ".concat(props.module || 'renta', ".")));
                    if (parseFloat(values.qty) <= 0 || isNaN(parseFloat(values.qty)))
                        throw new Error(setInputErrorFormat('qty', 'La cantidad debe ser mayor a cero.'));
                    if (parseFloat(values.unitPrice) <= 0 || isNaN(parseFloat(values.unitPrice)))
                        throw new Error(setInputErrorFormat('unitPrice', 'El precio por unidad debe ser mayor a cero.'));
                    return [4 /*yield*/, editRentProduct({
                            variables: {
                                id: productSaleId,
                                quantity: parseFloat(values.qty),
                                price: parseFloat(values.unitPrice),
                                taxesOption: values.taxesOption,
                                commentary: values.commentary || '',
                                outDate: values.outDate,
                            },
                        })];
                case 1:
                    _a.sent();
                    handleClose();
                    return [3 /*break*/, 3];
                case 2:
                    error_1 = _a.sent();
                    getInputErrorFormat(error_1);
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    var handleDelete = function () { return __awaiter(void 0, void 0, void 0, function () {
        var error_2;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, deleteRentProduct({ variables: { id: productSaleId } })];
                case 1:
                    _a.sent();
                    handleClose();
                    return [3 /*break*/, 3];
                case 2:
                    error_2 = _a.sent();
                    getInputErrorFormat(error_2);
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    return (React.createElement(DialogComponent, { fullScreen: true, open: !!productSaleId, title: "Editar producto a la ".concat(props.module || 'renta'), onClose: handleClose }, loadingSaleProduct || !data ? (React.createElement(LoadingProgress, null)) : (React.createElement(BodyRentProductComponent, { loading: loading || loadingDeleteProduct, allowCommentary: props.allowCommentary, defaultValues: {
            productId: (_a = data === null || data === void 0 ? void 0 : data.Product) === null || _a === void 0 ? void 0 : _a.id,
            qty: data === null || data === void 0 ? void 0 : data.quantity.toString(),
            unitPrice: data.withoutTaxes.toString(),
            taxesOption: 2,
            commentary: data.commentary || '',
            outDate: data.outDate ? new Date(data.outDate) : undefined,
        }, Button: { label: 'Editar', onClick: handleEdit }, DeleteButton: {
            label: 'Eliminar',
            color: 'error',
            onClick: handleDelete,
        } }))));
};
export default EditRentProductComponent;
