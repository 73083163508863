import React from 'react';
import ButtonFooterDialogComponent from '../../../../../../presentationals/Dialog/Footer/Button';
import { inputError } from '../../../../../../apollo/cache';
var DeleteCreatePurchaseOrderItemButtonComponent = function (props) {
    var handleClick = function () {
        inputError();
        props.onClick(props.index);
    };
    return (React.createElement(ButtonFooterDialogComponent, { color: 'error', onClick: handleClick }, "Eliminar"));
};
export default DeleteCreatePurchaseOrderItemButtonComponent;
