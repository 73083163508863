import { gql } from '@apollo/client';

const PRINT = gql`
  query Print($id: ID!, $type: String!) {
    print(id: $id, type: $type)
  }
`;

const GQL_PRINT = {
  PRINT,
};

export default GQL_PRINT;
