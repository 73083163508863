import React from 'react';
import ModuleV3Component from '../../presentationals/Module.v3';
import FilterBranchComponent from './Filter';
import useGetBranchData from './helpers/useGetBranchData';
import CreateAdressLine2DialogComponent from './Edit/AdressesLine2/CreateDialog';
import EditAdressLine2DialogComponent from './Edit/AdressesLine2/EditDialog';
import RedirectCreateFormToRouteComponent from '../../presentationals/RedirectCreateFormToRoute';
import RedirectEditFormToRouteComponent from '../../presentationals/RedirectEditFormToRoute';
var BranchComponent = function () {
    var _a = useGetBranchData({ getByFilter: true }), data = _a.data, loading = _a.loading;
    var header = [
        { Header: 'Tipo', accessor: 'type' },
        { Header: 'Prefijo', accessor: 'prefix' },
        { Header: 'Nombre', accessor: 'name' },
        { Header: 'Teléfono', accessor: 'phone' },
        { Header: 'Domicilio', accessor: 'address' },
    ];
    return (React.createElement(React.Fragment, null,
        React.createElement(ModuleV3Component, { title: 'Sucursales', data: data, header: header, isLoading: loading, filter: true }),
        React.createElement(FilterBranchComponent, null),
        React.createElement(RedirectCreateFormToRouteComponent, null),
        React.createElement(RedirectEditFormToRouteComponent, null),
        React.createElement(CreateAdressLine2DialogComponent, null),
        React.createElement(EditAdressLine2DialogComponent, null)));
};
export default BranchComponent;
