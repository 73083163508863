import { Checkbox } from '@mui/material';
import React, { forwardRef, useImperativeHandle, useState } from 'react';
var CheckboxSaleGlobalInvoiceFieldComponent = forwardRef(function (props, ref) {
    var _a = useState(true), checked = _a[0], setChecked = _a[1];
    var handleChange = function (event) {
        props.onChange(props.id, event.target.checked);
        setChecked(event.target.checked);
    };
    var handleSetChecked = function (isChecked) {
        if (checked !== isChecked)
            props.onChange(props.id, isChecked);
        setChecked(isChecked);
    };
    var handleChecked = function () { return (checked ? props.id : null); };
    useImperativeHandle(ref, function () { return ({
        checked: handleChecked,
        setChecked: handleSetChecked,
    }); });
    return React.createElement(Checkbox, { disabled: props.disabled, checked: checked, onChange: handleChange });
});
export default CheckboxSaleGlobalInvoiceFieldComponent;
