import React, { useEffect } from 'react';
import { useReactiveVar } from '@apollo/client';
import { exportXls } from '../../../apollo/cache';
import createXls from '../../XLS/table';
import getHeadersToExport from '../helpers/getHeadersToExport';
var XLSCreatorComponent = function (props) {
    var exportXlsVar = useReactiveVar(exportXls);
    useEffect(function () {
        if (exportXlsVar.export) {
            createXls(props.title, getHeadersToExport(props.header), props.data);
            exportXls({ export: false });
        }
    }, [exportXlsVar]);
    return React.createElement("div", null);
};
export default XLSCreatorComponent;
