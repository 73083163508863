import XLSX from 'xlsx';
import { format } from 'date-fns';
import rowFormat from '../Table/helpers/Format';

export default (title, keys, data) => {
  const filename = `${title}_${format(new Date(), 'dd-MMM-yyyy_HH-mm')}.xlsx`;
  const wb = XLSX.utils.book_new();
  const header = keys.map(el => el.label);
  const ws = XLSX.utils.json_to_sheet([], { header, skipHeader: true });
  const tmp = data.map(el => {
    const response = keys.reduce(
      (newObject, { key, strcat, ifNull, label, format: dataFormat }) => {
        let dataFormatFix = dataFormat;
        let value = key.includes(' ')
          ? key.includes(' && ')
            ? key.split(' && ').reduce((stringPincipal, elPincipal) => {
                const res = elPincipal.split(' ').reduce((string, elCh) => {
                  const tmpData = string[elCh] || ifNull || '-';
                  return tmpData;
                }, el);
                return stringPincipal !== '' ? `${stringPincipal}${strcat || ' '}${res}` : res;
              }, '')
            : key.split(' ').reduce((string, elCh) => {
                const tmpData = string[elCh] || ifNull || '-';
                return tmpData;
              }, el)
          : el[key];
        if (dataFormat === 'currency') {
          dataFormatFix = null;
          value = !Number.isNaN(parseFloat(value)) ? parseFloat(value) : 0;
        }
        return { ...newObject, [label]: rowFormat(value, dataFormatFix) };
      },
      {},
    );
    return response;
  });
  XLSX.utils.sheet_add_json(ws, tmp, { skipHeader: false, origin: 'A1' });
  XLSX.utils.book_append_sheet(wb, ws, title);
  XLSX.writeFile(wb, filename);
};
