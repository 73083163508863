import React from 'react';
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { useController, useFormContext } from 'react-hook-form';
import { FormLabel } from '@mui/material';
var RHFQtyFieldComponent = function (props) {
    var _a;
    var _b = useFormContext(), isSubmitting = _b.formState.isSubmitting, control = _b.control;
    var _c = useController({
        name: props.name,
        control: control,
        defaultValue: (_a = props.config) === null || _a === void 0 ? void 0 : _a.value,
        rules: props.config,
    }).field, value = _c.value, onChange = _c.onChange;
    var handleSubtract = function () {
        if (value - 1 === 0)
            return;
        onChange(value - 1);
    };
    var handleAdd = function () {
        onChange(value + 1);
    };
    return (React.createElement("div", { className: 'flex flex-col' },
        props.label && React.createElement(FormLabel, { component: 'legend' }, props.label),
        React.createElement("div", { className: "flex flex-row items-center ".concat(props.size === 'small' ? 'gap-1' : 'gap-2') },
            React.createElement(IconButton, { size: props.size, onClick: handleSubtract, disabled: props.disabled || isSubmitting, className: props.size === 'small' ? 'p-0' : undefined },
                React.createElement(RemoveIcon, { fontSize: props.size })),
            React.createElement("div", { className: props.size === 'small' ? 'text-base' : 'text-xl' }, value),
            React.createElement(IconButton, { size: props.size, onClick: handleAdd, disabled: props.disabled || isSubmitting, className: props.size === 'small' ? 'p-0' : undefined },
                React.createElement(AddIcon, { fontSize: props.size })))));
};
export default RHFQtyFieldComponent;
