import React from 'react';
import { Button } from '@mui/material';
import Subtitle from './subtitle';
var ButtonActionSaleV2Component = function (props) {
    var handleClick = function () {
        props.onClick(props.value);
    };
    return (React.createElement(Button, { disabled: props.disabled, className: 'min-w-[50%] md:min-w-0 h-full normal-case flex-col text-base rounded-none border-0 border-r border-solid border-gray-200 md:pt-3 md:px-6 md:pb-5', onClick: handleClick },
        props.title,
        props.subtitle && React.createElement(Subtitle, { label: props.subtitle })));
};
export default ButtonActionSaleV2Component;
