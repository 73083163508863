import React from 'react';

import useTheme from '@mui/material/styles/useTheme';
import useMediaQuery from '@mui/material/useMediaQuery';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitleMUI from '@mui/material/DialogTitle';

import LinearProgress from '@mui/material/LinearProgress';

import { DialogTitle, DialogContent, DialogActions } from './style';

const Component = props => {
  const Theme = useTheme();
  const isSmallScreen = useMediaQuery(Theme.breakpoints.down('md'));
  return (
    <Dialog
      open={props.isOpen}
      onClose={props.handleClose}
      fullWidth={true}
      maxWidth={props.width || 'sm'}
      fullScreen={isSmallScreen ? true : props.fullScreen}
      disableEscapeKeyDown={props.disableEscape || false}
      className='z-[1298]'
    >
      {props.isLoading && <LinearProgress color='secondary' />}
      {props.title && (
        <DialogTitle
          noActions={props.noActions}
          handleClose={props.handleClose}
          rightContent={props.rightContent}
        >
          {props.title}
        </DialogTitle>
      )}
      {props.altTitle && <DialogTitleMUI>{props.altTitle}</DialogTitleMUI>}
      <DialogContent contentBackground={props.contentBackground} noPadding={props.noPadding}>
        {props.children}
      </DialogContent>
      {!props.noActions && (
        <DialogActions>
          {props.extraMessage && (
            <div style={{ display: 'flex', flex: 1, alignItems: 'center' }}>
              {props.extraMessage}
            </div>
          )}
          {!props.hideClose && (
            <Button disabled={!!props.isLoading} onClick={props.handleClose}>
              Cerrar
            </Button>
          )}
          {!props.hideAction && (
            <Button
              onClick={props.handleAction}
              disabled={!!props.disableAction || !!props.isLoading}
              color='secondary'
            >
              {props.actionLabel || 'Guardar'}
            </Button>
          )}
        </DialogActions>
      )}
    </Dialog>
  );
};

export default Component;
