import React from 'react';
import Body from './Body';
import DialogComponent from '../../../../../../../../presentationals/Dialog';
import FooterDialogComponent from '../../../../../../../../presentationals/Dialog/Footer';
import CreateButtonColorEcommerceEditFormComponent from './CreateButton';
var FormAddButtonColorEcommerceEditFormComponent = function (props) {
    return (React.createElement(DialogComponent, { fullScreen: true, title: 'Configuraci\u00F3n del nuevo color', onClose: props.onClose, open: props.isOpen },
        React.createElement(Body, null),
        React.createElement(FooterDialogComponent, null,
            React.createElement("div", null),
            React.createElement(CreateButtonColorEcommerceEditFormComponent, { onClose: props.onClose }))));
};
export default FormAddButtonColorEcommerceEditFormComponent;
