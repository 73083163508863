var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React from 'react';
import { useFormContext } from 'react-hook-form';
import RHFButtonComponent from '../../../../../presentationals/RHForm/Button';
import setInputErrorFormat from '../../../../sale.v2/Action/helpers/setInputErrorFormat';
var AddProductManufactureToListButton = function (props) {
    var _a = useFormContext(), getValues = _a.getValues, setValue = _a.setValue, setError = _a.setError, resetField = _a.resetField;
    var handleClick = function () {
        try {
            var values = getValues();
            var currentArray = values[props.arrayName];
            var currentFormValues_1 = values[props.formName];
            if (!currentFormValues_1.productPresentationId)
                throw new Error(setInputErrorFormat("".concat(props.formName, ".productId"), 'Elige producto para añadir a la lista.'));
            var index_1 = currentArray.findIndex(function (el) { return el.productPresentationId === currentFormValues_1.productPresentationId; });
            var newArray = index_1 >= 0
                ? currentArray.map(function (el, i) {
                    return i === index_1 ? __assign(__assign({}, el), { qty: el.qty + parseFloat(currentFormValues_1.qty) }) : el;
                })
                : __spreadArray([
                    {
                        qty: parseFloat(currentFormValues_1.qty),
                        productPresentationId: currentFormValues_1.productPresentationId,
                    }
                ], currentArray, true);
            setValue(props.arrayName, newArray);
            resetField(props.formName);
        }
        catch (error) {
            if (error instanceof Error) {
                var formattedError = JSON.parse(error.message);
                setError(formattedError.name, { message: formattedError.message }, { shouldFocus: true });
            }
        }
    };
    return (React.createElement(RHFButtonComponent, { type: 'button', size: 'small', className: 'h-10 md:py-0 mt-[23px]', color: 'secondary', onClick: handleClick }, "A\u00F1adir"));
};
export default AddProductManufactureToListButton;
