import React from 'react';
import { useReactiveVar } from '@apollo/client';
import DialogComponent from '../../../presentationals/Dialog';
import { isPostponeCalendarOpen } from '../../../apollo/cache';
import BodyPosponeCalendarComponent from './Body';
var PostponeCalendarComponent = function () {
    var isOpen = useReactiveVar(isPostponeCalendarOpen);
    var handleClose = function () {
        isPostponeCalendarOpen(false);
    };
    return (React.createElement(DialogComponent, { title: 'Eventos pospuestos', onClose: handleClose, open: isOpen, fullScreen: true },
        React.createElement(BodyPosponeCalendarComponent, null)));
};
export default PostponeCalendarComponent;
