import React, { useState } from 'react';

import { useQuery } from '@apollo/client';
import Typography from '@mui/material/Typography';

import FilterDrawer from '../../presentationals/FilterDrawer.v2';
import Autocomplete from '../../presentationals/Autocomplete';
import { filter } from '../../apollo/cache';

import GQL_PAYMENT_METHOD from '../paymentMethod/_gql';

const defaultValues = {
  paymentMethodId: null,
};

const FilterDialog = () => {
  const [values, setValues] = useState(defaultValues);
  const [paymentMethods, setPaymentMethods] = useState([]);

  useQuery(GQL_PAYMENT_METHOD.GET, {
    onCompleted: data => {
      const tmp = data.paymentMethods.rows.map(el => ({
        label: el.name,
        value: el.id,
      }));
      setPaymentMethods([{ value: -1, label: 'EFECTIVO' }, ...tmp]);
    },
  });

  const handleAction = (e, data) => {
    const { startDate: start, endDate: end, branchId } = data;
    filter({
      type: 4,
      start,
      end,
      branchId,
      paymentMethodId: values.paymentMethodId ? values.paymentMethodId.value : null,
    });
  };

  const handleAutocompleteChange = (e, name, value) => {
    setValues(values => ({ ...values, [name]: value }));
  };

  return (
    <FilterDrawer handleAction={handleAction} showDates showBranches>
      <Typography variant='subtitle1'>Forma de Pago</Typography>
      <Autocomplete
        name='paymentMethodId'
        variant='outlined'
        onChange={handleAutocompleteChange}
        options={paymentMethods}
        value={values.paymentMethodId}
        noFormat
      />
    </FilterDrawer>
  );
};

export default FilterDialog;
