var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import getPreferences from './getPreferences';
import getServiceOrderTableColumnConfiguration, { defaultServiceOrderTableColumnsConfiguration, } from './getServiceOrderTableColumnConfiguration';
var setServiceOrderTableColumnConfiguration = function (column, toShow) {
    var _a;
    if (['id', 'total'].includes(column))
        return;
    var settings = getServiceOrderTableColumnConfiguration();
    var newConfiguration = __assign(__assign(__assign({}, defaultServiceOrderTableColumnsConfiguration), settings), (_a = { total: true }, _a[column] = toShow, _a));
    var Preferences = getPreferences();
    var newPreferences = Preferences
        ? __assign(__assign({}, Preferences), { serviceOrderTableColumnConfiguration: newConfiguration }) : { serviceOrderTableColumnConfiguration: newConfiguration };
    localStorage.setItem('Preferences', JSON.stringify(newPreferences));
};
export default setServiceOrderTableColumnConfiguration;
