var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useEffect, useMemo, useState } from 'react';
import DialogComponent from '../../../presentationals/Dialog';
import { filter } from '../../../apollo/cache';
import FooterDialogComponent from '../../../presentationals/Dialog/Footer';
import ButtonFooterDialogComponent from '../../../presentationals/Dialog/Footer/Button';
import getInputErrorFormat from '../../sale.v2/Action/helpers/getInputErrorFormat';
import getSession from '../../../helpers/getSession';
import CheckboxBranchFieldComponent from '../../../presentationals/FormComponents/CheckBoxBranchField';
import useFilterButtonDialog from '../../../store/filterButton/filterButtonDialog';
import SwitchComponent from '../../../presentationals/FormComponents/Switch';
import DateFieldComponent from '../../../presentationals/FormComponents/DateField';
var FilterReceivableDialog = function () {
    var defaultValues = useMemo(function () {
        var _a;
        var session = getSession();
        var branchId = (_a = session === null || session === void 0 ? void 0 : session.Company) === null || _a === void 0 ? void 0 : _a.Branch.id.toString();
        return {
            branchId: branchId ? [branchId] : [],
            start: new Date(),
            end: new Date(),
            getByDates: false,
        };
    }, []);
    var open = useFilterButtonDialog(function (state) { return state.open; });
    var setOpen = useFilterButtonDialog(function (state) { return state.setOpen; });
    var _a = useState(defaultValues), values = _a[0], setValues = _a[1];
    useEffect(function () {
        if (open) {
            var filterVar_1 = filter();
            setValues(function (values) { return (__assign(__assign({}, values), { branchId: filterVar_1.branchId ? filterVar_1.branchId : values.branchId, start: filterVar_1.start ? filterVar_1.start : values.start, end: filterVar_1.end ? filterVar_1.end : values.end, getByDates: filterVar_1.getByDates ? filterVar_1.getByDates : values.getByDates })); });
        }
    }, [open]);
    var handleClose = function () {
        setOpen(false);
    };
    var handleChange = function (name, value) {
        setValues(function (values) {
            var _a;
            return (__assign(__assign({}, values), (_a = {}, _a[name] = value, _a)));
        });
    };
    var handleClick = function () {
        try {
            var currentFilter = filter();
            filter(__assign(__assign({}, currentFilter), { start: values.getByDates ? values.start : undefined, end: values.getByDates ? values.end : undefined, branchId: values.branchId }));
            handleClose();
        }
        catch (error) {
            getInputErrorFormat(error);
        }
    };
    var handleSwitchChange = function (name, isActive) {
        setValues(function (values) {
            var _a;
            return (__assign(__assign({}, values), (_a = {}, _a[name] = isActive, _a)));
        });
    };
    var handleDateChange = function (name, value) {
        setValues(function (values) {
            var _a;
            return (__assign(__assign({}, values), (_a = {}, _a[name] = value, _a)));
        });
    };
    return (React.createElement(DialogComponent, { open: open, fullScreen: true, title: 'Filtro para consulta de cuentas por cobrar', onClose: handleClose },
        React.createElement("div", { className: 'flex flex-1 flex-col' },
            React.createElement("div", { className: 'flex flex-col flex-1 p-4 overflow-auto basis-0' },
                React.createElement("div", { className: 'flex flex-col w-full sm:w-1/4 ' },
                    React.createElement(CheckboxBranchFieldComponent, { name: 'branchId', defaultValues: values.branchId, onChange: handleChange }),
                    React.createElement("div", { className: 'mt-2' }),
                    React.createElement(SwitchComponent, { label: 'Contemplar fechas para la b\u00FAsqueda de las cuentas por cobrar', name: 'getByDates', defaultValue: values.getByDates, onChange: handleSwitchChange }),
                    values.getByDates && (React.createElement(React.Fragment, null,
                        React.createElement(DateFieldComponent, { name: 'start', label: 'Del', value: values.start, onChange: handleDateChange }),
                        React.createElement(DateFieldComponent, { name: 'end', label: 'Al', value: values.end, onChange: handleDateChange }))))),
            React.createElement(FooterDialogComponent, null,
                React.createElement("div", null),
                React.createElement(ButtonFooterDialogComponent, { color: 'success', onClick: handleClick }, "Aplicar filtro")))));
};
export default FilterReceivableDialog;
