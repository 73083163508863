import React from 'react';
import { Outlet } from 'react-router-dom';
import GoBackButtonComponent from '../../../presentationals/GoBackButton';
import MenuRoleComponent from './Menu';
var RoleLayoutComponent = function () {
    return (React.createElement("div", { className: 'flex flex-1 flex-col' },
        React.createElement("div", { className: 'flex items-center px-4 py-0 border-0 border-b border-[#d6d6d6] border-solid' },
            React.createElement("div", { className: 'mr-3' },
                React.createElement(GoBackButtonComponent, null)),
            React.createElement("div", { className: 'text-lg text-gray-700 tracking-tight' }, "Configuraci\u00F3n del rol de empleado")),
        React.createElement(MenuRoleComponent, null),
        React.createElement("div", { className: 'flex flex-1 flex-col h-full overflow-hidden' },
            React.createElement(Outlet, null))));
};
export default RoleLayoutComponent;
