import React from 'react';
import TextFieldComponent from '../../../../../../presentationals/FormComponents/TextField';
var ColorFieldQueueManagerCompanyComponent = function (props) {
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: 'mt-4 font-medium' }, props.label),
        React.createElement("div", { className: 'flex flex-row' },
            React.createElement(TextFieldComponent, { name: "".concat(props.section, "BackgroundColor"), label: 'Color de fondo', onChange: props.onChange, value: props.backgroundColor }),
            React.createElement("div", { className: 'ml-2' }),
            React.createElement(TextFieldComponent, { name: "".concat(props.section, "TextColor"), label: 'Color de texto', onChange: props.onChange, value: props.textColor }))));
};
export default ColorFieldQueueManagerCompanyComponent;
