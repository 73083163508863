import React from 'react';
import GoBackButtonComponent from '../../../presentationals/GoBackButton';
import CreatePurchaseOrderComponent from '../../../containers/purchaseOrder/Create';
var CreatePurchaseOrderLayoutComponent = function () {
    return (React.createElement("div", { className: 'flex flex-1 flex-col' },
        React.createElement("div", { className: 'flex items-center px-4 py-0 border-0 border-b border-[#d6d6d6] border-solid' },
            React.createElement("div", { className: 'mr-3' },
                React.createElement(GoBackButtonComponent, null)),
            React.createElement("div", { className: 'text-lg text-gray-700 tracking-tight' }, "Nueva orden de compra")),
        React.createElement("div", { className: 'flex flex-1 flex-col h-full overflow-hidden' },
            React.createElement(CreatePurchaseOrderComponent, null))));
};
export default CreatePurchaseOrderLayoutComponent;
