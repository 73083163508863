import React, { useEffect, useState } from 'react';

import { useReactiveVar } from '@apollo/client';

import SearchIcon from '@mui/icons-material/Search';

import { actions, filter } from '../../apollo/cache';

import ProductDetails from './productDetail.dialog';

const ActionComponent = () => {
  const filterVar = useReactiveVar(filter);

  const [dialog, setDialog] = useState([false, null, null]);

  const handleDetails = (e, params) => {
    setDialog([true, params.Product.id, null]);
  };

  const handleCloseDetails = () => {
    setDialog([false, null, null]);
  };

  useEffect(() => {
    actions([
      {
        icon: <SearchIcon />,
        label: 'Detalle por Producto',
        onClick: handleDetails,
      },
    ]);
    return () => {
      actions([]);
    };
  }, []);

  return (
    <ProductDetails
        handleClose={handleCloseDetails}
        isOpen={dialog[0]}
        productId={dialog[1]}
        start={filterVar.start}
        end={filterVar.end}
      />
  );
};

export default ActionComponent;
