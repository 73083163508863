import React from 'react';
import { useReactiveVar } from '@apollo/client';
import { Dialog } from '@mui/material';
import { isSatUnitKeyDialogOpen } from '../../../../../../../../../apollo/cache';
import Header from './Header';
import Form from './Form';
import Table from './Table';
var SearchDialogUnitKeyFieldProductComponent = function () {
    var isSatUnitKeyDialogOpenVar = useReactiveVar(isSatUnitKeyDialogOpen);
    return (React.createElement(Dialog, { fullScreen: true, open: isSatUnitKeyDialogOpenVar },
        React.createElement(Header, null),
        React.createElement(Form, null),
        React.createElement(Table, null)));
};
export default SearchDialogUnitKeyFieldProductComponent;
