import React from 'react';
import ButtonFooterDialogComponent from '../../../Dialog/Footer/Button';
import setInputErrorFormat from '../../../../containers/sale.v2/Action/helpers/setInputErrorFormat';
import getInputErrorFormat from '../../../../containers/sale.v2/Action/helpers/getInputErrorFormat';
var ActionTransactionAccountButtonComponent = function (props) {
    var handleClick = function () {
        try {
            if (props.values.name === '')
                throw new Error(setInputErrorFormat('name', 'Proporciona el nombre del titular.'));
            props.onClick(props.values);
        }
        catch (error) {
            getInputErrorFormat(error);
        }
    };
    return (React.createElement(ButtonFooterDialogComponent, { loading: props.loading, onClick: handleClick }, props.label));
};
export default ActionTransactionAccountButtonComponent;
