import React from 'react';
import LinkMenuDashboardComponent from '../../helpers/Link';
import useGetSession from '../../../../../../hooks/useGetSession';
var CustomerLinkBlockOneMenuDashboardComponent = function () {
    var _a;
    var session = useGetSession();
    var customerLabel = ((_a = session === null || session === void 0 ? void 0 : session.Company) === null || _a === void 0 ? void 0 : _a.activity) === 3 ? 'Pacientes' : 'Clientes';
    return (React.createElement(LinkMenuDashboardComponent, { url: 'customer', primary: customerLabel, secondary: "Captura, organiza y actualiza la informaci\u00F3n de los ".concat(customerLabel.toLowerCase(), " de la empresa.") }));
};
export default CustomerLinkBlockOneMenuDashboardComponent;
