var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { format } from 'date-fns';
import { dateFormat, hourFormat } from '../../../../helpers';
var getRentRows = function (data, deliveryStatus) {
    var defaultValue = [];
    var getDays = data.reduce(function (array, el) {
        var startDate = el.start && format(new Date(el.start), 'yyyy/MM/dd');
        var newArray = startDate
            ? array.includes(startDate)
                ? array
                : __spreadArray(__spreadArray([], array, true), [startDate], false)
            : array;
        var endDate = el.end && format(new Date(el.end), 'yyyy/MM/dd');
        return endDate ? (newArray.includes(endDate) ? newArray : __spreadArray(__spreadArray([], newArray, true), [endDate], false)) : newArray;
    }, defaultValue);
    var rentData = getDays.map(function (date, i) {
        var day = dateFormat(date);
        return {
            id: i,
            day: day,
            Rents: data
                .filter(function (el) {
                return (deliveryStatus === 0 &&
                    el.start &&
                    format(new Date(el.start), 'yyyy/MM/dd') === date) ||
                    (deliveryStatus === 4 && el.end && format(new Date(el.end), 'yyyy/MM/dd') === date);
            })
                .map(function (el) { return (__assign(__assign({}, el), { startHour: el.start ? hourFormat(el.start) : '-', endHour: el.end ? hourFormat(el.end) : '-' })); }),
        };
    });
    return rentData;
};
export default getRentRows;
