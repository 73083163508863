import React, { useEffect, Fragment, useState } from 'react';
import { useQuery, useReactiveVar } from '@apollo/client';

import LinearProgress from '@mui/material/LinearProgress';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';

import Table from '../../presentationals/Module.v2/Table/TableContainer';
import Pagination from '../../presentationals/Module.v2/Table/Pagination';
import { searchField, filter, count } from '../../apollo/cache';
import { currencyFormat } from '../../helpers';

import GQL from './_gql';
import CustomerSales from './customerSales.dialog';

const TableComponent = () => {
  const searchFieldVar = useReactiveVar(searchField);
  const filterVar = useReactiveVar(filter);
  const { data, loading, refetch } = useQuery(GQL.GET, {
    notifyOnNetworkStatusChange: true,
  });
  const [isOpenSales, setIsOpenSales] = useState([false, null]);

  useEffect(() => {
    if (Object.keys(filterVar).length > 0) refetch(filterVar);
  }, [filterVar, refetch]);

  useEffect(() => {
    filter({ ...filterVar, name: searchFieldVar });
  }, [searchFieldVar]);

  useEffect(() => {
    if (data && data.customerSales) count(data.customerSales.count);
  }, [data]);

  const handleRows = (offset, limit) => {
    filter({ ...filterVar, limit, offset });
  };

  const handleClick = async customerId => {
    setIsOpenSales([true, customerId]);
  };

  const handleCloseSales = () => {
    setIsOpenSales([false, null]);
  };

  return (
    <>
      {loading && <LinearProgress color='secondary' />}
      <Table>
        <TableHead>
          <TableRow>
            <TableCell />
            {data &&
              data.customerSales.count > 0 &&
              data.customerSales.rows[0].Sales.map((el, i) => {
                return (
                  <TableCell
                    key={el.Branch.name}
                    align='center'
                    colSpan={2}
                    style={{
                      background: i % 2 === 0 ? '#c9c9c9' : '#fafafa',
                      borderColor: i % 2 === 0 ? '#989898' : '#ddd',
                      minWidth: 10,
                    }}
                  >
                    <Typography variant='body2' noWrap>
                      <strong>{el.Branch.name}</strong>
                    </Typography>
                  </TableCell>
                );
              })}
          </TableRow>
          <TableRow>
            <TableCell style={{ top: 33 }}>CLIENTE</TableCell>
            {data &&
              data.customerSales.count > 0 &&
              data.customerSales.rows[0].Sales.map((el, i) => {
                return (
                  <Fragment key={i}>
                    <TableCell
                      align='center'
                      style={{
                        background: i % 2 === 0 ? '#c9c9c9' : '#fafafa',
                        borderColor: i % 2 === 0 ? '#989898' : '#ddd',
                        top: 33,
                      }}
                    >
                      #FOLIOS
                    </TableCell>
                    <TableCell
                      align='center'
                      style={{
                        background: i % 2 === 0 ? '#c9c9c9' : '#fafafa',
                        borderColor: i % 2 === 0 ? '#989898' : '#ddd',
                        top: 33,
                      }}
                    >
                      TOTAL
                    </TableCell>
                  </Fragment>
                );
              })}
          </TableRow>
        </TableHead>
        <TableBody>
          {data &&
            data.customerSales.rows.map(el => {
              return (
                <TableRow key={el.id}>
                  <TableCell>
                    <Typography variant='body2' noWrap>
                      <Link href='#' onClick={() => handleClick(el.id)}>
                        {`${el.Customer.firstName} ${el.Customer.lastName}`}
                      </Link>
                    </Typography>
                  </TableCell>
                  {el.Sales.map((elCh, ii) => {
                    return (
                      <Fragment key={`${el.id}-${ii}`}>
                        <TableCell
                          align='center'
                          style={{
                            background: ii % 2 === 0 ? '#ddd' : '#fafafa',
                            borderColor: ii % 2 === 0 ? '#989898' : '#ddd',
                          }}
                        >
                          {elCh.quantity}
                        </TableCell>
                        <TableCell
                          align='center'
                          style={{
                            background: ii % 2 === 0 ? '#ddd' : '#fafafa',
                            borderColor: ii % 2 === 0 ? '#989898' : '#ddd',
                          }}
                        >
                          {currencyFormat(elCh.total)}
                        </TableCell>
                      </Fragment>
                    );
                  })}
                </TableRow>
              );
            })}
        </TableBody>
      </Table>
      <Pagination handleRows={handleRows} />
      <CustomerSales
        isOpen={isOpenSales[0]}
        customerId={isOpenSales[1]}
        handleClose={handleCloseSales}
      />
    </>
  );
};

export default TableComponent;
