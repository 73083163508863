import React, { useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import useApolloLazyQuery from '../../../hooks/useApolloLazyQuery';
import FooterDialogComponent from '../../../presentationals/Dialog/Footer';
import DeleteButtonProviderComponent from './DeleteButton';
import EditButtonProviderComponent from './EditButton';
import LoadingProgress from '../../../presentationals/LoadingProgress';
import ErrorDataComponent from '../../../presentationals/ErrorData';
import BodyProviderComponent from '../Create/Body';
import GQL_PROVIDER from '../../../apollo/gql/provider';
var EditProviderComponent = function () {
    var params = useParams();
    var editButtonProviderRef = useRef(null);
    var bodyProviderRef = useRef(null);
    var _a = useApolloLazyQuery(GQL_PROVIDER.GET_PROVIDER_BY_ID), getById = _a[0], _b = _a[1], loading = _b.loading, data = _b.data;
    useEffect(function () {
        if (params.providerId)
            getById({ variables: { id: params.providerId } });
    }, [params]);
    var handleEdit = function () {
        var _a;
        (_a = editButtonProviderRef.current) === null || _a === void 0 ? void 0 : _a.onPressEnter();
    };
    var handleGetValues = function () {
        var _a;
        return (_a = bodyProviderRef.current) === null || _a === void 0 ? void 0 : _a.getValues();
    };
    if (loading)
        return React.createElement(LoadingProgress, null);
    if (!data)
        return React.createElement(ErrorDataComponent, null);
    return (React.createElement(React.Fragment, null,
        React.createElement(BodyProviderComponent, { ref: bodyProviderRef, defaultValues: data, onPressEnter: handleEdit }),
        React.createElement(FooterDialogComponent, null,
            React.createElement(DeleteButtonProviderComponent, null),
            React.createElement(EditButtonProviderComponent, { getValues: handleGetValues, ref: editButtonProviderRef }))));
};
export default EditProviderComponent;
