import useApolloQuery from './useApolloQuery';
import GQL_SURVEY from '../apollo/gql/survey';
var useGetSurveyCustomers = function (props) {
    var _a = useApolloQuery(GQL_SURVEY.GET_SURVEY_CUSTOMERS, {
        variables: {
            customerId: (props === null || props === void 0 ? void 0 : props.customerId) || undefined,
        },
    }), data = _a.data, loading = _a.loading;
    return { data: data || [], loading: loading };
};
export default useGetSurveyCustomers;
