import React from 'react';
import SearchIcon from '@mui/icons-material/Edit';
import IconButtonComponent from '../../../../../../../presentationals/IconButton';
import { isEditFormOpen } from '../../../../../../../apollo/cache';
var OpenEditOperationDateButtonComponent = function () {
    var handleClick = function () {
        isEditFormOpen(true);
    };
    return (React.createElement(IconButtonComponent, { action: handleClick, size: 'small', className: 'p-0 m-0', icon: React.createElement(SearchIcon, { fontSize: 'small' }) }));
};
export default OpenEditOperationDateButtonComponent;
