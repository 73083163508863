var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React from 'react';
import ButtonFooterDialogComponent from '../../../../../presentationals/Dialog/Footer/Button';
import getInputErrorFormat from '../../../../sale.v2/Action/helpers/getInputErrorFormat';
import setInputErrorFormat from '../../../../sale.v2/Action/helpers/setInputErrorFormat';
import getSession from '../../../../../helpers/getSession';
import PDF from '../individualBarcode.pdf';
import PDF71 from '../individualBarcode.71';
import PDF146 from '../individualBarcode.146';
import BarcodePDF84 from '../individualBarcode.84';
import useApolloMutation from '../../../../../hooks/useApolloMutation';
import GQL_PRODUCT from '../../../../../apollo/gql/product';
var PrintBarcodeLabelButtonComponent = function (props) {
    var updateProductPresentation = useApolloMutation(GQL_PRODUCT.UPDATE_PRODUCT_PRESENTATION)[0];
    var handleClick = function () { return __awaiter(void 0, void 0, void 0, function () {
        var session, ProductPresentations, ProductPresentation, body, qty, error_1;
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    _b.trys.push([0, 3, , 4]);
                    if (props.values.productPresentationId === '')
                        throw new Error(setInputErrorFormat('productPresentationId', 'Proporciona una presentación para la etiqueta.'));
                    if (props.values.qty === '' ||
                        isNaN(parseFloat(props.values.qty)) ||
                        parseFloat(props.values.qty) <= 0)
                        throw new Error(setInputErrorFormat('qty', 'Proporciona una cantidad mayor a cero.'));
                    session = getSession();
                    ProductPresentations = props.Product.ProductPresentations
                        ? __spreadArray([props.Product.PresentationBase], props.Product.ProductPresentations, true) : [props.Product.PresentationBase];
                    ProductPresentation = ProductPresentations.find(function (el) { return el.id === props.values.productPresentationId; });
                    if (!ProductPresentation)
                        throw new Error('No se encontró la presentación del producto.');
                    if (!!ProductPresentation.barcode) return [3 /*break*/, 2];
                    return [4 /*yield*/, updateProductPresentation({
                            variables: {
                                id: ProductPresentation.id,
                                barcode: ProductPresentation.id,
                                name: ProductPresentation.name,
                            },
                        })];
                case 1:
                    _b.sent();
                    _b.label = 2;
                case 2:
                    body = {
                        name: "".concat(props.Product.name, " ").concat(ProductPresentation.name),
                        Presentation: {
                            id: ProductPresentation.id,
                            barcode: ProductPresentation.barcode || ProductPresentation.id,
                            price: ProductPresentation.price,
                            sku: ProductPresentation.sku,
                        },
                    };
                    qty = parseInt(props.values.qty);
                    if (!((_a = session === null || session === void 0 ? void 0 : session.Company) === null || _a === void 0 ? void 0 : _a.id))
                        throw new Error('No se encontró el identificador de la empresa.');
                    switch (session.Company.id.toString()) {
                        case '146':
                            PDF146(body, qty);
                            break;
                        case '84':
                            BarcodePDF84(body, qty);
                            break;
                        case '71':
                            PDF71(body, qty);
                            break;
                        default:
                            PDF(body, qty);
                            break;
                    }
                    return [3 /*break*/, 4];
                case 3:
                    error_1 = _b.sent();
                    getInputErrorFormat(error_1);
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    }); };
    return (React.createElement(ButtonFooterDialogComponent, { onClick: handleClick }, "Imprimir etiquetas"));
};
export default PrintBarcodeLabelButtonComponent;
