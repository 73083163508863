var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var convertTotalizeCartToCreatePurchaseOrderCart = function (totalizeCart, cart) {
    return totalizeCart.map(function (el) {
        var CartItem = cart.find(function (elCh) { return elCh.ProductPresentation.id === el.productPresentationId; });
        if (!CartItem)
            throw new Error('Error interno al realizar la búsqueda de productos, contacto soporte técnico.');
        return __assign(__assign({}, CartItem), { withoutTaxes: el.withoutTaxes, price: el.unitPrice, taxes: el.taxes, total: el.total });
    });
};
export default convertTotalizeCartToCreatePurchaseOrderCart;
