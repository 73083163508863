var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
var OnlineOrderMenuButtonComponent = function (_a) {
    var Icon = _a.Icon, props = __rest(_a, ["Icon"]);
    return (React.createElement("div", { onClick: !props.disabled ? props.onClick : undefined, role: 'button', tabIndex: -1, className: "flex flex-col sm:flex-row items-center px-4 py-3 sm:gap-4 rounded-md min-w-40 ".concat(props.disabled ? 'bg-slate-100' : 'hover:bg-slate-200 cursor-pointer') },
        React.createElement(Icon, { fontSize: 'large', className: 'text-gray-500' }),
        React.createElement("div", { className: 'flex flex-col items-center sm:items-start' },
            React.createElement("div", { className: 'text-base tracking-tighter text-gray-700 whitespace-nowrap' }, props.label),
            React.createElement("div", { className: 'text-sm tracking-tighter leading-none text-gray-600 text-center sm:text-left' }, props.value),
            React.createElement("div", { className: 'text-xs tracking-tighter leading-none text-gray-400 text-center sm:text-left' }, props.description))));
};
export default OnlineOrderMenuButtonComponent;
