var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { Button } from '@mui/material';
var RHFButtonComponent = function (_a) {
    var children = _a.children, props = __rest(_a, ["children"]);
    return (React.createElement(Button, __assign({}, props, { type: props.type || 'submit', variant: props.variant || 'contained', size: props.size || 'large', color: props.color || 'success', className: "px-4 md:px-6 py-2 md:py-3 whitespace-nowrap ".concat(props.className || '') }), children));
};
export default RHFButtonComponent;
