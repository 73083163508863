import React, { useState } from 'react';

import { useMutation, useQuery } from '@apollo/client';

import LinearProgress from '@mui/material/LinearProgress';
import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';

import TextField from '../../presentationals/FormComponents/TextField';
import DateField from '../../presentationals/FormComponents/DateField';
import SelectField from '../../presentationals/FormComponents/SelectField';

import { parseError } from '../../helpers';

import GQL from './_gql';
import GQL_PAYMENT_METHOD from '../paymentMethod/_gql';
import useSnackbarDialog from '../../store/snackbar/snackbarDialog';

const defaultValues = {
  total: 0,
  subtotal: 0,
  discount: 0,
  iva: 0,
  ieps: 0,
  folio: '',
  date: new Date(),
  userPassword: '',
  paymentMethodId: 0,
};

const defaultSnackbar = { isOpen: true, time: 3000 };

const Component = props => {
  const { setSnackbar } = useSnackbarDialog();
  const [values, setValues] = useState(defaultValues);
  const [errors, setErrors] = useState({});
  const [paymentMethods, setPaymentMethods] = useState([]);

  const [createCreditNotePurchaseOrder, { loading }] = useMutation(
    GQL.CREATE_CREDIT_NOTE_PURCHASE_ORDER,
  );

  useQuery(GQL_PAYMENT_METHOD.GET, {
    variables: { limit: 0 },
    onCompleted: data => {
      const tmp = data.paymentMethods.rows.map(el => ({
        label: el.name,
        value: el.id,
      }));
      setPaymentMethods([{ label: 'EFECTIVO', value: 0 }, ...tmp]);
    },
  });

  const handleAction = async () => {
    try {
      if (
        !values.subtotal ||
        Number.isNaN(parseFloat(values.subtotal)) ||
        parseFloat(values.subtotal) <= 0
      ) {
        const e = new Error('El subtotal debe de ser mayor a cero');
        e.name = 'subtotal';
        e.fields = true;
        throw e;
      }
      if (Number.isNaN(parseFloat(values.discount)) || parseFloat(values.discount) < 0) {
        const e = new Error('El descuento debe de ser mayor o igual a cero');
        e.name = 'discount';
        e.fields = true;
        throw e;
      }
      if (Number.isNaN(parseFloat(values.iva)) || parseFloat(values.iva) < 0) {
        const e = new Error('El IVA debe de ser mayor 0 igual a cero');
        e.name = 'iva';
        e.fields = true;
        throw e;
      }
      if (Number.isNaN(parseFloat(values.ieps)) || parseFloat(values.ieps) < 0) {
        const e = new Error('El IEPS debe de ser mayor 0 igual a cero');
        e.name = 'ieps';
        e.fields = true;
        throw e;
      }
      if (
        !values.total ||
        Number.isNaN(parseFloat(values.total)) ||
        parseFloat(values.total) <= 0
      ) {
        const e = new Error('El total debe de ser mayor a cero');
        e.name = 'total';
        e.fields = true;
        throw e;
      }
      if (!values.folio || values.folio.trim() === '') {
        const e = new Error('Folio es obligatorio');
        e.name = 'folio';
        e.fields = true;
        throw e;
      }

      await createCreditNotePurchaseOrder({
        variables: {
          id: props.id,
          userPassword: values.userPassword,
          subtotal: parseFloat(values.subtotal),
          discount: parseFloat(values.discount) || 0,
          iva: parseFloat(values.iva),
          ieps: parseFloat(values.ieps),
          total: parseFloat(values.total),
          date: values.date,
          folio: values.folio,
          paymentMethodId: parseInt(values.paymentMethodId) === 0 ? null : values.paymentMethodId,
        },
      });

      setValues(defaultValues);
      props.handleClose();
    } catch (e) {
      if (e.fields) {
        setErrors({ [e.name]: e.message });
        return;
      }
      const parseErrors = parseError(e);
      parseErrors.forEach(el => {
        if (el.name === 'BAD_USER_INPUT') {
          const key = Object.keys(el.message)[0];
          setSnackbar({ ...defaultSnackbar, label: el.message[key], severity: 'error' });
        } else {
          setSnackbar({ ...defaultSnackbar, label: el.message, severity: 'error' });
        }
      });
    }
  };

  const handleClose = () => {
    setValues(defaultValues);
    props.handleClose();
  };

  const handleChange = (name, value) => {
    setValues(values => ({ ...values, [name]: value }));
  };

  return (
    <Dialog
      open={props.isOpen || false}
      fullWidth={true}
      maxWidth='xs'
      aria-labelledby='Confirmar'
      aria-describedby='Nota de Crédito por Bonificación'
    >
      {loading && <LinearProgress color='secondary' />}
      <DialogContent>
        <TextField
          name='folio'
          label='Ingrese el folio de la nota de crédito'
          autoFocus
          onChange={handleChange}
          value={values.folio}
          error={errors.folio}
          // variant='standard'
        />
        <TextField
          name='subtotal'
          label='Ingrese el subtotal de la nota de crédito'
          onChange={handleChange}
          value={values.subtotal}
          error={errors.subtotal}
          type='number'
          variant='standard'
        />
        <TextField
          name='discount'
          label='Ingrese el descuento de la nota de crédito'
          onChange={handleChange}
          value={values.discount}
          error={errors.discount}
          type='number'
          variant='standard'
        />
        <TextField
          name='iva'
          label='Ingrese el IVA de la nota de crédito'
          onChange={handleChange}
          value={values.iva}
          error={errors.iva}
          type='number'
          variant='standard'
        />
        <TextField
          name='ieps'
          label='Ingrese el IEPS de la nota de crédito'
          onChange={handleChange}
          value={values.ieps}
          error={errors.ieps}
          type='number'
          variant='standard'
        />
        <TextField
          name='total'
          label='Ingrese el total de la nota de crédito'
          onChange={handleChange}
          value={values.total}
          error={errors.total}
          type='number'
          variant='standard'
        />
        <SelectField
          name='paymentMethodId'
          label='Ingrese la forma de pago de la nota de crédito'
          onChange={handleChange}
          value={values.paymentMethodId}
          error={errors.paymentMethodId}
          options={paymentMethods}
          variant='standard'
        />
        <DateField
          name='date'
          label='Ingrese la fecha de la nota de crédito'
          onChange={handleChange}
          value={values.date}
          error={errors.date}
        />
        <TextField
          name='userPassword'
          label='PIN'
          onChange={handleChange}
          value={values.userPassword}
          error={errors.userPassword}
          type='password'
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color='primary'>
          Cerrar
        </Button>
        <Button onClick={handleAction} disabled={loading} color='primary' autoFocus>
          Continuar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default Component;
