import React from 'react';
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import DestinationBranchButtonComponent from './DestinationBranchButton';
import OriginBranchButtonComponent from './OriginBranch';
var BranchToTransferBodyProductsToTransferFormComponent = function () {
    return (React.createElement("div", { className: 'flex flex-row px-4 py-1 border-0 border-b border-solid border-gray-300 items-center bg-slate-50' },
        React.createElement("div", { className: 'flex flex-col' },
            React.createElement("div", { className: 'text-xs tracking-tighter leading-none text-gray-500' }, "Origen"),
            React.createElement(OriginBranchButtonComponent, null)),
        React.createElement(DoubleArrowIcon, { className: 'text-gray-700 mx-2' }),
        React.createElement(DestinationBranchButtonComponent, null)));
};
export default BranchToTransferBodyProductsToTransferFormComponent;
