import React, { useState, useEffect } from 'react';
import { useMutation, useReactiveVar } from '@apollo/client';

import Grid from '@mui/material/Grid';
import Chip from '@mui/material/Chip';

import LinearForm from '../../../../presentationals/LinearForm';
import TextField from '../../../../presentationals/TextField';
import DateField from '../../../../presentationals/DateField';

import { parseError } from '../../../../helpers/general';
import { discountType } from '../../../../apollo/cache';

import GQL from '../../_gql';
import useSnackbarDialog from '../../../../store/snackbar/snackbarDialog';

const defaultValues = {
  quantity: 1,
  unitPrice: 0,
  discount: 0,
  name: '',
  outDate: new Date(),
};

const defaultSnackbar = { isOpen: true, time: 3000 };

const Form = props => {
  const { setSnackbar } = useSnackbarDialog();

  const discountTypeVar = useReactiveVar(discountType);

  const [values, setValues] = useState(defaultValues);
  const [errors, setErrors] = useState({});
  const [ivaPercent, setIvaPercent] = useState(0);
  const [iepsPercent, setIepsPercent] = useState(0);
  const [addTaxes, setAddTaxes] = useState(false);

  const [createSaleExpense] = useMutation(GQL.CREATE_SALE_EXPENSE, {
    update(cache, { data }) {
      cache.modify({
        fields: {
          saleExpenses(cachedSale, { fieldName, storeFieldName }) {
            const args = JSON.parse(storeFieldName.replace(`${fieldName}(`, '').slice(0, -1));
            if (args && args.saleId && parseInt(args.saleId) === parseInt(props.id)) {
              const newSaleProductsRef = cache.writeFragment({
                data: { ...data.createSaleExpense },
                fragment: GQL.FRAGMENT_NEW_SALE_EXPENSE_PRODUCT,
              });
              return {
                ...cachedSale,
                count: cachedSale.count + 1,
                rows: [...cachedSale.rows, newSaleProductsRef],
              };
            }
            return cachedSale;
          },
        },
      });
    },
  });

  useEffect(() => {
    setValues(defaultValues);
    setIvaPercent(0);
    setIepsPercent(0);
    setAddTaxes(false);
  }, [props.codeToClear]);

  const handleChange = e => {
    const { name, value } = e.target;
    setValues(values => ({ ...values, [name]: value }));
  };
  const handleAddProduct = async () => {
    try {
      if (!values.name || values.name === '') {
        const e = new Error('Escribe un gasto.');
        e.name = 'name';
        e.input = true;
        throw e;
      }

      if (parseFloat(values.quantity) <= 0 || Number.isNaN(Number(values.quantity))) {
        const e = new Error('Proporciona la cantidad de gastos.');
        e.name = 'quantity';
        e.input = true;
        throw e;
      }

      if (values.discount < 0) {
        const e = new Error('Proporciona un descuento mayor o igual a cero.');
        e.name = 'discount';
        e.input = true;
        throw e;
      }

      if (values.discount > 100 && discountTypeVar === '%') {
        const e = new Error('Proporciona un descuento menor al 100%.');
        e.name = 'discount';
        e.input = true;
        throw e;
      }

      if (
        !values.unitPrice ||
        parseFloat(values.unitPrice) < 0 ||
        Number.isNaN(Number(values.unitPrice))
      ) {
        const e = new Error('Proporciona el precio del gasto.');
        e.name = 'unitPrice';
        e.input = true;
        throw e;
      }

      const amount = parseFloat(values.quantity) * parseFloat(values.unitPrice);

      let tmpDiscount = 0;

      if (discountTypeVar === '%') tmpDiscount = values.discount;
      else if (discountTypeVar === '$') tmpDiscount = (parseFloat(values.discount) / amount) * 100;

      const price = addTaxes
        ? parseFloat(values.unitPrice)
        : parseFloat(values.unitPrice) / (1 + parseFloat(ivaPercent) + parseFloat(iepsPercent));

      await createSaleExpense({
        variables: {
          saleId: props.id,
          name: values.name.trim(),
          quantity: parseFloat(values.quantity),
          discount: parseFloat(tmpDiscount),
          price,
          iva: parseFloat(ivaPercent) * 100,
          ieps: parseFloat(iepsPercent) * 100,
          outDate: values.outDate ? values.outDate : null,
        },
      });
      setValues(defaultValues);
      setErrors({});
      setIvaPercent(0);
      setIepsPercent(0);
      setAddTaxes(false);
    } catch (e) {
      console.log(e);
      if (e.input) {
        setErrors({ [e.name]: e.message });
      } else {
        console.log(e);
        const parseErrors = parseError(e);
        parseErrors.forEach(el => {
          if (el.name === 'BAD_USER_INPUT') {
            const key = Object.keys(el.message)[0];
            setSnackbar({ ...defaultSnackbar, label: el.message[key], severity: 'error' });
          } else {
            setSnackbar({ ...defaultSnackbar, label: el.message, severity: 'error' });
          }
        });
      }
    }
  };

  const handleIvaPercent = () => {
    const tmpIvaPercent = ivaPercent === 0 ? 0.16 : 0;
    setIvaPercent(tmpIvaPercent);
  };

  const handleIepsPercent = () => {
    const tmpIepsPercent = iepsPercent === 0 ? 0.08 : 0;
    setIepsPercent(tmpIepsPercent);
  };

  const handleTaxes = () => {
    setAddTaxes(addTaxes => !addTaxes);
  };

  const handleDateChange = (name, value) => {
    setValues(values => ({ ...values, [name]: value }));
  };

  return (
    <LinearForm handleAdd={handleAddProduct} align='center'>
      <Grid container spacing={1}>
        <Grid item xs={12} md={12}>
          <TextField
            name='name'
            label='Concepto'
            variant='outlined'
            onChange={handleChange}
            value={values.name}
            error={errors.name}
            noFormat
          />
        </Grid>
        <Grid item xs={2} className='flex flex-1 justify-between items-center'>
          <Chip
            className='mt-1 h-[40px] flex flex-1'
            onClick={handleIvaPercent}
            label={`IVA ${ivaPercent * 100}%`}
            classes={{ label: 'px-1' }}
            color='primary'
            variant='outlined'
          />
          <Chip
            className='mt-1 h-[40px] flex flex-1'
            onClick={handleIepsPercent}
            label={`IEPS ${iepsPercent * 100}%`}
            classes={{ label: 'px-1' }}
            color='primary'
            variant='outlined'
          />
        </Grid>
        <Grid item xs={2} className='flex flex-1 justify-between items-center'>
          <Chip
            className='mt-1 h-[40px] flex flex-1'
            onClick={handleTaxes}
            label={addTaxes ? 'Añadir Impuestos' : 'Desglosar Impuestos'}
            classes={{ label: 'px-1' }}
            color='secondary'
            variant='outlined'
          />
        </Grid>
        <Grid item xs={4} md={2}>
          <TextField
            name='quantity'
            label='Cant.'
            type='number'
            variant='outlined'
            onChange={handleChange}
            value={values.quantity}
            error={errors.quantity}
            noFormat
          />
        </Grid>
        <Grid item xs={4} md={2}>
          <TextField
            name='discount'
            label={`Dscto. ${discountTypeVar}`}
            type='number'
            variant='outlined'
            onChange={handleChange}
            value={values.discount}
            error={errors.discount}
            noFormat
          />
        </Grid>
        <Grid item xs={4} md={2}>
          <TextField
            name='unitPrice'
            label='Precio Unitario'
            variant='outlined'
            type='number'
            onChange={handleChange}
            value={values.unitPrice}
            error={errors.unitPrice}
            noFormat
          />
        </Grid>
        <Grid item xs={4} md={2}>
          <DateField
            name='outDate'
            label='Fecha de Salida'
            variant='outlined'
            onChange={handleDateChange}
            value={values.outDate}
            error={errors.outDate}
            noFormat
            size='small'
          />
        </Grid>
      </Grid>
    </LinearForm>
  );
};

export default Form;
