import React from 'react';

import { useReactiveVar } from '@apollo/client';

import { Typography } from '@mui/material';

import { commentarySaleV2, formatSaleV2 } from '../../../../../../apollo/cache';
import RelateCfdi from '../../PaymentForm/InvoiceForm/RelateCfdi';

const CommentaryInfoSummaryFormBodyConfirmDialogSaleV2Component = () => {
  const commentarySaleV2Var = useReactiveVar(commentarySaleV2);
  const formatSaleV2Var = useReactiveVar(formatSaleV2);
  return (
    <div className='flex flex-1 flex-row'>
      <div>{formatSaleV2Var[2] && <RelateCfdi />}</div>
      <div className='flex flex-1 flex-col'>
        <Typography className='font-bold'>Comentarios</Typography>
        <Typography>{commentarySaleV2Var}</Typography>
      </div>
    </div>
  );
};

export default CommentaryInfoSummaryFormBodyConfirmDialogSaleV2Component;
