import React, { forwardRef, useEffect, useState } from 'react';
import { InputBase } from '@mui/material';
import useProductFieldStore, { useProductFieldStoreInstance, } from '../../../../../../store/productField';
var PopoverProductInputBaseFieldComponent = forwardRef(function (props, ref) {
    var setGlobalValue = useProductFieldStore().setValue;
    var storeInstance = useProductFieldStoreInstance();
    var _a = useState(function () {
        var value = storeInstance.getState().value;
        return value || '';
    }), value = _a[0], setValue = _a[1];
    useEffect(function () {
        var stopTyping = setTimeout(function () {
            setGlobalValue(value);
        }, 1000);
        return function () { return clearTimeout(stopTyping); };
    }, [value]);
    var handleChange = function (e) {
        setValue(e.target.value);
    };
    return (React.createElement(InputBase, { ref: ref, placeholder: props.placeholder, disabled: props.disabled, onChange: handleChange, value: value, className: 'flex flex-1 ml-1', inputProps: { 'aria-label': 'product-field' }, autoComplete: 'off' }));
});
export default PopoverProductInputBaseFieldComponent;
