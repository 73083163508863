import React from 'react';

import { ListItem } from '@mui/material';

import ListItemButton from './ListItemButton';
import SearchButton from './SearchButton';

const ItemTableSearchFormCustomerActionSaleV2Component = props => {
  return (
    <ListItemButton id={props.id} background={props.background}>
      <ListItem secondaryAction={<SearchButton id={props.id} />}>
        <div className='flex flex-col'>
          <div>{props.name}</div>
          {(props.identifier || props.businessName) && (
            <div className='text-sm text-gray-500'>{`${props.identifier} ${props.businessName}`}</div>
          )}
          {props.phone && <div className='text-sm text-gray-500'>Teléfono: {props.phone}</div>}
        </div>
      </ListItem>
    </ListItemButton>
  );
};

export default ItemTableSearchFormCustomerActionSaleV2Component;
