import { Card } from '@mui/material';
import React from 'react';
var NeedHelpBoxMainComponent = function () {
    return (React.createElement(Card, { className: 'flex flex-col text-gray-800 bg-slate-100 h-40 items-center justify-center' },
        React.createElement("div", { className: 'flex flex-1 flex-col items-center justify-center px-4 pt-3' },
            React.createElement("div", { className: 'text-2xl tracking-tighter mb-2' }, "\u00BFNecesitas ayuda?"),
            React.createElement("div", { className: 'text-xs tracking-tighter leading-tight text-center' }, "Selecciona esta opci\u00F3n si necesitas ayuda o deseas reportar un problema.")),
        React.createElement("div", { className: 'py-3 text-sm text-center' },
            "Tienes ",
            React.createElement("b", null, "0 ticket"),
            " abiertos")));
};
export default NeedHelpBoxMainComponent;
