import React, { useEffect, useState } from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';

import Grid from '@mui/material/Grid';

import Link from '@mui/material/Link';
import Table from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import Typography from '@mui/material/Typography';

import dateFormat from 'date-fns/format';
import parseISO from 'date-fns/parseISO';

import { currencyFormat } from '../../helpers';

import Autocomplete from '../../presentationals/FormComponents/Autocomplete';
import FormDialog from '../../presentationals/FormDialog';
import Checkbox from '../../presentationals/Checkbox';

import CONFIG from '../../config/config.json';

import InvoiceDialog from './invoice.dialog';

import GQL from './_gql';
import getInputErrorFormat from '../sale.v2/Action/helpers/getInputErrorFormat';
import getSession from '../../helpers/getSession';
import useApolloLazyQuery from '../../hooks/useApolloLazyQuery';

const defaultValues = {
  customerId: null,
};

const CreateFormComponent = props => {
  const [values, setValues] = useState(defaultValues);
  const [customers, setCustomers] = useState([]);
  const [transactions, setTransactions] = useState([]);
  const [errors, setErrors] = useState({});
  const [selected, setSelected] = useState({});
  const [isOpenInvoice, setIsOpenInvoice] = useState([false, null]);

  const [getData, { loading: loadingData, data }] = useApolloLazyQuery(GQL.GET);

  const [getTransactions] = useLazyQuery(GQL.GET_CUSTOMER_TRANSACTIONS, {
    onCompleted: data => {
      const session = getSession();
      let selectedTmp = { '-1': false };
      const tmp = data.transactionsCustomer.map(el => {
        selectedTmp = { ...selectedTmp, [el.id]: false };
        return {
          id: el.id,
          total: parseFloat(el.total),
          clientCreatedAt: el.clientCreatedAt,
          uuid: el.Sale.Invoice.uuid,
          paymentMethod: el.PaymentMethod ? el.PaymentMethod.name.trim() : 'EFECTIVO',
          folio:
            session?.Company?.id === 71
              ? `A ${el.Sale.Invoice.serial}`
              : `${el.Sale.Invoice.folio} ${el.Sale.Invoice.serial}`,
        };
      });
      const transactionsTmp =
        tmp.length > 1
          ? [{ id: '-1', total: '', clientCreatedAt: '', folio: 'Seleccionar Todos' }, ...tmp]
          : tmp;
      setTransactions(transactionsTmp);
      setSelected(selectedTmp);
    },
  });

  const [createInvoiceComplements, { loading }] = useMutation(GQL.CREATE_INVOICE_COMPLEMENT_MANY, {
    update(cache) {
      Object.keys(selected).forEach(key => {
        if (!selected[key]) return;
        cache.evict({
          id: cache.identify({ __typename: 'TransactionsCustomer', id: key }),
        });
        cache.gc();
      });
    },
  });

  useEffect(() => {
    if (data) {
      const tmp = data
        .map(el => {
          return {
            label: el.customer,
            value: el.customerId,
          };
        })
        .filter(el => el);
      setCustomers([...new Map(tmp.map(x => [x.value, x])).values()]);
    }
  }, [data]);

  useEffect(() => {
    if (props.isOpen) getData({ variables: { limit: 0, paid: null, paymentMethod: 'PPD' } });
  }, [props.isOpen]);

  useEffect(() => {
    if (values.customerId?.value)
      getTransactions({ variables: { customerId: values.customerId.value } });
  }, [values.customerId]);

  const handleClose = () => {
    props.handleClose();
    setValues(defaultValues);
    setSelected({});
    setErrors({});
    setTransactions([]);
  };

  const handleAction = async () => {
    try {
      if (!values.customerId?.value) {
        const e = new Error('Seleccione un cliente');
        e.name = 'customerId';
        e.fields = true;
        throw e;
      }

      const Transactions = Object.keys(selected).filter(key => !!selected[key] && key !== '-1');

      if (Transactions.length <= 0) {
        const e = new Error('Seleccione los abonos');
        e.name = 'table';
        e.fields = true;
        throw e;
      }
      const variables = {
        customerId: values.customerId.value,
        Transactions,
      };
      const response = await createInvoiceComplements({ variables });
      setIsOpenInvoice([true, response.data.createInvoiceComplements.uuid]);
      handleClose();
    } catch (e) {
      getInputErrorFormat(e);
    }
  };

  const handleChange = (name, value) => {
    setValues(values => ({ ...values, [name]: value }));
  };

  const handleCheckBox = (name, checked) => {
    if (name === '-1') {
      const tmp = Object.keys(selected).reduce((object, key) => {
        return { ...object, [key]: checked };
      }, selected);
      setSelected(tmp);
      return;
    }
    const numberOfTrue = Object.keys(selected).filter(key => !!selected[key]);
    let number = numberOfTrue.length;
    if (checked) number = number + 2;
    else number = number - 1;
    if (number === Object.keys(selected).length)
      setSelected(values => ({ ...values, [name]: checked, '-1': true }));
    else setSelected(values => ({ ...values, [name]: checked, '-1': false }));
  };

  const handleInvoiceClose = () => setIsOpenInvoice([false, null]);

  const handleInvoice = uuid => {
    window.open(`${CONFIG['restServer'].uri}/files/invoices/${uuid}/pdf`);
  };

  return (
    <>
      <FormDialog
        isOpen={props.isOpen}
        handleClose={handleClose}
        handleAction={handleAction}
        isLoading={loading || loadingData}
        action='crear'
        title='Complementos de Pago'
        fullScreen
        noPadding
      >
        <div className='py-1 px-3'>
          <Grid container spacing={3}>
            <Grid item xs={12} md={5}>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <Autocomplete
                    name='customerId'
                    label='Cliente'
                    value={values.customerId}
                    error={errors.customerId}
                    options={customers}
                    onChange={handleChange}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={7}>
              <TableContainer className='my-2 rounded min-h-[120px] border border-solid border-[#d8d8d8]'>
                <Table size='small'>
                  <TableHead>
                    <TableRow className='bg-[#f6f6f6]'>
                      <TableCell />
                      <TableCell>Folio</TableCell>
                      <TableCell align='right'>
                        <Typography variant='subtitle2' noWrap>
                          Importe
                        </Typography>
                      </TableCell>
                      <TableCell align='right'>
                        <Typography variant='subtitle2' noWrap>
                          Forma de Pago
                        </Typography>
                      </TableCell>
                      <TableCell align='right'>
                        <Typography variant='subtitle2' noWrap>
                          Fecha
                        </Typography>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {errors.table && (
                      <TableRow>
                        <TableCell colSpan={6}>
                          <Typography color='error' align='center' gutterBottom>
                            {errors.table}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    )}
                    {transactions.map((el, i) => (
                      <TableRow key={i}>
                        <TableCell>
                          <div className='flex'>
                            <Checkbox
                              handleChange={handleCheckBox}
                              noFormat
                              values={[
                                {
                                  name: el.id,
                                  label: '',
                                  checked: selected[el.id] || false,
                                },
                              ]}
                            />
                          </div>
                        </TableCell>
                        <TableCell>
                          {el.id !== '-1' ? (
                            <Link href='#' onClick={() => handleInvoice(el.uuid)}>
                              {el.folio}
                            </Link>
                          ) : (
                            el.folio
                          )}
                        </TableCell>
                        <TableCell align='right'>
                          {parseInt(el.id) !== -1 ? `$${currencyFormat(el.total)}` : ''}
                        </TableCell>
                        <TableCell align='right'>
                          {parseInt(el.id) !== -1 ? el.paymentMethod : ''}
                        </TableCell>
                        <TableCell align='right'>
                          {parseInt(el.id) !== -1
                            ? dateFormat(parseISO(el.clientCreatedAt), 'dd/MM/yyyy')
                            : ''}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        </div>
      </FormDialog>
      <InvoiceDialog
        isOpen={isOpenInvoice[0]}
        uuid={isOpenInvoice[1]}
        handleClose={handleInvoiceClose}
      />
    </>
  );
};

export default CreateFormComponent;
