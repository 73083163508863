import React, { useEffect } from 'react';
import { Skeleton } from '@mui/material';
import useApolloLazyQuery from '../../../../hooks/useApolloLazyQuery';
import GQL_PRODUCT from '../../../../apollo/gql/product';
var ProductNameProductHistoryLabelComponent = function (props) {
    var _a = useApolloLazyQuery(GQL_PRODUCT.GET_PRODUCT_BY_ID), getProduct = _a[0], _b = _a[1], data = _b.data, loading = _b.loading;
    useEffect(function () {
        if (props.id)
            getProduct({ variables: { id: props.id } });
    }, [props.id]);
    if (loading)
        return React.createElement(Skeleton, { variant: 'text', className: 'text-2xl', width: 180 });
    if (!data)
        return React.createElement("div", null);
    return React.createElement("div", { className: 'text-2xl tracking-tighter' }, data.name);
};
export default ProductNameProductHistoryLabelComponent;
