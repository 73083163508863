import React from 'react';
import { TableCell } from '@mui/material';
import TableRowItemProductPriceTableEditPresentationComponent from './TableRow';
import { currencyFormat } from '../../../../../helpers';
import getPlatformLabel from '../../../../../helpers/getPlatformLabel';
var ItemTableRateProductPresentationComponent = function (props) {
    var _a;
    return (React.createElement(TableRowItemProductPriceTableEditPresentationComponent, { productPresentationRateId: props.ProductPresentationPrice.id, background: props.index % 2 > 0 ? 'bg-inherit' : 'bg-gray-100' },
        React.createElement(TableCell, null, props.ProductPresentationPrice.Rate ? props.ProductPresentationPrice.Rate.name : '-'),
        React.createElement(TableCell, null, currencyFormat(props.ProductPresentationPrice.price)),
        React.createElement(TableCell, null, !props.ProductPresentationPrice.type
            ? 'Cualquier plataforma'
            : getPlatformLabel(props.ProductPresentationPrice.type)),
        React.createElement(TableCell, null, ((_a = props.ProductPresentationPrice.Branch) === null || _a === void 0 ? void 0 : _a.name) || 'Cualquier sucursal')));
};
export default ItemTableRateProductPresentationComponent;
