var getPurchaseOrderTableSettingColumnLabel = function (key) {
    switch (key) {
        case 'provider':
            return 'Proveedor';
        case 'total':
            return 'Importe';
        default:
            return 'Indefinido';
    }
};
export default getPurchaseOrderTableSettingColumnLabel;
