import React, { useEffect, useState } from 'react';
import Card from '@mui/material/Card';
import { useParams } from 'react-router-dom';
import ItemTransactionComponent from './Item';
import useApolloQuery from '../../../../../hooks/useApolloQuery';
import GQL_TRANSACTION from '../../../../../apollo/gql/transaction';
import LoadingProgress from '../../../../../presentationals/LoadingProgress';
import getPaymentFormArrayByTransactions from './helpers/getPaymentFormArrayByTransactions';
var TransactionComponent = function () {
    var params = useParams();
    var _a = useState([]), paymentForms = _a[0], setPaymentForms = _a[1];
    var _b = useApolloQuery(GQL_TRANSACTION.GET_TRANSACTIONS, {
        variables: { cashClosingId: params.cashClosingId },
    }), data = _b.data, loading = _b.loading;
    useEffect(function () {
        if (data) {
            var PaymentForms = getPaymentFormArrayByTransactions(data);
            setPaymentForms(PaymentForms);
        }
    }, [data]);
    return (React.createElement(Card, { className: 'mt-2 flex flex-1 flex-col overflow-hidden' },
        React.createElement("div", { className: 'flex flex-col bg-slate-700 text-white p-2' },
            React.createElement("div", { className: 'font-semibold' }, "Transacciones"),
            React.createElement("div", { className: 'flex flex-row' },
                React.createElement("div", { className: 'flex flex-1 text-sm font-normal' }, "Forma de pago"),
                React.createElement("div", { className: 'flex flex-1 text-sm justify-center font-normal' }, "Cantidad"),
                React.createElement("div", { className: 'flex flex-1 text-sm justify-end font-normal' }, "Total"))),
        loading ? (React.createElement(LoadingProgress, null)) : (React.createElement("div", { className: 'flex flex-1 flex-col sm:basis-0 overflow-auto' }, paymentForms.map(function (el) { return (React.createElement(ItemTransactionComponent, { key: el.id, paymentForm: el.name, qty: el.qty, total: el.total })); })))));
};
export default TransactionComponent;
