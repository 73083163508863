import React, { useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import { IconButton } from '@mui/material';
import { useReactiveVar } from '@apollo/client';
import TextFieldComponent from '../../../FormComponents/TextField';
import { inputError } from '../../../../apollo/cache';
import setInputErrorFormat from '../../../../containers/sale.v2/Action/helpers/setInputErrorFormat';
import getInputErrorFormat from '../../../../containers/sale.v2/Action/helpers/getInputErrorFormat';
var EmailFieldSendSaleComponent = function (props) {
    var errors = useReactiveVar(inputError);
    var _a = useState(''), value = _a[0], setValue = _a[1];
    var handleChange = function (name, value) {
        setValue(value);
    };
    var handleClick = function () {
        try {
            inputError({});
            if (!value)
                throw new Error(setInputErrorFormat('email', 'Proporciona el correo electrónico.'));
            if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value))
                throw new Error(setInputErrorFormat('email', 'Proporciona un correo electrónico válido.'));
            props.onClick(value);
            setValue('');
        }
        catch (error) {
            getInputErrorFormat(error);
        }
    };
    var handleKeyDown = function (e) {
        if (e.key === 'Enter')
            handleClick();
    };
    return (React.createElement("div", { className: 'flex items-start' },
        React.createElement(TextFieldComponent, { defaultLabel: 'Correo electr\u00F3nico', name: 'email', onChange: handleChange, onKeyDown: handleKeyDown, value: value, autoFocus: true, error: errors.email, type: 'email' }),
        React.createElement("div", { className: 'pl-2' },
            React.createElement(IconButton, { className: 'text-white bg-green-600 hover:bg-green-700 mt-1', "aria-label": 'addEmail', onClick: handleClick },
                React.createElement(AddIcon, null)))));
};
export default EmailFieldSendSaleComponent;
