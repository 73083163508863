var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React, { useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';
import { Divider } from '@mui/material';
import NumericField from '../../../../helpers/numericField';
import useApolloQuery from '../../../../../../../../../../hooks/useApolloQuery';
import GQL_CALENDAR from '../../../../../../../../../../apollo/gql/calendar';
var QtyPerHourItemAgendaSettingsScheduleAvailabilityDialogSettingButtonFooterFormCalendarComponent = function (props) {
    var _a = useApolloQuery(GQL_CALENDAR.EMPLOYEE_CALENDAR_SETTINGS), data = _a.data, loadingData = _a.loading;
    var _b = useMutation(GQL_CALENDAR.UPDATE_EMPLOYEE_CALENDAR_SETTINGS), update = _b[0], loading = _b[1].loading;
    var _c = useState({ schedulePerHour: [0, 0], startHour: 9 }), values = _c[0], setValues = _c[1];
    useEffect(function () {
        var _a;
        if ((_a = data === null || data === void 0 ? void 0 : data.scheduleAvailability) === null || _a === void 0 ? void 0 : _a.scheduleSettings) {
            var currentSchedule_1 = data.scheduleAvailability.scheduleSettings.find(function (el, i) { return i === props.index; });
            currentSchedule_1 &&
                setValues(function (values) { return (__assign(__assign({}, values), { schedulePerHour: currentSchedule_1.schedulePerHour
                        ? currentSchedule_1.schedulePerHour
                        : [0, 0], startHour: currentSchedule_1.startHour ? currentSchedule_1.startHour : 9 })); });
        }
    }, [data]);
    var handleClick = function (action, _a) {
        var position = _a.position;
        return __awaiter(void 0, void 0, void 0, function () {
            var currentValue, newValue, newSchedulePerHour, isInArray, newScheduleSettings;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        if (!data)
                            return [2 /*return*/];
                        currentValue = values.schedulePerHour[position];
                        newValue = action === 'remove' ? currentValue - 1 : currentValue + 1;
                        newSchedulePerHour = [
                            position === 0 ? newValue : values.schedulePerHour[0],
                            position === 1 ? newValue : values.schedulePerHour[1],
                        ];
                        setValues(function (values) { return (__assign(__assign({}, values), { schedulePerHour: newSchedulePerHour })); });
                        isInArray = data.scheduleAvailability.scheduleSettings.find(function (el, i) { return i === props.index; });
                        newScheduleSettings = isInArray
                            ? data.scheduleAvailability.scheduleSettings.map(function (el, i) {
                                return i === props.index ? __assign(__assign({}, el), { schedulePerHour: newSchedulePerHour }) : el;
                            })
                            : __spreadArray(__spreadArray([], data.scheduleAvailability.scheduleSettings, true), [
                                { schedulePerHour: newSchedulePerHour },
                            ], false);
                        return [4 /*yield*/, update({
                                variables: {
                                    scheduleAvailability: __assign(__assign({}, data.scheduleAvailability), { scheduleSettings: newScheduleSettings }),
                                },
                            })];
                    case 1:
                        _b.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: 'px-2' },
            React.createElement(NumericField, { disabled: loadingData || loading, label: "Horarios disponibles para las ".concat(values.startHour, ":00:"), params: { position: 0 }, handleClick: handleClick, number: values.schedulePerHour[0] })),
        React.createElement(Divider, { className: 'my-4' }),
        React.createElement("div", { className: 'px-2' },
            React.createElement(NumericField, { disabled: loadingData || loading, label: "Horarios disponibles para las ".concat(values.startHour, ":30:"), params: { position: 1 }, handleClick: handleClick, number: values.schedulePerHour[1] }))));
};
export default QtyPerHourItemAgendaSettingsScheduleAvailabilityDialogSettingButtonFooterFormCalendarComponent;
