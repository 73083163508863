import React, { useEffect } from 'react';
import { useQuery } from '@apollo/client';
import ActionSaleV2Component from './Action';
import ChooseProductSaleV2Component from './ChooseProduct';
import CartSaleV2Component from './Cart';
import FooterSaleV2Component from './Footer';
import ConfirmDialogSaleV2Component from './ConfirmDialog';
import getDefaultTotalToPay from './helpers/getDefaultTotalToPay';
import { saleTotalToPay, isPettyCashOpen, formatSaleV2 } from '../../apollo/cache';
import SessionCalendarForm from './sessionCalendar.dialog';
import GQL from './_gql';
import LoadingProgress from '../../presentationals/LoadingProgress';
import PettyCash from './pettyCash.dialog';
import useBranchConfig from '../../hooks/useBranchConfig';

const SaleV2Component = () => {
  const { data: branchConfig, loading: loadingBranchConfig } = useBranchConfig();
  const { data, loading } = useQuery(GQL.GET_OPEN_PETTY_CASH);

  useEffect(() => {
    if (branchConfig) {
      const formatSaleV2Var = formatSaleV2();
      formatSaleV2([
        formatSaleV2Var[0],
        branchConfig.defaultTaxesTypeOption,
        formatSaleV2Var[2],
        formatSaleV2Var[3],
      ]);
    }
  }, [branchConfig]);

  useEffect(() => {
    if (data && !data.openPettyCash) isPettyCashOpen(true);
  }, [data]);

  useEffect(() => {
    return () => {
      const defaultTotalToPay = getDefaultTotalToPay();
      saleTotalToPay(defaultTotalToPay);
    };
  }, []);

  if (loading || loadingBranchConfig) return <LoadingProgress />;
  return (
    <div className='flex flex-1 flex-col h-full p-2 sm:p-4 bg-slate-50'>
      <ActionSaleV2Component />
      <ChooseProductSaleV2Component />
      <CartSaleV2Component />
      <FooterSaleV2Component />
      <ConfirmDialogSaleV2Component />
      <SessionCalendarForm />
      <PettyCash />
    </div>
  );
};

export default SaleV2Component;
