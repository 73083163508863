import React, { useEffect, useState } from 'react';

import { useLazyQuery, useReactiveVar } from '@apollo/client';

import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';

import { filter, count, totals } from '../../../../apollo/cache';
import { currencyFormat } from '../../../../helpers';

import ActionButton from './ActionButton';
import DeleteButton from './DeleteButton';
import EditButton from './EditButton';
import TableCell from './TableCell';
import format from './format';

const BodyComponent = props => {
  const filterVar = useReactiveVar(filter);
  const [data, setData] = useState([]);

  const [getMore, { data: response, loading }] = useLazyQuery(props.gql);
  useEffect(() => {
    props.handleLoading(loading);
  }, [loading]);

  useEffect(() => {
    getMore({ variables: filterVar });
  }, [filterVar]);

  useEffect(() => {
    if (response && response[props.operation[1]].count >= 0)
      count(response[props.operation[1]].count);

    if (response && response[props.operation[1]].totals) {
      const newTotals = response[props.operation[1]].totals.map(el => ({
        value: `$${currencyFormat(el.amount)}`,
        label: `${el.name}${el.qty ? `(${currencyFormat(el.qty)})` : ''}`,
      }));
      totals(newTotals);
    }

    if (response && response[props.operation[1]].rows) setData(response[props.operation[1]].rows);
    else if (response && response[props.operation[1]].length > 0)
      setData(response[props.operation[1]]);
    else if (response && response[props.operation[1]].length === 0) setData([]);
  }, [response]);

  return (
    <TableBody>
      {data.map(data => (
        <TableRow
          key={data.id}
          tabIndex={-1}
          hover
          style={{
            background: data.background
              ? data.background === 'danger'
                ? '#ff4500'
                : data.background === 'warning'
                ? '#ffff00'
                : '#ffffff'
              : '#ffffff',
          }}
        >
          {props.Rows.map(el => {
            let dataFormatTmp = el.format;
            let ifNull = false;
            let value = el.key.includes(' ')
              ? el.key.includes(' && ')
                ? el.key.split(' && ').reduce((stringPincipal, elPincipal) => {
                    const res = elPincipal.split(' ').reduce((string, elCh) => {
                      const tmpData = string[elCh] || '';
                      return tmpData;
                    }, data);
                    return stringPincipal !== ''
                      ? `${stringPincipal}${el.strcat || ' '}${res}`
                      : res;
                  }, '')
                : el.key.split(' ').reduce((string, elCh) => {
                    const tmpData = string[elCh] || '';
                    return tmpData;
                  }, data)
              : data[el.key];
            if (String(value).trim() === '' && el.ifNull) value = el.ifNull;
            if (String(value).trim() === '' && el.ifNullKey) {
              const valueTmp = el.ifNullKey.includes(' ')
                ? el.ifNullKey.includes(' && ')
                  ? el.ifNullKey.split(' && ').reduce((stringPincipal, elPincipal) => {
                      const res = elPincipal.split(' ').reduce((string, elCh) => {
                        const tmpData = string[elCh] || '';
                        return tmpData;
                      }, data);
                      return stringPincipal !== ''
                        ? `${stringPincipal}${el.strcat || ' '}${res}`
                        : res;
                    }, '')
                  : el.ifNullKey.split(' ').reduce((string, elCh) => {
                      const tmpData = string[elCh] || '';
                      return tmpData;
                    }, data)
                : data[el.ifNullKey];
              value = valueTmp || '-';
              ifNull = true;
            }
            if (el.ifValue) {
              const valueTmp = el.ifValue.key.includes(' ')
                ? el.ifValue.key.includes(' && ')
                  ? el.ifValue.key.split(' && ').reduce((stringPincipal, elPincipal) => {
                      const res = elPincipal.split(' ').reduce((string, elCh) => {
                        const tmpData = string[elCh] || '';
                        return tmpData;
                      }, data);
                      return stringPincipal !== ''
                        ? `${stringPincipal}${el.strcat || ' '}${res}`
                        : res;
                    }, '')
                  : el.ifValue.key.split(' ').reduce((string, elCh) => {
                      const tmpData = string[elCh] || '';
                      return tmpData;
                    }, data)
                : data[el.ifValue.key];
              if (String(valueTmp).trim() === String(el.ifValue.value).trim()) {
                value = el.ifValue.newValue || '-';
                ifNull = true;
              }
            }
            if (String(value).trim() === '') value = '-';

            if (['currency', 'number'].includes(dataFormatTmp)) {
              dataFormatTmp = null;
              value = !Number.isNaN(parseFloat(value)) ? parseFloat(value) : 0;
            }
            if (el.padStart && dataFormatTmp !== 'currency')
              value = `${value}`.padStart(el.padStart[0], el.padStart[1]);
            if (!ifNull && el.prefix && dataFormatTmp !== 'currency')
              value = `${el.prefix} ${value}`;
            if (ifNull && el.ifNullPrefix && dataFormatTmp !== 'currency')
              value = `${el.ifNullPrefix} ${value}`;

            return (
              <TableCell
                key={el.key}
                align={el.align}
                noWrap={!el.wrap}
                url={value}
                format={el.format}
              >
                {format(value, el.format)}
              </TableCell>
            );
          })}
          <TableCell align='right' noWrap>
            {!data.deletedAt && (
              <>
                <ActionButton data={data} />
                {!props.noEdit && props.edit && (
                  <EditButton
                    operation={props.operation[0]}
                    id={data.id}
                    getById={props.edit}
                    extraParams={props.extraParams}
                  />
                )}
                {!props.noDelete && <DeleteButton data={data} />}
              </>
            )}
          </TableCell>
        </TableRow>
      ))}
    </TableBody>
  );
};

export default BodyComponent;
