import React, { useEffect, useState } from 'react';
import { Tabs, Tab } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import CategoryIcon from '@mui/icons-material/Category';
import StyleIcon from '@mui/icons-material/Style';
import GridViewIcon from '@mui/icons-material/GridView';
import PercentIcon from '@mui/icons-material/Percent';
import getMenuValueFromPath from './getMenuValueFromPath';
import getRouteFromMenuValue from './getRouteFromMenuValue';
import getSession from '../../../helpers/getSession';
var MenuSaleLayoutComponent = function () {
    var navigate = useNavigate();
    var location = useLocation();
    var _a = useState(0), value = _a[0], setValue = _a[1];
    var _b = useState(false), show = _b[0], setShow = _b[1];
    useEffect(function () {
        var _a;
        var session = getSession();
        if (!(session === null || session === void 0 ? void 0 : session.Company))
            return;
        setShow(((_a = session === null || session === void 0 ? void 0 : session.Company) === null || _a === void 0 ? void 0 : _a.id.toString()) === '118');
    }, []);
    useEffect(function () {
        var value = getMenuValueFromPath(location.pathname);
        setValue(value);
    }, [location.pathname]);
    var handleChange = function (e, value) {
        var route = getRouteFromMenuValue(value);
        if (route)
            navigate(route);
    };
    return (React.createElement(Tabs, { value: value, onChange: handleChange, variant: 'scrollable', scrollButtons: 'auto', className: 'flex bg-slate-100 border-0 border-y border-[#d6d6d6] border-solid' },
        React.createElement(Tab, { icon: React.createElement(CategoryIcon, null), label: 'Productos' }),
        React.createElement(Tab, { icon: React.createElement(StyleIcon, null), label: 'Presentaci\u00F3n' }),
        React.createElement(Tab, { icon: React.createElement(GridViewIcon, null), label: 'Componentes' }),
        React.createElement(Tab, { icon: React.createElement(PercentIcon, null), label: 'Promociones' }),
        show && React.createElement(Tab, { icon: React.createElement(CategoryIcon, null), label: 'Todos' })));
};
export default MenuSaleLayoutComponent;
