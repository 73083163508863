import React, { useEffect, useState } from 'react';
import { Divider } from '@mui/material';
import BalanceIcon from '@mui/icons-material/Balance';
import FactoryIcon from '@mui/icons-material/Factory';
import ReceivableLinkBlockTwoMenuDashboardComponent from './ReceivableLink';
import PayableLinkBlockTwoListItemsMenuDashboardComponent from './PayableLink';
import TitleDrawerMenuDashboardComponent from '../helpers/Title';
import IncomeStatementLinkBlockTwoListItemsMenuDashboardComponent from './IncomeStatementLink';
import ExpensesLinkBlockTwoListItemsMenuDashboardComponent from './ExpensesLink';
import TransformationLinkBlockTwoListItemsMenuDashboardComponent from './TransformationLink';
import useGetSessionRole from '../../../../../hooks/useGetSessionRole';
import getIfShowTrackings from './helpers/getIfShowTrackings';
import getIfShowManufacture from './helpers/getIfShowManufacture';
import getIfShowFinance from './helpers/getIfShowFinance';
import TrackingSectionMenuComponent from './Trackings';
var BlockTwoMenuDashboardComponent = function (props) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
    var _l = useGetSessionRole(), data = _l.data, loading = _l.loading, isOwner = _l.isOwner;
    var _m = useState(true), showTrackings = _m[0], setShowTrackings = _m[1];
    var _o = useState(true), showFinance = _o[0], setShowFinance = _o[1];
    var _p = useState(true), showManufacture = _p[0], setShowManufacture = _p[1];
    useEffect(function () {
        if (data) {
            var showTrackings_1 = getIfShowTrackings(data.Permissions);
            setShowTrackings(showTrackings_1 || isOwner);
            var showManufacture_1 = getIfShowManufacture(data.Permissions);
            setShowManufacture(showManufacture_1 || isOwner);
            var showFinance_1 = getIfShowFinance(data.Permissions);
            setShowFinance(showFinance_1 || isOwner);
        }
    }, [data, isOwner]);
    if (loading || !data || (!showTrackings && !showFinance && !showManufacture))
        return null;
    return (React.createElement("div", { className: 'w-full' },
        showTrackings && React.createElement(TrackingSectionMenuComponent, { posModule: props.posModule }),
        showFinance && (React.createElement(React.Fragment, null,
            React.createElement(TitleDrawerMenuDashboardComponent, { icon: React.createElement(BalanceIcon, null), title: 'Finanzas / contabilidad' }),
            (isOwner || !!((_b = (_a = data.Permissions) === null || _a === void 0 ? void 0 : _a.Receivable) === null || _b === void 0 ? void 0 : _b.getReceivable)) && (React.createElement(ReceivableLinkBlockTwoMenuDashboardComponent, null)),
            (isOwner || !!((_d = (_c = data.Permissions) === null || _c === void 0 ? void 0 : _c.Payable) === null || _d === void 0 ? void 0 : _d.getPayable)) && (React.createElement(PayableLinkBlockTwoListItemsMenuDashboardComponent, null)),
            (isOwner || !!((_f = (_e = data.Permissions) === null || _e === void 0 ? void 0 : _e.Expense) === null || _f === void 0 ? void 0 : _f.getExpenses)) && (React.createElement(ExpensesLinkBlockTwoListItemsMenuDashboardComponent, null)),
            (isOwner || !!((_h = (_g = data.Permissions) === null || _g === void 0 ? void 0 : _g.IncomeStatement) === null || _h === void 0 ? void 0 : _h.getIncomeStatement)) && (React.createElement(IncomeStatementLinkBlockTwoListItemsMenuDashboardComponent, null)),
            React.createElement(Divider, { className: 'my-2' }))),
        showManufacture && (React.createElement(React.Fragment, null,
            React.createElement(TitleDrawerMenuDashboardComponent, { icon: React.createElement(FactoryIcon, null), title: 'Producci\u00F3n / Manufactura' }),
            (isOwner || !!((_k = (_j = data.Permissions) === null || _j === void 0 ? void 0 : _j.Transformation) === null || _k === void 0 ? void 0 : _k.getTransformation)) && (React.createElement(TransformationLinkBlockTwoListItemsMenuDashboardComponent, null)),
            React.createElement(Divider, { className: 'my-2' })))));
};
export default BlockTwoMenuDashboardComponent;
