import { Popover } from '@mui/material';
import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import SelectBranchInventoryReportMenuItemsComponent from '../../../containers/inventoryReport/SelectBranchInput/Menu/Items';
var RHFBranchPopoverComponent = forwardRef(function (props, ref) {
    var _a = useState(null), popover = _a[0], setPopover = _a[1];
    var control = useFormContext().control;
    var _b = useController({
        name: 'branch',
        control: control,
    }).field, onChange = _b.onChange, value = _b.value;
    var handleClose = function () {
        setPopover(null);
    };
    var handleOpen = function (anchorEl) {
        setPopover(anchorEl);
    };
    useImperativeHandle(ref, function () { return ({
        open: handleOpen,
    }); });
    var handleClick = function (id, label) {
        onChange({ id: id, name: label });
        handleClose();
    };
    return (React.createElement(Popover, { id: "BranchPopover".concat(props.name), open: !!popover, anchorEl: popover, onClose: handleClose, anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'right',
        }, transformOrigin: {
            vertical: 'top',
            horizontal: 'right',
        }, slotProps: {
            paper: {
                className: 'w-64',
            },
        } },
        React.createElement(SelectBranchInventoryReportMenuItemsComponent, { selected: value ? value.id : undefined, onClick: handleClick })));
});
export default RHFBranchPopoverComponent;
