import React from 'react';
import UpdateQueueManagerLogoImageButtonComponent from './UpdateButton';
import ImageComponent from '../../../../product/Item/Image/Item';
var LogoQueueManagerCompanyComponent = function (props) {
    return (React.createElement("div", null,
        React.createElement("div", { className: 'flex flex-1 flex-col' },
            React.createElement("div", { className: 'flex text-xl font-medium' }, "Configuraci\u00F3n del logotipo"),
            React.createElement("div", { className: 'w-1/4 mt-2' },
                React.createElement(UpdateQueueManagerLogoImageButtonComponent, null)),
            props.fileId ? (React.createElement("div", { className: 'w-full sm:w-1/3 mt-2' },
                React.createElement(ImageComponent, { width: 'full', src: props.fileId.toString(), alt: "company-".concat(props.fileId) }))) : (React.createElement("div", { className: 'mt-2 text-base font-light' }, "De momento no cuentas con un logotipo configurado, por lo que se tomar\u00E1 el que tienes dado de alta por default en la plataforma.")))));
};
export default LogoQueueManagerCompanyComponent;
