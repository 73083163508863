var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useState } from 'react';
import { useReactiveVar } from '@apollo/client';
import TextFieldComponent from '../../../../../../presentationals/FormComponents/TextField';
import { inputError } from '../../../../../../apollo/cache';
import FooterDialogComponent from '../../../../../../presentationals/Dialog/Footer';
import CreateButtonNameRateComponent from '../CreateButton';
var BodyCreateNameRateComponent = function () {
    var defaultValues = {
        name: '',
    };
    var errors = useReactiveVar(inputError);
    var _a = useState(defaultValues), values = _a[0], setValues = _a[1];
    var handleChange = function (name, value) {
        setValues(function (values) {
            var _a;
            return (__assign(__assign({}, values), (_a = {}, _a[name] = value, _a)));
        });
    };
    return (React.createElement("div", { className: 'flex flex-1 flex-col' },
        React.createElement("div", { className: 'flex flex-1 sm:grid sm:grid-cols-4 px-3 py-4' },
            React.createElement("div", { className: 'flex flex-1 flex-row' },
                React.createElement(TextFieldComponent, { name: 'name', label: 'Nombre', value: values.name, onChange: handleChange, error: errors.name }))),
        React.createElement(FooterDialogComponent, null,
            React.createElement("div", null),
            React.createElement(CreateButtonNameRateComponent, { name: values.name }))));
};
export default BodyCreateNameRateComponent;
