var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import { useNavigate } from 'react-router-dom';
import DeviceHubIcon from '@mui/icons-material/DeviceHub';
import { filter } from '../../../apollo/cache';
var getSaleProductPresentationRotationReportActions = function () {
    var navigate = useNavigate();
    return [
        {
            icon: React.createElement(DeviceHubIcon, null),
            label: 'Desglose de rotación',
            onClick: function (identifier) {
                var currentFilter = filter();
                filter(__assign(__assign({}, currentFilter), { like: [currentFilter.like ? currentFilter.like[0] : 'name', ''] }));
                navigate(identifier.toString());
            },
        },
    ];
};
export default getSaleProductPresentationRotationReportActions;
