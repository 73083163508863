var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useEffect, useState } from 'react';
import PercentIcon from '@mui/icons-material/Percent';
import ListItemPermissionEditRoleComponent from '../helpers/ListItem';
import SwitchPermissionFieldComponent from '../helpers/SwitchPermissionField';
var defaultPromotionPermissionValues = {
    createNewPromotion: false,
    editPromotion: false,
    getPromotion: false,
    deletePromotion: false,
};
var PromotionPermissionEditRoleComponent = function (props) {
    var _a = useState(defaultPromotionPermissionValues), values = _a[0], setValues = _a[1];
    useEffect(function () {
        setValues(function (values) { return (__assign(__assign({}, values), props.Permissions)); });
    }, [props.Permissions]);
    return (React.createElement(ListItemPermissionEditRoleComponent, { icon: React.createElement(PercentIcon, null), name: 'Promociones' },
        React.createElement("div", { className: 'mx-4 py-3 grid grid-cols-1 md:grid-cols-3 gap-4' },
            React.createElement("div", { className: 'flex flex-col' },
                React.createElement(SwitchPermissionFieldComponent, __assign({}, props, { name: 'createNewPromotion', label: 'Crear nuevas promociones', defaultValue: values.createNewPromotion })),
                React.createElement(SwitchPermissionFieldComponent, __assign({}, props, { name: 'editPromotion', label: 'Editar productos y caracter\u00EDsticas', defaultValue: values.editPromotion })),
                React.createElement(SwitchPermissionFieldComponent, __assign({}, props, { name: 'getPromotion', label: 'Consulta de datos', defaultValue: values.getPromotion })),
                React.createElement(SwitchPermissionFieldComponent, __assign({}, props, { name: 'deletePromotion', label: 'Eliminar promociones', defaultValue: values.deletePromotion }))))));
};
export default PromotionPermissionEditRoleComponent;
