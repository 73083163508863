import React, { useEffect, useState } from 'react';

import { useLazyQuery, useQuery, useReactiveVar, useMutation } from '@apollo/client';

import Collapse from '@mui/material/Collapse';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Switch from '@mui/material/Switch';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';

import Autocomplete from '../../../../presentationals/FormComponents/Autocomplete';
import SelectField from '../../../../presentationals/FormComponents/SelectField';
import TextField from '../../../../presentationals/FormComponents/TextField';
import DateField from '../../../../presentationals/FormComponents/DateField';

import { cart, invoiceFormState, billingInfo } from '../../../../apollo/cache';

import {
  paymentMethodOptions,
  useCfdiOptions,
  yesNoOptions,
  relateInvoiceOptions,
} from '../../../../helpers/invoice.options';

import GQL_PAYMENT_METHOD from '../../../paymentMethod/_gql';
import GQL_CUSTOMER from '../../../customer/_gql';
import useSnackbarDialog from '../../../../store/snackbar/snackbarDialog';
import useGetSession from '../../../../hooks/useGetSession';

const defaultValues = {
  applyExtraTaxes: false,
  paymentMethod: '',
  paymentMethodId: '',
  useCfdi: '',
  operationDate: null,
};

const defaultBillingValues = {
  id: null,
  identifier: '',
  businessName: '',
  addressLine1: '',
  addressLine2: '',
  zipCode: '',
  city: '',
  municipality: '',
  state: '',
  country: 'México',
  email: '',
  phone: '',
  otherEmail: '',
  altEmail: '',
  relateType: '',
  relateUUID: '',
};

const defaultAutocomplete = {
  billingInfo: null,
};

const InvoiceFormComponent = props => {
  const { setSnackbar } = useSnackbarDialog();
  const session = useGetSession();
  const customerLabel = session?.Company?.activity === 3 ? 'paciente' : 'cliente';
  const cartVar = useReactiveVar(cart);
  const invoiceFormStateVar = useReactiveVar(invoiceFormState);

  const [values, setValues] = useState({ ...defaultValues, ...defaultBillingValues });
  const [errors, setErrors] = useState({});
  const [extraTaxes, setExtraTaxes] = useState(false);
  const [paymentForms, setPaymentForms] = useState([]);
  const [isRelateInvoiceAvailable, setIsRelateInvoiceAvailable] = useState(false);
  const [billingDataOptions, setBillingDataOptions] = useState([]);
  const [billingSelected, setBillingSelected] = useState(defaultAutocomplete);

  const [updateCustomerBillingInfo] = useMutation(GQL_CUSTOMER.UPDATE_CUSTOMER_BILLING_INFO);

  useQuery(GQL_PAYMENT_METHOD.GET, {
    onCompleted: data => {
      const tmp = data.paymentMethods.rows.map(el => ({
        label: el.name,
        value: el.id,
      }));
      setPaymentForms([{ label: 'Efectivo', value: 0 }, ...tmp]);
    },
  });

  const [getCustomerData, { data: customerData }] = useLazyQuery(
    GQL_CUSTOMER.GET_BILLING_INFORMATION_BY_ID,
  );
  useQuery(GQL_CUSTOMER.GET_BILLING_INFORMATIONS, {
    onCompleted: data => {
      const tmp = data.customerBillingInformations
        .map(el => {
          if (!el.identifier) return false;
          const billingData = el;
          return {
            label: `${el.identifier} - ${el.businessName}`,
            value: el.identifier,
            data: billingData,
          };
        })
        .filter(elCh => elCh);
      setBillingDataOptions(tmp);
    },
  });

  useEffect(() => {
    if (cartVar && cartVar.newCustomer)
      setValues(values => ({
        ...values,
        identifier: cartVar.newCustomer.identifier,
        businessName: cartVar.newCustomer.businessName,
        email: cartVar.newCustomer.email,
        altEmail: cartVar.newCustomer.altEmail,
        otherEmail: cartVar.newCustomer.otherEmail,
        addressLine1: cartVar.newCustomer.addressLine1,
        addressLine2: cartVar.newCustomer.addressLine2,
        zipCode: cartVar.newCustomer.zipCode,
      }));
  }, [cartVar]);

  useEffect(() => {
    if (
      customerData &&
      customerData.customer &&
      customerData.customer.CustomerBillingInformation &&
      customerData.customer.CustomerBillingInformation.identifier
    ) {
      const billingInformation = customerData.customer.CustomerBillingInformation;
      const paymentMethod = billingInformation.paymentMethod
        ? billingInformation.paymentMethod
        : '';
      const paymentMethodId = billingInformation.paymentMethodId
        ? billingInformation.paymentMethodId
        : '';
      const useCfdi = billingInformation.useCfdi ? billingInformation.useCfdi : '';
      setValues(values => ({
        ...values,
        addressLine1: billingInformation.addressLine1 || '',
        addressLine2: billingInformation.addressLine2 || '',
        altEmail: billingInformation.altEmail || '',
        applyExtraTaxes: billingInformation.applyExtraTaxes || false,
        businessName: billingInformation.businessName || '',
        city: billingInformation.city || '',
        country: billingInformation.country || '',
        email: billingInformation.email || '',
        id: billingInformation.id || null,
        identifier: billingInformation.identifier || '',
        municipality: billingInformation.municipality || '',
        otherEmail: billingInformation.otherEmail || '',
        phone: billingInformation.phone || '',
        state: billingInformation.state || '',
        zipCode: billingInformation.zipCode || '',
        paymentMethod,
        paymentMethodId,
        useCfdi,
      }));
      setBillingSelected({
        billingInfo: {
          value: billingInformation.identifier,
          label: `${billingInformation.identifier} - ${billingInformation.businessName}`.trim(),
          data: billingInformation,
        },
      });
    }
  }, [customerData]);

  useEffect(() => {
    if (billingSelected?.billingInfo) {
      const billingInformation = billingSelected.billingInfo.data;
      const paymentMethod = billingInformation.paymentMethod
        ? billingInformation.paymentMethod
        : '';
      const paymentMethodId = billingInformation.paymentMethodId
        ? billingInformation.paymentMethodId
        : '';
      const useCfdi = billingInformation.useCfdi ? billingInformation.useCfdi : '';
      setValues(values => ({
        ...values,
        addressLine1: billingInformation.addressLine1 || '',
        addressLine2: billingInformation.addressLine2 || '',
        altEmail: billingInformation.altEmail || '',
        applyExtraTaxes: billingInformation.applyExtraTaxes || false,
        businessName: billingInformation.businessName || '',
        city: billingInformation.city || '',
        country: billingInformation.country || '',
        email: billingInformation.email || '',
        id: billingInformation.id || null,
        identifier: billingInformation.identifier || '',
        municipality: billingInformation.municipality || '',
        otherEmail: billingInformation.otherEmail || '',
        phone: billingInformation.phone || '',
        state: billingInformation.state || '',
        zipCode: billingInformation.zipCode || '',
        paymentMethod,
        paymentMethodId,
        useCfdi,
      }));
    }
  }, [billingSelected.billingInfo]);

  const validateForm = async () => {
    try {
      setErrors({});
      let newError = null;
      cartVar.SaleProductData.forEach(el => {
        if (el.productKey === '') {
          newError = new Error(`${el.frontName} no cuenta con clave (SAT) configurada.`);
          newError.name = 'general';
          throw newError;
        } else if (el.unitKey === '') {
          newError = new Error(`${el.frontName} no cuenta con unidad (SAT) configurada.`);
          newError.name = 'general';
          throw newError;
        }
      });
      if (values.paymentMethod === '') {
        newError = new Error('Selecciona un método de pago.');
        newError.name = 'paymentMethod';
        throw newError;
      }
      if (values.paymentMethodId === '') {
        newError = new Error('Selecciona una forma de pago.');
        newError.name = 'paymentMethodId';
        throw newError;
      }
      if (values.useCfdi === '') {
        newError = new Error('Selecciona el uso del CFDi.');
        newError.name = 'useCfdi';
        throw newError;
      }
      if (values.identifier === '') {
        newError = new Error('Proporciona el RFC del cliente.');
        newError.name = 'identifier';
        throw newError;
      }
      if (values.businessName === '') {
        newError = new Error('Proporciona la razón social del cliente.');
        newError.name = 'businessName';
        throw newError;
      }
      if (values.email === '') {
        newError = new Error('Proporciona un correo electrónico.');
        newError.name = 'email';
        throw newError;
      }
      if (isRelateInvoiceAvailable && values.relateUUID === '') {
        newError = new Error('Proporciona el UUID del CFDi a relacionar.');
        newError.name = 'relateUUID';
        throw newError;
      }
      if (isRelateInvoiceAvailable && values.relateType === '') {
        newError = new Error('Proporciona el tipo de relación.');
        newError.name = 'relateUUID';
        throw newError;
      }

      invoiceFormStateVar === 1 ? invoiceFormState(2) : invoiceFormState(3);
      billingInfo(values);
      const paymentMethod = values.paymentMethod ? `${values.paymentMethod}` : null;
      const paymentMethodId = values.paymentMethodId ? `${values.paymentMethodId}` : null;
      const useCfdi = values.useCfdi ? `${values.useCfdi}` : null;
      const id = props.customerId
        ? props.customerId
        : cartVar.customer
          ? cartVar.customer.value
          : null;
      if (id)
        await updateCustomerBillingInfo({
          variables: { ...values, id, paymentMethod, paymentMethodId, useCfdi },
        });
    } catch (e) {
      if (e.name === 'general')
        setSnackbar({ isOpen: true, time: 3000, label: e.message, severity: 'error' });
      console.log(e);
      invoiceFormState(0);
      console.log(e.message);
      setErrors({ [e.name]: e.message });
    }
  };

  useEffect(() => {
    if ([1, 1.5].includes(invoiceFormStateVar)) validateForm();
  }, [invoiceFormStateVar]);
  useEffect(() => {
    if (cartVar.customer || props.customerId)
      if (cartVar.customer) getCustomerData({ variables: { id: cartVar.customer.value } });
      else getCustomerData({ variables: { id: props.customerId } });
  }, [cartVar.customer, props.customerId]);

  useEffect(() => {
    const tmpExtraTaxes =
      localStorage.getItem('extraTaxes') && JSON.parse(localStorage.getItem('extraTaxes'));
    setExtraTaxes(tmpExtraTaxes);
  }, []);

  const handleChange = (name, value) => {
    setValues(values => ({ ...values, [name]: value }));
  };

  const handleSwitchChange = e => {
    setIsRelateInvoiceAvailable(e.target.checked);
  };

  const handleDateChange = (name, value) => {
    setValues(values => ({ ...values, [name]: value }));
  };

  const handleChangeAutocomplete = (name, value) => {
    setBillingSelected(values => ({ ...values, [name]: value }));
  };

  return (
    <Grid container spacing={0}>
      <Grid item md={12} xs={12}>
        <Collapse in={props.createInvoice}>
          <Grid item md={12} xs={12}>
            <Autocomplete
              name='billingInfo'
              label='Buscar Datos'
              value={billingSelected.billingInfo}
              options={billingDataOptions}
              onChange={handleChangeAutocomplete}
            />
          </Grid>
          <Typography className='mt-5 mb-0 font-semibold'>Datos generales</Typography>
          {extraTaxes && (
            <Grid item md={8} xs={12}>
              <SelectField
                name='applyExtraTaxes'
                label='¿Deseás sumar impuestos al valor total de la venta?'
                value={values.applyExtraTaxes}
                error={errors.applyExtraTaxes}
                options={yesNoOptions}
                onChange={handleChange}
              />
            </Grid>
          )}
          <Grid item md={8} xs={12}>
            <DateField
              noFormat
              size='small'
              variant='outlined'
              name='operationDate'
              label='Fecha de Emisión'
              onChange={handleDateChange}
              value={values.operationDate}
            />
          </Grid>
          <Grid item md={8} xs={12}>
            <SelectField
              name='paymentMethod'
              label='Método de Pago'
              value={values.paymentMethod}
              error={errors.paymentMethod}
              options={paymentMethodOptions}
              onChange={handleChange}
            />
          </Grid>
          <Grid item md={8} xs={12}>
            <SelectField
              name='paymentMethodId'
              label='Forma de Pago'
              value={values.paymentMethodId}
              error={errors.paymentMethodId}
              options={paymentForms}
              onChange={handleChange}
            />
          </Grid>
          <Grid item md={8} xs={12}>
            <SelectField
              name='useCfdi'
              label='Uso de CFDI'
              value={values.useCfdi}
              error={errors.useCfdi}
              options={useCfdiOptions}
              onChange={handleChange}
            />
          </Grid>
          <Typography className='mt-5 mb-0 font-semibold'>Datos del {customerLabel}</Typography>
          <Grid item md={8} xs={12}>
            <TextField
              name='identifier'
              label='RFC'
              value={values.identifier}
              error={errors.identifier}
              onChange={handleChange}
            />
          </Grid>
          <Grid item md={8} xs={12}>
            <TextField
              name='businessName'
              label='Razón Social'
              value={values.businessName}
              error={errors.businessName}
              onChange={handleChange}
            />
          </Grid>
          <Grid item md={8} xs={12}>
            <TextField
              type='email'
              name='email'
              label='Correo Electrónico'
              value={values.email}
              error={errors.email}
              onChange={handleChange}
            />
          </Grid>
          <Grid item md={8} xs={12}>
            <TextField
              type='email'
              name='altEmail'
              label='Correo Electrónico 2'
              value={values.altEmail}
              error={errors.altEmail}
              onChange={handleChange}
            />
          </Grid>
          <Grid item md={8} xs={12}>
            <TextField
              type='email'
              name='otherEmail'
              label='Correo Electrónico 3'
              value={values.otherEmail}
              error={errors.otherEmail}
              onChange={handleChange}
            />
          </Grid>
          <Grid item md={8} xs={12}>
            <TextField
              type='phone'
              name='phone'
              label='Teléfono'
              value={values.phone}
              error={errors.phone}
              onChange={handleChange}
            />
          </Grid>
          <Grid item md={8} xs={12}>
            <TextField
              name='addressLine1'
              label='Calle y Número'
              value={values.addressLine1}
              error={errors.addressLine1}
              onChange={handleChange}
            />
          </Grid>
          <Grid item md={8} xs={12}>
            <TextField
              name='addressLine2'
              label='Colonia'
              value={values.addressLine2}
              error={errors.addressLine2}
              onChange={handleChange}
            />
          </Grid>
          <Grid item md={8} xs={12}>
            <TextField
              name='city'
              label='Ciudad'
              value={values.city}
              error={errors.city}
              onChange={handleChange}
            />
          </Grid>
          <Grid item md={8} xs={12}>
            <TextField
              name='municipality'
              label='Municipio'
              value={values.municipality}
              error={errors.municipality}
              onChange={handleChange}
            />
          </Grid>
          <Grid item md={8} xs={12}>
            <TextField
              type='number'
              name='zipCode'
              label='Código Postal'
              value={values.zipCode}
              error={errors.zipCode}
              onChange={handleChange}
            />
          </Grid>
          <Grid item md={8} xs={12}>
            <TextField
              name='state'
              label='Estado'
              value={values.state}
              error={errors.state}
              onChange={handleChange}
            />
          </Grid>
          <Grid item md={8} xs={12}>
            <TextField
              name='country'
              label='Pais'
              value={values.country}
              error={errors.country}
              onChange={handleChange}
            />
          </Grid>
          <FormGroup>
            <FormControlLabel
              control={<Switch onChange={handleSwitchChange} />}
              label='Relacionar CFDi'
            />
          </FormGroup>
          <Collapse in={isRelateInvoiceAvailable}>
            <Grid item md={8} xs={12}>
              <TextField
                name='relateUUID'
                label='UUID de CFDi a relacionar'
                value={values.relateUUID}
                error={errors.relateUUID}
                onChange={handleChange}
              />
            </Grid>
            <Grid item md={8} xs={12}>
              <SelectField
                name='relateType'
                label='Tipo de relación'
                value={values.relateType}
                error={errors.relateType}
                options={relateInvoiceOptions}
                onChange={handleChange}
              />
            </Grid>
          </Collapse>
        </Collapse>
      </Grid>
    </Grid>
  );
};

export default InvoiceFormComponent;
