import React from 'react';

import DeleteIcon from '@mui/icons-material/DeleteOutlined';

import { deleteForm } from '../../../../apollo/cache';

import IconButton from '../../../IconButton';

const DeleteButtonComponent = props => {
  const handleOpenDelete = (e, params) => {
    deleteForm({ isOpen: true, params });
  };

  return (
    <IconButton
      label='Eliminar'
      action={handleOpenDelete}
      params={{ id: props.data.id, ...props.data }}
      className='p-1'
      icon={<DeleteIcon fontSize='small' />}
      size='large'
    />
  );
};

export default DeleteButtonComponent;
