var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React from 'react';
import SelectFieldComponent from '../../../../presentationals/FormComponents/SelectField';
import useGetBranches from '../../../../hooks/useGetBranches';
var BranchFieldFilterEmployeeComponent = function (props) {
    var _a = useGetBranches(undefined, 'select'), data = _a.data, loading = _a.loading;
    return (React.createElement("div", null,
        React.createElement(SelectFieldComponent, { name: 'branchId', isLoading: loading, label: 'Sucursal', options: __spreadArray([
                props.allBranchesOptionHidden === false && { value: '-1', label: 'Todas las sucursales' }
            ], data.rows, true).filter(function (el) { return el; }), onChange: props.onChange, value: data && data.rows.length > 0
                ? props.value
                : props.allBranchesOptionHidden === false
                    ? '-1'
                    : '' })));
};
export default BranchFieldFilterEmployeeComponent;
