import React, { useState } from 'react';
import { TableCell, TableRow } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import TableCellRentComponent from './TableCell';
var ItemBodyRentComponent = function (props) {
    var _a = useState(false), selected = _a[0], setSelected = _a[1];
    var navigate = useNavigate();
    var handleClick = function () {
        navigate("/panel/rent/".concat(props.Rent.id));
    };
    var handleMouseOver = function () {
        setSelected(true);
    };
    var handleMouseOut = function () {
        setSelected(false);
    };
    return (React.createElement(TableRow, { onMouseOver: handleMouseOver, onMouseOut: handleMouseOut, className: 'hover:bg-slate-200', onClick: handleClick },
        props.index === 0 && (React.createElement(TableCell, { className: 'bg-slate-200 border-0 border-solid border-t-2 border-t-slate-400', rowSpan: props.numberOfRents }, props.day)),
        [0, 5, 6].includes(props.deliveryStatus) && (React.createElement(TableCellRentComponent, { index: props.index, permanentSelected: !selected }, props.Rent.startHour)),
        [4, 5, 6].includes(props.deliveryStatus) && (React.createElement(TableCellRentComponent, { index: props.index, permanentSelected: !selected }, props.Rent.endHour)),
        React.createElement(TableCellRentComponent, { index: props.index }, props.Rent.folio || ''),
        React.createElement(TableCellRentComponent, { index: props.index }, props.Rent.customer),
        React.createElement(TableCellRentComponent, { index: props.index }, props.Rent.total || '')));
};
export default ItemBodyRentComponent;
