import React from 'react';
import { useNavigate } from 'react-router-dom';
import Container from '../../presentationals/Module.v2/Container';
import GQL from './_gql';
import Action from './_action';
import Filter from './_filter';
var title = 'Requisición de mercancía';
var operation = ['requisition', 'requisitions'];
var Rows = [
    { label: 'Fecha', key: 'date', sort: 'text', format: 'dateTime' },
    // { label: 'Captura', key: 'createdAt', sort: 'text', format: 'dateTime' },
    { label: 'Folio', key: 'serial', prefix: 'RE', sort: 'text' },
    { label: 'Estatus', key: 'status', sort: 'text', format: 'requisitionStatus' },
    { label: 'Responsable', key: 'Employee firstName && Employee lastName' },
    { label: 'Sucursal Solicitante', key: 'Branch name' },
    { label: 'Anotación', key: 'description', wrap: true },
    { label: 'Comentario', key: 'commentary' },
];
var RequisitionComponent = function () {
    var navigate = useNavigate();
    var handleOpenCreateForm = function () {
        navigate('create');
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(Container, { title: title, operation: operation, Rows: Rows, get: GQL.GET, handleCustomCreate: handleOpenCreateForm, delete: GQL.CANCEL_REQUISITION, noEdit: true, filter: true }),
        React.createElement(Action, null),
        React.createElement(Filter, null)));
};
export default RequisitionComponent;
