import React, { useEffect, useState } from 'react';
import { Alert, AlertTitle } from '@mui/material';
import getSession from '../../helpers/getSession';
import getFirstName from '../../helpers/getFirstName';
import FooterModuleV3Component from '../../presentationals/Module.v3/Footer';
import WhatsNewMainComponent from './WhatsNew';
import CompanyBoxMainComponent from './CompanyBox';
import NeedHelpBoxMainComponent from './NeedHelpBox';
import SuscriptionBoxMainComponent from './SuscriptionBox';
var Main = function () {
    var _a = useState(''), user = _a[0], setUser = _a[1];
    useEffect(function () {
        var session = getSession();
        if (!session)
            return;
        setUser(getFirstName(session.name));
    }, []);
    return (React.createElement("div", { className: 'flex flex-1 flex-col p-4 w-full basis-0 overflow-auto' },
        React.createElement("div", { className: 'flex flex-1 flex-col sm:flex-row sm:grid sm:grid-cols-4 sm:gap-4' },
            React.createElement("div", { className: 'flex flex-1 flex-col sm:col-span-3' },
                React.createElement("div", { className: 'text-2xl tracking-tighter' }, "Bienvenido, ".concat(user, "!")),
                React.createElement("div", { className: 'flex flex-col mt-2 sm:grid sm:grid-cols-4 gap-4 sm:flex-row' },
                    React.createElement("div", { className: 'col-span-2' },
                        React.createElement(CompanyBoxMainComponent, null)),
                    React.createElement(SuscriptionBoxMainComponent, null),
                    React.createElement(NeedHelpBoxMainComponent, null)),
                React.createElement("div", { className: 'flex flex-1 flex-col mt-4' },
                    React.createElement(Alert, { className: 'flex flex-1', severity: 'warning' },
                        React.createElement(AlertTitle, null, "\u00A1Aviso importante!"),
                        "La informaci\u00F3n que est\u00E1s visualizando en esta patanlla no es real ya que a\u00FAn se encuentra en construcci\u00F3n. ",
                        React.createElement("br", null),
                        React.createElement("b", null, "AUNQUE MUCHOS M\u00D3DULOS SE ENCUENTRAN EN CONSTRUCCI\u00D3N, LA PLATAFORMA SE ENCUENTRA OPERABLE.")))),
            React.createElement(WhatsNewMainComponent, null)),
        React.createElement(FooterModuleV3Component, null)));
};
export default Main;
