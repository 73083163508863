import React from 'react';
import { useReactiveVar } from '@apollo/client';
import DialogComponent from '../../../../../../../presentationals/Dialog';
import { isEditFormOpen } from '../../../../../../../apollo/cache';
import BodyEditOperationDateComponent from './Body';
var EditOperationDateDialogComponent = function () {
    var isOpen = useReactiveVar(isEditFormOpen);
    var handleClose = function () {
        isEditFormOpen(false);
    };
    return (React.createElement(DialogComponent, { fullScreen: true, open: !!isOpen, title: 'Modificar fecha de operaci\u00F3n', onClose: handleClose },
        React.createElement(BodyEditOperationDateComponent, null)));
};
export default EditOperationDateDialogComponent;
