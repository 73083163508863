import React from 'react';

import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Grid from '@mui/material/Grid';

const SelectField = ({ noFormat, error, icon, options, ...props }) => {
  return <Grid container spacing={0} alignItems='flex-end'>
    <Grid item xs={noFormat ? false : 1}>
      {icon || ''}
    </Grid>
    <Grid item xs={noFormat ? 12 : 10}>
      <TextField
        select
        name={props.name}
        label={props.label}
        variant={props.variant || 'standard'}
        fullWidth={props.fullWidth || true}
        autoFocus={props.autoFocus || false}
        onChange={props.onChange}
        value={props.value}
        error={typeof error === 'string'}
        helperText={error && error}
        autoComplete='off'
        {...props}
      >
        {options.map(el => (
          <MenuItem key={el.value} value={el.value}>
            {el.label}
          </MenuItem>
        ))}
      </TextField>
    </Grid>
  </Grid>;
};

export default SelectField;
