var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import getDefaultCreateBranchPurchaseOrderValues from './helpers/getDefaultValues';
import SelectBranchFieldComponent from '../../../../../../../presentationals/FormComponents/SelectBranchField';
var BodyBranchCreatePurchaseOrderDialogComponent = forwardRef(function (props, ref) {
    var defaultValues = getDefaultCreateBranchPurchaseOrderValues();
    var _a = useState(defaultValues), values = _a[0], setValues = _a[1];
    useEffect(function () {
        if (props.defaultValues)
            setValues(props.defaultValues);
    }, [props.defaultValues]);
    var handleChange = function (name, value) {
        setValues(function (values) {
            var _a;
            return (__assign(__assign({}, values), (_a = {}, _a[name] = value, _a)));
        });
    };
    var handleGetValues = function () { return values; };
    useImperativeHandle(ref, function () { return ({
        getValues: handleGetValues,
    }); });
    return (React.createElement("div", { className: 'grid grid-cols-1 sm:grid-cols-4 flex-1 flex-col' },
        React.createElement("div", { className: 'flex flex-1 flex-col px-4 py-3' },
            React.createElement(SelectBranchFieldComponent, { label: 'Sucursal a la que se asignar\u00E1 la compra y se a\u00F1adir\u00E1 el inventario', disallowAllOptions: true, defaultValue: values.branchId, onChange: handleChange }))));
});
export default BodyBranchCreatePurchaseOrderDialogComponent;
