import React, { useState } from 'react';
import { Skeleton, useMediaQuery, useTheme } from '@mui/material';
import Button from './Button';
var ItemMenuHeaderModuleV3Component = function (props) {
    var theme = useTheme();
    var isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    var _a = useState(true), isLoading = _a[0], setIsLoading = _a[1];
    var handleLoad = function () {
        setIsLoading(false);
    };
    return (React.createElement(Button, { onClick: props.onClick },
        React.createElement("div", { className: 'px-7' },
            isLoading && (React.createElement(Skeleton, { variant: 'circular', width: isSmallScreen ? 75 : 45, height: isSmallScreen ? 75 : 45 })),
            React.createElement("img", { src: props.imageSrc, alt: props.label, onLoad: handleLoad, className: "w-full ".concat(isLoading && 'hidden'), referrerPolicy: 'no-referrer' })),
        React.createElement("div", { className: 'text-sm' }, props.label)));
};
export default ItemMenuHeaderModuleV3Component;
