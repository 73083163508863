import { Link } from '@mui/material';
import React from 'react';
import { useParams } from 'react-router-dom';
import CONFIG from '../../../../../config/config.json';
var CreateXMLProductsButtonComponent = function () {
    var params = useParams();
    var handleClick = function (e) {
        e.preventDefault();
        window.open("".concat(CONFIG['restServer'].uri, "/files/serviceOrders/").concat(params.saleId, "/products/xlsx"));
    };
    return (React.createElement("div", { className: 'mt-2' },
        React.createElement(Link, { href: '#', onClick: handleClick }, "Descargar XML de productos")));
};
export default CreateXMLProductsButtonComponent;
