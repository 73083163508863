import { Popover } from '@mui/material';
import React, { forwardRef, useImperativeHandle, useState } from 'react';
import BodyCustomerAddessPopoverComponent from './Body';
var CustomerAddessPopoverComponent = forwardRef(function (props, ref) {
    var _a = useState(null), popover = _a[0], setPopover = _a[1];
    var _b = useState(null), customerId = _b[0], setCustomerId = _b[1];
    var handleClose = function () {
        setPopover(null);
    };
    var handleOpen = function (anchorEl, customerId) {
        setPopover(anchorEl);
        setCustomerId(customerId);
    };
    var handleSelect = function (data) {
        props.onSelect(data);
        handleClose();
    };
    useImperativeHandle(ref, function () { return ({
        open: handleOpen,
    }); });
    return (React.createElement(Popover, { id: "CustomerAddressPopover".concat(props.name), open: !!popover, anchorEl: popover, onClose: handleClose, anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'right',
        }, transformOrigin: {
            vertical: 'top',
            horizontal: 'right',
        }, slotProps: {
            paper: {
                className: 'w-[412px] min-h-52',
            },
        } },
        React.createElement(BodyCustomerAddessPopoverComponent, { onSelect: handleSelect, customerId: customerId })));
});
export default CustomerAddessPopoverComponent;
