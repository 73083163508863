import React, { useEffect, useState } from 'react';
import UpcomingAppointmentsCalendarQueueManagerComponent from '../UpcomingAppointments';
import DigitalContentCalendarQueueManagerComponent from '../DigitalContent';
//bg-[#2a818b]
//text-white
var BodyCalendarQueueManagerComponent = function (props) {
    var _a = useState('loading'), showContent = _a[0], setShowContent = _a[1];
    useEffect(function () {
        var interval = null;
        if (!props.digitalContentAvailability)
            if (props.digitalContentTimer)
                interval = setInterval(function () {
                    setShowContent(true);
                }, props.digitalContentTimer * 1000);
            else
                setShowContent(false);
        else if (props.DigitalContent.length > 0)
            setShowContent(true);
        return function () {
            if (interval)
                clearInterval(interval);
        };
    }, [props.digitalContentAvailability, props.digitalContentTimer]);
    if (showContent === 'loading')
        return React.createElement("div", null);
    return (React.createElement("div", { className: 'flex flex-row flex-1' },
        React.createElement("div", { className: "flex flex-col overflow-hidden ".concat(showContent ? 'w-5/12' : 'w-full flex-1', " "), style: {
                backgroundColor: props.Color.leftBodyBackgroundColor || '#fff',
            } },
            React.createElement(UpcomingAppointmentsCalendarQueueManagerComponent, { textColor: props.Color.leftBodyTextColor })),
        showContent && (React.createElement("div", { className: 'flex justify-center items-center w-7/12' },
            React.createElement(DigitalContentCalendarQueueManagerComponent, { DigitalContent: props.DigitalContent })))));
};
export default BodyCalendarQueueManagerComponent;
