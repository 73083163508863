import { gql } from '@apollo/client';
import FRAGMENT_PRODUCT_INVENTORY from '../../apollo/gql/productInventory/fragmentProductInventory';

const GET = gql`
  query Transfers(
    $limit: Int
    $offset: Int
    $order: [String]
    $start: Date
    $end: Date
    $branchId: ID
    $branchTransferId: [ID]
    $status: [Int]
  ) {
    transfers(
      limit: $limit
      offset: $offset
      order: $order
      start: $start
      end: $end
      branchId: $branchId
      branchTransferId: $branchTransferId
      status: $status
    ) {
      count
      rows {
        id
        folio
        serial
        total
        status
        companyId
        Company {
          id
          name
        }
        Branch {
          id
          name
        }
        BranchTransfer {
          id
          name
        }
        CompanyTransfer {
          id
          name
        }
        createdAt
        date
        Employee {
          id
          firstName
          lastName
        }
      }
      totals {
        id
        qty
        name
        amount
      }
    }
  }
`;

const CREATE_TRANSFER_RECEPTION = gql`
  mutation CreateTransferReception($transferId: ID!, $Products: [ProductReception]) {
    createTransferReception(input: { transferId: $transferId, Products: $Products }) {
      id
    }
  }
`;

const CREATE = gql`
  mutation CreateTransfer(
    $branchId: ID!
    $branchTransferId: ID!
    $description: String
    $date: Date
    $Products: [TransferProductData]
  ) {
    createTransferV3(
      input: {
        branchId: $branchId
        branchTransferId: $branchTransferId
        description: $description
        date: $date
        Products: $Products
      }
    ) {
      id
      folio
      serial
      total
      status
      Company {
        id
        name
      }
      Branch {
        id
        name
      }
      BranchTransfer {
        id
        name
      }
      CompanyTransfer {
        id
        name
      }
      createdAt
      date
      Employee {
        id
        firstName
        lastName
      }
    }
  }
`;

const GET_BY_ID = gql`
  query Transfer($id: ID!) {
    transfer(id: $id) {
      id
      folio
      serial
      ivaTotal
      iepsTotal
      subtotal
      taxes
      total
      status
      Company {
        id
        name
      }
      Branch {
        id
        name
        addressLine1
        addressLine2
        zipCode
        municipality
        city
        state
        phone
        altPhone
      }
      BranchTransfer {
        id
        name
      }
      CompanyTransfer {
        id
        name
      }
      createdAt
      description
      date
      Employee {
        id
        firstName
        lastName
      }
      AuthEmployee {
        id
        firstName
        lastName
      }
      TransferProducts {
        id
        status
        productId
        quantity
        cost
        iva
        ieps
        ivaTotal
        iepsTotal
        subtotal
        taxes
        total
        total
        currentQuantity
        productPriceId
        Product {
          id
          sku
          barcode
          cost
          name
          iva
          ieps
        }
        ProductPresentation {
          id
          name
        }
        PresentationReception {
          quantity
          Product {
            name
          }
          ProductPresentation {
            name
          }
          productPresentationId
        }
      }
    }
  }
`;

const EDIT = gql`
  mutation EditTransfer(
    $id: ID!
    $branchId: ID!
    $branchTransferId: ID!
    $description: String
    $date: Date
    $Products: [TransferProductData]
  ) {
    editTransfer(
      id: $id
      input: {
        branchId: $branchId
        branchTransferId: $branchTransferId
        description: $description
        date: $date
        Products: $Products
      }
    ) {
      id
      folio
      serial
      total
      status
      Company {
        id
        name
      }
      Branch {
        id
        name
      }
      BranchTransfer {
        id
        name
      }
      CompanyTransfer {
        id
        name
      }
      createdAt
      date
      Employee {
        id
        firstName
        lastName
      }
    }
  }
`;

const AUTH_TRANSFER = gql`
  ${FRAGMENT_PRODUCT_INVENTORY}
  mutation AuthTransfer($id: ID!) {
    authTransfer(id: $id) {
      id
      folio
      serial
      total
      status
      Company {
        id
        name
      }
      Branch {
        id
        name
      }
      BranchTransfer {
        id
        name
      }
      CompanyTransfer {
        id
        name
      }
      createdAt
      date
      Employee {
        id
        firstName
        lastName
      }
      ProductInventoriesBranch {
        ...FragmentProductInventory
      }
      ProductInventoriesBranchTransfer {
        ...FragmentProductInventory
      }
    }
  }
`;

const CONFIRM_TRANSFER = gql`
  mutation ConfirmTransfer($id: ID!) {
    confirmTransfer(id: $id) {
      id
      folio
      serial
      total
      status
      Company {
        id
        name
      }
      Branch {
        id
        name
      }
      BranchTransfer {
        id
        name
      }
      CompanyTransfer {
        id
        name
      }
      createdAt
      date
      Employee {
        id
        firstName
        lastName
      }
    }
  }
`;

export default {
  GET,
  CREATE,
  GET_BY_ID,
  EDIT,
  AUTH_TRANSFER,
  CREATE_TRANSFER_RECEPTION,
  CONFIRM_TRANSFER,
};
