import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import TotalsCreatePurchaseOrderCartLabelComponent from '../../../../purchaseOrder/Create/Cart/TotalsLabel';
import SelectedSalesGlobalInvoiceFieldComponent from '../SelectedSales';
import getSaleGlobalInvoiceTotals from '../helpers/getSaleGlobalInvoiceTotals';
import getInputErrorFormat from '../../../../sale.v2/Action/helpers/getInputErrorFormat';
import CONSTANTS_ERROR from '../../../../../constants/error';
var TotalsTableSalesGlobalInvoiveComponent = forwardRef(function (props, ref) {
    var _a, _b;
    var selectedSalesGlobalInvoiceFieldRef = useRef(null);
    var defaultTotals = [0, 0, 0, 0];
    var _c = useState(defaultTotals), totals = _c[0], setTotals = _c[1];
    useEffect(function () {
        if (props.Sales) {
            var totals_1 = getSaleGlobalInvoiceTotals(props.Sales);
            setTotals(totals_1);
        }
        else {
            setTotals(defaultTotals);
        }
    }, [props.Sales]);
    var handleSalesChange = function (array) {
        var _a;
        try {
            if (!props.Sales)
                throw new Error(CONSTANTS_ERROR.UNDEFINED_VALUES);
            (_a = selectedSalesGlobalInvoiceFieldRef.current) === null || _a === void 0 ? void 0 : _a.updateQty(array.length);
            var totals_2 = getSaleGlobalInvoiceTotals(props.Sales, array);
            setTotals(totals_2);
        }
        catch (error) {
            getInputErrorFormat(error);
        }
    };
    useImperativeHandle(ref, function () { return ({
        onSalesChange: handleSalesChange,
    }); });
    return (React.createElement("div", { className: 'flex flex-col' },
        React.createElement(TotalsCreatePurchaseOrderCartLabelComponent, { alternative: "".concat(((_a = props.Sales) === null || _a === void 0 ? void 0 : _a.count) || 0, " registros"), label: 'Subtotal', value: totals[0] }),
        React.createElement(TotalsCreatePurchaseOrderCartLabelComponent, { alternative: React.createElement(SelectedSalesGlobalInvoiceFieldComponent, { ref: selectedSalesGlobalInvoiceFieldRef, defaultQty: ((_b = props.Sales) === null || _b === void 0 ? void 0 : _b.count) || 0 }), label: 'Descuento', value: totals[3] }),
        React.createElement(TotalsCreatePurchaseOrderCartLabelComponent, { label: 'Impuestos', value: totals[1] }),
        React.createElement(TotalsCreatePurchaseOrderCartLabelComponent, { boldValue: true, label: 'Total', value: totals[2] })));
});
export default TotalsTableSalesGlobalInvoiveComponent;
