import { Card, Divider } from '@mui/material';
import React, { useEffect, useState } from 'react';
import getSession from '../../../helpers/getSession';
import getCompanyActivityOptions from '../../../helpers/getCompanyActivityOptions';
var CompanyBoxMainComponent = function () {
    var _a, _b, _c;
    var session = getSession();
    var _d = useState(''), activity = _d[0], setActivity = _d[1];
    useEffect(function () {
        var companyActivityOptions = getCompanyActivityOptions();
        var companyActivity = companyActivityOptions.find(function (el) { var _a; return el.value === ((_a = session === null || session === void 0 ? void 0 : session.Company) === null || _a === void 0 ? void 0 : _a.activity); });
        if (!companyActivity)
            return;
        setActivity(companyActivity.label);
    }, []);
    return (React.createElement(Card, { className: 'flex flex-col bg-blue-600 text-slate-50 px-4 py-3 h-40' },
        React.createElement("div", { className: 'flex flex-row justify-between' },
            React.createElement("div", { className: 'text-lg tracking-tighter' }, ((_a = session === null || session === void 0 ? void 0 : session.Company) === null || _a === void 0 ? void 0 : _a.name) || '')),
        React.createElement(Divider, { className: 'my-1 bg-white' }),
        React.createElement("div", { className: 'flex flex-1 flex-col justify-center' },
            React.createElement("div", { className: 'grid grid-cols-2 gap-4' },
                React.createElement("div", null,
                    React.createElement("div", { className: 'text-xs tracking-tighter font-medium mt-2' }, "Actividad de la empresa"),
                    React.createElement("div", { className: 'text-xs tracking-tighter' }, activity),
                    React.createElement("div", { className: 'text-xs tracking-tighter font-medium mt-2' }, "Sucursal predeterminada"),
                    React.createElement("div", { className: 'text-xs tracking-tighter' }, ((_b = session === null || session === void 0 ? void 0 : session.Company) === null || _b === void 0 ? void 0 : _b.Branch.name) || '')),
                React.createElement("div", { className: 'flex flex-1 flex-col' },
                    React.createElement("div", { className: 'text-xs tracking-tighter font-medium mt-2' }, "Permisos de usuario"),
                    React.createElement("div", { className: 'text-xs tracking-tighter' }, ((_c = session === null || session === void 0 ? void 0 : session.Company) === null || _c === void 0 ? void 0 : _c.Role.name) || ''))))));
};
export default CompanyBoxMainComponent;
