var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useEffect } from 'react';
import { Card } from '@mui/material';
import useGetAccountReceivables from './helpers/useGetAccountReceivables';
import HeaderModuleV3Component from '../../presentationals/Module.v3/Header';
import BodyReceivableComponent from './Body';
import FooterModuleV3Component from '../../presentationals/Module.v3/Footer';
import getSearchByReceivableOptions from '../../helpers/getSearchByReceivableOptions';
import FilterReceivableDialog from './Filter';
import ExportReceivableXlsComponent from './ExportXls';
import { filter, moduleMoreOptionsItems } from '../../apollo/cache';
import getInputErrorFormat from '../sale.v2/Action/helpers/getInputErrorFormat';
import getSession from '../../helpers/getSession';
import { dateTimeFormat } from '../../helpers';
var ReceivablesComponent = function () {
    var _a = useGetAccountReceivables(), data = _a.data, loading = _a.loading;
    var searchReceivableByOptions = getSearchByReceivableOptions();
    useEffect(function () {
        moduleMoreOptionsItems([
            {
                label: 'Descargar Edo. Cta.',
                imageSrc: '',
                onClick: function () { return __awaiter(void 0, void 0, void 0, function () {
                    var session, currentFilter, branches, start, end, companyId;
                    var _a;
                    return __generator(this, function (_b) {
                        try {
                            session = getSession();
                            currentFilter = filter();
                            branches = currentFilter.branchId
                                ? "?branchId=[".concat(currentFilter.branchId.join(','), "]")
                                : '';
                            if (!session)
                                return [2 /*return*/];
                            start = branches.length > 0 && currentFilter.start
                                ? "&start=".concat(dateTimeFormat(currentFilter.start, 'yyyy-MM-dd'))
                                : currentFilter.start
                                    ? "?start=".concat(dateTimeFormat(currentFilter.start, 'yyyy-MM-dd'))
                                    : '';
                            end = start.length > 0 && currentFilter.end
                                ? "&end=".concat(dateTimeFormat(currentFilter.end, 'yyyy-MM-dd'))
                                : '';
                            companyId = (_a = session === null || session === void 0 ? void 0 : session.Company) === null || _a === void 0 ? void 0 : _a.id;
                            window.open("https://api.erp.capitalike.com/files/receivablesByCustomer/".concat(companyId, "/pdf").concat(branches).concat(start).concat(end), '_blank');
                        }
                        catch (error) {
                            getInputErrorFormat(error);
                        }
                        return [2 /*return*/];
                    });
                }); },
            },
        ]);
    }, []);
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: 'flex flex-1 flex-col p-3 bg-slate-50' },
            React.createElement(HeaderModuleV3Component, { searchFieldOptions: {
                    name: 'searchReceivableBy',
                    options: searchReceivableByOptions,
                    defaultValue: 'customer',
                }, isLoading: loading, title: 'Cuentas por cobrar', filter: true, hideAdd: true }),
            React.createElement(Card, { className: 'mt-2 flex flex-1 flex-col basis-0 overflow-auto' },
                React.createElement(BodyReceivableComponent, { loading: loading, data: data })),
            React.createElement(FooterModuleV3Component, null)),
        React.createElement(FilterReceivableDialog, null),
        React.createElement(ExportReceivableXlsComponent, null)));
};
export default ReceivablesComponent;
