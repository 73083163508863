import { gql } from '@apollo/client';

const GET_BY_ID = gql`
  query Company($id: ID!) {
    company(id: $id) {
      id
      name
      activity
      phone
      extraTaxes
      pettyCash
      logoFileId
      percentPoints
      percentEMoney
      printClient
      openPrice
      clearSellCartInputs
      costOnSellModule
      stockOnSellModule
      belowZeroInventory
      sessionDefaultSeller
      showProductImage
      posModule
      handleIva
      handleIeps
      breakdownTaxes
      orderAuthMethod
      ecName
      hostname
      receivablePublicCustomer
    }
  }
`;

const GET_ADDRESS = gql`
  query Company($id: ID!) {
    company(id: $id) {
      id
      addressLine1
      addressLine2
      zipCode
      city
      state
      country
    }
  }
`;

const GET_BILLING_INFORMATION = gql`
  query Company($id: ID!) {
    company(id: $id) {
      id
      CompanyBillingInformation {
        identifier
        businessName
        taxRegime
        addressLine1
        addressLine2
        zipCode
        city
        municipality
        state
        country
        email
        phone
      }
    }
  }
`;

const GET_LOGO = gql`
  query Company($id: ID!) {
    company(id: $id) {
      id
      logoFileId
    }
  }
`;

const UPDATE_COMPANY = gql`
  mutation UpdateCompany(
    $id: ID!
    $name: String
    $activity: Int
    $addressLine1: String
    $addressLine2: String
    $zipCode: String
    $reference: String
    $phone: String
    $altPhone: String
    $country: Int
    $city: String
    $state: String
    $extraTaxes: Boolean
    $pettyCash: Boolean
    $logoFileId: ID
    $percentPoints: Int
    $percentEMoney: Int
    $printClient: Int
    $posModule: Int
    $openPrice: Boolean
    $clearSellCartInputs: Boolean
    $costOnSellModule: Boolean
    $stockOnSellModule: Boolean
    $belowZeroInventory: Boolean
    $sessionDefaultSeller: Boolean
    $showProductImage: Boolean
    $handleIva: Boolean
    $handleIeps: Boolean
    $breakdownTaxes: Boolean
    $orderAuthMethod: Int
    $ecName: String
    $hostname: String
    $receivablePublicCustomer: Boolean
  ) {
    updateCompany(
      id: $id
      input: {
        name: $name
        activity: $activity
        addressLine1: $addressLine1
        addressLine2: $addressLine2
        zipCode: $zipCode
        reference: $reference
        phone: $phone
        altPhone: $altPhone
        country: $country
        city: $city
        state: $state
        extraTaxes: $extraTaxes
        pettyCash: $pettyCash
        logoFileId: $logoFileId
        percentPoints: $percentPoints
        percentEMoney: $percentEMoney
        printClient: $printClient
        posModule: $posModule
        openPrice: $openPrice
        clearSellCartInputs: $clearSellCartInputs
        costOnSellModule: $costOnSellModule
        stockOnSellModule: $stockOnSellModule
        belowZeroInventory: $belowZeroInventory
        sessionDefaultSeller: $sessionDefaultSeller
        showProductImage: $showProductImage
        handleIva: $handleIva
        handleIeps: $handleIeps
        breakdownTaxes: $breakdownTaxes
        orderAuthMethod: $orderAuthMethod
        ecName: $ecName
        hostname: $hostname
        receivablePublicCustomer: $receivablePublicCustomer
      }
    ) {
      id
      name
      pettyCash
      extraTaxes
      country
      activity
      addressLine1
      addressLine2
      zipCode
      reference
      city
      municipality
      state
      phone
      altPhone
      logoFileId
      percentPoints
      percentEMoney
      printClient
      posModule
      openPrice
      clearSellCartInputs
      costOnSellModule
      stockOnSellModule
      belowZeroInventory
      showProductImage
      handleIva
      handleIeps
      breakdownTaxes
      ecName
      hostname
      receivablePublicCustomer
    }
  }
`;

const UPDATE_COMPANY_BILLING_INFORMATION = gql`
  mutation UpdateCompanyBillingInformation(
    $id: ID!
    $identifier: String!
    $businessName: String!
    $taxRegime: String
    $addressLine1: String
    $addressLine2: String
    $zipCode: String
    $city: String
    $municipality: String
    $state: String
    $country: String
    $email: String!
    $phone: String
    $passwordCsd: String
    $primaryKey: Upload
    $certificateImage: Upload
  ) {
    updateCompanyBillingInformation(
      id: $id
      input: {
        identifier: $identifier
        businessName: $businessName
        taxRegime: $taxRegime
        addressLine1: $addressLine1
        addressLine2: $addressLine2
        zipCode: $zipCode
        city: $city
        municipality: $municipality
        state: $state
        country: $country
        email: $email
        phone: $phone
        passwordCsd: $passwordCsd
        primaryKey: $primaryKey
        certificateImage: $certificateImage
      }
    ) {
      id
    }
  }
`;

const GET_ECSETTINGS = gql`
  query ECSettings($companyId: ID, $ecName: String, $hostname: String) {
    ecSettings(companyId: $companyId, ecName: $ecName, hostname: $hostname) {
      mainBackgroundFileId
      mainFontColor
      menuBackgroundColor
      menuFontColor
      relevantProductTitle
      relevantProductSubtitle
      relevantProductFontColor
      relevantProductBackgroundColor
      footerFontColor
      footerBackgroundColor
    }
  }
`;

const UPDATE_ECSETTINGS = gql`
  mutation UpdateECSettings(
    $companyId: ID!
    $mainBackgroundFile: Upload
    $mainFontColor: String
    $menuBackgroundColor: String
    $menuFontColor: String
    $relevantProductTitle: String
    $relevantProductSubtitle: String
    $relevantProductFontColor: String
    $relevantProductBackgroundColor: String
    $footerFontColor: String
    $footerBackgroundColor: String
  ) {
    updateECSettings(
      companyId: $companyId
      input: {
        mainBackgroundFile: $mainBackgroundFile
        mainFontColor: $mainFontColor
        menuBackgroundColor: $menuBackgroundColor
        menuFontColor: $menuFontColor
        relevantProductTitle: $relevantProductTitle
        relevantProductSubtitle: $relevantProductSubtitle
        relevantProductFontColor: $relevantProductFontColor
        relevantProductBackgroundColor: $relevantProductBackgroundColor
        footerFontColor: $footerFontColor
        footerBackgroundColor: $footerBackgroundColor
      }
    ) {
      mainBackgroundFileId
      mainFontColor
      menuBackgroundColor
      menuFontColor
      relevantProductTitle
      relevantProductSubtitle
      relevantProductFontColor
      relevantProductBackgroundColor
      footerFontColor
      footerBackgroundColor
    }
  }
`;

export default {
  GET_BY_ID,
  GET_ADDRESS,
  GET_BILLING_INFORMATION,
  GET_LOGO,
  UPDATE_COMPANY,
  UPDATE_COMPANY_BILLING_INFORMATION,
  GET_ECSETTINGS,
  UPDATE_ECSETTINGS,
};
