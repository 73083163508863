import React from 'react';

import Container from '../../presentationals/Module.v2/Container';

import Table from './_table';
import Filter from './_filter';

const title = 'Ventas por cliente';
const operation = ['customerSales', 'customerSales'];

const SaleCustomerReportComponent = () => {
  return (
    <Container title={title} filter noTable>
      <Table title={title} operation={operation} />
      <Filter />
    </Container>
  );
};

export default SaleCustomerReportComponent;
