import { useReactiveVar } from '@apollo/client';
import { useEffect, useState } from 'react';
import { filter } from '../../../apollo/cache';
import useGetRoles from '../../../hooks/useGetRoles';
var useGetRoleData = function (props) {
    var variables = useReactiveVar(filter);
    var _a = useGetRoles(props.getByFilter && variables), response = _a.data, loading = _a.loading;
    var _b = useState([]), data = _b[0], setData = _b[1];
    useEffect(function () {
        if (response) {
            var Rows = response.rows;
            var data_1 = Rows.map(function (el) {
                return {
                    id: el.id,
                    name: el.name,
                    Permissions: el.Permissions,
                };
            });
            setData(data_1);
        }
    }, [response]);
    return { data: data, loading: loading };
};
export default useGetRoleData;
