var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { useReactiveVar } from '@apollo/client';
import SubtitleDialogComponent from '../../../../presentationals/Dialog/helpers/Subtitle';
import TextFieldComponent from '../../../../presentationals/FormComponents/TextField';
import { inputError } from '../../../../apollo/cache';
import SelectFieldComponent from '../../../../presentationals/FormComponents/SelectField';
import TaxRegimeSelectFieldHelpersBodyCreateFormCustomerActionSaleV2Component from '../../../sale.v2/Action/Customer/Form/Create/Body/helpers/TaxRegimeSelectField';
import { getDefaultBranchCreateForm } from '../../helpers/getDefaultBranchValues';
export var typeOptions = [
    { value: 1, label: 'Punto de venta' },
    { value: 2, label: 'Almacen' },
    { value: 3, label: 'Franquicia' },
];
var BodyCreateBranchComponent = forwardRef(function (props, ref) {
    var defaultValues = getDefaultBranchCreateForm();
    var _a = useState(defaultValues), values = _a[0], setValues = _a[1];
    var errors = useReactiveVar(inputError);
    var handleChange = function (name, value) {
        var _a;
        setValues(__assign(__assign({}, values), (_a = {}, _a[name] = value, _a)));
    };
    var handleKeyPress = function (e) {
        if (e.key === 'Enter') {
            e.preventDefault();
            props.onPressEnter && props.onPressEnter();
        }
    };
    var getValues = function () { return values; };
    var handleClear = function () {
        setValues(defaultValues);
    };
    useImperativeHandle(ref, function () { return ({
        getValues: getValues,
        onClear: handleClear,
    }); });
    return (React.createElement("div", { className: 'flex-1 px-6 py-4 flex flex-col md:grid md:grid-cols-4 md:gap-4 basis-0 overflow-auto' },
        React.createElement("div", { className: 'mb-4 md:mb-0' },
            React.createElement(SubtitleDialogComponent, { label: 'Datos generales' }),
            React.createElement(TextFieldComponent, { label: 'Nombre', name: 'name', value: values.name, error: errors.name, onChange: handleChange, onKeyDown: handleKeyPress }),
            React.createElement(TextFieldComponent, { label: 'Prefijo', name: 'prefix', value: values.prefix, onChange: handleChange, onKeyDown: handleKeyPress }),
            React.createElement(TextFieldComponent, { label: 'Tel\u00E9fono', name: 'phone', value: values.phone, onChange: handleChange, onKeyDown: handleKeyPress }),
            React.createElement(TextFieldComponent, { label: 'Tel\u00E9fono Alternativo', name: 'altPhone', value: values.altPhone, onChange: handleChange, onKeyDown: handleKeyPress }),
            React.createElement(SelectFieldComponent, { label: 'Tipo', name: 'type', value: values.type, error: errors.type, onChange: handleChange, onKeyDown: handleKeyPress, options: typeOptions })),
        React.createElement("div", { className: 'mb-4 md:mb-0' },
            React.createElement(SubtitleDialogComponent, { label: 'Domicilio' }),
            React.createElement(TextFieldComponent, { label: 'Calle y n\u00FAmero', name: 'addressLine1', value: values.addressLine1, onChange: handleChange, onKeyDown: handleKeyPress }),
            React.createElement(TextFieldComponent, { label: 'Colonia', name: 'addressLine2', value: values.addressLine2, onChange: handleChange, onKeyDown: handleKeyPress }),
            React.createElement(TextFieldComponent, { label: 'Ciudad', name: 'city', value: values.city, onChange: handleChange, onKeyDown: handleKeyPress }),
            React.createElement(TextFieldComponent, { label: 'Municipio', name: 'municipality', value: values.municipality, onChange: handleChange, onKeyDown: handleKeyPress }),
            React.createElement(TextFieldComponent, { label: 'Estado', name: 'state', value: values.state, onChange: handleChange, onKeyDown: handleKeyPress }),
            React.createElement(TextFieldComponent, { label: 'C\u00F3digo postal', name: 'zipCode', value: values.zipCode, onChange: handleChange, onKeyDown: handleKeyPress })),
        values.type === 3 && (React.createElement("div", { className: 'mb-4 md:mb-0' },
            React.createElement(SubtitleDialogComponent, { label: 'Datos fiscales' }),
            React.createElement(TextFieldComponent, { label: 'Raz\u00F3n social', name: 'businessName', value: values.businessName, error: errors.businessName, onChange: handleChange, onKeyDown: handleKeyPress }),
            React.createElement(TextFieldComponent, { label: 'RFC', name: 'identifier', value: values.identifier, error: errors.identifier, onChange: handleChange, onKeyDown: handleKeyPress }),
            React.createElement(TaxRegimeSelectFieldHelpersBodyCreateFormCustomerActionSaleV2Component, { value: values.taxRegime, onChange: handleChange })))));
});
export default BodyCreateBranchComponent;
