var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { useMutation } from '@apollo/client';
import GQL_CALENDAR from '../apollo/gql/calendar';
import getInputErrorFormat from '../containers/sale.v2/Action/helpers/getInputErrorFormat';
var useCreateCalendars = function () {
    return useMutation(GQL_CALENDAR.CREATE_CALENDARS, {
        update: function (cache, _a) {
            var data = _a.data;
            try {
                data.createCalendars.forEach(function (el) {
                    cache.modify({
                        fields: {
                            calendars: function (existingCalendarRefs) {
                                var newCalendarRef = cache.writeFragment({
                                    data: el,
                                    fragment: GQL_CALENDAR.FRAGMENT_CALENDAR,
                                });
                                return __assign(__assign({}, existingCalendarRefs), { rows: __spreadArray(__spreadArray([], existingCalendarRefs.rows, true), [newCalendarRef], false) });
                            },
                        },
                    });
                });
            }
            catch (error) {
                getInputErrorFormat(error);
            }
        },
    });
};
export default useCreateCalendars;
