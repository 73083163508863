import React, { Fragment } from 'react';
import { TableCell, TableHead, TableRow } from '@mui/material';
var TableHeadProductInventoryComponent = function (props) {
    return (React.createElement(TableHead, null,
        React.createElement(TableRow, null,
            React.createElement(TableCell, { colSpan: 2 }),
            React.createElement(TableCell, { className: 'bg-[#c9c9c9] border-0 border-b border-solid border-[#989898]' }),
            React.createElement(TableCell, null),
            props.Inventories.map(function (el, i) { return (React.createElement(TableCell, { key: el.Branch.id, colSpan: 3, align: 'center', style: {
                    background: i % 2 === 0 ? '#c9c9c9' : '#fafafa',
                    borderColor: i % 2 === 0 ? '#989898' : '#ddd',
                }, className: 'whitespace-nowrap' }, el.Branch.name)); })),
        React.createElement(TableRow, { onClick: function () { return console.log('click'); } },
            React.createElement(TableCell, null, "SKU"),
            React.createElement(TableCell, null, "Producto"),
            React.createElement(TableCell, { align: 'center', className: 'bg-[#c9c9c9] border-0 border-b border-solid border-[#989898]' }, "Clasificaci\u00F3n"),
            React.createElement(TableCell, { align: 'center' }, "Unidad"),
            props.Inventories.map(function (el, i) { return (React.createElement(Fragment, { key: el.Branch.id },
                React.createElement(TableCell, { align: 'center', style: {
                        background: i % 2 === 0 ? '#c9c9c9' : '#fafafa',
                        borderColor: i % 2 === 0 ? '#989898' : '#ddd',
                    } }, "Cantidad"),
                React.createElement(TableCell, { align: 'right', style: {
                        background: i % 2 === 0 ? '#c9c9c9' : '#fafafa',
                        borderColor: i % 2 === 0 ? '#989898' : '#ddd',
                    }, className: 'whitespace-nowrap' }, "Costo unitario"),
                React.createElement(TableCell, { align: 'right', style: {
                        background: i % 2 === 0 ? '#c9c9c9' : '#fafafa',
                        borderColor: i % 2 === 0 ? '#989898' : '#ddd',
                    }, className: 'whitespace-nowrap' }, "Costo total"))); }))));
};
export default TableHeadProductInventoryComponent;
