import React from 'react';

import { FormControl, InputAdornment, InputLabel, OutlinedInput } from '@mui/material';
import IconButton from './IconButton';
import { discountTypeSaleV2 } from '../../../../../apollo/cache';

const DiscountFieldCartFormChooseProductSaleV2Component = props => {
  const handleChange = e => {
    const discountTypeSaleV2Var = discountTypeSaleV2();
    if (
      (Number.isNaN(parseFloat(e.target.value)) && e.target.value !== '') ||
      (parseFloat(e.target.value) > 100 && discountTypeSaleV2Var === 1)
    )
      return;
    props.handleChange('discount', e.target.value);
  };
  return (
    <FormControl size='small' variant='outlined' className='mt-1 pt-0 w-36'>
      <InputLabel htmlFor='outlined-adornment-discount-field'>Descuento</InputLabel>
      <OutlinedInput
        id='discount-field'
        type='numeric'
        size='small'
        value={props.value}
        onChange={handleChange}
        endAdornment={
          <InputAdornment position='end'>
            <IconButton />
          </InputAdornment>
        }
        label='Descuento'
      />
    </FormControl>
  );
};

export default DiscountFieldCartFormChooseProductSaleV2Component;
