import React, { useState, useEffect } from 'react';

import SettingsIcon from '@mui/icons-material/SettingsOutlined';
import PrintIcon from '@mui/icons-material/Print';
import ListAltIcon from '@mui/icons-material/ListAltOutlined';
import EmailIcon from '@mui/icons-material/EmailOutlined';
import CodeIcon from '@mui/icons-material/CodeOutlined';
// import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedInOutlined';

import { actions } from '../../apollo/cache';

import Settings from './settings.dialog';
import ReSendDialog from './reSend.dialog';
import PrintDialog from './print.dialog';
import LiquidateDialog from './liquidateReceivables.dialog';

import ProductsDialog from '../sale.report.v2/products.dialog';
import CONFIG from '../../config/config.json';

const ActionComponent = () => {
  const [isOpenSettings, setIsOpenSettings] = useState([false, null, null]);
  const [isOpenProduct, setIsOpenProduct] = useState([false, null]);
  const [isOpenReSend, setIsOpenReSend] = useState([false, null, null]);
  const [isOpenPrintDialog, setIsOpenPrintDialog] = useState([false, null]);
  const [isOpenLiquidateDialog, setIsOpenLiquidateDialog] = useState([false, null]);

  const handleSettings = (e, { id, ...data }) => {
    setIsOpenSettings([true, id, data]);
  };

  const handleCloseSettings = () => setIsOpenSettings([false, null, null]);

  const handlePDF = (e, { saleId, uuid }) => {
    if (uuid) window.open(`${CONFIG['restServer'].uri}/files/invoices/${uuid}/pdf`);
    else window.open(`${CONFIG['restServer'].uri}/files/sales/${saleId}?category=1`);
  };

  const handleProducts = (e, { saleId }) => setIsOpenProduct([true, saleId]);
  const handleCloseProducts = () => setIsOpenProduct([false, null]);

  const handleReSend = (e, { saleId, Sale }) =>
    setIsOpenReSend([true, saleId, Sale ? Sale.invoiceId : null]);

  const handleCloseReSend = () => setIsOpenReSend([false, null, null]);

  const handlePrintDialogClose = () => setIsOpenPrintDialog([false, null]);

  const handleXML = (e, { uuid }) => {
    if (uuid) window.open(`${CONFIG['restServer'].uri}/files/invoices/${uuid}/xml`);
  };

  // const handleOpenLiquidate = (e, { id }) => setIsOpenLiquidateDialog([true, id]);
  const handleCloseLiquidate = () => setIsOpenLiquidateDialog([false, null]);

  useEffect(() => {
    actions(
      [
        // {
        //   icon: <AssignmentTurnedInIcon />,
        //   label: 'Liquidar Deuda',
        //   onClick: handleOpenLiquidate,
        // },
        {
          icon: <EmailIcon />,
          label: 'Reenviar PDF',
          onClick: handleReSend,
        },
        {
          icon: <ListAltIcon />,
          label: 'Productos',
          onClick: handleProducts,
        },
        {
          icon: <CodeIcon />,
          label: 'Descargar XML',
          onClick: handleXML,
        },
        {
          icon: <PrintIcon />,
          label: 'Imprimir PDF',
          onClick: handlePDF,
        },
        {
          icon: <SettingsIcon />,
          label: 'Configuración',
          onClick: handleSettings,
          disable: { key: 'paid', value: true },
        },
      ].filter(el => el),
    );
    return () => {
      actions([]);
    };
  }, []);

  return (
    <>
      <ReSendDialog
        id={isOpenReSend[1]}
        invoiceId={isOpenReSend[2]}
        isOpen={isOpenReSend[0]}
        handleClose={handleCloseReSend}
      />
      <Settings
        isOpen={isOpenSettings[0]}
        handleClose={handleCloseSettings}
        id={isOpenSettings[1]}
        data={isOpenSettings[2]}
      />
      <ProductsDialog
        isOpen={isOpenProduct[0]}
        handleClose={handleCloseProducts}
        saleId={isOpenProduct[1]}
      />
      <PrintDialog
        isOpen={isOpenPrintDialog[0]}
        id={isOpenPrintDialog[1]}
        handleClose={handlePrintDialogClose}
      />
      <LiquidateDialog
        isOpen={isOpenLiquidateDialog[0]}
        id={isOpenLiquidateDialog[1]}
        handleClose={handleCloseLiquidate}
      />
    </>
  );
};

export default ActionComponent;
