import React, { useEffect, useState } from 'react';

import { useLazyQuery, useMutation } from '@apollo/client';
import Typography from '@mui/material/Typography';

import FormDialog from '../../presentationals/FormDialog';
import TextField from '../../presentationals/FormComponents/TextField';
import Snackbar from '../../presentationals/Snackbar';

import { parseError } from '../../helpers';

import GQL from './_gql';
import GQL_PURCHASE_ORDER from '../purchaseOrder.v2/_gql';
import PDF from '../purchaseOrder.v2/_pdf';
import useSnackbarDialog from '../../store/snackbar/snackbarDialog';

const defaultValues = {
  email: '',
  altEmail: '',
  otherEmail: '',
};

const Component = props => {
  const { setSnackbar } = useSnackbarDialog();
  const [values, setValues] = useState(defaultValues);
  const [sendPurchaseOrder, { loading }] = useMutation(GQL.SEND_PURCHASE_ORDER);
  const [getPurchaseOrder, { data: dataPurchaseOrder }] = useLazyQuery(
    GQL_PURCHASE_ORDER.GET_BY_ID,
  );

  useEffect(() => {
    if (props.id) getPurchaseOrder({ variables: { id: props.id } });
  }, [props.id]);

  useEffect(() => {
    if (dataPurchaseOrder)
      if (dataPurchaseOrder.purchaseOrder.Provider) {
        const email = dataPurchaseOrder.purchaseOrder.Provider.email
          ? dataPurchaseOrder.purchaseOrder.Provider.email
          : '';
        const altEmail = dataPurchaseOrder.purchaseOrder.Provider.altEmail
          ? dataPurchaseOrder.purchaseOrder.Provider.altEmail
          : '';
        const otherEmail = dataPurchaseOrder.purchaseOrder.Provider.otherEmail
          ? dataPurchaseOrder.purchaseOrder.Provider.otherEmail
          : '';
        setValues(values => ({ ...values, email, altEmail, otherEmail }));
      }
  }, [dataPurchaseOrder]);

  const handleClose = () => {
    props.handleClose();
    setValues(defaultValues);
  };

  const handleChange = (name, value) => {
    setValues(values => ({ ...values, [name]: value }));
  };

  const handleAction = async () => {
    try {
      const doc = await PDF(props.id, 2);
      const emails = [
        values.email !== '' && typeof values.email === 'string' && values.email.trim(),
        values.altEmail !== '' && typeof values.altEmail === 'string' && values.altEmail.trim(),
        values.otherEmail !== '' &&
          typeof values.otherEmail === 'string' &&
          values.otherEmail.trim(),
      ].filter(el => el);
      sendPurchaseOrder({ variables: { id: props.id, file: doc, emails } });
      handleClose();
      setValues(defaultValues);
    } catch (e) {
      console.log(e);
      if (e.input) {
        setErrors({ [e.name]: e.message });
        return;
      }
      const parseErrors = parseError(e);
      parseErrors.forEach(el => {
        if (el.name === 'BAD_USER_INPUT') setErrors(el.message);
        else
          setSnackbar({
            variables: {
              isOpen: true,
              time: 3000,
              label: el.message,
              severity: 'error',
            },
          });
      });
    }
  };

  return (
    <>
      <FormDialog
        title=''
        isOpen={props.isOpen}
        isLoading={loading}
        handleClose={handleClose}
        handleAction={handleAction}
        actionLabel='Enviar'
      >
        <div>
          <Typography variant='h5' gutterBottom>
            Reenviar PDF
          </Typography>
          <Typography variant='subtitle1'>
            Asegúrate de que el correo proporcionado sea el correcto para hacer llegar al archivo
            satisfactoriamente.
          </Typography>
          <TextField
            label='Correo 1'
            name='email'
            value={values.email}
            onChange={handleChange}
            noFormat
            variant='outlined'
          />
          <TextField
            label='Correo 2'
            name='altEmail'
            value={values.altEmail}
            onChange={handleChange}
            noFormat
            variant='outlined'
          />
          <TextField
            label='Correo 3'
            name='otherEmail'
            value={values.otherEmail}
            onChange={handleChange}
            noFormat
            variant='outlined'
          />
        </div>
      </FormDialog>
      <Snackbar horizontal='center' />
    </>
  );
};

export default Component;
