import React from 'react';

import Table from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';

import { currencyFormat } from '../../../helpers';

const CreditNoteDialogTableComponent = props => (
  <TableContainer className='my-2 rounded min-h-[120px] border border-solid border-[#d8d8d8]'>
    <Table size='small'>
      <TableHead>
        <TableRow className='bg-[#f6f6f6]'>
          <TableCell padding='none' />
          <TableCell>Descripción</TableCell>
          <TableCell align='center'>Cant.</TableCell>
          <TableCell align='right'>Dscto. Gral.</TableCell>
          <TableCell align='right'>Importe</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {props.products &&
          props.products.map(el => {
            return (
              <TableRow key={el.value}>
                <TableCell padding='none' />
                <TableCell>{`${el.sku} ${el.name}`}</TableCell>
                <TableCell align='center'>{el.quantity}</TableCell>
                <TableCell align='right'>$ {currencyFormat(el.discount)}</TableCell>
                <TableCell align='right'>$ {currencyFormat(el.total)}</TableCell>
              </TableRow>
            );
          })}
      </TableBody>
    </Table>
  </TableContainer>
);

export default CreditNoteDialogTableComponent;
