import { gql } from '@apollo/client';
import FRAGMENT_PRODUCT_INGREDIENT from '../../apollo/gql/product/fragmentProductIngredient';

const FRAGMENT_CORE_PRODUCT_FIELDS = gql`
  fragment CoreProductFields on Product {
    id
    code
    type
    name
    price
    sku
    barcode
    altBarcode
    minimum
    maximum
    prefix
    iva
    ieps
    adjustPrice
    percentAdjust
    isRequisition
    fileId
    unitKey
    productKey
    quantityDefaultPresentation
    weight
    length
    height
    width
    toShipping
    Prices {
      key
      name
      price
    }
    Inventory {
      quantity
    }
  }
`;

const FRAGMENT_CORE_PRODUCTS_FIELDS = gql`
  fragment CoreProductsFields on Product {
    id
    code
    type
    name
    prefix
    price
    sku
    barcode
    altBarcode
    minimum
    maximum
    stockDiff
    iva
    ieps
    status
  }
`;

const GET = gql`
  ${FRAGMENT_CORE_PRODUCT_FIELDS}
  query Products(
    $limit: Int
    $offset: Int
    $name: [String]
    $type: [Int]
    $classificationId: [ID]
    $lite: Boolean
  ) {
    products(
      limit: $limit
      offset: $offset
      name: $name
      type: $type
      classificationId: $classificationId
      lite: $lite
    ) {
      count
      rows {
        ...CoreProductFields
        Classification {
          id
          name
        }
        Inventory {
          quantity
        }
        ProductPresentations {
          name
        }
        PresentationBase {
          name
          cost
        }
      }
    }
  }
`;

const GET_LITE = gql`
  query Products(
    $limit: Int
    $offset: Int
    $name: [String]
    $type: [Int]
    $classificationId: [ID]
    $lite: Boolean
  ) {
    products(
      limit: $limit
      offset: $offset
      name: $name
      type: $type
      classificationId: $classificationId
      lite: $lite
    ) {
      count
      rows {
        id
        name
        price
        code
        isSerialized
        sku
        barcode
        altBarcode
        PresentationBase {
          id
        }
      }
    }
  }
`;

const GET_V2 = gql`
  ${FRAGMENT_CORE_PRODUCTS_FIELDS}
  query Products(
    $order: [String]
    $limit: Int
    $offset: Int
    $name: [String]
    $type: [Int]
    $classificationId: [ID]
    $forPursache: Boolean
    $includeBaseInPresentations: Boolean
    $onMinimum: Boolean
    $lite: Boolean
    $stock: Boolean
  ) {
    products(
      order: $order
      limit: $limit
      offset: $offset
      name: $name
      type: $type
      classificationId: $classificationId
      forPursache: $forPursache
      includeBaseInPresentations: $includeBaseInPresentations
      onMinimum: $onMinimum
      lite: $lite
      stock: $stock
    ) {
      count
      rows {
        ...CoreProductsFields
        productKey
        unitKey
        Classification {
          id
          name
        }
        Inventory {
          quantity
        }
        ProductPresentations {
          id
          name
          cost
          barcode
          altBarcode
          sku
          baseQuantity
          printer
        }
        PresentationBase {
          id
          name
          cost
          barcode
          altBarcode
        }
        fileId
      }
    }
  }
`;

const GET_BY_ID = gql`
  ${FRAGMENT_CORE_PRODUCT_FIELDS}
  ${FRAGMENT_PRODUCT_INGREDIENT}
  query Product($id: ID!) {
    product(id: $id) {
      ...CoreProductFields
      description
      typeProductionTime
      productionTimeLimit
      productKey
      unitKey
      providerId
      classificationId
      Commentaries
      isSerialized
      lastPurchaseDate
      currentInventory
      public
      PresentationBase {
        id
      }
      ProductAttributes {
        id
        type
        attribute
        productPresentationId
        principalAttributeId
        priceType
        price
        ProductAttributeFiles {
          fileId
        }
      }
      ProductPresentationPrices {
        id
        price
        type
        Rate {
          id
          name
        }
      }
      ProductPresentations {
        id
        name
        forPursache
        forSale
        quantity
        price
        limit
        fileId
        printer
        ProductVariations {
          id
          name
        }
        ProductIngredients {
          ...FragmentProductIngredient
        }
      }
      PresentationBase {
        id
        name
        forPursache
        forSale
        limit
        cost
        ProductVariations {
          id
          name
        }
        ProductIngredients {
          ...FragmentProductIngredient
        }
      }
      Files {
        id
      }
    }
  }
`;

const CREATE_PRODUCT_INGREDIENT = gql`
  ${FRAGMENT_PRODUCT_INGREDIENT}
  mutation CreateProductIngredient(
    $id: ID!
    $ingredientId: ID!
    $price: Float!
    $type: Int!
    $quantity: Float!
    $portion: Int!
    $productVariationId: ID
    $productSizeId: ID!
  ) {
    createProductIngredient(
      id: $id
      input: {
        ingredientId: $ingredientId
        price: $price
        type: $type
        quantity: $quantity
        portion: $portion
        productVariationId: $productVariationId
        productSizeId: $productSizeId
      }
    ) {
      ...FragmentProductIngredient
    }
  }
`;

const UPDATE_PRODUCT_INGREDIENT = gql`
  ${FRAGMENT_PRODUCT_INGREDIENT}
  mutation UpdateProductIngredient(
    $id: ID!
    $price: Float!
    $type: Int!
    $quantity: Float!
    $portion: Int!
    $productVariationId: ID
    $freeLimit: Float
  ) {
    updateProductIngredient(
      id: $id
      input: {
        price: $price
        type: $type
        quantity: $quantity
        portion: $portion
        productVariationId: $productVariationId
        freeLimit: $freeLimit
      }
    ) {
      ...FragmentProductIngredient
    }
  }
`;

const UPDATE_PRODUCT_INGREDIENT_PLATFORM_PRICE = gql`
  ${FRAGMENT_PRODUCT_INGREDIENT}
  mutation UpdateProductIngredientPlatformPrice(
    $id: ID!
    $rappiPrice: Float
    $didiPrice: Float
    $uberPrice: Float
  ) {
    updateProductIngredientPlatformPrice(
      id: $id
      input: { rappiPrice: $rappiPrice, didiPrice: $didiPrice, uberPrice: $uberPrice }
    ) {
      ...FragmentProductIngredient
    }
  }
`;

const GET_NAME = gql`
  query ProductsName($type: [Int], $limit: Int) {
    products(type: $type, limit: $limit) {
      count
      rows {
        id
        name
      }
    }
  }
`;

const GET_PRODUCT_VARIATION = gql`
  query ProductVariation($id: ID!, $productPresentationId: ID!) {
    productVariation(id: $id, productPresentationId: $productPresentationId) {
      id
      ProductVariation {
        id
        name
      }
    }
  }
`;

const GET_SIZE = gql`
  query ProductSize($id: ID!) {
    productSize(id: $id) {
      id
      name
      productId
      price
      quantity
      limit
    }
  }
`;

const CREATE_PRODUCT_SIZE = gql`
  mutation CreateProductSize(
    $productId: ID!
    $name: String!
    $price: Float!
    $quantity: Float!
    $limit: Float!
  ) {
    createProductSize(
      input: {
        productId: $productId
        name: $name
        price: $price
        quantity: $quantity
        limit: $limit
      }
    ) {
      id
      name
      price
      quantity
      limit
      productId
    }
  }
`;

const UPDATE_PRODUCT_SIZE = gql`
  mutation UpdateProductSize(
    $id: ID!
    $productId: ID!
    $name: String!
    $price: Float!
    $quantity: Float!
    $limit: Float!
  ) {
    updateProductSize(
      id: $id
      input: {
        productId: $productId
        name: $name
        price: $price
        quantity: $quantity
        limit: $limit
      }
    ) {
      id
      name
      productId
      price
      quantity
      limit
    }
  }
`;

const DELETE_PRODUCT_SIZE = gql`
  mutation DeleteProductSize($id: ID!) {
    deleteProductSize(id: $id) {
      id
    }
  }
`;

const GET_PRODUCT_SIZES = gql`
  query Product($id: ID!) {
    product(id: $id) {
      id
      ProductUnits {
        id
        name
        unitCompoundId
      }
      ProductSizes {
        id
        name
        price
        quantity
      }
      ProductPresentations {
        id
        name
      }
    }
  }
`;

const GET_PRODUCT_PRESENTATIONS = gql`
  ${FRAGMENT_PRODUCT_INGREDIENT}
  query Product($id: ID!, $forPursache: Boolean, $includeBaseInPresentations: Boolean) {
    product(
      id: $id
      forPursache: $forPursache
      includeBaseInPresentations: $includeBaseInPresentations
    ) {
      id
      name
      currentInventory
      ProductPresentations {
        id
        name
        cost
        baseQuantity
        barcode
        altBarcode
        price
        printer
        ProductIngredients {
          ...FragmentProductIngredient
        }
      }
    }
  }
`;

const CREATE_PRODUCT = gql`
  ${FRAGMENT_CORE_PRODUCT_FIELDS}
  mutation CreateProductV2(
    $name: String!
    $type: Int!
    $price: Float!
    $description: String
    $sku: String
    $barcode: String
    $altBarcode: String
    $productKey: String!
    $unitKey: String!
    $unitBase: String
    $classificationId: ID!
    $maximum: Float
    $minimum: Float
    $limit: Int
    $typeProductionTime: Int
    $productionTimeLimit: Float
    $Image: Upload
    $Commentary: [String]
    $Presentations: [PresentationData]
    $Ingredients: [ProductIngredientData]
    $Variations: [ProductVariationData]
    $iva: Float
    $ieps: Float
    $isWeighed: Int
    $providerId: ID
    $percentAdjust: Float
    $adjustPrice: Boolean
    $isRequisition: Boolean
    $cost: Float
    $prefix: String
    $weight: Float
    $length: Float
    $height: Float
    $width: Float
    $toShipping: Boolean
  ) {
    createProductV2(
      input: {
        name: $name
        type: $type
        price: $price
        description: $description
        sku: $sku
        barcode: $barcode
        altBarcode: $altBarcode
        productKey: $productKey
        unitKey: $unitKey
        unitBase: $unitBase
        classificationId: $classificationId
        maximum: $maximum
        minimum: $minimum
        limit: $limit
        typeProductionTime: $typeProductionTime
        productionTimeLimit: $productionTimeLimit
        Image: $Image
        Commentary: $Commentary
        Presentations: $Presentations
        Ingredients: $Ingredients
        Variations: $Variations
        iva: $iva
        ieps: $ieps
        isWeighed: $isWeighed
        providerId: $providerId
        adjustPrice: $adjustPrice
        isRequisition: $isRequisition
        percentAdjust: $percentAdjust
        cost: $cost
        prefix: $prefix
        weight: $weight
        length: $length
        height: $height
        width: $width
        toShipping: $toShipping
      }
    ) {
      ...CoreProductFields
      classificationId
      description
      typeProductionTime
      productionTimeLimit
      productKey
      unitKey
      providerId
      Classification {
        id
        name
      }
    }
  }
`;

const DELETE_PRESENTATION_PRODUCT = gql`
  mutation DeletePresentation($id: ID!) {
    deletePresentation(id: $id) {
      id
    }
  }
`;

const ADD_PRESENTATION = gql`
  mutation AddPresentation(
    $id: ID!
    $name: String!
    $forPursache: Boolean
    $forSale: Boolean
    $quantity: Float
    $price: Float
    $limit: Int
    $Image: Upload
    $printer: Int
  ) {
    addPresentation(
      id: $id
      input: {
        name: $name
        forPursache: $forPursache
        forSale: $forSale
        quantity: $quantity
        price: $price
        limit: $limit
        Image: $Image
        printer: $printer
      }
    ) {
      id
      name
      forPursache
      forSale
      quantity
      price
      limit
      printer
    }
  }
`;

const UPDATE_PRESENTATION = gql`
  mutation UpdatePresentation(
    $id: ID!
    $productPresentationId: ID
    $name: String!
    $forPursache: Boolean
    $forSale: Boolean
    $quantity: Float
    $price: Float
    $limit: Int
    $Image: Upload
    $uploadAction: Int
    $barcode: String
    $printer: Int
  ) {
    updatePresentation(
      id: $id
      input: {
        productPresentationId: $productPresentationId
        name: $name
        forPursache: $forPursache
        forSale: $forSale
        quantity: $quantity
        price: $price
        limit: $limit
        Image: $Image
        uploadAction: $uploadAction
        barcode: $barcode
        printer: $printer
      }
    ) {
      id
      name
      forPursache
      forSale
      quantity
      price
      limit
      printer
    }
  }
`;

const UPDATE_PRODUCT = gql`
  ${FRAGMENT_CORE_PRODUCT_FIELDS}
  mutation UpdateProductV2(
    $id: ID!
    $name: String
    $type: Int
    $price: Float
    $cost: Float
    $description: String
    $sku: String
    $barcode: String
    $altBarcode: String
    $productKey: String
    $unitKey: String!
    $unitBase: String
    $classificationId: ID
    $providerId: ID
    $maximum: Float
    $minimum: Float
    $typeProductionTime: Int
    $productionTimeLimit: Float
    $iva: Float
    $limit: Int
    $ieps: Float
    $isWeighed: Int
    $percentAdjust: Float
    $adjustPrice: Boolean
    $isRequisition: Boolean
    $prefix: String
    $weight: Float
    $length: Float
    $height: Float
    $width: Float
    $toShipping: Boolean
  ) {
    updateProductV2(
      id: $id
      input: {
        name: $name
        type: $type
        price: $price
        cost: $cost
        description: $description
        sku: $sku
        barcode: $barcode
        altBarcode: $altBarcode
        productKey: $productKey
        unitKey: $unitKey
        unitBase: $unitBase
        classificationId: $classificationId
        maximum: $maximum
        minimum: $minimum
        limit: $limit
        typeProductionTime: $typeProductionTime
        productionTimeLimit: $productionTimeLimit
        iva: $iva
        ieps: $ieps
        isWeighed: $isWeighed
        providerId: $providerId
        adjustPrice: $adjustPrice
        isRequisition: $isRequisition
        percentAdjust: $percentAdjust
        prefix: $prefix
        weight: $weight
        length: $length
        height: $height
        width: $width
        toShipping: $toShipping
      }
    ) {
      ...CoreProductFields
      description
      typeProductionTime
      productionTimeLimit
      productKey
      providerId
      unitKey
      Classification {
        id
        name
      }
    }
  }
`;

const DELETE_PRODUCT_INGREDIENT = gql`
  mutation DeleteProductIngredientV2($id: ID!) {
    deleteProductIngredientV2(id: $id) {
      id
    }
  }
`;

const ADD_PRODUCT_INGREDIENT = gql`
  ${FRAGMENT_PRODUCT_INGREDIENT}
  mutation AddProductIngredient(
    $productId: ID!
    $type: Int!
    $price: Float!
    $ingredientId: ID!
    $quantity: Float!
    $portion: Int!
    $productPresentationId: ID
    $productVariationId: ID
    $freeLimit: Float
    $extra: Float
  ) {
    addProductIngredient(
      id: $productId
      input: {
        type: $type
        price: $price
        ingredientId: $ingredientId
        quantity: $quantity
        portion: $portion
        productPresentationId: $productPresentationId
        productVariationId: $productVariationId
        freeLimit: $freeLimit
        extra: $extra
      }
    ) {
      ...FragmentProductIngredient
    }
  }
`;

const ADD_PRODUCT_VARIATION = gql`
  mutation CreateProductVariation(
    $productId: ID!
    $name: String!
    $productPresentationId: ID!
    $limitVariation: Int!
    $isRequired: Boolean
    $selectionType: Int
  ) {
    createProductVariation(
      input: {
        productId: $productId
        name: $name
        productPresentationId: $productPresentationId
        limitVariation: $limitVariation
        isRequired: $isRequired
        selectionType: $selectionType
      }
    ) {
      id
      name
      productPresentationId
      productPresentationVariationId
    }
  }
`;

const FRAGMENT_NEW_PRODUCT_VARIATION = gql`
  fragment NewProductVariation on ProductVariation {
    id
    name
  }
`;

const FRAGMENT_NEW_PRODUCT_PRESENTATION_VARIATION = gql`
  fragment NewProductPresentationVariation on ProductPresentationVariation {
    id
    ProductVariation {
      id
      name
    }
  }
`;

const FRAGMENT_NEW_PRODUCT_PRESENTATION = gql`
  fragment NewProductPresentation on ProductPresentation {
    id
    name
    forPursache
    forSale
    quantity
    price
    limit
    printer
  }
`;

const DELETE = gql`
  mutation Delete($id: ID!) {
    deleteProduct(id: $id) {
      id
    }
  }
`;

const DELETE_FILE = gql`
  mutation DeleteFile($id: ID!, $fileId: ID!, $type: String!) {
    deleteFile(id: $id, fileId: $fileId, type: $type) {
      id
    }
  }
`;

const UPDATE_PRODUCT_COMMENTARIES = gql`
  mutation UpdateProductCommentaries($id: ID!, $Commentaries: [String]!) {
    updateProductCommentaries(id: $id, Commentaries: $Commentaries) {
      Commentaries
    }
  }
`;

const UPDATE_FILE = gql`
  mutation UpdateFiles($id: ID!, $file: Upload!, $type: String!) {
    updateFiles(id: $id, file: $file, type: $type) {
      id
    }
  }
`;

const DELETE_PRESENTATION_FILE = gql`
  mutation DeletePresentationFile($id: ID!) {
    deletePresentationFile(id: $id) {
      id
    }
  }
`;

const UPDATE_PRODUCT_DEFAULT_PRESENTATION = gql`
  mutation UpdateProductDefaultPresentation($id: ID!, $productPresentationId: ID!) {
    updateProductDefaultPresentation(id: $id, productPresentationId: $productPresentationId) {
      id
      name
    }
  }
`;

const UPDATE_PRODUCT_PRICE = gql`
  mutation UpdateProductPrice(
    $productId: ID!
    $rateId: ID
    $price: Float!
    $branchId: ID
    $productPriceId: ID
  ) {
    updateProductPrice(
      input: {
        productId: $productId
        rateId: $rateId
        price: $price
        branchId: $branchId
        productPriceId: $productPriceId
      }
    ) {
      id
      price
      rateId
    }
  }
`;

const UPDATE_STANDAR_PRODUCT_PRICE = gql`
  mutation UpdateStandarProductPrice(
    $productId: ID!
    $price: Float!
    $branchId: ID!
    $productPriceId: ID
  ) {
    updateStandarProductPrice(
      input: {
        productId: $productId
        price: $price
        branchId: $branchId
        productPriceId: $productPriceId
      }
    ) {
      id
    }
  }
`;

const DELETE_PRODUCT_PRICE = gql`
  mutation DeleteProductPrice($id: ID!) {
    deleteProductPrice(id: $id) {
      id
    }
  }
`;

const CREATE_RATE = gql`
  mutation CreateRate($name: String!, $branchId: ID) {
    createRate(input: { name: $name, branchId: $branchId }) {
      id
      name
    }
  }
`;

const GET_RATES = gql`
  query Rates($branchId: ID) {
    rates(branchId: $branchId) {
      rows {
        id
        name
      }
    }
  }
`;

const GET_PRODUCT_PRICES_BY_ID = gql`
  query ProductPrices($productId: ID) {
    productPrices(productId: $productId) {
      rows {
        id
        price
        rateId
        Rate {
          name
        }
        Branch {
          name
        }
      }
    }
  }
`;

const GET_STANDAR_PRODUCT_PRICES_BY_ID = gql`
  query StandarProductPrices($productId: ID) {
    standarProductPrices(productId: $productId) {
      rows {
        id
        price
        branchId
        isProdutPrice
        Branch {
          name
        }
      }
    }
  }
`;

const FRAGMENT_NEW_RATE = gql`
  fragment NewRate on Rate {
    id
    name
  }
`;

const FRAGMENT_NEW_PRODUCT_PRICE = gql`
  fragment NewProductPrice on PriceProduct {
    id
    price
    rateId
  }
`;

const SET_IS_PUBLIC = gql`
  mutation UpdateProductPublic($id: ID!, $public: Boolean) {
    updateProductPublic(id: $id, public: $public) {
      id
      public
    }
  }
`;

const SET_IS_FOR_SHIPPING = gql`
  mutation UpdateECProductShipping($id: ID!, $toShipping: Boolean!) {
    updateECProductShipping(id: $id, toShipping: $toShipping) {
      id
      toShipping
    }
  }
`;

const ADD_PRODUCT_ATTRIBUTE = gql`
  mutation AddProductAttribute(
    $productId: ID!
    $attribute: String
    $name: String
    $productPresentationId: ID
    $price: Float
    $priceType: Int
    $type: Int!
    $file: Upload
    $principalAttributeId: ID
  ) {
    addProductAttribute(
      productId: $productId
      input: {
        attribute: $attribute
        name: $name
        productPresentationId: $productPresentationId
        principalAttributeId: $principalAttributeId
        price: $price
        priceType: $priceType
        type: $type
        file: $file
      }
    ) {
      id
      attribute
      name
      type
      price
      priceType
      productPresentationId
      fileId
      principalAttributeId
    }
  }
`;

const FRAGMENT_NEW_PRODUCT_ATTRIBUTE = gql`
  fragment NewProductAttribute on ProductAttribute {
    id
    attribute
    name
    type
    price
    priceType
    productPresentationId
    principalAttributeId
  }
`;

const DELETE_PRODUCT_ATTRIBUTE = gql`
  mutation DeleteProductAttribute($id: ID!) {
    deleteProductAttribute(id: $id) {
      id
    }
  }
`;

const UPDATE_PRODUCT_ATTRIBUTE = gql`
  mutation UpdateProductAttribute(
    $id: ID!
    $attribute: String
    $name: String
    $productPresentationId: ID
    $principalAttributeId: ID
    $price: Float
    $priceType: Int
    $type: Int!
  ) {
    updateProductAttribute(
      id: $id
      input: {
        attribute: $attribute
        name: $name
        productPresentationId: $productPresentationId
        principalAttributeId: $principalAttributeId
        price: $price
        priceType: $priceType
        type: $type
      }
    ) {
      id
      attribute
      name
      type
      price
      priceType
      productPresentationId
      principalAttributeId
    }
  }
`;

const DELETE_PRODUCT_ATTRIBUTE_FILE = gql`
  mutation DeleteProductAttributeFile($id: ID!) {
    deleteProductAttributeFile(id: $id) {
      id
    }
  }
`;

const CREATE_PRODUCT_ATTRIBUTE_FILE = gql`
  mutation CreateProductAttributeFile($productAttributeId: ID!, $file: Upload) {
    createProductAttributeFile(productAttributeId: $productAttributeId, file: $file) {
      id
      productId
      productAttributeId
      fileId
      mimetype
    }
  }
`;

const FRAGMENT_NEW_PRODUCT_ATTRIBUTE_FILE = gql`
  fragment NewProductAttributeFile on ProductAttributeFile {
    id
    productId
    productAttributeId
    fileId
    mimetype
  }
`;

const FRAGMENT_NEW_PRODUCT_FILE = gql`
  fragment NewFile on File {
    id
  }
`;

const GET_PRODUCT_ATTRIBUTE_FILES = gql`
  query ProductAttributeFiles($productAttributeId: ID!) {
    productAttributeFiles(productAttributeId: $productAttributeId) {
      id
      productId
      productAttributeId
      fileId
      mimetype
    }
  }
`;

const UPDATE_PRODUCT_STATUS = gql`
  mutation UpdateProductStatus($id: ID!, $status: Int!) {
    updateProductStatus(id: $id, status: $status) {
      status
    }
  }
`;

const CLONE_PRODUCT_INGREDIENTS = gql`
  mutation CloneProductIngredients($productPresentationId: ID!, $toProductId: ID!) {
    cloneProductIngredients(
      productPresentationId: $productPresentationId
      toProductId: $toProductId
    )
  }
`;

const UPDATE_PRODUCT_MEASUREMENTS = gql`
  mutation UpdateEcProductMeasurements(
    $id: ID!
    $weight: Float!
    $length: Float!
    $height: Float!
    $width: Float!
  ) {
    updateEcProductMeasurements(
      id: $id
      input: { weight: $weight, length: $length, height: $height, width: $width }
    ) {
      id
      weight
      length
      height
      width
    }
  }
`;

const GET_PRODUCT_INGREDIENT = gql`
  ${FRAGMENT_PRODUCT_INGREDIENT}
  query ProductIngredient($id: ID!) {
    productIngredient(id: $id) {
      ...FragmentProductIngredient
    }
  }
`;

const GQL = {
  UPDATE_PRODUCT_INGREDIENT_PLATFORM_PRICE,
  UPDATE_PRODUCT_INGREDIENT,
  GET_PRODUCT_INGREDIENT,
  UPDATE_PRODUCT_MEASUREMENTS,
  SET_IS_FOR_SHIPPING,
  FRAGMENT_NEW_PRODUCT_FILE,
  UPDATE_PRODUCT_ATTRIBUTE,
  GET_PRODUCT_ATTRIBUTE_FILES,
  FRAGMENT_NEW_PRODUCT_ATTRIBUTE_FILE,
  CREATE_PRODUCT_ATTRIBUTE_FILE,
  DELETE_PRODUCT_ATTRIBUTE_FILE,
  DELETE_PRODUCT_ATTRIBUTE,
  FRAGMENT_NEW_PRODUCT_ATTRIBUTE,
  ADD_PRODUCT_ATTRIBUTE,
  SET_IS_PUBLIC,
  GET,
  GET_LITE,
  GET_V2,
  GET_BY_ID,
  DELETE,
  GET_PRODUCT_VARIATION,
  GET_NAME,
  CREATE_PRODUCT_INGREDIENT,
  GET_SIZE,
  CREATE_PRODUCT_SIZE,
  UPDATE_PRODUCT_SIZE,
  DELETE_PRODUCT_SIZE,
  GET_PRODUCT_SIZES,
  CREATE_PRODUCT,
  GET_PRODUCT_PRESENTATIONS,
  DELETE_PRESENTATION_PRODUCT,
  ADD_PRESENTATION,
  UPDATE_PRESENTATION,
  UPDATE_PRODUCT,
  DELETE_PRODUCT_INGREDIENT,
  ADD_PRODUCT_INGREDIENT,
  ADD_PRODUCT_VARIATION,
  FRAGMENT_CORE_PRODUCT_FIELDS,
  DELETE_FILE,
  UPDATE_PRODUCT_COMMENTARIES,
  UPDATE_FILE,
  FRAGMENT_NEW_PRODUCT_VARIATION,
  FRAGMENT_NEW_PRODUCT_PRESENTATION_VARIATION,
  FRAGMENT_NEW_PRODUCT_PRESENTATION,
  DELETE_PRESENTATION_FILE,
  UPDATE_PRODUCT_DEFAULT_PRESENTATION,
  UPDATE_PRODUCT_PRICE,
  GET_RATES,
  CREATE_RATE,
  GET_PRODUCT_PRICES_BY_ID,
  DELETE_PRODUCT_PRICE,
  GET_STANDAR_PRODUCT_PRICES_BY_ID,
  UPDATE_STANDAR_PRODUCT_PRICE,
  FRAGMENT_NEW_RATE,
  FRAGMENT_NEW_PRODUCT_PRICE,
  UPDATE_PRODUCT_STATUS,
  FRAGMENT_CORE_PRODUCTS_FIELDS,
  CLONE_PRODUCT_INGREDIENTS,
  FRAGMENT_PRODUCT_INGREDIENT,
};

export default GQL;
