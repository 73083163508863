import React from 'react';
import { ListItem, ListItemText } from '@mui/material';
import ListItemButton from './ListItemButton';
import { currencyFormat } from '../../../../../../../helpers';
var ItemTableFormDialogProductFieldComponent = function (props) {
    return (React.createElement(ListItemButton, { Product: props.Product, background: props.background },
        React.createElement(ListItem, null,
            React.createElement(ListItemText, { primary: props.Product.name, secondary: "Precio: $".concat(currencyFormat(props.Product.price), " | SKU: ").concat(props.Product.sku || '-', " | C\u00F3digo de barras: ").concat(props.Product.barcode || '-') }))));
};
export default ItemTableFormDialogProductFieldComponent;
