var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useEffect, useState } from 'react';
import { useReactiveVar } from '@apollo/client';
import { subMonths } from 'date-fns';
import { List } from '@mui/material';
import useApolloLazyQuery from '../../../../../../../hooks/useApolloLazyQuery';
import GQL from '../../../../../_gql';
import getInputErrorFormat from '../../../../../Action/helpers/getInputErrorFormat';
import LoadingProgress from '../../../../../../../presentationals/LoadingProgress';
import { customerSaleV2 } from '../../../../../../../apollo/cache';
import Item from './Item';
var HistorySaleHistoryBodyItemDetailDialogCartSaleV2Component = function (props) {
    var customerSaleV2Var = useReactiveVar(customerSaleV2);
    var _a = useState([]), data = _a[0], setData = _a[1];
    var _b = useApolloLazyQuery(GQL.GET_LAST_PRICE), getLastPrice = _b[0], loading = _b[1].loading;
    useEffect(function () {
        if (props.productId) {
            var f = function () { return __awaiter(void 0, void 0, void 0, function () {
                var start, data_1, e_1;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            _a.trys.push([0, 2, , 3]);
                            start = new Date(subMonths(new Date(), 6));
                            return [4 /*yield*/, getLastPrice({
                                    variables: {
                                        customerId: customerSaleV2Var.id ? customerSaleV2Var.id : null,
                                        productId: props.productId,
                                        order: ['clientCreatedAt', 'desc'],
                                        limit: 0,
                                        start: start,
                                    },
                                })];
                        case 1:
                            data_1 = (_a.sent()).data;
                            setData(data_1);
                            return [3 /*break*/, 3];
                        case 2:
                            e_1 = _a.sent();
                            getInputErrorFormat(e_1);
                            return [3 /*break*/, 3];
                        case 3: return [2 /*return*/];
                    }
                });
            }); };
            f();
        }
    }, [props.productId, customerSaleV2Var]);
    if (loading)
        return React.createElement(LoadingProgress, { noText: true });
    return (React.createElement("div", { className: 'flex flex-1 max-h-80 overflow-scroll' }, data.length > 0 && (React.createElement(List, { className: 'w-100' }, data.map(function (el) { return (React.createElement(Item, { key: el.id, date: el.clientCreatedAt, price: el.unitPrice, index: props.index })); })))));
};
export default HistorySaleHistoryBodyItemDetailDialogCartSaleV2Component;
