var getPlatformLabel = function (value) {
    switch (value) {
        case 1:
            return 'En tienda';
        case 2:
            return 'Ordene y recoja';
        case 3:
            return 'Domicilio';
        case 4:
            return 'Drive';
        case 5:
            return 'Didi food';
        case 6:
            return 'Rappi';
        case 7:
            return 'Uber eats';
        default:
            return 'Idenfinido';
    }
};
export default getPlatformLabel;
