import { Card } from '@mui/material';
import React from 'react';
import BranchActionSaleV2Component from '../Action/Branch';
import CurrencyActionSaleV2Component from '../Action/Currency';
import EmployeeActionSaleV2Component from '../Action/Employee';
import CalendarActionSaleV2Component from '../Action/Calendar';
import CommentaryFieldFooterSaleV2Component from './CommentaryField';
import TotalFooterSaleV2Component from './Total';
var FooterSaleV2Component = function () {
    return (React.createElement("div", { className: 'mt-1 md:mt-4 flex flex-col md:flex-row' },
        React.createElement("div", { className: 'flex py-1' },
            React.createElement(Card, { className: 'flex md:block md:w-full bg-white overflow-x-auto rounded md:overflow-hidden border-0 border-b-[3px] border-solid border-[#3f51b5] md:mr-4' },
                React.createElement(CurrencyActionSaleV2Component, null),
                React.createElement(BranchActionSaleV2Component, null),
                React.createElement(EmployeeActionSaleV2Component, null),
                React.createElement(CalendarActionSaleV2Component, null))),
        React.createElement("div", { className: 'flex flex-1' },
            React.createElement("div", { className: 'flex flex-1' },
                React.createElement(CommentaryFieldFooterSaleV2Component, null)),
            React.createElement("div", { className: 'ml-4' },
                React.createElement(TotalFooterSaleV2Component, null)))));
};
export default FooterSaleV2Component;
