import GET_CLASSIFICATION from './getClassification';
import GET_CLASSIFICATION_BY_ID from './getClassificationById';
import GET_CLASSIFICATION_FILE from './getClassificationFile';
import DELETE_CLASSIFICATION from './deleteClassification';
import CREATE_CLASSIFICATION from './createClassification';
import UPDATE_CLASSIFICATION from './updateClassification';
import UPDATE_CLASSIFICATION_FILE from './updateClassificationFile';
import FRAGMENT_CLASSIFICATION from './fragmentClassification';
var GQL_CLASSIFICATION = {
    GET_CLASSIFICATION: GET_CLASSIFICATION,
    GET_CLASSIFICATION_BY_ID: GET_CLASSIFICATION_BY_ID,
    GET_CLASSIFICATION_FILE: GET_CLASSIFICATION_FILE,
    DELETE_CLASSIFICATION: DELETE_CLASSIFICATION,
    CREATE_CLASSIFICATION: CREATE_CLASSIFICATION,
    UPDATE_CLASSIFICATION: UPDATE_CLASSIFICATION,
    UPDATE_CLASSIFICATION_FILE: UPDATE_CLASSIFICATION_FILE,
    FRAGMENT_CLASSIFICATION: FRAGMENT_CLASSIFICATION,
};
export default GQL_CLASSIFICATION;
