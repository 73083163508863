import React from 'react';
import { Typography } from '@mui/material';
import SelectFieldComponent from '../../../../presentationals/FormComponents/SelectField';
var MonthFieldComponent = function (props) {
    var handleChange = function (name, value) {
        props.onChange(name, value);
    };
    return (React.createElement("div", null,
        React.createElement(Typography, { variant: 'subtitle1' }, "Mes de nacimiento"),
        React.createElement("div", { className: 'flex flex-row' },
            React.createElement("div", { className: 'flex mr-2' },
                React.createElement(SelectFieldComponent, { name: 'birthMonth', value: props.value, options: [
                        { value: '-1', label: 'Cualquier mes' },
                        { value: '1', label: 'Enero' },
                        { value: '2', label: 'Febrero' },
                        { value: '3', label: 'Marzo' },
                        { value: '4', label: 'Abril' },
                        { value: '5', label: 'Mayo' },
                        { value: '6', label: 'Junio' },
                        { value: '7', label: 'Julio' },
                        { value: '8', label: 'Agosto' },
                        { value: '9', label: 'Septiembre' },
                        { value: '10', label: 'Octubre' },
                        { value: '11', label: 'Noviembre' },
                        { value: '12', label: 'Diciembre' },
                    ], onChange: handleChange })))));
};
export default MonthFieldComponent;
