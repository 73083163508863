import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import useApolloLazyQuery from './useApolloLazyQuery';
import GQL_SALE from '../apollo/gql/sale';
var useGetSaleByParamSaleId = function () {
    var params = useParams();
    var _a = useApolloLazyQuery(GQL_SALE.GET_SALE_BY_ID), get = _a[0], _b = _a[1], data = _b.data, loading = _b.loading;
    useEffect(function () {
        if (params.saleId)
            get({ variables: { id: params.saleId } });
    }, [params.saleId]);
    return { data: data, loading: loading };
};
export default useGetSaleByParamSaleId;
