import { useEffect, useState } from 'react';

const useGetSatPaymentForm = () => {
  const [formattedData, setFormattedData] = useState([]);

  useEffect(() => {
    const f = async () => {
      try {
        const response = await fetch(
          'https://raw.githubusercontent.com/bambucode/catalogos_sat_JSON/master/c_FormaPago.json',
        );
        const jsonResponse = await response.json();
        setFormattedData(
          jsonResponse.map(el => ({
            label: `${el.id} ${el.descripcion}`,
            value: el.id,
          })),
        );
      } catch (e) {
        console.log(e);
      }
    };
    f();
  }, []);

  return formattedData;
};

export default useGetSatPaymentForm;
