var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { forwardRef, useImperativeHandle, useState } from 'react';
import DialogComponent from '../../../../presentationals/Dialog';
import FooterDialogComponent from '../../../../presentationals/Dialog/Footer';
import CreatePurchaseOrderButtonComponent from './CreateButton';
import WhatWilllHappenPurchaseOrdenLabelComponent from './WhatWiilHappenLabel';
import BarcodeLabelPurchaseOrderComponent from './BarcodeLabel';
import PreviewGeneralSettingsCreatePurchaseOrderComponent from './GeneralSettings';
var PreviewCreatePurchaseOrderDialogComponent = forwardRef(function (props, ref) {
    var _a;
    var _b = useState(false), open = _b[0], setOpen = _b[1];
    var _c = useState(1), action = _c[0], setAction = _c[1];
    var _d = useState(null), inputs = _d[0], setInputs = _d[1];
    var handleOpen = function (values) {
        if (values.General)
            setAction(values.General.action);
        setOpen(true);
        setInputs(values);
    };
    var handleClose = function () {
        setOpen(false);
    };
    useImperativeHandle(ref, function () { return ({
        open: handleOpen,
    }); });
    if (!inputs)
        return React.createElement("div", null);
    return (React.createElement(DialogComponent, { open: open, fullScreen: true, title: 'Antes de terminar...', onClose: handleClose },
        React.createElement("div", { className: 'flex flex-1 flex-col' },
            React.createElement("div", { className: 'flex flex-1 px-4 py-3' },
                React.createElement("div", { className: 'flex flex-1 flex-col' },
                    React.createElement(WhatWilllHappenPurchaseOrdenLabelComponent, { action: action }),
                    inputs.General && (React.createElement(PreviewGeneralSettingsCreatePurchaseOrderComponent, __assign({}, inputs.General))),
                    React.createElement(BarcodeLabelPurchaseOrderComponent, { Products: inputs.Products || [] }))),
            React.createElement(FooterDialogComponent, null,
                React.createElement("div", null),
                React.createElement(CreatePurchaseOrderButtonComponent, { disabled: ((_a = inputs.Products) === null || _a === void 0 ? void 0 : _a.length) === 0, inputs: inputs })))));
});
export default PreviewCreatePurchaseOrderDialogComponent;
