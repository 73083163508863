import React, { useEffect, useState } from 'react';

import { useReactiveVar } from '@apollo/client';

import CreditCardIcon from '@mui/icons-material/CreditCardOutlined';

import { Avatar, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import { capitalize, currencyFormat } from '../../../../../../../helpers';
import { saleTotalToPay } from '../../../../../../../apollo/cache';

const ItemPaymentMethodPaymentFormBodyConfirmDialogSaleV2Component = props => {
  const saleTotalToPayVar = useReactiveVar(saleTotalToPay);
  const [item, setItem] = useState({});

  useEffect(() => {
    const currentItem = saleTotalToPayVar.find(el => parseInt(el.id) === parseInt(props.id));
    if (currentItem) setItem(currentItem);
  }, [saleTotalToPayVar]);

  const handleClick = () => {
    if (Object.keys(item).length > 0) {
      saleTotalToPay(
        saleTotalToPayVar.map(el => ({
          ...el,
          selected: parseInt(el.id) === parseInt(props.id),
        })),
      );
      return;
    }
    saleTotalToPay([
      ...saleTotalToPayVar.map(el => ({ ...el, selected: false })),
      { id: props.id, total: '', selected: true, code: props.code },
    ]);
  };
  return (
    <ListItem
      button
      className={`${props.index % 2 === 0 ? 'bg-gray-100' : ''} ${
        item.selected ? 'bg-gray-500' : ''
      } ${item.selected ? 'hover:bg-gray-500' : 'hover:bg-gray-200'} ${
        item.selected ? 'text-gray-200' : 'text-black'
      }`}
      onClick={handleClick}
    >
      <ListItemIcon>
        <Avatar>
          <CreditCardIcon />
        </Avatar>
      </ListItemIcon>
      <ListItemText
        primary={`$${currencyFormat(item.total || 0)}`}
        secondary={capitalize(props.name)}
        classes={{ secondary: item.selected ? 'text-gray-200' : 'text-black' }}
      />
    </ListItem>
  );
};

export default ItemPaymentMethodPaymentFormBodyConfirmDialogSaleV2Component;
