var convertTextToPlural = function (word, props) {
    var pluralRules = [
        [/$/, 's'],
        [/([aeiou])s$/i, '$1ses'],
        [/(r|l|n|d|j|z)$/i, '$1es'],
        [/([aeiou])z$/i, '$1ces'],
        [/(g)u$/i, '$1ües'],
    ];
    var newWord = pluralRules.reduce(function (newWord, _a) {
        var pattern = _a[0], wordReplaced = _a[1];
        if (word.match(pattern))
            return word.replace(pattern, wordReplaced);
        return newWord;
    }, '');
    return (props === null || props === void 0 ? void 0 : props.uppercase) ? (newWord || word).toUpperCase() : newWord || word;
};
export default convertTextToPlural;
