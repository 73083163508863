import React, { useEffect, useState } from 'react';
import { Tab, Tabs } from '@mui/material';
import { useReactiveVar } from '@apollo/client';
import setProductTypeFilterOption from './setProductTypeFilterOption';
import { filter, isTableScrollLoading } from '../../../apollo/cache';
import getTypeByProductTypeFilterOption from './getTypeByProductTypeFilterOption';
var ItemCategoriesProductComponent = function (props) {
    var isLoading = useReactiveVar(isTableScrollLoading);
    var _a = useState(0), value = _a[0], setValue = _a[1];
    useEffect(function () {
        var filterVar = filter();
        if (!isNaN(parseInt(filterVar.type)))
            setValue(getTypeByProductTypeFilterOption(filterVar.type));
    }, []);
    var handleChange = function (e, value) {
        setValue(value);
        setProductTypeFilterOption(value);
    };
    return (React.createElement(Tabs, { value: value, onChange: handleChange, variant: 'scrollable', scrollButtons: 'auto', className: 'mt-1 bg-[#fff] border border-[#d6d6d6] border-solid' },
        React.createElement(Tab, { disabled: isLoading || props.isLoading, label: 'Producto para venta directa' }),
        React.createElement(Tab, { disabled: isLoading || props.isLoading, label: 'Producto elaborado' }),
        React.createElement(Tab, { disabled: isLoading || props.isLoading, label: 'Insumos / materia prima' }),
        React.createElement(Tab, { disabled: isLoading || props.isLoading, label: 'Servicios' }),
        React.createElement(Tab, { disabled: isLoading || props.isLoading, label: 'Paquetes' })));
};
export default ItemCategoriesProductComponent;
