import React from 'react';
import useManufactures from '../../hooks/useManufactures';
import ModuleV3Component from '../../presentationals/Module.v3';
import RedirectCreateFormToRouteComponent from '../../presentationals/RedirectCreateFormToRoute';
import getManufactureActions from './helpers/actions';
var ManufactureComponent = function () {
    var _a = useManufactures(), data = _a.data, loading = _a.loading;
    var actions = getManufactureActions();
    var header = [
        { Header: 'Creado el', accessor: 'createdAt' },
        { Header: 'Mermas', accessor: 'waste' },
        { Header: 'Creado por', accessor: 'employee' },
        { Header: 'Autorizado por', accessor: 'authEmployee' },
        { Header: 'Estatus', accessor: 'status' },
    ];
    return (React.createElement(React.Fragment, null,
        React.createElement(ModuleV3Component, { actions: actions, title: 'Transformaci\u00F3n de productos', data: data, header: header, isLoading: loading, hideSearch: true }),
        React.createElement(RedirectCreateFormToRouteComponent, null)));
};
export default ManufactureComponent;
