import XLSX from 'xlsx';
import { format } from 'date-fns';
import rowFormat from '../../../presentationals/Module.v2/Table/Body/format';

export default (title, keys, data) => {
  const filename = `${title}_${format(new Date(), 'dd-MMM-yyyy_HH-mm')}.xlsx`;
  const wb = XLSX.utils.book_new();

  const header = keys.map(el => el.label);

  const ws = XLSX.utils.json_to_sheet([], { header, skipHeader: true });
  let total = 0;
  let totalUsd = 0;

  // ROJO
  let totalDanger = 0;
  let totalDangerUsd = 0;

  // AMARILLO
  let totalWarning = 0;
  let totalWarningUsd = 0;

  const tmp = Object.keys(data)
    .sort((a, b) => {
      if (data[a].customerName > data[b].customerName) return 1;
      if (data[a].customerName < data[b].customerName) return -1;
      return 0;
    })
    .reduce((array, keyCh) => {
      const response = data[keyCh].data.reduce((array, el) => {
        if (el.exchangeRate && parseFloat(el.exchangeRate) > 1)
          totalUsd += parseFloat(el.balance) / parseFloat(el.exchangeRate);

        if (el.background === 'danger') {
          totalDanger += parseFloat(el.balance);
          if (el.exchangeRate && parseFloat(el.exchangeRate) > 1)
            totalDangerUsd += parseFloat(el.balance) / parseFloat(el.exchangeRate);
        }

        if (el.background === 'warning') {
          totalWarning += parseFloat(el.balance);
          if (el.exchangeRate && parseFloat(el.exchangeRate) > 1)
            totalWarningUsd += parseFloat(el.balance) / parseFloat(el.exchangeRate);
        }
        const newObject = keys.reduce((newObject, { key, strcat, label, format: dataFormat }) => {
          let dataFormatFix = dataFormat;
          let value = key.includes(' ')
            ? key.includes(' && ')
              ? key.split(' && ').reduce((stringPincipal, elPincipal) => {
                  const res = elPincipal.split(' ').reduce((string, elCh) => {
                    const tmpData = string[elCh] || '-';
                    return tmpData;
                  }, el);
                  return stringPincipal !== '' ? `${stringPincipal}${strcat || ' '}${res}` : res;
                }, '')
              : key.split(' ').reduce((string, elCh) => {
                  const tmpData = string[elCh] || '-';
                  return tmpData;
                }, el)
            : el[key];
          if (dataFormat === 'currency') {
            dataFormatFix = null;
            value = !Number.isNaN(parseFloat(value)) ? parseFloat(value) : 0;
          }
          return { ...newObject, [label]: rowFormat(value, dataFormatFix) };
        }, {});
        return [...array, newObject];
      }, []);
      total += parseFloat(data[keyCh].balance);
      return [
        ...array,
        {
          NV: data[keyCh].customerName,
          'Fecha Emisión': '',
          'Fecha Limite': '',
          Vendedor: '',
          Cargo: data[keyCh].total,
          Abono: data[keyCh].payment,
          Saldo: data[keyCh].balance,
        },
        ...response,
      ];
    }, []);

  XLSX.utils.sheet_add_json(ws, tmp, { skipHeader: false, origin: 'A1' });

  const totalData = [
    { NV: 'Gran total MXN', 'Fecha Emisión': total },
    { NV: 'Gran total DLS', 'Fecha Emisión': totalUsd },
    { NV: '' },
    { NV: 'Por vencer MXN', 'Fecha Emisión': totalWarning },
    { NV: 'Por vencer DLS', 'Fecha Emisión': totalWarningUsd },
    { NV: 'Vencido MXN', 'Fecha Emisión': totalDanger },
    { NV: 'Vencido DLS', 'Fecha Emisión': totalDangerUsd },
  ];

  XLSX.utils.sheet_add_json(ws, totalData, { skipHeader: true, origin: `A${tmp.length + 3}` });

  XLSX.utils.book_append_sheet(wb, ws, title);
  XLSX.writeFile(wb, filename);
};
