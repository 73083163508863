import React, { useRef } from 'react';
import FooterDialogComponent from '../../../../presentationals/Dialog/Footer';
import BodyMainAdjustInventoryComponent from './Body';
import NextStepAdjustInventoryButtonComponent from './NextStepButton';
var MainComponent = function (props) {
    var bodyMainAdjustInventoryFormRef = useRef(null);
    var handleGetValues = function () { var _a; return (_a = bodyMainAdjustInventoryFormRef === null || bodyMainAdjustInventoryFormRef === void 0 ? void 0 : bodyMainAdjustInventoryFormRef.current) === null || _a === void 0 ? void 0 : _a.getValues(); };
    return (React.createElement("div", { className: 'flex flex-1 flex-col' },
        React.createElement(BodyMainAdjustInventoryComponent, { ref: bodyMainAdjustInventoryFormRef }),
        React.createElement(FooterDialogComponent, null,
            React.createElement("div", null),
            React.createElement(NextStepAdjustInventoryButtonComponent, { onContinue: props.onContinue, getValues: handleGetValues }))));
};
export default MainComponent;
