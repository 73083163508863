import React, { useEffect, useMemo, useState } from 'react';
import BodyModuleV3Component from '../../../presentationals/Module.v3/Body';
import { dateTimeFormat } from '../../../helpers';
import convertToCurrencyLabel from '../../../helpers/convertToCurrencyLabel';
import getTransactionStatus from './helpers/getTransactionStatus';
import RedirectEditFormToRouteComponent from '../../../presentationals/RedirectEditFormToRoute';
import getTransactionActions from './helpers/actions';
import getTransactionReportHeaderColumns from './helpers/getTransactionReportHeaderColumns';
import PDFCreatorComponent from '../../../presentationals/Module.v3/PDFCreator';
import XLSCreatorComponent from '../../../presentationals/Module.v3/XLSCreator';
var BodyTransactionReportComponent = function (props) {
    var _a = useState([]), data = _a[0], setData = _a[1];
    var title = 'Transacciones de ventas';
    var TransactionsActions = getTransactionActions();
    var Header = useMemo(function () { return getTransactionReportHeaderColumns(); }, [props.data]);
    useEffect(function () {
        if (props.data) {
            var data_1 = props.data.map(function (el) { return ({
                id: el.id,
                createdAt: dateTimeFormat(el.createdAt),
                clientCreatedAt: dateTimeFormat(el.clientCreatedAt, 'dd/MM/yyyy'),
                status: getTransactionStatus(el.status),
                saleFolio: el.saleFolio,
                invoiceComplementUuid: el.invoiceComplementUuid || '-',
                saleTotal: convertToCurrencyLabel(parseFloat(el.saleTotal)),
                paymentForm: el.paymentMethod ? el.paymentMethod.toUpperCase() : 'EFECTIVO',
                total: convertToCurrencyLabel(parseFloat(el.total)),
                customer: el.customer,
                transactionAccount: el.transactionAccount || '-',
                currency: el.currency,
            }); });
            setData(data_1);
        }
    }, [props.data]);
    return (React.createElement(React.Fragment, null,
        React.createElement(BodyModuleV3Component, { actions: TransactionsActions, isLoading: props.loading, header: Header, data: data }),
        React.createElement(RedirectEditFormToRouteComponent, null),
        React.createElement(PDFCreatorComponent, { title: title, isLoading: props.loading, header: Header, data: props.data }),
        React.createElement(XLSCreatorComponent, { title: title, isLoading: props.loading, header: Header, data: data })));
};
export default BodyTransactionReportComponent;
