var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useRef } from 'react';
import { Card } from '@mui/material';
import HeaderCreatePurchaseOrderComponent from './Header';
import FinishPurchaseOrderButtonComponent from './FinishButton';
import SearchProductPurchaseOrderFormComponent from './SearchProductForm';
import AddProductToCartButtonComponent from './AddProductToCartButton';
import CreatePurchaseOrderCartComponent from './Cart';
import PreviewCreatePurchaseOrderDialogComponent from './PreviewDialog';
var CreatePurchaseOrderComponent = function () {
    var previewCreatePurchaseOrderDialogRef = useRef(null);
    var headerCreatePurchaseOrderRef = useRef(null);
    var searchProductPurchaseOrderFormRef = useRef(null);
    var createPurchaseOrderCartRef = useRef(null);
    var handleValidateProductToCart = function () { var _a; return (_a = searchProductPurchaseOrderFormRef.current) === null || _a === void 0 ? void 0 : _a.getValues(); };
    var handleAddProductToCart = function (values) {
        var _a, _b;
        (_a = searchProductPurchaseOrderFormRef.current) === null || _a === void 0 ? void 0 : _a.clearForm();
        return (_b = createPurchaseOrderCartRef.current) === null || _b === void 0 ? void 0 : _b.addProduct(values);
    };
    var hangleGetSettings = function () { var _a; return (_a = headerCreatePurchaseOrderRef.current) === null || _a === void 0 ? void 0 : _a.getValues(); };
    var handleSettingsChanges = function (partialSettings) {
        var _a, _b, _c;
        (_a = createPurchaseOrderCartRef.current) === null || _a === void 0 ? void 0 : _a.totalizeCart(partialSettings);
        if ((_b = partialSettings.General) === null || _b === void 0 ? void 0 : _b.action)
            (_c = searchProductPurchaseOrderFormRef.current) === null || _c === void 0 ? void 0 : _c.getAction(partialSettings.General.action);
    };
    var handleClick = function () {
        var _a, _b, _c;
        return (_a = previewCreatePurchaseOrderDialogRef.current) === null || _a === void 0 ? void 0 : _a.open(__assign(__assign({}, (_b = headerCreatePurchaseOrderRef.current) === null || _b === void 0 ? void 0 : _b.getValues()), { Products: (_c = createPurchaseOrderCartRef.current) === null || _c === void 0 ? void 0 : _c.getCart() }));
    };
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: 'flex flex-1 flex-col basis-0 overflow-auto px-4 py-3' },
            React.createElement("div", { className: 'flex flex-col-reverse sm:flex-row ' },
                React.createElement(HeaderCreatePurchaseOrderComponent, { onChange: handleSettingsChanges, ref: headerCreatePurchaseOrderRef }),
                React.createElement(FinishPurchaseOrderButtonComponent, { onClick: handleClick })),
            React.createElement("div", { className: 'flex-1 flex mt-2 flex-col sm:grid sm:grid-cols-3 sm:gap-3 sm:flex-row' },
                React.createElement(Card, { className: 'flex flex-1 flex-col px-2' },
                    React.createElement("div", { className: 'flex sm:flex-1' },
                        React.createElement("div", { className: 'w-full sm:h-full sm:relative sm:overflow-auto' },
                            React.createElement(SearchProductPurchaseOrderFormComponent, { isPositionAbsolute: true, ref: searchProductPurchaseOrderFormRef }))),
                    React.createElement("div", { className: 'flex pb-3 pt-1' },
                        React.createElement(AddProductToCartButtonComponent, { onClick: handleValidateProductToCart, addProductToCart: handleAddProductToCart }))),
                React.createElement(Card, { className: 'flex flex-1 mt-2 sm:col-span-2 sm:mt-0' },
                    React.createElement(CreatePurchaseOrderCartComponent, { getSettings: hangleGetSettings, ref: createPurchaseOrderCartRef })))),
        React.createElement(PreviewCreatePurchaseOrderDialogComponent, { ref: previewCreatePurchaseOrderDialogRef })));
};
export default CreatePurchaseOrderComponent;
