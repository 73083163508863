const getProductToCheckCart = cartItems =>
  cartItems.map(el => ({
    price: parseFloat(el.price) || parseFloat(el.unitPrice),
    quantity: parseFloat(el.quantity),
    cost: parseFloat(el.cost),
    discount: parseFloat(el.discountRate),
    commentary: el.commentary,
    productId: el.productId,
    employeeId: el.employeeId,
  }));

export default getProductToCheckCart;
