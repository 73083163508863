var getDefaultProductPresentationValues = function () {
    return {
        name: '',
        forPursache: true,
        forSale: true,
        forRequisition: true,
        quantityInBaseUnit: '',
        price: '',
        uberPrice: '',
        didiPrice: '',
        rappiPrice: '',
        shippingPrice: '',
        pickupPrice: '',
        limit: '',
        printer: -1,
        isDifferentPrices: false,
    };
};
export default getDefaultProductPresentationValues;
