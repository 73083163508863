import XLSX from 'xlsx';
import { format } from 'date-fns';
import locale from 'date-fns/locale/es';

const xls = data => {
  const nowDate = format(new Date(), 'dd-MMM-yyyy', { locale }).toUpperCase();
  const filename = `ROTACION_TRASPASOS_${nowDate}.xlsx`;
  const firstHeader = [
    'PRODUCTO',
    'CLASIFICACIÓN',
    'UNIDAD',
    ...data[0].TransferProducts.reduce((array, el) => (
      [...array, el.Branch.name.toUpperCase(), '', '']
    ), []),
  ];
  const secondHeader = [
    '',
    '',
    '',
    ...data[0].TransferProducts.reduce(array => (
      [...array, 'Cant.', 'CostUnit.', 'CostTot.']
    ), []),
  ];
  let index = 0;
  const merge = [
    { s: { r: 0, c: 0 }, e: { r: 1, c: 0 } },
    { s: { r: 0, c: 1 }, e: { r: 1, c: 1 } },
    { s: { r: 0, c: 2 }, e: { r: 1, c: 2 } },
    ...data[0].TransferProducts.reduce(array => {
      const tmp1 = index + 3;
      const tmp2 = index + 5;
      index += 3;
      return [
        ...array,
        { s: { r: 0, c: tmp1 }, e: { r: 0, c: tmp2 } },
      ];
    }, []),
  ];

  const dataRows = data.map(el => {
    const totals = el.TransferProducts.reduce((array, tmp) => (
      [...array, parseFloat(tmp.quantity), parseFloat(tmp.unitCost), parseFloat(tmp.cost)]
    ), []);
    return [
      el.Product ? el.Product.name.toUpperCase() : '',
      el.Product
        ? el.Product.Classification
          ? el.Product.Classification.name.toUpperCase()
          : ''
        : '',
      el.Product
        ? el.Product.UnitBase
          ? el.Product.UnitBase.name.toUpperCase()
          : ''
        : '',
      ...totals,
    ];
  });

  const rows = [
    firstHeader,
    secondHeader,
    ...dataRows,
  ];

  const ws = XLSX.utils.aoa_to_sheet(rows);
  if (!ws['!merges']) ws['!merges'] = [];
  ws['!merges'] = merge;
  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, `ROTACION_TRASPASOS_${nowDate}`);
  XLSX.writeFile(wb, filename);
};

export default xls;
