import React from 'react';

import PaymentForm from './PaymentForm';
import SummaryForm from './SummaryForm';

const BodyConfirmDialogSaleV2Component = () => {
  return (
    <div className='grid grid-cols-1 md:grid-cols-4 gap-4 flex-1 px-4  py-0'>
      <div className='h-full flex-1 flex'>
        <PaymentForm />
      </div>
      <div className='col-span-3 h-full hidden md:flex flex-1 '>
        <SummaryForm />
      </div>
    </div>
  );
};

export default BodyConfirmDialogSaleV2Component;
