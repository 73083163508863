import { useReactiveVar } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import { inputError } from '../../../apollo/cache';
import getCurrencyOptions from './helpers/getCurrencyOptions';
import SelectFieldComponent from '../SelectField';
var SelectCurrencyFieldComponent = function (props) {
    var errors = useReactiveVar(inputError);
    var _a = useState(false), isDefaultValueApplied = _a[0], setIsDefaultValueApplied = _a[1];
    var _b = useState(''), value = _b[0], setValue = _b[1];
    var options = getCurrencyOptions();
    useEffect(function () {
        if (props.defaultValue && options.length > 0 && !isDefaultValueApplied) {
            var isInArray = options.find(function (el) { return el.value === props.defaultValue; });
            if (isInArray)
                setValue(props.defaultValue);
            setIsDefaultValueApplied(true);
        }
    }, [isDefaultValueApplied, options, props.defaultValue]);
    var handleChange = function (name, value) {
        setValue(value);
        props.onChange(name, value);
    };
    return (React.createElement(SelectFieldComponent, { name: 'currency', label: 'Moneda', onChange: handleChange, value: value, error: errors.currency, options: options }));
};
export default SelectCurrencyFieldComponent;
