import React, { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import DialogComponent from '../../../../../../presentationals/Dialog';
import BodyGeneralCreatePurchaseOrderDialogComponent from './Body';
import ActionGeneralCreatePurchaseOrderButtonComponent from './ActionButton';
import FooterDialogComponent from '../../../../../../presentationals/Dialog/Footer';
var GeneralCreatePurchaseOrderDialogComponent = forwardRef(function (props, ref) {
    var bodyGeneralCreatePurchaseOrderDialogRef = useRef(null);
    var _a = useState(false), open = _a[0], setOpen = _a[1];
    var handleClose = function () {
        setOpen(false);
    };
    var handleAction = function (values) {
        props.getValues(values);
        props.onChange({ General: values });
        handleClose();
    };
    var handleOpen = function () {
        setOpen(true);
    };
    var handleGetValues = function () { var _a; return (_a = bodyGeneralCreatePurchaseOrderDialogRef.current) === null || _a === void 0 ? void 0 : _a.getValues(); };
    useImperativeHandle(ref, function () { return ({
        open: handleOpen,
    }); });
    return (React.createElement(DialogComponent, { title: 'Configuraci\u00F3n general', fullScreen: true, open: open, onClose: handleClose },
        React.createElement(BodyGeneralCreatePurchaseOrderDialogComponent, { defaultValues: props.defaultValues, ref: bodyGeneralCreatePurchaseOrderDialogRef }),
        React.createElement(FooterDialogComponent, null,
            React.createElement("div", null),
            React.createElement(ActionGeneralCreatePurchaseOrderButtonComponent, { getValues: handleGetValues, onClick: handleAction }))));
});
export default GeneralCreatePurchaseOrderDialogComponent;
