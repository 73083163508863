import CREATE_INVOICE_COMPLEMENT from './createInvoiceComplement';
import CREATE_PAYMENT_RELATED_SALE from './createPaymentRealedSale';
import FRAGMENT_ACCOUNT_RECEIVABLE from './fragmentAccountReceivable';
import GET_ACCOUNT_RECEIVABLE_BY_ID from './getAccountReceivableById';
import GET_ACCOUNT_RECEIVABLES from './getAccountReceivables';
import GET_CUSTOMER_ACCOUNT_RECEIVABLES from './getCustomerAccountReceivables';
import GET_CUSTOMER_TRANSACTIONS from './getCustomerTransactions';
import UPDATE_ACCOUNT_RECEIVABLE from './updateAccountReceivable';
var GQL_ACCOUNT_RECEIVABLE = {
    CREATE_INVOICE_COMPLEMENT: CREATE_INVOICE_COMPLEMENT,
    CREATE_PAYMENT_RELATED_SALE: CREATE_PAYMENT_RELATED_SALE,
    FRAGMENT_ACCOUNT_RECEIVABLE: FRAGMENT_ACCOUNT_RECEIVABLE,
    GET_ACCOUNT_RECEIVABLE_BY_ID: GET_ACCOUNT_RECEIVABLE_BY_ID,
    GET_ACCOUNT_RECEIVABLES: GET_ACCOUNT_RECEIVABLES,
    GET_CUSTOMER_ACCOUNT_RECEIVABLES: GET_CUSTOMER_ACCOUNT_RECEIVABLES,
    GET_CUSTOMER_TRANSACTIONS: GET_CUSTOMER_TRANSACTIONS,
    UPDATE_ACCOUNT_RECEIVABLE: UPDATE_ACCOUNT_RECEIVABLE,
};
export default GQL_ACCOUNT_RECEIVABLE;
