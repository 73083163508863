import React, { useRef } from 'react';
import FooterDialogComponent from '../../../../../presentationals/Dialog/Footer';
import ClearButtonFooterCreateProductDialogProductFormChooseProductSaleV2Component from './Footer/ClearButton';
import CreateButtonFooterCreateFormProductActionSaleV2Component from './Footer/CreateButton';
import BodyCreateProductDialogProductFormChooseProductSaleV2Component from './Body';
import DeleteButtonFooterCreateProductDialogProductFormChooseProductSaleV2Component from './Footer/DeleteButton';
import SearchDialogUnitKeyFieldProductComponent from './Body/helpers/UnitKeyField/SearchDialog';
import SearchDialogProductKeyFieldProductComponent from './Body/helpers/ProductKeyField/SearchDialog';
var CreateProductDialogProductFormChooseProductSaleV2Component = function (props) {
    var createButtonFooterCreateFormProductActionSaleV2Ref = useRef(null);
    var handleCreate = function () {
        var _a;
        (_a = createButtonFooterCreateFormProductActionSaleV2Ref.current) === null || _a === void 0 ? void 0 : _a.onPressEnter();
    };
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: 'flex flex-1 basis-0 flex-col overflow-auto' },
            React.createElement(BodyCreateProductDialogProductFormChooseProductSaleV2Component, { onPressEnter: handleCreate }),
            React.createElement(FooterDialogComponent, null,
                React.createElement("div", null,
                    React.createElement(DeleteButtonFooterCreateProductDialogProductFormChooseProductSaleV2Component, null)),
                React.createElement("div", null,
                    React.createElement(ClearButtonFooterCreateProductDialogProductFormChooseProductSaleV2Component, null),
                    React.createElement(CreateButtonFooterCreateFormProductActionSaleV2Component, { ref: createButtonFooterCreateFormProductActionSaleV2Ref, noReturn: props.noReturn })))),
        React.createElement(SearchDialogUnitKeyFieldProductComponent, null),
        React.createElement(SearchDialogProductKeyFieldProductComponent, null)));
};
export default CreateProductDialogProductFormChooseProductSaleV2Component;
