import React, { useEffect, useState } from 'react';
import { TableCell, TableFooter, TableRow } from '@mui/material';
import convertToCurrencyLabel from '../../../../helpers/convertToCurrencyLabel';
var ProductHistoryTableFooterComponent = function (props) {
    var _a = useState([0, 0, 0]), totals = _a[0], setTotals = _a[1];
    useEffect(function () {
        if (props.data) {
            var totals_1 = props.data.reduce(function (array, el) {
                if (el.input)
                    return [array[0] + el.input, array[1], array[2]];
                if (el.output)
                    return [array[0], array[1] + el.output, array[2]];
                return array;
            }, [0, 0, 0]);
            setTotals([
                totals_1[0],
                totals_1[1],
                props.data.length > 0
                    ? props.data[props.data.length - 1].balance +
                        props.data[props.data.length - 1].output -
                        props.data[props.data.length - 1].input
                    : 0,
            ]);
        }
    }, [props.data]);
    return (React.createElement(TableFooter, { className: 'sticky bottom-0 left-0 bg-white' },
        React.createElement(TableRow, null,
            React.createElement(TableCell, { colSpan: 3 }),
            React.createElement(TableCell, { className: 'bg-slate-300 leading-none text-base font-medium' }, convertToCurrencyLabel(totals[0])),
            React.createElement(TableCell, { className: 'bg-slate-300 leading-none text-base font-medium' }, convertToCurrencyLabel(totals[1])),
            React.createElement(TableCell, { className: 'bg-slate-400' },
                React.createElement("div", { className: 'leading-none' }, "Saldo inicial"),
                React.createElement("div", { className: 'leading-none text-base font-medium' }, convertToCurrencyLabel(totals[2]))))));
};
export default ProductHistoryTableFooterComponent;
