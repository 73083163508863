import React from 'react';
import ModuleV3Component from '../../presentationals/Module.v3';
import useGetProviderData from './helpers/useGetProviderData';
import RedirectCreateFormToRouteComponent from '../../presentationals/RedirectCreateFormToRoute';
import RedirectEditFormToRouteComponent from '../../presentationals/RedirectEditFormToRoute';
var ProviderComponent = function () {
    var _a = useGetProviderData({ getByFilter: true }), data = _a.data, loading = _a.loading;
    var header = [
        { Header: 'Nombre comercial', accessor: 'name' },
        { Header: 'Razón social', accessor: 'businessName' },
        { Header: 'RFC', accessor: 'identifier' },
        { Header: 'Teléfono', accessor: 'phone' },
        { Header: 'Correo electrónico', accessor: 'email' },
        { Header: 'Domicilio', accessor: 'address' },
        { Header: 'Días de crédito', accessor: 'creditDays' },
    ];
    return (React.createElement(React.Fragment, null,
        React.createElement(ModuleV3Component, { title: 'Proveedores', data: data, header: header, isLoading: loading }),
        React.createElement(RedirectCreateFormToRouteComponent, null),
        React.createElement(RedirectEditFormToRouteComponent, null)));
};
export default ProviderComponent;
