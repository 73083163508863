import React from 'react';

import Container from '../../presentationals/Module.v2/Container';

import Action from './_action';
import Filter from './_filter';
import { customerLabel } from '../../helpers';

import GQL from './_gql';

const customerVar = customerLabel({ textTransform: 'capitalize' });

const Rows = [
  { label: 'Fecha', key: 'createdAt', sort: 'text', format: 'dateTime' },
  {
    label: 'NV',
    key: 'folio',
    filter: false,
  },
  { label: customerVar, key: 'customer', filter: true },
  { label: 'COD', key: 'code', filter: true },
  { label: 'Producto', key: 'product', filter: true },
  { label: 'Sesiones', key: 'sessions', sort: 'text', filter: true, align: 'center' },
  { label: 'Tomadas', key: 'sessionsTaken', sort: 'text', filter: true, align: 'center' },
  { label: 'Precio', key: 'price', format: 'currency' },
  { label: 'Total de Venta', key: 'total', format: 'currency' },
  { label: 'Abonado', key: 'payment', format: 'currency', ifNull: '0' },
];
const title = 'Seguimiento de clientes';
const operation = ['crm', 'crms'];

const ClassificationComponent = () => {
  return (
    <>
      <Container
        title={title}
        operation={operation}
        Rows={Rows}
        delete={GQL.DELETE}
        get={GQL.GET}
        filter
      />
      <Action />
      <Filter />
    </>
  );
};

export default ClassificationComponent;
