import React, { useEffect, useState } from 'react';

import PrintIcon from '@mui/icons-material/Print';
import ListAltIcon from '@mui/icons-material/ListAltOutlined';
import LockIcon from '@mui/icons-material/LockOutlined';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import DescriptionIcon from '@mui/icons-material/DescriptionOutlined';
import LocalAtmOutlinedIcon from '@mui/icons-material/LocalAtmOutlined';

import { actions } from '../../apollo/cache';

import Ticket from '../sale.report.v2/_pdf';
import CancelForm from '../sale.report.v2/cancel.form';
import ProductDialog from './product.dialog.v2';
import PaymentDialog from './payment.dialog';
import ExpensesDialog from './expenses.dialog';
// import XLS from './xls.products';
import CONFIG from '../../config/config.json';
import getSession from '../../helpers/getSession';

const _permissions = localStorage.getItem('permissions')
  ? JSON.parse(localStorage.getItem('permissions'))
  : null;
const ServiceOrderPermission =
  _permissions && _permissions.ServiceOrder ? _permissions.ServiceOrder : null;
const SaleReportPermission = _permissions ? _permissions.SaleReport : null;

const ActionComponent = () => {
  const [isShoppingCartOpen, setIsShoppingCartOpen] = useState([false, null]);
  const [isOpen, setIsOpen] = useState([false, null]);
  const [isOpenExpenses, setIsOpenExpenses] = useState([false, null]);

  const handleTicket = (e, { id }) => {
    const session = getSession();

    parseInt(session?.Company?.id) === 71
      ? window.open(`${CONFIG['restServer'].uri}/files/serviceOrders/${id}`)
      : Ticket(id);
  };

  const handleOpenProducts = (e, params) => {
    setIsShoppingCartOpen([
      true,
      { saleId: params.id, folio: params.folio, serial: params.serial },
      params.status,
    ]);
  };
  const handleCloseShoppingCart = () => setIsShoppingCartOpen([false, null]);
  const handleClose = () => setIsOpen([false, null]);
  const handleFormCloseOrder = (e, { id }) => setIsOpen([true, id]);
  const [isOpenCancelSale, setIsOpenCancelSale] = useState([false, null]);
  const handleCancel = (e, { id }) => setIsOpenCancelSale([true, id]);
  const handleCloseCancel = () => setIsOpenCancelSale([false, null]);

  const handleProductsXls = (e, { id }) =>
    window.open(`${CONFIG['restServer'].uri}/files/serviceOrders/${id}/products/xlsx`);

  const handleOpenExpenses = (e, { id }) => setIsOpenExpenses([true, id]);
  const handleCloseExpenses = () => setIsOpenExpenses([false, null]);

  useEffect(() => {
    const closeServiceOrder =
      !ServiceOrderPermission || (ServiceOrderPermission && ServiceOrderPermission.confirm)
        ? [
            {
              icon: <LockIcon />,
              label: 'Cerrar Orden de Servicio',
              onClick: handleFormCloseOrder,
              params: { toStatus: 3 },
              disable: { key: 'status', value: [3, '3'] },
            },
          ]
        : [];
    actions([
      {
        icon: <DescriptionIcon />,
        label: 'Exportar Productos a xls',
        onClick: handleProductsXls,
      },
      {
        icon: <PrintIcon />,
        label: 'Imprimir Documento',
        onClick: handleTicket,
      },
      {
        icon: <ListAltIcon />,
        label: 'Editar Productos',
        onClick: handleOpenProducts,
        disable: { key: 'status', value: [3, '3'] },
      },
      {
        icon: <LocalAtmOutlinedIcon />,
        label: 'Editar Gastos Operativos',
        onClick: handleOpenExpenses,
        // disable: { key: 'status', value: [3, '3'] },
      },
      ...closeServiceOrder,
      (!SaleReportPermission || (SaleReportPermission && SaleReportPermission.delete)) && {
        icon: <DeleteOutlineIcon />,
        label: 'Cancelar Orden',
        onClick: handleCancel,
      },
    ]);
  }, []);

  return (
    <>
      <PaymentDialog isOpen={isOpen[0]} id={isOpen[1]} handleClose={handleClose} />
      <ProductDialog
        isOpen={isShoppingCartOpen[0]}
        handleClose={handleCloseShoppingCart}
        params={isShoppingCartOpen[1]}
        id={isShoppingCartOpen[1] && isShoppingCartOpen[1].saleId}
        status={isShoppingCartOpen[2]}
      />
      <ExpensesDialog
        isOpen={isOpenExpenses[0]}
        handleClose={handleCloseExpenses}
        id={isOpenExpenses[1]}
        // status={isShoppingCartOpen[2]}
      />
      <CancelForm
        isOpen={isOpenCancelSale[0]}
        handleClose={handleCloseCancel}
        saleId={isOpenCancelSale[1]}
      />
    </>
  );
};

export default ActionComponent;
