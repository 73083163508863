import { gql } from '@apollo/client';
import FRAGMENT_BRANCH_SESSION from '../../../../apollo/gql/session/fragmentBranchSession';

const FRAGMENT_NOTIFICATION = gql`
  fragment NewNotification on Notification {
    id
    dataId
    from
    type
    title
    message
    status
    createdAt
    saleId
    customerId
  }
`;

const GET_BRANCH_SESSION = gql`
  ${FRAGMENT_BRANCH_SESSION}
  query BranchesSession {
    branchesSession {
      ...FragmentBranchSession
    }
  }
`;

const SUBSCRIBE_TO_ERP = gql`
  subscription SubscribeToErp($wsToken: String!) {
    subscribeToErp(wsToken: $wsToken) {
      dataId
      type
    }
  }
`;

const GENERATE_WS_TOKEN = gql`
  query GenerateWsToken {
    generateWsToken
  }
`;

const GET_NOTIFICATIONS = gql`
  ${FRAGMENT_NOTIFICATION}
  query Notifications($status: Int) {
    notifications(status: $status) {
      count
      rows {
        ...NewNotification
      }
    }
  }
`;

const DELETE_NOTIFICATION = gql`
  mutation DeleteNotification($id: ID!) {
    deleteNotification(id: $id) {
      id
    }
  }
`;

const VIEW_ALL_NOTIFICATIONS = gql`
  mutation ViewedAllNotification {
    viewedAllNotification
  }
`;

const object = {
  VIEW_ALL_NOTIFICATIONS,
  GET_BRANCH_SESSION,
  SUBSCRIBE_TO_ERP,
  FRAGMENT_NOTIFICATION,
  GENERATE_WS_TOKEN,
  GET_NOTIFICATIONS,
  DELETE_NOTIFICATION,
};
export default object;
