import React, { useEffect, useState } from 'react';
import { NativeSelect } from '@mui/material';
import setSelectWidthByValue from './helpers/setSelectWidthByValue';
var SelectSearchInputComponent = function (props) {
    var _a = useState(''), value = _a[0], setValue = _a[1];
    var _b = useState('auto'), selectWidth = _b[0], setSelectWidth = _b[1];
    useEffect(function () {
        var defaultValue = localStorage.getItem(props.name) || props.defaultValue;
        var option = props.options.find(function (el) { return el.value === defaultValue; });
        if (option) {
            var textWidth = setSelectWidthByValue(option.label);
            setSelectWidth(textWidth);
        }
        setValue(defaultValue);
    }, [props.options]);
    var handleChange = function (e) {
        setValue(e.target.value);
        var option = props.options.find(function (el) { return el.value === e.target.value; });
        if (option) {
            var textWidth = setSelectWidthByValue(option.label);
            setSelectWidth(textWidth);
        }
        localStorage.setItem(props.name, e.target.value);
        props.onClick();
    };
    return (React.createElement("div", { className: 'flex flex-col min-w-[70px] relative mx-1', style: {
            width: selectWidth,
        } },
        React.createElement("div", { className: 'text-xs text-gray-500 p-0 m-0 absolute' }, "Buscar por:"),
        React.createElement(NativeSelect, { onChange: handleChange, className: 'text-gray-300 text-sm', inputProps: {
                IconComponent: function () { return null; },
                style: {
                    position: 'absolute',
                    top: 12,
                    margin: 0,
                    padding: 0,
                    backgroundColor: 'transparent',
                },
            }, disableUnderline: true, value: value }, props.options.map(function (el) { return (React.createElement("option", { key: el.value, value: el.value }, el.label)); }))));
};
export default SelectSearchInputComponent;
