import React from 'react';
import useApolloQuery from '../../../../../../hooks/useApolloQuery';
import GQL_BRANCH from '../../../../../../apollo/gql/branch';
import { capitalize } from '../../../../../../helpers';
var ItemTableBranchComponent = function (props) {
    var _a = useApolloQuery(GQL_BRANCH.GET_BRANCH_BY_ID, {
        variables: { id: props.id },
    }), data = _a.data, loading = _a.loading;
    var handleClick = function () {
        props.onClick(props.id);
    };
    return (React.createElement("div", { role: 'button', tabIndex: -1, onClick: handleClick, className: "".concat(props.index % 2 === 0 ? 'bg-slate-100' : 'bg-white', " px-3 py-2 cursor-pointer hover:bg-slate-200") },
        React.createElement("div", null, loading ? 'Cargando...' : data ? capitalize(data.name) : 'Todas las sucursales')));
};
export default ItemTableBranchComponent;
