import React from 'react';
import ColorQueueManagerCompanyComponent from './Color';
import LogoQueueManagerCompanyComponent from './Logo';
import DigitalContentQueueManagerCompanyComponent from './DigitalContent';
import NotificationsQueueManagerCompanyComponent from './Notifications';
import GQL_CALENDAR_QUEUE_MANAGER from '../../../../apollo/gql/calendarQueueManager';
import useApolloQuery from '../../../../hooks/useApolloQuery';
import LoadingProgress from '../../../../presentationals/LoadingProgress';
import ErrorDataComponent from '../../../../presentationals/ErrorData';
var QueueManagerCompanyComponent = function () {
    var _a = useApolloQuery(GQL_CALENDAR_QUEUE_MANAGER.GET_CALENDAR_QUEUE_MANAGER), data = _a.data, loading = _a.loading;
    if (loading)
        return React.createElement(LoadingProgress, null);
    if (!data)
        return React.createElement(ErrorDataComponent, null);
    return (React.createElement("div", { className: 'flex flex-1 px-4 py-3 flex-col basis-0 overflow-auto' },
        React.createElement("div", { className: 'text-base font-medium mb-1 flex' }, "Gesti\u00F3n de colas"),
        React.createElement("div", { className: 'text-sm sm:text-base text-gray-600 mb-2' },
            "Nuestro sistema de gesti\u00F3n de colas permite un enfoque m\u00E1s organizado y eficiente para atender a los clientes. Al asignar turnos y mostrar informaci\u00F3n en pantallas se reduce la confusi\u00F3n y la incertidumbre sobre cu\u00E1ndo ser\u00E1n atendidos.",
            React.createElement("br", null),
            "En esta secci\u00F3n podr\u00E1s configurar desde los colores hasta la informaci\u00F3n importante que se requiera compartir con el cliente."),
        React.createElement("div", { className: 'flex flex-1 flex-col rounded bg-slate-200 p-4' },
            React.createElement("div", { className: 'flex flex-1 flex-col rounded bg-slate-50 p-4' },
                React.createElement(LogoQueueManagerCompanyComponent, { fileId: data.logoFileId }),
                React.createElement(ColorQueueManagerCompanyComponent, { Colors: data.colors }),
                React.createElement(DigitalContentQueueManagerCompanyComponent, { digitalContentTimer: data.digitalContentTimer, digitalContentAvailability: data.digitalContentAvailability, DigitalContent: data.digitalContent }),
                React.createElement(NotificationsQueueManagerCompanyComponent, { Notifications: data.notifications })))));
};
export default QueueManagerCompanyComponent;
