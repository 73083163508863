var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { TableCell, TableRow } from '@mui/material';
import ProductSubtitleLabelItemCartCreatePurchaseOrderComponent from './ProductSubtitleLabel';
import convertToCurrencyLabel from '../../../../../helpers/convertToCurrencyLabel';
import ProductPriceLabelItemCartCreatePurchaseOrderComponent from './ProductPriceLabel';
var ItemCartCreatePurchaseOrderComponent = function (props) {
    var handleClick = function () {
        var onClick = props.onClick, showTaxes = props.showTaxes, createPurchaseOrderCartItem = __rest(props, ["onClick", "showTaxes"]);
        props.onClick(__assign(__assign({}, createPurchaseOrderCartItem), { index: props.index }));
    };
    return (React.createElement(TableRow, { onClick: handleClick, className: 'cursor-pointer' },
        React.createElement(TableCell, null,
            React.createElement("div", null,
                props.Product.name,
                " ",
                props.ProductPresentation.name),
            React.createElement(ProductSubtitleLabelItemCartCreatePurchaseOrderComponent, { sku: props.Product.sku, barcode: props.ProductPresentation.barcode }),
            React.createElement(ProductPriceLabelItemCartCreatePurchaseOrderComponent, { percentAdjust: props.percentAdjust, salePrice: props.salePrice, updateSalePrice: props.updateSalePrice })),
        React.createElement(TableCell, null, convertToCurrencyLabel(props.quantity)),
        React.createElement(TableCell, null, convertToCurrencyLabel(props.showTaxes ? props.withoutTaxes : props.price)),
        props.discount > 0 && (React.createElement(TableCell, null, props.discountType === 1
            ? "".concat(props.discount, "%")
            : "$".concat(convertToCurrencyLabel(props.discount)))),
        props.showTaxes && React.createElement(TableCell, null, convertToCurrencyLabel(props.taxes)),
        React.createElement(TableCell, null, convertToCurrencyLabel(props.total))));
};
export default ItemCartCreatePurchaseOrderComponent;
