import React from 'react';
import ImageComponent from './Item';
import UploadButtonImageProductComponent from './UploadButton';
import DeleteButtonImageProductComponent from './DeleteButton';
import useGetItemProduct from '../helpers/useGetItemProduct';
import LoadingProgress from '../../../../presentationals/LoadingProgress';
var ImageProductComponent = function () {
    var _a = useGetItemProduct(), data = _a.data, loading = _a.loading;
    if (loading || !data)
        return React.createElement(LoadingProgress, null);
    return (React.createElement("div", { className: 'flex flex-1 px-4 py-3 flex-col ' },
        React.createElement("div", { className: 'text-base font-medium mb-1 flex' }, "Im\u00E1genes del producto"),
        React.createElement("div", { className: 'text-sm sm:text-base text-gray-600 mb-2' },
            "Las im\u00E1genes son una pieza fundamental al presentar un producto. A trav\u00E9s de ellas, los clientes pueden visualizar y conectar de manera directa con lo que est\u00E1n adquiriendo. En caso de que el producto cuente con diferentes presentaciones, aseg\u00FArate de configurar las im\u00E1genes correspondientes para cada una de ellas en la secci\u00F3n de",
            ' ',
            React.createElement("strong", null, "Estructura"),
            ". De lo contrario, se utilizar\u00E1n las im\u00E1genes predeterminadas."),
        React.createElement("div", null,
            React.createElement(UploadButtonImageProductComponent, null)),
        React.createElement("div", { className: 'mt-3 flex-1 flex flex-col sm:grid sm:grid-cols-6 sm:gap-4 overflow-auto' }, (data === null || data === void 0 ? void 0 : data.Files) &&
            data.Files.map(function (el) { return (React.createElement("div", { key: el.id, className: 'flex flex-col' },
                React.createElement(ImageComponent, { width: 'full', src: el.id.toString(), alt: "product-".concat(el.id) }),
                React.createElement(DeleteButtonImageProductComponent, { fileId: el.id }))); }))));
};
export default ImageProductComponent;
