import React, { useEffect } from 'react';

import { useReactiveVar } from '@apollo/client';

import { exportPdf, filter } from '../../../apollo/cache';

import CONFIG from '../../../config/config.json';
import getSession from '../../../helpers/getSession';

const XlsExportComponent = () => {
  const exportPdfVar = useReactiveVar(exportPdf);
  const filterVar = useReactiveVar(filter);

  useEffect(() => {
    if (exportPdfVar.export) {
      const query = Object.keys(filterVar).reduce((query, el) => {
        if (!filterVar[el]) return query;
        if (query.length <= 0) return `?${el}=${filterVar[el]}`;
        return `${query}&${el}=${filterVar[el]}`;
      }, '');
      const session = getSession();
      window.open(
        `${CONFIG['restServer'].uri}/files/receivablesByCustomer/${session?.Company?.id}/pdf${query}`,
      );
      exportPdf({ export: false });
    }
  }, [exportPdfVar]);

  return <div />;
};

export default XlsExportComponent;
