import React from 'react';
import Card from '@mui/material/Card';
import CashClosingSaleResumeComponent from './SaleResume';
import CashClosingSaleDetailComponent from './SaleDetail';
var DetailSaleComponent = function () {
    return (React.createElement(Card, { className: 'mt-2 sm:mt-0 flex flex-1 flex-col overflow-hidden' },
        React.createElement("div", { className: 'flex flex-col bg-slate-700 text-white' },
            React.createElement("div", { className: 'font-semibold p-2' }, "Ventas"),
            React.createElement(CashClosingSaleResumeComponent, null)),
        React.createElement(CashClosingSaleDetailComponent, null)));
};
export default DetailSaleComponent;
