import React from 'react';
import { inputError, productSaleV2, searchProductField } from '../../../../../../../apollo/cache';
import getDefaultValues from '../../Body/helpers/getDefaultValues';
import Button from '../../../../../../../presentationals/Dialog/Footer/Button';
var ClearButtonFooterCreateProductDialogProductFormChooseProductSaleV2Component = function () {
    var handleClick = function () {
        var defaultProduct = getDefaultValues();
        productSaleV2(defaultProduct);
        searchProductField('');
        inputError({});
    };
    return (React.createElement(Button, { color: 'inherit', onClick: handleClick }, "Limpiar"));
};
export default ClearButtonFooterCreateProductDialogProductFormChooseProductSaleV2Component;
