import React from 'react';
import { Outlet } from 'react-router-dom';
import AppbarComponent from '../../containers/dashboard/Menu/Appbar';
import DrawerComponent from '../../containers/dashboard/Menu/Drawer';
import CreateCalendarDialogComponent from '../../containers/calendar/Create';
import FormCustomerActionSaleV2Component from '../../containers/sale.v2/Action/Customer/Form';
import FormDuplicateFormatFormFormatActionSaleV2Component from '../../containers/sale.v2/Action/Format/Form/DuplicateFormat/Form';
import AppsMenuDialogComponent from '../../containers/dashboard/AppsMenuDialog';
import ManageAccountDialogComponent from '../../containers/dashboard/ManageAccountDialog';
import ChangeProfilePictureDialogComponent from '../../containers/dashboard/ChangeProfilePictureDialog';
import ProductFieldDialogComponent from '../../presentationals/FormComponents/ProductField/Dialog';
import useGetSessionRole from '../../hooks/useGetSessionRole';
import LoadingProgress from '../../presentationals/LoadingProgress';
import EditCalendarComponent from '../../containers/calendar/Edit';
import CreatedInvoiceDialogComponent from '../../presentationals/CreatedInvoiceDialog';
import CreatedInvoiceComplementDialogComponent from '../../presentationals/CreateInvoiceComplementDialog';
var PanelLayoutComponent = function () {
    var loading = useGetSessionRole().loading;
    if (loading)
        return (React.createElement("div", { className: 'flex flex-1 flex-col h-screen' },
            React.createElement(LoadingProgress, null)));
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: 'flex flex-1 flex-col h-screen' },
            React.createElement(AppbarComponent, null),
            React.createElement("div", { className: 'flex flex-1 flex-col overflow-hidden bg-slate-50' },
                React.createElement(Outlet, null))),
        React.createElement(AppsMenuDialogComponent, null),
        React.createElement(CreateCalendarDialogComponent, null),
        React.createElement(EditCalendarComponent, null),
        React.createElement(DrawerComponent, null),
        React.createElement(FormCustomerActionSaleV2Component, null),
        React.createElement(FormDuplicateFormatFormFormatActionSaleV2Component, null),
        React.createElement(ManageAccountDialogComponent, null),
        React.createElement(ChangeProfilePictureDialogComponent, null),
        React.createElement(ProductFieldDialogComponent, null),
        React.createElement(CreatedInvoiceDialogComponent, null),
        React.createElement(CreatedInvoiceComplementDialogComponent, null)));
};
export default PanelLayoutComponent;
