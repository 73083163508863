import React from 'react';
import { useReactiveVar } from '@apollo/client';
import InsertPhotoOutlinedIcon from '@mui/icons-material/InsertPhotoOutlined';
import { Tabs, Tab } from '@mui/material';
import TimelineIcon from '@mui/icons-material/Timeline';
import EventNoteIcon from '@mui/icons-material/EventNote';
import EventBusyIcon from '@mui/icons-material/EventBusy';
import { calendarId } from '../../../../apollo/cache';
import useGetSession from '../../../../hooks/useGetSession';
var MenuEditCalendarComponent = function (props) {
    var _a;
    var saleCalendarId = useReactiveVar(calendarId);
    var session = useGetSession();
    var customerLabel = ((_a = session === null || session === void 0 ? void 0 : session.Company) === null || _a === void 0 ? void 0 : _a.activity) === 3 ? 'paciente' : 'cliente';
    return (React.createElement(Tabs, { value: props.value, onChange: props.onChange, variant: 'scrollable', scrollButtons: 'auto', className: 'bg-slate-200 border-0 border-b border-[#d6d6d6] border-solid' },
        React.createElement(Tab, { icon: React.createElement(EventNoteIcon, null), label: 'Datos generales' }),
        React.createElement(Tab, { icon: React.createElement(TimelineIcon, null), label: "Historial del ".concat(customerLabel) }),
        React.createElement(Tab, { icon: React.createElement(InsertPhotoOutlinedIcon, null), label: 'Imagen' }),
        !saleCalendarId && React.createElement(Tab, { icon: React.createElement(EventBusyIcon, null), label: 'Cancelar evento' })));
};
export default MenuEditCalendarComponent;
