import getServiceOrderTableColumnConfiguration from '../../../helpers/getServiceOrderTableColumnConfiguration';
var getServiceOrderReportHeaderColumns = function () {
    var defaultHeader = [
        { Header: 'Creado el', accessor: 'createdAt' },
        { Header: 'Folio', accessor: 'folio' },
        { Header: 'Cliente', accessor: 'customer' },
        { Header: 'Estatus', accessor: 'status' },
        { Header: 'Comisión', accessor: 'commission' },
        { Header: 'Envío', accessor: 'shipping' },
        { Header: 'Subtotal', accessor: 'subtotal' },
        { Header: 'Impuestos', accessor: 'taxes' },
        { Header: 'Importe', accessor: 'total' },
        { Header: 'Gastos', accessor: 'expenses' },
        { Header: 'Cajero', accessor: 'cashier' },
        { Header: 'Vendedor', accessor: 'seller' },
        { Header: 'Comentario', accessor: 'commentary' },
    ];
    var settings = getServiceOrderTableColumnConfiguration();
    var allowedColumns = Object.keys(settings).filter(function (key) { return settings[key]; });
    var columns = defaultHeader.filter(function (el) { return allowedColumns.includes(el.accessor); });
    return columns;
};
export default getServiceOrderReportHeaderColumns;
