import React from 'react';

import { useReactiveVar } from '@apollo/client';

import { List, Dialog } from '@mui/material';

import { isSaleCurrencyFormOpen } from '../../../../../apollo/cache';

import { currencyFormatArray } from '../../../../../helpers/getCurrencyLabel';

import Item from './Item';
import ExchangeRateComponent from './ExchangeRate';

const FormCurrencyActionSaleV2Component = () => {
  const isSaleCurrencyFormOpenVar = useReactiveVar(isSaleCurrencyFormOpen);
  return (
    <Dialog open={isSaleCurrencyFormOpenVar}>
      <List component='nav' aria-label='SaleFormatList'>
        {Object.keys(currencyFormatArray).map(key => (
          <Item key={key} id={key} label={currencyFormatArray[key]} />
        ))}
      </List>
      <ExchangeRateComponent />
    </Dialog>
  );
};

export default FormCurrencyActionSaleV2Component;
