import React, { useCallback } from 'react';
import { useFormContext } from 'react-hook-form';
import CONSTANTS_ERROR from '../../../constants/error';
import getMainError from './helpers/getMainError';
import getJsonString from '../../../helpers/getJsonString';
var FieldErrorComponent = function (_a) {
    var name = _a.name;
    var errors = useFormContext().formState.errors;
    var error = useCallback(getMainError(name, errors), [name, getJsonString(errors)]);
    if (!error)
        return React.createElement("div", null);
    return (React.createElement("div", { className: 'text-xs text-red-700' }, error.type === 'required' ? CONSTANTS_ERROR.REQUIRED_INPUT : "".concat(error.message)));
};
export default FieldErrorComponent;
