var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useMemo, useState } from 'react';
import axios from 'axios';
import CONFIG from '../../../config/config.json';
import GoBackButtonComponent from '../../../presentationals/GoBackButton';
import RHFormComponent from '../../../presentationals/RHForm/Form/indext';
import RHFSelectFieldComponent from '../../../presentationals/RHForm/SelectField';
import ProductColumsToExportFieldComponent from './ColumnsToExportField';
import RHFButtonComponent from '../../../presentationals/RHForm/Button';
import getInputErrorFormat from '../../sale.v2/Action/helpers/getInputErrorFormat';
import getSession from '../../../helpers/getSession';
import getProductColumKeysByType from './ColumnsToExportField/helpers/getColumKeysByType';
var ExportProductsComponent = function () {
    var _a = useState(false), loading = _a[0], setLoading = _a[1];
    var defaultValues = useMemo(function () { return ({
        type: 1,
        columns: [
            'presentationId',
            'presentationName',
            'productIngredientId',
            'ingredientId',
            'ingredientName',
            'principalProductName',
            'presentationName',
            'productId',
            'productName',
        ],
    }); }, []);
    var handleSubmit = function (data) { return __awaiter(void 0, void 0, void 0, function () {
        var session, token, authorization, allowedKeys_1, response, pdfBlob, url, tempLink, error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, 3, 4]);
                    setLoading(true);
                    session = getSession();
                    token = session === null || session === void 0 ? void 0 : session.accessToken;
                    authorization = token ? { authorization: "Bearer ".concat(token) } : {};
                    allowedKeys_1 = getProductColumKeysByType(data.type);
                    return [4 /*yield*/, axios.post("".concat(CONFIG.restServer.uri, "/files/products"), __assign(__assign({}, data), { columns: data.columns.filter(function (key) { return !defaultValues.columns.includes(key) && allowedKeys_1.includes(key); }) }), {
                            headers: __assign({}, authorization),
                            responseType: 'arraybuffer',
                        })];
                case 1:
                    response = _a.sent();
                    pdfBlob = new Blob([response.data], { type: 'application/xls' });
                    url = window.URL.createObjectURL(pdfBlob);
                    tempLink = document.createElement('a');
                    tempLink.href = url;
                    tempLink.setAttribute('download', 'produts.xls');
                    document.body.appendChild(tempLink);
                    tempLink.click();
                    document.body.removeChild(tempLink);
                    window.URL.revokeObjectURL(url);
                    return [3 /*break*/, 4];
                case 2:
                    error_1 = _a.sent();
                    getInputErrorFormat(error_1);
                    return [3 /*break*/, 4];
                case 3:
                    setLoading(false);
                    return [7 /*endfinally*/];
                case 4: return [2 /*return*/];
            }
        });
    }); };
    return (React.createElement("div", { className: 'flex flex-1 flex-col' },
        React.createElement("div", { className: 'flex items-center px-4 py-0 border-0 border-b border-[#d6d6d6] border-solid' },
            React.createElement("div", { className: 'mr-3' },
                React.createElement(GoBackButtonComponent, null)),
            React.createElement("div", { className: 'text-lg text-gray-700 tracking-tight' }, "Exportar lista de productos")),
        React.createElement("div", { className: 'flex flex-1 flex-col h-full overflow-hidden' },
            React.createElement(RHFormComponent, { onSubmit: handleSubmit, defaultValues: defaultValues },
                React.createElement("div", { className: 'flex flex-1 overflow-auto basis-0 px-4 py-3' },
                    React.createElement("div", { className: 'flex flex-1 flex-col' },
                        React.createElement("div", { className: 'w-full sm:w-1/4 mb-2' },
                            React.createElement(RHFSelectFieldComponent, { label: '\u00BFQu\u00E9 tipo de cat\u00E1logo deseas descargar?', name: 'type', options: [
                                    { label: 'Productos', value: 1 },
                                    { label: 'Presentaciones de productos', value: 2 },
                                    { label: 'Insumos / ingredientes de productos', value: 3 },
                                ] })),
                        React.createElement(ProductColumsToExportFieldComponent, null),
                        React.createElement("div", null,
                            React.createElement(RHFButtonComponent, { disabled: loading, size: 'medium', className: 'mt-3' }, "Descargar xls"))))))));
};
export default ExportProductsComponent;
