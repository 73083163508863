import React from 'react';
import { useReactiveVar } from '@apollo/client';
import { isAddProductPromotionOpen } from '../../../apollo/cache';
import DialogComponent from '../../../presentationals/Dialog';
import UnderConstructionAlertComponent from '../../../presentationals/UnderConstructionAlert';
var AppProductPromotionComponent = function () {
    var isOpen = useReactiveVar(isAddProductPromotionOpen);
    var handleClose = function () {
        isAddProductPromotionOpen(false);
    };
    return (React.createElement(DialogComponent, { fullScreen: true, open: isOpen, title: 'A\u00F1adir producto a la promoci\u00F3n', onClose: handleClose, "aria-labelledby": 'add-product-to-promotion', "aria-describedby": 'add-new-product-to-current-promotion' },
        React.createElement("div", { className: 'px-3 py-2 w-full sm:w-1/2' },
            React.createElement(UnderConstructionAlertComponent, null))));
};
export default AppProductPromotionComponent;
