var getIngredientColumnsToExport = function () {
    return [
        {
            value: 'ingredientId',
            label: 'Identificador del producto',
        },
        {
            value: 'ingredientName',
            label: 'Nombre del producto',
        },
        {
            value: 'ingredientType',
            label: 'Tipo de producto',
        },
        {
            value: 'productIngredientId',
            label: 'Identificador del ingrediente',
        },
        {
            value: 'productIngredientType',
            label: 'Tipo de ingrediente',
        },
        {
            value: 'productIngredientPrice',
            label: 'Precio por porción',
        },
        {
            value: 'productIngredientQuantity',
            label: 'Cantidad de porciones incluidas por default',
        },
        {
            value: 'productIngredientPortion',
            label: 'Tamaño de la porción',
        },
        {
            value: 'productIngredientFreeLimit',
            label: 'Número de porciones gratuitas',
        },
        {
            value: 'productIngredientAltPrice',
            label: 'Precio alternativo',
        },
        {
            value: 'productIngredientExtra',
            label: 'Precio de cada porción extra',
        },
        {
            value: 'productIngredientDidiPrice',
            label: 'Precio de venta en Didi',
        },
        {
            value: 'productIngredientRappiPrice',
            label: 'Precio de venta en Rappi',
        },
        {
            value: 'productIngredientUberPrice',
            label: 'Precio de venta en Uber',
        },
        {
            value: 'productIngredientShippingPrice',
            label: 'Precio de venta en servicio a domicilio',
        },
        {
            value: 'productIngredientPickupPrice',
            label: 'Precio de venta en servicio ordene y recoja',
        },
    ];
};
export default getIngredientColumnsToExport;
