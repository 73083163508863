import React, { useState } from 'react';
import { useMutation } from '@apollo/client';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import FormDialog from '../../presentationals/FormDialog';
import TextField from '../../presentationals/TextField';
import SelectField from '../../presentationals/SelectField';
import Snackbar from '../../presentationals/Snackbar';

import { parseError } from '../../helpers';

import GQL from './_gql';
import useSnackbarDialog from '../../store/snackbar/snackbarDialog';

const defaultValues = {
  reason: '',
  commentary: '',
};

const reasonOptions = [
  { value: 1, label: 'PRECIO' },
  { value: 2, label: 'CRÉDITO' },
  { value: 3, label: 'CALIDAD DEL PROYECTO' },
  { value: 1, label: 'TRATO AL CLIENTE' },
  { value: 4, label: 'TIEMPO DE ENTREGA DEL PROYECTO' },
  { value: 5, label: 'SITUACIÓN GEOGRAFICA' },
  { value: 6, label: 'MAL ENFOQUE DEL PROYECTO' },
  { value: 7, label: 'OTROS' },
  { value: 8, label: 'RAZÓN NO CLASIFICADO' },
];

const defaultSnackbar = { isOpen: true, time: 3000 };

const PettyCash = props => {
  const { setSnackbar } = useSnackbarDialog();
  const [values, setValues] = useState(defaultValues);
  const [error, setError] = useState({});

  const [deleteQuote, { loading }] = useMutation(GQL.DELETE);

  const resetValues = () => {
    setValues(defaultValues);
  };

  const handleAction = async () => {
    try {
      setError({});
      let newError = null;
      if (values.reason === '') {
        newError = new Error('Proporciona una razón por lo caul la venta no se logro.');
        newError.name = 'reason';
        throw newError;
      }
      await deleteQuote({
        variables: {
          id: props.id,
          reason: parseInt(values.reason),
          commentary: values.commentary,
        },
      });
      resetValues();
      setSnackbar({
        ...defaultSnackbar,
        label: 'La cotización fue cancelada con éxito.',
        severity: 'success',
      });
      props.handleClose();
    } catch (e) {
      console.log(e);
      if (['reason'].includes(e.name)) {
        setError({ [e.name]: e.message });
        return;
      }
      const parseErrors = parseError(e);
      parseErrors.forEach(el => {
        console.log(el);
        if (el.name === 'BAD_USER_INPUT') {
          const key = Object.keys(el.message)[0];
          setSnackbar({ ...defaultSnackbar, label: el.message[key], severity: 'error' });
        } else {
          setSnackbar({ ...defaultSnackbar, label: el.message, severity: 'error' });
        }
      });
    }
  };

  const handleChange = e => {
    const { name } = e.target;
    const { value } = e.target;
    setValues(values => ({ ...values, [name]: value }));
  };

  return (
    <>
      <FormDialog
        title='Cancelar Venta'
        isOpen={props.isOpen}
        isLoading={loading}
        handleAction={handleAction}
        handleClose={props.handleClose}
      >
        <Grid container>
          <Grid item xs={12}>
            <Typography variant='body2' gutterBottom>
              Estás a punto de cancelar una cotización.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <SelectField
              label='¿Razón de la venta no lograda?'
              name='reason'
              value={values.reason}
              error={error.reason}
              options={reasonOptions}
              onChange={handleChange}
              noFormat
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label='Comentarios'
              name='commentary'
              value={values.commentary}
              error={error.commentary}
              onChange={handleChange}
              noFormat
              multiline
            />
          </Grid>
        </Grid>
      </FormDialog>
      <Snackbar horizontal='center' />
    </>
  );
};

export default PettyCash;
