var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useEffect, useState } from 'react';
import { useReactiveVar } from '@apollo/client';
import { eachHourOfInterval, isSameDay } from 'date-fns';
import { calendarForm } from '../../../../../../apollo/cache';
import useApolloLazyQuery from '../../../../../../hooks/useApolloLazyQuery';
import GQL from '../../../../_gql';
import Item from './Item';
var ItemAgendaBodyFormCalendarComponent = function (props) {
    var values = useReactiveVar(calendarForm);
    var _a = useState([]), arrayDate = _a[0], setArrayDate = _a[1];
    var _b = useApolloLazyQuery(GQL.GET), getData = _b[0], _c = _b[1], data = _c.data, loading = _c.loading;
    useEffect(function () {
        if (values.start)
            getData({
                variables: {
                    start: values.start,
                    end: values.end,
                    tagId: props.availableTags || [],
                    limit: 0,
                    withCrm: true,
                },
            });
    }, [values.start]);
    useEffect(function () {
        if ((data === null || data === void 0 ? void 0 : data.rows) && values.start) {
            var date = values.start;
            var dateInterval = eachHourOfInterval({
                start: new Date(date.getFullYear(), date.getMonth(), date.getDate(), props.startHour || 9),
                end: new Date(date.getFullYear(), date.getMonth(), date.getDate(), props.endHour || 9),
            });
            var result = dateInterval.map(function (date) { return ({
                date: date,
                schedules: data.rows.filter(function (el) { return isSameDay(new Date(el.start), date); }),
            }); });
            setArrayDate(result);
        }
    }, [values.start, props.startHour, props.endHour, data]);
    if (loading)
        return React.createElement("div", null);
    return (React.createElement("div", { className: 'flex flex-col mb-4' },
        React.createElement("div", { className: 'text-3xl text-[#1376c7] font-semibold mb-2' }, props.title || 'Agenda'),
        React.createElement("div", { className: 'border border-solid border-gray-500 rounded' }, arrayDate.map(function (el, index) { return (React.createElement(Item, __assign({ key: index, limitNotification: props.limitNotification, schedulePerHour: props.schedulePerHour }, el))); }))));
};
export default ItemAgendaBodyFormCalendarComponent;
