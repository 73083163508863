import React from 'react';
import PaymentMethodComponent from '../containers/paymentMethod';
import CreatePaymentMethodLayoutComponent from './PaymentMethod/CreatePaymentMethodLayout.tsx';
import PaymentMethodLayoutComponent from './PaymentMethod/PaymentMethodLayout';
var getPaymentMethodChildrenRoutes = function () {
    return [
        { index: true, element: React.createElement(PaymentMethodComponent, null) },
        {
            path: 'create',
            element: React.createElement(CreatePaymentMethodLayoutComponent, null),
        },
        {
            path: ':paymentMethodId',
            element: React.createElement(PaymentMethodLayoutComponent, null),
        },
    ];
};
export default getPaymentMethodChildrenRoutes;
