import React, { useEffect, useState } from 'react';
import { useReactiveVar } from '@apollo/client';
import DialogComponent from '../../../../../presentationals/Dialog';
import { isEditDescriptionHistoryCustomerFormOpen } from '../../../../../apollo/cache';
import TextFieldComponent from '../../../../../presentationals/FormComponents/TextField';
import FooterDialogComponent from '../../../../../presentationals/Dialog/Footer';
import EditButtonDescriptionCalendarComponent from './EditButton';
var EditDesciptionFormHistoryCustomerComponent = function () {
    var _a = useState(''), value = _a[0], setValue = _a[1];
    var isOpen = useReactiveVar(isEditDescriptionHistoryCustomerFormOpen);
    useEffect(function () {
        setValue(isOpen[1]);
    }, [isOpen[1]]);
    var handleChange = function (name, value) {
        setValue(value);
    };
    var handleClose = function () {
        isEditDescriptionHistoryCustomerFormOpen([null, '']);
    };
    return (React.createElement(DialogComponent, { fullScreen: true, open: !!isOpen[0], title: 'Editar descripci\u00F3n del evento', onClose: handleClose },
        React.createElement("div", { className: 'flex-1 grid grid-cols-1 md:grid-cols-3 px-4' },
            React.createElement(TextFieldComponent, { name: 'description', label: 'Descripci\u00F3n del evento', onChange: handleChange, multiline: true, rows: 4, value: value })),
        React.createElement(FooterDialogComponent, null,
            React.createElement("div", null),
            React.createElement(EditButtonDescriptionCalendarComponent, { description: value }))));
};
export default EditDesciptionFormHistoryCustomerComponent;
