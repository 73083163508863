import React, { useState, Fragment } from 'react';
import { useMutation } from '@apollo/client';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import FormDialog from '../../presentationals/FormDialog';
import TextField from '../../presentationals/TextField';
import Snackbar from '../../presentationals/Snackbar';

import { parseError } from '../../helpers';

import GQL from './_gql';
import useSnackbarDialog from '../../store/snackbar/snackbarDialog';

const defaultValues = {
  deleteJustification: '',
};
const defaultSnackbar = { isOpen: true, time: 3000 };

const PettyCash = ({ id, ...props }) => {
  const { setSnackbar } = useSnackbarDialog();
  const [values, setValues] = useState(defaultValues);

  const [handleCancelRequisition, { loading }] = useMutation(GQL.CANCEL_REQUISITION);

  const resetValues = () => {
    setValues(defaultValues);
  };

  const handleAction = async () => {
    try {
      await handleCancelRequisition({
        variables: { id, deleteJustification: values.deleteJustification },
      });
      resetValues();
      setSnackbar({
        ...defaultSnackbar,
        label: 'La requisición fue cancelada con éxito.',
        severity: 'success',
      });
      props.handleClose();
    } catch (e) {
      const parseErrors = parseError(e);
      parseErrors.forEach(el => {
        console.log(el);
        if (el.name === 'BAD_USER_INPUT') {
          const key = Object.keys(el.message)[0];
          setSnackbar({ ...defaultSnackbar, label: el.message[key], severity: 'error' });
        } else {
          setSnackbar({ ...defaultSnackbar, label: el.message, severity: 'error' });
        }
      });
    }
  };

  const handleChange = e => {
    const { name } = e.target;
    const { value } = e.target;
    setValues(values => ({ ...values, [name]: value }));
  };

  const handleClose = () => {
    resetValues();
    props.handleClose();
  };

  return (
    <>
      <FormDialog
        title='Cancelar Requisición'
        isOpen={props.isOpen}
        isLoading={loading}
        handleAction={handleAction}
        handleClose={handleClose}
      >
        <Grid container>
          <Grid item xs={12}>
            <Typography variant='body2' gutterBottom>
              Escribe el motivo para cancelar la requisición
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField
              label='Justificación'
              name='deleteJustification'
              value={values.deleteJustification}
              onChange={handleChange}
              noFormat
            />
          </Grid>
        </Grid>
      </FormDialog>
      <Snackbar horizontal='center' />
    </>
  );
};

export default PettyCash;
