import React, { useEffect, useState } from 'react';
import { Tabs, Tab } from '@mui/material';
import NewspaperIcon from '@mui/icons-material/Newspaper';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import { useLocation, useNavigate } from 'react-router-dom';
import getRoleMenuValueFromPath from './getRoleMenuValueFromPath';
import getRouteFromRoleMenuValue from './getRouteFromRoleMenuValue';
var MenuRoleComponent = function () {
    var navigate = useNavigate();
    var location = useLocation();
    var _a = useState(0), value = _a[0], setValue = _a[1];
    useEffect(function () {
        var value = getRoleMenuValueFromPath(location.pathname);
        setValue(value);
    }, [location.pathname]);
    var handleChange = function (e, value) {
        var route = getRouteFromRoleMenuValue(value);
        if (route)
            navigate(route);
    };
    return (React.createElement(Tabs, { value: value, onChange: handleChange, variant: 'scrollable', scrollButtons: 'auto', className: 'bg-slate-100 border-0 border-b border-[#d6d6d6] border-solid' },
        React.createElement(Tab, { icon: React.createElement(NewspaperIcon, null), label: 'Datos generales' }),
        React.createElement(Tab, { icon: React.createElement(VpnKeyIcon, null), label: 'Permisos' })));
};
export default MenuRoleComponent;
