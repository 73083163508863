import React from 'react';
import { TableRow } from '@mui/material';
import { useNavigate } from 'react-router-dom';
var TableRowItemProductPriceTableEditIngredientComponent = function (props) {
    var navigate = useNavigate();
    var handleClick = function () {
        navigate(props.productIngredientId);
    };
    return (React.createElement(TableRow, { hover: true, className: "cursor-pointer ".concat(props.background), onClick: handleClick }, props.children));
};
export default TableRowItemProductPriceTableEditIngredientComponent;
