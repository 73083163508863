import React, { useEffect, useState } from 'react';

import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import CreditCardIcon from '@mui/icons-material/CreditCardOutlined';

import { actions } from '../../apollo/cache';

import DeleteOrderForm from './_delete.form';
import Payment from '../payable/payment.dialog';

const ActionComponent = () => {
  const [isOpenPayment, setIsOpenPayment] = useState([false, null]);
  const [isOpenDeleteOrder, setIsOpenDeleteOrder] = useState([false, null]);

  const handleDelete = (e, { id }) => setIsOpenDeleteOrder([true, id]);
  const handleCloseDelete = () => setIsOpenDeleteOrder([false, null]);

  const handlePayment = (e, { AccountPayable }) => {
    setIsOpenPayment([true, AccountPayable.id]);
  };
  const handleClosePayment = () => setIsOpenPayment([false, null]);

  useEffect(() => {
    actions([
      {
        icon: <CreditCardIcon />,
        label: 'Abonar',
        onClick: handlePayment,
        disable: { key: 'paid', value: true },
      },
      {
        icon: <DeleteIcon />,
        label: 'Eliminar Orden de Compra',
        onClick: handleDelete,
      },
    ]);
  }, []);

  return (
    <>
      <DeleteOrderForm
        isOpen={isOpenDeleteOrder[0]}
        id={isOpenDeleteOrder[1]}
        handleClose={handleCloseDelete}
      />
      <Payment
        isOpen={isOpenPayment[0]}
        handleClose={handleClosePayment}
        accountPayableId={isOpenPayment[1]}
      />
    </>
  );
};

export default ActionComponent;
