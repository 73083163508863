import React from 'react';
import { TableRow } from '@mui/material';
import { useNavigate } from 'react-router-dom';
var TableRowItemRateProductPresentationComponent = function (props) {
    var navigation = useNavigate();
    var handleClick = function () {
        navigation(props.productPresentationRateId);
    };
    return (React.createElement(TableRow, { hover: true, className: "cursor-pointer ".concat(props.background), onClick: handleClick }, props.children));
};
export default TableRowItemRateProductPresentationComponent;
