import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Card from '@mui/material/Card';
import { Skeleton } from '@mui/material';
import ItemCashClossinSummaryIncomeReportComponent from '../Item';
import useApolloLazyQuery from '../../../../../hooks/useApolloLazyQuery';
import GQL_CASH_CLOSINGS from '../../../../../apollo/gql/cashClosing';
import getCashClosingDatesLabel from './helpers/getCashClosingDatesLabel';
var BalanceComponent = function () {
    var params = useParams();
    var _a = useApolloLazyQuery(GQL_CASH_CLOSINGS.GET_CASH_CLOSING_BY_ID), getData = _a[0], data = _a[1].data;
    useEffect(function () {
        getData({ variables: { id: params.cashClosingId } });
    }, [params]);
    return (React.createElement(Card, { className: 'flex flex-col overflow-hidden p-3 bg-slate-700' },
        React.createElement("div", { className: 'flex text-white leading-none font-semibold' }, "CORTE DE CAJA"),
        data ? (React.createElement("div", { className: 'flex text-white text-xs leading-6' }, "DEL ".concat(getCashClosingDatesLabel(data.clientCreatedAt, data.closedAt)))) : (React.createElement(Skeleton, { variant: 'text', className: 'text-xs bg-gray-300 leading-6 w-1/2' })),
        React.createElement("div", { className: 'flex flex-1 overflow-x-auto overflow-y-hidden' },
            React.createElement(ItemCashClossinSummaryIncomeReportComponent, { label: 'Caja chica', total: data === null || data === void 0 ? void 0 : data.pettyCashAmount }),
            React.createElement(ItemCashClossinSummaryIncomeReportComponent, { label: 'Saldo en sistema', total: data === null || data === void 0 ? void 0 : data.virtualBalance }),
            React.createElement(ItemCashClossinSummaryIncomeReportComponent, { label: 'Saldo declarado', total: data === null || data === void 0 ? void 0 : data.realBalance }),
            React.createElement(ItemCashClossinSummaryIncomeReportComponent, { label: 'Diferencia', total: data === null || data === void 0 ? void 0 : data.difference }))));
};
export default BalanceComponent;
