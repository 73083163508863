import XLSX from 'xlsx';
import { format } from 'date-fns';
import locale from 'date-fns/locale/es';

import client from '../../apollo';
import GQL from './_gql';

import { currencyFormat } from '../../helpers/general';

const getDataFromServer = async filterVar => {
  try {
    const { data } = await client.mutate({
      mutation: GQL.GET_XLS_DATA,
      variables: { limit: 0, start: filterVar.start, end: filterVar.end },
    });
    return data.maintenances.rows;
  } catch (e) {
    console.log(e);
    return e;
  }
};

const orderStatus = ['Cancelada', 'Abierta', 'En Proceso', 'Cerrada', 'Completado'];

export default async filterVar => {
  const data = await getDataFromServer(filterVar);

  // const filename = `MTO_${data.serial.padStart(5, '0')}.xlsx`;
  const filename = 'mantenimiento.xlsx';
  const wb = XLSX.utils.book_new();

  const header = [
    'Cliente',
    'Fecha de Salida',
    'Estatus',
    'Folio',
    'NV',
    'Comentario',
    'Importe',
    'Abonado',
    'Saldo',
    'Gastos',
  ];

  const ws = XLSX.utils.json_to_sheet([], { header, skipHeader: true });
  const dataToPrint = data.reduce((array, el) => {
    let expenses = el.MaintenanceExpenses.map(elCh => {
      return {
        Cliente: '',
        'Fecha de Salida': '',
        Estatus: elCh.Product ? elCh.Product.name.trim() : elCh.name.trim(),
        Folio: elCh.outDate ? format(new Date(elCh.outDate), 'yyyy/MM/dd', { locale }) : '-',
        NV: elCh.quantity,
        Comentario: currencyFormat(elCh.withoutTaxes),
        Importe: currencyFormat(elCh.subtotal),
        Abonado: currencyFormat(elCh.discount),
        Saldo: currencyFormat(elCh.taxes),
        Gastos: currencyFormat(elCh.total),
      };
    });
    expenses =
      expenses.length > 0
        ? [
            {
              Cliente: '',
              'Fecha de Salida': '',
              Estatus: 'Producto',
              Folio: 'Salida',
              NV: 'Cant.',
              Comentario: 'P. Unitario',
              Importe: 'Subtotal',
              Abonado: 'Descuento',
              Saldo: 'IVA',
              Gastos: 'Total',
            },
            ...expenses,
          ]
        : [];

    return [
      ...array,
      {
        Cliente: `${el.Customer.firstName} ${el.Customer.lastName}`.trim(),
        'Fecha de Salida': el.createdAt
          ? format(new Date(el.createdAt), 'yyyy/MM/dd', { locale })
          : '-',
        Estatus: orderStatus[el.status],
        Folio: `MTO ${String(el.serial).padStart(5, '0')}`,
        NV: el.folioSale,
        Comentario: el.commentary,
        Importe: el.AccountReceivable ? currencyFormat(el.AccountReceivable.total) : 0,
        Abonado: el.AccountReceivable ? currencyFormat(el.AccountReceivable.payment) : 0,
        Saldo: el.AccountReceivable ? currencyFormat(el.AccountReceivable.balance) : 0,
        Gastos: currencyFormat(el.expenses),
      },
      ...expenses,
    ];
  }, []);
  XLSX.utils.sheet_add_json(ws, dataToPrint, { skipHeader: false, origin: 'A1' });

  XLSX.utils.book_append_sheet(wb, ws, 'mantenimiento');
  XLSX.writeFile(wb, filename);
};
