import React, { useEffect, useState } from 'react';

import { useLazyQuery, useMutation, useReactiveVar } from '@apollo/client';
import Typography from '@mui/material/Typography';

import FormDialog from '../../presentationals/FormDialog';
import TextField from '../../presentationals/FormComponents/TextField';
import { inputError } from '../../apollo/cache';

import getInputErrorFormat from '../sale.v2/Action/helpers/getInputErrorFormat';
import setInputErrorFormat from '../sale.v2/Action/helpers/setInputErrorFormat';
import GQL_INVOICE_COMPLEMENT from '../../apollo/gql/invoiceComplement';
import GQL_SALE from '../../apollo/gql/sale';

const defaultValues = {
  email: '',
  altEmail: '',
  otherEmail: '',
};

const ReSendEmailDialogComponent = props => {
  const [values, setValues] = useState(defaultValues);
  const errors = useReactiveVar(inputError);
  const [sendSale, { loading }] = useMutation(GQL_SALE.SEND_SALE_BY_EMAIL, {
    fetchPolicy: 'no-cache',
  });
  const [sendComplement, { loading: loadingComplement }] = useMutation(
    GQL_INVOICE_COMPLEMENT.SEND_INVOICE_COMPLEMENT_BY_EMAIL,
    {
      fetchPolicy: 'no-cache',
    },
  );
  const [getSale, { data: dataSale }] = useLazyQuery(GQL_SALE.GET_SALE_BY_ID);
  const [getComplement, { data: dataComplement }] = useLazyQuery(GQL_INVOICE_COMPLEMENT.GET_BY_ID, {
    fetchPolicy: 'no-cache',
  });

  useEffect(() => {
    if (props.isOpen) {
      if (props.complementId) getComplement({ variables: { id: props.complementId } });
      if (props.id) getSale({ variables: { id: props.id } });
    }
  }, [props.isOpen, props.id, props.complementId]);

  useEffect(() => {
    if (dataSale)
      if (dataSale.sale.Customer)
        if (dataSale.sale.Customer.CustomerBillingInformation) {
          const email = dataSale.sale.Customer.CustomerBillingInformation.email
            ? dataSale.sale.Customer.CustomerBillingInformation.email
            : '';
          const altEmail = dataSale.sale.Customer.CustomerBillingInformation.altEmail
            ? dataSale.sale.Customer.CustomerBillingInformation.altEmail
            : '';
          const otherEmail = dataSale.sale.Customer.CustomerBillingInformation.otherEmail
            ? dataSale.sale.Customer.CustomerBillingInformation.otherEmail
            : '';
          setValues(values => ({ ...values, email, altEmail, otherEmail }));
        }
  }, [dataSale]);

  useEffect(() => {
    if (dataComplement)
      if (dataComplement.invoiceComplement.Customer)
        if (dataComplement.invoiceComplement?.Customer?.CustomerBillingInformation) {
          const email = dataComplement.invoiceComplement.Customer.CustomerBillingInformation.email
            ? dataComplement.invoiceComplement.Customer.CustomerBillingInformation.email
            : '';
          const altEmail = dataComplement.invoiceComplement.Customer.CustomerBillingInformation
            .altEmail
            ? dataComplement.invoiceComplement.Customer.CustomerBillingInformation.altEmail
            : '';
          const otherEmail = dataComplement.invoiceComplement.Customer.CustomerBillingInformation
            .otherEmail
            ? dataComplement.invoiceComplement.Customer.CustomerBillingInformation.otherEmail
            : '';
          setValues(values => ({ ...values, email, altEmail, otherEmail }));
        }
  }, [dataComplement]);

  const handleClose = () => {
    props.handleClose();
    setValues(defaultValues);
  };

  const handleChange = (name, value) => {
    setValues(values => ({ ...values, [name]: value }));
  };

  const handleAction = async () => {
    try {
      const emails = [
        values.email !== '' && typeof values.email === 'string' && values.email.trim(),
        values.altEmail !== '' && typeof values.altEmail === 'string' && values.altEmail.trim(),
        values.otherEmail !== '' &&
          typeof values.otherEmail === 'string' &&
          values.otherEmail.trim(),
      ].filter(el => el);
      if (emails.length <= 0)
        throw new Error(setInputErrorFormat('email', 'Ingresa un correo electrónico.'));
      if (props.complementId)
        await sendComplement({ variables: { id: props.complementId, emails } });
      if (props.id) await sendSale({ variables: { id: props.id, emails } });
      handleClose();
      setValues(defaultValues);
    } catch (e) {
      getInputErrorFormat(e);
    }
  };

  return (
    <FormDialog
      title=''
      isOpen={props.isOpen}
      isLoading={loading || loadingComplement}
      handleClose={handleClose}
      handleAction={handleAction}
      actionLabel='Enviar'
    >
      <div>
        <Typography variant='h5' gutterBottom>
          Reenviar PDF
        </Typography>
        <Typography variant='subtitle1'>
          Asegúrate de que el correo proporcionado sea el correcto para hacer llegar al archivo
          satisfactoriamente.
        </Typography>
        <TextField
          label='Correo 1'
          name='email'
          value={values.email}
          error={errors.email}
          onChange={handleChange}
          noFormat
          variant='outlined'
        />
        <TextField
          label='Correo 2'
          name='altEmail'
          value={values.altEmail}
          error={errors.altEmail}
          onChange={handleChange}
          noFormat
          variant='outlined'
        />
        <TextField
          label='Correo 3'
          name='otherEmail'
          value={values.otherEmail}
          error={errors.otherEmail}
          onChange={handleChange}
          noFormat
          variant='outlined'
        />
      </div>
    </FormDialog>
  );
};

export default ReSendEmailDialogComponent;
