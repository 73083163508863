var getCashFlowTotalsFromData = function (data) {
    return data.reduce(function (totals, el) {
        console.log(el);
        var total = el.total
            ? ['Retiro', 'Caja fuerte'].includes(el.action)
                ? -Math.abs(parseFloat(el.total.replace(',', '')))
                : parseFloat(el.total.replace(',', ''))
            : 0;
        return {
            total: totals.total + total,
        };
    }, { total: 0 });
};
export default getCashFlowTotalsFromData;
