import React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { DialogTitle, IconButton } from '@mui/material';
var HeaderDialogComponent = function (props) {
    var handleClose = function () {
        props.onClose && props.onClose();
    };
    return (React.createElement(DialogTitle, { className: 'flex flex-row justify-between items-center py-2 px-4 border-0 border-b border-solid border-[#d6d6d6]' },
        React.createElement("div", { className: 'text-[#1276c7] text-lg sm:text-2xl leading-none' }, props.title),
        !props.hideHeaderCloseButton && (React.createElement(IconButton, { onClick: handleClose },
            React.createElement(CloseIcon, null)))));
};
export default HeaderDialogComponent;
