import { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import { objectArraySort } from '../helpers/general';
import GQL_PROVIDER from '../apollo/gql/provider';

const useGetProviders = (variables, format) => {
  const [formattedData, setFormattedData] = useState({ count: 0, rows: [] });
  const { data, loading, error } = useQuery(GQL_PROVIDER.GET_PROVIDERS, { variables });

  useEffect(() => {
    if (data && data.providers) {
      const { count, rows } = data.providers;
      let formattedRows = rows;
      switch (format) {
        case 'select':
          formattedRows = rows.map(el => ({
            label: el.name.trim().toUpperCase(),
            value: el.id,
          }));
          break;
        default:
          break;
      }
      setFormattedData({ count, rows: objectArraySort(formattedRows, 'label') });
    }
  }, [data, format]);

  return { data: formattedData, loading, error };
};

export default useGetProviders;
