import React, { useEffect, useState } from 'react';

import { useLazyQuery, useMutation } from '@apollo/client';

import Grid from '@mui/material/Grid';

import Autocomplete from '../../presentationals/Autocomplete';
import FormDialog from '../../presentationals/FormDialog';
import TextField from '../../presentationals/TextField';

import GQL_PRODUCT from '../product/_gql';

import GQL from './_gql';

const defaultValues = {
  Products: [],
  productUnit: null,
  quantity: '',
};

const Element = props => {
  console.log(props.values);
  const handleChange = e => {
    const { name, value } = e.target;
    props.onChange(name, value, props.id);
  };
  const handleAutocompleteChange = (e, name, value) => {
    props.onChange(name, value, props.id);
  };
  return (
    <Grid container alignItems='center' spacing={1}>
      <Grid item xs={2}>
        {props.name}
      </Grid>
      <Grid item xs={2}>{`1 ${props.presentation}`}</Grid>
      <Grid item xs={1}>
        <strong>=</strong>
      </Grid>
      <Grid item xs={5}>
        <Autocomplete
          name='productPresentationId'
          label=''
          variant='outlined'
          onChange={handleAutocompleteChange}
          options={props.Products}
          value={props.values ? props.values.productPresentationId : null}
          noFormat
        />
      </Grid>
      <Grid item xs={2}>
        <TextField
          label=''
          name='quantity'
          value={props.values ? props.values.quantityReception : ''}
          noFormat
          type='number'
          variant='outlined'
          onChange={handleChange}
        />
      </Grid>
    </Grid>
  );
};

const FormComponent = props => {
  const [productsA, setProductsA] = useState([]);
  const [products, setProducts] = useState([]);
  const [values, setValues] = useState(defaultValues);

  const [getProducts, { data: productsData, loading: loadingGet }] = useLazyQuery(
    GQL_PRODUCT.GET_NAME_AND_PRESENTATIONS,
  );

  const [getTransfer, { data: transferData, loading }] = useLazyQuery(GQL.GET_BY_ID, {
    fetchPolicy: 'network-only',
  });

  const [createTransferReception, { loading: loadingCreate }] = useMutation(
    GQL.CREATE_TRANSFER_RECEPTION,
  );

  // const [confirmRequisition, { loading }] = useMutation(GQL.CONFIRM_STATUS);

  useEffect(() => {
    if (props.id) getTransfer({ variables: { id: props.id } });
  }, [props.id]);

  useEffect(() => {
    if (transferData)
      getProducts({
        variables: { limit: 0, includeBaseInPresentations: true, transferId: props.id },
      });
  }, [transferData]);

  useEffect(() => {
    if (transferData && productsData) {
      const data = transferData.transfer.TransferProducts.map(el => {
        return {
          id: el.id,
          name: el.Product.name.toUpperCase().trim(),
          presentation: el.ProductPresentation.name.trim(),
          quantity: el.quantity,
          quantityReception: el.PresentationReception ? el.PresentationReception.quantity : 1,
          productPresentationId: el.PresentationReception
            ? {
                label:
                  `${el.PresentationReception.Product.name.trim()} ${el.PresentationReception.ProductPresentation.name.trim()}`.trim(),
                value: el.PresentationReception.productPresentationId,
              }
            : null,
        };
      }).filter(tmp => tmp);
      setProducts(data);

      const tmpValues = transferData.transfer.TransferProducts.map(el => {
        return {
          id: el.id,
          quantity: el.quantity,
          quantityReception: el.PresentationReception ? el.PresentationReception.quantity : 1,
          presentationReceptionId: el.ProductPresentation.id,
          productPresentationId: el.PresentationReception
            ? {
                label:
                  `${el.PresentationReception.Product.name.trim()} ${el.PresentationReception.ProductPresentation.name.trim()}`.trim(),
                value: el.PresentationReception.productPresentationId,
              }
            : null,
        };
      }).filter(tmp => tmp);
      setValues(values => ({ ...values, Products: tmpValues }));
    }
  }, [transferData, productsData]);

  useEffect(() => {
    if (productsData && productsData.productsV2) {
      const tmpProducts = productsData.productsV2.rows.map(el => ({
        label: `${el.name.trim()} ${el.ProductPresentations[0].name.trim()}`.trim(),
        value: el.ProductPresentations[0].id,
      }));
      setProductsA(tmpProducts);
    }
  }, [productsData]);

  const handleAction = async () => {
    try {
      const Products = values.Products.map(el => {
        if (parseInt(el.id) > 0)
          return {
            productPresentationId: el.productPresentationId ? el.productPresentationId.value : null,
            presentationReceptionId: el.presentationReceptionId ? el.presentationReceptionId : null,
            quantity: el.quantityReception ? parseFloat(el.quantityReception) : null,
          };

        return false;
      }).filter(el => el);

      const variables = {
        transferId: props.id,
        Products,
      };
      await createTransferReception({ variables });
      setValues(defaultValues);
      props.handleClose();
    } catch (e) {
      console.log(e);
    }
  };

  const handleClose = () => {
    setValues(defaultValues);
    props.handleClose();
  };

  const handleElementChange = (name, value, id) => {
    console.log(name);
    const tmpProducts = values.Products.map(el => {
      if (parseInt(el.id) === parseInt(id))
        return {
          ...el,
          quantity: name === 'quantity' ? value : el.quantity,
          productPresentationId:
            name === 'productPresentationId' ? value : el.productPresentationId,
        };

      return el;
    });
    setValues(values => ({ ...values, Products: tmpProducts }));
  };

  return (
    <FormDialog
      isOpen={props.isOpen}
      isLoading={loading || loadingGet || loadingCreate}
      title='Confirmar'
      name='cart'
      singular='Cart'
      disableEscape
      width='md'
      handleClose={handleClose}
      handleAction={handleAction}
    >
      <Grid container spacing={1}>
        <Grid item xs={2}>
          <strong>Recivido</strong>
        </Grid>
        <Grid item xs={2}>
          <strong>Presentación</strong>
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={5}>
          <strong>Equivalente</strong>
        </Grid>
        <Grid item xs={2}>
          <strong>Cant. Equivalente</strong>
        </Grid>
      </Grid>
      {products
        .map(el => {
          return (
            <Element
              key={el.id}
              id={el.id}
              name={el.name}
              presentation={el.presentation}
              quantity={el.quantity}
              values={values.Products.find(elCh => parseInt(elCh.id) === parseInt(el.id))}
              onChange={handleElementChange}
              Products={productsA}
            />
          );
        })
        .filter(tmp => tmp)}
    </FormDialog>
  );
};

export default FormComponent;
