import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableRow } from '@mui/material';
import DialogComponent from '../../../presentationals/Dialog';
import useApolloLazyQuery from '../../../hooks/useApolloLazyQuery';
import GQL_PRODUCT_INVENTORY from '../../../apollo/gql/productInventory';
import LoadingProgress from '../../../presentationals/LoadingProgress';
import ErrorDataComponent from '../../../presentationals/ErrorData';
var SerialNumberProductsDialogComponent = forwardRef(function (props, ref) {
    var _a = useState(false), open = _a[0], setOpen = _a[1];
    var _b = useApolloLazyQuery(GQL_PRODUCT_INVENTORY.GET_SERIAL_NUMBER_BY_PRODUCT_ID), getData = _b[0], _c = _b[1], loading = _c.loading, data = _c.data;
    var handleOpen = function (productId) {
        getData({ variables: { productId: productId } });
        setOpen(true);
    };
    var handleClose = function () {
        setOpen(false);
    };
    useImperativeHandle(ref, function () { return ({
        open: handleOpen,
    }); });
    return (React.createElement(DialogComponent, { title: 'N\u00FAmeros de series dispoinbles', open: open, onClose: handleClose }, loading ? (React.createElement(LoadingProgress, null)) : !data ? (React.createElement(ErrorDataComponent, null)) : (React.createElement("div", { className: 'flex flex-1 flex-col' },
        React.createElement(TableContainer, null,
            React.createElement(Table, null,
                React.createElement(TableBody, null, data.map(function (el) { return (React.createElement(TableRow, { key: el.id },
                    React.createElement(TableCell, null, el.serialNumber))); }))))))));
});
export default SerialNumberProductsDialogComponent;
