import React, { useState, useEffect } from 'react';

import CreditCardIcon from '@mui/icons-material/CreditCardOutlined';
import SettingsIcon from '@mui/icons-material/SettingsOutlined';
import EmailIcon from '@mui/icons-material/EmailOutlined';
import ListAltIcon from '@mui/icons-material/ListAltOutlined';

import { actions } from '../../apollo/cache';

import Payment from './payment.dialog';
import Settings from './settings.dialog';
import ReSendDialog from './reSend.dialog';
import ProductsDialog from './products.dialog';

const ActionComponent = () => {
  const [isOpenPayment, setIsOpenPayment] = useState([false, null]);
  const [isOpenSettings, setIsOpenSettings] = useState([false, null, null]);
  const [isOpenReSend, setIsOpenReSend] = useState([false, null]);
  const [isOpenProduct, setIsOpenProduct] = useState([false, null]);

  const handlePayment = (e, { id }) => {
    setIsOpenPayment([true, id]);
  };
  const handleSettings = (e, { id, ...data }) => {
    setIsOpenSettings([true, id, data]);
  };

  const handleClosePayment = () => setIsOpenPayment([false, null]);
  const handleCloseSettings = () => setIsOpenSettings([false, null, null]);

  const handleProducts = (e, { purchaseOrderId }) => setIsOpenProduct([true, purchaseOrderId]);
  const handleCloseProducts = () => setIsOpenProduct([false, null]);

  const handleReSend = (e, { purchaseOrderId }) => {
    if (!purchaseOrderId) return false;
    setIsOpenReSend([true, purchaseOrderId]);
  };
  const handleCloseReSend = () => setIsOpenReSend([false, null]);

  useEffect(() => {
    actions(
      [
        {
          icon: <EmailIcon />,
          label: 'Reenviar PDF',
          onClick: handleReSend,
        },
        {
          icon: <ListAltIcon />,
          label: 'Productos',
          onClick: handleProducts,
        },
        {
          icon: <CreditCardIcon />,
          label: 'Abonar',
          onClick: handlePayment,
          disable: { key: 'paid', value: true },
        },
        {
          icon: <SettingsIcon />,
          label: 'Configuración',
          onClick: handleSettings,
          disable: { key: 'paid', value: true },
        },
      ].filter(el => el),
    );
    return () => {
      actions([]);
    };
  }, []);

  return (
    <>
      <ReSendDialog id={isOpenReSend[1]} isOpen={isOpenReSend[0]} handleClose={handleCloseReSend} />
      <Payment
        isOpen={isOpenPayment[0]}
        handleClose={handleClosePayment}
        accountPayableId={isOpenPayment[1]}
      />
      <Settings
        isOpen={isOpenSettings[0]}
        handleClose={handleCloseSettings}
        id={isOpenSettings[1]}
        data={isOpenSettings[2]}
      />
      <ProductsDialog
        isOpen={isOpenProduct[0]}
        handleClose={handleCloseProducts}
        purchaseOrderId={isOpenProduct[1]}
      />
    </>
  );
};

export default ActionComponent;
