import React, { useEffect, useState } from 'react';

import { useLazyQuery, useMutation } from '@apollo/client';

import FormDialog from '../../presentationals/FormDialog';
import TextField from '../../presentationals/TextField';

import { parseError } from '../../helpers';

import GQL from './_gql';
import useSnackbarDialog from '../../store/snackbar/snackbarDialog';

const defaultValue = {
  name: 0,
  countTotal: 0,
  count: 0,
  total: 0,
};

const Customer = props => {
  const { setSnackbar } = useSnackbarDialog();

  const [values, setValues] = useState(defaultValue);
  const [errors, setErrors] = useState({});

  const [getCalendarControl, { data: calendarControlData }] = useLazyQuery(GQL.CALENDAR_CONTROL, {
    fetchPolicy: 'network-only',
  });
  const [updateCalendarControl, { loading }] = useMutation(GQL.UPDATE_CALENDAR_CONTROL);

  useEffect(() => {
    if (props.id) getCalendarControl({ variables: { id: props.id } });
  }, [props.id]);

  useEffect(() => {
    if (calendarControlData)
      setValues(values => ({
        ...values,
        name: calendarControlData.calendarControl.name,
        countTotal: parseInt(calendarControlData.calendarControl.countTotal),
        count: parseInt(calendarControlData.calendarControl.count),
        total: parseFloat(calendarControlData.calendarControl.total),
      }));
  }, [calendarControlData]);

  const handleClose = () => {
    setValues(defaultValue);
    props.handleClose();
  };

  const handleAction = async () => {
    try {
      if (values.name === '') {
        const nameError = new Error('El nombre no pude ir vacio');
        nameError.name = 'name';
        throw nameError;
      }
      if (parseInt(values.total) <= 0) {
        const totalError = new Error('El costo total no debe de ser menor o igual a cero.');
        totalError.name = 'total';
        throw totalError;
      }
      if (parseInt(values.countTotal) <= 0) {
        const countTotalError = new Error(
          'El total de citas permitidas no debe de ser menor o igual a cero.',
        );
        countTotalError.name = 'countTotal';
        throw countTotalError;
      }
      if (parseInt(values.count) < 0) {
        const countError = new Error(
          'El total de citas realizadas actualmente no debe de ser menor cero.',
        );
        countError.name = 'count';
        throw countError;
      }
      await updateCalendarControl({
        variables: {
          ...values,
          countTotal: parseInt(values.countTotal),
          count: parseInt(values.count),
          id: props.id,
        },
      });
      handleClose();
    } catch (e) {
      if (['name', 'total', 'countTotal', 'count'].includes(e.name)) {
        setErrors({ [e.name]: e.message });
        return;
      }
      const parseErrors = parseError(e);
      parseErrors.forEach(el => {
        if (el.name === 'BAD_USER_INPUT') setErrors(el.message);
        else
          setSnackbar({
            variables: {
              isOpen: true,
              time: 3000,
              label: el.message,
              severity: 'error',
            },
          });
      });
    }
  };

  const handleChange = e => {
    const { name } = e.target;
    const { value } = e.target;
    setValues(values => ({ ...values, [name]: value }));
  };

  return (
    <FormDialog
      title='Editar'
      isOpen={props.isOpen || false}
      isLoading={loading}
      handleClose={handleClose}
      handleAction={handleAction}
    >
      <TextField
        name='name'
        label='Nombre'
        onChange={handleChange}
        value={values.name}
        error={errors.name}
        autoFocus
      />
      <TextField
        name='total'
        label='Costo'
        onChange={handleChange}
        value={values.total}
        error={errors.total}
        autoFocus
        type='number'
      />
      <TextField
        name='countTotal'
        label='Total de citas perimitidas'
        onChange={handleChange}
        value={values.countTotal}
        error={errors.countTotal}
        autoFocus
        type='number'
      />
      <TextField
        name='count'
        label='Total de citas realizadas actualmente'
        onChange={handleChange}
        value={values.count}
        error={errors.count}
        autoFocus
        type='number'
      />
    </FormDialog>
  );
};

export default Customer;
