import { createContext, useContext, useEffect } from 'react';
import { createStore, useStore } from 'zustand';
export var createProductFieldStore = function () {
    return createStore(function (set) { return ({
        searchBy: ['Nombre', 'name'],
        value: '',
        popoverOpen: null,
        setValue: function (value) { return set({ value: value }); },
        setSearchBy: function (label, value) { return set({ searchBy: [label, value] }); },
        setPopoverOpen: function (anchorEl) { return set({ popoverOpen: anchorEl }); },
    }); });
};
export var ProductFieldContext = createContext(null);
var useProductFieldStore = function () {
    var store = useContext(ProductFieldContext);
    if (store === null)
        throw new Error('ProductFieldProvider is missing in the component tree');
    var state = useStore(store, function (state) { return state; });
    useEffect(function () {
        var unsubscribe = store.subscribe(function () { });
        return function () { return unsubscribe(); };
    }, [store]);
    return state;
};
export var useProductFieldStoreInstance = function () {
    var store = useContext(ProductFieldContext);
    if (store === null)
        throw new Error('ProductFieldProvider is missing in the component tree');
    return store;
};
export default useProductFieldStore;
