import React from 'react';

import { ListItem, ListItemText } from '@mui/material';

import ListItemButton from './ListItemButton';

const ItemTableSearchDialogChooseBillingInformationBodyCreateFormCustomerComponent = props => {
  return (
    <ListItemButton
      id={props.id}
      identifier={props.identifier}
      businessName={props.businessName}
      tradename={props.tradename}
      taxRegime={props.taxRegime}
      background={props.background}
      zipCode={props.zipCode}
      businessZipCode={props.businessZipCode}
    >
      <ListItem>
        <ListItemText primary={`${props.identifier} ${props.businessName}`} />
      </ListItem>
    </ListItemButton>
  );
};

export default ItemTableSearchDialogChooseBillingInformationBodyCreateFormCustomerComponent;
