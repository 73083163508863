import { gql } from '@apollo/client';

const GET = gql`
  query PurchaseOrderProducts(
    $order: [String]
    $limit: Int
    $offset: Int
    $classificationId: ID
    $providerId: ID
    $productId: ID
    $start: Date
    $end: Date
    $name: String
  ) {
    purchaseOrderProducts(
      order: $order
      limit: $limit
      offset: $offset
      start: $start
      end: $end
      classificationId: $classificationId
      providerId: $providerId
      productId: $productId
      like: $name
    ) {
      count
      totals {
        id
        name
        qty
        amount
      }
      rows {
        id
        unitPrice
        subtotal
        discount
        iva
        quantity
        total
        createdAt
        Product {
          name
          sku
          code
          Classification {
            name
          }
        }
        PurchaseOrder {
          id
          folio
          serial
          subFolio
          Provider {
            name
          }
        }
      }
    }
  }
`;

export default { GET };
