import { useEffect } from 'react';
import GQL_BRANCH from '../apollo/gql/branch';
import useApolloLazyQuery from './useApolloLazyQuery';
var useGetBranchById = function (id) {
    var _a = useApolloLazyQuery(GQL_BRANCH.GET_BRANCH_BY_ID), getData = _a[0], _b = _a[1], loading = _b.loading, data = _b.data;
    useEffect(function () {
        if (id)
            getData({ variables: { id: id } });
    }, [id]);
    return { data: data, loading: loading };
};
export default useGetBranchById;
