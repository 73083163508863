import React from 'react';
import Form from './Form';
import Agenda from './Agenda';
var BodyFormCalendarComponent = function (props) {
    return (React.createElement("div", { className: 'grid grid-cols-1 md:grid-cols-3 md:gap-4 flex-1 overflow-auto' },
        React.createElement("div", { className: 'flex md:overflow-hidden' },
            React.createElement(Form, { disabled: props.disabled })),
        React.createElement("div", { className: 'flex col-span-1 md:col-span-2 p-4 md:pt-0 md:pl-0 md:overflow-hidden' },
            React.createElement(Agenda, null))));
};
export default BodyFormCalendarComponent;
