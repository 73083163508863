import React, { useState, useEffect } from 'react';

import { useQuery } from '@apollo/client';

import Typography from '@mui/material/Typography';

import FilterDrawer from '../../presentationals/FilterDrawer.v2';
import SelectField from '../../presentationals/FormComponents/SelectField';
import TextField from '../../presentationals/FormComponents/TextField';
import Autocomplete from '../../presentationals/FormComponents/Autocomplete';

import { filter } from '../../apollo/cache';
import GQL_BRANCHES from '../branch/_gql';
import GQL_PRODUCTS from '../product/_gql';

const FilterDialog = () => {
  const [values, setValues] = useState({ branchId: '', sku: '', productId: null });
  const [products, setProducts] = useState([]);

  const { data: dataBranches } = useQuery(GQL_BRANCHES.GET, { variables: { limit: 0 } });
  const { data: dataProducts } = useQuery(GQL_PRODUCTS.GET_NAME_AND_PRESENTATIONS, {
    variables: { limit: 0 },
  });

  useEffect(() => {
    if (dataProducts) {
      const tmp = dataProducts.productsV2.rows.map(el => ({
        label: `${String(el.code).padStart(3, '0')} ${el.name.trim()} ${
          el.sku ? ` | SKU ${el.sku.trim()}` : ''
        } ${el.barcode ? ` | C.B. ${el.barcode.trim()}` : ''}`.trim(),
        value: el.id,
      }));
      setProducts(tmp);
    }
  }, [dataProducts]);

  const handleAction = (e, data) => {
    const { startDate: start, endDate: end } = data;
    filter({
      branchTransferId: values.branchId !== '' ? values.branchId : null,
      sku: values.sku !== '' ? values.sku : null,
      productId: values.productId ? values.productId.value : null,
      start,
      end,
    });
  };

  const handleChange = (name, value) => {
    setValues(values => ({ ...values, [name]: value }));
  };

  return (
    <FilterDrawer handleAction={handleAction} showDates>
      <Typography variant='subtitle1'>Suc. Destino:</Typography>
      <SelectField
        name='branchId'
        variant='outlined'
        onChange={handleChange}
        size='medium'
        value={values.branchId}
        options={
          dataBranches
            ? dataBranches.branches.rows.map(el => ({
                value: el.id,
                label: el.name,
              }))
            : []
        }
      />
      <Typography variant='subtitle1'>SKU:</Typography>
      <TextField
        name='sku'
        variant='outlined'
        onChange={handleChange}
        value={values.sku}
        size='medium'
      />
      <Typography variant='subtitle1'>Producto:</Typography>
      <Autocomplete
        name='productId'
        onChange={handleChange}
        options={products}
        value={values.productId}
        size='medium'
      />
    </FilterDrawer>
  );
};

export default FilterDialog;
