import React, { useEffect, useState } from 'react';

import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';

import { useReactiveVar } from '@apollo/client';

import { currencyFormat } from '../../../../../../../helpers';
import { purchaseOrderCartItems } from '../../../../../../../apollo/cache';

const TotalsComponent = () => {
  const BG_COLOR = 'bg-[#f5f5f5]';
  const [totals, setTotals] = useState([0, 0, 0, 0]);

  const purchaseOrderCartItemsVar = useReactiveVar(purchaseOrderCartItems);

  useEffect(() => {
    const newTotals = purchaseOrderCartItemsVar.reduce(
      (totals, el) => {
        const subtotal = parseFloat(el.total);
        const iva = subtotal * (el.iva / 100);
        const ieps = subtotal * (el.ieps / 100);
        const total = subtotal + iva + ieps;
        return [subtotal + totals[0], iva + totals[1], ieps + totals[2], total + totals[3]];
      },
      [0, 0, 0, 0],
    );
    setTotals(newTotals);
  }, [purchaseOrderCartItemsVar]);

  return (
    <>
      <TableRow>
        <TableCell colSpan={6} align='right' className={BG_COLOR}>
          Subtotal:
        </TableCell>
        <TableCell align='right' className={BG_COLOR}>
          {`$${currencyFormat(totals[0])}`}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell colSpan={6} align='right' className={BG_COLOR}>
          IVA:
        </TableCell>
        <TableCell align='right' className={BG_COLOR}>
          {`$${currencyFormat(totals[1])}`}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell colSpan={6} align='right' className={BG_COLOR}>
          IEPS:
        </TableCell>
        <TableCell align='right' className={BG_COLOR}>
          {`$${currencyFormat(totals[2])}`}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell colSpan={6} align='right' className={BG_COLOR}>
          <strong>Total:</strong>
        </TableCell>
        <TableCell align='right' className={BG_COLOR}>
          <strong>{`$${currencyFormat(totals[3])}`}</strong>
        </TableCell>
      </TableRow>
    </>
  );
};

export default TotalsComponent;
