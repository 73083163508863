var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useEffect } from 'react';
import { createSearchParams, useNavigate } from 'react-router-dom';
import { useReactiveVar } from '@apollo/client';
import { isEditFormOpen } from '../../apollo/cache';
var RedirectEditFormToRouteComponent = function (props) {
    var navigate = useNavigate();
    var isEditFormOpenVar = useReactiveVar(isEditFormOpen);
    useEffect(function () {
        if (isEditFormOpenVar)
            navigate({
                pathname: "".concat(props.path || '').concat(isEditFormOpenVar),
                search: props.queryParams
                    ? "?".concat(createSearchParams(Object.keys(props.queryParams).reduce(function (newObj, key) {
                        var _a;
                        return (props.queryParams && props.queryParams[key] ? __assign(__assign({}, newObj), (_a = {}, _a[key] = props.queryParams[key], _a)) : newObj);
                    }, {})))
                    : undefined,
            });
        return function () {
            isEditFormOpen(false);
        };
    }, [isEditFormOpenVar]);
    return React.createElement("div", null);
};
export default RedirectEditFormToRouteComponent;
