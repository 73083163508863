import React from 'react';

import LinearProgress from '@mui/material/LinearProgress';
import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';

const Component = props => {
  const handleAction = () => {
    props.handleAction(props.params);
  };

  return (
    <Dialog
      open={props.isOpen || false}
      fullWidth={true}
      maxWidth='xs'
      aria-labelledby='Confirmar'
      aria-describedby='Dialogo de confirmación para validar un registro.'
    >
      {props.isLoading && <LinearProgress color='secondary' />}
      <DialogTitle>¿Estás seguro que deseas continuar?</DialogTitle>
      <DialogContent />
      <DialogActions>
        <Button onClick={props.handleClose} color='primary'>
          Cancelar
        </Button>
        <Button onClick={handleAction} color='primary' autoFocus>
          Si, estoy seguro.
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default Component;
