var getDefaultProportionTextValues = function () {
    return {
        att12: 0,
        att13: 0,
        att14: 0,
        att15: 0,
        att16: 0,
        att17: 0,
    };
};
export default getDefaultProportionTextValues;
