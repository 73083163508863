import React from 'react';

import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';

import FormDialog from '../../presentationals/FormDialog';
import Button from '../../presentationals/Button';

import PDF from '../sale.report.v2/_pdf';

const Component = props => {
  const handleClose = () => {
    props.handleClose();
  };
  const printPdf = showTransactions => {
    PDF(props.id, { print: 1, showTransactions });
    props.handleClose();
  };
  return (
    <FormDialog isOpen={props.isOpen} handleClose={handleClose} noActions>
      <Typography variant='h5' gutterBottom>
        Selecciona para imprimir la nota de venta.
      </Typography>
      <Grid container spacing={1} alignItems='center'>
        <Grid item xs={6}>
          <Button label='Generar PDF sin abonos' onClick={() => printPdf(0)} />
        </Grid>
        <Grid item xs={6}>
          <Button label='Generar PDF con abonos' onClick={() => printPdf(1)} />
        </Grid>
      </Grid>
    </FormDialog>
  );
};

export default Component;
