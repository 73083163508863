var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React, { useMemo } from 'react';
import { useReactiveVar } from '@apollo/client';
import SelectFieldComponent from '../../../../../presentationals/FormComponents/SelectField';
import getPlatformOptions from '../../../../../helpers/getPlatformOptions';
import { inputError } from '../../../../../apollo/cache';
var PlatformFieldProductPromotionPriceComponent = function (props) {
    var errors = useReactiveVar(inputError);
    var platformOptions = useMemo(function () { return __spreadArray([{ value: -1, label: 'Cualquier plataforma' }], getPlatformOptions(), true); }, []);
    return (React.createElement(SelectFieldComponent, { name: 'type', label: 'Plataforma', value: props.value, error: errors.type, onChange: props.onChamge, options: platformOptions }));
};
export default PlatformFieldProductPromotionPriceComponent;
