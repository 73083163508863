import React, { useEffect, useState } from 'react';

import { useMutation, useReactiveVar } from '@apollo/client';
import format from 'date-fns/format';
import locale from 'date-fns/locale/es';

import CommentOutlinedIcon from '@mui/icons-material/CommentOutlined';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/SaveOutlined';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/AddOutlined';

import Typography from '@mui/material/Typography';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Link from '@mui/material/Link';

import { currencyFormat } from '../../../../helpers/general';

import TextField from '../../../../presentationals/TextField';
import DateField from '../../../../presentationals/DateField';
import Checkbox from '../../../../presentationals/FormComponents/Checkbox';

import AuthDialog from '../../../cashSale/Header/auth.dialog';

import IconButton from './helpers/iconButton';

import GQL from '../../_gql';
import Ticket from '../../../sale.report.v2/_pdf';
import ExpensesDialog from '../../expenses.dialog';
import CommentDialog from './comment.dialog';
import getInputErrorFormat from '../../../sale.v2/Action/helpers/getInputErrorFormat';
import { inputError } from '../../../../apollo/cache';

const defaultValues = {
  id: null,
  quantity: 0,
  outDate: null,
  price: 0,
  i: null,
};

const TableComponent = props => {
  const errors = useReactiveVar(inputError);
  const [itemToEdit, setItemToEdit] = useState(null);
  const [values, setValues] = useState(defaultValues);
  const [productToPrint, setProductToPrint] = useState([]);
  const [isAuthFormOpen, setIsAuthFormOpen] = useState(false);
  const [isOpenExpenses, setIsOpenExpenses] = useState([false, null]);
  const [isOpenComment, setIsOpenComment] = useState([false, null]);
  const [totalExpense, setTotalExpense] = useState(0);

  const [deleteProduct] = useMutation(GQL.DELETE_PRODUCT, {
    update(cache, { data }) {
      cache.evict({ id: `SaleProduct:${data.deleteServiceOrderProduct.id}` });
      cache.gc();
      cache.modify({
        id: cache.identify({ __typename: 'Sale', id: props.id }),
        fields: {
          subtotal: subtotal => subtotal - data.deleteServiceOrderProduct.subtotal,
          taxes: taxes => taxes - data.deleteServiceOrderProduct.taxes,
          total: total => total - data.deleteServiceOrderProduct.total,
        },
      });
      cache.modify({
        fields: {
          saleServiceOrder(cachedSale, { fieldName, storeFieldName }) {
            const args = JSON.parse(storeFieldName.replace(`${fieldName}(`, '').slice(0, -1));
            if (args && args.id && parseInt(args.id) === parseInt(props.id))
              return {
                ...cachedSale,
                subtotal: cachedSale.subtotal - data.deleteServiceOrderProduct.subtotal,
                taxes: cachedSale.taxes - data.deleteServiceOrderProduct.taxes,
                total: cachedSale.total - data.deleteServiceOrderProduct.total,
              };
            return cachedSale;
          },
        },
      });
    },
  });
  const [updateProductServiceOrder, { loading: editLoading }] = useMutation(
    GQL.UPDATE_PRODUCT_SERVICE_ORDER,
  );

  useEffect(() => {
    if (props.data?.SaleExpenses && props.data.SaleExpenses.length > 0) {
      const newTotalExpense = props.data.SaleExpenses.reduce((total, el) => {
        return total + parseFloat(el.total);
      }, 0);
      setTotalExpense(newTotalExpense);
    }
  }, [props.data]);

  const handleDeleteItem = async (e, id) => {
    await deleteProduct({ variables: { id } });
  };

  const handleEdit = (e, i) => {
    const row = props.data.SaleProducts[i];
    setItemToEdit(i);
    setValues({
      saleId: props.id,
      quantity: row.quantity,
      productId: row.productId,
      price: parseFloat(parseFloat(row.withoutTaxes).toFixed(2)),
      outDate: row.outDate ? row.outDate : null,
      id: row.id,
      i,
    });
  };

  const handleClose = () => {
    setItemToEdit(null);
    setValues(defaultValues);
  };

  const handleAuthClose = () => {
    setIsAuthFormOpen(false);
  };

  const handleSave = async (e, pin) => {
    try {
      if (parseFloat(values.quantity) <= 0 || !values.quantity) {
        const e = new Error('La cantidad debe de ser mayor a 0.');
        e.name = 'quantity';
        e.input = 'INPUT';
        throw e;
      }
      if (parseFloat(values.price) <= 0 || !values.price) {
        const e = new Error('La precio unitario debe de ser mayor a 0.');
        e.name = 'price';
        e.input = 'INPUT';
        throw e;
      }
      await updateProductServiceOrder({
        variables: {
          ...values,
          quantity: parseFloat(values.quantity),
          price: parseFloat(values.price),
          pin: pin && pin,
        },
      });
      handleClose();
      setIsAuthFormOpen(false);
    } catch (e) {
      getInputErrorFormat(e);
    }
  };

  const handleChange = e => {
    const { name, value } = e.target;
    setValues(values => ({ ...values, [name]: value }));
  };

  const handleDateChange = (name, value) => {
    setValues(values => ({ ...values, [name]: value }));
  };

  const handleCheckChange = (name, checked) => {
    if (checked && !productToPrint.includes(name)) {
      setProductToPrint(productToPrint => [...productToPrint, name]);
    } else if (!checked && productToPrint.includes(name)) {
      const newProductToPrint = productToPrint.filter(el => name !== el);
      setProductToPrint(newProductToPrint);
    }
  };

  const handlePrint = e => {
    e.preventDefault();
    Ticket(props.id, { productsToShow: productToPrint });
  };

  const handleOpenExpenses = () => setIsOpenExpenses([true, props.id]);
  const handleExpensesClose = () => setIsOpenExpenses([false, null]);

  const handleCommentItem = (e, data) => {
    setIsOpenComment([true, data]);
  };
  const handleCommentClose = () => setIsOpenComment([false, null]);

  return (
    <>
      <TableContainer>
        <Table size='small'>
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell>Registro</TableCell>
              <TableCell>Salida</TableCell>
              <TableCell>Producto</TableCell>
              <TableCell align='center'>Costo</TableCell>
              <TableCell align='center'>Cant.</TableCell>
              <TableCell align='center'>P. Unitario</TableCell>
              <TableCell align='center'>Subtotal</TableCell>
              <TableCell align='center'>Descuento</TableCell>
              <TableCell align='right'>IVA</TableCell>
              <TableCell align='right'>Total</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.data &&
              props.data.SaleProducts.map((el, i) => (
                <TableRow key={i}>
                  <TableCell>
                    <div className='flex items-center'>
                      {itemToEdit !== i ? (
                        <>
                          <Checkbox
                            onChange={handleCheckChange}
                            values={[
                              {
                                name: el.id,
                                checked: productToPrint[el.id],
                              },
                            ]}
                          />
                          {!props.noEdit && (
                            <IconButton
                              label='Editar'
                              size='small'
                              icon={<EditIcon />}
                              params={i}
                              action={handleEdit}
                            />
                          )}
                          <IconButton
                            label='Eliminar'
                            icon={<DeleteOutlineIcon />}
                            params={el.id}
                            action={handleDeleteItem}
                            size='small'
                          />
                          <IconButton
                            label='Comentario'
                            icon={<CommentOutlinedIcon />}
                            params={el.id}
                            action={handleCommentItem}
                            size='small'
                          />
                        </>
                      ) : (
                        <>
                          <IconButton
                            label='Guardar cambios'
                            size='small'
                            icon={<SaveIcon />}
                            disabled={editLoading}
                            action={handleSave}
                          />
                          <IconButton
                            label='Cancelar'
                            size='small'
                            icon={<CloseIcon />}
                            params={i}
                            action={handleClose}
                          />
                        </>
                      )}
                    </div>
                  </TableCell>
                  <TableCell>
                    {format(new Date(el.clientCreatedAt), 'dd/MM/yyyy', { locale })}
                  </TableCell>
                  <TableCell>
                    {itemToEdit !== i ? (
                      el.outDate ? (
                        format(new Date(el.outDate), 'dd/MM/yyyy', { locale })
                      ) : (
                        '-'
                      )
                    ) : (
                      <div className='w-[130px]'>
                        <DateField
                          name='outDate'
                          onChange={handleDateChange}
                          value={values.outDate}
                          error={errors.outDate}
                        />
                      </div>
                    )}
                  </TableCell>
                  <TableCell>
                    {el.Product ? el.Product.name.trim() : el.name ? el.name : ''}
                    {el.commentary ? (
                      <>
                        <br />
                        <Typography variant='caption'>{el.commentary}</Typography>
                      </>
                    ) : (
                      ''
                    )}
                  </TableCell>
                  <TableCell>{currencyFormat(el.Product ? el.Product.cost : 0)}</TableCell>
                  <TableCell align='center'>
                    {itemToEdit !== i ? (
                      el.quantity
                    ) : (
                      <div className='w-[130px]'>
                        <TextField
                          name='quantity'
                          type='number'
                          onChange={handleChange}
                          value={values.quantity}
                          error={errors.quantity}
                        />
                      </div>
                    )}
                  </TableCell>
                  <TableCell align='center'>
                    {itemToEdit !== i ? (
                      currencyFormat(el.withoutTaxes)
                    ) : (
                      <div className='w-[130px]'>
                        <TextField
                          name='price'
                          type='number'
                          onChange={handleChange}
                          value={values.price}
                          error={errors.price}
                        />
                      </div>
                    )}
                  </TableCell>
                  <TableCell align='center'>{currencyFormat(el.subtotal)}</TableCell>
                  <TableCell align='center'>{currencyFormat(el.discount)}</TableCell>
                  <TableCell align='right'>{currencyFormat(el.taxes)}</TableCell>
                  <TableCell align='right'>{currencyFormat(el.total)}</TableCell>
                </TableRow>
              ))}
            <TableRow>
              <TableCell colSpan={10} align='right'>
                Subtotal:
              </TableCell>
              <TableCell align='right'>
                {currencyFormat(props.data ? props.data.subtotal : 0)}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell colSpan={10} align='right'>
                Gastos:
              </TableCell>
              <TableCell align='right'>{currencyFormat(0)}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell colSpan={10} align='right'>
                Descuento:
              </TableCell>
              <TableCell align='right'>
                {currencyFormat(props.data ? props.data.discount : 0)}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell colSpan={10} align='right'>
                IVA:
              </TableCell>
              <TableCell align='right'>
                {currencyFormat(props.data ? props.data.taxes : 0)}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell colSpan={10} align='right'>
                <strong>Total:</strong>
              </TableCell>
              <TableCell align='right'>
                <strong>{currencyFormat(props.data ? props.data.total : 0)}</strong>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <IconButton
                  label='Añadir'
                  size='small'
                  icon={<AddIcon />}
                  action={handleOpenExpenses}
                />
                Añadir Gastos
              </TableCell>
              <TableCell colSpan={9} align='right'>
                Gastos Extras:
              </TableCell>
              <TableCell align='right'>{currencyFormat(totalExpense)}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell colSpan={2}>
                <Link href='#' onClick={handlePrint}>
                  Imprimir con productos seleccionados
                </Link>
              </TableCell>
              <TableCell colSpan={8} align='right'>
                <strong>Importe:</strong>
              </TableCell>
              <TableCell align='right'>
                <strong>
                  {currencyFormat(
                    parseFloat(props.data ? props.data.total : 0) + parseFloat(totalExpense),
                  )}
                </strong>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <AuthDialog isOpen={isAuthFormOpen} handleClose={handleAuthClose} handleAction={handleSave} />
      <ExpensesDialog
        isOpen={isOpenExpenses[0]}
        id={isOpenExpenses[1]}
        folio={props.folio ? props.folio : ''}
        handleClose={handleExpensesClose}
      />
      <CommentDialog
        isOpen={isOpenComment[0]}
        id={isOpenComment[1]}
        handleClose={handleCommentClose}
      />
    </>
  );
};

export default TableComponent;
