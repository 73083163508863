import React, { useRef } from 'react';
import FooterDialogComponent from '../../../presentationals/Dialog/Footer';
import ClearButtonFooterCreatePaymentMethodComponent from './Footer/ClearButton';
import CreateButtonFooterCreatePaymentMethodComponent from './Footer/CreateButton';
import BodyPaymentMethodComponent from './Body';
var CreatePaymentMethodComponent = function () {
    var bodyPaymentMethodRef = useRef(null);
    var handleGetValues = function () {
        var _a;
        return (_a = bodyPaymentMethodRef.current) === null || _a === void 0 ? void 0 : _a.getValues();
    };
    var handleClear = function () {
        var _a;
        (_a = bodyPaymentMethodRef.current) === null || _a === void 0 ? void 0 : _a.onClear();
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(BodyPaymentMethodComponent, { ref: bodyPaymentMethodRef }),
        React.createElement(FooterDialogComponent, null,
            React.createElement("div", null),
            React.createElement("div", null,
                React.createElement(ClearButtonFooterCreatePaymentMethodComponent, { onClick: handleClear }),
                React.createElement(CreateButtonFooterCreatePaymentMethodComponent, { getValues: handleGetValues })))));
};
export default CreatePaymentMethodComponent;
