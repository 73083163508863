import setInputErrorFormat from '../../../sale.v2/Action/helpers/setInputErrorFormat';
var validateCreatePurchaseOrderItem = function (values) {
    if (!values.Product)
        throw new Error(setInputErrorFormat('productId', 'Selecciona el producto que deseas añadir al carrito.'));
    if (!values.productPresentationId)
        throw new Error(setInputErrorFormat('productPresentationId', 'Selecciona la presentación del producto.'));
    if (isNaN(parseFloat(values.quantity)) || parseFloat(values.quantity) <= 0)
        throw new Error(setInputErrorFormat('quantity', 'Proporciona la cantidad de productos adquiridos o por adquirir.'));
    if (isNaN(parseFloat(values.cost)) || parseFloat(values.cost) < 0)
        throw new Error(setInputErrorFormat('cost', 'Proporciona el costo del producto por unidad.'));
    if (values.updateSalePrice &&
        (isNaN(parseFloat(values.salePrice)) || parseFloat(values.salePrice) <= 0))
        throw new Error(setInputErrorFormat('salePrice', 'Proporciona el nuevo precio de venta.'));
};
export default validateCreatePurchaseOrderItem;
