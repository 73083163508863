import CREATE_CALENDAR_SCHEDULE_CUSTOMER from './createCalendarScheduleCustomer';
import CREATE_CALENDAR_SCHEDULE_CUSTOMERS from './createCalendarScheduleCustomers';
import CREATE_CUSTOMER from './createCustomer';
import CREATE_CUSTOMER_ADDRESS from './createCustomerAddress';
import CUSTOMER_AUTO_RECEIVABLE_CONFIG from './customerAutoReceivableConfig';
import EDIT_CUSTOMER from './editCustomer';
import FRAGMENT_CUSTOMER from './fragmentCustomer';
import FRAGMENT_CUSTOMER_ADDRESS from './fragmentCustomerAddress';
import FRAGMENT_CUSTOMER_BILLING_INFORMATION from './fragmentCustomerBillingInformation';
import GET_CUSTOMER_ADDRESSES from './getCustomerAddresses';
import GET_CUSTOMER_BY_ID from './getCustomerById';
import GET_CUSTOMERS from './getCustomers';
import UPDATE_CUSTOMER_AUTO_RECEIVABLE_CONFIG from './updateCustomerAutoReceivableConfig';
import UPDATE_CUSTOMER_QUOTE from './updateCustomerQuote';
import UPDATE_SCHEDULE_CUSTOMER from './updateScheduleCustomer';
var GQL_CUSTOMER = {
    CREATE_CALENDAR_SCHEDULE_CUSTOMER: CREATE_CALENDAR_SCHEDULE_CUSTOMER,
    CREATE_CALENDAR_SCHEDULE_CUSTOMERS: CREATE_CALENDAR_SCHEDULE_CUSTOMERS,
    CREATE_CUSTOMER: CREATE_CUSTOMER,
    CREATE_CUSTOMER_ADDRESS: CREATE_CUSTOMER_ADDRESS,
    CUSTOMER_AUTO_RECEIVABLE_CONFIG: CUSTOMER_AUTO_RECEIVABLE_CONFIG,
    EDIT_CUSTOMER: EDIT_CUSTOMER,
    FRAGMENT_CUSTOMER: FRAGMENT_CUSTOMER,
    FRAGMENT_CUSTOMER_ADDRESS: FRAGMENT_CUSTOMER_ADDRESS,
    FRAGMENT_CUSTOMER_BILLING_INFORMATION: FRAGMENT_CUSTOMER_BILLING_INFORMATION,
    GET_CUSTOMER_ADDRESSES: GET_CUSTOMER_ADDRESSES,
    GET_CUSTOMERS: GET_CUSTOMERS,
    GET_CUSTOMER_BY_ID: GET_CUSTOMER_BY_ID,
    UPDATE_CUSTOMER_AUTO_RECEIVABLE_CONFIG: UPDATE_CUSTOMER_AUTO_RECEIVABLE_CONFIG,
    UPDATE_CUSTOMER_QUOTE: UPDATE_CUSTOMER_QUOTE,
    UPDATE_SCHEDULE_CUSTOMER: UPDATE_SCHEDULE_CUSTOMER,
};
export default GQL_CUSTOMER;
