import React, { useState, useEffect } from 'react';

import { useLazyQuery, useReactiveVar } from '@apollo/client';

import { Calendar, dateFnsLocalizer } from 'react-big-calendar';

import useTheme from '@mui/material/styles/useTheme';
import useMediaQuery from '@mui/material/useMediaQuery';

import AlarmIcon from '@mui/icons-material/Alarm';
import StoreIcon from '@mui/icons-material/Store';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

import subMonths from 'date-fns/subMonths';
import format from 'date-fns/format';
import parse from 'date-fns/parse';
import startOfWeek from 'date-fns/startOfWeek';
import getDay from 'date-fns/getDay';
import localeEs from 'date-fns/locale/es';
import startOfDay from 'date-fns/startOfDay';

import Grid from '@mui/material/Grid';
import LinearProgress from '@mui/material/LinearProgress';

import 'react-big-calendar/lib/sass/styles.scss';
import 'react-big-calendar/lib/addons/dragAndDrop/styles.scss';

import { filter, isCalendarFormOpen, isEditCalendarFormOpen } from '../../apollo/cache';
import statusLabel from '../../helpers/calendar.status';

import Plane from '../branch/PlaneZone/Plane';

import GQL from './_gql';
import Header from './header';
import Agenda from './Agenda';
import EditCalendarComponent from './Edit';
import PostponeCalendarComponent from './Postpone';
import subscribeSocket from '../../socket/helpers/subscribe';
import unsubscribeSocket from '../../socket/helpers/unsubscribe';
import useGetSession from '../../hooks/useGetSession';

const locales = { es: localeEs };

const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek,
  getDay,
  locales,
});

const messages = {
  next: '>',
  previous: '<',
  today: 'Hoy',
  month: 'Mes',
  week: 'Semana',
  day: 'Día',
  noEventsInRange: 'No hay eventos en este rango.',
  showMore: e => `+ ${e} más`,
};

const formats = {
  timeGutterFormat: (date, culture, localizer) => localizer.format(date, 'hh:mm aaa', culture),
  agendaTimeFormat: (date, culture, localizer) => localizer.format(date, 'hh:mm aaa', culture),
  agendaTimeRangeFormat: ({ start, end }, culture, localizer) =>
    `${localizer.format(start, 'hh:mm aaa', culture)} - ${localizer.format(
      end,
      'hh:mm aaa',
      culture,
    )}`,
};

const hourFormat = date => `${format(new Date(date), 'h:mmaaa', { locale: localeEs })}`;
const firstDay = format(subMonths(new Date(), 1), 'yyyy-MM-01', { locale: localeEs });

const components = {
  agenda: {
    event: props => {
      const handleClick = () => {
        isEditCalendarFormOpen(props.event.id);
      };
      const titleSplitted = props.title.split(' ');
      return (
        <div
          role='button'
          tabIndex='0'
          onClick={handleClick}
          className='flex flex-1 flex-row items-center'
        >
          <div className='mr-1'>
            <strong>{titleSplitted[0]}</strong>
          </div>
          <div className='mr-1'>{titleSplitted.map((el, i) => i > 0 && ` ${el}`)}</div>
          <div className='mr-1'>
            {props.event.status === 1 ? (
              <AlarmIcon fontSize='small' />
            ) : props.event.status === 4 ? (
              <StoreIcon fontSize='small' />
            ) : props.event.status === 5 ? (
              <CheckCircleOutlineIcon fontSize='small' />
            ) : (
              statusLabel[props.event.status]
            )}
          </div>
        </div>
      );
    },
  },
};

const CalendarComponent = () => {
  const session = useGetSession();

  const Theme = useTheme();
  const isSmallScreen = useMediaQuery(Theme.breakpoints.down('lg'));

  const filterVar = useReactiveVar(filter);

  const [myEventsList, setMyEventsList] = useState([]);
  const [dataSubsCalendarId, setDataSubsCalendarId] = useState(null);
  const [dataSubsPlaneZoneId, setDataSubsPlaneZoneId] = useState(null);

  const [getCalendars, { data, loading }] = useLazyQuery(GQL.GET, {
    fetchPolicy: 'cache-and-network',
  });

  const handleEvent = event => {
    isEditCalendarFormOpen(event.id);
  };

  useEffect(() => {
    const f = data => {
      setDataSubsCalendarId(data.calendarId);
      setDataSubsPlaneZoneId(data.planeZoneId);
    };
    subscribeSocket('EVENT_ZONE', f);
    return () => {
      unsubscribeSocket('EVENT_ZONE', f);
    };
  }, []);

  useEffect(() => {
    if (filterVar.tags && Object.keys(filterVar.tags).length > 0)
      getCalendars({
        variables: {
          start: firstDay,
          end: new Date('2099-01-01'),
          tagId: Object.keys(filterVar.tags).filter(key => filterVar.tags[key]),
          limit: 0,
          withCrm: true,
        },
      });
  }, [filterVar, dataSubsCalendarId, dataSubsPlaneZoneId]);

  useEffect(() => {
    if (data) {
      const tmp = data.calendars.rows
        .map(
          el =>
            ((!filterVar.showAll && [1, 2, 4].includes(parseInt(el.status))) ||
              filterVar.showAll) && {
              id: el.id,
              title: el.Customer
                ? `${hourFormat(new Date(el.start))} ${el.Customer.firstName} ${
                    el.Customer.lastName
                  }`
                : `${hourFormat(new Date(el.start))} ${el.title}`,
              start: new Date(el.start),
              end: new Date(el.end),
              color: el.status === 2 ? '#7b7b7b' : el.Tag ? el.Tag.color : '#ff0000',
              status: el.status,
            },
        )
        .filter(el => el);
      setMyEventsList(tmp);
    }
  }, [data, filterVar.showAll]);

  const handleStoreEvent = el => {
    setMyEventsList(myEventsList => [
      ...myEventsList,
      {
        id: el.id,
        title: el.Customer
          ? `${hourFormat(new Date(el.start))} ${el.Customer.label}`
          : `${hourFormat(new Date(el.start))} ${el.title}`,
        start: new Date(el.start),
        end: new Date(el.end),
        color: el.Tag ? el.Tag.color : '#ff0000',
        status: 1,
      },
    ]);
  };

  const handleSelect = ({ start, end }) => {
    isCalendarFormOpen([true, { start, end }, handleStoreEvent]);
  };

  const eventPropGetter = event => {
    if ([4, 5].includes(parseInt(event.status)))
      return {
        style: { backgroundColor: '#fff', border: `1px solid ${event.color}`, color: event.color },
      };

    return {
      style: { backgroundColor: event.color, color: '#fff' },
    };
  };

  return (
    <>
      {loading && <LinearProgress />}
      <Header handleStoreEvent={handleStoreEvent} />
      <div className='p-2 flex flex-1 flex-col overflow-auto bg-slate-50'>
        {isSmallScreen ? (
          <Agenda justDate />
        ) : session?.Company?.Role?.id === '81' ? (
          <Grid container spacing={1}>
            <Grid item md={7} style={{ height: '84.5vh' }}>
              <Calendar
                eventPropGetter={eventPropGetter}
                components={components}
                selectable
                localizer={localizer}
                culture='es'
                events={myEventsList}
                startAccessor='start'
                endAccessor='end'
                messages={messages}
                onSelectEvent={handleEvent}
                onSelectSlot={handleSelect}
                formats={formats}
                defaultDate={new Date(startOfDay(new Date()))}
              />
            </Grid>
            <Grid item md={5}>
              {session?.Company && <Plane branchId={session.Company.Branch.id} />}
            </Grid>
          </Grid>
        ) : (
          <Calendar
            eventPropGetter={eventPropGetter}
            components={components}
            selectable
            localizer={localizer}
            culture='es'
            events={myEventsList}
            startAccessor='start'
            endAccessor='end'
            messages={messages}
            onSelectEvent={handleEvent}
            onSelectSlot={handleSelect}
            formats={formats}
            defaultDate={new Date(startOfDay(new Date()))}
          />
        )}
      </div>
      <EditCalendarComponent />
      <PostponeCalendarComponent />
    </>
  );
};

export default CalendarComponent;
