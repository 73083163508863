import React from 'react';
import { useReactiveVar } from '@apollo/client';
import DialogComponent from '../../../presentationals/Dialog';
import { isCloseRequisitionOpen } from '../../../apollo/cache';
import BodyCloseRequisitionComponent from './Body';
var CloseRequisitionComponent = function () {
    var isOpen = useReactiveVar(isCloseRequisitionOpen);
    var handleClose = function () {
        isCloseRequisitionOpen(false);
    };
    return (React.createElement(DialogComponent, { fullScreen: true, open: !!isOpen, title: 'Cierre de requisici\u00F3n', onClose: handleClose },
        React.createElement(BodyCloseRequisitionComponent, null)));
};
export default CloseRequisitionComponent;
