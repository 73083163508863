var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
var getFormattedCustomerHistory = function (customerHistory) {
    return customerHistory.reduce(function (object, el) {
        var inArray = object.find(function (elCh) { return elCh.name === el.diagnosis; });
        return inArray
            ? object.map(function (elCh) {
                return elCh.name === el.diagnosis ? __assign(__assign({}, elCh), { rows: __spreadArray(__spreadArray([], elCh.rows, true), [el], false) }) : elCh;
            })
            : __spreadArray(__spreadArray([], object, true), [{ name: el.diagnosis, rows: [el] }], false);
    }, []);
};
export default getFormattedCustomerHistory;
