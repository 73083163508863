import React from 'react';

import { useReactiveVar } from '@apollo/client';

import PercentIcon from '@mui/icons-material/Percent';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';

import { IconButton } from '@mui/material';

import { cartItems, discountTypeSaleV2, form } from '../../../../../../apollo/cache';
import getInputErrorFormat from '../../../../Action/helpers/getInputErrorFormat';

const IconButtonDiscountFieldCartFormChooseProductSaleV2Component = () => {
  const discountTypeSaleV2Var = useReactiveVar(discountTypeSaleV2);

  const handleChange = () => {
    try {
      const cartItemsVar = cartItems();

      if (cartItemsVar.length > 0)
        throw new Error('Para cambiar el tipo de descuento, debes tener el carrito vacío.');

      const formVar = form();
      const newDiscountType = discountTypeSaleV2Var === 1 ? 2 : 1;

      discountTypeSaleV2(newDiscountType);
      form({ ...formVar, discount: 0 });
    } catch (e) {
      getInputErrorFormat(e);
    }
  };
  return (
    <IconButton onClick={handleChange} aria-label='changeDiscountType' className='flex'>
      {discountTypeSaleV2Var === 1 ? (
        <PercentIcon fontSize='small' />
      ) : (
        <AttachMoneyIcon fontSize='small' />
      )}
    </IconButton>
  );
};

export default IconButtonDiscountFieldCartFormChooseProductSaleV2Component;
