var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import ItemDetailSurveyResultComponent from './Item';
var DetailSurveyResultsComponent = function (props) {
    return (React.createElement("div", { className: 'flex flex-1 flex-col rounded border-solid border-slate-300 px-4 py-3 overflow-auto' }, props.data.map(function (el) { return (React.createElement(ItemDetailSurveyResultComponent, __assign({ key: el.id }, el))); })));
};
export default DetailSurveyResultsComponent;
