import React, { useState } from 'react';

import Typography from '@mui/material/Typography';

import FormDialog from '../../../presentationals/FormDialog';
import TextField from '../../../presentationals/TextField';

const defaultValues = {
  pin: '',
};

const InvoiceDialogComponent = props => {
  const [values, setValues] = useState(defaultValues);

  const handleClose = () => {
    props.handleClose();
  };

  const handleChange = e => {
    const { name } = e.target;
    const { value } = e.target;
    setValues(values => ({ ...values, [name]: value }));
  };

  const handleAction = () => {
    props.handleAction(null, values.pin);
  };

  return (
    <FormDialog isOpen={props.isOpen} handleClose={handleClose} handleAction={handleAction}>
      <Typography variant='h5' gutterBottom>
        ¡Detectamos que uno de los productos de la venta no cuenta con suficiente inventario, se
        require autorización!
      </Typography>
      <TextField
        label='PIN'
        name='pin'
        value={values.pin}
        onChange={handleChange}
        noFormat
        variant='outlined'
        type='password'
      />
    </FormDialog>
  );
};

export default InvoiceDialogComponent;
