import React, { useEffect, useState } from 'react';
import SelectFieldComponent from '../SelectField';
import getSettings from '../../../helpers/getSettings';
var SelectTaxBehaviorFieldComponent = function (props) {
    var taxesOptions = [
        { value: 1, label: 'Desglosar impuestos' },
        { value: 2, label: 'Añadir impuestos' },
    ];
    var _a = useState(1), value = _a[0], setValue = _a[1];
    useEffect(function () {
        var settings = getSettings();
        var formatSaleV2 = settings.defaultFormatSaleV2[1];
        setValue(formatSaleV2);
    }, []);
    useEffect(function () {
        if (props.value)
            setValue(props.value);
    }, [props.value]);
    var handleChange = function (name, value) {
        setValue(parseInt(value));
        props.onChange(name, value);
    };
    return (React.createElement(SelectFieldComponent, { autoFocus: true, label: props.label || '¿Qué deseás hacer con los impuestos?', name: props.name || 'taxesOption', value: value, onChange: handleChange, options: taxesOptions }));
};
export default SelectTaxBehaviorFieldComponent;
