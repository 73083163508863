import React from 'react';
import SearchIcon from '@mui/icons-material/SearchOutlined';
import { InputAdornment } from '@mui/material';
import OutlinedField from '../../../../../../../../presentationals/FormComponents/OutlinedField';
import IconButton from '../../../../../../../../presentationals/IconButton';
import { isSatUnitKeyDialogOpen, searchUnitKeyField } from '../../../../../../../../apollo/cache';
var UnitKeyProductComponent = function (props) {
    var handleClick = function () {
        isSatUnitKeyDialogOpen(true);
        searchUnitKeyField(props.value);
    };
    return (React.createElement(OutlinedField, { label: 'C\u00F3digo de unidad de medida (SAT)', name: 'unitKey', value: props.value, error: props.error, onChange: props.onChange, onKeyDown: props.onKeyPress, endAdornment: React.createElement(InputAdornment, { position: 'end' },
            React.createElement(IconButton, { action: handleClick, size: 'small', className: 'p-0 m-0', icon: React.createElement(SearchIcon, { fontSize: 'small' }) })) }));
};
export default UnitKeyProductComponent;
