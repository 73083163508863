import React from 'react';
import useGetCashClosings from './helpers/useGetCashClosings';
import ModuleV3Component from '../../presentationals/Module.v3';
import FilterCashClosingReportDialog from './Filter';
// import RedirectEditFormToRouteComponent from '../../presentationals/RedirectEditFormToRoute';
import getCashClosingActions from './actions';
import RedirectCreateFormToRouteComponent from '../../presentationals/RedirectCreateFormToRoute';
var CashClosingComponent = function () {
    var _a = useGetCashClosings(), data = _a.data, loading = _a.loading;
    var cashClosingActions = getCashClosingActions();
    var Header = [
        { Header: 'Creado el', accessor: 'clientCreatedAt' },
        { Header: 'Cerrado el', accessor: 'closedAt' },
        { Header: 'Empleado', accessor: 'cashClosingEmployee' },
        { Header: 'Saldo inicial', accessor: 'pettyCashAmount' },
        { Header: 'Saldo efectivo al cierre', accessor: 'virtualBalance' },
        { Header: 'Declaración del empleado', accessor: 'realBalance' },
        { Header: 'Diferencia', accessor: 'difference' },
    ];
    return (React.createElement(React.Fragment, null,
        React.createElement(ModuleV3Component, { actions: cashClosingActions, filter: true, title: 'Corte de caja', data: data, header: Header, isLoading: loading }),
        React.createElement(FilterCashClosingReportDialog, null),
        React.createElement(RedirectCreateFormToRouteComponent, null)));
};
export default CashClosingComponent;
