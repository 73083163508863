import React, { useState, useEffect, Fragment } from 'react';

import { useLazyQuery, useReactiveVar } from '@apollo/client';

import LinearProgress from '@mui/material/LinearProgress';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';

import { filter, count, totals } from '../../../apollo/cache';

import { currencyFormat } from '../../../helpers';

import Table from '../../../presentationals/Module.v2/Table/TableContainer';
import Head from '../../../presentationals/Module.v2/Table/Head';
import TableCell from '../../../presentationals/Module.v2/Table/Body/TableCell';
import format from '../../../presentationals/Module.v2/Table/Body/format';
import ActionButton from '../../../presentationals/Module.v2/Table/Body/ActionButton';
import PdfExport from '../../../presentationals/Module.v2/PdfExport';

import XlsExport from './xlsExport';

import GQL from '../_gql';

const Rows = [
  { label: 'Folio', key: 'serial', prefix: 'I', padStart: [5, '0'], sort: 'text', filter: true },
  { label: 'Fecha de Compra', key: 'clientCreatedAt', sort: 'text', format: 'dateTime' },
  { label: 'Fecha de Vencimiento', key: 'limitDate', sort: 'text', format: 'dateTime' },
  { label: 'Factura', key: 'PurchaseOrder subFolio', sort: 'text', filter: true },
  { label: 'OC', key: 'PurchaseOrder serial', prefix: 'OC', sort: 'text', filter: true },
  { label: 'Sucursal', key: 'Branch name', sort: 'text', filter: true },
  { label: 'Moneda', key: 'currency', sort: 'text', filter: true },
  { label: 'Descuento', key: 'discount', sort: 'text', format: 'currency', align: 'right' },
  { label: 'Subtotal', key: 'subtotal', sort: 'text', format: 'currency', align: 'right' },
  { label: 'IVA', key: 'taxes', sort: 'text', format: 'currency', align: 'right' },
  { label: 'Total', key: 'total', sort: 'text', format: 'currency', align: 'right' },
  { label: 'Dls', key: 'usdTotal', sort: 'text', format: 'currencyDls', align: 'right' },
  { label: 'Abonado', key: 'payment', sort: 'text', format: 'currency', align: 'right' },
  { label: 'Saldo', key: 'balance', sort: 'text', format: 'currency', align: 'right' },
];

const CxcTableComponent = props => {
  const filterVar = useReactiveVar(filter);

  const [data, setData] = useState({});

  const [getMore, { data: response, loading }] = useLazyQuery(GQL.GET);

  useEffect(() => {
    getMore({ variables: { ...filterVar, limit: 1000 } });
  }, [filterVar]);

  useEffect(() => {
    if (response) {
      const tmpKeys = response.accountPayables.rows.reduce(
        (object, el) => ({
          ...object,
          [el.Provider.id]: {
            customerName: el.Provider.name,
            data: [],
          },
        }),
        {},
      );
      const tmpData = response.accountPayables.rows.reduce((object, el) => {
        return {
          ...object,
          [el.Provider.id]: {
            customerName: object[el.Provider.id].customerName,
            data: [...object[el.Provider.id].data, el],
          },
        };
      }, tmpKeys);

      Object.keys(tmpData).forEach(key => {
        const { total, balance, payment } = tmpData[key].data.reduce(
          (totals, tmp) => {
            return {
              total: parseFloat(totals.total) + parseFloat(tmp.total),
              payment: parseFloat(totals.payment) + parseFloat(tmp.payment),
              balance: parseFloat(totals.balance) + parseFloat(tmp.balance),
            };
          },
          { total: 0, payment: 0, balance: 0 },
        );
        tmpData[key] = { ...tmpData[key], total, balance, payment };
      });

      setData(tmpData);

      if (response && response.accountPayables.count >= 0) count(response.accountPayables.count);

      if (response && response.accountPayables.totals) {
        const newTotals = response.accountPayables.totals.map(el => ({
          value: `$${currencyFormat(el.amount)}`,
          label: `${el.name}${el.qty ? `(${el.qty})` : ''}`,
        }));
        totals(newTotals);
      }
    }
  }, [response]);

  return (
    <>
      {loading && <LinearProgress color='secondary' />}
      <Table>
        <Head Rows={Rows} customXls />
        <TableBody>
          {Object.keys(data).map((key, i) => {
            return (
              <Fragment key={`${key}${i}`}>
                <TableRow>
                  <TableCell colSpan={9}>
                    <strong>{data[key].customerName}</strong>
                  </TableCell>
                  <TableCell align='right'>
                    <strong>${currencyFormat(data[key].total)}</strong>
                  </TableCell>
                  <TableCell />
                  <TableCell align='right'>
                    <strong>${currencyFormat(data[key].payment)}</strong>
                  </TableCell>
                  <TableCell align='right'>
                    <strong>${currencyFormat(data[key].balance)}</strong>
                  </TableCell>
                  <TableCell />
                </TableRow>
                {data[key].data.map((dataCh, ii) => {
                  return (
                    <TableRow
                      key={ii}
                      style={{
                        background: dataCh.background
                          ? dataCh.background === 'danger'
                            ? '#ff4500'
                            : dataCh.background === 'warning'
                            ? '#ffff00'
                            : '#ffffff'
                          : '#ffffff',
                      }}
                    >
                      {Rows.map(el => {
                        let dataFormatTmp = el.format;
                        let value = el.key.includes(' ')
                          ? el.key.includes(' && ')
                            ? el.key.split(' && ').reduce((stringPincipal, elPincipal) => {
                                const res = elPincipal.split(' ').reduce((string, elCh) => {
                                  const tmpData = string[elCh] || '';
                                  return tmpData;
                                }, dataCh);
                                return stringPincipal !== ''
                                  ? `${stringPincipal}${el.strcat || ' '}${res}`
                                  : res;
                              }, '')
                            : el.key.split(' ').reduce((string, elCh) => {
                                const tmpData = string[elCh] || '';
                                return tmpData;
                              }, dataCh)
                          : dataCh[el.key];
                        if (String(value).trim() === '') value = el.ifNull || '-';
                        if (dataFormatTmp === 'currency') {
                          dataFormatTmp = null;
                          value = !Number.isNaN(parseFloat(value)) ? parseFloat(value) : 0;
                        }
                        if (el.padStart && dataFormatTmp !== 'currency')
                          value = `${value}`.padStart(el.padStart[0], el.padStart[1]);
                        if (el.prefix && dataFormatTmp !== 'currency')
                          value = `${el.prefix} ${value}`;
                        return (
                          <TableCell
                            key={el.key}
                            align={el.align}
                            noWrap={!el.wrap}
                            url={value}
                            format={el.format}
                          >
                            {format(value, el.format)}
                          </TableCell>
                        );
                      })}
                      <TableCell align='right' noWrap>
                        <ActionButton data={dataCh} />
                      </TableCell>
                    </TableRow>
                  );
                })}
              </Fragment>
            );
          })}
        </TableBody>
      </Table>
      <PdfExport title={props.title} gql={GQL.GET} keys={Rows} />
      <XlsExport title={props.title} data={data} keys={Rows} />
    </>
  );
};

export default CxcTableComponent;
