import React, { useState } from 'react';

import { useMutation, useReactiveVar } from '@apollo/client';

import FormDialog from '../../presentationals/FormDialog';
import TextField from '../../presentationals/TextField';
import { filter } from '../../apollo/cache';
import { parseError } from '../../helpers';

import GQL from './_gql';
import useSnackbarDialog from '../../store/snackbar/snackbarDialog';

const defaultValues = {
  sessionsTaken: '',
};

const Component = props => {
  const { setSnackbar } = useSnackbarDialog();

  const [values, setValues] = useState(defaultValues);
  const [errors, setErrors] = useState({});
  const [updateCrm, { loading }] = useMutation(GQL.UPDATE);
  const filterVar = useReactiveVar(filter);

  const handleClose = () => {
    setValues(defaultValues);
    props.handleClose();
  };

  const handleChange = e => {
    const { name } = e.target;
    const { value } = e.target;
    setValues(values => ({ ...values, [name]: value }));
  };

  const handleAction = async () => {
    try {
      if (values.sessionsTaken === '' || parseFloat(values.sessionsTaken) < 0) {
        const totalError = new Error('La cantidad de abono tiene que ser mayor a cero.');
        totalError.name = 'sessionsTaken';
        totalError.input = true;
        throw totalError;
      }
      const variables = {
        id: props.crmId,
        sessionsTaken: parseFloat(values.sessionsTaken),
      };
      await updateCrm({ variables });
      setValues(defaultValues);
      filter({ ...filterVar });
      handleClose();
    } catch (e) {
      if (e.input) {
        setErrors({ [e.name]: e.message });
        return;
      }
      const parseErrors = parseError(e);
      parseErrors.forEach(el => {
        if (el.name === 'BAD_USER_INPUT') setErrors(el.message);
        else
          setSnackbar({
            variables: {
              isOpen: true,
              time: 3000,
              label: el.message,
              severity: 'error',
            },
          });
      });
    }
  };

  return (
    <FormDialog
      title='Actualizar CRM'
      isOpen={props.isOpen}
      handleClose={handleClose}
      handleAction={handleAction}
      isLoading={loading}
    >
      <TextField
        value={values.sessionsTaken}
        error={errors.sessionsTaken}
        name='sessionsTaken'
        label='Cantidad de sesiones tomadas'
        onChange={handleChange}
        required
        autoFocus
      />
    </FormDialog>
  );
};

export default Component;
