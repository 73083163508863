import React from 'react';
import AddCircleIcon from '@mui/icons-material/AddOutlined';
import IconButtonComponent from '../../../../../presentationals/IconButton';
import { isAddProductPromotionOpen } from '../../../../../apollo/cache';
var OpenAddProductPromotionDialogButton = function () {
    var handleClick = function () {
        isAddProductPromotionOpen(true);
    };
    return (React.createElement(IconButtonComponent, { action: handleClick, icon: React.createElement(AddCircleIcon, null), size: 'small', color: 'secondary', label: 'A\u00F1adir producto' }));
};
export default OpenAddProductPromotionDialogButton;
