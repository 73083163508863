import React from 'react';

import { Button } from '@mui/material';
import {
  editPurchaseOrderItemForm,
  isEditPurchaseOrderItemOpen,
  purchaseOrderCartItems,
  purchaseOrderId,
} from '../../../../../../../apollo/cache';
import getInputErrorFormat from '../../../../../../sale.v2/Action/helpers/getInputErrorFormat';
import setInputErrorFormat from '../../../../../../sale.v2/Action/helpers/setInputErrorFormat';
import getDefaultValues from '../../../Table/Body/Item/helpers/getDefaultValues';
import useApolloMutation from '../../../../../../../hooks/useApolloMutation';
import GQL from '../../../../../_gql';

const CreateButtonFooterItemEditFormProductComponent = () => {
  const [updatePursacheOrderDetail, { loading }] = useApolloMutation(GQL.UPDATE_ITEM);
  const handleClick = async () => {
    try {
      const values = editPurchaseOrderItemForm();
      const purchaseOrderIdVar = purchaseOrderId();

      if (values.qty === '' || parseFloat(values.qty) <= 0)
        throw new Error(setInputErrorFormat('qty', 'Proporciona una cantidad mayor a cero.'));

      if (values.discount === '' || parseFloat(values.discount) < 0)
        throw new Error(
          setInputErrorFormat('discount', 'Proporciona un descuento igual o mayor a cero.'),
        );

      if (values.price === '' || parseFloat(values.price) < 0)
        throw new Error(
          setInputErrorFormat('price', 'Proporciona un precio igual o mayor a cero.'),
        );

      if (values.purchaseOrderDetailId && purchaseOrderIdVar) {
        await updatePursacheOrderDetail({
          variables: {
            purchaseOrderId: purchaseOrderIdVar,
            purchaseOrderDetailId: values.purchaseOrderDetailId,
            quantity: parseFloat(values.qty),
            unitPrice: parseFloat(values.price),
            discount: parseFloat(values.discount),
          },
        });
      } else {
        const purchaseOrderCartItemsVar = purchaseOrderCartItems();
        const tmpRows = purchaseOrderCartItemsVar.map((el, i) =>
          i === values.index
            ? {
                ...el,
                quantity: values.qty,
                price: values.price,
                total: parseFloat(values.qty) * parseFloat(values.price),
              }
            : el,
        );
        purchaseOrderCartItems(tmpRows);
      }

      const defaultValues = getDefaultValues();
      editPurchaseOrderItemForm(defaultValues);
      isEditPurchaseOrderItemOpen(false);
    } catch (e) {
      getInputErrorFormat(e);
    }
  };
  return (
    <Button
      disabled={loading}
      color='secondary'
      variant='contained'
      className='storeButton'
      onClick={handleClick}
    >
      Editar
    </Button>
  );
};

export default CreateButtonFooterItemEditFormProductComponent;
