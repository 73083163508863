var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var setNewPickupItem = function (data, newValues) {
    return data.map(function (el) {
        if (el.id !== newValues.saleProductId)
            return el;
        var receivedQty = parseFloat(newValues.qty || '0');
        if (receivedQty > el.quantity)
            throw new Error('La cantidad de piezas recuperadas no puede ser mayor a las que se entregaron.');
        return __assign(__assign({}, el), { receivedQty: receivedQty.toString(), commentary: newValues.commentary || '' });
    });
};
export default setNewPickupItem;
