import React from 'react';

import useTheme from '@mui/material/styles/useTheme';
import useMediaQuery from '@mui/material/useMediaQuery';

import Typography from '@mui/material/Typography';

const TitleComponent = props => {
  const subTitleVar = '';
  // const subTitleVar = useReactiveVar(subTitle);

  const Theme = useTheme();
  const isSmallScreen = useMediaQuery(Theme.breakpoints.down('lg'));

  return (
    <div>
      <Typography className='text-[#454545]' variant={isSmallScreen ? 'h5' : 'h4'}>
        {props.name}
      </Typography>
      {subTitleVar !== '' && <small>{subTitleVar}</small>}
    </div>
  );
};

export default TitleComponent;
