var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useReactiveVar } from '@apollo/client';
import FooterDialogComponent from '../../../../presentationals/Dialog/Footer';
import BodyProductVariationComponent from '../Body';
import DialogComponent from '../../../../presentationals/Dialog';
import EditProductVariationButtonComponent from './EditButton';
import useApolloLazyQuery from '../../../../hooks/useApolloLazyQuery';
import GQL_PRODUCT from '../../../../apollo/gql/product';
import LoadingProgress from '../../../../presentationals/LoadingProgress';
import { isEditProductVariationOpen, productVariationForm } from '../../../../apollo/cache';
var EditProductVariationComponent = function () {
    var params = useParams();
    var isOpen = useReactiveVar(isEditProductVariationOpen);
    var _a = useApolloLazyQuery(GQL_PRODUCT.GET_PRODUCT_VARIATION, {
        variables: { productPresentationId: params.productPresentationId },
    }), get = _a[0], _b = _a[1], loading = _b.loading, data = _b.data;
    useEffect(function () {
        if (isOpen)
            get({ variables: { id: isOpen } });
    }, [isOpen]);
    useEffect(function () {
        if (isOpen && data) {
            var values = productVariationForm();
            productVariationForm(__assign(__assign({}, values), { name: data.name, limitVariation: data.limitVariation, isRequired: data.isRequired, selectionType: data.selectionType }));
        }
    }, [isOpen, data]);
    var handleClose = function () {
        isEditProductVariationOpen(false);
    };
    return (React.createElement(DialogComponent, { fullScreen: true, open: !!isOpen, title: 'Editar el tipo de componente', onClose: handleClose }, loading ? (React.createElement(LoadingProgress, null)) : (React.createElement("div", { className: 'flex flex-1 flex-col' },
        React.createElement(BodyProductVariationComponent, null),
        React.createElement(FooterDialogComponent, null,
            React.createElement("div", null),
            React.createElement(EditProductVariationButtonComponent, null))))));
};
export default EditProductVariationComponent;
