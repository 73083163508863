var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React from 'react';
import { Button, Popover, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, } from '@mui/material';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import getInputErrorFormat from '../../sale.v2/Action/helpers/getInputErrorFormat';
import CONSTANTS_ERROR from '../../../constants/error';
import convertToCurrencyLabel from '../../../helpers/convertToCurrencyLabel';
import IconButtonComponent from '../../../presentationals/IconButton';
import useApolloMutation from '../../../hooks/useApolloMutation';
import GQL_ADJUST_INVENTORY from '../../../apollo/gql/adjustInventory';
import { snackbar } from '../../../apollo/cache';
import useAdjustInventoryStore from '../../../store/inventoryReport/adjustInventoryStore';
import useInventoryReportStore from '../../../store/inventoryReport';
var AdjustInventoryFromInventoryReportFormComponent = function () {
    var branchId = useInventoryReportStore(function (state) { return state.branchId; });
    var anchorEl = useAdjustInventoryStore(function (state) { return state.openAdjustInventoryForm; });
    var products = useAdjustInventoryStore(function (state) { return state.products; });
    var clearProducts = useAdjustInventoryStore(function (state) { return state.clearProducts; });
    var deleteProduct = useAdjustInventoryStore(function (state) { return state.deleteProduct; });
    var setAnchorEl = useAdjustInventoryStore(function (state) { return state.setOpenAdjustInventoryForm; });
    var _a = useApolloMutation(GQL_ADJUST_INVENTORY.CREATE_ADJUST_INVENTORY, {
        name: 'adjustInventories',
        fragment: GQL_ADJUST_INVENTORY.FRAGMENT_ADJUST_INVENTORY,
    }), createAjustInventory = _a[0], loading = _a[1].loading;
    var handleClose = function () {
        setAnchorEl(null);
    };
    var handleDelete = function (e, productPresentationId) {
        deleteProduct(productPresentationId);
        handleClose();
    };
    var handleCreate = function () { return __awaiter(void 0, void 0, void 0, function () {
        var variables, error_1;
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    _b.trys.push([0, 3, , 4]);
                    if (!branchId)
                        throw new Error(CONSTANTS_ERROR.UNDEFINED_BRANCH_ID);
                    if (products.length === 0)
                        throw new Error(CONSTANTS_ERROR.STORE_AT_LEAST_ONE_PRODUCT);
                    _a = {
                        isZero: false,
                        type: 1,
                        branchId: branchId
                    };
                    return [4 /*yield*/, Promise.all(products.map(function (el) { return ({
                            productId: el.productId,
                            productPresentationId: el.productPresentationId,
                            quantity: el.currentInvQty,
                        }); }))];
                case 1:
                    variables = (_a.Products = _b.sent(),
                        _a);
                    return [4 /*yield*/, createAjustInventory({ variables: variables })];
                case 2:
                    _b.sent();
                    clearProducts();
                    snackbar({
                        isOpen: true,
                        label: 'El inventario fue ajustado exitosamente.',
                        severity: 'success',
                    });
                    handleClose();
                    return [3 /*break*/, 4];
                case 3:
                    error_1 = _b.sent();
                    getInputErrorFormat(error_1);
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    }); };
    return (React.createElement(Popover, { id: 'FormAdjustInventoryFromInventoryReport', open: !!anchorEl, anchorEl: anchorEl, onClose: handleClose, anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'right',
        }, transformOrigin: {
            vertical: 'top',
            horizontal: 'right',
        }, slotProps: {
            root: {
                className: 'mb-8',
            },
            paper: {
                className: 'w-full sm:w-96 h-96 sm:h-56 overflow-hidden',
            },
        } },
        React.createElement("div", { className: 'flex flex-1 flex-col h-full' },
            React.createElement("div", { className: 'leading-0 text-lg pt-2 pb-1 px-4 font-medium bg-slate-100' }, "Ajuste de inventario"),
            React.createElement("div", { className: 'flex flex-1 flex-col basis-0 overflow-auto' },
                React.createElement(TableContainer, null,
                    React.createElement(Table, { size: 'small', stickyHeader: true },
                        React.createElement(TableHead, null,
                            React.createElement(TableRow, null,
                                React.createElement(TableCell, null, "Producto"),
                                React.createElement(TableCell, null, "Cantidad"),
                                React.createElement(TableCell, null))),
                        React.createElement(TableBody, null, products.map(function (el, i) { return (React.createElement(TableRow, { key: i },
                            React.createElement(TableCell, null,
                                React.createElement("div", { className: 'text-sm' }, "".concat(el.productName, " ").concat(el.productPresentationName)),
                                React.createElement("div", { className: 'text-xs text-gray-500' }, el.sku)),
                            React.createElement(TableCell, { className: 'text-sm' }, convertToCurrencyLabel(el.currentInvQty)),
                            React.createElement(TableCell, { className: 'flex flex-row justify-end' },
                                React.createElement(IconButtonComponent, { size: 'small', params: el.productPresentationId, action: handleDelete, icon: React.createElement(DeleteOutlineIcon, { fontSize: 'small' }) })))); }))))),
            React.createElement("div", { className: 'flex flex-row justify-end px-3 py-2' },
                React.createElement(Button, { disabled: loading, variant: 'contained', color: 'success', size: 'small', onClick: handleCreate }, "Ajustar inventario")))));
};
export default AdjustInventoryFromInventoryReportFormComponent;
