var getDefaultCreatePurchaseOrderValues = function () { return ({
    quantity: '1',
    price: '0',
    discountType: 1,
    discount: '0',
    productPresentationId: '',
    productId: null,
    adjustPrice: false,
    percentAdjust: '0',
    type: 1,
    concept: '',
    iva: '0',
    ieps: '0',
    serialNumber: '',
}); };
export default getDefaultCreatePurchaseOrderValues;
