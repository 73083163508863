import React from 'react';

import Container from '../../presentationals/Module.v2/Container';

import GQL from './_gql';
import Filter from './_filter';

const title = 'Historial de movimientos por producto';
const operation = ['productMovementLog', 'productMovementLogs'];
const Rows = [
  { label: 'Fecha', key: 'createdAt', sort: 'text', format: 'dateTime', filter: true },
  { label: 'COD', key: 'Product code', sort: 'text', filter: true },
  { label: 'Producto', key: 'Product name', sort: 'text', filter: true },
  { label: 'Descripción', key: 'description', filter: true },
  { label: 'Folio', key: 'folio', filter: true },
  { label: 'Unidad', key: 'PresentationBase name', filter: true },
  { label: 'Entrada', key: 'input', sort: 'text', align: 'right', format: 'number', filter: true },
  { label: 'Salida', key: 'output', sort: 'text', align: 'right', format: 'number', filter: true },
  { label: 'Saldo', key: 'balance', sort: 'text', align: 'right', format: 'number', filter: true },
];

const ProductMovementComponent = () => {
  return (
    <>
      <Container
        title={title}
        operation={operation}
        Rows={Rows}
        get={GQL.GET}
        noCreate
        noDelete
        noEdit
        filter
      />
      <Filter />
    </>
  );
};

export default ProductMovementComponent;
