var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useEffect, useMemo, useState } from 'react';
import { useReactiveVar } from '@apollo/client';
import DialogComponent from '../../../presentationals/Dialog';
import { filter, inputError } from '../../../apollo/cache';
import DateFieldComponent from '../../../presentationals/FormComponents/DateField';
import BranchFieldFilterEmployeeComponent from '../../employee/Filter/BranchField';
import FooterDialogComponent from '../../../presentationals/Dialog/Footer';
import ButtonFooterDialogComponent from '../../../presentationals/Dialog/Footer/Button';
import getInputErrorFormat from '../../sale.v2/Action/helpers/getInputErrorFormat';
import getSession from '../../../helpers/getSession';
import SelectFieldComponent from '../../../presentationals/FormComponents/SelectField';
import SelectTransactionAccountFieldComponent from '../../../presentationals/FormComponents/SelectTransactionAccountField';
import SelectPaymentFormFieldComponent from '../../../presentationals/FormComponents/SelectPaymentFormField';
import CustomerFieldComponent from '../../../presentationals/FormComponents/CustomerField';
import useFilterButtonDialog from '../../../store/filterButton/filterButtonDialog';
var FilterTransactionReportDialog = function () {
    var statusOptions = [
        { value: -1, label: 'Todos los estatus' },
        { value: 1, label: 'Activos' },
        { value: 0, label: 'Cancelados' },
    ];
    var defaultValues = useMemo(function () {
        var _a;
        var session = getSession();
        var branchId = (_a = session === null || session === void 0 ? void 0 : session.Company) === null || _a === void 0 ? void 0 : _a.Branch.id;
        return {
            start: new Date(),
            end: new Date(),
            status: -1,
            transactionAccountId: -1,
            paymentFormId: -2,
            branchId: branchId ? branchId.toString() : '',
        };
    }, []);
    var errors = useReactiveVar(inputError);
    var open = useFilterButtonDialog(function (state) { return state.open; });
    var setOpen = useFilterButtonDialog(function (state) { return state.setOpen; });
    var _a = useState(defaultValues), values = _a[0], setValues = _a[1];
    useEffect(function () {
        if (open) {
            var filterVar_1 = filter();
            setValues(function (values) { return ({
                start: filterVar_1.start ? filterVar_1.start : values.start,
                end: filterVar_1.end ? filterVar_1.end : values.end,
                status: filterVar_1.status
                    ? filterVar_1.status.length > 1
                        ? -1
                        : filterVar_1.status.length === 1
                            ? filterVar_1.status[0]
                            : values.status
                    : values.status,
                branchId: filterVar_1.branchId ? filterVar_1.branchId.toString() : values.branchId,
                transactionAccountId: !filterVar_1.transactionAccountId
                    ? -1
                    : filterVar_1.transactionAccountId[0],
                paymentFormId: filterVar_1.paymentMethodId === null
                    ? -1
                    : !filterVar_1.paymentMethodId
                        ? -2
                        : filterVar_1.paymentMethodId,
            }); });
        }
    }, [open]);
    var handleClose = function () {
        setOpen(false);
    };
    var handleDateChange = function (name, value) {
        setValues(function (values) {
            var _a;
            return (__assign(__assign({}, values), (_a = {}, _a[name] = value, _a)));
        });
    };
    var handleChange = function (name, value) {
        setValues(function (values) {
            var _a;
            return (__assign(__assign({}, values), (_a = {}, _a[name] = value, _a)));
        });
    };
    var handleClick = function () {
        try {
            var currentFilter = filter();
            filter(__assign(__assign({}, currentFilter), { branchId: !values.branchId ? undefined : values.branchId, customerId: !values.customerId ? undefined : values.customerId, start: values.start || new Date(), status: values.status === -1 ? [0, 1] : [values.status], end: values.end, transactionAccountId: values.transactionAccountId === -1 ? undefined : [values.transactionAccountId], paymentMethodId: values.paymentFormId === -1
                    ? null
                    : values.paymentFormId === -2
                        ? undefined
                        : values.paymentFormId }));
            handleClose();
        }
        catch (error) {
            getInputErrorFormat(error);
        }
    };
    var handleCustomerChange = function (name, value) {
        setValues(function (values) { return (__assign(__assign({}, values), { customerId: value })); });
    };
    return (React.createElement(DialogComponent, { open: open, fullScreen: true, title: 'Filtro para consulta de ventas', onClose: handleClose },
        React.createElement("div", { className: 'flex flex-1 flex-col' },
            React.createElement("div", { className: 'flex flex-col flex-1 p-4 overflow-auto basis-0' },
                React.createElement("div", { className: 'flex flex-col w-full sm:w-1/4 ' },
                    React.createElement(DateFieldComponent, { name: 'start', label: 'Del', value: values.start, onChange: handleDateChange, error: errors.start }),
                    React.createElement(DateFieldComponent, { name: 'end', label: 'Al', value: values.end, onChange: handleDateChange, error: errors.end }),
                    React.createElement(SelectPaymentFormFieldComponent, { defaultValue: values.paymentFormId, chooseAllOptionsAllowed: true, onChange: handleChange }),
                    React.createElement(SelectTransactionAccountFieldComponent, { defaultValue: values.transactionAccountId, chooseAllOptionsAllowed: true, onChange: handleChange }),
                    React.createElement(CustomerFieldComponent, { onChange: handleCustomerChange }),
                    React.createElement(BranchFieldFilterEmployeeComponent, { allBranchesOptionHidden: false, value: values.branchId, onChange: handleChange }),
                    React.createElement(SelectFieldComponent, { name: 'status', label: 'Estatus de la transacci\u00F3n', value: values.status, onChange: handleChange, options: statusOptions }))),
            React.createElement(FooterDialogComponent, null,
                React.createElement("div", null),
                React.createElement(ButtonFooterDialogComponent, { color: 'success', onClick: handleClick }, "Aplicar filtro")))));
};
export default FilterTransactionReportDialog;
