var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React from 'react';
import PrintIcon from '@mui/icons-material/Print';
import IconButtonComponent from '../../../../../../../presentationals/IconButton';
import getInputErrorFormat from '../../../../../../sale.v2/Action/helpers/getInputErrorFormat';
import getSession from '../../../../../../../helpers/getSession';
import BarcodePDF146 from '../../../../../../product/Item/BarcodeLabel/individualBarcode.146';
import BarcodePDF71 from '../../../../../../product/Item/BarcodeLabel/individualBarcode.71';
import BarcodePDF from '../../../../../../product/Item/BarcodeLabel/individualBarcode.pdf';
import useApolloLazyQuery from '../../../../../../../hooks/useApolloLazyQuery';
import GQL_PRODUCT from '../../../../../../../apollo/gql/product';
import CONSTANTS_ERROR from '../../../../../../../constants/error';
var PrintBarcodeLabelItemButtonComponent = function (props) {
    var _a = useApolloLazyQuery(GQL_PRODUCT.GET_PRODUCT_PRESENTATION_BY_ID), getProduct = _a[0], loading = _a[1].loading;
    var handleClick = function () { return __awaiter(void 0, void 0, void 0, function () {
        var session, values, data, body, error_1;
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    _b.trys.push([0, 2, , 3]);
                    session = getSession();
                    values = props.getValues();
                    return [4 /*yield*/, getProduct({ variables: { id: values.ProductPresentation.id } })];
                case 1:
                    data = (_b.sent()).data;
                    if (!data)
                        throw new Error(CONSTANTS_ERROR.UNDEFINED_PRODUCT_ID);
                    body = {
                        name: "".concat(values.Product.name, " ").concat(values.ProductPresentation.name),
                        Presentation: {
                            id: values.ProductPresentation.id,
                            barcode: data.barcode || '',
                            price: values.ProductPresentation.price,
                            sku: data.sku || '',
                        },
                    };
                    if (!((_a = session === null || session === void 0 ? void 0 : session.Company) === null || _a === void 0 ? void 0 : _a.id))
                        throw new Error('No se encontró el identificador de la empresa.');
                    switch (session.Company.id.toString()) {
                        case '146':
                            BarcodePDF146(body, values.quantity);
                            break;
                        case '71':
                            BarcodePDF71(body, values.quantity);
                            break;
                        default:
                            BarcodePDF(body, values.quantity);
                            break;
                    }
                    return [3 /*break*/, 3];
                case 2:
                    error_1 = _b.sent();
                    getInputErrorFormat(error_1);
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    return (React.createElement(IconButtonComponent, { size: 'medium', disabled: loading, className: 'bg-green-800 hover:bg-green-700 text-slate-50 mb-1', icon: React.createElement(PrintIcon, null), action: handleClick }));
};
export default PrintBarcodeLabelItemButtonComponent;
