var getTransactionReportTableSettingColumnLabel = function (key) {
    switch (key) {
        case 'clientCreatedAt':
            return 'Fecha de pago';
        case 'customer':
            return 'Cliente';
        case 'status':
            return 'Estatus';
        case 'createdAt':
            return 'Creado el';
        case 'total':
            return 'Importe';
        case 'saleFolio':
            return 'Folio de venta';
        case 'saleTotal':
            return 'Importe de venta';
        case 'invoiceComplementUuid':
            return 'Complemento';
        case 'paymentForm':
            return 'Forma de pago';
        default:
            return 'Indefinido';
    }
};
export default getTransactionReportTableSettingColumnLabel;
