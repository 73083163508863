var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import SearchDialogUnitKeyFieldProductComponent from '../../../sale.v2/ChooseProduct/ProductDialog/Create/Body/helpers/UnitKeyField/SearchDialog';
import SearchDialogProductKeyFieldProductComponent from '../../../sale.v2/ChooseProduct/ProductDialog/Create/Body/helpers/ProductKeyField/SearchDialog';
import FooterDialogComponent from '../../../../presentationals/Dialog/Footer';
import EditButtonGeneralProductComponent from './EditButton';
import BodyGeneralEditProductComponent from './Body';
import useApolloQuery from '../../../../hooks/useApolloQuery';
import GQL_PRODUCT from '../../../../apollo/gql/product';
import LoadingProgress from '../../../../presentationals/LoadingProgress';
import getDefaultProductValues from '../../../sale.v2/ChooseProduct/ProductDialog/Create/Body/helpers/getDefaultValues';
import { productSaleV2 } from '../../../../apollo/cache';
import DeleteButtonGeneralProductComponent from './DeleteButton';
var GeneralProductComponent = function () {
    var params = useParams();
    var _a = useApolloQuery(GQL_PRODUCT.GET_PRODUCT_BY_ID, {
        variables: { id: params.productId },
    }), data = _a.data, loading = _a.loading;
    useEffect(function () {
        var _a, _b;
        if (data) {
            var defaultGeneralValues = getDefaultProductValues(__assign(__assign({}, data), { cost: ((_a = data === null || data === void 0 ? void 0 : data.PresentationBase) === null || _a === void 0 ? void 0 : _a.cost) ? data.PresentationBase.cost : '', unitBase: ((_b = data === null || data === void 0 ? void 0 : data.PresentationBase) === null || _b === void 0 ? void 0 : _b.name) ? data.PresentationBase.name : '' }));
            productSaleV2(defaultGeneralValues);
        }
    }, [data]);
    useEffect(function () {
        return function () {
            var defaultGeneralValues = getDefaultProductValues();
            productSaleV2(defaultGeneralValues);
        };
    }, []);
    if (loading || !data)
        return React.createElement(LoadingProgress, null);
    return (React.createElement("div", { className: 'flex flex-1 flex-col overflow-hidden' },
        React.createElement(BodyGeneralEditProductComponent, null),
        React.createElement(FooterDialogComponent, null,
            React.createElement(DeleteButtonGeneralProductComponent, null),
            React.createElement(EditButtonGeneralProductComponent, null)),
        React.createElement(SearchDialogUnitKeyFieldProductComponent, null),
        React.createElement(SearchDialogProductKeyFieldProductComponent, null)));
};
export default GeneralProductComponent;
