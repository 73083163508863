import CREATE_COMPANY from './createCompany';
import FRAGMENT_ALLOWED_MODULES from './fragmentAllowedModules';
import GET_ALLOWED_MODULES from './getAllowedModules';
import GET_COMPANY_BY_ID from './getCompanyById';
import UPDATE_ALLOWED_MODULES from './updateAllowedModules';
import UPDATE_COMPANY from './updateCompany';
import UPDATE_COMPANY_LOGO from './updateCompanyLogo';
var GQL_COMPANY = {
    CREATE: CREATE_COMPANY,
    FRAGMENT_ALLOWED_MODULES: FRAGMENT_ALLOWED_MODULES,
    GET_ALLOWED_MODULES: GET_ALLOWED_MODULES,
    GET_BY_ID: GET_COMPANY_BY_ID,
    UPDATE_ALLOWED_MODULES: UPDATE_ALLOWED_MODULES,
    UPDATE_COMPANY: UPDATE_COMPANY,
    UPDATE_LOGO: UPDATE_COMPANY_LOGO,
};
export default GQL_COMPANY;
