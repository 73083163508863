var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import jsPDF from 'jspdf';
import { format } from 'date-fns';
import rowFormat from '../Table/helpers/Format';
var currentDate = new Date();
var alignText = function (x, width, align) {
    var tmpX = x;
    switch (align) {
        case 'right':
            tmpX = x + width - 2;
            break;
        case 'center':
            tmpX = width / (2 + x);
            break;
        default:
            tmpX = x;
            break;
    }
    return tmpX;
};
var header = function (doc, title, keys) {
    var rowsLine = 1;
    doc.setFontSize(14);
    doc.text(title.toUpperCase(), 10, 20);
    doc.text("".concat(format(currentDate, 'dd-MMM-yyyy HH:mm')), 285, 20, 'right');
    var width = 275 / keys.length;
    var x = 10;
    doc.setFontSize(10);
    keys.forEach(function (_a) {
        var label = _a.label, align = _a.align;
        var tmpX = alignText(x, width, align);
        var labelFix = doc.splitTextToSize(label, width - 2);
        rowsLine = labelFix.length > rowsLine ? labelFix.length : rowsLine;
        doc.text(tmpX, 30, labelFix, align);
        x = x + width;
    });
    var breakLine = 31 + 2 * rowsLine;
    doc.line(10, breakLine, 285, breakLine);
    return breakLine;
};
export default (function (title, keys, data) { return __awaiter(void 0, void 0, void 0, function () {
    var doc, breakLine, width, heightBreakLine, rowsLine;
    return __generator(this, function (_a) {
        if (keys.length === 0)
            return [2 /*return*/, false];
        doc = new jsPDF('landscape');
        breakLine = header(doc, title, keys);
        width = 275 / keys.length;
        heightBreakLine = 4;
        rowsLine = 1;
        data.forEach(function (row) {
            breakLine = breakLine + heightBreakLine;
            var x = 10;
            keys.forEach(function (_a) {
                var key = _a.key, strcat = _a.strcat, _b = _a.align, align = _b === void 0 ? 'left' : _b, dataFormat = _a.format, el = __rest(_a, ["key", "strcat", "align", "format"]);
                var dataFormatTmp = dataFormat;
                var value = key.includes(' ')
                    ? key.includes(' && ')
                        ? key.split(' && ').reduce(function (stringPincipal, elPincipal) {
                            var res = elPincipal.split(' ').reduce(function (string, elCh) {
                                var tmpData = string[elCh] || el.ifNull || '-';
                                return tmpData;
                            }, row);
                            return stringPincipal !== '' ? "".concat(stringPincipal).concat(strcat || ' ').concat(res) : res;
                        }, '')
                        : key.split(' ').reduce(function (string, elCh) {
                            var tmpData = string[elCh] || el.ifNull || '-';
                            return tmpData;
                        }, row)
                    : row[key];
                if (dataFormatTmp === 'currency') {
                    dataFormatTmp = null;
                    value = !Number.isNaN(parseFloat(value)) ? parseFloat(value) : 0;
                }
                var label = doc.splitTextToSize(rowFormat(value, dataFormatTmp), width - 2);
                rowsLine = label.length > rowsLine ? label.length : rowsLine;
                var tmpX = alignText(x, width, align);
                doc.text(label, tmpX, breakLine, align);
                // doc.text(`${tmpX}`, breakLine, label, align);
                x = x + width;
            });
            breakLine = breakLine + 3 * rowsLine;
            doc.line(10, breakLine, 285, breakLine);
            rowsLine = 1;
            if (breakLine >= 200) {
                doc.addPage('L');
                breakLine = header(doc, title, keys);
            }
        });
        doc.save("".concat(title, ".pdf"));
        return [2 /*return*/];
    });
}); });
