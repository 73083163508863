var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { forwardRef, useEffect, useImperativeHandle, useMemo, useState } from 'react';
import { CircularProgress, Paper, Typography } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import { useReactiveVar } from '@apollo/client';
import SearchButtonHeaderModuleV3Component from '../../Module.v3/Header/SearchInput/SearchButton';
import InputProductFieldComponent from './Input';
import getInputErrorFormat from '../../../containers/sale.v2/Action/helpers/getInputErrorFormat';
import setInputErrorFormat from '../../../containers/sale.v2/Action/helpers/setInputErrorFormat';
import { inputError, isProductDialogOpen } from '../../../apollo/cache';
import useApolloLazyQuery from '../../../hooks/useApolloLazyQuery';
import GQL_PRODUCT from '../../../apollo/gql/product';
import ResetButtonHeaderModuleV3Component from '../../Module.v3/Header/SearchInput/ResetButton';
import getLocalStorageSearchProductBy from '../../../helpers/getLocalStorageSearchProductBy';
import SearchProductBySelectFieldComponent from '../SearchProductBySelectField';
import SearchProductByRadioFieldComponent from '../SearchProductByRadioField';
var ProductFieldComponent = forwardRef(function (props, ref) {
    var isProductDialogOpenVar = useReactiveVar(isProductDialogOpen);
    var _a = useState(false), isSelected = _a[0], setIsSelected = _a[1];
    var errors = useReactiveVar(inputError);
    var _b = useState(false), isDefaultValueProcessed = _b[0], setIsDefaultValueProcessed = _b[1];
    var _c = useState(''), localValue = _c[0], setLocalValue = _c[1];
    var GET = useMemo(function () { return GQL_PRODUCT.CUSTOMIZED_GET(); }, []);
    var _d = useApolloLazyQuery(GQL_PRODUCT.GET_PRODUCT_BY_ID), getProduct = _d[0], loadingProduct = _d[1].loading;
    var _e = useApolloLazyQuery(GQL_PRODUCT.GET_PRODUCT_BY_BARCODE), getProductByBarcode = _e[0], loadingProductBarcode = _e[1].loading;
    var _f = useApolloLazyQuery(GET, {
        variables: props.variables,
    }), getProducts = _f[0], loading = _f[1].loading;
    useEffect(function () {
        if (isProductDialogOpenVar[3] &&
            !isProductDialogOpenVar[0] &&
            isProductDialogOpenVar[3].id === props.id) {
            setLocalValue(isProductDialogOpenVar[3].label);
            setIsSelected(true);
            props.onChange(props.name, isProductDialogOpenVar[3].value); //
            isProductDialogOpen([false, '', '', null]);
        }
    }, [isProductDialogOpenVar[0], isProductDialogOpenVar[3]]);
    useEffect(function () {
        var f = function () { return __awaiter(void 0, void 0, void 0, function () {
            var data;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, getProduct({
                            variables: { id: props.initialValue, branchId: props.branchId },
                        })];
                    case 1:
                        data = (_a.sent()).data;
                        if (data) {
                            setLocalValue(data.name);
                            setIsSelected(true);
                            props.onChange(props.name, data.id);
                        }
                        setIsDefaultValueProcessed(true);
                        return [2 /*return*/];
                }
            });
        }); };
        if (props.initialValue && !isProductDialogOpenVar[3] && !isDefaultValueProcessed)
            f();
    }, [props.initialValue, isProductDialogOpenVar[3], isDefaultValueProcessed]);
    var handleClick = function () { return __awaiter(void 0, void 0, void 0, function () {
        var searchBy, data_1, data, error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 4, , 5]);
                    inputError({});
                    if (localValue.length <= 2)
                        throw new Error(setInputErrorFormat(props.name, 'Proporciona al menos 2 caracteres para realizar la búsqueda.'));
                    searchBy = getLocalStorageSearchProductBy();
                    if (!(searchBy === 'barcode')) return [3 /*break*/, 2];
                    if (props.noQueryServerByBarcode) {
                        props.onChange(props.name, localValue);
                        return [2 /*return*/];
                    }
                    return [4 /*yield*/, getProductByBarcode({
                            variables: { barcode: localValue },
                        })];
                case 1:
                    data_1 = (_a.sent()).data;
                    if (!data_1) {
                        setIsSelected(false);
                        throw new Error(setInputErrorFormat(props.name, 'No se encontraron coincidencias.'));
                    }
                    setLocalValue(data_1.name);
                    setIsSelected(true);
                    props.onChange(props.name, data_1.id, data_1);
                    return [2 /*return*/];
                case 2: return [4 /*yield*/, getProducts({
                        variables: { name: [searchBy, localValue], limit: 999 },
                    })];
                case 3:
                    data = (_a.sent()).data;
                    if (data.rows.length === 0) {
                        setIsSelected(false);
                        if (searchBy === 'barcode')
                            setLocalValue('');
                        throw new Error(setInputErrorFormat(props.name, 'No se encontraron coincidencias.'));
                    }
                    if (data.rows.length === 1) {
                        setLocalValue(data.rows[0].name);
                        setIsSelected(true);
                        props.onChange(props.name, data.rows[0].id);
                    }
                    else {
                        isProductDialogOpen([
                            true,
                            props.label || 'Producto',
                            props.name,
                            { label: localValue, value: null, id: props.id },
                            undefined,
                            props.variables,
                        ]);
                    }
                    return [3 /*break*/, 5];
                case 4:
                    error_1 = _a.sent();
                    getInputErrorFormat(error_1);
                    return [3 /*break*/, 5];
                case 5: return [2 /*return*/];
            }
        });
    }); };
    var handleClear = function () {
        props.onClear && props.onClear();
        inputError({});
        setLocalValue('');
        setIsSelected(false);
        isProductDialogOpen([false, '', '', null]);
    };
    var handleChange = function (value) {
        setLocalValue(value);
    };
    useImperativeHandle(ref, function () { return ({
        handleClear: handleClear,
    }); });
    return (React.createElement("div", { className: 'flex flex-col w-full' },
        props.searchBy === 'radio' && (React.createElement("div", { className: 'flex w-full' },
            React.createElement(SearchProductByRadioFieldComponent, { disabled: props.disabled }))),
        React.createElement("div", { className: 'flex w-full flex-row items-center' },
            !props.searchBy ||
                (props.searchBy && props.searchBy === 'select' && (React.createElement("div", { className: 'flex pr-2' },
                    React.createElement(SearchProductBySelectFieldComponent, { disabled: props.disabled })))),
            React.createElement("div", { className: 'flex flex-1 flex-col' },
                !props.hideLabel && (React.createElement(Typography, { variant: 'subtitle1' }, props.label || 'Producto')),
                React.createElement(Paper, { className: 'flex px-2 py-[3px] bg-transparent border-[#c5c5c5] items-center', variant: 'outlined' },
                    isSelected && React.createElement(CheckIcon, { color: 'success', fontSize: 'small' }),
                    React.createElement(InputProductFieldComponent, { value: localValue, placeholder: props.placeholder, isLoading: props.disabled, onChange: handleChange, onPressEnter: handleClick }),
                    loading || loadingProduct || loadingProductBarcode ? (React.createElement(CircularProgress, { size: 25 })) : (React.createElement(React.Fragment, null,
                        localValue.length > 0 && (React.createElement(ResetButtonHeaderModuleV3Component, { isLoading: loading || loadingProduct || loadingProductBarcode || !!props.disabled, onClick: handleClear })),
                        React.createElement(SearchButtonHeaderModuleV3Component, { isLoading: loading || loadingProduct || loadingProductBarcode || !!props.disabled, onClick: handleClick })))))),
        errors[props.name] && React.createElement("div", { className: 'text-xs text-red-700' }, errors[props.name])));
});
export default ProductFieldComponent;
