import React from 'react';

import { useMutation } from '@apollo/client';

import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';

import GQL from './_gql';

const Component = props => {
  const [generateCode] = useMutation(GQL.GENERATE_CODE);

  const handleAction = async () => {
    await generateCode({ variables: { id: props.id } });
    props.handleClose();
  };

  return (
    <Dialog
      open={props.isOpen || false}
      fullWidth={true}
      maxWidth='xs'
      aria-labelledby='Confirmar'
      aria-describedby='Dialogo de confirmación para validar un registro.'
    >
      <DialogTitle>¿Estás seguro que deseas continuar?</DialogTitle>
      <DialogContent />
      <DialogActions>
        <Button onClick={props.handleClose} color='primary'>
          Cancelar
        </Button>
        <Button onClick={handleAction} color='primary' autoFocus>
          Si, estoy seguro.
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default Component;
