import React, { useEffect } from 'react';
import { useReactiveVar } from '@apollo/client';
import { exportPdf } from '../../../apollo/cache';
import createPdf from '../../PDF/table.v2';
import getHeadersToExport from '../helpers/getHeadersToExport';
var PDFCreatorComponent = function (props) {
    var exportPdfVar = useReactiveVar(exportPdf);
    useEffect(function () {
        if (exportPdfVar.export) {
            createPdf(props.title, getHeadersToExport(props.header), props.data);
            exportPdf({ export: false });
        }
    }, [exportPdfVar]);
    return React.createElement("div", null);
};
export default PDFCreatorComponent;
