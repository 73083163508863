import { TableRow } from '@mui/material';
import React from 'react';
import { CloseRentItemForm, isEditPickupItemOpen } from '../../../../../../apollo/cache';
var ItemPickupRentComponent = function (props) {
    var handleClick = function () {
        CloseRentItemForm({
            name: props.productName,
            qty: props.receivedQty,
            price: props.unitCost,
            saleProductId: props.productSaleId,
        });
        isEditPickupItemOpen(props.productSaleId);
    };
    return (React.createElement(TableRow, { className: 'hover:bg-slate-300', onClick: handleClick }, props.children));
};
export default ItemPickupRentComponent;
