var getRouteFromSaleMenuValue = function (value) {
    switch (value) {
        case 0:
            return '.';
        case 1:
            return 'products';
        case 2:
            return 'invoice';
        case 3:
            return 'cancel';
        default:
            return false;
    }
};
export default getRouteFromSaleMenuValue;
