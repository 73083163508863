import GET_SALES from './getSales';
import UPDATE_SALE_COMMENTARY from './updateSaleCommentary';
import GET_SALE_BY_ID from './getSaleById';
import ADD_SERVICE_ORDER_PRODUCT from './addServiceOrderProduct';
import UPDATE_DELIVERY_STATUS from './updateDeliveryStatus';
import FRAGMENT_SALE_PRODUCT from './fragmentSaleProduct';
import ADD_SALE_PRODUCT from './addSaleProduct';
import EDIT_SALE_PRODUCT from './editSaleProduct';
import GET_SALE_PRODUCT from './getSaleProduct';
import DELETE_SALE_PRODUCT from './deleteSaleProduct';
import FRAGMENT_SALE from './fragmentSale';
import FRAGMENT_ACCOUNT_RECEIVABLE from './fragmentAccountReceivable';
import GET_GENERAL_SALE_REPORT from './getGeneralSaleReport';
import GET_SALE_PRODUCT_PRESENTATIONS_ROTATION from './getSaleProductPresentationsRotation';
import GET_SALE_PRODUCTS_ROTATION from './getSaleProductsRotation';
import GET_SALE_PRODUCTS_AND_INGREDIENTS_ROTATION from './getSaleProductsAndIngredientsRotation';
import GET_SALE_PRODUCT_INGREDIENTS_ROTATION from './getSaleProductIngredientsRotation';
import DELETE_SALE from './deleteSale';
import GET_SALE_EXPENSES from './getSaleExpenses';
import CREATE_SALE_EXPENSE from './createSaleExpense';
import FRAGMENT_SALE_EXPENSE from './fragmentSaleExpense';
import DELETE_SALE_EXPENSE from './deleteSaleExpense';
import CLOSE_SERVICE_ORDER_INVOICE from './closeServiceOrderInvoice';
import CLOSE_SERVICE_ORDER from './closeServiceOrder';
import GET_PROMOTION_ROTATION from './getPromotionRotation';
import SEND_SALE_BY_EMAIL from './sendSaleByEmail';
import GET_PROMOTION_SALES from './getPromotionSales';
import UPDATE_SALE_PRODUCT_RENT_PICKUP from './updateSaleProductRentPickup';
import TOTALIZE_CART from './totalizeCart';
import GET_SALE_PRODUCTS from './getSaleProducts';
import GET_EMPLOYEE_SALES from './getEmployeesSaleRotation';
import GET_SALE_PRODUCT_INGREDIENTS_ROTATION_DETAILS from './getSaleProductIngredientsRotationDetails';
import CREATE_ONLINE_ORDER from './createOnlineOrder';
var GQL_SALE = {
    ADD_SALE_PRODUCT: ADD_SALE_PRODUCT,
    ADD_SERVICE_ORDER_PRODUCT: ADD_SERVICE_ORDER_PRODUCT,
    CLOSE_SERVICE_ORDER: CLOSE_SERVICE_ORDER,
    CLOSE_SERVICE_ORDER_INVOICE: CLOSE_SERVICE_ORDER_INVOICE,
    CREATE_ONLINE_ORDER: CREATE_ONLINE_ORDER,
    CREATE_SALE_EXPENSE: CREATE_SALE_EXPENSE,
    DELETE_SALE: DELETE_SALE,
    DELETE_SALE_EXPENSE: DELETE_SALE_EXPENSE,
    DELETE_SALE_PRODUCT: DELETE_SALE_PRODUCT,
    EDIT_SALE_PRODUCT: EDIT_SALE_PRODUCT,
    FRAGMENT_ACCOUNT_RECEIVABLE: FRAGMENT_ACCOUNT_RECEIVABLE,
    FRAGMENT_SALE: FRAGMENT_SALE,
    FRAGMENT_SALE_EXPENSE: FRAGMENT_SALE_EXPENSE,
    FRAGMENT_SALE_PRODUCT: FRAGMENT_SALE_PRODUCT,
    GET_EMPLOYEE_SALES: GET_EMPLOYEE_SALES,
    GET_GENERAL_SALE_REPORT: GET_GENERAL_SALE_REPORT,
    GET_PROMOTION_ROTATION: GET_PROMOTION_ROTATION,
    GET_PROMOTION_SALES: GET_PROMOTION_SALES,
    GET_SALE_BY_ID: GET_SALE_BY_ID,
    GET_SALE_EXPENSES: GET_SALE_EXPENSES,
    GET_SALE_PRODUCT: GET_SALE_PRODUCT,
    GET_SALE_PRODUCTS: GET_SALE_PRODUCTS,
    GET_SALE_PRODUCTS_ROTATION: GET_SALE_PRODUCTS_ROTATION,
    GET_SALE_PRODUCT_PRESENTATIONS_ROTATION: GET_SALE_PRODUCT_PRESENTATIONS_ROTATION,
    GET_SALE_PRODUCT_INGREDIENTS_ROTATION: GET_SALE_PRODUCT_INGREDIENTS_ROTATION,
    GET_SALE_PRODUCT_INGREDIENTS_ROTATION_DETAILS: GET_SALE_PRODUCT_INGREDIENTS_ROTATION_DETAILS,
    GET_SALES: GET_SALES,
    SEND_SALE_BY_EMAIL: SEND_SALE_BY_EMAIL,
    TOTALIZE_CART: TOTALIZE_CART,
    UPDATE_DELIVERY_STATUS: UPDATE_DELIVERY_STATUS,
    UPDATE_SALE_COMMENTARY: UPDATE_SALE_COMMENTARY,
    UPDATE_SALE_PRODUCT_RENT_PICKUP: UPDATE_SALE_PRODUCT_RENT_PICKUP,
    GET_SALE_PRODUCTS_AND_INGREDIENTS_ROTATION: GET_SALE_PRODUCTS_AND_INGREDIENTS_ROTATION,
};
export default GQL_SALE;
