import XLSX from 'xlsx';

import client from '../../apollo';

import rowFormat from '../Table/helpers/Format';

const getQuery = async (gql, variables) => {
  try {
    const { data } = await client.mutate({
      mutation: gql,
      variables: { ...variables, limit: 0, offset: 0 },
    });
    const response = data[Object.keys(data)[0]];
    return response.rows || response;
  } catch (e) {
    return e;
  }
};

export default async (title, gql, vars, keys) => {
  const data = await getQuery(gql, vars);

  const filename = `${title.substring(0, 20)}.xlsx`;
  const wb = XLSX.utils.book_new();

  const header = keys.map(el => el.label);
  const ws = XLSX.utils.json_to_sheet([], { header, skipHeader: true });

  const tmp = data.map(el => {
    const response = keys.reduce(
      (newObject, { key, strcat, ifNull, label, format: dataFormat }) => {
        let dataFormatTmp = dataFormat;
        let value = key.includes(' ')
          ? key.includes(' && ')
            ? key.split(' && ').reduce((stringPincipal, elPincipal) => {
                const res = elPincipal.split(' ').reduce((string, elCh) => {
                  const tmpData = string[elCh] || ifNull || '-';
                  return tmpData;
                }, el);
                return stringPincipal !== '' ? `${stringPincipal}${strcat || ' '}${res}` : res;
              }, '')
            : key.split(' ').reduce((string, elCh) => {
                const tmpData = string[elCh] || ifNull || '-';
                return tmpData;
              }, el)
          : el[key];
        if (dataFormatTmp === 'currency') {
          dataFormatTmp = null;
          value = !Number.isNaN(parseFloat(value)) ? parseFloat(value) : 0;
        }
        return { ...newObject, [label]: rowFormat(value, dataFormatTmp) };
      },
      {},
    );
    return response;
  });
  XLSX.utils.sheet_add_json(ws, tmp, { skipHeader: false, origin: 'A1' });
  XLSX.utils.book_append_sheet(wb, ws, title.substring(0, 20));
  XLSX.writeFile(wb, filename);
};
