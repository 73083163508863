var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import { useReactiveVar } from '@apollo/client';
import { inputError, productVariationForm } from '../../../../apollo/cache';
import SubtitleDialogComponent from '../../../../presentationals/Dialog/helpers/Subtitle';
import TextFieldComponent from '../../../../presentationals/FormComponents/TextField';
import SwitchComponent from '../../../../presentationals/FormComponents/Switch';
import SelectionTypeProductVariationFieldComponent from './SelectionTypeField';
var BodyProductVariationComponent = function () {
    var errors = useReactiveVar(inputError);
    var values = useReactiveVar(productVariationForm);
    var handleChange = function (name, value) {
        var _a;
        productVariationForm(__assign(__assign({}, values), (_a = {}, _a[name] = value, _a)));
    };
    var handleSwitchChange = function (name, value) {
        var _a;
        productVariationForm(__assign(__assign({}, values), (_a = {}, _a[name] = value, _a)));
    };
    return (React.createElement("div", { className: 'flex-1 py-2 px-4 grid grid-cols-1 md:grid-cols-4 gap-4' },
        React.createElement("div", null,
            React.createElement(SubtitleDialogComponent, { label: 'Datos generales' }),
            React.createElement(TextFieldComponent, { label: 'Nombre', name: 'name', value: values.name, error: errors.name, onChange: handleChange }),
            React.createElement(TextFieldComponent, { name: 'limitVariation', label: '\u00BFCu\u00E1ntos insumos/materia prima de este tipo de componente se pueden a\u00F1adir por producto?', value: values.limitVariation, error: errors.limitVariation, onChange: handleChange, type: 'number' }),
            React.createElement(SwitchComponent, { name: 'isRequired', label: '\u00BFEs obligatorio seleccionar al menos un insumo/materia prima de este tipo de componente?', labelPlacement: 'end', defaultValue: values.isRequired, onChange: handleSwitchChange }),
            React.createElement(SelectionTypeProductVariationFieldComponent, { onChange: handleChange, value: values.selectionType }))));
};
export default BodyProductVariationComponent;
