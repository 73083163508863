import React, { useEffect, useState } from 'react';
import { useReactiveVar } from '@apollo/client';
import ModuleV3Component from '../../presentationals/Module.v3';
import useGetPromotionRotation from './helpers/useGetPromotionRotation';
import FilterSalePromotionRotationDialog from './Filter';
import RedirectEditFormToRouteComponent from '../../presentationals/RedirectEditFormToRoute';
import { filter } from '../../apollo/cache';
var SalePromotionRotationReportComponent = function () {
    var filterVar = useReactiveVar(filter);
    var _a = useGetPromotionRotation(), data = _a.data, loading = _a.loading;
    var header = [
        { Header: 'Promoción', accessor: 'promotion' },
        { Header: 'Cantidad', accessor: 'quantity' },
        { Header: 'Importe', accessor: 'total' },
    ];
    var _b = useState(undefined), queryParams = _b[0], setQueryParams = _b[1];
    useEffect(function () {
        if (filterVar.start && filterVar.end)
            setQueryParams({ start: filterVar.start.toString(), end: filterVar.end.toString() });
    }, [filterVar]);
    return (React.createElement(React.Fragment, null,
        React.createElement(ModuleV3Component, { title: 'Promociones', searchLabel: 'promociones', filter: true, data: data || [], header: header, hideAdd: true, isLoading: loading }),
        React.createElement(FilterSalePromotionRotationDialog, null),
        React.createElement(RedirectEditFormToRouteComponent, { queryParams: queryParams })));
};
export default SalePromotionRotationReportComponent;
