import React, { useState } from 'react';
import { Link } from '@mui/material';
import CreateTagForm from '../../../../../../Tag/create.form';
var AddButtonTagFieldBodyFormCalendarComponent = function () {
    var _a = useState(false), isOpen = _a[0], setIsOpen = _a[1];
    var handleClick = function (e) {
        if (e)
            e.preventDefault();
        setIsOpen(function (isOpen) { return !isOpen; });
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(Link, { underline: 'hover', href: '#', onClick: handleClick, className: 'mr-4' }, "Nueva etiqueta"),
        React.createElement(CreateTagForm, { isOpen: isOpen, handleClose: handleClick })));
};
export default AddButtonTagFieldBodyFormCalendarComponent;
