import React, { useEffect } from 'react';
import { List } from '@mui/material';
import useApolloLazyQuery from '../../../../../../../hooks/useApolloLazyQuery';
import LoadingProgress from '../../../../../../../presentationals/LoadingProgress';
import Item from './Item';
import GQL_PRODUCT from '../../../../../../../apollo/gql/product';
var RatesSaleHistoryBodyItemDetailDialogCartSaleV2Component = function (props) {
    var _a = useApolloLazyQuery(GQL_PRODUCT.GET_PRODUCT_BY_ID), getData = _a[0], _b = _a[1], loading = _b.loading, data = _b.data;
    useEffect(function () {
        if (props.productId)
            getData({ variables: { id: props.productId } });
    }, [props.productId]);
    if (loading)
        return React.createElement(LoadingProgress, { noText: true });
    return (React.createElement("div", null, data && data.ProductPresentationPrices && data.ProductPresentationPrices.length > 0 && (React.createElement("div", { className: 'flex flex-1 flex-col overflow-y-scroll overflow-x-hidden' },
        React.createElement(List, null, data.ProductPresentationPrices.map(function (el) { return (React.createElement(Item, { key: el.id, index: props.index, price: el.price, name: el.Rate ? el.Rate.name : '-' })); }))))));
};
export default RatesSaleHistoryBodyItemDetailDialogCartSaleV2Component;
