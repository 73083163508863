var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React, { createRef, useEffect, useMemo, useRef } from 'react';
import { Card, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, } from '@mui/material';
import useApolloLazyQuery from '../../../../../hooks/useApolloLazyQuery';
import GQL_ACCOUNT_RECEIVABLE from '../../../../../apollo/gql/accountReceivable';
import LoadingProgress from '../../../../../presentationals/LoadingProgress';
import ErrorDataComponent from '../../../../../presentationals/ErrorData';
import CreateInvoiceComplementButton from './CreateButton';
import ItemInvoiceComplementComponent from './Item';
import CheckAllPaymentInvoiceComplementButtonComponent from './CheckAllPaymentButton';
var BodyInvoiceComplementComponent = function (props) {
    var checkAllRef = useRef(null);
    var _a = useApolloLazyQuery(GQL_ACCOUNT_RECEIVABLE.GET_CUSTOMER_TRANSACTIONS), getData = _a[0], _b = _a[1], loading = _b.loading, data = _b.data;
    useEffect(function () {
        if (props.customerId)
            getData({ variables: { customerId: props.customerId } });
    }, [props.customerId]);
    var itemInvoiceComplementRefById = useMemo(function () {
        if (!data)
            return {};
        return data.reduce(function (newArray, el) {
            var _a;
            return __assign(__assign({}, newArray), (_a = {}, _a[el.id] = createRef(), _a));
        }, {});
    }, [data]);
    var handleCheckAllChange = function (checked) {
        Object.keys(itemInvoiceComplementRefById).forEach(function (key) {
            var _a;
            var item = itemInvoiceComplementRefById[key];
            (_a = item === null || item === void 0 ? void 0 : item.current) === null || _a === void 0 ? void 0 : _a.handleCheckAllChange(checked);
        });
    };
    var handleCheckItemChange = function (id, checked) {
        var _a;
        var isEveryItemsChecked = Object.keys(itemInvoiceComplementRefById).reduce(function (isChecked, key) {
            var _a;
            var item = itemInvoiceComplementRefById[key];
            var isItemChecked = id === key ? checked : (_a = item === null || item === void 0 ? void 0 : item.current) === null || _a === void 0 ? void 0 : _a.getChecked();
            if (!isChecked)
                return false;
            return !!isItemChecked;
        }, true);
        (_a = checkAllRef.current) === null || _a === void 0 ? void 0 : _a.handleItemChange(isEveryItemsChecked);
    };
    var handleButtonClick = function () {
        return Object.keys(itemInvoiceComplementRefById).reduce(function (array, key) {
            var _a;
            var item = itemInvoiceComplementRefById[key];
            var isItemChecked = (_a = item === null || item === void 0 ? void 0 : item.current) === null || _a === void 0 ? void 0 : _a.getChecked();
            return isItemChecked ? __spreadArray(__spreadArray([], array, true), [key], false) : array;
        }, []);
    };
    if (loading)
        return React.createElement(LoadingProgress, null);
    if (!data)
        return React.createElement(ErrorDataComponent, null);
    return (React.createElement("div", { className: 'flex flex-1 flex-col' },
        React.createElement("div", { className: 'flex flex-1 flex-col px-4 pt-3 pb-1' },
            React.createElement("div", { className: 'text-base font-medium mb-1 flex' }, "Pagos disponibles para facturar"),
            React.createElement("div", { className: 'text-sm sm:text-base text-gray-600 mb-2' }, "Los pagos que aparecen en esta secci\u00F3n son los pagos disponibles para emitir un complemento de pago, es decir, pagos que est\u00E1n vinculados a una cuenta por cobrar facturada con m\u00E9todo de pago diferido o en parcialidades (PPD)."),
            React.createElement(Card, { className: 'flex flex-1 flex-col basis-0 overflow-y-auto' }, data.length === 0 ? (React.createElement("div", { className: 'flex px-4 py-3' }, "De momento no cuentas con pagos pendientes por facturar.")) : (React.createElement(TableContainer, null,
                React.createElement(Table, { stickyHeader: true, size: 'small' },
                    React.createElement(TableHead, null,
                        React.createElement(TableRow, { className: 'bg-[#f6f6f6]' },
                            React.createElement(TableCell, { padding: 'checkbox' },
                                React.createElement(CheckAllPaymentInvoiceComplementButtonComponent, { onChange: handleCheckAllChange, ref: checkAllRef })),
                            React.createElement(TableCell, null, "Creado el"),
                            React.createElement(TableCell, null, "Pagado el"),
                            React.createElement(TableCell, null, "Folio"),
                            React.createElement(TableCell, { className: 'whitespace-nowrap' }, "Forma de pago"),
                            React.createElement(TableCell, { align: 'right' }, "Importe"))),
                    React.createElement(TableBody, null, data.map(function (el) { return (React.createElement(ItemInvoiceComplementComponent, __assign({ key: el.id, onChange: handleCheckItemChange, ref: itemInvoiceComplementRefById[el.id] }, el))); }))))))),
        data.length > 0 && (React.createElement("div", { className: 'flex justify-end px-4 py-2' },
            React.createElement(CreateInvoiceComplementButton, { customerId: props.customerId, onClick: handleButtonClick })))));
};
export default BodyInvoiceComplementComponent;
