import React from 'react';
import AddCircleIcon from '@mui/icons-material/AddOutlined';
import IconButtonComponent from '../../../../../../presentationals/IconButton';
import { isCreateProductComponentOpen } from '../../../../../../apollo/cache';
var OpenAddComponentButtonComponent = function () {
    var handleClick = function () {
        isCreateProductComponentOpen(true);
    };
    return (React.createElement(IconButtonComponent, { action: handleClick, icon: React.createElement(AddCircleIcon, null), size: 'small', color: 'secondary', label: 'Nuevo componente' }));
};
export default OpenAddComponentButtonComponent;
