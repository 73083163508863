var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import getDefaultBranchSettings from '../helpers/getDefaultBranchSettingsValues';
import SelectTaxBehaviorFieldComponent from '../../../../../presentationals/FormComponents/SelectTaxBehaviorField';
import SubtitleDialogComponent from '../../../../../presentationals/Dialog/helpers/Subtitle';
import SelectFieldComponent from '../../../../../presentationals/FormComponents/SelectField';
import SwitchComponent from '../../../../../presentationals/FormComponents/Switch';
import TextFieldComponent from '../../../../../presentationals/FormComponents/TextField';
import CustomerFieldComponent from '../../../../../presentationals/FormComponents/CustomerField';
import DinnerPreferenceFieldComponent from '../../../../../presentationals/FormComponents/DinnerPreferenceField';
var codeConfirmationOptions = [
    { value: 0, label: 'Ninguno' },
    { value: 1, label: 'Descuentos' },
    { value: 2, label: 'Cancelaciones' },
    { value: 3, label: 'Descuentos y cancelaciones' },
    { value: 4, label: 'No solicitar ninguna contraseña' },
];
var BodyBranchSettingsComponent = forwardRef(function (props, ref) {
    var defaultValues = getDefaultBranchSettings();
    var _a = useState(defaultValues), values = _a[0], setValues = _a[1];
    useEffect(function () {
        if (props.defaultValues)
            setValues(getDefaultBranchSettings(props.defaultValues));
    }, [props.defaultValues]);
    var handleChange = function (name, value) {
        setValues(function (values) {
            var _a;
            return (__assign(__assign({}, values), (_a = {}, _a[name] = value, _a)));
        });
    };
    var handleGetValues = function () { return values; };
    var handleSwitchChange = function (name, value) {
        setValues(function (values) {
            var _a;
            return (__assign(__assign({}, values), (_a = {}, _a[name] = value, _a)));
        });
    };
    var handleCustomerChange = function (name, value) {
        setValues(function (values) {
            var _a;
            return (__assign(__assign({}, values), (_a = {}, _a[name] = value, _a)));
        });
    };
    useImperativeHandle(ref, function () { return ({
        getValues: handleGetValues,
    }); });
    return (React.createElement("div", { className: 'flex flex-1 flex-col sm:grid sm:grid-cols-4 sm:gap-3 px-4 py-2 basis-0 overflow-auto' },
        React.createElement("div", { className: 'flex flex-1 flex-col' },
            React.createElement(SubtitleDialogComponent, { label: 'Punto de venta' }),
            React.createElement(CustomerFieldComponent, { label: 'Cliente por default al realizar una venta', onChange: handleCustomerChange, defaultValue: props.defaultValues.customerId || undefined }),
            React.createElement(TextFieldComponent, { name: 'tables', label: 'N\u00FAmero de mesas', onChange: handleChange, value: values.tables }),
            React.createElement(SelectTaxBehaviorFieldComponent, { name: 'defaultTaxesTypeOption', label: '\u00BFQu\u00E9 dese\u00E1s hacer con los impuestos de los productos?', onChange: handleChange, value: values.defaultTaxesTypeOption }),
            React.createElement(SwitchComponent, { label: 'Reiniciar folio de venta despu\u00E9s de corte de caja.', name: 'resetFolio', onChange: handleSwitchChange, defaultValue: values.resetFolio }),
            React.createElement(TextFieldComponent, { name: 'pettyCashAmount', label: 'Total de efectivo en caja chica al iniciar la jornada laboral', onChange: handleChange, type: 'number', value: values.pettyCashAmount }),
            React.createElement(TextFieldComponent, { name: 'withdrawalRequired', label: 'Total de efectivo m\u00E1ximo en caja chica durante la jornada laboral', onChange: handleChange, type: 'number', value: values.withdrawalRequired })),
        React.createElement("div", { className: 'flex flex-1 flex-col' },
            React.createElement(SelectFieldComponent, { name: 'codeConfirmation', label: 'Tipo de autorizaci\u00F3n para cancelar o realizar descuentos.', onChange: handleChange, options: codeConfirmationOptions, value: values.codeConfirmation }),
            React.createElement(DinnerPreferenceFieldComponent, { value: values.dinner, onChange: handleChange }),
            React.createElement(SubtitleDialogComponent, { label: 'Cuentas por cobrar', className: 'mt-2' }),
            React.createElement(SwitchComponent, { label: 'Imprimir comprobante despu\u00E9s de realizar un pago.', name: 'printReceiptAfterPayment', onChange: handleSwitchChange, defaultValue: values.printReceiptAfterPayment }))));
});
export default BodyBranchSettingsComponent;
