import React, { useEffect } from 'react';

import { useLazyQuery, useReactiveVar } from '@apollo/client';
import { List } from '@mui/material';

import { searchBillingInformationField } from '../../../../../../../../../../apollo/cache';
import Item from './Item';
import LoadingProgress from '../../../../../../../../../../presentationals/LoadingProgress';

import GQL from '../../../../../../../../../customer/_gql';

const TableSearchDialogChooseBillingInformationBodyCreateFormCustomerComponent = () => {
  const searchBillingInformationFieldVar = useReactiveVar(searchBillingInformationField);
  const [getData, { data, loading }] = useLazyQuery(GQL.GET_BILLING_INFORMATIONS, {
    variables: { limit: 0, offset: 0 },
  });

  useEffect(() => {
    if (searchBillingInformationFieldVar.value)
      getData({
        variables: {
          like: searchBillingInformationFieldVar.value,
          likeKey: searchBillingInformationFieldVar.key,
        },
      });
  }, [searchBillingInformationFieldVar]);

  return (
    <div className='flex flex-1 flex-col overflow-y-auto'>
      {loading && <LoadingProgress />}
      <List component='nav' aria-labelledby='nested-customer-list'>
        {data?.customerBillingInformations &&
          data.customerBillingInformations.map((el, i) => (
            <Item key={el.id} {...el} background={i % 2 > 0 ? 'bg-inherit' : 'bg-gray-100'} />
          ))}
      </List>
    </div>
  );
};

export default TableSearchDialogChooseBillingInformationBodyCreateFormCustomerComponent;
