import { useReactiveVar } from '@apollo/client';
import React from 'react';
import { currentPaymentAccountReceivable, isPaymentFormOpen } from '../../../../apollo/cache';
import DialogComponent from '../../../../presentationals/Dialog';
import BodyMultiPaymentDialogComponent from './Body';
var MultiPaymentDialogComponent = function () {
    var isOpen = useReactiveVar(isPaymentFormOpen);
    var handleClose = function () {
        isPaymentFormOpen(false);
        currentPaymentAccountReceivable([]);
    };
    return (React.createElement(DialogComponent, { open: !!isOpen, title: 'Abono a una o m\u00E1s cuentas por cobrar', onClose: handleClose, fullScreen: true },
        React.createElement(BodyMultiPaymentDialogComponent, { onSuccess: handleClose })));
};
export default MultiPaymentDialogComponent;
