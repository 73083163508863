import React, { useRef } from 'react';
import { useReactiveVar } from '@apollo/client';
import { actionForm, customerSaleV2, inputError, isEditFormOpen } from '../../../apollo/cache';
import getDefaultCustomerValues from '../../sale.v2/Action/Customer/Form/Create/Body/helpers/defaultValues';
import FooterDialogComponent from '../../../presentationals/Dialog/Footer';
import DeleteButtonFooterCreateFormCustomerActionSaleV2Component from '../../sale.v2/Action/Customer/Form/Create/Footer/DeleteButton';
import ClearButtonFooterCreateFormCustomerActionSaleV2Component from '../../sale.v2/Action/Customer/Form/Create/Footer/ClearButton';
import CreateButtonFooterCreateFormCustomerActionSaleV2Component from '../../sale.v2/Action/Customer/Form/Create/Footer/CreateButton';
import BodyCreateFormCustomerActionSaleV2Component from '../../sale.v2/Action/Customer/Form/Create/Body';
var CreateCustomerComponent = function () {
    var createButtonFooterCreateFormCustomerActionSaleV2Ref = useRef(null);
    var bodyCustomerRef = useRef(null);
    var values = useReactiveVar(customerSaleV2);
    if (values.id) {
        isEditFormOpen(false);
        var defaultValues = getDefaultCustomerValues();
        customerSaleV2(defaultValues);
        inputError({});
    }
    actionForm({ isOpen: false, action: '', defaultData: {}, callback: null });
    var handleCreate = function () {
        var _a;
        (_a = createButtonFooterCreateFormCustomerActionSaleV2Ref.current) === null || _a === void 0 ? void 0 : _a.onPressEnter();
    };
    var handleGetValues = function () { var _a; return (_a = bodyCustomerRef.current) === null || _a === void 0 ? void 0 : _a.getValues(); };
    var handleClear = function () {
        var _a;
        (_a = bodyCustomerRef.current) === null || _a === void 0 ? void 0 : _a.onClear();
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(BodyCreateFormCustomerActionSaleV2Component, { ref: bodyCustomerRef, onPressEnter: handleCreate }),
        React.createElement(FooterDialogComponent, null,
            React.createElement("div", null,
                React.createElement(DeleteButtonFooterCreateFormCustomerActionSaleV2Component, null)),
            React.createElement("div", null,
                React.createElement(ClearButtonFooterCreateFormCustomerActionSaleV2Component, { onClick: handleClear }),
                React.createElement(CreateButtonFooterCreateFormCustomerActionSaleV2Component, { getValues: handleGetValues, ref: createButtonFooterCreateFormCustomerActionSaleV2Ref })))));
};
export default CreateCustomerComponent;
