import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from '@mui/material';
import React from 'react';
import { currencyFormatArray } from '../../../../../../../../helpers/getCurrencyLabel';
var CurrencyPurchaseOrderFieldComponent = function (props) {
    var handleRadioChange = function (event) {
        props.onChange('currencyKey', event.target.value);
    };
    return (React.createElement(FormControl, { className: 'mt-1' },
        React.createElement(FormLabel, { className: 'text-gray-900', id: 'currencyKey' }, "Moneda"),
        React.createElement(RadioGroup, { onChange: handleRadioChange, row: true, value: props.value, "aria-labelledby": 'currencyLabel', name: 'currencyKey' }, Object.keys(currencyFormatArray).map(function (key) { return (React.createElement(FormControlLabel, { key: key, value: key, control: React.createElement(Radio, null), label: currencyFormatArray[key] })); }))));
};
export default CurrencyPurchaseOrderFieldComponent;
