import React from 'react';
import AddCircleIcon from '@mui/icons-material/AddOutlined';
import { isCreateFormOpen } from '../../../../../apollo/cache';
import IconButtonComponent from '../../../../../presentationals/IconButton';
var OpenAddExpenseButtonComponent = function () {
    var handleClick = function () {
        isCreateFormOpen(true);
    };
    return (React.createElement(IconButtonComponent, { action: handleClick, icon: React.createElement(AddCircleIcon, null), size: 'small', color: 'secondary', label: 'A\u00F1adir gasto' }));
};
export default OpenAddExpenseButtonComponent;
