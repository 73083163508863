import React, { useEffect, useState } from 'react';

import ListAltIcon from '@mui/icons-material/ListAltOutlined';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined';
import LocalPrintshopOutlinedIcon from '@mui/icons-material/LocalPrintshopOutlined';
import EmailIcon from '@mui/icons-material/EmailOutlined';
import TagOutlinedIcon from '@mui/icons-material/TagOutlined';

import { actions } from '../../apollo/cache';
import CONFIG from '../../config/config.json';

import ProductsDialog from './products.dialog';
import DeliveryStatusDialog from './deliveryStatus.dialog';
import ReSendDialog from './reSend.dialog';
import TrackingNumberDialog from './trackingNumber.dialog';

const ActionComponent = () => {
  const [isOpenProducts, setIsOpenProducts] = useState([false, null]);
  const [isOpenStatus, setIsOpenStatus] = useState([false, null, null]);
  const [isOpenReSend, setIsOpenReSend] = useState([false, null]);
  const [isOpenTrackingNumber, setIsOpenTrackingNumber] = useState([false, null]);

  const handleOpenConfirm = (e, { id }) => setIsOpenProducts([true, id]);
  const handleCloseConfirm = () => setIsOpenProducts([false, null]);

  const handleOpenStatus = (e, { id, deliveryStatus }) =>
    setIsOpenStatus([true, id, deliveryStatus || 0]);
  const handleCloseStatus = () => setIsOpenStatus([false, null, null]);

  const handlePDF = (e, { id }) => window.open(`${CONFIG['restServer'].uri}/files/ecSales/${id}`);

  const handleReSend = (e, { id }) => setIsOpenReSend([true, id]);
  const handleCloseReSend = () => setIsOpenReSend([false, null]);

  const handleTrackingNumber = (e, { id }) => setIsOpenTrackingNumber([true, id]);
  const handleCloseTrackingNumber = () => setIsOpenTrackingNumber([false, null]);

  useEffect(() => {
    actions([
      {
        icon: <ListAltIcon />,
        label: 'Productos',
        onClick: handleOpenConfirm,
      },
      {
        icon: <TagOutlinedIcon fontSize='small' />,
        label: 'Consultar Número de Rastreo',
        onClick: handleTrackingNumber,
      },
      {
        icon: <LocalPrintshopOutlinedIcon />,
        label: 'Imprimir PDF',
        onClick: handlePDF,
      },
      {
        icon: <EmailIcon />,
        label: 'Reenviar Comprobante de Compra',
        onClick: handleReSend,
      },
      {
        icon: <CheckOutlinedIcon />,
        label: 'Listo para Envío',
        onClick: handleOpenStatus,
        disable: { key: 'deliveryStatus', value: [1, 2, 3] },
      },
      {
        icon: <LocalShippingOutlinedIcon />,
        label: 'Enviado',
        onClick: handleOpenStatus,
        disable: { key: 'deliveryStatus', value: [0, 2, 3, null] },
      },
    ]);
  }, []);

  return (
    <>
      <ProductsDialog
        isOpen={isOpenProducts[0]}
        handleClose={handleCloseConfirm}
        saleId={isOpenProducts[1]}
      />
      <DeliveryStatusDialog
        isOpen={isOpenStatus[0]}
        handleClose={handleCloseStatus}
        id={isOpenStatus[1]}
        deliveryStatus={isOpenStatus[2]}
      />
      <TrackingNumberDialog
        isOpen={isOpenTrackingNumber[0]}
        handleClose={handleCloseTrackingNumber}
        id={isOpenTrackingNumber[1]}
        deliveryStatus={isOpenTrackingNumber[2]}
      />
      <ReSendDialog isOpen={isOpenReSend[0]} handleClose={handleCloseReSend} id={isOpenReSend[1]} />
    </>
  );
};

export default ActionComponent;
