var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { forwardRef, useImperativeHandle, useState } from 'react';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import Table from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import DialogComponent from '../../../../../presentationals/Dialog';
import convertToCurrencyLabel from '../../../../../helpers/convertToCurrencyLabel';
import IconButtonComponent from '../../../../../presentationals/IconButton';
var AdjustInventoryProductDetailDialogComponent = forwardRef(function (props, ref) {
    var _a = useState([]), History = _a[0], setHistory = _a[1];
    var _b = useState(0), rowIndex = _b[0], setRowIndex = _b[1];
    var _c = useState(false), open = _c[0], setOpen = _c[1];
    var handleOpen = function (index, history) {
        setRowIndex(index);
        setHistory(history);
        setOpen(true);
    };
    var handleClose = function () {
        setOpen(false);
        setHistory([]);
    };
    useImperativeHandle(ref, function () { return ({
        open: handleOpen,
    }); });
    var handleDelete = function (e, index) {
        var history = History.find(function (el, i) { return i === index; });
        if (!history)
            return;
        var quantity = history.quantity;
        var totalHistory = 0;
        var newHistory = History.filter(function (el, i) { return i !== index; }).map(function (el, i) {
            totalHistory = totalHistory + el.quantity;
            return __assign(__assign({}, el), { movement: i + 1, total: totalHistory });
        });
        var newRows = props.Rows.map(function (el, i) {
            return i === rowIndex
                ? __assign(__assign({}, el), { newInvQty: el.newInvQty - quantity, movement: el.movementQty - 1, diffQty: el.diffQty ? el.diffQty - quantity : 0, history: newHistory }) : el;
        });
        props.onRowsChange(newRows);
        handleClose();
    };
    return (React.createElement(DialogComponent, { maxWidth: 'sm', open: open, onClose: handleClose, title: 'Movimientos del producto' },
        React.createElement(Table, { size: 'small', className: 'mb-4' },
            React.createElement(TableHead, null,
                React.createElement(TableRow, null,
                    History.length > 1 && React.createElement(TableCell, { padding: 'none' }),
                    React.createElement(TableCell, null, "Movimiento"),
                    React.createElement(TableCell, null, "A\u00F1adido"),
                    React.createElement(TableCell, null, "Total"))),
            React.createElement(TableBody, null, History.map(function (el, i) { return (React.createElement(TableRow, { hover: true, key: el.movement },
                History.length > 1 && (React.createElement(TableCell, null,
                    React.createElement("div", null,
                        React.createElement(IconButtonComponent, { label: 'Eliminar', size: 'small', icon: React.createElement(DeleteOutlineIcon, { fontSize: 'small' }), params: i, action: handleDelete })))),
                React.createElement(TableCell, null, convertToCurrencyLabel(el.movement)),
                React.createElement(TableCell, null, convertToCurrencyLabel(el.quantity)),
                React.createElement(TableCell, null, convertToCurrencyLabel(el.total)))); })))));
});
export default AdjustInventoryProductDetailDialogComponent;
