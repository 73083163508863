var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
var addRequisitionProductById = function (Item, Rows) {
    var index = Rows.findIndex(function (el) {
        return (el.barcode && el.barcode === Item.barcode) ||
            el.productPresentationId === Item.productPresentationId;
    });
    if (typeof index === 'number' && index >= 0)
        return __spreadArray([
            {
                barcode: Item.barcode,
                productPresentationId: Item.productPresentationId,
                quantity: Rows[index].quantity + Item.quantity,
            }
        ], Rows.filter(function (el, i) { return i !== index; }), true);
    return __spreadArray([
        {
            barcode: Item.barcode,
            quantity: Item.quantity,
            productPresentationId: Item.productPresentationId,
        }
    ], Rows, true);
};
export default addRequisitionProductById;
