import client from '../../apollo';
import { getCompanyData } from '../../presentationals/PDF/cashClosing.80mm';
import GQL_PRINT from '../print/_gql';

const print = async (id, type) => {
  try {
    const { data } = await client.mutate({
      mutation: GQL_PRINT.PRINT,
      variables: { id, type },
    });
    return data.print;
  } catch (e) {
    console.log(e);
    return e;
  }
};

export default async uuid => {
  const companyData = await getCompanyData();
  if (parseInt(companyData.printClient) === 2) await print(uuid, 'invoice');
};
