var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { FormControl, FormLabel, TextField } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import FieldErrorComponent from '../Error';
var RHFTextFieldComponent = function (_a) {
    var config = _a.config, props = __rest(_a, ["config"]);
    var _b = useFormContext(), _c = _b.formState, errors = _c.errors, isSubmitting = _c.isSubmitting, register = _b.register;
    return (React.createElement(FormControl, { className: "w-full ".concat(props.marginY ? "my-[".concat(props.marginY, "px]") : ''), disabled: props.disabled || isSubmitting, error: !!errors[props.name] },
        props.label && React.createElement(FormLabel, { component: 'legend' }, props.label),
        React.createElement(TextField, __assign({}, register(props.name, config), { variant: props.variant || 'outlined', fullWidth: typeof props.fullWidth === 'boolean' ? props.fullWidth : true, autoFocus: props.autoFocus, onKeyDown: props.onKeyDown, placeholder: props.placeholder, size: props.size || 'small', disabled: props.disabled || isSubmitting, helperText: props.helperText, autoComplete: 'off', multiline: props.multiline, type: props.type, rows: props.rows })),
        React.createElement(FieldErrorComponent, { name: props.name })));
};
export default RHFTextFieldComponent;
