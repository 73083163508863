import React from 'react';
import useGetInvoiceComplements from './helpers/useGetInvoiceComplements';
import ModuleV3Component from '../../presentationals/Module.v3';
import FilterInvoiceComplementDialog from './Filter';
import CreateInvoiceComplementComponent from './Create';
import getSearchSaleByOptions from '../../helpers/getSearchSaleByOptions';
import RedirectEditFormToRouteComponent from '../../presentationals/RedirectEditFormToRoute';
var InvoiceComplementReportComponent = function () {
    var _a = useGetInvoiceComplements(), data = _a.data, loading = _a.loading;
    var searchSaleByOptions = getSearchSaleByOptions();
    var Header = [
        { Header: 'Creado el', accessor: 'createdAt' },
        { Header: 'Estatus', accessor: 'status' },
        { Header: 'Folio', accessor: 'folio' },
        { Header: 'UUID', accessor: 'uuid' },
        { Header: 'Cliente', accessor: 'customer' },
        { Header: 'Importe', accessor: 'total' },
        { Header: 'Sucursal', accessor: 'branch' },
        { Header: 'Emisor', accessor: 'businessNameSender' },
    ];
    return (React.createElement(React.Fragment, null,
        React.createElement(ModuleV3Component, { filter: true, searchFieldOptions: {
                name: 'searchInvoiceComplementBy',
                options: searchSaleByOptions,
                defaultValue: 'folio',
            }, title: 'Complementos de pago', header: Header, data: data, isLoading: loading, showReturnButton: true }),
        React.createElement(CreateInvoiceComplementComponent, null),
        React.createElement(FilterInvoiceComplementDialog, null),
        React.createElement(RedirectEditFormToRouteComponent, null)));
};
export default InvoiceComplementReportComponent;
