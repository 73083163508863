var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
export var convertSaleFormatLabelToNumber = function (label) {
    switch (label) {
        case 'invoice':
            return -1;
        case 'maintenance':
            return 6;
        case 'quotation':
            return -2;
        case 'sale':
            return 1;
        case 'serviceOrder':
            return 3;
        case 'rent':
            return 7;
        default:
            return 0;
    }
};
var getSaleFormatByCompany = function (companyId, allowedModules) {
    var allowedModuleKeys = Object.keys(allowedModules);
    var availableSaleFormats = allowedModuleKeys.reduce(function (newObject, key) {
        var _a;
        if (!((_a = allowedModules[key]) === null || _a === void 0 ? void 0 : _a.isAllowed))
            return newObject;
        switch (key) {
            case 'invoice':
                return __assign(__assign({}, newObject), { '-1': 'Factura' });
            case 'maintenance':
                return __assign(__assign({}, newObject), { 6: 'Mantenimiento' });
            case 'quotation':
                return __assign(__assign({}, newObject), { '-2': 'Cotización' });
            case 'sale':
                return __assign(__assign({}, newObject), { 1: 'Nota de venta' });
            case 'serviceOrder':
                return __assign(__assign({}, newObject), { 3: 'Orden de Servicio' });
            case 'rent':
                return __assign(__assign({}, newObject), { 7: 'Renta' });
            default:
                return newObject;
        }
    }, {});
    var availableSaleFormatsKeys = Object.keys(availableSaleFormats);
    switch (companyId.toString()) {
        case '116':
            return availableSaleFormatsKeys.reduce(function (newObject, key) {
                var _a;
                return !['-1', '-2', '1', '3', '6'].includes("".concat(key))
                    ? __assign(__assign({}, newObject), (_a = {}, _a[key] = "".concat(key) === '7' ? 'Presupuesto' : availableSaleFormats[key], _a)) : newObject;
            }, {});
        default:
            return availableSaleFormats;
    }
};
export default getSaleFormatByCompany;
