import React, { useRef } from 'react';
import BodyMainCreateRequisitionComponent from './Body';
import FooterDialogComponent from '../../../../presentationals/Dialog/Footer';
import NextStepCreateRequisitionButtonComponent from './NextStepButton';
var MainCreateRequisitionComponent = function (props) {
    var bodyMainCreateRequisitionRef = useRef(null);
    var handleGetValues = function () { var _a; return (_a = bodyMainCreateRequisitionRef === null || bodyMainCreateRequisitionRef === void 0 ? void 0 : bodyMainCreateRequisitionRef.current) === null || _a === void 0 ? void 0 : _a.getValues(); };
    return (React.createElement("div", { className: 'flex flex-1 flex-col' },
        React.createElement(BodyMainCreateRequisitionComponent, { ref: bodyMainCreateRequisitionRef }),
        React.createElement(FooterDialogComponent, null,
            React.createElement("div", null),
            React.createElement(NextStepCreateRequisitionButtonComponent, { onContinue: props.onContinue, getValues: handleGetValues }))));
};
export default MainCreateRequisitionComponent;
