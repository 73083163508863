import React, { useRef } from 'react';
import SearchIcon from '@mui/icons-material/SearchOutlined';
import { InputAdornment } from '@mui/material';
import IconButtonComponent from '../../IconButton';
import OutlinedFieldComponent from '../OutlinedField';
import SearchUnitKeyDialogComponent from './SearchDialog';
var SatUnitKeyComponent = function (props) {
    var searchUnitKeyDialogRef = useRef(null);
    var handleClick = function () {
        var _a;
        (_a = searchUnitKeyDialogRef.current) === null || _a === void 0 ? void 0 : _a.open(props.value);
    };
    var handleSelected = function (value) {
        props.onChange(props.name, value);
    };
    var handleKeyPress = function (event) {
        var _a;
        if (event.key === 'Enter')
            (_a = searchUnitKeyDialogRef.current) === null || _a === void 0 ? void 0 : _a.open(props.value);
    };
    var handleSetValue = function () { return props.value; };
    return (React.createElement(React.Fragment, null,
        React.createElement(OutlinedFieldComponent, { label: 'C\u00F3digo de unidad de medida (SAT)', name: props.name, value: props.value, error: props.error, onChange: props.onChange, onKeyDown: handleKeyPress, endAdornment: React.createElement(InputAdornment, { position: 'end' },
                React.createElement(IconButtonComponent, { action: handleClick, size: 'small', className: 'p-0 m-0', icon: React.createElement(SearchIcon, { fontSize: 'small' }) })) }),
        React.createElement(SearchUnitKeyDialogComponent, { onSelected: handleSelected, getValue: handleSetValue, ref: searchUnitKeyDialogRef })));
};
export default SatUnitKeyComponent;
