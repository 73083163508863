import { InMemoryCache, makeVar } from '@apollo/client';
import { offsetLimitPagination } from '@apollo/client/utilities';
import getDefaultCreateColorValues from '../containers/product/Item/Ecommerce/Body/Color/AddButton/Form/helpers/defaultValues';
import getDefaultCreateSecondaryColorValues from '../containers/product/Item/Ecommerce/Body/SecondaryColor/AddButton/Form/helpers/defaultValues';
import getDefaultCustomerFormValues from '../containers/sale.v2/Action/Customer/Form/Create/Body/helpers/defaultValues';
import getDefaultProductFormValues from '../containers/sale.v2/ChooseProduct/ProductDialog/Create/Body/helpers/getDefaultValues';
import getSettings from '../helpers/getSettings';
import getDefaultCalendarValues from '../containers/calendar/Create/Body/Form/helpers/defaultValues';
import getDefaultValues from '../containers/purchaseOrder.v2/CreateForm/Products/Table/Body/Item/helpers/getDefaultValues';
import getDefaultTotalToPay from '../containers/sale.v2/helpers/getDefaultTotalToPay';
import getDefaultBranchValues from '../containers/branch/helpers/getDefaultBranchValues';
import getDefaultRoleValues from '../containers/role/helpers/getDefaultBranchValues';
import { defaultSearchBillingInformationField } from '../containers/sale.v2/Action/Customer/Form/Create/Body/ChooseBillingInformation/SearchDialog/Form';
import getDefaultEmployeeForm from '../containers/employee/helpers/getDefaultEmployeeForm';
import getDefaulPaymentMethod from '../containers/paymentMethod/helpers/getDefaulPaymentMethod';
import getDefaultBoxValues from '../containers/box/helpers/getDefaultBoxValues';
import getDefaultClassificationValues from '../containers/classification/helpers/getDefaultClassificationValues';
import getDefaultCompanyValues from '../containers/company/helpers/getDefaultCompanyValues';
import getSession from '../helpers/getSession';
import getDefaultProductPromotionPriceValues from '../containers/promotion/CreateProductPromotionPrice/Body/helpers/getDefaultValues';
import getDefaultProductVariationValues from '../containers/product/ProductVariation/Body/helpers/getDefaultProductVariationValues';
import getDefaultProductPresentationValues from '../containers/product/Item/Structure/CreatePresentation/Body/helpers/getDefaultValues';
import getDefaultIngredientProductForm from '../containers/product/ProductPresentation/Component/Create/Body/helpers/getDefaultIngredientProductForm';
export var isCreateRateOpen = makeVar(false);
export var cashFlowSalev2Id = makeVar(undefined);
export var isCreateAddressLine2Open = makeVar(undefined);
export var isEditAddressLine2Open = makeVar(false);
export var rentPath = makeVar(0);
export var isCancelPaymentOpen = makeVar(false);
export var isSendSaleByEmailOpen = makeVar(false);
export var isSendInvoiceComplementByEmailOpen = makeVar(false);
export var isCreatedInvoiceOpen = makeVar(false);
export var isCreatedInvoiceComplementOpen = makeVar(false);
export var isInvoiceComplementOpen = makeVar(false);
export var isCreateTransactionAccountOpen = makeVar(false);
export var isAssignPaymentFormOpen = makeVar(false);
export var isPaymentFormOpen = makeVar(false);
export var PickupItemForm = makeVar({});
export var CloseRentItemForm = makeVar({});
var defaultProductVariationValues = getDefaultProductVariationValues();
export var productVariationForm = makeVar(defaultProductVariationValues);
export var isEditPickupItemOpen = makeVar(false);
export var isEditProductVariationOpen = makeVar(false);
export var isEditRentProductOpen = makeVar(false);
export var isCreateProductVariationOpen = makeVar(false);
export var isAddRentProductOpen = makeVar(false);
var defaultProductPresentationValues = getDefaultProductPresentationValues();
export var productPresentationForm = makeVar(defaultProductPresentationValues);
export var isProductDialogOpen = makeVar([false, '', '', null]);
var defaultProductPromotionPriceValues = getDefaultProductPromotionPriceValues();
export var productPromotionPriceForm = makeVar(defaultProductPromotionPriceValues);
export var isCloseRequisitionOpen = makeVar(false);
export var isCreateProductIngredientPriceOpen = makeVar(false);
export var isCreateProductComponentOpen = makeVar(false);
export var isCreateProductPriceOpen = makeVar(false);
export var isProductPricePromotionOpen = makeVar(false);
export var isProductPromotionOpen = makeVar(false);
export var isEditProductLimitInventoryOpen = makeVar(false);
export var isCreateProductLimitInventoryOpen = makeVar(false);
export var isAddProductPromotionOpen = makeVar(false);
export var isCreatePresentationProductOpen = makeVar(false);
export var isAppsMenuOpen = makeVar([false, null]);
export var isManageAccountOpen = makeVar(false);
export var isChangeProfilePictureOpen = makeVar(false);
var session = getSession();
export var wsToken = makeVar(session === null || session === void 0 ? void 0 : session.wsToken);
var defaultCompanyValues = getDefaultCompanyValues();
export var companyForm = makeVar(defaultCompanyValues);
export var workingHoursForm = makeVar([]);
export var isTableScrollLoading = makeVar(false);
export var fetchMoreData = makeVar(false);
export var isPettyCashOpen = makeVar(false);
var defaultClassificationValues = getDefaultClassificationValues();
export var classificationForm = makeVar(defaultClassificationValues);
var defaultBoxForm = getDefaultBoxValues();
export var boxForm = makeVar(defaultBoxForm);
var defaultPaymentMethodForm = getDefaulPaymentMethod();
export var paymentMethodForm = makeVar(defaultPaymentMethodForm);
var defaultEmployeeForm = getDefaultEmployeeForm();
export var employeeForm = makeVar(defaultEmployeeForm);
export var isEditDescriptionHistoryCustomerFormOpen = makeVar([
    null,
    '',
]);
export var isEditDiagnosisHistoryCustomerFormOpen = makeVar([false, null, null]);
export var isImageClassificationDialogOpen = makeVar(null);
export var isHistoryCalendarOpen = makeVar(null);
export var isEditCalendarFormOpen = makeVar(null);
export var isPostponeCalendarOpen = makeVar(false);
export var isCreateEmployeeOpen = makeVar(false);
export var saleV2FolioField = makeVar({ folio: '', serial: '' });
var defaultIngredientProductForm = getDefaultIngredientProductForm();
export var ingredientProductForm = makeVar(defaultIngredientProductForm);
export var isIngredientProductSettingsDialogOpen = makeVar([false, null]);
export var permissionSearchField = makeVar('');
var defaultRoleForm = getDefaultRoleValues();
export var roleForm = makeVar(defaultRoleForm);
var defaultBranchValues = getDefaultBranchValues();
export var branchForm = makeVar(defaultBranchValues);
export var isCreateFormOpen = makeVar(false);
export var isEditFormOpen = makeVar(false);
export var publicChatToken = makeVar([null, null]);
var settings = getSettings();
var defaultEditPurchaseOrderItemValues = getDefaultValues();
export var limitDays = makeVar(null);
export var productsToPrint = makeVar([false, []]);
export var moduleMoreOptionsItems = makeVar([]);
export var isCloseSaleOpen = makeVar([false, null]);
export var isCloseQuotationOpen = makeVar([false, null]);
export var isCloseMaintenanceOpen = makeVar([false, null]);
/*
 * PurchaseOrder
 */
export var purchaseOrderCartItems = makeVar([]);
export var isEditPurchaseOrderItemOpen = makeVar(false);
export var editPurchaseOrderItemForm = makeVar(defaultEditPurchaseOrderItemValues);
/*
 *
 */
/*
 * CalendarV2
 */
export var calendarForm = makeVar(getDefaultCalendarValues());
export var calendarSettings = makeVar(settings.agenda);
/*
 *
 */
var defaultCreateColorValues = getDefaultCreateColorValues();
var defaultCreateSecondaryColorValues = getDefaultCreateSecondaryColorValues();
export var createECFormColor = makeVar(defaultCreateColorValues);
export var createECFormSecondaryColor = makeVar(defaultCreateSecondaryColorValues);
export var isDuplicateSaleOpen = makeVar(false);
export var isECUpdateColorFormOpen = makeVar([false, null]);
export var isECUpdateSecColorFormOpen = makeVar([false, null]);
/*
 * Sale v2
 */
var defaultTotalToPay = getDefaultTotalToPay();
export var totalsSaleV2 = makeVar([0, 0, 0, 0]);
export var saleTotalToPay = makeVar(defaultTotalToPay);
export var cartItems = makeVar([]);
export var productListSaleV2 = makeVar([]);
export var addToCartSaleV2 = makeVar(false);
export var isSaleConfirmDialogOpen = makeVar(false);
export var isItemDetailDialogOpen = makeVar(null);
export var isChooseBillingInformationDialogOpen = makeVar(false);
export var isSatUnitKeyDialogOpen = makeVar(false);
export var isSatProductKeyDialogOpen = makeVar(false);
export var isSaleEmployeeFormOpen = makeVar(false);
export var isSaleBranchFormOpen = makeVar(false);
export var isSaleProductFormOpen = makeVar(false);
export var isSaleCustomerFormOpen = makeVar(false);
export var isSaleCurrencyFormOpen = makeVar(false);
export var isSaleFormatFormOpen = makeVar(false);
var defaultCustomerFormValues = getDefaultCustomerFormValues();
var defaultProductFormValues = getDefaultProductFormValues();
export var discountTypeSaleV2 = makeVar(1);
export var editProductSaleV2 = makeVar(false);
export var quotationSaleV2 = makeVar({ expirationDate: null, bankAccount: null });
export var productSaleV2 = makeVar(defaultProductFormValues);
export var customerSaleV2 = makeVar(defaultCustomerFormValues);
export var formatSaleV2 = makeVar(settings.defaultFormatSaleV2);
export var invoiceSaleV2 = makeVar({
    paymentMethod: 'PUE',
    paymentForm: '',
    useCfdi: '',
    relateUUID: '',
    relateType: '',
});
export var operationDateSaleV2 = makeVar(null);
export var pinSaleV2 = makeVar('');
export var employeeSaleV2 = makeVar('');
export var commentarySaleV2 = makeVar('');
export var branchSaleV2 = makeVar({ branchSale: '', branchInventory: '' });
export var currencySaleV2 = makeVar(['MXN', 1]);
export var setUseContactAddress = makeVar(true);
export var inputError = makeVar({});
/*
 *
 */
export var resetImage = makeVar(false);
export var resetFile = makeVar(false);
export var globalInvoiceSales = makeVar([[], [0, 0, 0]]);
export var invoiceFormState = makeVar(0);
export var billingInfo = makeVar({
    saleId: null,
    applyExtraTaxes: '',
    paymentMethod: '',
    paymentMethodId: '',
    useCfdi: '',
});
export var pagination = makeVar([0, 25]);
export var snackbar = makeVar({
    isOpen: false,
    time: null,
    label: '',
    severity: 'success',
});
export var actionForm = makeVar({
    isOpen: false,
    action: '',
    defaultData: {},
    callback: null,
});
export var deleteForm = makeVar({
    isOpen: false,
    params: {},
});
export var cart = makeVar({
    customer: null,
    newCustomer: null,
    commentary: '',
    SaleProductData: [],
    priceName: 'Estándar',
    currency: 'MXN',
    exchangeRate: '1',
    sellerId: '',
});
export var discountType = makeVar(settings.discountType);
export var form = makeVar({});
export var item = makeVar({});
export var editItem = makeVar({});
export var isMenuOpen = makeVar(false);
export var reloadSize = makeVar(false);
export var filterForm = makeVar(false);
export var sessionCalendarForm = makeVar({ isOpen: false, Crms: [] });
export var searchV3 = makeVar('');
export var searchField = makeVar(null);
export var searchProductField = makeVar('');
export var searchUnitKeyField = makeVar('');
export var searchProductKeyField = makeVar('');
export var searchBillingInformationField = makeVar(defaultSearchBillingInformationField);
export var exportPdf = makeVar({ export: false });
export var exportXls = makeVar({ export: false });
export var filter = makeVar({});
export var count = makeVar(0);
export var actions = makeVar([]);
export var customerCalendarCheck = makeVar({ isOpen: false, dataId: null, type: '' });
export var isCalendarFormOpen = makeVar([false, {}]);
export var sort = makeVar({});
export var totals = makeVar(null);
export var focusRoom = makeVar({ isOpen: false, room: null, user: null });
export var step = makeVar(0);
export var module = makeVar(0);
export var clearForm = makeVar(false);
export var handleBack = makeVar(false);
export var handleContinue = makeVar(false);
export var handleContinueConfiguration = makeVar({});
export var steps = makeVar([]);
export var adjustPricePurchase = makeVar(false);
export var editPurchaseProduct = makeVar(false);
export var isNewChatOpen = makeVar(false);
export var exportCustomXls = makeVar(false);
export var purchaseOrderId = makeVar(null);
export var packageId = makeVar(null);
export var storeAction = makeVar([false, false]);
export var isStoreLoading = makeVar(false);
export var exportCustomPdf = makeVar(false);
export var calendarId = makeVar(null);
export var currentPaymentAccountReceivable = makeVar([]);
export var cache = new InMemoryCache({
    typePolicies: {
        AccountReceivable: {
            fields: {
                currentPayment: {
                    read: function (_, _a) {
                        var readField = _a.readField;
                        var accountReceivableId = readField('id');
                        var inArray = currentPaymentAccountReceivable().find(function (el) { return el.accountReceivableId === accountReceivableId; });
                        return inArray ? inArray.currentPayment : 0;
                    },
                },
            },
        },
        TwilioSettingsData: {
            keyFields: ['messagingServiceSid'],
        },
        TwilioSettings: {
            keyFields: ['companyId'],
        },
        RolePermissionsResponse: {
            keyFields: ['roleId'],
        },
        Permissions: {
            keyFields: ['roleId'],
        },
        CalendarQueueManager: {
            keyFields: ['companyId'],
        },
        CalendarSettings: {
            keyFields: ['companyId'],
        },
        EmployeeCalendarSettings: {
            keyFields: ['employeeId'],
        },
        BranchConfig: {
            keyFields: ['branchId'],
        },
        ProductInventory: {
            keyFields: ['ProductPresentation', ['id'], 'branchId'],
        },
        SaleProductsRotation: {
            keyFields: ['productId'],
        },
        CustomerAutoReceivableConfig: {
            keyFields: ['customerId'],
        },
        Query: {
            fields: {
                productsEC: offsetLimitPagination(['relevant', 'classificationId']),
                snackbar: {
                    read: function () {
                        return snackbar();
                    },
                },
                actionForm: {
                    read: function () {
                        return actionForm();
                    },
                },
                deleteForm: {
                    read: function () {
                        return deleteForm();
                    },
                },
                cart: {
                    read: function () {
                        return cart();
                    },
                },
                form: {
                    read: function () {
                        return form();
                    },
                },
                pagination: {
                    read: function () {
                        return pagination();
                    },
                },
                isMenuOpen: {
                    read: function () {
                        return isMenuOpen();
                    },
                },
                searchField: {
                    read: function () {
                        return searchField();
                    },
                },
                filterForm: {
                    read: function () {
                        return filterForm();
                    },
                },
                exportPdf: {
                    read: function () {
                        return exportPdf();
                    },
                },
                exportXls: {
                    read: function () {
                        return exportXls();
                    },
                },
                count: {
                    read: function () {
                        return count();
                    },
                },
                actions: {
                    read: function () {
                        return actions();
                    },
                },
                filter: {
                    read: function () {
                        return filter();
                    },
                },
                sessionCalendarForm: {
                    read: function () {
                        return sessionCalendarForm();
                    },
                },
                customerCalendarCheck: {
                    read: function () {
                        return customerCalendarCheck();
                    },
                },
                isCalendarFormOpen: {
                    read: function () {
                        return isCalendarFormOpen();
                    },
                },
                sort: {
                    read: function () {
                        return sort();
                    },
                },
                totals: {
                    read: function () {
                        return totals();
                    },
                },
                exportCustomXls: {
                    read: function () {
                        return exportCustomXls();
                    },
                },
                exportCustomPdf: {
                    read: function () {
                        return exportCustomPdf();
                    },
                },
            },
        },
    },
});
