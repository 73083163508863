import CREATE_ADJUST_INVENTORY from './createAdjustInventory';
import DELETE_ADJUST_INVENTORY from './deleteAdjustInventory';
import FRAGMENT_ADJUST_INVENTORY from './fragmentAdjustInventory';
import GET_ADJUST_INVENTORIES from './getAdjustInventories';
import GET_ADJUST_INVENTORY_BY_ID from './getAdjustInventoryById';
import GET_LAST_ADJUST_INVENTORY_PRODUCT from './getLastAdjustInventoryProduct';
var GQL_ADJUST_INVENTORY = {
    CREATE_ADJUST_INVENTORY: CREATE_ADJUST_INVENTORY,
    DELETE_ADJUST_INVENTORY: DELETE_ADJUST_INVENTORY,
    FRAGMENT_ADJUST_INVENTORY: FRAGMENT_ADJUST_INVENTORY,
    GET_ADJUST_INVENTORIES: GET_ADJUST_INVENTORIES,
    GET_ADJUST_INVENTORY_BY_ID: GET_ADJUST_INVENTORY_BY_ID,
    GET_LAST_ADJUST_INVENTORY_PRODUCT: GET_LAST_ADJUST_INVENTORY_PRODUCT,
};
export default GQL_ADJUST_INVENTORY;
