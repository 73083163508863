import React from 'react';
import LoadingProgress from '../../../../presentationals/LoadingProgress';
import CONFIG from '../../../../config/config.json';
import useGetCompanySettings from '../../../../hooks/useGetCompanySettings';
import UpdateCompanyImageButtonComponent from './UpdateButton';
var ImageCompanyComponent = function () {
    var _a;
    var _b = useGetCompanySettings(), data = _b.data, loading = _b.loading;
    if (loading)
        return React.createElement(LoadingProgress, null);
    return (React.createElement("div", { className: 'flex flex-1 px-4 py-3 flex-col ' },
        React.createElement("div", { className: 'text-base font-medium mb-1 flex' }, "Logotipo de la empresa"),
        React.createElement("div", { className: 'text-sm sm:text-base text-gray-600 mb-2' },
            "Al incorporar el logotipo de la empresa en nuestra plataforma, estamos estableciendo un enlace visual directo con nuestra identidad. Esto no solo refuerza la presencia de la marca, sino que tambi\u00E9n contribuye a una experiencia m\u00E1s cohesiva y memorable para todos los usuarios y clientes. que interact\u00FAan con nuestra plataforma.",
            React.createElement("br", null),
            React.createElement("div", { className: 'font-medium' }, "Para una mejor experiencia, se sugiere que el tama\u00F1o de la imagen sea de 256px x 256px, en formato PNG.")),
        React.createElement("div", null,
            React.createElement(UpdateCompanyImageButtonComponent, null)),
        ((_a = data === null || data === void 0 ? void 0 : data.company) === null || _a === void 0 ? void 0 : _a.logoFileId) && (React.createElement("div", { className: 'flex-1 py-4 flex flex-col md:grid md:grid-cols-4 md:gap-4 overflow-auto' },
            React.createElement("img", { src: "".concat(CONFIG['api-file-service'].url, "/files/").concat(data.company.logoFileId), alt: "company-img-".concat(data.company.logoFileId), className: 'w-96' })))));
};
export default ImageCompanyComponent;
