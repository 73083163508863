import React from 'react';
import PromotionLayoutComponent from './PromotionLayout';
import GeneralEditPromotionComponent from '../containers/promotion/Edit/General';
import ContentEditPromotionComponent from '../containers/promotion/Edit/Content';
import PromotionComponent from '../containers/promotion';
import ImagePromotionComponent from '../containers/promotion/Edit/Image';
import FormPromotionComponent from '../containers/promotion/Form';
var getPromotionChildrenRoutes = function () {
    return [
        { index: true, element: React.createElement(PromotionComponent, null) },
        { path: 'create', element: React.createElement(FormPromotionComponent, null) },
        {
            path: ':promotionId',
            element: React.createElement(PromotionLayoutComponent, null),
            children: [
                { index: true, element: React.createElement(GeneralEditPromotionComponent, null) },
                { path: 'products', element: React.createElement(ContentEditPromotionComponent, null) },
                { path: 'image', element: React.createElement(ImagePromotionComponent, null) },
            ],
        },
    ];
};
export default getPromotionChildrenRoutes;
