import React from 'react';
import SettingsIcon from '@mui/icons-material/Settings';
import IconButtonComponent from '../../../IconButton';
import useTableSettingsDialog from '../../../../store/tableSettings/tableSettingsDialog';
var TableSettingButtonComponent = function (props) {
    var setOpen = useTableSettingsDialog(function (state) { return state.setOpen; });
    var handleClick = function () {
        setOpen(true);
    };
    return (React.createElement(IconButtonComponent, { action: handleClick, icon: React.createElement(SettingsIcon, null), size: 'large', label: 'Preferencias', disabled: props.isLoading }));
};
export default TableSettingButtonComponent;
