import React from 'react';

import Form from './form';
import Table from './table';

const Cart = props => {
  return (
    <>
      <Form codeToClear={props.codeToClear} id={props.id} status={props.status} />
      <Table id={props.id} data={props.data} folio={props.folio} />
    </>
  );
};

export default Cart;
