import React from 'react';
import { useNavigate } from 'react-router-dom';
import Container from '../../presentationals/Module.v2/Container';
import GQL from './_gql';
import Action from './_action';

const title = 'Promociones';
const operation = ['promotion', 'promotions'];

const Rows = [
  { label: 'Fecha Creación', key: 'createdAt', format: 'dateTime' },
  { label: 'Nombre', key: 'name', sort: 'text' },
  { label: 'Fecha Lanzamiento', key: 'start', format: 'date' },
  { label: 'Fecha Final', key: 'end', format: 'date' },
  { label: 'Dias Disponibles', key: 'availableDays', format: 'promotionAvailableDays' },
];

const PromotionComponent = () => {
  const navigate = useNavigate();
  const handleCustomCreate = () => {
    navigate('create');
  };
  return (
    <>
      <Container
        title={title}
        operation={operation}
        Rows={Rows}
        get={GQL.GET}
        create={GQL.CREATE}
        handleCustomCreate={handleCustomCreate}
        noEdit
      />
      <Action />
    </>
  );
};

export default PromotionComponent;
