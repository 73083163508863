var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { gql } from '@apollo/client';
import FRAGMENT_PRODUCT_INGREDIENT from './fragmentProductIngredient';
import FRAGMENT_PRODUCT_INGREDIENT_PRICES from './fragmentProductIngredientPrices';
var GET_PRODUCT_INGREDIENT_BY_ID = gql(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  ", "\n  ", "\n  query ProductIngredient($id: ID!) {\n    productIngredient(id: $id) {\n      ...FragmentProductIngredient\n      ProductIngredientPrices {\n        ...FragmentProductIngredientPrices\n      }\n    }\n  }\n"], ["\n  ", "\n  ", "\n  query ProductIngredient($id: ID!) {\n    productIngredient(id: $id) {\n      ...FragmentProductIngredient\n      ProductIngredientPrices {\n        ...FragmentProductIngredientPrices\n      }\n    }\n  }\n"])), FRAGMENT_PRODUCT_INGREDIENT, FRAGMENT_PRODUCT_INGREDIENT_PRICES);
export default GET_PRODUCT_INGREDIENT_BY_ID;
var templateObject_1;
