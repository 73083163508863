import React, { useRef, useState } from 'react';
import HorizontalSplitIcon from '@mui/icons-material/HorizontalSplitOutlined';
import Container from '../../presentationals/Module.v2/Container';
import CreateAdjustInventoryDialogComponent from './CreateForm';
import Action from './_action';
import Filter from './_filter';
import AdjustInventoriesProducts from './adjustInventoriesProducts.dialog';
import GQL_ADJUST_INVENTORY from '../../apollo/gql/adjustInventory';
var title = 'Ajuste de inventario';
var operation = ['adjustInventory', 'adjustInventories'];
var Rows = [
    { label: 'Fecha', key: 'createdAt', format: 'dateTime' },
    { label: 'Folio', key: 'folio && serial', strcat: '-', sort: 'text', filter: true },
    { label: 'Tipo de ajuste', key: 'action' },
    { label: 'Empleado', key: 'Employee firstName' },
    { label: 'Sucursal', key: 'Branch name' },
];
var AdjustInventoryComponent = function () {
    var createAdjustInventoryDialogRef = useRef(null);
    var _a = useState(false), isOpenXlsx = _a[0], setIsOpenXlsx = _a[1];
    var handleOpenXlsx = function () { return setIsOpenXlsx(true); };
    var handleCloseXlsx = function () { return setIsOpenXlsx(false); };
    var handleOpenCreateForm = function () {
        var _a;
        (_a = createAdjustInventoryDialogRef === null || createAdjustInventoryDialogRef === void 0 ? void 0 : createAdjustInventoryDialogRef.current) === null || _a === void 0 ? void 0 : _a.open();
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(Container, { title: title, operation: operation, Rows: Rows, get: GQL_ADJUST_INVENTORY.GET_ADJUST_INVENTORIES, filter: true, noEdit: true, handleCustomCreate: handleOpenCreateForm, additionalAction: [
                {
                    handle: handleOpenXlsx,
                    label: 'Descargar XLS de Diferencias',
                    icon: React.createElement(HorizontalSplitIcon, null),
                },
            ] }),
        React.createElement(CreateAdjustInventoryDialogComponent, { ref: createAdjustInventoryDialogRef }),
        React.createElement(Action, null),
        React.createElement(Filter, null),
        React.createElement(AdjustInventoriesProducts, { isOpen: isOpenXlsx, handleClose: handleCloseXlsx })));
};
export default AdjustInventoryComponent;
