var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { currencyFormat } from '../../../helpers';
import getProductTableColumnConfiguration from '../../../helpers/getProductTableColumnConfiguration';
var getProductRowsToShowInTable = function (rows) {
    var columnsSetting = getProductTableColumnConfiguration();
    return rows.map(function (el) {
        var sku = columnsSetting.sku ? { sku: el.sku || '-' } : {};
        var code = columnsSetting.code ? { code: el.code || '-' } : {};
        var prefix = columnsSetting.prefix ? { prefix: el.prefix || '-' } : {};
        var stock = columnsSetting.stock
            ? { stock: el.Inventory ? "".concat(el.Inventory.quantity) : '0' }
            : {};
        var cost = columnsSetting.cost ? { cost: currencyFormat(el.cost || 0) } : {};
        var classification = columnsSetting.classification
            ? { classification: el.Classification ? el.Classification.name : '-' }
            : {};
        var presentation = columnsSetting.presentation
            ? { presentation: el.PresentationBase ? el.PresentationBase.name : '-' }
            : {};
        var barcode = columnsSetting.barcode ? { barcode: el.barcode || '-' } : {};
        var productKey = columnsSetting.productKey ? { productKey: el.productKey || '-' } : {};
        var unitKey = columnsSetting.unitKey ? { unitKey: el.unitKey || '-' } : {};
        var iva = columnsSetting.iva ? { iva: el.iva || '-' } : {};
        var ieps = columnsSetting.ieps ? { ieps: el.ieps || '-' } : {};
        return __assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign({ id: el.id }, sku), code), prefix), { name: el.name || '-' }), stock), { price: currencyFormat(el.price || 0) }), cost), classification), presentation), barcode), productKey), unitKey), iva), ieps);
    });
};
export default getProductRowsToShowInTable;
