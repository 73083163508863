import React, { useEffect, useState } from 'react';
import { useReactiveVar } from '@apollo/client';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableHead from '@mui/material/TableHead';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActiveOutlined';
import CreditCardIcon from '@mui/icons-material/CreditCardOutlined';
import PermIdentityIcon from '@mui/icons-material/PermIdentity';
import RoomIcon from '@mui/icons-material/RoomOutlined';
import NotesIcon from '@mui/icons-material/NotesOutlined';
import { customerCalendarCheck, isCalendarFormOpen } from '../../../../../apollo/cache';
import {
  eventFormat,
  dateTimeFormat,
  currencyFormat,
  dateFormat,
  hourFormat,
} from '../../../../../helpers';
import FormDialog from '../../../../../presentationals/FormDialog';
import IconButton from '../../../../../presentationals/IconButton';
import Button from '../../../../../presentationals/Button';
import GQL_CALENDAR from '../../../../calendar/_gql';
import Payment from '../../../../receivable.v2/payment.dialog';
import useApolloLazyQuery from '../../../../../hooks/useApolloLazyQuery';
import GQL_ACCOUNT_RECEIVABLE from '../../../../../apollo/gql/accountReceivable';

const Component = () => {
  const customerCalendarCheckVar = useReactiveVar(customerCalendarCheck);
  const [isOpenPayment, setIsOpenPayment] = useState([false, null]);
  const [getCalendar, { data, loading }] = useApolloLazyQuery(GQL_CALENDAR.GET_BY_ID);
  const [getReceivables, { data: dataReceivables, refetch: refetchReceivables }] =
    useApolloLazyQuery(GQL_ACCOUNT_RECEIVABLE.GET_ACCOUNT_RECEIVABLES);

  useEffect(() => {
    if (data && customerCalendarCheckVar.isOpen)
      getReceivables({
        variables: { limit: 0, paid: false, customerId: data.customerId },
      });
  }, [data, customerCalendarCheckVar.isOpen]);

  useEffect(() => {
    if (customerCalendarCheckVar)
      customerCalendarCheckVar.dataId &&
        getCalendar({ variables: { id: customerCalendarCheckVar.dataId } });
  }, [customerCalendarCheckVar.dataId]);

  const handleClose = () => {
    customerCalendarCheck({ isOpen: false, dataId: null });
  };
  const handlePayment = (e, accountReceivableId) => setIsOpenPayment([true, accountReceivableId]);
  const handleClosePayment = () => {
    refetchReceivables({ limit: 0, paid: false, customerId: data.customerId });
    setIsOpenPayment([false, null]);
  };

  const handleCalendar = () => {
    if (data) isCalendarFormOpen([true, { crmId: data.crmId, customerId: data.customerId }]);
  };
  return (
    <>
      <FormDialog
        isOpen={customerCalendarCheckVar.isOpen}
        handleClose={handleClose}
        hideAction
        isLoading={loading}
        width='sm'
        title='Resumen de Cita'
        rightContent={
          data &&
          data.session < data.Crm.sessions && (
            <Grid item xs={12} align='right'>
              <Button
                variant='outlined'
                size='small'
                color='secondary'
                label='Nueva Cita'
                className='text-right mt-2'
                onClick={handleCalendar}
              />
            </Grid>
          )
        }
      >
        <br />
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Grid container spacing={1} alignItems='center'>
              <Grid item xs={1}>
                <div
                  style={{
                    backgroundColor: data && data.Tag ? data.Tag.color : '#ff0000',
                    width: 20,
                    height: 20,
                    borderRadius: 10,
                  }}
                />
              </Grid>
              <Grid item xs={11}>
                <Typography variant='h5'>{data && data.title}</Typography>
              </Grid>
              {data && data.session && (
                <>
                  <Grid item xs={1} />
                  <Grid item xs={11}>
                    <Typography variant='body2'>{`Sesión: ${data.session}`}</Typography>
                  </Grid>
                </>
              )}
              <Grid item xs={1} />
              <Grid item xs={11}>
                <Typography variant='body2'>{data && eventFormat(data.start, data.end)}</Typography>
              </Grid>
              <Grid item xs={12} className='mt-2' />
              {data && data.Customer && (
                <Grid item xs={1}>
                  <PermIdentityIcon className='m-0 p-0' />
                </Grid>
              )}
              {data && data.Customer && (
                <Grid item xs={11}>
                  <Typography variant='body2'>
                    {`${data.Customer.firstName} ${data.Customer.lastName}`}
                    <br />
                    {data.Customer.phone}
                  </Typography>
                </Grid>
              )}
              {data && data.reminder && (
                <Grid item xs={1}>
                  <NotificationsActiveIcon className='m-0 p-0' />
                </Grid>
              )}
              {data && data.reminder && (
                <Grid item xs={11}>
                  <Typography variant='body2'>
                    Recordatorio activado
                    <br />
                    <span className='text-[#808080]'>
                      {`${dateFormat(data.reminder)} ${hourFormat(data.reminder)}`}
                    </span>
                  </Typography>
                </Grid>
              )}
              {data && data.address && (
                <Grid item xs={1}>
                  <RoomIcon className='m-0 p-0' />
                </Grid>
              )}
              {data && data.address && (
                <Grid item xs={11}>
                  <Typography variant='body2'>{data.address}</Typography>
                </Grid>
              )}
              {data && data.diagnosis && (
                <Grid item xs={1}>
                  <NotesIcon className='m-0 p-0' />
                </Grid>
              )}
              {data && data.diagnosis && <Typography variant='body2'>{data.diagnosis}</Typography>}
              {data && data.CalendarNotes.length > 0 && (
                <Grid item xs={1}>
                  <NotesIcon className='m-0 p-0' />
                </Grid>
              )}
              {data && data.CalendarNotes.length > 0 && (
                <Grid item xs={11}>
                  <Typography variant='body2'>
                    {data.CalendarNotes.map(el => (
                      <span key={el.id}>
                        {el.description}
                        <br />
                      </span>
                    ))}
                  </Typography>
                </Grid>
              )}
            </Grid>
            <Grid item xs={12}>
              <br />
              <Grid item xs={12}>
                <Typography variant='h6'>CxC</Typography>
              </Grid>
              <TableContainer className='rounded mt-3'>
                <Table size='small'>
                  <TableHead>
                    <TableRow>
                      <TableCell>Fecha</TableCell>
                      <TableCell align='right'>Folio</TableCell>
                      <TableCell align='right'>Total</TableCell>
                      <TableCell align='right'>Abonado</TableCell>
                      <TableCell align='right'>Saldo</TableCell>
                      <TableCell />
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {dataReceivables &&
                      dataReceivables.map(el => (
                        <TableRow key={el.id} hover>
                          <TableCell>{dateTimeFormat(el.clientCreatedAt)}</TableCell>
                          <TableCell align='right'>{el.folio}</TableCell>
                          <TableCell align='right'>{currencyFormat(el.total)}</TableCell>
                          <TableCell align='right'>{currencyFormat(el.payment)}</TableCell>
                          <TableCell align='right'>{currencyFormat(el.balance)}</TableCell>
                          <TableCell align='right'>
                            <IconButton
                              label='Abonar'
                              params={el.id}
                              action={handlePayment}
                              icon={<CreditCardIcon fontSize='small' />}
                              className='p-1'
                              size='large'
                            />
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        </Grid>
      </FormDialog>
      <Payment
        isOpen={isOpenPayment[0]}
        calendarId={customerCalendarCheckVar.dataId}
        handleClose={handleClosePayment}
        accountReceivableId={isOpenPayment[1]}
      />
    </>
  );
};

export default Component;
