import React from 'react';

import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';

const LinearForm = props => {
  const handleClick = e => {
    props.handleAdd(e, props.params);
  };

  return (
    <div className='flex flex-1'>
      <div className='flex flex-1 pr-2'>{props.children}</div>
      <div>
        <Fab
          disabled={props.isLoading}
          color={props.color || 'secondary'}
          size='small'
          onClick={handleClick}
        >
          {props.icon ? props.icon : <AddIcon fontSize={props.size || 'medium'} />}
        </Fab>
      </div>
    </div>
  );
};

export default LinearForm;
