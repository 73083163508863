var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React from 'react';
import { useMutation } from '@apollo/client';
import ButtonFooterDialogComponent from '../../../../../presentationals/Dialog/Footer/Button';
import getInputErrorFormat from '../../../../sale.v2/Action/helpers/getInputErrorFormat';
import CONSTANTS_ERROR from '../../../../../constants/error';
import GQL_PURCHASE_ORDER from '../../../../../apollo/gql/purchaseOrder';
import useSnackbarDialog from '../../../../../store/snackbar/snackbarDialog';
var CreatePurchaseOrderButtonComponent = function (props) {
    var setSnackbar = useSnackbarDialog().setSnackbar;
    var _a = useMutation(GQL_PURCHASE_ORDER.CREATE_PURCHASE_ORDER), createPurchaseOrder = _a[0], loading = _a[1].loading;
    var handleClick = function () { return __awaiter(void 0, void 0, void 0, function () {
        var discount_1, discountType_1, error_1;
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r;
        return __generator(this, function (_s) {
            switch (_s.label) {
                case 0:
                    _s.trys.push([0, 2, , 3]);
                    if (!props.inputs)
                        throw new Error(CONSTANTS_ERROR.UNDEFINED_VALUES);
                    if (!((_a = props.inputs.General) === null || _a === void 0 ? void 0 : _a.providerId))
                        throw new Error(CONSTANTS_ERROR.UNDEFINED_PURCHASE_ORDER_PROVIDER);
                    discount_1 = parseFloat(((_b = props.inputs.Discount) === null || _b === void 0 ? void 0 : _b.discount) || '0');
                    discountType_1 = ((_c = props.inputs.Discount) === null || _c === void 0 ? void 0 : _c.discountType) || 1;
                    return [4 /*yield*/, createPurchaseOrder({
                            variables: {
                                status: (_d = props.inputs.General) === null || _d === void 0 ? void 0 : _d.action,
                                folio: ((_e = props.inputs.General) === null || _e === void 0 ? void 0 : _e.docFolio) || '',
                                commentary: '',
                                discount: discount_1,
                                discountType: (_f = props.inputs.Discount) === null || _f === void 0 ? void 0 : _f.discountType,
                                currencyKey: (_g = props.inputs.Currency) === null || _g === void 0 ? void 0 : _g.currencyKey,
                                exchangeRate: parseFloat(((_h = props.inputs.Currency) === null || _h === void 0 ? void 0 : _h.exchangeRate) || '1'),
                                date: (_j = props.inputs.General) === null || _j === void 0 ? void 0 : _j.pursacheDate,
                                branchId: (_k = props.inputs.Branch) === null || _k === void 0 ? void 0 : _k.branchId,
                                taxesOption: (_l = props.inputs.General) === null || _l === void 0 ? void 0 : _l.taxesOption,
                                showTaxes: (_m = props.inputs.General) === null || _m === void 0 ? void 0 : _m.showTaxesOptions,
                                providerId: (_o = props.inputs.General) === null || _o === void 0 ? void 0 : _o.providerId,
                                paymentForm: (_p = props.inputs.General) === null || _p === void 0 ? void 0 : _p.paymentForm,
                                payment: parseFloat(((_q = props.inputs.General) === null || _q === void 0 ? void 0 : _q.amountPaid) || '0'),
                                transactionAccountId: null,
                                Products: (_r = props.inputs.Products) === null || _r === void 0 ? void 0 : _r.map(function (el) {
                                    var _a, _b;
                                    return ({
                                        productId: el.Product.id,
                                        productPresentationId: el.ProductPresentation.id,
                                        name: el.Product.name,
                                        commentary: '',
                                        quantity: el.quantity,
                                        discount: discountType_1 === 1 ? discount_1 : discount_1 / (((_b = (_a = props.inputs) === null || _a === void 0 ? void 0 : _a.Products) === null || _b === void 0 ? void 0 : _b.length) || 1),
                                        unitPrice: el.cost,
                                        price: el.salePrice,
                                        iva: 0,
                                        ieps: 0,
                                        adjustPrice: el.updateSalePrice,
                                        percentAdjust: el.percentAdjust,
                                    });
                                }),
                            },
                        })];
                case 1:
                    _s.sent();
                    setSnackbar({
                        isOpen: true,
                        label: 'No cerrare la venta para seguir haciendo pruebas sin batallar.',
                        severity: 'success',
                    });
                    return [3 /*break*/, 3];
                case 2:
                    error_1 = _s.sent();
                    getInputErrorFormat(error_1);
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    return (React.createElement(ButtonFooterDialogComponent, { disabled: props.disabled || loading, onClick: handleClick }, "Finalizar"));
};
export default CreatePurchaseOrderButtonComponent;
