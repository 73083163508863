import { gql } from '@apollo/client';

const SEND_MESSAGE = gql`
  mutation SendMessage($message: String! $employeeId: ID $user: ID){
    sendMessage(
    	input: {
      	message: $message
      	filter: {
        	employeeId: $employeeId
          user: $user
      	}
      }
    )
  }
`;

const SET_CALENDAR_ZONE = gql`
  mutation SetCalendarZone($calendarId: ID!, $planeZoneId: ID) {
    setCalendarZone(calendarId: $calendarId, planeZoneId: $planeZoneId) {
      id
      title
      start
      end
      diagnosis
      Tag {
        color
        name
      }
      status
      Customer {
        firstName
        lastName
        phone
      }
      PlaneZone {
        id
      }
    }
  }
`;

export default { SEND_MESSAGE, SET_CALENDAR_ZONE };
