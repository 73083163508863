var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import getProductTableColumnConfiguration from './getProductTableColumnConfiguration';
export var defaultProductTableColumnsConfiguration = {
    name: true,
    code: true,
    prefix: true,
    sku: true,
    classification: true,
    presentation: true,
    stock: false,
    price: true,
    cost: true,
    barcode: true,
    iva: true,
    ieps: true,
    productKey: true,
    unitKey: true,
};
var setProductTableColumnConfiguration = function (column, toShow) {
    var _a;
    if (['id', 'name', 'price'].includes(column))
        return;
    var settings = getProductTableColumnConfiguration();
    var newConfiguration = __assign(__assign(__assign({}, defaultProductTableColumnsConfiguration), settings), (_a = { name: true, price: true }, _a[column] = toShow, _a));
    localStorage.setItem('productTableColumnConfiguration', JSON.stringify(newConfiguration));
};
export default setProductTableColumnConfiguration;
