import React, { useMemo } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import CheckboxGroupComponent from '../../../../presentationals/RHForm/CheckboxGroup';
import getProductColumnsToExport from './helpers/getColumnsToExport';
import getPresentationColumnsToExport from './helpers/getPresentationColumnsToExport';
import getIngredientColumnsToExport from './helpers/getIngredientColumnsToExport';
var ProductColumsToExportFieldComponent = function () {
    var control = useFormContext().control;
    var value = useController({
        name: 'type',
        control: control,
    }).field.value;
    var options = useMemo(function () {
        if (value === 1)
            return getProductColumnsToExport();
        if (value === 2)
            return getPresentationColumnsToExport();
        if (value === 3)
            return getIngredientColumnsToExport();
        return [];
    }, [value]);
    return (React.createElement(CheckboxGroupComponent, { name: 'columns', label: 'Selecciona las propiedades que deseas exportar', options: options }));
};
export default ProductColumsToExportFieldComponent;
