import React from 'react';
import EditIcon from '@mui/icons-material/Edit';
import IconButtonComponent from '../../../../../../presentationals/IconButton';
import { isEditCalendarFormOpen } from '../../../../../../apollo/cache';
var OpenEditCalendarButtonComponent = function (props) {
    var handleClick = function () {
        isEditCalendarFormOpen(props.calendarId);
    };
    return (React.createElement(IconButtonComponent, { color: 'default', icon: React.createElement(EditIcon, { fontSize: 'small' }), action: handleClick, size: 'small' }));
};
export default OpenEditCalendarButtonComponent;
