import React, { useRef } from 'react';
import ButtonFooterDialogComponent from '../../../../../presentationals/Dialog/Footer/Button';
import setInputErrorFormat from '../../../../sale.v2/Action/helpers/setInputErrorFormat';
import getInputErrorFormat from '../../../../sale.v2/Action/helpers/getInputErrorFormat';
import ConfirmAutoRequisitionDialogComponent from '../ConfirmAutoRequisitionDialog';
var NextStepCreateRequisitionButtonComponent = function (props) {
    var confirmAutoRequisitionDialogRef = useRef(null);
    var handleClick = function () {
        var _a;
        try {
            var values = props.getValues();
            if (!values)
                return;
            if (!values.branchId)
                throw new Error(setInputErrorFormat('branchId', 'Selecciona una sucursal.'));
            if (values.createAutoRequisition) {
                (_a = confirmAutoRequisitionDialogRef.current) === null || _a === void 0 ? void 0 : _a.open();
                return;
            }
            props.onContinue(values);
        }
        catch (error) {
            getInputErrorFormat(error);
        }
    };
    return (React.createElement("div", null,
        React.createElement(ButtonFooterDialogComponent, { onClick: handleClick }, "Continuar"),
        React.createElement(ConfirmAutoRequisitionDialogComponent, { onCreate: props.getValues, ref: confirmAutoRequisitionDialogRef })));
};
export default NextStepCreateRequisitionButtonComponent;
