import React from 'react';
import SearchIcon from '@mui/icons-material/SearchOutlined';
import IconButton from '../../../../../../../../../presentationals/IconButton';
import { isChooseBillingInformationDialogOpen } from '../../../../../../../../../apollo/cache';
var ButtonChooseBillingInformationBodyCreateFormCustomerComponent = function () {
    var handleClick = function () {
        isChooseBillingInformationDialogOpen(true);
    };
    return (React.createElement("div", { className: 'flex' },
        React.createElement(IconButton, { action: handleClick, size: 'small', className: 'p-0 m-0', icon: React.createElement(SearchIcon, { fontSize: 'small' }) })));
};
export default ButtonChooseBillingInformationBodyCreateFormCustomerComponent;
