import React, { useEffect, useState } from 'react';

import { useLazyQuery, useMutation } from '@apollo/client';

import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/SaveOutlined';
import CloseIcon from '@mui/icons-material/Close';
import CommentOutlinedIcon from '@mui/icons-material/CommentOutlined';
import Typography from '@mui/material/Typography';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Chip from '@mui/material/Chip';

import TextField from '../../../../presentationals/FormComponents/TextField';

import { currencyFormat } from '../../../../helpers/general';

import IconButton from './helpers/iconButton';

import CommentDialog from './comment.dialog';
import GQL from '../../_gql';

const defaultValues = {
  id: null,
  quantity: 0,
  unitPrice: 0,
};

const TableComponent = props => {
  const [itemToEdit, setItemToEdit] = useState(null);
  const [values, setValues] = useState(defaultValues);
  const [errors, setErrors] = useState({});
  const [ivaPercent, setIvaPercent] = useState(0);
  const [iepsPercent, setIepsPercent] = useState(0);
  const [addTaxes, setAddTaxes] = useState(true);
  const [isOpenComment, setIsOpenComment] = useState([false, null]);

  const [getProducts, { data }] = useLazyQuery(GQL.GET_BY_ID);
  const [updateProduct, { loading: editLoading }] = useMutation(GQL.UPDATE_PRODUCT, {
    update(cache, { data }) {
      try {
        cache.modify({
          id: cache.identify({ __typename: 'Quote', id: props.id }),
          fields: {
            total() {
              return data.updataQuoteProduct.Quote.total;
            },
            taxes() {
              return data.updataQuoteProduct.Quote.taxes;
            },
            subtotal() {
              return data.updataQuoteProduct.Quote.subtotal;
            },
          },
        });
      } catch (e) {
        console.log(e.message);
      }
    },
  });
  const [deleteProduct] = useMutation(GQL.DELETE_PRODUCT, {
    update(cache, { data }) {
      cache.modify({
        id: cache.identify({ __typename: 'Quote', id: props.id }),
        fields: {
          total(cachedTotal) {
            return cachedTotal - data.deleteQuoteProduct.total;
          },
          taxes(cachedTaxes) {
            return cachedTaxes - data.deleteQuoteProduct.taxes;
          },
          subtotal(cachedSubtotal) {
            return cachedSubtotal - data.deleteQuoteProduct.subtotal;
          },
        },
      });
      const normalizedId = cache.identify({
        id: data.deleteQuoteProduct.id,
        __typename: 'QuoteProduct',
      });
      cache.evict({ id: normalizedId });
      cache.gc();
    },
  });

  useEffect(() => {
    if (props.id) getProducts({ variables: { id: props.id } });
  }, [props.id]);

  const handleDeleteItem = async (e, id) => {
    await deleteProduct({ variables: { id } });
  };

  const handleEdit = (e, id) => {
    const row = data.quote.QuoteProducts.find(tmp => parseInt(tmp.id) === parseInt(id));
    setItemToEdit(id);
    setValues({ quantity: row.quantity, id, unitPrice: row.withoutTaxes });
    setIvaPercent(parseInt(row.iva) / 100);
    setIepsPercent(parseInt(row.ieps) / 100);
    setAddTaxes(true);
  };

  const handleClose = () => {
    setItemToEdit(null);
    setValues(defaultValues);
    setErrors({});
    setAddTaxes(true);
    setIepsPercent(0);
    setIvaPercent(0);
  };

  const handleChange = (name, value) => {
    setValues(values => ({ ...values, [name]: value }));
  };

  const handleSave = async (e, id) => {
    try {
      if (values.quantity === '') {
        const e = new Error('Escribe una cantidad.');
        e.name = 'quantity';
        throw e;
      }
      if (parseFloat(values.quantity) <= 0 || Number.isNaN(parseFloat(values.quantity))) {
        const e = new Error('Escribe una cantidad mayor a cero.');
        e.name = 'quantity';
        throw e;
      }
      if (values.unitPrice === '') {
        const e = new Error('Escribe un precio unitario.');
        e.name = 'unitPrice';
        throw e;
      }
      if (parseFloat(values.unitPrice) < 0 || Number.isNaN(parseFloat(values.unitPrice))) {
        const e = new Error('Escribe un precio unitario mayor o igual a cero.');
        e.name = 'unitPrice';
        throw e;
      }

      const unitPrice = addTaxes
        ? parseFloat(values.unitPrice)
        : parseFloat(values.unitPrice) / (1 + parseFloat(ivaPercent) + parseFloat(iepsPercent));

      await updateProduct({
        variables: {
          id,
          quantity: parseFloat(values.quantity),
          unitPrice,
          iva: parseFloat(ivaPercent) * 100,
          ieps: parseFloat(iepsPercent) * 100,
        },
      });
      handleClose();
    } catch (e) {
      setErrors({ [e.name]: e.message });
    }
  };

  const handleIvaPercent = () => {
    const tmpIvaPercent = ivaPercent === 0 ? 0.16 : 0;
    setIvaPercent(tmpIvaPercent);
  };

  const handleIepsPercent = () => {
    const tmpIepsPercent = iepsPercent === 0 ? 0.08 : 0;
    setIepsPercent(tmpIepsPercent);
  };

  const handleTaxes = () => {
    setAddTaxes(addTaxes => !addTaxes);
  };

  const handleCommentItem = (e, data) => {
    setIsOpenComment([true, data]);
  };
  const handleCommentClose = () => setIsOpenComment([false, null]);

  return (
    <>
      <TableContainer>
        <Table size='small'>
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell>Producto</TableCell>
              <TableCell>Cant.</TableCell>
              <TableCell align='center'>Costo</TableCell>
              <TableCell align='center'>P. Unitario</TableCell>
              <TableCell align='center'>Subtotal</TableCell>
              <TableCell align='center'>Descuento</TableCell>
              <TableCell align='center'>IVA</TableCell>
              <TableCell align='center'>IEPS</TableCell>
              {itemToEdit && <TableCell align='center' />}
              <TableCell align='right'>Impuestos</TableCell>
              <TableCell align='right'>Total</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data &&
              data.quote &&
              data.quote.QuoteProducts.map((el, i) => (
                <TableRow key={i}>
                  <TableCell>
                    <div className='flex'>
                      {parseInt(itemToEdit) !== parseInt(el.id) ? (
                        <>
                          <IconButton
                            label='Eliminar'
                            icon={<DeleteOutlineIcon />}
                            params={el.id}
                            action={handleDeleteItem}
                            size='small'
                          />
                          <IconButton
                            label='Editar'
                            size='small'
                            icon={<EditIcon />}
                            params={el.id}
                            action={handleEdit}
                          />
                          <IconButton
                            label='Comentario'
                            icon={<CommentOutlinedIcon />}
                            params={el.id}
                            action={handleCommentItem}
                            size='small'
                          />
                        </>
                      ) : (
                        <>
                          <IconButton
                            label='Guardar cambios'
                            size='small'
                            icon={<SaveIcon />}
                            disabled={editLoading}
                            params={el.id}
                            action={handleSave}
                          />
                          <IconButton
                            label='Cancelar'
                            size='small'
                            icon={<CloseIcon />}
                            params={el.id}
                            action={handleClose}
                          />
                        </>
                      )}
                    </div>
                  </TableCell>
                  <TableCell>
                    {`${el.Product.name} ${
                      el.Product.sku !== '' ? `| SKU ${el.Product.sku}` : ''
                    }`.trim()}
                    {el.commentary ? (
                      <>
                        <br />
                        <Typography variant='caption'>{el.commentary}</Typography>
                      </>
                    ) : (
                      ''
                    )}
                  </TableCell>
                  <TableCell>
                    {parseInt(itemToEdit) !== parseInt(el.id) ? (
                      el.quantity
                    ) : (
                      <div className='w-[100px]'>
                        <TextField
                          name='quantity'
                          onChange={handleChange}
                          value={values.quantity}
                          error={errors.quantity}
                          type='number'
                          variant='outlined'
                        />
                      </div>
                    )}
                  </TableCell>
                  <TableCell align='center'>{currencyFormat(el.cost)}</TableCell>
                  <TableCell align='center'>
                    {parseInt(itemToEdit) !== parseInt(el.id) ? (
                      currencyFormat(el.withoutTaxes)
                    ) : (
                      <div className='w-[100px]'>
                        <TextField
                          name='unitPrice'
                          onChange={handleChange}
                          value={values.unitPrice}
                          error={errors.unitPrice}
                          type='number'
                          variant='outlined'
                        />
                      </div>
                    )}
                  </TableCell>
                  <TableCell align='center'>{currencyFormat(el.subtotal)}</TableCell>
                  <TableCell align='center'>{currencyFormat(el.discount)}</TableCell>
                  {/*<TableCell align='center'>{currencyFormat(el.iva)}</TableCell>*/}
                  <TableCell align='center'>
                    {parseInt(itemToEdit) !== parseInt(el.id) ? (
                      `${parseFloat(el.iva)}%`
                    ) : (
                      <div className='w-[100px]'>
                        <Chip
                          className='h-[40px] flex flex-1 mr-1'
                          onClick={handleIvaPercent}
                          label={`IVA ${parseFloat(ivaPercent) * 100}%`}
                          classes={{ label: 'px-1' }}
                          color='primary'
                          variant='outlined'
                        />
                      </div>
                    )}
                  </TableCell>
                  {/*<TableCell align='center'>{currencyFormat(el.ieps)}</TableCell>*/}
                  <TableCell align='center'>
                    {parseInt(itemToEdit) !== parseInt(el.id) ? (
                      `${parseFloat(el.ieps)}%`
                    ) : (
                      <div className='w-[100px]'>
                        <Chip
                          className='h-[40px] flex flex-1 mr-1'
                          onClick={handleIepsPercent}
                          label={`IEPS ${parseFloat(iepsPercent) * 100}%`}
                          classes={{ label: 'px-1' }}
                          color='primary'
                          variant='outlined'
                        />
                      </div>
                    )}
                  </TableCell>
                  {itemToEdit && (
                    <TableCell align='center'>
                      {parseInt(itemToEdit) !== parseInt(el.id) ? (
                        <div />
                      ) : (
                        <div className='w-[100px]'>
                          <Chip
                            className='h-[40px] flex flex-1 mr-1'
                            onClick={handleTaxes}
                            label={addTaxes ? 'Añadir Impuestos' : 'Desglosar Impuestos'}
                            classes={{ label: 'px-1' }}
                            color='secondary'
                            variant='outlined'
                          />
                        </div>
                      )}
                    </TableCell>
                  )}
                  <TableCell align='right'>{currencyFormat(el.taxes)}</TableCell>
                  <TableCell align='right'>{currencyFormat(el.total)}</TableCell>
                </TableRow>
              ))}
            <TableRow>
              <TableCell colSpan={itemToEdit ? 11 : 10} align='right'>
                Subtotal:
              </TableCell>
              <TableCell align='right'>
                {currencyFormat(data && data.quote ? data.quote.subtotal : 0)}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell colSpan={itemToEdit ? 11 : 10} align='right'>
                Descuento:
              </TableCell>
              <TableCell align='right'>
                {currencyFormat(data && data.quote ? data.quote.discount : 0)}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell colSpan={itemToEdit ? 11 : 10} align='right'>
                IVA:
              </TableCell>
              <TableCell align='right'>
                {currencyFormat(data && data.quote ? data.quote.taxes : 0)}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell colSpan={itemToEdit ? 11 : 10} align='right'>
                <strong>Total:</strong>
              </TableCell>
              <TableCell align='right'>
                <strong>{currencyFormat(data && data.quote ? data.quote.total : 0)}</strong>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <CommentDialog
        isOpen={isOpenComment[0]}
        id={isOpenComment[1]}
        handleClose={handleCommentClose}
      />
    </>
  );
};

export default TableComponent;
