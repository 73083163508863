import React from 'react';

import { Card } from '@mui/material';

import Format from './Format';
import Customer from './Customer';
import FinishButtonHeaderSaleV2Component from './FinishButton';
import ButtonGeneralDiscountActionSaleV2Component from './GeneralDiscount';
import CashFlowActionSaleV2ButtonComponent from './CashFlow';

const ActionSaleV2Component = () => {
  return (
    <div className='flex flex-col-reverse md:flex-row'>
      <Card className='flex flex-row md:w-full bg-white md:mt-0 overflow-x-auto rounded md:overflow-hidden border-0 border-b-[3px] border-solid border-[#3f51b5] md:mr-4'>
        <Format />
        <Customer />
        <ButtonGeneralDiscountActionSaleV2Component />
        <CashFlowActionSaleV2ButtonComponent />
      </Card>
      <FinishButtonHeaderSaleV2Component />
    </div>
  );
};

export default ActionSaleV2Component;
