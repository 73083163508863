import React, { useEffect, useState } from 'react';
import { Checkbox, FormControlLabel } from '@mui/material';
import { useFormContext, useWatch } from 'react-hook-form';
import dropKeysWithCoincidences from '../../../../../../../helpers/dropKeysWithCoincidences';
import addKeysWithoutCoincidences from '../../../../../../../helpers/addKeysWithoutCoincidences';
var SelectAllBranchesCheckboxMultiCompanyReportComponent = function (props) {
    var _a = useState(false), checked = _a[0], setChecked = _a[1];
    var setValue = useFormContext().setValue;
    var values = useWatch({ name: props.name });
    var handleChange = function (e) {
        e.stopPropagation();
    };
    useEffect(function () {
        if (props.availableBranches.length > 0 && values.length > 0) {
            var everyChecked = props.availableBranches.reduce(function (isChecked, el) { return (values.includes(el) ? isChecked : false); }, true);
            setChecked(everyChecked);
        }
    }, [values, props.availableBranches]);
    var handleCheckboxChange = function (e, isChecked) {
        setChecked(isChecked);
        var newArray = (isChecked
            ? addKeysWithoutCoincidences(values, props.availableBranches)
            : dropKeysWithCoincidences(values, props.availableBranches));
        setValue(props.name, newArray);
    };
    return (React.createElement("div", { className: 'flex items-center font-medium' },
        React.createElement(FormControlLabel, { label: '', onClick: handleChange, disabled: props.disabled, checked: checked, className: 'm-0', control: React.createElement(Checkbox, { onChange: handleCheckboxChange }) }),
        props.label));
};
export default SelectAllBranchesCheckboxMultiCompanyReportComponent;
