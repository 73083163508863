import React, { useEffect } from 'react';
import { useReactiveVar } from '@apollo/client';
import useApolloLazyQuery from '../../../../hooks/useApolloLazyQuery';
// import GQL from '../../_gql.classification';
import { classificationForm } from '../../../../apollo/cache';
import LoadingProgress from '../../../../presentationals/LoadingProgress';
import CONFIG from '../../../../config/config.json';
import UploadButtonImageClassificationComponent from './UploadButton';
import DeleteButtonImageClassificationComponent from './DeleteButton';
import FooterDialogComponent from '../../../../presentationals/Dialog/Footer';
import GQL_CLASSIFICATION from '../../../../apollo/gql/classification';
var ImageEditClassificationComponent = function () {
    var values = useReactiveVar(classificationForm);
    var _a = useApolloLazyQuery(GQL_CLASSIFICATION.GET_CLASSIFICATION_BY_ID), getFile = _a[0], _b = _a[1], data = _b.data, loading = _b.loading;
    useEffect(function () {
        if (values.id)
            getFile({ variables: { id: values.id } });
    }, [values]);
    if (loading)
        return React.createElement(LoadingProgress, null);
    if (!values.id)
        return React.createElement("div", null);
    if (data === null || data === void 0 ? void 0 : data.fileId)
        return (React.createElement(React.Fragment, null,
            React.createElement("div", { className: 'flex-1 px-6 py-4 flex flex-col md:grid md:grid-cols-4 md:gap-4 overflow-auto' },
                React.createElement("img", { src: "".concat(CONFIG['api-file-service'].url, "/files/").concat(data.fileId), alt: "ClassificationImg-".concat(data.fileId), className: 'w-96' })),
            React.createElement(FooterDialogComponent, null,
                React.createElement(DeleteButtonImageClassificationComponent, { classificationId: values.id }),
                React.createElement("div", null))));
    return (React.createElement("div", { className: 'px-6 py-4 flex flex-col md:grid md:grid-cols-4 md:gap-4 overflow-auto' },
        React.createElement(UploadButtonImageClassificationComponent, { classificationId: values.id })));
};
export default ImageEditClassificationComponent;
