import React, { useState, useEffect } from 'react';

import { useQuery, useLazyQuery, useReactiveVar } from '@apollo/client';

import Grid from '@mui/material/Grid';

import TextField from '../../../../../presentationals/TextField';
import LinearForm from '../../../../../presentationals/LinearForm';
import Autocomplete from '../../../../../presentationals/Autocomplete';
import SelectField from '../../../../../presentationals/SelectField';

import { item, clearForm, form } from '../../../../../apollo/cache';

import GQL_PRODUCT from '../../../../product.v2/_gql';

const defaultValues = {
  quantity: 1,
  price: '',
  productPresentationId: '',
  productId: null,
  discountPercent: 0,
  typeQuantity: 1,
};

const ProductPromotionFormComponent = () => {
  const formVar = useReactiveVar(form);

  const clearFormVar = useReactiveVar(clearForm);

  const [values, setValues] = useState(defaultValues);
  const [products, setProducts] = useState([]);
  const [errors, setErrors] = useState({});
  const [focus, setFocus] = useState(false);
  const [itemsVisibility, setItemsVisibility] = useState(true);

  const { data: productsData } = useQuery(GQL_PRODUCT.GET_NAME, { variables: { limit: 0 } });

  const [getProductPresentations, { data: productPresentationsData }] = useLazyQuery(
    GQL_PRODUCT.GET_PRODUCT_PRESENTATIONS,
  );

  useEffect(() => {
    if (clearFormVar) {
      setValues(defaultValues);
      clearForm(false);
    }
  }, [clearFormVar]);

  useEffect(() => {
    if (!focus)
      setTimeout(() => {
        setItemsVisibility(true);
      }, 500);
  }, [focus]);

  useEffect(() => {
    if (
      productPresentationsData &&
      productPresentationsData.product.ProductPresentations.length > 0
    )
      setValues(values => ({
        ...values,
        productPresentationId: productPresentationsData.product.ProductPresentations[0].id,
        price: productPresentationsData.product.ProductPresentations[0].price,
      }));
  }, [productPresentationsData]);

  useEffect(() => {
    if (values.productId) {
      setValues(values => ({ ...values, productPresentationId: '' }));
      getProductPresentations({
        variables: { id: values.productId.value, includeBaseInPresentations: true },
      });
    }
  }, [values.productId]);

  useEffect(() => {
    if (productsData && productsData.products) {
      const tmpProducts = productsData.products.rows.map(el => ({
        label: el.name.trim(),
        value: el.id,
      }));
      setProducts(tmpProducts);
    }
  }, [productsData]);

  const handleAdd = () => {
    try {
      setErrors({});
      if (!values.productId) {
        const e = new Error('Selecciona un producto.');
        e.name = 'productId';
        throw e;
      }
      if (values.productPresentationId === '') {
        const e = new Error('Selecciona una unidad.');
        e.name = 'productPresentationId';
        throw e;
      }
      if (Number.isNaN(parseInt(values.quantity)) || parseInt(values.quantity) <= 0) {
        const e = new Error('Proporciona una cantidad.');
        e.name = 'quantity';
        throw e;
      }
      if (
        parseInt(formVar.promotionType) !== 3 &&
        (Number.isNaN(parseInt(values.price)) || parseInt(values.price) < 0)
      ) {
        const e = new Error('Proporciona un precio.');
        e.name = 'price';
        throw e;
      }
      const productPresentationLabel = productPresentationsData.product.ProductPresentations.find(
        el => parseInt(el.id) === parseInt(values.productPresentationId),
      );

      if (
        values.discountPercent &&
        (parseFloat(values.discountPercent) < 0 || parseFloat(values.discountPercent) > 100)
      ) {
        const e = new Error('El porcentaje de descuento debe ser un número entre 0 y 100.');
        e.name = 'discountPercent';
        throw e;
      }

      const price = parseInt(formVar.promotionType) !== 3 ? parseFloat(values.price) : 0;
      item({
        ...values,
        price,
        productPresentationId: {
          value: values.productPresentationId,
          label: productPresentationLabel.name,
        },
      });
      setValues(defaultValues);
    } catch (e) {
      console.log(e.message);
      setErrors({ [e.name]: e.message });
    }
  };

  const handleAutocomplete = (e, name, value) => {
    value === '' || !value ? setFocus(true) : setFocus(false);
    (value === '' || !value) && setItemsVisibility(false);
    setErrors({});
    setValues(values => ({ ...values, [name]: value }));
  };

  const handleChange = e => {
    const { name, value } = e.target;
    setErrors({});
    if (name === 'productPresentationId') {
      const { price } = productPresentationsData.product.ProductPresentations.find(
        tmp => parseInt(tmp.id) === parseInt(value),
      );
      setValues(values => ({ ...values, [name]: value, price }));
      return;
    }
    setValues(values => ({ ...values, [name]: value }));
  };

  const handleAutocompleteFocus = () => {
    !values.productId && setFocus(true);
    !values.productId && setItemsVisibility(false);
  };

  const handleAutocompleteBlur = () => {
    setFocus(false);
  };

  return (
    <div className='my-2'>
      <LinearForm handleAdd={handleAdd}>
        <Grid container spacing={1}>
          <Grid item xs={12} md={focus ? 12 : 4}>
            <Autocomplete
              name='productId'
              label='Producto'
              variant='outlined'
              onFocus={handleAutocompleteFocus}
              onBlur={handleAutocompleteBlur}
              onChange={handleAutocomplete}
              options={products}
              value={values.productId}
              error={errors.productId}
              noFormat
            />
          </Grid>
          <Grid item xs={12} md={focus ? false : !itemsVisibility ? false : 8}>
            {itemsVisibility && (
              <Grid container spacing={1}>
                <Grid item md={4}>
                  <SelectField
                    name='productPresentationId'
                    label='Unidad'
                    variant='outlined'
                    onChange={handleChange}
                    options={
                      productPresentationsData
                        ? productPresentationsData.product.ProductPresentations.map(el => ({
                            value: el.id,
                            label: el.name,
                          }))
                        : []
                    }
                    value={values.productPresentationId}
                    error={errors.productPresentationId}
                    size='small'
                    noFormat
                  />
                </Grid>
                <Grid item md={2}>
                  <SelectField
                    name='typeQuantity'
                    label='Tipo'
                    variant='outlined'
                    onChange={handleChange}
                    value={values.typeQuantity}
                    error={errors.typeQuantity}
                    noFormat
                    size='small'
                    options={[
                      { value: 1, label: 'Se pesa' },
                      { value: 2, label: 'Pieza' },
                    ]}
                  />
                </Grid>
                <Grid item md={2}>
                  <TextField
                    name='quantity'
                    label='Cantidad'
                    type='number'
                    variant='outlined'
                    onChange={handleChange}
                    value={values.quantity}
                    error={errors.quantity}
                    noFormat
                  />
                </Grid>
                {parseInt(formVar.promotionType) !== 3 && (
                  <Grid item md={2}>
                    <TextField
                      name='price'
                      label='Precio'
                      type='number'
                      variant='outlined'
                      onChange={handleChange}
                      value={values.price}
                      error={errors.price}
                      noFormat
                    />
                  </Grid>
                )}
                <Grid item md={2}>
                  <TextField
                    name='discountPercent'
                    label='% de Descuento'
                    type='number'
                    variant='outlined'
                    onChange={handleChange}
                    value={values.discountPercent}
                    error={errors.discountPercent}
                    noFormat
                  />
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
      </LinearForm>
    </div>
  );
};

export default ProductPromotionFormComponent;
