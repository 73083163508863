var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { FormLabel, Paper } from '@mui/material';
import RHFormControlComponent from '../FormControl';
import FieldErrorComponent from '../Error';
import PhoneInputBaseFieldComponent from './Input/InputBase';
import CountryCodeFieldComponent from './Input/CountryCodeField';
var RHFPhoneFieldComponent = function (_a) {
    var name = _a.name, countryCodeName = _a.countryCodeName, props = __rest(_a, ["name", "countryCodeName"]);
    return (React.createElement(RHFormControlComponent, { disabled: props.disabled },
        React.createElement(FormLabel, { htmlFor: name || 'phone', component: 'legend' }, props.label || 'Teléfono'),
        React.createElement(Paper, { className: 'flex px-2 py-[3px] bg-transparent border-[#c5c5c5] items-center', variant: 'outlined' },
            React.createElement(CountryCodeFieldComponent, { name: countryCodeName || 'countryCode' }),
            React.createElement(PhoneInputBaseFieldComponent, { config: props.config, name: name || 'phone' })),
        React.createElement(FieldErrorComponent, { name: name || 'phone' })));
};
export default RHFPhoneFieldComponent;
