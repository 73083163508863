import jsPDF from 'jspdf';
import format from 'date-fns/format';
import locale from 'date-fns/locale/es';

import client from '../../apollo';
import { phoneFormat, currencyFormat } from '../../helpers';
import { paymentFormLabel } from '../../helpers/paymentForm.options';

import config from '../../config/img.json';

import GQL from './_gql';

const titleCategoryOption = value => {
  switch (parseInt(value)) {
    case -1:
      return 'cotización';
    case 1:
      return 'nota_de_venta';
    case 3:
      return 'orden_de_servicio';
    case 4:
      return 'obra_y_remodelación';
    default:
      return 'indefinido';
  }
};

const categoryOptions = value => {
  switch (parseInt(value)) {
    case -1:
      return 'COTIZACIÓN';
    case 1:
      return 'NOTA DE VENTA';
    case 3:
      return 'ORDEN DE SERVICIO';
    case 4:
      return 'OBRAS/REMODELACIÓN';
    default:
      return 'INDEFINIDO';
  }
};

const categoryAbbreviationOptions = value => {
  switch (parseInt(value)) {
    case -1:
      return 'C';
    case 1:
      return 'NV';
    case 3:
      return 'OS';
    case 4:
      return 'OR';
    default:
      return 'INDEFINIDO';
  }
};

const getDataFromServer = async id => {
  try {
    const { data } = await client.mutate({
      mutation: GQL.GET_BY_ID,
      variables: { id },
      fetchPolicy: 'no-cache',
    });
    return data.sale;
  } catch (e) {
    console.log(e);
  }
};

const Pdf = async (id, settings) => {
  // { print = 1, showTransactions = 0 }
  const print = settings && settings.print ? settings.print : 1;
  const showTransactions = settings && settings.showTransactions ? settings.showTransactions : 0;
  const ProductToShow = settings && settings.productsToShow ? settings.productsToShow : null;
  const currentDate = format(new Date(), 'dd/MM/yyyy HH:mm:ss', { locale });

  const data = await getDataFromServer(id);

  const products = data.SaleProducts.sort((a, b) => parseInt(a.id) - parseInt(b.id));

  const folio = `${data.serial}`.padStart(5, '0');
  const zipCode = data.Branch.zipCode ? `${data.Branch.zipCode},` : '';
  const city = data.Branch.city ? `${data.Branch.city},` : '';
  const state = data.Branch.state ? `${data.Branch.state},` : '';
  const createdAt = ProductToShow
    ? format(new Date(), 'dd/MM/yyyy HH:mm', { locale })
    : format(new Date(data.clientCreatedAt), 'dd/MM/yyyy HH:mm', { locale });

  const doc = new jsPDF();

  let breakLine = 20;

  if (parseInt(data.Company.id) === 71)
    doc.addImage(config['logoTurquesa'], 5, 10, 55, 40, 'center');

  doc.setProperties({
    title: `${titleCategoryOption(data.category)}_${folio}`,
  });

  doc.setFontSize(9); //11
  doc.setFont('arial', 'bold');
  //doc.setFont('arial', 'bold');
  doc.text(data.Company.name, 105, breakLine, 'center');

  if (data.Company.name !== data.Branch.name) {
    doc.setFontSize(9);
    breakLine += 4;
    doc.text(data.Branch.name, 105, breakLine, 'center');
  }

  //doc.setFont('arial', 'normal');
  doc.setFont('ARIAL', 'normal');
  doc.setFontSize(10); //10
  breakLine += 4;
  doc.text(
    doc.splitTextToSize(`${data.Branch.addressLine1}, ${data.Branch.addressLine2}`, 130),
    105,
    breakLine,
    'center',
  );
  breakLine += 4;
  doc.text(`CP ${zipCode} ${city} ${state} México`, 105, breakLine, 'center');

  if (data.Branch.phone || data.Branch.altPhone) {
    breakLine += 5;
    doc.text(
      `Tel. ${phoneFormat(data.Branch.phone || data.Branch.altPhone)}`,
      105,
      breakLine,
      'center',
    );
  }
  /*if (data.Branch.phone) {
    breakLine += 5;
    doc.text(`Tel. ${phoneFormat(data.Branch.phone)}`, 105, breakLine, 'center');
  }
  if (data.Branch.altPhone) {
    breakLine += 5;
    doc.text(`Cel. ${phoneFormat(data.Branch.altPhone)}`, 105, breakLine, 'center');
  }*/

  doc.setFontSize(14);
  //doc.setFont('arial', 'bold');
  doc.setFont('arial', 'bold');

  breakLine += 10;
  doc.text(`${categoryOptions(data.category)}`, 105, breakLine, 'center');

  doc.text(`${categoryAbbreviationOptions(data.category)} ${folio}`, 195, 18, 'right');

  doc.setFontSize(10);

  //doc.setFont('times', 'bold');
  doc.setFont('arial', 'bold');
  doc.text('Fecha de Emisión', 195, 24, 'right');
  doc.setFont('ARIAL', 'normal');
  doc.text(createdAt, 195, 28, 'right');

  breakLine = data.Branch.phone || data.Branch.altPhone ? 57 : 53;

  doc.setFontSize(9);
  doc.setFont('arial', 'bold');

  if (parseInt(data.Company.id) === 71) {
    doc.setDrawColor(37, 150, 190);
    doc.setFillColor(37, 150, 190);
  } else {
    doc.setDrawColor(30, 30, 30);
    doc.setFillColor(30, 30, 30);
  }
  doc.rect(10, breakLine - 3, 185, 4, 'FD');
  doc.setTextColor(255, 255, 255);
  doc.text('Datos del Cliente', 12, breakLine);

  breakLine += 1.5;

  if (parseInt(data.Company.id) === 71) {
    doc.setDrawColor(255, 255, 255);
    doc.setFillColor(255, 255, 255);
  } else {
    doc.setDrawColor(244, 244, 244);
    doc.setFillColor(244, 244, 244);
  }
  doc.rect(10, breakLine, 185, 18, 'FD');

  doc.setTextColor(0);
  //doc.setFont('times', 'bold');
  doc.setFont('arial', 'bold');

  doc.text('Nombre', 12, breakLine + 4);
  doc.text('Razón Social', 12, breakLine + 8);
  doc.text('RFC', 12, breakLine + 12);
  doc.text('Teléfono', 12, breakLine + 16);

  //doc.setFont('times', 'normal');
  doc.setFont('ARIAL', 'normal');

  doc.text(`${data.Customer.firstName} ${data.Customer.lastName}`.trim(), 42, breakLine + 4);
  doc.text(
    data.Customer.CustomerBillingInformation &&
      data.Customer.CustomerBillingInformation.businessName
      ? data.Customer.CustomerBillingInformation.businessName
      : '-',
    42,
    breakLine + 8,
  );
  doc.text(
    data.Customer.CustomerBillingInformation && data.Customer.CustomerBillingInformation.identifier
      ? data.Customer.CustomerBillingInformation.identifier
      : '-',
    42,
    breakLine + 12,
  );
  doc.text(data.Customer.phone, 42, breakLine + 16);

  doc.setFontSize(9);
  //doc.setFont('times', 'bold');
  doc.setFont('arial', 'bold');

  breakLine += 24;

  if (parseInt(data.Company.id) === 71) {
    doc.setDrawColor(37, 150, 190);
    doc.setFillColor(37, 150, 190);
  } else {
    doc.setDrawColor(30, 30, 30);
    doc.setFillColor(30, 30, 30);
  }
  doc.rect(10, breakLine - 3, 185, 4, 'FD');
  doc.setTextColor(255, 255, 255);
  doc.text('Conceptos', 105, breakLine, 'center');

  breakLine += 5;

  if (parseInt(data.Company.id) === 71) {
    doc.setDrawColor(37, 150, 190);
    doc.setFillColor(37, 150, 190);
  } else {
    doc.setDrawColor(30, 30, 30);
    doc.setFillColor(30, 30, 30);
  }
  doc.rect(10, breakLine - 3, 185, 4, 'FD');
  doc.setTextColor(255, 255, 255);

  doc.text('Cantidad', 19, breakLine, 'center');
  doc.text('Unidad', 40, breakLine, 'center');
  doc.text('Descripción', 56, breakLine);
  doc.text('Costo Unitario', 140, breakLine, 'right');
  doc.text('Descuento', 167, breakLine, 'right');
  doc.text('Importe', 192, breakLine, 'right');

  //doc.setFont('times', 'normal');
  doc.setFont('ARIAL', 'normal');
  doc.setTextColor(0);

  breakLine += 4;
  products.forEach(el => {
    if (ProductToShow && !ProductToShow.includes(el.id)) return;
    const name = el.Product
      ? `${el.Product.sku} ${el.Product.name}`
      : el.name
      ? el.name
      : el.commentary;
    const unit = el.ProductPresentation ? el.ProductPresentation.name : '-';

    if (parseInt(data.Company.id) === 71) {
      doc.setDrawColor(255, 255, 255);
      doc.setFillColor(255, 255, 255);
    } else {
      doc.setDrawColor(244, 244, 244);
      doc.setFillColor(244, 244, 244);
    }
    doc.rect(10, breakLine - 3, 185, 6, 'FD');

    doc.text(`${currencyFormat(el.quantity)}`, 19, breakLine, 'center');
    doc.text(unit, 40, breakLine, 'center');
    //doc.text(name, 56, breakLine);
    if (name.length < 40) doc.text(name, 56, breakLine);
    if (name.length >= 40 && name.length < 80) {
      doc.text(name.substring(0, 40), 56, breakLine);
      doc.text(name.substring(40, name.length), 56, breakLine + 4);
    }
    if (name.length >= 80 && name.length < 120) {
      doc.text(name.substring(0, 40), 56, breakLine);
      doc.text(name.substring(40, 80), 56, breakLine + 4);
      doc.text(name.substring(80, name.length), 56, breakLine + 8);
    }
    if (name.length >= 120 && name.length < 160) {
      doc.text(name.substring(0, 40), 56, breakLine);
      doc.text(name.substring(40, 80), 56, breakLine + 4);
      doc.text(name.substring(80, 120), 56, breakLine + 8);
      doc.text(name.substring(120, name.length), 56, breakLine + 12);
    }
    if (name.length >= 160 && name.length < 200) {
      doc.text(name.substring(0, 40), 56, breakLine);
      doc.text(name.substring(40, 80), 56, breakLine + 4);
      doc.text(name.substring(80, 120), 56, breakLine + 8);
      doc.text(name.substring(120, 160), 56, breakLine + 12);
      doc.text(name.substring(160, name.length), 56, breakLine + 16);
    }
    doc.text(currencyFormat(el.unitPrice), 140, breakLine, 'right');
    doc.text(currencyFormat(el.discount), 167, breakLine, 'right');
    doc.text(currencyFormat(el.total), 192, breakLine, 'right');
    if (el.commentary) {
      if (name.length < 40) breakLine += 3;
      if (name.length >= 40 && name.length < 80) breakLine += 7;
      if (name.length >= 80 && name.length < 120) breakLine += 11;
      if (name.length >= 120 && name.length < 160) breakLine += 15;
      if (name.length >= 160 && name.length < 200) breakLine += 19;

      const commentary = el.commentary.replace(/\n|\r/g, ' ');
      if (commentary.length < 40) doc.text(commentary, 56, breakLine);
      if (commentary.length >= 40 && commentary.length < 80) {
        doc.text(commentary.substring(0, 40), 56, breakLine);
        doc.text(commentary.substring(40, commentary.length), 56, breakLine + 4);
      }
      if (commentary.length >= 80 && commentary.length < 120) {
        doc.text(commentary.substring(0, 40), 56, breakLine);
        doc.text(commentary.substring(40, 80), 56, breakLine + 4);
        doc.text(commentary.substring(80, commentary.length), 56, breakLine + 8);
      }
      if (commentary.length >= 120 && commentary.length < 160) {
        doc.text(commentary.substring(0, 40), 56, breakLine);
        doc.text(commentary.substring(40, 80), 56, breakLine + 4);
        doc.text(commentary.substring(80, 120), 56, breakLine + 8);
        doc.text(commentary.substring(120, commentary.length), 56, breakLine + 12);
      }
      if (commentary.length >= 160 && commentary.length < 200) {
        doc.text(commentary.substring(0, 40), 56, breakLine);
        doc.text(commentary.substring(40, 80), 56, breakLine + 4);
        doc.text(commentary.substring(80, 120), 56, breakLine + 8);
        doc.text(commentary.substring(120, 160), 56, breakLine + 12);
        doc.text(commentary.substring(160, commentary.length), 56, breakLine + 16);
      }

      if (commentary.length < 40) breakLine += 2;
      if (commentary.length >= 40 && commentary.length < 80) breakLine += 4;
      if (commentary.length >= 80 && commentary.length < 120) breakLine += 6;
      if (commentary.length >= 120 && commentary.length < 160) breakLine += 8;
      if (commentary.length >= 160 && commentary.length < 200) breakLine += 10;
    }

    if (name.length < 40) breakLine += 6;
    if (name.length >= 40 && name.length < 80) breakLine += 10;
    if (name.length >= 80 && name.length < 120) breakLine += 14;
    if (name.length >= 120 && name.length < 160) breakLine += 18;
    if (name.length >= 160 && name.length < 200) breakLine += 22;
    //breakLine += 6;
  });

  if (!ProductToShow) {
    if (data.Transactions.length > 0 && showTransactions) {
      doc.setFont('arial', 'bold');
      if (parseInt(data.Company.id) === 71) {
        doc.setDrawColor(37, 150, 190);
        doc.setFillColor(37, 150, 190);
      } else {
        doc.setDrawColor(30, 30, 30);
        doc.setFillColor(30, 30, 30);
      }
      doc.rect(10, breakLine - 3, 185, 4, 'FD');
      doc.setTextColor(255, 255, 255);
      doc.text('Abonos', 105, breakLine, 'center');

      breakLine += 5;

      if (parseInt(data.Company.id) === 71) {
        doc.setDrawColor(37, 150, 190);
        doc.setFillColor(37, 150, 190);
      } else {
        doc.setDrawColor(30, 30, 30);
        doc.setFillColor(30, 30, 30);
      }
      doc.rect(10, breakLine - 3, 185, 4, 'FD');
      doc.setTextColor(255, 255, 255);

      doc.text('Fecha de pago', 30, breakLine, 'center');
      doc.text('Forma de pago', 130, breakLine, 'center');
      doc.text('Total ', 192, breakLine, 'right');

      doc.setFont('ARIAL', 'normal');
      doc.setTextColor(0);

      breakLine += 4;

      data.Transactions.forEach(el => {
        const clientCreatedAt = format(new Date(el.clientCreatedAt), 'dd/MM/yyyy', { locale });
        const paymentMethod = el.paymentMethodId ? el.PaymentMethod.name : 'EFECTIVO';

        if (parseInt(data.Company.id) === 71) {
          doc.setDrawColor(255, 255, 255);
          doc.setFillColor(255, 255, 255);
        } else {
          doc.setDrawColor(244, 244, 244);
          doc.setFillColor(244, 244, 244);
        }
        doc.rect(10, breakLine - 3, 185, 6, 'FD');

        doc.text(clientCreatedAt, 30, breakLine, 'center');
        doc.text(paymentMethod, 130, breakLine, 'center');
        doc.text(`${currencyFormat(el.total)}`, 192, breakLine, 'right');

        breakLine += 6;
      });
    }

    //doc.setFont('times', 'normal');
    doc.setFont('ARIAL', 'normal');
    doc.setFontSize(10);
    if (parseInt(data.Company.id) === 71) {
      doc.setDrawColor(37, 150, 190);
      doc.setFillColor(37, 150, 190);
    } else {
      doc.setDrawColor(30, 30, 30);
      doc.setFillColor(30, 30, 30);
    }
    doc.rect(10, breakLine, 125, 4, 'FD');
    doc.rect(138, breakLine, 32, showTransactions ? 26 : 18, 'FD');

    if (parseInt(data.Company.id) === 71) {
      doc.setDrawColor(255, 255, 255);
      doc.setFillColor(255, 255, 255);
    } else {
      doc.setDrawColor(244, 244, 244);
      doc.setFillColor(244, 244, 244);
    }
    doc.rect(171, breakLine, 24, 18, 'FD');

    doc.setTextColor(255, 255, 255);

    //doc.setFont('times', 'bold');
    doc.setFont('arial', 'bold');

    doc.text('Comentarios', 12, breakLine + 3);

    doc.setFont('times', 'normal');
    doc.text('Subtotal', 168, breakLine + 4, 'right');
    doc.text('Descuento', 168, breakLine + 8, 'right');
    doc.text('Impuestos', 168, breakLine + 12, 'right');

    //doc.setFont('times', 'bold');
    doc.setFont('arial', 'bold');
    doc.text('Total', 168, breakLine + 17, 'right');
    if (showTransactions) {
      doc.text('Abonado', 168, breakLine + 21, 'right');
      doc.text('Saldo', 168, breakLine + 25, 'right');
    }

    doc.setTextColor(0);
    //doc.setFont('times', 'normal');
    doc.setFont('ARIAL', 'normal');
    doc.text(currencyFormat(data.total), 193, breakLine + 4, 'right');
    doc.text(currencyFormat(data.discount), 193, breakLine + 8, 'right');
    doc.text(currencyFormat(0), 193, breakLine + 12, 'right');

    //doc.setFont('times', 'bold');
    doc.setFont('arial', 'bold');
    doc.text(currencyFormat(data.total), 193, breakLine + 17, 'right');
    if (showTransactions) {
      doc.text(
        currencyFormat(data.AccountReceivable ? data.AccountReceivable.payment : 0),
        193,
        breakLine + 21,
        'right',
      );
      doc.text(
        currencyFormat(data.AccountReceivable.balance ? data.AccountReceivable.balance : 0),
        193,
        breakLine + 25,
        'right',
      );
    }

    const receptionText = data.commentary ? data.commentary : '';

    if (parseInt(data.Company.id) === 71) {
      doc.setDrawColor(255, 255, 255);
      doc.setFillColor(255, 255, 255);
    } else {
      doc.setDrawColor(244, 244, 244);
      doc.setFillColor(244, 244, 244);
    }
    doc.rect(10, breakLine + 4, 125, 14, 'FD');

    //doc.setFont('times', 'normal');
    doc.setFont('ARIAL', 'normal');
    doc.setTextColor(0);
    doc.text(doc.splitTextToSize(receptionText, 134), 12, breakLine + 8);
  }
  //doc.setFont('times', 'bold');
  doc.setFont('arial', 'bold');
  doc.text('Forma de Pago:', 12, breakLine + 24);
  doc.text('Método de Pago:', 12, breakLine + 29);
  doc.text('Emitido por:', 12, breakLine + 34);

  const paymentMethods = data.Transactions.reduce((str, el) => {
    const code = el.PaymentMethod ? el.PaymentMethod.code : '01';
    const strFix = str ? `${str}, ${paymentFormLabel(code)}` : paymentFormLabel(code);
    return strFix;
  }, '');

  //doc.setFont('times', 'normal');
  doc.setFont('ARIAL', 'normal');
  doc.text(paymentMethods, 42, breakLine + 24);
  doc.text('Contado', 42, breakLine + 29);
  doc.text(
    data.Seller ? `${data.Seller.firstName} ${data.Seller.lastName}` : 'Venta de Mostrador',
    42,
    breakLine + 34,
  );

  if (parseInt(data.Company.id) === 71 && parseInt(data.category) === 1) {
    //doc.setFont('times', 'bold');
    doc.setFont('arial', 'bold');
    doc.text('DATOS BANCARIOS', 100, breakLine + 24);
    doc.text('BANCO:', 100, breakLine + 28);
    doc.text('CUENTA:', 100, breakLine + 32);
    doc.text('CLABE:', 100, breakLine + 36);
    doc.text('TITULAR:', 100, breakLine + 40);

    //doc.setFont('times', 'normal');
    doc.setFont('ARIAL', 'normal');
    doc.text('BANORTE', 115, breakLine + 28);
    doc.text('0461 0864 94', 117, breakLine + 32);
    doc.text('0727 4400 4610 8649 42', 115, breakLine + 36);
    doc.text('JORGE ALBERTO MORENO PERAZA', 118, breakLine + 40);
  }

  if ([3, 4].includes(parseInt(data.category))) {
    doc.setFont('ARIAL', 'normal');
    doc.text(
      'Me comprometo a hacer buen uso del material adquirido y a devolverlo parcial o totalmente en caso de no utilizarlo',
      12,
      breakLine + (ProductToShow ? 4 : 50),
    );
    doc.text(
      'en el servicio requerido, de no ser así seré acreedor de una penalización.',
      12,
      breakLine + (ProductToShow ? 8 : 54),
    );
    doc.text('______________', 38, breakLine + 65);
    doc.text('Autorizó', 45, breakLine + 70);
    doc.text('______________', 122, breakLine + 65);
    doc.text('Responsable', 126, breakLine + 70);
  }

  doc.setDrawColor(0);
  doc.setFillColor(0);
  const fontSize = parseInt(data.Company.id) === 71 ? 8 : 10;
  doc.setFontSize(fontSize);
  const finishBreackLine = parseInt(data.Company.id) === 71 ? 275 : 280;
  doc.text(
    `Este documento no tiene validez fiscal. Fecha de Impresión: ${currentDate}.`,
    10,
    finishBreackLine,
  );
  if (parseInt(data.Company.id) === 71) {
    doc.setTextColor(37, 150, 190);
    doc.setFontSize(20);
    doc.setFont('ARIAL', 'normal');
    doc.text('www.albercasturquesa.mx', 70, 288);
  }

  if (print === 1) window.open(doc.output('bloburl'), '_blank');
  // doc.save(`traspaso_${folio}_${data.Branch.name}_a_${data.BranchTransfer.name}.pdf`);
  if (print === 2) {
    const binary = doc.output();
    return binary ? btoa(binary) : false;
  }
};

export default Pdf;
