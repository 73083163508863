var getDefaultIngredientProductForm = function () { return ({
    id: undefined,
    ingredientId: null,
    type: '',
    portion: '',
    portionQty: '',
    extra: '',
    portionPrice: '',
    freePortionQty: '',
    didiPrice: '',
    rappiPrice: '',
    uberPrice: '',
    toGo: false,
}); };
export default getDefaultIngredientProductForm;
