import React, { useEffect } from 'react';

import { useReactiveVar } from '@apollo/client';

import Collapse from '@mui/material/Collapse';

import { actionForm, step, steps, handleContinueConfiguration } from '../../../apollo/cache';

import FormDialog from '../../../presentationals/FormDialog';
import Stepper from '../../../presentationals/FormComponents/Stepper';
import FooterAction from '../../../presentationals/FormComponents/FooterAction';

import Main from './Main';
import Products from './Products';

const CreateFormComponent = () => {
  const stepVar = useReactiveVar(step);
  const actionFormVar = useReactiveVar(actionForm);

  useEffect(() => {
    steps(['Datos Generales', 'Productos']);
  }, []);

  const handleClose = () => {
    actionForm({ ...actionForm(), isOpen: false, action: '' });
    handleContinueConfiguration({});
    step(0);
  };

  return (
    <FormDialog
      isOpen={actionFormVar.isOpen && actionFormVar.action === 'create'}
      handleClose={handleClose}
      title='Orden de Compra'
      noActions
      fullScreen
      noPadding
    >
      <Stepper />
      <div className='py-1 px-3'>
        <Collapse in={stepVar === 0}>
          <Main />
        </Collapse>
        <Collapse in={stepVar === 1}>
          <Products />
        </Collapse>
      </div>
      <FooterAction />
    </FormDialog>
  );
};

export default CreateFormComponent;
