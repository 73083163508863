import React, { useState } from 'react';
import MoreVertIcon from '@mui/icons-material/MoreVertOutlined';
import IconButtonComponent from '../../../IconButton';
import Popover from './Popover';
var MenuHeaderModuleV3Component = function (props) {
    var _a = useState(null), anchorEl = _a[0], setAnchorEl = _a[1];
    var open = Boolean(anchorEl);
    var id = open ? 'simple-popover' : undefined;
    var handleClick = function (event) {
        setAnchorEl(event.currentTarget);
    };
    var handleClose = function () {
        setAnchorEl(null);
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(IconButtonComponent, { action: handleClick, icon: React.createElement(MoreVertIcon, null), size: 'large', label: 'M\u00E1s opciones', disabled: props.isLoading }),
        React.createElement(Popover, { open: !!anchorEl, id: id, onClose: handleClose, anchorEl: anchorEl })));
};
export default MenuHeaderModuleV3Component;
