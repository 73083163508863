import getSession from '../../../helpers/getSession';
var getProductTypes = function () {
    var _a;
    var session = getSession();
    return [
        { label: 'Producto para venta directa', value: 1 },
        { label: 'Producto elaborado', value: 2 },
        { label: 'Insumo / materia prima', value: 5 },
        { label: 'Servicio', value: 6 },
        { label: 'Paquete', value: ((_a = session === null || session === void 0 ? void 0 : session.Company) === null || _a === void 0 ? void 0 : _a.id) === 25 ? 4 : 7 },
    ];
};
export default getProductTypes;
