import React from 'react';
import { ListItemButton } from '@mui/material';
import getInputErrorFormat from '../../../../../../../../containers/sale.v2/Action/helpers/getInputErrorFormat';
import { isProductDialogOpen } from '../../../../../../../../apollo/cache';
var ListItemButtonTableDialogProductFieldComponent = function (props) {
    var handleClick = function () {
        var _a;
        try {
            var values = isProductDialogOpen();
            isProductDialogOpen([
                false,
                values[1],
                values[2],
                { value: props.Product.id, label: props.Product.name, id: (_a = values[3]) === null || _a === void 0 ? void 0 : _a.id },
                values[4] || undefined,
                values[5] || undefined,
            ]);
        }
        catch (e) {
            getInputErrorFormat(e);
        }
    };
    return (React.createElement(ListItemButton, { onClick: handleClick, className: "".concat(props.background, " p-0") }, props.children));
};
export default ListItemButtonTableDialogProductFieldComponent;
