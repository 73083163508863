import React from 'react';
import RentComponent from '../containers/rent';
import GeneralRentComponent from '../containers/rent/Item/General';
import ItemRentLayoutComponent from './RentLayout/Item';
import DeliveryRentComponent from '../containers/rent/Item/Delivery';
import PickupRentComponent from '../containers/rent/Item/Pickup';
import ProductsRentComponent from '../containers/rent/Item/Products';
import CloseRentComponent from '../containers/rent/Item/Close';
import RentLayoutComponent from './RentLayout';
var getRentChildrenRoutes = function () {
    return [
        {
            element: React.createElement(RentLayoutComponent, null),
            children: [
                { index: true, element: React.createElement(RentComponent, { status: 0 }) },
                { path: 'pickup', element: React.createElement(RentComponent, { status: 4 }) },
                { path: 'close', element: React.createElement(RentComponent, { status: 5 }) },
                { path: 'finished', element: React.createElement(RentComponent, { status: 6 }) },
            ],
        },
        {
            path: ':saleId',
            element: React.createElement(ItemRentLayoutComponent, null),
            children: [
                { index: true, element: React.createElement(GeneralRentComponent, null) },
                { path: 'delivery', element: React.createElement(DeliveryRentComponent, null) },
                { path: 'pickup', element: React.createElement(PickupRentComponent, null) },
                { path: 'products', element: React.createElement(ProductsRentComponent, null) },
                { path: 'close', element: React.createElement(CloseRentComponent, null) },
            ],
        },
    ];
};
export default getRentChildrenRoutes;
