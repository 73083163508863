var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import { useReactiveVar } from '@apollo/client';
import SubtitleDialogComponent from '../../../../../../presentationals/Dialog/helpers/Subtitle';
import TextFieldComponent from '../../../../../../presentationals/FormComponents/TextField';
import { inputError, productPresentationForm } from '../../../../../../apollo/cache';
import QuantityPresentationFieldComponent from './QuantityField';
import CheckboxComponent from '../../../../../../presentationals/FormComponents/Checkbox';
import LimitPresentationFieldComponent from './LimitField';
import useGetSession from '../../../../../../hooks/useGetSession';
import SwitchComponent from '../../../../../../presentationals/FormComponents/Switch';
import PrinterPresentationFieldComponent from './PrinterField';
var BodyCreateProductPresentationDialogComponent = function () {
    var _a, _b;
    var session = useGetSession();
    var errors = useReactiveVar(inputError);
    var values = useReactiveVar(productPresentationForm);
    var handleChange = function (name, value) {
        var _a;
        productPresentationForm(__assign(__assign({}, values), (_a = {}, _a[name] = value, _a)));
    };
    var handleSwitchChange = function (name, value) {
        var _a;
        productPresentationForm(__assign(__assign({}, values), (_a = {}, _a[name] = value, _a)));
    };
    return (React.createElement("div", { className: 'flex-1 px-6 py-4 flex flex-col md:grid md:grid-cols-4 md:gap-4 overflow-auto' },
        React.createElement("div", null,
            React.createElement(SubtitleDialogComponent, { label: 'Datos generales' }),
            React.createElement(TextFieldComponent, { name: 'name', label: 'Nombre', value: values.name, error: errors.name, onChange: handleChange }),
            React.createElement(QuantityPresentationFieldComponent, { value: values.quantityInBaseUnit, onChange: handleChange }),
            React.createElement(LimitPresentationFieldComponent, { value: values.limit, onChange: handleChange }),
            React.createElement(PrinterPresentationFieldComponent, { value: values.printer, onChange: handleChange }),
            React.createElement(SubtitleDialogComponent, { label: 'Datos comerciales', className: 'mt-3' }),
            React.createElement(CheckboxComponent, { label: 'La presentaci\u00F3n estar\u00E1 disponible para:', onChange: handleChange, row: true, error: errors.forSale, values: [
                    { name: 'forSale', label: 'Venta', checked: values.forSale },
                    { name: 'forPursache', label: 'Compra', checked: values.forPursache },
                    {
                        name: 'forRequisition',
                        label: 'Requisición de mercancía',
                        checked: values.forRequisition,
                    },
                ] }),
            values.forSale && (React.createElement(TextFieldComponent, { name: 'price', label: 'Precio de venta est\u00E1ndar', value: values.price, error: errors.price, onChange: handleChange, type: 'number' })),
            values.forSale && (session === null || session === void 0 ? void 0 : session.Company) && [2, 4].includes((_a = session === null || session === void 0 ? void 0 : session.Company) === null || _a === void 0 ? void 0 : _a.activity) && (React.createElement(SwitchComponent, { name: 'isDifferentPrices', label: 'Los precios de plataformas son diferentes al precio est\u00E1ndar', defaultValue: values.isDifferentPrices, onChange: handleSwitchChange, labelPlacement: 'end', className: 'mt-2 mb-3' }))),
        values.forSale &&
            (session === null || session === void 0 ? void 0 : session.Company) &&
            [2, 4].includes((_b = session === null || session === void 0 ? void 0 : session.Company) === null || _b === void 0 ? void 0 : _b.activity) &&
            values.isDifferentPrices && (React.createElement("div", null,
            React.createElement(SubtitleDialogComponent, { label: 'Precios en diferentes plataformas' }),
            React.createElement(TextFieldComponent, { name: 'pickupPrice', label: 'Precio de venta en ordene y recoja', value: values.pickupPrice, error: errors.pickupPrice, disabled: !values.isDifferentPrices, onChange: handleChange, type: 'number' }),
            React.createElement(TextFieldComponent, { name: 'shippingPrice', label: 'Precio de venta a domicilio', value: values.shippingPrice, error: errors.shippingPrice, disabled: !values.isDifferentPrices, onChange: handleChange, type: 'number' }),
            React.createElement(TextFieldComponent, { name: 'didiPrice', label: 'Precio de venta en didi', value: values.didiPrice, error: errors.didiPrice, disabled: !values.isDifferentPrices, onChange: handleChange, type: 'number' }),
            React.createElement(TextFieldComponent, { name: 'rappiPrice', label: 'Precio de venta en rappi', value: values.rappiPrice, error: errors.rappiPrice, disabled: !values.isDifferentPrices, onChange: handleChange, type: 'number' }),
            React.createElement(TextFieldComponent, { name: 'uberPrice', label: 'Precio de venta en uber', value: values.uberPrice, error: errors.uberPrice, disabled: !values.isDifferentPrices, onChange: handleChange, type: 'number' })))));
};
export default BodyCreateProductPresentationDialogComponent;
