var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useEffect, useState } from 'react';
import { TableCell, TableRow } from '@mui/material';
import DeleteProductAdjustInventoryButtonComponent from '../DeleteButton';
import useApolloLazyQuery from '../../../../../../hooks/useApolloLazyQuery';
import GQL_PRODUCT from '../../../../../../apollo/gql/product';
import convertToCurrencyLabel from '../../../../../../helpers/convertToCurrencyLabel';
import addProductToCache from '../../../helpers/addProductToCache';
import getDiffQty from './helpers/getDiffQty';
var TableRowAdjustInventoryProductComponent = function (props) {
    var _a = useState(0), diffQty = _a[0], setDiffQty = _a[1];
    var _b = useState(0), totalCost = _b[0], setTotalCost = _b[1];
    var _c = useApolloLazyQuery(GQL_PRODUCT.GET_PRODUCT_BY_BARCODE), getData = _c[0], _d = _c[1], loading = _d.loading, data = _d.data, error = _d.error;
    useEffect(function () {
        if (error)
            props.onError(props.index);
    }, [error]);
    useEffect(function () {
        var variables = props.barcode
            ? { barcode: props.barcode }
            : props.productPresentationId
                ? { productPresentationId: props.productPresentationId }
                : null;
        if (!variables)
            return;
        getData({
            variables: __assign(__assign({}, variables), { branchId: props.branchId }),
        });
    }, [props.branchId]);
    useEffect(function () {
        var _a;
        if (data && props.newInvQty) {
            addProductToCache(data.ProductPresentation.id, data.barcode, props.newInvQty);
            var cost = ((_a = data === null || data === void 0 ? void 0 : data.ProductPresentation) === null || _a === void 0 ? void 0 : _a.cost) ? data.ProductPresentation.cost : 0;
            var currentStockInv = data.Inventory ? data.Inventory.quantity : 0;
            var diffQty_1 = getDiffQty(props.newInvQty, currentStockInv, props.adjustType);
            setDiffQty(diffQty_1);
            setTotalCost(cost * diffQty_1);
        }
    }, [data, props.newInvQty]);
    var handleClick = function () {
        props.history && props.history.length > 1 && props.onClick(props.index, props.history);
    };
    return (React.createElement(TableRow, { hover: true, onClick: handleClick },
        React.createElement(TableCell, { padding: 'none' },
            React.createElement(DeleteProductAdjustInventoryButtonComponent, { loading: loading, onClick: props.onDelete, index: props.index, productPresentationId: (data === null || data === void 0 ? void 0 : data.ProductPresentation) ? data.ProductPresentation.id : null })),
        React.createElement(TableCell, { className: 'whitespace-nowrap' },
            React.createElement("div", null, data ? data.name : ''),
            React.createElement("div", { className: 'text-xs text-slate-600' }, data ? "".concat(data.sku, " ").concat(data.barcode).trim() : '')),
        React.createElement(TableCell, { className: 'whitespace-nowrap' }, (data === null || data === void 0 ? void 0 : data.ProductPresentation) ? data.ProductPresentation.name : ''),
        React.createElement(TableCell, { align: 'center' }, props.movementQty || 0),
        React.createElement(TableCell, { align: 'center' }, (data === null || data === void 0 ? void 0 : data.Inventory) ? data.Inventory.quantity : 0),
        React.createElement(TableCell, { align: 'center' }, props.newInvQty),
        React.createElement(TableCell, { align: 'center' }, diffQty),
        React.createElement(TableCell, { align: 'right' }, convertToCurrencyLabel(totalCost))));
};
export default TableRowAdjustInventoryProductComponent;
