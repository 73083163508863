import React from 'react';

import { styled } from '@mui/system';

const RootComponent = styled('div')(({ theme, margin }) => ({
  fontWeight: '700',
  fontSize: 18,
  borderBottom: '1px solid #d6d6d6',
  paddingBottom: theme.spacing(0.5),
  marginBottom: theme.spacing(1),
  marginTop: margin && theme.spacing(parseInt(margin)),
  display: 'flex',
  flex: 1,
}));

const TitleHelperpsBodyCreateFormCustomerActionSaleV2Component = props => {
  return <RootComponent margin={props.margin}>{props.label}</RootComponent>;
};

export default TitleHelperpsBodyCreateFormCustomerActionSaleV2Component;
