import CREATE_ADDRESS_LINE_2 from './createAddressLine2';
import DELETE_ADDRESS_LINE_2 from './deleteAddressLine';
import EDIT_ADDRESS_LINE_2 from './editAddressLine2';
import FRAGMENT_ADDRESS_LINE_2 from './fragmentAddressLine2';
import GET_ADDRESS_LINE_2_BY_ID from './getAddressLine2ById';
import GET_ADDRESSES_LINE_2 from './getAddressesLine2';
import GET_BRANCH_CONFIG_BY_BRANCH_ID from './getBranchConfigByBranchId';
import GET_BRANCHES from './getBranches';
import GET_BRANCH_BY_ID from './getById';
import UPDATE_BRANCH_CONFIG_BY_BRANCH_ID from './updateBranchConfigByBranchId';
var GQL_BRANCH = {
    CREATE_ADDRESS_LINE_2: CREATE_ADDRESS_LINE_2,
    DELETE_ADDRESS_LINE_2: DELETE_ADDRESS_LINE_2,
    EDIT_ADDRESS_LINE_2: EDIT_ADDRESS_LINE_2,
    GET_ADDRESS_LINE_2_BY_ID: GET_ADDRESS_LINE_2_BY_ID,
    GET_ADDRESSES_LINE_2: GET_ADDRESSES_LINE_2,
    GET_BRANCH_CONFIG_BY_BRANCH_ID: GET_BRANCH_CONFIG_BY_BRANCH_ID,
    GET_BRANCHES: GET_BRANCHES,
    GET_BRANCH_BY_ID: GET_BRANCH_BY_ID,
    FRAGMENT_ADDRESS_LINE_2: FRAGMENT_ADDRESS_LINE_2,
    UPDATE_BRANCH_CONFIG_BY_BRANCH_ID: UPDATE_BRANCH_CONFIG_BY_BRANCH_ID,
};
export default GQL_BRANCH;
