import React from 'react';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import { currencyFormat } from '../../../../../../../helpers';
import DeleteButton from './DeleteButton';
import EditButton from './EditButton';
var ItemBodyTableComponent = function (props) {
    var _a, _b;
    return (React.createElement(TableRow, null,
        React.createElement(TableCell, null,
            React.createElement("div", { className: 'flex' },
                !props.noEdit && props.id && (React.createElement(EditButton, { quantity: props.quantity, discount: props.discount, price: props.price, index: props.index, purchaseOrderDetailId: props.id })),
                React.createElement(DeleteButton, { index: props.index }))),
        React.createElement(TableCell, null,
            React.createElement("div", null, props.product
                ? props.product
                : ((_a = props === null || props === void 0 ? void 0 : props.productId) === null || _a === void 0 ? void 0 : _a.name)
                    ? props.productId.name.trim()
                    : props.concept
                        ? props.concept.trim()
                        : ''),
            props.serialNumber && (React.createElement("div", { className: 'text-sm text-gray-400' }, "N\u00FAmero de serie: ".concat(props.serialNumber)))),
        React.createElement(TableCell, null, ((_b = props === null || props === void 0 ? void 0 : props.productPresentationId) === null || _b === void 0 ? void 0 : _b.label)
            ? props.productPresentationId.label
            : props.productPresentation),
        React.createElement(TableCell, { className: 'text-center' }, currencyFormat(props.quantity)),
        React.createElement(TableCell, { className: 'text-right' }, currencyFormat(props.discount)),
        React.createElement(TableCell, { className: 'text-right' }, currencyFormat(props.price)),
        React.createElement(TableCell, { className: 'text-right' }, "$".concat(currencyFormat(props.total)))));
};
export default ItemBodyTableComponent;
