import React, { useEffect, useState } from 'react';

import { useQuery } from '@apollo/client';

import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';

import FilterDrawer from '../../presentationals/FilterDrawer.v2';
import Checkbox from '../../presentationals/Checkbox';

import { filter } from '../../apollo/cache';

import GQL_BRANCHES from '../branch/_gql';
import useGetSession from '../../hooks/useGetSession';

const FilterDialog = () => {
  const session = useGetSession();

  const [valuesBranches, setValuesBranches] = useState({});
  const [branches, setBranches] = useState([]);
  const [valuesStatus, setValuesStatus] = useState({ '-1': true, 1: true, 2: true, 3: true });

  const { data: dataBranches } = useQuery(GQL_BRANCHES.GET, { variables: { limit: 0 } });

  useEffect(() => {
    if (dataBranches && session.Company) {
      const tmp = dataBranches.branches.rows.reduce(
        (object, el) => ({ ...object, [el.id]: true }),
        {},
      );
      setValuesBranches({ ...tmp, '-1': true });
      setBranches([
        { id: '-1', name: 'Seleccionar Todos', Company: { id: session?.Company.id } },
        ...dataBranches.branches.rows,
      ]);
      // filter({ ...filter(), branchId: Object.keys(tmp).filter(key => !!tmp[key]) });
    }
  }, [dataBranches]);

  const handleAction = (e, data) => {
    const { startDate: start, endDate: end, branchId } = data;
    filter({
      branchId,
      start,
      end,
      branchTransferId: Object.keys(valuesBranches).filter(
        key => !!valuesBranches[key] && key !== '-1',
      ),
      status: Object.keys(valuesStatus)
        .filter(key => !!valuesStatus[key] && key !== '-1')
        .map(el => parseInt(el)),
    });
  };

  const handleCheckBoxBranches = (name, checked) => {
    if (name === '-1') {
      const tmp = Object.keys(valuesBranches).reduce((object, key) => {
        return { ...object, [key]: checked };
      }, valuesBranches);
      setValuesBranches(tmp);
      return;
    }
    const numberOfTrue = Object.keys(valuesBranches).filter(key => !!valuesBranches[key]);
    if ((numberOfTrue.length === 1 || Object.keys(valuesBranches).length === 2) && !checked) return;
    let number = numberOfTrue.length;
    if (checked) number = number + 2;
    else number = number - 1;
    if (number === Object.keys(valuesBranches).length)
      setValuesBranches(values => ({ ...values, [name]: checked, '-1': true }));
    else setValuesBranches(values => ({ ...values, [name]: checked, '-1': false }));
  };

  const handleCheckBoxStatus = (name, checked) => {
    if (name === '-1') {
      const tmp = Object.keys(valuesStatus).reduce((object, key) => {
        return { ...object, [key]: checked };
      }, valuesStatus);
      setValuesStatus(tmp);
      return;
    }
    const numberOfTrue = Object.keys(valuesStatus).filter(key => !!valuesStatus[key]);
    if ((numberOfTrue.length === 1 || Object.keys(valuesStatus).length === 2) && !checked) return;
    let number = numberOfTrue.length;
    if (checked) number = number + 2;
    else number = number - 1;
    if (number === Object.keys(valuesStatus).length)
      setValuesStatus(values => ({ ...values, [name]: checked, '-1': true }));
    else setValuesStatus(values => ({ ...values, [name]: checked, '-1': false }));
  };

  return (
    <FilterDrawer handleAction={handleAction} showDates showBranches>
      <Typography variant='subtitle1'>Sucursal Destino:</Typography>
      <Checkbox
        handleChange={handleCheckBoxBranches}
        noFormat
        values={branches.map(el => ({
          name: el.id,
          label: el.name,
          checked: !!valuesBranches[el.id] && valuesBranches[el.id],
          color: el.color,
        }))}
      />
      <Divider />
      <Typography variant='subtitle1'>Estatus:</Typography>
      <Checkbox
        handleChange={handleCheckBoxStatus}
        noFormat
        values={[
          {
            name: '-1',
            label: 'Seleccionar Todos',
            checked: valuesStatus[-1],
          },
          {
            name: '1',
            label: 'Por Autorizar',
            checked: valuesStatus[1],
          },
          {
            name: '2',
            label: 'Autorizada',
            checked: valuesStatus[2],
          },
          {
            name: '3',
            label: 'Recibido',
            checked: valuesStatus[3],
          },
        ]}
      />
    </FilterDrawer>
  );
};

export default FilterDialog;
