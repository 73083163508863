import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { Checkbox, TableCell, TableRow } from '@mui/material';
import { Link } from 'react-router-dom';
import { dateTimeFormat } from '../../../../../../helpers';
import convertToCurrencyLabel from '../../../../../../helpers/convertToCurrencyLabel';
import CONFIG from '../../../../../../config/config.json';
var ItemInvoiceComplementComponent = forwardRef(function (props, ref) {
    var _a, _b, _c;
    var _d = useState(false), checked = _d[0], setChecked = _d[1];
    var handleCheckAllChange = function (checked) {
        setChecked(checked);
    };
    var handleChange = function (event) {
        setChecked(event.target.checked);
        props.onChange(props.id, event.target.checked);
    };
    var getChecked = function () { return checked; };
    useImperativeHandle(ref, function () { return ({
        handleCheckAllChange: handleCheckAllChange,
        getChecked: getChecked,
    }); });
    return (React.createElement(TableRow, null,
        React.createElement(TableCell, { padding: 'checkbox' },
            React.createElement(Checkbox, { checked: checked, onChange: handleChange, size: 'small' })),
        React.createElement(TableCell, null, dateTimeFormat(props.createdAt)),
        React.createElement(TableCell, null, dateTimeFormat(props.clientCreatedAt, 'dd/MM/yyyy')),
        React.createElement(TableCell, null, ((_a = props.Sale.Invoice) === null || _a === void 0 ? void 0 : _a.uuid) && (React.createElement(Link, { target: '_blank', to: "".concat(CONFIG['restServer'].uri, "/files/invoices/").concat(props.Sale.Invoice.uuid, "/pdf") }, "".concat((_b = props.Sale.Invoice) === null || _b === void 0 ? void 0 : _b.folio, " ").concat((_c = props.Sale.Invoice) === null || _c === void 0 ? void 0 : _c.serial)))),
        React.createElement(TableCell, { className: 'whitespace-nowrap' }, props.PaymentMethod ? props.PaymentMethod.name : 'Efectivo'),
        React.createElement(TableCell, { align: 'right' }, convertToCurrencyLabel(props.total))));
});
export default ItemInvoiceComplementComponent;
