import React from 'react';

import Typography from '@mui/material/Typography';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import Tooltip from '@mui/material/Tooltip';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';

const RadioComponent = ({ error, label, ...props }) => {
  const handleChange = e => {
    props.onChange(e.target.name, e.target.value, props.params);
  };
  return (
    <div className='mt-1'>
      <Typography variant='subtitle1'>{label}</Typography>
      <FormControl error={!!error} component='fieldset'>
        <RadioGroup
          row
          aria-label={props.name}
          name={props.name}
          onChange={handleChange}
          defaultValue='top'
        >
          {props.options.map(el => (
            <FormControlLabel
              checked={el.value === props.value}
              key={el.value}
              value={el.value}
              control={
                el.tooltip ? (
                  <Tooltip title={el.tooltip}>
                    <Radio style={{ color: el.color || '#1976d2' }} />
                  </Tooltip>
                ) : (
                  <Radio style={{ color: el.color || '#1976d2' }} />
                )
              }
              label={el.label || ''}
            />
          ))}
        </RadioGroup>
      </FormControl>
      {error && (
        <Typography variant='caption' color='error'>
          {error}
        </Typography>
      )}
    </div>
  );
};

export default RadioComponent;
