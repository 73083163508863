import XLSX from 'xlsx';
import { currencyFormat } from '../../helpers';
import getSession from '../../helpers/getSession';

export default data => {
  const session = getSession();
  const filename = 'DIFERENCIAS_DE_AJUSTE.xlsx';
  const wb = XLSX.utils.book_new();

  const header = [
    'SKU',
    'Producto',
    'Cant. Anterior',
    'Ajuste',
    'Diferencia',
    'Costo Unitario',
    'Costo Total Diff.',
    'Costo Total de Inventario',
  ];

  const ws = XLSX.utils.json_to_sheet([], { header, skipHeader: true });

  const tmp =
    data && data.length > 0
      ? data.map(el => {
          const diff =
            session?.Company.id === 71
              ? parseFloat(el.previousQuantity) + parseFloat(el.currentQuantity)
              : parseFloat(el.currentQuantity) - parseFloat(el.previousQuantity);
          const totalCostDiff = parseFloat(diff) * parseFloat(el.Product.cost || 0);
          const totalCostAdjust = parseFloat(el.currentQuantity) * parseFloat(el.Product.cost || 0);
          return {
            SKU: el.Product.sku.trim(),
            Producto: el.Product.name.trim(),
            'Cant. Anterior': el.previousQuantity,
            Ajuste: el.currentQuantity,
            Diferencia: diff,
            'Costo Unitario': currencyFormat(el.Product.cost || 0),
            'Costo Total Diff.': currencyFormat(totalCostDiff),
            'Costo Total de Inventario': currencyFormat(totalCostAdjust),
          };
        })
      : [];

  XLSX.utils.sheet_add_json(ws, tmp, { skipHeader: false, origin: 'A1' });
  XLSX.utils.book_append_sheet(wb, ws, 'DIFERENCIAS_DE_AJUSTE');
  XLSX.writeFile(wb, filename);
};
