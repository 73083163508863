import React from 'react';

import { useMutation } from '@apollo/client';

import ConfirmDialog from '../../presentationals/ConfirmDialog';

import { parseError } from '../../helpers';

import GQL from './_gql';
import useSnackbarDialog from '../../store/snackbar/snackbarDialog';

const defaultSnackbar = { isOpen: true, time: 3000 };

const AuthDialog = props => {
  const { setSnackbar } = useSnackbarDialog();

  const [confirmTransfer, { loading }] = useMutation(GQL.CONFIRM_TRANSFER);

  const handleAction = async () => {
    try {
      await confirmTransfer({ variables: { id: props.id } });
      props.handleClose();
    } catch (e) {
      const parseErrors = parseError(e);
      parseErrors.forEach(el => {
        if (el.name === 'BAD_USER_INPUT') {
          const key = Object.keys(el.message)[0];
          setSnackbar({ ...defaultSnackbar, label: el.message[key], severity: 'error' });
        } else {
          setSnackbar({ ...defaultSnackbar, label: el.message, severity: 'error' });
        }
      });
    }
  };

  const handleClose = () => {
    props.handleClose();
  };

  return (
    <ConfirmDialog
      isOpen={props.isOpen}
      isLoading={loading}
      handleAction={handleAction}
      handleClose={handleClose}
    />
  );
};

export default AuthDialog;
