import React, { useState, useEffect } from 'react';

import Form from './form';
import Table from './table';

// const ivaTax = localStorage.getItem('iva');
// const iva = ivaTax || 0;

const Cart = props => {
  const [cartItems, setCartItems] = useState([]);

  const addItemToCart = data => {
    const withoutTaxes = parseFloat(parseFloat(data.cost).toFixed(2));
    const subtotal = parseFloat((parseFloat(data.quantity) * parseFloat(withoutTaxes)).toFixed(2));
    const totalTaxesPercent = parseFloat(data.iva) + parseFloat(data.ieps);
    const taxes = totalTaxesPercent
      ? parseFloat((parseFloat(subtotal) * (parseFloat(totalTaxesPercent) / 100)).toFixed(2))
      : 0;
    const total = parseFloat((parseFloat(subtotal) + parseFloat(taxes)).toFixed(2));

    setCartItems(cartItems => [...cartItems, { ...data, withoutTaxes, taxes, subtotal, total }]);
    props.getCart([...cartItems, { ...data, withoutTaxes, taxes, subtotal, total }]);
  };

  useEffect(() => {
    if (props.products.length > 0) {
      const tmp = props.products.map(data => {
        const withoutTaxes =
          data.total && parseFloat(data.total) > 0
            ? parseFloat(data.total).toFixed(2)
            : parseFloat(parseFloat(data.cost).toFixed(2));
        const subtotal = parseFloat(
          (parseFloat(data.quantity) * parseFloat(withoutTaxes)).toFixed(2),
        );
        const totalTaxesPercent = parseFloat(data.iva) + parseFloat(data.ieps);
        const taxes = totalTaxesPercent
          ? parseFloat((parseFloat(subtotal) * (parseFloat(totalTaxesPercent) / 100)).toFixed(2))
          : 0;
        const total = parseFloat((parseFloat(subtotal) + parseFloat(taxes)).toFixed(2));
        return { ...data, withoutTaxes, taxes, subtotal, total };
      });
      setCartItems(tmp);
      props.getCart(tmp);
    }
  }, [props.products.length]); // eslint-disable-next-line

  const removeItemFromCart = position => {
    const tmpCartItems = cartItems
      .map((el, i) => parseInt(i) !== parseInt(position) && el)
      .filter(el => el);
    setCartItems(tmpCartItems);
    props.getCart(tmpCartItems);
  };

  return (
    <>
      <Form handleAdd={addItemToCart} codeToClear={props.codeToClear} branchId={props.branchId} />
      <Table
        cartItems={cartItems}
        removeItem={removeItemFromCart}
        codeToClear={props.codeToClear}
      />
    </>
  );
};

export default Cart;
