var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { useEffect, useState } from 'react';
import GQL_MANUFACTURE from '../apollo/gql/manufacture';
import useApolloLazyQuery from './useApolloLazyQuery';
import { filter } from '../apollo/cache';
import { dateTimeFormat } from '../helpers';
var useManufactures = function () {
    var filterVar = filter();
    var _a = useState([]), data = _a[0], setData = _a[1];
    var _b = useApolloLazyQuery(GQL_MANUFACTURE.GET_MANUFACTURES), getManufactures = _b[0], queryResult = _b[1];
    useEffect(function () {
        getManufactures({
            variables: {
                start: filterVar.start,
                end: filterVar.end,
                branchId: filterVar.branchId,
            },
        });
    }, [filterVar]);
    useEffect(function () {
        if (queryResult.data) {
            var newData = queryResult.data.map(function (el) { return ({
                id: el.id,
                status: el.status ? 'Finalizado' : 'Cancelado',
                waste: el.waste,
                employee: el.Employee ? "".concat(el.Employee.firstName, " ").concat(el.Employee.lastName) : '',
                authEmployee: el.AuthEmployee
                    ? "".concat(el.AuthEmployee.firstName, " ").concat(el.AuthEmployee.lastName)
                    : '',
                createdAt: dateTimeFormat(el.createdAt, 'dd/MM/yyyy HH:mm'),
            }); });
            setData(newData);
        }
    }, [queryResult.data]);
    return __assign(__assign({}, queryResult), { data: data });
};
export default useManufactures;
