import React from 'react';
import useGetRoleData from './helpers/useGetRoleData';
import ModuleV3Component from '../../presentationals/Module.v3';
import RedirectCreateFormToRouteComponent from '../../presentationals/RedirectCreateFormToRoute';
import RedirectEditFormToRouteComponent from '../../presentationals/RedirectEditFormToRoute';
var RoleComponent = function () {
    var _a = useGetRoleData({ getByFilter: true }), data = _a.data, loading = _a.loading;
    var header = [{ Header: 'Nombre', accessor: 'name' }];
    return (React.createElement(React.Fragment, null,
        React.createElement(ModuleV3Component, { title: 'Roles de empleados', data: data, header: header, isLoading: loading }),
        React.createElement(RedirectCreateFormToRouteComponent, null),
        React.createElement(RedirectEditFormToRouteComponent, null)));
};
export default RoleComponent;
