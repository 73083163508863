import React from 'react';
import { useReactiveVar } from '@apollo/client';
import FooterDialogComponent from '../../../../presentationals/Dialog/Footer';
import BodyProductVariationComponent from '../Body';
import CreateProductVariationButtonComponent from './CreateButton';
import DialogComponent from '../../../../presentationals/Dialog';
import { isCreateProductVariationOpen } from '../../../../apollo/cache';
var CreateProductVariationComponent = function () {
    var isOpen = useReactiveVar(isCreateProductVariationOpen);
    var handleClose = function () {
        isCreateProductVariationOpen(false);
    };
    return (React.createElement(DialogComponent, { fullScreen: true, open: isOpen, title: 'Nuevo tipo de componente', onClose: handleClose },
        React.createElement("div", { className: 'flex flex-1 flex-col' },
            React.createElement(BodyProductVariationComponent, null),
            React.createElement(FooterDialogComponent, null,
                React.createElement("div", null),
                React.createElement(CreateProductVariationButtonComponent, null)))));
};
export default CreateProductVariationComponent;
