import React from 'react';
import BodyModuleV3Component from './Body';
import FooterModuleV3Component from './Footer';
import HeaderModuleV3Component from './Header';
import PDFCreator from './PDFCreator';
import XLSCreator from './XLSCreator';
var ModuleV3Component = function (props) {
    return (React.createElement("div", { className: 'flex flex-1 flex-col p-3 bg-slate-50' },
        React.createElement(HeaderModuleV3Component, { hideTitle: props.hideTitle, isLoading: props.isLoading, searchLabel: props.searchLabel, title: props.title, filter: props.filter, hideSearch: props.hideSearch, tableSetting: props.tableSetting, hideAdd: props.hideAdd, showReturnButton: props.showReturnButton, route: props.route, searchFieldOptions: props.searchFieldOptions, customizedCreateOpenForm: props.customizedCreateOpenForm }),
        props.ItemCategories,
        React.createElement(BodyModuleV3Component, { isLoading: props.isLoading, header: props.header, data: props.data, actions: props.actions }),
        React.createElement(FooterModuleV3Component, null),
        React.createElement(PDFCreator, { title: props.title, isLoading: props.isLoading, header: props.header, data: props.data }),
        React.createElement(XLSCreator, { title: props.title, isLoading: props.isLoading, header: props.header, data: props.data })));
};
export default ModuleV3Component;
