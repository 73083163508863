import GET_CALENDARS from './getCalendars';
import FRAGMENT_CALENDAR from './fragmentCalendar';
import CREATE_CALENDARS from './createCalendars';
import CREATE_CALENDAR from './createCalendar';
import GET_CALENDAR_BY_ID from './getCalendarById';
import UPDATE_CALENDAR_FILE from './updateCalendarFile';
import DELETE_CALENDAR_FILE from './deleteCalendarFile';
var GQL_CALENDAR = {
    CREATE_CALENDAR: CREATE_CALENDAR,
    CREATE_CALENDARS: CREATE_CALENDARS,
    DELETE_CALENDAR_FILE: DELETE_CALENDAR_FILE,
    FRAGMENT_CALENDAR: FRAGMENT_CALENDAR,
    GET_CALENDAR_BY_ID: GET_CALENDAR_BY_ID,
    GET_CALENDARS: GET_CALENDARS,
    UPDATE_CALENDAR_FILE: UPDATE_CALENDAR_FILE,
};
export default GQL_CALENDAR;
