import React from 'react';
import { Outlet } from 'react-router-dom';
import GoBackButtonComponent from '../../../presentationals/GoBackButton';
import MenuCustomerComponent from './Menu';
import useGetSession from '../../../hooks/useGetSession';
var CustomerLayoutComponent = function () {
    var _a;
    var session = useGetSession();
    var customerLabel = ((_a = session === null || session === void 0 ? void 0 : session.Company) === null || _a === void 0 ? void 0 : _a.activity) === 3 ? 'paciente' : 'cliente';
    return (React.createElement("div", { className: 'flex flex-1 flex-col' },
        React.createElement("div", { className: 'flex items-center px-4 py-0 border-0 border-b border-[#d6d6d6] border-solid' },
            React.createElement("div", { className: 'mr-3' },
                React.createElement(GoBackButtonComponent, null)),
            React.createElement("div", { className: 'text-lg text-gray-700 tracking-tight' },
                "Configuraci\u00F3n del ",
                customerLabel)),
        React.createElement(MenuCustomerComponent, null),
        React.createElement("div", { className: 'flex flex-1 flex-col h-full overflow-hidden' },
            React.createElement(Outlet, null))));
};
export default CustomerLayoutComponent;
