import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import ItemCashClossinSummaryIncomeReportComponent from '../../Item';
import useApolloQuery from '../../../../../../hooks/useApolloQuery';
import GQL_SALE from '../../../../../../apollo/gql/sale';
import getDefaultCashClosingSaleResumeValues from './helpers/getDefaultValues';
import getSaleResumeBySales from './helpers/getSaleResumeBySales';
var CashClosingSaleResumeComponent = function () {
    var defaultValues = getDefaultCashClosingSaleResumeValues();
    var params = useParams();
    var _a = useState(defaultValues), resume = _a[0], setResume = _a[1];
    var data = useApolloQuery(GQL_SALE.GET_SALES('total discount status folio'), {
        variables: {
            cashClosingId: params.cashClosingId,
            limit: 0,
        },
    }).data;
    useEffect(function () {
        if (data) {
            var SaleResume = getSaleResumeBySales(data.rows);
            setResume(SaleResume);
        }
    }, [data]);
    return (React.createElement("div", { className: 'flex flex-row border-0 border-t border-b border-solid border-gray-400 p-2' },
        React.createElement("div", { className: 'flex flex-1' },
            React.createElement(ItemCashClossinSummaryIncomeReportComponent, { label: 'Ventas', total: resume.sale })),
        React.createElement("div", { className: 'flex flex-1' },
            React.createElement(ItemCashClossinSummaryIncomeReportComponent, { label: 'Descuentos', total: resume.discount })),
        React.createElement("div", { className: 'flex flex-1' },
            React.createElement(ItemCashClossinSummaryIncomeReportComponent, { label: 'Cancelaciones', total: resume.cancelled }))));
};
export default CashClosingSaleResumeComponent;
