import React from 'react';

import Container from '../../presentationals/Module.v2/Container';

import Table from './_table';
import Filter from './_filter';

const title = 'Traspasos de productos entre sucursales';

const TransferProductsComponent = () => {
  return (
    <>
      <Container title={title} noFilter noTable filter customXls>
        <Table />
      </Container>
      <Filter />
    </>
  );
};

export default TransferProductsComponent;
