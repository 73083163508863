import React from 'react';
import { Card } from '@mui/material';
import useGetRents from './helpers/useGetRents';
import FilterRentDialog from './Filter';
import getSearchSaleByOptions from '../../helpers/getSearchSaleByOptions';
import HeaderModuleV3Component from '../../presentationals/Module.v3/Header';
import FooterModuleV3Component from '../../presentationals/Module.v3/Footer';
import BodyRentComponent from './Body';
import ListRentComponent from './List';
var RentComponent = function (props) {
    var _a = useGetRents({ status: props.status }), data = _a.data, loading = _a.loading;
    var searchByOptions = getSearchSaleByOptions();
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: 'flex flex-1 flex-col p-3 bg-slate-50' },
            React.createElement(HeaderModuleV3Component, { searchFieldOptions: {
                    name: 'searchSaleBy',
                    options: searchByOptions,
                    defaultValue: 'folio',
                }, isLoading: loading, title: 'Rentas', filter: true, hideAdd: true }),
            React.createElement(Card, { className: 'mt-2 flex flex-1 flex-col basis-0 overflow-auto' }, [5, 6].includes(props.status) ? (React.createElement(ListRentComponent, { data: data, loading: loading })) : (React.createElement(BodyRentComponent, { deliveryStatus: props.status, data: data, loading: loading }))),
            React.createElement(FooterModuleV3Component, null)),
        React.createElement(FilterRentDialog, null)));
};
export default RentComponent;
