import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from '@mui/material';
import React from 'react';
var DiscountTypeFieldComponent = function (props) {
    var handleRadioChange = function (event) {
        props.onChange(props.name, parseInt(event.target.value));
    };
    return (React.createElement(FormControl, null,
        React.createElement(FormLabel, { id: props.name }, "Tipo de descuento"),
        React.createElement(RadioGroup, { onChange: handleRadioChange, row: true, value: props.value, "aria-labelledby": 'discountTypeLabel', name: props.name },
            React.createElement(FormControlLabel, { value: 1, control: React.createElement(Radio, null), label: 'Porcentaje' }),
            React.createElement(FormControlLabel, { value: 2, control: React.createElement(Radio, null), label: 'Precio fijo' }))));
};
export default DiscountTypeFieldComponent;
