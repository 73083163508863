var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useRef } from 'react';
import { Card } from '@mui/material';
import GoBackButtonComponent from '../../presentationals/GoBackButton';
import FormGlobalInvoiveComponent from './Form';
import SalesGlobalInvoiveComponent from './Sales';
import CreateGlobalInvoiceButtonComponent from './CreateButton';
import getDefaultGlobalInvoiceValues from './Form/helpers/getDefaultValues';
var GlobalInvoiceComponent = function () {
    var salesGlobalInvoive = useRef(null);
    var formGlobalInvoiveRef = useRef(null);
    var handleGetFormValues = function () {
        var _a, _b;
        var Inputs = ((_a = formGlobalInvoiveRef.current) === null || _a === void 0 ? void 0 : _a.getValues()) || getDefaultGlobalInvoiceValues();
        var Sales = ((_b = salesGlobalInvoive.current) === null || _b === void 0 ? void 0 : _b.getSales()) || [];
        return __assign(__assign({}, Inputs), { Sales: Sales });
    };
    var handleCreated = function () {
        var _a;
        (_a = formGlobalInvoiveRef.current) === null || _a === void 0 ? void 0 : _a.clear();
    };
    var handleGlobalInvoiceTypeChange = function (type) {
        var _a;
        (_a = salesGlobalInvoive.current) === null || _a === void 0 ? void 0 : _a.setGlobalInvoiceType(type);
    };
    return (React.createElement("div", { className: 'flex flex-1 h-full flex-col' },
        React.createElement("div", { className: 'flex items-center px-4 py-0 border-0 border-b border-[#d6d6d6] border-solid' },
            React.createElement("div", { className: 'mr-3' },
                React.createElement(GoBackButtonComponent, { route: -1 })),
            React.createElement("div", { className: 'text-lg text-gray-700 tracking-tight' }, "Factura global")),
        React.createElement("div", { className: 'sm:flex-1 flex-col p-3 sm:flex-row sm:grid sm:grid-cols-3 sm:gap-3 overflow-auto' },
            React.createElement(Card, { className: 'flex sm:flex-1 flex-col px-2 py-3' },
                React.createElement("div", { className: 'flex' },
                    React.createElement("div", { className: 'text-base font-medium text-sky-600 mb-2' }, "Configuraci\u00F3n de la factura")),
                React.createElement("div", { className: 'flex sm:flex-1' },
                    React.createElement("div", { className: 'w-full sm:h-full sm:relative sm:overflow-auto' },
                        React.createElement(FormGlobalInvoiveComponent, { onGlobalInvoiceTypeChange: handleGlobalInvoiceTypeChange, ref: formGlobalInvoiveRef }))),
                React.createElement("div", { className: 'flex mt-1' },
                    React.createElement(CreateGlobalInvoiceButtonComponent, { onCreated: handleCreated, onClick: handleGetFormValues }))),
            React.createElement("div", { className: 'sm:col-span-2 sm:flex sm:flex-1' },
                React.createElement(SalesGlobalInvoiveComponent, { ref: salesGlobalInvoive })))));
};
export default GlobalInvoiceComponent;
