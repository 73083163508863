import React from 'react';
import AddIcon from '@mui/icons-material/Add';
import { IconButton, Tooltip } from '@mui/material';
import { isCreateRateOpen } from '../../../../../apollo/cache';
var OpenCreateRateButtonComponent = function () {
    var handleClick = function () {
        isCreateRateOpen(true);
    };
    return (React.createElement("div", { className: 'flex items-center ml-1 mb-1' },
        React.createElement(Tooltip, { title: 'Nuevo nombre de tarifa' },
            React.createElement(IconButton, { onClick: handleClick, "aria-label": 'new-rate' },
                React.createElement(AddIcon, null)))));
};
export default OpenCreateRateButtonComponent;
