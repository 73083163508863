const getProductPriceByCustomerPrice = (UnformattedPrices, price, customerPrice) => {
  const Prices = UnformattedPrices || [];
  const findProduct = Prices.find(
    el => el.Rate && parseInt(el.Rate.id) === parseInt(customerPrice),
  );
  if (findProduct) return findProduct.price;
  return findProduct ? findProduct.price : price || 0;
};

export default getProductPriceByCustomerPrice;
