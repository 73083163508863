import React, { useMemo } from 'react';
import { useReactiveVar } from '@apollo/client';
import SelectFieldComponent from '../../../../../presentationals/FormComponents/SelectField';
import { inputError } from '../../../../../apollo/cache';
var SelectionTypeProductVariationFieldComponent = function (props) {
    var errors = useReactiveVar(inputError);
    var selectionTypeOptions = useMemo(function () { return [
        { value: 1, label: 'Máximo una porción por producto' },
        { value: 2, label: 'Una o muchas porciones por producto' },
    ]; }, []);
    return (React.createElement(SelectFieldComponent, { name: 'selectionType', label: '\u00BFCu\u00E1ntas porciones por producto se pueden elegir?', value: props.value, error: errors.selectionType, options: selectionTypeOptions, onChange: props.onChange }));
};
export default SelectionTypeProductVariationFieldComponent;
