import React, { useState } from 'react';

import SettingsIcon from '@mui/icons-material/Settings';

import IconButton from '../../../IconButton';

import Form from './FormDialog';

const GlobalBillButtonComponent = () => {
  const [isOpen, setIsOpen] = useState(false);
  const handleOpen = () => {
    setIsOpen(true);
  };
  const handleClose = () => {
    setIsOpen(false);
  };
  return (
    <>
      <IconButton
        label='Configuración de Autorizaciones'
        action={handleOpen}
        icon={<SettingsIcon />}
      />
      <Form isOpen={isOpen} handleClose={handleClose} />
    </>
  );
};

export default GlobalBillButtonComponent;
