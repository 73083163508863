var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import { useReactiveVar } from '@apollo/client';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '../../../../../../presentationals/FormComponents/TextField';
import SelectField from '../../../../../../presentationals/FormComponents/SelectField';
import OutlinedField from '../../../../../../presentationals/FormComponents/OutlinedField';
import Title from '../../../../Action/Customer/Form/Create/Body/helpers/title';
import { productSaleV2 as setValues } from '../../../../../../apollo/cache';
import useInputError from '../../../../../../hooks/useInputError';
import { getIvaOptions } from '../../../../../../helpers/invoice.options';
import ClassificationField from './helpers/ClassificationField';
import ProductKeyField from './helpers/ProductKeyField';
import UnitKeyField from './helpers/UnitKeyField';
import ProductTypeFieldProductComponent from './helpers/ProductTypeField';
import UtilityPercentFieldProductComponent from './helpers/UtilityPercentField';
import ProviderFieldProductComponent from './helpers/ProviderField';
import UnitBaseFieldProductComponent from '../../../../../product/Item/General/helpers/UnitBaseField';
import getSalePriceByCostAndUtilityPercent from '../../../../../../helpers/getSalePriceByCostAndUtilityPercent';
import SwitchComponent from '../../../../../../presentationals/FormComponents/Switch';
var BodyCreateProductDialogProductFormChooseProductSaleV2Component = function (props) {
    var ivaOptions = getIvaOptions();
    var values = useReactiveVar(setValues);
    var errors = useInputError();
    var handleChange = function (name, value) {
        var _a, _b, _c;
        if (name === 'cost' && parseFloat(values.percentAdjust) > 0) {
            var price = getSalePriceByCostAndUtilityPercent(parseFloat(value), parseFloat(values.percentAdjust));
            setValues(__assign(__assign({}, values), (_a = {}, _a[name] = value, _a.price = price.toFixed(2).toString(), _a)));
            return;
        }
        if (name === 'percentAdjust' && parseFloat(values.cost) > 0) {
            var price = getSalePriceByCostAndUtilityPercent(parseFloat(values.cost), parseFloat(value));
            setValues(__assign(__assign({}, values), (_b = {}, _b[name] = value, _b.price = price.toFixed(2).toString(), _b)));
            return;
        }
        setValues(__assign(__assign({}, values), (_c = {}, _c[name] = value, _c)));
    };
    var handleKeyPress = function (e) {
        if (e.key === 'Enter') {
            e.preventDefault();
            props.onPressEnter && props.onPressEnter();
        }
    };
    var handleSwitchChange = function (name, value) {
        var _a;
        setValues(__assign(__assign({}, values), (_a = {}, _a[name] = value, _a)));
    };
    return (React.createElement("div", { className: 'p-5 flex-1 grid grid-cols-1 md:grid-cols-4 gap-4 overflow-auto' },
        React.createElement("div", null,
            React.createElement(Title, { label: 'Datos generales' }),
            React.createElement(TextField, { label: 'Nombre', name: 'name', value: values.name, error: errors.name, onChange: handleChange, onKeyDown: handleKeyPress }),
            React.createElement(ProductTypeFieldProductComponent, { value: values.type, error: errors.type, onChange: handleChange }),
            React.createElement(UnitBaseFieldProductComponent, { value: values.unitBase, error: errors.unitBase, onChange: handleChange }),
            React.createElement(ClassificationField, { value: values.classificationId, error: errors.classificationId, onChange: handleChange }),
            React.createElement(TextField, { label: 'C\u00F3digo SKU', name: 'sku', value: values.sku, error: errors.sku, onChange: handleChange, onKeyDown: handleKeyPress }),
            React.createElement(TextField, { label: 'C\u00F3digo de barras', name: 'barcode', value: values.barcode, error: errors.barcode, onChange: handleChange, onKeyDown: handleKeyPress }),
            React.createElement(SwitchComponent, { label: '\u00BFSe comercializa por n\u00FAmero de serie?', name: 'isSerialized', onChange: handleSwitchChange, defaultValue: values.isSerialized })),
        React.createElement("div", null,
            React.createElement(Title, { label: 'Informaci\u00F3n comercial' }),
            React.createElement(TextField, { label: 'Precio de venta por unidad', name: 'price', value: values.price, error: errors.price, onChange: handleChange, onKeyDown: handleKeyPress, type: 'number' }),
            React.createElement(TextField, { name: 'cost', label: 'Precio de compra por unidad', onChange: handleChange, onKeyDown: handleKeyPress, value: values.cost, error: errors.cost }),
            React.createElement(UtilityPercentFieldProductComponent, { onChange: handleChange, value: values.percentAdjust, error: errors.percentAdjust }),
            React.createElement(ProviderFieldProductComponent, { onChange: handleChange, value: values.providerId, error: errors.providerId })),
        React.createElement("div", null,
            React.createElement(Title, { label: 'Impuestos y facturaci\u00F3n' }),
            React.createElement(SelectField, { name: 'iva', label: 'IVA', value: values.iva, error: errors.iva, onChange: handleChange, onKeyDown: handleKeyPress, options: ivaOptions }),
            React.createElement(OutlinedField, { name: 'ieps', label: 'IEPS %', endAdornment: React.createElement(InputAdornment, { position: 'end' }, "%"), value: values.ieps, error: errors.ieps, onChange: handleChange, onKeyDown: handleKeyPress }),
            React.createElement(ProductKeyField, { value: values.productKey, error: errors.productKey, onChange: handleChange }),
            React.createElement(UnitKeyField, { value: values.unitKey, error: errors.unitKey, onChange: handleChange })),
        React.createElement("div", null)));
};
export default BodyCreateProductDialogProductFormChooseProductSaleV2Component;
