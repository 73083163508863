import XLSX from 'xlsx';
import { format } from 'date-fns';
import locale from 'date-fns/locale/es';

import client from '../../apollo';
import GQL from './_gql';

import { currencyFormat } from '../../helpers/general';

const getDataFromServer = async id => {
  try {
    const { data } = await client.mutate({
      mutation: GQL.GET_BY_ID,
      variables: { id },
    });
    return data.maintenance;
  } catch (e) {
    console.log(e);
    return e;
  }
};

export default async id => {
  const data = await getDataFromServer(id);

  const filename = `MTO_${data.serial.padStart(5, '0')}.xlsx`;
  const wb = XLSX.utils.book_new();

  const header = [
    'Producto',
    'Costo',
    'Salida',
    'Cant.',
    'P. Unitario',
    'Subtotal',
    'Descuento',
    'IVA',
    'Total',
  ];

  const ws = XLSX.utils.json_to_sheet([], { header, skipHeader: true });
  const tmp = data.MaintenanceProducts.map(el => {
    return {
      Producto: el.Product ? el.Product.name.trim() : el.name ? el.name : '',
      Costo: el.Product?.cost ? currencyFormat(el.Product.cost) : currencyFormat(el.cost),
      Salida: el.outDate ? format(new Date(el.outDate), 'yyyy/MM/dd', { locale }) : '-',
      'Cant.': el.quantity,
      'P. Unitario': currencyFormat(el.withoutTaxes),
      Subtotal: currencyFormat(el.subtotal),
      Descuento: currencyFormat(el.discount),
      IVA: currencyFormat(el.taxes),
      Total: currencyFormat(el.total),
    };
  });

  XLSX.utils.sheet_add_json(ws, tmp, { skipHeader: false, origin: 'A1' });

  if (data.MaintenanceExpenses?.length > 0) {
    const expenses = data.MaintenanceExpenses.map(el => {
      return {
        Producto: el.Product ? el.Product.name.trim() : el.name.trim(),
        Salida: el.outDate ? format(new Date(el.outDate), 'yyyy/MM/dd', { locale }) : '-',
        'Cant.': el.quantity,
        'P. Unitario': currencyFormat(el.withoutTaxes),
        Subtotal: currencyFormat(el.subtotal),
        Descuento: currencyFormat(el.discount),
        IVA: currencyFormat(el.taxes),
        Total: currencyFormat(el.total),
      };
    });
    XLSX.utils.sheet_add_json(ws, [{ Producto: 'Gastos Extras' }], {
      skipHeader: true,
      origin: `A${tmp.length + 3}`,
    });
    XLSX.utils.sheet_add_json(ws, expenses, { skipHeader: false, origin: `A${tmp.length + 4}` });
  }

  XLSX.utils.book_append_sheet(wb, ws, `OS_${data.serial.padStart(5, '0')}`);
  XLSX.writeFile(wb, filename);
};
