import React from 'react';

import Container from '../../presentationals/Module.v2/Container';

import Action from './_action';
import Filter from './_filter';
import GQL from '../sale.report.v2/_gql';

const Rows = [
  { label: 'Fecha', key: 'clientCreatedAt', sort: 'text', format: 'dateTime' },
  { label: 'NV', key: 'folio', sort: 'text', filter: true },
  {
    label: 'Cliente',
    key: 'customer',
    sort: 'text',
    filter: true,
  },
  {
    label: 'RFC',
    key: 'identifier',
    sort: 'text',
  },
  {
    label: 'Vendedor',
    key: 'seller',
    sort: 'text',
    filter: true,
  },
  { label: 'SKU', key: 'sku', sort: 'text', filter: true },
  { label: 'Producto', key: 'product', sort: 'text', filter: true },
  { label: 'Presentación', key: 'presentation', sort: 'text', filter: true },
  { label: 'Clasificación', key: 'classification', sort: 'text', filter: true },
  { label: 'Cantidad', key: 'quantity', sort: 'text', align: 'center' },
  { label: 'Descuento', key: 'discount', sort: 'text', format: 'currency', align: 'right' },
  { label: 'Subtotal', key: 'subtotal', sort: 'text', format: 'currency', align: 'right' },
  { label: 'IVA', key: 'taxes', sort: 'text', format: 'currency', align: 'right' },
  { label: 'Total', key: 'total', sort: 'text', format: 'currency', align: 'right' },
  { label: 'FormaPago', key: 'paymentMethod' },
  { label: 'Estatus', key: 'saleStatus', sort: 'text', format: 'orderStatus' },
];
const title = 'Ventas por ticket detallada';
const operation = ['saleProduct', 'saleProducts'];

const ClassificationComponent = () => {
  return (
    <>
      <Container
        title={title}
        operation={operation}
        Rows={Rows}
        get={GQL.GET_SALE_PRODUCTS}
        noDelete
        filter
      />
      <Action />
      <Filter />
    </>
  );
};

export default ClassificationComponent;
