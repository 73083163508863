import React from 'react';
import { useReactiveVar } from '@apollo/client';
import { commentarySaleV2 } from '../../../../apollo/cache';
import TextFieldComponent from '../../../../presentationals/FormComponents/TextField';
var CommentaryFieldFooterSaleV2Component = function () {
    var commentarySaleV2Var = useReactiveVar(commentarySaleV2);
    var handleChange = function (name, value) {
        commentarySaleV2(value);
    };
    return (React.createElement(TextFieldComponent, { defaultLabel: 'Anotaciones o comentarios', className: 'bg-white shadow-sm', rows: 5, multiline: true, value: commentarySaleV2Var, onChange: handleChange }));
};
export default CommentaryFieldFooterSaleV2Component;
