import jsPDF from 'jspdf';

import { format, parseISO } from 'date-fns';
import locale from 'date-fns/locale/es';
import client from '../../apollo';

import { currencyFormat, phoneFormat, customerLabel } from '../../helpers';
import RECEIVABLE_GQL from '../../containers/receivable.v2/_gql';
import GQL_PRINT from '../../containers/print/_gql';
import { getCompanyData } from './cashClosing.80mm';

const customerVar = customerLabel({ textTransform: 'capitalize' });

const getSale = async id => {
  try {
    const { data } = await client.mutate({
      mutation: RECEIVABLE_GQL.GET_BY_ID,
      variables: { id },
    });
    return data.accountReceivable;
  } catch (e) {
    return e;
  }
};

const print = async (id, type) => {
  try {
    const { data } = await client.mutate({
      mutation: GQL_PRINT.PRINT,
      variables: { id, type },
    });
    return data.print;
  } catch (e) {
    return e;
  }
};

export default async saleId => {
  const companyData = await getCompanyData();
  if (parseInt(companyData.printClient) === 2) {
    await print(saleId, 'payment');
    return;
  }

  const data = await getSale(saleId);

  const { Branch, Customer, balance } = data;
  const Sale = data.Sale ? data.Sale : data.Charge;
  const { folio, serial, commentary, subtotal, taxes, total } = Sale;

  const clientCreatedAt = data.Sale ? data.Sale.clientCreatedAt : data.Charge.createdAt;

  const Cashier = data.Sale && data.Sale.cashier ? data.Sale.cashier : '-';
  const discount = data.Sale ? data.Sale.discount : 0;
  const SaleProducts = data.Sale
    ? data.Sale.SaleProducts
    : [
        {
          Product: { name: 'Cargo' },
          quantity: 1,
          withoutTaxes: subtotal,
          subtotal,
          commentary,
        },
      ];

  const addressLineBranch = `${Branch.addressLine1}, ${Branch.addressLine2}`.trim();

  const doc = new jsPDF({
    unit: 'mm',
    format: [200, 600],
  });

  doc.setProperties({
    title: `doc_${folio}-${serial}_${clientCreatedAt}`,
  });

  let breakline = 10;

  doc.setFontSize(10);
  doc.setFont('times', 'bold');
  doc.text(`${Branch.name}`, 35, breakline, 'center');
  doc.setFontSize(8);
  doc.setFont('times', 'normal');

  breakline += 3;
  doc.text(doc.splitTextToSize(addressLineBranch, 60), 35, breakline, 'center');

  breakline = addressLineBranch.length >= 30 ? breakline + 7 : breakline + 3;
  doc.text(
    `${Branch.city ? `${Branch.city},` : ' '} ${Branch.state ? `${Branch.state},` : ''} C.P. ${
      Branch.zipCode
    }`,
    35,
    breakline,
    'center',
  );

  breakline += 3;
  doc.text(`Tel. ${phoneFormat(Branch.phone)}`, 35, breakline, 'center');

  breakline += 5;
  doc.setFontSize(10);
  doc.text('NOTA DE ABONO', 35, breakline, 'center');

  const addressLineCustomer =
    Customer.addressLine1.length > 0 && `${Customer.addressLine1}, ${Customer.addressLine2}`.trim();
  breakline += 5;

  const allowedCategories = ['', 'NOTA DE VENTA', 'VENTA A CRÉDITO', 'ORDEN DE SERVICIO'];
  doc.setFontSize(8);

  const customerName = data.customer || '';
  doc.text(`${customerVar}: ${customerName}`, 5, breakline, 'left');

  if (customerName.trim() === 'PUBLICO EN GENERAL') {
    breakline += 6;
  } else {
    breakline += 3;
    doc.text(`Teléfono: ${data.customerPhone || ''}`, 5, breakline, 'left');
    if (addressLineCustomer) {
      breakline += 3;
      doc.text(doc.splitTextToSize(`Domicilio: ${addressLineCustomer}`, 60), 5, breakline, 'left');
      breakline = addressLineCustomer.length >= 60 ? breakline + 12 : breakline + 9;
    } else {
      breakline = breakline + 5;
    }
  }

  doc.text(`Atendió: ${Cashier}`, 5, breakline, 'left');

  breakline += 3;
  doc.text(
    `Creado el: ${format(parseISO(clientCreatedAt), 'dd/MM/yyyy HH:ss', { locale })}`,
    5,
    breakline,
    'left',
  );

  breakline += 4;
  doc.text('=====================================', 35, breakline, 'center');

  breakline += 3;
  doc.setFont('times', 'bold');
  doc.text('ABONOS', 35, breakline, 'center');

  breakline += 1;
  doc.line(5, breakline, 66, breakline);

  breakline += 3;
  doc.text('Fecha', 5, breakline, 'left');
  doc.text('Forma de Pago', 25, breakline, 'left');
  doc.text('Monto', 66, breakline, 'right');
  doc.setFont('times', 'normal');

  breakline += 1;
  doc.line(5, breakline, 66, breakline);

  breakline += 3;
  doc.setFontSize(6);
  let totalAmount = 0;
  data.Transactions.forEach(el => {
    const tmpDate = format(parseISO(el.clientCreatedAt), 'dd/MM/yyyy HH:ss', { locale });
    const paymentMethodName = el.PaymentMethod ? el.PaymentMethod.name : 'EFECTIVO';
    const tmpAmount = parseFloat(el.total);

    doc.text(tmpDate, 5, breakline, 'left');
    doc.text(paymentMethodName, 25, breakline, 'left');
    doc.text(currencyFormat(tmpAmount), 66, breakline, 'right');

    totalAmount += tmpAmount;

    breakline += 1;
    doc.line(5, breakline, 66, breakline);

    breakline += 3;
  });

  doc.setFont('times', 'bold');
  doc.setFontSize(8);
  doc.text('Total Abonado:', 48, breakline, 'right');
  doc.text(`${currencyFormat(totalAmount)}`, 66, breakline, 'right');

  breakline += 3;
  doc.setFont('times', 'normal');
  doc.text('=====================================', 35, breakline, 'center');

  breakline += 5;
  doc.line(5, breakline, 66, breakline);

  breakline += 3;
  doc.setFont('times', 'bold');
  doc.text(
    `${
      data.Sale && parseInt(data.Sale.category) >= 0
        ? allowedCategories[data.Sale.category]
        : 'CARGO'
    }: ${folio}-${serial} `,
    35,
    breakline,
    'center',
  );
  // doc.text('CONCEPTOS', 35, breakline, 'center' );

  breakline += 1;
  doc.line(5, breakline, 66, breakline);

  breakline += 3;
  doc.setFontSize(6);
  doc.text('Producto', 5, breakline, 'left');
  doc.text('Cant', 35, breakline, 'center');
  doc.text('Precio U.', 52, breakline, 'right');
  doc.text('Importe', 66, breakline, 'right');
  doc.setFont('times', 'normal');

  breakline += 1;
  doc.line(5, breakline, 66, breakline);

  breakline += 3;
  doc.setFontSize(6);
  SaleProducts.forEach(el => {
    const serialNumber = el.serialNumber ? ` ${el.serialNumber}` : '';
    const productText = doc.splitTextToSize(
      el.Product ? el.Product.name + serialNumber : el.name ? el.name + serialNumber : '',
      18,
    );
    doc.text(productText, 5, breakline, 'left');
    doc.text(`${el.quantity}`, 35, breakline, 'center');
    doc.text(`${currencyFormat(el.withoutTaxes)}`, 52, breakline, 'right');
    doc.text(`${currencyFormat(el.subtotal)}`, 66, breakline, 'right');

    breakline += productText.length * 2.5;

    if (el.commentary !== null && el.commentary !== '') {
      const splitCommentary = doc.splitTextToSize(el.commentary, 60);
      doc.text(splitCommentary, 5, breakline, 'left');
      breakline += splitCommentary.length * 2.5;
    }

    doc.line(5, breakline, 66, breakline);

    breakline += 3;
  });

  doc.setFontSize(8);
  doc.text('Subtotal:', 48, breakline, 'right');
  doc.text(`${currencyFormat(subtotal)}`, 66, breakline, 'right');

  doc.text('Dscto:', 48, breakline + 3, 'right');
  doc.text(`${currencyFormat(discount)}`, 66, breakline + 3, 'right');

  doc.text('IVA:', 48, breakline + 6, 'right');
  doc.text(`${currencyFormat(taxes)}`, 66, breakline + 6, 'right');

  doc.setFontSize(8);
  doc.setFont('times', 'bold');
  doc.text('Cargo Total:', 48, breakline + 10, 'right');
  doc.text(`${currencyFormat(total)}`, 66, breakline + 10, 'right');

  doc.line(5, breakline + 11, 66, breakline + 11);

  breakline += 18;
  doc.setFontSize(12);
  doc.setFont('times', 'bold');
  doc.text('SALDO PENDIENTE:', 34, breakline, 'center');

  breakline += 6;
  doc.setFontSize(18);
  doc.text(`$${currencyFormat(balance)}`, 34, breakline, 'center');

  let tmpbreakline = 9;

  doc.setFontSize(8);
  if (commentary.length > 0) {
    breakline += 6;
    doc.setFont('times', 'bold');
    doc.text('Anotaciones:', 35, breakline, 'center');
    doc.setFont('times', 'normal');
    breakline += 3;
    doc.text(doc.splitTextToSize(commentary, 60), 35, breakline, 'center', 'justify');
  } else {
    tmpbreakline = 6;
  }

  doc.setFont('times', 'normal');
  doc.text('¡Gracias por su compra!', 35, breakline + parseInt(tmpbreakline), 'center');

  breakline += 6;
  doc.text(
    doc.splitTextToSize(
      'Puedes solicitar tu factura electrónica ingresando a www.capitalike.com/facturacion. El folio caducará en 3 días a partir de la fecha de compra.',
      60,
    ),
    35,
    breakline + parseInt(tmpbreakline),
    'center',
  );

  // doc.autoPrint();

  window.open(doc.output('bloburl'), '_blank');
};
