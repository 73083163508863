var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
var IconButtonComponent = function (_a) {
    var params = _a.params, action = _a.action, props = __rest(_a, ["params", "action"]);
    var handleClick = function (e) {
        action(e, params);
    };
    if (props.hide)
        return React.createElement("div", null);
    return props.label ? (React.createElement(Tooltip, { title: props.label, classes: { tooltip: 'mt-2' } },
        React.createElement("span", null,
            React.createElement(IconButton, __assign({ color: props.color, onClick: handleClick, "aria-label": props.label }, props, { size: props.size || 'large' }), props.icon)))) : (React.createElement(IconButton, __assign({ color: props.color, onClick: handleClick, "aria-label": props.label }, props, { size: props.size || 'large' }), props.icon));
};
export default IconButtonComponent;
