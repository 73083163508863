import { gql } from '@apollo/client';

const GET = gql`
  query Sales(
    $name: [String]
    $limit: Int
    $offset: Int
    $start: Date
    $end: Date
    $category: [Int]
    $status: [Int]
    $type: [Int]
    $paid: Boolean
    $customerId: ID
    $cashierId: ID
    $sellerId: ID
    $deliveryManId: ID
    $branchId: ID
    $order: [String]
    $options: [Int]
    $isInvoice: Boolean
    $classificationId: [ID]
    $outRange: Boolean
  ) {
    sales(
      classificationId: $classificationId
      like: $name
      limit: $limit
      offset: $offset
      start: $start
      end: $end
      category: $category
      status: $status
      type: $type
      paid: $paid
      customerId: $customerId
      cashierId: $cashierId
      sellerId: $sellerId
      deliveryManId: $deliveryManId
      branchId: $branchId
      order: $order
      options: $options
      isInvoice: $isInvoice
      outRange: $outRange
    ) {
      count
      totals {
        id
        name
        qty
        amount
      }
      rows {
        id
        type
        subtype
        serial
        folio
        status
        subtotal
        shipping
        discount
        taxes
        total
        createdAt
        clientCreatedAt
        paid
        commentary
        invoiceId
        folio2
        category
        pdfFileId
        pdfServiceOrderFileId
        pdfWorksAndRemodelingFilId
        customerId
        expenses
        commission
        Invoice {
          id
          uuid
        }
        Customer {
          id
          firstName
          lastName
          CustomerBillingInformation {
            id
            addressLine1
            addressLine2
            altEmail
            applyExtraTaxes
            businessName
            city
            country
            email
            identifier
            municipality
            otherEmail
            paymentMethod
            paymentMethodId
            phone
            state
            useCfdi
            zipCode
          }
        }
        Seller {
          firstName
          lastName
        }
        Cashier {
          firstName
          lastName
        }
        Transactions {
          total
        }
        cashier
        seller
        AccountReceivable {
          balance
        }
      }
    }
  }
`;

const GET_RECEIVABLE = gql`
  query Sales(
    $like: String
    $limit: Int
    $offset: Int
    $start: Date
    $end: Date
    $category: [Int]
    $status: [Int]
    $type: [Int]
    $paid: Boolean
    $customerId: ID
    $cashierId: ID
    $sellerId: ID
    $deliveryManId: ID
    $branchId: ID
    $outRange: Boolean
  ) {
    sales(
      like: $like
      limit: $limit
      offset: $offset
      start: $start
      end: $end
      category: $category
      status: $status
      type: $type
      paid: $paid
      customerId: $customerId
      cashierId: $cashierId
      sellerId: $sellerId
      deliveryManId: $deliveryManId
      branchId: $branchId
      outRange: $outRange
    ) {
      count
      rows {
        id
        serial
        folio
        total
        clientCreatedAt
        Customer {
          firstName
          lastName
        }
        AccountReceivable {
          payment
          balance
        }
      }
    }
  }
`;

const GET_SALE_PRODUCTS = gql`
  query SaleProducts(
    $name: [String]
    $limit: Int
    $offset: Int
    $start: Date
    $end: Date
    $productId: ID
    $branchId: ID
    $order: [String]
    $customerId: ID
    $classificationId: [ID]
    $status: [Int]
    $withDiscount: Boolean
  ) {
    saleProducts(
      like: $name
      limit: $limit
      offset: $offset
      start: $start
      end: $end
      productId: $productId
      branchId: $branchId
      order: $order
      customerId: $customerId
      classificationId: $classificationId
      status: $status
      withDiscount: $withDiscount
    ) {
      id
      clientCreatedAt
      product
      presentation
      folio
      customer
      sku
      quantity
      discount
      subtotal
      taxes
      total
      identifier
      seller
      classification
      paymentMethod
      saleStatus
      AuthOp {
        name
        createdAt
      }
    }
  }
`;

const GET_SALE_PRODUCTS_ROTATION = gql`
  query SaleProductsRotation(
    $name: String
    $limit: Int
    $offset: Int
    $start: Date
    $end: Date
    $branchId: ID
    $order: [String]
    $greaterThanZero: Boolean
    $productId: ID
    $customerId: ID
    $classificationId: [ID]
    $sellerId: ID
  ) {
    saleProductsRotation(
      like: $name
      limit: $limit
      offset: $offset
      start: $start
      end: $end
      branchId: $branchId
      order: $order
      greaterThanZero: $greaterThanZero
      productId: $productId
      customerId: $customerId
      classificationId: $classificationId
      sellerId: $sellerId
    ) {
      count
      totals {
        id
        name
        qty
        amount
      }
      rows {
        id
        name
        code
        sku
        quantity
        total
        taxes
        Classification {
          id
          name
        }
      }
    }
  }
`;

const GET_BY_ID = gql`
  query Sale($id: ID, $includeModels: Boolean, $folio: String, $branchId: ID, $category: Int) {
    sale(
      id: $id
      includeModels: $includeModels
      folio: $folio
      branchId: $branchId
      category: $category
    ) {
      id
      type
      service
      status
      serial
      invoiceId
      folio
      commentary
      currencyKey
      exchangeRate
      subtotal
      discount
      taxes
      total
      customerId
      sellerId
      cashierId
      deliveryManId
      category
      clientCreatedAt
      updatedAt
      Seller {
        firstName
        lastName
      }
      Cashier {
        firstName
        lastName
      }
      DeliveryMan {
        firstName
        lastName
      }
      SaleProducts {
        id
        name
        productId
        quantity
        unitPrice
        withoutTaxes
        subtotal
        discount
        taxes
        total
        commentary
        Product {
          id
          sku
          name
        }
        ProductPresentation {
          id
          name
        }
      }
      Customer {
        id
        firstName
        lastName
        addressLine1
        addressLine2
        zipCode
        city
        state
        phone
        CustomerBillingInformation {
          identifier
          businessName
          email
          altEmail
          otherEmail
        }
      }
      Branch {
        id
        addressLine1
        addressLine2
        zipCode
        city
        state
        phone
        altPhone
        name
      }
      Transactions {
        id
        total
        clientCreatedAt
        paymentMethodId
        PaymentMethod {
          code
          name
        }
      }
      AccountReceivable {
        id
        payment
        balance
      }
      Company {
        id
        name
      }
    }
  }
`;

const GET_BY_ID_FOR_NC = gql`
  query Sale($id: ID, $includeModels: Boolean, $folio: String, $branchId: ID, $category: Int) {
    sale(
      id: $id
      includeModels: $includeModels
      folio: $folio
      branchId: $branchId
      category: $category
    ) {
      id
      invoiceId
      SaleProducts {
        id
        quantity
        total
        productId
        Product {
          id
          sku
          name
        }
        ProductPresentation {
          id
          name
        }
      }
    }
  }
`;

const GET_BY_ID_TOTAL = gql`
  query Sale($id: ID!, $includeModels: Boolean) {
    sale(id: $id, includeModels: $includeModels) {
      id
      total
      customerId
    }
  }
`;

const GET_SALE_COMMENTARIES = gql`
  query SaleCommentaries($id: ID!) {
    sale(id: $id) {
      id
      commentary
      SaleProducts {
        id
        name
        commentary
        Product {
          name
        }
      }
    }
  }
`;

const UPDATE_STATUS = gql`
  mutation ($id: ID!, $status: Int!) {
    updateSaleStatus(id: $id, status: $status) {
      id
      type
      service
      status
      serial
      folio
      commentary
      currencyKey
      exchangeRate
      subtotal
      discount
      taxes
      total
      customerId
      code
      sellerId
      cashierId
      deliveryManId
      Seller {
        firstName
        lastName
      }
      Cashier {
        firstName
        lastName
      }
      DeliveryMan {
        firstName
        lastName
      }
      SaleProducts {
        id
        name
        productId
        quantity
        unitPrice
        withoutTaxes
        subtotal
        discount
        taxes
        total
        Product {
          name
        }
      }
      Customer {
        firstName
        lastName
        addressLine1
        addressLine2
        zipCode
        city
        state
        phone
        CustomerBillingInformation {
          identifier
          businessName
        }
      }
      Branch {
        addressLine1
        addressLine2
        zipCode
        city
        state
        phone
        name
      }
      clientCreatedAt
      updatedAt
    }
  }
`;

const CANCEL = gql`
  mutation (
    $id: ID!
    $userPassword: String!
    $reason: String
    $relatedFolio: String
    $noReturnInventory: Boolean
    $reasonCancel: Int
    $commentary: String
  ) {
    cancelSale(
      id: $id
      userPassword: $userPassword
      reason: $reason
      relatedFolio: $relatedFolio
      noReturnInventory: $noReturnInventory
      reasonCancel: $reasonCancel
      commentary: $commentary
    ) {
      id
    }
  }
`;

const GET_SERVICE_ORDERS = gql`
  query ServiceOrders {
    serviceOrders {
      count
      rows {
        id
        serial
        category
        Customer {
          firstName
          lastName
        }
      }
    }
  }
`;

export default {
  GET,
  GET_BY_ID,
  UPDATE_STATUS,
  CANCEL,
  GET_SALE_PRODUCTS,
  GET_SALE_PRODUCTS_ROTATION,
  GET_RECEIVABLE,
  GET_SALE_COMMENTARIES,
  GET_BY_ID_TOTAL,
  GET_SERVICE_ORDERS,
  GET_BY_ID_FOR_NC,
};
