import React, { useEffect } from 'react';
import ButtonActionSaleV2Component from '../../../../../sale.v2/Action/helpers/Button';
import useApolloLazyQuery from '../../../../../../hooks/useApolloLazyQuery';
import GQL_BRANCH from '../../../../../../apollo/gql/branch';
var BranchSubtitlePurchaseOrderComponent = function (props) {
    var _a = useApolloLazyQuery(GQL_BRANCH.GET_BRANCH_BY_ID), getData = _a[0], _b = _a[1], loading = _b.loading, data = _b.data;
    useEffect(function () {
        if (props.branchId)
            getData({ variables: { id: props.branchId } });
    }, [props.branchId]);
    return (React.createElement(ButtonActionSaleV2Component, { title: 'Sucursal', subtitle: loading ? 'Cargando...' : data ? data.name : 'No identificado', onClick: props.onClick }));
};
export default BranchSubtitlePurchaseOrderComponent;
