import React, { useMemo } from 'react';
import useGetProductData from './helpers/useGetProductData';
import ModuleV3Component from '../../presentationals/Module.v3';
import ItemCategoriesProductComponent from './ItemCategories';
import TableSettingsProductComponent from './TableSettings';
import getProductHeaderColumns from './helpers/getHeaderColumns';
import RedirectEditFormToRouteComponent from '../../presentationals/RedirectEditFormToRoute';
import { searchProductsByOptions } from '../../presentationals/FormComponents/SearchProductBySelectField';
import RedirectCreateFormToRouteComponent from '../../presentationals/RedirectCreateFormToRoute';
import FilterProductComponent from './Filter';
var ProductComponent = function () {
    var _a = useGetProductData({ getByFilter: true }), data = _a.data, loading = _a.loading;
    var header = useMemo(function () { return getProductHeaderColumns(); }, []);
    return (React.createElement(React.Fragment, null,
        React.createElement(ModuleV3Component, { searchFieldOptions: {
                name: 'searchProductBy',
                options: searchProductsByOptions,
                defaultValue: 'name',
            }, tableSetting: true, title: 'Productos', data: data, header: header, isLoading: loading, filter: true, ItemCategories: React.createElement(ItemCategoriesProductComponent, { isLoading: loading }) }),
        React.createElement(TableSettingsProductComponent, null),
        React.createElement(RedirectCreateFormToRouteComponent, null),
        React.createElement(RedirectEditFormToRouteComponent, null),
        React.createElement(FilterProductComponent, null)));
};
export default ProductComponent;
