var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import { NativeSelect } from '@mui/material';
import { hourOptions } from '../../../../../../helpers';
import { inputError, workingHoursForm } from '../../../../../../apollo/cache';
import setInputErrorFormat from '../../../../../sale.v2/Action/helpers/setInputErrorFormat';
import getInputErrorFormat from '../../../../../sale.v2/Action/helpers/getInputErrorFormat';
var WorkHourWorkingHoursFieldComponent = function (props) {
    var handleChange = function (e) {
        try {
            inputError({});
            var workingHoursFormVar = workingHoursForm();
            var newArray = workingHoursFormVar.map(function (el) {
                var _a;
                return el.day === props.day
                    ? __assign(__assign({}, el), (_a = {}, _a[props.type] = parseFloat(e.target.value), _a)) : el;
            });
            workingHoursForm(newArray);
            newArray.forEach(function (el) {
                if (el.entry >= el.out && el.entry !== -1)
                    throw new Error(setInputErrorFormat("".concat(el.day), 'La hora de entrada debe ser menor a la hora de salida.'));
            });
        }
        catch (error) {
            getInputErrorFormat(error);
        }
    };
    return (React.createElement(NativeSelect, { disableUnderline: true, className: 'border-0 border-b-4 border-gray-200 border-solid hover:border-sky-600 cursor-default', inputProps: {
            IconComponent: function () { return null; },
            style: {
                padding: '0.5rem',
                paddingTop: '0.75rem',
                backgroundColor: 'transparent',
            },
        }, onChange: handleChange, endAdornment: false, value: props.hour }, hourOptions.map(function (el) { return (React.createElement("option", { key: el.value, value: el.value }, el.label)); })));
};
export default WorkHourWorkingHoursFieldComponent;
