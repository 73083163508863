var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useEffect, useState } from 'react';
import { useReactiveVar } from '@apollo/client';
import ActionSettingsReceivableButtonComponent from './ActionButton';
import TextFieldComponent from '../../../../presentationals/FormComponents/TextField';
import { inputError } from '../../../../apollo/cache';
var SettingsReceivableComponent = function (props) {
    var defaultValues = {
        timeLimit: '0',
        commentary: '',
    };
    var errors = useReactiveVar(inputError);
    var _a = useState(defaultValues), values = _a[0], setValues = _a[1];
    useEffect(function () {
        if (props.timeLimit || props.commentary)
            setValues(function (values) { return (__assign(__assign({}, values), { timeLimit: props.timeLimit ? props.timeLimit.toString() : '0', commentary: props.commentary || '' })); });
    }, [props.timeLimit]);
    var handleChange = function (name, value) {
        setValues(function (values) {
            var _a;
            return (__assign(__assign({}, values), (_a = {}, _a[name] = value, _a)));
        });
    };
    return (React.createElement("div", { className: 'flex flex-1 flex-col' },
        React.createElement("div", { className: 'text-lg' }, "Configuraci\u00F3n de la cuenta"),
        React.createElement(TextFieldComponent, { label: 'Comentario del documento', name: 'commentary', onChange: handleChange, value: values.commentary, error: errors.commentary, multiline: true, rows: 2 }),
        React.createElement(TextFieldComponent, { label: 'Plazo para pago de la cuenta', name: 'timeLimit', type: 'number', onChange: handleChange, value: values.timeLimit, error: errors.timeLimit }),
        React.createElement(ActionSettingsReceivableButtonComponent, { values: values })));
};
export default SettingsReceivableComponent;
