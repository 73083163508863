var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Card, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, } from '@mui/material';
import OpenAddQuestionButtonComponent from './OpenAddQuestionButton';
import GQL_SURVEY from '../../../apollo/gql/survey';
import LoadingProgress from '../../../presentationals/LoadingProgress';
import ErrorDataComponent from '../../../presentationals/ErrorData';
import useApolloLazyQuery from '../../../hooks/useApolloLazyQuery';
import ItemEditSurveyQuestionComponent from './Item';
import CopyrightComponent from '../../../presentationals/Module.v2/Copyright';
import CreateSurveyQuestionDialogComponent from './Create';
var EditSurveyComponent = function () {
    var params = useParams();
    var _a = useApolloLazyQuery(GQL_SURVEY.GET_SURVEY_BY_ID), getData = _a[0], _b = _a[1], loading = _b.loading, data = _b.data;
    useEffect(function () {
        if (params === null || params === void 0 ? void 0 : params.surveyId)
            getData({ variables: { id: params.surveyId } });
    }, [params]);
    if (loading)
        return React.createElement(LoadingProgress, null);
    if (!data)
        return React.createElement(ErrorDataComponent, null);
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: 'flex flex-1 flex-col' },
            React.createElement("div", { className: 'grid grid-cols-1 sm:grid-cols-3 flex-1 flex-col basis-0 overflow-auto' },
                React.createElement("div", { className: 'flex flex-1 flex-col px-4 py-3' },
                    React.createElement("div", { className: 'flex items-center' },
                        React.createElement("div", { className: 'text-base font-medium mb-1 flex flex-1' }, "Preguntas de la encuesta"),
                        React.createElement(OpenAddQuestionButtonComponent, null)),
                    React.createElement("div", { className: 'text-sm' }, "Aqu\u00ED podr\u00E1s manipular las preguntas de la encuenta, podr\u00E1s a\u00F1adir, modificar o eliminar seg\u00FAn sean tus necesidades."),
                    React.createElement(Card, { className: 'flex flex-1 flex-col mt-4' }, data.SurveyQuestions.length === 0 ? (React.createElement("div", { className: 'text-sm p-4' }, "De momento la encuenta no cuenta con preguntas vinculadas.")) : (React.createElement(TableContainer, null,
                        React.createElement(Table, { size: 'small' },
                            React.createElement(TableHead, null,
                                React.createElement(TableRow, null,
                                    React.createElement(TableCell, null, "Pregunta o texto"),
                                    React.createElement(TableCell, null, "Tipo"),
                                    React.createElement(TableCell, null))),
                            React.createElement(TableBody, null, data.SurveyQuestions.map(function (el) { return (React.createElement(ItemEditSurveyQuestionComponent, __assign({ key: el.id }, el))); })))))))),
            React.createElement("div", { className: 'py-4' },
                React.createElement(CopyrightComponent, null))),
        React.createElement(CreateSurveyQuestionDialogComponent, null)));
};
export default EditSurveyComponent;
