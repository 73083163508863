import React from 'react';
import ModuleV3Component from '../../presentationals/Module.v3';
import useGetEmployees from './helpers/useGetEmployees';
import FilterEmployeeComponent from './Filter';
import RedirectCreateFormToRouteComponent from '../../presentationals/RedirectCreateFormToRoute';
import RedirectEditFormToRouteComponent from '../../presentationals/RedirectEditFormToRoute';
var EmployeeComponent = function () {
    var _a = useGetEmployees({ getByFilter: true }), data = _a.data, loading = _a.loading;
    var header = [
        { Header: 'Permisos', accessor: 'permissions' },
        { Header: 'Puesto', accessor: 'position' },
        { Header: 'Nombre', accessor: 'name' },
        { Header: 'Teléfono', accessor: 'phone' },
        { Header: 'Email', accessor: 'email' },
        { Header: 'Sucursal', accessor: 'branch' },
    ];
    return (React.createElement(React.Fragment, null,
        React.createElement(ModuleV3Component, { title: 'Empleados', data: data, header: header, isLoading: loading, filter: true }),
        React.createElement(FilterEmployeeComponent, null),
        React.createElement(RedirectEditFormToRouteComponent, null),
        React.createElement(RedirectCreateFormToRouteComponent, null)));
};
export default EmployeeComponent;
