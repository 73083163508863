import React from 'react';
import { TableRow } from '@mui/material';
import { useNavigate } from 'react-router-dom';
var AccountReceivableRowComponent = function (props) {
    var navigate = useNavigate();
    var handleClick = function () {
        navigate("/panel/customer/".concat(props.customerId, "/receivable/").concat(props.accountReceivableId));
    };
    return React.createElement(TableRow, { onClick: handleClick }, props.children);
};
export default AccountReceivableRowComponent;
