import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import BodyCancelPaymentDialogComponent from '../../../receivable/Receivable/CancelPaymentDialog/Body';
import useApolloLazyQuery from '../../../../hooks/useApolloLazyQuery';
import LoadingProgress from '../../../../presentationals/LoadingProgress';
import ErrorDataComponent from '../../../../presentationals/ErrorData';
import GQL_TRANSACTION from '../../../../apollo/gql/transaction';
var CancelTransactionComponent = function () {
    var params = useParams();
    var _a = useApolloLazyQuery(GQL_TRANSACTION.GET_TRANSACTION_BY_ID), getData = _a[0], _b = _a[1], data = _b.data, loading = _b.loading;
    useEffect(function () {
        getData({ variables: { id: params.transactionId } });
    }, [params.transactionId]);
    if (loading)
        return React.createElement(LoadingProgress, null);
    if (!data)
        return React.createElement(ErrorDataComponent, null);
    if (!params.transactionId)
        return React.createElement(ErrorDataComponent, null);
    return (React.createElement(BodyCancelPaymentDialogComponent, { accountReceivableId: data.accountReceivableId, transactionId: params.transactionId }));
};
export default CancelTransactionComponent;
