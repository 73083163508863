var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React, { useEffect, useState } from 'react';
import { useReactiveVar } from '@apollo/client';
import SelectFieldComponent from '../../../../../../../../../../presentationals/FormComponents/SelectField';
import { productSaleV2 } from '../../../../../../../../../../apollo/cache';
import useApolloLazyQuery from '../../../../../../../../../../hooks/useApolloLazyQuery';
import GQL_PRODUCT from '../../../../../../../../../../apollo/gql/product';
var PresentationFieldBodyFormAddButtonColorEcommerceEditFormComponent = function (props) {
    var productSaleV2Var = useReactiveVar(productSaleV2);
    var _a = useState([]), options = _a[0], setOptions = _a[1];
    var _b = useApolloLazyQuery(GQL_PRODUCT.GET_PRODUCT_BY_ID), getProductById = _b[0], _c = _b[1], loading = _c.loading, data = _c.data;
    useEffect(function () {
        if (productSaleV2Var.id)
            getProductById({ variables: { id: productSaleV2Var.id } });
    }, [productSaleV2Var.id]);
    useEffect(function () {
        if (data === null || data === void 0 ? void 0 : data.ProductPresentations) {
            var prensetations = data.ProductPresentations.map(function (el) { return ({
                label: el.name,
                value: el.id,
            }); });
            setOptions(__spreadArray([{ label: 'Todas las presentaciones', value: -1 }], prensetations, true));
        }
    }, [data]);
    return (React.createElement("div", null, options.length > 0 && (React.createElement(SelectFieldComponent, { disabled: loading, name: 'presentationId', label: 'Presentaci\u00F3n del color', options: options, onChange: props.onChange, value: options.length > 0 ? props.value : '' }))));
};
export default PresentationFieldBodyFormAddButtonColorEcommerceEditFormComponent;
