import React, { useEffect } from 'react';

import { useReactiveVar } from '@apollo/client';

import { exportPdf, filter } from '../../../apollo/cache';

import PDF from '../../PDF/table';

const PdfExportComponent = props => {
  const exportPdfVar = useReactiveVar(exportPdf);
  const filterVar = useReactiveVar(filter);

  useEffect(() => {
    if (exportPdfVar.export) {
      PDF(props.title, props.gql, filterVar, props.keys);
      exportPdf({ export: false });
    }
  }, [exportPdfVar]);

  return <div />;
};

export default PdfExportComponent;
