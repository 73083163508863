import React from 'react';
import Divider from '@mui/material/Divider';
import convertToCurrencyLabel from '../../../../../../helpers/convertToCurrencyLabel';
var ItemTransactionComponent = function (props) {
    return (React.createElement("div", { className: 'flex flex-col' },
        React.createElement("div", { className: 'flex flex-1 flex-row p-2' },
            React.createElement("div", { className: 'flex flex-1' }, props.paymentForm),
            React.createElement("div", { className: 'flex flex-1 justify-center' }, props.qty),
            React.createElement("div", { className: 'flex flex-1 justify-end' }, convertToCurrencyLabel(props.total || 0))),
        React.createElement(Divider, null)));
};
export default ItemTransactionComponent;
