import React from 'react';

import { useMutation } from '@apollo/client';

import DeleteIcon from '@mui/icons-material/DeleteOutlined';

import IconButton from '@mui/material/IconButton';

import GQL from '../_gql';
import getInputErrorFormat from '../../../../sale.v2/Action/helpers/getInputErrorFormat';

const DeleteButton = props => {
  const [deleteNotification, { loading }] = useMutation(GQL.DELETE_NOTIFICATION, {
    update(cache, { data: response }) {
      try {
        const notificationId = response.deleteNotification.id;
        cache.modify({
          fields: {
            notifications(existingNotificationRefs, { readField, fieldName, storeFieldName }) {
              const args = JSON.parse(storeFieldName.replace(`${fieldName}(`, '').slice(0, -1));
              const newNotificationRows =
                existingNotificationRefs.rows &&
                existingNotificationRefs.rows.filter(el => notificationId !== readField('id', el));
              return Object.keys(args).length === 0
                ? {
                    ...existingNotificationRefs,
                    count: existingNotificationRefs.count - 1,
                    rows: newNotificationRows,
                  }
                : existingNotificationRefs;
            },
          },
        });
      } catch (e) {
        getInputErrorFormat(e);
      }
    },
  });
  const handleClick = async () => {
    try {
      await deleteNotification({ variables: { id: props.id } });
    } catch (e) {
      getInputErrorFormat(e);
    }
  };
  return (
    <IconButton
      onClick={handleClick}
      size='small'
      edge='end'
      aria-label='delete'
      disabled={loading}
    >
      <DeleteIcon fontSize='small' />
    </IconButton>
  );
};

export default DeleteButton;
