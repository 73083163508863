import React, { useRef } from 'react';
import FilterFormSurveyResultsComponent from './FilterForm';
import BodySurveyResultsComponent from './Body';
var SurveyResultsComponent = function () {
    var bodySurveyResultsRef = useRef(null);
    var handleFilterFormChange = function (values) {
        var _a;
        (_a = bodySurveyResultsRef.current) === null || _a === void 0 ? void 0 : _a.getData(values);
    };
    return (React.createElement("div", { className: 'flex flex-1 flex-col px-4 py-3' },
        React.createElement("div", { className: 'flex w-full sm:w-1/3' },
            React.createElement(FilterFormSurveyResultsComponent, { onChange: handleFilterFormChange })),
        React.createElement("div", { className: 'flex flex-1' },
            React.createElement(BodySurveyResultsComponent, { ref: bodySurveyResultsRef }))));
};
export default SurveyResultsComponent;
