var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useReactiveVar } from '@apollo/client';
import useApolloQuery from '../../../../hooks/useApolloQuery';
import GQL_PRODUCT from '../../../../apollo/gql/product';
import LoadingProgress from '../../../../presentationals/LoadingProgress';
import SelectFieldComponent from '../../../../presentationals/FormComponents/SelectField';
import TextFieldComponent from '../../../../presentationals/FormComponents/TextField';
import FooterDialogComponent from '../../../../presentationals/Dialog/Footer';
import PrintBarcodeLabelButtonComponent from './PrintButton';
import { inputError } from '../../../../apollo/cache';
var BarcodeLabelProductComponent = function () {
    var params = useParams();
    var errors = useReactiveVar(inputError);
    var defaultValues = {
        productPresentationId: '',
        qty: '',
    };
    var _a = useState(defaultValues), values = _a[0], setValues = _a[1];
    var _b = useState([]), Presentations = _b[0], setPresentations = _b[1];
    var _c = useApolloQuery(GQL_PRODUCT.GET_PRODUCT_BY_ID, {
        variables: { id: params.productId },
    }), data = _c.data, loading = _c.loading;
    useEffect(function () {
        if (data) {
            var ProductPresentations = data.ProductPresentations
                ? data.ProductPresentations.map(function (el) { return ({ value: el.id, label: el.name }); })
                : [];
            setPresentations(__spreadArray([
                { value: data.PresentationBase.id, label: data.PresentationBase.name }
            ], ProductPresentations, true));
            setValues(function (values) { return (__assign(__assign({}, values), { productPresentationId: data.PresentationBase.id })); });
        }
    }, [data]);
    var handleChange = function (name, value) {
        setValues(function (el) {
            var _a;
            return (__assign(__assign({}, el), (_a = {}, _a[name] = value, _a)));
        });
    };
    if (loading || !data)
        return React.createElement(LoadingProgress, null);
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: 'p-3 flex flex-1 flex-col' },
            React.createElement("div", { className: 'flex items-center' },
                React.createElement("div", { className: 'text-base font-medium mb-1 flex flex-1' }, "Etiquetas del producto")),
            React.createElement("div", { className: 'text-sm sm:text-base text-gray-600' }, "En esta secci\u00F3n podr\u00E1s generar etiquetas que podr\u00E1s imprimir y a\u00F1adir a tus productos para identificarlos de una manera sencilla."),
            React.createElement("div", { className: 'pt-4 flex flex-1 flex-col sm:w-1/4' },
                React.createElement(SelectFieldComponent, { name: 'productPresentationId', label: 'Presentaci\u00F3n del producto', value: values.productPresentationId, error: errors.productPresentationId, options: Presentations, onChange: handleChange }),
                React.createElement(TextFieldComponent, { name: 'qty', label: 'Cantidad de etiquetas', value: values.qty, error: errors.qty, onChange: handleChange }))),
        React.createElement(FooterDialogComponent, null,
            React.createElement("div", null),
            React.createElement(PrintBarcodeLabelButtonComponent, { Product: data, values: values }))));
};
export default BarcodeLabelProductComponent;
