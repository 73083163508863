import CREATE_CUSTOMER from './createCustomer';
import EDIT_CUSTOMER from './editCustomer';
import FRAGMENT_CUSTOMER from './fragmentCustomer';
import FRAGMENT_CUSTOMER_BILLING_INFORMATION from './fragmentCustomerBillingInformation';
import GET_CUSTOMER_BY_ID from './getCustomerById';
import GET_CUSTOMERS from './getCustomers';
import UPDATE_SCHEDULE_CUSTOMER from './updateScheduleCustomer';
var GQL_CUSTOMER = {
    CREATE_CUSTOMER: CREATE_CUSTOMER,
    EDIT_CUSTOMER: EDIT_CUSTOMER,
    FRAGMENT_CUSTOMER: FRAGMENT_CUSTOMER,
    FRAGMENT_CUSTOMER_BILLING_INFORMATION: FRAGMENT_CUSTOMER_BILLING_INFORMATION,
    GET_CUSTOMERS: GET_CUSTOMERS,
    GET_CUSTOMER_BY_ID: GET_CUSTOMER_BY_ID,
    UPDATE_SCHEDULE_CUSTOMER: UPDATE_SCHEDULE_CUSTOMER,
};
export default GQL_CUSTOMER;
