import React, { useEffect, useState } from 'react';
import { isAfter } from 'date-fns';
import { Divider } from '@mui/material';
import useGetSaleByParamSaleId from '../../../../hooks/useGetSaleByParamSaleId';
import LoadingProgress from '../../../../presentationals/LoadingProgress';
import { dateTimeFormat } from '../../../../helpers';
import getSaleStatus from '../../../../helpers/getSaleDeliveryStatus';
import ConfirmDeliveryButtonComponent from './ConfirmDeliveryButton';
import FooterDialogComponent from '../../../../presentationals/Dialog/Footer';
import PrintOptionFooterConfirmDialogSaleV2Component from '../../../sale.v2/ConfirmDialog/Footer/PrintOption';
import PrintSaleButtonComponent from '../helpers/PrintSaleButton';
var DeliveryRentComponent = function () {
    var _a = useState(true), onTime = _a[0], setOnTime = _a[1];
    var _b = useGetSaleByParamSaleId(), data = _b.data, loading = _b.loading;
    useEffect(function () {
        var _a, _b;
        if ((data === null || data === void 0 ? void 0 : data.deliveryStatus) === 0 &&
            ((_a = data === null || data === void 0 ? void 0 : data.Calendar) === null || _a === void 0 ? void 0 : _a.start) &&
            isAfter(new Date(), new Date((_b = data === null || data === void 0 ? void 0 : data.Calendar) === null || _b === void 0 ? void 0 : _b.start)))
            setOnTime(false);
    }, [data]);
    if (loading || !data)
        return React.createElement(LoadingProgress, null);
    return (React.createElement("div", { className: 'flex flex-1 flex-col' },
        React.createElement("div", { className: 'flex flex-1 flex-col' },
            React.createElement("div", { className: 'p-6' },
                React.createElement("div", { className: 'text-xl' }, [4, 5].includes(data.deliveryStatus)
                    ? 'Productos entregados el'
                    : 'Fecha programada para entrega'),
                React.createElement("div", { className: "text-4xl ".concat(onTime ? 'text-green-600' : 'text-red-600') }, dateTimeFormat(data.Calendar.start, "EEEE, d 'de' MMMM hh:mmaaa"))),
            React.createElement(Divider, { className: 'my-3' }),
            React.createElement("div", { className: 'p-6' },
                React.createElement("div", { className: 'text-lg' }, "Estatus de la renta"),
                React.createElement("div", { className: 'text-3xl' }, getSaleStatus(data.deliveryStatus))),
            data.Calendar.address && (React.createElement(React.Fragment, null,
                React.createElement(Divider, { className: 'my-3' }),
                React.createElement("div", { className: 'p-6' },
                    React.createElement("div", { className: 'text-lg' }, "Domicilio para entrega"),
                    React.createElement("div", { className: 'text-3xl' }, data.Calendar.address)))),
            React.createElement(Divider, { className: 'my-3' }),
            React.createElement("div", { className: 'p-6 w-full sm:w-1/3' },
                React.createElement(PrintSaleButtonComponent, { label: 'Imprimir comprobante de entrega', type: 'delivery', saleId: data.id }))),
        React.createElement(FooterDialogComponent, null,
            React.createElement("div", null,
                React.createElement(PrintOptionFooterConfirmDialogSaleV2Component, null)),
            data.deliveryStatus === 0 ? React.createElement(ConfirmDeliveryButtonComponent, null) : React.createElement("div", null))));
};
export default DeliveryRentComponent;
