import CREATE_CASH_FLOW from './createCashFlow';
import DELETE_CASH_FLOW from './deleteCashFlow';
import FRAGMENT_CASH_FLOW from './fragmentCashFlow';
import GET_CASH_FLOW_BY_ID from './getCashFlowById';
import GET_CASH_FLOWS from './getCashFlows';
import UPDATE_CASH_FLOW from './updateCashFlow';
var GQL_CASH_FLOW = {
    CREATE_CASH_FLOW: CREATE_CASH_FLOW,
    DELETE_CASH_FLOW: DELETE_CASH_FLOW,
    FRAGMENT_CASH_FLOW: FRAGMENT_CASH_FLOW,
    GET_CASH_FLOW_BY_ID: GET_CASH_FLOW_BY_ID,
    GET_CASH_FLOWS: GET_CASH_FLOWS,
    UPDATE_CASH_FLOW: UPDATE_CASH_FLOW,
};
export default GQL_CASH_FLOW;
