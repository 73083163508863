var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { useEffect, useState } from 'react';
import { useReactiveVar } from '@apollo/client';
import { Button, InputAdornment } from '@mui/material';
import { branchSaleV2, cartItems, currencySaleV2, discountTypeSaleV2, formatSaleV2, inputError, isItemDetailDialogOpen, productSaleV2, totalsSaleV2, } from '../../../../../../../apollo/cache';
import SelectField from '../../../../../../../presentationals/FormComponents/SelectField';
import OutlinedField from '../../../../../../../presentationals/FormComponents/OutlinedField';
import ProductKeyField from '../../../../../ChooseProduct/ProductDialog/Create/Body/helpers/ProductKeyField';
import UnitKeyField from '../../../../../ChooseProduct/ProductDialog/Create/Body/helpers/UnitKeyField';
import useApolloMutation from '../../../../../../../hooks/useApolloMutation';
import getInputErrorFormat from '../../../../../Action/helpers/getInputErrorFormat';
import { getIvaOptions } from '../../../../../../../helpers/invoice.options';
import GQL_CART from '../../../../../ChooseProduct/AddButton/_gql';
import getTotalizedCartProducts from '../../../../../helpers/getTotalizedCartProducts';
import GQL_PRODUCT from '../../../../../../../apollo/gql/product';
var TaxesFormBodyItemDetailDialogCartSaleV2Component = function (props) {
    var defaultValues = {
        id: null,
        iva: '',
        ieps: '',
        productKey: '',
        unitKey: '',
    };
    var productSalev2Var = useReactiveVar(productSaleV2);
    var errors = useReactiveVar(inputError);
    var ivaOptions = getIvaOptions();
    var _a = useState(defaultValues), values = _a[0], setValues = _a[1];
    var _b = useApolloMutation(GQL_PRODUCT.UPDATE_PRODUCT), edit = _b[0], loading = _b[1].loading;
    var _c = useApolloMutation(GQL_CART.CHECK_CART), checkCart = _c[0], loadingCart = _c[1].loading;
    useEffect(function () {
        if (props)
            setValues({
                id: props.id,
                iva: props.iva ? props.iva.toString() : '0',
                ieps: props.ieps ? props.ieps.toString() : '0',
                productKey: props.productKey ? props.productKey.toString() : '',
                unitKey: props.unitKey ? props.unitKey.toString() : '',
            });
    }, [props]);
    useEffect(function () {
        if (productSalev2Var.unitKey.length > 0)
            setValues(function (values) { return (__assign(__assign({}, values), { unitKey: productSalev2Var.unitKey || '' })); });
        if (productSalev2Var.productKey.length > 0)
            setValues(function (values) { return (__assign(__assign({}, values), { productKey: productSalev2Var.productKey || '' })); });
    }, [productSalev2Var.unitKey, productSalev2Var.productKey]);
    var handleChange = function (name, value) {
        if (errors[name])
            inputError({});
        setValues(function (values) {
            var _a;
            return (__assign(__assign({}, values), (_a = {}, _a[name] = value, _a)));
        });
    };
    var handleClick = function () { return __awaiter(void 0, void 0, void 0, function () {
        var formatSaleV2Var, currencySaleV2Var, branchSaleV2Var, cartItemsVar, discountTypeSaleV2Var_1, newCart, data, cart, e_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 3, , 4]);
                    formatSaleV2Var = formatSaleV2();
                    currencySaleV2Var = currencySaleV2();
                    branchSaleV2Var = branchSaleV2();
                    cartItemsVar = cartItems();
                    discountTypeSaleV2Var_1 = discountTypeSaleV2();
                    return [4 /*yield*/, edit({
                            variables: __assign(__assign({}, values), { iva: parseFloat(values.iva), ieps: parseFloat(values.ieps) }),
                        })];
                case 1:
                    _a.sent();
                    newCart = cartItemsVar.map(function (_a) {
                        var stock = _a.stock, el = __rest(_a, ["stock"]);
                        return ({
                            price: parseFloat(el.unitPrice ? el.unitPrice.toString() : '0'),
                            quantity: el.quantity ? parseFloat(el.quantity.toString()) : 0,
                            cost: el.cost ? parseFloat(el.cost.toString()) : 0,
                            discount: parseInt(discountTypeSaleV2Var_1.toString()) === 1
                                ? el.discountRate
                                    ? parseFloat(el.discountRate.toString())
                                    : 0
                                : el.discount
                                    ? parseFloat(el.discount.toString())
                                    : 0,
                            commentary: el.commentary,
                            productId: el.productId,
                            employeeId: el.employeeId,
                        });
                    });
                    return [4 /*yield*/, checkCart({
                            variables: {
                                isInvoice: formatSaleV2Var[2],
                                taxesOption: formatSaleV2Var[1],
                                discountType: parseInt(discountTypeSaleV2Var_1.toString()),
                                shipping: 0,
                                branchId: parseInt(branchSaleV2Var.branchInventory.toString()),
                                currencyKey: parseFloat(currencySaleV2Var[0].toString()),
                                exchangeRate: parseFloat(currencySaleV2Var[1].toString()),
                                category: parseInt(formatSaleV2Var[0].toString()),
                                Products: newCart,
                                Transactions: [],
                                showTaxes: formatSaleV2Var[3],
                            },
                        })];
                case 2:
                    data = (_a.sent()).data;
                    cart = getTotalizedCartProducts(data.totalizeCart.Products, cartItemsVar, {
                        isInvoice: formatSaleV2Var[2],
                        taxesOption: formatSaleV2Var[1],
                        showTaxes: formatSaleV2Var[3],
                    });
                    totalsSaleV2([
                        data.totalizeCart.subtotal,
                        data.totalizeCart.discount,
                        data.totalizeCart.taxes,
                        data.totalizeCart.total,
                    ]);
                    cartItems(cart);
                    isItemDetailDialogOpen(null);
                    return [3 /*break*/, 4];
                case 3:
                    e_1 = _a.sent();
                    getInputErrorFormat(e_1);
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    }); };
    var handleKeyPress = function (e) { return e.key === 'Enter' && handleClick(); };
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: 'mt-3' }),
        React.createElement(SelectField, { name: 'iva', label: 'IVA', value: values.iva, error: errors.iva, onChange: handleChange, onKeyDown: handleKeyPress, options: ivaOptions }),
        React.createElement("div", { className: 'mt-2' }),
        React.createElement(OutlinedField, { name: 'ieps', label: 'IEPS %', endAdornment: React.createElement(InputAdornment, { position: 'end' }, "%"), value: values.ieps, error: errors.ieps, onChange: handleChange, onKeyDown: handleKeyPress, type: 'number' }),
        React.createElement("div", { className: 'mt-2' }),
        React.createElement(ProductKeyField, { value: values.productKey, error: errors.productKey, onChange: handleChange, onKeyPress: handleKeyPress }),
        React.createElement("div", { className: 'mt-2' }),
        React.createElement(UnitKeyField, { value: values.unitKey, error: errors.unitKey, onChange: handleChange, onKeyPress: handleKeyPress }),
        React.createElement(Button, { disabled: loading || loadingCart, variant: 'contained', color: 'success', className: 'mt-2', onClick: handleClick }, "Actualizar informaci\u00F3n fiscal")));
};
export default TaxesFormBodyItemDetailDialogCartSaleV2Component;
