import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { CircularProgress, Paper } from '@mui/material';
import HelperTextSearchProductFieldPopoverComponent from './HelperText';
import PopoverSearchProductByFieldComponent from './SearchBy';
import PopoverProductInputBaseFieldComponent from './InputBase';
var SearchProductFieldPopoverComponent = forwardRef(function (props, ref) {
    var _a = useState(false), loading = _a[0], setLoading = _a[1];
    var handleLoading = function (loading) {
        setLoading(loading);
    };
    useImperativeHandle(ref, function () { return ({
        setLoading: handleLoading,
    }); });
    return (React.createElement("div", { className: 'flex flex-col' },
        React.createElement(Paper, { className: 'w-full flex px-2 py-1 items-center', variant: 'outlined' },
            React.createElement(PopoverSearchProductByFieldComponent, { disabled: loading }),
            React.createElement(PopoverProductInputBaseFieldComponent, { disabled: loading }),
            loading && React.createElement(CircularProgress, { size: 25 })),
        React.createElement(HelperTextSearchProductFieldPopoverComponent, null)));
});
export default SearchProductFieldPopoverComponent;
