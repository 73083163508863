import React from 'react';
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';
import { IconButton, Typography } from '@mui/material';
var NumericField = function (props) {
    var handleRemove = function () {
        props.handleClick('remove', props.params || {});
    };
    var handleAdd = function () {
        props.handleClick('add', props.params || {});
    };
    return (React.createElement("div", { className: 'flex flex-col' },
        React.createElement(Typography, null, props.label),
        React.createElement("div", { className: 'flex flex-row items-center' },
            React.createElement(IconButton, { onClick: handleRemove },
                React.createElement(RemoveIcon, null)),
            React.createElement(Typography, { className: 'mx-2' }, props.number),
            React.createElement(IconButton, { onClick: handleAdd },
                React.createElement(AddIcon, null)))));
};
export default NumericField;
