import React, { forwardRef, useImperativeHandle, useRef } from 'react';
import { useReactiveVar } from '@apollo/client';
import SelectBranchFieldComponent from '../../../../presentationals/FormComponents/SelectBranchField';
import { branchSaleV2 } from '../../../../apollo/cache';
var BranchCloseQuotationFieldComponent = forwardRef(function (props, ref) {
    var selectBranchFieldRef = useRef(null);
    var branchSaleV2Var = useReactiveVar(branchSaleV2);
    var handleChange = function (name, value) {
        branchSaleV2({
            branchInventory: value,
            branchSale: value,
        });
    };
    var handleClear = function () {
        var _a;
        (_a = selectBranchFieldRef.current) === null || _a === void 0 ? void 0 : _a.clear();
        branchSaleV2({
            branchSale: '',
            branchInventory: '',
        });
    };
    useImperativeHandle(ref, function () { return ({
        clear: handleClear,
    }); });
    return (React.createElement(SelectBranchFieldComponent, { disallowAllOptions: true, defaultValue: branchSaleV2Var.branchSale, onChange: handleChange, label: 'Sucursal para venta e inventario' }));
});
export default BranchCloseQuotationFieldComponent;
