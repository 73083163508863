var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import DialogComponent from '../../../presentationals/Dialog';
import GQL_PRODUCT_INVENTORY from '../../../apollo/gql/productInventory';
import useApolloLazyQuery from '../../../hooks/useApolloLazyQuery';
import LoadingProgress from '../../../presentationals/LoadingProgress';
import ErrorDataComponent from '../../../presentationals/ErrorData';
import getDefaultProductHistoryValues from './helpers/getDefaultValues';
import DateRangeSalesGlobalInvoiceFieldComponent from '../../globalInvoice/Sales/DateRangeField';
import SelectBranchInventoryReportInputComponent from '../SelectBranchInput';
import { dateTimeFormat } from '../../../helpers';
import convertToCurrencyLabel from '../../../helpers/convertToCurrencyLabel';
import ProductNameProductHistoryLabelComponent from './ProductLabel';
import useInventoryReportStore from '../../../store/inventoryReport';
import ProductHistoryTableFooterComponent from './Footer';
var ProductHistoryInventoryReportDialogComponent = forwardRef(function (props, ref) {
    var branchId = useInventoryReportStore(function (state) { return state.branchId; });
    var dateRangeSalesGlobalInvoiceFieldRef = useRef(null);
    var _a = useApolloLazyQuery(GQL_PRODUCT_INVENTORY.GET_PRODUCT_MOVEMENT_LOGS, {
        fetchPolicy: 'cache-and-network',
    }), getProductHistory = _a[0], _b = _a[1], loading = _b.loading, data = _b.data;
    var defaultProductHistoryValues = getDefaultProductHistoryValues();
    var _c = useState(null), productId = _c[0], setProductPresentationId = _c[1];
    var _d = useState(defaultProductHistoryValues), values = _d[0], setValues = _d[1];
    useEffect(function () {
        if (productId)
            getProductHistory({
                variables: {
                    start: values.start,
                    end: values.end,
                    limit: 0,
                    offset: 0,
                    productId: productId,
                    branchId: branchId,
                },
            });
    }, [productId, values]);
    var handleOpen = function (productId) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            setProductPresentationId(productId);
            return [2 /*return*/];
        });
    }); };
    var handleClose = function () {
        setProductPresentationId(null);
    };
    var handleDateRangeFieldChange = function (start, end) {
        setValues(function (values) { return (__assign(__assign({}, values), { start: start, end: end })); });
    };
    useImperativeHandle(ref, function () { return ({
        open: handleOpen,
    }); });
    return (React.createElement(DialogComponent, { open: !!productId, fullScreen: true, title: 'Historial de movimientos', onClose: handleClose },
        React.createElement("div", { className: 'flex flex-1 flex-col' },
            React.createElement("div", { className: 'flex flex-col sm:flex-row pb-2 border-0 border-b border-solid border-gray-300 px-4 pt-3' },
                React.createElement("div", { className: 'flex pb-2 sm:pb-0 sm:pr-2' },
                    React.createElement(ProductNameProductHistoryLabelComponent, { id: productId })),
                React.createElement("div", { className: 'flex flex-1' },
                    React.createElement(DateRangeSalesGlobalInvoiceFieldComponent, { defaultValues: [values.start, values.end], onChange: handleDateRangeFieldChange, ref: dateRangeSalesGlobalInvoiceFieldRef }),
                    React.createElement(SelectBranchInventoryReportInputComponent, { disabled: true, align: 'left' }))),
            React.createElement("div", { className: 'flex flex-1 overflow-auto basis-0' }, loading ? (React.createElement(LoadingProgress, null)) : !data ? (React.createElement(ErrorDataComponent, null)) : (React.createElement(TableContainer, null,
                React.createElement(Table, { stickyHeader: true, size: 'small' },
                    React.createElement(TableHead, null,
                        React.createElement(TableRow, null,
                            React.createElement(TableCell, null, "Creado el"),
                            React.createElement(TableCell, null, "Acci\u00F3n"),
                            React.createElement(TableCell, null, "Folio"),
                            React.createElement(TableCell, { className: 'bg-slate-300' }, "Entrada"),
                            React.createElement(TableCell, { className: 'bg-slate-300' }, "Salida"),
                            React.createElement(TableCell, { className: 'bg-slate-400' }, "Saldo"))),
                    React.createElement(TableBody, null, data.rows.map(function (el, i) { return (React.createElement(TableRow, { key: i },
                        React.createElement(TableCell, null, dateTimeFormat(el.createdAt)),
                        React.createElement(TableCell, null, el.description),
                        React.createElement(TableCell, { className: el.saleId ? 'cursor-pointer' : '' }, el.saleId ? (React.createElement(Link, { className: 'no-underline', to: "/panel/pos/report/sale/ticket/".concat(el.saleId) }, el.folio)) : (el.folio)),
                        React.createElement(TableCell, { className: 'bg-slate-200' }, convertToCurrencyLabel(el.input)),
                        React.createElement(TableCell, { className: 'bg-slate-200' }, convertToCurrencyLabel(el.output)),
                        React.createElement(TableCell, { className: 'bg-slate-300' }, convertToCurrencyLabel(el.balance)))); })),
                    React.createElement(ProductHistoryTableFooterComponent, { data: data.rows }))))))));
});
export default ProductHistoryInventoryReportDialogComponent;
