var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { useReactiveVar } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import { calendarForm, calendarId, customerSaleV2, isEditCalendarFormOpen, } from '../../../apollo/cache';
import DialogComponent from '../../../presentationals/Dialog';
import useApolloLazyQuery from '../../../hooks/useApolloLazyQuery';
import GQL from '../_gql';
import LoadingProgress from '../../../presentationals/LoadingProgress';
import MenuEditCalendarComponent from './Menu';
import getInputErrorFormat from '../../sale.v2/Action/helpers/getInputErrorFormat';
import getDefaultCalendarValues from '../Create/Body/Form/helpers/defaultValues';
import convertHourToHouseSelectFieldOption from './helpers/convertHourToHouseSelectFieldOption';
import GeneralEditCalendarComponent from './General';
import CancelCalendarComponent from './Cancel';
import AuthCancelCalendarComponent from './Cancel/Auth';
import BodyHistoryCalendarComponent from '../History/Body';
import getDefaultCustomerValues from '../../sale.v2/Action/Customer/Form/Create/Body/helpers/defaultValues';
import ImageCalendarComponent from './Image';
var EditCalendarComponent = function () {
    var isEditCalendarFormOpenVar = useReactiveVar(isEditCalendarFormOpen);
    var _a = useState(0), currentForm = _a[0], setCurrentForm = _a[1];
    var _b = useState(null), customerId = _b[0], setCustomerId = _b[1];
    var _c = useState([false, '']), cancelRequest = _c[0], setCancelRequest = _c[1];
    var _d = useState(true), allowedToEdit = _d[0], setAllowedToEdit = _d[1];
    var _e = useApolloLazyQuery(GQL.GET_BY_ID), getEvent = _e[0], loading = _e[1].loading;
    useEffect(function () {
        var f = function () { return __awaiter(void 0, void 0, void 0, function () {
            var data, start, end, reminder, defaultCalendarValues, cancelRequest_1, error_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, getEvent({ variables: { id: isEditCalendarFormOpenVar } })];
                    case 1:
                        data = (_a.sent()).data;
                        start = new Date(data.start);
                        end = new Date(data.end);
                        reminder = data.reminder && new Date(data.reminder);
                        setAllowedToEdit([1, 4, 5, 6].includes(parseInt(data.status)));
                        defaultCalendarValues = getDefaultCalendarValues({
                            title: data.title,
                            customer: data.Customer && {
                                value: data.Customer.id,
                                label: "".concat(data.Customer.firstName, " ").concat(data.Customer.lastName),
                            },
                            start: start,
                            end: end,
                            reminder: reminder,
                            startHour: convertHourToHouseSelectFieldOption(start),
                            endHour: convertHourToHouseSelectFieldOption(end),
                            reminderHour: reminder && convertHourToHouseSelectFieldOption(reminder),
                            address: data.address,
                            diagnosis: data.diagnosis,
                            description: data.description,
                            permissions: data.permission,
                            tagId: data.Tag.id,
                            crmId: data.crmId,
                            adviserId: data.Adviser
                                ? {
                                    label: "".concat(data.Adviser.firstName.trim(), " ").concat(data.Adviser.lastName.trim()).trim(),
                                    value: data.adviserId,
                                }
                                : null,
                        });
                        setCustomerId(data.Customer && data.Customer.id);
                        calendarForm(__assign(__assign({}, defaultCalendarValues), { status: data.status }));
                        customerSaleV2(getDefaultCustomerValues(data.Customer));
                        cancelRequest_1 = data.status === 2 ? [true, data.cancelCommentary] : [false, ''];
                        setCancelRequest(cancelRequest_1);
                        return [3 /*break*/, 3];
                    case 2:
                        error_1 = _a.sent();
                        getInputErrorFormat(error_1);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        }); };
        if (isEditCalendarFormOpenVar)
            f();
    }, [isEditCalendarFormOpenVar]);
    var handleClose = function () {
        var saleCalendarId = calendarId();
        var defaultCustomerData = getDefaultCustomerValues();
        if (!saleCalendarId)
            customerSaleV2(defaultCustomerData);
        var defaultData = getDefaultCalendarValues();
        calendarForm(defaultData);
        isEditCalendarFormOpen(null);
    };
    var handleMenu = function (e, value) {
        setCurrentForm(value);
    };
    if (!isEditCalendarFormOpenVar)
        return React.createElement("div", null);
    return (React.createElement(DialogComponent, { title: 'Configuraci\u00F3n del evento', onClose: handleClose, open: !!isEditCalendarFormOpenVar, fullScreen: true },
        React.createElement(MenuEditCalendarComponent, { value: currentForm, onChange: handleMenu }),
        loading ? (React.createElement(LoadingProgress, null)) : currentForm === 0 ? (React.createElement(GeneralEditCalendarComponent, { disabled: !allowedToEdit })) : currentForm === 1 ? (React.createElement(BodyHistoryCalendarComponent, { customerId: customerId })) : currentForm === 2 ? (React.createElement(ImageCalendarComponent, null)) : currentForm === 3 ? (!cancelRequest[0] ? (React.createElement(CancelCalendarComponent, { eventId: isEditCalendarFormOpenVar, description: cancelRequest[1] })) : (React.createElement(AuthCancelCalendarComponent, { eventId: isEditCalendarFormOpenVar, description: cancelRequest[1] }))) : (React.createElement("div", null))));
};
export default EditCalendarComponent;
