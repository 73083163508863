import React, { useState, useEffect } from 'react';

import { useReactiveVar, useMutation } from '@apollo/client';

import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';

import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Typography from '@mui/material/Typography';

import {
  item,
  form,
  handleContinue,
  step,
  actionForm,
  handleContinueConfiguration,
} from '../../../../../../apollo/cache';
import { currencyFormat, getTotalFromPercent, parseError } from '../../../../../../helpers';
import IconButton from '../../../../../../presentationals/IconButton';
import Snackbar from '../../../../../../presentationals/Snackbar';

import GQL from '../../../../_gql';
import Totals from './Totals';
import useSnackbarDialog from '../../../../../../store/snackbar/snackbarDialog';

const defaultTotals = {
  subtotal: 0,
  iva: 0,
  ieps: 0,
  total: 0,
};

const BodyTableComponent = () => {
  const { setSnackbar } = useSnackbarDialog();
  const itemVar = useReactiveVar(item);
  const handleContinueVar = useReactiveVar(handleContinue);
  const stepVar = useReactiveVar(step);
  const formVar = useReactiveVar(form);

  const [rows, setRows] = useState([]);
  const [errors, setErrors] = useState({});
  const [totals, setTotals] = useState(defaultTotals);

  const [createOperatingExpenses] = useMutation(GQL.CREATE, {
    update(cache, { data }) {
      try {
        cache.modify({
          fields: {
            operingExpenses(cachedOperingExpenses) {
              const newOperingExpenseRef = cache.writeFragment({
                data: { ...data.createOperingExpense },
                fragment: GQL.FRAGMENT_NEW_OPERING_EXPENSE,
              });
              return {
                ...cachedOperingExpenses,
                count: cachedOperingExpenses.count + 1,
                rows: [...cachedOperingExpenses.rows, newOperingExpenseRef],
              };
            },
          },
        });
      } catch (e) {
        console.log(e);
      }
    },
  });

  const [createServiceOrderExpense] = useMutation(GQL.CREATE_SERVICE_ORDER_EXPENSE);

  const storePursache = async () => {
    try {
      if (rows.length === 0) {
        const e = new Error('Añade al menos un concepto al gasto operativo.');
        e.name = 'table';
        e.input = true;
        throw e;
      }
      const Products = rows.map(el => {
        const discount = el.discount !== '' ? parseFloat(el.discount) : null;
        const unitPrice = el.price !== '' ? parseFloat(el.price) : null;
        return {
          concept: el.concept,
          quantity: parseFloat(el.quantity),
          discount,
          unitPrice,
          ieps: parseFloat(el.ieps),
          iva: parseInt(el.iva),
        };
      });
      const variables = {
        date: formVar.pursacheDate,
        branchId: formVar.branchId,
        providerId: ['2', '5', '6', '7', '8', '9'].includes(formVar.action)
          ? formVar.providerId.value
          : null,
        saleId: ['3', '4'].includes(formVar.action) ? formVar.saleId.value : null,
        description: formVar.description,
        Products,
        subFolio: formVar.subFolio,
        paymentForm: formVar.paymentForm,
        type: formVar.type,
        currency: formVar.currency,
        exchangeRate: formVar.currency === 'MXN' ? 1 : parseFloat(formVar.exchangeRate),
        status: 1,
        subtype: parseInt(formVar.action),
      };

      if (['2', '5', '6', '7', '8', '9'].includes(formVar.action))
        await createOperatingExpenses({ variables });
      if (['3', '4'].includes(formVar.action)) await createServiceOrderExpense({ variables });

      form({});
      actionForm({ ...actionForm(), isOpen: false, action: '' });
      step(0);
      setRows([]);
      handleContinueConfiguration({});
    } catch (e) {
      if (e.input) {
        setErrors({ [e.name]: e.message });
        return;
      }
      const parseErrors = parseError(e);
      parseErrors.forEach(el => {
        if (el.name === 'BAD_USER_INPUT') setErrors(el.message);
        else
          setSnackbar({
            isOpen: true,
            time: 3000,
            label: el.message,
            severity: 'error',
          });
      });
    }
  };

  useEffect(() => {
    if (handleContinueVar && stepVar === 1) {
      storePursache();
      handleContinue(false);
    }
  }, [handleContinueVar, stepVar]);

  useEffect(() => {
    if (Object.keys(itemVar).length > 0) {
      setRows(rows => [...rows, itemVar]);
      const iva = getTotalFromPercent(itemVar.iva, itemVar.total);
      const ieps = getTotalFromPercent(itemVar.ieps, itemVar.total);
      setTotals(totals => ({
        subtotal: totals.subtotal + parseFloat(itemVar.total),
        iva: totals.iva + iva,
        ieps: totals.ieps + ieps,
        total: totals.total + parseFloat(itemVar.total) + iva + ieps,
      }));
      item({});
      setErrors({});
    }
  }, [itemVar]);

  const handleDelete = async (e, i) => {
    const purchaseOrderDetailData = rows.find((el, ii) => i === ii);
    const tmpIva = purchaseOrderDetailData.iva ? parseFloat(purchaseOrderDetailData.iva) : 0;
    const tmpIeps = purchaseOrderDetailData.ieps ? parseFloat(purchaseOrderDetailData.ieps) : 0;
    const iva = getTotalFromPercent(tmpIva, purchaseOrderDetailData.total);
    const ieps = getTotalFromPercent(tmpIeps, purchaseOrderDetailData.total);
    setTotals(totals => ({
      subtotal: totals.subtotal - parseFloat(purchaseOrderDetailData.total),
      iva: totals.iva - iva,
      ieps: totals.ieps - ieps,
      total: totals.total - parseFloat(purchaseOrderDetailData.total) - iva - ieps,
    }));
    const tmp = rows.filter((el, ii) => i !== ii);
    setRows(tmp);
  };

  return (
    <>
      <TableBody>
        {errors.table && (
          <TableRow>
            <TableCell colSpan={6}>
              <Typography color='error' align='center' gutterBottom>
                {errors.table}
              </Typography>
            </TableCell>
          </TableRow>
        )}
        {rows.map((el, i) => (
          <TableRow key={i}>
            <TableCell>
              <div className='flex'>
                <IconButton
                  label='Eliminar'
                  size='small'
                  icon={<DeleteOutlineIcon />}
                  params={i}
                  action={handleDelete}
                />
              </div>
            </TableCell>
            <TableCell>{el.concept}</TableCell>
            <TableCell align='center'>{currencyFormat(el.quantity)}</TableCell>
            {formVar.action === '3' && (
              <TableCell align='right'>{`$${currencyFormat(el.discount)}`}</TableCell>
            )}
            <TableCell align='right'>{`$${currencyFormat(el.price)}`}</TableCell>
            <TableCell align='right'>{`$${currencyFormat(el.total)}`}</TableCell>
          </TableRow>
        ))}
        {rows.length > 0 && <Totals totals={totals} action={formVar.action} />}
      </TableBody>
      <Snackbar horizontal='center' />
    </>
  );
};

export default BodyTableComponent;
