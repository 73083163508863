import React from 'react';

import { useReactiveVar } from '@apollo/client';
import { ListItemButton, ListItemText } from '@mui/material';

import {
  branchSaleV2,
  cartItems,
  currencySaleV2,
  discountTypeSaleV2,
  formatSaleV2,
  totalsSaleV2,
} from '../../../../../../apollo/cache';

import GQL from '../../../../ChooseProduct/AddButton/_gql';
import useApolloMutation from '../../../../../../hooks/useApolloMutation';
import getInputErrorFormat from '../../../helpers/getInputErrorFormat';
import getProductToCheckCart from '../../../../helpers/getProductToCheckCart';
import getTotalizedCartProducts from '../../../../helpers/getTotalizedCartProducts';

const ItemFormFormatActionSalV2Component = props => {
  const formatSaleV2Var = useReactiveVar(formatSaleV2);

  const [checkCart, { loading }] = useApolloMutation(GQL.CHECK_CART);

  const handleClick = async () => {
    try {
      const cartItemsVar = cartItems();
      formatSaleV2([props.id, 1, props.id === '-1', false]);

      if (cartItemsVar.length === 0) return;

      const currencySaleV2Var = currencySaleV2();
      const branchSaleV2Var = branchSaleV2();

      const discountTypeSaleV2Var = discountTypeSaleV2();

      const newCart = getProductToCheckCart(cartItemsVar);

      const { data } = await checkCart({
        variables: {
          isInvoice: props.id === '-1',
          taxesOption: 1,
          discountType: parseInt(discountTypeSaleV2Var),
          shipping: 0,
          branchId: parseInt(branchSaleV2Var.branchInventory),
          currencyKey: parseFloat(currencySaleV2Var[0]),
          exchangeRate: parseFloat(currencySaleV2Var[1]),
          category: parseInt(props.id),
          Products: newCart,
          Transactions: [],
          showTaxes: false,
        },
      });

      const cart = getTotalizedCartProducts(data.totalizeCart.Products, cartItemsVar, {
        isInvoice: props.id === '-1',
        taxesOption: 1,
        showTaxes: false,
      });

      totalsSaleV2([
        data.totalizeCart.subtotal,
        data.totalizeCart.discount,
        data.totalizeCart.taxes,
        data.totalizeCart.total,
      ]);

      cartItems(cart);
    } catch (e) {
      getInputErrorFormat(e);
    }
  };

  return (
    <ListItemButton
      disabled={loading}
      onClick={handleClick}
      className={formatSaleV2Var[0].toString() === props.id.toString() ? 'bg-gray-200' : ''}
    >
      <ListItemText primary={props.label} />
    </ListItemButton>
  );
};

export default ItemFormFormatActionSalV2Component;
