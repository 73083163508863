import React from 'react';
import { useReactiveVar } from '@apollo/client';
import DialogComponent from '../../../presentationals/Dialog';
import { isCreateFormOpen } from '../../../apollo/cache';
import BodyCreateBoxComponent from './Body';
import FooterCreateBoxComponent from './Footer';
var CreateBoxComponent = function () {
    var isOpen = useReactiveVar(isCreateFormOpen);
    var handleClose = function () {
        isCreateFormOpen(false);
    };
    return (React.createElement(DialogComponent, { title: 'Nueva caja o paquete para env\u00EDo', fullScreen: true, open: isOpen, onClose: handleClose },
        React.createElement(BodyCreateBoxComponent, null),
        React.createElement(FooterCreateBoxComponent, null)));
};
export default CreateBoxComponent;
