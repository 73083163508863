import React, { useEffect, useRef } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import PersonOutlinedIcon from '@mui/icons-material/PersonOutlined';
import OnlineOrderMenuButtonComponent from '../helpers/MenuButton';
import SearchCustomerSalesGlobalInvoiceFieldComponent from '../../globalInvoice/Sales/CustomerField/SearchCustomer';
import useApolloLazyQuery from '../../../hooks/useApolloLazyQuery';
import GQL_CUSTOMER from '../../../apollo/gql/customer';
var OnlineOrderCustomerButtonComponent = function () {
    var searchCustomerSalesGlobalInvoiceFieldRef = useRef(null);
    var control = useFormContext().control;
    var _a = useController({
        name: 'customer',
        control: control,
    }).field, value = _a.value, onChange = _a.onChange;
    var _b = useApolloLazyQuery(GQL_CUSTOMER.GET_CUSTOMER_BY_ID), getCustomer = _b[0], _c = _b[1], loading = _c.loading, data = _c.data;
    useEffect(function () {
        if (value === null || value === void 0 ? void 0 : value.id)
            getCustomer({ variables: { id: value.id } });
    }, [value]);
    var handleClick = function (e) {
        var _a;
        (_a = searchCustomerSalesGlobalInvoiceFieldRef.current) === null || _a === void 0 ? void 0 : _a.open(e.currentTarget);
    };
    var handleChange = function (name, value) {
        onChange({ id: value, name: name });
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(OnlineOrderMenuButtonComponent, { onClick: handleClick, label: 'Cliente', disabled: loading, Icon: PersonOutlinedIcon, value: value ? value.name : 'Público en general', description: data ? data.phone : '' }),
        React.createElement(SearchCustomerSalesGlobalInvoiceFieldComponent, { disableAllCustomers: true, ref: searchCustomerSalesGlobalInvoiceFieldRef, onChange: handleChange })));
};
export default OnlineOrderCustomerButtonComponent;
