import { gql } from '@apollo/client';

const GET = gql`
	query Tags{
		tags{
			id name color
		}
	}
`;

const CREATE = gql`
	mutation CreateTag(
		$name: String!
		$color: String!
	){
		createTag(
			input: {
				name: $name
				color: $color
			}
		){
			id name color
		}
	}
`;

const DELETE = gql`
	mutation DeleteTag($id: ID!){
		deleteTag(id: $id){
			id name color
		}
	}
`;

export default { CREATE, GET, DELETE };
