var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { inputError } from '../../../../../../apollo/cache';
import setInputErrorFormat from '../../../../../sale.v2/Action/helpers/setInputErrorFormat';
import getInputErrorFormat from '../../../../../sale.v2/Action/helpers/getInputErrorFormat';
import ButtonFooterDialogComponent from '../../../../../../presentationals/Dialog/Footer/Button';
import GQL_INVOICE_COMPLEMENT from '../../../../../../apollo/gql/invoiceComplement';
import useApolloMutation from '../../../../../../hooks/useApolloMutation';
import useSnackbarDialog from '../../../../../../store/snackbar/snackbarDialog';
var CancelInvoiceComplementButtonComponent = function (props) {
    var setSnackbar = useSnackbarDialog().setSnackbar;
    var params = useParams();
    var _a = useState(false), loading = _a[0], setLoading = _a[1];
    var deleteInvoiceComplement = useApolloMutation(GQL_INVOICE_COMPLEMENT.DELETE_INVOICE_COMPLEMENT, {
        action: 'delete',
        mutationName: 'DeleteInvoiceComplement',
    })[0];
    var handleClick = function () { return __awaiter(void 0, void 0, void 0, function () {
        var error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, 3, 4]);
                    setLoading(true);
                    inputError({});
                    if (!props.values.reason)
                        throw new Error(setInputErrorFormat('reason', 'Selecciona la razón por la cual será cancelado el abono.'));
                    if (props.values.reason === '01' && !props.values.relatedFolio)
                        throw new Error(setInputErrorFormat('relatedFolio', 'Proporciona el UUID que sustituye a este abono.'));
                    if (!props.values.userPassword)
                        throw new Error(setInputErrorFormat('userPassword', 'Escribe una contraseña con privilegios para cancelación.'));
                    return [4 /*yield*/, deleteInvoiceComplement({
                            variables: {
                                id: params.invoiceComplementId,
                                userPassword: props.values.userPassword,
                                reason: props.values.reason,
                                relatedFolio: props.values.relatedFolio,
                            },
                        })];
                case 1:
                    _a.sent();
                    setSnackbar({
                        isOpen: true,
                        label: 'El complemento de pago fue cancelado exitosamente',
                        severity: 'success',
                    });
                    return [3 /*break*/, 4];
                case 2:
                    error_1 = _a.sent();
                    getInputErrorFormat(error_1);
                    return [3 /*break*/, 4];
                case 3:
                    setLoading(false);
                    return [7 /*endfinally*/];
                case 4: return [2 /*return*/];
            }
        });
    }); };
    return (React.createElement(ButtonFooterDialogComponent, { loading: loading, onClick: handleClick }, "Cancelar complemento"));
};
export default CancelInvoiceComplementButtonComponent;
